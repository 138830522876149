/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useCategory = ({
  actions,
}) => {
  useEffect(() => {
    actions.fetchVirtualRootCategories();
  }, []);
};

export default useCategory;
