import {isEmpty} from 'lodash';
import {ksort} from './fields';

export const getCamsFields = (fields, camsActionsOptions) => {
  const unsortedFieldsKeys = [];
  const unsortedSearchFieldsKeys = [];
  const camsFieldsKeys = Object.keys(fields);
  let count = 0;
  let searchCount = 0;
  for (let i = 0; i < camsFieldsKeys.length; i++) {
    if (fields[camsFieldsKeys[i]].order && camsFieldsKeys[i]) {
      unsortedFieldsKeys[fields[camsFieldsKeys[i]].order] = camsFieldsKeys[i];
      count++;
    }
    if (fields[camsFieldsKeys[i]].searchable && camsFieldsKeys[i]) {
      unsortedSearchFieldsKeys[fields[camsFieldsKeys[i]].searchableOrder] = camsFieldsKeys[i];
      searchCount++;
    }
  }

  const sortedFields = {};
  const sortedSearchFields = camsActionsOptions ? {
    error_status: {
      name: 'Errors',
      options: camsActionsOptions,
      searchable: true,
      searchableOrder: 0,
      type: 'select',
    },
  } : {};
  const sortedFieldsKeys = ksort(unsortedFieldsKeys);
  const sortedSearchFieldsKeys = ksort(unsortedSearchFieldsKeys);
  for (let i = 1; i <= count + 2; i++) {
    if (fields[sortedFieldsKeys[i]]) {
      sortedFields[sortedFieldsKeys[i]] = fields[sortedFieldsKeys[i]];
    }
  }
  for (let i = 1; i <= searchCount + 2; i++) {
    if (fields[sortedSearchFieldsKeys[i]]) {
      sortedSearchFields[sortedSearchFieldsKeys[i]] = fields[sortedSearchFieldsKeys[i]];
    }
  }

  const selectedFields = {};
  const storedSearchFields = sessionStorage.getItem('pim.searchFields');
  const parsedStoredSearchFields = JSON.parse(storedSearchFields) || { cams: [] };
  if (parsedStoredSearchFields['cams'].length > 0) {
    for (let i = 0; i < parsedStoredSearchFields['cams'].length; i++) {
      const field = parsedStoredSearchFields['cams'][i].replace('info.', '');
      if (sortedFields[field]) {
        selectedFields[field] = sortedFields[field];
      }
    }
  }

  return {
    sortedFields: sortedFields,
    sortedSearchFields: sortedSearchFields,
    currentFields: !isEmpty(selectedFields) ? selectedFields : sortedFields,
  };
};

export const camsFirstLoad = (firstLoad, setFirstLoad, getData, getProductActions, itemsPerPage) => {
  if (firstLoad === false) {
    setFirstLoad(true);
    if (getProductActions) {
      getProductActions();
    }
    const storedSearchFilters = sessionStorage.getItem('pim.searchFilters');
    const storedSearchFields = sessionStorage.getItem('pim.searchFields');
    const parsedStoredSearchFilters = JSON.parse(storedSearchFilters) || { cams: [] };
    const parsedStoredSearchFields = JSON.parse(storedSearchFields) || { cams: [] };
    if (parsedStoredSearchFilters.cams.length === 0 && parsedStoredSearchFields.cams.length === 0) {
      const query = {
        from: 0,
        sort: [
          { date: { order: 'desc' } },
        ],
      };
      if (itemsPerPage) {
        query['size'] = itemsPerPage;
      }
      getData(query);
    }
  }
};
