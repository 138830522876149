import React, { memo } from 'react';
import { shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { camelize } from 'humps';
import { isEmpty } from 'lodash';
import { Grid } from '@material-ui/core';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import VariantLevelItem from '../VariantLevelItem';
import {
  generateParentProductData,
  generateVariantProductData,
  generateMerchantProductData,
  moneyFormat
} from '../../pages/Home/utils/productDataGenerator';

const SortableItem = SortableElement(props => (
  <VariantLevelItem index={props.index} itemProps={props.itemProps} />
));

const SortableList = SortableContainer((props) => {
  const {
    sortableItemProps,
  } = props;

  return (
    <div style={{ minWidth: sortableItemProps.tableHeaderWidth }} className="product-list">
      <Grid container spacing={0} className="justify-center">
        {sortableItemProps.variants && sortableItemProps.variants.map((item, index) => {

          let value = [];
          if (item.type === 'parent') {
            value = generateParentProductData(
              item,
              sortableItemProps.defaultImage,
              sortableItemProps.certainProductAttributes,
              sortableItemProps.productFields.parent,
            );
          } else if (item.type === 'merchant') {
            value = generateMerchantProductData(
              item,
              sortableItemProps.defaultImage,
              sortableItemProps.certainProductAttributes,
              sortableItemProps.productFields.merchant,
            );
          } else {
            value = generateVariantProductData(
              item,
              sortableItemProps.defaultImage,
              sortableItemProps.certainProductAttributes,
            );
          }

          if (!isEmpty(sortableItemProps.productAttributes) && !isEmpty(item.variationAttributes)) {
            Object.keys(item.variationAttributes).forEach((attrKey) => {
              const currentAttribute = sortableItemProps.productAttributes
                .find(attr => camelize(attr.code) === attrKey);
              const val = item.variationAttributes[attrKey];

              if (currentAttribute) value[camelize(currentAttribute.label.en)] = val;
            });
          }

          if (item.merchants) {
            item.merchants.forEach(function(value) {
              if (value.minPrice) {
                value.minPrice = moneyFormat(value.minPrice, '$');
              }
              if (value.maxPrice) {
                value.maxPrice = moneyFormat(value.maxPrice, '$');
              }
            });
          }

          return (
            <SortableItem
              key={item.id}
              index={index}
              itemProps={{
                variantValue: value,
                itemsCount: sortableItemProps.variants.length,
                disabled: sortableItemProps.disabled,
                variantProductItem: item,
                certainProductAttributes: sortableItemProps.certainProductAttributes,
                onUnlockAttributesClick: sortableItemProps.onUnlockAttributesClick,
                hasComments:
                  sortableItemProps.productsCommentsCount.some(pcc => pcc.id === item.id),
                fetchActivityLogsByProductId: sortableItemProps.fetchActivityLogsByProductId,
                productCommentsLoading: sortableItemProps.productCommentsLoading,
                productComments: sortableItemProps.productComments,
                handleExpandVariantLevel: sortableItemProps.handleExpandVariantLevel,
                categoriesAttributes: sortableItemProps.categoriesAttributes,
                variantsCollapsed: sortableItemProps.variantsCollapsed,
                setVariantsCollapsed: sortableItemProps.setVariantsCollapsed,
                defaultImage: sortableItemProps.defaultImage,
                onProductSelect: sortableItemProps.onProductSelect,
                selectedProducts: sortableItemProps.selectedProducts,
                onEditClick: sortableItemProps.onEditClick,
                searchFields: sortableItemProps.searchFields,
                fetchVariantLevelChildrenProductsStarted:
                  sortableItemProps.fetchVariantLevelChildrenProductsStarted,
                lastParentChildrenLoaded: sortableItemProps.lastParentChildrenLoaded,
                isEditMode: sortableItemProps.isEditMode,
                disableEdit: sortableItemProps.disableEdit,
                commentMessages: sortableItemProps.commentMessages,
                bulkDeleteProductLockedAttributesStarted:
                  sortableItemProps.bulkDeleteProductLockedAttributesStarted,
                bulkDeleteProductLockedAttributesFinished:
                  sortableItemProps.bulkDeleteProductLockedAttributesFinished,
                multipleUpdateUserActivityLogs: sortableItemProps.multipleUpdateUserActivityLogs,
                currency: sortableItemProps.currency,
                urlKey: item.urlKey
              }}
            />
          );
        })}
      </Grid>
    </div>
  );
});

const VariantLevelList = memo((props) => {
  const {
    listProps, fields, currency
  } = props;

  const onSortEnd = () => {};

  return (
    <div className={`dnd-wrapper level-variant product list ignore-parent ${listProps.className}`}>
      <SortableList
        axis="y"
        useDragHandle
        sortableItemProps={{
          productList: listProps.variants,
          productFields: fields,
          onSortEnd,
          certainProductAttributes: listProps.certainProductAttributes,
          productsCommentsCount: listProps.productsCommentsCount,
          fetchActivityLogsByProductId: listProps.fetchActivityLogsByProductId,
          productCommentsLoading: listProps.productCommentsLoading,
          productComments: listProps.productComments,
          categoriesAttributes: listProps.categoriesAttributes,
          variantsCollapsed: listProps.variantsCollapsed,
          setVariantsCollapsed: listProps.setVariantsCollapsed,
          defaultImage: listProps.defaultImage,
          onProductSelect: listProps.onProductSelect,
          selectedProducts: listProps.selectedProducts,
          onEditClick: listProps.onEditClick,
          searchFields: listProps.searchFields,
          variants: listProps.variants,
          onUnlockAttributesClick: listProps.onUnlockAttributesClick,
          handleExpandVariantLevel: listProps.handleExpandVariantLevel,
          fetchVariantLevelChildrenProductsStarted:
            listProps.fetchVariantLevelChildrenProductsStarted,
          lastParentChildrenLoaded: listProps.lastParentChildrenLoaded,
          isEditMode: listProps.isEditMode,
          tableHeaderWidth: listProps.tableHeaderWidth,
          disableEdit: listProps.disableEdit,
          productAttributes: listProps.productAttributes,
          commentMessages: listProps.commentMessages,
          bulkDeleteProductLockedAttributesFinished:
            listProps.bulkDeleteProductLockedAttributesFinished,
          bulkDeleteProductLockedAttributesStarted:
            listProps.bulkDeleteProductLockedAttributesStarted,
          multipleUpdateUserActivityLogs: listProps.multipleUpdateUserActivityLogs,
          currency: currency,
        }}
      />
    </div>
  );
});

VariantLevelList.propTypes = {
  listProps: shape().isRequired,
};

VariantLevelList.defaultProps = {
  fields: {},
  currency: {},
};

const mapStateToProps = state => ({
  fields: state.system.modes.pim,
  currency: state.currency
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VariantLevelList));
