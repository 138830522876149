import React from 'react';
import { shape, number } from 'prop-types';

import Item from './Item';

const Row = ({ index, data, style }) => (
  <Item
    style={style}
    collapsed={data.collapsed}
    setCollapsed={data.setCollapsed}
    variantsCollapsed={data.variantsCollapsed}
    setVariantsCollapsed={data.setVariantsCollapsed}
    handleExpandParentLevel={data.handleExpandParentLevel}
    searchFields={data.searchFields}
    key={index}
    disabled={data.disabled}
    index={index}
    parentValue={data.getVal(data.list[index])}
    parentItem={data.list[index]}
    productList={data.list}
    productItem={data.list[index]}
    itemsCount={data.list.length}
    sortedAttributes={data.sortedAttributes}
    certainProductAttributes={data.certainProductAttributes}
    productAttributesForVariations={data.productAttributesForVariations}
    fetchProductAttributesForVariations={data.fetchProductAttributesForVariations}
    isEditMode={data.isEditMode}
    pagination={data.pagination}
    productComments={data.productComments}
    disableEdit={data.disableEdit}
    productsCommentsCount={data.productsCommentsCount}
    productCommentsLoading={data.productCommentsLoading}
    fetchActivityLogsByProductId={data.fetchActivityLogsByProductId}
    defaultImage={data.defaultImage}
  />
);

Row.propTypes = {
  index: number.isRequired,
  data: shape().isRequired,
  style: shape().isRequired,
};

export default Row;
