import { createSelector } from 'reselect';

const previewRootSelector = state => state;

const stateSelector = createSelector(
  previewRootSelector,
  preview => ({
    displayMode: preview.displayMode,
    isEditMode: preview.isEditMode,
    selected: preview.selected,
    virtualFacetFilter: preview.virtualFacetFilter,
    dir: preview.dir,
    facetsPreFilters: preview.facetsPreFilters,
    compoundSearch: preview.compoundSearch,
    searchQuery: preview.searchQuery,
    pagination: preview.pagination,
    list: preview.list,
    fetching: preview.fetching,
    selectedSearchFilter: preview.selectedSearchFilter,
    total: preview.total,
    isHiddenProductsMode: preview.isHiddenProductsMode,
    pages: preview.pages,
    fetched: preview.fetched,
    containerStyles: preview.containerStyles,
  }),
);

export const previewSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  previewSelector,
};
