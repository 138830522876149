export const getMapperCategoriesStyles = (item, marketplace, showMarkedCategories) => {
  if (item.level === 0) {
    return {
      paddingLeft: '0',
      fontSize: '13px',
    }
  }
  if (item.level === 1) {
    return {
      paddingLeft: '0',
      fontWeight: 'bold',
      fontSize: '14px',
      textDecoration: 'underline',
    }
  }
  if (item.level === 2) {
    return {
      paddingLeft: showMarkedCategories ? '0' : '20px',
      fontSize: '13px',
      textDecoration: 'underline',
    }
  }
  if (item.level === 3) {
    if (marketplace.indexOf('zap') !== -1) {
      return {
        paddingLeft: showMarkedCategories ? '0' : '40px',
        fontSize: '12px',
      }
    }
    if (marketplace.indexOf('ksp') !== -1) {
      return {
        paddingLeft: showMarkedCategories ? '0' : '40px',
        fontSize: '12px',
        textDecoration: 'underline',
      }
    }
  }
  if (item.level === 4) {
    return {
      paddingLeft: showMarkedCategories ? '0' : '60px',
      fontSize: '11px',
    }
  }
};

export const getCatClassName = (categories, item) => {
  const div = document.getElementById('cat-' + item.id);
  if (div && !div.className) {
    for (let i = 0; i < item.children.length; i++) {
      if (item.children[i].children && item.children[i].children.length > 0) {
        getCatClassName(categories, item.children[i]);
      } else {
        if (item && categories[item.children[i].id] === true) {
          return 'mapper-block';
        }
      }
    }
  }

  return 'mapper-none';
};

export const selectCategories = (id, checked, selectedCategories, children, finalLevel) => {
  if (!children) {
    children = [];
  }

  if (finalLevel === true) {
    checked = !selectedCategories[id];
  }

  if (checked === true) {
    selectedCategories[id] = true;

    for (let i = 0; i < children.length; i++) {
      if (children[i]) {
        selectedCategories[children[i].id] = true;
        selectCategories(children[i].id, checked, selectedCategories, children[i].children, finalLevel);
      }
    }
  } else {
    selectedCategories[id] = false;

    for (let i = 0; i < children.length; i++) {
      if (children[i]) {
        selectedCategories[children[i].id] = false;
        selectCategories(children[i].id, checked, selectedCategories, children[i].children, finalLevel);
      }
    }
  }

  return selectedCategories;
};

const setMarketCats = (item) => {
  return {
    id: item.id, title: item.title, level: item.level, count: item.count, successCount: item.successCount, children: [],
  };
};

export const getMarketCats = (markedCats, mapCategories, selectedMarketplaceCategories) => {
  for (let i = 0; i < mapCategories.length; i++) {
    if (mapCategories[i] && selectedMarketplaceCategories.indexOf(mapCategories[i].id.toString()) !== -1) {
      markedCats.push(setMarketCats(mapCategories[i]));
    }

    const children = mapCategories[i].children ? mapCategories[i].children : [];
    if (children.length === 0) {
      continue;
    }

    getMarketCats(markedCats, children, selectedMarketplaceCategories);
  }

  return markedCats;
};

export const getNewMarketCats = (newMarkedCats, mapCategories, uniqueMarketplaceCategories) => {
  for (let i = 0; i < mapCategories.length; i++) {
    for (let j = 0; j < uniqueMarketplaceCategories.length; j++) {
      if (parseInt(uniqueMarketplaceCategories[j]) === parseInt(mapCategories[i].id)) {
        newMarkedCats.push(setMarketCats(mapCategories[i]));
      }
    }

    const children = mapCategories[i].children ? mapCategories[i].children : [];
    if (children.length === 0) {
      continue;
    }

    getNewMarketCats(newMarkedCats, children, uniqueMarketplaceCategories);
  }

  return newMarkedCats;
};
