import { camelizeKeys, decamelizeKeys } from 'humps';
import firebase from 'firebase/app';
import RestActions from '../util/rest/actions';

const restSystemActions = new RestActions('system');
const {
  fetchAllStart,
  fetchAllFinished,
  updateStart,
  updateFinished,
  updateMultipleStart,
  updateMultipleFinished,
} = restSystemActions;

export const fetchAttributes = () => async (dispatch) => {
  dispatch(fetchAllStart());

  const modes = await firebase.database().ref('/modes').once('value');
  const modesObject = modes.val();
  const modesResult = camelizeKeys(modesObject);

  const result = {
    modes: {
      preview: modesResult.preview,
      export: modesResult.export,
      pim: modesResult.pim,
      mapping: modesObject.mapping,
      repricing: modesObject.repricing,
      activityLog: modesObject.activity_log,
      rules: modesObject.rules,
      cams: modesObject.cams,
      feeds: modesObject.feeds,
      repricer: modesObject.repricer,
      repricerIssues: modesObject.repricer_issues,
    },
  };

  await dispatch(fetchAllFinished(result));
};

export const updateAttribute = (key, attr) => (dispatch) => {
  const payload = Object.assign({}, decamelizeKeys(attr));

  delete payload.id;
  dispatch(updateStart());

  return firebase.database().ref(`modes/${key}/${attr.id}`).update({
    ...payload,
  })
    .then((res) => {
      dispatch(updateFinished(res));
    })
    .catch(error => dispatch(updateFinished({ error, hasError: true })));
};

export const updateAttributes = (attributes, key) => (dispatch) => {
  dispatch(updateMultipleStart());

  firebase.database().ref(`modes/${key}`).set(attributes)
    .then(() => {
      dispatch(updateMultipleFinished({
        success: true,
        hasError: false,
      }));
    })
    .catch(error => dispatch(updateMultipleFinished({ error, hasError: true })));
};


export default {
  fetchAttributes,
  updateAttribute,
}
