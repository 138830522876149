import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { isEmpty, uniq } from 'lodash';
import firebase from 'firebase/app';
import 'firebase/storage';

import RestActions from '../util/rest/actions';
import { showErrorNotification } from '../util/api';

import {
  UPDATE_INFLUENCER_FIELDS,
  SET_AVATAR_DATA,
  UPDATE_AVATAR_START,
  UPDATE_AVATAR_FINISHED,
  GET_AVATAR_FROM_STORAGE_START,
  GET_AVATAR_FROM_STORAGE_FINISHED,
  SET_MAIN_BANNER_DATA,
  GET_MAIN_BANNER_FROM_STORAGE_START,
  GET_MAIN_BANNER_FROM_STORAGE_FINISHED,
  UPDATE_MAIN_BANNER_START,
  UPDATE_MAIN_BANNER_FINISHED,
  SET_SELECTED_VIRTUAL_CATEGORIES,
  UPDATE_TOP_TEN_FILTERS,
  SET_SELECTED_CATEGORIES_ATTRIBUTES,
  INFLUENCER_FETCH_PRODUCT_LIST_START,
  INFLUENCER_FETCH_PRODUCT_LIST_FINISHED,
  INFLUENCER_FETCH_TOP_TEN_PRODUCTS_BY_IDS_START,
  INFLUENCER_FETCH_TOP_TEN_PRODUCTS_BY_IDS_FINISHED,
  INFLUENCER_COMPLETE_TOP_TEN_PRODUCTS_LIST_START,
  INFLUENCER_COMPLETE_TOP_TEN_PRODUCTS_LIST_FINISHED,
  INFLUENCER_SET_TOP_TEN_PRODUCT_FILTER,
  INFLUENCER_SET_SELECTED_TOP_TEN_PRODUCT_LIST,
  INFLUENCER_SET_TOP_TEN_PRODUCTS_IN_IDS,
  INFLUENCER_SET_PRODUCT_SECTION_PRODUCTS_IN_IDS,
  INFLUENCER_SET_SELECTED_TOP_TEN_PRODUCTS_FILTER,
  INFLUENCER_SET_SELECTED_PRODUCT_SECTION_PRODUCTS_FILTER,
  INFLUENCER_FETCH_EXAMPLE_PRODUCTS_START,
  INFLUENCER_FETCH_EXAMPLE_PRODUCTS_FINISHED,
  UPDATE_PRODUCTS_FILTERS,
  INFLUENCER_PRODUCTS_SECTION_PRODUCT_FILTER,
  INFLUENCER_SET_SELECTED_PRODUCTS_SECTION_PRODUCT_LIST,
  INFLUENCER_UPDATE_TOP_TEN_LOADED_PRODUCTS_IN,
  INFLUENCER_UPDATE_PRODUCT_SECTION_LOADED_PRODUCTS_IN,
  INFLUENCER_COMPLETE_PRODUCT_SECTION_PRODUCTS_LIST_START,
  INFLUENCER_COMPLETE_PRODUCT_SECTION_PRODUCTS_LIST_FINISHED,
  INFLUENCER_FETCH_PRODUCT_SECTION_PRODUCTS_BY_IDS_START,
  INFLUENCER_FETCH_PRODUCT_SECTION_PRODUCTS_BY_IDS_FINISHED,
  INFLUENCER_SET_SELECTED_DEAL_SECTION_PRODUCT_LIST,
  INFLUENCER_DEAL_SECTION_PRODUCT_FILTER,
  INFLUENCER_UPDATE_DEAL_SECTION_LOADED_PRODUCTS_IN,
  INFLUENCER_SET_DEAL_SECTION_PRODUCTS_IN_IDS,
  INFLUENCER_COMPLETE_DEAL_SECTION_PRODUCTS_LIST_START,
  INFLUENCER_COMPLETE_DEAL_SECTION_PRODUCTS_LIST_FINISHED,
  INFLUENCER_FETCH_DEAL_SECTION_PRODUCTS_BY_IDS_START,
  INFLUENCER_FETCH_DEAL_SECTION_PRODUCTS_BY_IDS_FINISHED,
  INFLUENCER_SET_SELECTED_DEAL_SECTION_PRODUCTS_FILTER,
  UPDATE_DEAL_FILTERS,
  SET_DEAL_BANNER_DATA,
  GET_DEAL_BANNER_FROM_STORAGE_START,
  GET_DEAL_BANNER_FROM_STORAGE_FINISHED,
  UPDATE_DEAL_BANNER_START,
  UPDATE_DEAL_BANNER_FINISHED,
  INFLUENCER_SET_TEXT_FIELDS_ERRORS,
  INFLUENCER_CLEAR_PRODUCT_STATE,
} from './types';

import {
  getValueByOperator,
  getFieldByOperator,
} from '../util/mapFiltersByOperator';
import { generateRequestBody } from '../util/productPayload';
import { mapExportableProducts } from '../util/mapExportableProducts';

import {
  getFrontendProductsByIds,
} from '../services/API/product';

const restInfluencerActions = new RestActions('influencer');
const {
  fetchStart,
  fetchFinished,
  createStart,
  createFinished,
  updateStart,
  updateFinished,
} = restInfluencerActions;

export const createInfluencer = data => async (dispatch) => {
  dispatch(createStart());
  const ref = firebase.database().ref(`influencers/${data.id}`);
  const result = await ref.set(decamelizeKeys(data));

  dispatch(createFinished({
    createdInfluencer: { ...result },
  }));
};

export const fetchInfluencer = userId => async (dispatch) => {
  dispatch(fetchStart());

  try {
    const influencer = await firebase.database().ref(`/influencers/${userId}`).once('value');
    const influencerData = camelizeKeys(influencer.val());

    dispatch(fetchFinished({
      item: influencerData,
      original: influencerData,
    }));
  } catch (error) {
    showErrorNotification(error, 'Firebase');
    throw error;
  }
};

export const updateInfluencerFields = influencer => (dispatch) => {
  dispatch({
    type: UPDATE_INFLUENCER_FIELDS,
    payload: { item: influencer },
  });
};

export const getAvatarFromStorage = (influencerId, nameWithPath) => async (dispatch) => {
  dispatch({
    type: GET_AVATAR_FROM_STORAGE_START,
    payload: {
      getAvatarFromStorageFinished: false,
    },
  });

  const storageRef = firebase.storage().ref();
  const url = await storageRef.child(nameWithPath).getDownloadURL();

  dispatch({
    type: GET_AVATAR_FROM_STORAGE_FINISHED,
    payload: {
      avatarUrl: url,
      getAvatarFromStorageFinished: true,
    },
  });

  return url;
};

export const uploadAvatar = ({ url, nameWithPath }) => async (dispatch) => {
  const storageRef = firebase.storage().ref();
  const imagesRef = storageRef.child(nameWithPath);

  dispatch({
    type: UPDATE_AVATAR_START,
    payload: {
      updateAvatarStart: true,
      updateAvatarFinished: false,
    },
  });

  try {
    imagesRef.putString(
      url,
      'data_url',
    );
  } catch (error) {
    showErrorNotification(error, 'Firebase');
    throw error;
  }

  dispatch({
    type: UPDATE_AVATAR_FINISHED,
    payload: {
      avatarData: {},
      updateAvatarStart: false,
      updateAvatarFinished: true,
    },
  });
};

export const getMainBannerFromStorage = (influencerId, nameWithPath) => async (dispatch) => {
  dispatch({
    type: GET_MAIN_BANNER_FROM_STORAGE_START,
    payload: {
      getMainBannerFromStorageFinished: false,
    },
  });

  const storageRef = firebase.storage().ref();
  const url = await storageRef.child(nameWithPath).getDownloadURL();

  dispatch({
    type: GET_MAIN_BANNER_FROM_STORAGE_FINISHED,
    payload: {
      bannerUrl: url,
      getMainBannerFromStorageFinished: true,
    },
  });

  return url;
};

export const getDealBannerFromStorage = (influencerId, nameWithPath) => async (dispatch) => {
  dispatch({
    type: GET_DEAL_BANNER_FROM_STORAGE_START,
    payload: {
      getDealBannerFromStorageFinished: false,
    },
  });

  const storageRef = firebase.storage().ref();
  const url = await storageRef.child(nameWithPath).getDownloadURL();

  dispatch({
    type: GET_DEAL_BANNER_FROM_STORAGE_FINISHED,
    payload: {
      bannerUrl: url,
      getDealBannerFromStorageFinished: true,
    },
  });

  return url;
};

export const uploadMainBanner = ({ url, nameWithPath }) => async (dispatch) => {
  const storageRef = firebase.storage().ref();
  const imagesRef = storageRef.child(nameWithPath);

  dispatch({
    type: UPDATE_MAIN_BANNER_START,
    payload: {
      updateMainBannerStart: true,
      updateMainBannerFinished: false,
    },
  });

  try {
    imagesRef.putString(
      url,
      'data_url',
    );
  } catch (error) {
    showErrorNotification(error, 'Firebase');
    throw error;
  }

  dispatch({
    type: UPDATE_MAIN_BANNER_FINISHED,
    payload: {
      mainBannerData: {},
      updateMainBannerStart: false,
      updateMainBannerFinished: true,
    },
  });
};

export const uploadDealBanner = ({ url, nameWithPath }) => async (dispatch) => {
  const storageRef = firebase.storage().ref();
  const imagesRef = storageRef.child(nameWithPath);

  dispatch({
    type: UPDATE_DEAL_BANNER_START,
    payload: {
      updateDealBannerStart: true,
      updateDealBannerFinished: false,
    },
  });

  try {
    imagesRef.putString(
      url,
      'data_url',
    );
  } catch (error) {
    showErrorNotification(error, 'Firebase');
    throw error;
  }

  dispatch({
    type: UPDATE_DEAL_BANNER_FINISHED,
    payload: {
      dealBannerData: {},
      updateDealBannerStart: false,
      updateDealBannerFinished: true,
    },
  });
};

export const updateInfluencer = influencer => async (dispatch) => {
  dispatch(updateStart());

  try {
    const result = await firebase.database().ref(`influencers/${influencer.id}`).update({
      ...decamelizeKeys(influencer),
    });
    dispatch(updateFinished({ item: result }));
  } catch (error) {
    showErrorNotification(error, 'Firebase');
    dispatch(updateFinished({ hasErrors: true, error: error }));
  }
};

export const setAvatarData = file => (dispatch) => {
  dispatch({
    type: SET_AVATAR_DATA,
    payload: { avatarData: file },
  });
};

export const setMainBannerData = file => (dispatch) => {
  dispatch({
    type: SET_MAIN_BANNER_DATA,
    payload: { mainBannerData: file },
  });
};

export const setDealBannerData = file => (dispatch) => {
  dispatch({
    type: SET_DEAL_BANNER_DATA,
    payload: { dealBannerData: file },
  });
};

export const setSelectedVirtualCategories = categories => (dispatch) => {
  dispatch({
    type: SET_SELECTED_VIRTUAL_CATEGORIES,
    payload: { virtualCategories: categories },
  });
};

export const updateTopTenFilters = filters => (dispatch) => {
  dispatch({
    type: UPDATE_TOP_TEN_FILTERS,
    payload: { topTenFilters: filters },
  });
};

export const updateProductsFilters = filters => (dispatch) => {
  dispatch({
    type: UPDATE_PRODUCTS_FILTERS,
    payload: { productsFilters: filters },
  });
};

export const updateDealFilters = filters => (dispatch) => {
  dispatch({
    type: UPDATE_DEAL_FILTERS,
    payload: { dealFilters: filters },
  });
};

export const setSelectedCategoriesAttributes = attributes => (dispatch) => {
  dispatch({
    type: SET_SELECTED_CATEGORIES_ATTRIBUTES,
    payload: { selectedCategoriesAttributes: attributes },
  });
};

export const fetchProductList = ({
  viewMode,
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  fullText = {},
  compoundSearch = null,
}) => (dispatch, getState) => {
  const { list } = getState().previewFilterAttribute;
  const mappedFilters = filter.map((f) => {
    const group = f.group.map(g => ({
      ...g,
      value: getValueByOperator({ value: g.value, operator: g.operator }),
      field: getFieldByOperator({
        field: g.field, operator: g.operator, previewFilterAttribute: list,
      }),
    }));

    return {
      ...f,
      group,
    };
  });

  const params = {
    ...generateRequestBody({
      viewMode,
      filter: mappedFilters,
      fields,
      pagination,
      fullText,
      searchFilter,
    }),
  };

  if (compoundSearch && !isEmpty(compoundSearch) && compoundSearch.value) {
    params.body.compoundSearch = compoundSearch;
  }

  dispatch({
    type: INFLUENCER_FETCH_PRODUCT_LIST_START,
    payload: {
      influencerFetchProductListStart: true,
      influencerFetchProductListFinished: false,
    },
  });

  return axios.post(
    params.path,
    decamelizeKeys(params.body),
  ).then((res) => {
    const {
      pages, total, aggregation,
    } = res.data;
    const result = res.data.data.map((p) => {
      if (p.variants) {
        return {
          ...p,
          variants: Object.keys(p.variants).map((v) => {
            const item = p.variants[v];
            return ({
              ...item,
              id: v,
            });
          }),
        };
      }
      return p;
    });

    dispatch({
      type: INFLUENCER_FETCH_PRODUCT_LIST_FINISHED,
      payload: {
        list: mapExportableProducts(camelizeKeys(result)),
        aggregation,
        pages,
        total,
        influencerFetchProductListStart: false,
        influencerFetchProductListFinished: true,
      },
    });
  }, (error) => {
    dispatch({
      type: INFLUENCER_FETCH_PRODUCT_LIST_FINISHED,
      payload: {
        error: error.response.data,
        hasErrors: true,
        list: [],
      },
    });
  });
};

export const fetchTopTenProductsByIds = (ids, key, pagination) => async (dispatch, getState) => {
  const influencerState = getState().influencer;
  const currentKey = key || 'productsByIds';
  const currentTargetList = influencerState.product.topTen[currentKey];

  dispatch({
    type: INFLUENCER_FETCH_TOP_TEN_PRODUCTS_BY_IDS_START,
    payload: {
      fetchProductsByIdsStarted: true,
      fetchProductsByIdsFinished: false,
    },
  });

  try {
    const query = {
      filter: [{
        group: [{
          field: 'id',
          value: uniq(ids),
          operator: 'in',
        }],
      }],
      pagination,
    };

    const res = await getFrontendProductsByIds(query);
    const items = mapExportableProducts(camelizeKeys(res));

    dispatch({
      type: INFLUENCER_FETCH_TOP_TEN_PRODUCTS_BY_IDS_FINISHED,
      payload: {
        [currentKey]: [...currentTargetList.map((l) => {
          const itemToSet = items.find(p => p.id === l.id);
          return itemToSet || l;
        })],
        fetchProductsByIdsStarted: false,
        fetchProductsByIdsFinished: true,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: INFLUENCER_FETCH_TOP_TEN_PRODUCTS_BY_IDS_FINISHED,
      payload: {
        error: error.response.data, hasErrors: true,
      },
    });
  }
};

export const fetchProductSectionProductsByIds = (
  ids, key, pagination,
) => async (dispatch, getState) => {
  const influencerState = getState().influencer;
  const currentKey = key || 'productsByIds';
  const currentTargetList = influencerState.product.productSection[currentKey];

  dispatch({
    type: INFLUENCER_FETCH_PRODUCT_SECTION_PRODUCTS_BY_IDS_START,
    payload: {
      fetchProductsByIdsStarted: true,
      fetchProductsByIdsFinished: false,
    },
  });

  try {
    const query = {
      filter: [{
        group: [{
          field: 'id',
          value: uniq(ids),
          operator: 'in',
        }],
      }],
      pagination,
    };

    const res = await getFrontendProductsByIds(query);
    const items = mapExportableProducts(camelizeKeys(res));

    dispatch({
      type: INFLUENCER_FETCH_PRODUCT_SECTION_PRODUCTS_BY_IDS_FINISHED,
      payload: {
        [currentKey]: [...currentTargetList.map((l) => {
          const itemToSet = items.find(p => p.id === l.id);
          return itemToSet || l;
        })],
        fetchProductsByIdsStarted: false,
        fetchProductsByIdsFinished: true,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: INFLUENCER_FETCH_PRODUCT_SECTION_PRODUCTS_BY_IDS_FINISHED,
      payload: {
        error: error.response.data, hasErrors: true,
      },
    });
  }
};

export const fetchDealSectionProductsByIds = (
  ids, key, pagination,
) => async (dispatch, getState) => {
  const influencerState = getState().influencer;
  const currentKey = key || 'productsByIds';
  const currentTargetList = influencerState.product.deal[currentKey];

  dispatch({
    type: INFLUENCER_FETCH_DEAL_SECTION_PRODUCTS_BY_IDS_START,
    payload: {
      fetchProductsByIdsStarted: true,
      fetchProductsByIdsFinished: false,
    },
  });

  try {
    const query = {
      filter: [{
        group: [{
          field: 'id',
          value: uniq(ids),
          operator: 'in',
        }],
      }],
      pagination,
    };

    const res = await getFrontendProductsByIds(query);
    const items = mapExportableProducts(camelizeKeys(res));

    dispatch({
      type: INFLUENCER_FETCH_DEAL_SECTION_PRODUCTS_BY_IDS_FINISHED,
      payload: {
        [currentKey]: [...currentTargetList.map((l) => {
          const itemToSet = items.find(p => p.id === l.id);
          return itemToSet || l;
        })],
        fetchProductsByIdsStarted: false,
        fetchProductsByIdsFinished: true,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: INFLUENCER_FETCH_DEAL_SECTION_PRODUCTS_BY_IDS_FINISHED,
      payload: {
        error: error.response.data, hasErrors: true,
      },
    });
  }
};

export const completeTopTenProductList = (ids, key, pagination) => async (dispatch, getState) => {
  dispatch({
    type: INFLUENCER_COMPLETE_TOP_TEN_PRODUCTS_LIST_START,
    payload: {
      completingCategoryProductsList: true,
      completedCategoryProductsList: false,
    },
  });

  try {
    const currentKey = key || 'productsByIds';
    const influencerState = getState().influencer;
    const currentTargetList = influencerState.product.topTen[currentKey];
    const query = {
      filter: [{
        group: [{
          field: 'id',
          value: uniq(ids),
          operator: 'in',
        }],
      }],
      pagination,
    };

    const res = await getFrontendProductsByIds(query);
    const items = camelizeKeys(res);

    dispatch({
      type: INFLUENCER_COMPLETE_TOP_TEN_PRODUCTS_LIST_FINISHED,
      payload: {
        completingCategoryProductsList: false,
        completedCategoryProductsList: true,
        [currentKey]: [...currentTargetList.map((l) => {
          const itemToSet = items.find(p => p.id === l.id);
          return itemToSet || l;
        })],
      },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: INFLUENCER_COMPLETE_TOP_TEN_PRODUCTS_LIST_FINISHED,
      payload: {
        error: error.response.data, hasErrors: true,
      },
    });
  }
};

export const completeProductSectionProductList = (
  ids, key, pagination,
) => async (dispatch, getState) => {
  dispatch({
    type: INFLUENCER_COMPLETE_PRODUCT_SECTION_PRODUCTS_LIST_START,
    payload: {
      completingCategoryProductsList: true,
      completedCategoryProductsList: false,
    },
  });

  const currentKey = key || 'productsByIds';
  const influencerState = getState().influencer;
  const currentTargetList = influencerState.product.productSection[currentKey];
  const query = {
    filter: [{
      group: [{
        field: 'id',
        value: uniq(ids),
        operator: 'in',
      }],
    }],
    pagination,
  };

  const res = await getFrontendProductsByIds(query);
  const items = camelizeKeys(res);

  dispatch({
    type: INFLUENCER_COMPLETE_PRODUCT_SECTION_PRODUCTS_LIST_FINISHED,
    payload: {
      completingCategoryProductsList: false,
      completedCategoryProductsList: true,
      [currentKey]: [...currentTargetList.map((l) => {
        const itemToSet = items.find(p => p.id === l.id);
        return itemToSet || l;
      })],
    },
  });
};

export const completeDealSectionProductList = (
  ids, key, pagination,
) => async (dispatch, getState) => {
  dispatch({
    type: INFLUENCER_COMPLETE_DEAL_SECTION_PRODUCTS_LIST_START,
    payload: {
      completingCategoryProductsList: true,
      completedCategoryProductsList: false,
    },
  });

  const currentKey = key || 'productsByIds';
  const influencerState = getState().influencer;
  const currentTargetList = influencerState.product.deal[currentKey];
  const query = {
    filter: [{
      group: [{
        field: 'id',
        value: uniq(ids),
        operator: 'in',
      }],
    }],
    pagination,
  };

  const res = await getFrontendProductsByIds(query);
  const items = camelizeKeys(res);

  dispatch({
    type: INFLUENCER_COMPLETE_DEAL_SECTION_PRODUCTS_LIST_FINISHED,
    payload: {
      completingCategoryProductsList: false,
      completedCategoryProductsList: true,
      [currentKey]: [...currentTargetList.map((l) => {
        const itemToSet = items.find(p => p.id === l.id);
        return itemToSet || l;
      })],
    },
  });
};

export const setSelectedTopTenProductList = payload => (dispatch) => {
  dispatch({
    type: INFLUENCER_SET_SELECTED_TOP_TEN_PRODUCT_LIST,
    payload: {
      selectedProductList: payload,
    },
  });
};

export const setSelectedProductsSectionProductList = payload => (dispatch) => {
  dispatch({
    type: INFLUENCER_SET_SELECTED_PRODUCTS_SECTION_PRODUCT_LIST,
    payload: {
      selectedProductList: payload,
    },
  });
};

export const setSelectedDealSectionProductList = payload => (dispatch) => {
  dispatch({
    type: INFLUENCER_SET_SELECTED_DEAL_SECTION_PRODUCT_LIST,
    payload: {
      selectedProductList: payload,
    },
  });
};

export const setProductsTopTenInIds = payload => (dispatch) => {
  dispatch({
    type: INFLUENCER_SET_TOP_TEN_PRODUCTS_IN_IDS,
    payload: {
      productsInIds: payload,
    },
  });
};

export const setProductSectionProductsInIds = payload => (dispatch) => {
  dispatch({
    type: INFLUENCER_SET_PRODUCT_SECTION_PRODUCTS_IN_IDS,
    payload: {
      productsInIds: payload,
    },
  });
};

export const setDealSectionProductsInIds = payload => (dispatch) => {
  dispatch({
    type: INFLUENCER_SET_DEAL_SECTION_PRODUCTS_IN_IDS,
    payload: {
      productsInIds: payload,
    },
  });
};

export const updateTopTenLoadedProductsIn = payload => (dispatch) => {
  dispatch({
    type: INFLUENCER_UPDATE_TOP_TEN_LOADED_PRODUCTS_IN,
    payload: {
      productsByIdsForIn: payload,
    },
  });
};

export const updateProductSectionLoadedProductsIn = payload => (dispatch) => {
  dispatch({
    type: INFLUENCER_UPDATE_PRODUCT_SECTION_LOADED_PRODUCTS_IN,
    payload: {
      productsByIdsForIn: payload,
    },
  });
};

export const updateDealSectionLoadedProductsIn = payload => (dispatch) => {
  dispatch({
    type: INFLUENCER_UPDATE_DEAL_SECTION_LOADED_PRODUCTS_IN,
    payload: {
      productsByIdsForIn: payload,
    },
  });
};

export const setTopTenProductFilter = ProductFilter => (dispatch) => {
  dispatch({
    type: INFLUENCER_SET_TOP_TEN_PRODUCT_FILTER,
    payload: { ProductFilter },
  });
};

export const setProductsSectionProductFilter = ProductFilter => (dispatch) => {
  dispatch({
    type: INFLUENCER_PRODUCTS_SECTION_PRODUCT_FILTER,
    payload: { ProductFilter },
  });
};

export const setDealSectionProductFilter = ProductFilter => (dispatch) => {
  dispatch({
    type: INFLUENCER_DEAL_SECTION_PRODUCT_FILTER,
    payload: { ProductFilter },
  });
};

export const setSelectedTopTenProductsFilters = selectedProductsFilters => (dispatch) => {
  dispatch({
    type: INFLUENCER_SET_SELECTED_TOP_TEN_PRODUCTS_FILTER,
    payload: { selectedProductsFilters },
  });
};

export const setSelectedProductSectionProductsFilters = selectedProductsFilters => (dispatch) => {
  dispatch({
    type: INFLUENCER_SET_SELECTED_PRODUCT_SECTION_PRODUCTS_FILTER,
    payload: { selectedProductsFilters },
  });
};

export const setSelectedDealSectionProductsFilters = selectedProductsFilters => (dispatch) => {
  dispatch({
    type: INFLUENCER_SET_SELECTED_DEAL_SECTION_PRODUCTS_FILTER,
    payload: { selectedProductsFilters },
  });
};

export const fetchExampleProducts = (ids, pagination) => async (dispatch) => {
  dispatch({
    type: INFLUENCER_FETCH_EXAMPLE_PRODUCTS_START,
    payload: {
      fetchingExampleProductsList: true,
      fetchedExampleProductsList: false,
    },
  });

  try {
    const query = {
      filter: [{
        group: [{
          field: 'id',
          value: uniq(ids),
          operator: 'in',
        }],
      }],
      fields: [
        'title',
        'description',
        'status',
        'images',
        'specifications',
      ],
      pagination,
    };

    const res = await getFrontendProductsByIds(query);
    const items = mapExportableProducts(camelizeKeys(res));

    dispatch({
      type: INFLUENCER_FETCH_EXAMPLE_PRODUCTS_FINISHED,
      payload: {
        fetchingExampleProductsList: false,
        fetchedExampleProductsList: true,
        exampleProductsList: items,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'Firebase');
    throw error;
  }
};

export const setTextFieldsErrors = payload => (dispatch) => {
  dispatch({
    type: INFLUENCER_SET_TEXT_FIELDS_ERRORS,
    payload: {
      textFieldsErrors: payload,
    },
  });
};

export const clearInfluencerProductState = () => (dispatch) => {
  dispatch({
    type: INFLUENCER_CLEAR_PRODUCT_STATE,
  });
};
