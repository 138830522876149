import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { decamelizeKeys } from 'humps';

import {
  productsFrontAppPath,
  productsSearchAppPath,
} from './paths';

export const generateRequestBody = ({
  viewMode, filter, fields, pagination,
  fullText, searchFilter, virtualFacets,
}) => {
  const useSearchKey = !!searchFilter.urlKey;
  const mappedSearchParams = !isEmpty(searchFilter)
    ? `?${queryString.stringify(decamelizeKeys(searchFilter))}` : '';
  const params = {
    path: '',
    body: {
      pagination,
    },
  };

  switch (viewMode) {
    case 'frontend':
      params.path = `${productsFrontAppPath}/${!useSearchKey
        ? 'search'
        : 'navigation_by_url_key'}${mappedSearchParams}`;
      break;
    case 'frontendWithParams':
      params.body.filter = filter;
      params.body.virtual_facets = virtualFacets;
      params.path = `${productsFrontAppPath}/${!useSearchKey
        ? 'search'
        : 'navigation_by_url_key'}${mappedSearchParams}`;
      break;
    default:
      params.path = `${productsSearchAppPath}${mappedSearchParams}`;
      params.body.filter = filter;
      break;
  }

  if (!isEmpty(fullText)) params.body.fullText = fullText;
  if (!isEmpty(fields)) params.body.fields = fields;

  return params;
};

export default generateRequestBody;
