import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const Info = props => (
  props.showInfo && (
    <Tooltip
      className="ml-15"
      title="You have unsaved data."
      placement="right"
    >
      <i className="icon-info" />
    </Tooltip>
  )
);

export default Info;
