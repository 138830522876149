import React from 'react';
import {
  number, shape, func, arrayOf, string, bool,
} from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { isEmpty } from 'lodash';
import Field from '../Field';
import IntlMessages from '../../util/IntlMessages';
import { ascDescOptions } from '../../util/constants/constants';

export const SortByRow = ({
  row, sortIndex, onSortSelectChange, attrOptions,
  onRowRemove, onRankingChange, errors, rankingRuleCode,
  rankingOptions, loading, disabled,
}) => {

  const handleOnChange = (e) => {
    onSortSelectChange(e, row, 'field', sortIndex);
  };

  const onDirectionChange = (e) => {
    onSortSelectChange(e, row, 'defaultValue', sortIndex);
  };

  const handleRankingChange = (e) => {
    onRankingChange(e);
  };

  const onRemoveClick = () => {
    onRowRemove(row, sortIndex);
  };

  return (
    <>
      <Field
        key={`attributeName-${row.field}-${sortIndex}`}
        searchable
        disabled={loading || disabled}
        element="autocomplete"
        useAdvancedOptions
        containerClassName="block"
        onChange={handleOnChange}
        className={`select-wrapper form-group block ml-0 ${!isEmpty(errors) && errors.field ? 'has-errors' : ''}`}
        label="Attribute name"
        name={`${row.field}-${sortIndex}`}
        inputProps={{
          name: 'attributeName',
          id: 'attribute-value-select',
        }}
        value={attrOptions.find(ao => ao.value === row.field) || ''}
        options={attrOptions}
        hideStaticLabel
        helperText={
          errors && !isEmpty(errors) && errors.field
            ? <IntlMessages id={errors.field} />
            : null
        }
        errors={errors && !isEmpty(errors) ? errors.field : {}}
      />
      {row.field === 'ranking_rule' && (
        <div className="flex flex-0 justify-center m-lg">
          <Field
            element="message"
            message="Is"
          />
        </div>
      )}
      {row.field === 'ranking_rule' && (
        <Field
          key={`rankingRuleCode-${row.field}-${sortIndex}`}
          element="autocomplete"
          containerClassName="block"
          disabled={loading || disabled}
          onChange={handleRankingChange}
          className={`select-wrapper form-group block ml-0 ${!isEmpty(errors) && errors.rankingRuleCode ? 'has-errors' : ''}`}
          label="Ranking"
          name="ranking"
          inputProps={{
            name: 'rankingRuleCode',
            id: 'ranking-select',
          }}
          value={rankingOptions.find(ao => ao.value === rankingRuleCode) || ''}
          options={rankingOptions}
          hideStaticLabel
          helperText={
            errors && !isEmpty(errors) && errors.rankingRuleCode
              ? <IntlMessages id={errors.rankingRuleCode} />
              : null
          }
          errors={errors && !isEmpty(errors) ? errors.rankingRuleCode : {}}
        />
      )}
      {row.field !== 'ranking_rule' && (
        <Field
          key={`attributeSortDirection-${row.field}-${sortIndex}`}
          element="autocomplete"
          containerClassName="block ml-15"
          disabled={loading || disabled}
          onChange={onDirectionChange}
          className={`select-wrapper form-group block ${!isEmpty(errors) && errors.defaultValue ? 'has-errors' : ''}`}
          label="Direction"
          name={`${row.field}-${row.defaultValue}`}
          inputProps={{
            name: 'attributeSortDirection',
            id: 'attribute-sort-direction',
          }}
          value={row.options.find(ro => ro.value === row.defaultValue) || ascDescOptions.asc}
          options={row.options}
          hideStaticLabel
          helperText={
            errors && !isEmpty(errors) && errors.defaultValue
              ? <IntlMessages id={errors.defaultValue} />
              : null
          }
          errors={errors && !isEmpty(errors) ? errors.defaultValue : {}}
        />
      )}
      <IconButton
        disabled={loading || disabled}
        aria-label="Delete"
        className="btn-mui-sm text-danger ml-10"
        onClick={onRemoveClick}
      >
        <i className="ti-close" />
      </IconButton>
    </>
  );
};

SortByRow.propTypes = {
  row: shape(),
  errors: shape(),
  attrOptions: arrayOf(shape()),
  rankingOptions: arrayOf(shape()),
  sortIndex: number,
  onSortSelectChange: func,
  onRankingChange: func,
  onRowRemove: func,
  rankingRuleCode: string,
  loading: bool,
  disabled: bool,
};

SortByRow.defaultProps = {
  row: {},
  errors: {},
  attrOptions: [],
  rankingOptions: [],
  sortIndex: null,
  onSortSelectChange: null,
  onRankingChange: null,
  onRowRemove: null,
  rankingRuleCode: '',
  loading: false,
  disabled: false,
};

export default SortByRow;
