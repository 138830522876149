import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import IntlMessages from '../../../../util/IntlMessages';
import Pagination from "../../../../components/Pagination";
import PagePlaceholder from "../../../../components/PagePlaceholder";

const CamsTable = ({
  getCamsData,
  fields,
  data,
  camsActions,
  camsScroll,
  camsCondition,
  itemsPerPage,
}) => {
  const [camsData, setCamsData] = useState([]);
  const [statusInfo, setStatusInfo] = useState(null);
  const [allPages, setAllPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const formatData = [];
    if (data.hits && data.hits.hits) {
      for (let i = 0; i < data.hits.hits.length; i++) {
        formatData[i] = data.hits.hits[i]._source;
      }
    }
    setCamsData(formatData);
    setAllPages(Math.ceil(camsScroll.total / itemsPerPage));
    const currentPage = Math.ceil(camsScroll.offset / itemsPerPage) + 1;
    setCurrentPage(currentPage);
  }, [
    data, camsScroll, itemsPerPage
  ]);

  const handleShowStatusInfo = (e, affected_id, action) => {
    setStatusInfo(affected_id + action);
  }

  const handleHideStatusInfo = () => {
    setTimeout(() => {
      setStatusInfo('');
    },100);
  }

  const tableData = [];
  for (let i = 0; i < camsData.length; i++) {
    const productStatus = {};
    const productStatusKeys = Object.keys(camsData[i].product_status);

    let firstError = false;

    for (let j = 0; j < camsActions.length; j++) {
      const prodStat = camsData[i].product_status[camsActions[j]];
      if (prodStat) {
        productStatus[j] = {
          type: productStatusKeys[j],
          date: prodStat.date,
          error_reason: prodStat.error_reason,
          status: firstError === false ? prodStat.status : 'none',
        };
        if (prodStat.status === 'error') {
          firstError = true;
        }
      } else {
        productStatus[j] = {
          date: '',
          status: 'none',
        };
      }
    }

    tableData[i] = {
      affected_id: camsData[i].affected_id,
      date: camsData[i].date,
      info: camsData[i].info,
      product_status: productStatus,
    }
  }

  const getTableRowColor = (itemKey) => {
    return itemKey%2 ? '#ffffff' : '#DFDFDF';
  };

  const CamsTitle = (props) => {
    return (
      <TableCell className="cams-no-wrap mapper-td" style={{width: props.width}}>
        <strong>{props.name}</strong>
      </TableCell>
    );
  };

  const CamsItem = (props) => {
    let tableValue = props.parent ? props.item[props.parent][props.value] : props.item[props.value];
    if (props.type === 'date') {
      tableValue = tableValue.toString().replace('T', ' ').substr(0, 19);
    }
    if (props.type === 'camelize') {
      tableValue = tableValue.replace(/_/g, ' ');
      tableValue = tableValue.charAt(0).toUpperCase() + tableValue.slice(1);
    }

    return (
      <TableCell>{tableValue}</TableCell>
    );
  };

  const CamsStatus = (props) => {
    let actionItemValue = props.actionItem;
    actionItemValue = actionItemValue.replace(/_/g, ' ');
    actionItemValue = actionItemValue.charAt(0).toUpperCase() + actionItemValue.slice(1);

    const productStatus = props.item.product_status;

    if (productStatus[props.actionItemKey]) {
      if (productStatus[props.actionItemKey].status === 'success') {
        return (
          <div
            className="cams-action-success"
            onMouseOver={e => handleShowStatusInfo(e, props.item.affected_id, props.actionItem)}
          >
            {statusInfo === props.item.affected_id + props.actionItem && (
              <div
                onMouseOver={e => handleShowStatusInfo(e, props.item.affected_id, props.actionItem)}
                onMouseOut={handleHideStatusInfo} className="cams-info-wrapper"
              >
                <div className="cams-info">
                  <div className="cams-no-wrap"><strong>{actionItemValue}</strong></div>
                  <div className="cams-no-wrap">
                    <strong><IntlMessages id="cams.date" /></strong>
                    &nbsp;
                    {productStatus[props.actionItemKey].date.toString().replace('T', ' ').substr(0, 19)}
                  </div>
                </div>
              </div>
            )}
            &nbsp;
          </div>
        );
      } else if (productStatus[props.actionItemKey].status === 'error') {
        return (
          <div
            className="cams-action-error"
            onMouseOver={e => handleShowStatusInfo(e, props.item.affected_id, props.actionItem)}
          >
            {statusInfo === props.item.affected_id + props.actionItem && (
              <div
                onMouseOver={e => handleShowStatusInfo(e, props.item.affected_id, props.actionItem)}
                onMouseOut={handleHideStatusInfo} className="cams-info-wrapper"
              >
                <div className="cams-info">
                  <div className="cams-no-wrap"><strong>{actionItemValue}</strong></div>
                  <div className="cams-no-wrap">
                    <strong><IntlMessages id="cams.date" /></strong>
                    &nbsp;
                    {productStatus[props.actionItemKey].date.toString().replace('T', ' ').substr(0, 19)}
                  </div>
                  <div className="cams-status-error">
                    {productStatus[props.actionItemKey].error_reason}
                  </div>
                </div>
              </div>
            )}
            &nbsp;
          </div>
        );
      } else {
        return (
          <div
            className="cams-action-not-available"
            onMouseOver={e => handleShowStatusInfo(e, props.item.affected_id, props.actionItem)}
          >
            {statusInfo === props.item.affected_id + props.actionItem && (
              <div
                onMouseOver={e => handleShowStatusInfo(e, props.item.affected_id, props.actionItem)}
                onMouseOut={handleHideStatusInfo} className="cams-info-wrapper"
              >
                <div className="cams-info">
                  <div className="cams-no-wrap"><strong>{actionItemValue}</strong></div>
                </div>
              </div>
            )}
            &nbsp;
          </div>
        );
      }
    }
  }

  return (
    <>
      {tableData && tableData.length > 0 ? (
        <TableContainer id="camsTableContainer" className="product-table-container-no-scroll">
          <Table id="camsTable" stickyHeader aria-label="sticky table" size="small">
            <TableHead className="products-table-header">
              <TableRow>
                {Object.keys(fields).map((item, itemKey) => (
                  <CamsTitle
                    key={'title_' + itemKey}
                    itemkey={itemKey}
                    item={item}
                    condition={camsCondition}
                    itemsPerPage={itemsPerPage}
                    type={Object.values(fields)[itemKey].type}
                    name={Object.values(fields)[itemKey].name}
                    width={Object.values(fields)[itemKey].width}
                  />
                ))}
                <TableCell
                  key="status">
                  <strong><IntlMessages id="cams.status" /></strong>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData && tableData.map((item, itemKey) => (
                <TableRow key={'row_' + itemKey} style={{backgroundColor: getTableRowColor(itemKey)}}>
                  {Object.keys(fields).map((value, valueKey) => (
                    <CamsItem
                      key={'item_' + itemKey + '_' + valueKey}
                      type={Object.values(fields)[valueKey].type}
                      parent={Object.values(fields)[valueKey].parent}
                      item={item}
                      itemKey={itemKey}
                      value={value}
                      valueKey={valueKey}
                    />
                  ))}
                  <TableCell>
                    {camsActions && camsActions.map((actionItem, actionItemKey) => (
                      <CamsStatus
                        itemKey={itemKey}
                        key={'status_' + actionItemKey}
                        actionItemKey={actionItemKey}
                        item={item}
                        actionItem={actionItem}
                        camsActions={camsActions}
                      />
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableRow>
          <TableCell colSpan="5" className="feed-no-border">
            <PagePlaceholder
              icon={<i className="ti-face-sad text-primary text-lg" />}
              title={<IntlMessages id="title.sorry" />}
              subtitle={<IntlMessages id="title.noResultsFound" />}
            />
          </TableCell>
        </TableRow>
      )}

      <Pagination
        type="cams"
        getData={getCamsData}
        dataCount={tableData ? tableData.length : 0}
        itemsPerPage={itemsPerPage}
        allPages={allPages}
        currentPage={currentPage}
        scroll={camsScroll}
        condition={camsCondition}
      />
    </>
  );
};

export default CamsTable;
