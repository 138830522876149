/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import { connect } from 'react-redux';
import {
  string, func, arrayOf, shape, bool, number,
} from 'prop-types';

import MerchantLevelList from '../../components/MerchantLevelList';
import { pimSelector } from '../../pages/Home/selectors/pim';

import {
  fetchVariantLevelChildren,
  fetchCountProductsActivitiesByProductIds,
  toggleSelectedProduct,
  bulkDeleteProductLockedAttributes,
} from '../../../../actions/pim';

import {
  fetchActivityLogsByProductId,
  multipleUpdateUserActivityLogs,
} from '../../../../actions/product';

const MerchantContainer = memo((props) => {
  const {
    merchants, variant, onEditClick, commentMessages,
    searchFields, pim, filterTarget, attributes, isEditMode,
    disableEdit, initialAttributesOptions, productComments,
    fetchProductCommentsStart,
  } = props;

  const handleUnlockAttributesClick = ({ productItem }) => {
    const productTypes = [];
    productTypes[productItem.id] = productItem.type;
    props.bulkDeleteProductLockedAttributes({
      productIds: [productItem.id],
      fields: [...searchFields],
      filterTarget,
      productTypes,
    });
  };

  const onProductSelect = productItem => props.toggleSelectedProduct(productItem);

  const pimAttributes = attributes.parent || [];
  const sortedAttributes = pimAttributes.sort((a, b) => a.order - b.order);

  return (
    <MerchantLevelList
      variant={variant}
      merchants={merchants}
      searchFields={searchFields}
      onEditClick={onEditClick}
      selectedProducts={pim.selected}
      onProductSelect={onProductSelect}
      defaultImage={pim.defaultImage}
      onUnlockAttributesClick={handleUnlockAttributesClick}
      fetchActivityLogsByProductId={props.fetchActivityLogsByProductId}
      productCommentsLoading={fetchProductCommentsStart}
      productComments={productComments}
      filterTarget={filterTarget}
      sortedAttributes={sortedAttributes}
      isEditMode={isEditMode}
      productsCommentsCount={pim.productsCommentsCount}
      disableEdit={disableEdit}
      commentMessages={commentMessages}
      bulkDeleteProductLockedAttributesStarted={pim.bulkDeleteProductLockedAttributesStarted}
      bulkDeleteProductLockedAttributesFinished={pim.bulkDeleteProductLockedAttributesFinished}
      bulkDeleteProductLockedAttributesError={pim.bulkDeleteProductLockedAttributesError}
      multipleUpdateUserActivityLogs={props.multipleUpdateUserActivityLogs}
      initialAttributesOptions={initialAttributesOptions}
      currency={props.currency}
    />
  );
});

MerchantContainer.propTypes = {
  merchants: arrayOf(shape()).isRequired,
  variant: shape().isRequired,
  searchFields: arrayOf(string).isRequired,
  pim: shape().isRequired,
  attributes: shape(),
  commentMessages: arrayOf(shape()).isRequired,
  initialAttributesOptions: arrayOf(shape()).isRequired,
  bulkDeleteProductLockedAttributes: func.isRequired,
  toggleSelectedProduct: func.isRequired,
  onEditClick: func.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  multipleUpdateUserActivityLogs: func.isRequired,
  filterTarget: string.isRequired,
  isEditMode: bool.isRequired,
  disableEdit: bool,
  currency: shape({
    item: shape({
      value: number,
    }),
  }),
  productComments: arrayOf(shape()).isRequired,
  fetchProductCommentsStart: bool.isRequired,
};

MerchantContainer.defaultProps = {
  attributes: {},
  disableEdit: false,
  currency: {},
};

const mapStateToProps = state => ({
  pim: pimSelector(state.pim),
  currency: state.currency,
  system: state.system,
  filterTarget: state.pim.filterTarget,
  attributes: state.system.modes.pim,
  commentMessages: state.commentMessage.list,
  initialAttributesOptions: state.productAttribute.initialOptions,
  productComments: state.product.productComments,
  fetchProductCommentsStart: state.product.fetchProductCommentsStart,
});

const mapDispatchToProps = {
  fetchVariantLevelChildren,
  fetchCountProductsActivitiesByProductIds,
  bulkDeleteProductLockedAttributes,
  toggleSelectedProduct,
  fetchActivityLogsByProductId,
  multipleUpdateUserActivityLogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantContainer);
