import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const promotionListSelector = state => state.list;
const promotionItemSelector = state => state.item;
const promotionRootSelector = state => state;

const mapProductsOverride = (virtualParams) => {
  if (!virtualParams) return {};
  return ({
    ...virtualParams,
    productsOverride: virtualParams.positions
      ? Object.keys(virtualParams.positions)
        .map(p => ({ productId: p, position: virtualParams.positions[p] }))
      : [],
  });
};

const listSelector = createSelector(
  promotionListSelector,
  list => (!isEmpty(list) ? list.reduce((acc, current) => {
    acc.push({
      ...current,
      virtualParams: mapProductsOverride(current.virtualParams),
    });
    return acc;
  }, []) : []),
);

const itemSelector = createSelector(
  promotionItemSelector,
  item => (!isEmpty(item) ? ({
    ...item,
    virtualParams: mapProductsOverride(item.virtualParams),
  }) : {}),
);

export const promotionSelector = createSelector(
  listSelector,
  itemSelector,
  promotionRootSelector,
  (list, item, state) => ({
    ...state, list, item,
  }),
);

export default {
  promotionSelector,
};
