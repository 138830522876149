import React from 'react';
import { node, string } from 'prop-types';

const PagePlaceholder = ({
  title, subtitle, content, icon, className,
}) => (
  <div className={`flex items-center justify-center flex-abs no-event ${className}`}>
    <div className="pageholder-wrapper">
      {icon && <div className="h1">{icon}</div>}
      {title && <div className="h1">{title}</div>}
      {subtitle && <div className="h5">{subtitle}</div>}
      {content && <div className="pageholder-content">{content}</div>}
    </div>
  </div>
);

PagePlaceholder.propTypes = {
  className: string,
  title: node,
  subtitle: node,
  icon: node,
  content: node,
};

PagePlaceholder.defaultProps = {
  className: '',
  title: null,
  subtitle: null,
  icon: null,
  content: null,
};

export default PagePlaceholder;
