/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import {
  bool, func,
} from 'prop-types';
import FormDialog from '../../../../components/FormDialog';
import IntlMessages from '../../../../util/IntlMessages';

const DeleteRuleDialog = memo((props) => {
  const {
    onDeleteRuleClick,
    isOpened,
    onClose,
  } = props;

  const DeleteRule = () => {
    onDeleteRuleClick();
  };

  const closeDeleteRuleDialog = () => {
    onClose();
  };

  return (
    <FormDialog
      title={<IntlMessages id="rules.DeleteRuleDialog.title" />}
      open={isOpened}
      hideCloseButton={false}
      className="dialog-inputs-custom dialog-content-visible"
      closeButtonTitle="Close"
      submitButtonTitle="Submit"
      maxWidth="xs"
      onClose={closeDeleteRuleDialog}
      onSubmit={DeleteRule}
    >
      &nbsp;
    </FormDialog>
  );
});

DeleteRuleDialog.propTypes = {
  onDeleteRuleClick: func.isRequired,
  onClose: func.isRequired,
  isOpened: bool.isRequired,
};

DeleteRuleDialog.defaultProps = {};

export default DeleteRuleDialog;
