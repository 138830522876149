import { createSelector } from 'reselect';

const productRootSelector = state => state;

const stateSelector = createSelector(
  productRootSelector,
  product => ({
    item: product.item,
  }),
);

export const productSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  productSelector,
};
