import { isEmpty } from 'lodash';
import { defaultFields } from '../../../utils/defaultData';

const operatorsWithoutValue = new Set(['exists', 'not_exists']);

export const mapPrefix = (field) => {
  if (field.startsWith('ao_')) {
    return `specifications.${field}`;
  }
  return field;
};

export const mapPrefixToFilters = filters => (filters.map(f => ({
  ...f,
  group: f.group.map(g => ({
    ...g,
    field: mapPrefix(g.field),
  })),
})));

export const mergeFilters = (filters = [], stateRootFilter = []) => {
  if (JSON.stringify(filters) === JSON.stringify(stateRootFilter)) return filters;
  return [...filters, ...stateRootFilter];
};

export const mergeFields = (fields = [], stateRootFields = []) => ([
  ...new Set([...fields, ...stateRootFields, ...defaultFields]),
]);

export const getNewFilter = (filter) => {
  if (!isEmpty(filter)) {
    return filter.map(sf => ({
      ...sf,
      group: sf.group.map((g) => {
        if (operatorsWithoutValue.has(g.operator)) {
          return ({ field: g.field, operator: g.operator });
        } return g;
      }),
    }));
  }
  return [];
};

export default {
  mapPrefixToFilters,
  mergeFilters,
  mergeFields,
  getNewFilter,
};
