/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { func, shape, array } from 'prop-types';
import { isEmpty } from 'lodash';
import { decamelizeKeys } from 'humps';

import Layout from '../../components/Layout';
import List from '../../components/List';
import NavMenuItem from '../../components/Sidebar/NavMenuItem';
import { AbilityContext } from '../../components/AbilityContext';

import Users from './Users';
import Preview from './Preview';
import BulkCategoryImport from './BulkCategoryImport';

import { headerList } from '../utils/headerList';
import { usersPath, previewPath, importPath } from './utils/paths';
import { sidebarLinks } from './utils/sidebarLinks';
import appPermissions from '../../util/appPermissions';

import { onToggleMenu } from '../../actions/AppSettingsActions';

export const System = (props) => {
  const [sidebarList, setSidebarList] = useState([]);
  const abilityContext = useContext(AbilityContext);

  const mapPermissions = () => {
    const links = sidebarLinks(abilityContext);

    const mappedLinks = links.map(l => ({
      ...l,
      childRoutes: l.childRoutes.filter(r => r.enabled !== false),
    }));

    return decamelizeKeys(mappedLinks);
  };

  useEffect(() => {
    const withPermissions = mapPermissions();

    props.onToggleMenu(withPermissions);
  }, []);

  useEffect(() => {
    if (!isEmpty(props.sidebar.list)) {
      setSidebarList(props.sidebar.list);
    }
  }, [props.sidebar.list]);

  const handleMenuState = (currentMenus, menu) => {
    const menuItemToUpdate = currentMenus.find(m => m.key === menu.key);
    const updatedMenuItem = {
      ...menuItemToUpdate,
      open: !menuItemToUpdate.open,
    };

    return currentMenus.map((item) => {
      if (item.key === menu.key) return updatedMenuItem;
      return item;
    });
  };

  const toggleMenu = (menu) => {
    const newMenuItems = handleMenuState(sidebarList, menu);

    props.onToggleMenu(newMenuItems);
  };

  const renderSidebarContent = () => (
    sidebarList.map(menu => (
      <NavMenuItem
        menu={menu}
        key={sidebarList.indexOf(menu)}
        onToggleMenu={() => toggleMenu(menu)}
      />
    ))
  );

  return (
    <Layout
      horizontalMenu
      header={{
        className: 'header-mer',
        subHeaderContent: (
          <List
            dir="horizontal"
            className="justify-center"
            dataSource={headerList({ abilityContext })}
          />
        ),
      }}
      sidebar={{
        className: 'sidebar-mer sidebar-users-mer',
        content: renderSidebarContent(),
      }}
      main={{
        className: 'main-mer main-users-mer',
        content: (
          <>
            {abilityContext.can(
              appPermissions.displayFields.permissions.view,
              appPermissions.displayFields.name,
            ) && <Route exact path={previewPath} component={Preview} />}
            {abilityContext.can(
              appPermissions.users.permissions.view,
              appPermissions.users.name,
            ) && <Route exact path={usersPath} component={Users} />}
            {abilityContext.can(
              appPermissions.bulkImport.permissions.view,
              appPermissions.bulkImport.name,
            ) && <Route exact path={importPath} component={BulkCategoryImport} />}
          </>
        ),
      }}
    />
  );
};

System.propTypes = {
  onToggleMenu: func.isRequired,

  sidebar: shape({
    list: array,
  }).isRequired,
};

const mapStateToProps = state => ({
  sidebar: state.sidebar,
});

const mapDispatchToProps = {
  onToggleMenu,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(System));
