/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */

import { isEmpty } from 'lodash';

const getNameValidations = ({ minSize, maxSize }) => [
  {
    type: 'isRequired',
    message: 'text.helper.cantBeBlank',
  }, {
    type: 'minSize',
    message: `text.errors.moreThan${minSize}Characters`,
    condition: minSize,
  }, {
    type: 'maxSize',
    message: `text.errors.lessThan${maxSize}Characters`,
    condition: maxSize,
  },
];

export const virtualFacetAttributes = [
  {
    type: 'isRequired',
    message: 'text.helper.cantBeBlank',
  },
];

export const isRequired = [
  {
    type: 'isRequired',
    message: 'text.helper.cantBeBlank',
  },
];

export const groupedValueItemValues = [
  {
    type: 'isRequired',
    message: 'text.helper.cantBeBlank',
  },
];

export const groupedValueItemMaxSize = [
  {
    type: 'maxSize',
    message: 'text.errors.lessThan300Characters',
  },
];

export const virtualFacetValidation = {
  en: getNameValidations({ minSize: 3, maxSize: 100 }),
  basedOn: virtualFacetAttributes,
  basedOnOperator: isRequired,
  basedOnOptions: isRequired,
  type: isRequired,
};

const validateFacetGroupMinLength = (label) => {
  if (!label.en) return groupedValueItemValues;
  if (label.en.length > 100) return groupedValueItemMaxSize;
  if (validateFacetName(label.en)) {
    const messages = validateFacetName(label.en);
    return [messages];
  }
  return [];
};

export const validateVirtualFacetGroupedValues = array => array.map(gv => ({
  id: gv.id,
  label: {
    ...gv.label,
    messages: validateFacetGroupMinLength(gv.label),
  },

  values: gv.values.map(i => ({
    ...i,
    messages: isEmpty(i.value) ? groupedValueItemValues : [],
  })),
}));


export const validateFacetName = (name) => {
  const specialSymbolsRegex = /[@;~≈ç√∫˜µ≤≥÷åß∂ƒ©˙∆˚¬…æ«œ∑´®†¥ˆøπ¡™£¢∞§¶•ªº≠:{}|[«<>+=]/g.test(name);
  if (specialSymbolsRegex) {
    return ({
      type: 'specialName',
      message: 'facet.helper.incorectFacetName',
    });
  }

  return false;
};


export default {
  validateFacetName,
  virtualFacetValidation,
  validateVirtualFacetGroupedValues,
};
