import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer, Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { setMerchantActivityDialog } from '../../actions/pim';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {},
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box className={classes.title}>
          <Typography variant="h6">
            { children }
          </Typography>
        </Box>
        <Box>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


const DialogComponent = (props) => {
  const [viewMore, setViewMore] = useState(false);
  const [viewMoreContent, setViewMoreContent] = useState(null);

  const handleClose = () => props.setMerchantActivityDialog(false);
  const handleOpenViewMore = commentItem => () => {
    setViewMore(true);
    setViewMoreContent(commentItem);
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        open={viewMore}
        onClose={() => setViewMore(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <pre>
            { JSON.stringify(viewMoreContent, null, 3) }
          </pre>
        </DialogContent>
      </Dialog>
      <Dialog maxWidth="lg" onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.merchantActivityDialog}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Product Changes History
        </DialogTitle>
        <DialogContent dividers>
          {props.productComments.length ? (
            <TableContainer>
              <Table>
                <TableBody>
                  { props.productComments.map(commentItem => (
                    <TableRow key={commentItem.id}>
                      <TableCell>
                        <Typography gutterBottom key={commentItem.id}>
                          {moment.utc(commentItem.createdAt).format('MM-DD-YYYY HH:mm')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography gutterBottom key={commentItem.id}>
                          { commentItem.comment || 'No comment message' }
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: 150 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenViewMore(commentItem)}
                        >
                          View Changes
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : 'No changes has been recorded yet'}
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogComponent.propTypes = {
  merchantActivityDialog: PropTypes.bool.isRequired,
  productComments: PropTypes.arrayOf(Object).isRequired,
  setMerchantActivityDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  merchantActivityDialog: state.pim.merchantActivityDialog,
  productComments: state.product.productComments,
});

const mapDispatchToProps = { setMerchantActivityDialog };

export default connect(mapStateToProps, mapDispatchToProps)(DialogComponent);
