import React, { useState, useEffect } from 'react';
import {
  func
} from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IntlMessages from '../../../../util/IntlMessages';
import { getMappingData, addMappingData, getMappingNewRecord } from '../../../../actions/mapper';
import { Button, FormControl, Input, OutlinedInput } from '@material-ui/core';

export const NewRecord = (props) => {
  const [newRecordValue, setNewRecordValue] = useState([]);
  const [isNewRecord, setIsNewRecord] = useState(true);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (props.item.length > 0) {
      for (let i = 0; i < Object.keys(props.mapperFields).length; i++) {
        newRecordValue[Object.keys(props.mapperFields)[i]] = props.item[i];
      }
      props.getMappingNewRecord({open: true});
      setNewRecordValue(newRecordValue);
      setIsNewRecord(false);
    }

    if (props.mapScroll && total !== props.mapScroll.total) {
      const totalSpan = document.getElementById('total');
      if (totalSpan) {
        totalSpan.innerText = props.mapScroll.total;
      }
      setTotal(props.mapScroll.total);
    }
  }, [
    props, newRecordValue, isNewRecord, total,
  ]);

  const handleNewRecordChange = (e) => {
    const { target } = e;

    if (target.type === 'checkbox') {
      newRecordValue[target.name] = !newRecordValue[target.name];
    }  else if (target.type === 'checkbox_revert') {
      newRecordValue[target.name] = !!newRecordValue[target.name];
    } else if (target.type === 'select') {
      newRecordValue[target.name] = parseInt(target.value);
    } else {
      newRecordValue[target.name] = target.value;
    }

    if (target.name !== 'status' && (!newRecordValue['status'] || parseInt(newRecordValue['status']) === 0)) {
      document.getElementById('edit_status').value = 1;
    }

    const specialRule = props.mapperFields[target.name].specialRule;
    if (specialRule && specialRule.type === 'url') {
      const targetValue = target.value ? specialRule.value.replace('[value]', target.value) : '';
      newRecordValue[specialRule.target] = targetValue;
      document.getElementById('edit_' + specialRule.target).value = targetValue;
    }

    const rule = props.mapperFields[target.name].rule;
    if (rule === 'json') {
      newRecordValue[target.name] = target.value.toString().split(',');
    }

    setNewRecordValue(newRecordValue);
  };

  const handleNewRecordUrlClick = (e) => {
    const { target } = e;
    window.open(target.value);
  };

  const saveNewRecord = (e) => {
    const savingData = {};
    const newRecordValueKeys = Object.keys(newRecordValue);

    let itemId = null;
    for (let i = 0; i < newRecordValueKeys.length; i++) {
      if (newRecordValueKeys[i] === 'id') {
        itemId = newRecordValue[newRecordValueKeys[i]];
      } else {
        savingData[newRecordValueKeys[i]] = newRecordValue[newRecordValueKeys[i]];
      }
    }
    savingData['marketplace'] = props.mapperMarketplace.replace('.co.il', '');

    props.addMappingData({ savingData: savingData, id: itemId });

    const filter = props.mapCondition.filter ? props.mapCondition.filter : null;
    const sort = props.mapCondition.sort ? props.mapCondition.sort : null;
    const offset = props.mapScroll.offset;

    props.getMappingData({
      limit: props.mapperItemsPerPage,
      offset: offset,
      filter: filter,
      sort: sort,
      marketplace: props.mapperMarketplace
    });
    props.getMappingNewRecord({open: false});
    setNewRecordValue([]);
    setIsNewRecord(true);
  };

  const cancelNewRecord = (e) => {
    props.getMappingNewRecord({open: false});
    setNewRecordValue([]);
    setIsNewRecord(true);
  };

  const handleChangeMarketplace = (e) => {
    const { target } = e;
    window.location.href = '/mapper?marketplace=' + target.value;
  }

  const NewRecordItem = ((props) => {
    const {
      item, type, name, options, copyEditable, copyVisible, defaultCopyValue
    } = props;

    if (item === 'id' || copyVisible === false) {
      return (<input type="hidden" name={item} value={newRecordValue[item]} />);
    }

    if (type === 'select') {
      return (
        <div className="mapper-padd-5">
          <label className="mapper-padd-5">
            {name}
            <select id={'edit_' + item}
                    name={item}
                    className="mapper-select"
                    readOnly={!(copyEditable || isNewRecord)}
                    InputProps={{
                      readOnly: !(copyEditable || isNewRecord)
                    }}
                    onChange={e => handleNewRecordChange(e)}
                    defaultValue={defaultCopyValue ? defaultCopyValue : newRecordValue[item]}>
              {options.map((selectValue, selectKey) => (
                <option value={selectKey}>{selectValue}</option>
              ))}
            </select>
          </label>
        </div>
      );
    } else if (type === 'checkbox') {
      return (
        <div className="mapper-padd-5">
          <label className="mapper-padd-5">
            {name}
            <input type="checkbox"
                   id={'edit_' + item}
                   name={item}
                   className="mapper-checkbox"
                   onChange={e => handleNewRecordChange(e)}
                   defaultChecked={!!newRecordValue[item]}
                   value="1"
            />
          </label>
        </div>
      );
    } else if (type === 'checkbox_revert') {
      return (
        <div className="mapper-padd-5">
          <label className="mapper-padd-5">
            {name}:
            &nbsp;
            {newRecordValue[item] ? 'No' : 'Yes'}
          </label>
          <Input
              type='hidden'
              id={'edit_' + item}
              name={item}
              defaultValue={!!newRecordValue[item]}
          />
        </div>
      );
    } else if (type === 'url') {
      return (
        <div className="mapper-padd-5">
          <label className="mapper-padd-5">
            {name}
            <Input
              id={'edit_' + item}
              name={item}
              type="text"
              className="mapper-search-input search-input-lg float-left mapper-copy-url"
              onClick={e => handleNewRecordUrlClick(e)}
              readOnly={!(copyEditable || isNewRecord)}
              InputProps={{
                readOnly: !(copyEditable || isNewRecord)
              }}
              defaultValue={newRecordValue[item]}
            />
          </label>
        </div>
      );
    } else {
      return (
        <div className="mapper-padd-5">
          <label className="mapper-padd-5">
            {name}
            <OutlinedInput
              ref={input => input && input.focus()}
              id={'edit_' + item}
              name={item}
              type="text"
              className="mapper-search-input search-input-lg float-left"
              placeholder={name}
              onChange={e => handleNewRecordChange(e)}
              readOnly={!(copyEditable || isNewRecord)}
              InputProps={{
                readOnly: !(copyEditable || isNewRecord)
              }}
              defaultValue={newRecordValue[item]}
            />
            </label>
        </div>
      );
    }
  });

  if (!props.mapNewRecordOpen) {
    return (
      <>
        <div style={{ float: 'left' }}>
          Marketplace: &nbsp;
        </div>
        <div style={{ float: 'left' }}>
          <select
            id="marketplace"
            className="mapper-select-marketplace"
            onChange={e => handleChangeMarketplace(e)}
            defaultValue={props.mapperMarketplace}
          >
            {props.mapperMarketplaces.map((selectValue, selectKey) => (
              <option key={selectKey} value={selectValue}>
                {selectValue}
              </option>
            ))}
          </select>
        </div>
        <div  style={{ float: 'right' }} className="mapper-total">
          <IntlMessages id="sidebar.found" />
          &nbsp;
          <span id="total">{ total }</span>
          &nbsp;
          <IntlMessages id="sidebar.records" />
        </div>
        <div style={{ clear: 'both' }} />
      </>
    );
  }

  return (
    <div className="mapper-new-record">
      <div className="mapper-new-record-close" onClick={cancelNewRecord}>x</div>

      {props.item.length > 0 ? (
        <h3><IntlMessages id="button.newMappingRecord" /></h3>
      ) : (
        <h3><IntlMessages id="button.copyMappingRecord" /></h3>
      )}

      <FormControl className="search-input-lg block" variant="outlined">

        {Object.keys(props.mapperFields).map((item, itemKey) => (
          <NewRecordItem item={item}
                         type={Object.values(props.mapperFields)[itemKey] ?
                                 Object.values(props.mapperFields)[itemKey].type : ''}
                         name={Object.values(props.mapperFields)[itemKey] ?
                                 Object.values(props.mapperFields)[itemKey].name : ''}
                         options={Object.values(props.mapperFields)[itemKey] ?
                                 Object.values(props.mapperFields)[itemKey].options : ''}
                         copyEditable={Object.values(props.mapperFields)[itemKey] ?
                                 Object.values(props.mapperFields)[itemKey].copyEditable : ''}
                         copyVisible={Object.values(props.mapperFields)[itemKey] ?
                                 Object.values(props.mapperFields)[itemKey].copyVisible : ''}
          />
        ))}

      </FormControl>

      <Button
        variant="contained"
        color="primary"
        size="middle"
        className="text-white mapper-w-75 float-left"
        onClick={saveNewRecord}
      >
        <IntlMessages id="button.save" />
      </Button>

      &nbsp;&nbsp;&nbsp;

      <Button
        variant="contained"
        color="primary"
        size="middle"
        className="text-white mapper-w-75 float-right"
        onClick={cancelNewRecord}
      >
        <IntlMessages id="button.cancel" />
      </Button>

    </div>
  );
};

NewRecord.propTypes = {
  addMappingData: func.isRequired,
  getMappingData: func.isRequired,
  getMappingNewRecord: func.isRequired,
};

NewRecord.defaultProps = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  addMappingData,
  getMappingData,
  getMappingNewRecord,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewRecord));
