import { isEmpty } from 'lodash';

export default ({
  onCloseClick, onSaveAndCloseClick, onSaveClick, onDeleteClick,
  buttonSettings = {}, disabled = false, buttons = { success: { className: 'button-success save' } },
}) => [
  {
    key: 'saveAndClose',
    id: 'saveAndClose',
    value: 'buttons.saveAndClose',
    buttonSettings: {
      color: 'link',
    },
    materialButtonSettings: {
      disabled: !isEmpty(buttonSettings) ? buttonSettings.saveAndClose.material.disabled : false,
      color: 'primary',
      className: 'text-primary',
    },
    onClick: onSaveAndCloseClick,
    hide: !onSaveAndCloseClick,
    disabled,
  }, {
    id: 'save',
    key: `${buttons.success.className}`,
    value: 'buttons.save',
    buttonSettings: {
      color: 'link',
    },
    materialButtonSettings: {
      disabled: !isEmpty(buttonSettings) ? buttonSettings.save.material.disabled : false,
      className: 'button-success text-primary',
    },
    onClick: onSaveClick,
    hide: !onSaveClick,
    disabled,
  }, {
    id: 'close',
    key: 'button-default close',
    value: 'buttons.close',
    buttonSettings: {
      color: 'link',
    },
    materialButtonSettings: {
      className: 'button-default text-primary',
    },
    onClick: onCloseClick,
    hide: !onCloseClick,
    disabled,
  }, {
    id: 'delete',
    key: 'button-danger delete',
    value: 'buttons.delete',
    buttonSettings: {
      color: 'link',
    },
    materialButtonSettings: {
      className: 'button-danger text-danger',
    },
    onClick: onDeleteClick,
    hide: !onDeleteClick,
    disabled,
  },
];
