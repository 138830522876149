import { createSelector } from 'reselect';

const previewRootSelector = state => state;

const stateSelector = createSelector(
  previewRootSelector,
  preview => ({
    selectedEntityName: preview.selectedEntityName,
    total: preview.total,
    updatedMultiple: preview.updatedMultiple,
    selectedPromotion: preview.selectedPromotion,
    selectedSearchFilter: preview.selectedSearchFilter,
    selectedCategory: preview.selectedCategory,
    hiddenProductsExist: preview.hiddenProductsExist,
  }),
);

export const previewSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  previewSelector,
};
