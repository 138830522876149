import {
  addProductDataActionLogPath,
  getProductDataStatusPath,
  getProductDataActionPath,
  getDataActionsPath
} from '../util/paths';

import {
  SET_CAMS_PRODUCT_DATA,
} from './types';

import { CAMSClient, showErrorNotification } from '../util/api';

import { setIsLoading } from './global';

export const addProductDataActionLogRes = condition => CAMSClient.post(addProductDataActionLogPath, condition);
export const getProductDataStatusRes = condition => CAMSClient.post(getProductDataStatusPath, condition);
export const getProductDataActionRes = condition => CAMSClient.post(getProductDataActionPath, condition);
export const getDataActionRes = condition => CAMSClient.post(getDataActionsPath, condition);

export const getProductActionsByAggs = () => async (dispatch) => {
  try {
    const query = { aggs: { action: { terms: { field: 'action', size: 500 } } }, size: 0 };
    const res = await getProductDataActionRes(query);
    const { data } = res;

    const actions = data.aggregations.action.buckets;

    dispatch({
      type: SET_CAMS_PRODUCT_DATA,
      payload: { cams: { actions: actions } }
    });
  } catch (error) {
    showErrorNotification(error, 'CAMS');
  }
};

export const getProductActions = () => async (dispatch) => {
  try {
    const query = {
      from : 0, size : 500,
      sort : [
        { priority : { order : 'asc'} },
      ],
    };
    const res = await getDataActionRes(query);
    const { data } = res;

    const actions = [];
    const hits = data.hits.hits;
    for (let i = 0; i < hits.length; i++) {
      actions.push({
        key: hits[i]._source.code,
        label: hits[i]._source.label,
      });
    }

    dispatch({
      type: SET_CAMS_PRODUCT_DATA,
      payload: { cams: { actions: actions } }
    });
  } catch (error) {
    showErrorNotification(error, 'CAMS');
    dispatch({
      type: SET_CAMS_PRODUCT_DATA,
      payload: { cams: { loading: false } }
    });
  }
};

export const getProductDataStatus = (query) => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const res = await getProductDataStatusRes(query);
    const { data } = res;

    const sortFields = query.sort ? Object.keys(query.sort[0]) : ['date'];

    dispatch({
      type: SET_CAMS_PRODUCT_DATA,
      payload: { cams: {
          data: data,
          scroll: {
            field: sortFields[0],
            order: query.sort ? query.sort[0][sortFields[0]].order : 'desc',
            total: data.hits.total.value,
            offset: query.from,
            itemsPerPage: query.size
          },
          condition: query,
      } }
    });
  } catch (error) {
    showErrorNotification(error, 'CAMS');
  }
  dispatch(setIsLoading(false));
};

export const getProductDataErrors = (query) => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    if (!query['query']) {
      query['query'] = { bool: { should: [] } };
    } else if (!query['query']['bool']) {
      query['query']['bool'] = { should: [] };
    } else if (!query['query']['bool']['should']) {
      query['query']['bool']['should'] = [];
    }

    if (query['query']['bool']['should'].length === 0) {
      const actionsQuery = {
        from : 0, size : 500,
        sort : [
          { priority : { order : 'asc'} },
        ],
      };
      const actionsRes = await getDataActionRes(actionsQuery);
      const actionsData = actionsRes.data.hits.hits;

      const shouldCond = [];
      for (let i = 0; i <= actionsData.length; i++) {
        if (actionsData[i]) {
          const errorField = 'product_status.' + actionsData[i]._source.code + '.status';
          const errorCond = {term: {}};
          errorCond['term'][errorField] = 'error';
          shouldCond.push(errorCond);
        }
      }
      query['query']['bool']['should'] = shouldCond;
    }

    const res = await getProductDataStatusRes(query);
    const { data } = res;

    const sortFields = query.sort ? Object.keys(query.sort[0]) : ['date'];

    dispatch({
      type: SET_CAMS_PRODUCT_DATA,
      payload: { cams: {
          data: data,
          scroll: {
            field: sortFields[0],
            order: query.sort ? query.sort[0][sortFields[0]].order : 'desc',
            total: data.hits.total.value,
            offset: query.from,
            itemsPerPage: query.size
          },
          condition: query,
        } }
    });
  } catch (error) {
    showErrorNotification(error, 'CAMS');
  }
  dispatch(setIsLoading(false));
};

export const getProductDataMerchantStatus = (query) => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const actionsQuery = {
      from : 0, size : 500,
      sort : [
        { priority : { order : 'asc'} },
      ],
    };
    const actionsRes = await getDataActionRes(actionsQuery);
    const actionsData = actionsRes.data.hits.hits;

    const aggsQuery = {
      aggs: {
        aggregations: {
          aggs: {
            errorCount: {
              filter: {
                bool: {
                  should: [ ]
                }
              }
            },
          },
          terms: {
            field: 'info.merchant_id.keyword',
            size: 1000,
          }
        }
      },
      size: 0,
      sort: query.sort,
    }

    if (query.query) {
      aggsQuery['query'] = query.query;
    }

    const shouldConds = [];
    for (let i = 0; i <= actionsData.length; i++) {
      if (actionsData[i]) {
        const action = actionsData[i]._source.code;

        const errorField = 'product_status.' + action + '.status';

        const aggsCond = { filter: {term: { } } };
        aggsCond['filter']['term'][errorField] = { value: 'error' };
        aggsQuery['aggs']['aggregations']['aggs'][action] = aggsCond;

        const shouldCond = { term: { } };
        shouldCond['term'][errorField] = { value: 'error' };
        shouldConds.push(shouldCond);
      }
    }

    aggsQuery['aggs']['aggregations']['aggs']['errorCount']['filter']['bool']['should'] = shouldConds;

    const res = await getProductDataStatusRes(aggsQuery);
    const { data } = res;

    const sortFields = query.sort ? Object.keys(query.sort[0]) : ['date'];

    dispatch({
      type: SET_CAMS_PRODUCT_DATA,
      payload: { cams: {
          data: data,
          scroll: {
            field: sortFields[0],
            order: query.sort ? query.sort[0][sortFields[0]].order : 'desc',
            total: data.hits.total.value,
            offset: query.from,
            itemsPerPage: query.size
          },
          condition: query,
        } }
    });
  } catch (error) {
    showErrorNotification(error, 'CAMS');
  }
  dispatch(setIsLoading(false));
};
