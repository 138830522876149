import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

import RestActions from '../util/rest/actions';

import {
  virtualFacetPath,
  virtualFacetSearchPath,
  getVirtualFacetPath,
} from '../util/paths';

import {
  SET_NAVIGATION_VIRTUAL_FACET_ID_TO_DELETE,
} from './types';
import { showErrorNotification } from '../util/api';

const restNavigationVirtualFacetActions = new RestActions('navigation_virtual_facet');

export const fetchNavigationVirtualFacet = id => (dispatch) => {
  dispatch(restNavigationVirtualFacetActions.fetchStart());
  return axios.get(getVirtualFacetPath(id)).then((res) => {
    dispatch(restNavigationVirtualFacetActions.fetchFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restNavigationVirtualFacetActions.fetchFinished({
      error: error.response.data,
      hasError: true,
      item: {},
    }));
  });
};

export const fetchNavigationVirtualFacetList = params => (dispatch) => {
  dispatch(restNavigationVirtualFacetActions.fetchAllStart());
  return axios.post(virtualFacetSearchPath, params).then((res) => {
    dispatch(restNavigationVirtualFacetActions.fetchAllFinished({
      list: camelizeKeys(res.data.data),
      origList: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restNavigationVirtualFacetActions.fetchAllFinished({
      error: error.response.data,
      hasError: true,
      list: [],
      origList: [],
    }));
  });
};

export const deleteNavigationVirtualFacet = (id, isSync = true) => (dispatch) => {
  const transformedPath = isSync
    ? `${getVirtualFacetPath(id)}?is_synchronous=${isSync}`
    : getVirtualFacetPath(id);
  dispatch(restNavigationVirtualFacetActions.deleteStart());
  return axios.delete(transformedPath).then(() => {
    dispatch(restNavigationVirtualFacetActions.deleteFinished({ lastDeletedVirtualFacetId: id }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restNavigationVirtualFacetActions.deleteFinished({
      error: error.response.data,
      hasError: true,
      lastDeletedVirtualFacetId: '',
    }));
  });
};

export const updateNavigationVirtualFacet = (facetId, payload, isSync = true) => (dispatch) => {
  const transformedPath = isSync
    ? `${getVirtualFacetPath(facetId)}?is_synchronous=${isSync}`
    : getVirtualFacetPath(facetId);

  const body = {
    data: payload,
  };

  dispatch(restNavigationVirtualFacetActions.updateStart());
  return axios.put(transformedPath, decamelizeKeys(body)).then((res) => {
    dispatch(restNavigationVirtualFacetActions.updateFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restNavigationVirtualFacetActions.updateFinished({
      error: error.response.data,
      hasError: true,
    }));
  });
};

export const createNavigationVirtualFacet = payload => (dispatch) => {
  const body = {
    data: payload,
  };
  dispatch(restNavigationVirtualFacetActions.createStart());
  return axios.post(virtualFacetPath, decamelizeKeys(body)).then((res) => {
    dispatch(restNavigationVirtualFacetActions.createFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restNavigationVirtualFacetActions.createFinished({
      error: error.response.data,
      hasError: true,
    }));
  });
};

export const setNavigatioVirtualFacetIdToDelete = id => (dispatch) => {
  dispatch({
    type: SET_NAVIGATION_VIRTUAL_FACET_ID_TO_DELETE,
    payload: {
      virtualFacetIdToDelete: id,
    },
  });
};

export const setNavigationVirtualFacetItem = facet => (dispatch) => {
  dispatch(restNavigationVirtualFacetActions.updateStart());
  dispatch(restNavigationVirtualFacetActions.updateFinished({ item: facet }));
};

export const setNavigationVirtualFacetList = list => (dispatch) => {
  dispatch(restNavigationVirtualFacetActions.fetchAllStart());
  dispatch(restNavigationVirtualFacetActions.fetchAllFinished({ list }));
};

export const clearNavigationVirtualFacet = () => (dispatch) => {
  dispatch(restNavigationVirtualFacetActions.clearStateFinished({ list: [] }));
};

export const clearFromNavigationVirtualFacetState = payload => (dispatch) => {
  dispatch(restNavigationVirtualFacetActions.clearFromStateFinished(payload));
};
