/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

const usePhysicalCategory = ({
  virtualCategoryItem, physicalCategoriesByIds, fetchedPhysicalCategoriesByIds,
  setMappedCategories, setSelectedCategories,
}) => {
  useEffect(() => {
    if (fetchedPhysicalCategoriesByIds) {
      if (!isEmpty(virtualCategoryItem)
        && !isEmpty(virtualCategoryItem.virtualParams.mappedCategories)) {
        setMappedCategories([...physicalCategoriesByIds]);
      } else {
        setMappedCategories([]);
      }

      if (!isEmpty(virtualCategoryItem)
        && !isEmpty(virtualCategoryItem.virtualParams.selectedCategories)) {
        setSelectedCategories([...physicalCategoriesByIds]);
      } else {
        setSelectedCategories([]);
      }
    }
  }, [fetchedPhysicalCategoriesByIds]);
};

export default usePhysicalCategory;
