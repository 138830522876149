import React from 'react';
import {
  shape, arrayOf, string, func, bool, number,
} from 'prop-types';
import { isEmpty, uniq } from 'lodash';
import Button from '@material-ui/core/Button';
import { Form } from 'reactstrap';

import SelectCategoryDialog from '../../../../components/SelectCategoryDialog';
import IntlMessages from '../../../../util/IntlMessages';

const SelectCategories = (props) => {
  const {
    showSelectCategoriesDialog,
    onUpdateSelectedCategory,
    searched,
    searchResults,
    onSelectCategoriesSearchSubmit,
    mappedCategories,
    onTransferChange,
    onToggleSelectCategoriesDialog,
    physicalCategorySearchQuery,
    selectedCategories,
    queryLengthLimit,
    errorMessage,
    disabled,
  } = props;
  return (
    <Form inline className="form-group min-w-s">
      <Button
        disabled={disabled}
        className="no-wrap block"
        variant="contained"
        color="primary"
        onClick={onToggleSelectCategoriesDialog}
      >
        <IntlMessages id="label.selectCategoriesLabel" />
      </Button>

      <SelectCategoryDialog
        dialog={{
          maxWidth: 'md',
          className: 'select-categories-mod',
          open: showSelectCategoriesDialog,
          onClose: onUpdateSelectedCategory,
          hideCloseButton: true,
          onSubmit: onUpdateSelectedCategory,
        }}
        content={{
          noResult: searched
            && isEmpty(searchResults),
          onSearchSubmit: onSelectCategoriesSearchSubmit,
          query: physicalCategorySearchQuery,
          queryLengthLimit,
          errorMessage,
        }}
        transferSourceData={{
          sourceTitle: <IntlMessages id="label.sourceCategoriesLabel" />,
          targetTitle: <IntlMessages id="label.selectedCategoriesLabel" />,
          sourceList: searchResults,
          targetList: uniq([...mappedCategories, ...selectedCategories]),
          onChange: onTransferChange,
          selectAll: true,
          tooltip: {
            placement: 'right',
          },
        }}
      />
    </Form>
  );
};

SelectCategories.propTypes = {
  showSelectCategoriesDialog: bool.isRequired,
  physicalCategorySearchQuery: string.isRequired,
  errorMessage: string.isRequired,
  searched: bool.isRequired,
  searchResults: arrayOf(shape()).isRequired,
  mappedCategories: arrayOf(shape()).isRequired,
  selectedCategories: arrayOf(shape()).isRequired,
  onUpdateSelectedCategory: func.isRequired,
  onSelectCategoriesSearchSubmit: func.isRequired,
  onTransferChange: func.isRequired,
  onToggleSelectCategoriesDialog: func.isRequired,
  queryLengthLimit: number.isRequired,
  disabled: bool,
};

SelectCategories.defaultProps = {
  disabled: false,
};

export default SelectCategories;
