import React, { memo } from 'react';
import { connect } from 'react-redux';
import {
  bool,
  func, shape, number,
} from 'prop-types';
import Can from '../../../../../../components/Can';
import appPermissions from '../../../../../../util/appPermissions';
import RowViewSidebar from '../../../../components/RowViewSidebar';

import {
  fetchChildCategories as fetchVirtualChildCategories,
  onToggleVirtualCategoryTreeCollapse,
  handleLoadVirtualSubcategories,
} from '../../../../../../actions/virtualCategory';
import {
  fetchScopeCategoriesByIds,
  clearFromScopeCategoryState,
} from '../../../../../../actions/scopeCategory';
import {
  setFilterTarget,
  setSelectedProducts,
  setGlobalFilters,
  setGlobalFields,
  setSavedFilterFields,
  setSavedFilterFilters,
  setFullTextListModeSearch,
  setProductsListEmpty,
  setIsCustomFieldsMode,
  setSearchQuery,
  setPagination,
  fetchProductList,
  setHelperText,
  setPaginationHelperTextId,
} from '../../../../../../actions/pim';

import {
  setSelectedSavedFilter,
} from '../../../../../../actions/previewFilter';

import {
  pimSelector,
} from './selectors/pimSelector';
import {
  virtualCategorySelector,
} from './selectors/virtualCategorySelector';
import {
  previewFilterSelector,
} from './selectors/previewFilterSelector';
import {
  getMapperFilterOptions, getActivityLogFilterOptions,
  getRulesFilterOptions, getFeedsFilterOptions,
  getRepricerFilterOptions, getRepricingFilterOptions,
  getCamsFilterOptions,
} from "../../../../utils/sidebar";

const SidebarContentComponent = memo((props) => {
  const {
    pimProps, virtualCategoryProps, previewFilterProps, fromRules,
  } = props;

  const handleUnselectAllFilters = () => {
    props.setSavedFilterFields([]);
    props.setSavedFilterFilters([]);
    props.setProductsListEmpty();
    props.setFullTextListModeSearch({});
  };

  const handleFetchProductList = (data) => {
    props.setHelperText('');
    props.setPaginationHelperTextId('');
    if (pimProps.filterTarget === 'mapper' && props.getMappingData) {
      props.getMappingData(data);
    } else if (pimProps.filterTarget === 'activityLog' && props.getActivityLogData) {
      props.getActivityLogData(data);
    } else if (pimProps.filterTarget === 'rule' && props.getRulesData) {
      props.getRulesData(data);
    } else if (pimProps.filterTarget === 'feed' && props.getFeedsData) {
      props.getFeedsData(data);
    } else if (pimProps.filterTarget === 'repricer' && props.getRepricerData) {
      props.getRepricerData(data);
    } else if (pimProps.filterTarget === 'repricing' && props.getRepricingData) {
      props.getRepricingData(data);
    } else if (pimProps.filterTarget === 'cams' && props.getCamsData) {
      props.getCamsData(data);
    } else if (data && data.viewMode) {
      props.fetchProductList(data);
    }
  };

  const savedFilters = previewFilterProps.list
    .filter(f => f.filterTarget === pimProps.filterTarget);

  return (
    <Can
      do={appPermissions.pim.permissions.view}
      on={appPermissions.pim.name}
    >
      <RowViewSidebar
        collapsed={virtualCategoryProps.collapse}
        loadedKeys={virtualCategoryProps.loadedKeys}
        globalFilters={pimProps.globalFilters}
        globalFields={pimProps.globalFields}
        selectedSavedFilter={previewFilterProps.selectedSavedFilter}
        savedFilters={savedFilters}
        sort={pimProps.sort}
        searchQuery={pimProps.searchQuery}
        savedFilterFields={pimProps.savedFilterFields}
        savedFilterFilters={pimProps.savedFilterFilters}
        fullTextListModeSearch={pimProps.fullTextListModeSearch}
        handleUnselectAllFilters={handleUnselectAllFilters}
        filterTarget={pimProps.filterTarget}
        prevFilterTarget={pimProps.prevFilterTarget}
        setFilterTarget={props.setFilterTarget}
        displayMode={pimProps.displayMode}
        setSelectedProducts={props.setSelectedProducts}
        selectedProducts={pimProps.selectedProducts}
        previewFilterAttribute={previewFilterProps.list} // ???
        fetchProductList={handleFetchProductList}
        fetchVirtualChildCategories={props.fetchVirtualChildCategories}
        onToggleCategoryTreeCollapse={props.onToggleVirtualCategoryTreeCollapse}
        handleLoadSubcategories={props.handleLoadVirtualSubcategories}
        setSavedFilterFields={props.setSavedFilterFields}
        setSavedFilterFilters={props.setSavedFilterFilters}
        setProductsListEmpty={props.setProductsListEmpty}
        setFullTextListModeSearch={props.setFullTextListModeSearch}
        setIsCustomFieldsMode={props.setIsCustomFieldsMode}
        setSearchQuery={props.setSearchQuery}
        setPagination={props.setPagination}
        fetchScopeCategoriesByIds={props.fetchScopeCategoriesByIds}
        clearFromScopeCategoryState={props.clearFromScopeCategoryState}
        setGlobalFilters={props.setGlobalFilters}
        setSelectedSavedFilter={props.setSelectedSavedFilter}
        setGlobalFields={props.setGlobalFields}

        sortedSearchFields={props.sortedSearchFields}
        sortedFilters={props.sortedFilters}
        sortedFields={props.sortedFields}
        condition={props.condition}
        scroll={props.scroll}
        reset={props.reset}
        itemsPerPage={props.itemsPerPage}
        mapperCategories={props.mapperCategories}
        mapperMarketplace={props.mapperMarketplace}
        fromRules={fromRules}

        getMappingData={props.getMappingData}
        getMapperFilterOptions={getMapperFilterOptions}

        getActivityLogData={props.getActivityLogData}
        getActivityLogFilterOptions={getActivityLogFilterOptions}

        getRulesData={props.getRulesData}
        getRulesFilterOptions={getRulesFilterOptions}

        getFeedsData={handleFetchProductList}
        getFeedsFilterOptions={getFeedsFilterOptions}

        getRepricerData={props.getRepricerData}
        getRepricerFilterOptions={getRepricerFilterOptions}

        getRepricingData={props.getRepricingData}
        getRepricingFilterOptions={getRepricingFilterOptions}

        getCamsData={props.getCamsData}
        getCamsFilterOptions={getCamsFilterOptions}
      />
    </Can>
  );
});

SidebarContentComponent.propTypes = {
  pimProps: shape().isRequired,
  virtualCategoryProps: shape().isRequired,
  previewFilterProps: shape().isRequired,

  fetchVirtualChildCategories: func.isRequired,
  onToggleVirtualCategoryTreeCollapse: func.isRequired,
  handleLoadVirtualSubcategories: func.isRequired,
  fetchScopeCategoriesByIds: func.isRequired,
  clearFromScopeCategoryState: func.isRequired,
  setFilterTarget: func.isRequired,
  setSelectedProducts: func.isRequired,
  setGlobalFilters: func.isRequired,
  setGlobalFields: func.isRequired,
  setSelectedSavedFilter: func.isRequired,
  setSavedFilterFields: func.isRequired,
  setSavedFilterFilters: func.isRequired,
  setFullTextListModeSearch: func.isRequired,
  setProductsListEmpty: func.isRequired,
  setIsCustomFieldsMode: func.isRequired,
  setSearchQuery: func.isRequired,
  setPagination: func.isRequired,
  fetchProductList: func.isRequired,
  setHelperText: func.isRequired,
  setPaginationHelperTextId: func.isRequired,

  sortedSearchFields: shape(),
  sortedFilters: shape(),
  sortedFields: shape(),
  condition: shape(),
  scroll: shape(),
  reset: bool,
  itemsPerPage: number,
  fromRules: bool,
  mapperCategories: shape(),
  mapperMarketplace: shape(),

  getMappingData: func,
  getMapperFilterOptions: func,

  getActivityLogData: func,
  getActivityLogFilterOptions: func,

  getRulesData: func,
  getRulesFilterOptions: func,

  getFeedsData: func,
  getFeedsFilterOptions: func,

  getRepricerData: func,
  getRepricerFilterOptions: func,

  getRepricingData: func,
  getRepricingFilterOptions: func,

  getCamsData: func,
  getCamsFilterOptions: func,
};

SidebarContentComponent.defaultProps = {};

export const mapStateToProps = state => ({
  pimProps: pimSelector(state.pim),
  virtualCategoryProps: virtualCategorySelector(state.virtualCategory),
  previewFilterProps: previewFilterSelector(state.previewFilter),
});

export const mapDispatchToProps = {
  fetchVirtualChildCategories,
  onToggleVirtualCategoryTreeCollapse,
  handleLoadVirtualSubcategories,
  fetchScopeCategoriesByIds,
  clearFromScopeCategoryState,
  setFilterTarget,
  setSelectedProducts,
  setGlobalFilters,
  setGlobalFields,
  setSelectedSavedFilter,
  setSavedFilterFields,
  setSavedFilterFilters,
  setFullTextListModeSearch,
  setProductsListEmpty,
  setIsCustomFieldsMode,
  setSearchQuery,
  setPagination,
  fetchProductList,
  setHelperText,
  setPaginationHelperTextId,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContentComponent);
