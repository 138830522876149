/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import {
  string, arrayOf, func, shape, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { Tooltip } from '@material-ui/core';
import { FormGroup, Label, Input } from 'reactstrap';
import { InfoOutlined } from '@material-ui/icons';

import IntlMessages from '../../../../util/IntlMessages';
import InputErrors from '../../../../components/InputErrors';

const Designation = memo((props) => {
  const {
    name,
    searchPhrase,
    urlKey,
    type,
    onTextFieldChange,
    onSearchPhraseChange,
    onUrlKeyChange,
    errors,
    disabled,
    existedSearchPhrase,
  } = props;

  const getUrl = <span className="page-url">{name ? name.toLowerCase().replace(/\s/g, '_') : ''}</span>;
  const showPhraseTooltip = name && name.length > 50;
  const showPromotionTooltip = urlKey && urlKey.length > 45;
  const isPromotion = type === 'promotion_url';

  return (
    <div className="preview-drawer-designation navigation">
      <div className="name-row">
        <FormGroup className="mb-10 mr-sm-20 mb-sm-0">
          <Label for="name" className="mr-sm-10 form-label">
            <IntlMessages id="form.previewNavigation.nameLabel" />
          </Label>
          <div className="form-control-wrapper">
            <Input
              className={errors.name ? 'has-error' : ''}
              value={name || ''}
              type="text"
              name="name"
              placeholder="Name"
              onChange={onTextFieldChange}
              disabled={disabled}
            />
            {errors.name && <InputErrors className="errors-rel" errors={errors.name} />}
          </div>
        </FormGroup>
        {isPromotion ? (
          <FormGroup className="mb-10 mb-sm-0">
            <Label for="name" className="mr-sm-10 form-label">
              <IntlMessages id="form.previewNavigation.urlKeyLabel" />
            </Label>
            <div className="form-control-wrapper">
              <Input
                className={errors.urlKey ? 'has-error' : ''}
                value={urlKey && Array.isArray(urlKey) ? urlKey.join(',') : urlKey}
                type="text"
                name="urlKey"
                onChange={onUrlKeyChange}
                disabled={disabled}
              />
              {errors.urlKey && <InputErrors className="errors-rel" errors={errors.urlKey} />}
            </div>
          </FormGroup>
        ) : (
          <FormGroup className="mb-10 mb-sm-0">
            <Label for="name" className="mr-sm-10 form-label">
              <IntlMessages id="form.previewNavigation.searchPhraseLabel" />
            </Label>
            <div className="form-control-wrapper">
              <Input
                className={errors.searchPhrase ? 'has-error' : ''}
                value={searchPhrase && Array.isArray(searchPhrase)
                  ? searchPhrase.join(', ')
                  : searchPhrase}
                type="text"
                name="searchPhrase"
                onChange={onSearchPhraseChange}
                disabled={disabled}
              />
              {!isEmpty(existedSearchPhrase) && (
                <div className="search-phrase-suffix">
                  <Tooltip title={existedSearchPhrase}>
                    <InfoOutlined fontSize="small" style={{ color: '#ff0000' }} />
                  </Tooltip>
                </div>
              )}
              {errors.searchPhrase && <InputErrors className="errors-rel" errors={errors.searchPhrase} />}
            </div>
          </FormGroup>
        )}
      </div>
      {isPromotion ? (
        <div className="search-header-result flex items-center">
          <Label className="form-label">
            <IntlMessages id="text.urlKey" />
          </Label>
          {
            showPromotionTooltip
              ? (
                <Tooltip
                  title={urlKey}
                  placement="top"
                >
                  {<span className="page-url row-tirm">{urlKey}</span>}
                </Tooltip>
              ) : urlKey
          }
        </div>
      ) : (
        <div className="search-header-result flex items-center">
          <Label className="form-label">
            <IntlMessages id="searchPage.searchUrlName" />
          </Label>
          {
            showPhraseTooltip
              ? (
                <Tooltip
                  title={getUrl}
                  placement="top"
                >
                  {getUrl}
                </Tooltip>
              ) : getUrl
          }
        </div>
      )}
    </div>
  );
});

Designation.propTypes = {
  name: string.isRequired,
  searchPhrase: arrayOf(string),
  errors: shape().isRequired,
  urlKey: string,
  type: string.isRequired,
  disabled: bool,
  existedSearchPhrase: string,

  onTextFieldChange: func.isRequired,
  onSearchPhraseChange: func.isRequired,
  onUrlKeyChange: func.isRequired,
};

Designation.defaultProps = {
  disabled: false,
  searchPhrase: [],
  urlKey: '',
  existedSearchPhrase: '',
};

export default Designation;
