import restInitState from '../util/rest/initState';

import {
  SET_PRODUCT_EDIT_LANGUAGE,
} from '../actions/types';

const initialState = {
  productLanguage: { en: true, he: true },
};

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case SET_PRODUCT_EDIT_LANGUAGE:
      return {
        ...state,
        ...action.payload,
      };

    default: return state;
  }
};
