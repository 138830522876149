/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import { connect } from 'react-redux';
import {
  string, func, arrayOf, shape, bool,
} from 'prop-types';

import VariantLevelImageViewList from '../../components/VariantLevelImageViewList';
import { pimSelector } from '../../pages/Home/selectors/pim';

import {
  fetchVariantLevelChildren,
  fetchCountProductsActivitiesByProductIds,
  fetchVariantParents,
  setUpdatedProductsImagesOverrides,
  injectProducts,
} from '../../../../actions/pim';

import {
  fetchActivityLogsByProductId,
} from '../../../../actions/product';

const VariantImageViewContainer = memo((props) => {
  const {
    variants, parent, certainProductAttributes, variantsCollapsed,
    setVariantsCollapsed, product, isEditMode, filterTarget, attributes,
    searchFields, disableEdit, onMouseEnter, onMouseLeave, productComments,
    fetchProductCommentsStart,
  } = props;

  const handleExpandVariantLevel = (variant) => {
    const { childrenIds } = variant;

    if (filterTarget === 'parent') props.fetchVariantLevelChildren(childrenIds, variant);
    else props.fetchVariantParents(variant);
  };

  const sortedAttributes = attributes.sort((a, b) => a.order - b.order);

  return (
    <VariantLevelImageViewList
      variants={variants}
      parent={parent}
      certainProductAttributes={certainProductAttributes}
      variantsCollapsed={variantsCollapsed}
      setVariantsCollapsed={setVariantsCollapsed}
      productsCommentsCount={product.productsCommentsCount}
      fetchActivityLogsByProductId={props.fetchActivityLogsByProductId}
      productCommentsLoading={fetchProductCommentsStart}
      productComments={productComments}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      handleExpandVariantLevel={handleExpandVariantLevel}
      defaultImage={product.defaultImage}
      searchFields={searchFields}
      fetchVariantLevelChildrenProductsStarted={product.fetchVariantLevelChildrenProductsStarted}
      lastParentChildrenLoaded={product.lastParentChildrenLoaded}
      isEditMode={isEditMode}
      filterTarget={filterTarget}
      disableEdit={disableEdit}
      productsImagesOverride={product.productsImagesOverride}
      sortedAttributes={sortedAttributes}
      setUpdatedProductsImagesOverrides={props.setUpdatedProductsImagesOverrides}
      injectProducts={props.injectProducts}
      disabled={!isEditMode}
    />
  );
});

VariantImageViewContainer.propTypes = {
  variants: arrayOf(shape()).isRequired,
  parent: shape().isRequired,
  certainProductAttributes: arrayOf(shape()).isRequired,
  productComments: arrayOf(shape()).isRequired,
  variantsCollapsed: arrayOf(string).isRequired,
  product: shape().isRequired,
  searchFields: arrayOf(string).isRequired,
  attributes: arrayOf(shape()).isRequired,
  setVariantsCollapsed: func.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  fetchVariantLevelChildren: func.isRequired,
  injectProducts: func.isRequired,
  onMouseEnter: func,
  onMouseLeave: func,
  isEditMode: bool.isRequired,
  filterTarget: string.isRequired,
  fetchVariantParents: func.isRequired,
  setUpdatedProductsImagesOverrides: func.isRequired,
  disableEdit: bool,
  fetchProductCommentsStart: bool.isRequired,
};

VariantImageViewContainer.defaultProps = {
  onMouseEnter: null,
  onMouseLeave: null,
  disableEdit: false,
};

const mapStateToProps = state => ({
  product: pimSelector(state.pim),
  certainProductAttributes: state.productAttribute.certainProductAttributes,
  filterTarget: state.pim.filterTarget,
  attributes: state.system.modes.pim.parent,
  productComments: state.product.productComments,
  fetchProductCommentsStart: state.product.fetchProductCommentsStart,
});

const mapDispatchToProps = {
  fetchVariantLevelChildren,
  fetchCountProductsActivitiesByProductIds,
  fetchActivityLogsByProductId,
  fetchVariantParents,
  setUpdatedProductsImagesOverrides,
  injectProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(VariantImageViewContainer);
