// App Global
export const SET_IS_LOADING = 'SET_IS_LOADING';

// Autocomplete

export const AUTOCOMPLETE_START = 'AUTOCOMPLETE_START';
export const AUTOCOMPLETE_FINISHED = 'AUTOCOMPLETE_FINISHED';

// App Settings
export const COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR';
export const DARK_MODE = 'DARK_MODE';
export const BOXED_LAYOUT = 'BOXED_LAYOUT';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const MINI_SIDEBAR = 'MINI_SIDEBAR';
export const SEARCH_FORM_ENABLE = 'SEARCH_FORM_ENABLE';
export const CHANGE_THEME_COLOR = 'CHANGE_THEME_COLOR';
export const TOGGLE_SIDEBAR_IMAGE = 'TOGGLE_SIDEBAR_IMAGE';
export const SET_SIDEBAR_IMAGE = 'SET_SIDEBAR_IMAGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const START_USER_TOUR = 'START_USER_TOUR';
export const STOP_USER_TOUR = 'STOP_USER_TOUR';
export const TOGGLE_DARK_SIDENAV = 'TOGGLE_DARK_SIDENAV';
// Chat App Actions
export const CHAT_WITH_SELECTED_USER = 'CHAT_WITH_SELECTED_USER';
export const SEND_MESSAGE_TO_USER = 'SEND_MESSAGE_TO_USER';
export const UPDATE_USERS_SEARCH = 'UPDATE_USERS_SEARCH';
export const SEARCH_USERS = 'SEARCH_USERS';
export const GET_RECENT_CHAT_USERS = 'GET_RECENT_CHAT_USERS';
// Agency Sidebar
export const AGENCY_TOGGLE_MENU = 'AGENCY_TOGGLE_MENU';
// Mail App
export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAIL_SUCCESS = 'GET_EMAIL_SUCCESS';
export const GET_EMAIL_FAILURE = 'GET_EMAIL_FAILURE';
export const SET_EMAIL_AS_STAR = 'SET_EMAIL_AS_STAR';
export const READ_EMAIL = 'READ_EMAIL';
export const HIDE_LOADING_INDICATOR = 'HIDE_LOADING_INDICATOR';
export const FETCH_EMAILS = 'FETCH_EMAILS';
export const ON_SELECT_EMAIL = 'ON_SELECT_EMAIL';
export const UPDATE_EMAIL_SEARCH = 'UPDATE_EMAIL_SEARCH';
export const SEARCH_EMAIL = 'SEARCH_EMAIL';
export const ON_DELETE_MAIL = 'ON_DELETE_MAIL';
export const ON_BACK_PRESS_NAVIGATE_TO_EMAIL_LISTING = 'ON_BACK_PRESS_NAVIGATE_TO_EMAIL_LISTING';
export const GET_SENT_EMAILS = 'GET_SENT_EMAILS';
export const GET_INBOX = 'GET_INBOX';
export const GET_DRAFTS_EMAILS = 'GET_DRAFTS_EMAILS';
export const GET_SPAM_EMAILS = 'GET_SPAM_EMAILS';
export const GET_TRASH_EMAILS = 'GET_TRASH_EMAILS';
export const ON_EMAIL_MOVE_TO_FOLDER = 'ON_EMAIL_MOVE_TO_FOLDER';
export const SELECT_ALL_EMAILS = 'SELECT_ALL_EMAILS';
export const UNSELECT_ALL_EMAILS = 'UNSELECT_ALL_EMAILS';
export const ON_SEND_EMAIL = 'ON_SEND_EMAIL';
export const EMAIL_SENT_SUCCESSFULLY = 'EMAIL_SENT_SUCCESSFULLY';
export const FILTER_EMAILS_WITH_LABELS = 'FILTER_EMAILS_WITH_LABELS';
export const ADD_LABELS_INTO_EMAILS = 'ADD_LABELS_INTO_EMAILS';
// sidebar
export const TOGGLE_MENU = 'TOGGLE_MENU';
// ToDo App
export const GET_TODOS = 'GET_TODOS';
export const FETCH_TODOS = 'FETCH_TODOS';
export const ADD_NEW_TASK = 'ADD_NEW_TASK';
export const ON_SELECT_TODO = 'ON_SELECT_TODO';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const ON_BACK_TO_TODOS = 'ON_BACK_TO_TODOS';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const MARK_AS_STAR_TODO = 'MARK_AS_STAR_TODO';
export const DELETE_TODO = 'DELETE_TODO';
export const ADD_LABELS_INTO_THE_TASK = 'ADD_LABELS_INTO_THE_TASK';
export const GET_ALL_TODO = 'GET_ALL_TODO';
export const GET_COMPLETED_TODOS = 'GET_COMPLETED_TODOS';
export const GET_DELETED_TODOS = 'GET_DELETED_TODOS';
export const GET_STARRED_TODOS = 'GET_STARRED_TODOS';
export const GET_FILTER_TODOS = 'GET_FILTER_TODOS';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const COMPLETE_TASK = 'COMPLETE_TASK';
export const UPDATE_TASK_TITLE = 'UPDATE_TASK_TITLE';
export const UPDATE_TASK_DESCRIPTION = 'UPDATE_TASK_DESCRIPTION';
export const CHANGE_TASK_ASSIGNER = 'CHANGE_TASK_ASSIGNER';
export const ON_CHECK_BOX_TOGGLE_TODO_ITEM = 'ON_CHECK_BOX_TOGGLE_TODO_ITEM';
export const SELECT_ALL_TODO = 'SELECT_ALL_TODO';
export const GET_UNSELECTED_ALL_TODO = 'GET_UNSELECTED_ALL_TODO';
export const SELECT_STARRED_TODO = 'SELECT_STARRED_TODO';
export const SELECT_UNSTARRED_TODO = 'SELECT_UNSTARRED_TODO';
export const ON_LABEL_SELECT = 'ON_LABEL_SELECT';
export const ON_LABEL_MENU_ITEM_SELECT = 'ON_LABEL_MENU_ITEM_SELECT';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const SEARCH_TODO = 'SEARCH_TODO';

// Auth Actions
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const RESET_USER_PASSWORD_START = 'RESET_USER_PASSWORD_START';
export const RESET_USER_PASSWORD_FINISHED = 'RESET_USER_PASSWORD_FINISHED';
export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE';
export const VERIFY_USER_RESET_CODE_START = 'VERIFY_USER_RESET_CODE_START';
export const VERIFY_USER_RESET_CODE_FINISHED = 'VERIFY_USER_RESET_CODE_FINISHED';
export const CONFIRM_RESET_USER_PASSWORD_START = 'CONFIRM_RESET_USER_PASSWORD_START';
export const CONFIRM_RESET_USER_PASSWORD_FINISHED = 'CONFIRM_RESET_USER_PASSWORD_FINISHED';

// Feedbacks
export const GET_FEEDBACKS = 'GET_FEEDBACKS';
export const GET_FEEDBACKS_SUCCESS = 'GET_FEEDBACKS_SUCCESS';
export const GET_ALL_FEEDBACKS = 'GET_ALL_FEEDBACKS';
export const ON_CHANGE_FEEDBACK_PAGE_TABS = 'ON_CHANGE_FEEDBACK_PAGE_TABS';
export const MAKE_FAVORITE_FEEDBACK = 'MAKE_FAVORITE_FEEDBACK';
export const ON_DELETE_FEEDBACK = 'ON_DELETE_FEEDBACK';
export const VIEW_FEEDBACK_DETAILS = 'VIEW_FEEDBACK_DETAILS';
export const ADD_NEW_FEEDBACK = 'ADD_NEW_FEEDBACK';
export const SHOW_FEEDBACK_LOADING_INDICATOR = 'SHOW_FEEDBACK_LOADING_INDICATOR';
export const HIDE_FEEDBACK_LOADING_INDICATOR = 'HIDE_FEEDBACK_LOADING_INDICATOR';
export const NAVIGATE_TO_BACK = 'NAVIGATE_TO_BACK';
export const REPLY_FEEDBACK = 'REPLY_FEEDBACK';
export const SEND_REPLY = 'SEND_REPLY';
export const UPDATE_SEARCH_IDEA = 'UPDATE_SEARCH_IDEA';
export const ON_SEARCH_IDEA = 'ON_SEARCH_IDEA';
export const ON_COMMENT_FEEDBACK = 'ON_COMMENT_FEEDBACK';
// ecommerce
export const ON_DELETE_ITEM_FROM_CART = 'ON_DELETE_ITEM_FROM_CART';
export const ON_QUANTITY_CHANGE = 'ON_QUANTITY_CHANGE';
export const ON_ADD_ITEM_TO_CART = 'ON_ADD_ITEM_TO_CART';
// crm
export const ADD_NEW_CLIENT = 'ADD_NEW_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';

// virtual category
export const FETCH_CHILD_VIRTUAL_CATEGORIES_START = 'FETCH_CHILD_VIRTUAL_CATEGORIES_START';
export const FETCH_CHILD_VIRTUAL_CATEGORIES_FINISHED = 'FETCH_CHILD_VIRTUAL_CATEGORIES_FINISHED';
export const FETCH_ROOT_VIRTUAL_CATEGORIES_START = 'FETCH_ROOT_VIRTUAL_CATEGORIES_START';
export const FETCH_ROOT_VIRTUAL_CATEGORIES_FINISHED = 'FETCH_ROOT_VIRTUAL_CATEGORIES_FINISHED';
export const SET_CATEGORY_TREE_ITEMS = 'SET_CATEGORY_TREE_ITEMS';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const TOGGLE_CATEGORY_TREE_COLLAPSE = 'TOGGLE_CATEGORY_TREE_COLLAPSE';
export const TOGGLE_CATEGORY_TREE_COLLAPSE_ITEM = 'TOGGLE_CATEGORY_TREE_COLLAPSE_ITEM';
export const COLLAPSE_INITIAL_CATEGORIES_TREE = 'COLLAPSE_INITIAL_CATEGORIES_TREE';
export const FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_START = 'FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_START';
export const FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_FINISHED = 'FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_FINISHED';
export const HANDLE_LOAD_SUBCATEGORIES = 'HANDLE_LOAD_SUBCATEGORIES';
export const CLEAR_VIRTUAL_CATEGORY_TREE_STATE = 'CLEAR_VIRTUAL_CATEGORY_TREE_STATE';
export const SEARCH_VIRTUAL_CATEGORIES_START = 'SEARCH_VIRTUAL_CATEGORIES_START';
export const SEARCH_VIRTUAL_CATEGORIES_FINISHED = 'SEARCH_VIRTUAL_CATEGORIES_FINISHED';
export const SEARCH_VIRTUAL_CATEGORIES_FILTER_START = 'SEARCH_VIRTUAL_CATEGORIES_FILTER_START';
export const SEARCH_VIRTUAL_CATEGORIES_FILTER_FINISHED = 'SEARCH_VIRTUAL_CATEGORIES_FILTER_FINISHED';
export const SEARCH_PHYSICAL_CATEGORIES_FILTER_START = 'SEARCH_VIRTUAL_CATEGORIES_FILTER_START';
export const SEARCH_PHYSICAL_CATEGORIES_FILTER_FINISHED = 'SEARCH_VIRTUAL_CATEGORIES_FILTER_FINISHED';
export const FETCH_VIRTUAL_CATEGORIES_BY_NAME_START = 'FETCH_VIRTUAL_CATEGORIES_BY_NAME_START';
export const FETCH_VIRTUAL_CATEGORIES_BY_NAME_FINISHED = 'FETCH_VIRTUAL_CATEGORIES_BY_NAME_FINISHED';
export const CATEGORY_FETCH_PRODUCT_LIST_START = 'CATEGORY_FETCH_PRODUCT_LIST_START';
export const CATEGORY_FETCH_PRODUCT_LIST_FINISHED = 'CATEGORY_FETCH_PRODUCT_LIST_FINISHED';
export const CLEAR_CATEGORY_PRODUCT_STATE = 'CLEAR_CATEGORY_PRODUCT_STATE';
export const CATEGORY_FETCH_PRODUCTS_BY_IDS_START = 'CATEGORY_FETCH_PRODUCTS_BY_IDS_START';
export const CATEGORY_FETCH_PRODUCTS_BY_IDS_FINISHED = 'CATEGORY_FETCH_PRODUCTS_BY_IDS_FINISHED';
export const COMPLETE_CATEGORY_PRODUCTS_LIST_FINISHED = 'COMPLETE_CATEGORY_PRODUCTS_LIST_FINISHED';
export const COMPLETE_CATEGORY_PRODUCTS_LIST_START = 'COMPLETE_CATEGORY_PRODUCTS_LIST_START';
export const CATEGORY_SET_SELECTED_PRODUCT_LIST = 'CATEGORY_SET_SELECTED_PRODUCT_LIST';
export const CATEGORY_SET_PRODUCTS_IN_IDS = 'CATEGORY_SET_PRODUCTS_IN_IDS';
export const CATEGORY_SET_PRODUCTS_NIN_IDS = 'CATEGORY_SET_PRODUCTS_NIN_IDS';
export const CATEGORY_UPDATE_LOADED_PRODUCTS_IN = 'CATEGORY_UPDATE_LOADED_PRODUCTS_IN';
export const CATEGORY_UPDATE_LOADED_PRODUCTS_NIN = 'CATEGORY_UPDATE_LOADED_PRODUCTS_NIN';
export const FETCH_CATEGORY_TO_DUPLICATE_START = 'FETCH_CATEGORY_TO_DUPLICATE_START';
export const FETCH_CATEGORY_TO_DUPLICATE_FINISHED = 'FETCH_CATEGORY_TO_DUPLICATE_FINISHED';

// virtual category widget
export const VIRTUAL_CATEGORY_WIDGET_SEARCH_VIRTUAL_CATEGORIES_START = 'VIRTUAL_CATEGORY_WIDGET_SEARCH_VIRTUAL_CATEGORIES_START';
export const VIRTUAL_CATEGORY_WIDGET_SEARCH_VIRTUAL_CATEGORIES_FINISHED = 'VIRTUAL_CATEGORY_WIDGET_SEARCH_VIRTUAL_CATEGORIES_FINISHED';

// context menu
export const SET_CONTEXT_MENU_TARGET_TREE_NODE = 'SET_CONTEXT_MENU_TARGET_TREE_NODE';
export const SET_CONTEXT_MENU_SHOW = 'SET_CONTEXT_MENU_SHOW';
export const SET_CONTEXT_MENU_STYLES = 'SET_CONTEXT_MENU_STYLES';

// category facet
export const COMBINED_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START = 'COMBINED_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START';
export const COMBINED_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED = 'COMBINED_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED';
export const HIERARCHICAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START = 'HIERARCHICAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START';
export const HIERARCHICAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED = 'HIERARCHICAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED';

// virtual facets
export const VIRTUAL_FACET_SEARCH_ATTRIBUTE_OPTIONS_START = 'VIRTUAL_FACET_SEARCH_ATTRIBUTE_OPTIONS_START';
export const VIRTUAL_FACET_SEARCH_ATTRIBUTE_OPTIONS_FINISHED = 'VIRTUAL_FACET_SEARCH_ATTRIBUTE_OPTIONS_FINISHED';
export const VIRTUAL_FACET_CLEAR_FOUND_ATTRIBUTE_OPTIONS = 'VIRTUAL_FACET_CLEAR_FOUND_ATTRIBUTE_OPTIONS';
export const VIRTUAL_FACET_CLEAR_INITIAL_ATTRIBUTE_OPTIONS = 'VIRTUAL_FACET_CLEAR_INITIAL_ATTRIBUTE_OPTIONS';
export const VIRTUAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START = 'VIRTUAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START';
export const VIRTUAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED = 'VIRTUAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED';
export const VIRTUAL_FACET_SET_SELECTED_ATTRIBUTE_OPTION = 'VIRTUAL_FACET_SET_SELECTED_ATTRIBUTE_OPTION';
export const VIRTUAL_FACET_SET_SELECTED_ATTRIBUTES_OPTIONS = 'VIRTUAL_FACET_SET_SELECTED_ATTRIBUTES_OPTIONS';

// virtual category facet
export const SET_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE = 'SET_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE';

// virtual navigation facet
export const SET_NAVIGATION_VIRTUAL_FACET_ID_TO_DELETE = 'SET_NAVIGATION_VIRTUAL_FACET_ID_TO_DELETE';

// preview category virtual facet
export const SET_PREVIEW_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE = 'SET_PREVIEW_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE';

// preview navigation virtual facet
export const SET_PREVIEW_NAVIGATION_VIRTUAL_FACET_ID_TO_DELETE = 'SET_PREVIEW_NAVIGATION_VIRTUAL_FACET_ID_TO_DELETE';

// ranking
export const CLEAR_CREATE_RANKING_STATE = 'CLEAR_CREATE_RANKING_STATE';
export const CLEAR_FETCHED_RANKING_STATE = 'CLEAR_FETCHED_RANKING_STATE';
export const CLEAR_UPDATE_RANKING_STATE = 'CLEAR_UPDATE_RANKING_STATE';
export const CLEAR_DELETE_RANKING_STATE = 'CLEAR_DELETE_RANKING_STATE';
export const CLEAR_RANKING_STATE = 'CLEAR_RANKING_STATE';
export const FETCH_RANKING_BY_NAME_START = 'FETCH_RANKING_BY_NAME_START';
export const FETCH_RANKING_BY_NAME_FINISHED = 'FETCH_RANKING_BY_NAME_FINISHED';
export const RANKING_SEARCH_CATEGORIES_BY_RANKING_RULE_START = 'RANKING_SEARCH_CATEGORIES_BY_RANKING_RULE_START';
export const RANKING_SEARCH_CATEGORIES_BY_RANKING_RULE_FINISHED = 'RANKING_SEARCH_CATEGORIES_BY_RANKING_RULE_FINISHED';
export const RANKING_SEARCH_NAVIGATIONS_BY_RANKING_RULE_START = 'RANKING_SEARCH_NAVIGATIONS_BY_RANKING_RULE_START';
export const RANKING_SEARCH_NAVIGATIONS_BY_RANKING_RULE_FINISHED = 'RANKING_SEARCH_NAVIGATIONS_BY_RANKING_RULE_FINISHED';
export const RANKING_BULK_CATEGORIES_UPDATE_START = 'RANKING_BULK_CATEGORIES_UPDATE_START';
export const RANKING_BULK_CATEGORIES_UPDATE_FINISHED = 'RANKING_BULK_CATEGORIES_UPDATE_FINISHED';
export const RANKING_BULK_NAVIGATIONS_UPDATE_START = 'RANKING_BULK_NAVIGATIONS_UPDATE_START';
export const RANKING_BULK_NAVIGATIONS_UPDATE_FINISHED = 'RANKING_BULK_NAVIGATIONS_UPDATE_FINISHED';

// preview
export const PREVIEW_SET_SELECTED_PRODUCTS = 'PREVIEW_SET_SELECTED_PRODUCTS';
export const PREVIEW_SET_PRODUCT_FOR_EDIT = 'PREVIEW_SET_PRODUCT_FOR_EDIT';
export const PREVIEW_CLEAR_PRODUCT_STATE = 'PREVIEW_CLEAR_PRODUCT_STATE';
export const PREVIEW_SET_CATEGORY_PRODUCTS_MIN_POSITION = 'PREVIEW_SET_CATEGORY_PRODUCTS_MIN_POSITION';
export const PREVIEW_SET_CATEGORY_PRODUCTS_MAX_POSITION = 'PREVIEW_SET_CATEGORY_PRODUCTS_MAX_POSITION';
export const PREVIEW_CLEAR_PARENT_LEVEL_CHILDREN_PRODUCTS = 'PREVIEW_CLEAR_PARENT_LEVEL_CHILDREN_PRODUCTS';
export const PREVIEW_CLEAR_VARIANT_LEVEL_CHILDREN_PRODUCTS = 'PREVIEW_CLEAR_VARIANT_LEVEL_CHILDREN_PRODUCTS';
export const PREVIEW_SET_ALL_PRODUCTS_AS_SELECTED = 'PREVIEW_SET_ALL_PRODUCTS_AS_SELECTED';
export const PREVIEW_FETCH_PRODUCT_BY_IDS_START = 'PREVIEW_FETCH_PRODUCT_BY_IDS_START';
export const PREVIEW_FETCH_PRODUCT_BY_IDS_FINISHED = 'PREVIEW_FETCH_PRODUCT_BY_IDS_FINISHED';
export const PREVIEW_FETCH_PRODUCT_ACTIVITY_START = 'PREVIEW_FETCH_PRODUCT_ACTIVITY_START';
export const PREVIEW_FETCH_PRODUCT_ACTIVITY_FINISHED = 'PREVIEW_FETCH_PRODUCT_ACTIVITY_FINISHED';
export const PREVIEW_SET_PRODUCTS_LIST_EMPTY = 'PREVIEW_SET_PRODUCTS_LIST_EMPTY';
export const PREVIEW_INJECT_UPDATED_PRODUCTS = 'PREVIEW_INJECT_UPDATED_PRODUCTS';
export const PREVIEW_FETCH_PRODUCTS_COMMENTS_COUNT_START = 'PREVIEW_FETCH_PRODUCTS_COMMENTS_COUNT_START';
export const PREVIEW_FETCH_PRODUCTS_COMMENTS_COUNT_FINISHED = 'PREVIEW_FETCH_PRODUCTS_COMMENTS_COUNT_FINISHED';
export const PREVIEW_SET_SELECTED_PROMOTION = 'PREVIEW_SET_SELECTED_PROMOTION';
export const PREVIEW_SET_SELECTED_SEARCH_FILTER = 'PREVIEW_SET_SELECTED_SEARCH_FILTER';
export const PREVIEW_SET_SELECTED_ENTITY_NAME = 'PREVIEW_SET_SELECTED_ENTITY_NAME';
export const PREVIEW_SET_UPDATED_PRODUCTS_OVERRIDES = 'PREVIEW_SET_UPDATED_PRODUCTS_OVERRIDES';
export const PREVIEW_SET_UPDATED_PRODUCTS_IMAGES_OVERRIDES = 'PREVIEW_SET_UPDATED_PRODUCTS_IMAGES_OVERRIDES';
export const SET_PREVIEW_LIST_DIRECTION = 'SET_PREVIEW_LIST_DIRECTION';
export const PREVIEW_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START = 'PREVIEW_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START';
export const REVIEW_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED = 'REVIEW_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED';
export const FETCH_SELECTED_CATEGORY_FINISHED = 'FETCH_SELECTED_CATEGORY_FINISHED';
export const FETCH_SELECTED_CATEGORY_START = 'FETCH_SELECTED_CATEGORY_START';
export const PREVIEW_SET_SELECTED_CATEGORY = 'PREVIEW_SET_SELECTED_CATEGORY';
export const PREVIEW_SET_CONTAINER_STYLE = 'PREVIEW_SET_CONTAINER_STYLE';
export const PREVIEW_SET_EDIT_MODE = 'PREVIEW_SET_EDIT_MODE';
export const PREVIEW_SET_PAGINATION = 'PREVIEW_SET_PAGINATION';
export const PREVIEW_SET_SEARCH_QUERY = 'PREVIEW_SET_SEARCH_QUERY';
export const PREVIEW_SET_DISPLAY_MODE = 'PREVIEW_SET_DISPLAY_MODE';
export const PREVIEW_SET_HIDDEN_PRODUCTS_MODE = 'PREVIEW_SET_HIDDEN_PRODUCTS_MODE';
export const PREVIEW_SET_COMPOUND_SEARCH = 'PREVIEW_SET_COMPOUND_SEARCH';
export const PREVIEW_SET_SELECTED_ACTION = 'PREVIEW_SET_SELECTED_ACTION';
export const PREVIEW_SET_VIRTUAL_FACET_FILTER = 'PREVIEW_SET_VIRTUAL_FACET_FILTER';
export const PREVIEW_SET_GLOBAL_SEARCH_VALUE = 'PREVIEW_SET_GLOBAL_SEARCH_VALUE';
export const PREVIEW_SET_HIDDEN_PRODUCTS_FILTER = 'PREVIEW_SET_HIDDEN_PRODUCTS_FILTER';
export const PREVIEW_SET_HIDDEN_PRODUCTS_IDS = 'PREVIEW_SET_HIDDEN_PRODUCTS_IDS';
export const PREVIEW_SET_HIDDEN_PRODUCTS_IS_EXIST = 'PREVIEW_SET_HIDDEN_PRODUCTS_IS_EXIST';
export const PREVIEW_SET_FACETS_PRE_FILTERS = 'PREVIEW_SET_FACETS_PRE_FILTERS';
export const PREVIEW_SET_SEARCH_VALUE = 'PREVIEW_SET_SEARCH_VALUE';
export const PREVIEW_SET_MODE = 'PREVIEW_SET_MODE';
export const PREVIEW_SET_PRODUCTS_OVERRIDE = 'PREVIEW_SET_PRODUCTS_OVERRIDE';
export const PREVIEW_SET_ACTION_POPOVER_STATE = 'PREVIEW_SET_ACTION_POPOVER_STATE';
export const PREVIEW_SET_ACTION_POPOVER_ANCHOR = 'PREVIEW_SET_ACTION_POPOVER_ANCHOR';
export const PREVIEW_SET_SELECTED_PRODUCT = 'PREVIEW_SET_SELECTED_PRODUCT';
export const PREVIEW_SET_PRODUCT_PIN_ORDER_HELPER_TEXT_ID = 'PREVIEW_SET_PRODUCT_PIN_ORDER_HELPER_TEXT_ID';
export const PREVIEW_SET_SELECTED_PIN_ACTION = 'PREVIEW_SET_SELECTED_PIN_ACTION';
export const PREVIEW_SET_PRODUCT_PIN_ORDER = 'PREVIEW_SET_PRODUCT_PIN_ORDER';

// preview filters
export const SET_SELECTED_SAVED_FILTER = 'SET_SELECTED_SAVED_FILTER';
export const SET_SAVED_FILTERS = 'SET_SAVED_FILTERS';

// pim
export const PIM_SET_SELECTED_PRODUCTS = 'PIM_SET_SELECTED_PRODUCTS';
export const PIM_SET_PRODUCT_FOR_EDIT = 'PIM_SET_PRODUCT_FOR_EDIT';
export const PIM_CLEAR_PRODUCT_STATE = 'PIM_CLEAR_PRODUCT_STATE';
export const PIM_SET_CATEGORY_PRODUCTS_MIN_POSITION = 'PIM_SET_CATEGORY_PRODUCTS_MIN_POSITION';
export const PIM_SET_CATEGORY_PRODUCTS_MAX_POSITION = 'PIM_SET_CATEGORY_PRODUCTS_MAX_POSITION';
export const PIM_FETCH_PARENT_LEVEL_CHILDREN_PRODUCTS_START = 'PIM_FETCH_PARENT_LEVEL_CHILDREN_PRODUCTS_START';
export const PIM_FETCH_PARENT_LEVEL_CHILDREN_PRODUCTS_FINISHED = 'PIM_FETCH_PARENT_LEVEL_CHILDREN_PRODUCTS_FINISHED';
export const PIM_CLEAR_PARENT_LEVEL_CHILDREN_PRODUCTS = 'PIM_CLEAR_PARENT_LEVEL_CHILDREN_PRODUCTS';
export const PIM_FETCH_VARIANT_LEVEL_CHILDREN_PRODUCTS_START = 'PIM_FETCH_VARIANT_LEVEL_CHILDREN_PRODUCTS_START';
export const PIM_FETCH_VARIANT_LEVEL_CHILDREN_PRODUCTS_FINISHED = 'PIM_FETCH_VARIANT_LEVEL_CHILDREN_PRODUCTS_FINISHED';
export const PIM_CLEAR_VARIANT_LEVEL_CHILDREN_PRODUCTS = 'PIM_CLEAR_VARIANT_LEVEL_CHILDREN_PRODUCTS';
export const PIM_SET_ALL_PRODUCTS_AS_SELECTED = 'PIM_SET_ALL_PRODUCTS_AS_SELECTED';
export const PIM_FETCH_PRODUCT_BY_IDS_START = 'PIM_FETCH_PRODUCT_BY_IDS_START';
export const PIM_FETCH_PRODUCT_BY_IDS_FINISHED = 'PIM_FETCH_PRODUCT_BY_IDS_FINISHED';
export const PIM_SET_PRODUCTS_LIST_EMPTY = 'PIM_SET_PRODUCTS_LIST_EMPTY';
export const PIM_INJECT_UPDATED_PRODUCTS = 'PIM_INJECT_UPDATED_PRODUCTS';
export const PIM_FETCH_PRODUCTS_COMMENTS_COUNT_START = 'PIM_FETCH_PRODUCTS_COMMENTS_COUNT_START';
export const PIM_FETCH_PRODUCTS_COMMENTS_COUNT_FINISHED = 'PIM_FETCH_PRODUCTS_COMMENTS_COUNT_FINISHED';
export const PIM_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START = 'PIM_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START';
export const PIM_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED = 'PIM_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED';
export const PIM_SET_LIST_DIRECTION = 'PIM_SET_LIST_DIRECTION';
export const PIM_SET_UPDATED_PRODUCTS_IMAGES_OVERRIDES = 'PIM_SET_UPDATED_PRODUCTS_IMAGES_OVERRIDES';
export const PIM_SET_FILTER_TARGET = 'PIM_SET_FILTER_TARGET';
export const PIM_FETCH_VARIANT_PARENTS_START = 'PIM_FETCH_VARIANT_PARENTS_START';
export const PIM_FETCH_VARIANT_PARENTS_FINISHED = 'PIM_FETCH_VARIANT_PARENTS_FINISHED';
export const PIM_SEARCH_PHYSICAL_CATEGORIES_BY_IDS_START = 'PIM_SEARCH_PHYSICAL_CATEGORIES_BY_IDS_START';
export const PIM_SEARCH_PHYSICAL_CATEGORIES_BY_IDS_FINISHED = 'PIM_SEARCH_PHYSICAL_CATEGORIES_BY_IDS_FINISHED';
export const PIM_SET_CONTAINER_STYLE = 'PIM_SET_CONTAINER_STYLE';
export const PIM_SET_GLOBAL_FILTERS = 'PIM_SET_GLOBAL_FILTERS';
export const PIM_SET_GLOBAL_FIELDS = 'PIM_SET_GLOBAL_FIELDS';
export const PIM_SET_IS_CUSTOM_FIELDS_MODE = 'PIM_SET_IS_CUSTOM_FIELDS_MODE';
export const PIM_SET_SEARCH_QUERY = 'PIM_SET_SEARCH_QUERY';
export const PIM_SET_PAGINATION = 'PIM_SET_PAGINATION';
export const PIM_SET_DISPLAY_MODE = 'PIM_SET_DISPLAY_MODE';
export const PIM_SET_IS_EDIT_MODE = 'PIM_SET_IS_EDIT_MODE';
export const PIM_SET_SAVED_FILTER_FILTERS = 'PIM_SET_SAVED_FILTER_FILTERS';
export const PIM_SET_SAVED_FILTER_FIELDS = 'PIM_SET_SAVED_FILTER_FIELDS';
export const PIM_SET_FULL_TEXT_LIST_SEARCH = 'PIM_SET_FULL_TEXT_LIST_SEARCH';
export const PIM_SET_SELECTED_ACTION = 'PIM_SET_SELECTED_ACTION';
export const PIM_SET_ORDER = 'PIM_SET_ORDER';
export const PIM_CLEAR_SELECTED = 'PIM_CLEAR_SELECTED';
export const PIM_CLEAR_PRODUCT_LIST = 'PIM_CLEAR_PRODUCT_LIST';
export const PIM_REMOVE_PRODUCTS_IMAGES_START = 'PIM_REMOVE_PRODUCTS_IMAGES_START';
export const PIM_REMOVE_PRODUCTS_IMAGES_FINISHED = 'PIM_REMOVE_PRODUCTS_IMAGES_FINISHED';
export const PIM_SET_PRODUCTS_WITHOUT_IMAGES = 'PIM_SET_PRODUCTS_WITHOUT_IMAGES';
export const PIM_SET_PRICE_FORMULA_MODAL = 'PIM_SET_PRICE_FORMULA_MODAL';
export const PIM_SET_HELPER_TEXT = 'PIM_SET_HELPER_TEXT';
export const PIM_SET_PAGINATION_HELPER_TEXT_ID = 'PIM_SET_PAGINATION_HELPER_TEXT_ID';
export const PIM_SET_SELECTED_PRODUCT = 'PIM_SET_SELECTED_PRODUCT';
export const PIM_SET_SEARCH_VALUE = 'PIM_SET_SEARCH_VALUE';
export const PIM_SET_ROOT_FILTER = 'PIM_SET_ROOT_FILTER';
export const PIM_SET_MAPPER_DATA = 'PIM_SET_MAPPER_DATA';
export const PIM_SET_RULES_DATA = 'PIM_SET_RULES_DATA';
export const PIM_SET_VIDEOS_ENRICHMENT_DATA = 'PIM_SET_VIDEOS_ENRICHMENT_DATA';
export const PIM_SET_MERCHANT_ACTIVITY_DIALOG = 'PIM_SET_MERCHANT_ACTIVITY_DIALOG';
export const PIM_SET_ACTIVITY_LOGS_FILTER = 'PIM_SET_ACTIVITY_LOGS_FILTER';
export const PIM_SET_ACTIVITY_LOGS_CUSTOM_FILTER = 'PIM_SET_ACTIVITY_LOGS_CUSTOM_FILTER';
export const PIM_SET_ACTIVITY_LOGS_SORTS = 'PIM_SET_ACTIVITY_LOGS_SORTS';
export const PIM_SET_ACTIVITY_LOGS_MERCHANT = 'PIM_SET_ACTIVITY_LOGS_MERCHANT';
export const PIM_SET_SHOW_ZAP_INFO = 'PIM_SET_SHOW_ZAP_INFO';

// physical category
export const SEARCH_PHYSICAL_CATEGORIES_START = 'SEARCH_PHYSICAL_CATEGORIES_START';
export const SEARCH_PHYSICAL_CATEGORIES_FINISHED = 'SEARCH_PHYSICAL_CATEGORIES_FINISHED';
export const CLEAR_PHYSICAL_CATEGORY_STATE = 'CLEAR_PHYSICAL_CATEGORY_STATE';
export const FETCH_PHYSICAL_CHILD_CATEGORIES_START = 'FETCH_PHYSICAL_CHILD_CATEGORIES_START';
export const FETCH_PHYSICAL_CHILD_CATEGORIES_FINISHED = 'FETCH_PHYSICAL_CHILD_CATEGORIES_FINISHED';
export const FETCH_PHYSICAL_ROOT_CATEGORIES_START = 'FETCH_PHYSICAL_ROOT_CATEGORIES_START';
export const FETCH_PHYSICAL_ROOT_CATEGORIES_FINISHED = 'FETCH_PHYSICAL_ROOT_CATEGORIES_FINISHED';
export const SEARCH_PHYSICAL_CATEGORIES_BY_IDS_START = 'SEARCH_PHYSICAL_CATEGORIES_BY_IDS_START';
export const SEARCH_PHYSICAL_CATEGORIES_BY_IDS_FINISHED = 'SEARCH_PHYSICAL_CATEGORIES_BY_IDS_FINISHED';

// product attribute
export const FETCH_CATEGORIES_ATTRIBUTES_START = 'FETCH_CATEGORIES_ATTRIBUTES_START';
export const FETCH_CATEGORIES_ATTRIBUTES_FINISHED = 'FETCH_CATEGORIES_ATTRIBUTES_FINISHED';
export const CLEAR_CATEGORIES_ATTRIBUTES_STATE = 'CLEAR_CATEGORIES_ATTRIBUTES_STATE';
export const CLEAR_PRODUCT_ATTRIBUTE_STATE = 'CLEAR_PRODUCT_ATTRIBUTE_STATE';
export const FETCH_PRODUCT_ATTRIBUTES_START = 'FETCH_PRODUCT_ATTRIBUTES_START';
export const FETCH_PRODUCT_ATTRIBUTES_FINISHED = 'FETCH_PRODUCT_ATTRIBUTES_FINISHED';
export const UPDATE_PRODUCT_ATTRIBUTE_LIST = 'UPDATE_PRODUCT_ATTRIBUTE_LIST';
export const FETCH_CERTAIN_PRODUCT_ATTRIBUTES_START = 'FETCH_CERTAIN_PRODUCT_ATTRIBUTES_START';
export const FETCH_CERTAIN_PRODUCT_ATTRIBUTES_FINISHED = 'FETCH_CERTAIN_PRODUCT_ATTRIBUTES_FINISHED';
export const FETCH_ATTRIBUTE_OPTIONS_START = 'FETCH_ATTRIBUTE_OPTIONS_START';
export const FETCH_ATTRIBUTE_OPTIONS_FINISHED = 'FETCH_ATTRIBUTE_OPTIONS_FINISHED';
export const FETCH_ATTRIBUTES_OPTIONS_START = 'FETCH_ATTRIBUTES_OPTIONS_START';
export const FETCH_ATTRIBUTES_OPTIONS_FINISHED = 'FETCH_ATTRIBUTES_OPTIONS_FINISHED';
export const FETCH_PRODUCT_ATTRIBUTES_FOR_VARIATIONS_FINISHED = 'FETCH_PRODUCT_ATTRIBUTES_FOR_VARIATIONS_FINISHED';
export const FETCH_PRODUCT_ATTRIBUTES_FOR_VARIATIONS_START = 'FETCH_PRODUCT_ATTRIBUTES_FOR_VARIATIONS_START';
export const FETCH_PRODUCT_ATTRIBUTE_SETS_START = 'FETCH_PRODUCT_ATTRIBUTE_SETS_START';
export const FETCH_PRODUCT_ATTRIBUTE_SETS_FINISHED = 'FETCH_PRODUCT_ATTRIBUTE_SETS_FINISHED';
export const SEARCH_ATTRIBUTE_OPTIONS_START = 'SEARCH_ATTRIBUTE_OPTIONS_START';
export const SEARCH_ATTRIBUTE_OPTIONS_FINISHED = 'SEARCH_ATTRIBUTE_OPTIONS_FINISHED';
export const FETCH_INITIAL_ATTRIBUTES_OPTIONS_START = 'FETCH_INITIAL_ATTRIBUTES_OPTIONS_START';
export const FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED = 'FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED';
export const SET_SELECTED_ATTRIBUTE_OPTION = 'SET_SELECTED_ATTRIBUTE_OPTION';
export const CLEAR_FOUND_ATTRIBUTE_OPTIONS = 'CLEAR_FOUND_ATTRIBUTE_OPTIONS';
export const SET_SELECTED_ATTRIBUTES_OPTIONS = 'SET_SELECTED_ATTRIBUTES_OPTIONS';
export const FETCH_FACETS_ATTRIBUTES_OPTIONS_START = 'FETCH_FACETS_ATTRIBUTES_OPTIONS_START';
export const FETCH_FACETS_ATTRIBUTES_OPTIONS_FINISHED = 'FETCH_FACETS_ATTRIBUTES_OPTIONS_FINISHED';
export const CLEAR_INITIAL_ATTRIBUTE_OPTIONS = 'CLEAR_INITIAL_ATTRIBUTE_OPTIONS';

// scope category
export const FETCH_ROOT_SCOPE_CATEGORIES_START = 'FETCH_ROOT_SCOPE_CATEGORIES_START';
export const FETCH_ROOT_SCOPE_CATEGORIES_FINISHED = 'FETCH_ROOT_SCOPE_CATEGORIES_FINISHED';
export const FETCH_CHILD_SCOPE_CATEGORIES_START = 'FETCH_CHILD_SCOPE_CATEGORIES_START';
export const FETCH_CHILD_SCOPE_CATEGORIES_FINISHED = 'FETCH_CHILD_SCOPE_CATEGORIES_FINISHED';
export const CLEAR_SCOPE_CATEGORIES_STATE = 'CLEAR_SCOPE_CATEGORIES_STATE';
export const SEARCH_SCOPE_CATEGORIES_START = 'SEARCH_SCOPE_CATEGORIES_START';
export const SEARCH_SCOPE_CATEGORIES_FINISHED = 'SEARCH_SCOPE_CATEGORIES_FINISHED';
export const CLEAR_FETCH_SCOPE_CATEGORY_STATE = 'CLEAR_FETCH_SCOPE_CATEGORY_STATE';
export const TOGGLE_SCOPE_CATEGORY_TREE_COLLAPSE = 'TOGGLE_SCOPE_CATEGORY_TREE_COLLAPSE';
export const HANDLE_LOAD_SCOPE_SUBCATEGORIES = 'HANDLE_LOAD_SCOPE_SUBCATEGORIES';
export const CLEAR_SCOPE_CATEGORY_TREE_STATE = 'CLEAR_SCOPE_CATEGORY_TREE_STATE';
export const FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_START = 'FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_START';
export const FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_FINISHED = 'FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_FINISHED';
export const FETCH_SCOPE_CATEGORIES_BY_IDS_START = 'FETCH_SCOPE_CATEGORIES_BY_IDS_START';
export const FETCH_SCOPE_CATEGORIES_BY_IDS_FINISHED = 'FETCH_SCOPE_CATEGORIES_BY_IDS_FINISHED';
export const FETCH_PARENT_CATEGORIES_BY_IDS_START = 'FETCH_PARENT_CATEGORIES_BY_IDS_START';
export const FETCH_PARENT_CATEGORIES_BY_IDS_FINISHED = 'FETCH_PARENT_CATEGORIES_BY_IDS_FINISHED';
export const SET_SELECTED_SCOPE_CATEGORY_ID = 'SET_SELECTED_SCOPE_CATEGORY_ID';
export const FETCH_PARENT_SCOPE_CATEGORY_START = 'FETCH_PARENT_SCOPE_CATEGORY_START';
export const FETCH_PARENT_SCOPE_CATEGORY_FINISHED = 'FETCH_PARENT_SCOPE_CATEGORY_FINISHED';

// search filter
export const FETCH_SEARCH_LIST_BY_NAME_START = 'FETCH_SEARCH_LIST_BY_NAME_START';
export const FETCH_SEARCH_LIST_BY_NAME_FINISHED = 'FETCH_SEARCH_LIST_BY_NAME_FINISHED';
export const FIND_NAVIGATION_BY_PHRASE_START = 'FIND_NAVIGATION_BY_PHRASE_START';
export const FIND_NAVIGATION_BY_PHRASE_FINISHED = 'FIND_NAVIGATION_BY_PHRASE_FINISHED';
export const CAMPAIGN_FETCH_PRODUCT_LIST_START = 'CAMPAIGN_FETCH_PRODUCT_LIST_START';
export const CAMPAIGN_FETCH_PRODUCT_LIST_FINISHED = 'CAMPAIGN_FETCH_PRODUCT_LIST_FINISHED';
export const CLEAR_CAMPAIGN_PRODUCT_STATE = 'CLEAR_CAMPAIGN_PRODUCT_STATE';
export const CAMPAIGN_FETCH_PRODUCTS_BY_IDS_START = 'CAMPAIGN_FETCH_PRODUCTS_BY_IDS_START';
export const CAMPAIGN_FETCH_PRODUCTS_BY_IDS_FINISHED = 'CAMPAIGN_FETCH_PRODUCTS_BY_IDS_FINISHED';
export const SEARCH_SEARCH_FILTERS_START = 'SEARCH_SEARCH_FILTERS_START';
export const SEARCH_SEARCH_FILTERS_FINISHED = 'SEARCH_SEARCH_FILTERS_FINISHED';
export const COMPLETE_SEARCH_PRODUCTS_LIST_FINISHED = 'COMPLETE_SEARCH_PRODUCTS_LIST_FINISHED';
export const COMPLETE_SEARCH_PRODUCTS_LIST_START = 'COMPLETE_SEARCH_PRODUCTS_LIST_START';
export const SEARCH_SET_SELECTED_PRODUCT_LIST = 'SEARCH_SET_SELECTED_PRODUCT_LIST';
export const SEARCH_SET_PRODUCTS_IN_IDS = 'SEARCH_SET_PRODUCTS_IN_IDS';
export const SEARCH_SET_PRODUCTS_NIN_IDS = 'SEARCH_SET_PRODUCTS_NIN_IDS';
export const SEARCH_UPDATE_LOADED_PRODUCTS_IN = 'SEARCH_UPDATE_LOADED_PRODUCTS_IN';
export const SEARCH_UPDATE_LOADED_PRODUCTS_NIN = 'SEARCH_UPDATE_LOADED_PRODUCTS_NIN';
export const FETCH_NAVIGATION_TO_DUPLICATE_START = 'FETCH_NAVIGATION_TO_DUPLICATE_START';
export const FETCH_NAVIGATION_TO_DUPLICATE_FINISHED = 'FETCH_NAVIGATION_TO_DUPLICATE_FINISHED';
export const NAVIGATION_SEARCH_BY_PHRASES_START = 'NAVIGATION_SEARCH_BY_PHRASES_START';
export const NAVIGATION_SEARCH_BY_PHRASES_FINISHED = 'NAVIGATION_SEARCH_BY_PHRASES_FINISHED';
export const UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_STARTED = 'UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_STARTED';
export const UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_FINISHED = 'UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_FINISHED';

// NAVIGATION

export const NAVIGATION_SET_IS_CATEGORY_SAVING = 'NAVIGATION_SET_IS_CATEGORY_SAVING';
export const NAVIGATION_SET_IS_DATA_CHANGED = 'NAVIGATION_SET_IS_DATA_CHANGED';
export const NAVIGATION_SET_FILTERS_ERRORS = 'NAVIGATION_SET_FILTERS_ERRORS';
export const NAVIGATION_SET_ERRORS = 'NAVIGATION_SET_ERRORS';
export const SEARCH_NAVIGATION_FILTERS_START = 'SEARCH_NAVIGATION_FILTERS_START';
export const SEARCH_NAVIGATION_FILTERS_FINISHED = 'SEARCH_NAVIGATION_FILTERS_FINISHED';
export const FETCH_NAVIGATION_LIST_BY_NAME_START = 'FETCH_NAVIGATION_LIST_BY_NAME_START';
export const FETCH_NAVIGATION_LIST_BY_NAME_FINISHED = 'FETCH_NAVIGATION_LIST_BY_NAME_FINISHED';
export const NAVIGATION_CLEAR_CATEGORY_PRODUCT_STATE = 'NAVIGATION_CLEAR_CATEGORY_PRODUCT_STATE';
export const NAVIGATION_UPDATE_LOADED_PRODUCTS_IN = 'NAVIGATION_UPDATE_LOADED_PRODUCTS_IN';
export const NAVIGATION_UPDATE_LOADED_PRODUCTS_NIN = 'NAVIGATION_UPDATE_LOADED_PRODUCTS_NIN';
export const NAVIGATION_COMPLETE_PRODUCTS_LIST_START = 'NAVIGATION_COMPLETE_PRODUCTS_LIST_START';
export const NAVIGATION_COMPLETE_PRODUCTS_LIST_FINISHED = 'NAVIGATION_COMPLETE_PRODUCTS_LIST_FINISHED';
export const NAVIGATION_FETCH_PRODUCT_LIST_START = 'NAVIGATION_FETCH_PRODUCT_LIST_START';
export const NAVIGATION_FETCH_PRODUCT_LIST_FINISHED = 'NAVIGATION_FETCH_PRODUCT_LIST_FINISHED';
export const NAVIGATION_FETCH_PRODUCTS_BY_IDS_START = 'NAVIGATION_FETCH_PRODUCTS_BY_IDS_START';
export const NAVIGATION_FETCH_PRODUCTS_BY_IDS_FINISHED = 'NAVIGATION_FETCH_PRODUCTS_BY_IDS_FINISHED';
export const NAVIGATION_SET_SELECTED_PRODUCT_LIST = 'NAVIGATION_SET_SELECTED_PRODUCT_LIST';
export const NAVIGATION_SET_PRODUCTS_IN_IDS = 'NAVIGATION_SET_PRODUCTS_IN_IDS';
export const NAVIGATION_SET_PRODUCTS_NIN_IDS = 'NAVIGATION_SET_PRODUCTS_NIN_IDS';
export const NAVIGATION_SET_CATEGORY_FIELDS = 'NAVIGATION_SET_CATEGORY_FIELDS';
export const NAVIGATION_SET_PRODUCT_CATEGORIES_ATTRIBUTES = 'NAVIGATION_SET_PRODUCT_CATEGORIES_ATTRIBUTES';
export const NAVIGATION_SET_ATTRIBUTES_MATRIX_ATTRIBUTES = 'NAVIGATION_SET_ATTRIBUTES_MATRIX_ATTRIBUTES';
export const NAVIGATION_SET_FILTERS = 'NAVIGATION_SET_FILTERS';
export const NAVIGATION_SET_PRODUCT_FILTER = 'NAVIGATION_SET_PRODUCT_FILTER';
export const NAVIGATION_SET_PRODUCT_COMPOUND_QUERY = 'NAVIGATION_SET_PRODUCT_COMPOUND_QUERY';
export const NAVIGATION_SET_LEFT_SIDE_PAGINATION = 'NAVIGATION_SET_LEFT_SIDE_PAGINATION';
export const NAVIGATION_SET_IS_SWITCH_ON = 'NAVIGATION_SET_IS_SWITCH_ON';
export const NAVIGATION_SET_IS_APPLY_FILTER_ACTIVE = 'NAVIGATION_SET_IS_APPLY_FILTER_ACTIVE';
export const NAVIGATION_SET_MAPPED_CATEGORIES = 'NAVIGATION_SET_MAPPED_CATEGORIES';
export const NAVIGATION_SET_SELECTED_CATEGORIES = 'NAVIGATION_SET_SELECTED_CATEGORIES';
export const NAVIGATION_SET_PHYSICAL_CATEGORIES_IDS = 'NAVIGATION_SET_PHYSICAL_CATEGORIES_IDS';
export const NAVIGATION_SET_SELECTED_TREE_ITEMS = 'NAVIGATION_SET_SELECTED_TREE_ITEMS';
export const NAVIGATION_SET_SHOW_CATEGORIES_DIALOG = 'NAVIGATION_SET_SHOW_CATEGORIES_DIALOG';
export const NAVIGATION_CLEAR_NAVIGATION_FILTER_STATE = 'NAVIGATION_CLEAR_NAVIGATION_FILTER_STATE';
export const NAVIGATION_SET_RANKING_RULE_CODE = 'NAVIGATION_SET_RANKING_RULE_CODE';
export const NAVIGATION_SET_SORTING_ERRORS = 'NAVIGATION_SET_SORTING_ERRORS';
export const NAVIGATION_SET_SORT_RULES = 'NAVIGATION_SET_SORT_RULES';
export const NAVIGATION_SET_EXISTED_SEARCH_PHRASE = 'NAVIGATION_SET_EXISTED_SEARCH_PHRASE';
export const NAVIGATION_SET_FACETS = 'NAVIGATION_SET_FACETS';
export const CLEAR_NAVIGATION_SORT = 'CLEAR_NAVIGATION_SORT';

// import categories
export const UPLOAD_XML_CATEGORIES_START = 'UPLOAD_XML_CATEGORIES_START';
export const UPLOAD_XML_CATEGORIES_FINISHED = 'UPLOAD_XML_CATEGORIES_FINISHED';
export const REMOVE_VIRTUAL_CATEGORIES_START = 'REMOVE_VIRTUAL_CATEGORIES_START';
export const REMOVE_VIRTUAL_CATEGORIES_FINISHED = 'REMOVE_VIRTUAL_CATEGORIES_FINISHED';
export const CREATE_BULK_VIRTUAL_CATEGORIES_START = 'CREATE_BULK_VIRTUAL_CATEGORIES_START';
export const CREATE_BULK_VIRTUAL_CATEGORIES_FINISHED = 'CREATE_BULK_VIRTUAL_CATEGORIES_FINISHED';
export const CLEAR_CATEGORY_IMPORT_STATE = 'CLEAR_CATEGORY_IMPORT_STATE';
export const UPDATE_BULK_VIRTUAL_CATEGORIES_START = 'UPDATE_BULK_VIRTUAL_CATEGORIES_START';
export const UPDATE_BULK_VIRTUAL_CATEGORIES_FINISHED = 'UPDATE_BULK_VIRTUAL_CATEGORIES_FINISHED';

// user
export const CLEAR_USER_STATE = 'CLEAR_USER_STATE';
export const USERS_SYNC_START = 'USERS_SYNC_START';
export const USERS_SYNC_FINISHED = 'USERS_SYNC_FINISHED';
export const CLEAR_FROM_USER_STATE = 'CLEAR_FROM_USER_STATE';

// session
export const CLEAR_SESSION_STATE = 'CLEAR_SESSION_STATE';

// product management
export const IMPORT_PRODUCTS_WITH_NEW_POSITION_START = 'IMPORT_PRODUCTS_WITH_NEW_POSITION_START';
export const IMPORT_PRODUCTS_WITH_NEW_POSITION_FINISHED = 'IMPORT_PRODUCTS_WITH_NEW_POSITION_FINISHED';
export const GENERATE_NEW_XLSX_FILE_START = 'GENERATE_NEW_XLSX_FILE_START';
export const GENERATE_NEW_XLSX_FILE_FINISHED = 'GENERATE_NEW_XLSX_FILE_FINISHED';
export const SET_IMPORT_FILE_NAME = 'SET_IMPORT_FILE_NAME';
export const FETCH_IMPORTED_PRODUCTS_START = 'FETCH_IMPORTED_PRODUCTS_START';
export const FETCH_IMPORTED_PRODUCTS_FINISHED = 'FETCH_IMPORTED_PRODUCTS_FINISHED';
export const CLEAR_PRODUCT_MANAGEMENT_STATE = 'CLEAR_PRODUCT_MANAGEMENT_STATE';

// product language
export const SET_PRODUCT_EDIT_LANGUAGE = 'SET_PRODUCT_EDIT_LANGUAGE';

// promotions
export const SEARCH_PROMOTIONS_START = 'SEARCH_PROMOTIONS_START';
export const SEARCH_PROMOTIONS_FINISHED = 'SEARCH_PROMOTIONS_FINISHED';
export const SET_SELECTED_PROMOTION = 'SET_SELECTED_PROMOTION';

// service config
export const CLEAR_SERVICE_CONFIG_STATE = 'CLEAR_SERVICE_CONFIG_STATE';

// influencer
export const UPDATE_INFLUENCER_FIELDS = 'UPDATE_INFLUENCER_FIELDS';
export const SET_AVATAR_DATA = 'SET_AVATAR_DATA';
export const UPDATE_AVATAR_START = 'UPDATE_AVATAR_START';
export const UPDATE_AVATAR_FINISHED = 'UPDATE_AVATAR_FINISHED';
export const GET_AVATAR_FROM_STORAGE_START = 'GET_AVATAR_FROM_STORAGE_START';
export const GET_AVATAR_FROM_STORAGE_FINISHED = 'GET_AVATAR_FROM_STORAGE_FINISHED';
export const SET_MAIN_BANNER_DATA = 'SET_MAIN_BANNER_DATA';
export const GET_MAIN_BANNER_FROM_STORAGE_START = 'GET_MAIN_BANNER_FROM_STORAGE_START';
export const GET_MAIN_BANNER_FROM_STORAGE_FINISHED = 'GET_MAIN_BANNER_FROM_STORAGE_FINISHED';
export const UPDATE_MAIN_BANNER_START = 'UPDATE_MAIN_BANNER_START';
export const UPDATE_MAIN_BANNER_FINISHED = 'UPDATE_MAIN_BANNER_FINISHED';
export const SET_SELECTED_VIRTUAL_CATEGORIES = 'SET_SELECTED_VIRTUAL_CATEGORIES';
export const UPDATE_TOP_TEN_FILTERS = 'UPDATE_TOP_TEN_FILTERS';
export const SET_SELECTED_CATEGORIES_ATTRIBUTES = 'SET_SELECTED_CATEGORIES_ATTRIBUTES';
export const INFLUENCER_FETCH_PRODUCT_LIST_START = 'INFLUENCER_FETCH_PRODUCT_LIST_START';
export const INFLUENCER_FETCH_PRODUCT_LIST_FINISHED = 'INFLUENCER_FETCH_PRODUCT_LIST_FINISHED';
export const INFLUENCER_FETCH_TOP_TEN_PRODUCTS_BY_IDS_START = 'INFLUENCER_FETCH_TOP_TEN_PRODUCTS_BY_IDS_START';
export const INFLUENCER_FETCH_TOP_TEN_PRODUCTS_BY_IDS_FINISHED = 'INFLUENCER_FETCH_TOP_TEN_PRODUCTS_BY_IDS_FINISHED';
export const INFLUENCER_SET_TOP_TEN_PRODUCT_FILTER = 'INFLUENCER_SET_TOP_TEN_PRODUCT_FILTER';
export const INFLUENCER_SET_SELECTED_TOP_TEN_PRODUCT_LIST = 'INFLUENCER_SET_SELECTED_TOP_TEN_PRODUCT_LIST';
export const INFLUENCER_SET_PRODUCTS_IN_IDS = 'INFLUENCER_SET_PRODUCTS_IN_IDS';
export const INFLUENCER_UPDATE_TOP_TEN_LOADED_PRODUCTS_IN = 'INFLUENCER_UPDATE_TOP_TEN_LOADED_PRODUCTS_IN';
export const INFLUENCER_FETCH_EXAMPLE_PRODUCTS_START = 'INFLUENCER_FETCH_EXAMPLE_PRODUCTS_START';
export const INFLUENCER_FETCH_EXAMPLE_PRODUCTS_FINISHED = 'INFLUENCER_FETCH_EXAMPLE_PRODUCTS_FINISHED';
export const UPDATE_PRODUCTS_FILTERS = 'UPDATE_PRODUCTS_FILTERS';
export const INFLUENCER_PRODUCTS_SECTION_PRODUCT_FILTER = 'INFLUENCER_PRODUCTS_SECTION_PRODUCT_FILTER';
export const INFLUENCER_SET_SELECTED_PRODUCTS_SECTION_PRODUCT_LIST = 'INFLUENCER_SET_SELECTED_PRODUCTS_SECTION_PRODUCT_LIST';
export const INFLUENCER_UPDATE_PRODUCT_SECTION_LOADED_PRODUCTS_IN = 'INFLUENCER_UPDATE_PRODUCT_SECTION_LOADED_PRODUCTS_IN';
export const INFLUENCER_SET_TOP_TEN_PRODUCTS_IN_IDS = 'INFLUENCER_SET_TOP_TEN_PRODUCTS_IN_IDS';
export const INFLUENCER_SET_PRODUCT_SECTION_PRODUCTS_IN_IDS = 'INFLUENCER_SET_PRODUCT_SECTION_PRODUCTS_IN_IDS';
export const INFLUENCER_COMPLETE_TOP_TEN_PRODUCTS_LIST_START = 'INFLUENCER_COMPLETE_TOP_TEN_PRODUCTS_LIST_START';
export const INFLUENCER_COMPLETE_TOP_TEN_PRODUCTS_LIST_FINISHED = 'INFLUENCER_COMPLETE_TOP_TEN_PRODUCTS_LIST_FINISHED';
export const INFLUENCER_COMPLETE_PRODUCT_SECTION_PRODUCTS_LIST_START = 'INFLUENCER_COMPLETE_PRODUCT_SECTION_PRODUCTS_LIST_START';
export const INFLUENCER_COMPLETE_PRODUCT_SECTION_PRODUCTS_LIST_FINISHED = 'INFLUENCER_COMPLETE_PRODUCT_SECTION_PRODUCTS_LIST_FINISHED';
export const INFLUENCER_SET_SELECTED_TOP_TEN_PRODUCTS_FILTER = 'INFLUENCER_SET_SELECTED_TOP_TEN_PRODUCTS_FILTER';
export const INFLUENCER_SET_SELECTED_PRODUCT_SECTION_PRODUCTS_FILTER = 'INFLUENCER_SET_SELECTED_PRODUCT_SECTION_PRODUCTS_FILTER';
export const INFLUENCER_FETCH_PRODUCT_SECTION_PRODUCTS_BY_IDS_START = 'INFLUENCER_FETCH_PRODUCT_SECTION_PRODUCTS_BY_IDS_START';
export const INFLUENCER_FETCH_PRODUCT_SECTION_PRODUCTS_BY_IDS_FINISHED = 'INFLUENCER_FETCH_PRODUCT_SECTION_PRODUCTS_BY_IDS_FINISHED';
export const INFLUENCER_SET_SELECTED_DEAL_SECTION_PRODUCT_LIST = 'INFLUENCER_SET_SELECTED_DEAL_SECTION_PRODUCT_LIST';
export const INFLUENCER_DEAL_SECTION_PRODUCT_FILTER = 'INFLUENCER_DEAL_SECTION_PRODUCT_FILTER';
export const INFLUENCER_UPDATE_DEAL_SECTION_LOADED_PRODUCTS_IN = 'INFLUENCER_UPDATE_DEAL_SECTION_LOADED_PRODUCTS_IN';
export const INFLUENCER_SET_DEAL_SECTION_PRODUCTS_IN_IDS = 'INFLUENCER_SET_DEAL_SECTION_PRODUCTS_IN_IDS';
export const INFLUENCER_COMPLETE_DEAL_SECTION_PRODUCTS_LIST_START = 'INFLUENCER_COMPLETE_DEAL_SECTION_PRODUCTS_LIST_START';
export const INFLUENCER_COMPLETE_DEAL_SECTION_PRODUCTS_LIST_FINISHED = 'INFLUENCER_COMPLETE_DEAL_SECTION_PRODUCTS_LIST_FINISHED';
export const INFLUENCER_FETCH_DEAL_SECTION_PRODUCTS_BY_IDS_START = 'INFLUENCER_FETCH_DEAL_SECTION_PRODUCTS_BY_IDS_START';
export const INFLUENCER_FETCH_DEAL_SECTION_PRODUCTS_BY_IDS_FINISHED = 'INFLUENCER_FETCH_DEAL_SECTION_PRODUCTS_BY_IDS_FINISHED';
export const INFLUENCER_SET_SELECTED_DEAL_SECTION_PRODUCTS_FILTER = 'INFLUENCER_SET_SELECTED_DEAL_SECTION_PRODUCTS_FILTER';
export const UPDATE_DEAL_FILTERS = 'UPDATE_DEAL_FILTERS';
export const SET_DEAL_BANNER_DATA = 'SET_DEAL_BANNER_DATA';
export const GET_DEAL_BANNER_FROM_STORAGE_START = 'GET_DEAL_BANNER_FROM_STORAGE_START';
export const GET_DEAL_BANNER_FROM_STORAGE_FINISHED = 'GET_DEAL_BANNER_FROM_STORAGE_FINISHED';
export const UPDATE_DEAL_BANNER_START = 'UPDATE_DEAL_BANNER_START';
export const UPDATE_DEAL_BANNER_FINISHED = 'UPDATE_DEAL_BANNER_FINISHED';
export const INFLUENCER_SET_TEXT_FIELDS_ERRORS = 'INFLUENCER_SET_TEXT_FIELDS_ERRORS';
export const INFLUENCER_CLEAR_PRODUCT_STATE = 'INFLUENCER_CLEAR_PRODUCT_STATE';

// tune
export const TUNE_CREATE_NEW_AFFILIATE_START = 'TUNE_CREATE_NEW_AFFILIATE_START';
export const TUNE_CREATE_NEW_AFFILIATE_FINISHED = 'TUNE_CREATE_NEW_AFFILIATE_FINISHED';
export const TUNE_CREATE_NEW_AFFILIATE_USER_START = 'TUNE_CREATE_NEW_AFFILIATE_USER_START';
export const TUNE_CREATE_NEW_AFFILIATE_USER_FINISHED = 'TUNE_CREATE_NEW_AFFILIATE_USER_FINISHED';
export const TUNE_FETCH_SETTINGS_START = 'TUNE_FETCH_SETTINGS_START';
export const TUNE_FETCH_SETTINGS_FINISHED = 'TUNE_FETCH_SETTINGS_FINISHED';
export const TUNE_CREATE_OFFER_START = 'TUNE_CREATE_OFFER_START';
export const TUNE_CREATE_OFFER_FINISHED = 'TUNE_CREATE_OFFER_FINISHED';
export const TUNE_CREATE_GOAL_START = 'TUNE_CREATE_GOAL_START';
export const TUNE_CREATE_GOAL_FINISHED = 'TUNE_CREATE_GOAL_FINISHED';
export const TUNE_GENERATE_TRACKING_URLS_START = 'TUNE_GENERATE_TRACKING_URLS_START';
export const TUNE_GENERATE_TRACKING_URLS_FINISHED = 'TUNE_GENERATE_TRACKING_URLS_FINISHED';

// preview-category
export const PREVIEW_CATEGORY_SET_CATEGORY_FIELDS = 'PREVIEW_CATEGORY_SET_CATEGORY_FIELDS';
export const PREVIEW_CATEGORY_SET_PRODUCT_CATEGORIES_ATTRIBUTES = 'PREVIEW_CATEGORY_SET_PRODUCT_CATEGORIES_ATTRIBUTES';
export const PREVIEW_CATEGORY_SET_ATTRIBUTES_MATRIX_ATTRIBUTES = 'PREVIEW_CATEGORY_SET_ATTRIBUTES_MATRIX_ATTRIBUTES';
export const PREVIEW_CATEGORY_SET_FILTERS = 'PREVIEW_CATEGORY_SET_FILTERS';
export const PREVIEW_CATEGORY_SET_FILTERS_ERRORS = 'PREVIEW_CATEGORY_SET_FILTERS_ERRORS';
export const PREVIEW_CATEGORY_SET_PRODUCTS_NIN_IDS = 'PREVIEW_CATEGORY_SET_PRODUCTS_NIN_IDS';
export const PREVIEW_CATEGORY_SET_PRODUCTS_IN_IDS = 'PREVIEW_CATEGORY_SET_PRODUCTS_IN_IDS';
export const PREVIEW_CATEGORY_SET_SELECTED_PRODUCT_LIST = 'PREVIEW_CATEGORY_SET_SELECTED_PRODUCT_LIST';
export const PREVIEW_CATEGORY_UPDATE_LOADED_PRODUCTS_IN = 'PREVIEW_CATEGORY_UPDATE_LOADED_PRODUCTS_IN';
export const PREVIEW_CATEGORY_UPDATE_LOADED_PRODUCTS_NIN = 'PREVIEW_CATEGORY_UPDATE_LOADED_PRODUCTS_NIN';
export const PREVIEW_CATEGORY_COMPLETE_PRODUCTS_LIST_START = 'PREVIEW_CATEGORY_COMPLETE_PRODUCTS_LIST_START';
export const PREVIEW_CATEGORY_COMPLETE_PRODUCTS_LIST_FINISHED = 'PREVIEW_CATEGORY_COMPLETE_PRODUCTS_LIST_FINISHED';
export const PREVIEW_CATEGORY_SET_IS_APPLY_FILTER_ACTIVE = 'PREVIEW_CATEGORY_SET_IS_APPLY_FILTER_ACTIVE';
export const PREVIEW_CATEGORY_SET_IS_SWITCH_ON = 'PREVIEW_CATEGORY_SET_IS_SWITCH_ON';
export const PREVIEW_CATEGORY_SET_PRODUCT_FILTER = 'PREVIEW_CATEGORY_SET_PRODUCT_FILTER';
export const PREVIEW_CATEGORY_FETCH_PRODUCT_LIST_START = 'PREVIEW_CATEGORY_FETCH_PRODUCT_LIST_START';
export const PREVIEW_CATEGORY_FETCH_PRODUCT_LIST_FINISHED = 'PREVIEW_CATEGORY_FETCH_PRODUCT_LIST_FINISHED';
export const PREVIEW_CATEGORY_SET_PRODUCT_COMPOUND_QUERY = 'PREVIEW_CATEGORY_SET_PRODUCT_COMPOUND_QUERY';
export const PREVIEW_CATEGORY_SET_LEFT_SIDE_PAGINATION = 'PREVIEW_CATEGORY_SET_LEFT_SIDE_PAGINATION';
export const PREVIEW_CATEGORY_FETCH_PRODUCTS_BY_IDS_START = 'PREVIEW_CATEGORY_FETCH_PRODUCTS_BY_IDS_START';
export const PREVIEW_CATEGORY_FETCH_PRODUCTS_BY_IDS_FINISHED = 'PREVIEW_CATEGORY_FETCH_PRODUCTS_BY_IDS_FINISHED';
export const PREVIEW_CATEGORY_SET_SHOW_CATEGORIES_DIALOG = 'PREVIEW_CATEGORY_SET_SHOW_CATEGORIES_DIALOG';
export const PREVIEW_CATEGORY_SET_MAPPED_CATEGORIES = 'PREVIEW_CATEGORY_SET_MAPPED_CATEGORIES';
export const PREVIEW_CATEGORY_SET_SELECTED_CATEGORIES = 'PREVIEW_CATEGORY_SET_SELECTED_CATEGORIES';
export const PREVIEW_CATEGORY_SET_PHYSICAL_CATEGORY_SEARCH_QUERY = 'PREVIEW_CATEGORY_SET_PHYSICAL_CATEGORY_SEARCH_QUERY';
export const PREVIEW_CATEGORY_SET_SELECTED_DIALOG_CATEGORIES = 'PREVIEW_CATEGORY_SET_SELECTED_DIALOG_CATEGORIES';
export const PREVIEW_CATEGORY_CLEAR_CATEGORY_PRODUCT_STATE = 'PREVIEW_CATEGORY_CLEAR_CATEGORY_PRODUCT_STATE';
export const PREVIEW_CATEGORY_SET_RANKING_RULE_CODE = 'PREVIEW_CATEGORY_SET_RANKING_RULE_CODE';
export const PREVIEW_CATEGORY_SET_SORTING_ERRORS = 'PREVIEW_CATEGORY_SET_SORTING_ERRORS';
export const PREVIEW_CATEGORY_SET_SORT_RULES = 'PREVIEW_CATEGORY_SET_SORT_RULES';
export const PREVIEW_CATEGORY_SET_FACETS = 'PREVIEW_CATEGORY_SET_FACETS';
export const PREVIEW_CATEGORY_SET_IS_DATA_CHANGED = 'PREVIEW_CATEGORY_SET_IS_DATA_CHANGED';
export const PREVIEW_CATEGORY_SET_IS_CATEGORY_SAVING = 'PREVIEW_CATEGORY_SET_IS_CATEGORY_SAVING';
export const PREVIEW_CATEGORY_SET_ERRORS = 'PREVIEW_CATEGORY_SET_ERRORS';
export const PREVIEW_CATEGORY_FETCH_VIRTUAL_CATEGORIES_BY_NAME_START = 'PREVIEW_CATEGORY_FETCH_VIRTUAL_CATEGORIES_BY_NAME_START';
export const PREVIEW_CATEGORY_FETCH_VIRTUAL_CATEGORIES_BY_NAME_FINISHED = 'PREVIEW_CATEGORY_FETCH_VIRTUAL_CATEGORIES_BY_NAME_FINISHED';

// preview-navigation
export const PREVIEW_NAVIGATION_SET_CATEGORY_FIELDS = 'PREVIEW_NAVIGATION_SET_CATEGORY_FIELDS';
export const PREVIEW_NAVIGATION_SET_PRODUCT_CATEGORIES_ATTRIBUTES = 'PREVIEW_NAVIGATION_SET_PRODUCT_CATEGORIES_ATTRIBUTES';
export const PREVIEW_NAVIGATION_SET_ATTRIBUTES_MATRIX_ATTRIBUTES = 'PREVIEW_NAVIGATION_SET_ATTRIBUTES_MATRIX_ATTRIBUTES';
export const PREVIEW_NAVIGATION_SET_FILTERS = 'PREVIEW_NAVIGATION_SET_FILTERS';
export const PREVIEW_NAVIGATION_SET_FILTERS_ERRORS = 'PREVIEW_NAVIGATION_SET_FILTERS_ERRORS';
export const PREVIEW_NAVIGATION_SET_PRODUCTS_NIN_IDS = 'PREVIEW_NAVIGATION_SET_PRODUCTS_NIN_IDS';
export const PREVIEW_NAVIGATION_SET_PRODUCTS_IN_IDS = 'PREVIEW_NAVIGATION_SET_PRODUCTS_IN_IDS';
export const PREVIEW_NAVIGATION_SET_SELECTED_PRODUCT_LIST = 'PREVIEW_NAVIGATION_SET_SELECTED_PRODUCT_LIST';
export const PREVIEW_NAVIGATION_UPDATE_LOADED_PRODUCTS_IN = 'PREVIEW_NAVIGATION_UPDATE_LOADED_PRODUCTS_IN';
export const PREVIEW_NAVIGATION_UPDATE_LOADED_PRODUCTS_NIN = 'PREVIEW_NAVIGATION_UPDATE_LOADED_PRODUCTS_NIN';
export const PREVIEW_NAVIGATION_COMPLETE_PRODUCTS_LIST_START = 'PREVIEW_NAVIGATION_COMPLETE_PRODUCTS_LIST_START';
export const PREVIEW_NAVIGATION_COMPLETE_PRODUCTS_LIST_FINISHED = 'PREVIEW_NAVIGATION_COMPLETE_PRODUCTS_LIST_FINISHED';
export const PREVIEW_NAVIGATION_SET_IS_APPLY_FILTER_ACTIVE = 'PREVIEW_NAVIGATION_SET_IS_APPLY_FILTER_ACTIVE';
export const PREVIEW_NAVIGATION_SET_IS_SWITCH_ON = 'PREVIEW_NAVIGATION_SET_IS_SWITCH_ON';
export const PREVIEW_NAVIGATION_SET_PRODUCT_FILTER = 'PREVIEW_NAVIGATION_SET_PRODUCT_FILTER';
export const PREVIEW_NAVIGATION_FETCH_PRODUCT_LIST_START = 'PREVIEW_NAVIGATION_FETCH_PRODUCT_LIST_START';
export const PREVIEW_NAVIGATION_FETCH_PRODUCT_LIST_FINISHED = 'PREVIEW_NAVIGATION_FETCH_PRODUCT_LIST_FINISHED';
export const PREVIEW_NAVIGATION_SET_PRODUCT_COMPOUND_QUERY = 'PREVIEW_NAVIGATION_SET_PRODUCT_COMPOUND_QUERY';
export const PREVIEW_NAVIGATION_SET_LEFT_SIDE_PAGINATION = 'PREVIEW_NAVIGATION_SET_LEFT_SIDE_PAGINATION';
export const PREVIEW_NAVIGATION_FETCH_PRODUCTS_BY_IDS_START = 'PREVIEW_NAVIGATION_FETCH_PRODUCTS_BY_IDS_START';
export const PREVIEW_NAVIGATION_FETCH_PRODUCTS_BY_IDS_FINISHED = 'PREVIEW_NAVIGATION_FETCH_PRODUCTS_BY_IDS_FINISHED';
export const PREVIEW_NAVIGATION_SET_SHOW_CATEGORIES_DIALOG = 'PREVIEW_NAVIGATION_SET_SHOW_CATEGORIES_DIALOG';
export const PREVIEW_NAVIGATION_SET_MAPPED_CATEGORIES = 'PREVIEW_NAVIGATION_SET_MAPPED_CATEGORIES';
export const PREVIEW_NAVIGATION_SET_SELECTED_CATEGORIES = 'PREVIEW_NAVIGATION_SET_SELECTED_CATEGORIES';
export const PREVIEW_NAVIGATION_CLEAR_CATEGORY_PRODUCT_STATE = 'PREVIEW_NAVIGATION_CLEAR_CATEGORY_PRODUCT_STATE';
export const PREVIEW_NAVIGATION_SET_RANKING_RULE_CODE = 'PREVIEW_NAVIGATION_SET_RANKING_RULE_CODE';
export const PREVIEW_NAVIGATION_SET_SORTING_ERRORS = 'PREVIEW_NAVIGATION_SET_SORTING_ERRORS';
export const PREVIEW_NAVIGATION_SET_SORT_RULES = 'PREVIEW_NAVIGATION_SET_SORT_RULES';
export const PREVIEW_NAVIGATION_SET_FACETS = 'PREVIEW_NAVIGATION_SET_FACETS';
export const PREVIEW_NAVIGATION_SET_IS_DATA_CHANGED = 'PREVIEW_NAVIGATION_SET_IS_DATA_CHANGED';
export const PREVIEW_NAVIGATION_SET_IS_CATEGORY_SAVING = 'PREVIEW_NAVIGATION_SET_IS_CATEGORY_SAVING';
export const PREVIEW_NAVIGATION_SET_ERRORS = 'PREVIEW_NAVIGATION_SET_ERRORS';
export const PREVIEW_NAVIGATION_SET_SELECTED_TREE_ITEMS = 'PREVIEW_NAVIGATION_SET_SELECTED_TREE_ITEMS';
export const PREVIEW_NAVIGATION_SET_PHYSICAL_CATEGORIES_IDS = 'PREVIEW_NAVIGATION_SET_PHYSICAL_CATEGORIES_IDS';
export const PREVIEW_NAVIGATION_CLEAR_NAVIGATION_FILTER_STATE = 'PREVIEW_NAVIGATION_CLEAR_NAVIGATION_FILTER_STATE';
export const PREVIEW_NAVIGATION_SEARCH_BY_PHRASES_START = 'PREVIEW_NAVIGATION_SEARCH_BY_PHRASES_START';
export const PREVIEW_NAVIGATION_SEARCH_BY_PHRASES_FINISHED = 'PREVIEW_NAVIGATION_SEARCH_BY_PHRASES_FINISHED';
export const PREVIEW_NAVIGATION_SET_EXISTED_SEARCH_PHRASE = 'PREVIEW_NAVIGATION_SET_EXISTED_SEARCH_PHRASE';
export const PREVIEW_NAVIGATION_FETCH_SEARCH_LIST_BY_NAME_START = 'PREVIEW_NAVIGATION_FETCH_SEARCH_LIST_BY_NAME_START';
export const PREVIEW_NAVIGATION_FETCH_SEARCH_LIST_BY_NAME_FINISHED = 'PREVIEW_NAVIGATION_FETCH_SEARCH_LIST_BY_NAME_FINISHED';
export const PREVIEW_NAVIGATION_CLEAR_NAVIGATION_FACET_STATE = 'PREVIEW_NAVIGATION_CLEAR_NAVIGATION_FACET_STATE';

// quick-edit-drawer
export const QUICK_EDIT_SET_DRAWER_STATE = 'QUICK_EDIT_SET_DRAWER_STATE';

// entity facet
export const SET_ENTITY_FACET_FIELDS = 'SET_ENTITY_FACET_FIELDS';

// app settings
export const FETCH_APP_SETTINGS_START = 'FETCH_APP_SETTINGS_START';
export const FETCH_APP_SETTINGS_FINISHED = 'FETCH_APP_SETTINGS_FINISHED';

// edit product
export const PRODUCT_SET_ERRORS = 'PRODUCT_SET_ERRORS';
export const PRODUCT_SET_FIELDS = 'PRODUCT_SET_FIELDS';
export const PRODUCT_FETCH_CHILD_PRODUCTS_START = 'PRODUCT_FETCH_CHILD_PRODUCTS_START';
export const PRODUCT_FETCH_CHILD_PRODUCTS_FINISHED = 'PRODUCT_FETCH_CHILD_PRODUCTS_FINISHED';
export const PRODUCT_FETCH_PRODUCT_COMMENTS_START = 'PRODUCT_FETCH_PRODUCT_COMMENTS_START';
export const PRODUCT_FETCH_PRODUCT_COMMENTS_FINISHED = 'PRODUCT_FETCH_PRODUCT_COMMENTS_FINISHED';
export const PRODUCT_SET_PRODUCT_FOR_EDIT = 'PRODUCT_SET_PRODUCT_FOR_EDIT';
export const PRODUCT_SET_PRODUCT_CUSTOM_COLUMNS = 'PRODUCT_SET_PRODUCT_CUSTOM_COLUMNS';
export const PRODUCT_FINISH_PRODUCT_EDIT = 'PRODUCT_FINISH_PRODUCT_EDIT';
export const PRODUCT_FETCH_PURE_PRODUCT_START = 'PRODUCT_FETCH_PURE_PRODUCT_START';
export const PRODUCT_FETCH_PURE_PRODUCT_FINISHED = 'PRODUCT_FETCH_PURE_PRODUCT_FINISHED';
export const PRODUCT_BULK_UPDATE_ACTIVITY_LOGS_START = 'PRODUCT_BULK_UPDATE_ACTIVITY_LOGS_START';
export const PRODUCT_BULK_UPDATE_ACTIVITY_LOGS_FINISHED = 'PRODUCT_BULK_UPDATE_ACTIVITY_LOGS_FINISHED';
export const PRODUCT_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START = 'PRODUCT_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START';
export const PRODUCT_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED = 'PRODUCT_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED';
export const PRODUCT_SET_SPECIFICATIONS_ERRORS = 'PRODUCT_SET_SPECIFICATIONS_ERRORS';
export const PRODUCT_SET_IS_SAVE_AND_CLOSE = 'PRODUCT_SET_IS_SAVE_AND_CLOSE';


// virtual category page
export const VIRTUAL_CATEGORY_PAGE_SET_CATEGORY_FIELDS = 'VIRTUAL_CATEGORY_PAGE_SET_CATEGORY_FIELDS';
export const VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_CATEGORIES_ATTRIBUTES = 'VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_CATEGORIES_ATTRIBUTES';
export const VIRTUAL_CATEGORY_PAGE_SET_ATTRIBUTES_MATRIX_ATTRIBUTES = 'VIRTUAL_CATEGORY_PAGE_SET_ATTRIBUTES_MATRIX_ATTRIBUTES';
export const VIRTUAL_CATEGORY_PAGE_SET_FILTERS = 'VIRTUAL_CATEGORY_PAGE_SET_FILTERS';
export const VIRTUAL_CATEGORY_PAGE_SET_FILTERS_ERRORS = 'VIRTUAL_CATEGORY_PAGE_SET_FILTERS_ERRORS';
export const VIRTUAL_CATEGORY_PAGE_SET_PRODUCTS_NIN_IDS = 'VIRTUAL_CATEGORY_PAGE_SET_PRODUCTS_NIN_IDS';
export const VIRTUAL_CATEGORY_PAGE_SET_PRODUCTS_IN_IDS = 'VIRTUAL_CATEGORY_PAGE_SET_PRODUCTS_IN_IDS';
export const VIRTUAL_CATEGORY_PAGE_SET_SELECTED_PRODUCT_LIST = 'VIRTUAL_CATEGORY_PAGE_SET_SELECTED_PRODUCT_LIST';
export const VIRTUAL_CATEGORY_PAGE_UPDATE_LOADED_PRODUCTS_IN = 'VIRTUAL_CATEGORY_PAGE_UPDATE_LOADED_PRODUCTS_IN';
export const VIRTUAL_CATEGORY_PAGE_UPDATE_LOADED_PRODUCTS_NIN = 'VIRTUAL_CATEGORY_PAGE_UPDATE_LOADED_PRODUCTS_NIN';
export const VIRTUAL_CATEGORY_PAGE_COMPLETE_PRODUCTS_LIST_START = 'VIRTUAL_CATEGORY_PAGE_COMPLETE_PRODUCTS_LIST_START';
export const VIRTUAL_CATEGORY_PAGE_COMPLETE_PRODUCTS_LIST_FINISHED = 'VIRTUAL_CATEGORY_PAGE_COMPLETE_PRODUCTS_LIST_FINISHED';
export const VIRTUAL_CATEGORY_PAGE_SET_IS_APPLY_FILTER_ACTIVE = 'VIRTUAL_CATEGORY_PAGE_SET_IS_APPLY_FILTER_ACTIVE';
export const VIRTUAL_CATEGORY_PAGE_SET_IS_SWITCH_ON = 'VIRTUAL_CATEGORY_PAGE_SET_IS_SWITCH_ON';
export const VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_FILTER = 'VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_FILTER';
export const VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCT_LIST_START = 'VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCT_LIST_START';
export const VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCT_LIST_FINISHED = 'VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCT_LIST_FINISHED';
export const VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_COMPOUND_QUERY = 'VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_COMPOUND_QUERY';
export const VIRTUAL_CATEGORY_PAGE_SET_LEFT_SIDE_PAGINATION = 'VIRTUAL_CATEGORY_PAGE_SET_LEFT_SIDE_PAGINATION';
export const VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCTS_BY_IDS_START = 'VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCTS_BY_IDS_START';
export const VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCTS_BY_IDS_FINISHED = 'VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCTS_BY_IDS_FINISHED';
export const VIRTUAL_CATEGORY_PAGE_SET_SHOW_CATEGORIES_DIALOG = 'VIRTUAL_CATEGORY_PAGE_SET_SHOW_CATEGORIES_DIALOG';
export const VIRTUAL_CATEGORY_PAGE_SET_MAPPED_CATEGORIES = 'VIRTUAL_CATEGORY_PAGE_SET_MAPPED_CATEGORIES';
export const VIRTUAL_CATEGORY_PAGE_SET_SELECTED_CATEGORIES = 'VIRTUAL_CATEGORY_PAGE_SET_SELECTED_CATEGORIES';
export const VIRTUAL_CATEGORY_PAGE_SET_PHYSICAL_CATEGORY_SEARCH_QUERY = 'VIRTUAL_CATEGORY_PAGE_SET_PHYSICAL_CATEGORY_SEARCH_QUERY';
export const VIRTUAL_CATEGORY_PAGE_SET_SELECTED_DIALOG_CATEGORIES = 'VIRTUAL_CATEGORY_PAGE_SET_SELECTED_DIALOG_CATEGORIES';
export const VIRTUAL_CATEGORY_PAGE_CLEAR_CATEGORY_PRODUCT_STATE = 'VIRTUAL_CATEGORY_PAGE_CLEAR_CATEGORY_PRODUCT_STATE';
export const VIRTUAL_CATEGORY_PAGE_SET_RANKING_RULE_CODE = 'VIRTUAL_CATEGORY_PAGE_SET_RANKING_RULE_CODE';
export const VIRTUAL_CATEGORY_PAGE_SET_SORTING_ERRORS = 'VIRTUAL_CATEGORY_PAGE_SET_SORTING_ERRORS';
export const VIRTUAL_CATEGORY_PAGE_SET_SORT_RULES = 'VIRTUAL_CATEGORY_PAGE_SET_SORT_RULES';
export const VIRTUAL_CATEGORY_PAGE_SET_FACETS = 'VIRTUAL_CATEGORY_PAGE_SET_FACETS';
export const VIRTUAL_CATEGORY_PAGE_SET_IS_DATA_CHANGED = 'VIRTUAL_CATEGORY_PAGE_SET_IS_DATA_CHANGED';
export const VIRTUAL_CATEGORY_PAGE_SET_IS_CATEGORY_SAVING = 'VIRTUAL_CATEGORY_PAGE_SET_IS_CATEGORY_SAVING';
export const VIRTUAL_CATEGORY_PAGE_SET_ERRORS = 'VIRTUAL_CATEGORY_PAGE_SET_ERRORS';
export const VIRTUAL_CATEGORY_PAGE_FETCH_VIRTUAL_CATEGORIES_BY_NAME_START = 'VIRTUAL_CATEGORY_PAGE_FETCH_VIRTUAL_CATEGORIES_BY_NAME_START';
export const VIRTUAL_CATEGORY_PAGE_FETCH_VIRTUAL_CATEGORIES_BY_NAME_FINISHED = 'VIRTUAL_CATEGORY_PAGE_FETCH_VIRTUAL_CATEGORIES_BY_NAME_FINISHED';
export const VIRTUAL_CATEGORY_PAGE_SET_ISDUPLICATED_VIRTUAL_CATEGORY = 'VIRTUAL_CATEGORY_PAGE_SET_ISDUPLICATED_VIRTUAL_CATEGORY';

// virtual category facet
export const SET_VIRTUAL_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE = 'SET_VIRTUAL_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE';

// cams
export const SET_CAMS_PRODUCT_DATA = 'SET_CAMS_PRODUCT_DATA';

// feed manager
export const SET_FEEDS_DATA = 'SET_FEEDS_DATA';

// repricer
export const SET_REPRICER_DATA = 'SET_REPRICER_DATA'

// yotpo
export const SET_YOTPO_DATA = 'SET_YOTPO_DATA';
