import React, { memo } from 'react';
import {
  shape, func, arrayOf, string, node, bool, number,
} from 'prop-types';
import { isEmpty } from 'lodash';
import SelectProductsButton from '../../../../components/SelectProductsButton';
import IntlMessages from '../../../../util/IntlMessages';

const SelectProducts = memo((props) => {
  const {
    operator, loading, disabled, search, onOpen, onClose, sourceList, listIn, listNin,
    listInIds, listNinIds, completingCategoryProductsList, fetchProductsByIdsStarted,
    fetchProductsByIds, onSearchSubmit, onSelectedListChange, onDialogSelectClick,
    completeProductList, leftPagination, maxSelected,
  } = props;

  const targetList = operator === 'in'
    ? listIn
    : listNin;
  const selectedProductsIds = operator === 'in'
    ? listInIds
    : listNinIds;

  const isExtraSearch = Boolean(
    search && search.suffix,
  );

  const selectProductsProps = {
    onOpen,
    onClose,
    sourceList,
    listIn,
    listNin,
    listInIds,
    listNinIds,
    completingCategoryProductsList,
    fetchProductsByIdsStarted,
    fetchProductsByIds,
    onSearchSubmit,
    onSelectedListChange,
    onDialogSelectClick,
    completeProductList,
    loading,
    leftPagination,
    search,
    maxSelected,
    filterOperatorKey: operator,
  };

  return (
    <div className="flex direction-column block">
      <SelectProductsButton
        {...selectProductsProps}
        className="min-w-md select-products-wrapper"
        disabled={disabled}
        badge={!isEmpty(selectedProductsIds)}
        targetList={targetList}
        maxWidth="xl"
        selectedProductsIds={selectedProductsIds}
        productsFilterKey={operator === 'in'
          ? 'productsByIdsForIn'
          : 'productsByIdsForNin'}
        search={{
          suffix: isExtraSearch ? search.suffix : null,
          className: isExtraSearch ? 'w-p-25' : 'block',
          queryLengthLimit: 500,
          errorMessage: 'text.errors.lessThan500Characters',
          useExtraSearchButton: isExtraSearch,
          searchButton: {
            color: 'primary',
            variant: 'outlined',
            value: <IntlMessages id="selectProductsDialog.search.button.search" />,
          },
        }}
        loading={loading}
      />
    </div>
  );
});

SelectProducts.propTypes = {
  search: shape().isRequired,
  sourceList: arrayOf(shape()).isRequired,
  listIn: arrayOf(shape()).isRequired,
  listNin: arrayOf(shape()).isRequired,
  listInIds: arrayOf(string).isRequired,
  listNinIds: arrayOf(string).isRequired,
  selectProductsSettings: shape(),

  loading: shape().isRequired,
  disabled: bool,
  operator: string.isRequired,
  completingCategoryProductsList: bool.isRequired,
  fetchProductsByIdsStarted: bool.isRequired,
  maxSelected: number,

  onOpen: func.isRequired,
  onClose: func.isRequired,
  fetchProductsByIds: func.isRequired,
  onSearchSubmit: func.isRequired,
  onSelectedListChange: func.isRequired,
  onDialogSelectClick: func.isRequired,
  completeProductList: func.isRequired,
  leftPagination: node.isRequired,
};

SelectProducts.defaultProps = {
  selectProductsSettings: {},
  disabled: false,
  maxSelected: null,
};

export default SelectProducts;
