
import React from 'react';
import {
  func, bool,
} from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import Checkbox from 'components/Checkbox';

const Controls = (props) => {
  const handleIsPromotedChange = (e) => {
    const { checked } = e.target;
    props.onIsPromotedChange(checked);
  };

  return (
    <div className="form-group-inline">
      <Checkbox
        key="isPromoted"
        size="small"
        disabled={props.disabled}
        className="checkbox-md mt-10"
        onChange={handleIsPromotedChange}
        checked={props.isPromoted}
        label={<IntlMessages id="facet.promoted" />}
        name="isPromoted"
      />
    </div>
  );
};


Controls.propTypes = {
  onIsPromotedChange: func.isRequired,
  isPromoted: bool,
  disabled: bool,
};

Controls.defaultProps = {
  isPromoted: false,
  disabled: false,
};


export default Controls;
