/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import {
  bool, func, shape,
} from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { Box, TableContainer, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import IntlMessages from "../../../../util/IntlMessages";

const JsonPopup = memo((props) => {
  const {
    isOpened,
    onClose,
    value,
  } = props;

  const closeJsonPopup = () => {
    onClose();
  };

  const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    closeButton: {}
  });

  const DialogTitle = withStyles(styles)((props) => {
    const {
      children, classes, onClose
    } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box className={classes.title}>
            <Typography variant="h6">
              { children }
            </Typography>
          </Box>
          <Box>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2)
    }
  }))(MuiDialogContent);

  return (
    <Dialog
      open={isOpened}
      aria-labelledby="customized-dialog-title"
      maxWidth="lg"
      onClose={closeJsonPopup}
    >
      <DialogTitle style={{position: 'absolute'}} id="customized-dialog-title" onClose={closeJsonPopup}>
        &nbsp;
      </DialogTitle>

      <DialogContent>
        <TableContainer className="feed-json-table-container">
          <Table className="feed-json-table">
            <TableBody>
              <TableRow>
                <TableCell className="valign-top" style={{fontWeight: 'bold'}}>
                  <IntlMessages id="widgets.time" />
                </TableCell>
                <TableCell style={{fontFamily: 'Courier'}}>
                  {value.time}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell className="valign-top" style={{backgroundColor: '#f3e2e8', fontWeight: 'bold'}}>
                  <IntlMessages id="widgets.error" />
                </TableCell>
                <TableCell style={{backgroundColor: '#f3e2e8', fontFamily: 'Courier'}}>
                  {value.error_message}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell className="valign-top" style={{fontWeight: 'bold'}}>
                  <IntlMessages id="widgets.trace" />
                </TableCell>
                <TableCell style={{fontFamily: 'Courier'}}>
                  {value.trace}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

      </DialogContent>

    </Dialog>
  );
});

JsonPopup.propTypes = {
  onClose: func.isRequired,
  isOpened: bool.isRequired,
  value: shape(),
};

JsonPopup.defaultProps = {
  value: {},
  isOpened: false,
};

export default JsonPopup;
