import React, { useState, useEffect } from 'react';
import {
  string, shape, arrayOf, func, bool,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import MerchantLevelItem from '../MerchantLevelItem';
import { generateParentProductData, getPublishPrice, moneyFormat } from '../../pages/Home/utils/productDataGenerator';

const SortableItem = SortableElement(props => <MerchantLevelItem {...props} />);

const getProfitLost = (product) => {
  const profitLost = (
    product.price.profit / product.price.cost
  ) * 100;
  return profitLost;
};

const SortableList = SortableContainer((props) => {
  const {
    merchants, disabled, defaultImage,
    searchFields, selectedProducts, onProductSelect, onEditClick,
    onUnlockAttributesClick, fetchActivityLogsByProductId, productsCommentsCount,
    productCommentsLoading, productComments, filterTarget, sortedAttributes, isEditMode,
    disableEdit, commentMessages, bulkDeleteProductLockedAttributesStarted,
    bulkDeleteProductLockedAttributesFinished, multipleUpdateUserActivityLogs,
    initialAttributesOptions, currency,
  } = props;

  return (
    <div className="product-list">
      <Grid container spacing={0} className="justify-center">
        {merchants && merchants.map((item, index) => {
          const productIsMerchant = item.type === 'merchant';
          const origPrice = item.price
            ? item.price.origPrice
            : null;
          const finalPrice = item.price
            ? item.price.finalPrice
            : null;
          const minPrice = item.price
            ? item.price.minPrice
            : null;
          const items = item.stock
            ? item.stock.qty
            : null;
          const variationImages = item.variationImages
            ? item.variationImages[0]
            : defaultImage;

          const imgData = (item.images && item.images.length) > 0 ? item.images[0] : variationImages;
          const profitLost = productIsMerchant ? getProfitLost(item) : null;

          const originPublishPrice = productIsMerchant
            ? getPublishPrice(item.price.finalPrice, currency.item.value, item.isDomestic)
            : 0;

          let value = filterTarget === 'parent'
            ? {
              productId: item.id,
              merchantId: item.merchantId,
              imgData,
              items,
              pubAopi: item.pubAopi || 'N/A',
              origPrice: moneyFormat(origPrice, '$'),
              finalPrice: moneyFormat(finalPrice, '$'),
            }
            : generateParentProductData(
              item,
              defaultImage,
              initialAttributesOptions,
              sortedAttributes,
              currency,
            );

          let currencySymbol = currency.item.symbols;
          if (currencySymbol === 'ILS') {
            currencySymbol = '₪';
          }

          value = !isEmpty(item.price) && item.type === 'merchant'
            ? ({
              ...value,
              minPrice: moneyFormat(minPrice, '$'),
              msrp: moneyFormat(item.price.msrp, '$'),
              netCost: moneyFormat(item.price.cost, '$'),
              markup: moneyFormat(item.price.profit, '$'),
              originPublishPrice: moneyFormat(Math.ceil(originPublishPrice), currencySymbol),
              publishPrice: item.frontendPrice
                ? moneyFormat(Math.ceil(item.frontendPrice.publishPrice), currencySymbol)
                : moneyFormat(Math.ceil(originPublishPrice), currencySymbol),
              profitLost: `${profitLost.toFixed(2)}% ${profitLost > 0 ? '↑' : '↓'}`,
              shippingPrice: moneyFormat(item.price.shippingCost, '$'),
              isDomestic: item.isDomestic === true ? 'Yes' : 'No',
              isVisibleByCategory: item.isVisibleByCategory === true ? 'Yes' : 'No',
            })
            : value;

          const rowClassName = profitLost > 0 ? 'tr-success' : 'tr-danger';

          return (
            <SortableItem
              key={item.id}
              className={profitLost ? rowClassName : ''}
              index={index}
              merchantValue={value}
              productList={merchants}
              itemsCount={merchants.length}
              disabled={disabled}
              merchantProductItem={item}
              hasMerchantComments={productsCommentsCount.some(pcc => pcc.id === item.id)}
              searchFields={searchFields}
              selectedProducts={selectedProducts}
              onProductSelect={onProductSelect}
              defaultImage={defaultImage}
              onUnlockAttributesClick={onUnlockAttributesClick}
              fetchActivityLogsByProductId={fetchActivityLogsByProductId}
              onEditClick={onEditClick}
              productCommentsLoading={productCommentsLoading}
              productComments={productComments}
              isEditMode={isEditMode}
              disableEdit={disableEdit}
              commentMessages={commentMessages}
              bulkDeleteProductLockedAttributesStarted={bulkDeleteProductLockedAttributesStarted}
              bulkDeleteProductLockedAttributesFinished={bulkDeleteProductLockedAttributesFinished}
              multipleUpdateUserActivityLogs={multipleUpdateUserActivityLogs}
              urlKey={item.urlKey}
            />
          );
        })}
      </Grid>
    </div>
  );
});

const MerchantLevelList = (props) => {
  const {
    className, merchants, searchFields,
    selectedProducts, onProductSelect, defaultImage, onUnlockAttributesClick,
    fetchActivityLogsByProductId, onEditClick, productsCommentsCount,
    productCommentsLoading, productComments, filterTarget, sortedAttributes,
    isEditMode, disableEdit, commentMessages, bulkDeleteProductLockedAttributesStarted,
    bulkDeleteProductLockedAttributesFinished, multipleUpdateUserActivityLogs,
    initialAttributesOptions,
  } = props;
  const [localItems, setItems] = useState(merchants);

  useEffect(
    () => setItems(merchants),
    [merchants],
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = arrayMove(localItems, oldIndex, newIndex);
    setItems(newItems);
  };

  return (
    <div className={`dnd-wrapper level-merchant product list ${className}`}>
      <SortableList
        axis="y"
        useDragHandle
        productList={localItems}
        onSortEnd={onSortEnd}
        searchFields={searchFields}
        selectedProducts={selectedProducts}
        onProductSelect={onProductSelect}
        defaultImage={defaultImage}
        onUnlockAttributesClick={onUnlockAttributesClick}
        fetchActivityLogsByProductId={fetchActivityLogsByProductId}
        onEditClick={onEditClick}
        merchants={merchants}
        productsCommentsCount={productsCommentsCount}
        productCommentsLoading={productCommentsLoading}
        productComments={productComments}
        filterTarget={filterTarget}
        sortedAttributes={sortedAttributes}
        isEditMode={isEditMode}
        disableEdit={disableEdit}
        commentMessages={commentMessages}
        bulkDeleteProductLockedAttributesStarted={bulkDeleteProductLockedAttributesStarted}
        bulkDeleteProductLockedAttributesFinished={bulkDeleteProductLockedAttributesFinished}
        multipleUpdateUserActivityLogs={multipleUpdateUserActivityLogs}
        initialAttributesOptions={initialAttributesOptions}
        currency={props.currency}
      />
    </div>
  );
};

MerchantLevelList.propTypes = {
  productComments: arrayOf(shape()).isRequired,
  productsCommentsCount: arrayOf(shape()),
  selectedProducts: arrayOf(shape()),
  merchants: arrayOf(shape()),
  searchFields: arrayOf(string).isRequired,
  sortedAttributes: arrayOf(shape()).isRequired,
  commentMessages: arrayOf(shape()).isRequired,
  initialAttributesOptions: arrayOf(shape()).isRequired,
  className: string,
  onProductSelect: func,
  defaultImage: string.isRequired,
  onUnlockAttributesClick: func.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  multipleUpdateUserActivityLogs: func.isRequired,
  onEditClick: func.isRequired,
  productCommentsLoading: bool.isRequired,
  filterTarget: string.isRequired,
  isEditMode: bool.isRequired,
  disableEdit: bool,
  bulkDeleteProductLockedAttributesStarted: bool.isRequired,
  bulkDeleteProductLockedAttributesFinished: bool.isRequired,
  currency: shape().isRequired,
};

MerchantLevelList.defaultProps = {
  className: '',
  onProductSelect: null,
  selectedProducts: [],
  merchants: [],
  productsCommentsCount: [],
  disableEdit: false,
};

export default MerchantLevelList;
