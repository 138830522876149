/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import {
  shape, func, arrayOf, bool,
} from 'prop-types';
import Popper from '@material-ui/core/Popover';

import PopoverContent from './PopoverContent';

const InfoPopup = memo((props) => {
  const {
    anchor, categories, anchorOrigin, transformOrigin, handleClosePopper,
    loading,
  } = props;

  const isOpen = !!anchor;

  return (
    <Popper
      open={isOpen}
      anchorEl={anchor}
      onClose={handleClosePopper}
      className="no-event"
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <PopoverContent
        categories={categories}
        loading={loading}
      />
    </Popper>
  );
});

InfoPopup.propTypes = {
  anchor: shape(),
  handleClosePopper: func,
  anchorOrigin: shape(),
  transformOrigin: shape(),
  categories: arrayOf(shape()).isRequired,
  loading: bool,
};

InfoPopup.defaultProps = {
  anchor: null,
  handleClosePopper: null,
  anchorOrigin: {},
  transformOrigin: {},
  loading: false,
};

export default InfoPopup;
