import firebase from 'firebase/app';
import { camelizeKeys } from 'humps';

import RestActions from '../util/rest/actions';

import { previewFilterAttributesPath } from '../util/paths';
import { showErrorNotification } from '../util/api';

const restPreviewFilterAttributeActions = new RestActions('preview_filter_attribute');

export const fetchPreviewFilterAttributes = () => async (dispatch) => {
  dispatch(restPreviewFilterAttributeActions.fetchAllStart());

  let previewFilterAttributes;
  try {
    previewFilterAttributes = await firebase.database().ref(
      previewFilterAttributesPath,
    ).once('value');
  } catch (error) {
    showErrorNotification(error, 'Firebase');
    dispatch(restPreviewFilterAttributeActions.fetchAllFinished({ hasError: true }));
  }
  const previewFilterAttributesArr = previewFilterAttributes.val();
  dispatch(restPreviewFilterAttributeActions.fetchAllFinished(camelizeKeys(
    previewFilterAttributesArr,
  )));
};

export default {
  fetchPreviewFilterAttributes,
};
