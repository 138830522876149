import React, { Component, Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {
  arrayOf, shape, string, func, node, oneOfType, bool,
} from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { cloneDeep } from 'lodash';

import IntlMessages from '../../util/IntlMessages';
import arraysAreEqual from '../../util/arraysAreEqual';

export default class Transfer extends Component {
  static propTypes = {
    sourceList: arrayOf(shape()),
    targetList: arrayOf(shape()),
    className: string,
    targetTitle: oneOfType([string, node]),
    sourceTitle: oneOfType([string, node]),
    onChange: func,
    tooltip: shape({
      placement: string,
    }),
    selectAll: bool,
  };

  static defaultProps = {
    onChange: null,
    sourceList: [],
    targetList: [],
    sourceTitle: '',
    targetTitle: '',
    className: '',
    tooltip: null,
    selectAll: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      targetList: cloneDeep([...props.targetList]),
      sourceList: props.sourceList,
    };
  }

  componentWillUnmount() {
    this.setState({
      targetList: [],
      sourceList: [],
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { sourceList } = nextProps;
    if (!arraysAreEqual(sourceList, prevState.sourceList)) {
      return {
        sourceList,
      };
    }

    return null;
  }

  handleOnSourceItemClick = async (item) => {
    const { targetList } = this.state;
    let newTargetList = targetList;

    if (!targetList.find(el => el.id === item.id)) {
      await this.setState((prevState) => {
        newTargetList = [...prevState.targetList, item];
        return {
          targetList: [...prevState.targetList, item],
        };
      });
    }
    if (this.props.onChange) {
      this.props.onChange({ targetList: newTargetList });
    }
  }

  handleOnTargetItemClick = (item) => {
    const { sourceList, targetList } = this.state;
    if (targetList.find(el => el.id === item.id)) {
      const index = targetList.indexOf(item);
      targetList.splice(index, 1);
    }

    if (!sourceList.find(sItem => sItem.id === item.id)) {
      sourceList.push(item);
    }

    this.setState({ targetList, sourceList });

    if (this.props.onChange) {
      this.props.onChange({ targetList });
    }
  }

  handleOnSelectAllClick = () => {
    const { targetList, sourceList } = this.state;

    const filteredSourceItems = sourceList.filter(sl => !targetList.find(tl => tl.id === sl.id));

    const targetListToUpdate = [...targetList, ...filteredSourceItems];

    this.setState({ targetList: targetListToUpdate });

    if (this.props.onChange) {
      this.props.onChange({ targetList: targetListToUpdate });
    }
  }

  renderSourceItem = ({ dataSource: { targetList, sItem } }) => (
    <Fragment>
      <span className="transfer-list-item-label">
        <span className="transfer-list-item-label-container">
          {sItem.title || sItem.label || (sItem.name ? sItem.name.en : '')}
        </span>
        {sItem.id && <div className="transfer-list-item-sublabel">{`(${sItem.amazonNodeId})`}</div>}
      </span>
      {!targetList.find(tItem => tItem.id === sItem.id)
        ? (
          <IconButton
            aria-label="Select"
            className={`btn-ico-sm text-primary  ml-10${targetList.find(tItem => tItem.id === sItem.id) ? 'item-disabled' : ''}`}
            onClick={
              () => this.handleOnSourceItemClick(sItem)
            }
          >
            <i className="ti-plus" />
          </IconButton>
        )
        : (
          <IconButton
            aria-label="Access"
            className={`btn-ico-sm text-success ml-10 ${targetList.find(tItem => tItem.id === sItem.id) ? 'item-disabled' : ''}`}
          >
            <i className="ti-check" />
          </IconButton>
        )
      }
    </Fragment>
  )

  renderTargetItem = ({ dataSource: { tItem } }) => (
    <Fragment>
      <span className="transfer-list-item-label">
        <span className="transfer-list-item-label-container">
          {tItem.title || tItem.label || (tItem.name ? tItem.name.en : '')}
        </span>
        {tItem.id && <div className="transfer-list-item-sublabel">{`(${tItem.amazonNodeId})`}</div>}
      </span>
      <IconButton
        aria-label="Delete"
        className="btn-ico-sm ml-10 text-danger"
        onClick={
          () => this.handleOnTargetItemClick(tItem)
        }
      >
        <i className="ti-close" />
      </IconButton>
    </Fragment>
  )

  render() {
    const {
      className, sourceTitle, targetTitle, tooltip, selectAll,
    } = this.props;
    const { sourceList, targetList } = this.state;
    return (
      <div className={`transfer-wrapper ${className}`}>
        <div className="source-list-wrapper">
          {
            sourceTitle && (
              <div className="source-list-title">
                {sourceTitle}
                {
                  selectAll && (
                    <Button
                      onClick={this.handleOnSelectAllClick}
                      color="primary"
                      className="btn-xs float-right"
                    >
                      <IntlMessages id="label.selectAllCategories" />
                    </Button>
                  )
                }
              </div>
            )
          }
          <ul className="transfer-list transfer-source-list">
            {sourceList.map(sItem => (
              <li
                key={sItem.id || sItem.value}
                className={`transfer-list-item ${targetList.find(tItem => tItem.id === sItem.id) ? 'transfer-list-item-disabled' : ''}`}
              >
                {
                  tooltip && tooltip.placement
                    ? (
                      <Tooltip
                        title={sItem.pathByName.en}
                        placement={tooltip.placement}
                      >
                        <div className="wrapper">
                          <this.renderSourceItem dataSource={{ targetList, sItem }} />
                        </div>
                      </Tooltip>
                    )

                    : <this.renderSourceItem dataSource={{ targetList, sItem }} />
                }
              </li>
            ))}
          </ul>
        </div>
        <div className="target-list-wrapper">
          {targetTitle && (
            <div className="target-list-title">
              {targetTitle}
              {` (${targetList.length})`}
            </div>
          )}
          <ul className="transfer-list transfer-target-list">
            {targetList && targetList.length > 0 && targetList.map(tItem => (
              <li key={tItem.id || tItem.value} className="transfer-list-item">
                {
                  tooltip && tooltip.placement
                    ? (
                      <Tooltip
                        title={tItem.pathByName.en}
                        placement={tooltip.placement}
                      >
                        <div className="wrapper">
                          <this.renderTargetItem dataSource={{ tItem }} />
                        </div>
                      </Tooltip>
                    )

                    : <this.renderTargetItem dataSource={{ tItem }} />
                }
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
