import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const pimListSelector = state => state.list;
const pimItemSelector = state => state.item;
const pimRootSelector = state => state;

const mapProductsImages = (images) => {
  if (!images) return [];
  return ([
    ...images.map((img) => {
      const params = '?auto=format%2Ccompress&bg=transparent&fill=solid&fit=fillmax&h=220&trim=auto&trimsd=1&w=auto';
      return {
        ...img,
        url: `${img.url}${params}`,
      };
    }),
  ]);
};

const listSelector = createSelector(
  pimListSelector,
  list => (!isEmpty(list) ? [...list].reduce((acc, current) => {
    acc.push({
      ...current,
      images: mapProductsImages(current.images),
      variants: !isEmpty(current.variants)
        ? current.variants.map(v => ({
          ...v,
          images: mapProductsImages(v.images),
          merchants: !isEmpty(v.merchants)
            ? v.merchants.map(m => ({
              ...m,
              parentImages: mapProductsImages(m.parentImages),
              variationImages: mapProductsImages(m.variationImages),
            }))
            : [],
        }))
        : [],
    });
    return acc;
  }, []) : []),
);

const itemSelector = createSelector(
  pimItemSelector,
  item => (!isEmpty(item) ? ({
    ...item,
    images: mapProductsImages(item.images),
  }) : {}),
);

export const pimSelector = createSelector(
  listSelector,
  itemSelector,
  pimRootSelector,
  (list, item, state) => ({
    ...state, ...list, item,
  }),
);

export default {
  pimSelector,
};
