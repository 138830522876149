import React from 'react';
import {
  bool, node, func, string, shape, oneOfType,
} from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const FormDialog = (props) => {
  const {
    open,
    title,
    onClose,
    onSubmit,
    children,
    closeButtonTitle,
    submitButtonTitle,
    maxWidth,
    className,
    hideCloseButton,
    actionClassName,
    contentClassName,
    buttons,
    type,
  } = props;

  const submitStyle = (type === 'pimOptions') ? { background: '#5d92f2' } : {};
  const closeStyle = (type === 'pimOptions') ? { background: '#6286c6' } : {};

  const onSubmitClick = buttons.submit.onClick || onSubmit;
  const onCloseClick = buttons.close.onClick || onClose;
  return (
    <Dialog
      className={className}
      open={open}
      onClose={onCloseClick}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={maxWidth}
    >
      {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
      <DialogContent className={`dialog-content ${contentClassName}`}>{children}</DialogContent>
      {
        (onCloseClick || onSubmitClick) && (
          <DialogActions className={`dialog-actions ${actionClassName}`}>
            {
              onSubmitClick && (
                <Button
                  variant={buttons.submit.variant}
                  onClick={onSubmitClick}
                  color={buttons.submit.color}
                  style={submitStyle}
                  className={`${buttons.submit.className}`}
                >
                  {buttons.submit.title || submitButtonTitle}
                </Button>
              )}
            {
              onCloseClick && !hideCloseButton && (
                <Button
                  variant={buttons.close.variant}
                  onClick={onCloseClick}
                  color={buttons.close.color}
                  style={closeStyle}
                  className={`text-white ${buttons.submit.className}`}
                >
                  {buttons.close.title || closeButtonTitle}
                </Button>
              )}
          </DialogActions>
        )
      }
    </Dialog>
  );
};

FormDialog.propTypes = {
  open: bool.isRequired,
  children: node.isRequired,
  onClose: func,
  onSubmit: func,
  title: node,
  closeButtonTitle: node,
  contentClassName: string,
  actionClassName: string,
  submitButtonTitle: node,
  maxWidth: string,
  className: string,
  hideCloseButton: bool,
  buttons: shape({
    submit: shape({
      variant: string,
      title: oneOfType([node, string]),
      color: string,
      className: string,
      onClick: func,
    }),
    close: shape({
      variant: string,
      title: oneOfType([node, string]),
      color: string,
      className: string,
      onClick: func,
    }),
  }),
  type: string,
};

FormDialog.defaultProps = {
  title: null,
  onSubmit: null,
  onClose: null,
  contentClassName: '',
  actionClassName: '',
  closeButtonTitle: '',
  submitButtonTitle: '',
  maxWidth: 'sm',
  className: '',
  hideCloseButton: false,
  buttons: {
    submit: {
      variant: 'contained',
      className: 'btn-info text-white',
      color: '',
      onClick: null,
    },
    close: {
      variant: 'contained',
      className: '',
      color: 'primary',
      onClick: null,
    },
  },
  type: 'default',
};

export default FormDialog;
