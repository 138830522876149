import React, { Fragment, memo } from 'react';
import {
  func, bool, string, shape, number,
} from 'prop-types';

import MatButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';

import IntlMessages from '../../../../../../../../../util/IntlMessages';

export const ExportDialogComponent = memo(props => (
  <Dialog
    open={props.showDialog}
    aria-labelledby="simple-dialog-title"
    disableBackdropClick
  >
    <DialogTitle id="simple-dialog-title">
      {!props.generatedNewXLSX
        && (props.totalProductsCount || props.hiddenProductsExist)
        && <IntlMessages id="previewPage.exportDialog.headerInProgress" />}
      {props.generatedNewXLSX && <IntlMessages id="previewPage.exportDialog.headerFinished" />}
      {!props.totalProductsCount && !props.hiddenProductsExist && <IntlMessages id="previewPage.exportDialog.exportError" />}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {!props.generatedNewXLSX
          && (props.totalProductsCount || props.hiddenProductsExist)
          && <IntlMessages id="previewPage.exportDialog.subheaderInProgress" />}
        {props.generatedNewXLSX && <IntlMessages id="previewPage.exportDialog.subheaderFinished" />}
      </DialogContentText>
      <div className="relative export-dialog-content flex justify-center items-center direction-column">
        {!props.totalProductsCount && !props.hiddenProductsExist
          && <IntlMessages id="previewPage.exportDialog.noProductsToExport" />}
        {props.generatingNewXLSX
          && (props.totalProductsCount || props.hiddenProductsExist) && (
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className="progress-warning loader bottom"
            size={40}
            thickness={4}
          />
        )}
        {props.generatedNewXLSX && (
          <Fragment>
            <i className="ti-file pb-10" />
            <span className="export-filename">{`${props.xlsxDocument.fileName}.xlsx`}</span>
          </Fragment>
        )}
      </div>
    </DialogContent>
    <DialogActions>
      <MatButton
        variant="contained"
        color="primary"
        className="text-white"
        onClick={props.handleDownloadClick}
        disabled={!props.generatedNewXLSX}
      >
        <IntlMessages id="previewPage.exportDialog.downloadButton" />
      </MatButton>
      <MatButton
        variant="contained"
        color="secondary"
        className="text-white"
        onClick={props.handleCloseExportDialog}
      >
        <IntlMessages id="previewPage.exportDialog.closeButton" />
      </MatButton>
    </DialogActions>
  </Dialog>
));

ExportDialogComponent.propTypes = {
  showDialog: bool,
  generatedNewXLSX: bool,
  generatingNewXLSX: bool,
  handleCloseExportDialog: func,
  handleDownloadClick: func,
  xlsxDocument: shape({
    fileName: string,
  }),
  totalProductsCount: number,
  hiddenProductsExist: bool.isRequired,
};

ExportDialogComponent.defaultProps = {
  showDialog: false,
  generatedNewXLSX: false,
  generatingNewXLSX: false,
  handleCloseExportDialog: null,
  handleDownloadClick: null,
  xlsxDocument: {},
  totalProductsCount: 0,
};

export default ExportDialogComponent;
