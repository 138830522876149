export const emptySearchState = {
  parent: [],
  variation: [],
  merchant: [],
  feed: [],
  rule: [],
  repricing: [],
  mapper: [],
  activityLog: [],
  cams: [],
  repricer: []
}

export const getAllGlobalFiltersPayload = (globalFiltersPayload) => {
  const storedSearchFilters = sessionStorage.getItem('pim.searchFilters');
  const parsedStoredSearchFilters = JSON.parse(storedSearchFilters) || emptySearchState;

  return {
    parent: (globalFiltersPayload.parent && globalFiltersPayload.parent.length) > 0
      ? globalFiltersPayload.parent
      : parsedStoredSearchFilters.parent,
    variation: (globalFiltersPayload.variation && globalFiltersPayload.variation.length) > 0
      ? globalFiltersPayload.variation
      : parsedStoredSearchFilters.variation,
    merchant: (globalFiltersPayload.merchant && globalFiltersPayload.merchant.length) > 0
      ? globalFiltersPayload.merchant
      : parsedStoredSearchFilters.merchant,
    feed: (globalFiltersPayload.feed && globalFiltersPayload.feed.length) > 0
      ? globalFiltersPayload.feed
      : parsedStoredSearchFilters.feed,
    rule: (globalFiltersPayload.rule && globalFiltersPayload.rule.length) > 0
      ? globalFiltersPayload.rule
      : parsedStoredSearchFilters.rule,
    repricing: (globalFiltersPayload.repricing && globalFiltersPayload.repricing.length) > 0
      ? globalFiltersPayload.repricing
      : parsedStoredSearchFilters.repricing,
    mapper: (globalFiltersPayload.mapper && globalFiltersPayload.mapper.length) > 0
      ? globalFiltersPayload.mapper
      : parsedStoredSearchFilters.mapper,
    activityLog: (globalFiltersPayload.activityLog && globalFiltersPayload.activityLog.length) > 0
      ? globalFiltersPayload.activityLog
      : parsedStoredSearchFilters.activityLog,
    cams: (globalFiltersPayload.cams && globalFiltersPayload.cams.length) > 0
      ? globalFiltersPayload.cams
      : parsedStoredSearchFilters.cams,
    repricer: (globalFiltersPayload.repricer && globalFiltersPayload.repricer.length) > 0
      ? globalFiltersPayload.repricer
      : parsedStoredSearchFilters.repricer,
  };
};

export const getProductIdFromURL = () => {
  const locationSearch = document.location.search;

  return (locationSearch && locationSearch.indexOf('?product_id=') !== -1)
    ? locationSearch.replace('?product_id=', '')
    : null;
};

export const getFilteredDefaultAttributes = ({filterTarget, pimModeAttributes, sortedSearchFields}) => {
  const filteredDefaultPimAttributes = [];
  if ((
    filterTarget === 'feed'
    || filterTarget === 'rule'
    || filterTarget === 'repricing'
    || filterTarget === 'mapper'
    || filterTarget === 'activityLog'
    || filterTarget === 'cams'
    || filterTarget === 'repricer'
  ) && sortedSearchFields) {
    const sortedSearchFieldsKeys = Object.keys(sortedSearchFields);

    for (let i = 0; i < sortedSearchFieldsKeys.length; i++) {
      const field = sortedSearchFieldsKeys[i];
      const value = sortedSearchFields[field];

      if (value.searchable === true) {
        let elementType = 'text';
        let options = [];

        if (value.type === 'select' && value.options) {
          elementType = 'select';
          const valueOptionsKeys = Object.keys(value.options);
          for (let j = 0; j < valueOptionsKeys.length; j++) {
            const option = {
              label: value.options[valueOptionsKeys[j]],
              value: valueOptionsKeys[j],
            }
            options.push(option);
          }
        }

        if (value.type === 'checkbox' || value.type === 'flag') {
          elementType = 'select';
          options = [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ];
        }

        if (value.type === 'truecheckbox') {
          elementType = 'select';
          options = [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ];
        }

        if (value.type === 'date') {
          elementType = 'datePicker';
        }

        if (value.type === 'number' || value.type === 'price') {
          elementType = 'number';
        }

        const filteredDefaultPimAttribute = {
          code: value.field ? value.field : field,
          elementType: elementType,
          options: options,
          enabled: true,
          isCustomAttribute: true,
          isFilterable: true,
          key: field,
          label: value.name,
          order: value.order,
          sortCode: value.field ? value.field : field,
          value: value.field ? value.field : field,
        };

        filteredDefaultPimAttributes.push(filteredDefaultPimAttribute);
      }
    }
  } else if (pimModeAttributes[filterTarget]) {
    return pimModeAttributes[filterTarget].filter(a => a.isFilterable);
  }

  return filteredDefaultPimAttributes;
};

const getFilterOptions = ({ filter, storedSearchFields, fullText, scroll, condition, itemsPerPage, fullTextFields }) => {
  if (fullText && fullText.value) {
    filter.push({
      condition: 'or',
      group: fullTextFields,
    });
  }

  return {
    limit: itemsPerPage,
    offset: scroll ? scroll.offset : 0,
    filter: filter.length > 0 ? filter : null,
    sort: condition ? condition.sort : null,
    fields: storedSearchFields,
  };
};

const getTimeFieldsFilter = (filter, fields) => {
  const timeFieldsFilter = [];
  for (let i = 0; i < filter.length; i++) {
    const group = filter[i].group ? filter[i].group[0] : [];
    const field = group.field.toString();
    const operator = group.operator.toString();

    for (let j = 0; j < fields.length; j++) {
      if ((field === fields[i]) && operator === 'eq') {
        const dateValue = group.value.toString().substr(0, 10);
        timeFieldsFilter[i] = {
          condition: filter[i].condition,
          group: [
            { field: field, value: dateValue + ' 00:00:00', operator: 'gte' },
            { field: field, value: dateValue + ' 23:59:59', operator: 'lte' },
          ]
        };
      } else if (field !== 'type') {
        timeFieldsFilter[i] = filter[i];
      }
    }
  }

  return timeFieldsFilter;
};

export const getFeedsFilterOptions = ({ filter, storedSearchFields, fullText, scroll, condition, itemsPerPage }) => {
  const modifiedFilter = getTimeFieldsFilter(filter, [
    'last_executed_at', 'last_finished_at', 'created_at', 'updated_at'
  ]);
  for (let i = 0; i < modifiedFilter.length; i++) {
    const group = modifiedFilter[i].group ? modifiedFilter[i].group[0] : [];
    const field = group.field.toString();
    const operator = group.operator.toString();
    if (field === 'active') {
      const activeValue = parseInt(group.value) === 1;
      modifiedFilter[i] = {
        condition: modifiedFilter[i].condition,
        group: [
          { field: field, value: activeValue, operator: operator },
        ]
      };
    }
  }

  const fullTextFields = [
    { field: 'id', value: fullText.value, operator: 'eq' },
    { field: 'code', value: '*' + fullText.value + '*', operator: 'like' },
  ];

  return getFilterOptions({
    filter: modifiedFilter, storedSearchFields, fullText, scroll, condition, itemsPerPage, fullTextFields
  });
};

export const getRulesFilterOptions = ({ filter, storedSearchFields, fullText, scroll, condition, itemsPerPage }) => {
  const modifiedFilter = getTimeFieldsFilter(filter, [
    'active_from', 'active_to', 'created_at', 'updated_at'
  ]);
  for (let i = 0; i < modifiedFilter.length; i++) {
    const group = modifiedFilter[i].group ? modifiedFilter[i].group[0] : [];
    const field = group.field.toString();
    const operator = group.operator.toString();
    if (field === 'discard_subsequent_rules') {
      modifiedFilter[i] = {
        condition: modifiedFilter[i].condition,
        group: [
          { field: field, value: !!group.value, operator: operator },
        ]
      };
    }
  }

  const fullTextFields = [
    { field: 'id', value: fullText.value, operator: 'eq' },
    { field: 'name', value: '*' + fullText.value + '*', operator: 'like' },
    { field: 'description', value: '*' + fullText.value + '*', operator: 'like' },
  ];

  return getFilterOptions({
    filter: modifiedFilter, storedSearchFields, fullText, scroll, condition, itemsPerPage, fullTextFields
  });
};

export const getRepricingFilterOptions = (
  { filter, storedSearchFields, fullText, scroll, condition, itemsPerPage }
) => {
  const modifiedFilter = getTimeFieldsFilter(filter, ['created_at', 'updated_at']);

  const fullTextFields = [
    { field: 'id', value: fullText.value, operator: 'eq' },
    { field: 'pub_aopi', value: fullText.value, operator: 'eq' },
    { field: 'title.en', value: '*' + fullText.value + '*', operator: 'like' },
  ];

  return getFilterOptions({
    filter: modifiedFilter, storedSearchFields, fullText, scroll, condition, itemsPerPage, fullTextFields
  });
};

export const getMapperFilterOptions = ({ filter, storedSearchFields, fullText, scroll, condition, itemsPerPage }) => {
  const modifiedFilter = getTimeFieldsFilter(filter, ['created_at', 'updated_at']);

  const fullTextFields = [
    { field: 'id', value: fullText.value, operator: 'eq' },
    { field: 'model_id', value: fullText.value, operator: 'eq' },
    { field: 'title', value: '%' + fullText.value + '%', operator: 'like' },
  ];

  for (let i = 0; i < modifiedFilter.length; i++) {
    for (let j = 0; j < modifiedFilter[i].group.length; j++) {
      if (modifiedFilter[i].group[j].operator === 'like') {
        modifiedFilter[i].group[j].value = modifiedFilter[i].group[j].value.replace('*', '%').replace('*', '%');
      }
    }
  }

  return getFilterOptions({
    filter: modifiedFilter, storedSearchFields, fullText, scroll, condition, itemsPerPage, fullTextFields
  });
};

export const getActivityLogFilterOptions = (
  { filter, predefinedFilter, storedSearchFields, fullText, scroll, condition, itemsPerPage }
) => {
  const modifiedFilter = getTimeFieldsFilter(filter, ['created_at']);

  const fullTextFields = [
    { field: 'id', value: fullText.value, operator: 'eq' },
    { field: 'comment', value: '*' + fullText.value + '*', operator: 'like' },
  ];

  if (predefinedFilter && predefinedFilter[0] === 'manual_changes_only') {
    modifiedFilter.push({ group: [ { field: 'client_id', value: 'pim_user', operator: 'eq' } ] });
  }
  if (predefinedFilter && predefinedFilter[0] === 'today_manual_changes_only') {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const dateValue = {
      from: yyyy + '-' + mm + '-' + dd + ' 00:00:00',
      to: yyyy + '-' + mm + '-' + dd + ' 23:59:59',
    };

    modifiedFilter.push(
      { group: [
        { field: 'created_at', operator: 'between', value: dateValue },
        { field: 'client_id', value: 'pim_user', operator: 'eq' }
      ] }
    );
  }
  if (predefinedFilter && predefinedFilter[0] === 'system_changes_only') {
    modifiedFilter.push(
      { group: [ { field: 'client_id', value: 'pim_user', operator: 'ne' } ] }
    );
  }

  return getFilterOptions({
    filter: modifiedFilter, storedSearchFields, fullText, scroll, condition, itemsPerPage, fullTextFields
  });
};

export const getProductFilterOptions = (filterOptions, realFilterTarget) => {
  const modifiedFilter = [];
  for (let i = 0; i < filterOptions.filter.length; i++) {
    const group = filterOptions.filter[i].group ? filterOptions.filter[i].group[0] : [];
    const field = group.field.toString();
    if (field === 'type') {
      modifiedFilter[i] = {
        condition: filterOptions.filter[i].condition,
        group: [
          { field: field, value: realFilterTarget, operator: 'eq' },
        ]
      };
    } else {
      modifiedFilter[i] = filterOptions.filter[i];
    }
  }
  filterOptions.filter = modifiedFilter;

  return filterOptions;
};

const getCamsQuery = (filter) => {
  const query = { query: { bool: { filter: [], should: [], must_not: [] } } }

  for (let i = 0; i < filter.length; i++) {
    const groups = filter[i].group;
    const condition = filter[i].condition === 'and' ? 'filter' : 'should';
    if (groups) {
      for (let j = 0; j < groups.length; j++) {
        if (groups[j]['field'] === 'info.original_sku' || groups[j]['field'] === 'original_sku') {
          groups[j]['field'] = 'info.original_sku.keyword';
        }
        if (groups[j]['operator'] === 'eq') {
          query['query']['bool'][condition][j] = { term: {} };
          if (groups[j]['field'] === 'error_status') {
            const errorField = 'product_status.' + groups[j]['value'] + '.status';
            query['query']['bool'][condition][j]['term'][errorField] = 'error';
          } else {
            query['query']['bool'][condition][j]['term'][groups[j]['field']] = groups[j]['value'];
          }
        }
        if (groups[j]['operator'] === 'neq') {
          query['query']['bool']['must_not'][j] = { term: {} };
          if (groups[j]['field'] === 'error_status') {
            const errorField = 'product_status.' + groups[j]['value'] + '.status';
            query['query']['bool']['must_not'][j]['term'][errorField] = 'error';
          } else {
            query['query']['bool']['must_not'][j]['term'][groups[j]['field']] = groups[j]['value'];
          }
        }
      }
    }
  }

  return query;
};

export const getCamsFilterOptions = ({ filter, storedSearchFields, fullText, scroll, condition, itemsPerPage }) => {
  const modifiedFilter = getTimeFieldsFilter(filter, ['date']);
  const query = getCamsQuery(modifiedFilter);

  query.from = 0;
  query.size = itemsPerPage;
  query.sort = [
    { date : { order : 'desc'} },
  ];

  return query;
};

export const getRepricerFilterOptions = (
  { filter, predefinedFilter, storedSearchFields, fullText, scroll, condition, itemsPerPage }
) => {
  const modifiedFilter = getTimeFieldsFilter(filter, [
    'price_enrichment.competitors.zap.updated_at',
    'price_enrichment.competitors.ksp.updated_at',
  ]);

  const storedSearchFilters = sessionStorage.getItem('pim.searchFilters');
  const parsedStoredSearchFilters = JSON.parse(storedSearchFilters) || emptySearchState;
  const categoriesIds = [];
  if (parsedStoredSearchFilters.repricer.length > 0) {
    for (let i = 0; i < parsedStoredSearchFilters.repricer.length; i++) {
      if (parsedStoredSearchFilters.repricer[i].selectedTreeCategories) {
        for (let j = 0; j < parsedStoredSearchFilters.repricer[i].selectedTreeCategories.length; j++) {
          const catId = parsedStoredSearchFilters.repricer[i].selectedTreeCategories[j].id;
          if (catId && categoriesIds.indexOf(catId) === -1) {
            categoriesIds.push(parsedStoredSearchFilters.repricer[i].selectedTreeCategories[j].id);
          }
        }
      }
    }
  }
  if (categoriesIds.length > 0) {
    modifiedFilter.push({ group: [ { field: 'categories', operator: 'in', value: categoriesIds } ] });
  }

  if (predefinedFilter && predefinedFilter[0] === 'we_mapped_but_out_of_stock') {
    modifiedFilter.push({
      group: [
        { field: 'stock_status', operator: 'eq', value: 'out_of_stock' }
      ]
    });
  }

  if (predefinedFilter && predefinedFilter[0] === 'we_mapped_but_it_was_hidden_by_repricer') {
    modifiedFilter.push({
      group: [
        { field: 'visibility_status', operator: 'eq', value: 'hidden_by_repricing' }
      ]
    });
  }

  if (predefinedFilter && predefinedFilter[0] === 'product_is_on_feed_zap') {
    modifiedFilter.push({
      group: [
        { field: 'is_on_feeds.zap', operator: 'eq', value: true }
      ]
    });
  }

  if (predefinedFilter && predefinedFilter[0] === 'product_is_on_feed_ksp') {
    modifiedFilter.push({
      group: [
        {field: 'is_on_feeds.ksp', operator: 'eq', value: true}
      ]
    });
  }

  if (predefinedFilter && predefinedFilter[0] === 'a_am_cheapest_zap') {
    modifiedFilter.push({
      group: [
        { field: 'scores.zap', operator: 'gte', value: '0' }
      ]
    });

    if (condition) {
      condition.sort = [
        { field: 'scores.zap', order: 'desc' },
      ];
    } else {
      condition = { sort: [
        { field: 'scores.zap', order: 'desc' },
      ]};
    }
  }

  if (predefinedFilter && predefinedFilter[0] === 'a_am_cheapest_ksp') {
    modifiedFilter.push({
      group: [
        { field: 'scores.ksp', operator: 'gte', value: '0' }
      ]
    });

    if (condition) {
      condition.sort = [
        { field: 'scores.ksp', order: 'desc' }
      ];
    } else {
      condition = { sort: [
          { field: 'scores.ksp', order: 'desc' }
        ]};
    }
  }

  if (predefinedFilter && predefinedFilter[0] === 'a_am_the_most_expensive_zap') {
    modifiedFilter.push({
      group: [
        { field: 'scores.zap', operator: 'lte', value: '0' }
      ]
    });

    if (condition) {
      condition.sort = [
        { field: 'scores.zap', order: 'asc' }
      ];
    } else {
      condition = { sort: [
          { field: 'scores.zap', order: 'asc' }
        ]};
    }
  }

  if (predefinedFilter && predefinedFilter[0] === 'a_am_the_most_expensive_ksp') {
    modifiedFilter.push({
      group: [
        { field: 'scores.ksp', operator: 'lte', value: '0' }
      ]
    });

    if (condition) {
      condition.sort = [
        { field: 'scores.ksp', order: 'asc' }
      ];
    } else {
      condition = { sort: [
          { field: 'scores.ksp', order: 'asc' }
        ]};
    }
  }

  if (predefinedFilter && (
    predefinedFilter[0] === 'some_price_activity_less_24h_zap' ||
    predefinedFilter[0] === 'some_price_activity_less_48h_zap' ||
    predefinedFilter[0] === 'some_price_activity_less_48h_ksp' ||
    predefinedFilter[0] === 'some_price_activity_less_48h_ksp'
  )) {
    const timestamp = (predefinedFilter[0] === 'some_price_activity_less_24h_zap' ||
      predefinedFilter[0] === 'some_price_activity_less_24h_ksp')
      ? Math.floor(Date.now()) - (60 * 60 * 24) * 1000
      : Math.floor(Date.now()) - (60 * 60 * 48) * 1000;
    const date = new Date(timestamp);

    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = String(date.getFullYear());
    const hh = String(date.getHours()).padStart(2, '0');
    const mi = String(date.getMinutes()).padStart(2, '0');
    const ss = String(date.getSeconds()).padStart(2, '0');
    const dt = yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + mi + ':' + ss;

    if (predefinedFilter[0] === 'some_price_activity_less_24h_zap'
      || predefinedFilter[0] === 'some_price_activity_less_48h_zap')
    {
      modifiedFilter.push({
        group: [ { field: 'price_enrichment.competitors.zap.updated_at', value: dt, operator: 'gt' } ]
      });
    }

    if (predefinedFilter[0] === 'some_price_activity_less_24h_ksp'
      || predefinedFilter[0] === 'some_price_activity_less_48h_ksp')
    {
      modifiedFilter.push({
        group: [ { field: 'price_enrichment.competitors.ksp.updated_at', value: dt, operator: 'gt' } ]
      });
    }
  }

  const fullTextFields = [
    { field: 'title.en', value: '*' + fullText.value + '*', operator: 'like' },
  ];

  return getFilterOptions({
    filter: modifiedFilter, storedSearchFields, fullText, scroll, condition, itemsPerPage, fullTextFields
  });
};
