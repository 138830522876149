import {
  QUICK_EDIT_SET_DRAWER_STATE,
} from './types';

export const toggleDrawer = drawerState => (dispatch) => {
  dispatch({
    type: QUICK_EDIT_SET_DRAWER_STATE,
    payload: { drawerState },
  });
};

export default toggleDrawer;
