import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

const initialState = {};
const currencyRestTypes = restTypes('currency');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case currencyRestTypes.fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
        ...action.payload,
      };
    case currencyRestTypes.fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };

    default: return state;
  }
};
