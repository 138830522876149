/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import {
  func, bool, shape, number, arrayOf,
} from 'prop-types';
import IntlMessages from '../../../../util/IntlMessages';
import ScopeDialog from '../../../../components/ScopeDialog';
import {
  mapStateToProps,
  actionCreators,
} from './connect';

export const searchParams = data => ({
  full_text: {
    fields: [
      'name.en',
    ],
    value: data.full_text.value,
    match_type: 'must',
  },
  pagination: {
    page: 1,
    limit: 500,
  },
});

const SidebarFilterScopeDialog = (props) => {
  const handleSearchScopeCategory = (query) => {
    props.searchScopeCategories(searchParams({
      filter: { value: 'true' },
      full_text: { value: query },
    }));
  };

  const placeholder = (document.location.href.indexOf('repricer') !== -1)
    ? 'Search physical categories ...'
    : 'Search virtual categories ...';

  const scopeDialogSettings = {
    search: {
      noResult: props.scopeCategory.searched && props.scopeCategory.searchResults.length === 0,
      placeholder: placeholder,
      list: props.scopeCategory.searchResults,
      queryLengthLimit: 500,
      errorMessage: 'text.errors.lessThan500Characters',
    },
    showScopeDialog: props.showScopeDialog,
    collapsed: props.scopeCategory.collapse,
    loadedKeys: props.scopeCategory.loadedKeys,
    treeItems: props.scopeCategory.treeItems,
    handleToggleCollapse: props.onToggleScopeCategoryTreeCollapse,
    fetchChildCategories: props.fetchChildScopeCategories,
    onSearchSubmit: handleSearchScopeCategory,
    toogleTreeComponents: props.clearScopeCategorySearchState,
    onToggleCategoryTreeCollapse: props.onToggleScopeCategoryTreeCollapse,
    handleLoadSubcategories: props.handleLoadScopeSubcategories,
    loadAllChildCategories: props.loadAllChildCategories,
    multiple: true,
    extraClassName: 'filter-scope-categories',
    loading: props.scopeCategory.fetchingParentCategoriesByIds,
  };

  const handleCloseScopeDialog = () => {
    props.onCloseScopeDialog();
    props.clearScopeCategorySearchState();
  };

  const handleSubmitScopeDialog = (items) => {
    props.onCloseScopeDialog();
    props.onSubmitScopeDialog(items, props.filterId);
  };

  return (
    <ScopeDialog
      multiple
      dialog={{
        closeButtonTitle: <IntlMessages id="rankings.cancelScopeDialogButtonLabel" />,
        submitButtonTitle: <IntlMessages id="rankings.selectScopeDialogButtonLabel" />,
      }}
      closeScopeDialog={handleCloseScopeDialog}
      onSubmit={handleSubmitScopeDialog}
      selectedTreeItems={props.selectedTreeCategories}
      {...scopeDialogSettings}
    />
  );
};

SidebarFilterScopeDialog.propTypes = {
  onCloseScopeDialog: func.isRequired,
  onSubmitScopeDialog: func.isRequired,
  clearScopeCategorySearchState: func.isRequired,
  loadAllChildCategories: func.isRequired,
  handleLoadScopeSubcategories: func.isRequired,
  fetchChildScopeCategories: func.isRequired,
  searchScopeCategories: func.isRequired,
  showScopeDialog: bool.isRequired,
  scopeCategory: shape().isRequired,
  filterId: number.isRequired,
  selectedTreeCategories: arrayOf(shape()),
  onToggleScopeCategoryTreeCollapse: func.isRequired,
};

SidebarFilterScopeDialog.defaultProps = {
  selectedTreeCategories: [],
};

export default connect(mapStateToProps, actionCreators)(SidebarFilterScopeDialog);
