import { createSelector } from 'reselect';

const previewRootSelector = state => state;

const stateSelector = createSelector(
  previewRootSelector,
  preview => ({
    previewCompoundSearch: preview.compoundSearch,
    previewPagination: preview.pagination,
    previewSearchQuery: preview.searchQuery,
  }),
);

export const previewSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  previewSelector,
};
