/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import { connect } from 'react-redux';
import {
  string, shape, arrayOf, bool, func,
} from 'prop-types';

import {
  toggleSelectedProduct,
  fetchParentLevelChildren,
} from '../../../../actions/pim';

import {
  fetchActivityLogsByProductId,
} from '../../../../actions/product';
import { fetchProductAttributesForVariations } from '../../../../actions/productAttribute';

import List from './List';

const ProductImageViewListDnD = memo((props) => {
  const {
    items, disabled, defaultImage, attributes,
    certainProductAttributes, productAttributesForVariations, searchFields,
    isEditMode, pagination, productComments,
    productsCommentsCount, productCommentsLoading,
  } = props;

  const handleExpandParentLevel = (parent) => {
    const { childrenIds } = parent;
    props.fetchParentLevelChildren(childrenIds, parent, items);
  };

  const onProductSelect = productItem => props.toggleSelectedProduct(productItem);

  return (
    <List
      disabled={disabled}
      onProductSelect={onProductSelect}
      productList={items}
      defaultImage={defaultImage}
      attributes={attributes}
      certainProductAttributes={certainProductAttributes}
      productAttributesForVariations={productAttributesForVariations}
      fetchProductAttributesForVariations={props.fetchProductAttributesForVariations}
      isEditMode={isEditMode}
      handleExpandParentLevel={handleExpandParentLevel}
      pagination={pagination}
      productComments={productComments}
      searchFields={searchFields}
      productsCommentsCount={productsCommentsCount}
      fetchActivityLogsByProductId={props.fetchActivityLogsByProductId}
      productCommentsLoading={productCommentsLoading}
      disableEdit
    />
  );
});

ProductImageViewListDnD.propTypes = {
  items: arrayOf(shape()),
  attributes: arrayOf(shape()),
  defaultImage: string.isRequired,
  productAttributesForVariations: arrayOf(shape()),
  fetchProductAttributesForVariations: func.isRequired,
  isEditMode: bool.isRequired,
  toggleSelectedProduct: func.isRequired,
  pagination: shape().isRequired,
  disabled: bool,
  certainProductAttributes: arrayOf(shape()).isRequired,

  fetchParentLevelChildren: func.isRequired,
  productComments: arrayOf(shape()).isRequired,
  searchFields: arrayOf(string).isRequired,
  productsCommentsCount: arrayOf(shape()).isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  productCommentsLoading: bool.isRequired,
};

ProductImageViewListDnD.defaultProps = {
  items: [],
  attributes: [],
  productAttributesForVariations: [],
  disabled: false,
};

const mapStateToProps = state => ({
  certainProductAttributes: state.productAttribute.certainProductAttributes,
  productAttributesForVariations: state.productAttribute.productAttributesForVariations,
  attributes: state.system.modes.pim.parent,
  productComments: state.product.productComments,
  defaultImage: state.pim.defaultImage,
  productsCommentsCount: state.pim.productsCommentsCount,
  productCommentsLoading: state.product.fetchProductCommentsStart,
});

const mapDispatchToProps = {
  toggleSelectedProduct,
  fetchProductAttributesForVariations,
  fetchParentLevelChildren,
  fetchActivityLogsByProductId,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductImageViewListDnD);
