import { createSelector } from 'reselect';

const previewRootSelector = state => state;

const stateSelector = createSelector(
  previewRootSelector,
  previewCategory => ({
    virtualCategoryFetching: previewCategory.fetchingOne,
    virtualCategoryFetched: previewCategory.fetchedOne,
    virtualCategoryItem: previewCategory.item,
    productCategoriesAttributes: previewCategory.filter.productCategoriesAttributes,
    attributesMatrixAttributes: previewCategory.filter.attributesMatrixAttributes,
    filters: previewCategory.filter.filters,
    filtersErrors: previewCategory.filter.filtersErrors,
    isSwitchOn: previewCategory.filter.isSwitchOn,
    productFilter: previewCategory.filter.productFilter,
    filterProduct: previewCategory.filter.product,
    filterCategory: previewCategory.filter.category,
  }),
);

export const previewCategorySelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  previewCategorySelector,
};
