/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const usePreviewFilterAttributes = ({
  actions,
}) => {
  useEffect(() => {
    actions.fetchPreviewFilterAttributes();
  }, []);
};

export default usePreviewFilterAttributes;
