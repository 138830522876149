import axios from 'axios';
import {
  activityLogSearch,
  activityLogBuklUpdate,
} from '../../util/paths';
import {
  getCountProductsActivitiesByProductIdsQuery,
  getActivityLogsByProductIdQuery, getActivityLogList,
} from './queries/activityLog';
import {
  countProductsActivitiesMapper,
} from './mappers/activityLog';
import { showErrorNotification } from '../../util/api';

export const getCountProductsActivitiesByProductIds = async (productIds) => {
  const query = getCountProductsActivitiesByProductIdsQuery(productIds);
  try {
    const result = await axios.post(activityLogSearch, query);
    const mappedData = countProductsActivitiesMapper(result.data);
    return mappedData;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const getActivityLogsByProductId = async (productId) => {
  const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';
  const query = getActivityLogsByProductIdQuery(productId);
  try {
    const result = await axios.post(activityLogSearch + '?ref=' + ref, query);
    return result.data.data;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const getActivityLogs = async (page, options) => {
  const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';
  const query = getActivityLogList(page, options);
  try {
    const result = await axios.post(activityLogSearch + '?ref=' + ref, query);
    return result.data;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const bulkUpdateActivityLogs = async (items) => {
  try {
    const result = await axios.post(activityLogBuklUpdate, { data: items });
    return result.data.data;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export default {
  getCountProductsActivitiesByProductIds,
  getActivityLogsByProductId,
  bulkUpdateActivityLogs,
};
