import React, { useEffect, useState } from 'react';
import {
  arrayOf, string, func, shape, oneOfType, node, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { Paper, Typography, FormHelperText } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { useStyles, StyledToggleButtonGroup } from 'components/TooglePaper/style';
import IntlMessages from 'util/IntlMessages';

const renderHelper = messages => (
  <div className="helper errors">
    {
      messages.map(m => (
        <span key={m.type}>
          <IntlMessages id={m.message} />
        </span>
      ))
    }
  </div>
);

const TooglePaper = (props) => {
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (!isEmpty(props.value)) {
      setSelected(props.value);
    }
  }, [props.value]);

  const handleChange = (event, selectedValue) => {
    if (!isEmpty(selectedValue)) setSelected(selectedValue);
    if (props.onChange) props.onChange(props.list.find(l => l.value === selectedValue));
  };

  const classes = useStyles();

  const disabledClassName = props.disabled ? 'disabled' : '';
  const showRight = props.helper.placement === 'right';

  const hasError = !isEmpty(props.errors);
  const showHelper = props.helper.text && hasError;

  return (
    <Paper
      elevation={0}
      className={`toogle-paper-wrapper ${classes.paper} toogle-${props.color} ${props.className} ${disabledClassName}`}
    >
      {showHelper && !showRight && <FormHelperText className={`helper-text-${props.helper.placement}`}>{props.helper.text}</FormHelperText>}
      {props.label && (
        <Typography
          className={classes.label}
          variant="body1"
        >
          {props.label}
        </Typography>
      )}
      <StyledToggleButtonGroup
        size={props.size}
        value={selected}
        exclusive
        name="toogler"
        onChange={handleChange}
        className={classes.buttonGroup}
      >
        {!isEmpty(props.list) && props.list.map(item => (
          <ToggleButton key={item.value} value={item.value} aria-label={item.ariaLabel}>
            {item.label}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
      {
        showHelper && showRight && (
          <FormHelperText className={`helper errors helper-text-${props.helper.placement}`}>
            {renderHelper(props.errors)}
          </FormHelperText>
        )
      }
    </Paper>
  );
};

TooglePaper.propTypes = {
  color: string,
  value: string,
  size: string,
  className: string,
  list: arrayOf(shape({
    value: string,
    ariaLabel: string,
    label: string,
  })),
  onChange: func,
  label: oneOfType([node, string]),
  helper: shape({
    text: oneOfType([node, string]),
    placement: string,
  }),
  disabled: bool,
  errors: arrayOf(shape()),
};

TooglePaper.defaultProps = {
  color: 'primary',
  value: '',
  size: 'small',
  className: '',
  list: [],
  onChange: null,
  label: null,
  helper: {
    text: null,
    placement: '',
  },
  disabled: false,
  errors: [],
};

export default TooglePaper;
