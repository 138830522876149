import { decamelizeKeys } from 'humps';

export const mapToAttributesMatrix = (field, attributesMatrix) => {
  const exludeFields = ['id'];

  if (exludeFields.some(eF => eF !== field)) {
    const defaultField = attributesMatrix.find(a => a.field === 'default');
    if (defaultField && field.startsWith(defaultField.startsWith)) {
      return `${defaultField.prefix}.${field}`;
    }
    const matrixField = attributesMatrix.find(am => am.field === field);
    if (matrixField) {
      return `${matrixField.prefix}.${field}`;
    }
  }
  return field;
};

const getCondition = filter => (filter.group.length > 1 ? { condition: filter.condition } : { condition: 'and' });

export const mapFilters = (filters, attributesMatrix) => (filters.map(f => ({
  ...getCondition(f),
  group: decamelizeKeys(f.group.map(r => ({
    ...r,
    field: mapToAttributesMatrix(f.group[0].field, attributesMatrix),
    operator: f.group[0].operator,
  }))),
})));

export const getFacets = (facets, attributesMatrix) => facets.map(
  f => mapToAttributesMatrix(f, attributesMatrix),
);

export const unmapFieldFromMatrix = (field) => {
  const isRootProductAttribute = field.startsWith('product.');
  const splitedField = field.split('.');
  const fieldValue = isRootProductAttribute
    ? splitedField.slice(1, 4).join('.')
    : splitedField.pop();

  return fieldValue;
};

export const unmapFromAttributesMatrix = (category, attributesMatrix) => {
  if (!category.virtualParams) return category;

  const {
    sortRules,
    filters = [],
    // facets = [],
  } = category.virtualParams;
  const mappedSortings = sortRules.map(s => ({
    ...s,
    field: unmapFieldFromMatrix(s.field, attributesMatrix),
  }));

  const mappedFilters = filters.map(f => ({
    ...f,
    group: f.group.map(g => ({
      ...g,
      field: unmapFieldFromMatrix(g.field, attributesMatrix),
    })),

  }));
  // const mappedFaccets = facets.map(mF => unmapFieldFromMatrix(mF, attributesMatrix));
  const unMappedCategory = {
    ...category,
    virtualParams: {
      ...category.virtualParams,
      sortRules: mappedSortings,
      filters: mappedFilters,
      // facets: mappedFaccets,
    },
  };

  return unMappedCategory;
};

export const getMatrixAttributes = (attributesMatrixList, show) => (
  attributesMatrixList
    .filter(am => am.field !== 'default' && show(am))
    .map(am => ({
      frontendInputType: am.frontendInputType,
      allowDuplicates: am.allowDuplicates,
      isFilterable: am.isFilterable,
      options: am.options,
      isSortable: am.isSortable,
      label: am.label,
      field: am.field,
      code: am.field,
      value: am.field,
      id: am.field,
    }))
);

export const getFilterMatrixAttributes = attributesMatrixList => getMatrixAttributes(
  attributesMatrixList, a => a.isFilterable,
);
export const getSortsMatrixAttributes = attributesMatrixList => getMatrixAttributes(
  attributesMatrixList, a => a.isSortable,
);

export default {
  mapToAttributesMatrix,
  mapFilters,
  getFacets,
  unmapFieldFromMatrix,
  unmapFromAttributesMatrix,
  getMatrixAttributes,
  getFilterMatrixAttributes,
  getSortsMatrixAttributes,
};
