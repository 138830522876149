import { camelizeKeys, decamelizeKeys } from 'humps';
import axios from 'axios';
import { uniq, isEmpty } from 'lodash';

import RestActions from '../util/rest/actions';
import { showErrorNotification } from '../util/api';

import {
  getCategoryPath,
  productsFrontAppPath,
  categoriesSearchPath,
} from '../util/paths';

import {
  PREVIEW_CATEGORY_SET_CATEGORY_FIELDS,
  PREVIEW_CATEGORY_SET_PRODUCT_CATEGORIES_ATTRIBUTES,
  PREVIEW_CATEGORY_SET_ATTRIBUTES_MATRIX_ATTRIBUTES,
  PREVIEW_CATEGORY_SET_FILTERS,
  PREVIEW_CATEGORY_SET_FILTERS_ERRORS,
  PREVIEW_CATEGORY_SET_PRODUCTS_NIN_IDS,
  PREVIEW_CATEGORY_SET_PRODUCTS_IN_IDS,
  PREVIEW_CATEGORY_SET_SELECTED_PRODUCT_LIST,
  PREVIEW_CATEGORY_UPDATE_LOADED_PRODUCTS_IN,
  PREVIEW_CATEGORY_UPDATE_LOADED_PRODUCTS_NIN,
  PREVIEW_CATEGORY_COMPLETE_PRODUCTS_LIST_START,
  PREVIEW_CATEGORY_COMPLETE_PRODUCTS_LIST_FINISHED,
  PREVIEW_CATEGORY_SET_IS_APPLY_FILTER_ACTIVE,
  PREVIEW_CATEGORY_SET_IS_SWITCH_ON,
  PREVIEW_CATEGORY_SET_PRODUCT_FILTER,
  PREVIEW_CATEGORY_FETCH_PRODUCT_LIST_START,
  PREVIEW_CATEGORY_FETCH_PRODUCT_LIST_FINISHED,
  PREVIEW_CATEGORY_SET_PRODUCT_COMPOUND_QUERY,
  PREVIEW_CATEGORY_SET_LEFT_SIDE_PAGINATION,
  PREVIEW_CATEGORY_FETCH_PRODUCTS_BY_IDS_START,
  PREVIEW_CATEGORY_FETCH_PRODUCTS_BY_IDS_FINISHED,
  PREVIEW_CATEGORY_SET_SHOW_CATEGORIES_DIALOG,
  PREVIEW_CATEGORY_SET_MAPPED_CATEGORIES,
  PREVIEW_CATEGORY_SET_SELECTED_CATEGORIES,
  PREVIEW_CATEGORY_SET_PHYSICAL_CATEGORY_SEARCH_QUERY,
  PREVIEW_CATEGORY_SET_SELECTED_DIALOG_CATEGORIES,
  PREVIEW_CATEGORY_CLEAR_CATEGORY_PRODUCT_STATE,
  PREVIEW_CATEGORY_SET_RANKING_RULE_CODE,
  PREVIEW_CATEGORY_SET_SORTING_ERRORS,
  PREVIEW_CATEGORY_SET_SORT_RULES,
  PREVIEW_CATEGORY_SET_FACETS,
  PREVIEW_CATEGORY_SET_IS_DATA_CHANGED,
  PREVIEW_CATEGORY_SET_IS_CATEGORY_SAVING,
  PREVIEW_CATEGORY_SET_ERRORS,
  PREVIEW_CATEGORY_FETCH_VIRTUAL_CATEGORIES_BY_NAME_START,
  PREVIEW_CATEGORY_FETCH_VIRTUAL_CATEGORIES_BY_NAME_FINISHED,
} from './types';

import normalizeFilters from '../util/normalizeFilters';
import { mapPositions } from '../util/mapVirtualParamsPositions';
import {
  unmapFromAttributesMatrix,
  mapToAttributesMatrix,
  mapFilters,
} from '../util/attributesMatrixMapping';

import {
  getValueByOperator,
  getFieldByOperator,
} from '../util/mapFiltersByOperator';
import { generateRequestBody } from '../util/productPayload';
import { mapExportableProducts } from '../util/mapExportableProducts';

import {
  getFrontendProductsByIds,
} from '../services/API/product';

const restPreviewCategoryActions = new RestActions('preview-category');
const {
  fetchStart,
  fetchFinished,
  clearErrorFinished,
  updateStart,
  updateFinished,
  clearFromStateFinished,
} = restPreviewCategoryActions;

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';

export const mapVirtualParams = (virtualParams, mapToMatrix, attributesMatrix) => {
  const positions = Array.isArray(virtualParams.positions) ? {} : virtualParams.positions;
  return ({
    ...virtualParams,
    filters: mapToMatrix ? [...mapFilters(virtualParams.filters, attributesMatrix)]
      : virtualParams.filters,
    sortRules: mapToMatrix ? virtualParams.sortRules.map(s => ({
      field: mapToAttributesMatrix(s.field, attributesMatrix),
      direction: s.direction.toLowerCase(),
    })) : virtualParams.sortRules,
    positions: virtualParams.productsOverride
      ? virtualParams.productsOverride.reduce((result, item) => {
        const payload = { ...result };

        payload[item.productId] = item.position;
        return payload;
      }, {})
      : positions,
  });
};

export const fetchVirtualCategory = id => (dispatch, getState) => {
  const { list: attributesMatrix } = getState().attributesMatrix;
  dispatch(fetchStart());

  axios.get(getCategoryPath(id)).then((res) => {
    const data = normalizeFilters(camelizeKeys(res.data.data));
    const payload = { ...data };

    payload.virtualParams = mapPositions(
      data.virtualParams,
      res.data.data.virtual_params,
    );

    const categoryItem = unmapFromAttributesMatrix(payload, attributesMatrix);

    dispatch(fetchFinished({
      item: categoryItem,
      originalItem: categoryItem,
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(fetchFinished({
      error: error.response.data, hasError: true,
    }));
  });
};

export const setCategoryFields = item => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_CATEGORY_FIELDS,
    payload: { item },
  });
};

export const setIsDataChanged = isDataChanged => (dispatch) => { // TODO mb delete
  dispatch({
    type: PREVIEW_CATEGORY_SET_IS_DATA_CHANGED,
    payload: { isDataChanged },
  });
};

export const setIsCategorySaving = isCategorySaving => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_IS_CATEGORY_SAVING,
    payload: { isCategorySaving },
  });
};

export const fetchVirtualCategoryByPathByName = (filter, pagination, cb) => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_FETCH_VIRTUAL_CATEGORIES_BY_NAME_START,
  });
  axios.post(categoriesSearchPath + '?ref=' + ref, decamelizeKeys({ ...filter, pagination })).then((res) => {
    const itemAlreadyExist = !isEmpty(res.data.data);
    dispatch({
      type: PREVIEW_CATEGORY_FETCH_VIRTUAL_CATEGORIES_BY_NAME_FINISHED,
      payload: { itemAlreadyExist },
    });
    if (cb) cb(res.data.data);
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: PREVIEW_CATEGORY_FETCH_VIRTUAL_CATEGORIES_BY_NAME_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  });
};

// FILTER

export const setProductCategoriesAttributes = productCategoriesAttributes => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_PRODUCT_CATEGORIES_ATTRIBUTES,
    payload: { productCategoriesAttributes },
  });
};

export const setAttributesMatrixAttributes = attributesMatrixAttributes => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_ATTRIBUTES_MATRIX_ATTRIBUTES,
    payload: { attributesMatrixAttributes },
  });
};

export const setFilters = filters => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_FILTERS,
    payload: { filters },
  });
};

export const setFiltersErrors = filtersErrors => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_FILTERS_ERRORS,
    payload: { filtersErrors },
  });
};

export const setIsSwitchOn = isSwitchOn => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_IS_SWITCH_ON,
    payload: { isSwitchOn },
  });
};

export const setProductFilter = productFilter => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_PRODUCT_FILTER,
    payload: { productFilter },
  });
};

export const setErrors = errors => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_ERRORS,
    payload: { errors },
  });
};

export const clearErrorState = () => (dispatch) => {
  dispatch(clearErrorFinished({ error: {}, hasErrors: false }));
};

export const updateCategory = (category, params) => (dispatch, getState) => {
  dispatch(clearErrorState());
  dispatch(updateStart());

  const transformedPath = params.isSync
    ? `${getCategoryPath(category.id)}?is_synchronous=${params.isSync}`
    : getCategoryPath(category.id);
  const { list: attributesMatrix } = getState().attributesMatrix;
  const { virtualParams } = category;
  const categoryToUpdate = {
    ...category,
    virtualParams: mapVirtualParams(virtualParams, params.mapToMatrix, attributesMatrix),
  };
  const data = decamelizeKeys(categoryToUpdate);

  if (category.virtualParams.productsOverride
    && isEmpty(category.virtualParams.productsOverride)) {
    delete data.virtual_params.positions;
  } else {
    data.virtual_params.positions = categoryToUpdate.virtualParams.positions;
  }

  if (data.virtual_params.products_override) {
    delete data.virtual_params.products_override;
  }

  delete data.virtual_params.facets; // TODO remove

  return axios.put(transformedPath, {
    data,
  }).then((res) => {
    const updatedCategory = camelizeKeys(res.data.data);
    const payload = { ...updatedCategory };
    payload.virtualParams = mapPositions(
      updatedCategory.virtualParams,
      res.data.data.virtual_params,
    );

    const newItem = unmapFromAttributesMatrix(payload, attributesMatrix);

    dispatch(updateFinished({
      success: true,
      hasErrors: false,
      item: newItem,
      originalItem: newItem,
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(updateFinished({
      error, hasErrors: true,
    }));
  });
};

// FILTER PRODUCTS

export const setProductsNotInIds = productsNotInIds => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_PRODUCTS_NIN_IDS,
    payload: { productsNotInIds },
  });
};

export const setProductsInIds = productsInIds => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_PRODUCTS_IN_IDS,
    payload: { productsInIds },
  });
};

export const setSelectedProductList = selectedProductList => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_SELECTED_PRODUCT_LIST,
    payload: { selectedProductList },
  });
};

export const updateLoadedProductsIn = productsByIdsForIn => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_UPDATE_LOADED_PRODUCTS_IN,
    payload: { productsByIdsForIn },
  });
};

export const updateLoadedProductsNin = productsByIdsForNin => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_UPDATE_LOADED_PRODUCTS_NIN,
    payload: { productsByIdsForNin },
  });
};

export const completeProductList = (ids, key, pagination) => async (dispatch, getState) => {
  dispatch({
    type: PREVIEW_CATEGORY_COMPLETE_PRODUCTS_LIST_START,
    payload: {
      completingCategoryProductsList: true,
      completedCategoryProductsList: false,
    },
  });

  const currentKey = key || 'productsByIds';
  const previewCategoryState = getState().previewCategory;
  const currentTargetList = previewCategoryState.filter.product[currentKey];
  const query = {
    filter: [{
      group: [{
        field: 'id',
        value: uniq(ids),
        operator: 'in',
      }],
    }],
    pagination,
  };

  const res = await getFrontendProductsByIds(query);
  const items = camelizeKeys(res);

  dispatch({
    type: PREVIEW_CATEGORY_COMPLETE_PRODUCTS_LIST_FINISHED,
    payload: {
      completingCategoryProductsList: false,
      completedCategoryProductsList: true,
      [currentKey]: [...currentTargetList.map((l) => {
        const itemToSet = items.find(p => p.id === l.id);
        return itemToSet || l;
      })],
    },
  });
};

export const setIsProductDialogApplyFilterActive = isProductDialogApplyFilterActive => (
  dispatch,
) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_IS_APPLY_FILTER_ACTIVE,
    payload: { isProductDialogApplyFilterActive },
  });
};

export const fetchProductList = ({
  viewMode,
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  fullText = {},
  compoundSearch = null,
}) => (dispatch, getState) => {
  const sort = [{
    field: 'categories.position',
    order: 'asc',
  }];
  const { list } = getState().previewFilterAttribute;
  const mappedFilters = filter.map((f) => {
    const group = f.group.map(g => ({
      ...g,
      value: getValueByOperator({ value: g.value, operator: g.operator }),
      field: getFieldByOperator({
        field: g.field, operator: g.operator, previewFilterAttribute: list,
      }),
    }));

    return {
      ...f,
      group,
    };
  });

  const body = {
    viewMode,
    fields,
    pagination,
    fullText,
    sort,
    searchFilter,
  };

  if (!isEmpty(mappedFilters)) body.filter = mappedFilters;

  const params = {
    ...generateRequestBody(body),
  };

  if (compoundSearch && !isEmpty(compoundSearch) && compoundSearch.value) {
    params.body.compoundSearch = compoundSearch;
  }

  dispatch({
    type: PREVIEW_CATEGORY_FETCH_PRODUCT_LIST_START,
    payload: {
      categoryFetchProductListStart: true,
      categoryFetchProductListFinished: false,
    },
  });

  return axios.post(
    params.path,
    decamelizeKeys(params.body),
  ).then((res) => {
    const {
      pages, total, aggregation,
    } = res.data;
    const result = res.data.data.map((p) => {
      if (p.variants) {
        return {
          ...p,
          variants: Object.keys(p.variants).map((v) => {
            const item = p.variants[v];
            return ({
              ...item,
              id: v,
            });
          }),
        };
      }
      return p;
    });

    dispatch({
      type: PREVIEW_CATEGORY_FETCH_PRODUCT_LIST_FINISHED,
      payload: {
        list: mapExportableProducts(camelizeKeys(result)),
        aggregation,
        pages,
        total,
        updated: false,
        updating: false,
        categoryFetchProductListStart: false,
        categoryFetchProductListFinished: true,
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: PREVIEW_CATEGORY_FETCH_PRODUCT_LIST_FINISHED,
      payload: {
        error: error.response.data,
        hasErrors: true,
        list: [],
      },
    });
  });
};

export const setCompoundSearch = compoundSearch => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_PRODUCT_COMPOUND_QUERY,
    payload: { compoundSearch },
  });
};

export const setLeftSidePagination = leftSidePagination => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_LEFT_SIDE_PAGINATION,
    payload: { leftSidePagination },
  });
};

export const fetchProductsByIds = (ids, key, pagination) => async (dispatch, getState) => {
  const previewCategoryState = getState().previewCategory;
  const currentKey = key || 'productsByIds';
  const currentTargetList = previewCategoryState.filter.product[currentKey];

  dispatch({
    type: PREVIEW_CATEGORY_FETCH_PRODUCTS_BY_IDS_START,
    payload: {
      fetchProductsByIdsStarted: true,
      fetchProductsByIdsFinished: false,
    },
  });

  const condition = ids.length > 1
    ? { condition: 'or' }
    : {};

  return axios.post(
    `${productsFrontAppPath}/search`,
    {
      filter: [
        {
          ...condition,
          group: [{
            field: 'id',
            value: uniq(ids),
            operator: 'in',
          }],
        },
      ],
      pagination,
    },
  ).then((res) => {
    const items = mapExportableProducts(camelizeKeys(res.data.data));

    dispatch({
      type: PREVIEW_CATEGORY_FETCH_PRODUCTS_BY_IDS_FINISHED,
      payload: {
        [currentKey]: [...currentTargetList.map((l) => {
          const itemToSet = items.find(p => p.id === l.id);
          return itemToSet || l;
        })],
        fetchProductsByIdsStarted: false,
        fetchProductsByIdsFinished: true,
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: PREVIEW_CATEGORY_FETCH_PRODUCTS_BY_IDS_FINISHED,
      payload: {
        error: error.response.data,
        hasErrors: true,
      },
    });
  });
};

export const clearProductState = () => (dispatch) => {
  dispatch({ type: PREVIEW_CATEGORY_CLEAR_CATEGORY_PRODUCT_STATE });
};

// SORT

export const setRankingRuleCode = rankingRuleCode => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_RANKING_RULE_CODE,
    payload: { rankingRuleCode },
  });
};

export const setSortingsErrors = sortingsErrors => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_SORTING_ERRORS,
    payload: { sortingsErrors },
  });
};

export const setSortRules = sortRules => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_SORT_RULES,
    payload: { sortRules },
  });
};

// CATEGORY
export const setShowSelectCategoriesDialog = showSelectCategoriesDialog => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_SHOW_CATEGORIES_DIALOG,
    payload: { showSelectCategoriesDialog },
  });
};

export const setMappedCategories = mappedCategories => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_MAPPED_CATEGORIES,
    payload: { mappedCategories },
  });
};

export const setSelectedCategories = selectedCategories => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_SELECTED_CATEGORIES,
    payload: { selectedCategories },
  });
};

export const setPhysicalCategorySearchQuery = physicalCategorySearchQuery => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_PHYSICAL_CATEGORY_SEARCH_QUERY,
    payload: { physicalCategorySearchQuery },
  });
};

export const setSelectedDialogCategories = selectedDialogCategories => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_SELECTED_DIALOG_CATEGORIES,
    payload: { selectedDialogCategories },
  });
};

// FACET

export const setFacets = facets => (dispatch) => {
  dispatch({
    type: PREVIEW_CATEGORY_SET_FACETS,
    payload: { facets },
  });
};

export const clearFromPreviewCategoryState = payload => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};
