import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  SET_CATEGORY_TREE_ITEMS,
  SEARCH_VIRTUAL_CATEGORIES_START,
  SEARCH_VIRTUAL_CATEGORIES_FINISHED,
  FETCH_CHILD_VIRTUAL_CATEGORIES_START,
  FETCH_CHILD_VIRTUAL_CATEGORIES_FINISHED,
  FETCH_ROOT_VIRTUAL_CATEGORIES_START,
  FETCH_ROOT_VIRTUAL_CATEGORIES_FINISHED,
  TOGGLE_CATEGORY_TREE_COLLAPSE,
  TOGGLE_CATEGORY_TREE_COLLAPSE_ITEM,
  FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_START,
  FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_FINISHED,
  HANDLE_LOAD_SUBCATEGORIES,
  CLEAR_VIRTUAL_CATEGORY_TREE_STATE,
  CATEGORY_FETCH_PRODUCT_LIST_START,
  CATEGORY_FETCH_PRODUCT_LIST_FINISHED,
  CLEAR_CATEGORY_PRODUCT_STATE,
  CATEGORY_FETCH_PRODUCTS_BY_IDS_START,
  CATEGORY_FETCH_PRODUCTS_BY_IDS_FINISHED,
  COMPLETE_CATEGORY_PRODUCTS_LIST_FINISHED,
  COMPLETE_CATEGORY_PRODUCTS_LIST_START,
  CATEGORY_SET_SELECTED_PRODUCT_LIST,
  CATEGORY_SET_PRODUCTS_IN_IDS,
  CATEGORY_SET_PRODUCTS_NIN_IDS,
  CATEGORY_UPDATE_LOADED_PRODUCTS_IN,
  CATEGORY_UPDATE_LOADED_PRODUCTS_NIN,
  FETCH_CATEGORY_TO_DUPLICATE_START,
  FETCH_CATEGORY_TO_DUPLICATE_FINISHED,
} from '../actions/types';

const initialState = {
  treeItems: [],
  searchResults: [],
  collapse: [],
  loadedKeys: [],
  multipleChildListFetching: false,
  multipleChildListFetched: false,
  categoryToDuplicate: {},
  product: {
    list: [],
    selectedProductList: { in: [], nin: [] },
    productsInIds: [],
    productsNotInIds: [],
    productsByIdsForIn: [],
    productsByIdsForNin: [],
  },
};
const virtualCategoryRestTypes = restTypes('virtual_category');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case virtualCategoryRestTypes.createStart:
      return {
        ...state,
        ...restStates.createStart,
        ...action.payload,
      };
    case virtualCategoryRestTypes.createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        ...action.payload,
      };
    case virtualCategoryRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case virtualCategoryRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case virtualCategoryRestTypes.fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };
    case virtualCategoryRestTypes.fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case virtualCategoryRestTypes.clearAllFinished:
      return {
        ...restInitState,
        ...initialState,
        collapse: state.collapse,
        loadedKeys: state.loadedKeys,
      };
    case virtualCategoryRestTypes.clearOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        item: {},
        fetchedOne: false,
      };
    case virtualCategoryRestTypes.clearSearchListFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        search: [],
        searched: false,
      };
    case virtualCategoryRestTypes.clearErrorFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case virtualCategoryRestTypes.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case SET_CATEGORY_TREE_ITEMS:
      return {
        ...state,
        treeItems: [
          ...state.treeItems,
          ...action.payload.items,
        ],
      };
    case SEARCH_VIRTUAL_CATEGORIES_START:
      return {
        ...state,
        searchStarted: true,
        searchFinished: false,
      };
    case SEARCH_VIRTUAL_CATEGORIES_FINISHED:
      return {
        ...state,
        searchStarted: false,
        searchFinished: true,
        searchResults: action.payload.list,
      };
    case virtualCategoryRestTypes.updateMultipleStart:
      return {
        ...state,
        ...restStates.updateMultipleStart,
        ...action.payload,
      };
    case virtualCategoryRestTypes.updateMultipleFinished:
      return {
        ...state,
        ...restStates.updateMultipleFinished,
        success: action.payload.success,
      };
    case FETCH_ROOT_VIRTUAL_CATEGORIES_START:
      return {
        ...state,
        fetchRootStarted: true,
        fetchRootFinished: false,
      };
    case FETCH_ROOT_VIRTUAL_CATEGORIES_FINISHED:
      return {
        ...state,
        treeItems: action.payload.list,
        fetchRootStarted: false,
        fetchRootFinished: true,
      };
    case FETCH_CHILD_VIRTUAL_CATEGORIES_START:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_CHILD_VIRTUAL_CATEGORIES_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case virtualCategoryRestTypes.deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
        ...action.payload,
      };
    case virtualCategoryRestTypes.deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
        collapse: state.collapse.filter(c => c !== action.payload.lastDeletedCategoryId),
        loadedKeys: state.loadedKeys.filter(k => k !== action.payload.lastDeletedCategoryId),
      };
    case virtualCategoryRestTypes.updateStart:
      return {
        ...state,
        ...restStates.updateStart,
        ...action.payload,
      };
    case virtualCategoryRestTypes.updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };
    case TOGGLE_CATEGORY_TREE_COLLAPSE:
      return {
        ...state,
        collapse: action.payload,
      };
    case TOGGLE_CATEGORY_TREE_COLLAPSE_ITEM:
      return {
        ...state,
        collapse: state.collapse.some(c => c === action.payload)
          ? state.collapse
          : [...state.collapse, action.payload],
      };
    case FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_START:
      return {
        ...state,
        multipleChildListFetching: true,
        multipleChildListFetched: false,
      };
    case FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_FINISHED:
      return {
        ...state,
        multipleChildListFetching: false,
        multipleChildListFetched: true,
      };
    case HANDLE_LOAD_SUBCATEGORIES:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_VIRTUAL_CATEGORY_TREE_STATE:
      return {
        ...state,
        loadedKeys: [],
        collapse: [],
      };
    case CATEGORY_FETCH_PRODUCT_LIST_START:
      return {
        ...state,
        ...action.payload,
        categoryFetchProductListStart: true,
        categoryFetchProductListFinished: false,
      };
    case CATEGORY_FETCH_PRODUCT_LIST_FINISHED:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
        },
        categoryFetchProductListStart: false,
        categoryFetchProductListFinished: true,
      };
    case CLEAR_CATEGORY_PRODUCT_STATE:
      return {
        ...state,
        product: initialState.product,
      };
    case CATEGORY_FETCH_PRODUCTS_BY_IDS_START:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
          fetchProductsByIdsStarted: true,
          fetchProductsByIdsFinished: false,
        },
      };
    case CATEGORY_FETCH_PRODUCTS_BY_IDS_FINISHED:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
          fetchProductsByIdsStarted: false,
          fetchProductsByIdsFinished: true,
        },
      };
    case COMPLETE_CATEGORY_PRODUCTS_LIST_FINISHED:
    case COMPLETE_CATEGORY_PRODUCTS_LIST_START:
    case CATEGORY_SET_SELECTED_PRODUCT_LIST:
    case CATEGORY_SET_PRODUCTS_IN_IDS:
    case CATEGORY_SET_PRODUCTS_NIN_IDS:
    case CATEGORY_UPDATE_LOADED_PRODUCTS_IN:
    case CATEGORY_UPDATE_LOADED_PRODUCTS_NIN:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
        },
      };
    case FETCH_CATEGORY_TO_DUPLICATE_START:
    case FETCH_CATEGORY_TO_DUPLICATE_FINISHED:
      return {
        ...state,
        ...action.payload,
      };

    default: return state;
  }
};
