/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import {
  bool, func, shape,
} from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const VideosEnrichmentPreview = memo((props) => {
  const {
    isOpened,
    onClose,
    video,
  } = props;

  const closeVideosEnrichmentPreview = () => {
    onClose();
  };

  const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    closeButton: {}
  });

  const DialogTitle = withStyles(styles)((props) => {
    const {
      children, classes, onClose
    } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box className={classes.title}>
            <Typography variant="h6">
              { children }
            </Typography>
          </Box>
          <Box>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2)
    }
  }))(MuiDialogContent);

  return (
    <Dialog
      open={isOpened}
      aria-labelledby="customized-dialog-title"
      maxWidth="lg"
      onClose={closeVideosEnrichmentPreview}
    >
      <DialogTitle id="customized-dialog-title" onClose={closeVideosEnrichmentPreview}>
        { video.title ? video.title.en : '' }
      </DialogTitle>

      <DialogContent>
        <iframe
          src={video.url}
          frameBorder="0"
          width="800"
          height="600"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title={ video.title ? video.title.en : '' }
        />
      </DialogContent>

    </Dialog>
  );
});

VideosEnrichmentPreview.propTypes = {
  onClose: func.isRequired,
  isOpened: bool.isRequired,
  videos: shape(),
};

VideosEnrichmentPreview.defaultProps = {
  video: [],
  isOpened: false,
};

export default VideosEnrichmentPreview;
