/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  memo, useState, Fragment,
} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  func, shape,
} from 'prop-types';
import { isEmpty } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import Field from '../../../../components/Field';
import FormDialog from '../../../../components/FormDialog';
import IntlMessages from '../../../../util/IntlMessages';

import {
  commentValidations,
  systemCommentValidations,
} from './utils/validations';
import validateAll from '../../../../util/validator';
import { NotificationManager } from "react-notifications";
import { updateProduct, bulkDeleteProductLockedAttributes } from '../../../../actions/product';

const ProductActionsDialog = memo((props) => {
  const {
    type,
    isOpened,
    onClose,
    commentMessages,
    product,
  } = props;

  const [comment, setComment] = useState('');
  const [systemComment, setSystemComment] = useState('');
  const [errors, setErrors] = useState({});

  const handleChangeComment = e => setComment(e.target.value);
  const handleChangeSystemComment = e => setSystemComment(e.target.value);

  const validateComments = () => {
    let hasErrors = false;
    const commentErrors = validateAll({
      ...commentValidations, ...systemCommentValidations,
    }, { comment, systemComment });

    if (!isEmpty(commentErrors)) {
      hasErrors = true;
      setErrors(commentErrors);
    } else {
      setErrors({});
    }
    return hasErrors;
  };

  const submitProductActions = () => {
    const hasErrors = validateComments();
    if (hasErrors) {
      NotificationManager.error('Please enter a comment');
      return false;
    }

    if (type === 'unlockAttributes') {
      props.bulkDeleteProductLockedAttributes({
        productIds: [product.id],
        fields: [],
        productTypes: [product.type],
      });

      NotificationManager.success('Product has been updated successfully.');
    } else {
      const payload = {};
      if (type === 'remove') {
        payload.visibility_status = 'not_visible';
      }
      if (type === 'restore') {
        payload.visibility_status = 'visible_global';
      }
      if (type === 'markAsReviewed') {
        payload.pim_status = {
          status: "reviewed",
          reviewed_by: props.activeUser.email,
        };
      }
      if (type === 'markAsNotReviewed') {
        payload.pim_status = {
          status: "not_reviewed",
          reviewed_by: props.activeUser.email,
        };
      }

      const userInfo = 'User details [' +
        'Display name: ' + props.activeUser.displayName + ', ' +
        'Email: ' + props.activeUser.email + ', ' +
        'Id: ' + props.activeUser.id + ']';

      props.updateProduct({
        payload: payload,
        comment: systemComment + '@' + comment + ' ' + userInfo,
        id: product.id,
      });

      NotificationManager.success('Product has been updated successfully.');
    }

    closeProductActionsDialog();
  };

  const closeProductActionsDialog = () => {
    setComment('');
    setSystemComment('');
    onClose();
  };

  return (
    <FormDialog
      title={<IntlMessages id="components.removeProduct" />}
      open={isOpened}
      hideCloseButton={false}
      className="dialog-inputs-custom dialog-content-visible"
      closeButtonTitle="Close"
      submitButtonTitle="Submit"
      maxWidth="xs"
      onClose={closeProductActionsDialog}
      onSubmit={submitProductActions}
    >
      <Fragment>
        <Field
          type="dropdown"
          element="autocomplete"
          onChange={handleChangeSystemComment}
          options={commentMessages.map(cm => ({
            ...cm, label: cm.title, value: cm.title,
          }))}
          value={systemComment
            ? { label: systemComment, value: systemComment }
            : ''}
          className={
            `select-wrapper select-bordered top-oriented min-w-sm form-group block ml-0
            `}
          hideStaticLabel
          searchable
          menuPlacement="top"
          emptyPlaceholder="System comment"
          name="System comment"
          key="system-comment"
          label="System comment"
          suffixClassName="suffix-abs"
          suffix={!isEmpty(errors.systemComment) && (
            <Tooltip
              placement="top-end"
              title={(
                <div className="tooltip-title-content-wrapper flex direction-column">
                  {errors.systemComment.map(e => <IntlMessages id={e.message} key={e.message} />)}
                </div>
              )}
            >
              <i className="ti-info-alt" />
            </Tooltip>
          )}
          inputProps={{
            name: 'System comment',
            id: 'system-comment-select',
          }}
        />
        <Field
          element="text"
          onChange={handleChangeComment}
          value={comment}
          className={`min-w-sm input-bordered form-group input-text
            `}
          emptyPlaceholder="Comment"
          name="comment"
          key="comment"
          label="Comment"
          suffix={!isEmpty(errors.comment) && (
            <Tooltip
              placement="top-end"
              title={(
                <div className="tooltip-title-content-wrapper flex direction-column">
                  {errors.comment.map(e => <IntlMessages id={e.message} key={e.message} />)}
                </div>
              )}
            >
              <i className="ti-info-alt" />
            </Tooltip>
          )}
        />
      </Fragment>
    </FormDialog>
  );
});

ProductActionsDialog.propTypes = {
  updateProduct: func,
  bulkDeleteProductLockedAttributes: func,
  activeUser: shape().isRequired,
};

ProductActionsDialog.defaultProps = {};

const mapStateToProps = state => ({
  activeUser: state.session.item,
});

const mapDispatchToProps = {
  updateProduct,
  bulkDeleteProductLockedAttributes,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductActionsDialog));
