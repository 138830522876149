import React from 'react';
import IntlMessages from "../../../util/IntlMessages";
import {isEmpty} from "lodash";

export const ProductInfo = ({productId, info, editProduct, productProps, VariationInfo, productAttributeProps}) => {
  if (isEmpty(info.parent)) {
    return (
      <div className="comments no-comments">
        <IntlMessages id="preview.productHasNoParents" />
      </div>
    );
  }

  const productTitle = info.parent && info.parent.title
    ? info.parent.title.en
    : <IntlMessages id="preview.viewProductDialogTitle" />;

  const totalQty = info.parent && info.parent.totalQty ? info.parent.totalQty : 0;

  const productImageUrl = info.parent && info.parent.images && info.parent.images[0]
    ? info.parent.images[0].url
    : productProps.defaultImage;

  const productClass = info.parent && info.parent.id === productId ? ' active' : '';

  return (
    <>
      <div
        onClick={() => editProduct(info.parent.id)}
        className={'product-edit-dialog-top-buttons' + productClass}
      >
        <div className="product-edit-parent-product-left">
          <div><IntlMessages id="components.product" /></div>
          <img  width="75px" alt={ productTitle } src={ productImageUrl } />
        </div>
        <div className="product-edit-parent-product-middle">
          <div>{ productTitle }</div>
          <div className="product-edit-qty"><IntlMessages id="components.quantity" />: { totalQty }</div>
        </div>
      </div>
      {
        info.variations && info.variations.map((item, index) => (
          <VariationInfo
            key={'variation-' + index}
            index={index}
            productId={productId}
            item={item}
            merchants={info.merchants}
            productTitle={productTitle}
            productImageUrl={productImageUrl}
            productAttributeProps={productAttributeProps}
            editProduct={editProduct}
          />
        ))
      }
    </>
  );
}
