/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import {
  func, arrayOf, string, shape, bool, oneOfType, node, number,
} from 'prop-types';
import { connect } from 'react-redux';

import { isEmpty, uniq } from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import FormDialog from '../FormDialog';
import CategoriesCheckboxTree from '../CategoriesCheckboxTree';
import ListCheckboxComponent from '../ListCheckbox';
import IntlMessages from '../../util/IntlMessages';
import { scopeValidations } from './utils/validations';
import checkValidations from '../../util/validator';
import InputErrors from '../InputErrors';

import {
  fetchRecursiveParentCategories,
  clearFromScopeCategoryState,
} from '../../actions/scopeCategory';

const ScopeDialog = memo((props) => {
  const [errors, setErrors] = useState({});
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState([]);
  const [multipleSelected, setMultipleSelected] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [keyboardKey, setKeyboardKey] = useState(false);

  useEffect(() => {
    const payload = props.selectedTreeItems.map((c) => {
      if (typeof c === 'string') return c;
      return c.id;
    });
    if (selected.some(s => payload.some(p => p !== s)) || isEmpty(selected)) {
      setSelected(payload);
    }
    setMultipleSelected(payload);
  }, [props.showScopeDialog, props.selectedTreeItems]);

  useEffect(() => {
    if (isEmpty(query) && keyboardKey === 'Backspace' && isSubmitted) {
      const payload = props.selectedTreeItems.map((c) => {
        if (typeof c === 'string') return c;
        return c.id;
      });
      setSelected(payload);
      setIsSubmitted(false);
      setKeyboardKey('');
      if (props.toogleTreeComponents) props.toogleTreeComponents();
    }
  }, [keyboardKey, query, props.search, isSubmitted]);

  useEffect(() => {
    if (isEmpty(props.selectedTreeItems)) {
      setSelected([]);
    }
  }, [props.treeItems, props.selectedTreeItems]);

  useEffect(() => {
    if (
      props.showScopeDialog
      && !isEmpty([...props.selectedTreeItems, ...selected])
      && !props.scopeCategory.fetchingParentCategoriesByIds
      && isEmpty(props.search.list)
    ) {
      const payload = [...props.selectedTreeItems, ...selected].map((c) => {
        if (typeof c === 'string') return c;
        return c.id;
      });
      const categoriesIds = uniq(payload);

      if (!isEmpty(categoriesIds) && props.scopeCategory.fetchRootFinished) {
        props.fetchRecursiveParentCategories(categoriesIds);
      }
    }
  }, [props.showScopeDialog, props.search.list]);

  const handleOnTreeSelect = (itemId) => {
    let selectedList = [...selected];

    if (!itemId) {
      if (props.onChange) props.onChange([]);
      return setSelected([]);
    }

    if (!selectedList.some(treeElId => treeElId === itemId)) {
      selectedList.push(itemId);
    } else {
      selectedList = selectedList.filter(elId => elId !== itemId);
    }

    if (props.onChange) props.onChange(selectedList);
    return setSelected(selectedList);
  };

  const handleSearchTreeSelect = (itemId) => {
    const selectedList = [...selected
      .map(el => (el && typeof el === 'string' ? el : el.id)), ...multipleSelected];
    const itemAlreadyExist = selectedList.some(s => (s.id || s) === itemId);

    if (!itemAlreadyExist) {
      const payload = [
        ...selected,
        { id: itemId },
      ];

      setSelected(payload);
      setMultipleSelected(payload.map(p => (p.id || p)));
      if (props.onChange) props.onChange(payload);
    } else {
      setSelected(selected.filter(s => (s.id || s) !== itemId));
      if (props.onChange) props.onChange(selected.filter(s => (s.id || s) !== itemId));
      setMultipleSelected(multipleSelected
        .filter(s => (s.id || s) !== itemId)
        .map(p => (p.id || p)));
    }

    return false;
  };

  const handleMultipleSelect = (itemsIds) => {
    setMultipleSelected(itemsIds);
  };

  const closeScopeDialog = () => {
    setSelected([]);
    props.closeScopeDialog();
    props.clearFromScopeCategoryState({
      searched: false,
    });
    if (props.onChange) props.onChange([]);
  };

  const onSubmit = () => {
    if (props.checkStrictly) props.onSubmit([...selected]);
    else props.onSubmit([...multipleSelected]);
    props.toogleTreeComponents();
  };

  const handleOnCategorySearch = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  const handleOnCategoryKeyPress = (e) => {
    const { search } = props;
    const { value } = e.target;
    const { charCode } = e;

    handleOnCategorySearch(e);

    if (charCode === 13 && value && value.length <= search.queryLengthLimit) {
      if (props.onSearchSubmit) {
        const newErrors = checkValidations(scopeValidations, { searchCategories: value });

        setErrors(newErrors);
        setIsSubmitted(true);
        if (isEmpty(newErrors)) props.onSearchSubmit(value);
      }
    }
  };

  const handleOnCategoryKeyDown = (e) => {
    const { which } = e;
    if (which === 8 && isSubmitted) {
      setKeyboardKey(e.key);
    }
  };

  const handleOnSearchSubmit = () => {
    const { search } = props;
    setIsSubmitted(true);
    if (query && query.length <= search.queryLengthLimit) {
      const newErrors = checkValidations(scopeValidations, { searchCategories: query });

      setErrors(newErrors);
      if (props.onSearchSubmit && isEmpty(newErrors)) {
        props.onSearchSubmit(query);
      }
    }
  };

  const {
    showScopeDialog,
    handleToggleCollapse,
    collapsed,
    treeItems,
    isRadio,
    dialog,
    search,
    multiple,
    loadAllChildCategories,
    extraClassName,
    showSecondaryTitle,
    loading,
  } = props;

  const treeSecondaryTitleClassName = showSecondaryTitle ? 'rct-with-secondary-title' : '';
  const loadingClass = loading ? 'disabled-list' : '';

  const useTreeComponent = (search.list && isEmpty(search.list))
    || (props.initOpen && !isEmpty(search.list));

  const disableNotSelected = props.maxSelected && props.maxSelected === selected.length;
  const wrapperElement = document.querySelector('.MuiDialogContent-root');

  return showScopeDialog && (
    <FormDialog
      className={`scope-dialog ${extraClassName}`}
      open={showScopeDialog}
      title={dialog.title}
      closeButtonTitle={dialog.closeButtonTitle}
      submitButtonTitle={dialog.submitButtonTitle}
      onClose={closeScopeDialog}
      onSubmit={onSubmit}
    >
      <Paper className={`${loadingClass} flex items-center mb-15 pos-rel`} elevation={1}>
        {search.noResult && (
          <span className="input-placeholder">
            <IntlMessages id="placeholder.noResult" />
          </span>
        )}
        {search.errorMessage && (!isEmpty(query) && query.length > search.queryLengthLimit) && (
          <span className="input-placeholder">
            <IntlMessages id={search.errorMessage} />
          </span>
        )}
        <input
          className="form-control form-control-clear block pl-15"
          placeholder={search.placeholder}
          onChange={handleOnCategorySearch}
          onKeyPress={handleOnCategoryKeyPress}
          onKeyDown={handleOnCategoryKeyDown}
          name="searchCategories"
        />
        <IconButton aria-label="Search" onClick={handleOnSearchSubmit}>
          <SearchIcon />
        </IconButton>
      </Paper>
      {
        errors.searchCategories && !Array.isArray(errors)
        && (
          <InputErrors errors={errors.searchCategories} />
        )
      }
      <Scrollbars
        className="rct-scroll rct-scroll-gray"
        autoHeight
        autoHeightMin={300}
        autoHeightMax={wrapperElement ? wrapperElement.clientHeight : 600}
        autoHide
        style={{ height: 'calc(100vh - 110px)', overflowX: 'hidden' }}
      >
        {
          useTreeComponent
            ? (
              <CategoriesCheckboxTree
                isRadio={isRadio}
                className={`tree-wrapper-secondary ${loadingClass} ${treeSecondaryTitleClassName}`}
                categories={treeItems}
                selected={selected}
                collapsed={collapsed}
                onSelect={handleOnTreeSelect}
                onMultipleSelect={handleMultipleSelect}
                onToggleCollapse={handleToggleCollapse}
                loadChildren={props.fetchChildCategories}
                checkedKeys={props.checkStrictly
                  ? selected
                  : multipleSelected
                }
                multiple={multiple}
                checkable
                loadAllChildCategories={loadAllChildCategories}
                showSecondaryTitle={showSecondaryTitle}
                disableNotSelected={disableNotSelected}
                {...props}
              />
            )
            : (
              <ListCheckboxComponent
                className={`list-checkbox-wrapper list-checkbox-sm ${treeSecondaryTitleClassName}`}
                isRadio={isRadio}
                list={search.list}
                enabledHoverEffects={showSecondaryTitle}
                selected={props.checkStrictly
                  ? selected
                  : multipleSelected
                }
                onChange={!props.checkStrictly
                  ? handleSearchTreeSelect
                  : handleOnTreeSelect
                }
                disableNotSelected={disableNotSelected}
              />
            )
        }
      </Scrollbars>
    </FormDialog>
  );
});

ScopeDialog.propTypes = {
  fetchChildCategories: func,
  handleToggleCollapse: func,
  closeScopeDialog: func,
  onSubmit: func.isRequired,
  toogleTreeComponents: func,
  showScopeDialog: bool,
  collapsed: arrayOf(string),
  isRadio: bool,
  treeItems: arrayOf(shape({
    id: string,
  })),
  dialog: shape({
    title: oneOfType([string, node]),
    closeButtonTitle: oneOfType([string, node]),
    submitButtonTitle: oneOfType([string, node]),
  }),
  search: shape({
    list: arrayOf(shape({
      id: string,
    })),
    placeholder: string,
    errorMessage: string,
    queryLengthLimit: number,
  }),
  onSearchSubmit: func.isRequired,
  multiple: bool,
  loadAllChildCategories: func,
  checkStrictly: bool,
  initOpen: bool,
  showSecondaryTitle: bool,
  extraClassName: string,
  loading: bool,
  selectedTreeItems: arrayOf(oneOfType([string, shape()])),
  fetchRecursiveParentCategories: func.isRequired,
  scopeCategory: shape().isRequired,
  maxSelected: number,
  onChange: func,

  clearFromScopeCategoryState: func.isRequired,
};

ScopeDialog.defaultProps = {
  fetchChildCategories: null,
  toogleTreeComponents: null,
  closeScopeDialog: null,
  handleToggleCollapse: null,
  showScopeDialog: false,
  collapsed: [],
  treeItems: [],
  isRadio: false,
  dialog: {
    title: '',
    closeButtonTitle: 'Close',
    submitButtonTitle: 'Submit',
  },
  search: {
    placeholder: 'Search',
    noResult: bool,
    errorMessage: '',
    queryLengthLimit: null,
  },
  multiple: false,
  loadAllChildCategories: null,
  checkStrictly: true,
  initOpen: false,
  showSecondaryTitle: true,
  extraClassName: '',
  loading: false,
  selectedTreeItems: [],
  maxSelected: null,
  onChange: null,
};

const mapStateToProps = ({
  scopeCategory,
}) => ({
  scopeCategory,
});

export const actionCreators = {
  fetchRecursiveParentCategories,
  clearFromScopeCategoryState,
};

export default connect(mapStateToProps, actionCreators)(ScopeDialog);
