import React, { useState, useEffect } from 'react';
import Search from '../../components/Search';
import Pagination from '../../../../components/Pagination';
import Sorting from '../../../../components/Sorting';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import IntlMessages from '../../../../util/IntlMessages';
import config from "../../../../util/config";
import { getPublishPrice, moneyFormat } from "../../../Pim/pages/Home/utils/productDataGenerator";
import PagePlaceholder from "../../../../components/PagePlaceholder";
import {initialTableScroll, moveTableScroll} from "../../../../util/table";

export const RepricerTable = (
  {
    getRepricerData,
    repricerFields,
    repricerData,
    repricerScroll,
    repricerCondition,
    currency,
    itemsPerPage,
  }) => {

  const [data, setData] = useState([]);
  const [allPages, setAllPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [repricerPopup, setRepricerPopup] = useState(null);

  useEffect(() => {
    const repricerFieldsKeys = Object.keys(repricerFields);

    const formatData = [];
    for (let i = 0; i < repricerData.length; i++) {
      if (repricerData[i]) {
        const formatDataItem = [];
        for (let j = 0; j < repricerFieldsKeys.length; j++) {
          if (repricerFieldsKeys[j] === 'product') {
            formatDataItem[j] = {
              id: repricerData[i].id,
              parentId: repricerData[i].parentId,
              urlKey: repricerData[i].urlKey,
              title: repricerData[i].title,
              zmid: repricerData[i].competitors.zmid,
              kmid: repricerData[i].competitors.kmid,
              price: repricerData[i].price,
              isDomestic: repricerData[i].isDomestic,
              data: repricerData[i].data,
            };
          } else if (repricerFieldsKeys[j] === 'competitors' || repricerFieldsKeys[j] === 'stats') {
            formatDataItem[j] = {
              competitors: repricerData[i].competitorsData,
              zmid: repricerData[i].competitors.zmid,
              kmid: repricerData[i].competitors.kmid,
              price: repricerData[i].price,
              isDomestic: repricerData[i].isDomestic,
              zap: repricerData[i].zap,
              ksp: repricerData[i].ksp,
              data: repricerData[i].data,
            };
          } else {
            formatDataItem[j] = repricerData[i];
          }
        }
        formatData[i] = formatDataItem;
      }
    }
    setData(formatData);

    setAllPages(Math.ceil(repricerScroll.total / itemsPerPage));
    const page = repricerScroll.offset ? Math.ceil(repricerScroll.offset / itemsPerPage) + 1 : 1;
    const currentPage = (page <= 0) ? 1 : page;
    setCurrentPage(currentPage);
  }, [
    repricerData, repricerScroll, repricerFields, itemsPerPage
  ]);

  const getTableRowColor = (itemKey) => {
    return itemKey % 2 ? '#dedede' : '#ffffff';
  };

  const handleShowPricePopup = (id) => {
    setRepricerPopup(id);
  };

  const handleHidePricePopup = () => {
    setRepricerPopup(null);
  };

  const RepricerTitle = ((props) => {
    const { item, itemKey, field, name, sortable } = props;

    const tdId = document.getElementById('td-0-' + itemKey);
    const width = tdId ? tdId.getBoundingClientRect().width + 'px' : 'auto';

    const style = { minWidth: width };

    if (sortable === true) {
      const className = "mapper-sortable";
      return (
        <Sorting
          getData={getRepricerData}
          item={field ? field : item}
          condition={repricerCondition}
          name={name}
          className={className}
          width={width}
          itemsPerPage={itemsPerPage}
        />
      );
    }

    return (
      <TableCell style={ style }>
        <div className="mapper-no-wrap">{ name }</div>
      </TableCell>
    );
  });

  const Competitor = ((props) => {
    const { store, storeKey, roundPublishPrice, competitor } = props;
    if (storeKey > 4) {
      return (<></>);
    }

    const today = new Date();
    const date = competitor ? new Date(competitor.updatedAt) : null;
    const daysSinceLastChange = date ? new Date(today.getTime() - date.getTime()).getUTCDate() - 1 : null;
    const position = store && store.position ? store.position : null;
    const storeName = store && store.storeName ? store.storeName : null;
    const isAOStore = storeName === 'American Outlets';

    const storePrice = store && store.price ? Math.round(store.price.toFixed(2)) : null;
    const priceDiff = store && storePrice ? 100 - Math.round((storePrice * 100) / roundPublishPrice) : null;

    return (
      <div style={{ whiteSpace: 'nowrap' }}>
        {position && (
          <span>
            {position}.
            &nbsp;
          </span>
        )}

        {competitor && competitor.name && (
          <span>
            {competitor.name}:
            &nbsp;
          </span>
        )}

        {daysSinceLastChange !== null && (
          <span>
            {daysSinceLastChange} days
            &nbsp;
          </span>
        )}

        {priceDiff && storePrice > roundPublishPrice && (
          <span style={{ color: '#00A000' }}>
            {priceDiff * -1}% &#8593;
            &nbsp;
          </span>
        )}

        {priceDiff && storePrice === roundPublishPrice && (
          <span>
            {priceDiff}%
            &nbsp;
          </span>
        )}

        {priceDiff && storePrice < roundPublishPrice && (
          <span style={{ color: '#A9203E' }}>
            {priceDiff}% &#8595;
            &nbsp;
          </span>
        )}

        {store && storePrice && competitor.currency === 'ILS' && (
          <span>
            {moneyFormat(storePrice, '₪')}
            &nbsp;
          </span>
        )}

        {storeName && (
          <span>
            &nbsp;-&nbsp;
            {isAOStore ? (
              <>
                <img alt={storeName} src="https://stageamericanoutlets.com/static/favicon-16x16.png" />
                &nbsp;
                <strong>{storeName}</strong>
              </>
            ) : (
              <>{storeName}</>
            )}
            &nbsp;
          </span>
        )}
      </div>
    );
  });

  const getProductUrl = (item) => {
    const url = item.urlKey ? item.urlKey.en : '';

    return config.websitePath + '/' + url + '/' + item.data.parentId + '?variantId=' + item.id;
  }

  const RepricerItem = ((props) => {
    const {
      item, itemKey, valueKey, type, width
    } = props;

    const id = 'td-' + itemKey + '-' + valueKey;
    const style = { minWidth: width };

    const publishPrice = item && item.price
      ? getPublishPrice(item.price.finalPrice, currency.value, item.isDomestic)
      : 0;
    const roundPublishPrice = Math.round(publishPrice.toFixed(2));

    if (type === 'image') {
      const noImage = 'http://www.netzerotools.com/assets/images/msa-10162695-workman-arc-flash-full-body-harness.png';

      return (
        <TableCell id={ id } className="mapper-td" style={ style }>
          <div className="repricer-image">
            <a href={getProductUrl(item)}
               target="_blank"
               rel="noopener noreferrer"
            >
              {(item && item.data && item.data.images && item.data.images[0] && item.data.images[0].url) ? (
                <img src={item.data.images[0].url} alt="productName" width="100" />
              ) : (
                <img src={noImage} alt="productName" width="100" />
              )}
            </a>
          </div>
        </TableCell>
      );
    }

    if (type === 'product' && item) {
      const zmidProductUrl = item.zmid && item.zmid[0]
        ? config.zapWebsitePath.replace('%id%', item.zmid[0])
        : null;
      const kmidProductUrl = item.kmid && item.kmid[0]
        ? config.zapWebsitePath.replace('%id%', item.kmid[0])
        : null;

      const productName = item.title ? item.title.en : item.id;

      return (
        <TableCell id={ id } className="mapper-td" style={ style }>
          <a href={getProductUrl(item)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {productName}
          </a>
          {item.zmid && item.zmid[0] && (
            <div className="repricer-model-name">
              <IntlMessages id="preview.edit.label.zap_model_id" />:&nbsp;
              <a href={zmidProductUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="repricer-link"
              >
                {item.zmid[0]}
              </a>
            </div>
          )}
          {item.kmid && item.kmid[0] && (
            <div className="repricer-model-name">
              <IntlMessages id="preview.edit.label.ksp_model_id" />:&nbsp;
              <a href={kmidProductUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="repricer-link"
              >
                {item.kmid[0]}
              </a>
            </div>
          )}
        </TableCell>
      );
    } else if (type === 'competitors' && item) {
      return (
        <TableCell id={ id } className="mapper-td" style={ style }>

          {item.zap && item.zap.stores && item.zap.stores.map((store, storeKey) => (
            <Competitor
              key={storeKey}
              storeKey={storeKey}
              store={store}
              roundPublishPrice={roundPublishPrice}
              competitor={item.competitors.zap}
            />
          ))}

          {item.ksp && item.ksp.stores && item.ksp.stores.map((store, storeKey) => (
            <Competitor
              key={storeKey}
              storeKey={storeKey}
              store={store}
              roundPublishPrice={roundPublishPrice}
              competitor={item.competitors.ksp}
            />
          ))}

        </TableCell>
      );
    } else if (type === 'stats' && item) {
      const totalCompetitors = item.competitors.zap && item.competitors.ksp ? 2 : 1;

      const zapPriceDiff = item.competitors.zap ? item.competitors.zap.price - roundPublishPrice : null;
      const kspPriceDiff = item.competitors.ksp ? item.competitors.ksp.price - roundPublishPrice : null;

      const zapUpdatedAt = item.competitors.zap ? item.competitors.zap.updatedAt : null;
      const kspUpdatedAt = item.competitors.ksp ? item.competitors.ksp.updatedAt : null;

      style.whiteSpace = 'nowrap';

      return (
        <TableCell id={ id } className="mapper-td" style={ style }>
          <IntlMessages id="competitors.total" />: {totalCompetitors}
          <br />
          {zapPriceDiff && zapPriceDiff >= 0 && (
            <span>
              <IntlMessages id="competitors.zapPriceDiff" />:
              <span style={{ color: '#00A000' }}>
                {moneyFormat(zapPriceDiff, '₪')}
              </span>
            </span>
          )}
          {zapPriceDiff && zapPriceDiff < 0 && (
            <span>
              <IntlMessages id="competitors.zapPriceDiff" />:
              &nbsp;
              <span style={{ color: '#A9203E' }}>
                {moneyFormat(zapPriceDiff * -1, '₪')}
              </span>
            </span>
          )}
          <br />
          {kspPriceDiff && kspPriceDiff >= 0 && (
            <span>
              <IntlMessages id="competitors.kspPriceDiff" />:
              &nbsp;
              <span style={{ color: '#A9203E' }}>
                {moneyFormat(kspPriceDiff, '₪')}
              </span>
            </span>
          )}
          {kspPriceDiff && kspPriceDiff < 0 && (
            <span>
              <IntlMessages id="competitors.kspPriceDiff" />:
              &nbsp;
              <span style={{ color: '#00A000' }}>
                {moneyFormat(kspPriceDiff * -1, '₪')}
              </span>
            </span>
          )}
          <br />
          {zapUpdatedAt && (
            <span>
              <IntlMessages id="competitors.zapUpdatedAt" />:
              &nbsp;
              <span style={{ color: '#00A000' }}>
                {zapUpdatedAt}
              </span>
            </span>
          )}
          <br />
          {kspUpdatedAt && (
            <span>
              <IntlMessages id="competitors.kspUpdatedAt" />:
              &nbsp;
              <span style={{ color: '#00A000' }}>
                {kspUpdatedAt}
              </span>
            </span>
          )}
        </TableCell>
      );
    } else if (type === 'statuses' && item) {
      const stockStatus = item.data && item.data.stock
        ? item.data.stock.status.replace('_', ' ').replace('_', ' ')
        : '';
      const visibilityStatus = item.data && item.data.visibilityStatus
        ? item.data.visibilityStatus.replace('_', ' ').replace('_', ' ')
        : '';

      style.whiteSpace = 'nowrap';

      const displayedPrice = item.price && item.price.publishPrice ? item.price.publishPrice : roundPublishPrice;

      style.whiteSpace = 'nowrap';

      return (
        <TableCell id={ id } className="mapper-td" style={ style }>
          {item.data.id && (
            <div><IntlMessages id="preview.edit.label.variation_id" />: &nbsp;
              <span><strong>{item.data.id}</strong></span>
            </div>
          )}
          {displayedPrice && (
            <>
              <div>
                <IntlMessages id="repricer.variationPrice" />: &nbsp;
                <span style={{ color: '#5D92F4', cursor: 'pointer' }} onMouseOver={() => handleShowPricePopup(item.id)}>
                  { moneyFormat(displayedPrice, '₪')}
                </span>
              </div>

              {repricerPopup === item.id && (
                <div className="repricer-popup-container" onMouseOut={() => handleHidePricePopup()}>
                  <div className="repricer-popup">
                    <div className="repricer-popup-item">
                      <IntlMessages id="merhcant.container.edit.price.profit.label" />:
                      &nbsp;
                      <strong>{item.price.profit}</strong>
                    </div>
                    <div className="repricer-popup-item">
                      <IntlMessages id="merhcant.container.edit.price.lostProfit.label" />:
                      &nbsp;
                      <strong>{Math.round((item.price.profit / item.price.cost) * 100)} %</strong>
                    </div>
                    <div className="repricer-popup-item">
                      <IntlMessages id="merhcant.container.edit.price.shippingCost.label" />:
                      &nbsp;
                      <strong>{item.price.shippingCost}</strong>
                    </div>
                    <div className="repricer-popup-item">
                      <IntlMessages id="merhcant.container.edit.price.netCost.label" />:
                      &nbsp;
                      <strong>{item.price.cost}</strong>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {item.data.stock && (
            <div><IntlMessages id="repricer.stock.qty" />: &nbsp;
              <span><strong>{item.data.stock.qty}</strong></span>
            </div>
          )}
          {item.visibilityStatus === 'hidden_by_repricing' && (
            <div><IntlMessages id="repricer.expensive" /></div>
          )}
          {item.isOnFeeds && item.isOnFeeds.zap === true && (
            <div><IntlMessages id="repricer.isOnFeedZap" /></div>
          )}
          {item.isOnFeeds && item.isOnFeeds.ksp === true && (
            <div><IntlMessages id="repricer.isOnFeedKsp" /></div>
          )}

          {item.data.stock && (
            <div><IntlMessages id="repricer.stock.status" />: &nbsp;
              <span style={{ textTransform: 'capitalize' }}><strong>{stockStatus}</strong></span>
            </div>
          )}
          {item.data.visibilityStatus && (
            <div><IntlMessages id="repricer.visibilityStatus" />: &nbsp;
              <span style={{ textTransform: 'capitalize' }}><strong>{visibilityStatus}</strong></span>
            </div>
          )}
        </TableCell>
      );
    }

    return (<TableCell id={ id } className="mapper-td" style={ style } />);
  })

  initialTableScroll(
    'repricerTable',
    'repricerHeadTable',
    'repricerTableContainer',
    'repricerHeadTableContainer'
  );

  const moveScroll1 = () => {
    moveTableScroll('repricerTableContainer', 'repricerHeadTableContainer');
  };

  const moveScroll2 = () => {
    moveTableScroll('repricerHeadTableContainer', 'repricerTableContainer');
  };

  return (
    <>
      <div className="sticky-search">
        <Search
          repricerFields={repricerFields}
          itemsPerPage={itemsPerPage}
          data={data}
          scroll={repricerScroll}
          condition={repricerCondition}
        />

        <TableContainer id="repricerHeadTableContainer" className="product-table-container"  onScroll={moveScroll1}>
          <Table id="repricerHeadTable" stickyHeader aria-label="sticky table" size="small">
            <TableHead style={{ position: 'sticky', top: '0', height: '44px' }}>
              <TableRow>
                {Object.keys(repricerFields).map((item, itemKey) => (
                  <RepricerTitle
                    key={itemKey}
                    itemKey={itemKey}
                    item={item}
                    field={Object.values(repricerFields)[itemKey].field}
                    name={Object.values(repricerFields)[itemKey].name}
                    sortable={Object.values(repricerFields)[itemKey].sortable}
                  />
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </div>

      {data.length > 0 ? (
        <TableContainer id="repricerTableContainer" className="product-table-container" onScroll={moveScroll2}>
          <Table id="repricerTable" stickyHeader aria-label="sticky table" size="small">
            <TableBody>
              {data && data.map((item, itemKey) =>
                <TableRow key={itemKey} style={{ backgroundColor: getTableRowColor(itemKey) }}>
                  {Object.keys(repricerFields).map((value, valueKey) => (
                    <RepricerItem
                      key={valueKey}
                      itemKey={itemKey}
                      valueKey={valueKey}
                      item={item[valueKey]}
                      type={Object.values(repricerFields)[valueKey].type}
                      width={Object.values(repricerFields)[valueKey].width}
                    />
                  ))}
                </TableRow>
              )}

            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <PagePlaceholder
          icon={<i className="ti-face-sad text-primary text-lg" />}
          title={<IntlMessages id="title.sorry" />}
          subtitle={<IntlMessages id="title.noResultsFound" />}
        />
      )}

      <div className="repricer-pagination">
        <Pagination
          getData={getRepricerData}
          dataCount={data ? data.length : 0}
          itemsPerPage={itemsPerPage}
          allPages={allPages}
          currentPage={currentPage}
          scroll={repricerScroll}
          condition={repricerCondition}
        />
      </div>
    </>
  );
};

export default RepricerTable;
