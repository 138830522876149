import firebase from 'firebase/app';
import { camelizeKeys } from 'humps';

import RestActions from '../util/rest/actions';
import { defaultAttributesPath } from '../util/paths';
import { showErrorNotification } from '../util/api';

const restDefaultAttributesActions = new RestActions('default_attribute');

export const fetchListDefaultAttributes = () => async (dispatch) => {
  dispatch(restDefaultAttributesActions.fetchAllStart());
  let defaultAttributesResponse;
  try {
    defaultAttributesResponse = await firebase.database().ref(defaultAttributesPath).once('value');
  } catch (error) {
    showErrorNotification(error, 'Firebase');
    dispatch(restDefaultAttributesActions.fetchAllFinished({ hasError: true }));
  }

  const defaultAttributes = defaultAttributesResponse.val();
  dispatch(restDefaultAttributesActions.fetchAllFinished(camelizeKeys(defaultAttributes)));
};

export default {
  fetchListDefaultAttributes,
};
