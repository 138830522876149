import React from 'react';
import {
  bool, string, oneOfType, node,
} from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from '../../util/IntlMessages';

const CircularProggressComponent = props => (
  props.loading && (
    <div className={`pos-fixed loader-wrapper-inverse ${props.className || ''}`}>
      <div className="loader-inner">
        <CircularProgress
          className="progress-primary mr-30 mb-10"
          size={props.size}
          mode="determinate"
          value={props.value}
          variant="indeterminate"
          disableShrink
          thickness={4}
        />
        {props.title && (
          <div className="loader-title">
            <IntlMessages id={props.title} />
          </div>
        )}
        {props.subtitle && (
          <div className="loader-subtitle">
            <IntlMessages id={props.subtitle} />
          </div>
        )}
        {props.message && (
          <div className="loader-message">{props.message}</div>
        )}
      </div>
    </div>
  )
);

CircularProggressComponent.propTypes = {
  loading: bool.isRequired,
  title: string,
  message: oneOfType([node, string]),
};

CircularProggressComponent.defaultProps = {
  size: 40,
  value: 75,
};

export default CircularProggressComponent;