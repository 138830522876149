/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import {
  shape, arrayOf, bool,
} from 'prop-types';

import {
  CircularProgress, List, ListItem, ListItemText,
} from '@material-ui/core';

const InfoPopupContent = memo((props) => {
  const {
    categories, loading,
  } = props;

  return (
    <div className="product-info-content flex">
      {loading ? (
        <CircularProgress className="product-info-loader" size={20} disableShrink />
      ) : (
        <List component="nav">
          {categories.map(c => (
            <ListItem key={c.id} button>
              <ListItemText primary={c.pathByName.en} />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
});

InfoPopupContent.propTypes = {
  categories: arrayOf(shape()).isRequired,
  loading: bool,
};

InfoPopupContent.defaultProps = {
  loading: false,
};

export default InfoPopupContent;
