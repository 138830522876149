import { isEmpty } from 'lodash';

export default productList => (!isEmpty(productList)
  ? productList.map((product) => {
    if (product.description || product.title) {
      return ({
        ...product,
        image: !isEmpty(product.images) ? [...product.images].shift() : {},
        disabled: false,
        checked: false,
        attribute: {
          title: product.title || '',
          price: `${product.minPrice ? `$${product.minPrice}` : ''}`,
          description: product.description || '',
          pubAopi: product.pubAopi,
        },
      });
    }
    return ({
      ...product,
      disabled: true,
      deleted: true,
      checked: false,
      image: {},
      messageId: 'transfer.list.product.deleted',
      messageType: 'danger',
      attribute: {
        title: '',
        price: '',
        description: '',
        pubAopi: '',
      },
    });
  })
  : []);
