import React, { Fragment } from 'react';
import {
  string, arrayOf, shape, oneOfType,
} from 'prop-types';
import { camelize } from 'humps';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export const TableComponent = ({
  wrapperClassName, rows, cells, align, className,
}) => {
  const formattedCells = cells.map(c => camelize(c));

  return (
    <div className={`table-responsive ${wrapperClassName}`}>
      <Table className={className}>
        <TableHead>
          <TableRow hover>
            {
              cells.map(c => (
                <TableCell key={c} align={align}>{c}</TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          <Fragment>
            {rows.map(r => (
              <TableRow hover key={r.id}>
                {
                  formattedCells.map(c => (
                    <TableCell key={c} align={align}>{r[c]}</TableCell>
                  ))
                }
              </TableRow>
            ))}
          </Fragment>
        </TableBody>
      </Table>
    </div>
  );
};

TableComponent.propTypes = {
  wrapperClassName: string,
  className: string,
  rows: arrayOf(oneOfType([
    shape(), string,
  ])),
  cells: arrayOf(oneOfType([
    shape(), string,
  ])),
  align: string,
};

TableComponent.defaultProps = {
  wrapperClassName: '',
  className: '',
  rows: [],
  cells: [],
  align: '',
};

export default TableComponent;
