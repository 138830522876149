/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { uniq, isEmpty } from 'lodash';

export const useInfluencer = (props) => {
  useEffect(() => {
    if (
      props.offerCreated
      && props.goalCreated
      && props.trackingGenerated
      && !isEmpty(props.influencer)
    ) {
      const { publicNetworkId } = props.tuneSettings;
      const offerId = props.createdOffer.id;
      const { offerUrl } = props.createdOffer;
      const { name: goalName } = props.createdGoal;

      props.updateInfluencer({
        ...props.influencer,
        tuneOffer: props.createdOffer,
        tuneGoal: props.createdGoal,
        trackingInfo: {
          ...props.influencer.trackingInfo,
          trackingUrl: props.generatedTrackingUrls.clickUrl,
          offerPostbackUrl: `https://${publicNetworkId}.go2cloud.org/aff_lsr?offer_id=${offerId}&aff_id=AFFILIATE_ID&amount=AMOUNT`,
          goalPostbackUrl: `https://${publicNetworkId}.go2cloud.org/aff_goal?a=lsr&goal_name=${goalName}&adv_id=0&offer_id=${offerId}&amount=AMOUNT&aff_id=AFFILIATE_ID`,
          offerUrl,
        },
      });
    }
  }, [
    props.offerCreated,
    props.goalCreated,
    props.trackingGenerated,
  ]);

  useEffect(() => {
    if (props.influencerUpdated) {
      props.fetchInfluencer(props.influencer.id);
    }
  }, [props.influencerUpdated]);

  useEffect(() => {
    if (props.influencerFetched && !isEmpty(props.influencer)) {
      const { topTen, deal, productSection } = props.influencer.page;
      const topTenFilters = topTen.filters || [];
      const dealFilters = deal.filters || [];
      const productSectionFilters = productSection.filters || [];

      const filters = [
        ...topTenFilters,
        ...dealFilters,
        ...productSectionFilters,
      ];
      const codes = uniq(filters.map(f => f.field).flat());
      const options = uniq(filters.map(f => f.value).flat());

      if (!isEmpty(codes) && !isEmpty(options)) {
        props.fetchInitialAttributeOptions(codes, options);
      }
    }
  }, [props.influencerFetched]);
};

export default useInfluencer;
