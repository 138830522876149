import React, { memo } from 'react';
import {
  string, node, func, shape, bool, arrayOf,
} from 'prop-types';
import { isEmpty } from 'lodash';

import DefaultFilterRow from './DefaultRow';
import AttributeRow from './AttributeRow';

const FilterByBody = memo((props) => {
  const {
    defaultRowSource, className, attributeRowSource, filters, parentFilters, parentOptions, errors,
    defaultRow, loading, disabled, isParent, hasPermissions, handleSearchOptions, loadOptions, onBlur,
  } = props;

  return (
    <div className={className}>
      <DefaultFilterRow {...defaultRowSource} disabled={disabled} />
      {defaultRow}
      {!isEmpty(filters) && filters.map((f, index) => (
        <AttributeRow
          filters={filters}
          parentFilters={parentFilters}
          key={filters.indexOf(f)}
          filterRowIndex={index}
          errors={errors[index]}
          row={f}
          loading={loading}
          disabled={disabled}
          isParent={isParent}
          parentOptions={parentOptions}
          hasPermissions={hasPermissions}
          handleSearchOptions={handleSearchOptions}
          onBlur={onBlur}
          loadOptions={loadOptions}
          {...attributeRowSource}
        />
      ))}
    </div>
  );
});

FilterByBody.propTypes = {
  className: string,
  defaultRowSource: shape({
    isSwitchOn: bool,
    selectCategoriesButton: node,
    onSwitchClick: func,
  }),
  attributeRowSource: shape({
    onSelect: func,
  }),
  filters: arrayOf(shape({
    condition: string,
  })),
  errors: arrayOf(shape({
    condition: string,
  })),
  parentFilters: arrayOf(shape()),
  parentOptions: arrayOf(shape()),
  defaultRow: node,
  loading: bool,
  disabled: bool,
  isParent: bool,
  hasPermissions: bool,
  loadOptions: bool.isRequired,
  handleSearchOptions: func,
  onBlur: func.isRequired,
};

FilterByBody.defaultProps = {
  className: '',
  defaultRowSource: {
    isSwitchOn: false,
    selectCategoriesButton: null,
    onSwitchClick: null,
  },
  attributeRowSource: {
    onSelect: null,
  },
  filters: [],
  parentFilters: [],
  errors: [],
  parentOptions: [],
  defaultRow: null,
  loading: false,
  disabled: false,
  isParent: false,
  hasPermissions: false,
  handleSearchOptions: null,
};

export default FilterByBody;
