import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const normalizationListSelector = state => state.normalizations;
const pushingListSelector = state => state.pushings;
const rankingAttributeListSelector = state => state.rankingAttributes

export const normalizationSelector = createSelector(
  normalizationListSelector,
  list => {
    return {
      list: !isEmpty(list) ? list.map(l => ({
        title: l.name,
        value: l.value,
      })) : [],
    }
  }
);

export const pushingSelector = createSelector(
  pushingListSelector,
  list => {
    return {
      list: !isEmpty(list) ? list.map(l => ({
        title: l.name,
        value: l.value,
      })) : [],
    }
  }
);

export const rankingAttributeSelector = createSelector(
  rankingAttributeListSelector,
  list => {
    return {
      list: !isEmpty(list) ? list.map(l => ({
        title: l.name,
        value: l.value,
      })) : [],
    }
  }
);

export default {
  normalizationSelector,
  pushingSelector,
  rankingAttributeSelector,
};
