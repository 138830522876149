import {
  shape, func,
} from 'prop-types';

export const propTypes = {
  navigation: shape({}).isRequired,
  fetchNavigationList: func.isRequired,
  fetchNavigation: func.isRequired,
  fetchAttributesMatrix: func.isRequired,
  fetchListDefaultAttributes: func.isRequired,
  searchNavigationFilters: func.isRequired,
  searchPromotions: func.isRequired,
};

export const defaultProps = {};

export default {
  propTypes,
  defaultProps,
};
