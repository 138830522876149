import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  PRODUCT_SET_ERRORS,
  PRODUCT_SET_FIELDS,
  PRODUCT_FETCH_CHILD_PRODUCTS_START,
  PRODUCT_FETCH_CHILD_PRODUCTS_FINISHED,
  PRODUCT_FETCH_PRODUCT_COMMENTS_START,
  PRODUCT_FETCH_PRODUCT_COMMENTS_FINISHED,
  PRODUCT_SET_PRODUCT_FOR_EDIT,
  PRODUCT_SET_PRODUCT_CUSTOM_COLUMNS,
  PRODUCT_FETCH_PURE_PRODUCT_START,
  PRODUCT_FETCH_PURE_PRODUCT_FINISHED,
  PRODUCT_BULK_UPDATE_ACTIVITY_LOGS_START,
  PRODUCT_BULK_UPDATE_ACTIVITY_LOGS_FINISHED,
  PRODUCT_SET_IS_SAVE_AND_CLOSE,
  PRODUCT_FINISH_PRODUCT_EDIT,
  PRODUCT_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START,
  PRODUCT_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED,
  PRODUCT_SET_SPECIFICATIONS_ERRORS,
} from '../actions/types';

const initialState = {
  defaultImage: 'http://www.netzerotools.com/assets/images/msa-10162695-workman-arc-flash-full-body-harness.png',
  item: {},
  edit: {},
  info: {},
  errors: {},
  fields: {},
  childList: [],
  productComments: [],
  specificationErrors: {},
  isSaveAndClose: false,
  editInProgress: false,
  nextProductLoading: false,
  pimOptions: [],
  childProductsFetching: false,
  childProductsFetched: false,
  fetchProductCommentsStart: false,
  fetchProductCommentsFinished: false,
  fetchingPureProduct: false,
  fetchedPureProduct: false,
  bulkUpdateActivityLogsStart: false,
  bulkUpdateActivityLogsFinished: false,
  bulkDeleteProductLockedAttributesStarted: false,
  bulkDeleteProductLockedAttributesFinished: false,
};

const product = restTypes('product');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case product.fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };
    case product.fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case product.updateStart:
      return {
        ...state,
        ...restStates.updateStart,
      };
    case product.updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };
    case product.mergeSearchStart:
      return {
        ...state,
        ...restStates.mergeSearchStart,
      };
    case product.mergeSearchFinished:
      return {
        ...state,
        ...restStates.mergeSearchFinished,
        ...action.payload,
      };
    case product.mergeStart:
      return {
        ...state,
        ...restStates.mergeStart,
      };
    case product.mergeFinished:
      return {
        ...state,
        ...restStates.mergeFinished,
        ...action.payload,
      };

    case PRODUCT_SET_ERRORS:
    case PRODUCT_SET_FIELDS:
    case PRODUCT_FETCH_CHILD_PRODUCTS_START:
    case PRODUCT_FETCH_CHILD_PRODUCTS_FINISHED:
    case PRODUCT_FETCH_PRODUCT_COMMENTS_START:
    case PRODUCT_FETCH_PRODUCT_COMMENTS_FINISHED:
    case PRODUCT_SET_PRODUCT_FOR_EDIT:
    case PRODUCT_SET_PRODUCT_CUSTOM_COLUMNS:
    case PRODUCT_FETCH_PURE_PRODUCT_START:
    case PRODUCT_FETCH_PURE_PRODUCT_FINISHED:
    case PRODUCT_BULK_UPDATE_ACTIVITY_LOGS_START:
    case PRODUCT_BULK_UPDATE_ACTIVITY_LOGS_FINISHED:
    case PRODUCT_SET_IS_SAVE_AND_CLOSE:
    case PRODUCT_FINISH_PRODUCT_EDIT:
    case PRODUCT_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START:
    case PRODUCT_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED:
    case PRODUCT_SET_SPECIFICATIONS_ERRORS:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
};
