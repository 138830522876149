import { createSelector } from 'reselect';

const previewRootSelector = state => state;

const stateSelector = createSelector(
  previewRootSelector,
  attributesMatrix => ({
    attributesMatrixFetched: attributesMatrix.fetched,
    attributesMatrixList: attributesMatrix.list,
  }),
);

export const attributesMatrixSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  attributesMatrixSelector,
};
