export const updateProductInList = (list, product) => {
  if (!product || !list) {
    return false;
  }
  const variantId = product.parentId;
  const editedProdId = product.id;
  return [...list].map(((parent) => {
    if (parent.childrenIds && parent.childrenIds.includes(variantId)) {
      const variants = parent.variants.map((variant) => {
        if (variant.id === variantId) {
          const merchants = variant.merchants.map((merchant) => {
            if (merchant.id === editedProdId) {
              return { ...product };
            }
            return { ...merchant };
          });
          return { ...variant, merchants };
        }
        return { ...variant };
      });
      return { ...parent, variants };
    }
    return { ...parent };
  }));
};

export default updateProductInList;
