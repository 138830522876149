/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { camelize, decamelize } from 'humps';
import {
  func, string, shape, bool, arrayOf,
} from 'prop-types';
import {
  Paper, Button, CircularProgress,
} from '@material-ui/core';

// import Sidebar from '../components/Sidebar';
import InfluencerInfoContainer from './InfluencerInfoContainer';
import TopTenContainer from './TopTenContainer';
import MainBannerContainer from './MainBannerContainer';
import ProductContainer from './ProductContainer';
import NewDealContainer from './NewDealContainer';

import Header from '../../../components/Header';
import ButtonGroup from '../../../components/ButtonGroup';

import buttonList from '../../../util/buttonList';
import IntlMessages from '../../../util/IntlMessages';

import {
  getEditInfluencerPath,
  getAvatarPath,
  getMainBannerPath,
  getDealBannerPath,
} from '../utils/paths';

import {
  systemAppPath,
} from '../../../util/paths';
import roles from '../../../util/roles';

import {
  updateInfluencerFields,
  setAvatarData,
  setMainBannerData,
  setDealBannerData,
} from '../../../actions/influencer';

import { fetchTuneSettings } from '../../../actions/tune';

const Influencer = memo((props) => {
  const {
    influencer,
    contentContainerClassName,
    handleSubmitChanges,
    fetchedOne,
    sessionUserId,
    tuneSettings,
    offerCreating,
    goalCreating,
    trackingGenerating,
    influencerUpdating,
    sessionUserRoles,
  } = props;

  useEffect(() => {
    const { pathName } = window.location;
    const currentId = props.match.params.id;
    const path = currentId && currentId !== sessionUserId
      ? getEditInfluencerPath(currentId)
      : getEditInfluencerPath(sessionUserId);
    const isInfluencer = sessionUserRoles.includes(roles.influencer.name);

    props.fetchTuneSettings();
    if (isInfluencer && path !== pathName) props.history.push(path);
  }, []);

  const handleInfluencerChange = (e) => {
    const { value, name } = e.target;

    props.updateInfluencerFields({
      ...influencer,
      [name]: value,
    });
  };

  const getParseStoreLink = (value) => {
    const { storeLink } = tuneSettings;
    const parsedDealName = value
      ? decamelize(camelize(value))
      : '';

    return `${storeLink}/${parsedDealName}`;
  };

  const handleInfluencerPageChange = (e) => {
    const { value, name } = e.target;

    if (name === 'storeName') {
      return props.updateInfluencerFields({
        ...influencer,
        page: {
          ...influencer.page,
          [name]: value,
          storeLink: getParseStoreLink(value),
        },
      });
    }

    return props.updateInfluencerFields({
      ...influencer,
      page: {
        ...influencer.page,
        [name]: value,
      },
    });
  };

  const handleAvatarChange = ({ url, name }) => {
    const nameWithPath = getAvatarPath(influencer.id);

    props.setAvatarData({ url, name, nameWithPath });
    props.updateInfluencerFields({
      ...influencer,
      photoUrl: nameWithPath,
    });
  };

  const handleMainBannerChange = ({ url, name }) => {
    const nameWithPath = getMainBannerPath(influencer.id);

    props.setMainBannerData({ url, name, nameWithPath });
    props.updateInfluencerFields({
      ...influencer,
      page: {
        ...influencer.page,
        bannerImageUrl: nameWithPath,
      },
    });
  };

  const handleDealBannerChange = ({ url, name }) => {
    const nameWithPath = getDealBannerPath(influencer.id);

    props.setDealBannerData({ url, name, nameWithPath });
    props.updateInfluencerFields({
      ...influencer,
      page: {
        ...influencer.page,
        deal: {
          ...influencer.page.deal,
          bannerImageUrl: nameWithPath,
        },
      },
    });
  };

  const handleSubmit = () => handleSubmitChanges();

  const listParams = {
    disabled: false,
  };

  const loading = (
    offerCreating
    || goalCreating
    || trackingGenerating
    || influencerUpdating
  );

  const isReadOnlyMode = sessionUserId !== props.match.params.id;

  const handleClosePreview = () => {
    props.history.push(`${systemAppPath}/users`);
  };

  if (!fetchedOne) {
    return (
      <div className="loader-cover flex justify-center items-center">
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className="progress-warning custom-loader influencer cover"
          size={30}
          thickness={4}
        />
      </div>
    );
  }

  return (
    <>
      <div className="app-header">
        <Header
          horizontalMenu
          header={{
            className: 'header-mer',
          }}
          subHeaderContent={isReadOnlyMode && (
            <Button
              className="ml-15"
              variant="contained"
              color="primary"
              onClick={handleClosePreview}
            >
              <IntlMessages id="influencer.button.preview" />
            </Button>
          )}
          content={(
            <ButtonGroup
              materialBtn
              className="buttons-group-influencer"
              list={buttonList(listParams)}
            />
          )}
        />
      </div>
      <div className="influencers-content-wrapper">
        <Paper className="influencers-content-paper">
          <div className={`${contentContainerClassName} flex justify-center items-center direction-column`}>
            <InfluencerInfoContainer
              handleInfluencerChange={handleInfluencerChange}
              handleInfluencerPageChange={handleInfluencerPageChange}
              handleAvatarChange={handleAvatarChange}
              disabled={isReadOnlyMode}
            />
          </div>
        </Paper>
        <Paper className="influencers-content-paper">
          <div className={`${contentContainerClassName} flex justify-center items-center direction-column`}>
            <MainBannerContainer
              handleBannerChange={handleMainBannerChange}
              disabled={isReadOnlyMode}
            />
          </div>
        </Paper>
        <Paper className="influencers-content-paper">
          <div className={`${contentContainerClassName} flex justify-center items-end direction-column`}>
            <TopTenContainer disabled={isReadOnlyMode} />
          </div>
        </Paper>
        <Paper className="influencers-content-paper">
          <div className={`${contentContainerClassName} flex justify-center items-end direction-column`}>
            <NewDealContainer
              handleBannerChange={handleDealBannerChange}
              disabled={isReadOnlyMode}
            />
          </div>
        </Paper>
        <Paper className="influencers-content-paper">
          <div className={`${contentContainerClassName} flex justify-center items-end direction-column`}>
            <ProductContainer disabled={isReadOnlyMode} />
          </div>
        </Paper>
        <div className="flex influencers-confirm justify-center items-center">
          {loading && (
            <div className="loader-cover flex justify-center items-center">
              <CircularProgress
                variant="indeterminate"
                disableShrink
                className="progress-warning custom-loader influencer cover"
                size={30}
                thickness={4}
              />
            </div>
          )}
          <Button
            className="ml-15"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isReadOnlyMode}
          >
            <IntlMessages id="influencer.button.save" />
          </Button>
        </div>
      </div>
    </>
  );
});

Influencer.propTypes = {
  contentContainerClassName: string,
  influencer: shape().isRequired,
  history: shape().isRequired,
  tuneSettings: shape().isRequired,
  updateInfluencerFields: func.isRequired,
  handleSubmitChanges: func.isRequired,
  setAvatarData: func.isRequired,
  setMainBannerData: func.isRequired,
  setDealBannerData: func.isRequired,
  fetchTuneSettings: func.isRequired,
  fetchedOne: bool.isRequired,
  offerCreating: bool.isRequired,
  goalCreating: bool.isRequired,
  trackingGenerating: bool.isRequired,
  influencerUpdating: bool.isRequired,
  sessionUserId: string.isRequired,
  sessionUserRoles: arrayOf(string).isRequired,
  match: shape().isRequired,
};

Influencer.defaultProps = {
  contentContainerClassName: '',
};

const mapStateToProps = state => ({
  influencer: state.influencer.item,
  fetchedOne: state.influencer.fetchedOne,
  sessionUserId: state.session.item.id,
  tuneSettings: state.tune.settings,
  offerCreating: state.tune.offerCreating,
  goalCreating: state.tune.goalCreating,
  trackingGenerating: state.tune.trackingGenerating,
  influencerUpdating: state.influencer.updating,
  sessionUserRoles: state.session.item.roles,
});

const actionCreators = {
  updateInfluencerFields,
  setAvatarData,
  setMainBannerData,
  setDealBannerData,
  fetchTuneSettings,
};

export default withRouter(connect(mapStateToProps, actionCreators)(Influencer));
