export const commentValidations = {
  comment: [{
    type: 'isRequired',
    message: 'pim.list.actionBar.validations.commentIsRequired',
  }],
};

export const systemCommentValidations = {
  systemComment: [{
    type: 'isRequired',
    message: 'pim.list.actionBar.validations.systemCommentIsRequired',
  }],
};

export default {
  commentValidations,
  systemCommentValidations,
};
