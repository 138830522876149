/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState } from 'react';
import {
  bool, func, shape,
} from 'prop-types';
import FormDialog from '../../../../components/FormDialog';

const EditFormDialog = memo((props) => {
  const {
    isOpened,
    onClose,
    itemsPerPage,
    scroll,
    condition,
    editingFeed,
    editField,
    editFieldValue,
    getFeedsData,
    updateFeedsData,
  } = props;

  const [schedule, setSchedule] = useState('');
  const [filters, setFilters] = useState(null);

  const closeVideosEnrichmentDialog = () => {
    onClose();
  };

  const handleChangeShedule = (e) => {
    const { target } = e;
    setSchedule(target.value);
  };

  const handleChangeFilters = (e) => {
    const { target } = e;
    const filters = JSON.parse(target.value);
    setFilters(filters);
  };

  const saveFeedData = () => {
    const cond = editField === 'schedule' ? { data: { schedule: schedule } } : { data: { filters: filters } };

    updateFeedsData(editingFeed, cond).then(() => {
      const filter = condition.filter;
      const sort = condition.sort;
      const offset = scroll.offset - itemsPerPage > 0 ? scroll.offset - itemsPerPage : 0;

      setTimeout(() => {
        getFeedsData({itemsPerPage, offset, filter, sort});
        onClose();
      }, 100);
    });
  }

  const editFieldTitle = editField ? editField.charAt(0).toUpperCase() + editField.slice(1) : '';

  return (
    <FormDialog
      title={'Edit ' + editFieldTitle}
      open={isOpened}
      hideCloseButton={false}
      className="dialog-inputs-custom dialog-content-visible"
      closeButtonTitle="Close"
      submitButtonTitle="Submit"
      maxWidth="sm"
      onClose={closeVideosEnrichmentDialog}
      onSubmit={saveFeedData}
    >
      {editField === 'schedule' ? (
        <input
          className="mapper-input"
          onChange={(e) => handleChangeShedule(e)}
          defaultValue={editFieldValue}
        />
      ) : (
        <textarea
          className="mapper-input"
          defaultValue={editFieldValue}
          onChange={(e) => handleChangeFilters(e)}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
            height: '300px',
          }}
        />
      )}
    </FormDialog>
  );
});

EditFormDialog.propTypes = {
  isOpened: bool.isRequired,
  onClose: func.isRequired,
  itemsPerPage: shape(),
  scroll: shape(),
  condition: shape(),
  editingFeed: shape(),
  editField: shape(),
  editFieldValue: shape(),
  getFeedsData: func.isRequired,
  updateFeedsData: func.isRequired,
};

EditFormDialog.defaultProps = {
  data: [],
  itemsPerPage: null,
  scroll: {},
  condition: {},
  editingField: '',
  editField: '',
  editFieldValue: null,
};

export default EditFormDialog;
