export default data => ({
  ...data,
  virtualParams: {
    ...data.virtualParams,
    filters: data.virtualParams.filters
      ? [...data.virtualParams.filters.map(f => ({
        ...f,
        group: f.group.map((g) => {
          const group = g;

          if (group.operator === 'like' || group.operator === 'nlike') {
            group.value = group.value.replace(/\*/g, '');
          }

          return group;
        }),
      }))]
      : [],
  },
});
