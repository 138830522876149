/* eslint-disable no-nested-ternary */
import React, { memo } from 'react';
import {
  func, number, arrayOf, shape, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import Field from '../Field';
import operators from '../../util/constants/operators';
import IntlMessages from '../../util/IntlMessages';
import AttributeRowOptionsComponent from './AttributeRowOptions';
import { isFilterInParentFilter } from '../../pages/Category/utils/defaultData';

const AttributeRow = memo((props) => {
  const {
    row, filterRowIndex, errors, productAttributes, filters, parentFilters, selectProductsSettings,
    loading, disabled, isParent, hasPermissions, handleSearchOptions, loadOptions, parentOptions,
  } = props;

  let timeout = null;

  const handleCheckIfOperatorDisabled = (option) => {
    if (option.allowDuplicates === true) {
      return false;
    }
    const alreadyDisabled = option.disabled;
    const idFilters = filters.filter(f => f.field === 'id');
    const filterAlreadyExist = filters
      .filter(f => f.field !== 'id')
      .some(f => f.field === (option.code || option.value));
    const disabledOperator = (option.code === 'id' && idFilters.length >= 2)
      || alreadyDisabled || filterAlreadyExist;

    return disabledOperator;
  };

  const mapAttributes = options => !isEmpty(options) && options.map(o => ({
    label: o.label && o.label.en ? o.label.en : o.label,
    value: o.code || o.label,
    type: o.frontendInputType,
    disabled: handleCheckIfOperatorDisabled(o),
  }));

  const mapSelectedProductOptions = options => (!isEmpty(options)
    ? options.map((o) => {
      const optionLabel = o.label && o.label.en ? o.label.en : o.label;
      return ({
        label: optionLabel,
        value: o.optionCode,
        disabled: row.options.some(rO => rO === optionLabel),
      });
    })
    : []
  );

  const onAttributeSelect = (selectedOption) => {
    props.onAttributeSelect({
      selectedOption, row, filterRowIndex,
    });
  };

  const onOperatorChange = (event) => {
    props.onOperatorChange({
      event, row, filterRowIndex,
    });
  };

  const onAttributeRowDelete = () => {
    props.onAttributeRowDelete(filterRowIndex);
  };

  const selectedProductAttribute = productAttributes.find(p => p.value === row.field) || null;
  const attrHasErrorClass = !isEmpty(errors) && errors.group[0].field ? 'has-errors' : '';
  const operatorHasErrorClass = !isEmpty(errors) && errors.group[0].operator ? 'has-errors' : '';
  const attrCodeHepler = row && row.options && row.options.length > 1 ? <IntlMessages id="helper.moreThan" /> : null;
  const disabledOperatorSelect = !row.field;
  const mapAttributeOptions = mapAttributes([...productAttributes]) || [];

  let attributeOperators = operators;
  let operatorHelperText = null;

  if ((row.operator === 'in' || row.operator === 'nin') && row.field !== 'id') {
    operatorHelperText = !isParent ? <IntlMessages id="text.useOtherOperatorsFilterByMessage" /> : '';
    if (row.options.flat().length > 1) {
      attributeOperators = operators.map((op) => {
        if (op.value === 'in' || op.value === 'nin') {
          return ({
            ...op,
            disabled: false,
          });
        }
        return ({
          ...op,
          disabled: true,
        });
      });
    }
  }

  if (
    row.operator !== 'in'
    && row.operator !== 'nin'
    && row.operator !== 'like'
    && row.operator !== 'nlike'
    && row.options.length > 1
  ) {
    attributeOperators = operators.map((op) => {
      operatorHelperText = <IntlMessages id="text.useInNinOperatorsFilterByMessage" />;
      if (op.value === 'in' || op.value === 'nin') {
        return ({
          ...op,
          disabled: true,
        });
      }
      return ({
        ...op,
        disabled: false,
      });
    });
  }

  const getAvailableOperators = () => attributeOperators.filter(
    (o) => {
      const currentAttribute = productAttributes.find(a => a.code === row.field);
      if (currentAttribute && o.value !== 'not_exists') {
        const type = currentAttribute.frontendInputType.startsWith('dropdown_')
          ? 'dropdown'
          : currentAttribute.frontendInputType;
        return o.availableFor.includes(type);
      }
      return false;
    },
  ).map(o => ({
    ...o,
    disabled: row.field === 'id' && filters.some(f => f.field === 'id' && f.operator === o.value),
  })) || [];

  const getLabel = (attr) => {
    if (!attr.label) return '';
    const label = typeof selectedProductAttribute.label === 'string'
      ? selectedProductAttribute.label
      : selectedProductAttribute.label.en;
    return label;
  };
  const label = getLabel(selectedProductAttribute || {});

  const attributeCodeValue = !isEmpty(selectedProductAttribute)
    && !isEmpty(selectedProductAttribute.value)
    ? {
      label,
      value: selectedProductAttribute.value,
    }
    : '';

  const operatorsWithoutValue = new Set(['exists', 'not_exists']);
  const showValueField = !operatorsWithoutValue.has(row.operator);

  const onOptionsSearch = (query) => {
    const { code } = selectedProductAttribute;

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      handleSearchOptions(code, query);
    }, 100);
  };

  const inParentFilter = isFilterInParentFilter(parentFilters, attributeCodeValue);

  return (
    <div className="flex form-group-m filter-attributes-row ml-0 mr-0">
      <div className="form-group block ml-0">
        <Field
          useAdvancedOptions
          element="autocomplete"
          wrapperClassName="mt-10"
          className={`select-autocomplete-wrapper ${attrHasErrorClass} ${disabled ? 'disabled' : ''}`}
          label="Attribute code"
          name="field"
          inputProps={{
            name: 'field',
            id: 'attributeCode-select',
          }}
          value={attributeCodeValue}
          options={mapAttributeOptions}
          hideStaticLabe
          onChange={onAttributeSelect}
          error={!isEmpty(errors) && errors.group[0].field}
          disabled={row.options.length > 1 || loading || disabled}
          helperText={
            !isEmpty(errors) && errors.group[0].field
              ? <IntlMessages id={errors.group[0].field} />
              : (inParentFilter ? <IntlMessages id="text.parentFiltersAttention" /> : attrCodeHepler)
          }
        />
      </div>
      <div className="form-group block">
        <Field
          element="autocomplete"
          className={`select-wrapper ${operatorHasErrorClass}`}
          label="Operator"
          name="operator"
          inputProps={{
            name: 'operator',
            id: 'operator-select',
          }}
          value={operators.find(o => o.value === row.operator)}
          disabled={disabledOperatorSelect || loading || disabled}
          options={getAvailableOperators()}
          hideStaticLabel
          onChange={onOperatorChange}
          error={!isEmpty(errors) && errors.group[0].operator}
          helperText={
            !isEmpty(errors) && errors.group[0].operator
              ? <IntlMessages id={errors.group[0].operator} />
              : operatorHelperText
          }
        />
      </div>

      {showValueField && (
        <div className="form-group last-group">
          <AttributeRowOptionsComponent
            row={row}
            selected={row.options || []}
            options={
              selectedProductAttribute
                ? mapSelectedProductOptions(selectedProductAttribute.options) || []
                : []
            }
            productAttributes={productAttributes}
            selectProductsSettings={selectProductsSettings}
            filterRowIndex={filterRowIndex}
            errors={errors}
            loading={loading}
            disabled={disabled}
            isParent={isParent}
            parentOptions={parentOptions}
            hasPermissions={hasPermissions}
            onBlur={props.onBlur}
            handleSearchOptions={onOptionsSearch}
            loadOptions={loadOptions}
            {...props.filterValueActions}
          />
        </div>
      )}

      {hasPermissions && (
        <Field
          element="icon-button"
          groupclass="flex-0 ml-0"
          icon={<i className="ti-close" />}
          className="btn-mui-sm text-danger"
          onClick={onAttributeRowDelete}
          disabled={loading || disabled}
        />
      )}
    </div>
  );
});

AttributeRow.propTypes = {
  row: shape(),
  filterValueActions: shape({
    onFilterСonditionChange: func,
    onDeleteFilterValueClick: func,
    onAddFilterValueClick: func,
  }),
  filterRowIndex: number,
  errors: shape(),
  filters: arrayOf(shape()),
  parentFilters: arrayOf(shape()),
  parentOptions: arrayOf(shape()),
  productAttributes: arrayOf(shape()),
  onAttributeSelect: func,
  onOperatorChange: func,
  onAttributeRowDelete: func,
  selectProductsSettings: shape(),
  loading: bool,
  disabled: bool,
  isParent: bool,
  hasPermissions: bool,
  handleSearchOptions: func,
  onBlur: func.isRequired,
  loadOptions: bool.isRequired,
};

AttributeRow.defaultProps = {
  row: {},
  filterValueActions: {
    onFilterСonditionChange: null,
    onDeleteFilterValueClick: null,
    onAddFilterValueClick: null,
  },
  filterRowIndex: null,
  errors: {},
  filters: [],
  parentFilters: [],
  parentOptions: [],
  productAttributes: [],
  onAttributeSelect: null,
  onOperatorChange: null,
  onAttributeRowDelete: null,
  selectProductsSettings: null,
  loading: false,
  disabled: false,
  isParent: false,
  hasPermissions: false,
  handleSearchOptions: null,
};

export default AttributeRow;
