import { createSelector } from 'reselect';

const headerContentRootSelector = state => state;

const stateSelector = createSelector(
  headerContentRootSelector,
  preview => ({
    selectedCategory: preview.selectedCategory,
    selectedPromotion: preview.selectedPromotion,
    searchQuery: preview.searchQuery,
    facetsPreFilters: preview.facetsPreFilters,
    mode: preview.mode,
    searchValue: preview.searchValue,
  }),
);

export const headerContentSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  headerContentSelector,
};
