import React from "react";

export const getMerchantFieldInfo = (attrCode, handleOpenMerchantInfo) => {
  if (attrCode === 'price.rma_cost') {
    return (
        <>
          <h3>Net Cost</h3>
          <p>Net Cost <b>=</b> Original Price - Original Price x Merchant Fixed Comission (<b>%</b>)</p>
          <p><b>OR</b> (<b>if</b> Merchant Fixed Comission is not set)</p>
          <p>Net Cost <b>=</b> Original Price - Original Price x Category Fee (<b>%</b>)</p>
          <p><b>OR</b> (<b>if</b> Merchant Category Fee is not set)</p>
          <br />
          <p>Net Cost <b>=</b> Original Price - Original Price x 20<b>%</b></p>
        </>
    );
  }
  if (attrCode === 'price.profit') {
    return (
        <>
          <h3>Markup</h3>
          <p>Publish Price w/ No Shipping <b>=</b> Original Price + Original Price x Merchant Markup</p>
          <p><b>OR</b> (<b>if</b> Merchant Markup is not set)</p>
          <p>Publish Price w/ No Shipping <b>=</b> Original Price + Original Price x Category Markup Fee</p>
          <p><b>OR</b> (<b>if</b> Category Markup Fee is not set)</p>
          <p>Publish Price w/ No Shipping <b>=</b> Original Price + Original Price x 10<b>%</b></p>
          <br />
          <p>Markup <b>=</b> Publish Price w/ No Shipping - Net Cost</p>
        </>
    );
  }
  if (attrCode === 'price.profit_lost') {
    return (
        <>
          <h3>Profit/Lost</h3>
          <p>Profit/Lost <b>=</b> (Markup / Net Cost) in <b>%</b></p>
        </>
    );
  }
  if (attrCode === 'price.msrp') {
    return (
        <>
          <h3>MSRP</h3>
          <p>Markup MSRP <b>=</b> Final Price + Final Price * Category MSRP Markup (<b>%</b>)</p>
          <p><b>OR</b> (<b>if</b> Category MSRP Markup <b>&lt;=</b> 0 <b>OR</b> not is set)</p>
          <p>Markup MSRP <b>=</b> Final Price + Final Price * 30<b>%</b></p>
          <br />
          <p>MSRP <b>=</b> MAX(Original MSRP, Markup MSRP)</p>
        </>
    );
  }
  if (attrCode === 'price.min_price') {
    return (
        <>
          <h3>Min Price</h3>
          <p>Product Max Weight <b>=</b> MAX(Orignal Weight, Dimensional Weight, Category Default Weight)</p>
          <br />
          <p>Min Price <b>=</b> FunctionOf(Net Cost, Product Max Weight)</p>
          <br />
          <p>See: &nbsp;
            <a href="https://frendino.atlassian.net/wiki/spaces/AO/pages/2131263489/Min+Price+Merchant+Level+Product"
               target="_blank" rel="noopener noreferrer">Min Price</a>
          </p>
        </>
    );
  }
  if (attrCode === 'price.final_price') {
    return (
        <>
          <h3>Final Price</h3>
          <p>
              Product Weight <b>=</b> Original Product Weight <b>OR</b> (<b>if</b> Original Product Weight is not set)
          </p>
          <p><b>OR</b> (<b>if</b> Original Product Weight is not set)</p>
          <p>Product Weight <b>=</b> Category Product Weight</p>
          <p>Final Weight <b>=</b> MAX(Product Weight, Dimensional Weight)</p>
          <br />
          <p>Publish Price w/ no Shipping - see how it is calculated in &nbsp;
            <span className="merchant-info-link" onClick={e => handleOpenMerchantInfo(e, 'price.profit')}>Markup</span>
          </p>
          <br />
          <p>Final Price <b>=</b> FunctionOf(Publish Price w/ no Shipping, Final Weight)</p>
          <br />
          <p>See: &nbsp;
            <a href="https://frendino.atlassian.net/wiki/spaces/AO/pages/2627108865/Final+Price"
               target="_blank" rel="noopener noreferrer">Final Price</a>,
            <a href="https://frendino.atlassian.net/browse/AAI-1060"
               target="_blank" rel="noopener noreferrer">Price Calculation Logic in IMS</a>,
            <a href="https://frendino.atlassian.net/browse/AAI-1348"
               target="_blank" rel="noopener noreferrer">Automatic Repricing Logic v1</a>,
            <a href="https://frendino.atlassian.net/browse/AAI-1399"
               target="_blank" rel="noopener noreferrer">Automatic Repricing Logic v2</a>
          </p>
        </>
    );
  }
  if (attrCode === 'price.shipping_cost') {
    return (
        <>
          <h3>Shipping Cost</h3>
          <p>Publish Price w/ No Shipping - see how it is calculated in &nbsp;
            <span className="merchant-info-link" onClick={e => handleOpenMerchantInfo(e, 'price.profit')}>Markup</span>
          </p>
          <p>Shipping Cost <b>=</b> Final Price - Publish Price w/ No Shipping</p>
        </>
    );
  }

  return false;
}
