import React from 'react';
import IntlMessages from "../../../util/IntlMessages";
import AddComments from "./AddComments";
import {Button, CircularProgress} from "@material-ui/core";

export const DialogActionsContent = (
  {
    handleCommentChange,
    handleSystemCommentChange,
    handleUnlockAllAttributes,
    handleSaveAndCloseProductClick,
    handleSaveAndClose,
    comment,
    errors,
    props,
    systemComment,
    productProps,
    qtyError,
    disabled,
    onSaveProductClick,
    isAbleUpdate,
    handleSaveClick,
    onCloseDialog,
    pageMode,
  }
) => {
  return (
    <>
      { pageMode === 'information' ? (
        <div className="dialog-footer-actions items-center">
          <AddComments
            className="flex-grid-50 block"
            onCommentChange={handleCommentChange}
            onSystemCommentChange={handleSystemCommentChange}
            comment={comment}
            systemComment={systemComment}
            errors={errors}
            commentMessagesOptions={props.commentMessages.map(cm => ({
              ...cm, value: cm.title,
            }))}
          />

          { qtyError && <div className="errorMsg">{ qtyError }</div> }

          { props.productUpdating && (
            <CircularProgress
              className="primary-color ml-5 dialog-actions-loader"
              size={20}
              variant="indeterminate"
              disableShrink
              thickness={4}
            />
          )}

          { productProps.item.lockedAttributes && productProps.item.lockedAttributes.length > 0 && (
            <Button
              variant="contained"
              onClick={handleUnlockAllAttributes}
              color="primary"
              className="btn-info text-white"
              style={{minWidth: '130px'}}
              disabled={props.productUpdating || disabled}
            >
              <IntlMessages id="buttons.unlockAllAttributes" />
            </Button>
          )}

          { onSaveProductClick && isAbleUpdate && (
            <Button
              id="save-button"
              variant="contained"
              onClick={handleSaveClick}
              color="primary"
              className="text-white"
              style={{backgroundColor: '#2b629b'}}
              disabled={props.productUpdating || disabled}
            >
              <IntlMessages id="buttons.save" />
            </Button>
          )}
          { handleSaveAndCloseProductClick && isAbleUpdate && (
            <Button
              id="save-and-close-button"
              variant="contained"
              onClick={handleSaveAndClose}
              color="primary"
              className="text-white"
              style={{backgroundColor: '#6c94d1', minWidth: '105px'}}
              disabled={props.productUpdating || disabled}
            >
              <IntlMessages id="buttons.saveAndClose" />
            </Button>
          )}
          { onCloseDialog && (
            <Button
              variant="contained"
              onClick={onCloseDialog}
              color="primary"
              className="text-white"
              style={{backgroundColor: '#d3233e'}}
              disabled={props.productUpdating}
            >
              <IntlMessages id="buttons.close" />
            </Button>
          )}
        </div>
      ) : (
        <div className="dialog-footer-actions items-right" style={{float: 'right'}}>
          { onCloseDialog && (
            <Button
              variant="contained"
              onClick={onCloseDialog}
              color="primary"
              className="text-white"
              style={{backgroundColor: '#d3233e'}}
              disabled={props.productUpdating}
            >
              <IntlMessages id="buttons.close" />
            </Button>
          )}
        </div>
      )}
    </>
  );
}

export default DialogActionsContent;
