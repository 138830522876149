export const getCountProductsActivitiesByProductIdsQuery = productIds => ({
  aggregation: [
    'affected_ids',
  ],
  filter: [{
    group: [{
      field: 'affected_ids',
      value: productIds,
      operator: 'in',
    }, {
      field: 'action',
      value: ['attributes_updated', 'attributes_mass_updated', 'manual_update'],
      operator: 'in',
    }, {
      field: 'comment',
      value: '**',
      operator: 'like',
    }],
  }],
  fields: ['id', 'affected_ids'],
  pagination: {
    page: 1,
    limit: 1000,
  },
});

export const getActivityLogsByProductIdQuery = productId => ({
  aggregation: [
    'affected_ids',
  ],
  filter: [{
    group: [{
      field: 'affected_ids',
      value: productId,
      operator: 'eq',
    }],
  }],
  sort: [{
    field: 'created_at',
    order: 'desc',
  }],
  fields: [],
  pagination: {
    page: 1,
    limit: 50,
  },
});

export const getActivityLogList = (page, options) => {
  const query = {
    aggregation: [
      'action',
      'entity_type',
    ],
    // sort: [{
    //   field: 'created_at',
    //   order: 'desc',
    // }],
    fields: [],
    pagination: {
      page,
      limit: 25,
    },
  };
  
  const filters = options.filters.filter(e => e.field && e.operator)
  const customFilters = options.filters.filter(e => e.group && e.group.length)
  const isOptionsValid = 
    filters.length && filters.every(e => e.field && e.operator);
  const isCustomOptionsValid = 
    customFilters.length && customFilters.every(e => e.group.length && e.group.every(e => e.field && e.operator));
  
  if (isOptionsValid) query.filter = [{ group: filters }];
  if (isCustomOptionsValid) query.filter = [...(query.filter ? query.filter : []), ...customFilters];

  const isSortsValid = options.sorts.length && options.sorts.every(e => e.field && e.order);
  if (isSortsValid) query.sort = options.sorts;

  return query;
};

export default {
  getCountProductsActivitiesByProductIdsQuery,
  getActivityLogsByProductIdQuery,
  getActivityLogList,
};
