import { isEmpty } from 'lodash';

export default filters => filters.map(nf => nf.group.map((nfg) => {
  if (!isEmpty(nfg.value)) {
    if (typeof nfg.value === 'string') {
      return nf;
    }
    if (Array.isArray(nfg.value) && !nfg.value.map(v => v.includes('prod_')).includes(true)) {
      return nf;
    }
  }
  return false;
})).flat().filter(e => e);
