import { previewSelector } from '../../pages/Home/selectors/preview';
import {
  setUpdatedProductsImagesOverrides,
  injectProducts,
} from '../../../../actions/preview';

export const mapStateToProps = state => ({
  preview: previewSelector(state.preview),
});

export const mapDispatchToProps = {
  setUpdatedProductsImagesOverrides,
  injectProducts,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
