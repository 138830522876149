import React from 'react';
import {
  string, func, bool,
} from 'prop-types';
import { connect } from 'react-redux';

import Select from '../../../../../../../components/Select';
import { gridOptions } from '../../../../../utils/gridOptions';

import {
  setDisplayMode,
} from '../../../../../../../actions/preview';

const GridSelect = (props) => {
  const handleDisplayModeChange = (event) => {
    const selectedDisplayMode = event.target.value;

    props.setDisplayMode(selectedDisplayMode);
  };

  return (
    <div className={`flex justify-center ${!props.flexAuto ? 'third-width' : ''} ${props.className}`}>
      <Select
        value={props.displayMode}
        options={gridOptions.filter(go => go.availableFor.includes('preview'))}
        onChange={handleDisplayModeChange}
        name="displayMode"
        selectClassName="simple-select"
        className="select-wrapper select-underline-hide ml-25"
      />
    </div>
  );
};

GridSelect.propTypes = {
  className: string,
  displayMode: string.isRequired,
  flexAuto: bool,
  setDisplayMode: func.isRequired,
};

GridSelect.defaultProps = {
  className: '',
  flexAuto: false,
};

const mapStateToProps = state => ({
  displayMode: state.preview.displayMode,
});

const actionCreators = {
  setDisplayMode,
};

export default connect(mapStateToProps, actionCreators)(GridSelect);
