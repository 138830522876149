import React from 'react';
import IntlMessages from '../../util/IntlMessages';
import { Button } from '@material-ui/core';

export const Pagination = (props) => {
  if (!props.allPages) {
    return (<></>);
  }

  const location = document.location.href;
  const itemsPerPages = [5, 10, 15, 20, 25, 50, 100, 200];
  const pagesButtons = 4;

  const pages = [];
  const firstPage = props.currentPage - pagesButtons > pagesButtons ? props.currentPage - pagesButtons : 1;
  const lastPage = props.currentPage + pagesButtons < props.allPages ? props.currentPage + pagesButtons : props.allPages;
  for (let i = firstPage; i <= lastPage; i++) {
    pages[i] = i;
  }

  const getPageData = (page, limit = null, offset = null) => {
    const sort = props.condition.sort ? props.condition.sort : null;
    if (limit === null) {
      limit = props.itemsPerPage;
    }
    if (offset === null) {
      offset = page * props.itemsPerPage - props.itemsPerPage;
    }

    if (props.type === 'cams') {
      const query = {
        from : offset,
        size : limit,
        sort : sort,
      };
      query['query'] = props.condition.query;
      props.getData(query);
    } else if (props.type === 'yotpo') {
      props.getData(props.productId, page, limit);
    } else {
      const filter = props.condition.filter ? props.condition.filter : null;
      props.getData({
        limit: limit,
        offset: offset,
        filter: filter,
        sort: sort,
        marketplace: props.marketplace
      });
    }
  };

  const handleChangeItemsPerPage = (e) => {
    const { target } = e;
    const limit = parseInt(target.value);
    getPageData(1, limit);
  };

  const firstMapperData = () => {
    getPageData(1);
  };

  const prevMapperData = () => {
    const offsetDiff = props.scroll.offset - props.itemsPerPage;
    const offset = offsetDiff < 0 ? 0 : offsetDiff;
    getPageData(1, null, offset);
  };

  const nextMapperData = () => {
    const offsetDiff = props.scroll.offset + props.itemsPerPage;
    const offset = offsetDiff > props.scroll.total - props.itemsPerPage
      ? props.scroll.total - props.itemsPerPage
      : offsetDiff;
    getPageData(1, null, offset);
  };

  const lastMapperData = () => {
    getPageData(props.allPages);
  };

  const handleChangePage = (e) => {
    const pageInput = document.getElementById('page-input');
    if (pageInput) {
      const {target} = e;

      setTimeout(() => {
        if (target.value <= 0) {
          pageInput.value = 1;
        } else if (target.value > props.allPages) {
          pageInput.value = props.allPages;
        }
      }, 100);

      if (e.key === 'Enter') {
        getPageData(target.value);
        setTimeout(() => {
          pageInput.value = '';
        }, 200);
      }
    }
  }

  const Page = ((props) => {
    const {
      page, currentPage
    } = props;

    if (page === currentPage) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="medium"
          className="text-white logo-color float-left marg-right-10"
          style={{cursor: 'default'}}
        >
          {page}
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          color="primary"
          size="medium"
          className="text-white float-left marg-right-10"
          onClick={() => getPageData(page)}
        >
          {page}
        </Button>
      );
    }
  });

  const style = props.type === 'yotpo' ? { backgroundColor: '#fff', zIndex: 100, position: 'relative' } : {};

  return (
    <div className="mapper-navigation" style={style}>
      {props.currentPage > 1 && (
        <>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            className="text-white float-left marg-right-10"
            onClick={() => firstMapperData()}
          >
            <IntlMessages id="buttons.first" />
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="medium"
            className="text-white float-left marg-right-10"
            onClick={() => prevMapperData()}
          >
            <IntlMessages id="buttons.prev" />
          </Button>
        </>
      )}

      {pages.map((item, itemKey) => (
        <Page key={'page-' + itemKey} page={item} currentPage={props.currentPage} />
      ))}

      {(props.allPages - props.currentPage > pagesButtons) && (
        <>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            className="text-white float-left marg-right-10"
            onClick={() => nextMapperData()}
          >
            <IntlMessages id="buttons.next" />
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="medium"
            className="text-white float-left marg-right-10"
            onClick={() => lastMapperData()}
          >
            <IntlMessages id="buttons.last" />
          </Button>
        </>
      )}

      <input
        id="page-input"
        className="mapper-select no-spinner float-left"
        style={{width: '140px', position: 'relative', zIndex: 1000}}
        type="number"
        min="1"
        max={props.allPages}
        placeholder="Enter Page Number"
        onKeyPress={(e) => handleChangePage(e)}
      />

      <select
        className="mapper-select mapper-w-75 float-right"
        style={{position: 'relative', zIndex: 1000}}
        onChange={e => handleChangeItemsPerPage(e)}
        defaultValue={props.itemsPerPage}
      >
        {itemsPerPages.map((value, key) => (
          <option key={'nav-option' + key} value={value}>{value}</option>
        ))}
      </select>

      <div className="mapper-navigation-per-page">
        <IntlMessages id="sidebar.itemsPerPage" />
      </div>

      <br/><br/><br/><br/>
      {(location.indexOf('activity_log') !== -1 || location.indexOf('repricing') !== -1) && (
        <>
          <br/><br/><br/><br/>
        </>
      )}
    </div>
  );
};

export default Pagination;
