import { showErrorNotification } from '../util/api';
import { setIsLoading } from './global';
import { PIM_SET_RULES_DATA } from './types';

import { CDMSClient } from '../util/api';
import { NotificationManager } from "react-notifications";
import { getResultCondition } from "./pim";
import {
  categoriesAutocomplete,
  brandsAutocomplete,
  merchantsAutocomplete,
} from './autocomplete';
import {camelizeKeys} from "humps";

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';
export const ruleResult = id => CDMSClient.get('/price_rules/' + id);
export const deleteResult = id => CDMSClient.delete('/price_rules/' + id);
export const rulesResult = condition => CDMSClient.post('/price_rules/search?ref=' + ref, condition);
export const addResult = condition => CDMSClient.post('/price_rules', condition);
export const updateResult = (condition, id) => CDMSClient.put('/price_rules/' + id, condition);

export const getRule = ({ id, rulesConditions }) => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const res = await ruleResult(id);
    const { data } = res.data;

    if (data.condition) {
      for (let i = 0; i < data.condition.length; i++) {
        for (let j = 0; j < rulesConditions.length; j++) {
          if (rulesConditions[j].value === data.condition[i].name) {
            if (rulesConditions[j].value === 'categories') {
              const conditionValue = Array.isArray(data.condition[i].value)
                ? data.condition[i].value
                : data.condition[i].value.split(',');
              const condition = {
                filter: [{
                  group: [{
                    field: 'id',
                    value: conditionValue,
                    operator: 'in'
                  }]
                }],
                fields: ['name', 'path_by_name', 'id'],
                pagination: {
                  page: 1,
                  limit: conditionValue.length
                }
              }
              const res = await categoriesAutocomplete(condition);
              const searchOptions = camelizeKeys(res.data.data);

              const label = [];
              for (let k = 0; k < searchOptions.length; k++) {
                for (let l = 0; l < conditionValue.length; l++) {
                  if (searchOptions[k].id === conditionValue[l]) {
                    label[k] = searchOptions[k].pathByName;
                  }
                }
              }
              data.condition[i].label = label;
              data.condition[i].value = conditionValue;
            }
            if (rulesConditions[j].value === 'brand') {
              const conditionValue = Array.isArray(data.condition[i].value)
                ? data.condition[i].value
                : data.condition[i].value.split(',');
              const condition = {
                filter: [{
                  group: [{
                    field: 'option_code',
                    operator: 'in',
                    value: conditionValue,
                  }],
                }],
                fields: ['option_code', 'label'],
                pagination: {
                  page: 1,
                  limit: conditionValue.length,
                }
              };
              const res = await brandsAutocomplete(condition);
              const searchOptions = camelizeKeys(res.data.data);

              const label = [];
              for (let k = 0; k < searchOptions.length; k++) {
                for (let l = 0; l < conditionValue.length; l++) {
                  if (searchOptions[k].optionCode === conditionValue[l]) {
                    label[k] = searchOptions[k].label.en;
                  }
                }
              }
              data.condition[i].label = label;
              data.condition[i].value = conditionValue;
            }
            if (rulesConditions[j].value === 'merchant') {
              const conditionValue = Array.isArray(data.condition[i].value)
                ? data.condition[i].value
                : data.condition[i].value.split(',');
              const condition = {
                filter: [{
                  group: [{
                    field: 'merchant_id',
                    operator: 'in',
                    value: conditionValue.map(String),
                  }],
                }],
                fields: ['name', 'merchant_id'],
                pagination: {
                  page: 1,
                  limit: conditionValue.length
                }
              };
              const res = await merchantsAutocomplete(condition);
              const searchOptions = camelizeKeys(res.data.data);

              const label = [];
              for (let k = 0; k < searchOptions.length; k++) {
                for (let l = 0; l < conditionValue.length; l++) {
                  if (parseInt(searchOptions[k].merchantId) === parseInt(conditionValue[l])) {
                    label[k] = searchOptions[k].name;
                  }
                }
              }
              data.condition[i].label = label;
              data.condition[i].value = conditionValue;
            }
          }
        }
      }
    }

    dispatch({
      type: PIM_SET_RULES_DATA,
      payload: {
        rules: {
          rule: data,
          formOpen: true,
        }
      }
    });

  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
  dispatch(setIsLoading(false));
};

export const getRulesData = (
  { limit, offset, filter = null, sort = null, fields, reset = false }
) => async (dispatch) => {
  dispatch(setIsLoading(true));
  dispatch({
    type: PIM_SET_RULES_DATA,
    payload: {
      rules: {
        itemsPerPage: limit,
        error: {},
        reset: reset
      }
    }
  });

  try {
    const condition = getResultCondition(filter, sort, limit, offset / limit, true);
    if (fields) {
      condition['fields'] = fields;
    }
    const res = await rulesResult(condition);
    const { data } = res.data;

    dispatch({
      type: PIM_SET_RULES_DATA,
      payload: {
        rules: {
          data: data,
          scroll: { offset: offset, pages: res.data.pages, total: res.data.total },
          condition: condition,
          reset: false,
        }
      }
    });

  } catch (error) {
    showErrorNotification(error, 'CDMS');

    dispatch({
      type: PIM_SET_RULES_DATA,
      payload: {
        rules: {
          data: [], scroll: [], reset: false
        }
      }
    });
  }
  dispatch(setIsLoading(false));
};

export const getRulesForm = ({open}) => async (dispatch) => {
  dispatch({
    type: PIM_SET_RULES_DATA,
    payload: {
      rules: {
        formOpen: open
      }
    }
  });
};

export const saveRulesData = ({savingData, id = null}) => async (dispatch) => {
  dispatch(setIsLoading(true));
  dispatch({
    type: PIM_SET_RULES_DATA,
    payload: { rules: { formOpen: true } }
  });

  try {

    if (id === null) {
      await addResult(savingData);
    } else {
      await updateResult(savingData, id);
    }

    NotificationManager.success('The data was saved successfully');

    dispatch({
      type: PIM_SET_RULES_DATA,
      payload: { rules: { formOpen: false } }
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');

    dispatch({
      type: PIM_SET_RULES_DATA,
      payload: { rules: { formOpen: false } }
    });
  }
  dispatch(setIsLoading(false));
};

export const deleteRule = ({ id }) => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    await deleteResult(id);

  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
  dispatch(setIsLoading(false));
};

export const getRulesCondition = ({ limit, offset, filter = null, sort = null, reset = false }) =>
    async (dispatch) => {
  const condition = getResultCondition(filter, sort, limit, offset);
  dispatch({
    type: PIM_SET_RULES_DATA,
    payload: {
      rules: {
        condition: condition,
        reset: reset
      }
    }
  });
};

export const setRulesFilterState = ({ disabled }) => async (dispatch) => {
  dispatch({
    type: PIM_SET_RULES_DATA,
    payload: {
      rules: {
        filterDisabled: disabled
      }
    }
  });
};

export const replaceVariationFilters = (type) => {
  const globalFilters = JSON.parse(sessionStorage.getItem('pim.searchFilters'));
  const globalFields = JSON.parse(sessionStorage.getItem('pim.searchFields'));

  if (type === 'rules') {
    sessionStorage.setItem('pim.savedSearchFilters', JSON.stringify(globalFilters));
    sessionStorage.setItem('pim.savedSearchFields', JSON.stringify(globalFields));

    const emptyFiltersAndFields = {
      parent: [],
      variation: [],
      merchant: [],
      feed: [],
      rule: [],
      repricing: [],
      mapper: [],
      activityLog: [],
    };
    sessionStorage.setItem('pim.searchFilters', JSON.stringify(emptyFiltersAndFields));
    sessionStorage.setItem('pim.searchFields', JSON.stringify(emptyFiltersAndFields));
  } else {
    const savedGlobalFilters = JSON.parse(sessionStorage.getItem('pim.savedSearchFilters'));
    const savedGlobalFields = JSON.parse(sessionStorage.getItem('pim.savedSearchFields'));
    sessionStorage.setItem('pim.searchFilters', JSON.stringify(savedGlobalFilters));
    sessionStorage.setItem('pim.searchFields', JSON.stringify(savedGlobalFields));
  }
}
