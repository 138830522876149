import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Input } from 'reactstrap';
import {
  string, arrayOf, shape, number, oneOfType, func, bool, node, array,
} from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputErrors from '../InputErrors';
import CircularProggress from '../CircularProggress';
import useStateStore from './hooks/useState';
import useOptionsEffect from './hooks/useOptionsEffect';

const SelectComponent = (props) => {
  const state = useStateStore(props);
  useOptionsEffect(state, props);

  const { stateOptions, open } = state;
  const {
    className, value, displayEmpty, errors, id,
    inputProps, label, emptyPlaceholder, multiple, name, selectClassName,
    prefix, suffix, disabled, helperText, searchable, limitsToShow, error,
    loading, loaderClassName, suffixClassName, prefixClassName,
  } = props;

  const mappedOptions = stateOptions && stateOptions.length > 0
    ? stateOptions : props.options;

  const slicedList = !limitsToShow && limitsToShow > mappedOptions.length
    ? mappedOptions
    : mappedOptions.slice(0, limitsToShow);

  const existedOption = mappedOptions
    && !slicedList.some(sl => sl.value === value)
    ? mappedOptions.find(l => l.value === value) : null;

  const listToShow = slicedList && slicedList.length === 0 // eslint-disable-line
    ? mappedOptions : existedOption
      ? [...slicedList, existedOption] : slicedList;

  const handleClose = () => {
    const { options } = props;
    state.setOpen(false);
    state.setStateOptions(options);
  };

  const onChange = (e) => {
    if (props.onChange && e.target.value) {
      props.onChange(e);
    }
  };

  const handleOpen = () => {
    state.setOpen(true);
  };

  const filterOptions = (e) => {
    const { options } = props;
    const filteredOptions = [...options.filter(
      o => (o.label || o.title).toLowerCase().includes(e.target.value.toLowerCase()),
    )];
    const noOptions = filteredOptions && filteredOptions.length === 0 ? [{ key: 'no-options', value: 'No options' }] : options;
    const newOptions = filteredOptions && filteredOptions.length > 0 ? filteredOptions : noOptions;

    state.setOpen(true);
    state.setStateOptions(newOptions);
  };

  const prefixClass = prefix ? ' select-wrapper-prefix' : '';
  const sufixClass = suffix ? ' select-wrapper-suffix' : '';

  return (
    <nav
      className={`select-wrapper ${className}${prefixClass}${sufixClass}`}
    >
      <FormControl error={error} fullWidth disabled={disabled}>
        {loading && (
          <CircularProggress
            className={`select-loader value-loader bottom ${loaderClassName}`}
            loading={loading}
            size={15}
            variant="indeterminate"
            disableShrink
            thickness={4}
          />
        )}
        {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
        {prefix && <div className={`select-prefix ${prefixClassName}`}>{prefix}</div>}
        <Select
          className={`select-inner-wrapper ${selectClassName}`}
          value={value}
          onChange={onChange}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          inputProps={inputProps}
          displayEmpty={displayEmpty}
          multiple={multiple}
          name={name}
          id={id}
        >

          <MenuItem disabled value="" className={`item-loader ${!loading ? 'hidden' : ''}`}>
            <CircularProggress
              className={`select-loader bottom ${loaderClassName}`}
              loading={loading}
              variant="indeterminate"
              disableShrink
              thickness={4}
            />
          </MenuItem>
          <MenuItem disabled value=""><em>{emptyPlaceholder}</em></MenuItem>
          {searchable && (
            <MenuItem onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} className="menu-item-search">
              <Input
                className="form-control"
                onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                onChange={filterOptions}
              />
            </MenuItem>
          )}
          {listToShow && listToShow.length > 0 && listToShow.map(o => (
            <MenuItem
              key={`${o.key || o.id || o.value}_${listToShow.indexOf(o)}`}
              value={o.id || o.value}
              disabled={o.disabled}
            >
              {o.title || o.label || o.value}
            </MenuItem>
          ))}
        </Select>
        {suffix && <div className={`select-suffix ${suffixClassName}`}>{suffix}</div>}
      </FormControl>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {
        (error[name] || errors[name]) && !Array.isArray(errors)
          ? <InputErrors errors={error[name] || errors[name]} />
          : Array.isArray(errors) && <InputErrors errors={errors} />
      }
    </nav>
  );
};

SelectComponent.propTypes = {
  value: oneOfType([
    arrayOf(string),
    string,
  ]),
  prefix: oneOfType([node, string]),
  suffix: oneOfType([node, string]),
  helperText: oneOfType([node, string]),
  emptyPlaceholder: string,
  className: string,
  label: node,
  options: oneOfType([
    arrayOf(shape({
      id: number,
      key: string,
    })),
    func,
  ]),
  onChange: func,
  displayEmpty: bool,
  inputProps: shape(),
  multiple: bool,
  disabled: bool,
  name: string,
  selectClassName: string,
  errors: oneOfType([
    shape(),
    array,
  ]),
  id: oneOfType([
    string,
    number,
  ]),
  searchable: bool,
  error: oneOfType([bool, string]),
  limitsToShow: number,
  loading: bool,
  loaderClassName: string,
  suffixClassName: string,
  prefixClassName: string,
};

SelectComponent.defaultProps = {
  onChange: null,
  value: null,
  prefix: null,
  suffix: null,
  className: '',
  options: null,
  label: null,
  helperText: null,
  displayEmpty: false,
  inputProps: null,
  emptyPlaceholder: 'Select',
  multiple: false,
  disabled: false,
  name: '',
  selectClassName: '',
  errors: {},
  id: '',
  searchable: false,
  error: false,
  limitsToShow: null,
  loading: false,
  loaderClassName: '',
  suffixClassName: '',
  prefixClassName: '',
};

export default SelectComponent;
