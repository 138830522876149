/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty, uniqBy } from 'lodash';

const useVirtualCategoryEffects = ({
  physicalCategoriesIds,
  categoriesAttributesFetching,
  fetchCategoriesAttributes,
}) => {
  useEffect(() => {
    if (!categoriesAttributesFetching
    && !isEmpty(physicalCategoriesIds)) {
      fetchCategoriesAttributes(uniqBy(physicalCategoriesIds));
    }
  }, [physicalCategoriesIds]);
};

export default useVirtualCategoryEffects;
