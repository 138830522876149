import { influencersAppPath } from '../../../util/paths';

// APP paths
export const rootPath = `${influencersAppPath}`;
export const editPath = `${influencersAppPath}/:id/edit`;
export const getEditInfluencerPath = id => `${influencersAppPath}/${id}/edit`;
export const newPath = `${influencersAppPath}/new`;

// FB storage
export const getAvatarPath = id => `images/${id}/avatars/avatar`;
export const getMainBannerPath = id => `images/${id}/banners/main_banner`;
export const getDealBannerPath = id => `images/${id}/banners/deal_banner`;
