/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { func, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../../components/Layout';
import List from '../../components/List';
import SidebarContent from '../Pim/pages/Home/components/SidebarContent';
import { setFilterTarget } from '../../actions/pim';
import CamsTable from './components/CamsTable';
import { headerList } from '../utils/headerList';
import { AbilityContext } from '../../components/AbilityContext';
import { fetchAttributes } from '../../actions/system';
import { getProductActions, getProductDataStatus } from "../../actions/cams";
import { getCamsFields, camsFirstLoad } from "../../util/cams";

export const Cams = (props) => {
  const abilityContext = useContext(AbilityContext);

  const [firstLoad, setFirstLoad] = useState(false);
  const [camsData, setCamsData] = useState({});
  const [camsActions, setCamsActions] = useState([]);
  const [camsActionsOptions, setCamsActionsOptions] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  useEffect(() => {
    props.fetchAttributes();
    props.setFilterTarget('cams');
    if (props.camsState.cams && props.camsState.cams.data) {
      const data = props.camsState.cams.data;
      setCamsData(data);
    }

    if (props.camsState.cams && props.camsState.cams.actions) {
      const actions = [];
      for (let i = 0; i < props.camsState.cams.actions.length; i++) {
        actions.push(props.camsState.cams.actions[i].key);
        camsActionsOptions[props.camsState.cams.actions[i].key] = props.camsState.cams.actions[i].key;
      }
      setCamsActions(actions);
      setCamsActionsOptions(camsActionsOptions);
    }

    if (props.camsState.cams && props.camsState.cams.scroll) {
      setItemsPerPage(props.camsState.cams.scroll.itemsPerPage);
    }
  }, [
    props.camsState, props.setFilterTarget
  ]);

  const {sortedFields, sortedSearchFields, currentFields} = getCamsFields(props.camsFields, camsActionsOptions);
  camsFirstLoad(firstLoad, setFirstLoad, props.getProductDataStatus, props.getProductActions, itemsPerPage);

  return (
    <Layout
      horizontalMenu
      header={{
        className: 'header-mer',
        subHeaderContent: (
          <List
            dir="horizontal"
            className="justify-center"
            dataSource={headerList({ abilityContext })}
          />
        ),
      }}
      sidebar={{
        className: `sidebar-mer sidebar-ranking-mer sidebar-scroll-secondary`,
        content: <SidebarContent
          sortedFields={sortedFields}
          sortedSearchFields={sortedSearchFields}
          condition={props.camsState.cams && props.camsState.cams.condition ? props.camsState.cams.condition : []}
          scroll={props.camsState.cams && props.camsState.cams.scroll ? props.camsState.cams.scroll : []}
          getCamsData={props.getProductDataStatus}
          itemsPerPage={itemsPerPage}
        />,
      }}
      main={{
        className: 'main-mer main-ranking-mer',
        content: (
          <CamsTable
            getCamsData={props.getProductDataStatus}
            fields={currentFields}
            data={camsData}
            camsActions={camsActions}
            camsScroll={props.camsState.cams && props.camsState.cams.scroll ? props.camsState.cams.scroll : []}
            camsCondition={props.camsState.cams && props.camsState.cams.condition ? props.camsState.cams.condition : []}
            itemsPerPage={itemsPerPage}
          />
        )
      }}
    />
  );
};

Cams.propTypes = {
  camsFields: shape(),
  setFilterTarget: func.isRequired,
  fetchAttributes: func.isRequired,
  getProductActions: func.isRequired,
  getProductDataStatus: func.isRequired,
  camsState: shape().isRequired,
};

Cams.defaultProps = {
  camsFields: {},
};

const stateToProps = state => ({
  camsFields: state.system.modes.cams ? state.system.modes.cams.camsFields : {},
  camsState: state.cams,
});

const dispatchToProps = {
  setFilterTarget,
  fetchAttributes,
  getProductActions,
  getProductDataStatus,
};

export default withRouter(connect(stateToProps, dispatchToProps)(Cams));
