import React from 'react';
import {
  func, shape,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import Search from '../../../../components/Search';

import { previewSearchSelector } from './selectors/searchSelector';
import { virtualCategoryWidgetSelector } from '../../selectors/virtualCategoryWidgetSelector';
import { promotionWidgetSelector } from '../../selectors/promotionWidgetSelector';

import {
  setCompoundSearch,
  fetchProductList,
  setProductsListEmpty,
  setPagination,
  setGlobalSearchValue,
} from '../../../../actions/preview';

import {
  mapPrefixToFilters,
} from '../../pages/Home/utils/mappers';
import {
  defaultPagination,
} from '../../utils/defaultData';

const PreviewSearch = (props) => {
  const { previewSearchProps } = props;

  const getEntityToUse = () => {
    const { selectedSearchFilter } = previewSearchProps;
    const { selectedCategory } = props.virtualCategoryWidgetProps;
    const { selectedPromotion } = props.promotionWidgetProps;
    let entityToUse = {};

    if (!isEmpty(selectedCategory)) entityToUse = selectedCategory;
    if (!isEmpty(selectedPromotion)) entityToUse = selectedPromotion;
    if (!isEmpty(selectedSearchFilter)) entityToUse = selectedSearchFilter;
    return entityToUse;
  };

  const getVirtualFacets = () => {
    const virtualFacets = {
      filters: previewSearchProps.virtualFacetFilter,
    };
    return virtualFacets;
  };

  const handleGlobalSearchByQuery = (productsSearchValue) => {
    const filterPayload = [{
      condition: 'or',
      group: previewSearchProps.facetsPreFilters,
    }];

    if (isEmpty(productsSearchValue)) {
      props.setCompoundSearch({ value: '' });
      if (!isEmpty(props.virtualCategoryWidgetProps.selectedCategory)
      || !isEmpty(props.promotionWidgetProps.selectedPromotion)
      || !isEmpty(previewSearchProps.selectedSearchFilter)) {
        props.fetchProductList({
          viewMode: 'frontendWithParams',
          filter: previewSearchProps.isHiddenProductsMode
            ? previewSearchProps.hiddenProductsFilter
            : mapPrefixToFilters(filterPayload)
              .filter(f => !isEmpty(f.group)),
          pagination: previewSearchProps.pagination,
          searchFilter: previewSearchProps.isHiddenProductsMode
            ? {}
            : previewSearchProps.searchQuery,
          virtualFacets: getVirtualFacets(),
        });
      } else {
        props.setProductsListEmpty();
      }
    }

    props.setPagination(defaultPagination);

    if (!isEmpty(productsSearchValue)
      && (!isEmpty(props.virtualCategoryWidgetProps.selectedCategory)
      || !isEmpty(props.promotionWidgetProps.selectedPromotion)
      || !isEmpty(previewSearchProps.selectedSearchFilter))
    ) {
      props.setCompoundSearch({ value: productsSearchValue });

      props.fetchProductList({
        viewMode: 'frontendWithParams',
        compoundSearch: {
          value: productsSearchValue,
        },
        filter: previewSearchProps.isHiddenProductsMode
          ? previewSearchProps.hiddenProductsFilter
          : mapPrefixToFilters(filterPayload)
            .filter(f => !isEmpty(f.group)),
        pagination: defaultPagination,
        searchFilter: previewSearchProps.isHiddenProductsMode
          ? {}
          : previewSearchProps.searchQuery,
        virtualFacets: getVirtualFacets(),
      });
    }
  };

  const handleGlobalSearchChange = (e) => {
    const { value } = e.target;
    if (!isEmpty(value)) {
      props.setGlobalSearchValue(value);
    } else {
      if (!isEmpty(props.virtualCategoryWidgetProps.selectedCategory)
        || !isEmpty(props.promotionWidgetProps.selectedPromotion)
        || !isEmpty(previewSearchProps.selectedSearchFilter)
      ) {
        handleGlobalSearchByQuery(value);
      } else {
        props.setProductsListEmpty();
      }
      props.setGlobalSearchValue('');
    }
  };

  const handleGlobalSearch = () => {
    handleGlobalSearchByQuery(previewSearchProps.globalSearchValue);
  };

  const handleGlobalSearchKeyPress = (e) => {
    const { charCode } = e;
    if (charCode === 13) handleGlobalSearch();
  };

  return (
    <Search
      value={previewSearchProps.globalSearchValue}
      onChange={handleGlobalSearchChange}
      onSearchKeyPress={handleGlobalSearchKeyPress}
      key="listViewSearch"
      className="search-preview"
      wrapperClassName="product-header-search flex"
      errorMessage="text.errors.lessThan200Characters"
      queryLengthLimit={170}
      placeholder="Search by title, description"
      disabled={isEmpty(getEntityToUse())}
      isShowSearchButton
      onSearch={handleGlobalSearch}
    />
  );
};

PreviewSearch.propTypes = {
  previewSearchProps: shape().isRequired,
  virtualCategoryWidgetProps: shape().isRequired,
  promotionWidgetProps: shape().isRequired,
  setCompoundSearch: func.isRequired,
  fetchProductList: func.isRequired,
  setProductsListEmpty: func.isRequired,
  setPagination: func.isRequired,
  setGlobalSearchValue: func.isRequired,
};

PreviewSearch.defaultProps = {};

const mapStateToProps = state => ({
  previewSearchProps: previewSearchSelector(state.preview),
  virtualCategoryWidgetProps: virtualCategoryWidgetSelector(state.virtualCategoryWidget),
  promotionWidgetProps: promotionWidgetSelector(state.promotionWidget),
});

const actionCreators = {
  setCompoundSearch,
  fetchProductList,
  setProductsListEmpty,
  setPagination,
  setGlobalSearchValue,
};

export default connect(mapStateToProps, actionCreators)(PreviewSearch);
