import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { camelize, decamelize } from 'humps';
import { shape, func } from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import {
  Grid, Card, CardMedia, CardContent, CardActions,
  IconButton, Button, Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines } from '@fortawesome/free-solid-svg-icons';
import { sortableHandle } from 'react-sortable-hoc';
import config from '../../../../util/config'

import IntlMessages from '../../../../util/IntlMessages';
import Checkbox from '../../../../components/Checkbox';
import MerchantContainer from '../../containers/MerchantContainer';
import UnlockAttributesDialog from '../UnlockAttributesDialog';
import ShowMore from '../ShowMore';
import { setMerchantActivityDialog, setShowZapInfo } from '../../../../actions/pim';
import { getPublishPrice, moneyFormat } from '../../pages/Home/utils/productDataGenerator';

import OpenProductPriceModal from "components/ProductPrice/OpenProductPriceModal"
import { getProductIdFromURL } from "../../utils/sidebar";
import ProductActionsDialog from "../ProductActionsDialog";
import MergeProductsDialog from "../MergeProductsDialog";
import FormDialog from "../../../../components/FormDialog";
import { bulkUpdateProductStatus } from "../../../../actions/product";

const VariantLevelItem = memo((props) => {
  const {
    itemProps
  } = props;

  const [isOpenedUnlockDialog, setIsOpenUnlockDialog] = useState(false);
  const [isOpenedActionsPicker, setIsOpenedActionsPicker] = useState(false);
  const [isOpenedMergeDialog, setIsOpenMergeDialog] = useState(false);
  const [isOpenedProductActions, setIsOpenedProductActions] = useState(false);
  const [productActionType, setProductActionType] = useState(null);
  const [isOpenedSetInStockDialog, setIsOpenSetInStockDialog] = useState(false);
  const [isOpenedSetOOSDialog, setIsOpenSetOOSDialog] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const DragHandle = sortableHandle(() => <FontAwesomeIcon size="lg" icon={faGripLines} />);
  const { merchants, childrenIds, merchantsLoaded } = itemProps.variantProductItem;
  const existedFields = itemProps.categoriesAttributes
    .filter(cA => itemProps.variantProductItem.variationAttributes[camelize(cA.code)]);

  window.addEventListener('click', () => {
    setIsOpenedActionsPicker(false);
    const variantLevelDiv = document.getElementById('variant-level-' + itemProps.variantProductItem.id);
    if (variantLevelDiv) {
      variantLevelDiv.style.height = '100%';
    }
  });

  const showActionsPicker = () => {
    const variantLevelDiv = document.getElementById('variant-level-' + itemProps.variantProductItem.id);
    if (isOpenedActionsPicker === false) {
      setTimeout(() => {
        setIsOpenedActionsPicker(true);
        if (variantLevelDiv) {
          variantLevelDiv.style.height = '300px';
        }
      }, 100);
    } else {
      setIsOpenedActionsPicker(false);
      if (variantLevelDiv) {
        variantLevelDiv.style.height = '100%';
      }
    }
  }

  const handleCloseUnlockAttributesDialog = () => {
    setIsOpenUnlockDialog(false);
  };

  const handleUnlockAttributes = () => {
    itemProps.onUnlockAttributesClick({ variantProductItem: itemProps.variantProductItem });
  };

  const handleExpandProduct = () => {
    if (merchantsLoaded) {
      itemProps.setVariantsCollapsed(
        itemProps.variantsCollapsed.includes(itemProps.variantProductItem.id)
          ? itemProps.variantsCollapsed.filter(c => c !== itemProps.variantProductItem.id)
          : [...itemProps.variantsCollapsed, itemProps.variantProductItem.id],
      );
    } else {
      itemProps.handleExpandVariantLevel(itemProps.variantProductItem);
      itemProps.setVariantsCollapsed([
        ...itemProps.variantsCollapsed, itemProps.variantProductItem.id,
      ]);
    }
  };

  if (isFirstLoad === true && props.pim.origList.length === 1) {
    setIsFirstLoad(false);
    const productId = getProductIdFromURL();
    if (productId !== null) {
      handleExpandProduct();
    }
  }

  const temporaryDisabled = true;
  const isReviewed = (
    itemProps.variantProductItem.pimStatu
     && itemProps.variantProductItem.pimStatus.status === 'reviewed'
  );
  const exludedFieldsSet = new Set(['sizeUS']);

  const RenderVariationAttributes = () => (
    existedFields.map((attr) => {
      const currentAttributeValue = (
        itemProps.variantProductItem.variationAttributes[camelize(attr.code)]
      );

      return (
        <div className="flex direction-column pr-20 pl-20 product-item-data" key={attr.id}>
          <span className="pr-5 key">{attr.label.en}</span>
          <span className="pl-5 value">{currentAttributeValue}</span>
        </div>
      );
    })
  );

  const handleShowZapInfo = () => {
    props.setShowZapInfo(itemProps.variantValue);
  };

  const handleMergeProductsClick = () => {
    setIsOpenMergeDialog(true);
  };

  const handleCloseMergeProductsDialog = () => {
    setIsOpenMergeDialog(false);
  };

  const handleCloseProductActionsDialog = () => {
    setIsOpenedProductActions(false);
  };

  const handleSetInStock = () => {
    setIsOpenSetInStockDialog(true);
  };

  const handleCloseSetInStockDialog = () => {
    setIsOpenSetInStockDialog(false);
  };

  const submitSetInStock = () => {
    props.bulkUpdateProductStatus(itemProps.variantProductItem.id, 'in_stock');
    setIsOpenSetInStockDialog(false);
  };

  const handleSetOOS = () => {
    setIsOpenSetOOSDialog(true);
  };

  const handleCloseSetOOSDialog = () => {
    setIsOpenSetOOSDialog(false);
  };

  const submitOOS = () => {
    props.bulkUpdateProductStatus(itemProps.variantProductItem.id, 'out_of_stock');
    setIsOpenSetOOSDialog(false);
  };

  const handleRemoveProduct = () => {
    setProductActionType('remove');
    setIsOpenedProductActions(true);
  };

  const handleRestoreProduct = () => {
    setProductActionType('restore');
    setIsOpenedProductActions(true);
  };

  const handleMarkAsReviewed = () => {
    setProductActionType('markAsReviewed');
    setIsOpenedProductActions(true);
  };

  const handleMarkAsNotReviewed = () => {
    setProductActionType('markAsNotReviewed');
    setIsOpenedProductActions(true);
  };

  const ActionsPicker = ({type, productItem}) => {
    return (
      <div className="product-actions-picker">
        <div
          className="product-actions-picker-option"
          onClick={event => itemProps.onEditClick({ event, productItem: productItem })}
        >
          <IntlMessages id="preview.table.product.button.edit.label" />
        </div>

        {type === 'parent' && (
          <div
            className="product-actions-picker-option"
            onClick={handleSetInStock}
          >
            <IntlMessages id="pim.table.product.button.setInStock" />
          </div>
        )}
        {type === 'parent' && (
          <div
            className="product-actions-picker-option"
            onClick={handleSetOOS}
          >
            <IntlMessages id="pim.table.product.button.setOOS" />
          </div>
        )}
        {type !== 'parent' && (
          <div
            className="product-actions-picker-option"
            onClick={handleMergeProductsClick}
          >
            <IntlMessages id="pim.table.product.button.mergeProducts" />
          </div>
        )}
        <div
          className="product-actions-picker-option"
          onClick={fetchComments}
        >
          <IntlMessages id="pim.table.product.button.history" />
        </div>
        <div
          className="product-actions-picker-option"
          onClick={handleRemoveProduct}
        >
          <IntlMessages id="pim.table.product.button.remove" />
        </div>
        <div
          className="product-actions-picker-option"
          onClick={handleRestoreProduct}
        >
          <IntlMessages id="pim.table.product.button.restore" />
        </div>
        <div
          className="product-actions-picker-option"
          onClick={handleMarkAsReviewed}
        >
          <IntlMessages id="pim.table.product.button.markAsReviewed" />
        </div>
        <div
          className="product-actions-picker-option"
          onClick={handleMarkAsNotReviewed}
        >
          <IntlMessages id="pim.table.product.button.markAsNotReviewed" />
        </div>
        <div
          className="product-actions-picker-option"
          onClick={handleUnlockAttributes}
        >
          <IntlMessages id="pim.table.product.button.unlockAttributes" />
        </div>
      </div>
    );
  };

  const renderValue = (val, attr, urlKey, handleShowZapInfo) => {
    if (attr === 'id' && urlKey) {
      const linkToProduct = config.websitePath + '/' + urlKey.en + '/' + val;

      return (<div><a href={linkToProduct} target="_blank" rel="noopener noreferrer">{val}</a></div>);
    }

    if (attr === 'lockedAttributes') {
      if (!isEmpty(val) && val !== '-' && val !== 'N/A') {
        return val.map(v => (
          <span key={v} className="first-letter-up attribute-single">{v}</span>
        ));
      } return 'N/A';
    }

    if (attr === 'zapMinPrice' || attr === 'zapMaxPrice') {
      return (<span className="zap-link" onClick={handleShowZapInfo}>{val}</span>);
    }

    if (attr === 'zapUrl' && val && val !== 'N/A') {
      return (<a href={val} target="_blank" rel="noopener noreferrer">zap.co.il</a>);
    }

    if (attr === 'isDomestic' || attr === 'isVisibleByCategory') {
      return val === true ? 'Yes' : 'No';
    }

    if (val && val.length > 40) return `${val.substring(0, 40)}...`;

    val = (val === '-') ? 'N/A' : val;

    return val;
  };

  let imgUrl = itemProps.defaultImage;
  if (itemProps.productItem && itemProps.productItem.images && itemProps.productItem.images[0]) {
    imgUrl = itemProps.productItem.images[0].url;
  }
  if (itemProps.variantValue.imgData && itemProps.variantValue.imgData.url) {
    imgUrl = itemProps.variantValue.imgData.url;
  }
  if (itemProps.variantProductItem.parentImages && itemProps.variantProductItem.parentImages[0]) {
    imgUrl = itemProps.variantProductItem.parentImages[0].url;
  }
  if (itemProps.variantProductItem.variationImages && itemProps.variantProductItem.variationImages[0]) {
    imgUrl = itemProps.variantProductItem.variationImages[0].url;
  }

  const fetchComments = () => {
    props.setMerchantActivityDialog(true);
    itemProps.fetchActivityLogsByProductId(itemProps.variantProductItem.id);
  };

  const handleProductSelect = () => {
    itemProps.onProductSelect(cloneDeep(itemProps.variantProductItem));
  };

  const iconClassName = itemProps.variantsCollapsed
    .includes(itemProps.variantProductItem.id) ? 'zmdi-chevron-down' : 'zmdi-chevron-right';

  const className = itemProps.variantProductItem.type === 'merchant' ? 'tr-success' : '';

  return (
    <Grid item xs={12} className={className}>
      <div id={'variant-level-' + itemProps.variantProductItem.id} className="product-item product-item-child flex items-center pr-20 pl-20">
        <div className={`product-item-type ${itemProps.variantProductItem.type}`}>
          <span>{itemProps.variantProductItem.type}</span>
        </div>
        <Card className="product-item-card-list without-dnd">
          <CardActions className="card-collapse-btn pr-0" style={{width: '100px'}}>

            {itemProps.variantProductItem.type !== 'parent' && !isEmpty(childrenIds) && (
              <div
                id={'product-action-arrow-' + itemProps.variantProductItem.id}
                className="justify-center direction-column product-arrow-variation">
                <IconButton color="inherit" onClick={handleExpandProduct}>
                  <i className={`zmdi ${iconClassName}`} />
                </IconButton>
              </div>
            )}

            {!itemProps.disabled && itemProps.isEditMode && (
              <Checkbox
                id={'product-action-checkbox-' + itemProps.variantProductItem.id}
                className="product-checkbox-variation"
                checked={
                  itemProps.selectedProducts
                    .some(p => p.id === itemProps.variantProductItem.id)
                }
                onChange={handleProductSelect}
                name={itemProps.variantValue.title ? camelize(itemProps.variantValue.title) : ''}
              />
            )}

          </CardActions>
          <Tooltip
            className="img-tooltip"
            title={(
              <img
                className="product-item-image tooltip-inner"
                src={imgUrl}
                alt=""
              />
            )}
            placement="right"
          >
            <CardMedia
              className="product-item-image"
              style={{marginRight: '20px'}}
              image={imgUrl}
            />
          </Tooltip>

          <CardContent className="flex full-width ml-0 mr-0">
            <div className="flex items-center full-width">
              {
                Object.keys(itemProps.variantValue).filter(
                  vKey => (
                    typeof itemProps.variantValue[vKey] === 'string'
                    || typeof itemProps.variantValue[vKey] === 'number'
                    || vKey === 'lockedAttributes'
                  ) && vKey !== 'ils' &&
                  vKey !== 'aoBrandName' && vKey !== 'condition' && vKey !== 'title' && vKey !== 'description',
                ).map((itemKey) => {
                  let val = itemProps.variantValue[itemKey];
                  const itemValueLength = val ? val.toString().length : 0;

                  let title = decamelize(itemKey).replace('_', ' ').replace('_', ' ');
                  const productFields = props.system.modes.pim[itemProps.variantProductItem.type];
                  for (let i = 0; i < productFields.length; i++) {
                    if (productFields[i].value === decamelize(itemKey)) {
                      title = productFields[i].label;
                    }
                  }

                  const tinyClass = itemValueLength <= 25 && !exludedFieldsSet.has(itemKey) ? 'tiny' : '';

                  if (
                    itemKey === 'price.finalPrice'
                    || itemKey === 'minPrice'
                    || itemKey === 'maxPrice'
                    || itemKey === 'price.minPrice'
                    || itemKey === 'price.maxPrice'
                    || itemKey === 'price.msrp'
                    || itemKey === 'price.cost'
                    || itemKey === 'price.shippingCost'
                    || itemKey === 'price.profit'
                    || itemKey === 'shippingCost') {
                    val = moneyFormat(val, '$');
                  }

                  if ((
                    itemKey === 'originPublishPrice'
                    || itemKey === 'publishPrice'
                    || itemKey === 'frontendPrice.publishPrice'
                  ) && itemProps.currency) {
                    let currencySymbol = itemProps.currency.item.symbols;
                    if (currencySymbol === 'ILS') {
                      currencySymbol = '₪';
                    }
                    if (val >= 0) {
                      val = moneyFormat(Math.ceil(val), currencySymbol);
                    } else {
                      if (itemProps.variantProductItem.price && itemProps.variantProductItem.price.finalPrice) {
                        const originPublishPrice = getPublishPrice(
                          itemProps.variantProductItem.price.finalPrice,
                          itemProps.currency.item.value,
                          itemProps.variantProductItem.isDomestic
                        );
                        val = moneyFormat(Math.ceil(originPublishPrice), currencySymbol);
                      }
                    }
                  }

                  return (itemKey === "price" && itemProps.variantProductItem.priceFormula ? 
                  <OpenProductPriceModal priceFormula={itemProps.variantProductItem.priceFormula}>
                    <div
                    className={`flex direction-column pr-20 pl-20 product-item-data ${tinyClass}`}
                    key={itemKey}
                  >
                    <div className="flex">
                      <span className="key capitalize">{title}</span>
                      {itemValueLength
                        && itemValueLength > 25
                        && !itemProps.isEditMode
                        ? (
                          <ShowMore
                            attr={{ key: title }}
                            itemValue={val || '-'}
                          />
                        )
                        : <></>}
                    </div>

                    <span className={`value value-${itemKey}`}>
                      {renderValue(val, itemKey, itemProps.urlKey, handleShowZapInfo)}
                    </span>

                  </div>
                  </OpenProductPriceModal> :
                    <div
                      className={`flex direction-column pr-20 pl-20 product-item-data ${tinyClass}`}
                      key={itemKey}
                    >
                      <div className="flex">
                        <span className="key capitalize">{title}</span>
                        {itemValueLength
                          && itemValueLength > 25
                          && !itemProps.isEditMode
                          ? (
                            <ShowMore
                              attr={{ key: title }}
                              itemValue={val || '-'}
                            />
                          )
                          : null}
                      </div>
                      <span className={`value value-${itemKey}`}>
                        {renderValue(val, itemKey, itemProps.urlKey, handleShowZapInfo)}
                      </span>
                    </div>
                  );
                })
              }
              <RenderVariationAttributes />
            </div>
            <div className="flex edit-icon-wrapper items-center">
              <div className="edit-icon">
                <Button
                  color="primary"
                  className="btn-xs extra float-right"
                  onClick={showActionsPicker}
                >
                  <IntlMessages id="title.actions" />
                </Button>
              </div>

              {isReviewed && (
                <Tooltip title={`by ${itemProps.variantProductItem.pimStatus.reviewedBy}`} placement="top">
                  <span className="badge-success badge-xs badge-rel badge-text secondary">
                    <IntlMessages id="pim.table.product.badge.reviewed.label" />
                  </span>
                </Tooltip>
              )}
            </div>

            {!itemProps.disabled && !temporaryDisabled && (
              <div className="flex ml-auto dnd-icon items-center">
                <DragHandle />
              </div>
            )}

          </CardContent>
        </Card>

        {isOpenedActionsPicker && (
          <ActionsPicker type={itemProps.variantProductItem.type} productItem={itemProps.variantProductItem} />
        )}

      </div>
      {itemProps.fetchVariantLevelChildrenProductsStarted
        && itemProps.lastParentChildrenLoaded === itemProps.variantProductItem.id
        && <span className="loading">Loading...</span>}
      {
        (
          itemProps.variantsCollapsed.includes(itemProps.variantProductItem.id)
          && !isEmpty(merchants)
        ) && (
          <MerchantContainer
            variant={itemProps.variantProductItem}
            merchants={merchants}
            searchFields={itemProps.searchFields}
            onEditClick={itemProps.onEditClick}
            isEditMode={itemProps.isEditMode}
            disableEdit={itemProps.disableEdit}
          />
        )
      }
      <UnlockAttributesDialog
        key={`unlock-dialog-${itemProps.variantProductItem.id}`}
        isOpened={isOpenedUnlockDialog}
        onClose={handleCloseUnlockAttributesDialog}
        onUnlockAttributesClick={handleUnlockAttributes}
        commentMessages={itemProps.commentMessages}
        bulkDeleteProductLockedAttributesStarted={
          itemProps.bulkDeleteProductLockedAttributesStarted
        }
        bulkDeleteProductLockedAttributesFinished={
          itemProps.bulkDeleteProductLockedAttributesFinished
        }
        onCommentsUpdate={itemProps.multipleUpdateUserActivityLogs}
        product={itemProps.variantProductItem}
      />
      <MergeProductsDialog
        key={`merge-dialog-${itemProps.variantProductItem.id}`}
        isOpened={isOpenedMergeDialog}
        onClose={handleCloseMergeProductsDialog}
        onMergeProductsClick={handleMergeProductsClick}
        commentMessages={itemProps.commentMessages}
        onCommentsUpdate={itemProps.multipleUpdateUserActivityLogs}
        product={itemProps.variantProductItem}
      />
      <FormDialog
        title={<IntlMessages id="pim.table.product.badge.setInStock.label" />}
        open={isOpenedSetInStockDialog}
        hideCloseButton={false}
        className="dialog-inputs-custom dialog-content-visible"
        closeButtonTitle="Close"
        submitButtonTitle="Submit"
        maxWidth="xs"
        onClose={handleCloseSetInStockDialog}
        onSubmit={submitSetInStock}
      >
        <IntlMessages id="alert.sureTitle" />
      </FormDialog>
      <FormDialog
        title={<IntlMessages id="pim.table.product.badge.setOOS.label" />}
        open={isOpenedSetOOSDialog}
        hideCloseButton={false}
        className="dialog-inputs-custom dialog-content-visible"
        closeButtonTitle="Close"
        submitButtonTitle="Submit"
        maxWidth="xs"
        onClose={handleCloseSetOOSDialog}
        onSubmit={submitOOS}
      >
        <IntlMessages id="alert.sureTitle" />
      </FormDialog>
      <ProductActionsDialog
        key={`product-actions-dialog-${itemProps.variantProductItem.id}`}
        type={productActionType}
        isOpened={isOpenedProductActions}
        onClose={handleCloseProductActionsDialog}
        commentMessages={itemProps.commentMessages}
        product={itemProps.variantProductItem}
      />
    </Grid>
  );
});

VariantLevelItem.propTypes = {
  itemProps: shape().isRequired,
  setMerchantActivityDialog: func.isRequired,
  setShowZapInfo: func.isRequired,
  bulkUpdateProductStatus: func.isRequired,
};
const mapStateToProps = state => (state);

const mapDispatchToProps = {
  setMerchantActivityDialog,
  setShowZapInfo,
  bulkUpdateProductStatus,
};

VariantLevelItem.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VariantLevelItem);
