import React from 'react';
import MatButton from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';

import {
  string, node, shape, func, oneOfType, bool,
} from 'prop-types';

export const ButtonPrimary = ({
  settings, helperText, className, matSettings,
}) => {
  const {
    prefix, suffix, value, type, wrapperClassName, onClick, disabled, variant = 'contained',
  } = settings;

  return (
    <div className={`button no-wrap ${wrapperClassName || ''}`}>
      <MatButton
        variant={variant}
        disabled={disabled}
        className={`btn-${type} text-white ${className}`}
        onClick={onClick}
        {...matSettings}
      >
        {prefix && prefix}
        {value}
        {suffix && suffix}
      </MatButton>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
};

ButtonPrimary.propTypes = {
  className: string,
  matSettings: shape(),
  settings: shape({
    suffix: node,
    prefix: node,
    value: oneOfType([string, node]),
    type: string,
    wrapperClassName: string,
    variant: string,
    onClick: func,
    disabled: bool,
  }),
  helperText: oneOfType([string, node]),
};

ButtonPrimary.defaultProps = {
  className: '',
  helperText: null,
  matSettings: {},
  settings: {},
};

export default ButtonPrimary;
