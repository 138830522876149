import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import IntlMessages from '../../../../util/IntlMessages';
import PagePlaceholder from "../../../../components/PagePlaceholder";

const CamsTable = ({
  fields,
  data,
  camsCondition,
}) => {
  const [camsData, setCamsData] = useState([]);

  useEffect(() => {
    const formatData = [];
    if (data.aggregations && data.aggregations.aggregations.buckets) {
      for (let i = 0; i < data.aggregations.aggregations.buckets.length; i++) {
        formatData[i] = data.aggregations.aggregations.buckets[i];
      }
    }
    setCamsData(formatData);
  }, [ data ]);

  const tableData = [];

  for (let i = 0; i < camsData.length; i++) {
    tableData[i] = {
      merchant_id: camsData[i].key,
      total_products: camsData[i].doc_count,
      error_products: camsData[i].errorCount.doc_count,
    }
  }

  const getTableRowColor = (itemKey) => {
    return itemKey%2 ? '#ffffff' : '#DFDFDF';
  };

  const CamsTitle = (props) => {
    return (
      <TableCell className="cams-no-wrap mapper-td" style={{width: props.width}}>
        <strong>{props.name}</strong>
      </TableCell>
    );
  };

  const CamsItem = (props) => {
    let tableValue = props.parent ? props.item[props.parent][props.value] : props.item[props.value];

    return (
      <TableCell className="cams-errors-td">{ tableValue }</TableCell>
    );
  };

  return (
    <>
      {tableData && tableData.length > 0 ? (
        <TableContainer id="camsTableContainer" className="product-table-container-no-scroll">
          <Table id="camsTable" stickyHeader aria-label="sticky table" size="small">
            <TableHead className="products-table-header">
              <TableRow>
                {Object.keys(fields).map((item, itemKey) => (
                  <CamsTitle
                    key={'title_' + itemKey}
                    itemkey={itemKey}
                    item={item}
                    condition={camsCondition}
                    type={Object.values(fields)[itemKey].type}
                    name={Object.values(fields)[itemKey].name}
                    width={Object.values(fields)[itemKey].width}
                  />
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData && tableData.map((item, itemKey) => (
                <TableRow key={'row_' + itemKey} style={{backgroundColor: getTableRowColor(itemKey)}}>
                  {Object.keys(fields).map((value, valueKey) => (
                    <CamsItem
                      key={'item_' + itemKey + '_' + valueKey}
                      type={Object.values(fields)[valueKey].type}
                      parent={Object.values(fields)[valueKey].parent}
                      item={item}
                      itemKey={itemKey}
                      value={value}
                      valueKey={valueKey}
                    />
                  ))}
                </TableRow>
              ))}
            </TableBody>

          </Table>
        </TableContainer>
      ) : (
        <TableRow>
          <TableCell className="feed-no-border">
            <PagePlaceholder
              icon={<i className="ti-face-sad text-primary text-lg" />}
              title={<IntlMessages id="title.sorry" />}
              subtitle={<IntlMessages id="title.noResultsFound" />}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default CamsTable;
