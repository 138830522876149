export const paginationLimit = 24;

export const getProductCompoundSearchFilter = query => ({
  value: query,
});

export const defaultPagination = {
  page: 1,
  limit: 100,
};
