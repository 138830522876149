
import React from 'react';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import JssProvider from 'react-jss/lib/JssProvider';
import { createGenerateClassName } from '@material-ui/core/styles';
import Router from './Router';
import Auth from './Auth';
import ErrorBoundary from './ErrorBoundary';
import { PermissionsProvider } from './PermissionsContext';
import { AbilityContext } from './AbilityContext';
import ability from '../util/ability';

import '../lib/reactifyCss';
import '../firebase';

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
});

const App = () => (
  <PermissionsProvider>
    <AbilityContext.Provider value={ability}>
      <JssProvider generateClassName={generateClassName}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Auth>
            <ErrorBoundary>
              <Router />
            </ErrorBoundary>
          </Auth>
        </MuiPickersUtilsProvider>
      </JssProvider>
    </AbilityContext.Provider>
  </PermissionsProvider>
);

App.propTypes = {};

export default App;
