import axios from 'axios';
import { uniq, isEmpty } from 'lodash';
import { camelizeKeys } from 'humps';
import RestActions from '../util/rest/actions';
import {
  importProductsXLSXCloudFuncPath,
  generateXLSXCloudFuncPath,
} from '../util/paths';
import { showErrorNotification } from '../util/api';

import {
  IMPORT_PRODUCTS_WITH_NEW_POSITION_START,
  IMPORT_PRODUCTS_WITH_NEW_POSITION_FINISHED,
  GENERATE_NEW_XLSX_FILE_START,
  GENERATE_NEW_XLSX_FILE_FINISHED,
  SET_IMPORT_FILE_NAME,
  FETCH_IMPORTED_PRODUCTS_START,
  FETCH_IMPORTED_PRODUCTS_FINISHED,
  CLEAR_PRODUCT_MANAGEMENT_STATE,
} from './types';

import {
  getFrontendProductsByCategory,
  getFrontendProductsByUrlKey,
  getFrontendProductsByPhrase,
  getFrontendProductsByIds,
} from '../services/API/product';

import { mapExportableProducts } from '../util/mapExportableProducts';

const productManagementActions = new RestActions('product_management');

const getFBAuthHeader = (cdmsToken) => {
  const token = localStorage.getItem('fb_token');
  const authHeader = {
    Authorization: `Bearer ${token}`,
    'CDMS-Authorization': `Bearer ${cdmsToken}`,
  };
  return authHeader;
};

const getProductsMethod = (params) => {
  let targetMethod = null;
  let urlSearchParams = '';

  if (params.entity === 'category') {
    targetMethod = getFrontendProductsByCategory;
    urlSearchParams = `?category_id=${params.categoryId}`;
  }
  if (params.entity === 'promotion') {
    targetMethod = getFrontendProductsByUrlKey;
    urlSearchParams = `?url_key=${params.urlKey}`;
  }
  if (params.entity === 'searchFilter') {
    targetMethod = getFrontendProductsByPhrase;
    urlSearchParams = `?search_phrase=${params.searchPhrase}`;
  }

  return { urlSearchParams, targetMethod };
};

export const fetchImportedProducts = (ids, pagination) => async (dispatch) => {
  const pages = Array.from(Array(Math.ceil(ids.length / pagination.limit)).keys());

  dispatch({ type: FETCH_IMPORTED_PRODUCTS_START });

  const promises = await pages.map(async (page) => {
    const query = {
      filter: [{
        group: [{
          field: 'id',
          value: uniq(ids),
          operator: 'in',
        }],
      }],
      sort: [{
        field: 'id',
        order: 'asc',
      }],
      pagination: {
        page: page + 1,
        limit: pagination.limit,
      },
    };
    const res = await getFrontendProductsByIds(query);

    return res;
  });

  const list = await Promise.all(promises);

  dispatch({
    type: FETCH_IMPORTED_PRODUCTS_FINISHED,
    payload: { importedProducts: camelizeKeys(list.flat()) },
  });
  return camelizeKeys(list.flat());
};

export const importXLSXProducts = xmlBase64String => async (dispatch, getState) => {
  const cdmsToken = getState().serviceConfig.item.token;
  try {
    dispatch({ type: IMPORT_PRODUCTS_WITH_NEW_POSITION_START });

    const xlsxImportResult = await axios.post(
      importProductsXLSXCloudFuncPath,
      { xmlBase64String },
      {
        headers: {
          ...getFBAuthHeader(cdmsToken),
        },
      },
    );
    const { products } = camelizeKeys(xlsxImportResult.data.result);
    const productsBody = [...products];
    productsBody.shift();

    dispatch({
      type: IMPORT_PRODUCTS_WITH_NEW_POSITION_FINISHED,
      payload: { parsedImportProducts: products },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    throw error;
  }
};

export const generateXLSX = inputData => async (dispatch, getState) => {
  const cdmsToken = getState().serviceConfig.item.token;
  try {
    dispatch({ type: GENERATE_NEW_XLSX_FILE_START });

    const xlsxDocumentResult = await axios.post(
      generateXLSXCloudFuncPath,
      { inputData },
      {
        headers: {
          ...getFBAuthHeader(cdmsToken),
        },
      },
    );
    const xlsxDocument = xlsxDocumentResult.data.result;

    dispatch({
      type: GENERATE_NEW_XLSX_FILE_FINISHED,
      payload: { xlsxDocument },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    throw error;
  }
};

export const getExportableProducts = (
  params, hiddenProductsIds, fields, pagination = {}, total = 0,
) => async (dispatch) => {
  const pages = Array.from(Array(Math.ceil(total / pagination.limit)).keys());
  const { urlSearchParams, targetMethod } = getProductsMethod(params);
  let hiddenProducts = [];
  const query = {
    fields: [
      ...fields,
      'specifications',
      'variation.facets',
    ],
    sort: [{
      field: 'id',
      order: 'asc',
    }],
  };

  dispatch(productManagementActions.fetchAllStart());

  if (!isEmpty(hiddenProductsIds)) {
    hiddenProducts = await getFrontendProductsByIds({
      ...query,
      pagination: {
        page: 1,
        limit: 500,
      },
      filter: [{
        condition: 'and',
        group: [{
          field: 'id',
          value: uniq(hiddenProductsIds),
          operator: 'in',
        }],
      }],
    });
  }

  if (total === 0) {
    return {
      regularProducts: [],
      hiddenProducts: camelizeKeys(hiddenProducts),
    };
  }

  const promises = await pages.map(async (page) => {
    const regularProducts = await targetMethod({
      ...query,
      pagination: {
        page: page + 1,
        limit: pagination.limit,
      },
    }, urlSearchParams);

    return regularProducts;
  });

  const list = await Promise.all(promises);
  const mappedList = mapExportableProducts(camelizeKeys(list.flat()));
  const mappedHiddenList = mapExportableProducts(camelizeKeys(hiddenProducts));

  dispatch(productManagementActions.fetchAllFinished({ mappedList }));
  return {
    regularProducts: mappedList,
    hiddenProducts: mappedHiddenList,
  };
};

export const setImportFileName = fileName => (dispatch) => {
  dispatch({ type: SET_IMPORT_FILE_NAME, payload: { fileName } });
};

export const clearProductManagementState = () => (dispatch) => {
  dispatch({ type: CLEAR_PRODUCT_MANAGEMENT_STATE });
};

export default {
  importXLSXProducts,
  generateXLSX,
  getExportableProducts,
  setImportFileName,
  clearProductManagementState,
};
