import React, { useState, useEffect } from 'react';
import Search from '../../components/Search';
import Pagination from '../../../../components/Pagination';
import Sorting from '../../../../components/Sorting';
import NewRecord from '../../components/NewRecord';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import IntlMessages from '../../../../util/IntlMessages';
import {initialTableScroll, moveMapperTableScroll, moveTableScroll} from '../../../../util/table';
import PagePlaceholder from "../../../../components/PagePlaceholder";

export const MapperTable = ({
  getMappingData,
  mapperFields,
  mapperData,
  mapperError,
  mapperScroll,
  mapperCondition,
  mapperNewRecordOpen,
  mapperItemsPerPage,
  mapperMarketplace,
  mapperMarketplaces,
  getProductPage
}) => {

  const [tableState, setTableState] = useState('');
  const [unsavedMapData, setUnsavedMapData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [mapError, setMapError] = useState([]);
  const [mapScroll, setMapScroll] = useState([]);
  const [mapCondition, setMapCondition] = useState([]);
  const [mapNewRecordOpen, setMapNewRecordOpen] = useState(false);
  const [defaultSavingValue, setDefaultSavingValue] = useState([]);
  const [newMapperItem, setNewMapperItem] = useState([]);
  const [mapperAllPages, setMapperAllPages] = useState(0);
  const [mapperCurrentPage, setMapperCurrentPage] = useState(1);
  const [currentMapperFields, setCurrentMapperFeelds] = useState([]);

  useEffect(() => {
    const formatMapData = [];
    const formatMapError = [];
    const defSavingValue = [];
    const mapperFieldsKeys = Object.keys(mapperFields);
    const allMapperFieldsKeys = Object.keys(mapperFields);

    for (let i = 0; i < mapperData.length; i++) {
      if (mapperData[i]) {
        const formatDataItem = [];
        const formatErrorItem = [];
        for (let j = 0; j < mapperFieldsKeys.length; j++) {

          if (mapperFields[mapperFieldsKeys[j]].defaultSavingValue) {
            defSavingValue[i] = {
              'position': j,
              'value': mapperFields[mapperFieldsKeys[j]].defaultSavingValue
            }
          }
          setDefaultSavingValue(defSavingValue);

          formatDataItem[j] = mapperData[i][mapperFieldsKeys[j]];

          formatErrorItem[j] = '';
          if (mapperError[i]) {
            if (mapperError[i][mapperFieldsKeys[j]]) {
              formatErrorItem[j] = mapperError[i][mapperFieldsKeys[j]];
            }
          }

        }
        formatMapData[i] = formatDataItem;
        formatMapError[i] = formatErrorItem;
      }
    }

    let j = 0;
    const currentMapperFields = [];
    for (let i = 0; i < allMapperFieldsKeys.length; i++) {
      if (formatMapData && formatMapData[0] && formatMapData[0][i] !== undefined) {
        currentMapperFields[j] = mapperFields[allMapperFieldsKeys[i]];
        currentMapperFields[j].field = allMapperFieldsKeys[i];
        j++;
      }
    }
    setCurrentMapperFeelds(currentMapperFields);

    const currentFormatData = [];
    for (let i = 0; i < formatMapData.length; i++) {
      currentFormatData[i] = formatMapData[i].filter(function (el) {
        return el !== undefined;
      });
    }
    setMapData(currentFormatData);
    setUnsavedMapData(currentFormatData);

    setMapError(formatMapError);
    setMapScroll(mapperScroll);
    setMapCondition(mapperCondition);
    setMapNewRecordOpen(mapperNewRecordOpen);
    setNewMapperItem([]);
    setMapperAllPages(Math.ceil(mapperScroll.total / mapperItemsPerPage));
    let currentPage = Math.ceil(mapperScroll.offset / mapperItemsPerPage) + 1;
    if (currentPage <= 0) {
      currentPage = 1;
    }
    setMapperCurrentPage(currentPage);
  }, [
    mapperData, mapperError, mapperScroll, mapperCondition, mapperNewRecordOpen, mapperFields, mapperItemsPerPage
  ]);

  const handleEditMappingItem = (e, type, value, itemKey, valueKey) => {
    e.stopPropagation();
    if (type === 'url') {
      window.open(value);
    }
    else if (Object.values(currentMapperFields)[valueKey].editable) {
      setTimeout(function(){
        setTableState(itemKey + ',' +  valueKey);
      }, 100);
    } else {
      setTableState('');
    }
  };

  const handleCopyMappingItem = (e, item) => {
    setNewMapperItem(item);
  };

  const endMappingEdit = (e, value, itemKey, valueKey, isCheckbox = false, specialRule = null) => {
    if (e.key === 'Enter') {
      changeMappingItem(e, e.target.value, itemKey, valueKey, false, specialRule);
      setTableState('');
    }
  };

  const changeMappingItem = (e, value, itemKey, valueKey, isCheckbox = false, specialRule = null) => {
    const formatMapData = [];
    for (let i = 0; i < mapData.length; i++) {
      formatMapData[i] = [];
      for (let j = 0; j < mapData[i].length; j++) {
        if (i === itemKey && j === valueKey) {
          if (isCheckbox === true) {
            formatMapData[i][j] = !mapData[i][j];
          } else {
            formatMapData[i][j] = e.target.value ? e.target.value : value;
          }
          if (defaultSavingValue[i] && !formatMapData[i][defaultSavingValue[i].position]) {
            formatMapData[i][defaultSavingValue[i].position] = defaultSavingValue[i].value;
          }
          if (j !== 4 && (!formatMapData[i][4] || formatMapData[i][4] === "0")) {
            formatMapData[i][4] = 1;
          }

          if (specialRule && specialRule.type === 'url') {
            const target = specialRule.targetIndex;
            if (j === specialRule.index && formatMapData[i][j]) {
              formatMapData[i][target] = mapData[i][target] =
                specialRule.value.replace('[value]', formatMapData[i][j]);
            } else if (j === specialRule.index) {
              formatMapData[i][target] = mapData[i][target] = '';
            }
          }

        } else {
          formatMapData[i][j] = mapData[i][j];
        }
      }
    }
    setMapData(formatMapData);
  };

  const MapperTitle = ((props) => {
    const {
      type, name, field, sortable, fixedWidth
    } = props;

    if (type === 'invisible') {
      return '';
    }

    let className = "mapper-no-wrap";
    if (fixedWidth === 'long') {
      className += " mapper-w-350";
    }
    if (fixedWidth === 'middle') {
      className += " mapper-w-150";
    }
    if (fixedWidth === 'short') {
      className += " mapper-w-50";
    }

    if (sortable === true) {
      className += " mapper-sortable";

      return (
        <Sorting
          getData={getMappingData}
          item={field}
          condition={mapCondition}
          name={name}
          className={className}
          itemsPerPage={mapperItemsPerPage}
          marketplace={mapperMarketplace}
        />
      );
    }

    return (
      <TableCell><div className={className}>{ name }</div></TableCell>
    );
  });

  const getTableRowColor = (item, itemKey, currentMapperFields) => {
    let color = '';

    Object.keys(currentMapperFields).map((value, valueKey) => {
      if (value === 'is_active') {
        const val = item[valueKey] ? 1 : 0;
        const colors = Object.values(currentMapperFields)[valueKey].colors;
        if (colors[val]) {
          color = colors[parseInt(item[valueKey])];
        }
      } else if (value === 'status') {
        const colors = Object.values(currentMapperFields)[valueKey].colors;
        if (colors) {
          color = colors[item[valueKey]];
        }
        if (item[valueKey] === 1) {
          Object.keys(currentMapperFields).map((val, valKey) => {
            if (val === 'last_sync_status') {
              const colors = Object.values(currentMapperFields)[valKey].colors;
              if (colors) {
                color = colors[item[valKey]];
              }
            }

            return color;
          });
        }
      }

      return color;
    });

    return color;
  };

  const getItemValue = (item, field) => {
    const currentMapperFieldsKeys = Object.keys(currentMapperFields);
    for (let i = 0; i < currentMapperFieldsKeys.length; i++) {
      if (currentMapperFields[currentMapperFieldsKeys[i]].field === field) {
        return item[currentMapperFieldsKeys[i]];
      }
    }

    return false;
  };

  const MapperItem = ((props) => {
    const {
      type, editable, specialRule, options, maxLength, item, itemKey, value, valueKey
    } = props;

    if (type === 'id') {
      return (
        <TableCell className="mapper-td">
          <span>{value}</span>
        </TableCell>
      );
    }

    if (type === 'invisible') {
      return '';
    }

    if (type === 'copy') {
      return (
        <TableCell className="mapper-td">
          <span className="mapper-copy" onClick={e => handleCopyMappingItem(e, item)}>Copy</span>
        </TableCell>
      );
    }

    if (tableState === itemKey + ',' + valueKey) {
      return (
        <TableCell className="mapper-td-input">
          <input className="mapper-input"
                 id={'mapper-field-' + itemKey + '-' + valueKey}
                 ref={input => input && input.focus()}
                 onClick={e => handleEditMappingItem(e, type, value, itemKey, valueKey, tableState)}
                 onKeyPress={e => endMappingEdit(e, value, itemKey, valueKey, false, specialRule)}
                 defaultValue={value}
          />
        </TableCell>
      );
    }

    const lastSyncStatus = getItemValue(item, 'last_sync_status');

    if (type === 'image') {
      return (
        <TableCell className="mapper-td"
            onClick={e => handleEditMappingItem(e, type, value, itemKey, valueKey, tableState)}>
          <a href={item[valueKey - 1]} target="_blank" rel="noopener noreferrer">
            <img src={value} alt={item[valueKey + 4]} height="80" />
          </a>
        </TableCell>
      );
    } else if (type === 'title' && lastSyncStatus === 2) {
      const pubAopi = getItemValue(item, 'pub_aopi');
      const titleValue = (maxLength && value.length > maxLength) ? value.substring(0, maxLength) + '...' : value;
      return (
        <TableCell className="mapper-sortable" onClick={() => getProductPage(pubAopi)}>
          { titleValue }
        </TableCell>
      );
    } else if (type === 'select' && editable === true) {
      return (
        <TableCell className="mapper-td-input">
          <select className="mapper-select"
                  id={'status_' + itemKey + valueKey}
                  onChange={e => changeMappingItem(e, value, itemKey, valueKey)}
                  value={value}>
            {options.map((selectValue, selectKey) => (
              <option key={selectKey} value={selectKey}>{selectValue}</option>
            ))}
          </select>
        </TableCell>
      );
    } else if (type === 'checkbox' && editable === true) {
      return (
        <TableCell className="mapper-td mapper-editable">
          <input className="mapper-checkbox"
                 type="checkbox"
                 onChange={e => changeMappingItem(e, value, itemKey, valueKey, true)}
                 checked={!!value}
                 value={value} />
        </TableCell>
      );
    } else {
      let tablevalue = '';

      if (type === 'checkbox') {
        tablevalue = value ? 'Yes' : 'No';
      } else if (type === 'checkbox_revert') {
        tablevalue = value ? 'No' : 'Yes';
      } else if (type === 'select' && options) {
        for (let i = 0; i < options.length; i++) {
          if (i === value) {
            tablevalue = options[i];
          }
        }
      } else {
        tablevalue = value ? value : 'N/A';
        if (maxLength && tablevalue.length > maxLength) {
          tablevalue = tablevalue.substring(0, maxLength) + '...';
        }
      }

      let className = 'mapper-td';
      if (editable === true) {
        className += ' mapper-editable';
      } else if (tablevalue !== 'N/A' && type === 'url') {
        className += ' mapper-sortable';
      }

      if (Array.isArray(tablevalue)) {
        let arrValue = '';
        for (let i = 0; i < tablevalue.length; i++) {
          arrValue += tablevalue[i];
          if (i < tablevalue.length - 1) {
            arrValue += ',';
          }
        }
        tablevalue = arrValue;
      }

      return (
        <TableCell className={className}
            onClick={e => handleEditMappingItem(e, type, value, itemKey, valueKey, tableState)}>
          {tablevalue}
          <div className="mapper-error">{mapError[itemKey][valueKey]}</div>
        </TableCell>
      );
    }
  });

  initialTableScroll(
    'mapperTable',
    'mapperTableScroll',
    'mapperTableContainer',
    'mapperTableContainerScroll'
  );

  const moveScroll = () => {
    moveTableScroll('mapperTableContainer', 'mapperTableContainerScroll');
  };

  window.addEventListener('wheel', () => {
    moveMapperTableScroll('mapperTableHead');
  });

  window.addEventListener('click', () => {
    moveMapperTableScroll('mapperTableHead');
  });

  return (
    <>
      <Search mapperFields={mapperFields}
              mapperItemsPerPage={mapperItemsPerPage}
              unsavedMapData={unsavedMapData}
              mapData={mapData}
              mapScroll={mapScroll}
              mapCondition={mapCondition}
              mapperMarketplace={mapperMarketplace}
      />

      <br/>

      <NewRecord mapperFields={mapperFields}
                 item={newMapperItem}
                 mapperItemsPerPage={mapperItemsPerPage}
                 mapNewRecordOpen={mapNewRecordOpen}
                 mapScroll={mapScroll}
                 mapCondition={mapCondition}
                 mapperMarketplace={mapperMarketplace}
                 mapperMarketplaces={mapperMarketplaces}
      />

      <div id="mapperTableScrollDiv" className="mapper-table-scroll">
        <TableContainer id="mapperTableContainerScroll"
                        className="product-table-container"
                        onScroll={moveScroll} >
          <Table id="mapperTableScroll">
            <TableHead style={{backgroundColor: 'transparent'}}>
              <TableRow><TableCell>&nbsp;</TableCell></TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </div>

      <TableContainer id="mapperTableContainer" className="product-table-container-no-scroll">
        <Table id="mapperTable" stickyHeader aria-label="sticky table" size="small">

          {mapData && (
            <TableHead id="mapperTableHead" className="products-table-header mapper-sticky">
              <TableRow>
                {Object.keys(currentMapperFields).map((item, itemKey) => (
                  <MapperTitle key={'mapper-title-' + itemKey}
                               classNaqme="mapper-no-wrap"
                               field={Object.values(currentMapperFields)[itemKey].field}
                               type={Object.values(currentMapperFields)[itemKey].type}
                               name={Object.values(currentMapperFields)[itemKey].name}
                               sortable={Object.values(currentMapperFields)[itemKey].sortable}
                               fixedWidth={Object.values(currentMapperFields)[itemKey].fixedWidth}
                  />
                ))}
                <MapperTitle classNaqme="mapper-no-wrap"
                             name="Actions"
                             sortable="false"
                             fixedWidth="small"
                />
              </TableRow>
            </TableHead>
          )}

          <TableBody>

          {mapData && mapData.map((item, itemKey) => (
            <TableRow key={'tr-' + itemKey} style={{backgroundColor: getTableRowColor(item, itemKey, mapperFields)}}>
              {Object.keys(currentMapperFields).map((value, valueKey) => (
                <MapperItem key={'mapper-item-' + valueKey}
                            type={Object.values(currentMapperFields)[valueKey].type}
                            editable={Object.values(currentMapperFields)[valueKey].editable}
                            specialRule={Object.values(currentMapperFields)[valueKey].specialRule}
                            options={
                            Object.values(currentMapperFields)[valueKey].saveOptions ?
                            Object.values(currentMapperFields)[valueKey].saveOptions :
                            Object.values(currentMapperFields)[valueKey].options
                            }
                            maxLength={Object.values(currentMapperFields)[valueKey].maxLength}
                            item={item}
                            itemKey={itemKey}
                            value={item[valueKey]}
                            valueKey={valueKey}
                />
              ))}
              <MapperItem key={'mapper-item-copy'} type="copy" item={item} />
            </TableRow>
          ))}

          {mapData.length === 0 && (
            <TableRow key={'tr-no-results'}>
              <TableCell>
                <PagePlaceholder
                  icon={<i className="ti-face-sad text-primary text-lg" />}
                  title={<IntlMessages id="title.sorry" />}
                  subtitle={<IntlMessages id="title.noResultsFound" />}
                />
              </TableCell>
            </TableRow>
          )}

          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        getData={getMappingData}
        dataCount={mapData ? mapData.length : 0}
        itemsPerPage={mapperItemsPerPage}
        allPages={mapperAllPages}
        currentPage={mapperCurrentPage}
        scroll={mapScroll}
        condition={mapCondition}
        marketplace={mapperMarketplace}
      />
    </>
  );
};

export default MapperTable;
