/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useRankingEffect = ({
  navigationFetched, navigationItem, setFacets,
}) => {
  useEffect(() => {
    if (navigationFetched) {
      setFacets(navigationItem.virtualParams.facets);
    }
  }, [navigationFetched]);
};

export default useRankingEffect;
