import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  SET_NAVIGATION_VIRTUAL_FACET_ID_TO_DELETE,
} from '../actions/types';

const initialState = {};
const navigationVirtualFacetRestTypes = restTypes('navigation_virtual_facet');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case navigationVirtualFacetRestTypes.createStart:
      return {
        ...state,
        ...restStates.createStart,
      };
    case navigationVirtualFacetRestTypes.createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        ...action.payload,
      };
    case navigationVirtualFacetRestTypes.fetchStart:
      return {
        ...state,
        ...restStates.fetchStart,
      };
    case navigationVirtualFacetRestTypes.fetchFinished:
      return {
        ...state,
        ...restStates.fetchFinished,
        ...action.payload,
      };
    case navigationVirtualFacetRestTypes.deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
      };
    case navigationVirtualFacetRestTypes.deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };
    case navigationVirtualFacetRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case navigationVirtualFacetRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case navigationVirtualFacetRestTypes.updateStart:
      return {
        ...state,
        ...restStates.updateStart,
      };
    case navigationVirtualFacetRestTypes.updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };
    case navigationVirtualFacetRestTypes.clearStateFinished:
      return {
        ...initialState,
        ...restInitState,
        ...action.payload,
      };
    case SET_NAVIGATION_VIRTUAL_FACET_ID_TO_DELETE:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
};
