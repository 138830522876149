/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useState, useContext
} from 'react';
import { func, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../../components/Layout';
import List from '../../components/List';
import SidebarContent from '../Pim/pages/Home/components/SidebarContent';
import { setFilterTarget } from '../../actions/pim';
import MapperTable from './components/MapperTable';
import { getMappingData, getMappingCategories } from '../../actions/mapper';
import { getProductPage } from "../../actions/product";
import { headerList } from '../utils/headerList';
import { AbilityContext } from '../../components/AbilityContext';
import { fetchAttributes } from '../../actions/system';
import { sortFields } from '../../util/fields';
import { emptySearchState } from '../Pim/utils/sidebar';

export const Mapper = (props) => {
  const abilityContext = useContext(AbilityContext);
  const [firstLoad, setFirstLoad] = useState(false);
  const [mapperReset, setMapperReset] = useState([]);
  const [mapperData, setMapperData] = useState([]);
  const [mapperCategories, setMapperCategories] = useState([]);
  const [mapperScroll, setMapperScroll] = useState([]);
  const [mapperCondition, setMapperCondition] = useState({});
  const [mapperItemsPerPage, setMapperItemsPerPage] = useState(10);
  const [mapperMarketplace, setMapperMarketplace] = useState('');
  const mapperMarketplaces = ['zap.co.il', 'ksp.co.il'];

  useEffect(() => {
    props.fetchAttributes();
    props.setFilterTarget('mapper');
    if (props.mapperPim.pim.mapper.data) {
      setMapperData(props.mapperPim.pim.mapper.data);
    }
    if (props.mapperPim.pim.mapper.categories) {
      setMapperCategories(props.mapperPim.pim.mapper.categories);
    }
    if (props.mapperPim.pim.mapper.scroll) {
      setMapperScroll(props.mapperPim.pim.mapper.scroll);
    }
    if (props.mapperPim.pim.mapper.condition) {
      setMapperCondition(props.mapperPim.pim.mapper.condition);
    }
    setMapperReset(false);
    if (props.mapperPim.pim.mapper.reset === true) {
      setMapperReset(true);
    }
    if (props.mapperPim.pim.mapper.itemsPerPage) {
      setMapperItemsPerPage(props.mapperPim.pim.mapper.itemsPerPage);
    }
  }, [
    props.mapperPim.pim.mapper, props.setFilterTarget
  ]);

  const getPreparedMapperValues = (mapperFields) => {
    const mapperFieldsKeys = Object.keys(mapperFields);

    const resMapperData = mapperData;
    for (let i = 0; i < mapperFieldsKeys.length; i++) {
      if (mapperFields[mapperFieldsKeys[i]].specialRule === undefined) {
        continue;
      }

      const target = mapperFields[mapperFieldsKeys[i]].specialRule.target;
      const targetValue = mapperFields[mapperFieldsKeys[i]].specialRule.value;
      if (!target || !targetValue) {
        continue;
      }

      for (let j = 0; j < resMapperData.length; j++) {
        if (resMapperData[j][target] || !resMapperData[j][mapperFieldsKeys[i]]) {
          continue;
        }

        resMapperData[j][target] = targetValue.replace('[value]', resMapperData[j][mapperFieldsKeys[i]]);
      }
    }
    
    return {
      sortedMapFields: sortFields(mapperFields, 'order'),
      sortedSearchMapFields: sortFields(mapperFields, 'searchableOrder'),
      sortedCopyMapFields: sortFields(mapperFields, 'copyOrder'),
      resMapperData: resMapperData,
    };
  };

  if (firstLoad === false) {
    let marketplace = window.location.search.replace('?marketplace=', '');
    if (mapperMarketplaces.indexOf(marketplace) === -1) {
      props.history.push('/mapper?marketplace=' + mapperMarketplaces[0]);
      marketplace = mapperMarketplaces[0];
    } else {
      sessionStorage.setItem('pim.selectedMapperCategories', null);
      sessionStorage.setItem('pim.searchFilters', JSON.stringify(emptySearchState));
      sessionStorage.setItem('pim.searchFields', JSON.stringify(emptySearchState));
    }
    setMapperMarketplace(marketplace);
    const storedSelectedFilter = sessionStorage.getItem('pim.selectedFilter');
    const storedSearchFilters = sessionStorage.getItem('pim.searchFilters');
    const storedSearchFields = sessionStorage.getItem('pim.searchFields');
    const parsedStoredSelectedFilter = JSON.parse(storedSelectedFilter) || { mapper: [] };
    const parsedStoredSearchFilters = JSON.parse(storedSearchFilters) || { mapper: [] };
    const parsedStoredSearchFields = JSON.parse(storedSearchFields) || { mapper: [] };
    if (parsedStoredSearchFilters.mapper.length === 0
      && parsedStoredSearchFields.mapper.length === 0
      && parsedStoredSelectedFilter.mapper.length === 0
    ) {
      const selectedCategories = [];
      const selectedCategoriesString = sessionStorage.getItem('pim.selectedMapperCategories');
      if (selectedCategoriesString) {
        const selectedCats = selectedCategoriesString.split(',');
        for (let i = 0; i < selectedCats.length; i++) {
          selectedCategories[selectedCats[i]] = true;
        }
      }
      setTimeout(function(){
        props.getMappingData({limit: mapperItemsPerPage, offset: 0, marketplace: marketplace, categories: selectedCategories});
      }, 1000);
    }

    props.getMappingCategories(marketplace);
    setFirstLoad(true);
  }

  const mapper = (sortedMapFields, sortedSearchMapFields, sortedCopyMapFields, resMapperData) => {
    return (
      <Layout
        horizontalMenu
        header={{
          className: 'header-mer',
          subHeaderContent: (
            <List
              dir="horizontal"
              className="justify-center"
              dataSource={headerList({ abilityContext })}
            />
          ),
        }}
        sidebar={{
          className: `sidebar-mer sidebar-ranking-mer sidebar-scroll-secondary`,
          content: <SidebarContent
            mapperCategories={mapperCategories}
            mapperMarketplace={mapperMarketplace}
            sortedFields={sortedMapFields}
            sortedSearchFields={sortedSearchMapFields}
            condition={mapperCondition}
            scroll={mapperScroll}
            getMappingData={props.getMappingData}
            reset={mapperReset}
            itemsPerPage={mapperItemsPerPage}
          />,
        }}
        main={{
          className: 'main-mer main-ranking-mer',
          content: (
            <MapperTable
              getMappingData={props.getMappingData}
              mapperFields={sortedMapFields}
              copyMapFields={sortedCopyMapFields}
              mapperData={resMapperData}
              mapperScroll={mapperScroll}
              mapperCondition={mapperCondition}
              mapperError={props.mapperPim.pim.mapper.error ? props.mapperPim.pim.mapper.error : []}
              mapperNewRecordOpen={!!props.mapperPim.pim.mapper.newRecordOpen}
              mapperItemsPerPage={mapperItemsPerPage}
              mapperMarketplace={mapperMarketplace}
              mapperMarketplaces={mapperMarketplaces}
              getProductPage={getProductPage}
            />
          )
        }}
      />
    );
  };

  if (mapperMarketplace.indexOf('zap') !== -1 && props.mapperFields.zap) {
    const preparedMapperValues = getPreparedMapperValues(props.mapperFields.zap);
    return mapper(
      preparedMapperValues.sortedMapFields,
      preparedMapperValues.sortedSearchMapFields,
      preparedMapperValues.sortedCopyMapFields,
      preparedMapperValues.resMapperData
    );
  }
  if (mapperMarketplace.indexOf('ksp') !== -1 && props.mapperFields.ksp) {
    const preparedMapperValues = getPreparedMapperValues(props.mapperFields.ksp);
    return mapper(
      preparedMapperValues.sortedMapFields,
      preparedMapperValues.sortedSearchMapFields,
      preparedMapperValues.sortedCopyMapFields,
      preparedMapperValues.resMapperData
    );
  }

  return (<></>);
};

Mapper.propTypes = {
  setFilterTarget: func.isRequired,
  getMappingData: func.isRequired,
  getMappingCategories: func.isRequired,
  getProductPage: func.isRequired,
  mapperPim: shape().isRequired,
  fetchAttributes: func.isRequired,
  mapperFields: shape(),
};

Mapper.defaultProps = {
  mapperFields: {},
};

const mapStateToProps = state => ({
  mapperFields: state.system.modes.mapping,
  mapperPim: state,
});

const mapDispatchToProps = {
  setFilterTarget,
  getMappingData,
  getMappingCategories,
  getProductPage,
  fetchAttributes
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Mapper));
