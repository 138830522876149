/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useContext } from 'react';
import { connect } from 'react-redux';
import {
  func, arrayOf, shape, bool, string,
} from 'prop-types';
import { isEmpty, uniqBy, uniq } from 'lodash';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import swal from '@sweetalert/with-react';

import {
  setProductsNotInIds,
  setProductsInIds,
  setSelectedProductList,
  updateLoadedProductsIn,
  updateLoadedProductsNin,
  fetchProductList,
  fetchProductsByIds,
  completeProductList,
  setProductCategoriesAttributes,
  setAttributesMatrixAttributes,
  setFilters,
  setProductFilter,
  setCompoundSearch,
  setLeftSidePagination,
  setIsSwitchOn,
  setIsProductDialogApplyFilterActive,
  setMappedCategories,
  setSelectedCategories,
  setPhysicalCategoriesIds,
  setSelectedTreeItems,
  setShowCategoriesDialog,
  clearNavigationFilterState,
} from 'actions/navigation';

import {
  clearSearchListState,
  fetchChildCategories,
  searchCategories as searchScopeCategories,
  onToggleScopeCategoryTreeCollapse,
  handleLoadScopeSubcategories,
  fetchScopeCategoriesByIds,
  clearFromScopeCategoryState,
  fetchRootCategories as fetchRootScopeCategories,
} from 'actions/scopeCategory';

import {
  fetchAttributeOptions,
  fetchAttributesOptions,
  fetchCategoriesAttributes,
} from 'actions/productAttribute';

import { fetchCategoriesByIds as fetchPhysicalCategoriesByIds } from 'actions/physicalCategory';
import { fetchList as fetchRankingList } from 'actions/ranking';

import FilterBy from 'components/FilterBy';
import ProductsPagination from 'components/ProductsPagination';
import { AbilityContext } from 'components/AbilityContext';

import appPermissions from 'util/appPermissions';
import { getFilterableAttributes, getUniqueAttributes } from 'util/attributesMappers';
import { getFilterMatrixAttributes } from 'util/attributesMatrixMapping';
import getFilterWithoutProductFilter from 'util/getFilterWithoutProductFilter';
import { mapFrontEndPrefixToFilters } from 'util/filterMapper';
import getMappedProductList from 'util/getMappedProductList';
import getSplitedFilterByQuery from 'util/getSplitedFilterByQuery';
import { defaultPagination } from 'util/defaultData';
import IntlMessages from 'util/IntlMessages';

import useAttributesMatrixEffects from './hooks/useAttributesMatrix';
import useRankingEffects from './hooks/useRanking';
import useProductAttributeEffects from './hooks/useProductAttribute';
import useProductEffects from './hooks/useProduct';
import useFilterEffects from './hooks/useFilter';
import useVirtualCategoryEffects from './hooks/useVirtualCategory';
import useScopeCategoryEffects from './hooks/useScopeCategory';

import SelectCategories from './SelectCategories';
import {
  defaultProductAttribute,
} from '../../../utils/defaultData';
import mapProductList from '../../../utils/mappedProductList';


const Filter = memo((props) => {
  useAttributesMatrixEffects({
    attributesOptions: props.attributesOptions,
    attributesMatrixFetched: props.attributesMatrixFetched,
    attributesMatrixList: props.attributesMatrixList,
    setAttributesMatrixAttributes: props.setAttributesMatrixAttributes,
    navigationFetched: props.navigationFetched,
    fetchedAttributeOptions: props.fetchedAttributeOptions,
    fetchedAttributesOptions: props.fetchedAttributesOptions,
    attributesMatrixAttributes: props.attributesMatrixAttributes,
  });
  useRankingEffects({
    rankingFetched: props.rankingFetched,
    fetchRankingList: props.fetchRankingList,
  });
  useFilterEffects({
    navigationItem: props.navigationItem,
    navigationFetched: props.navigationFetched,
    setFilters: props.setFilters,
    updateLoadedProductsIn: props.updateLoadedProductsIn,
    updateLoadedProductsNin: props.updateLoadedProductsNin,
    setProductsInIds: props.setProductsInIds,
    setProductsNotInIds: props.setProductsNotInIds,
    setSelectedTreeItems: props.setSelectedTreeItems,
    setSelectedCategories: props.setSelectedCategories,
    setMappedCategories: props.setMappedCategories,
    setIsSwitchOn: props.setIsSwitchOn,
  });
  useProductAttributeEffects({
    attributesOptions: props.attributesOptions,
    categoriesAttributesFetched: props.categoriesAttributesFetched,
    fetchedAttributeOptions: props.fetchedAttributeOptions,
    fetchedAttributesOptions: props.fetchedAttributesOptions,
    categoriesAttributes: props.categoriesAttributes,
    setProductCategoriesAttributes: props.setProductCategoriesAttributes,
    productCategoriesAttributes: props.productCategoriesAttributes,
    navigationItem: props.navigationItem,
    fetchAttributesOptions: props.fetchAttributesOptions,
    filters: props.filters,
    navigationFetched: props.navigationFetched,
    fetchCategoriesAttributes: props.fetchCategoriesAttributes,
    fetchPhysicalCategoriesByIds: props.fetchPhysicalCategoriesByIds,
  });
  useProductEffects({
    setSelectedProductList: props.setSelectedProductList,
    productsByIdsForNin: props.filterProduct.productsByIdsForNin,
    productsByIdsForIn: props.filterProduct.productsByIdsForIn,
    fetchProductsByIdsFinished: props.filterProduct.fetchProductsByIdsFinished,
  });
  useVirtualCategoryEffects({
    physicalCategoriesIds: props.filterCategory.physicalCategoriesIds,
    categoriesAttributesFetching: props.categoriesAttributesFetching,
    fetchCategoriesAttributes: props.fetchCategoriesAttributes,
  });
  useScopeCategoryEffects({
    scopeCategoriesFetched: props.scopeCategoriesFetched,
    fetchRootScopeCategories: props.fetchRootScopeCategories,
    scopeSearchFilterResults: props.scopeSearchFilterResults,
    scopeSearchFilterFinished: props.scopeSearchFilterFinished,
    setPhysicalCategoriesIds: props.setPhysicalCategoriesIds,
    fetchCategoriesAttributes: props.fetchCategoriesAttributes,
  });

  const {
    productCategoriesAttributes,
    attributesMatrixAttributes,
    filterProduct,
    filtersErrors,
    isSwitchOn,
    productFilter,
    filterCategory,
    fetchingParentCategoriesByIds,
    searched,
    searchResults,
    collapse,
    loadedKeys,
    treeItems,
    fetchChildrenStarted,
    scopeCategoriesByIds,
    fetchedScopeCategoriesByIds,
  } = props;

  const abilityContext = useContext(AbilityContext);

  const noPermissions = !abilityContext.can(
    appPermissions.category.permissions.update,
    appPermissions.category.name,
  );

  const filtersLoading = (
    props.categoriesAttributesFetching
    || props.fetchingAttributeOptions
    || props.fetchingAttributesOptions
  );

  const searchProductsWithAppliedFilters = (isApplyFilterChecked) => {
    const newFilters = getFilterWithoutProductFilter(props.filters);
    const filtersWithPrefix = mapFrontEndPrefixToFilters(newFilters);

    const productSearchFilter = isApplyFilterChecked
      ? [...filtersWithPrefix, ...productFilter]
      : [...productFilter];

    props.setLeftSidePagination(defaultPagination);
    props.fetchProductList({
      viewMode: 'frontendWithParams',
      compoundSearch: filterProduct.compoundSearch,
      pagination: defaultPagination,
      searchFilter: {},
      filter: productSearchFilter,
    });
  };

  const handleProductDialogApplyFilterChange = (e) => {
    const { checked } = e.target;

    props.setIsProductDialogApplyFilterActive(checked);
    searchProductsWithAppliedFilters(checked);
  };

  const getSearchSuffix = () => (
    <FormControlLabel
      className="label-clear checkbox-sm"
      control={(
        <Checkbox
          checked={filterProduct.isProductDialogApplyFilterActive}
          onChange={handleProductDialogApplyFilterChange}
          color="primary"
        />
      )}
      label={<IntlMessages id="previewCategory.selectProductsDialog.search.checkbox.applyFilter" />}
    />
  );

  const handleFiltersChange = (data) => {
    const { productsByIdsForIn, productsByIdsForNin } = filterProduct;
    const {
      filters: newFilters, filter, operator, isProduct, removedFilter,
    } = data;
    const operatorSet = new Set(newFilters.map(f => f.group.map(g => g.operator)).flat());
    const productFilterRemoved = removedFilter && removedFilter.group.find(g => g.field === 'id');

    if (productFilterRemoved && productFilterRemoved.operator === 'nin') {
      props.setProductsNotInIds([]);
      props.updateLoadedProductsNin([]);
      props.setSelectedProductList({
        nin: [],
        in: [...mapProductList(productsByIdsForNin)],
      });
    }

    if (productFilterRemoved && productFilterRemoved.operator === 'in') {
      props.setProductsInIds([]);
      props.updateLoadedProductsIn([]);
      props.setSelectedProductList({
        nin: [...mapProductList(productsByIdsForIn)],
        in: [],
      });
    }

    props.setFilters(newFilters);

    if (
      isProduct
      && (isEmpty(productsByIdsForIn) || isEmpty(productsByIdsForNin))
      && !(operatorSet.has('in') && operatorSet.has('nin'))
    ) {
      const productIds = filter.options.flat();
      if (operator === 'nin') {
        props.setProductsNotInIds(productIds);
        props.setProductsInIds([]);
      }
      if (operator === 'in') {
        props.setProductsInIds(productIds);
        props.setProductsNotInIds([]);
      }

      props.setSelectedProductList({
        nin: [...mapProductList(productsByIdsForIn)],
        in: [...mapProductList(productsByIdsForNin)],
      });
      props.updateLoadedProductsIn(productsByIdsForNin);
      props.updateLoadedProductsNin(productsByIdsForIn);
    }
  };

  const handleSelectProductsDialogOpen = () => {
    let newProductFilter = [];
    let productFilters = [];

    if (filterProduct.isProductDialogApplyFilterActive) {
      productFilters = mapFrontEndPrefixToFilters(getFilterWithoutProductFilter(props.filters));
    }

    if (!isEmpty(filterCategory.mappedCategories) && isSwitchOn) {
      newProductFilter = [{
        group: [{
          field: 'categories',
          value: filterCategory.mappedCategories,
          operator: 'in',
        }],
      }];
    }

    props.setProductFilter(newProductFilter);
    props.fetchProductList({
      viewMode: 'frontendWithParams',
      compoundSearch: filterProduct.compoundSearch,
      pagination: filterProduct.leftSidePagination,
      filter: [...newProductFilter.flat(), ...productFilters].flat(),
    });
  };

  const handleSelectProductsDialogClose = () => {
    const updatedFilters = props.filters.map(f => ({
      ...f,
      group: f.group.map((g) => {
        const isProductFilter = g.field === 'id';
        if (isProductFilter) {
          return {
            ...g,
            value: !isEmpty(filterProduct.selectedProductList[g.operator])
              ? filterProduct.selectedProductList[g.operator].map(p => p.id)
              : [],
          };
        }
        return g;
      }),
    }));

    props.setCompoundSearch({ value: '' });
    props.setFilters(updatedFilters);
  };

  const handleSearchSubmit = (query) => {
    const splitedFilter = getSplitedFilterByQuery(query);
    const compoundSearch = { value: splitedFilter.queryString.join(', ') };

    props.setCompoundSearch(compoundSearch);
    props.setLeftSidePagination(defaultPagination);

    const productsGroupFilter = !isEmpty(splitedFilter.productIds)
      ? [{
        group: [{
          field: 'id',
          value: uniq(splitedFilter.productIds),
          operator: 'in',
        }],
      }]
      : [];

    const newFilters = getFilterWithoutProductFilter(props.filters);
    const filtersWithPrefix = mapFrontEndPrefixToFilters(newFilters);

    const productSearchFilter = filterProduct.isProductDialogApplyFilterActive
      ? [...filtersWithPrefix, ...productFilter]
      : [...productFilter];

    props.fetchProductList({
      viewMode: 'frontendWithParams',
      compoundSearch,
      pagination: defaultPagination,
      searchFilter: {},
      filter: [...productSearchFilter, ...productsGroupFilter].flat(),
    });
  };

  const handleSelectedListChange = (selectedProductList, key) => {
    props.setSelectedProductList({
      ...filterProduct.selectedProductList,
      [key]: selectedProductList,
    });

    if (key === 'in') {
      props.setProductsInIds(selectedProductList.map(p => p.id));
      props.updateLoadedProductsIn(selectedProductList);
    } else {
      props.setProductsNotInIds(selectedProductList.map(p => p.id));
      props.updateLoadedProductsNin(selectedProductList);
    }
  };

  const handleDialogSelectClick = () => {
    const { selectedProductList } = filterProduct;
    if (!isEmpty(selectedProductList.in) || !isEmpty(selectedProductList.nin)) {
      const newFilters = [...props.filters.map((f) => {
        if (f.group && !isEmpty(f.group)) {
          return ({
            ...f,
            condition: f.group && f.group.length > 1 ? 'or' : 'and',
            group: f.group.map((g) => {
              if (g.field === 'id') {
                return ({
                  ...g,
                  value: uniq(selectedProductList[g.operator].map(p => p.id)),
                });
              } return g;
            }),
          });
        } return f;
      })];
      props.setFilters([...newFilters]);
    }
  };

  const onLeftSidePaginate = ({ selected }) => {
    const updatedPagination = {
      ...filterProduct.leftSidePagination,
      page: selected + 1,
    };

    const newFilters = getFilterWithoutProductFilter(props.filters);
    const filtersWithPrefix = mapFrontEndPrefixToFilters(newFilters);

    const productSearchFilter = filterProduct.isProductDialogApplyFilterActive
      ? [...filtersWithPrefix, ...productFilter]
      : [...productFilter];

    props.setLeftSidePagination(updatedPagination);
    props.fetchProductList({
      viewMode: 'frontendWithParams',
      compoundSearch: filterProduct.compoundSearch,
      filter: productSearchFilter,
      pagination: updatedPagination,
      searchFilter: {},
    });
  };

  const handleShowAlert = () => {
    swal({
      title: 'Are you sure?',
      text: 'There are no any selected category, all selected filters and facets will be removed',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    }).then((accepted) => {
      if (accepted) {
        props.clearNavigationFilterState();
      } else {
        props.setSelectedTreeItems(filterCategory.selectedCategories);
      }
    });
  };

  const handleSelectCategories = (selectedvirtualCategoriesIds, physicalCategoriesIds) => {
    if (isEmpty(selectedvirtualCategoriesIds)) {
      return handleShowAlert();
    }

    if (isSwitchOn) {
      props.setMappedCategories(physicalCategoriesIds);
    } else {
      props.setMappedCategories([]);
    }

    props.setSelectedCategories(selectedvirtualCategoriesIds);
    props.setPhysicalCategoriesIds(physicalCategoriesIds);
    return false;
  };

  const handleSwitchClick = (isOn) => {
    if (isOn) {
      props.setMappedCategories(filterCategory.physicalCategoriesIds);
    } else {
      props.setMappedCategories([]);
    }
    props.setIsSwitchOn(isOn);
  };

  const {
    productsByIdsForIn,
    productsByIdsForNin,
    completedCategoryProductsList,
    fetchProductsByIdsFinished,
  } = filterProduct;

  const listIn = uniqBy([
    ...productsByIdsForIn,
  ], 'id');

  const listNin = uniqBy([
    ...productsByIdsForNin,
  ], 'id');

  const switchList = completedCategoryProductsList
    && fetchProductsByIdsFinished;

  const mappedListIn = switchList ? getMappedProductList(listIn) : mapProductList(listIn);
  const mappedListNin = switchList ? getMappedProductList(listNin) : mapProductList(listNin);

  const attributesList = uniqBy([
    defaultProductAttribute,
    ...getUniqueAttributes(
      getFilterableAttributes(productCategoriesAttributes),
      getFilterMatrixAttributes(attributesMatrixAttributes),
    ),
    ...getFilterMatrixAttributes(attributesMatrixAttributes),
  ], 'code');

  return (
    <FormGroup className="preview-drawer-filter">
      <FilterBy
        key="filter"
        filterContentClass="direction-column"
        disabled={noPermissions || filtersLoading}
        hasPermissions={!noPermissions}
        loading={filtersLoading}
        productAttributes={attributesList}
        fetchAttributeOptions={props.fetchAttributeOptions}
        fetchAttributesOptions={props.fetchAttributesOptions}
        filters={props.filters}
        onChange={handleFiltersChange}
        errors={filtersErrors}
        showAddFilterButton
        selectCategoriesButton={(
          <SelectCategories
            selectedTreeItems={filterCategory.selectedTreeItems}
            showCategoriesDialog={filterCategory.showCategoriesDialog}
            setSelectedTreeItems={props.setSelectedTreeItems}
            setShowCategoriesDialog={props.setShowCategoriesDialog}
            data={{
              scopeCategory: {
                fetchingParentCategoriesByIds,
                searched,
                searchResults,
                collapse,
                loadedKeys,
                treeItems,
                fetchChildrenStarted,
              },
            }}
            clearSearchListState={props.clearSearchListState}
            fetchChildCategories={props.fetchChildCategories}
            searchScopeCategories={props.searchScopeCategories}
            onToggleScopeCategoryTreeCollapse={props.onToggleScopeCategoryTreeCollapse}
            handleLoadScopeSubcategories={props.handleLoadScopeSubcategories}
            fetchScopeCategoriesByIds={props.fetchScopeCategoriesByIds}
            scopeCategoriesByIds={scopeCategoriesByIds}
            fetchedScopeCategoriesByIds={fetchedScopeCategoriesByIds}
            clearFromScopeCategoryState={props.clearFromScopeCategoryState}
            onSubmit={handleSelectCategories}
            errorMessage="text.errors.lessThan500Characters"
            queryLengthLimit={500}
            disabled={noPermissions || filtersLoading}
          />
        )}
        selectProductsSettings={{
          onOpen: handleSelectProductsDialogOpen,
          onClose: handleSelectProductsDialogClose,
          sourceList: mapProductList(filterProduct.list) || [],
          listIn: mappedListIn || [],
          listNin: mappedListNin || [],
          listInIds: filterProduct.productsInIds || [],
          listNinIds: filterProduct.productsNotInIds || [],
          completingCategoryProductsList: filterProduct.completingCategoryProductsList,
          fetchProductsByIdsStarted: filterProduct.fetchProductsByIdsStarted,
          fetchProductsByIds: props.fetchProductsByIds,
          onSearchSubmit: handleSearchSubmit,
          onSelectedListChange: handleSelectedListChange,
          onDialogSelectClick: handleDialogSelectClick,
          completeProductList: props.completeProductList,
          loading: {
            leftList: filterProduct.categoryFetchProductListStart,
            rightList: filterProduct.fetchProductsByIdsStarted,
          },
          leftPagination: (
            <ProductsPagination
              containerClassName="pagination-sm pagination-abs"
              onPaginate={onLeftSidePaginate}
              product={filterProduct}
              pagination={filterProduct.leftSidePagination}
            />
          ),
          search: {
            suffix: getSearchSuffix(),
          },
        }}
        onSwitchClick={handleSwitchClick}
        isSwitchOn={isSwitchOn}
      />
    </FormGroup>
  );
});

Filter.propTypes = {
  categoriesAttributesFetching: bool.isRequired,
  fetchingAttributeOptions: bool.isRequired,
  fetchingAttributesOptions: bool.isRequired,
  isSwitchOn: bool.isRequired,

  setProductCategoriesAttributes: func.isRequired,
  fetchAttributeOptions: func.isRequired,
  fetchAttributesOptions: func.isRequired,
  setFilters: func.isRequired,
  setProductsNotInIds: func.isRequired,
  setProductsInIds: func.isRequired,
  setSelectedProductList: func.isRequired,
  updateLoadedProductsIn: func.isRequired,
  updateLoadedProductsNin: func.isRequired,
  setProductFilter: func.isRequired,
  fetchProductList: func.isRequired,
  setCompoundSearch: func.isRequired,
  fetchProductsByIds: func.isRequired,
  completeProductList: func.isRequired,
  setLeftSidePagination: func.isRequired,
  setIsSwitchOn: func.isRequired,
  setIsProductDialogApplyFilterActive: func.isRequired,
  fetchCategoriesAttributes: func.isRequired,
  setMappedCategories: func.isRequired,
  setSelectedCategories: func.isRequired,
  setPhysicalCategoriesIds: func.isRequired,
  fetchRankingList: func.isRequired,
  fetchPhysicalCategoriesByIds: func.isRequired,
  setAttributesMatrixAttributes: func.isRequired,
  setSelectedTreeItems: func.isRequired,
  setShowCategoriesDialog: func.isRequired,

  clearSearchListState: func.isRequired,
  fetchChildCategories: func.isRequired,
  searchScopeCategories: func.isRequired,
  onToggleScopeCategoryTreeCollapse: func.isRequired,
  handleLoadScopeSubcategories: func.isRequired,
  fetchScopeCategoriesByIds: func.isRequired,
  clearFromScopeCategoryState: func.isRequired,
  fetchRootScopeCategories: func.isRequired,
  clearNavigationFilterState: func.isRequired,

  productCategoriesAttributes: arrayOf(shape()).isRequired,
  attributesMatrixAttributes: arrayOf(shape()).isRequired,
  filters: arrayOf(shape()).isRequired,
  productFilter: arrayOf(shape()).isRequired,
  filterProduct: shape().isRequired,
  filtersErrors: arrayOf(shape()).isRequired,
  filterCategory: shape().isRequired,
  navigationItem: shape().isRequired,

  attributesOptions: arrayOf(shape()).isRequired,
  attributesMatrixList: arrayOf(shape()).isRequired,
  categoriesAttributes: arrayOf(shape()).isRequired,
  searchResults: arrayOf(shape()).isRequired,
  treeItems: arrayOf(shape()).isRequired,
  scopeCategoriesByIds: arrayOf(shape()).isRequired,
  scopeSearchFilterResults: arrayOf(shape()).isRequired,
  collapse: arrayOf(string).isRequired,
  loadedKeys: arrayOf(string).isRequired,

  attributesMatrixFetched: bool.isRequired,
  categoriesAttributesFetched: bool.isRequired,
  navigationFetched: bool.isRequired,
  fetchedAttributeOptions: bool.isRequired,
  fetchedAttributesOptions: bool.isRequired,
  rankingFetched: bool.isRequired,
  fetchingParentCategoriesByIds: bool.isRequired,
  searched: bool.isRequired,
  fetchChildrenStarted: bool.isRequired,
  fetchedScopeCategoriesByIds: bool.isRequired,
  scopeCategoriesFetched: bool.isRequired,
  scopeSearchFilterFinished: bool.isRequired,
};

Filter.defaultProps = {};

const mapStateToProps = state => ({
  navigationFetching: state.navigation.fetchingOne,
  navigationFetched: state.navigation.fetchedOne,
  navigationItem: state.navigation.item,
  productCategoriesAttributes: state.navigation.filter.productCategoriesAttributes,
  attributesMatrixAttributes: state.navigation.filter.attributesMatrixAttributes,
  filters: state.navigation.filter.filters,
  filtersErrors: state.navigation.filter.filtersErrors,
  isSwitchOn: state.navigation.filter.isSwitchOn,
  productFilter: state.navigation.filter.productFilter,
  filterProduct: state.navigation.filter.product,
  filterCategory: state.navigation.filter.category,

  categoriesAttributesFetching: state.productAttribute.categoriesAttributesFetching,
  categoriesAttributesFetched: state.productAttribute.categoriesAttributesFetched,
  categoriesAttributes: state.productAttribute.categoriesAttributes,
  fetchingAttributeOptions: state.productAttribute.fetchingAttributeOptions,
  fetchingAttributesOptions: state.productAttribute.fetchingAttributesOptions,
  fetchedAttributeOptions: state.productAttribute.fetchedAttributeOptions,
  fetchedAttributesOptions: state.productAttribute.fetchedAttributesOptions,
  attributesOptions: state.productAttribute.attributesOptions,

  fetchingParentCategoriesByIds: state.scopeCategory.fetchingParentCategoriesByIds,
  searched: state.scopeCategory.searched,
  searchResults: state.scopeCategory.searchResults,
  collapse: state.scopeCategory.collapse,
  loadedKeys: state.scopeCategory.loadedKeys,
  treeItems: state.scopeCategory.treeItems,
  fetchChildrenStarted: state.scopeCategory.fetchChildrenStarted,
  scopeCategoriesByIds: state.scopeCategory.categoriesByIds,
  fetchedScopeCategoriesByIds: state.scopeCategory.fetchedScopeCategoriesByIds,
  scopeCategoriesFetched: state.scopeCategory.fetched,
  scopeSearchFilterResults: state.scopeCategory.searchFilterResults,
  scopeSearchFilterFinished: state.scopeCategory.searchFilterFinished,

  attributesMatrixFetched: state.attributesMatrix.fetched,
  attributesMatrixList: state.attributesMatrix.list,

  rankingFetched: state.ranking.fetchedOne,
});

const mapDispatchToProps = {
  setProductCategoriesAttributes,
  setAttributesMatrixAttributes,
  fetchAttributeOptions,
  fetchAttributesOptions,
  setFilters,
  setProductsNotInIds,
  setProductsInIds,
  setSelectedProductList,
  updateLoadedProductsIn,
  updateLoadedProductsNin,
  setProductFilter,
  fetchProductList,
  setCompoundSearch,
  fetchProductsByIds,
  completeProductList,
  setLeftSidePagination,
  setIsSwitchOn,
  setIsProductDialogApplyFilterActive,
  fetchCategoriesAttributes,
  setMappedCategories,
  setSelectedCategories,
  setPhysicalCategoriesIds,
  fetchRankingList,
  fetchPhysicalCategoriesByIds,
  setSelectedTreeItems,
  setShowCategoriesDialog,
  clearSearchListState,
  fetchChildCategories,
  searchScopeCategories,
  onToggleScopeCategoryTreeCollapse,
  handleLoadScopeSubcategories,
  fetchScopeCategoriesByIds,
  clearFromScopeCategoryState,
  fetchRootScopeCategories,
  clearNavigationFilterState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
