import React from 'react';
import {
  func, oneOfType, node, string, bool,
} from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Subheader from '../../../../components/Subheader';
import Search from '../../../../components/Search';
import IntlMessages from '../../../../util/IntlMessages';
import Can from '../../../../components/Can';
import appPermissions from '../../../../util/appPermissions';

const UsersSubheader = props => (
  <div className="content-subheader">
    <Subheader
      wrapperClassName="flex items-center justify-between mt-15-rev"
      title={(
        <div className="flex items-center">
          <IntlMessages id="text.availabelUsers" />
          <Can
            do={appPermissions.users.permissions.create}
            on={appPermissions.users.name}
          >
            <Button
              className="btn-link btn-xs text-normal ml-10"
              variant="outlined"
              color="primary"
              onClick={props.onAddUserClick}
            >
              <IntlMessages id="text.addAUser" />
            </Button>
            <Button
              className="btn-link btn-xs text-normal ml-10"
              variant="outlined"
              color="primary"
              onClick={props.onSyncClick}
              disabled={props.usersSyncing}
            >
              <IntlMessages id="pages.system.button.text.syncUsers" />
            </Button>
            {props.usersSyncing && (
              <CircularProgress
                variant="indeterminate"
                disableShrink
                className="progress-warning custom-loader users-sync bottom ml-15"
                size={20}
                thickness={4}
              />
            )}
          </Can>
          {props.content}
        </div>
      )}
      content={(
        <Search
          onChange={props.onSearch}
          errorMessage="text.errors.lessThan200Characters"
          queryLengthLimit={170}
          value={props.searchValue || ''}
          className="search-system"
          placeholder="Search users..."
        />
      )}
    />
  </div>
);

UsersSubheader.propTypes = {
  onSearch: func,
  onAddUserClick: func,
  content: oneOfType([node, string]),
  searchValue: string,
  onSyncClick: func.isRequired,
  usersSyncing: bool,
};

UsersSubheader.defaultProps = {
  onSearch: null,
  onAddUserClick: null,
  content: null,
  searchValue: '',
  usersSyncing: false,
};

export default UsersSubheader;
