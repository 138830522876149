import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import { CLEAR_SERVICE_CONFIG_STATE } from '../actions/types';

const initialState = {
  item: {},
};
const serviceConfigRestTypes = restTypes('service_config');
const {
  fetchOneStart,
  fetchOneFinished,
} = serviceConfigRestTypes;

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };
    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case CLEAR_SERVICE_CONFIG_STATE:
      return {
        ...initialState,
        ...restInitState,
      };

    default: return state;
  }
};
