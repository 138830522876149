/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import axios from 'axios';

import {
  oneOfType, arrayOf, node,
  func, shape,
} from 'prop-types';
import setAuthToken from '../../util/setAuthToken';
import { auth } from '../../firebase';
import { fetchSession } from '../../actions/session';
import { fetchServiceConfig } from '../../actions/serviceConfig';
import { fetchCommentMessages } from '../../actions/commentMessage';
import { fetchAppSettings } from '../../actions/appSettings';

import { PermissionsContext } from '../PermissionsContext';
import ability from '../../util/ability';
import {
  signinAppPath,
  forgotPasswordAppPath,
  changePasswordAppPath,
} from '../../util/paths';

import AuthContainer from '../../pages/Auth';

const Auth = (props) => {
  const [loading, setLoading] = useState(true);
  const [subscribed, setSubscribed] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [permissions, setPermissions] = useContext(PermissionsContext);
  const [showContent, setShowContent] = useState(false);

  const setRequestInterceptors = (userId) => {
    axios.interceptors.request.use(
      (axiosConfig) => {
        const pureConfig = Object.assign({}, axiosConfig);
        const url = new URL(pureConfig.url);
        const params = queryString.parse(url.search);

        const newUrlWithParams = isEmpty(params)
          ? `${url.href}?ref=${userId}`
          : `${url.href}&ref=${userId}`;

        if (url.href.includes('products') && url.href.includes('search')) {
          return {
            ...axiosConfig,
            url: newUrlWithParams,
          };
        }
        return {
          ...axiosConfig,
        };
      },
    );
  };

  useEffect(() => {
    if (props.serviceConfig.fetchedOne) {
      setAuthToken(props.serviceConfig.item.token);
      setShowContent(true);
    }
  }, [loading, props.serviceConfig.fetchedOne]);

  useEffect(() => {
    const { fetchedOne, fetchingOne } = props.serviceConfig;

    if (!fetchedOne && !fetchingOne && props.session.fetchedOne && authorized) {
      props.fetchServiceConfig();
      props.fetchCommentMessages();
      props.fetchAppSettings();
    }
  }, [props]);

  useEffect(() => {
    if (!subscribed) {
      const { fetchedOne } = props.session;

      auth.onAuthStateChanged((user) => {
        if (user) {
          setRequestInterceptors(user.uid);
          setAuthorized(true);
          const { ya: fbToken } = user;
          localStorage.setItem('fb_token', fbToken);
          if (!fetchedOne) {
            props.fetchSession(user.uid);
          }
        } else {
          const { pathname } = location;

          setAuthorized(false);
          setLoading(false);
          localStorage.removeItem('fb_token');

          if (pathname !== signinAppPath
            && pathname !== forgotPasswordAppPath
            && pathname !== changePasswordAppPath) {
            location.replace(signinAppPath);
          }
        }
      });
      setSubscribed(true);
    }
  }, [props]);

  useEffect(() => {
    if (props.session.fetchedOne) {
      setPermissions(props.session.item.permissions);
      const abilityRules = [];
      const { permissions: sessionPermissions } = props.session.item;
      Object.keys(sessionPermissions).forEach((p) => {
        abilityRules.push({
          subject: p,
          actions: Object.keys(sessionPermissions[p]).map(k => (sessionPermissions[p][k])),
        });
      });
      ability.update(abilityRules);
    }
  }, [props, props.session, props.session.fetchedOne]);

  return (
    (showContent && authorized && !isEmpty(permissions))
      ? props.children
      : <AuthContainer />
  );
};

Auth.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  fetchSession: func.isRequired,
  session: shape({
    item: shape({
      permissions: shape({}),
    }),
  }).isRequired,
  fetchServiceConfig: func.isRequired,
  fetchCommentMessages: func.isRequired,
  fetchAppSettings: func.isRequired,
  serviceConfig: shape().isRequired,
};

Auth.defaultProps = {};

const mapStateToProps = state => ({
  session: state.session,
  serviceConfig: state.serviceConfig,
});

const mapDispatchToProps = {
  fetchSession,
  fetchServiceConfig,
  fetchCommentMessages,
  fetchAppSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
