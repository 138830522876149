import React, { Component } from 'react';
import {
  func, arrayOf, string, shape, bool, oneOfType, node, object,
} from 'prop-types';
import FormDialog from '../FormDialog';
import IntlMessages from '../../util/IntlMessages';
import SelectCategories from './components/SelectCategories';

export default class SelectCategoryDialog extends Component {
  static propTypes = {
    dialog: shape({
      maxWidth: string,
      className: string,
      open: bool,
      closeButtonTitle: oneOfType([string, node]),
      submitButtonTitle: oneOfType([string, node]),
      onClose: func,
      onSubmit: func,
      hideCloseButton: bool,
    }).isRequired,
    content: shape({
      noResult: bool,
      onSearchSubmit: func,
      query: string,
    }).isRequired,
    transferSourceData: shape({
      sourceTitle: oneOfType([string, node]),
      targetTitle: oneOfType([string, node]),
      sourceList: arrayOf(shape({
        id: string,
      })),
      targetList: arrayOf(oneOfType([string, object])),
      onChange: func,
      selectAll: bool,
    }).isRequired,
  };

  static defaultProps = {

  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { dialog, content, transferSourceData } = this.props;
    return (
      <FormDialog
        maxWidth={dialog.maxWidth}
        className={dialog.className}
        open={dialog.open}
        closeButtonTitle={dialog.closeButtonTitle || <IntlMessages id="rankings.cancelScopeDialogButtonLabel" />}
        submitButtonTitle={dialog.submitButtonTitle || <IntlMessages id="rankings.selectScopeDialogButtonLabel" />}
        onClose={dialog.onClose}
        onSubmit={dialog.onSubmit}
        hideCloseButton={dialog.hideCloseButton}
      >
        <SelectCategories
          noResult={content.noResult}
          errorMessage={content.errorMessage}
          queryLengthLimit={content.queryLengthLimit}
          onSearchSubmit={content.onSearchSubmit}
          query={content.query}
          transferSourceData={{
            tooltip: transferSourceData.tooltip,
            sourceTitle: transferSourceData.sourceTitle || <IntlMessages id="label.sourceCategoriesLabel" />,
            targetTitle: transferSourceData.targetTitle || <IntlMessages id="label.selectedCategoriesLabel" />,
            sourceList: transferSourceData.sourceList,
            targetList: transferSourceData.targetList,
            onChange: transferSourceData.onChange,
            selectAll: transferSourceData.selectAll,
          }}
        />
      </FormDialog>
    );
  }
}
