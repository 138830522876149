/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useState, useContext
} from 'react';
import { func, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../../components/Layout';
import List from '../../components/List';
import SidebarContent from '../Pim/pages/Home/components/SidebarContent';
import { setFilterTarget } from '../../actions/pim';
import ActivityLogTable from './components/ActivityLogTable';
import { getActivityLogData } from '../../actions/pim';
import { headerList } from '../utils/headerList';
import { AbilityContext } from '../../components/AbilityContext';
import { fetchAttributes } from '../../actions/system';
import {sortFields} from "../../util/fields";

export const ActivityLog = (props) => {
  const abilityContext = useContext(AbilityContext);
  const [firstLoad, setFirstLoad] = useState(false);
  const [mapperData, setMapperData] = useState([]);
  const [mapperScroll, setMapperScroll] = useState([]);
  const [mapperReset, setMapperReset] = useState(false);
  const [mapperCondition, setMapperCondition] = useState({});
  const [mapperItemsPerPage, setMapperItemsPerPage] = useState(20);

  useEffect(() => {
    props.fetchAttributes();
    props.setFilterTarget('activityLog');
    if (props.activityLogPim.pim.activityLog.data) {
      setMapperData(props.activityLogPim.pim.activityLog.data);
    }
    if (props.activityLogPim.pim.activityLog.scroll) {
      setMapperScroll(props.activityLogPim.pim.activityLog.scroll);
    }
    setMapperReset(false);
    if (props.activityLogPim.pim.activityLog.reset === true) {
      setMapperReset(true);
    }
    if (props.activityLogPim.pim.activityLog.condition) {
      setMapperCondition(props.activityLogPim.pim.activityLog.condition);
    }
    if (props.activityLogPim.pim.activityLog.itemsPerPage) {
      setMapperItemsPerPage(props.activityLogPim.pim.activityLog.itemsPerPage);
    }
  }, [
    props.activityLogPim.pim.activityLog, props.setFilterTarget
  ]);

  const activityLogFields = props.activityLogFields;
  const sortedFields = sortFields(activityLogFields, 'order');
  const sortedSearchFields = sortFields(activityLogFields, 'searchableOrder');

  if (firstLoad === false) {
    const storedSearchFilters = sessionStorage.getItem('pim.searchFilters');
    const storedSearchFields = sessionStorage.getItem('pim.searchFields');
    const parsedStoredSearchFilters = JSON.parse(storedSearchFilters) || { activityLog: [] };
    const parsedStoredSearchFields = JSON.parse(storedSearchFields) || { activityLog: [] };
    if (parsedStoredSearchFilters.activityLog.length === 0 && parsedStoredSearchFields.activityLog.length === 0) {
      setTimeout(function(){
        props.getActivityLogData({limit: mapperItemsPerPage, offset: 0});
      }, 1000);
    }
    setFirstLoad(true);
  }

  const sortedPredefinedFilters = {
    "manual_changes_only": {
      "name": "Manual Changes Only",
    },
    "today_manual_changes_only": {
      "name": "Today Manual Changes Only",
    },
    "system_changes_only": {
      "name": "System Changes Only",
    },
  };

  return (
    <Layout
      horizontalMenu
      header={{
        className: 'header-mer',
        subHeaderContent: (
          <List
            dir="horizontal"
            className="justify-center"
            dataSource={headerList({ abilityContext })}
          />
        ),
      }}
      sidebar={{
        className: `sidebar-mer sidebar-ranking-mer sidebar-scroll-secondary`,
        content: <SidebarContent
          sortedFields={sortedFields}
          sortedFilters={sortedPredefinedFilters}
          sortedSearchFields={sortedSearchFields}
          condition={mapperCondition}
          scroll={mapperScroll}
          getActivityLogData={props.getActivityLogData}
          reset={mapperReset}
          itemsPerPage={mapperItemsPerPage}
        />,
      }}
      main={{
        className: 'main-mer main-ranking-mer',
        content: (
          <ActivityLogTable
            getActivityLogData={props.getActivityLogData}
            activityLogFields={sortedFields}
            mapperData={mapperData}
            mapperScroll={mapperScroll}
            mapperCondition={mapperCondition}
            mapperItemsPerPage={mapperItemsPerPage}
          />
        )
      }}
    />
  );
};

ActivityLog.propTypes = {
  setFilterTarget: func.isRequired,
  getActivityLogData: func.isRequired,
  activityLogPim: shape().isRequired,
  fetchAttributes: func.isRequired,
  activityLogFields: shape(),
};

ActivityLog.defaultProps = {
  activityLogFields: {},
};

const mapStateToProps = state => ({
  activityLogFields: state.system.modes.activityLog,
  activityLogPim: state,
});

const mapDispatchToProps = {
  setFilterTarget,
  getActivityLogData,
  fetchAttributes
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityLog));
