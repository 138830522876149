import React, { memo } from 'react';
import { connect } from 'react-redux';
import { camelize } from 'humps';
import {
  string, shape, arrayOf, bool, func, number,
} from 'prop-types';
import { isEmpty } from 'lodash';
import {
  Grid, Card, CardContent, CardActions, IconButton, Checkbox, Tooltip,
  Button,
} from '@material-ui/core';

import VariantImageViewContainer from '../../containers/VariantImageViewContainer';
import SortableImage from '../SortableImage';
import { mapStateToProps, mapDispatchToProps } from './connect';
import IntlMessages from '../../../../util/IntlMessages';

const Item = memo((props) => {
  const localAttributes = ['id', 'title.en', 'images', 'specifications.ao_brand_name'];

  const {
    parentValue, sortedAttributes, productItem, disabled, style,
    certainProductAttributes, isEditMode, pagination, index: productIndex,
    productComments, handleExpandParentLevel, collapsed, setCollapsed, variantsCollapsed,
    setVariantsCollapsed, searchFields, disableEdit, fetchActivityLogsByProductId,
    parentItem, productsCommentsCount, productCommentsLoading,
  } = props;

  const { variants, childrenIds, variantsLoaded } = productItem;

  const handleExpandProduct = () => {
    if (variantsLoaded) {
      setCollapsed(
        collapsed.includes(productItem.id)
          ? collapsed.filter(c => c !== productItem.id)
          : [...collapsed, productItem.id],
      );
    } else {
      handleExpandParentLevel(productItem);
      setCollapsed([...collapsed, productItem.id]);
    }
  };

  const availableAttributes = localAttributes
    .map(a => sortedAttributes
      .find(s => s.code === a)).filter(a => a);

  const handleSortEnd = (images, removedImage) => {
    const { productsImagesOverride } = props.pim;
    let updatedList = [...productsImagesOverride];
    if (!productsImagesOverride.find(p => p.id === productItem.id)) {
      updatedList.push({ id: productItem.id, images });
    } else {
      updatedList = [...productsImagesOverride].map((ul) => {
        if (ul.id === productItem.id) return ({ id: ul.id, images });
        return ul;
      });
    }

    if (isEmpty(images) && removedImage) {
      const payload = {
        id: productItem.id,
        images: productItem.images.map(img => img.url),
      };
      props.setProductsWithoutImages([
        ...props.productsWithoutImages,
        payload,
      ]);
    }

    props.setUpdatedProductsImagesOverrides(updatedList);
  };

  const handleProductSelect = () => {
    const { productsImagesOverride, list } = props.pim;

    if (productsImagesOverride.find(pio => pio.id === productItem.id)) {
      const filteredList = productsImagesOverride.filter(pio => pio.id !== productItem.id);
      props.setUpdatedProductsImagesOverrides(filteredList);
      const returnedProduct = list.find(p => p.id === productItem.id);
      const filteredProductsWithoutImages = props.productsWithoutImages
        .filter(p => p.id !== productItem.id);

      props.injectProducts([], returnedProduct, props.pim.filterTarget);
      props.setProductsWithoutImages(filteredProductsWithoutImages);
    } else {
      const clonnedList = [...productsImagesOverride];
      const newItem = ({
        id: productItem.id,
        images: productItem.images,
      });
      clonnedList.push(newItem);
      props.setUpdatedProductsImagesOverrides(clonnedList);
    }
  };

  const getSortableImageList = () => {
    const { productsImagesOverride } = props.pim;
    const item = productsImagesOverride.find(pio => pio.id === parentValue.id);
    return item ? item.images : parentItem.images;
  };

  const handleUnlockAttributesClick = () => {};
  const fetchComments = () => fetchActivityLogsByProductId(productItem.id);

  const hasComments = productsCommentsCount && productsCommentsCount
    .some(pcc => pcc.id === productItem.id);
  const isReviewed = productItem.pimStatus && productItem.pimStatus.status === 'reviewed';
  const showUnlockAttributeButton = !isEmpty(productItem.lockedAttributes);

  return (
    <Grid style={style} item xs={12}>
      <div className="product-item block flex items-center pr-20 pl-20 images-item">
        <div className="product-item-type">
          <span>{productItem.type}</span>
        </div>
        <Card className="product-item-card-list without-dnd parent">
          <CardActions className="card-collapse-btn pr-0">
            {!disabled && (
              <div>
                <Checkbox
                  color="primary"
                  checked={props.pim.productsImagesOverride.some(p => p.id === parentValue.id)}
                  onChange={handleProductSelect}
                  name={parentValue.title
                    ? camelize(parentValue.title)
                    : ''
                  }
                />
              </div>
            )}
            {!isEmpty(childrenIds) && (
              <IconButton color="inherit" onClick={handleExpandProduct}>
                <i className={`zmdi ${collapsed.includes(productItem.id) ? 'zmdi-chevron-down' : 'zmdi-chevron-right'}`} />
              </IconButton>
            )}
          </CardActions>
          <CardContent className="product-content flex full-width ml-0 mr-0">
            <div className="flex full-width list-items direction-column justify-center">
              {availableAttributes.map((attr) => {
                const cellXlClassName = attr.code === 'id' ? 'cell-xl' : '';
                const currentAttribute = certainProductAttributes
                  .find(el => el.code === attr.value) || {};

                let itemValue = typeof parentValue[camelize(attr.value)] === 'string' ? parentValue[camelize(attr.value)] : false;

                if (camelize(attr.value) === 'aoBrandName') {
                  itemValue = parentValue.brand;
                }

                return (
                  <div
                    className={`flex direction-column product-item-data relative ${cellXlClassName}`}
                    key={attr.key}
                  >
                    {attr.label !== 'Images' && (
                      <div className="value relative">
                        <div className="product-cell product-cell-inline">
                          <div className="product-cell-label">{(currentAttribute.label ? currentAttribute.label.en : '') || attr.label}</div>
                          {itemValue && <div className="product-cell-value ytyr">{itemValue}</div>}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex edit-icon-wrapper items-center mr-3">
              <span className={`icon-xs icon-premium position-info list ${isEditMode ? 'with-margin' : ''}`}>
                {((pagination.page - 1) * pagination.limit) + productIndex + 1}
              </span>
              {showUnlockAttributeButton && (
                <div className="lock-icon">
                  <Button
                    color="primary"
                    className="btn-xs extra float-right"
                    onClick={handleUnlockAttributesClick}
                    disabled
                  >
                    <IntlMessages id="pim.table.product.button.unlockAttributes" />
                  </Button>
                </div>
              )}
              {isReviewed && (
                <Tooltip
                  title={`by ${productItem.pimStatus.reviewedBy}`}
                  placement="top"
                >
                  <span className="badge-success badge-xs badge-rel badge-text secondary">
                    <IntlMessages id="pim.table.product.badge.reviewed.label" />
                  </span>
                </Tooltip>
              )}
              {
                hasComments && (
                  <Tooltip
                    onClick={fetchComments}
                    title={(
                      <div className="comments">
                        {
                          productCommentsLoading
                            ? <IntlMessages id="pim.list.productComments.loading" />
                            : productComments.map((pc, index) => (
                              <div key={pc.id}>
                                {`${index + 1}. ${pc.comment}`}
                              </div>
                            ))
                        }
                      </div>
                    )}
                    placement="right"
                  >
                    <i className="ti-comment-alt icon-premium icon-xs" />
                  </Tooltip>
                )}
            </div>
          </CardContent>
          <SortableImage
            list={getSortableImageList()}
            onSortEnd={handleSortEnd}
            isEditMode={isEditMode}
          />
        </Card>
      </div>
      {
        collapsed.includes(productItem.id) && !isEmpty(variants) && (
          <VariantImageViewContainer
            variants={variants}
            parent={productItem}
            variantsCollapsed={variantsCollapsed}
            setVariantsCollapsed={setVariantsCollapsed}
            productComments={productComments}
            searchFields={searchFields}
            isEditMode={isEditMode}
            disableEdit={disableEdit}
          />
        )
      }
    </Grid>
  );
});

Item.propTypes = {
  sortedAttributes: arrayOf(shape()),
  parentValue: shape(),
  productItem: shape(),
  certainProductAttributes: arrayOf(shape),
  // eslint-disable-next-line react/no-unused-prop-types
  handleExpandParentLevel: func.isRequired,
  isEditMode: bool.isRequired,
  setUpdatedProductsImagesOverrides: func.isRequired,
  injectProducts: func.isRequired,
  pim: shape({
    productsImagesOverride: arrayOf(shape({
      id: string,
    })),
    list: arrayOf(shape({
      id: string,
    })),
  }).isRequired,
  pagination: shape().isRequired,
  style: shape().isRequired,
  index: number,
  disabled: bool.isRequired,
  collapsed: arrayOf(string).isRequired,
  setCollapsed: func.isRequired,
  variantsCollapsed: arrayOf(string).isRequired,
  setVariantsCollapsed: func.isRequired,
  productComments: arrayOf(shape()).isRequired,
  searchFields: arrayOf(string).isRequired,
  parentItem: shape().isRequired,
  productsCommentsCount: arrayOf(shape()).isRequired,
  disableEdit: bool.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  productCommentsLoading: bool.isRequired,
  setProductsWithoutImages: func.isRequired,
  productsWithoutImages: arrayOf(shape()).isRequired,
};

Item.defaultProps = {
  sortedAttributes: [],
  parentValue: {},
  productItem: {},
  certainProductAttributes: [],
  index: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
