export const gridOptions = [
  {
    title: 'Grid - 4 per row',
    value: 'grid_4_per_row',
    availableFor: ['preview'],
  }, {
    title: 'Grid - 5 per row',
    value: 'grid_5_per_row',
    availableFor: ['preview'],
  }, {
    title: 'Grid - 6 per row',
    value: 'grid_6_per_row',
    availableFor: ['preview'],
  }, {
    title: 'List',
    value: 'list',
    availableFor: ['pim', 'preview'],
  }, {
    title: 'Image View',
    value: 'image_view',
    availableFor: ['pim', 'preview'],
  },
];

export default gridOptions;
