/* eslint-disable prefer-destructuring */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import { connect } from 'react-redux';
import {
  func, shape,
} from 'prop-types';

import useProductEffects from './hooks/useProduct';
import usePim from './hooks/usePim';
import useCategoryEffects from './hooks/useCategory';
import useAtributMatrixEffects from './hooks/useAtributMatrix';
import usePreviewFilterAttributesEffects from './hooks/usePreviewFilterAttributes';
import usePreviewFieldAttributesEffects from './hooks/usePreviewFieldAttributes';
import useFacetInputTypesEffects from './hooks/useFacetInputTypes';
import usePreviewFilterEffects from './hooks/usePreviewFilter';
import useScopeCategoryEffects from './hooks/useScopeCategory';
import useProductAttributesEffects from './hooks/productAttribute';

import SubHeaderContent from './components/SubHeaderContent';
import HeaderControls from './components/HeaderControls';
import PimLayout from '../../../../components/Layout/pimLayout';
import Can from '../../../../components/Can';

import appPermissions from '../../../../util/appPermissions';

import MainContent from './components/MainContent';
import SidebarContent from './components/SidebarContent';

import { pimSelector } from './selectors/pim';
import { productSelector } from './selectors/product';
import { previewFilterSelector } from './selectors/previewFilter';
import { virtualCategorySelector } from './selectors/virtualCategory';

import {
  setSelectedProducts,
  clearProductState,
  fetchCountProductsActivitiesByProductIds,
  injectProducts,
  setUpdatedProductsImagesOverrides,
  clearFromProductState,
  setSelectedProduct,
  setProductsListEmpty,
  setRootFilter,
} from '../../../../actions/pim';

import {
  setFields,
  finishProductEdit,
  setProductForEdit,
  fetchActivityLogsByProductId,
} from '../../../../actions/product';

import {
  clearVirtualCategoryTreeState,
  clearState as clearVirtualCategoryState,
  fetchRootCategories as fetchVirtualRootCategories,
} from '../../../../actions/virtualCategory';

import {
  fetchList as fetchListAttributesMatrix,
} from '../../../../actions/attributesMatrix';

import {
  fetchFacetInputTypeList,
} from '../../../../actions/facetInputTypes';

import {
  fetchPreviewFilterAttributes,
} from '../../../../actions/previewFilterAttribute';

import {
  fetchPreviewFieldAttributes,
} from '../../../../actions/previewFieldAttribute';

import {
  fetchPreviewFilters,
  updatePreviewFilters,
} from '../../../../actions/previewFilter';

import {
  fetchRootCategories as fetchRootScopeCategories,
  clearScopeCategoryTreeState,
} from '../../../../actions/scopeCategory';

import {
  fetchCertainProductAttributes,
  fetchProductAttributeSets,
} from '../../../../actions/productAttribute';

const Home = memo((props) => {
  const {
    pimProps, productProps, previewFilterProps, virtualCategoryProps,
    systemModes, fromRules,
  } = props;

  const pimModeAttributes = systemModes.pim[pimProps.filterTarget];

  usePim({
    pimProps,
    actions: {
      setSelectedProducts: props.setSelectedProducts,
    },
  });
  useProductEffects({
    virtualCategoryProps,
    productProps,
    pimProps,
    actions: {
      setFields: props.setFields,
      setRootFilter: props.setRootFilter,
      injectProducts: props.injectProducts,
      setProductForEdit: props.setProductForEdit,
      finishProductEdit: props.finishProductEdit,
      clearProductState: props.clearProductState,
      setSelectedProduct: props.setSelectedProduct,
      setSelectedProducts: props.setSelectedProducts,
      setProductsListEmpty: props.setProductsListEmpty,
      clearFromProductState: props.clearFromProductState,
      clearVirtualCategoryState: props.clearVirtualCategoryState,
      fetchActivityLogsByProductId: props.fetchActivityLogsByProductId,
      clearVirtualCategoryTreeState: props.clearVirtualCategoryTreeState,
      setUpdatedProductsImagesOverrides: props.setUpdatedProductsImagesOverrides,
      fetchCountProductsActivitiesByProductIds: props.fetchCountProductsActivitiesByProductIds,
    },
  });
  useCategoryEffects({
    actions: {
      fetchVirtualRootCategories: props.fetchVirtualRootCategories,
    },
  });
  useAtributMatrixEffects({
    actions: {
      fetchListAttributesMatrix: props.fetchListAttributesMatrix,
    },
  });
  useFacetInputTypesEffects({
    actions: {
      fetchFacetInputTypeList: props.fetchFacetInputTypeList,
    },
  });
  usePreviewFilterAttributesEffects({
    actions: {
      fetchPreviewFilterAttributes: props.fetchPreviewFilterAttributes,
    },
  });
  usePreviewFieldAttributesEffects({
    actions: {
      fetchPreviewFieldAttributes: props.fetchPreviewFieldAttributes,
    },
  });
  usePreviewFilterEffects({
    previewFilterProps,
    actions: {
      updatePreviewFilters: props.updatePreviewFilters,
      fetchPreviewFilters: props.fetchPreviewFilters,
    },
  });
  useScopeCategoryEffects({
    actions: {
      fetchRootScopeCategories: props.fetchRootScopeCategories,
      clearScopeCategoryTreeState: props.clearScopeCategoryTreeState,
    },
  });
  useProductAttributesEffects({
    pimProps,
    productProps,
    pimModeAttributes,
    actions: {
      fetchProductAttributeSets: props.fetchProductAttributeSets,
      fetchCertainProductAttributes: props.fetchCertainProductAttributes,
    },
  });

  return (
    <PimLayout
      wrapperClassName={pimProps.fetching ? 'container-loading' : ''}
      containerWrapperClassName="app-content-wrapper pim sm"
      horizontalMenu
      header={{
        className: 'header-mer',
        subHeaderContent: <SubHeaderContent />,
        controls: (
          <Can
            do={appPermissions.pim.permissions.update}
            on={appPermissions.pim.name}
          >
            <HeaderControls />
          </Can>
        ),
      }}
      sidebar={{
        className: 'sidebar-mer sidebar-home-mer sidebar-scroll-secondary',
        content: <SidebarContent fromRules={fromRules} />,
      }}
      main={{
        className: 'main-mer grid-wide',
        withoutScroll: !fromRules,
        content: (
          <MainContent fromRules={fromRules} />
        ),
      }}
    />
  );
});

Home.propTypes = {
  pimProps: shape().isRequired,
  productProps: shape().isRequired,
  previewFilterProps: shape().isRequired,
  virtualCategoryProps: shape().isRequired,
  systemModes: shape().isRequired,
  setSelectedProducts: func.isRequired,
  clearProductState: func.isRequired,
  fetchCountProductsActivitiesByProductIds: func.isRequired,
  injectProducts: func.isRequired,
  setUpdatedProductsImagesOverrides: func.isRequired,
  clearFromProductState: func.isRequired,
  setSelectedProduct: func.isRequired,
  setProductsListEmpty: func.isRequired,
  setRootFilter: func.isRequired,
  setFields: func.isRequired,
  finishProductEdit: func.isRequired,
  setProductForEdit: func.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  clearVirtualCategoryTreeState: func.isRequired,
  clearVirtualCategoryState: func.isRequired,
  fetchVirtualRootCategories: func.isRequired,
  fetchListAttributesMatrix: func.isRequired,
  fetchFacetInputTypeList: func.isRequired,
  fetchPreviewFilterAttributes: func.isRequired,
  fetchPreviewFieldAttributes: func.isRequired,
  fetchPreviewFilters: func.isRequired,
  updatePreviewFilters: func.isRequired,
  fetchRootScopeCategories: func.isRequired,
  clearScopeCategoryTreeState: func.isRequired,
  fetchCertainProductAttributes: func.isRequired,
  fetchProductAttributeSets: func.isRequired,
};
Home.defaultProps = {};

const mapDispatchToProps = {
  setSelectedProducts,
  clearProductState,
  fetchCountProductsActivitiesByProductIds,
  injectProducts,
  setUpdatedProductsImagesOverrides,
  clearFromProductState,
  setSelectedProduct,
  setProductsListEmpty,
  setRootFilter,
  setFields,
  finishProductEdit,
  setProductForEdit,
  fetchActivityLogsByProductId,
  clearVirtualCategoryTreeState,
  clearVirtualCategoryState,
  fetchVirtualRootCategories,
  fetchListAttributesMatrix,
  fetchFacetInputTypeList,
  fetchPreviewFilterAttributes,
  fetchPreviewFieldAttributes,
  fetchPreviewFilters,
  updatePreviewFilters,
  fetchRootScopeCategories,
  clearScopeCategoryTreeState,
  fetchCertainProductAttributes,
  fetchProductAttributeSets,
};

export const mapStateToProps = state => ({
  pimProps: pimSelector(state.pim),
  productProps: productSelector(state.product),
  previewFilterProps: previewFilterSelector(state.previewFilter),
  virtualCategoryProps: virtualCategorySelector(state.virtualCategory),
  systemModes: state.system.modes,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
