/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  memo, useContext, useState, useEffect,
} from 'react';
import {
  func, shape, bool, string,
} from 'prop-types';
import { connect } from 'react-redux';
import {
  isEqual, isEmpty, cloneDeep, uniq,
} from 'lodash';
import swal from '@sweetalert/with-react';
import { NotificationManager } from 'react-notifications';
import { CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import {
  createVirtualCategoryVirtualFacet,
  updateVirtualCategoryVirtualFacet,
  deleteVirtualCategoryVirtualFacet,
  setVirtualCategoryVirtualFacetToDeleteId,
} from 'actions/virtualCategoryVirtualFacet';

import {
  setSelectedProductList,
  setIsCategorySaving,
  setErrors,
  setSortingsErrors,
  setFiltersErrors,
  fetchVirtualCategoryByPathByName,
  updateCategory,
  createCategory,
  clearFromVirtualCategoryState,
  deleteVirtualCategory,
} from 'actions/virtualCategoryPage';

import { getCamelizeKey } from 'util/virtualFacetManagment';

import { AbilityContext } from 'components/AbilityContext';
import ButtonGroup from 'components/ButtonGroup';

import { rankingRule } from 'util/constants/constants';
import IntlMessages from 'util/IntlMessages';
import { mapFiltersByOperator } from 'util/mapFiltersByOperator';
import checkValidations from 'util/validator';
import { defaultPagination } from 'util/defaultData';
import { categoriesAppPath, getCategoryEditAppPath } from 'util/paths';

import appPermissions from 'util/appPermissions';
import buttonList from 'util/buttonList';
import { getCheckCategoryNameFilter } from 'util/getCheckCategoryNameFilter';
import { virtualCategoryPageSelector } from './selectors/virtualCategoryPage';
import { categoryFacetSelector } from './selectors/categoryFacet';
import actionModes from '../../utils/actionModes';


import {
  categoryNameValidations,
  validateSortings,
  validateFilters,
} from '../../utils/validations';

const Actions = memo((props) => {
  const [saveAndClose, setSaveAndClose] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [categoryCreating, setCategoryCreating] = useState(false);
  const abilityContext = useContext(AbilityContext);

  const { onClose } = props;

  const {
    virtualCategoryItem,
    originalVirtualCategoryItem,
    virtualCategoryUpdating,
    virtualCategoryUpdated,
    virtualCategorySort,
    virtualCategoryFilter,
    isCategorySaving,
    virtualCategoryFetching,
    virtualCategoryDeleted,
    virtualCategoryDeleting,
    virtualCategoryCreated,
    virtualCategoryCreating,
    isDuplicatedCategory,
  } = props.virtualCategoryPageState;

  useEffect(() => {
    if (
      virtualCategoryUpdated
      || (props.categoryFacet.created || props.categoryFacet.updated)
    ) {
      if (props.categoryFacet.updated && !props.categoryFacet.updating && virtualCategoryUpdated) {
        NotificationManager.success(<IntlMessages id="virtualCategoryPage.categoryUpdated.success" />);
        NotificationManager.warning(
          <IntlMessages id="virtualCategoryPage.update.alert.warning.notification" />,
        );
      }

      props.setIsCategorySaving(false);

      if (saveAndClose) onClose();
      setSaveAndClose(false);
      props.clearFromVirtualCategoryState({
        updated: false,
      });
    }
  }, [
    virtualCategoryUpdated,
    props.categoryFacet.created,
    props.categoryFacet.updated,
  ]);

  useEffect(() => {
    if (virtualCategoryDeleted && !virtualCategoryDeleting && deleting) {
      NotificationManager.success(<IntlMessages id="virtualCategoryPage.delete.alert.success" />);
      setTimeout(() => {
        props.history.push(categoriesAppPath);
      }, 1200);
    }
  }, [virtualCategoryDeleted]);

  useEffect(() => {
    if (virtualCategoryCreated && !virtualCategoryCreating && categoryCreating) {
      let isVirtualCategoryCreating = false;
      if (!isEmpty(props.categoryFacet.list) && !props.categoryFacet.creating) {
        isVirtualCategoryCreating = true;
        const categoryFacet = cloneDeep(props.categoryFacet.item);
        let reducedFacet = cloneDeep(categoryFacet.facetsList);
        if (!isDuplicatedCategory) {
          reducedFacet = categoryFacet.facetsList
            .reduce((acc, cur) => ({ ...acc, [getCamelizeKey(cur.label.en)]: cur }), {});
        } else {
          delete categoryFacet.id;
        }

        categoryFacet.facetsList = reducedFacet;
        if (isEmpty(categoryFacet.groupedValues)) {
          delete categoryFacet.groupedValues;
        }
        categoryFacet.referencedEntityId = virtualCategoryItem.id;
        props.createVirtualCategoryVirtualFacet(categoryFacet);
      }
      if (!isVirtualCategoryCreating) {
        NotificationManager.success(<IntlMessages id="virtualCategoryPage.create.alert.success" />);
        if (saveAndClose) {
          props.history.push(categoriesAppPath);
        } else {
          props.history.push(getCategoryEditAppPath(virtualCategoryItem.id));
        }
      }
    }
  }, [
    virtualCategoryCreated,
  ]);

  useEffect(() => {
    if (virtualCategoryCreated && props.categoryFacet.created && categoryCreating) {
      NotificationManager.success(<IntlMessages id="virtualCategoryPage.create.alert.success" />);
      if (saveAndClose) {
        props.history.push(categoriesAppPath);
      } else {
        props.history.push(getCategoryEditAppPath(virtualCategoryItem.id));
      }
    }
  }, [
    props.categoryFacet.created,
  ]);

  useEffect(() => {
    if (!isEmpty(props.categoryFacet.virtualFacetIdToDelete)) {
      setSaveAndClose({ closeAfterUpdate: true });
    }
  }, [
    props.categoryFacet.virtualFacetIdToDelete,
  ]);

  const checkChanges = () => {
    const sortRules = virtualCategorySort.sortRules.map(sr => ({
      field: sr.field,
      direction: sr.defaultValue.toLowerCase(),
    })).filter(el => el.field !== rankingRule);

    const isCategoryChanged = (
      !isEqual(
        virtualCategoryItem,
        originalVirtualCategoryItem,
      )
      || !isEqual(
        virtualCategoryFilter.filters,
        originalVirtualCategoryItem.virtualParams.filters,
      )
      || !isEqual(
        sortRules,
        originalVirtualCategoryItem.virtualParams.sortRules,
      )
      || !isEqual(
        virtualCategoryFilter.category.mappedCategories.map(mc => mc.id).sort(),
        originalVirtualCategoryItem.virtualParams.mappedCategories.sort(),
      )
      || !isEqual(
        virtualCategorySort.rankingRuleCode,
        originalVirtualCategoryItem.virtualParams.rankingRuleCode,
      )
      || !isEqual(
        virtualCategoryFilter.category.selectedCategories
          && virtualCategoryFilter.category.selectedCategories.sort(),
        originalVirtualCategoryItem.virtualParams.selectedCategories
          && originalVirtualCategoryItem.virtualParams.selectedCategories.sort(),
      )
    );

    const isFacetsChanged = !isEqual(
      props.categoryFacet.list,
      props.categoryFacet.origList,
    );

    return { isCategoryChanged, isFacetsChanged };
  };

  const handleOnClose = () => {
    const { isCategoryChanged, isFacetsChanged } = checkChanges();

    if (isCategoryChanged || isFacetsChanged) {
      swal({
        title: 'Are you sure?',
        text: 'If you leave this page then any unsaved changes will be lost.',
        icon: 'warning',
        dangerMode: true,
        buttons: true,
      }).then((willDiscard) => {
        if (willDiscard) {
          props.setSelectedProductList({ in: [], nin: [] });
          onClose();
        }
      });
    } else {
      props.setSelectedProductList({ in: [], nin: [] });
      onClose();
    }
  };

  const checkSortings = () => {
    let sortingsErrors = [];
    if (!isEmpty(virtualCategorySort.sortRules)) {
      sortingsErrors = validateSortings(virtualCategorySort.sortRules);
    }

    if (virtualCategorySort.sortRules.some(p => p.field === rankingRule)
      && !virtualCategorySort.rankingRuleCode) {
      sortingsErrors = [{ rankingRuleCode: 'helper.fieldCantNotBeBlank' }];
    }

    if (
      (
        !isEmpty(sortingsErrors) && sortingsErrors
          .some(s => s.field === '') && sortingsErrors
          .map(s => s.field === '')
          .filter(el => el).length === sortingsErrors.length
      )
      || (virtualCategorySort.sortRules.some(p => p.field === rankingRule)
        && virtualCategorySort.rankingRuleCode)
    ) {
      sortingsErrors = [];
    }
    return sortingsErrors;
  };

  const checkCategoryValidations = (categoryData) => {
    const errors = checkValidations(
      categoryNameValidations, virtualCategoryItem.name || categoryData.name,
    );
    const sortingsErrors = checkSortings();
    let filtersErrors = validateFilters(virtualCategoryFilter.filters);

    props.setErrors(errors);
    props.setSortingsErrors(sortingsErrors);
    props.setFiltersErrors(filtersErrors);

    const isFiltersErrorsGroupEmpty = filtersErrors.map(f => f.group.map(g => isEmpty(g))).flat();

    if (!isFiltersErrorsGroupEmpty.some(f => !f)) {
      filtersErrors = [];
      props.setFiltersErrors([]);
    }

    if (!isEmpty(errors) || !isEmpty(sortingsErrors) || !isEmpty(filtersErrors)) {
      return false;
    }

    return true;
  };

  const handleFacetDelete = () => {
    if (props.categoryFacet.virtualFacetIdToDelete) {
      props.deleteVirtualCategoryVirtualFacet(props.categoryFacet.virtualFacetIdToDelete);
    }
  };

  const virtualFacetManagement = (categoryFacet) => {
    if (
      isEmpty(props.categoryFacet.list)
      || (!categoryFacet.id && !props.categoryFacet.virtualFacetIdToDelete)
    ) {
      if (isEmpty(categoryFacet.groupedValues)) {
        // eslint-disable-next-line no-param-reassign
        delete categoryFacet.groupedValues;
      }
      props.createVirtualCategoryVirtualFacet(categoryFacet);
    } else {
      const payload = {
        ...categoryFacet,
        facetsList: {
          ...categoryFacet.facetsList,
        },
      };

      delete payload.id;
      delete payload.updatedAt;
      delete payload.createdAt;

      if (isEmpty(payload.groupedValues)) {
        delete payload.groupedValues;
      }

      if (!isEmpty(props.categoryFacet.list)) {
        const categoryFacetId = categoryFacet.id || props.categoryFacet.virtualFacetIdToDelete;
        props.updateVirtualCategoryVirtualFacet(categoryFacetId, payload);
      }
    }
  };

  const handleOnSaveClick = ({ closeAfterUpdate }) => {
    if (!isCategorySaving) {
      props.setIsCategorySaving(true);
      setSaveAndClose(closeAfterUpdate);
      const { isCategoryChanged, isFacetsChanged } = checkChanges();
      if (!isCategoryChanged && !isFacetsChanged) {
        props.setIsCategorySaving(false);
        return NotificationManager.warning(
          <IntlMessages id="virtualCategoryPage.create.alert.warning" />,
        );
      }

      const params = { isSync: true, mapToMatrix: true };
      const { name } = virtualCategoryItem;
      const categoryVirtualParams = {
        ...virtualCategoryItem.virtualParams,
        rankingRuleCode: virtualCategorySort.rankingRuleCode || null,
        filters: mapFiltersByOperator(virtualCategoryFilter.filters),
        sortRules: !isEmpty(virtualCategorySort.sortRules)
          ? virtualCategorySort.sortRules.map(s => ({
            field: s.field,
            direction: s.defaultValue.toLowerCase(),
          })).filter(s => s.field !== rankingRule)
          : [],
        positions: virtualCategoryItem.virtualParams
          ? virtualCategoryItem.virtualParams.positions
          : {},
        mappedCategories: virtualCategoryFilter.isSwitchOn
          ? uniq(virtualCategoryFilter.category.mappedCategories.map(mc => mc.id))
          : [],
        selectedCategories: !virtualCategoryFilter.isSwitchOn
          ? uniq(virtualCategoryFilter.category.selectedCategories.map(mc => mc.id))
          : [],
      };

      const categoryData = {
        virtualParams: categoryVirtualParams,
        name: {
          en: (!isEmpty(name) && name.en) || '',
          he: (!isEmpty(name) && name.he) || '',
        },
        parentId: props.selectedScopeCategoryId || virtualCategoryItem.parentId || null,
        isEnabled: virtualCategoryItem.isEnabled,
        useInProductSearch: virtualCategoryItem.useInProductSearch,
        useInDepartmentsTree: virtualCategoryItem.useInDepartmentsTree,
        isShowSubcategoriesOnTop: virtualCategoryItem.isShowSubcategoriesOnTop,
        includeInMenu: virtualCategoryItem.includeInMenu,
      };

      const isValid = checkCategoryValidations(categoryData);

      if (
        !isEmpty(props.categoryFacet.list)
        && isFacetsChanged
      ) {
        const categoryFacet = cloneDeep(props.categoryFacet.item);

        const reducedFacet = categoryFacet.facetsList
          .reduce((acc, cur) => ({ ...acc, [getCamelizeKey(cur.label.en)]: cur }), {});

        categoryFacet.facetsList = reducedFacet;
        if (props.actionMode === actionModes.edit) {
          virtualFacetManagement(categoryFacet);
        }
      }

      if (
        !isEmpty(props.categoryFacet.virtualFacetIdToDelete)
        && isEmpty(props.categoryFacet.list)
      ) {
        handleFacetDelete();
      } else props.setVirtualCategoryVirtualFacetToDeleteId('');

      if (isValid && isCategoryChanged) {
        props.setErrors({});

        const parentId = virtualCategoryItem.parentId || props.selectedScopeCategoryId;
        const parentCategory = props.scopeTreeItems.find(c => c.id === parentId) || {};
        const generatedPathByNameEn = parentCategory.pathByName
          ? `${parentCategory.pathByName.en} > ${virtualCategoryItem.name.en}`
          : virtualCategoryItem.name.en;
        const generatedPathByNameHe = parentCategory.pathByName
          ? `${parentCategory.pathByName.he} > ${virtualCategoryItem.name.he}`
          : virtualCategoryItem.name.he;

        props.fetchVirtualCategoryByPathByName(
          getCheckCategoryNameFilter(generatedPathByNameEn, generatedPathByNameHe),
          defaultPagination,
          (items) => {
            const pathByNameEnWasChanged = props.actionMode === actionModes.edit
              && (generatedPathByNameEn !== virtualCategoryItem.pathByName.en);

            const pathByNameHeWasChanged = props.actionMode === actionModes.edit
              && (generatedPathByNameHe !== virtualCategoryItem.pathByName.he);

            if (items.length > 0 && props.actionMode === actionModes.new) {
              props.setIsCategorySaving(false);
              return NotificationManager.error(<IntlMessages id="virtualCategoryPage.create.alert.error" />);
            }

            if (props.actionMode === actionModes.edit
              && (pathByNameEnWasChanged || pathByNameHeWasChanged)
              && items.some(i => i.id !== virtualCategoryItem.id) > 0) {
              props.setIsCategorySaving(false);
              return NotificationManager.error(<IntlMessages id="virtualCategoryPage.edit.alert.error" />);
            }

            if (props.actionMode === actionModes.new) {
              setCategoryCreating(true);
              return props.createCategory(categoryData, params);
            }

            return props.updateCategory({ ...categoryData, id: virtualCategoryItem.id }, params);
          },
        );
      } else {
        props.setIsCategorySaving(false);
      }
    }
    return false;
  };

  const loading = isCategorySaving
    || virtualCategoryUpdating
    || props.fetchingCategoriesByIds
    || virtualCategoryFetching;

  const deleteCategory = () => {
    const params = {
      isSync: true,
    };

    props.deleteVirtualCategory(virtualCategoryItem.id, params);
    if (!isEmpty(props.categoryFacet.item)) {
      props.deleteVirtualCategoryVirtualFacet(props.categoryFacet.item.id);
    }
    setDeleting(true);
  };

  const handleDelete = () => {
    const { hasChildren } = virtualCategoryItem;
    if (hasChildren) {
      swal({
        title: 'Warning',
        text: 'Current category has child categories. You need to delete the child categories first',
        icon: 'warning',
        dangerMode: true,
      });
    } else {
      swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to permanently delete this category?',
        icon: 'warning',
        dangerMode: true,
        buttons: true,
      })
        .then((willDiscard) => {
          if (willDiscard) {
            deleteCategory();
          }
        });
    }
  };

  const getListParams = () => {
    let listParams = {
      onCloseClick: handleOnClose,
      disabled: loading,
    };

    const checkPermissions = (permission) => {
      if (abilityContext.can(
        permission,
        appPermissions.category.name,
      )) {
        return true;
      }
      return false;
    };

    if (checkPermissions(appPermissions.category.permissions.update)) {
      listParams = {
        ...listParams,
        buttons: {
          success: {
            className: 'button-primary',
          },
        },
        onSaveAndCloseClick: () => handleOnSaveClick({ closeAfterUpdate: true }),
        onSaveClick: handleOnSaveClick,
      };
    }

    if (abilityContext.can(
      appPermissions.category.permissions.delete,
      appPermissions.category.name,
    ) && props.actionMode !== actionModes.new) {
      listParams = {
        ...listParams,
        onDeleteClick: handleDelete,
      };
    }

    return buttonList(listParams);
  };

  const buttonOrder = ['save', 'saveAndClose', 'close', 'delete'];
  const orderedButtons = getListParams()
    .sort((a, b) => buttonOrder.indexOf(a.id) - buttonOrder.indexOf(b.id));

  const disabledClass = loading ? 'disabled' : '';

  return (
    <div className={`relative ${disabledClass}`}>
      {loading && (
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className="progress-warning custom-loader bottom"
          size={20}
          thickness={4}
        />
      )}
      <ButtonGroup
        materialBtn
        className={`btn-group-${props.type} flex items-center mb-${props.gap}`}
        list={orderedButtons}
      />
    </div>
  );
});

Actions.propTypes = {
  type: string,
  gap: string,
  virtualCategoryPageState: shape().isRequired,
  onClose: func.isRequired,
  setSelectedProductList: func.isRequired,
  setIsCategorySaving: func.isRequired,
  setErrors: func.isRequired,
  setSortingsErrors: func.isRequired,
  setFiltersErrors: func.isRequired,
  fetchVirtualCategoryByPathByName: func.isRequired,
  updateCategory: func.isRequired,
  createCategory: func.isRequired,
  clearFromVirtualCategoryState: func.isRequired,
  categoryFacet: shape().isRequired,
  history: shape().isRequired,
  scopeTreeItems: shape().isRequired,
  createVirtualCategoryVirtualFacet: func.isRequired,
  updateVirtualCategoryVirtualFacet: func.isRequired,
  deleteVirtualCategoryVirtualFacet: func.isRequired,
  deleteVirtualCategory: func.isRequired,
  setVirtualCategoryVirtualFacetToDeleteId: func.isRequired,
  fetchingCategoriesByIds: bool.isRequired,
  actionMode: string.isRequired,
  selectedScopeCategoryId: string.isRequired,
};

Actions.defaultProps = {
  type: 'white',
  gap: '20',
};

const mapStateToProps = state => ({
  virtualCategoryPageState: virtualCategoryPageSelector(state.virtualCategoryPage),
  fetchingCategoriesByIds: state.physicalCategory.fetchingCategoriesByIds,
  categoryFacet: categoryFacetSelector(state.virtualCategoryVirtualFacet),
  selectedScopeCategoryId: state.scopeCategory.selectedScopeCategoryId,
  scopeTreeItems: state.scopeCategory.treeItems,
});

const mapDispatchToProps = {
  setSelectedProductList,
  setIsCategorySaving,
  setErrors,
  setSortingsErrors,
  setFiltersErrors,
  fetchVirtualCategoryByPathByName,
  updateCategory,
  createCategory,
  clearFromVirtualCategoryState,
  createVirtualCategoryVirtualFacet,
  updateVirtualCategoryVirtualFacet,
  deleteVirtualCategoryVirtualFacet,
  deleteVirtualCategory,
  setVirtualCategoryVirtualFacetToDeleteId,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Actions));
