import { createSelector } from 'reselect';

const previewSearchRootSelector = state => state;

const stateSelector = createSelector(
  previewSearchRootSelector,
  preview => ({
    selectedCategory: preview.selectedCategory,
    selectedPromotion: preview.selectedPromotion,
    selectedSearchFilter: preview.selectedSearchFilter,
    isHiddenProductsMode: preview.isHiddenProductsMode,
    hiddenProductsFilter: preview.hiddenProductsFilter,
    pagination: preview.pagination,
    virtualFacetFilter: preview.virtualFacetFilter,
    searchQuery: preview.searchQuery,
    facetsPreFilters: preview.facetsPreFilters,
    globalSearchValue: preview.globalSearchValue,
  }),
);

export const previewSearchSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  previewSearchSelector,
};
