import { useState } from 'react';

export const useStateHook = () => {
  const [files, setFiles] = useState([]);
  const [updateOnly, setUpdateOnly] = useState(true);

  const state = {
    files,
    updateOnly,
  };

  const setState = {
    setFiles,
    setUpdateOnly,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
