import { isEmpty } from 'lodash';

const validateTextField = value => value && value !== null && value !== 0 && value.toString().trim() !== '';

export const validate = (validation, value) => {
  let isValid;
  switch (validation.type) {
    case 'isRequired':
      isValid = value && value !== null && value !== 0 && value.toString().trim() !== '';
      break;
    case 'textFieldIsRequired':
      isValid = value && value.en && value.en !== null && value.en !== 0 && value.en.toString().trim() !== '';
      break;
    case 'textFieldForBothLanguagesIsRequired':
      isValid = value && validateTextField(value.en) && validateTextField(value.he);
      break;
    case 'minSize':
      isValid = value && value.length >= validation.condition;
      break;
    case 'maxSize':
      isValid = value && value.length <= validation.condition;
      break;
    case 'maxNumber':
      isValid = value && !Number.isNaN(Number(value)) && value <= validation.condition;
      break;
    case 'includeUppercase':
      isValid = value && value.split('').some(l => l >= 'A' && l <= 'Z');
      break;
    case 'isCompleted':
      isValid = value !== null && value === 100;
      break;
    case 'email':
      isValid = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(value);
      break;
    case 'number':
      isValid = /[^\d,]/g.test(value);
      break;
    case 'tel':
      isValid = /^(.{10})(.*)(.{10})$/g.test(value);
      break;
    case 'comparePasswords':
      isValid = value && value.newPassword === value.confirmPassword;
      break;
    case 'xss':
      isValid = !/(\b)(on\S+)(\s*)=|javascript|(<\s*)(\/*)script/i.test(value)
        && /^[a-zA-Z0-9_\s,]/.test(value);
      break;
    case 'alphanumeric':
      isValid = !value || /^[a-zA-Z0-9_]+$/.test(value);
      break;
    case 'notOnlySymbols':
      isValid = !value || /^[a-zA-Z0-9_\s]/.test(value);
      break;
    case 'onlyNumber':
      isValid = !value || !/^[a-zA-Z0-9_\s]/.test(value);
      break;
    case 'specialSymbols':
      isValid = !/[!@#$%^&*()_,.?"';~≈ç√∫˜µ≤≥÷åß∂ƒ©˙∆˚¬…æ«œ∑´®†¥¨ˆøπ“‘¡™£¢∞§¶•ªº–≠:{}|<>(+=._-]/g.test(value);
      break;
    case 'priceValidation':
      isValid = value > 0;
      break;
    default: return null;
  }
  if (!isValid) {
    return validation;
  }
  return false;
};

const validateAll = (validations, values) => {
  const validationsKeys = Object.keys(validations);
  if (!isEmpty(validationsKeys)) {
    return validationsKeys.reduce((acc, key) => {
      if (!isEmpty(validations[key])) {
        const result = validations[key].map(v => validate(v, values[key])).filter(e => !!e);
        return (!isEmpty(result)) ? { ...acc, [key]: result } : acc;
      } return acc;
    }, {});
  } return {};
};

export const validateTextFields = (validations, values) => {
  const validationsKeys = Object.keys(validations);
  if (!isEmpty(validationsKeys)) {
    return validationsKeys.reduce((acc, key) => {
      if (!isEmpty(validations[key])) {
        const enField = validations[key].map(v => validate(v, values[key].en)).filter(e => !!e);
        const heField = validations[key].map(v => validate(v, values[key].he)).filter(e => !!e);

        return (!isEmpty(enField) || !isEmpty(heField))
          ? { ...acc, [key]: { en: enField, he: heField } }
          : acc;
      } return acc;
    }, {});
  } return {};
};

export default validateAll;
