import React, { memo } from 'react';
import {
  shape, func, number, arrayOf, string, oneOfType, array, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Field from '../Field';
import Select from '../Select';
import IntlMessages from '../../util/IntlMessages';

const AttributeRowOptions = memo((props) => {
  const {
    options, row, filterRowIndex, productAttributes, selected, errors,
    loading, disabled, isParent, hasPermissions, onFilterValueChange, handleSearchOptions,
    loadOptions, parentOptions,
  } = props;

  const availableAttribute = props.productAttributes.find(
    p => p.value === row.field,
  ) || { frontendInputType: 'select', options: [] };

  const onAddFilterValueClick = () => {
    props.onAddFilterValueClick({
      row,
      filterRowIndex,
    });
  };

  let showAddFilterValueButton = true;
  let isMultiple = false;

  return (
    <div className="flex direction-column block">
      {
        !isEmpty(selected) && selected.map((s, optionIndex) => {
          const key = `filter_value_${optionIndex}_${row.field}`;

          const hasError = !isEmpty(errors) && errors.group[optionIndex]
            && errors.group[optionIndex].value;
          const valueHasErrorClass = hasError ? 'has-errors' : '';

          showAddFilterValueButton = availableAttribute.frontendInputType !== 'selectProductsButton';

          const prefix = (
            <Select
              className="select-prefix-wrapper select-clear preview-select"
              name="condition"
              value={row.condition}
              inputProps={{
                name: 'condition',
                id: 'condition-select',
              }}
              options={
                [
                  { value: 'or', label: 'Or' },
                  { value: 'and', label: 'And' },
                ] || []
              }
              onChange={() => props.onFilterConditionChange({
                filterRowIndex,
              })}
            />
          );

          const suffix = (
            <IconButton
              aria-label="Delete"
              className="btn-mui-sm text-danger"
              disabled={disabled}
              onClick={() => props.onDeleteFilterValueClick({
                  filterRowIndex,
                  optionToDeleteIndex: optionIndex,
                })
              }
            >
              <i className="ti-minus" />
            </IconButton>
          );

          let isMultipleValue = selected.flat();
          if (row.operator === 'in' || row.operator === 'nin') {
            isMultiple = true;
            isMultipleValue = !selected.map(o => !isEmpty(o)).some(o => o) ? [] : selected.flat();
          }

          const getInputType = (inputType) => {
            const type = inputType.startsWith('dropdown_')
              ? 'dropdown'
              : inputType;
            return type;
          };

          const singleValue = s && !isMultiple && String(s).startsWith('*')
            ? s.replace(/\*/g, '')
            : s;

          const fieldBody = {
            element: getInputType(availableAttribute.frontendInputType),
            type: availableAttribute.frontendInputType || availableAttribute.elementType,
            groupclass: 'pos-rel',
            className: `min-w-md ${isMultiple ? 'select-multiple-wrapper' : ''}`,
            selectClassName: `select-container ${valueHasErrorClass}`,
            name: 'value',
            fullWidth: true,
            label: optionIndex === 0 ? 'Product attribute' : null,
            value: isMultiple ? isMultipleValue : singleValue,
            multiple: isMultiple,
            inputProps: {
              name: 'value',
              id: 'attribute-value-select',
            },
            disabled: !row.field || loading || disabled,
            searchable: true,
            limitsToShow: 100,
            hideStaticLabel: true,
            prefix: optionIndex === 1 && prefix,
            suffix: optionIndex > 0 && suffix,
            error: hasError,
            helperText:
              hasError
                ? <IntlMessages id={errors.group[optionIndex].value} />
                : null,
          };

          const filterOperatorKey = row.operator;
          const productBtnSettings = {
            ...props.selectProductsSettings,
            filterOperatorKey,
          };
          const isExtraSearch = Boolean(
            productBtnSettings.search && productBtnSettings.search.suffix,
          );

          const targetList = row.operator === 'in'
            ? props.selectProductsSettings.listIn
            : props.selectProductsSettings.listNin;
          const selectedProductsIds = row.operator === 'in'
            ? props.selectProductsSettings.listInIds
            : props.selectProductsSettings.listNinIds;

          switch (getInputType(availableAttribute.frontendInputType)) {
            case 'selectProductsButton':
              return (
                <div key={`input_${key}`} className="pos-rel">
                  <Field
                    {...fieldBody}
                    {...productBtnSettings}
                    className={`${fieldBody.className} select-products-wrapper`}
                    disabled={isEmpty(row.operator) || disabled}
                    badge={!isEmpty(selectedProductsIds)}
                    targetList={targetList}
                    maxWidth="xl"
                    selectedProductsIds={selectedProductsIds}
                    productsFilterKey={row.operator === 'in'
                      ? 'productsByIdsForIn'
                      : 'productsByIdsForNin'}
                    search={{
                      suffix: isExtraSearch ? productBtnSettings.search.suffix : null,
                      className: isExtraSearch ? 'w-p-25 w-p-50-md-down' : 'block',
                      queryLengthLimit: 500,
                      errorMessage: 'text.errors.lessThan500Characters',
                      useExtraSearchButton: isExtraSearch,
                      searchButton: {
                        color: 'primary',
                        variant: 'outlined',
                        value: <IntlMessages id="selectProductsDialog.search.button.search" />,
                      },
                    }}
                  />
                </div>
              );

            case 'number':
            case 'slider':
            case 'text':
              return (
                <div
                  className={`pos-rel ${optionIndex > 0 ? 'mt-15' : ''} ${fieldBody.className}`}
                  key={`input_${key}`}
                >
                  <Field
                    {...fieldBody}
                    key={`input_${key}`}
                    element={availableAttribute.frontendInputType === 'slider' ? 'textField' : availableAttribute.frontendInputType}
                    type={availableAttribute.frontendInputType === 'slider' ? 'number' : availableAttribute.frontendInputType}
                    onChange={event => onFilterValueChange({
                      event,
                      filterRowIndex,
                      filterValueIndex: optionIndex,
                    })}
                  />
                </div>
              );

            case !isMultiple && 'select':
            case !isMultiple && 'dropdown':
              return (
                <Field
                  {...fieldBody}
                  loadOptions={loadOptions}
                  element="autocomplete"
                  key={`select_${key}`}
                  className={`select-autocomplete-wrapper ${fieldBody.className}`}
                  value={!isEmpty(options) ? options.find(el => el.value === fieldBody.value) : ''}
                  options={options}
                  limitsToShow={100}
                  input={<Input id="select-single" />}
                  handleSearchOptions={handleSearchOptions}
                  onBlur={props.onBlur}
                  onChange={event => onFilterValueChange({
                    event,
                    filterRowIndex,
                    filterValueIndex: optionIndex,
                  })}
                />
              );

            case isMultiple && 'select':
            case isMultiple && 'dropdown':
              return (
                <Field
                  {...fieldBody}
                  element="autocomplete-multiple"
                  loadOptions={loadOptions}
                  key={`select_${key}`}
                  className={`select-wrapper select-container block hide-arrow item-xs clear-abs hide-placeholder mt-17 ${fieldBody.className}`}
                  options={isParent === true ? parentOptions : options}
                  limitsToShow={100}
                  valueType="arrayOfString"
                  input={<Input id="select-multiple" />}
                  handleSearchOptions={handleSearchOptions}
                  onChange={event => props.onFilterValueChange({
                    event,
                    filterRowIndex,
                    filterValueIndex: optionIndex,
                  })}
                />
              );

            default:
              return (
                <Field
                  {...fieldBody}
                  key={`select_${key}`}
                  className={`select-wrapper select-container block ${optionIndex > 0 ? 'mt-15' : ''} ${fieldBody.className}`}
                  options={options}
                  limitsToShow={100}
                  onChange={event => props.onFilterValueChange({
                    event,
                    filterRowIndex,
                    filterValueIndex: optionIndex,
                  })}
                />
              );
          }
        })
      }
      {showAddFilterValueButton && !isMultiple && hasPermissions && (
        <div className="mt-15 mb-15 text-right">
          <Field
            element="button"
            className="min-w-s"
            helperText={
              !productAttributes.find(aP => aP.value === row.field)
                ? <IntlMessages id="helper.selectAttributeBefore" />
                : null
            }
            settings={
              {
                disabled: !productAttributes.find(aP => aP.value === row.field) || disabled,
                value: <IntlMessages id="button.addFilterValue" />,
                type: 'primary',
                onClick: onAddFilterValueClick,
              }
            }
          />
        </div>
      )}
    </div>
  );
});

AttributeRowOptions.propTypes = {
  selected: arrayOf(oneOfType([string, array])),
  options: arrayOf(shape()),
  parentOptions: arrayOf(shape()),
  productAttributes: arrayOf(shape()),
  row: shape(),
  filterRowIndex: number,
  onFilterConditionChange: func,
  onDeleteFilterValueClick: func,
  onAddFilterValueClick: func,
  onFilterValueChange: func,
  errors: shape({
    value: string,
  }),
  selectProductsSettings: shape(),
  loading: bool,
  disabled: bool,
  isParent: bool,
  hasPermissions: bool,
  loadOptions: bool.isRequired,
  handleSearchOptions: func,
  onBlur: func.isRequired,
};

AttributeRowOptions.defaultProps = {
  selected: [],
  options: [],
  parentOptions: [],
  productAttributes: [],
  row: {},
  filterRowIndex: null,
  onDeleteFilterValueClick: null,
  onFilterConditionChange: null,
  onAddFilterValueClick: null,
  onFilterValueChange: null,
  errors: {},
  selectProductsSettings: {},
  loading: false,
  disabled: false,
  isParent: false,
  hasPermissions: false,
  handleSearchOptions: null,
};

export default AttributeRowOptions;
