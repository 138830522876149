/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useNavigationEffect = ({
  navigationItemId,
  navigationFetched,
  navigationUpdated,
  fetchNavigationVirtualFacetList,
  navigationVirtualFacetUpdated,
  navigationVirtualFacetDeleted,
  navigationVirtualFacetCreated,
  navigationVirtualFacetFatching,
}) => {
  useEffect(() => {
    if (
      (navigationFetched || navigationUpdated)
      && !navigationVirtualFacetFatching
    ) {
      const params = {
        filter: [
          {
            group: [
              {
                field: 'referenced_entity_id',
                value: navigationItemId,
                operator: 'eq',
              },
              {
                field: 'status',
                value: 'enabled',
                operator: 'eq',
              },
            ],
          },
        ],
        pagination: {
          page: 1,
        },
      };

      fetchNavigationVirtualFacetList(params);
    }
  }, [
    navigationItemId,
    navigationFetched,
    navigationVirtualFacetUpdated,
    navigationVirtualFacetDeleted,
    navigationVirtualFacetCreated,
  ]);
};

export default useNavigationEffect;
