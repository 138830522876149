import React from 'react';
import { TableCell } from '@material-ui/core';

export const Sorting = (props) => {

  const handleSorting = () => {
    const filter = props.condition.filter ? props.condition.filter : null;
    const existingSort = props.condition.sort ? props.condition.sort : [];

    let sortOrder = 'asc';
    if (existingSort[0] && existingSort[0].field === props.item) {
      sortOrder = (existingSort[0].order === 'asc') ? 'desc' : 'asc';
    }

    const sort = [ { field: props.item, order: sortOrder } ];

    props.getData({
      limit: props.itemsPerPage,
      offset: 0,
      filter,
      sort,
      marketplace: props.marketplace
    });
  };

  const existingSort = props.condition.sort ? props.condition.sort : [];

  let sortOrderArrow = '';
  if (existingSort[0] && existingSort[0].field === props.item) {
    if (existingSort[0].order === 'asc') {
      sortOrderArrow = '↓';
    } else {
      sortOrderArrow = '↑';
    }
  }

  return (
    <TableCell style={{ width: props.width }} onClick={() => handleSorting()}>
      <div
        className={'mapper-no-wrap ' + props.className}
        style={{ whiteSpace: 'nowrap' }}>
        { props.name }
        <span className="mapper-sort-arrow">
          {sortOrderArrow}
        </span>
      </div>
    </TableCell>
  );
};

export default Sorting;
