import React from 'react';
import { string, shape, number } from 'prop-types';

const Logo = ({
  className, width, height, fill, logo,
}) => (
  <div className={`logo-wrapper ${className}`}>
    {logo && <img className={`object-contain logo ${logo.className}`} src={logo.src} alt={logo.alt} />}
    {!logo && (
      <svg width={width} height={height} viewBox="0 0 115.6 108.93">
        <g
          fill={fill}
          fillRule="nonzero"
        >
          <path
            d="M49.38,108.93c-3.99,0-7.99-0.51-11.9-1.56c-9.37-2.51-17.72-7.56-24.14-14.61c-8.2-9-12.8-20.26-13.29-32.58    C0.02,59.4,0,58.6,0,57.8C0,25.93,25.93,0,57.8,0s57.8,25.93,57.8,57.8v35.12c0,5.41-2.76,10.32-7.39,13.13    c-4.62,2.81-10.25,3-15.05,0.51l-0.54-0.28l-11.78-8.99C71.78,104.89,60.59,108.93,49.38,108.93z M57.8,14.05    c-24.12,0-43.75,19.63-43.75,43.75c0,0.6,0.01,1.2,0.04,1.8c0.36,8.99,3.7,17.18,9.64,23.7c4.61,5.06,10.62,8.69,17.39,10.51    c10.49,2.82,21.99,0.06,30.78-7.35l8.49-7.17l19.58,14.94c0.32,0.07,0.64,0.02,0.94-0.17c0.42-0.25,0.63-0.63,0.63-1.12V57.8    C101.55,33.68,81.92,14.05,57.8,14.05z"
          />
          <path
            d="M55.05,29.86c-1.7,0-3.15-1.29-3.33-3.01c-0.19-1.84,1.15-3.48,2.99-3.67c2.3-0.24,4.65-0.24,6.98-0.01    c1.84,0.18,3.19,1.82,3.01,3.66c-0.18,1.84-1.78,3.18-3.66,3.01c-1.88-0.18-3.78-0.18-5.65,0.01    C55.28,29.85,55.17,29.86,55.05,29.86z"
          />
          <path
            d="M28.8,51.32c-0.35,0-0.7-0.05-1.05-0.17c-1.76-0.58-2.71-2.47-2.13-4.23c3.02-9.18,9.78-16.78,18.56-20.85    c1.68-0.78,3.67-0.05,4.45,1.63c0.78,1.68,0.05,3.67-1.63,4.45c-7.1,3.3-12.58,9.44-15.02,16.86    C31.52,50.43,30.21,51.32,28.8,51.32z"
          />
        </g>
      </svg>
    )}
  </div>
);

Logo.propTypes = {
  className: string,
  width: number,
  height: number,
  fill: string,
  logo: shape(),
};

Logo.defaultProps = {
  className: '',
  width: 20,
  height: 20,
  fill: '#bababa',
  logo: null,
};

export default Logo;
