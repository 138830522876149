/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import {
  func, string,
} from 'prop-types';
import { IconButton } from '@material-ui/core';

const DrawerHeader = memo((props) => {
  const { id, name, onClose } = props;

  return (
    <div className="preview-drawer-header">
      <span className="preview-drawer-header-title">{name}</span>
      <span className="preview-drawer-header-subtitle">{id}</span>
      <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
        <i className="ti-close" />
      </IconButton>
    </div>
  );
});

DrawerHeader.propTypes = {
  id: string,
  name: string,
  onClose: func.isRequired,
};

DrawerHeader.defaultProps = {
  id: '',
  name: '',
};

export default DrawerHeader;
