import React, { useState, Fragment } from 'react';
import { isEmpty } from 'lodash';
import {
  string, arrayOf, shape, oneOfType, node,
} from 'prop-types';
import {
  ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';


const Expansion = (props) => {
  const { panels, className } = props;
  const [expanded, setExpanded] = useState('panel0');

  const handleChange = panelName => (event, newExpanded) => {
    setExpanded(newExpanded ? panelName : false);
  };

  return !isEmpty(panels) && (
    <div className={`expansion-panel ${className}`}>
      {
        panels.map((panel, index) => (
          <Fragment key={`panel-${panels.indexOf(panel)}-${panel.titleId}`}>
            <ExpansionPanel square expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${index}d-content`}
                id={`panel${index}d-content`}
              >
                <div className="pane-title">
                  {panel.title}
                  {panel.badge && <div className="panel-badge">{panel.badge}</div>}
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {panel.details}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Fragment>
        ))
      }
    </div>
  );
};

Expansion.propType = {
  titleId: string,
  panels: arrayOf(shape({
    titleId: string,
    title: oneOfType([string, node]),
    details: oneOfType([string, node]),
  })),
};

Expansion.defaultTypes = {
  className: '',
  panels: [],
};

export default Expansion;
