import {
  fetchUsers,
  updateUsers,
  createUser,
  clearUserState,
  syncUsers,
  deleteUser,
  clearFromUserState,
} from '../../../actions/user';
import {
  fetchRootCategories,
  clearSearchListState as clearScopeCategorySearchState,
  fetchChildCategories as fetchChildScopeCategories,
  searchCategories as searchScopeCategories,
  onToggleScopeCategoryTreeCollapse,
  handleLoadScopeSubcategories,
  loadAllChildCategories,
  clearScopeCategoryTreeState,
} from '../../../actions/scopeCategory';
import {
  createNewAffiliate,
  createNewAffiliateUser,
  fetchTuneSettings,
} from '../../../actions/tune';
import { createInfluencer } from '../../../actions/influencer';
import { fetchSession } from '../../../actions/session';

export const mapStateToProps = state => ({
  user: state.user,
  session: state.session,
  virtualCategory: state.virtualCategory,
  scopeCategory: state.scopeCategory,
  tune: state.tune,
  influencer: state.influencer,
});

export const mapDispatchToProps = {
  fetchUsers,
  updateUsers,
  createUser,
  fetchRootCategories,
  clearScopeCategorySearchState,
  fetchChildScopeCategories,
  searchScopeCategories,
  onToggleScopeCategoryTreeCollapse,
  handleLoadScopeSubcategories,
  loadAllChildCategories,
  fetchSession,
  clearScopeCategoryTreeState,
  clearUserState,
  syncUsers,
  deleteUser,
  clearFromUserState,
  createNewAffiliate,
  createNewAffiliateUser,
  createInfluencer,
  fetchTuneSettings,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
