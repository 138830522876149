import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import { CLEAR_SESSION_STATE } from '../actions/types';

const initialState = {
  item: {},
};
const sessionRestTypes = restTypes('session');
const {
  fetchOneStart,
  fetchOneFinished,
} = sessionRestTypes;

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };
    case fetchOneFinished:
      const result = {
        ...state,
        ...restStates.fetchOneFinished,
        item: action.payload.user,
        roles: action.payload.roles,
      };
      if (!result.item.selectedZapCategories) {
        result.item['selectedZapCategories'] = [];
      }
      if (!result.item.selectedKspCategories) {
        result.item['selectedKspCategories'] = [];
      }
      if (!result.item.selectedPreviewProducts) {
        result.item['selectedPreviewProducts'] = [];
      }
      if (!result.item.selectedPimColumns) {
        result.item['selectedPimColumns'] = {
          parent: [],
          variation: [],
          merchant: [],
        };
      }
      return result;
    case CLEAR_SESSION_STATE:
      return {
        ...initialState,
        ...restInitState,
      };

    default: return state;
  }
};
