import React, { memo } from 'react';
import {
  func, shape, string, bool,
} from 'prop-types';
import { ImageSearchOutlined } from '@material-ui/icons';

import IntlMessages from '../../../../util/IntlMessages';

const Banner = memo((props) => {
  const {
    bannerUrl,
    alt,
    onBannerChange,
    bannerData,
    classNameSuffix,
    id,
    disabled,
  } = props;

  const uploadBannerContent = () => (
    <label className="banner-upload" htmlFor={id}>
      <ImageSearchOutlined fontSize="large" />
      <input
        onChange={onBannerChange}
        type="file"
        id={id}
        style={{ display: 'none' }}
        accept="image/*"
        disabled={disabled}
      />
    </label>
  );

  return (
    <div className={`influencers-banner-main ${classNameSuffix} banner relative`}>
      {
        bannerData.url || bannerUrl ? (
          <>
            <img
              alt={alt}
              src={bannerData.url || bannerUrl}
              className="influencers-banner-main-img"
            />
            {uploadBannerContent()}
          </>
        ) : (
          <>
            <div className="influencers-banner-empty">
              <IntlMessages id="influencer.mainBanner.noImage" />
            </div>
            {uploadBannerContent()}
          </>
        )
      }
    </div>
  );
});

Banner.propTypes = {
  alt: string,
  classNameSuffix: string,
  id: string,
  bannerUrl: string,
  bannerData: shape().isRequired,
  onBannerChange: func.isRequired,
  disabled: bool.isRequired,
};

Banner.defaultProps = {
  bannerUrl: '',
  alt: '',
  id: '',
  classNameSuffix: 'main',
};

export default Banner;
