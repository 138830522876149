import { useState } from 'react';

export const useStateHook = () => {
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(null);
  const [showScopeDialog, setShowScopeDialog] = useState(false);
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [closeAfterUpdate, setCloseAfterUpdate] = useState(false);
  const [usersChanged, setUsersChanged] = useState({});
  const [checkedFormattedCategories, setCheckedFormattedCategories] = useState([]);
  const [notificationError, setNotificationError] = useState('');
  const [notificationSuccess, setNotificationSuccess] = useState('');
  const [newUserForm, setNewUserForm] = useState({});
  const [newUserErrors, setNewUserErrors] = useState({});
  const [alertSettings, setAlertSettings] = useState({
    title: 'alert.sureTitle',
    confirmBtnText: 'alert.leaveEditSystemPageTitle',
    body: 'alert.leaveEditSystemPageBody',
  });
  const [selectedTreeItems, setSelectedTreeItems] = useState([]);
  const [closeAfterSave, setCloseAfterSave] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [managedUser, setManagedUser] = useState({});
  const [companyName, setCompanyName] = useState('');

  const state = {
    errors,
    users,
    userId,
    usersChanged,
    showScopeDialog,
    showAlert,
    alertSettings,
    notificationError,
    notificationSuccess,
    closeAfterUpdate,
    checkedFormattedCategories,
    newUserForm,
    showAddUserDialog,
    newUserErrors,
    showLoader,
    selectedTreeItems,
    closeAfterSave,
    searchValue,
    managedUser,
    companyName,
  };

  const setState = {
    setErrors,
    setUsers,
    setUserId,
    setUsersChanged,
    setShowScopeDialog,
    setShowAlert,
    setAlertSettings,
    setNotificationError,
    setNotificationSuccess,
    setCloseAfterUpdate,
    setCheckedFormattedCategories,
    setNewUserForm,
    setShowAddUserDialog,
    setNewUserErrors,
    setShowLoader,
    setSelectedTreeItems,
    setCloseAfterSave,
    setSearchValue,
    setManagedUser,
    setCompanyName,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
