/* eslint-disable no-param-reassign */
import { camelize } from 'humps';
import { isEmpty } from 'lodash';
import config from '../../../../../util/config';

export const getPublishedPriceOffset = (price, rate, isDomestic = false) => {
  const MAX_PRICE_WITHOUT_TAX = 120;
  let vatAddition = 1;
  const conversionRate = rate || 1;
  if (isDomestic === false && price > MAX_PRICE_WITHOUT_TAX) vatAddition = 1.17;
  return conversionRate * vatAddition;
};

export const getPublishPrice = (price, rate, isDomestic = false) => {
  if (isDomestic !== true) {
    isDomestic = false;
  }
  const offset = getPublishedPriceOffset(price, rate, isDomestic);
  const newPrice = price * offset;
  return newPrice || '';
};

export const moneyFormat = (price, symbol) => {
  if ((price + '').indexOf(symbol) !== -1) {
    return price;
  }
  if (price === 0) {
    return symbol + '0.00';
  }
  else if (!price || price === '-') {
    return 'N/A';
  }
  let formattedPrice = parseFloat(price).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ").replace(' ', ',');
  return symbol + formattedPrice;
};

export const getPublishEditPrice = (price, rate, isDomestic = false) => {
  if (!parseFloat(price)) {
    return 'N/A';
  }
  const newPrice = getPublishPrice(price, rate, isDomestic);
  return Math.round(newPrice.toFixed(2)) || 'N/A';
};

const getExtraValue = (key, product, currency) => {
  const isDomestic = !!product.isDomestic;
  if (!isEmpty(product.price)) {
    const profitLost = (product.price.profit / product.price.cost) * 100;
    switch (key) {
      case 'ils':
        return `${(getPublishPrice(
          product.price.finalPrice, 
          currency.value, 
          isDomestic
        )).toFixed(2)} ${currency.symbols}`;
      case 'profitLost':
        return profitLost.toFixed(2);

      default:
        return '-';
    }
  }

  return '-';
};

const getValue = (p, o, currency) => p.reduce((xs, x) => (
  ((xs && xs[x]) || (xs && Number.isInteger(xs[x])))
    ? xs[x]
    : getExtraValue(x, o, currency)
), o);


export const generateParentProductData = (
  item,
  defaultImage,
  attributesOptions,
  attributes,
  currency,
) => {
  const imgData = item.images ? item.images[0] : defaultImage;
  const rootValues = !isEmpty(attributes)
    ? attributes.filter(attr => attr.enabled && getValue(
      camelize(attr.code).split('.'), item, currency,
    ) !== undefined)
    : [];
  const value = {
    id: item.id,
    imgData
  };

  rootValues.forEach((v) => {
    const itemValue = getValue(camelize(v.code).split('.'), item, currency);
    const specificationValue = attributesOptions.find(o => o.optionCode === itemValue);

    value[camelize(v.value)] = specificationValue
      ? specificationValue.label.en
      : itemValue;
  });

  if (value.isVisibleByCategory === true) {
    value.isVisibleByCategory = 'Yes';
  }
  if (value.isVisibleByCategory === false) {
    value.isVisibleByCategory = 'No';
  }

  return value;
};

export const generateVariantProductData = (item, defaultImage, certainProductAttributes) => {
  const { finalPrice } = item.price || {};

  let zapMinPrice = 'N/A';
  let zapMaxPrice = 'N/A';
  let zapUrl = 'N/A';

  if (item.priceEnrichment && item.priceEnrichment.competitors && item.priceEnrichment.competitors.zap) {
    if (item.priceEnrichment.competitors.zap.price) {
      zapMinPrice = item.priceEnrichment.competitors.zap.price;
    }
    if (zapMinPrice > 0) {
      if (item.priceEnrichment.competitors.zap.currency === 'ILS') {
        zapMinPrice = '₪' + zapMinPrice;
      } else {
        zapMinPrice = '$' + zapMinPrice;
      }
    }

    if (item.priceEnrichment.competitors.zap.maxPrice) {
      zapMaxPrice = item.priceEnrichment.competitors.zap.maxPrice;
    }
    if (zapMaxPrice > 0) {
      if (item.priceEnrichment.competitors.zap.currency === 'ILS') {
        zapMaxPrice = '₪' + zapMaxPrice;
      } else {
        zapMaxPrice = '$' + zapMaxPrice;
      }
    }
  }
  if (item.zmid) {
    zapUrl = config.zapWebsitePath.replace('%id%', item.zmid[0]);
  }

  const imgData = item.images
    ? item.images[0]
    : defaultImage;

  const value = {
    productId: item.id,
    status: item.status,
    imgData,
    items: item.stock ? item.stock.qty : 0,
    originPublishPrice: '-',
    publishPrice: item.frontendPrice ? item.frontendPrice.publishPrice : '-',
    price: `$${finalPrice || 0}`,
    zapMinPrice: zapMinPrice,
    zapMaxPrice: zapMaxPrice,
    zapUrl: zapUrl,
    lockedAttributes: item.lockedAttributes,
    pubAopi: item.pubAopi || 'N/A',
  };

  if (!isEmpty(item.upc)) {
    value.upc = item.upc.join(', ');
  } else {
    value.upc = '-';
  }

  if (!isEmpty(certainProductAttributes) && !isEmpty(item.variationAttributes)) {
    Object.keys(item.variationAttributes).forEach((attrKey) => {
      const currentAttribute = certainProductAttributes
        .find(attr => camelize(attr.code) === attrKey);

      if (Object.keys(item.variationAttributes).includes(`${attrKey}Orig`)) {
        item.variationAttributes[attrKey] = item.variationAttributes[`${attrKey}Orig`];
      }

      const val = item.variationAttributes[attrKey];

      if (currentAttribute) value[camelize(currentAttribute.label.en)] = val;
    });
  }

  return value;
};

export const generateMerchantProductData = (
  item,
  defaultImage,
  attributesOptions,
  attributes,
  currencyItem,
) => {
  const ils = item.price && item.price !== Infinity
    ? `$${item.price}`
    : '-';
  let imgData = item.parentImages && item.parentImages[0];
  imgData = imgData || (item.variationImages && item.variationImages[0]) || defaultImage;
  const rootValues = attributes
    .filter(attr => attr.enabled && getValue(
      camelize(attr.code).split('.'),
      item,
      currencyItem,
    ) !== undefined);
  const value = {
    id: item.id,
    imgData,
    ils,
  };

  rootValues.forEach((v) => {
    const itemValue = getValue(camelize(v.code).split('.'), item, currencyItem);
    const specificationValue = attributesOptions.find(o => o.optionCode === itemValue);

    value[camelize(v.value)] = specificationValue
      ? specificationValue.label.en
      : itemValue;
  });
  return value;
};
