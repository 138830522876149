import React from 'react';
import {
  string, shape, arrayOf, bool, func,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { Grid } from '@material-ui/core';

import { SortableElement } from 'react-sortable-hoc';
import VariantLevelImageViewItem from '../VariantLevelImageViewItem';
import { generateParentProductData } from '../../pages/Home/utils/productDataGenerator';

const SortableItem = SortableElement(props => <VariantLevelImageViewItem {...props} />);

const SortableList = (props) => {
  const {
    variants, disabled, defaultImage, certainProductAttributes,
    productsCommentsCount, sortedAttributes, productsImagesOverride,
    isEditMode, setUpdatedProductsImagesOverrides, parent, injectProducts,
  } = props;

  return (
    <div className="product-list">
      <Grid container spacing={0} className="justify-center">
        {variants && variants.map((item, index) => {
          const parentValue = generateParentProductData(parent, defaultImage, sortedAttributes);

          const title = !isEmpty(parentValue.title) ? parentValue.title : parent.title.en;
          const aoBrandName = parentValue.aoBrandName || item.brandName.en;

          const value = {
            id: item.id,
            title,
            aoBrandName,
          };

          return (
            <SortableItem
              key={item.id}
              index={index}
              variantValue={value}
              itemsCount={variants.length}
              disabled={disabled}
              variantProductItem={item}
              certainProductAttributes={certainProductAttributes}
              hasComments={productsCommentsCount.some(pcc => pcc.id === item.id)}
              fetchActivityLogsByProductId={props.fetchActivityLogsByProductId}
              productCommentsLoading={props.productCommentsLoading}
              productComments={props.productComments}
              isEditMode={isEditMode}
              sortedAttributes={sortedAttributes}
              setUpdatedProductsImagesOverrides={setUpdatedProductsImagesOverrides}
              productsImagesOverride={productsImagesOverride}
              injectProducts={injectProducts}
            />
          );
        })}
      </Grid>
    </div>
  );
};

SortableList.propTypes = {
  variants: arrayOf(shape()).isRequired,
  certainProductAttributes: arrayOf(shape()).isRequired,
  productsCommentsCount: arrayOf(shape()),
  productComments: arrayOf(shape()).isRequired,
  sortedAttributes: arrayOf(shape()).isRequired,
  productsImagesOverride: arrayOf(shape()).isRequired,
  parent: shape().isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  injectProducts: func.isRequired,
  defaultImage: string.isRequired,
  setUpdatedProductsImagesOverrides: func.isRequired,
  isEditMode: bool.isRequired,
  productCommentsLoading: bool.isRequired,
  disabled: bool.isRequired,
};

SortableList.defaultProps = {
  productsCommentsCount: [],
};

export default SortableList;
