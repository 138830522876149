import axios from 'axios';
import {
  createAffiliateCloudFuncPath,
  createAffiliateUserCloudFuncPath,
  createOfferCloudFuncPath,
  createGoalCloudFuncPath,
  generateTrackingLinkCloudFuncPath,
} from '../../util/paths';
import { showErrorNotification } from '../../util/api';

const getFBAuthHeader = () => {
  const token = localStorage.getItem('fb_token');
  const authHeader = {
    Authorization: `Bearer ${token}`,
  };
  return authHeader;
};

export const createAffiliate = async (query) => {
  try {
    const result = await axios.post(
      createAffiliateCloudFuncPath,
      query,
      {
        headers: {
          ...getFBAuthHeader(),
        },
      },
    );
    const { data } = result.data.result.response;

    return data.Affiliate;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const createAffiliateUser = async (query) => {
  try {
    const result = await axios.post(
      createAffiliateUserCloudFuncPath,
      query,
      {
        headers: {
          ...getFBAuthHeader(),
        },
      },
    );
    const { data } = result.data.result.response;

    return data.AffiliateUser;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const createOffer = async (query) => {
  try {
    const result = await axios.post(
      createOfferCloudFuncPath,
      query,
      {
        headers: {
          ...getFBAuthHeader(),
        },
      },
    );
    const { data } = result.data.result.response;

    return data.Offer;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const createGoal = async (query) => {
  try {
    const result = await axios.post(
      createGoalCloudFuncPath,
      query,
      {
        headers: {
          ...getFBAuthHeader(),
        },
      },
    );
    const { data } = result.data.result.response;

    return data.Goal;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const generateTrackingUrls = async (query) => {
  try {
    const result = await axios.post(
      generateTrackingLinkCloudFuncPath,
      query,
      {
        headers: {
          ...getFBAuthHeader(),
        },
      },
    );
    const { data } = result.data.result.response;

    return data;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};
