import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  PIM_SET_SELECTED_PRODUCTS,
  PIM_SET_PRODUCT_FOR_EDIT,
  PIM_CLEAR_PRODUCT_STATE,
  PIM_FETCH_PARENT_LEVEL_CHILDREN_PRODUCTS_FINISHED,
  PIM_FETCH_PARENT_LEVEL_CHILDREN_PRODUCTS_START,
  PIM_CLEAR_PARENT_LEVEL_CHILDREN_PRODUCTS,
  PIM_FETCH_VARIANT_LEVEL_CHILDREN_PRODUCTS_FINISHED,
  PIM_FETCH_VARIANT_LEVEL_CHILDREN_PRODUCTS_START,
  PIM_CLEAR_VARIANT_LEVEL_CHILDREN_PRODUCTS,
  PIM_SET_ALL_PRODUCTS_AS_SELECTED,
  PIM_FETCH_PRODUCT_BY_IDS_START,
  PIM_FETCH_PRODUCT_BY_IDS_FINISHED,
  PIM_SET_PRODUCTS_LIST_EMPTY,
  PIM_INJECT_UPDATED_PRODUCTS,
  PIM_FETCH_PRODUCTS_COMMENTS_COUNT_START,
  PIM_FETCH_PRODUCTS_COMMENTS_COUNT_FINISHED,
  PIM_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START,
  PIM_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED,
  PIM_SET_LIST_DIRECTION,
  PIM_SET_UPDATED_PRODUCTS_IMAGES_OVERRIDES,
  PIM_SET_FILTER_TARGET,
  PIM_FETCH_VARIANT_PARENTS_START,
  PIM_FETCH_VARIANT_PARENTS_FINISHED,
  PIM_SEARCH_PHYSICAL_CATEGORIES_BY_IDS_START,
  PIM_SEARCH_PHYSICAL_CATEGORIES_BY_IDS_FINISHED,
  PIM_SET_CONTAINER_STYLE,
  PIM_SET_GLOBAL_FILTERS,
  PIM_SET_GLOBAL_FIELDS,
  PIM_SET_IS_CUSTOM_FIELDS_MODE,
  PIM_SET_SEARCH_QUERY,
  PIM_SET_PAGINATION,
  PIM_SET_DISPLAY_MODE,
  PIM_SET_IS_EDIT_MODE,
  PIM_SET_SAVED_FILTER_FILTERS,
  PIM_SET_SAVED_FILTER_FIELDS,
  PIM_SET_FULL_TEXT_LIST_SEARCH,
  PIM_SET_SELECTED_ACTION,
  PIM_SET_ORDER,
  PIM_CLEAR_SELECTED,
  PIM_CLEAR_PRODUCT_LIST,
  PIM_REMOVE_PRODUCTS_IMAGES_START,
  PIM_REMOVE_PRODUCTS_IMAGES_FINISHED,
  PIM_SET_PRICE_FORMULA_MODAL,
  PIM_SET_PRODUCTS_WITHOUT_IMAGES,
  PIM_SET_HELPER_TEXT,
  PIM_SET_PAGINATION_HELPER_TEXT_ID,
  PIM_SET_SELECTED_PRODUCT,
  PIM_SET_SEARCH_VALUE,
  PIM_SET_ROOT_FILTER,
  PIM_SET_MAPPER_DATA,
  PIM_SET_RULES_DATA,
  PIM_SET_VIDEOS_ENRICHMENT_DATA,
  PIM_SET_MERCHANT_ACTIVITY_DIALOG,
  PIM_SET_SHOW_ZAP_INFO,
  PIM_SET_ACTIVITY_LOGS_FILTER,
  PIM_SET_ACTIVITY_LOGS_CUSTOM_FILTER,
  PIM_SET_ACTIVITY_LOGS_SORTS,
  SET_REPRICER_DATA,
  PIM_SET_ACTIVITY_LOGS_MERCHANT,
} from '../actions/types';
import updateProductInList from '../pages/Pim/utils/updateProductInList';
import { emptySearchState } from '../pages/Pim/utils/sidebar';

const initialState = {
  list: [],
  selected: [],
  editInProgress: false,
  nextProductLoading: false,
  pimOptions: [],
  edit: {},
  info: {},
  dir: 'ltr',
  facets: [],
  defaultImage: 'http://www.netzerotools.com/assets/images/msa-10162695-workman-arc-flash-full-body-harness.png',
  productsByIds: [],
  fetchProductsByIdsStarted: false,
  fetchProductsByIdsFinished: false,
  productsByIdsForIn: [],
  productsByIdsForNin: [],
  fetchProductsCommentsCountStart: false,
  fetchProductsCommentsCountFinished: false,
  productsCommentsCount: [],
  updatedProduct: {},
  fetchProductCommentsStart: false,
  fetchProductCommentsFinished: false,
  productComments: [],
  productsImagesOverride: [],
  bulkUpdateActivityLogsStart: false,
  bulkUpdateActivityLogsFinished: false,
  fetchVariationsCommentsCountStart: false,
  fetchVariationsCommentsCountFinished: true,
  variationsCommentsCount: {},
  fetchMerchantsCommentsCountStart: false,
  fetchMerchantsCommentsCountFinished: true,
  merchantsCommentsCount: {},
  filterTarget: sessionStorage.getItem('pim.filterTarget') || 'parent',
  prevFilterTarget: '',
  fetchParentLevelChildrenProductsStarted: false,
  fetchParentLevelChildrenProductsFinished: false,
  fetchVariantLevelChildrenProductsStarted: false,
  fetchVariantLevelChildrenProductsFinished: false,
  bulkDeleteProductLockedAttributesFinished: false,
  bulkDeleteProductLockedAttributesStarted: false,
  bulkDeleteSucess: false,
  bulkDeleteProductLockedAttributesError: '',
  lastParentChildrenLoaded: '',
  productsPhysicalCategoryList: [],
  containerStyles: {},
  globalFilters: emptySearchState,
  globalFields: emptySearchState,
  activityLogFilters: [],
  activityLogCustomFilter: [],
  activityLogSorts: [{
    field: 'created_at',
    order: 'desc',
  }],
  isCustomFieldsMode: false,
  searchQuery: {},
  pagination: { page: 1, limit: 96 },
  displayMode: 'list',
  isEditMode: true,
  savedFilterFilters: emptySearchState,
  savedFilterFields: emptySearchState,
  fullTextListModeSearch: {},
  selectedAction: '',
  sort: emptySearchState,
  removeImagesStart: false,
  removeImagesFinished: false,
  productsWithoutImages: [],
  helperText: '',
  paginationHelperTextId: '',
  selectedProduct: {},
  searchValue: '',
  rootFilter: {},
  
  priceFormulaModal: {
    isOpen: false,
    loading: false,
    priceFormula: {}
  },

  mapper: {
    data: [],
    error: [],
    scroll: [],
    reset: false,
    categories: [],
    condition: [],
    itemsPerPage: 10,
    newRecordOpen: false,
    filterDisabled: false,
  },
  repricing: {
    data: [],
    scroll: [],
    reset: false,
    condition: [],
    itemsPerPage: 20,
    filterDisabled: false,
  },
  repricer: {
    data: [],
    scroll: [],
    reset: false,
    condition: [],
    itemsPerPage: 20,
    filterDisabled: false,
  },
  activityLog: {
    data: [],
    scroll: [],
    reset: false,
    condition: [],
    itemsPerPage: 20,
    filterDisabled: false,
  },
  rules: {
    data: [],
    rule: null,
    scroll: [],
    reset: false,
    condition: [],
    itemsPerPage: 20,
    formOpen: null,
    filterDisabled: false,
    pimPage: false,
  },

  merchantActivityDialog: false,

  zapInfo: {
    variationProduct: {},
    parentProduct: {},
    showZapInfo: false,
    competitors: {},
  }
  
};
const pim = restTypes('pim');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case pim.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case pim.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case PIM_SET_SELECTED_PRODUCTS:
      return {
        ...state,
        selected: action.payload,
      };
    case PIM_SET_PRODUCT_FOR_EDIT:
      return {
        ...state,
        ...action.payload,
      };
    case PIM_CLEAR_PRODUCT_STATE:
      return {
        ...restInitState,
        ...initialState,
        dir: state.dir,
      };
    case PIM_FETCH_PARENT_LEVEL_CHILDREN_PRODUCTS_START:
      return {
        ...state,
        ...action.payload,
        fetchParentLevelChildrenProductsStarted: true,
        fetchParentLevelChildrenProductsFinished: false,
      };
    case PIM_FETCH_PARENT_LEVEL_CHILDREN_PRODUCTS_FINISHED:
      return {
        ...state,
        ...action.payload,
        fetchParentLevelChildrenProductsStarted: false,
        fetchParentLevelChildrenProductsFinished: true,
      };
    case PIM_CLEAR_PARENT_LEVEL_CHILDREN_PRODUCTS:
      return {
        ...state,
        ...action.payload,
      };
    case PIM_FETCH_VARIANT_LEVEL_CHILDREN_PRODUCTS_START:
      return {
        ...state,
        ...action.payload,
        fetchVariantLevelChildrenProductsStarted: true,
        fetchVariantLevelChildrenProductsFinished: false,
      };
    case PIM_FETCH_VARIANT_LEVEL_CHILDREN_PRODUCTS_FINISHED:
      return {
        ...state,
        ...action.payload,
        fetchVariantLevelChildrenProductsStarted: false,
        fetchVariantLevelChildrenProductsFinished: true,
      };
    case PIM_CLEAR_VARIANT_LEVEL_CHILDREN_PRODUCTS:
      return {
        ...state,
        ...action.payload,
      };
    case PIM_SET_ALL_PRODUCTS_AS_SELECTED:
      return {
        ...state,
        ...action.payload,
      };
    case pim.updateMultipleStart:
      return {
        ...state,
        ...restStates.updateMultipleStart,
      };
    case pim.updateMultipleFinished:
      return {
        ...state,
        ...restStates.updateMultipleFinished,
        ...action.payload,
      };
    case pim.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case PIM_FETCH_PRODUCT_BY_IDS_START:
      return {
        ...state,
        ...action.payload,
        fetchProductsByIdsStarted: true,
        fetchProductsByIdsFinished: false,
      };
    case PIM_FETCH_PRODUCT_BY_IDS_FINISHED:
      return {
        ...state,
        ...action.payload,
        fetchProductsByIdsStarted: false,
        fetchProductsByIdsFinished: true,
      };
    case PIM_SET_PRODUCTS_LIST_EMPTY:
      return {
        ...state,
        list: [],
        total: 0,
        fetching: false,
        fetched: false,
      };
    case PIM_INJECT_UPDATED_PRODUCTS:
      return {
        ...state,
        ...action.payload,
      };
    case PIM_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START:
      return {
        ...state,
        ...action.payload,
      };
    case PIM_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case PIM_FETCH_PRODUCTS_COMMENTS_COUNT_START:
      return {
        ...state,
        ...action.payload,
        fetchProductsCommentsCountStart: true,
        fetchProductsCommentsCountFinished: false,
      };
    case PIM_FETCH_PRODUCTS_COMMENTS_COUNT_FINISHED:
      return {
        ...state,
        ...action.payload,
        fetchProductsCommentsCountStart: false,
        fetchProductsCommentsCountFinished: true,
      };
    case PIM_SET_LIST_DIRECTION:
      return {
        ...state,
        ...action.payload,
      };
    case PIM_SET_UPDATED_PRODUCTS_IMAGES_OVERRIDES:
      return {
        ...state,
        ...action.payload,
      };
    case PIM_SET_FILTER_TARGET:
      return {
        ...state,
        ...action.payload,
      };
    case PIM_FETCH_VARIANT_PARENTS_START:
    case PIM_FETCH_VARIANT_PARENTS_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case PIM_SEARCH_PHYSICAL_CATEGORIES_BY_IDS_START:
    case PIM_SEARCH_PHYSICAL_CATEGORIES_BY_IDS_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case PIM_SET_CONTAINER_STYLE:
      return {
        ...state,
        ...action.payload,
      };
    case PIM_SET_MERCHANT_ACTIVITY_DIALOG:
    case PIM_SET_SHOW_ZAP_INFO:
    case PIM_SET_GLOBAL_FILTERS:
    case PIM_SET_GLOBAL_FIELDS:
    case PIM_SET_IS_CUSTOM_FIELDS_MODE:
    case PIM_SET_SEARCH_QUERY:
    case PIM_SET_PAGINATION:
    case PIM_SET_DISPLAY_MODE:
    case PIM_SET_IS_EDIT_MODE:
    case PIM_SET_SAVED_FILTER_FILTERS:
    case PIM_SET_SAVED_FILTER_FIELDS:
    case PIM_SET_FULL_TEXT_LIST_SEARCH:
    case PIM_SET_SELECTED_ACTION:
    case PIM_SET_ORDER:
    case PIM_CLEAR_SELECTED:
    case PIM_CLEAR_PRODUCT_LIST:
    case PIM_REMOVE_PRODUCTS_IMAGES_START:
    case PIM_REMOVE_PRODUCTS_IMAGES_FINISHED:
    case PIM_SET_PRODUCTS_WITHOUT_IMAGES:
    case PIM_SET_PRICE_FORMULA_MODAL:
    case PIM_SET_HELPER_TEXT:
    case PIM_SET_PAGINATION_HELPER_TEXT_ID:
    case PIM_SET_SELECTED_PRODUCT:
    case PIM_SET_SEARCH_VALUE:
    case PIM_SET_ROOT_FILTER:
    case PIM_SET_MAPPER_DATA:
    case PIM_SET_RULES_DATA:
    case PIM_SET_VIDEOS_ENRICHMENT_DATA:
    case PIM_SET_ACTIVITY_LOGS_FILTER:
    case PIM_SET_ACTIVITY_LOGS_CUSTOM_FILTER:
    case PIM_SET_ACTIVITY_LOGS_SORTS:
    case PIM_SET_ACTIVITY_LOGS_MERCHANT:
    case SET_REPRICER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case restTypes('product').updateFinished: {
      const editedProd = action.payload.edit;
      const list = updateProductInList(state.list, editedProd);
      return {
        ...state,
        list,
      };
    }
    default: return state;
  }
};
