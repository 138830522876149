import { createSelector } from 'reselect';

const productRootSelector = state => state;

const stateSelector = createSelector(
  productRootSelector,
  state => ({
    updating: state.updating,
    editInProgress: state.editInProgress,
    nextProductLoading: state.nextProductLoading,
    pimOptions: state.nextProductLoading,
    item: state.item,
    info: state.info,
    bulkUpdateActivityLogsStart: state.bulkUpdateActivityLogsStart,
  }),
);

export const productSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  productSelector,
};
