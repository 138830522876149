import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import RestActions from '../util/rest/actions';
import { showErrorNotification } from '../util/api';

import {
  virtualFacetPath,
  virtualFacetSearchPath,
  getVirtualFacetPath,
} from '../util/paths';

import {
  SET_PREVIEW_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE,
} from './types';

const restPreviewCategoryVirtualFacetActions = new RestActions('preview_category_virtual_facet');

export const fetchPreviewCategoryVirtualFacet = id => (dispatch) => {
  dispatch(restPreviewCategoryVirtualFacetActions.fetchStart());
  return axios.get(getVirtualFacetPath(id)).then((res) => {
    dispatch(restPreviewCategoryVirtualFacetActions.fetchFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restPreviewCategoryVirtualFacetActions.fetchFinished({
      error: error.response.data,
      hasError: true,
      item: {},
    }));
  });
};

export const fetchPreviewCategoryVirtualFacetList = params => (dispatch) => {
  dispatch(restPreviewCategoryVirtualFacetActions.fetchAllStart());
  return axios.post(virtualFacetSearchPath, params).then((res) => {
    dispatch(restPreviewCategoryVirtualFacetActions.fetchAllFinished({
      list: camelizeKeys(res.data.data),
      origList: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restPreviewCategoryVirtualFacetActions.fetchAllFinished({
      error: error.response.data,
      hasError: true,
      list: [],
      origList: [],
    }));
  });
};

export const deletePreviewCategoryVirtualFacet = (id, isSync = true) => (dispatch) => {
  const transformedPath = isSync
    ? `${getVirtualFacetPath(id)}?is_synchronous=${isSync}`
    : getVirtualFacetPath(id);
  dispatch(restPreviewCategoryVirtualFacetActions.deleteStart());
  return axios.delete(transformedPath).then(() => {
    dispatch(
      restPreviewCategoryVirtualFacetActions.deleteFinished({ lastDeletedVirtualFacetId: id }),
    );
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restPreviewCategoryVirtualFacetActions.deleteFinished({
      error: error.response.data,
      hasError: true,
      lastDeletedVirtualFacetId: '',
    }));
  });
};

export const updatePreviewCategoryVirtualFacet = (
  facetId, payload, isSync = true,
) => (dispatch) => {
  const transformedPath = isSync
    ? `${getVirtualFacetPath(facetId)}?is_synchronous=${isSync}`
    : getVirtualFacetPath(facetId);
  const body = {
    data: payload,
  };
  dispatch(restPreviewCategoryVirtualFacetActions.updateStart());
  return axios.put(transformedPath, decamelizeKeys(body)).then((res) => {
    dispatch(restPreviewCategoryVirtualFacetActions.updateFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restPreviewCategoryVirtualFacetActions.updateFinished({
      error: error.response.data,
      hasError: true,
    }));
  });
};

export const createPreviewCategoryVirtualFacet = payload => (dispatch) => {
  const body = {
    data: payload,
  };
  dispatch(restPreviewCategoryVirtualFacetActions.createStart());
  return axios.post(virtualFacetPath, decamelizeKeys(body)).then((res) => {
    dispatch(restPreviewCategoryVirtualFacetActions.createFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restPreviewCategoryVirtualFacetActions.createFinished({
      error: error.response.data,
      hasError: true,
    }));
  });
};

export const setPreviewCategoryVirtualFacetToDeleteId = id => (dispatch) => {
  dispatch({
    type: SET_PREVIEW_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE,
    payload: {
      virtualFacetIdToDelete: id,
    },
  });
};

export const setPreviewCategoryVirtualFacetItem = facet => (dispatch) => {
  dispatch(restPreviewCategoryVirtualFacetActions.updateStart());
  dispatch(restPreviewCategoryVirtualFacetActions.updateFinished({ item: facet }));
};

export const setPreviewCategoryVirtualFacetList = list => (dispatch) => {
  dispatch(restPreviewCategoryVirtualFacetActions.fetchAllStart());
  dispatch(restPreviewCategoryVirtualFacetActions.fetchAllFinished({ list }));
};

export const clearPreviewCategoryVirtualFacet = () => (dispatch) => {
  dispatch(restPreviewCategoryVirtualFacetActions.clearStateFinished());
};
