import { useState } from 'react';

export const useStateHook = () => {
  const [filters, setFilters] = useState([]);
  const [isSwitchOn, setIsSwitchOn] = useState(true);

  const state = {
    filters,
    isSwitchOn,
  };

  const setState = {
    setFilters,
    setIsSwitchOn,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
