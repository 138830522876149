import { createSelector } from 'reselect';

const productAttributeRootSelector = state => state;

const stateSelector = createSelector(
  productAttributeRootSelector,
  productAttribute => ({
    initialOptions: productAttribute.initialOptions,
    foundOptions: productAttribute.foundOptions,
    selectedAttributesOptions: productAttribute.selectedAttributesOptions,
    searchAttributeOptionsStart: productAttribute.searchAttributeOptionsStart,
    fetchInitialAttributesOptionsFinished: productAttribute.fetchInitialAttributesOptionsFinished,
    fetchInitialAttributesOptionsStart: productAttribute.fetchInitialAttributesOptionsStart,
    productAttributes: productAttribute.productAttributes,
    attributeSets: productAttribute.attributeSets,
    fetchingAttributeOptions: productAttribute.fetchingAttributeOptions,
    optionsLoadingCode: productAttribute.optionsLoadingCode,
    productAttributesFetching: productAttribute.productAttributesFetching,
    productAttributesForVariations: productAttribute.productAttributesForVariations,
  }),
);

export const productAttributeSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  productAttributeSelector,
};
