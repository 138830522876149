import axios from 'axios';
import {
  isEmpty, uniq, difference, uniqBy, cloneDeep,
} from 'lodash';
import { setIsLoading } from './global';
import queryString from 'query-string';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { showErrorNotification } from '../util/api';
import RestActions from '../util/rest/actions';
import {
  getProductsAppPath,
  productsSearchAppPath,
  productsBulkAppPath,
  productsFrontAppPath,
  getDeleteProductLockedAttributesPath,
  physicalCategoriesSearchPath,
  getDeleteProductImagesPath,
} from '../util/paths';
import { PMACClient } from '../util/api';
import { CDMSClient } from '../util/api';
import { IMSClient } from '../util/api'

import {
  cdmsGetProduct,
  imsUnlockAttributes,
} from './product';

import {
  getValueByOperator,
  getFieldByOperator,
} from '../util/mapFiltersByOperator';

import {
  getCountProductsActivitiesByProductIds,
} from '../services/API/activityLog';

import {
  PIM_SET_SELECTED_PRODUCTS,
  PIM_SET_PRODUCT_FOR_EDIT,
  PIM_CLEAR_PRODUCT_STATE,
  PIM_FETCH_PARENT_LEVEL_CHILDREN_PRODUCTS_START,
  PIM_FETCH_PARENT_LEVEL_CHILDREN_PRODUCTS_FINISHED,
  PIM_CLEAR_PARENT_LEVEL_CHILDREN_PRODUCTS,
  PIM_FETCH_VARIANT_LEVEL_CHILDREN_PRODUCTS_START,
  PIM_FETCH_VARIANT_LEVEL_CHILDREN_PRODUCTS_FINISHED,
  PIM_CLEAR_VARIANT_LEVEL_CHILDREN_PRODUCTS,
  PIM_SET_ALL_PRODUCTS_AS_SELECTED,
  PIM_SET_PRODUCTS_LIST_EMPTY,
  PIM_INJECT_UPDATED_PRODUCTS,
  PIM_FETCH_PRODUCTS_COMMENTS_COUNT_START,
  PIM_FETCH_PRODUCTS_COMMENTS_COUNT_FINISHED,
  PIM_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START,
  PIM_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED,
  PIM_SET_LIST_DIRECTION,
  PIM_SET_UPDATED_PRODUCTS_IMAGES_OVERRIDES,
  PIM_SET_FILTER_TARGET,
  PIM_FETCH_VARIANT_PARENTS_START,
  PIM_FETCH_VARIANT_PARENTS_FINISHED,
  PIM_SEARCH_PHYSICAL_CATEGORIES_BY_IDS_START,
  PIM_SEARCH_PHYSICAL_CATEGORIES_BY_IDS_FINISHED,
  PIM_SET_CONTAINER_STYLE,
  PIM_SET_GLOBAL_FILTERS,
  PIM_SET_GLOBAL_FIELDS,
  PIM_SET_IS_CUSTOM_FIELDS_MODE,
  PIM_SET_SEARCH_QUERY,
  PIM_SET_PAGINATION,
  PIM_SET_DISPLAY_MODE,
  PIM_SET_IS_EDIT_MODE,
  PIM_SET_SAVED_FILTER_FILTERS,
  PIM_SET_SAVED_FILTER_FIELDS,
  PIM_SET_FULL_TEXT_LIST_SEARCH,
  PIM_SET_SELECTED_ACTION,
  PIM_SET_ORDER,
  PIM_CLEAR_SELECTED,
  PIM_CLEAR_PRODUCT_LIST,
  PIM_REMOVE_PRODUCTS_IMAGES_START,
  PIM_REMOVE_PRODUCTS_IMAGES_FINISHED,
  PIM_SET_PRODUCTS_WITHOUT_IMAGES,
  PIM_SET_PRICE_FORMULA_MODAL,
  PIM_SET_HELPER_TEXT,
  PIM_SET_PAGINATION_HELPER_TEXT_ID,
  PIM_SET_SELECTED_PRODUCT,
  PIM_SET_SEARCH_VALUE,
  PIM_SET_ROOT_FILTER,
  PIM_SET_MAPPER_DATA,
  PIM_SET_MERCHANT_ACTIVITY_DIALOG,
  PIM_SET_SHOW_ZAP_INFO,
  PIM_SET_VIDEOS_ENRICHMENT_DATA,
  PIM_SET_ACTIVITY_LOGS_MERCHANT,
} from './types';

import { getBackendProductsByIds } from '../services/API/product';

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';
export const mappingCategoriesResult = (place) =>
  PMACClient.get('/mapping_categories_tree?marketplace=' + place);
export const pmacGetCompetitors = (data) =>
  PMACClient.post('/competitors/search', data);
export const pmacGetCompetitorsPriceLog = (data) =>
  PMACClient.post('/competitors_price_log/search', data);
export const productsEnrichmentDataResult = (data) =>
  CDMSClient.post('/v2/products_enrichment_data/search?ref=' + ref, data);
export const exportProducts = condition =>
  CDMSClient.post('/products/export', condition);
export const activityLogResult = condition =>
  CDMSClient.post('/activity_log/search?ref=' + ref, condition);
export const categoriesExport = () =>
  CDMSClient.get('/virtual_categories_tree/export?expand=3');
export const productSearch = (url, data) =>
  CDMSClient.post(url, data);

export const imsProductLogs = (data) => IMSClient.post('/product_logs/search', data);

const pimActions = new RestActions('pim');

const generateRequestBody = ({
  viewMode, filter, fields, pagination,
  fullText, searchFilter,
}) => {
  const mappedSearchParams = !isEmpty(searchFilter)
    ? `?${queryString.stringify(decamelizeKeys(searchFilter))}` : '';
  const params = {
    path: '',
    body: {
      pagination,
    },
  };

  const modifiedFilter = [];
  for (let i = 0; i < filter.length; i++) {
    const group = filter[i].group ? filter[i].group[0] : [];
    const field = group.field.toString();
    const operator = group.operator.toString();
    if ((field === 'created_at' || field === 'updated_at') && operator === 'eq') {
      const dateValue = group.value.toString().substr(0, 10);
      modifiedFilter[i] = {
        condition: filter[i].condition,
        group: [
          { field: field, value: dateValue + ' 00:00:00', operator: 'gte' },
          { field: field, value: dateValue + ' 23:59:59', operator: 'lte' },
        ]
      };
    } else {
      modifiedFilter[i] = filter[i];
    }
  }

  switch (viewMode) {
    case 'frontend':
      params.path = `${productsFrontAppPath}/search${mappedSearchParams}`;
      break;
    case 'frontendWithParams':
      params.body.filter = modifiedFilter;
      params.path = `${productsFrontAppPath}/search${mappedSearchParams}`;
      break;
    default:
      params.path = `${productsSearchAppPath}${mappedSearchParams}`;
      params.body.filter = modifiedFilter;
      break;
  }

  if (!isEmpty(fullText)) params.body.fullText = fullText;
  if (!isEmpty(fields)) params.body.fields = fields;

  return params;
};

export const fetchProductList = ({
  viewMode,
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  sort = [],
  fullText = {},
  compoundSearch = null,
  isExport = false,
  exportEmail = null,
}) => async (dispatch, getState) => {
  const { list } = getState().previewFilterAttribute;
  if (!filter) {
    filter = [];
  }
  const mappedFilters = filter.map((f) => {
    const group = f.group.map(g => ({
      ...g,
      value: getValueByOperator({ value: g.value, operator: g.operator }),
      field: getFieldByOperator({
        field: g.field, operator: g.operator, previewFilterAttribute: list,
      }),
    }));

    return {
      ...f,
      group,
    };
  });

  const params = {
    ...generateRequestBody({
      viewMode,
      filter: mappedFilters,
      fields,
      pagination,
      fullText,
      searchFilter,
    }),
  };

  if (compoundSearch && !isEmpty(compoundSearch) && compoundSearch.value) {
    params.body.compoundSearch = compoundSearch;
  }

  if (!isEmpty(sort)) {
    params.body.sort = sort;
  }

  if (isExport === true) {
    const filter = decamelizeKeys(params.body);
    const condition = {
      email: exportEmail,
      filter: filter.filter,
    };
    exportProducts(condition);
  } else {
    dispatch(pimActions.fetchAllStart());

    try {
      const path = params.path.indexOf('?') === -1 ? params.path + '?ref=' + ref : params.path + '&ref=' + ref;
      const res = await productSearch(path, decamelizeKeys(params.body));

      const {
        pages, total, aggregation,
      } = res.data;

      const result = res.data.data.map((p) => {
        if (p.variants) {
          return {
            ...p,
            variants: Object.keys(p.variants).map((v) => {
              const item = p.variants[v];
              return ({
                ...item,
                id: v,
              });
            }),
          };
        }
        return p;
      });

      dispatch(pimActions.fetchAllFinished(
        {
          list: camelizeKeys(result),
          origList: camelizeKeys(result),
          aggregation,
          pages,
          total,
          updated: false,
          updating: false,
        },
      ));
    } catch (error) {
      showErrorNotification(error, 'CDMS');
    }
  }
};

export const toggleSelectedProduct = payload => (dispatch, getState) => {
  const { selected } = getState().pim;
  const clonnedSelectedList = cloneDeep(selected);
  const selectedProducts = clonnedSelectedList.some(s => s.id === payload.id)
    ? clonnedSelectedList.filter(s => s.id !== payload.id)
    : [
      ...clonnedSelectedList,
      cloneDeep(payload),
    ];

  dispatch({
    type: PIM_SET_SELECTED_PRODUCTS,
    payload: selectedProducts,
  });
};


export const setSelectedProducts = payload => (dispatch) => {
  dispatch({
    type: PIM_SET_SELECTED_PRODUCTS,
    payload,
  });
};

export const setAllProductsAsSelected = products => (dispatch, getState) => {
  const { selected } = getState().pim;
  const allProductsAreSelected = products.every(p => selected.some(pr => pr.id === p.id));
  const payload = {
    selected: allProductsAreSelected
      ? selected.filter(s => products.every(p => p.id !== s.id))
      : [...selected, ...products],
  };

  dispatch({
    type: PIM_SET_ALL_PRODUCTS_AS_SELECTED,
    payload,
  });
};

export const setProductForEdit = payload => (dispatch) => {
  dispatch({
    type: PIM_SET_PRODUCT_FOR_EDIT,
    payload,
  });
};

export const clearProductState = () => (dispatch) => {
  dispatch({ type: PIM_CLEAR_PRODUCT_STATE });
};

export const fetchParentLevelChildren = (childrenIds, parent) => async (dispatch, getState) => {
  if (parent.type === 'variation' && parent.childrenIds) {
    childrenIds = childrenIds.concat(parent.childrenIds);
  }

  const { list } = getState().pim;
  dispatch({
    type: PIM_FETCH_PARENT_LEVEL_CHILDREN_PRODUCTS_START,
    payload: { lastParentChildrenLoaded: parent.id },
  });

  const pagination = {
    limit: childrenIds.length,
  };

  const condition = childrenIds.length > 1 ? { condition: 'or' } : {};

  const filter = [{
    ...condition,
    group: [{
      field: 'id',
      value: uniq(childrenIds),
      operator: 'in',
    }],
  }];

  const sort = [{
    field: 'type',
    order: 'desc'
  }];

  const request = (parent.type === 'variation') ? { filter, sort, pagination } : { filter, pagination };

  try {
    const res = await productSearch(productsSearchAppPath + '?ref=' + ref, decamelizeKeys(request));
    const updatedList = list.map((product) => {
      let current = product;

      if (current.id === parent.id && res.data.data.length > 0) {
        current = {
          ...current,
          variants: res.data.data,
          variantsLoaded: true,
        };
      }
      return current;
    });

    const payload = {
      list: camelizeKeys(updatedList),
    };

    dispatch({
      type: PIM_FETCH_PARENT_LEVEL_CHILDREN_PRODUCTS_FINISHED, payload,
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const clearParentLevelChildren = (parent, list) => (dispatch) => {
  const updatedList = list.map((product) => {
    const current = product;

    if (current.id === parent.id) {
      delete current.variants;
    }
    return current;
  });

  dispatch({
    type: PIM_CLEAR_PARENT_LEVEL_CHILDREN_PRODUCTS,
    payload: { list: updatedList },
  });
};

export const fetchVariantLevelChildren = (childrenIds, variant) => async (dispatch, getState) => {
  const { list } = getState().pim;
  dispatch({
    type: PIM_FETCH_VARIANT_LEVEL_CHILDREN_PRODUCTS_START,
    payload: { lastParentChildrenLoaded: variant.id },
  });

  const pagination = {
    limit: childrenIds.length,
  };

  const condition = childrenIds.length > 1 ? { condition: 'or' } : {};

  const filter = [{
    ...condition,
    group: childrenIds.map((pId) => {
      const payload = {
        field: 'id',
        value: pId,
        operator: 'eq',
      };
      return payload;
    }),
  }];

  try {
    const res = await productSearch(
      productsSearchAppPath + '?ref=' + ref, decamelizeKeys({ filter, pagination })
    );

    const parent = list.find(product => product.id === variant.parentId);
    const updatedVariants = parent ? parent.variants.map((v) => {
      let currentVariant = v;

      if (currentVariant.id === variant.id) {
        currentVariant = {
          ...currentVariant,
          merchants: res.data.data,
          merchantsLoaded: true,
        };
      }

      return currentVariant;
    }) : [];

    const updatedParent = {
      ...parent,
      variants: updatedVariants,
    };

    const updatedList = list.map((product) => {
      let current = product;

      if (current.id === updatedParent.id && res.data.data.length > 0) {
        current = updatedParent;
      }

      return current;
    });

    const payload = {
      list: camelizeKeys(updatedList),
    };

    dispatch({
      type: PIM_FETCH_VARIANT_LEVEL_CHILDREN_PRODUCTS_FINISHED,
      payload,
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const fetchVariantParents = variant => (dispatch, getState) => {
  const { list } = getState().pim;

  dispatch({
    type: PIM_FETCH_VARIANT_PARENTS_START,
    payload: { lastParentChildrenLoaded: variant.id },
  });

  return axios.get(
    getProductsAppPath(variant.parentId),
  ).then((res) => {
    const result = camelizeKeys(res.data.data);
    const firstLevelItem = list.find(p => p.parentId === variant.id);

    const updatedVariants = firstLevelItem ? firstLevelItem.variants.map((v) => {
      let currentVariant = v;

      if (currentVariant.id === variant.id) {
        currentVariant = {
          ...currentVariant,
          merchants: [result],
          merchantsLoaded: true,
        };
      }

      return currentVariant;
    }) : [];

    const updatedParent = {
      ...firstLevelItem,
      variants: updatedVariants,
    };

    const updatedList = list.map((product) => {
      let current = product;

      if (current.id === updatedParent.id && !isEmpty(res.data.data)) {
        current = updatedParent;
      }

      return current;
    });

    const payload = {
      list: updatedList,
    };

    dispatch({
      type: PIM_FETCH_VARIANT_PARENTS_FINISHED,
      payload,
    });
  }).catch(error => {
    showErrorNotification(error, 'CDMS');
  });
};

export const clearVariantLevelChildren = (variant, list) => (dispatch) => {
  const parent = list.find(product => product.id === variant.parentId);

  const updatedVariants = parent.variants.map((v) => {
    const currentVariant = v;

    if (currentVariant.id === variant.id) {
      delete currentVariant.merchants;
    }

    return currentVariant;
  });

  const updatedParent = {
    ...parent,
    variants: updatedVariants,
  };

  const updatedList = list.map((product) => {
    const current = product;

    if (current.id === updatedParent.id) {
      delete current.merchants;
    }
    return current;
  });

  dispatch({
    type: PIM_CLEAR_VARIANT_LEVEL_CHILDREN_PRODUCTS,
    payload: { list: updatedList },
  });
};

export const clearFromProductState = payload => (dispatch) => {
  dispatch(pimActions.clearFromStateFinished(payload));
};

export const updateMultipleProducts = (products, comment) => (dispatch) => {
  const updateComment = comment ? { comment } : {};
  dispatch(pimActions.updateMultipleStart());
  return axios.put(
    productsBulkAppPath,
    { data: [...decamelizeKeys(products)], ...updateComment },
  ).then((res) => {
    dispatch(pimActions.updateMultipleFinished({
      updatedMultipleList: camelizeKeys(res.data.data),
    }));
  }).catch(error => {
    showErrorNotification(error, 'CDMS');
  });
};

export const setProductsListEmpty = () => (dispatch) => {
  dispatch({ type: PIM_SET_PRODUCTS_LIST_EMPTY });
};

export const injectProducts = (
  products,
  updatedProduct,
  filterTarget,
) => (dispatch, getState) => {
  const { list } = getState().pim;
  const variations = list.filter(p => p.variants).map(p => p.variants).flat();
  const merchants = variations.filter(p => p.merchants).map(p => p.merchants).flat();
  const productsList = [...list, ...variations, ...merchants];

  const newList = productsList.map((p) => {
    let item = p;
    const newProduct = products.find(product => product.id === p.id);

    if (newProduct) {
      item = newProduct;
    }

    return item;
  });

  let structuredList = [];

  if (filterTarget === 'parent') {
    structuredList = newList.filter(p => p.type === 'parent').map(p => ({
      ...p,
      variants: newList.filter(v => v.parentId === p.id).map(v => ({
        ...v,
        merchants: newList.filter(m => m.parentId === v.id),
      })),
    }));
  }

  if (filterTarget === 'variation') {
    structuredList = newList.filter(v => v.type === 'variation').map(v => ({
      ...v,
      parent: newList.filter(p => p.id === v.parentId),
      merchants: newList.filter(m => m.parentId === v.id),
    }));
  }

  if (filterTarget === 'merchant') {
    structuredList = newList.filter(p => p.type === 'merchant').map(p => ({
      ...p,
      variants: newList.filter(v => v.id === p.parentId).map(v => ({
        ...v,
        merchants: uniqBy(newList.filter(m => m.id === v.parentId), 'id'),
      })),
    }));
  }

  const data = {
    list: structuredList,
  };

  if (!isEmpty(updatedProduct)) {
    data.item = updatedProduct;
  }

  return dispatch({
    type: PIM_INJECT_UPDATED_PRODUCTS,
    payload: data,
  });
};

export const fetchCountProductsActivitiesByProductIds = productIds => async (
  dispatch,
  getState,
) => {
  const currentProductsCommentsCount = getState().pim.productsCommentsCount;

  try {
    dispatch({
      type: PIM_FETCH_PRODUCTS_COMMENTS_COUNT_START,
      payload: {},
    });
    const res = await getCountProductsActivitiesByProductIds(productIds);
    const filteredCurrentProductsCommentsCount = currentProductsCommentsCount
      .filter(c => res.every(r => r.id !== c.id));

    dispatch({
      type: PIM_FETCH_PRODUCTS_COMMENTS_COUNT_FINISHED,
      payload: { productsCommentsCount: [...filteredCurrentProductsCommentsCount, ...res] },
    });
  } catch (err) {
    dispatch({
      type: PIM_FETCH_PRODUCTS_COMMENTS_COUNT_FINISHED,
      payload: { error: err.response.data, hasErrors: true },
    });
  }
};

export const bulkDeleteProductLockedAttributes = ({
  productIds, fields, singleUpdate, filterTarget, productTypes,
}) => async (dispatch) => {
  dispatch({
    type: PIM_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START,
    payload: {
      bulkDeleteProductLockedAttributesStarted: true,
      bulkDeleteProductLockedAttributesFinished: false,
      bulkDeleteProductLockedAttributesError: '',
    },
  });

  let isCDMSRequest = null;
  for (let i = 0; i < productIds.length; i++) {
    const productId = productIds[i];
    if (productTypes && productTypes[productId] === 'merchant') {
      try {
        const { data: { errors: someErrors } } = await imsUnlockAttributes(productId);
        if (someErrors && someErrors.length > 0) {
          isCDMSRequest = false;

          dispatch({
            type: PIM_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED,
            payload: {
              bulkDeleteProductLockedAttributesStarted: false,
              bulkDeleteProductLockedAttributesFinished: true,
              bulkDeleteProductLockedAttributesError: someErrors,
            },
          });
        } else if (isCDMSRequest === null) {
          isCDMSRequest = true;
        }
      } catch (error) {
        isCDMSRequest = false;
        showErrorNotification(error, 'IMS');
      }
    } else if (isCDMSRequest === null) {
      isCDMSRequest = true;
    }
  }

  if (isCDMSRequest === true) {
    try {
      await axios.all(productIds.map(id => axios.delete(getDeleteProductLockedAttributesPath(id))));

      const query = {
        filter: [{
          group: [{
            field: 'id',
            value: uniq(productIds),
            operator: 'in',
          }],
        }],
        pagination: {
          page: 1,
          limit: 500,
        },
      };

      if (!isEmpty(fields)) {
        query.fields = uniq([...fields, 'images', 'children_ids', 'type']);
      }

      const updatedProducts = await getBackendProductsByIds(query);

      dispatch(injectProducts(
        camelizeKeys(updatedProducts),
        singleUpdate && camelizeKeys(updatedProducts[0]),
        filterTarget,
      ));

      dispatch({
        type: PIM_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED,
        payload: {
          bulkDeleteProductLockedAttributesStarted: false,
          bulkDeleteProductLockedAttributesFinished: true,
          bulkDeleteProductLockedAttributesError: '',
          bulkDeleteSucess: true,
          edit: singleUpdate ? camelizeKeys(updatedProducts[0]) : {},
        },
      });
    } catch (error) {
      showErrorNotification(error, 'CDMS');
    }
  }
};

export const setPIMListDirection = contentDirection => (dispatch) => {
  dispatch({
    type: PIM_SET_LIST_DIRECTION,
    payload: { dir: contentDirection },
  });
};

export const setUpdatedProductsImagesOverrides = productsImagesOverride => (dispatch) => {
  dispatch({
    type: PIM_SET_UPDATED_PRODUCTS_IMAGES_OVERRIDES,
    payload: { productsImagesOverride },
  });
};

export const setFilterTarget = filterTarget => async (dispatch, getState) => {
  const prevFilterTarget = getState().pim.filterTarget;

  await dispatch({
    type: PIM_SET_FILTER_TARGET,
    payload: { filterTarget, prevFilterTarget },
  });
};

export const fetchPimProductPhysicalCategoriesByIds = ids => async (dispatch, getState) => {
  try {
    const { productsPhysicalCategoryList } = getState().pim;
    const sourceIds = Object.keys(productsPhysicalCategoryList);
    const idsDiff = difference(ids, sourceIds);

    if (!isEmpty(idsDiff)) {
      dispatch({
        type: PIM_SEARCH_PHYSICAL_CATEGORIES_BY_IDS_START,
        payload: {
          productsPhysicalCategoryListFetching: true,
          productsPhysicalCategoryListFetched: false,
        },
      });

      const query = {
        filter: [{
          group: [{
            field: 'id',
            value: uniq(idsDiff),
            operator: 'in',
          }],
        }],
      };
      const res = await axios.post(physicalCategoriesSearchPath + '?ref=' + ref, { ...query });
      const categories = camelizeKeys(res.data.data);

      dispatch({
        type: PIM_SEARCH_PHYSICAL_CATEGORIES_BY_IDS_FINISHED,
        payload: {
          productsPhysicalCategoryListFetching: false,
          productsPhysicalCategoryListFetched: true,
          productsPhysicalCategoryList: uniqBy([
            ...productsPhysicalCategoryList,
            ...categories,
          ], 'id'),
        },
      });
    }
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const clearPimProductList = () => (dispatch) => {
  dispatch({
    type: PIM_CLEAR_PRODUCT_LIST,
    payload: {
      fetched: false,
      pages: 0,
      pagination: {},
      total: 0,
      list: [],
      origList: [],
    },
  });
};

export const setContainerStyles = styles => (dispatch) => {
  dispatch({
    type: PIM_SET_CONTAINER_STYLE,
    payload: { containerStyles: styles },
  });
};

export const setGlobalFilters = globalFilters => (dispatch) => {
  dispatch({
    type: PIM_SET_GLOBAL_FILTERS,
    payload: { globalFilters },
  });
};

export const setGlobalFields = globalFields => (dispatch) => {
  dispatch({
    type: PIM_SET_GLOBAL_FIELDS,
    payload: { globalFields },
  });
};

export const setIsCustomFieldsMode = isCustomFieldsMode => (dispatch) => {
  dispatch({
    type: PIM_SET_IS_CUSTOM_FIELDS_MODE,
    payload: { isCustomFieldsMode },
  });
};

export const setSearchQuery = searchQuery => (dispatch) => {
  dispatch({
    type: PIM_SET_SEARCH_QUERY,
    payload: { searchQuery },
  });
};

export const setPagination = pagination => (dispatch) => {
  dispatch({
    type: PIM_SET_PAGINATION,
    payload: { pagination },
  });
};

export const setDisplayMode = displayMode => (dispatch) => {
  dispatch({
    type: PIM_SET_DISPLAY_MODE,
    payload: { displayMode },
  });
};

export const setIsEditMode = isEditMode => (dispatch) => {
  dispatch({
    type: PIM_SET_IS_EDIT_MODE,
    payload: { isEditMode },
  });
};

export const setSavedFilterFilters = savedFilterFilters => (dispatch) => {
  dispatch({
    type: PIM_SET_SAVED_FILTER_FILTERS,
    payload: { savedFilterFilters },
  });
};

export const setSavedFilterFields = savedFilterFields => (dispatch) => {
  dispatch({
    type: PIM_SET_SAVED_FILTER_FIELDS,
    payload: { savedFilterFields },
  });
};

export const setFullTextListModeSearch = fullTextListModeSearch => (dispatch) => {
  dispatch({
    type: PIM_SET_FULL_TEXT_LIST_SEARCH,
    payload: { fullTextListModeSearch },
  });
};

export const setSortField = sort => (dispatch) => {
  dispatch({
    type: PIM_SET_ORDER,
    payload: { sort },
  });
};

export const setSelectedAction = selectedAction => (dispatch) => {
  dispatch({
    type: PIM_SET_SELECTED_ACTION,
    payload: { selectedAction },
  });
};

export const clearSelected = () => (dispatch) => {
  dispatch({
    type: PIM_CLEAR_SELECTED,
    payload: { selected: [] },
  });
};

export const removeImages = products => async (dispatch) => {
  dispatch({
    type: PIM_REMOVE_PRODUCTS_IMAGES_START,
    payload: {
      removeImagesStart: true,
      removeImagesFinished: false,
    },
  });

  try {
    const promises = await products.map(async (product) => {
      const data = { data: { images: product.images } };
      const result = await axios.delete(getDeleteProductImagesPath(product.id), { data });
      return result.data;
    });

    await Promise.all(promises);

    dispatch({
      type: PIM_REMOVE_PRODUCTS_IMAGES_FINISHED,
      payload: {
        removeImagesStart: false,
        removeImagesFinished: true,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const downLoadFile = (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

export const getActivityLogData = (
  { limit, offset, filter = null, sort = null, fields, reset = false }
) => async (dispatch) => {
  dispatch(setIsLoading(true));
  dispatch({
    type: PIM_SET_MAPPER_DATA,
    payload: {
      activityLog: {
        itemsPerPage: limit,
        error: {},
        reset: reset
      }
    }
  });

  try {
    const condition = getResultCondition(filter, sort, limit, offset / limit, true);
    if (fields) {
      condition['fields'] = fields;
    }

    const res = await activityLogResult(condition);
    const { data } = res.data;

    dispatch({
      type: PIM_SET_MAPPER_DATA,
      payload: {
        activityLog: {
          data: data,
          scroll: { offset: offset ? offset : 0, pages: res.data.pages, total: res.data.total },
          condition: condition,
          reset: false
        }
      }
    });

  } catch (error) {
    showErrorNotification(error, 'CDMS');

    dispatch({
      type: PIM_SET_MAPPER_DATA,
      payload: {
        activityLog: {
          data: [], scroll: [], reset: false
        }
      }
    });
  }
  dispatch(setIsLoading(false));
};

export const setProductsWithoutImages = productsWithoutImages => (dispatch) => {
  dispatch({
    type: PIM_SET_PRODUCTS_WITHOUT_IMAGES,
    payload: { productsWithoutImages },
  });
};

export const setPriceFormulaModal = (priceFormulaModal) => (dispatch) => {
  dispatch({
    type: PIM_SET_PRICE_FORMULA_MODAL,
    payload: {
      priceFormulaModal
    },
  });
}

export const setHelperText = helperText => (dispatch) => {
  dispatch({
    type: PIM_SET_HELPER_TEXT,
    payload: { helperText },
  });
};

export const setPaginationHelperTextId = paginationHelperTextId => (dispatch) => {
  dispatch({
    type: PIM_SET_PAGINATION_HELPER_TEXT_ID,
    payload: { paginationHelperTextId },
  });
};

export const setSelectedProduct = selectedProduct => (dispatch) => {
  dispatch({
    type: PIM_SET_SELECTED_PRODUCT,
    payload: { selectedProduct },
  });
};

export const setSearchValue = searchValue => (dispatch) => {
  dispatch({
    type: PIM_SET_SEARCH_VALUE,
    payload: { searchValue },
  });
};

export const setRootFilter = rootFilter => (dispatch) => {
  dispatch({
    type: PIM_SET_ROOT_FILTER,
    payload: { rootFilter },
  });
};

export const setMerchantActivityDialog = merchantActivityDialog => (dispatch) => {
  dispatch({
    type: PIM_SET_MERCHANT_ACTIVITY_DIALOG,
    payload: { merchantActivityDialog },
  });
};

export const setShowZapInfo = (productItem = null) => async (dispatch) => {
  if (productItem === null) {
    dispatch({
      type: PIM_SET_SHOW_ZAP_INFO,
      payload: {
        zapInfo: {
          variationProduct: {},
          parentProduct: {},
          showZapInfo: false,
          competitors: {}
        }
      }
    });
  } else {
    try {
      let parentProduct = {};
      let variationProduct = {};
      let competitors = {};

      if (productItem.parentId) {
        variationProduct = productItem;
        const res = await cdmsGetProduct(productItem.parentId);
        parentProduct = camelizeKeys(res.data.data);
      } else if (productItem.productId) {
        const res = await cdmsGetProduct(productItem.productId);
        variationProduct = camelizeKeys(res.data.data);
        const parentRes = await cdmsGetProduct(variationProduct.parentId);
        parentProduct = camelizeKeys(parentRes.data.data);
      }

      if (variationProduct && variationProduct.zmid) {
        const zapModelIds = [];
        for (let i = 0; i < variationProduct.zmid.length; i++) {
          zapModelIds[i] = parseInt(variationProduct.zmid[i]);
        }

        const payload = {
          filter: [
            {
              group: [
                {
                  field: 'model_id',
                  value: zapModelIds,
                  operator: 'in'
                }
              ]
            }
          ],
          scroll: {
            limit: zapModelIds.length,
            offset: 0
          }
        };

        try {
          const result = await pmacGetCompetitors(payload);
          competitors = camelizeKeys(result.data.data);
        } catch (error) {
          showErrorNotification(error, 'PMAC');
        }
      }

      dispatch({
        type: PIM_SET_SHOW_ZAP_INFO,
        payload: {
          zapInfo: {
            variationProduct: variationProduct,
            parentProduct: parentProduct,
            showZapInfo: true,
            competitors: competitors
          }
        }
      });
    } catch (error) {
      showErrorNotification(error, 'CDMS');
    }    
  }
};

export const getProductsEnrichmentDataVideos = (productId, page, limit) => async (dispatch) => {
  try {
    const payload = {
      filter: [
        { group: [ { field: 'videos', operator: 'exists' } ] },
        { group: [ { field: 'id', operator: 'eq', value: productId } ] },
      ],
      fields: ['id', 'videos'], pagination: { page: page, limit: limit }
    }

    const res = await productsEnrichmentDataResult(payload);
    const { data } = camelizeKeys(res.data);

    dispatch({
      type: PIM_SET_VIDEOS_ENRICHMENT_DATA,
      payload: { enrichmentVideos: data }
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const getResultCondition = (
    filter,
    sort,
    limit = 0,
    offset = 0,
    pagination = false,
    repricer = false,
) => {
  const condition = {};

  if (limit && pagination === false) {
    condition.scroll = { limit, offset };
  }

  if (limit && pagination === true) {
    condition.pagination = {
      limit : limit,
      page: offset > 0 ? Math.ceil(offset) + 1 : 1
    };
  }

  if (!sort) {
    if (pagination === false) {
      condition.sort = [ { field: 'id', order: 'asc' } ];
    }
    if (pagination === true) {
      if (repricer === true) {
        condition.sort = [ { field: 'price.final_price', order: 'asc' } ];
      } else {
        condition.sort = [ { field: 'created_at', order: 'desc' } ];
      }
    }
  } else {
    condition.sort = sort;
  }

  if (filter) {
    condition.filter = filter;
  }

  return condition;
};

export const exportCategories = () => async () => {
  try {
    const res = await categoriesExport();
    downLoadFile(res.data, 'categories.csv');
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const getPriceQuantityHistory = (productId) => async (dispatch) => {
  try {
    const payload = {
      filter: [
        {
          group: [
            {
              field: 'product_gid',
              value: productId,
              operator: 'eq'
            }
          ]
        }
      ],
      scroll: {
        limit: 50,
        last_id: null
      }
    }

    const res = await imsProductLogs(payload);
    const { data } = camelizeKeys(res.data);

    dispatch({
      type: PIM_SET_ACTIVITY_LOGS_MERCHANT,
      payload: { merchantLogs: data }
    });
  } catch (error) {
    showErrorNotification(error, 'IMS');
  }
};