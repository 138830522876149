export const ksort = (obj) => {
  let keys = Object.keys(obj).sort();
  let sortedObj = {};
  for (let i = 0; i < keys.length; i++) {
    sortedObj[keys[i]] = obj[keys[i]];
  }

  return sortedObj;
};

export const sortFields = (fields, order) => {
  const sortedFields = {};
  const unsortedFieldsKeys = [];
  const fieldsKeys = Object.keys(fields);
  let count = 0;
  for (let i = 0; i < fieldsKeys.length; i++) {
    if (fields[fieldsKeys[i]].order) {
      unsortedFieldsKeys[fields[fieldsKeys[i]][order]] = fieldsKeys[i];
      count++;
    }
  }

  const sortedFieldsKeys = ksort(unsortedFieldsKeys);
  for (let i = 1; i <= count + 1; i++) {
    if (fields[sortedFieldsKeys[i]]) {
      sortedFields[sortedFieldsKeys[i]] = fields[sortedFieldsKeys[i]];
    }
  }

  return sortedFields;
};
