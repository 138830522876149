import React, { useState } from 'react';
import {
  func, bool, string, shape, number, oneOfType, node,
} from 'prop-types';
import { isEmpty } from 'lodash';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from '../../util/IntlMessages';

const SearchGroup = (props) => {
  const {
    queryLengthLimit, errorMessage, className, useExtraSearchButton, searchButton, suffix, loading,
  } = props.search;
  const [query, setQuery] = useState('');

  const handleOnCategorySearch = (e) => {
    const { value } = e.target;

    setQuery(value);
    if (!value && e.type !== 'keypress') props.onSearchSubmit('');
  };


  const handleOnCategoryKeyPress = (e) => {
    const { value } = e.target;
    const { charCode } = e;

    handleOnCategorySearch(e);

    if (charCode === 13) {
      if (props.onSearchSubmit && query.length < queryLengthLimit) {
        props.onSearchSubmit(value);
      }
    }
  };

  const handleOnSearchIconClick = () => {
    if (props.onSearchSubmit && query.length < queryLengthLimit) {
      props.onSearchSubmit(query);
    }
  };

  return (
    <div className="flex">
      <Paper className={`flex items-center pos-rel ${className}`} elevation={1}>
        {props.noResult && (
          <span className="input-placeholder">
            <IntlMessages id="placeholder.noResult" />
          </span>
        )}
        {errorMessage && (!isEmpty(query) && query.length > queryLengthLimit) && (
          <span className="input-placeholder">
            <IntlMessages id={errorMessage} />
          </span>
        )}
        <input
          className="form-control form-control-clear block pl-15"
          placeholder={props.placeholder}
          onChange={handleOnCategorySearch}
          onKeyPress={handleOnCategoryKeyPress}
          value={query}
        />
        {!useExtraSearchButton && (
          <IconButton aria-label="Search" onClick={handleOnSearchIconClick}>
            <SearchIcon />
          </IconButton>
        )}
        {loading && loading.leftList && (
          <div className="loader-abs right">
            <CircularProgress disableShrink size={15} value={80} />
          </div>
        )}
      </Paper>
      {useExtraSearchButton && (
        <Button
          variant={searchButton.variant}
          color={searchButton.color}
          onClick={handleOnSearchIconClick}
        >
          {searchButton.value}
        </Button>
      )}
      {suffix}
    </div>
  );
};

SearchGroup.propTypes = {
  noResult: bool,
  onSearchSubmit: func.isRequired,
  placeholder: string,
  search: shape({
    queryLengthLimit: number,
    errorMessage: string,
    useExtraSearchButton: bool,
    suffix: oneOfType([string, node]),
    searchButton: shape({
      color: string,
      variant: string,
      value: oneOfType([string, node]),
      loading: shape({
        leftList: bool,
      }),
    }),
  }),
};

SearchGroup.defaultProps = {
  noResult: false,
  placeholder: '',
  search: {
    queryLengthLimit: null,
    errorMessage: '',
    useExtraSearchButton: false,
    searchButton: {
      color: 'primary',
      variant: 'outlined',
      value: <IntlMessages id="selectProductsDialog.search.button.search" />,
    },
    loading: {
      leftList: false,
    },
  },
};

export default SearchGroup;
