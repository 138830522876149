/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState, useEffect, memo,
} from 'react';
import {
  shape,
} from 'prop-types';

import {
  Table, TableCell, TableContainer, TableHead, TableRow, TableSortLabel,
} from '@material-ui/core';

import { isEmpty } from 'lodash';

import { SortableContainer } from 'react-sortable-hoc';

import Checkbox from '../../../../components/Checkbox';
import IntlMessages from '../../../../util/IntlMessages';
import SortableList from './SortableList';

const HocSortableList = SortableContainer(props => <SortableList {...props} />);

const ParentLevelList = memo((props) => {
  const { listViewListProps } = props;

  const tableHeaderNode = document.querySelector('.products-table-header') || {};

  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [collapsed, setCollapsed] = useState([]);
  const [tableHeaderWidth, setTableHeaderWidth] = useState(0);
  const [productsWithChangedPositions, setProductsWithChangedPositions] = useState([]);

  useEffect(() => {
    if (listViewListProps.categoryUpdated && !isEmpty(productsWithChangedPositions)) {
      setProductsWithChangedPositions([]);
    }
  }, [listViewListProps.categoryUpdated]);

  useEffect(() => {
    if (listViewListProps.isHiddenProductsMode) {
      setCollapsed([]);
    }
  }, [listViewListProps.isHiddenProductsMode]);

  useEffect(() => {
    setCollapsed([]);
  }, [listViewListProps.selectedEntity.id]);

  useEffect(() => {
    if (listViewListProps.bulkUpdateActivityLogsFinished) {
      setCollapsed([]);
    }
  }, [listViewListProps.bulkUpdateActivityLogsFinished]);

  useEffect(() => {
    if (tableHeaderNode.offsetWidth && tableHeaderWidth !== tableHeaderNode.offsetWidth) {
      const variantRowPadding = 66;
      setTableHeaderWidth(tableHeaderNode.offsetWidth - variantRowPadding);
    }
  }, [tableHeaderNode.offsetWidth]);

  const handleAllProductsSelect = () => listViewListProps
    .onAllProductsSelect(listViewListProps.productList);
  const allProductsAreChecked = listViewListProps.productList
    .every(item => listViewListProps.selectedProducts
      .some(p => p.id === item.id));

  const handleSortEnd = (data) => {
    const product = listViewListProps.productList[data.oldIndex];

    if (data.oldIndex !== data.newIndex) {
      setProductsWithChangedPositions([
        ...productsWithChangedPositions,
        product.id,
      ]);
    }
    listViewListProps.onSortEnd(data);
  };

  const handleCellClick = (cell) => {
    if (cell.isSortable) {
      const newOrder = order === 'asc' && cell.code === orderBy
        ? 'desc'
        : 'asc';
      setOrder(newOrder);
      setOrderBy(cell.code);
      listViewListProps.onHeaderCellClick(cell, newOrder);
    }
  };

  const showSelectAllCheckbox = !listViewListProps.disabled
    && !isEmpty(listViewListProps.productList);

  const sortedAttributes = listViewListProps.attributes.sort((a, b) => a.order - b.order);
  const filteredAttributes = sortedAttributes
    .filter(attr => attr.enabled).map(attr => ({
      label: attr.label,
      code: attr.code,
      isSortable: attr.isSortable,
      sortCode: attr.sortCode,
    }));
  const defaultFields = showSelectAllCheckbox
    ? [{
      code: 1,
      label: '',
    }] : [{
      code: 1,
      label: '',
    }, {
      code: 2,
      label: '',
    }];
  const attributesList = [...defaultFields, ...filteredAttributes];

  return (
    <>
      <TableContainer className="product-table-container">
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead className="products-table-header">
            <TableRow>
              {showSelectAllCheckbox && (
                <TableCell
                  key="check-all"
                  align="left"
                >
                  <div className="flex items-center select-all-checkbox">
                    <Checkbox
                      checked={allProductsAreChecked}
                      onChange={handleAllProductsSelect}
                    />
                    <IntlMessages id="preview.productList.selectAllLabel" />
                  </div>
                </TableCell>
              )}
              {
                !isEmpty(listViewListProps.productList) && attributesList.map((f) => {
                  const onCellClick = () => handleCellClick(f);

                  return (
                    <TableCell
                      key={f.code}
                      align="left"
                    >
                      <TableSortLabel
                        active={orderBy === f.code}
                        direction={orderBy === f.code ? order : 'asc'}
                        onClick={onCellClick}
                        hideSortIcon={!f.isSortable}
                      >
                        {f.label}
                      </TableSortLabel>
                    </TableCell>
                  );
                })
              }
            </TableRow>
          </TableHead>
          <HocSortableList
            lockAxis="y"
            onSortEnd={handleSortEnd}
            disabled={listViewListProps.disabled}
            helperClass="dnd-active"
            useDragHandle
            sortableItemProps={{
              sortedAttributes,
              onProductSelect: listViewListProps.onProductSelect,
              onEditClick: listViewListProps.onEditClick,
              selectedProducts: listViewListProps.selectedProducts,
              disabled: listViewListProps.disabled,
              defaultImage: listViewListProps.defaultImage,
              certainProductAttributes: listViewListProps.certainProductAttributes,
              productsCommentsCount: listViewListProps.productsCommentsCount,
              collapsed,
              setCollapsed,
              productCommentsLoading: listViewListProps.productCommentsLoading,
              productComments: listViewListProps.productComments,
              fetchActivityLogsByProductId: listViewListProps.fetchActivityLogsByProductId,
              isHiddenProductsMode: listViewListProps.isHiddenProductsMode,
              isEditMode: listViewListProps.isEditMode,
              productsWithChangedPositions,
              pagination: listViewListProps.pagination,
              productList: listViewListProps.productList,
              selectedEntity: listViewListProps.selectedEntity,
              fetchProductAttributeSets: listViewListProps.fetchProductAttributeSets,
              attributeSets: listViewListProps.attributeSets,
              tableHeaderWidth,
              initialAttributesOptions: listViewListProps.initialAttributesOptions,
              productsFetching: listViewListProps.productsFetching,
            }}
          />
        </Table>
      </TableContainer>
    </>
  );
});

ParentLevelList.propTypes = {
  listViewListProps: shape().isRequired,
};

ParentLevelList.defaultProps = {};

export default ParentLevelList;
