/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useMerchantEdit = ({
  productToEdit,
  productUpdated,
  getProductComments,
}) => {
  useEffect(() => {
    if (productUpdated) {
      getProductComments(productToEdit.id);
    }
  }, [productUpdated]);
};

export default useMerchantEdit;
