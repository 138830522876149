/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  shape, string, func, arrayOf, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { camelize } from 'humps';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import { fetchAttributes } from '../../actions/system';
import { generateParentProductData } from './utils/productDataGenerator';

const ProductCardComponent = memo((props) => {
  useEffect(() => {
    if (!props.fetchedAttributes && !props.fetchingAttributes) {
      props.fetchAttributes();
    }
  }, []);

  const {
    productData, productAttribute, attributes, defaultImage,
    fetchingAttributes,
  } = props;

  const { certainProductAttributes } = productAttribute;
  const sortedAttributes = attributes
    ? attributes.sort((a, b) => a.order - b.order)
    : [];

  const productValue = generateParentProductData(productData, defaultImage, sortedAttributes);

  return (
    fetchingAttributes ? (
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className="progress-warning bottom custom-loader"
        size={40}
        thickness={4}
      />
    ) : (
      <Card className="product-item-card-full">
        <div className="product-item-card-images flex">
          {!isEmpty(productData.images)
            ? productData.images.map(img => (
              <img key={img.url} className="product-card-image" alt={img.alt ? img.alt.en : ''} src={img.url} />
            ))
            : (
              <img className="product-card-image" alt="empty" src={defaultImage} />
            )
        }
        </div>
        <CardContent className="product-item-card-content">
          <Typography variant="body2" color="textSecondary" component="div" className="flex ">
            {
              sortedAttributes.map((attr) => {
                const { enabled } = attr;
                const itemValue = productValue[camelize(attr.value)];
                const currentAttribute = certainProductAttributes
                  .find(el => el.code === attr.value) || {};
                const sizeClass = !itemValue || String(itemValue).length < 90
                  ? 'flex-four'
                  : 'flex-one';

                return (
                  enabled !== false
                  && (
                    <div className={`flex pr-10 pl-10 product-item-data ${sizeClass}`} key={attr.code}>
                      <span className="pr-5 key">{(currentAttribute.label ? currentAttribute.label.en : '') || attr.label}</span>
                      <span className="pl-5 value">{itemValue && String(itemValue).trim() ? itemValue : '-'}</span>
                    </div>
                  ));
              })
            }
          </Typography>
        </CardContent>
      </Card>
    )
  );
});

ProductCardComponent.propTypes = {
  productData: shape().isRequired,
  fetchingAttributes: bool.isRequired,
  fetchedAttributes: bool.isRequired,
  productAttribute: shape().isRequired,
  defaultImage: string.isRequired,
  fetchAttributes: func.isRequired,
  attributes: arrayOf(shape()),
};

ProductCardComponent.defaultProps = {
  attributes: [],
};

const mapStateToProps = state => ({
  defaultImage: state.pim.defaultImage,
  attributes: state.system.modes.pim.parent,
  productAttribute: state.productAttribute,
  fetchingAttributes: state.system.fetching,
  fetchedAttributes: state.system.fetched,
});

const mapDispatchToProps = {
  fetchAttributes,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardComponent);
