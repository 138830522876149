import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { isEmpty, cloneDeep } from 'lodash';
import RestActions from '../util/rest/actions';
import { showErrorNotification } from '../util/api';

import {
  rankingsPath,
  getRankingPath,
  rankingsSearchPath,
  categoriesSearchPath,
  navigationsSearchPath,
  categoriesBulkPath,
  navigationsBulkPath,
} from '../util/paths';

import {
  CLEAR_CREATE_RANKING_STATE,
  CLEAR_FETCHED_RANKING_STATE,
  CLEAR_UPDATE_RANKING_STATE,
  CLEAR_DELETE_RANKING_STATE,
  CLEAR_RANKING_STATE,
  FETCH_RANKING_BY_NAME_START,
  FETCH_RANKING_BY_NAME_FINISHED,
  RANKING_SEARCH_CATEGORIES_BY_RANKING_RULE_START,
  RANKING_SEARCH_CATEGORIES_BY_RANKING_RULE_FINISHED,
  RANKING_SEARCH_NAVIGATIONS_BY_RANKING_RULE_START,
  RANKING_SEARCH_NAVIGATIONS_BY_RANKING_RULE_FINISHED,
  RANKING_BULK_CATEGORIES_UPDATE_START,
  RANKING_BULK_CATEGORIES_UPDATE_FINISHED,
  RANKING_BULK_NAVIGATIONS_UPDATE_START,
  RANKING_BULK_NAVIGATIONS_UPDATE_FINISHED,
} from './types';

import {
  getRankingByName,
} from '../services/API/ranking';

import {
  mapToAttributesMatrix,
  mapFilters,
} from '../util/attributesMatrixMapping';

import { CDMSClient } from '../util/api';

const restRankingActions = new RestActions('ranking');

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';

export const mapVirtualParams = (virtualParams, mapToMatrix, attributesMatrix) => {
  const clonedVirtualParams = cloneDeep(virtualParams);
  const positions = Array.isArray(clonedVirtualParams.positions)
    ? {}
    : clonedVirtualParams.positions;
  const positionsFromOverrides = clonedVirtualParams.productsOverride
    ? clonedVirtualParams.productsOverride.reduce((result, item) => {
      const payload = { ...result };
      payload[item.productId] = item.position;
      return payload;
    }, {})
    : positions;

  if (clonedVirtualParams.productsOverride) {
    delete clonedVirtualParams.productsOverride;
  }

  if (clonedVirtualParams.facets) delete clonedVirtualParams.facets;

  return ({
    ...clonedVirtualParams,
    filters: mapToMatrix ? [...mapFilters(clonedVirtualParams.filters, attributesMatrix)]
      : clonedVirtualParams.filters,
    sortRules: mapToMatrix ? clonedVirtualParams.sortRules.map(s => ({
      field: mapToAttributesMatrix(s.field, attributesMatrix),
      direction: s.direction.toLowerCase(),
    })) : clonedVirtualParams.sortRules,
    positions: positionsFromOverrides,
  });
};

export const clearCreateRankingState = () => (dispatch) => {
  dispatch({ type: CLEAR_CREATE_RANKING_STATE });
};

export const clearFetchedRankingState = () => (dispatch) => {
  dispatch({ type: CLEAR_FETCHED_RANKING_STATE });
};

export const clearUpdatedRankingState = () => (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_RANKING_STATE });
};

export const clearDeletedRankingState = () => (dispatch) => {
  dispatch({ type: CLEAR_DELETE_RANKING_STATE });
};

export const clearRankingState = () => (dispatch) => {
  dispatch({ type: CLEAR_RANKING_STATE });
};


export const updateRank = (rank, params = {
  isSync: false,
}) => (dispatch) => {
  const transformedPath = `${getRankingPath(rank.id)}?is_synchronous=${params.isSync}`;
  dispatch(restRankingActions.updateStart());
  const newGroup = rank.group.map((item) => {
    const element = {
      ...item,
      isLocked: false,
    };
    return element;
  });
  return axios.put(transformedPath, {
    data: decamelizeKeys({
      ...rank,
      group: newGroup,
    }),
  }).then((res) => {
    dispatch(restRankingActions.updateFinished({ item: camelizeKeys(res.data.data) }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restRankingActions.updateFinished({ error: error.response.data, hasErrors: true }));
  });
};

const mapObjectToArray = obj => (Object.keys(obj).map(k => obj[k]));

export const fetchList = () => async (dispatch) => {
  dispatch(restRankingActions.fetchAllStart());
  try {
    const res = await cdmsFetchRankingsList({pagination: {
      page: 1,
      limit: 500
    }});

    dispatch(restRankingActions.fetchAllFinished({
      list: camelizeKeys(mapObjectToArray(res.data.data)),
    }));
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch(restRankingActions.fetchAllFinished({ error: error.response, hasErrors: true }));
  }
};

export const cdmsFetchRankingsList = (payload) => CDMSClient.post(rankingsSearchPath + '?ref=' + ref, payload);

export const createRank = (rank, params = {
  isSync: false,
}) => (dispatch) => {
  const transformedPath = `${rankingsPath}?is_synchronous=${params.isSync}`;
  dispatch(restRankingActions.createStart());
  return axios.post(transformedPath, {
    data: {
      name: rank.name,
      status: 'live',
      scope: 'null',
      group: rank.rankAttributes.map(r => ({
        field: r.field,
        normalization: r.normalization,
        pushing: r.pushing,
        weight: r.weight,
        is_locked: false,
        scope: 'null',
      })),
    },
  }).then((res) => {
    dispatch(restRankingActions.createFinished({ item: res.data.data }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restRankingActions.createFinished({ error: error.response.data, hasErrors: true }));
  });
};

export const fetchItem = id => (dispatch) => {
  dispatch(restRankingActions.fetchStart());
  return axios.get(getRankingPath(id)).then((res) => {
    dispatch(restRankingActions.fetchFinished({ item: camelizeKeys(res.data.data) }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restRankingActions.fetchFinished({ error: error.response.data, hasError: true }));
  });
};

export const deleteRank = (id, params = {
  isSync: false,
}) => (dispatch) => {
  const transformedPath = `${getRankingPath(id)}?is_synchronous=${params.isSync}`;
  dispatch(restRankingActions.deleteStart());
  return axios.delete(transformedPath).then(() => {
    dispatch(restRankingActions.deleteFinished());
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restRankingActions.deleteFinished({ error: error.response.data, hasError: true }));
  });
};

export const deleteRankFromState = id => (dispatch, getState) => {
  const { list } = getState().ranking;
  if (list.some(l => l.id === id)) {
    const index = list.indexOf(list.find(l => l.id === id));
    list.splice(index, 1);
    dispatch(restRankingActions.fetchAllFinished({ list }));
  }
};

export const fetchRankingByName = (rankingName, callback) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_RANKING_BY_NAME_START,
      payload: {
        fetchRankingByNameStarted: true,
        fetchRankingByNameFinished: false,
        rankingByName: {},
      },
    });
    const res = await getRankingByName(rankingName);
    dispatch({
      type: FETCH_RANKING_BY_NAME_FINISHED,
      payload: {
        fetchRankingByNameStarted: false,
        fetchRankingByNameFinished: true,
        rankingByName: camelizeKeys(res),
      },
    });
    callback(!isEmpty(res));
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_RANKING_BY_NAME_FINISHED,
      payload: {
        error: error.response.data,
        hasErrors: true,
        rankingByName: {},
        fetchRankingByNameStarted: false,
        fetchRankingByNameFinished: false,
      },
    });
  }
};

export const searchCategoriesByRankingRule = query => async (dispatch) => {
  dispatch({
    searchCategoriesByRankingRuleStarted: true,
    searchCategoriesByRankingRuleFinished: false,
    type: RANKING_SEARCH_CATEGORIES_BY_RANKING_RULE_START,
  });
  return axios.post(categoriesSearchPath + '?ref=' + ref, query).then((res) => {
    const searchResults = camelizeKeys(res.data.data);
    dispatch({
      type: RANKING_SEARCH_CATEGORIES_BY_RANKING_RULE_FINISHED,
      payload: {
        searchCategoriesByRankingRuleStarted: false,
        searchCategoriesByRankingRuleFinished: true,
        list: searchResults,
      },
    });
    return searchResults;
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: RANKING_SEARCH_CATEGORIES_BY_RANKING_RULE_FINISHED,
      payload: {
        error: error.response.data, hasError: true,
      },
    });
  });
};

export const searchNavigationsByRankingRule = query => async (dispatch) => {
  dispatch({
    searchNavigationsByRankingRuleStart: true,
    searchNavigationsByRankingRuleFinished: false,
    type: RANKING_SEARCH_NAVIGATIONS_BY_RANKING_RULE_START,
  });
  return axios.post(navigationsSearchPath, query).then((res) => {
    const searchResults = camelizeKeys(res.data.data);
    dispatch({
      type: RANKING_SEARCH_NAVIGATIONS_BY_RANKING_RULE_FINISHED,
      payload: {
        searchNavigationsByRankingRuleStart: false,
        searchNavigationsByRankingRuleFinished: true,
        list: searchResults,
      },
    });
    return searchResults;
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: RANKING_SEARCH_NAVIGATIONS_BY_RANKING_RULE_FINISHED,
      payload: {
        error: error.response.data, hasError: true,
      },
    });
  });
};

export const bulkUpdateVirtualCategories = (categories, params) => (dispatch, getState) => {
  const { list: attributesMatrix } = getState().attributesMatrix;

  dispatch({
    type: RANKING_BULK_CATEGORIES_UPDATE_START,
    payload: {
      bulkUpdateCategoriesStart: true,
      bulkUpdateCategoriesFinished: false,
    },
  });

  return axios.put(categoriesBulkPath, {
    data: decamelizeKeys(categories.map(c => ({
      ...c,
      virtualParams: mapVirtualParams(c.virtualParams, params.mapToMatrix, attributesMatrix),
    }))),
  }).then(() => {
    dispatch({
      type: RANKING_BULK_CATEGORIES_UPDATE_FINISHED,
      payload: {
        bulkUpdateCategoriesStart: false,
        bulkUpdateCategoriesFinished: true,
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: RANKING_BULK_CATEGORIES_UPDATE_FINISHED,
      payload: {
        error,
        hasErrors: true,
      },
    });
  });
};

export const bulkUpdateNavigations = (navigations, params) => (dispatch, getState) => {
  const { list: attributesMatrix } = getState().attributesMatrix;

  dispatch({
    type: RANKING_BULK_NAVIGATIONS_UPDATE_START,
    payload: {
      bulkUpdateNavigationsStart: true,
      bulkUpdateNavigationsFinished: false,
    },
  });

  return axios.put(navigationsBulkPath, {
    data: decamelizeKeys(navigations.map(c => ({
      ...c,
      virtualParams: mapVirtualParams(c.virtualParams, params.mapToMatrix, attributesMatrix),
    }))),
  }).then(() => {
    dispatch({
      type: RANKING_BULK_NAVIGATIONS_UPDATE_FINISHED,
      payload: {
        bulkUpdateNavigationsStart: false,
        bulkUpdateNavigationsFinished: true,
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: RANKING_BULK_NAVIGATIONS_UPDATE_FINISHED,
      payload: {
        error,
        hasErrors: true,
      },
    });
  });
};
