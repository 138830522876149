/* eslint-disable no-nested-ternary */
import firebase from 'firebase/app';
import humps from 'humps';

import { settingsPath } from '../util/paths';
import { showErrorNotification } from '../util/api';

import {
  FETCH_APP_SETTINGS_START,
  FETCH_APP_SETTINGS_FINISHED,
} from './types';

export const fetchAppSettings = () => async (dispatch) => {
  dispatch({
    type: FETCH_APP_SETTINGS_START,
    payload: {
      fetchSettingsStarted: true,
      fetchSettingsFinished: false,
    },
  });

  let appSettings;
  try {
    appSettings = await firebase.database().ref(settingsPath).once('value');
  } catch (error) {
    showErrorNotification(error, 'Firebase');
    
    dispatch({
      type: FETCH_APP_SETTINGS_FINISHED,
      payload: {
        hasErrors: true,
      },
    });
  }
  const appSettingsResult = appSettings.val();

  dispatch({
    type: FETCH_APP_SETTINGS_FINISHED,
    payload: {
      fetchSettingsStarted: false,
      fetchSettingsFinished: true,
      settings: humps.camelizeKeys(appSettingsResult),
    },
  });
}
