import React, { useState } from 'react';
import {
  func
} from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import IntlMessages from '../../../../util/IntlMessages';
import { getRepricerData } from '../../../../actions/repricer';
import { Button, IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import Field from "../../../../components/Field";

export const Search = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [repricerPublishedStatus, setRepricerPublishedStatus] = useState('');
  const [repricerPosition, setRepricerPosition] = useState('');
  const [repricerStockStatus, setRepricerStockStatus] = useState('');

  const repricerPublishedStatusOptions = [
    { label: 'Not published', value: 'not'},
    { label: 'Published on ZAP', value: 'zap'},
    { label: 'Published on KSP', value: 'ksp'},
    { label: 'Published All', value: 'all'},
  ];

  const repricerPositionOptions = [
    { label: '1', value: '1'},
    { label: '2', value: '2'},
    { label: '3', value: '3'},
    { label: '4', value: '4'},
    { label: '5', value: '5'},
  ];

  const repricerStockStatusOptions = [
    { label: 'In Stock', value: 'in_stock'},
    { label: 'Out Of Stock', value: 'out_of_stock'},
  ];

  const handleSearchValueChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handlePublishedStatusChange = (e) => {
    if (e.target.value === 'not') {
      setRepricerPosition('');
    }
    setRepricerPublishedStatus(e.target.value);
  };

  const handlePositionChange = (e) => {
    if (e.target.value !== '' && !repricerPublishedStatus) {
      setRepricerPublishedStatus('all');
    }
    setRepricerPosition(e.target.value);
  };

  const handleStockStatusChange = (e) => {
    setRepricerStockStatus(e.target.value);
  };

  const handlePublishedStatusClear = () => {
    setRepricerPublishedStatus('');
  };

  const handlePositionClear = () => {
    setRepricerPosition('');
  };

  const handleStockStatusClear = () => {
    setRepricerStockStatus('');
  };

  const handleSearchValueClear = () => {
    setSearchValue('');
  }

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      getSearch();
    }
  };

  const getReset = () => {
    setSearchValue('');
    setRepricerPublishedStatus('');
    setRepricerPosition('');
    setRepricerStockStatus('');
    props.getRepricerData({limit: props.itemsPerPage, offset: 0, reset: true});
  };

  const getSearch = () => {
    //const filter = props.condition.filter ? props.condition.filter : [];
    const filter = [];

    if (repricerPublishedStatus === 'not' && !repricerPosition) {
      filter.push({
        condition: 'or',
        group: [
          { field: 'competitors_data.zap.ao_store_position', value: 0, operator: 'eq' },
          { field: 'competitors_data.zap.ao_store_position', operator: 'not_exists' },
          { field: 'competitors_data.ksp.ao_store_position', value: 0, operator: 'eq' },
          { field: 'competitors_data.ksp.ao_store_position', operator: 'not_exists' }
        ]
      });
    } else if (repricerPublishedStatus === 'zap' && !repricerPosition) {
      filter.push({
        group: [ { field: 'competitors_data.zap.ao_store_position', value: 0, operator: 'gt' } ]
      });
    } else if (repricerPublishedStatus === 'ksp' && !repricerPosition) {
      filter.push({
        group: [ { field: 'competitors_data.ksp.ao_store_position', value: 0, operator: 'gt' } ]
      });
    } else if (repricerPublishedStatus === 'all' && !repricerPosition) {
      filter.push({
        condition: 'or',
        group: [
          { field: 'competitors_data.zap.ao_store_position', value: 0, operator: 'gt' },
          { field: 'competitors_data.ksp.ao_store_position', value: 0, operator: 'gt' }
        ]
      });
    }

    if (repricerPublishedStatus === 'zap' && repricerPosition) {
      filter.push({
        group: [ { field: 'competitors_data.zap.ao_store_position', value: repricerPosition, operator: 'eq' } ]
      });
    } else if (repricerPublishedStatus === 'ksp' && repricerPosition) {
      filter.push({
        group: [ { field: 'competitors_data.ksp.ao_store_position', value: repricerPosition, operator: 'eq' } ]
      });
    } else if (repricerPublishedStatus === 'all' && repricerPosition) {
      filter.push({
        condition: 'or',
        group: [
          { field: 'competitors_data.zap.ao_store_position', value: repricerPosition, operator: 'eq' },
          { field: 'competitors_data.ksp.ao_store_position', value: repricerPosition, operator: 'eq' }
        ]
      });
    }

    if (repricerStockStatus) {
      filter.push({
        group: [ { field: 'stock_status', value: repricerStockStatus, operator: 'eq' } ]
      });
    }

    const sort = props.condition.sort ? props.condition.sort : null;
    const fullText = searchValue ? { 'fields': [ 'title.en', ], 'match_type': 'must', 'value': searchValue } : null;

    props.getRepricerData({
      limit: props.itemsPerPage,
      offset: 0,
      filter,
      fullText,
      sort,
    });
  };

  const currentPage = Math.ceil(props.scroll.offset / props.itemsPerPage) + 1;
  const pageOf = Math.ceil(props.scroll.total / props.itemsPerPage);

  return (
    <>
      <div id="search-container" className="product-heading flex justify-between">
        <div className="mapper-search-wrapper float-right">

          <div className="search-container">
            <div className="row" style={{height: '50px'}}>
              <div className="col-3">
                {repricerPublishedStatus && (
                  <span className="repricer-clear-filter" onClick={handlePublishedStatusClear}>&#935;</span>
                )}
                <Field
                  element="dropdown"
                  className="input-wrapper input-bordered form-group input-select field-white field-secondary"
                  label={<IntlMessages id="repricer.publishedStatus" />}
                  variant="outlined"
                  wrapperClassName="flex-wrap"
                  name="comment"
                  size="small"
                  onChange={handlePublishedStatusChange}
                  value={repricerPublishedStatus}
                  options={repricerPublishedStatusOptions}
                />
              </div>

              <div className="col-3">
                {repricerPosition && (
                  <span className="repricer-clear-filter" onClick={handlePositionClear}>&#935;</span>
                )}
                <Field
                  element="dropdown"
                  className="input-wrapper input-bordered form-group input-select field-white field-secondary"
                  label={<IntlMessages id="repricer.position" />}
                  variant="outlined"
                  wrapperClassName="flex-wrap"
                  name="comment"
                  size="small"
                  onChange={handlePositionChange}
                  value={repricerPosition}
                  options={repricerPositionOptions}
                />
              </div>

              <div className="col-3">
                {repricerStockStatus && (
                  <span className="repricer-clear-filter" onClick={handleStockStatusClear}>&#935;</span>
                )}
                <Field
                  element="dropdown"
                  className="input-wrapper input-bordered form-group input-select field-white field-secondary"
                  label={<IntlMessages id="repricer.stockStatus" />}
                  variant="outlined"
                  wrapperClassName="flex-wrap"
                  name="comment"
                  size="small"
                  onChange={handleStockStatusChange}
                  value={repricerStockStatus}
                  options={repricerStockStatusOptions}
                />
              </div>

              <div className="col-3">
                <div className="search-container" style={{padding: '0'}}>
                  <div className="row">
                    <div className="col-3">
                      <Button
                        variant="contained"
                        color="primary"
                        className="text-white"
                        style={{backgroundColor: '#2b629b', width: '75px', height: '35px'}}
                        onClick={getSearch}
                      >
                        <IntlMessages id="buttons.apply" />
                      </Button>
                    </div>

                    <div className="col-1" style={{padding: '0', maxWidth: '5px'}}>&nbsp;</div>

                    <div className="col-3">
                      <Button
                        variant="contained"
                        color="primary"
                        className="text-white"
                        style={{backgroundColor: '#d3233e', width: '75px', height: '35px'}}
                        onClick={getReset}
                      >
                        <IntlMessages id="buttons.clear" />
                      </Button>
                    </div>

                    <div className="col-4">&nbsp;</div>
                  </div>
                </div>

              </div>

            </div>
            <div className="row">
              <div className="col-9">
                {searchValue && (
                  <span className="repricer-clear-filter text" onClick={handleSearchValueClear}>&#935;</span>
                )}
                <OutlinedInput
                  id="search-input-lg"
                  type="search"
                  className="search-input-lg float-left mapper-search-form"
                  placeholder="Search"
                  onChange={handleSearchValueChange}
                  value={searchValue}
                  onKeyPress={handleSearchKeyPress}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={getSearch}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </div>

              <div className="col-3" style={{paddingTop: '10px'}}>
                {props.scroll.total ? (
                  <div style={{ whiteSpace: 'nowrap' }}>
                    {props.scroll.total.toLocaleString()} <IntlMessages id="repricer.items" />.&nbsp;
                    <IntlMessages id="repricer.page" /> { currentPage } <IntlMessages id="repricer.pageOf" /> { pageOf }
                  </div>
                ) : (
                  <div style={{ whiteSpace: 'nowrap' }}>
                    0 <IntlMessages id="repricer.items" />.
                  </div>
                )}
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  );
};

Search.propTypes = {
  getRepricerData: func.isRequired,
};

Search.defaultProps = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  getRepricerData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
