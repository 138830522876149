export const promotionFilter = [{
  group: [{
    field: 'type',
    value: 'promotion_url',
    operator: 'eq',
  }],
}];

export const promotionPagination = {
  page: 1,
  limit: 500,
};
