import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const previewRootSelector = state => state;
const previewListSelector = state => state.list;

const mapProductsImages = (images) => {
  if (!images) return [];
  return ([
    ...images.map((img) => {
      const params = '?auto=format%2Ccompress&bg=transparent&h=220&trim=auto&trimsd=1&w=auto';
      return {
        ...img,
        url: `${img.url}${params}`,
      };
    }),
  ]);
};

const listSelector = createSelector(
  previewListSelector,
  list => (!isEmpty(list) ? list.reduce((acc, current) => {
    acc.push({
      ...current,
      images: mapProductsImages(current.images),
      variants: !isEmpty(current.variants)
        ? current.variants.map(v => ({
          ...v,
          images: mapProductsImages(v.images),
        }))
        : [],
    });
    return acc;
  }, []) : []),
);

const stateSelector = createSelector(
  previewRootSelector,
  preview => ({
    updatingMultiple: preview.updatingMultiple,
    updating: preview.updating,
    isEditMode: preview.isEditMode,
    list: preview.list,
    selectedProduct: preview.selectedProduct,
    displayMode: preview.displayMode,
    productsOverride: preview.productsOverride,
    containerStyles: preview.containerStyles,
    pagination: preview.pagination,
    facetsPreFilters: preview.facetsPreFilters,
    searchQuery: preview.searchQuery,
    fetchingSelectedCategory: preview.fetchingSelectedCategory,
    fetching: preview.fetching,
    selectedSearchFilter: preview.selectedSearchFilter,
  }),
);

export const previewSelector = createSelector(
  stateSelector,
  listSelector,
  (state, list) => ({
    ...state, list,
  }),
);

export default {
  previewSelector,
};
