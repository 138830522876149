import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const virtualCategoryWidgetRootSelector = state => state;
const virtualCategoryWidgetSelectedCategorySelector = state => state.selectedCategory;


const mapProductsOverride = (virtualParams) => {
  if (!virtualParams) return {};
  return ({
    ...virtualParams,
    productsOverride: virtualParams.positions
      ? Object.keys(virtualParams.positions)
        .map(p => ({ productId: p, position: virtualParams.positions[p] }))
      : [],
  });
};

const selectedCategorySelector = createSelector(
  virtualCategoryWidgetSelectedCategorySelector,
  selectedCategory => (!isEmpty(selectedCategory) ? ({
    ...selectedCategory,
    virtualParams: mapProductsOverride(selectedCategory.virtualParams),
  }) : {}),
);

const stateSelector = createSelector(
  virtualCategoryWidgetRootSelector,
  state => ({
    selectedCategory: state.selectedCategory,
  }),
);

export const virtualCategoryWidgetSelector = createSelector(
  stateSelector,
  selectedCategorySelector,
  (state, selectedCategory) => ({
    ...state,
    selectedCategory,
  }),
);

export default {
  virtualCategoryWidgetSelector,
};
