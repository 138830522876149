import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MatButton from '@material-ui/core/Button';
import swal from '@sweetalert/with-react';
import {
  shape, func, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import useStateStore from './hooks/useState';

import Checkbox from '../../../components/Checkbox';
import { AlertWarning } from './components/Alert';
import RctCollapsibleCard from '../../../components/RctCollapsibleCard/RctCollapsibleCard';
import PageTitleBar from '../../../components/PageTitleBar/PageTitleBar';
import IntlMessages from '../../../util/IntlMessages';
import Dropzone from './components/DropZone';
import StepLoader from './components/StepLoader';
import { AbilityContext } from '../../../components/AbilityContext';
import appPermissions from '../../../util/appPermissions';
import Can from '../../../components/Can';

import {
  uploadXMLWithCategories,
  clearCategoryImportState,
  bulkUpdateHebrewNames,
} from '../../../actions/categoryImport';

const BulkCategoryImport = (props) => {
  const abilityContext = useContext(AbilityContext);
  const state = useStateStore();
  const { match, categoryImport } = props;
  const {
    xlsxUploading,
    xlsxUploaded,
    bulkVirtualCategoriesDeleting,
    bulkVirtualCategoriesDeleted,
    bulkVirtualCategoriesCreating,
    bulkVirtualCategoriesCreated,
    bulkVirtualCategoriesUpdating,
    bulkVirtualCategoriesUpdated,
    hasErrors,
  } = categoryImport;

  const showCloseButton = (xlsxUploaded
    && bulkVirtualCategoriesDeleted
    && bulkVirtualCategoriesCreated) || (xlsxUploaded && bulkVirtualCategoriesUpdated);
  const showPopup = xlsxUploading || xlsxUploaded
    || bulkVirtualCategoriesDeleting || bulkVirtualCategoriesDeleted
    || bulkVirtualCategoriesCreating || bulkVirtualCategoriesCreated
    || bulkVirtualCategoriesUpdating || bulkVirtualCategoriesUpdated;

  const toBase64 = async file => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
    reader.readAsBinaryString(file);
  });

  const handleClearCategoryImportState = () => {
    props.clearCategoryImportState();
  };

  const handleSubmitClick = async () => {
    await swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete all virtual categories and upload new categories tree?',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    })
      .then(async (willDiscard) => {
        if (willDiscard) {
          const xlsx = await toBase64(state.files[0]);

          if (!state.updateOnly) {
            props.uploadXMLWithCategories(xlsx);
          } else {
            props.bulkUpdateHebrewNames(xlsx);
          }
        }
      });
  };

  const handleCheckboxChange = () => {
    state.setUpdateOnly(!state.updateOnly);
  };

  const bulkImportTitle = hasErrors
    ? <IntlMessages id="importPage.status.categoriesBulkCreatedWithError" />
    : <IntlMessages id="importPage.status.title.categoriesBulkCreating" />;
  const bulkUpdateTitle = bulkVirtualCategoriesUpdating || xlsxUploading
    ? <IntlMessages id="importPage.status.title.categoriesBulkUpdating" />
    : <IntlMessages id="importPage.status.title.categoriesBulkUpdated" />;

  const noPermissions = !abilityContext.can(
    appPermissions.bulkImport.permissions.update,
    appPermissions.bulkImport.name,
  );

  return (
    <div className="container-import">
      <PageTitleBar
        title={(
          <IntlMessages id="pageTitle.importPage" />
        )}
        match={match}
      />
      <RctCollapsibleCard>
        <AlertWarning />
        <Dropzone
          {...props}
          disabled={noPermissions}
          state={state}
        />
        <div className="import-submit flex">
          <Can
            do={appPermissions.bulkImport.permissions.update}
            on={appPermissions.bulkImport.name}
          >
            <MatButton
              variant="contained"
              color="primary"
              className="text-white"
              disabled={noPermissions || isEmpty(state.files)}
              onClick={handleSubmitClick}
            >
              <IntlMessages id="button.submitCategoriesImport" />
            </MatButton>
          </Can>
          <Checkbox
            className="ml-15"
            onChange={handleCheckboxChange}
            checked={state.updateOnly}
            label="Only update Hebrew names"
            disabled={noPermissions}
          />
        </div>
        <div className="pt-10 pb-10">
          {state.updateOnly
            ? (
              <span className="label-info">
                <IntlMessages id="importCategories.label.onlyUpdate" />
              </span>
            ) : (
              <span className="label-info">
                <IntlMessages id="importCategories.label.fullImport" />
              </span>
            )}
        </div>
        <Dialog
          open={showPopup}
          aria-labelledby="simple-dialog-title"
          disableBackdropClick
        >
          <DialogTitle id="simple-dialog-title">
            {state.updateOnly
              ? (
                bulkUpdateTitle
              ) : (
                bulkImportTitle
              )}
          </DialogTitle>
          <DialogContent>
            <StepLoader {...props} state={state} />
          </DialogContent>
          {showCloseButton && (
            <DialogActions>
              <MatButton
                variant="contained"
                color="primary"
                className="text-white"
                onClick={handleClearCategoryImportState}
              >
                Close
              </MatButton>
            </DialogActions>
          )}
        </Dialog>
      </RctCollapsibleCard>
    </div>
  );
};

BulkCategoryImport.propTypes = {
  match: shape().isRequired,
  uploadXMLWithCategories: func.isRequired,
  clearCategoryImportState: func.isRequired,
  bulkUpdateHebrewNames: func.isRequired,

  categoryImport: shape({
    xlsxUploading: bool,
    xlsxUploaded: bool,
    bulkVirtualCategoriesDeleting: bool,
    bulkVirtualCategoriesDeleted: bool,
    bulkVirtualCategoriesCreating: bool,
    bulkVirtualCategoriesCreated: bool,
    hasError: bool,
  }).isRequired,
};

const mapStateToProps = state => ({
  system: state.system,
  categoryImport: state.categoryImport,
});

const mapDispatchToProps = {
  uploadXMLWithCategories,
  clearCategoryImportState,
  bulkUpdateHebrewNames,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkCategoryImport));
