import React from 'react';
import {
  string, func, number, bool, arrayOf,
} from 'prop-types';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const { Handle } = Slider;

const handle = (props) => {
  const {
    value, dragging, index, ...restProps
  } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="bottom"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};
const SimpleSlider = ({
  className, onChange, value, name, useRange,
  rangeValue, min, max, disabled,
}) => {
  const maxKey = max <= 100 ? 100 : max;
  return (
    <div className={`slider-range ${className}`}>
      {useRange
        ? (
          <Range
            defaultValue={rangeValue}
            name={name}
            min={0}
            max={max}
            onAfterChange={val => onChange(val, name)}
            marks={{
              0: min,
              [maxKey]: maxKey,
            }}
            step={1}
            handle={handle}
            disabled={disabled}
          />
        )
        : (
          <Slider
            min={min}
            max={max}
            value={value}
            defaultValue={3}
            handle={handle}
            name={name}
            onChange={val => onChange(val, name)}
            disabled={disabled}
          />
        )}
    </div>
  );
};

SimpleSlider.propTypes = {
  value: number,
  min: number,
  max: number,
  onChange: func,
  name: string,
  className: string,
  useRange: bool,
  rangeValue: arrayOf(number),
  disabled: bool,
};

SimpleSlider.defaultProps = {
  value: 0,
  min: 0,
  max: 100,
  onChange: null,
  name: '',
  className: '',
  useRange: false,
  rangeValue: [],
  disabled: false,
};

export default SimpleSlider;
