import restInitState from '../util/rest/initState';

import {
  SET_FEEDS_DATA,
} from '../actions/types';

const initialState = {
  errors: {},
  data: {},
  actions: [],
  scroll: {},
  condition: {},
};

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case SET_FEEDS_DATA:
      return {
        ...state,
        ...action.payload,
      };

    default: return state;
  }
};
