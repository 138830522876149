import React, { memo } from 'react';
import {
  shape,
} from 'prop-types';
import { isEmpty } from 'lodash';

import Field from '../../../../components/Field';
import AutoComplete from '../../../../components/AutoComplete';
import IntlMessages from '../../../../util/IntlMessages';

const FiltersList = memo((props) => {
  const {
    filter, itemProps,
  } = props;

  let timeout = null;

  const attrHasErrorClass = !isEmpty(itemProps.errors) && itemProps.errors.group[0].field ? 'has-errors' : '';
  const selectedAttribute = itemProps.attributesOptions
    .find(aO => aO.value === filter.field) || { value: '', label: '' };
  const selectedAttributeOption = itemProps.attributeValueOptions
    .find(a => a.value === selectedAttribute.value);

  const mappedAttributeValueOptions = selectedAttributeOption && selectedAttributeOption.options
    ? selectedAttributeOption.options.map(o => ({
      value: o.optionCode,
      label: o.label.en,
      type: o.frontendInputType,
    }))
    : [];

  const onAttributeChange = (e) => {
    const { value, name } = e.target;
    const selectedOption = itemProps.attributesOptions.find(aO => aO.value === value);

    const updatedFilter = {
      ...filter,
      [name]: value,
      value: '',
    };

    itemProps.handleFilterChange({
      updatedFilter,
      filterId: filter.id,
    });

    if (selectedOption && selectedOption.type.startsWith('dropdown')) {
      itemProps.fetchAttributeOptions(value);
    }
  };

  const onAttributeValueChange = (e) => {
    const { value, name } = e.target;
    const selectedOption = mappedAttributeValueOptions.find(aO => aO.value === value);
    const newValue = selectedAttribute.type && selectedAttribute.type.startsWith('dropdown')
      ? selectedOption.value
      : value;

    const currentOption = selectedAttributeOption.options.find(
      o => o.attributeCode === filter.field
        && (
          o.optionCode === value
          || [...value].pop().includes(o.optionCode)
        ),
    );

    const updatedFilter = {
      ...filter,
      [name]: newValue,
    };

    itemProps.handleFilterChange({
      updatedFilter,
      filterId: filter.id,
    });

    itemProps.setSelectedAttributeOption(currentOption);
  };

  const getAttributeValue = () => {
    if (selectedAttribute.type && selectedAttribute.type.startsWith('dropdown')) {
      return mappedAttributeValueOptions
        .find(aO => aO.value === filter.value) || { value: '', label: '' };
    }
    return filter.value;
  };

  const onAttributeRowDelete = () => {
    itemProps.handleAttributeRowDelete(filter.id);
  };

  const onOptionsSearch = (query) => {
    const { value } = selectedAttributeOption;

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      itemProps.handleSearchOptions(value, query);
    }, 100);
  };

  return (
    <div className="filter-item filters-item-wrapper flex items-center">
      <Field
        element="icon-button"
        groupclass="flex-0"
        icon={<i className="ti-close" />}
        className="btn-mui-sm text-danger mr-10"
        onClick={onAttributeRowDelete}
        disabled={itemProps.loading || itemProps.disabled}
      />
      <AutoComplete
        useAdvancedOptions
        containerClassName="block"
        wrapperClassName="mt-10"
        className={`select-autocomplete-wrapper ${attrHasErrorClass} ${itemProps.disabled ? 'disabled' : ''}`}
        label="Attribute code"
        name="field"
        inputProps={{
          name: 'field',
        }}
        value={selectedAttribute}
        options={itemProps.attributesOptions.map(o => ({
          label: o.label,
          value: o.value,
          disabled: itemProps.filters.some(f => f.field === o.value),
        }))}
        hideStaticLabel
        onChange={onAttributeChange}
        error={!isEmpty(itemProps.errors) && itemProps.errors.group[0].field}
        disabled={itemProps.fetchingAttributeOptions || itemProps.loading || itemProps.disabled}
        helperText={
          filter.errors && filter.errors.field
            ? <IntlMessages id="influencer.errors.cantBeBlank" />
            : ''
        }
      />
      <Field
        element={
          selectedAttribute.type
          && selectedAttribute.type.startsWith('dropdown')
            ? 'autocomplete'
            : selectedAttribute.type
        }
        useAdvancedOptions
        containerClassName="block mt-0 pl-20"
        wrapperClassName="block pl-20"
        className={`select-autocomplete-wrapper block ${attrHasErrorClass} ${itemProps.disabled ? 'disabled' : ''}`}
        label="Attribute value"
        name="value"
        inputProps={{
          name: 'value',
        }}
        value={getAttributeValue()}
        options={mappedAttributeValueOptions}
        hideStaticLabel
        onChange={onAttributeValueChange}
        handleSearchOptions={onOptionsSearch}
        error={!isEmpty(itemProps.errors) && itemProps.errors.group[0].value}
        disabled={itemProps.fetchingAttributeOptions || itemProps.disabled}
        loading={itemProps.fetchingAttributeOptions}
        helperText={
          filter.errors && filter.errors.value
            ? <IntlMessages id="influencer.errors.cantBeBlank" />
            : ''
        }
      />
    </div>
  );
});

FiltersList.propTypes = {
  filter: shape().isRequired,
  itemProps: shape().isRequired,
};

FiltersList.defaultProps = {};

export default FiltersList;
