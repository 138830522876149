/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import {
  node, arrayOf, shape, func, bool, string,
} from 'prop-types';
import { isEmpty, uniq, uniqBy } from 'lodash';
import MatButton from '@material-ui/core/Button';
import swal from '@sweetalert/with-react';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';

import IntlMessages from '../../util/IntlMessages';
import Body from './FilterByBody';
import useStateStore from './hooks/useState';
import useFiltersEffect from './hooks/useFiltersEffect';
import useSwitch from './hooks/useSwitch';

import {
  searchAttributeOptions,
  clearFoundAttributeOptions,
  fetchInitialAttributeOptions,
  setSelectedAttributeOption,
} from '../../actions/productAttribute';

const multipleOperatorsSet = new Set(['nin', 'in']);
const operatorsWithoutValue = new Set(['exists', 'not_exists']);

const FilterBy = memo((props) => {
  const state = useStateStore();
  useFiltersEffect(state, props);
  useSwitch(state, props);

  const {
    content, selectProductsSettings, loading, disabled, isParent, filters, parentFilters, parentOptions,
    hasPermissions, filterContentClass, foundOptions, initialOptions, selectedAttributesOptions,
    loadOptions,
  } = props;

  const realFilters = isParent === true ? filters : state.filters;

  const mapFilters = array => array.map(f => ({
    condition: f.condition,
    field: f.group[0].field,
    type: f.group[0].type,
    operator: f.group[0].operator,
    options: f.group && f.group.length > 0
      && [...new Set(f.group)].map(r => r.value),
  }));

  useEffect(() => {
    const filtersGroups = realFilters.map(f => f.group).flat().map((g) => {
      if (!operatorsWithoutValue.has(g.operator)) {
        return ({
          code: g.field,
          value: Array.isArray(g.value) ? g.value : g.value.split(),
        });
      }
      return ({
        code: g.field,
      });
    });
    const codes = uniq(filtersGroups.map(g => g.code).flat());
    const options = uniq(filtersGroups
      .map(g => g.value).flat()).filter(o => o);

    const mappedOptions = options
      .map(o => (o && o.startsWith('*') ? o.replace(/\*/g, '') : o));

    if (
      !isEmpty(codes)
      && !isEmpty(mappedOptions)
      && !loading
    ) {
      props.fetchInitialAttributeOptions(codes, mappedOptions);
    }
  }, [loading]);

  const handleAddNewFilter = () => {
    const newFilters = [...realFilters];
    newFilters.push({
      condition: 'or',
      group: [{
        field: '',
        value: '',
        operator: '',
      }],
    });
    state.setFilters(newFilters);
    props.onChange({ filters: newFilters });
  };

  const handleProductsAttribute = ({
    name, value, filterRowIndex,
  }) => {
    const newFilters = [...realFilters];

    if (value === 'id') {
      return newFilters.map((n, i) => {
        if (i === filterRowIndex) {
          return ({
            ...n,
            group: [{
              [name]: value,
              value: [],
              operator: '',
            }],
          });
        } return n;
      });
    }

    newFilters[filterRowIndex].group.forEach((r) => {
      const payload = r;
      payload[name] = value;
      payload.value = '';
    });
    return newFilters;
  };

  const handleAttributeSelect = ({ filterRowIndex, selectedOption }) => {
    const { value, name, type } = selectedOption.target;
    const newFilters = handleProductsAttribute({
      name, value, type, filterRowIndex,
    });

    state.setFilters([...newFilters]);
    props.onChange({ filters: [...newFilters] });
  };

  const onSwitchClick = () => {
    state.setIsSwitchOn(!state.isSwitchOn);
    props.onSwitchClick(!state.isSwitchOn);
  };

  const checkFilter = array => array.map(f => ({
    ...f,
    group: f.group.map((g) => {
      if (g.operator === 'exists') {
        return ({
          field: g.field,
          operator: g.operator,
        });
      } return g;
    }),
  }));

  const resetCurrentFilterValue = ({
    array, index, value = [], isSliced,
  }) => (
    [...array].map((f, i) => {
      if (i === index) {
        return ({
          ...f,
          group: !isSliced
            ? f.group.map(g => ({ ...g, value }))
            : [{ ...f.group[0], value }].flat(),
        });
      } return f;
    })
  );

  const handleOperatorChange = ({ event, row, filterRowIndex }) => {
    const { value, name } = event.target;
    let newFilters = [...realFilters];
    const isProduct = !row.options.flat().map(o => o.includes('prod_')).some(o => !o);

    if (!isProduct) {
      if (multipleOperatorsSet.has(value)) {
        newFilters = resetCurrentFilterValue({
          array: newFilters, index: filterRowIndex, value: [], isSliced: true,
        });
      } else {
        if (operatorsWithoutValue.has(value)) {
          newFilters = resetCurrentFilterValue({
            array: newFilters, index: filterRowIndex, noValue: true, isSliced: true,
          });
        }

        if (!operatorsWithoutValue.has(value)) {
          newFilters = resetCurrentFilterValue({
            array: newFilters, index: filterRowIndex, value: '', isSliced: true,
          });
        }
      }
    }

    newFilters[filterRowIndex].group.forEach(g => g[name] = value); //eslint-disable-line

    const updatedFiltes = checkFilter(newFilters);

    state.setFilters([...updatedFiltes]);
    props.onChange({
      filters: updatedFiltes,
      filter: row,
      operator: value,
      isProduct,
    });
  };

  const onFilterConditionChange = ({ filterRowIndex }) => {
    const cloneFilters = [...realFilters];
    cloneFilters[filterRowIndex]['condition'] = cloneFilters[filterRowIndex]['condition'] === 'or' ? 'and' : 'or';

    state.setFilters([...cloneFilters]);
    props.onChange({ filters: cloneFilters });
  };

  const onDeleteFilterValueClick = ({ filterRowIndex, optionToDeleteIndex }) => {
    const cloneFilters = [...realFilters];
    cloneFilters[filterRowIndex].group.splice(optionToDeleteIndex, 1);

    state.setFilters([...cloneFilters]);
    props.onChange({ filters: cloneFilters });
  };

  const changeGroupConditionToOr = (filters, filterRowIndex) => {
    if (filters[filterRowIndex].group.length > 0) {
      const updatedFilters = [...filters];
      updatedFilters[filterRowIndex].condition = 'or';
      return updatedFilters;
    }
    return filters;
  };

  const onAddFilterValueClick = ({ row, filterRowIndex }) => {
    const cloneFilters = [...realFilters];

    cloneFilters[filterRowIndex].group.push({
      field: row.field,
      value: '',
      operator: row.operator,
    });

    state.setFilters([...changeGroupConditionToOr(cloneFilters, filterRowIndex)]);
    props.onChange({ filters: cloneFilters });
  };

  const onFilterValueChange = ({ event, filterRowIndex, filterValueIndex }) => {
    const { value, name } = event.target;
    const cloneFilters = [...realFilters];
    cloneFilters[filterRowIndex].group[filterValueIndex][name] = value;

    const currentGroup = cloneFilters[filterRowIndex].group[filterValueIndex];
    const currentOption = foundOptions.find(
      o => o.attributeCode === currentGroup.field
        && (
          // for single and multiple selects
          o.optionCode === currentGroup.value
          || [...currentGroup.value].pop().includes(o.optionCode)
        ),
    );
    state.setFilters([...cloneFilters]);
    props.onChange({ filters: cloneFilters });
    props.setSelectedAttributeOption(currentOption);
  };

  const handleAttributeRowDelete = (attributeRowIndexToDelete) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to permanently delete this filter?',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    })
      .then((willDiscard) => {
        if (willDiscard) {
          const cloneFilters = [...realFilters];
          const removedFilter = realFilters[attributeRowIndexToDelete];
          cloneFilters.splice(attributeRowIndexToDelete, 1);

          state.setFilters([...cloneFilters]);
          props.onChange({ filters: cloneFilters, removedFilter });
        }
      });
  };

  const combinedOptions = uniqBy(
    [...foundOptions, ...initialOptions, ...selectedAttributesOptions],
    'optionCode',
  );

  const attributesWithOptions = props.productAttributes.map(attr => ({
    ...attr,
    options: isEmpty(attr.options) ? combinedOptions.filter(o => !isEmpty(o) && o.attributeCode === attr.code) : attr.options,
  }));

  return (
    <div className="category-filter-wrapper filter-by-section form-dashed pt-0 block">
      <div className={`flex items-start relative ${filterContentClass}`}>
        {isParent === true ? (
          <div className="form-label">
            <IntlMessages id="form.parentFilterByLabel" />
          </div>
        ) : (
          <div className="form-label">
            <IntlMessages id="form.filterByLabel" />
          </div>
        )}
        <Body
          hasPermissions={hasPermissions}
          disabled={disabled}
          className="form-compact flex-1 block"
          formInlineGroupClassName="flex-child-auto items-start form-group-m"
          filters={mapFilters(realFilters)}
          parentFilters={parentFilters}
          parentOptions={parentOptions}
          isParent={isParent}
          errors={props.errors}
          loadOptions={loadOptions}
          defaultRowSource={{
            onSwitchClick,
            isSwitchOn: state.isSwitchOn,
            selectCategoriesButton: props.selectCategoriesButton,
            className: loading ? 'disabled' : '',
          }}
          defaultRow={props.defaultRow}
          attributeRowSource={{
            onAttributeSelect: handleAttributeSelect,
            onOperatorChange: handleOperatorChange,
            onAttributeRowDelete: handleAttributeRowDelete,
            productAttributes: attributesWithOptions,
            selectProductsSettings,
            filterValueActions: {
              onFilterConditionChange,
              onDeleteFilterValueClick,
              onAddFilterValueClick,
              onFilterValueChange,
            },
          }}
          handleSearchOptions={props.searchAttributeOptions}
          onBlur={props.clearFoundAttributeOptions}
          loading={loading}
        />
        {content}
      </div>
      {!isEmpty(props.productAttributes) && props.showAddFilterButton && (
        <div className="category-filter-footer mt-15 relative">
          <MatButton
            variant="contained"
            color="primary"
            className="text-white"
            onClick={handleAddNewFilter}
            disabled={loading || disabled}
          >
            <IntlMessages id="button.addFilterLabel" />
          </MatButton>
          {loading && (
            <CircularProgress
              variant="indeterminate"
              disableShrink
              className="progress-warning search-filter-loader loader bottom"
              size={15}
              thickness={4}
            />
          )}
        </div>
      )}
    </div>
  );
});

FilterBy.propTypes = {
  content: node,
  defaultRow: node,
  selectProductsSettings: shape(),
  selectCategoriesButton: node,
  productAttributes: arrayOf(shape()),
  onChange: func,
  errors: arrayOf(shape()),
  filters: arrayOf(shape()),
  parentFilters: arrayOf(shape()),
  parentOptions: arrayOf(shape()),
  showAddFilterButton: bool,
  loading: bool,
  onSwitchClick: func,
  hasPermissions: bool,
  disabled: bool,
  isParent: bool,
  filterContentClass: string,
  searchAttributeOptions: func.isRequired,
  clearFoundAttributeOptions: func.isRequired,
  fetchInitialAttributeOptions: func.isRequired,
  setSelectedAttributeOption: func.isRequired,
  initialOptions: arrayOf(shape()).isRequired,
  foundOptions: arrayOf(shape()).isRequired,
  selectedAttributesOptions: arrayOf(shape()).isRequired,
  loadOptions: bool.isRequired,
};

FilterBy.defaultProps = {
  content: null,
  defaultRow: null,
  selectProductsSettings: null,
  selectCategoriesButton: null,
  productAttributes: [],
  onChange: null,
  errors: [],
  filters: [],
  parentFilters: [],
  parentOptions: [],
  showAddFilterButton: false,
  loading: false,
  onSwitchClick: null,
  hasPermissions: false,
  disabled: false,
  isParent: false,
  filterContentClass: '',
};

const mapStateToProps = state => ({
  initialOptions: state.productAttribute.initialOptions,
  foundOptions: state.productAttribute.foundOptions,
  selectedAttributesOptions: state.productAttribute.selectedAttributesOptions,
  loadOptions: state.productAttribute.searchAttributeOptionsStart,
});

const actionCreators = {
  searchAttributeOptions,
  clearFoundAttributeOptions,
  fetchInitialAttributeOptions,
  setSelectedAttributeOption,
};

export default connect(mapStateToProps, actionCreators)(FilterBy);
