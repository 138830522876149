import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Badge } from 'reactstrap';
import {
  string, arrayOf, shape, number, oneOfType, func, node, bool,
} from 'prop-types';
import IntlMessages from '../../util/IntlMessages';

const List = ({
  className, dataSource, type, gap, size, dir, activeClassName, first, last,
  disable,
}) => {
  const listType = type ? ` list-${type}` : '';
  const listDir = dir ? ` list-${dir}` : '';
  const listSize = size ? ` list-${size}` : '';
  const listGap = gap ? ` list-${gap}` : '';
  const disableClass = disable ? 'disabled' : '';
  return (
    <nav className="list-wrapper">
      <ul className={`list ${className}${listType}${listGap}${listSize}${listDir} ${disableClass}`}>
        {first}
        {dataSource && dataSource.length > 0 && dataSource.map((item) => {
          const itemLinkContent = (
            item.notifications
              ? (
                <div className="list-item-badge-wrapper">
                  {item.icon && <i className={item.icon} />}
                  <IntlMessages id={item.valueId} />
                  <Badge className="badge-tr" color="primary">{item.notifications}</Badge>
                </div>
              )
              : (
                <Fragment>
                  {item.icon && <i className={item.icon} />}
                  {
                    item.ignoreTranslation
                      ? item.value
                      : <IntlMessages id={item.valueId} />
                  }
                </Fragment>
              )
          );
          return (
            <li key={item.key || item.id} className={`list-item-wrapper ${item.className || ''}`}>
              {activeClassName
                ? (
                  <Link onClick={item.onClick} to={item.route} className="list-item-link">
                    {itemLinkContent}
                  </Link>
                )
                : (
                  <NavLink
                    exact
                    onClick={item.onClick}
                    to={item.route}
                    className={`list-item-link ${item.linkClassName}`}
                    activeClassName={item.activeClassName}
                  >
                    {itemLinkContent}
                  </NavLink>
                )
              }
            </li>
          );
        })}
        {last}
      </ul>
    </nav>
  );
};

List.propTypes = {
  className: string,
  activeClassName: string,
  first: node,
  last: node,
  size: string,
  dir: string,
  type: string,
  dataSource: oneOfType([
    arrayOf(shape({
      id: number,
      key: string,
    })),
    func,
  ]),
  gap: number,
  disable: bool,
};

List.defaultProps = {
  className: '',
  first: null,
  last: null,
  activeClassName: '',
  type: '',
  dir: '',
  size: '',
  dataSource: null,
  gap: null,
  disable: false,
};

export default List;
