/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { defaultMaxPagination, searchPhraseFilter } from '../utils/defaultData';
import { getSearchSearchFiltersQuery } from '../../utils/queries';

export const useSearch = (state, props) => {
  const handleClickOutside = (event) => {
    if (state.node && state.node.contains(event.target)) {
      return;
    }
    state.setUseContext(false);
  };

  useEffect(() => {
    if (state.useContext) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [state.useContext]);

  useEffect(() => {
    props.fetchNavigationList(searchPhraseFilter, defaultMaxPagination);
  }, []);

  useEffect(() => {
    if (props.navigation.deleted && !props.navigation.hasError) {
      state.setValue('');
      props.searchNavigationFilters(getSearchSearchFiltersQuery(''));
      props.fetchNavigationList(searchPhraseFilter, defaultMaxPagination);
    }
  }, [props.navigation.deleted]);
};

export default useSearch;
