import React, { memo } from 'react';
import { func, bool, shape } from 'prop-types';
import { Save } from '@material-ui/icons';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import _, { isEmpty } from 'lodash';
import { NotificationManager } from 'react-notifications';

import IntlMessages from '../../../../../../util/IntlMessages';

import {
  setActionPopoverState,
  setActionPopoverAnchor,
  setUpdatedProductsOverrides,
  updateMultipleProducts,
  setUpdatedProductsImagesOverrides,
  setEditMode,
} from '../../../../../../actions/preview';

import {
  update as updateCategory,
} from '../../../../../../actions/virtualCategory';

import {
  updateSearchFilter,
} from '../../../../../../actions/search';

import {
  updatePromotionFilter,
} from '../../../../../../actions/promotion';

import {
  previewSelector,
} from './selectors/previewSelector';
import {
  virtualCategoryWidgetSelector,
} from '../../../../selectors/virtualCategoryWidgetSelector';
import {
  promotionWidgetSelector,
} from '../../../../selectors/promotionWidgetSelector';
import { mapUserDetailsToComment } from '../../../../../../util/activityLog';

const handleEmptyOnChange = () => { };

const handleNotificationWarning = msg => NotificationManager.warning(msg);

const isArrayEqual = (x, y) => _(x).differenceWith(y, _.isEqual).isEmpty();

const HeaderControls = memo((props) => {
  const {
    previewProps,
    sessionItem,
    virtualCategoryUpdating,
    promotionUpdating,
    searchFilterUpdating,
    virtualCategoryWidgetProps,
    promotionWidgetProps,
  } = props;

  const loading = virtualCategoryUpdating
    || searchFilterUpdating
    || promotionUpdating
    || previewProps.updating
    || previewProps.updatingMultiple;

  const getEntityToUse = () => {
    const { selectedSearchFilter } = previewProps;
    let entityToUse = {};

    if (!isEmpty(virtualCategoryWidgetProps.selectedCategory)) {
      entityToUse = virtualCategoryWidgetProps.selectedCategory;
    }
    if (!isEmpty(promotionWidgetProps.selectedPromotion)) {
      entityToUse = promotionWidgetProps.selectedPromotion;
    }
    if (!isEmpty(selectedSearchFilter)) entityToUse = selectedSearchFilter;
    return entityToUse;
  };

  const substrProductImageUrl = diff => diff.map(d => ({
    ...d,
    images: d.images.map(i => ({
      ...i,
      url: i.url.indexOf('?') !== -1
        ? i.url.substring(0, i.url.indexOf('?'))
        : i.url,
    })),
  }));

  const checkProductsImagesOverride = () => {
    const { productsImagesOverride, list } = previewProps;
    const origProductsImagesOrder = list
      .filter(l => productsImagesOverride.find(p => p.id === l.id))
      .map(p => ({ id: p.id, images: p.images }));

    const mappedProductsImagesOverride = substrProductImageUrl(productsImagesOverride);
    const isEqual = isArrayEqual(origProductsImagesOrder, mappedProductsImagesOverride);
    const diff = origProductsImagesOrder.map((originalItem) => {
      const updatedItem = mappedProductsImagesOverride.find(pio => pio.id === originalItem.id);
      if (!isArrayEqual([originalItem], [updatedItem])) {
        return updatedItem;
      } return [];
    }).flat();
    return ({ isEqual, diff });
  };

  const handleUpdateEntityFilters = ({ filters, productsOverride }) => {
    const { selectedEntityName } = previewProps;

    const entityToUse = getEntityToUse();
    const { virtualParams } = entityToUse;
    const params = { isSync: true, mapToMatrix: true };

    const payload = {
      id: entityToUse.id,
      virtualParams: {
        ...virtualParams,
        filters: filters || virtualParams.filters,
        productsOverride: productsOverride || virtualParams.productsOverride,
      },
    };

    props.setActionPopoverState(false);
    props.setActionPopoverAnchor(null);

    if (selectedEntityName === 'category') {
      payload.isEnabled = entityToUse.isEnabled;
      payload.includeInMenu = entityToUse.includeInMenu;
      payload.useInProductSearch = entityToUse.useInProductSearch;

      props.updateCategory(payload, params);
    } else if (selectedEntityName === 'promotion') {
      payload.type = entityToUse.type;
      payload.urlKey = entityToUse.urlKey;

      props.updatePromotionFilter(payload.id, payload);
    } else {
      payload.searchPhrase = entityToUse.searchPhrase;
      payload.type = entityToUse.type;

      props.updateSearchFilter(payload.id, payload);
    }
  };

  const handleSaveChangesClick = () => {
    const { productsOverride, productsImagesOverride } = previewProps;

    if (!isEmpty(productsOverride)) {
      handleUpdateEntityFilters({ productsOverride });
      props.setUpdatedProductsOverrides([]);
      return true;
    }

    const { isEqual, diff } = checkProductsImagesOverride();
    if (!isEmpty(productsImagesOverride) && !isEqual) {
      const comment = `Products images order was changed (${productsImagesOverride.map(pio => pio.id)})`;
      const userComment = comment
        ? mapUserDetailsToComment(comment, sessionItem)
        : null;

      props.updateMultipleProducts(diff, userComment);
      props.setUpdatedProductsImagesOverrides([]);
      return true;
    }

    return handleNotificationWarning(<IntlMessages id="preview.save.alert.noChanges" />);
  };

  const onEditModeChange = () => {
    props.setEditMode(!previewProps.isEditMode);
  };

  const isHeaderControlsDisabled = !isEmpty(previewProps.productsOverride)
    || !isEmpty(previewProps.productsImagesOverride);

  return (
    <div className="flex justify-end items-center min-h-xs pl-10 pos-rel">
      {previewProps.isEditMode && (
        <>
          {loading && (
            <CircularProgress
              className="primary-color ml-5 custom-loader abs"
              size={26}
              variant="indeterminate"
              disableShrink
              thickness={3}
            />
          )}

          <Tooltip title={<IntlMessages id="button.saveChanges" />}>
            <div className="tooltip-container">
              <IconButton
                color="primary"
                className="btn-link btn-xs text-normal"
                onClick={handleSaveChangesClick}
                disabled={!isHeaderControlsDisabled}
              >
                <Save fontSize="small" />
              </IconButton>
            </div>
          </Tooltip>
        </>
      )}
      <Tooltip
        title={
          !previewProps.isEditMode
            ? <IntlMessages id="header.switch.editMode.off" />
            : <IntlMessages id="header.switch.editMode.on" />
        }
      >
        <label
          role="presentation"
          htmlFor="mer-switch"
          className="mer-switch sm ml-10"
          onClick={onEditModeChange}
          onKeyDown={onEditModeChange}
        >
          <input
            type="checkbox"
            name="mer-switch"
            checked={previewProps.isEditMode}
            onChange={handleEmptyOnChange}
          />
          <span />
        </label>
      </Tooltip>
    </div>
  );
});

HeaderControls.propTypes = {
  virtualCategoryUpdating: bool.isRequired,
  promotionUpdating: bool.isRequired,
  searchFilterUpdating: bool.isRequired,

  previewProps: shape().isRequired,
  sessionItem: shape().isRequired,
  setActionPopoverState: func.isRequired,
  setActionPopoverAnchor: func.isRequired,
  setUpdatedProductsOverrides: func.isRequired,
  updateMultipleProducts: func.isRequired,
  setUpdatedProductsImagesOverrides: func.isRequired,
  updateCategory: func.isRequired,
  updateSearchFilter: func.isRequired,
  updatePromotionFilter: func.isRequired,
  setEditMode: func.isRequired,
  virtualCategoryWidgetProps: shape().isRequired,
  promotionWidgetProps: shape().isRequired,
};

HeaderControls.defaultProps = {};

const mapStateToProps = state => ({
  previewProps: previewSelector(state.preview),
  sessionItem: state.session.item,
  virtualCategoryUpdating: state.virtualCategory.updating,
  promotionUpdating: state.promotion.updating,
  searchFilterUpdating: state.search.updating,
  virtualCategoryWidgetProps: virtualCategoryWidgetSelector(state.virtualCategoryWidget),
  promotionWidgetProps: promotionWidgetSelector(state.promotionWidget),
});

const mapDispatchToProps = {
  setActionPopoverState,
  setActionPopoverAnchor,
  setUpdatedProductsOverrides,
  updateMultipleProducts,
  setUpdatedProductsImagesOverrides,
  updateCategory,
  updateSearchFilter,
  updatePromotionFilter,
  setEditMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderControls);
