import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const pimListSelector = state => state.list;
const pimRootSelector = state => state;

const mapProductsImages = (images) => {
  if (!images) return [];
  return ([
    ...images.map((img) => {
      const params = '?auto=format%2Ccompress&bg=transparent&fill=solid&fit=fillmax&h=220&trim=auto&trimsd=1&w=auto';
      return {
        ...img,
        url: `${img.url}${params}`,
      };
    }),
  ]);
};

const listSelector = createSelector(
  pimListSelector,
  list => (!isEmpty(list) ? list.reduce((acc, current) => {
    acc.push({
      ...current,
      images: mapProductsImages(current.images),
      variants: !isEmpty(current.variants)
        ? current.variants.map(v => ({
          ...v,
          images: mapProductsImages(v.images),
          merchants: !isEmpty(v.merchants)
            ? v.merchants.map(m => ({
              ...m,
              images: mapProductsImages(m.images),
            }))
            : [],
        }))
        : [],
    });
    return acc;
  }, []) : []),
);

export const pimSelector = createSelector(
  listSelector,
  pimRootSelector,
  (list, state) => ({
    productsList: list,
    filterTarget: state.filterTarget,
    containerStyles: state.containerStyles,
    searchFilter: state.searchQuery,
    pagination: state.pagination,
    isCustomFieldsMode: state.isCustomFieldsMode,
    displayMode: state.displayMode,
    isEditMode: state.isEditMode,
    globalFields: state.globalFields,
    savedFilterFields: state.savedFilterFields,
    fetchedList: state.fetched,
    fetching: state.fetching,
    updatingMultiple: state.updatingMultiple,
    selectedProduct: state.selectedProduct,
    selectedProducts: state.selected,
  }),
);

export default {
  pimSelector,
};
