import React, { useState, useEffect, memo } from 'react';
import { connect } from "react-redux";
import {
  shape, arrayOf, func, string,
} from 'prop-types';
import { isEmpty } from 'lodash';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from "@material-ui/icons/Edit";
import RemoveIcon from '@material-ui/icons/Remove';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../util/IntlMessages';
import Field from '../Field';
import { getProductsEnrichmentDataVideos } from '../../actions/pim';
import VideosEnrichmentDialog from './components/VideosEnrichmentDialog';
import VideosEnrichmentPreview from './components/VideosEnrichmentPreview';
import { Box, Button } from "@material-ui/core";

const VideoFormItem = ((props) => {
  const {
    index,
    vid,
    edit,
    addProductVideos,
    updateProductVideos,
    removeProductVideos,
    onRemoveLockedAttribute,
    getLockIcon,
    errors,
    handleOpenEnrichmentPreview,
    setEditProductVideos,
  } = props;

  const [currentVideo, setCurrentVideo] = useState(vid);

  const videoTypes = [
    { value: 'youtube', label: 'Youtube' },
    { value: 'vimeo', label: 'Vimeo' },
  ];

  const editProductVideos = (e, index) => {
    setEditProductVideos(index);
    setCurrentVideo(vid);
  };

  const updateCurrentVideo = (e) => {
    const { name, value } = e.target;
    if (!currentVideo['title']) {
      currentVideo['title'] = { en: '', he: '' };
    }
    if (name === 'title_en') {
      currentVideo['title']['en'] = value;
    } else if (name === 'title_he') {
      currentVideo['title']['he'] = value;
    } else {
      currentVideo[name] = value;
    }

    if (!currentVideo['previewUrl'] && currentVideo['url']) {
      const embedPos = currentVideo['url'].indexOf('embed');
      const embed = currentVideo['url'].substr(embedPos + 6);
      currentVideo['previewUrl'] = 'https://img.youtube.com/vi/' + embed + '/mqdefault.jpg'
    }

    setCurrentVideo({...currentVideo});
  }

  const saveProductVideos = (e, index) => {
    if (edit === 'new') {
      if(currentVideo.type) {
        addProductVideos(currentVideo);
      } else {
        addProductVideos({...currentVideo, type: "youtube"});
      }
    } else {
      updateProductVideos(currentVideo, index);
    }
    editProductVideos(e, null);
  }

  const maxVideoTitleLength = index === 0 ? 50 : 20;
  let videoTitle = vid.title ? vid.title.en : '';
  if (videoTitle.length > maxVideoTitleLength) {
    videoTitle = videoTitle.substr(0, maxVideoTitleLength) + '...';
  }

  if (edit === null) {
    return (
      <div className={`flex dnd-img ${index === 0 ? 'main' : ''}`}>
        <div className="img-item relative">
          <img
            style={{ cursor: 'pointer' }}
            onClick={e => handleOpenEnrichmentPreview(e, vid)}
            alt={vid.title ? vid.title.en : ''}
            src={vid.previewUrl}
          />
          <div className="img-size" style={{ width: '100%' }}>
            {videoTitle}
          </div>
          <Box style={{ position: 'absolute', bottom: 0 }}>
            <Fab
              style={{ left: '0', backgroundColor: '#d3233e' }}
              color="primary"
              aria-label="Remove"
              className="product-edit-image-delete"
              onClick={e => removeProductVideos(e, index)}
            >
              <RemoveIcon />
            </Fab>
            <Fab
              style={{ left: '25px', top: '5px' }}
              color="primary"
              aria-label="Edit"
              className="product-edit-image-control"
              onClick={e => editProductVideos(e, index)}
            >
              <EditIcon />
            </Fab>
          </Box>
        </div>
      </div>
    );
  } else if (edit === index || edit === 'new') {
    return (
      <div className="vid">
        <div className="vid-item relative">
          <div className="group-sm">
            <>
              <Field
                element="select"
                label={<IntlMessages id="preview.edit.label.video_type" />}
                variant="outlined"
                size="small"
                className="vid-select autocomplete-outlined"
                options={videoTypes}
                name="type"
                onChange={e => updateCurrentVideo(e)}
                value={currentVideo.type ? currentVideo.type : 'youtube'}
              />
              <span className="locked-attribute locked-attribute-videos"
                    onClick={e => onRemoveLockedAttribute(e, 'videos.' + index + '.type')}
              >
              { getLockIcon('videos.' + index + '.type', 'left') }
            </span>
            </>
          </div>

          <div className="group-sm">
            <>
              <TextField
                label={<IntlMessages id="preview.edit.label.video_url" />}
                variant="outlined"
                size="small"
                className="block"
                name="url"
                onChange={e => updateCurrentVideo(e)}
                defaultValue={currentVideo.url}
                helperText={
                  (errors && errors[index] && errors[index].url)
                    ? <IntlMessages key={errors[index].url.type} id={errors[index].url.message} /> : null
                }
              />
              <span className="locked-attribute locked-attribute-videos"
                    onClick={e => onRemoveLockedAttribute(e, 'videos.' + index + '.url')}
              >
              { getLockIcon('videos.' + index + '.url', 'left') }
            </span>
            </>
          </div>

          <div className="group-sm">
            <>
              <TextField
                label={<IntlMessages id="preview.edit.label.video_preview_url" />}
                variant="outlined"
                size="small"
                className="block"
                name="previewUrl"
                onChange={e => updateCurrentVideo(e)}
                defaultValue={currentVideo.previewUrl}
                helperText={
                  (errors && errors[index] && errors[index].preview_url)
                    ? <IntlMessages key={errors[index].preview_url.type} id={errors[index].preview_url.message} /> : null
                }
              />
              <span className="locked-attribute locked-attribute-videos"
                    onClick={e => onRemoveLockedAttribute(e, 'videos.' + index + '.preview_url')}
              >
              { getLockIcon('videos.' + index + '.preview_url', 'left') }
            </span>
            </>
          </div>

          <div className="group-sm">
            <>
              <TextField
                label={<IntlMessages id="preview.edit.label.title.en" />}
                variant="outlined"
                size="small"
                className="block"
                name="title_en"
                onChange={e => updateCurrentVideo(e)}
                defaultValue={currentVideo.title ? currentVideo.title.en : ''}
              />
              <span className="locked-attribute locked-attribute-videos"
                    onClick={e => onRemoveLockedAttribute(e, 'videos.' + index + '.title.en')}
              >
              { getLockIcon('videos.' + index + '.title.en', 'left') }
            </span>
            </>
          </div>

          <div className="group-sm">
            <>
              <TextField
                label={<IntlMessages id="preview.edit.label.title.he" />}
                variant="outlined"
                size="small"
                className="block"
                name="title_he"
                onChange={e => updateCurrentVideo(e)}
                defaultValue={currentVideo.title ? currentVideo.title.he : ''}
              />
              <span className="locked-attribute locked-attribute-videos"
                    onClick={e => onRemoveLockedAttribute(e, 'videos.' + index + '.title.he')}
              >
              { getLockIcon('videos.' + index + '.title.he', 'left') }
            </span>
            </>
          </div>

          <hr/>

          <Button
            variant="contained"
            onClick={e => editProductVideos(e, null)}
            color="primary"
            className="btn-info text-white"
            style={{float: 'right', width: '70px'}}
          >
            <IntlMessages id="buttons.cancel" />
          </Button>

          <div style={{float: 'right', padding: '5px'}} />

          <Button
            variant="contained"
            onClick={e => saveProductVideos(e, index)}
            color="primary"
            className="text-white"
            style={{float: 'right', width: '70px'}}
          >
            <IntlMessages id="buttons.save" />
          </Button>

          <div style={{clear: 'both'}} />

        </div>
      </div>
    );
  }

  return (<></>);
});

const VideoForm = (props) => {
  const listClassName = props.edit === null ? 'img-list' : 'vid-list';
  const containerClassName = props.edit === null ? 'flex flex-wrap img-container' : 'flex flex-wrap vid-container';
  const videoList = props.edit === 'new' ? [{}] : props.videosList;

  return (
    <div className={listClassName}>
      <div className={containerClassName}>
        {videoList.map((vid, index) => (
          <VideoFormItem
            key={vid.url}
            index={index}
            vid={vid}
            edit={props.edit}
            addProductVideos={props.addProductVideos}
            updateProductVideos={props.updateProductVideos}
            removeProductVideos={props.removeProductVideos}
            onRemoveLockedAttribute={props.onRemoveLockedAttribute}
            getLockIcon={props.getLockIcon}
            productProps={props.productProps}
            errors={props.errors}
            handleOpenEnrichmentPreview={props.handleOpenEnrichmentPreview}
            setEditProductVideos={props.setEditProductVideos}
            {...props}
          />
        ))}
      </div>
    </div>
  )
};

const ProductVideosComponent = memo((props) => {
  const {
    productId,
    productType,
    videos,
    errors,
    addProductVideos,
    removeProductVideos,
    updateProductVideos,
    updateProductVideosEnrichment,
    sendEnrichmentRetriggerVideos,
    onRemoveLockedAttribute,
    getLockIcon,
    getProductsEnrichmentDataVideos,
    enrichmentVideos,
  } = props;
  const [localItems, setItems] = useState(videos);
  const [localEnrichmentVideos, setEnrichmentVideos] = useState(enrichmentVideos);
  const [isOpenedEnrichmentDialog, setIsOpenedEnrichmentDialog] = useState(false);
  const [isOpenedEnrichmentPreview, setIsOpenedEnrichmentPreview] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState(null);
  const [preview, setPreview] = useState([]);
  const [edit, setEdit] = useState(null);

  useEffect(
    () => {
      setItems(videos);
      if (enrichmentVideos && enrichmentVideos[0] && enrichmentVideos[0].videos) {
        setEnrichmentVideos(enrichmentVideos[0].videos);
      }
      if (selectedVideos === null) {
        const selectedVideos = [];
        for (let i = 0; i < videos.length; i++) {
          selectedVideos.push(videos[i].url);
        }
        setSelectedVideos(selectedVideos);
      }
    },
    [videos, enrichmentVideos, selectedVideos],
  );

  const addNewProductVideo = (e) => {
    setEdit('new');
  }

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  const handleShowVideoEnrichmentDialog = () => {
    getProductsEnrichmentDataVideos(productId, 1, 10);
    setIsOpenedEnrichmentDialog(true);
  };

  const handleCloseEnrichmentDialog = () => {
    setSelectedVideos(null);
    setIsOpenedEnrichmentDialog(false);
  };

  const handleOpenEnrichmentPreview = (e, video) => {
    setPreview(video);
    setIsOpenedEnrichmentPreview(true);
  };

  const handleCloseEnrichmentPreview = () => {
    setIsOpenedEnrichmentPreview(false);
  };

  const handleSelectEnrichmentVideo = (e, url) => {
    const index = selectedVideos.indexOf(url);

    if (index === -1) {
      selectedVideos.push(url);
    } else {
      selectedVideos.splice(index, 1);
    }
    const selectedVideosUnique = selectedVideos.filter(onlyUnique);
    setSelectedVideos(selectedVideosUnique);
  };

  const handleAddEnrichmentVideos = () => {
    const videos = localItems && localItems.length > 0 ? localItems : [];
    const allVideosUrls = [];
    const allVideos = [];

    for (let i = 0; i < videos.length; i++) {
      let isInEnrichment = false;
      for (let j = 0; j < localEnrichmentVideos.length; j++) {
        if (videos[i].url === localEnrichmentVideos[j].url) {
          isInEnrichment = true;
        }
      }

      if (isInEnrichment === false && allVideosUrls.indexOf(videos[i].url) === -1) {
        allVideosUrls.push(videos[i].url);
        allVideos.push(videos[i]);
      }
    }

    for (let i = 0; i < selectedVideos.length; i++) {
      for (let j = 0; j < localEnrichmentVideos.length; j++) {
        if (selectedVideos[i] === localEnrichmentVideos[j].url) {
          if (allVideosUrls.indexOf(selectedVideos[i]) === -1) {
            allVideosUrls.push(selectedVideos[i]);
            allVideos.push(localEnrichmentVideos[j]);
          }
        }
      }
    }

    setItems(allVideos);
    updateProductVideosEnrichment(allVideos);
    setIsOpenedEnrichmentDialog(false);
  }

  const videoFormClassName = localItems.length > 0
    ? 'images-list-dnd min-height-250 product-images-list-wrapper flex direction-column'
    : 'images-list-dnd product-images-list-wrapper direction-column';


  return (
    <>
      <div className="attributes-fields">
        <div key="videos" className="attributes-group-1">

          <h4 className="attribute-title">
            <div style={{float: 'left'}}><IntlMessages id="pim.dialog.videos.label" /></div>
            {productType === 'parent' && (
              <div onClick={handleShowVideoEnrichmentDialog} style={{float: 'right', cursor: 'pointer'}}>
                <IntlMessages id="pim.dialog.videos.enrichment.label" />
              </div>
            )}
            <div style={{clear: 'both'}} />
          </h4>

          <div className={videoFormClassName}>
            <VideoForm
              edit={edit}
              videosList={!isEmpty(localItems) ? localItems : []}
              errors={errors}
              addProductVideos={addProductVideos}
              removeProductVideos={removeProductVideos}
              updateProductVideos={updateProductVideos}
              onRemoveLockedAttribute={onRemoveLockedAttribute}
              getLockIcon={getLockIcon}
              handleOpenEnrichmentPreview={handleOpenEnrichmentPreview}
              setEditProductVideos={setEdit}
            />
          </div>

          {edit === null && (
            <div className="group-sm">
              <Fab
                color="primary"
                aria-label="Add"
                className="product-edit-image-add"
                onClick={addNewProductVideo}
              >
                <AddIcon />
              </Fab>
            </div>
          )}

        </div>
      </div>
      <VideosEnrichmentDialog
        key={`videos-enrichment-dialog-${productId}`}
        sendEnrichmentRetriggerVideos={sendEnrichmentRetriggerVideos}
        isOpened={isOpenedEnrichmentDialog}
        onClose={handleCloseEnrichmentDialog}
        enrichmentVideos={localEnrichmentVideos}
        onShowPreview={handleOpenEnrichmentPreview}
        onAddVideosClick={handleAddEnrichmentVideos}
        onSelectVideoClick={handleSelectEnrichmentVideo}
        selectedVideos={selectedVideos}
      />
      <VideosEnrichmentPreview
        key={`videos-enrichment-preview-${productId}`}
        isOpened={isOpenedEnrichmentPreview}
        onClose={handleCloseEnrichmentPreview}
        video={preview}
      />
    </>
  );
});

ProductVideosComponent.propTypes = {
  productId: string,
  productType: string,
  videos: arrayOf(shape()),
  errors: arrayOf(shape()),
  addProductVideos: func,
  removeProductVideos: func,
  updateProductVideos: func,
  updateProductVideosEnrichment: func,
  sendEnrichmentRetriggerVideos: func,
  onRemoveLockedAttribute: func,
  getLockIcon: func,
  getProductsEnrichmentDataVideos: func,
  enrichmentVideos: shape(),
};

ProductVideosComponent.defaultProps = {
  productId: null,
  productType: null,
  videos: [],
  errors: [],
  addProductVideos: null,
  removeProductVideos: null,
  updateProductVideos: null,
  updateProductVideosEnrichment: null,
  sendEnrichmentRetriggerVideos: null,
  onRemoveLockedAttribute: null,
  getLockIcon: null,
};

const mapStateToProps = state => ({
  enrichmentVideos: state.pim.enrichmentVideos,
});

export const mapDispatchToProps = {
  getProductsEnrichmentDataVideos
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductVideosComponent);
