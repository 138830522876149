import { createSelector } from 'reselect';

const pimRootSelector = state => state;

const stateSelector = createSelector(
  pimRootSelector,
  state => ({
    productsImagesOverride: state.productsImagesOverride,
    list: state.list,
    productsWithoutImages: state.productsWithoutImages,
    isEditMode: state.isEditMode,
    fetchProductsByIdsStarted: state.fetchProductsByIdsStarted,
    updatingMultiple: state.updatingMultiple,
    fetchProductsCommentsCountStart: state.fetchProductsCommentsCountStart,
  }),
);

export const pimSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  pimSelector,
};
