/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

import {
  mapPrefixToFilters,
} from '../utils/mappers';
import {
  mapFilters,
} from '../../../../../util/attributesMatrixMapping';

export const usePromotion = ({
  setHiddenProductsExist,
  setHiddenProductsIds,
  fetchPromotionList,
  clearFromPromotionState,
  fetchProductList,
  setSelectedPromotion,
  selectedPromotion,
  compoundSearch,
  isHiddenProductsMode,
  hiddenProductsFilter,
  facetsPreFilters,
  pagination,
  selectedAction,
  previewFetched,
  searchQuery,
  promotionUpdated,
  item,
  attributesMatrixList,
}) => {
  const maxPagination = {
    page: 1,
    limit: 500,
  };
  const promotionFilter = [{
    group: [{
      field: 'type',
      value: 'promotion_url',
      operator: 'eq',
    }],
  }];

  const findAndUpdateFilterById = (promotion) => {
    const { virtualParams } = promotion;
    const { filters } = virtualParams || {};
    const hiddenProductsExist = filters
      ? filters.find(f => f.group
        .some(fG => fG.field === 'id' && fG.operator === 'nin'))
      : false;
    const currentGroup = hiddenProductsExist
      ? hiddenProductsExist.group.find(g => g.field === 'id')
      : {};

    setHiddenProductsExist(!!hiddenProductsExist);
    setHiddenProductsIds(currentGroup.value || []);
  };

  useEffect(() => {
    fetchPromotionList(promotionFilter, maxPagination);
  }, []);

  useEffect(() => {
    if (promotionUpdated && !isEmpty(selectedPromotion)) {
      const visibilityActions = new Set(['hide', 'show', 'unpin', 'pin_to_top', 'pin_to_position', 'pin_to_bottom']);

      clearFromPromotionState({
        list: [],
        fetching: false,
        fetched: false,
      });
      fetchPromotionList(promotionFilter, maxPagination);
      fetchProductList({
        viewMode: 'frontendWithParams',
        compoundSearch,
        filter: isHiddenProductsMode
          ? hiddenProductsFilter
          : mapPrefixToFilters(facetsPreFilters),
        pagination,
        searchFilter: isHiddenProductsMode
          ? {}
          : searchQuery,
        ignoreFacets: visibilityActions.has(selectedAction),
      });

      setSelectedPromotion({
        ...item,
        virtualParams: {
          ...item.virtualParams,
          filters: mapFilters(
            item.virtualParams.filters,
            attributesMatrixList,
          ),

        },
      });
    }
  }, [promotionUpdated]);

  useEffect(() => {
    if (!isEmpty(selectedPromotion)) {
      findAndUpdateFilterById(selectedPromotion);
    }
  }, [
    selectedPromotion,
    promotionUpdated,
    previewFetched,
  ]);
};

export default usePromotion;
