import { createSelector } from 'reselect';

const virtualCategoryRootSelector = state => state;

const stateSelector = createSelector(
  virtualCategoryRootSelector,
  virtualCategory => ({
    updating: virtualCategory.updating,
    updated: virtualCategory.updated,
  }),
);

export const virtualCategorySelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  virtualCategorySelector,
};
