/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import { connect } from 'react-redux';
import {
  string, func, arrayOf, shape, bool, number,
} from 'prop-types';

import VariantLevelList from '../../components/VariantLevelList';
import { pimSelector } from '../../pages/Home/selectors/pim';

import {
  fetchVariantLevelChildren,
  fetchCountProductsActivitiesByProductIds,
  toggleSelectedProduct,
  fetchVariantParents,
  bulkDeleteProductLockedAttributes,
} from '../../../../actions/pim';

import {
  fetchActivityLogsByProductId,
  multipleUpdateUserActivityLogs,
} from '../../../../actions/product';

const VariantContainer = memo((props) => {
  const { pimProps } = props;

  const searchFields = [
    ...pimProps.globalFields[pimProps.filterTarget],
    ...pimProps.savedFilterFields[pimProps.filterTarget],
  ];

  const handleExpandVariantLevel = (variant) => {
    const { childrenIds } = variant;

    if (pimProps.filterTarget === 'parent') props.fetchVariantLevelChildren(childrenIds, variant);
    else props.fetchVariantParents(variant);
  };

  const handleUnlockAttributesClick = ({ variantProductItem }) => {
    props.bulkDeleteProductLockedAttributes({
      productIds: [variantProductItem.id],
      fields: [...searchFields],
      filterTarget: pimProps.filterTarget,
    });
  };

  const onProductSelect = productItem => props.toggleSelectedProduct(productItem);

  const pimListActions = {
    onProductSelect,
    handleExpandVariantLevel,
    onUnlockAttributesClick: handleUnlockAttributesClick,
    fetchActivityLogsByProductId: props.fetchActivityLogsByProductId,
    multipleUpdateUserActivityLogs: props.multipleUpdateUserActivityLogs,
  };

  const pimListProps = {
    isEditMode: pimProps.isEditMode,
    defaultImage: pimProps.defaultImage,
    selectedProducts: pimProps.selected,
    filterTarget: pimProps.filterTarget,
    productsCommentsCount: pimProps.productsCommentsCount,
    lastParentChildrenLoaded: pimProps.lastParentChildrenLoaded,
    fetchVariantLevelChildrenProductsStarted: pimProps.fetchVariantLevelChildrenProductsStarted,
    bulkDeleteProductLockedAttributesStarted: pimProps.bulkDeleteProductLockedAttributesStarted,
    bulkDeleteProductLockedAttributesFinished:
      pimProps.bulkDeleteProductLockedAttributesFinished,
  };

  return (
    <VariantLevelList
      listProps={{
        searchFields,
        parent: props.parent,
        variants: props.variants,
        onEditClick: props.onEditClick,
        disableEdit: props.disableEdit,
        productComments: props.productComments,
        tableHeaderWidth: props.tableHeaderWidth,
        productAttributes: props.productAttributes,
        variantsCollapsed: props.variantsCollapsed,
        categoriesAttributes: props.categoriesAttributes,
        setVariantsCollapsed: props.setVariantsCollapsed,
        certainProductAttributes: props.certainProductAttributes,
        productCommentsLoading: props.fetchProductCommentsStart,
        commentMessages: props.commentMessages,
        ...pimListActions,
        ...pimListProps,
      }}
    />
  );
});

VariantContainer.propTypes = {
  variants: arrayOf(shape()).isRequired,
  parent: shape().isRequired,
  certainProductAttributes: arrayOf(shape()).isRequired,
  productComments: arrayOf(shape()).isRequired,
  variantsCollapsed: arrayOf(string).isRequired,
  categoriesAttributes: arrayOf(shape()).isRequired,
  pimProps: shape().isRequired,
  productAttributes: arrayOf(shape()).isRequired,
  setVariantsCollapsed: func.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  fetchVariantLevelChildren: func.isRequired,
  bulkDeleteProductLockedAttributes: func.isRequired,
  toggleSelectedProduct: func.isRequired,
  onEditClick: func.isRequired,
  tableHeaderWidth: number,
  fetchVariantParents: func.isRequired,
  disableEdit: bool,
  commentMessages: arrayOf(shape()).isRequired,
  multipleUpdateUserActivityLogs: func.isRequired,
  fetchProductCommentsStart: bool.isRequired,
};

VariantContainer.defaultProps = {
  tableHeaderWidth: 1000,
  disableEdit: false,
};

const mapStateToProps = state => ({
  pimProps: pimSelector(state.pim),
  system: state.system,
  categoriesAttributes: state.productAttribute.categoriesAttributes,
  certainProductAttributes: state.productAttribute.certainProductAttributes,
  productAttributes: state.productAttribute.productAttributes,
  commentMessages: state.commentMessage.list,
  productComments: state.product.productComments,
  fetchProductCommentsStart: state.product.fetchProductCommentsStart,
});

const mapDispatchToProps = {
  fetchVariantLevelChildren,
  fetchCountProductsActivitiesByProductIds,
  bulkDeleteProductLockedAttributes,
  toggleSelectedProduct,
  fetchActivityLogsByProductId,
  fetchVariantParents,
  multipleUpdateUserActivityLogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(VariantContainer);
