import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  PREVIEW_SET_SELECTED_PRODUCTS,
  PREVIEW_SET_PRODUCT_FOR_EDIT,
  PREVIEW_CLEAR_PRODUCT_STATE,
  PREVIEW_CLEAR_PARENT_LEVEL_CHILDREN_PRODUCTS,
  PREVIEW_CLEAR_VARIANT_LEVEL_CHILDREN_PRODUCTS,
  PREVIEW_SET_ALL_PRODUCTS_AS_SELECTED,
  PREVIEW_FETCH_PRODUCT_BY_IDS_START,
  PREVIEW_FETCH_PRODUCT_BY_IDS_FINISHED,
  PREVIEW_SET_PRODUCTS_LIST_EMPTY,
  PREVIEW_INJECT_UPDATED_PRODUCTS,
  PREVIEW_FETCH_PRODUCTS_COMMENTS_COUNT_START,
  PREVIEW_FETCH_PRODUCTS_COMMENTS_COUNT_FINISHED,
  PREVIEW_SET_SELECTED_PROMOTION,
  PREVIEW_SET_SELECTED_SEARCH_FILTER,
  PREVIEW_SET_SELECTED_ENTITY_NAME,
  PREVIEW_SET_UPDATED_PRODUCTS_OVERRIDES,
  PREVIEW_SET_UPDATED_PRODUCTS_IMAGES_OVERRIDES,
  SET_PREVIEW_LIST_DIRECTION,
  PREVIEW_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START,
  REVIEW_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED,
  FETCH_SELECTED_CATEGORY_FINISHED,
  FETCH_SELECTED_CATEGORY_START,
  PREVIEW_SET_SELECTED_CATEGORY,
  PREVIEW_SET_CONTAINER_STYLE,
  PREVIEW_SET_EDIT_MODE,
  PREVIEW_SET_PAGINATION,
  PREVIEW_SET_SEARCH_QUERY,
  PREVIEW_SET_DISPLAY_MODE,
  PREVIEW_SET_HIDDEN_PRODUCTS_MODE,
  PREVIEW_SET_COMPOUND_SEARCH,
  PREVIEW_SET_SELECTED_ACTION,
  PREVIEW_SET_VIRTUAL_FACET_FILTER,
  PREVIEW_SET_GLOBAL_SEARCH_VALUE,
  PREVIEW_SET_HIDDEN_PRODUCTS_FILTER,
  PREVIEW_SET_HIDDEN_PRODUCTS_IDS,
  PREVIEW_SET_HIDDEN_PRODUCTS_IS_EXIST,
  PREVIEW_SET_FACETS_PRE_FILTERS,
  PREVIEW_SET_SEARCH_VALUE,
  PREVIEW_SET_PRODUCTS_OVERRIDE,
  PREVIEW_SET_MODE,
  PREVIEW_SET_ACTION_POPOVER_STATE,
  PREVIEW_SET_ACTION_POPOVER_ANCHOR,
  PREVIEW_SET_SELECTED_PRODUCT,
  PREVIEW_SET_PRODUCT_PIN_ORDER_HELPER_TEXT_ID,
  PREVIEW_SET_SELECTED_PIN_ACTION,
  PREVIEW_SET_PRODUCT_PIN_ORDER,
  PREVIEW_FETCH_PRODUCT_ACTIVITY_FINISHED,
} from '../actions/types';

const initialState = {
  list: [],
  selected: [],
  editInProgress: false,
  edit: {},
  dir: 'ltr',
  facets: [],
  defaultImage: 'http://www.netzerotools.com/assets/images/msa-10162695-workman-arc-flash-full-body-harness.png',
  productsByIds: [],
  fetchProductsByIdsStarted: false,
  fetchProductsByIdsFinished: false,
  productsByIdsForIn: [],
  productsByIdsForNin: [],
  fetchProductsCommentsCountStart: false,
  fetchProductsCommentsCountFinished: false,
  productsCommentsCount: [],
  fetchVariationsCommentsCountStart: false,
  fetchVariationsCommentsCountFinished: false,
  variationsCommentsCount: {},
  selectedPromotion: {},
  selectedSearchFilter: {},
  selectedCategory: {},
  fetchProductCommentsStart: false,
  fetchProductCommentsFinished: false,
  productComments: [],
  productsImagesOverride: [],
  bulkUpdateActivityLogsStart: false,
  bulkUpdateActivityLogsFinished: false,
  containerStyles: {},
  isEditMode: false,
  pagination: { page: 1, limit: 96 },
  searchQuery: {},
  displayMode: 'grid_5_per_row',
  isHiddenProductsMode: false,
  compoundSearch: '',
  selectedAction: '',
  virtualFacetFilter: {},
  globalSearchValue: '',
  hiddenProductsFilter: [],
  hiddenProductsIds: [],
  hiddenProductsExist: false,
  facetsPreFilters: [],
  searchValue: '',
  mode: 'preview',
  productOverride: {},
  showPopover: false,
  actionPopoverAnchor: null,
  selectedProduct: {},
  productPinOrderHeplerTextId: '',
  selectedPinAction: '',
  productPinOrder: '',
  productActivity: {},
};
const product = restTypes('preview');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case product.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case product.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case PREVIEW_SET_SELECTED_PRODUCTS:
      return {
        ...state,
        selected: action.payload,
      };
    case PREVIEW_SET_PRODUCT_FOR_EDIT:
      return {
        ...state,
        ...action.payload,
      };
    case PREVIEW_CLEAR_PRODUCT_STATE:
      return {
        ...restInitState,
        ...initialState,
        dir: state.dir,
      };
    case PREVIEW_CLEAR_PARENT_LEVEL_CHILDREN_PRODUCTS:
      return {
        ...state,
        ...action.payload,
      };
    case PREVIEW_CLEAR_VARIANT_LEVEL_CHILDREN_PRODUCTS:
      return {
        ...state,
        ...action.payload,
      };
    case PREVIEW_SET_ALL_PRODUCTS_AS_SELECTED:
      return {
        ...state,
        ...action.payload,
      };
    case product.updateMultipleStart:
      return {
        ...state,
        ...restStates.updateMultipleStart,
      };
    case product.updateMultipleFinished:
      return {
        ...state,
        ...restStates.updateMultipleFinished,
        ...action.payload,
      };
    case product.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case PREVIEW_FETCH_PRODUCT_BY_IDS_START:
      return {
        ...state,
        ...action.payload,
        fetchProductsByIdsStarted: true,
        fetchProductsByIdsFinished: false,
      };
    case PREVIEW_FETCH_PRODUCT_BY_IDS_FINISHED:
      return {
        ...state,
        ...action.payload,
        fetchProductsByIdsStarted: false,
        fetchProductsByIdsFinished: true,
      };
    case PREVIEW_SET_PRODUCTS_LIST_EMPTY:
      return {
        ...state,
        list: [],
        total: 0,
        aggregation: {},
        aggregation_values: {},
        virtual_facet: {},
      };
    case PREVIEW_INJECT_UPDATED_PRODUCTS:
      return {
        ...state,
        ...action.payload,
      };
    case PREVIEW_FETCH_PRODUCTS_COMMENTS_COUNT_START:
      return {
        ...state,
        ...action.payload,
        fetchProductsCommentsCountStart: true,
        fetchProductsCommentsCountFinished: false,
      };
    case PREVIEW_FETCH_PRODUCTS_COMMENTS_COUNT_FINISHED:
      return {
        ...state,
        ...action.payload,
        fetchProductsCommentsCountStart: false,
        fetchProductsCommentsCountFinished: true,
      };
    case PREVIEW_SET_SELECTED_PROMOTION:
      return {
        ...state,
        ...action.payload,
      };
    case PREVIEW_SET_SELECTED_SEARCH_FILTER:
      return {
        ...state,
        ...action.payload,
      };
    case PREVIEW_SET_SELECTED_ENTITY_NAME:
      return {
        ...state,
        ...action.payload,
      };
    case PREVIEW_SET_UPDATED_PRODUCTS_OVERRIDES:
      return {
        ...state,
        ...action.payload,
      };
    case PREVIEW_SET_UPDATED_PRODUCTS_IMAGES_OVERRIDES:
      return {
        ...state,
        ...action.payload,
      };
    case SET_PREVIEW_LIST_DIRECTION:
      return {
        ...state,
        ...action.payload,
      };
    case PREVIEW_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START:
      return {
        ...state,
        ...action.payload,
      };
    case REVIEW_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_SELECTED_CATEGORY_START:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_SELECTED_CATEGORY_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case PREVIEW_SET_SELECTED_CATEGORY:
      return {
        ...state,
        ...action.payload,
      };
    case PREVIEW_SET_CONTAINER_STYLE:
      return {
        ...state,
        ...action.payload,
      };

    case PREVIEW_SET_EDIT_MODE:
    case PREVIEW_SET_PAGINATION:
    case PREVIEW_SET_SEARCH_QUERY:
    case PREVIEW_SET_DISPLAY_MODE:
    case PREVIEW_SET_HIDDEN_PRODUCTS_MODE:
    case PREVIEW_SET_COMPOUND_SEARCH:
    case PREVIEW_SET_SELECTED_ACTION:
    case PREVIEW_SET_VIRTUAL_FACET_FILTER:
    case PREVIEW_SET_GLOBAL_SEARCH_VALUE:
    case PREVIEW_SET_HIDDEN_PRODUCTS_FILTER:
    case PREVIEW_SET_HIDDEN_PRODUCTS_IDS:
    case PREVIEW_SET_HIDDEN_PRODUCTS_IS_EXIST:
    case PREVIEW_SET_FACETS_PRE_FILTERS:
    case PREVIEW_SET_SEARCH_VALUE:
    case PREVIEW_SET_MODE:
    case PREVIEW_SET_PRODUCTS_OVERRIDE:
    case PREVIEW_SET_ACTION_POPOVER_STATE:
    case PREVIEW_SET_ACTION_POPOVER_ANCHOR:
    case PREVIEW_SET_SELECTED_PRODUCT:
    case PREVIEW_SET_PRODUCT_PIN_ORDER_HELPER_TEXT_ID:
    case PREVIEW_SET_SELECTED_PIN_ACTION:
    case PREVIEW_SET_PRODUCT_PIN_ORDER:
    case PREVIEW_FETCH_PRODUCT_ACTIVITY_FINISHED:
      return {
        ...state,
        ...action.payload,
      };

    default: return state;
  }
};
