/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const usePreviewFieldAttributes = ({
  actions,
}) => {
  useEffect(() => {
    actions.fetchPreviewFieldAttributes();
  }, []);
};

export default usePreviewFieldAttributes;
