import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { NotificationManager } from 'react-notifications';
import { showErrorNotification } from '../util/api';

import IntlMessages from '../util/IntlMessages';

import { clearSessionState } from './session';

import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  RESET_USER_PASSWORD_START,
  RESET_USER_PASSWORD_FINISHED,
  CLEAR_AUTH_STATE,
  VERIFY_USER_RESET_CODE_START,
  VERIFY_USER_RESET_CODE_FINISHED,
  CONFIRM_RESET_USER_PASSWORD_START,
  CONFIRM_RESET_USER_PASSWORD_FINISHED,
} from './types';

export const signinUserInFirebase = user => (dispatch) => {
  const { email, password } = user;

  dispatch({ type: LOGIN_USER });
  firebase.auth()
    .signInWithEmailAndPassword(email, password)
    .then((data) => {
      const { uid, ya: fbToken } = data.user;
      localStorage.setItem('user_id', uid);
      localStorage.setItem('fb_token', fbToken);
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: { user: localStorage.getItem('user_id'), success: true, loading: false },
      });
    })
    .catch((error) => {
      showErrorNotification(error, 'Firebase');
      dispatch({
        type: LOGIN_USER_FAILURE,
        payload: { error: error.message, success: false, loading: false },
      });
      localStorage.removeItem('fb_token');
    });
};

export const requestResetUserPasswordInFirebase = email => (dispatch) => {
  dispatch({ type: RESET_USER_PASSWORD_START });
  firebase.auth().sendPasswordResetEmail(email).then(() => {
    dispatch({
      type: RESET_USER_PASSWORD_FINISHED,
      payload: {
        hasError: false,
      },
    });
  }, () => {
    dispatch({
      type: RESET_USER_PASSWORD_FINISHED,
      payload: {
        hasError: true,
        errorMessage: 'Incorrect email',
      },
    });
  });
};

export const verifyPasswordResetCode = code => (dispatch) => {
  dispatch({ type: VERIFY_USER_RESET_CODE_START });
  firebase.auth().verifyPasswordResetCode(code).then(() => {
    dispatch({
      type: VERIFY_USER_RESET_CODE_FINISHED,
      payload: {
        hasError: false,
        codeInvalid: false,
      },
    });
  }, () => {
    dispatch({
      type: VERIFY_USER_RESET_CODE_FINISHED,
      payload: {
        hasError: true,
        codeInvalid: true,
        errorMessage: 'Code invalid',
      },
    });
  });
};

export const confirmPasswordReset = (password, code) => (dispatch) => {
  dispatch({ type: CONFIRM_RESET_USER_PASSWORD_START });
  firebase.auth().confirmPasswordReset(code, password).then(() => {
    dispatch({
      type: CONFIRM_RESET_USER_PASSWORD_FINISHED,
      payload: {
        hasError: false,
      },
    });
  }, () => {
    dispatch({
      type: CONFIRM_RESET_USER_PASSWORD_FINISHED,
      payload: {
        hasError: true,
      },
    });
  });
};

export const clearAuthState = () => dispatch => dispatch({ type: CLEAR_AUTH_STATE });

export const logOut = () => (dispatch) => {
  firebase.auth().signOut()
    .then(() => {
      dispatch(clearSessionState());
      dispatch({ type: LOGOUT_USER, payload: { logout: true } });
      NotificationManager.success(<IntlMessages id="auth.userLogOutSuccess" />);
      localStorage.removeItem('user_id');
    }).catch((error) => {
      showErrorNotification(error, 'Firebase');
      dispatch({ type: LOGOUT_USER, payload: { error: error.message } });
    });
};
