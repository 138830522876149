import React from 'react';
import {
  func, shape, number, bool,
} from 'prop-types';
import Checkbox from '../../../../components/Checkbox';

const EnabledCheckbox = ({
  user, onChange, disabled,
}) => (
  <Checkbox
    value="test"
    checked={user.enabled}
    onChange={e => onChange(user.id, e)}
    name="enabled"
    disabled={disabled}
  />
);

EnabledCheckbox.propTypes = {
  user: shape({
    id: number,
    enabled: bool,
  }),
  onChange: func,
  disabled: bool,
};

EnabledCheckbox.defaultProps = {
  user: {},
  onChange: null,
  disabled: false,
};

export default EnabledCheckbox;
