import React from 'react';
import {
  arrayOf, shape, bool, func, string, oneOfType,
} from 'prop-types';
import IntlMessages from '../../../../util/IntlMessages';
import ScopeDialog from '../../../../components/ScopeDialog';

const ScopeDialogComponent = (props) => {
  const {
    scopeCategory,
    showScopeDialog,
    selectedTreeItems,
    closeScopeDialog,
    commitCheckedCategories,
    clearScopeCategorySearchState,
    fetchChildScopeCategories,
    handleSearchScopeCategory,
    onToggleScopeCategoryTreeCollapse,
    handleLoadScopeSubcategories,
    loadAllChildCategories,
    ignoreScopeValidation,
    loading,
  } = props;

  return (
    <div className="flex">
      <ScopeDialog
        dialog={{
          closeButtonTitle: <IntlMessages id="users.cancelScopeDialogButtonLabel" />,
          submitButtonTitle: <IntlMessages id="users.selectScopeDialogButtonLabel" />,
        }}
        search={{
          noResult: scopeCategory.searched
            && scopeCategory.searchResults && scopeCategory.searchResults.length === 0,
          placeholder: 'Search virtual categories ...',
          list: scopeCategory.searchResults,
          errorMessage: 'text.errors.lessThan500Characters',
          queryLengthLimit: 500,
        }}
        showScopeDialog={showScopeDialog}
        collapsed={scopeCategory.collapse}
        loadedKeys={scopeCategory.loadedKeys}
        selectedTreeItems={selectedTreeItems}
        treeItems={scopeCategory.treeItems}
        closeScopeDialog={closeScopeDialog}
        onSubmit={commitCheckedCategories}
        toogleTreeComponents={clearScopeCategorySearchState}
        fetchChildCategories={id => fetchChildScopeCategories(id)}
        onSearchSubmit={handleSearchScopeCategory}
        onToggleCategoryTreeCollapse={onToggleScopeCategoryTreeCollapse}
        handleLoadSubcategories={handleLoadScopeSubcategories}
        loadAllChildCategories={loadAllChildCategories}
        multiple
        checkStrictly={false}
        ignoreScopeValidation={ignoreScopeValidation}
        loading={loading}
      />
    </div>
  );
};

ScopeDialogComponent.propTypes = {
  scopeCategory: shape(),
  selectedTreeItems: oneOfType(
    [arrayOf(shape()), arrayOf(string)],
  ),
  showScopeDialog: bool,
  closeScopeDialog: func,
  commitCheckedCategories: func,
  clearScopeCategorySearchState: func,
  fetchChildScopeCategories: func,
  handleSearchScopeCategory: func,
  onToggleScopeCategoryTreeCollapse: func,
  handleLoadScopeSubcategories: func,
  loadAllChildCategories: func,
  ignoreScopeValidation: bool,
  loading: bool,
};

ScopeDialogComponent.defaultProps = {
  scopeCategory: {},
  selectedTreeItems: [],
  showScopeDialog: false,
  closeScopeDialog: null,
  commitCheckedCategories: null,
  clearScopeCategorySearchState: null,
  fetchChildScopeCategories: null,
  handleSearchScopeCategory: null,
  onToggleScopeCategoryTreeCollapse: null,
  handleLoadScopeSubcategories: null,
  loadAllChildCategories: null,
  ignoreScopeValidation: false,
  loading: false,
};

export default ScopeDialogComponent;
