import React, {
  memo,
} from 'react';
import { connect } from 'react-redux';
import {
  string, func, arrayOf, shape, bool,
} from 'prop-types';

import ParentLevelGrid from '../../components/ParentLevelGrid';

import {
  fetchActivityLogsByProductId,
} from '../../../../actions/product';

const ParentContainerGrid = memo((props) => {
  const {
    attributes, certainProductAttributes, initialAttributesOptions,
    isEditMode, productsCommentsCount, productCommentsLoading, defaultImage,
    productComments, gridContainerProps,
  } = props;

  const {
    grid, items, disabled, onSettingsCLick, selectedEntity, onSortEnd,
    pagination, edidActionsAreDisabled,
  } = gridContainerProps;

  return (
    <ParentLevelGrid
      gridListProps={{
        grid,
        items,
        attributes,
        certainProductAttributes,
        disabled,
        onSettingsCLick,
        selectedEntity,
        onSortEnd,
        isEditMode,
        productsCommentsCount,
        fetchActivityLogsByProductId: props.fetchActivityLogsByProductId,
        productCommentsLoading,
        productComments,
        pagination,
        edidActionsAreDisabled,
        defaultImage,
        initialAttributesOptions,
      }}
    />
  );
});

ParentContainerGrid.propTypes = {
  gridContainerProps: shape().isRequired,
  attributes: arrayOf(shape()).isRequired,
  certainProductAttributes: arrayOf(shape()).isRequired,
  productComments: arrayOf(shape()).isRequired,
  productsCommentsCount: arrayOf(shape()).isRequired,
  initialAttributesOptions: arrayOf(shape()).isRequired,
  isEditMode: bool.isRequired,
  productCommentsLoading: bool.isRequired,
  defaultImage: string.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
};

ParentContainerGrid.defaultProps = {};

const mapStateToProps = state => ({
  attributes: state.system.modes.preview,
  certainProductAttributes: state.productAttribute.certainProductAttributes,
  productsCommentsCount: state.preview.productsCommentsCount,
  productCommentsLoading: state.product.fetchProductCommentsStart,
  productComments: state.product.productComments,
  defaultImage: state.preview.defaultImage,
  isEditMode: state.preview.isEditMode,
  initialAttributesOptions: state.productAttribute.initialOptions,
});

const mapDispatchToProps = {
  fetchActivityLogsByProductId,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentContainerGrid);
