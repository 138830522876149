/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  memo, useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  func, arrayOf, shape, bool, number,
} from 'prop-types';
import { isEmpty } from 'lodash';

import VariantLevelList from '../../components/VariantLevelList';
import { previewSelector } from '../../pages/Home/selectors/preview';

import {
  fetchCountProductsActivitiesByProductIds,
  toggleSelectedProduct,
} from '../../../../actions/preview';

import {
  fetchActivityLogsByProductId,
  bulkDeleteProductLockedAttributes,
} from '../../../../actions/product';

const VariantContainer = memo((props) => {
  const {
    variants, parent, certainProductAttributes, productComments,
    preview, isEditMode, categoriesAttributes, productAttributes,
    onEditClick, tableHeaderWidth, disabled, fetchProductCommentsStart,
  } = props;

  useEffect(() => {
    const ids = variants.map(v => v.id);

    if (!isEmpty(ids)) {
      props.fetchCountProductsActivitiesByProductIds(ids);
    }
  }, [parent.id]);

  const handleUnlockAttributesClick = ({ variantProductItem }) => {
    props.bulkDeleteProductLockedAttributes({
      productIds: [variantProductItem.id],
    });
  };

  const onProductSelect = productItem => props.toggleSelectedProduct(productItem);

  return (
    <VariantLevelList
      variants={variants}
      disabled={disabled}
      parent={parent}
      certainProductAttributes={certainProductAttributes}
      onProductSelect={onProductSelect}
      onEditClick={onEditClick}
      selectedProducts={preview.selected}
      categoriesAttributes={categoriesAttributes}
      defaultImage={preview.defaultImage}
      isEditMode={isEditMode}
      onUnclockAttributesClick={handleUnlockAttributesClick}
      productsCommentsCount={preview.productsCommentsCount}
      productCommentsLoading={fetchProductCommentsStart}
      productComments={productComments}
      fetchActivityLogsByProductId={props.fetchActivityLogsByProductId}
      productAttributes={productAttributes}
      tableHeaderWidth={tableHeaderWidth}
    />
  );
});

VariantContainer.propTypes = {
  variants: arrayOf(shape()).isRequired,
  parent: shape().isRequired,
  certainProductAttributes: arrayOf(shape()).isRequired,
  categoriesAttributes: arrayOf(shape()).isRequired,
  preview: shape().isRequired,
  productAttributes: arrayOf(shape()).isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  fetchCountProductsActivitiesByProductIds: func.isRequired,
  bulkDeleteProductLockedAttributes: func.isRequired,
  toggleSelectedProduct: func.isRequired,
  onEditClick: func.isRequired,
  isEditMode: bool.isRequired,
  tableHeaderWidth: number,
  disabled: bool,
  fetchProductCommentsStart: bool.isRequired,
  productComments: arrayOf(shape()).isRequired,
};

VariantContainer.defaultProps = {
  tableHeaderWidth: 1000,
  disabled: false,
};

const mapStateToProps = state => ({
  preview: previewSelector(state.preview),
  system: state.system,

  categoriesAttributes: state.productAttribute.categoriesAttributes,
  certainProductAttributes: state.productAttribute.certainProductAttributes,
  productAttributes: state.productAttribute.productAttributes,
  fetchProductCommentsStart: state.product.fetchProductCommentsStart,
  productComments: state.product.productComments,
});

const mapDispatchToProps = {
  fetchCountProductsActivitiesByProductIds,
  bulkDeleteProductLockedAttributes,
  toggleSelectedProduct,
  fetchActivityLogsByProductId,
};

export default connect(mapStateToProps, mapDispatchToProps)(VariantContainer);
