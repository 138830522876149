import { createSelector } from 'reselect';

const previewFilterRootSelector = state => state;

const stateSelector = createSelector(
  previewFilterRootSelector,
  state => ({
    selectedSavedFilter: state.selectedSavedFilter,
    list: state.list,
  }),
);

export const previewFilterSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  previewFilterSelector,
};
