/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  func, shape, bool, string,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { Divider, CircularProgress } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';

import { fetchNavigation, setNavigationFields } from 'actions/navigation';

import PageTitleBar from 'components/PageTitleBar/PageTitleBar';
import Select from 'components/Select';
import RctCollapsibleCard from 'components/RctCollapsibleCard/RctCollapsibleCard';
import { AbilityContext } from 'components/AbilityContext';

import appPermissions from 'util/appPermissions';
import IntlMessages from 'util/IntlMessages';
import navigationTypes from 'util/constants/navigationTypes';

import Designation from './Designation';
import Filter from './Filter';
import Sort from './Sort';
import Facet from './Facet';
import Actions from './Actions';

const EditNavigation = memo((props) => {
  const { onClose, errors } = props;
  const abilityContext = useContext(AbilityContext);

  useEffect(() => {
    if (!isEmpty(props.match.params.searchFilterId)) {
      props.fetchNavigation(props.match.params.searchFilterId);

      if (props.navigationCreated && !props.isNavigationSaving) {
        NotificationManager.success(<IntlMessages id="navigation.navigationCreated.success" />);
      }
    }
  }, [props.match.params.searchFilterId]);

  const getUrl = ({ prefix = '', value, divider = '_' }) => `${value.includes(prefix)
    ? '' : prefix}${value ? value.toLowerCase().replace(/\s/g, divider)
    : ''}`;

  const handleChangeUrlKey = (e) => {
    const { name, value } = e.target;

    props.setNavigationFields({
      ...props.navigationItem,
      [name]: getUrl({
        value,
        divider: '-',
      }),
    });
  };

  const handleNavigationFieldChange = (e) => {
    const { name, value } = e.target;

    props.setNavigationFields({
      ...props.navigationItem,
      [name]: value,
    });
  };

  const handleSearchPhraseChange = (e) => {
    const { name, value } = e.target;
    const url = value ? value.split(', ') : [];
    props.setNavigationFields({
      ...props.navigationItem,
      [name]: url,
    });
  };

  const getHeaderLine = () => {
    const { name, id } = props.navigationItem;

    if (id && name) {
      return (
        <>
          <span className="separator flex justify-center items-center"> &gt; </span>
          <>
            <span>{name}</span>
            <span className="header-line-id flex justify-center items-center pl-5">{`(${id})`}</span>
          </>
        </>
      );
    }
    return '';
  };

  const isSaving = props.isNavigationSaving
    || props.navigationUpdating
    || props.navigationDeleting
    || props.navigationFetching
    || props.navigationVirtualFacetUpdating
    || props.navigationVirtualFacetDeleting
    || props.navigationVirtualFacetFetching;

  const noPermissions = (!abilityContext.can(
    appPermissions.navigation.permissions.update,
    appPermissions.navigation.name,
    appPermissions.navigation.permissions.create,
    appPermissions.navigation.name,
  ));


  return (
    <div className={`drawer-scroll-container scroll-light rct-page-content ${isSaving ? 'disabled' : ''}`}>
      {isSaving && (
        <div className="loader-main flex direction-column">
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className="progress-warning bottom mb-10"
            size={25}
            thickness={4}
          />
        </div>
      )}
      <PageTitleBar
        titleClassName="block"
        match={props.match}
        title={(
          <div className="flex items-center justify-between block">
            <div className="flex">
              <IntlMessages id="pageTitle.navigationEdit" />
              {props.navigationFetching
                ? <span className="page-title-loading flex justify-center items-center">Loading...</span>
                : getHeaderLine()
              }
            </div>
            {props.navigationFetched && props.navigationItem.type && (
              <Select
                value={props.navigationItem.type}
                options={navigationTypes}
                onChange={handleNavigationFieldChange}
                name="type"
                selectClassName="simple-select select-xs"
                className="select-wrapper select-underline-hide"
                label={<IntlMessages id="text.searchType" />}
                disabled={noPermissions || isSaving}
              />
            )}
          </div>
        )}
      />
      <RctCollapsibleCard>
        <Actions type="gray" />
        <Divider className="divider-dashed divider-gap-md" />
        <Designation
          name={props.navigationItem.name}
          searchPhrase={props.navigationItem.searchPhrase}
          urlKey={props.navigationItem.urlKey}
          type={props.navigationItem.type}
          onTextFieldChange={handleNavigationFieldChange}
          onSearchPhraseChange={handleSearchPhraseChange}
          onUrlKeyChange={handleChangeUrlKey}
          errors={errors}
          existedSearchPhrase={props.existedSearchPhrase}
          disabled={false} // TODO
        />
      </RctCollapsibleCard>

      <RctCollapsibleCard className="p-0">
        <Filter viewMode="edit" />
      </RctCollapsibleCard>

      <RctCollapsibleCard className="p-0">
        <Sort />
      </RctCollapsibleCard>

      <RctCollapsibleCard className="p-0">
        <Facet viewMode="edit" />
      </RctCollapsibleCard>

      <RctCollapsibleCard>
        <Actions gap="0" onClose={onClose} />
      </RctCollapsibleCard>
    </div>
  );
});

EditNavigation.propTypes = {
  fetchNavigation: func.isRequired,
  onClose: func,
  match: shape().isRequired,
  errors: shape().isRequired,
  navigationFetched: bool.isRequired,
  navigationFetching: bool.isRequired,
  setNavigationFields: func.isRequired,
  existedSearchPhrase: string.isRequired,

  navigationItem: shape().isRequired,

  isNavigationSaving: bool.isRequired,
  navigationUpdating: bool.isRequired,
  navigationCreated: bool.isRequired,
  navigationDeleting: bool.isRequired,

  navigationVirtualFacetFetching: bool.isRequired,
  navigationVirtualFacetUpdating: bool.isRequired,
  navigationVirtualFacetDeleting: bool.isRequired,
};

EditNavigation.defaultProps = {
  onClose: null,
};

const mapStateToProps = state => ({
  selectedPromotion: state.promotionWidget.selectedPromotion,
  selectedSearchFilter: state.preview.selectedSearchFilter,

  drawerState: state.quickEditDrawer.drawerState,
  navigationFetched: state.navigation.fetchedOne,
  navigationFetching: state.navigation.fetchingOne,
  navigationItem: state.navigation.item,
  isNavigationSaving: state.navigation.isNavigationSaving,
  navigationUpdating: state.navigation.updating,
  navigationCreated: state.navigation.created,
  navigationDeleting: state.navigation.deleting,
  errors: state.navigation.errors,
  existedSearchPhrase: state.navigation.existedSearchPhrase,
  isDataChanged: state.navigation.isDataChanged,

  navigationVirtualFacetFetching: state.navigationVirtualFacet.fetching,
  navigationVirtualFacetUpdating: state.navigationVirtualFacet.updating,
  navigationVirtualFacetDeleting: state.navigationVirtualFacet.deleting,
});

const mapDispatchToProps = {
  fetchNavigation,
  setNavigationFields,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditNavigation));
