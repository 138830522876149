import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  SET_VIRTUAL_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE,
} from '../actions/types';

const initialState = {};
const virtualCategoryVirtualFacetRestTypes = restTypes('virtual_category_virtual_facet');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case virtualCategoryVirtualFacetRestTypes.createStart:
      return {
        ...state,
        ...restStates.createStart,
      };
    case virtualCategoryVirtualFacetRestTypes.createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        ...action.payload,
      };
    case virtualCategoryVirtualFacetRestTypes.fetchStart:
      return {
        ...state,
        ...restStates.fetchStart,
      };
    case virtualCategoryVirtualFacetRestTypes.fetchFinished:
      return {
        ...state,
        ...restStates.fetchFinished,
        ...action.payload,
      };
    case virtualCategoryVirtualFacetRestTypes.deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
      };
    case virtualCategoryVirtualFacetRestTypes.deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };
    case virtualCategoryVirtualFacetRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case virtualCategoryVirtualFacetRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case virtualCategoryVirtualFacetRestTypes.updateStart:
      return {
        ...state,
        ...restStates.updateStart,
      };
    case virtualCategoryVirtualFacetRestTypes.updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };
    case virtualCategoryVirtualFacetRestTypes.clearStateFinished:
      return {
        ...initialState,
        ...restInitState,
      };
    case SET_VIRTUAL_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
};
