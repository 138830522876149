import React, { memo } from 'react';
import {
  func, shape, node, string, arrayOf,
} from 'prop-types';
import { isEmpty } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';

import IntlMessages from '../../../util/IntlMessages';
import Field from '../../Field';

const AddComments = memo(({
  content,
  comment,
  systemComment,
  onSystemCommentChange,
  onCommentChange,
  errors,
  commentMessagesOptions,
  className,
}) => {
  const commentErrorClass = !isEmpty(errors.comment) ? 'has-error input-padding-clear' : '';
  const systemCommentErrorClass = !isEmpty(errors.systemComment) ? 'has-error input-padding-clear' : '';

  return (
    <div className={`flex comment-actions-wrapper ${className}`}>
      <Field
        hideStaticLabel
        hideFormErrors
        element="text"
        label={<IntlMessages id="preview.productDialog.comment.label" />}
        variant="outlined"
        className={`input-wrapper min-w-sm input-bordered form-group input-select field-white field-secondary min-w-md ${commentErrorClass}`}
        wrapperClassName="flex-wrap"
        name="comment"
        size="small"
        value={comment}
        onChange={onCommentChange}
        suffix={!isEmpty(errors.comment) && (
          <Tooltip
            placement="top-end"
            title={(
              <div className="tooltip-title-content-wrapper">
                {errors.comment.map(e => <IntlMessages id={e.message} key={e.message} />)}
              </div>
            )}
          >
            <i className="ti-info-alt" />
          </Tooltip>
        )}
      />
      <Field
        hideStaticLabel
        hideFormErrors
        element="dropdown"
        label={<IntlMessages id="preview.productDialog.systemComment.label" />}
        className={`comment-field select-wrapper min-w-sm select-bordered form-group input-select field-white field-secondary ml-10 mb-0 ${systemCommentErrorClass}`}
        wrapperClassName="flex-wrap"
        name="systemComment"
        value={systemComment}
        onChange={onSystemCommentChange}
        options={commentMessagesOptions}
        suffix={!isEmpty(errors.systemComment) && (
          <Tooltip
            placement="top-end"
            title={(
              <div className="tooltip-title-content-wrapper">
                {errors.systemComment.map(
                  e => <IntlMessages id={e.message} key={e.message} />,
                )}
              </div>
            )}
          >
            <i className="ti-info-alt" />
          </Tooltip>
        )}
      />
      {content}
    </div>
  );
});


AddComments.propTypes = {
  content: node,
  onCommentChange: func.isRequired,
  comment: string,
  errors: shape({}),
  systemComment: string,
  className: string,
  commentMessagesOptions: arrayOf(shape({
    value: string,
    title: string,
  })),
  onSystemCommentChange: func.isRequired,
};

AddComments.defaultProps = {
  content: null,
  comment: '',
  systemComment: '',
  className: '',
  errors: {},
  commentMessagesOptions: [],
};

export default AddComments;
