import React, {
  memo, useState, useCallback, useEffect,
} from 'react';
import { string, shape, func } from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  TextField,
  InputAdornment, FormControl, Select,
  InputLabel, Button,
} from '@material-ui/core';
import {
  ArrowUpward, ArrowDownward,
} from '@material-ui/icons';

import { getPublishEditPrice, getPublishedPriceOffset, moneyFormat } from
    'pages/Pim/pages/Home/utils/productDataGenerator';
import { fetchActivityLogsByProductId, setFields } from 'actions/product';
import IntlMessages from 'util/IntlMessages';
import { stockStatusList } from 'util/constants/constants';

import getMerchantFieldValue from './utils/getMerchantFieldValue';
import useMerchantEditEffects from './hooks/useMerchantEdit';
import { productSelector } from './selectors/productSelector';
import {getMerchantFieldInfo} from "./utils/getMerchantFieldInfo";
import { Checkbox } from '@material-ui/core';

const MerchantDetailContainer = memo((props) => {
  const {
    className, productProps, currencyItem, errors,
  } = props;

  const [showMerchantInfo, setShowMerchantInfo] = useState(null);
  const [sendIlsPrice, setSendIlsPrice] = useState(null);
  const [ilsError, setIllsError] = useState(false);
  const [ilsValue, setIlsValue] = useState(null);

  useMerchantEditEffects({
    productToEdit: productProps.productToEdit,
    productUpdated: productProps.productUpdated,
    getProductComments: props.fetchActivityLogsByProductId,
  });

  const handleMerhcantLevelChange = ({ value, name, id }) => {
    const newValue = getMerchantFieldValue(value, name);
    props.setFields({
      ...productProps.fields,
      [id]: {
        ...productProps.fields[id],
        [name]: newValue,
      },
      sendIlsPrice
    });
  };

  const merchantLevelFields = {};

  if (!isEmpty(productProps.fields.stock)) {
    merchantLevelFields.stock = {
      ...merchantLevelFields.stock,
      quantity: {
        onChange: handleMerhcantLevelChange,
        value: productProps.fields.stock.qty,
      },
      status: {
        onChange: handleMerhcantLevelChange,
        value: productProps.fields.stock.status,
      },
    };
  }

  if (!isEmpty(productProps.fields.price)) {
    merchantLevelFields.price = {
      ...merchantLevelFields.price,
      finalPrice: {
        value: productProps.fields.price.finalPrice,
      },
      minPrice: {
        value: productProps.fields.price.minPrice,
      },
      minPublishPrice: {
        value: productProps.fields.price.minPublishPrice,
      },
      ils: {
        value: productProps.fields.price.ils,
      },
      msrp: {
        onChange: handleMerhcantLevelChange,
        value: productProps.fields.price.msrp,
      },
      shippingCost: {
        onChange: handleMerhcantLevelChange,
        value: productProps.fields.price.shippingCost,
      },
      profitLost: {
        onChange: handleMerhcantLevelChange,
        value: (productProps.fields.price.profit / productProps.fields.price.cost) * 100,
      },
    };

    if (productProps.productToEdit.price.ils && sendIlsPrice === null) {
      setSendIlsPrice(true);
    }

    if (ilsValue === null) {
      const ilsValue = Math.round(merchantLevelFields.price.ils.value) || getPublishEditPrice(
        merchantLevelFields.price.finalPrice.value,
        currencyItem.value,
        productProps.fields.isDomestic
      ) || 0;

      setIlsValue(ilsValue);
    }
  }

  const handleSetIlsPrice = (e) => {
    if (sendIlsPrice === true) {
      setSendIlsPrice(false);
      props.setFields({
        ...productProps.fields,
        price: {
          ...productProps.fields.price,
        },
        sendIlsPrice: false,
      });
    } else {
      setSendIlsPrice(true);
      props.setFields({
        ...productProps.fields,
        price: {
          ...productProps.fields.price,
        },
        sendIlsPrice: true,
      });
    }
  };

  const handleFieldChange = (event) => {
    const { value, name, id } = event.target;

    if (merchantLevelFields.stock.quantity.onChange) {
      merchantLevelFields.stock.quantity.onChange({ value, name, id });
    }
  };

  const handleFinalPriceChange = useCallback((event) => {
    const { value, id } = event.target;
    const offset = getPublishedPriceOffset(+value, currencyItem.value, productProps.fields.isDomestic);
    const profit = (+value - productProps.fields.price.cost - productProps.fields.price.shippingCost).toFixed(2);
    const ilsValue = Math.round(+value * offset);
    props.setFields({
      ...productProps.fields,
      [id]: {
        ...productProps.fields[id],
        finalPrice: value ? +value : value,
        ils: +ilsValue,
        profit: +profit,
      },
      sendIlsPrice
    });
    setIlsValue(ilsValue);
  }, [currencyItem.value, productProps.fields, props, sendIlsPrice]);

  const handlePublishPriceChange = useCallback((event) => {
    const { value, id } = event.target;
    const saveButton = document.getElementById('save-button');
    const saveAndCloseButtonButton = document.getElementById('save-and-close-button');

    setIllsError(false);
    if (saveButton && saveAndCloseButtonButton) {
      saveButton.className = saveButton.className.replace('disabled', '');
      saveAndCloseButtonButton.className = saveAndCloseButtonButton.className.replace('disabled', '');
    }

    if (value && value > 0) {
      const blockerPriceMax = productProps.fields.isDomestic ? 1 : 120 * 1.17;
      const price = +value / currencyItem.value;
      const finalPrice = productProps.fields.isDomestic ? price : (price > blockerPriceMax ? price / 1.17 : price);
      const profit = +(
        finalPrice -
        productProps.fields.price.cost -
        productProps.fields.price.shippingCost
      ).toFixed(2);
      if (!productProps.fields.isDomestic && price > 120 && price <= blockerPriceMax) {
        setIllsError(true);
        if (saveButton && saveAndCloseButtonButton) {
          saveButton.className += ' disabled';
          saveAndCloseButtonButton.className += ' disabled';
        }
      }
      props.setFields({
        ...productProps.fields,
        [id]: {
          ...productProps.fields[id],
          finalPrice: +(finalPrice.toFixed(2)),
          ils: +value,
          profit: +profit,
        },
        sendIlsPrice
      });
    } else {
      setIllsError(true);
      if (saveButton && saveAndCloseButtonButton) {
        saveButton.className += ' disabled';
        saveAndCloseButtonButton.className += ' disabled';
      }
    }

    setIlsValue(value);
  }, [currencyItem.value, productProps.fields, props, sendIlsPrice]);

  const onRemoveLockedAttribute = useCallback((e, field) => {
    const removingLockedAttributes = productProps.fields.removingLockedAttributes ?
        productProps.fields.removingLockedAttributes :
        [];
    removingLockedAttributes.push(field);
    props.setFields({
      ...productProps.fields,
      removingLockedAttributes: removingLockedAttributes,
      sendIlsPrice
    });
  }, [props, productProps.fields, sendIlsPrice]);

  const handleProfitChange = () => false;

  const getLockIcon = (attrCode) => {
    const productLockedAttributes = !isEmpty(productProps.productToEdit.lockedAttributes)
      ? productProps.productToEdit.lockedAttributes
      : [];

    const removingLockedAttributes = productProps.fields.removingLockedAttributes
      ? productProps.fields.removingLockedAttributes
      : [];

    if (!isEmpty(productLockedAttributes) && productLockedAttributes.indexOf(attrCode) !== -1) {
      if (removingLockedAttributes.indexOf(attrCode) === -1) {
        return true;
      }
    }

    return false;
  };

  // Fix is does not exist ils in CDMS
  useEffect(() => {
    if (!merchantLevelFields.price.ils.value) {
      props.setFields({
        ...productProps.fields,
        price: {
          ...productProps.fields.price,
          ils: +(getPublishEditPrice(
            merchantLevelFields.price.finalPrice.value,
            currencyItem.value,
            productProps.fields.isDomestic
          ) || 0),
        },
      });
    }
  }, [
    currencyItem.value,
    merchantLevelFields.price.finalPrice.value,
    merchantLevelFields.price.minPrice.value,
    merchantLevelFields.price.minPublishPrice.value,
    merchantLevelFields.price.ils.value,
    productProps.fields, props
  ]);

  const handleOpenMerchantInfo = (e, field) => {
    setShowMerchantInfo(field);
  }

  const handleCloseMerchantInfo = () => {
    setShowMerchantInfo(null);
  }

  const RightIcons = ({ attr, divClass }) => {
    const lockIcon = getLockIcon(attr);
    const infoClassName = lockIcon ? 'product-info-merchant' : 'product-info-merchant product-info-merchant-top';
    const divClassName = divClass ? ('merchant-w-15 ' + divClass) : 'merchant-w-15';

    const merchantInfo = getMerchantFieldInfo(attr, handleOpenMerchantInfo);

    return (
      <div className={divClassName}>
        {lockIcon && (
          <span className="locked-attribute-merchant" onClick={e => onRemoveLockedAttribute(e, attr)}>
            <i className={'zmdi zmdi-lock text-primary left'} />
          </span>
        )}
        {merchantInfo && (
            <>
              <span className={infoClassName} onClick={e => handleOpenMerchantInfo(e, attr)}>
                <i className={'zmdi zmdi-info text-primary'} />
              </span>
              {showMerchantInfo === attr && (
                  <div className="merchant-info">
                    {merchantInfo}
                    <br /><br />
                    <Button
                        variant="contained"
                        onClick={handleCloseMerchantInfo}
                        color="primary"
                        className="btn-info text-white merchant-info-button"
                    >
                      <IntlMessages id="buttons.close" />
                    </Button>
                  </div>
              )}
            </>
        )}
      </div>
    );
  };

  return (
    <div className="merahcnet-container-wrapper">
      <div className={`product-edit-wrapper flex direction-column full-height relative ${className}`}>
        <div className="flex m-15-auto">
          <div className="block">
            <FormControl className="block" variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">
                <IntlMessages id="merhcant.container.edit.stock.status.label" />
              </InputLabel>
              <Select
                native
                id="stock"
                name="status"
                size="small"
                className="select-sm block"
                value={merchantLevelFields.stock.status.value}
                onChange={handleFieldChange}
                label={<IntlMessages id="merhcant.container.edit.stock.status.label" />}
              >
                {isEmpty(merchantLevelFields.stock.status) && <option aria-label="None" value="" />}
                {stockStatusList.map(status => (
                  <option value={status.value}>{status.label}</option>
                ))}
              </Select>
            </FormControl>
          </div>
          <RightIcons attr="stock.status" divClass="ml-10" />
        </div>
        <div className="flex m-15-auto">
          <div className="block mr-10">
            <TextField
              id="price"
              label={<IntlMessages id="merhcant.container.edit.price.finalPrice.label" />}
              variant="outlined"
              type="number"
              size="small"
              className="block"
              name="finalPrice"
              value={merchantLevelFields.price.finalPrice.value}
              onChange={handleFinalPriceChange}
              error={!isEmpty(errors.finalPrice)}
              helperText={!isEmpty(errors.finalPrice) && (errors.finalPrice.map(e => <IntlMessages id={e.message} key={e.message} />))}
            />
            <div style={{ fontSize: 12 }}>
              Original Price: &nbsp;
              <strong>{ moneyFormat(productProps.fields.price.origPrice, '$') }</strong> &nbsp;
              (
                <strong>
                  { moneyFormat(Math.round(productProps.fields.price.origPrice * currencyItem.value), '₪') }
                </strong>
              )
            </div>
          </div>
          <RightIcons attr="price.final_price" />
          <div className="block ml-10">
            <TextField
              id="price"
              label={<IntlMessages id="merhcant.container.edit.price.publishPrice.label" />}
              variant="outlined"
              type="number"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {!isEmpty(currencyItem) ? '₪' : '$'}
                  </InputAdornment>
                ),
              }}
              className="block"
              name="ils"
              value={ilsValue}
              error={!isEmpty(errors.ils) || ilsError}
              helperText={!isEmpty(errors.ils) && (errors.ils.map(e => <IntlMessages id={e.message} key={e.message} />))}
              onChange={handlePublishPriceChange}
            />
            <Checkbox
              onChange={(e) => handleSetIlsPrice(e)}
              checked={sendIlsPrice === true}
            /> - <IntlMessages id="merhcant.container.edit.set_ils_price" />
            {!productProps.fields.isDomestic && (
            <div style={{ fontSize: 12 }}>
              Please do not enter shekels between &nbsp;
              <strong>{ Math.ceil((120 * currencyItem.value).toFixed(2)) }</strong>
              &nbsp;and&nbsp;
              <strong>{ Math.ceil((120 * currencyItem.value * 1.17).toFixed(2)) }</strong>
            </div>
            )}
          </div>
          <RightIcons attr="price.ils" divClass="ml-10" />
        </div>

        <div className="flex m-15-auto">
          <div className="block mr-10">
            <TextField
              id="price"
              label={<IntlMessages id="merhcant.container.edit.price.minPrice.label" />}
              variant="outlined"
              size="small"
              InputProps={{
                readOnly: true,
              }}
              className="block"
              name="minPrice"
              value={merchantLevelFields.price.minPrice.value ? merchantLevelFields.price.minPrice.value : 'N/A'}
              onChange={handleProfitChange}
            />
          </div>
          <RightIcons attr="price.min_price" />
          <div className="block ml-10">
            <TextField
              id="price"
              label={<IntlMessages id="merhcant.container.edit.price.minPublishPrice.label" />}
              variant="outlined"
              size="small"
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    {!isEmpty(currencyItem) ? '₪' : '$'}
                  </InputAdornment>
                ),
              }}
              className="block"
              name="minPublishPrice"
              value={merchantLevelFields.price.minPublishPrice.value || getPublishEditPrice(
                merchantLevelFields.price.minPrice.value,
                currencyItem.value,
                productProps.fields.isDomestic
              ) || 0}
              onChange={handleProfitChange}
            />
          </div>
          <RightIcons attr="price.min_price" divClass="ml-10" />
        </div>

        <div className="flex m-15-auto">
          <div className="block mr-10">
            <TextField
              id="stock"
              label={<IntlMessages id="merhcant.container.edit.stock.quantity.label" />}
              variant="outlined"
              type="number"
              size="small"
              className="block"
              name="qty"
              value={merchantLevelFields.stock.quantity.value}
              onChange={handleFieldChange}
            />
          </div>
          <RightIcons attr="stock.qty" />
          <div className="block ml-10">
            <TextField
              id="price"
              label={<IntlMessages id="merhcant.container.edit.price.lostProfit.label" />}
              variant="outlined"
              size="small"
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    {
                      merchantLevelFields.price.profitLost.value > 0
                        ? <ArrowUpward color="primary" fontSize="small" />
                        : <ArrowDownward color="error" fontSize="small" />
                    }
                  </InputAdornment>
                ),
              }}
              className="block"
              name="profitLost"
              value={`${merchantLevelFields.price.profitLost.value.toFixed(2)} %`}
              onChange={handleProfitChange}
            />
          </div>
          <RightIcons attr="price.profit_lost" divClass="ml-10" />
        </div>
        <div className="flex m-15-auto">
          <div className="block mr-10">
            <TextField
              id="price"
              label={<IntlMessages id="merhcant.container.edit.price.msrp.label" />}
              variant="outlined"
              type="number"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
              className="block"
              name="msrp"
              value={merchantLevelFields.price.msrp.value.toFixed(2)}
              onChange={handleFieldChange}
            />
          </div>

          <RightIcons attr='price.msrp' />

          <div className="block ml-10">
            <TextField
              id="price"
              label={<IntlMessages id="merhcant.container.edit.price.aoMarkUp.label" />}
              variant="outlined"
              size="small"
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              className="block"
              name="profit"
              value={
                productProps.fields.price
                && productProps.fields.price.profit
                && productProps.fields.price.profit.toFixed(2)
              }
              onChange={handleProfitChange}
            />
          </div>
          <RightIcons attr="price.profit" divClass="ml-10" />
        </div>
        <div className="flex m-15-auto">
          <div className="block mr-10">
            <TextField
              id="price"
              label={<IntlMessages id="merhcant.container.edit.price.netCost.label" />}
              variant="outlined"
              type="number"
              size="small"
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              className="block"
              name="cost"
              value={`${productProps.productToEdit.price.cost.toFixed(2)}`}
              onChange={handleFieldChange}
            />
          </div>
          <RightIcons attr="price.cost" />
          <div className="block ml-10">
            <TextField
              id="price"
              label={<IntlMessages id="merhcant.container.edit.price.shippingCost.label" />}
              variant="outlined"
              type="number"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
              className="block"
              name="shippingCost"
              value={merchantLevelFields.price.shippingCost.value.toFixed(2)}
              onChange={handleFieldChange}
            />
          </div>
          <RightIcons attr="price.shipping_cost" divClass="ml-10" />
        </div>

      </div>
    </div>
  );
});

MerchantDetailContainer.propTypes = {
  fetchActivityLogsByProductId: func.isRequired,
  setFields: func.isRequired,
  productProps: shape().isRequired,
  className: string,
  errors: shape({}),
  currencyItem: shape().isRequired,
};

MerchantDetailContainer.defaultProps = {
  className: '',
};

const mapDispatchToProps = {
  fetchActivityLogsByProductId,
  setFields,
};

const mapStateToProps = state => ({
  productProps: productSelector(state.product),
  currencyItem: state.currency.item,
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantDetailContainer);
