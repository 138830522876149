export const paginationLimit = 100;

export const defaultFields = ['id', 'price', 'sku', 'title', 'description', 'images', 'specifications', 'scores'];

export const defaultPagination = {
  page: 1,
  limit: paginationLimit,
};

export const defaultMaxPagination = {
  page: 1,
  limit: 500,
};
