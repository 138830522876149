import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  SET_CATEGORY_TREE_ITEMS,
  SEARCH_PHYSICAL_CATEGORIES_START,
  SEARCH_PHYSICAL_CATEGORIES_FINISHED,
  CLEAR_PHYSICAL_CATEGORY_STATE,
  SEARCH_PHYSICAL_CATEGORIES_BY_IDS_START,
  SEARCH_PHYSICAL_CATEGORIES_BY_IDS_FINISHED,
  FETCH_PHYSICAL_ROOT_CATEGORIES_FINISHED,
} from '../actions/types';

const initialState = {
  treeItems: [],
  searchResults: [],
  categoriesByIds: [],
  searching: false,
  searched: false,
  fetchingCategoriesByIds: false,
  fetchedCategoriesByIds: false,
};
const physicalCategoryRestTypes = restTypes('physical_category');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case physicalCategoryRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case physicalCategoryRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case physicalCategoryRestTypes.fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };
    case physicalCategoryRestTypes.fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case SET_CATEGORY_TREE_ITEMS:
      return {
        ...state,
        treeItems: [
          ...state.treeItems,
          ...action.payload.items,
        ],
      };
    case SEARCH_PHYSICAL_CATEGORIES_START:
      return {
        ...state,
        searching: true,
        searched: false,
      };
    case SEARCH_PHYSICAL_CATEGORIES_FINISHED:
      return {
        ...state,
        searching: false,
        searched: true,
        searchResults: action.payload.list,
      };
    case CLEAR_PHYSICAL_CATEGORY_STATE:
      return { ...restInitState, ...initialState };
    case SEARCH_PHYSICAL_CATEGORIES_BY_IDS_START:
      return {
        ...state,
        fetchingCategoriesByIds: true,
        fetchedCategoriesByIds: false,
      };
    case SEARCH_PHYSICAL_CATEGORIES_BY_IDS_FINISHED:
      return {
        ...state,
        fetchingCategoriesByIds: false,
        fetchedCategoriesByIds: true,
        categoriesByIds: action.payload.list,
      };
    case FETCH_PHYSICAL_ROOT_CATEGORIES_FINISHED:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };
    default: return state;
  }
};
