import React, { useState } from 'react';
import { connect } from 'react-redux';
import { camelize } from 'humps';
import {
  string, shape, arrayOf, bool, func, number,
} from 'prop-types';
import { isEmpty } from 'lodash';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';

import VariantImageViewContainer from '../../containers/VariantImageViewContainer';
import SortableImage from './SortableImage';
import { mapStateToProps, mapDispatchToProps } from './connect';

const Item = (props) => {
  const localAttributes = ['id', 'title.en', 'images', 'specifications.ao_brand_name'];
  const [collapsed, setCollapsed] = useState(false);

  const {
    parentValue, sortedAttributes, productItem, disabled, productComments,
    certainProductAttributes, isEditMode, pagination, index: productIndex,
    productsImagesOverride, list,
  } = props;

  const { variants, childrenIds } = productItem;

  const handleExpandProduct = () => setCollapsed(!collapsed);

  const availableAttributes = localAttributes.map(a => sortedAttributes.find(s => s.code === a));

  const handleSortEnd = (images) => {
    let updatedList = [...productsImagesOverride];
    if (!productsImagesOverride.find(p => p.id === productItem.id)) {
      updatedList.push({ id: productItem.id, images });
    } else {
      updatedList = [...productsImagesOverride].map((ul) => {
        if (ul.id === productItem.id) return ({ id: ul.id, images });
        return ul;
      });
    }
    props.setUpdatedProductsImagesOverrides(updatedList);
  };

  const handleProductSelect = () => {
    if (productsImagesOverride.find(pio => pio.id === productItem.id)) {
      const filteredList = productsImagesOverride.filter(pio => pio.id !== productItem.id);
      props.setUpdatedProductsImagesOverrides(filteredList);
      const returnedProduct = list.find(p => p.id === productItem.id);
      props.injectProducts([], returnedProduct);
    } else {
      const clonnedList = [...productsImagesOverride];
      const newItem = ({
        id: productItem.id,
        images: productItem.images,
      });
      clonnedList.push(newItem);
      props.setUpdatedProductsImagesOverrides(clonnedList);
    }
  };


  const getSortableImageList = () => {
    const item = productsImagesOverride.find(pio => pio.id === parentValue.id);
    return item ? item.images : parentValue.images;
  };

  return (
    <Grid item xs={12}>
      <div className="product-item flex items-center pr-20 pl-20">
        <div className="product-item-type">
          <span>parent</span>
        </div>
        <Card className="product-item-card-list without-dnd parent">
          <CardActions className="card-collapse-btn pr-0">
            {!disabled && (
              <div>
                <Checkbox
                  color="primary"
                  disabled={productItem.images.length < 2}
                  checked={productsImagesOverride.some(p => p.id === parentValue.id)}
                  onChange={handleProductSelect}
                  name={parentValue.title
                    ? camelize(parentValue.title)
                    : ''
                  }
                />
              </div>
            )}
            {!isEmpty(childrenIds) && (
              <IconButton color="inherit" onClick={handleExpandProduct}>
                <i className={`zmdi ${collapsed ? 'zmdi-chevron-down' : 'zmdi-chevron-right'}`} />
              </IconButton>
            )}
          </CardActions>
          <CardContent className="product-content 111 flex full-width ml-0 mr-0">
            <div className="flex full-width list-items direction-column justify-center">
              {availableAttributes.map((attr) => {
                const cellXlClassName = attr.code === 'id' ? 'cell-xl' : '';
                const currentAttribute = certainProductAttributes
                  .find(el => el.code === attr.value) || {};

                const itemValue = typeof parentValue[camelize(attr.value)] === 'string' ? parentValue[camelize(attr.value)] : false;

                return (
                  <div
                    className={`flex direction-column product-item-data relative ${cellXlClassName}`}
                    key={attr.key}
                  >
                    {attr.label !== 'Images' && (
                      <div className="value relative">
                        <div className="product-cell product-cell-inline">
                          <div className="product-cell-label">{(!isEmpty(currentAttribute.label) ? currentAttribute.label.en : '') || attr.label}</div>
                          {itemValue && <div className="product-cell-value">{itemValue}</div>}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex edit-icon-wrapper items-center mr-3">
              <span className={`icon-xs icon-premium position-info list ${isEditMode ? 'with-margin' : ''}`}>
                {((pagination.page - 1) * pagination.limit) + productIndex + 1}
              </span>
            </div>
          </CardContent>
          <SortableImage
            list={getSortableImageList()}
            onSortEnd={handleSortEnd}
          />
        </Card>
      </div>
      {
        collapsed && !isEmpty(variants) && (
          <VariantImageViewContainer
            variants={variants}
            parent={productItem}
            productComments={productComments}
          />
        )
      }
    </Grid>
  );
};

Item.propTypes = {
  sortedAttributes: arrayOf(shape()).isRequired,
  parentValue: shape({
    id: string,
    title: string,
  }).isRequired,
  productsImagesOverride: arrayOf(shape()).isRequired,
  list: arrayOf(shape()).isRequired,
  productComments: arrayOf(shape()).isRequired,
  pagination: shape().isRequired,
  productItem: shape().isRequired,
  certainProductAttributes: arrayOf(shape()).isRequired,
  isEditMode: bool.isRequired,
  index: number,
  disabled: bool.isRequired,
  setUpdatedProductsImagesOverrides: func.isRequired,
  injectProducts: func.isRequired,
};

Item.defaultProps = {
  index: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
