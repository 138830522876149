import { camelizeKeys, decamelizeKeys } from 'humps';
import axios from 'axios';
import { uniq, isEmpty, cloneDeep, isEqual, } from 'lodash';

import RestActions from '../util/rest/actions';
import { showErrorNotification } from '../util/api';

import {
  getCategoryPath,
  productsFrontAppPath,
  categoriesSearchPath,
  virtualCategoriesPath,
} from '../util/paths';

import {
  VIRTUAL_CATEGORY_PAGE_SET_CATEGORY_FIELDS,
  VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_CATEGORIES_ATTRIBUTES,
  VIRTUAL_CATEGORY_PAGE_SET_ATTRIBUTES_MATRIX_ATTRIBUTES,
  VIRTUAL_CATEGORY_PAGE_SET_FILTERS,
  VIRTUAL_CATEGORY_PAGE_SET_FILTERS_ERRORS,
  VIRTUAL_CATEGORY_PAGE_SET_PRODUCTS_NIN_IDS,
  VIRTUAL_CATEGORY_PAGE_SET_PRODUCTS_IN_IDS,
  VIRTUAL_CATEGORY_PAGE_SET_SELECTED_PRODUCT_LIST,
  VIRTUAL_CATEGORY_PAGE_UPDATE_LOADED_PRODUCTS_IN,
  VIRTUAL_CATEGORY_PAGE_UPDATE_LOADED_PRODUCTS_NIN,
  VIRTUAL_CATEGORY_PAGE_COMPLETE_PRODUCTS_LIST_START,
  VIRTUAL_CATEGORY_PAGE_COMPLETE_PRODUCTS_LIST_FINISHED,
  VIRTUAL_CATEGORY_PAGE_SET_IS_APPLY_FILTER_ACTIVE,
  VIRTUAL_CATEGORY_PAGE_SET_IS_SWITCH_ON,
  VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_FILTER,
  VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCT_LIST_START,
  VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCT_LIST_FINISHED,
  VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_COMPOUND_QUERY,
  VIRTUAL_CATEGORY_PAGE_SET_LEFT_SIDE_PAGINATION,
  VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCTS_BY_IDS_START,
  VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCTS_BY_IDS_FINISHED,
  VIRTUAL_CATEGORY_PAGE_SET_SHOW_CATEGORIES_DIALOG,
  VIRTUAL_CATEGORY_PAGE_SET_MAPPED_CATEGORIES,
  VIRTUAL_CATEGORY_PAGE_SET_SELECTED_CATEGORIES,
  VIRTUAL_CATEGORY_PAGE_SET_PHYSICAL_CATEGORY_SEARCH_QUERY,
  VIRTUAL_CATEGORY_PAGE_SET_SELECTED_DIALOG_CATEGORIES,
  VIRTUAL_CATEGORY_PAGE_CLEAR_CATEGORY_PRODUCT_STATE,
  VIRTUAL_CATEGORY_PAGE_SET_RANKING_RULE_CODE,
  VIRTUAL_CATEGORY_PAGE_SET_SORTING_ERRORS,
  VIRTUAL_CATEGORY_PAGE_SET_SORT_RULES,
  VIRTUAL_CATEGORY_PAGE_SET_FACETS,
  VIRTUAL_CATEGORY_PAGE_SET_IS_DATA_CHANGED,
  VIRTUAL_CATEGORY_PAGE_SET_IS_CATEGORY_SAVING,
  VIRTUAL_CATEGORY_PAGE_SET_ERRORS,
  VIRTUAL_CATEGORY_PAGE_FETCH_VIRTUAL_CATEGORIES_BY_NAME_START,
  VIRTUAL_CATEGORY_PAGE_FETCH_VIRTUAL_CATEGORIES_BY_NAME_FINISHED,
  VIRTUAL_CATEGORY_PAGE_SET_ISDUPLICATED_VIRTUAL_CATEGORY,
} from './types';

import normalizeFilters from '../util/normalizeFilters';
import { mapPositions } from '../util/mapVirtualParamsPositions';
import {
  unmapFromAttributesMatrix,
  mapToAttributesMatrix,
  mapFilters,
} from '../util/attributesMatrixMapping';

import {
  getValueByOperator,
  getFieldByOperator,
} from '../util/mapFiltersByOperator';
import { generateRequestBody } from '../util/productPayload';
import { mapExportableProducts } from '../util/mapExportableProducts';

import {
  getFrontendProductsByIds,
} from '../services/API/product';

import {
  updateNavigationsMappedcategoriesByVirtualCategoryId
} from './search';

const restVirtualCategoryPageActions = new RestActions('virtual-category-page');
const {
  fetchStart,
  fetchFinished,
  clearErrorFinished,
  updateStart,
  updateFinished,
  createStart,
  createFinished,
  clearFromStateFinished,
  clearStateFinished,
} = restVirtualCategoryPageActions;

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';

export const mapVirtualParams = (virtualParams, mapToMatrix, attributesMatrix) => {
  const positions = Array.isArray(virtualParams.positions) ? {} : virtualParams.positions;
  return ({
    ...virtualParams,
    filters: mapToMatrix ? [...mapFilters(virtualParams.filters, attributesMatrix)]
      : virtualParams.filters,
    sortRules: mapToMatrix ? virtualParams.sortRules.map(s => ({
      field: mapToAttributesMatrix(s.field, attributesMatrix),
      direction: s.direction.toLowerCase(),
    })) : virtualParams.sortRules,
    positions: virtualParams.productsOverride
      ? virtualParams.productsOverride.reduce((result, item) => {
        const payload = { ...result };

        payload[item.productId] = item.position;
        return payload;
      }, {})
      : positions,
  });
};

export const fetchVirtualCategory = id => (dispatch, getState) => {
  const { list: attributesMatrix } = getState().attributesMatrix;
  dispatch(fetchStart());

  axios.get(getCategoryPath(id)).then((res) => {
    const data = normalizeFilters(camelizeKeys(res.data.data));
    const payload = { ...data };

    payload.virtualParams = mapPositions(
      data.virtualParams,
      res.data.data.virtual_params,
    );

    const categoryItem = unmapFromAttributesMatrix(payload, attributesMatrix);

    dispatch(fetchFinished({
      item: categoryItem,
      originalItem: categoryItem,
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(fetchFinished({
      error: error.response.data, hasError: true,
    }));
  });
};

export const fetchDuplicateVirtualCategory = id => (dispatch, getState) => {
  const { list: attributesMatrix } = getState().attributesMatrix;
  dispatch(fetchStart());

  axios.get(getCategoryPath(id)).then((res) => {
    const data = normalizeFilters(camelizeKeys(res.data.data));
    const payload = { ...data };

    payload.virtualParams = mapPositions(
      data.virtualParams,
      res.data.data.virtual_params,
    );

    const categoryItem = {
      ...unmapFromAttributesMatrix(payload, attributesMatrix),
      name: {
        en: '',
        he: '',
      }
    };

    dispatch(fetchFinished({
      item: categoryItem,
      originalItem: categoryItem,
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(fetchFinished({
      error: error.response.data, hasError: true,
    }));
  });
};

export const setIsDuplicatedCategory = isDuplicatedCategory => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_ISDUPLICATED_VIRTUAL_CATEGORY,
    payload: { isDuplicatedCategory },
  });
};

export const deleteVirtualCategory = (id, params) => (dispatch) => {
  const transformedPath = params.isSync
    ? `${getCategoryPath(id)}?is_synchronous=${params.isSync}`
    : getCategoryPath(id);
  dispatch(restVirtualCategoryPageActions.deleteStart());
  return axios.delete(transformedPath).then(() => {
    dispatch(restVirtualCategoryPageActions.deleteFinished());
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restVirtualCategoryPageActions.deleteFinished({
      error: error.response.data,
      hasError: true,
    }));
  });
};

export const setCategoryFields = item => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_CATEGORY_FIELDS,
    payload: { item },
  });
};

export const setIsDataChanged = isDataChanged => (dispatch) => { // TODO mb delete
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_IS_DATA_CHANGED,
    payload: { isDataChanged },
  });
};

export const setIsCategorySaving = isCategorySaving => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_IS_CATEGORY_SAVING,
    payload: { isCategorySaving },
  });
};

export const fetchVirtualCategoryByPathByName = (filter, pagination, cb) => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_FETCH_VIRTUAL_CATEGORIES_BY_NAME_START,
  });
  axios.post(categoriesSearchPath + '?ref=' + ref, decamelizeKeys({ ...filter, pagination })).then((res) => {
    const itemAlreadyExist = !isEmpty(res.data.data);
    dispatch({
      type: VIRTUAL_CATEGORY_PAGE_FETCH_VIRTUAL_CATEGORIES_BY_NAME_FINISHED,
      payload: { itemAlreadyExist },
    });
    if (cb) cb(res.data.data);
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: VIRTUAL_CATEGORY_PAGE_FETCH_VIRTUAL_CATEGORIES_BY_NAME_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  });
};

// FILTER

export const setProductCategoriesAttributes = productCategoriesAttributes => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_CATEGORIES_ATTRIBUTES,
    payload: { productCategoriesAttributes },
  });
};

export const setAttributesMatrixAttributes = attributesMatrixAttributes => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_ATTRIBUTES_MATRIX_ATTRIBUTES,
    payload: { attributesMatrixAttributes },
  });
};

export const setFilters = filters => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_FILTERS,
    payload: { filters },
  });
};

export const setFiltersErrors = filtersErrors => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_FILTERS_ERRORS,
    payload: { filtersErrors },
  });
};

export const setIsSwitchOn = isSwitchOn => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_IS_SWITCH_ON,
    payload: { isSwitchOn },
  });
};

export const setProductFilter = productFilter => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_FILTER,
    payload: { productFilter },
  });
};

export const setErrors = errors => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_ERRORS,
    payload: { errors },
  });
};

export const clearErrorState = () => (dispatch) => {
  dispatch(clearErrorFinished({ error: {}, hasErrors: false }));
};

export const updateCategory = (category, params) => (dispatch, getState) => {
  dispatch(clearErrorState());
  dispatch(updateStart());

  const transformedPath = params.isSync
    ? `${getCategoryPath(category.id)}?is_synchronous=${params.isSync}`
    : getCategoryPath(category.id);
  const { list: attributesMatrix } = getState().attributesMatrix;
  const { item: virtualCategory } = getState().virtualCategoryPage;
  const { virtualParams } = category;
  const categoryToUpdate = {
    ...category,
    virtualParams: mapVirtualParams(virtualParams, params.mapToMatrix, attributesMatrix),
  };
  const data = decamelizeKeys(categoryToUpdate);

  if (category.virtualParams.productsOverride
    && isEmpty(category.virtualParams.productsOverride)) {
    delete data.virtual_params.positions;
  } else {
    data.virtual_params.positions = categoryToUpdate.virtualParams.positions;
  }

  if (data.virtual_params.products_override) {
    delete data.virtual_params.products_override;
  }

  delete data.virtual_params.facets; // TODO remove

  return axios.put(transformedPath, {
    data,
  }).then(async (res) => {
    if (res) {
      const updatedCategory = camelizeKeys(res.data.data);
      const mappedCategoriesList = updatedCategory.virtualParams.mappedCategories;
      if (!isEqual(mappedCategoriesList,
        virtualCategory.virtualParams.mappedCategories)) {
        await dispatch(updateNavigationsMappedcategoriesByVirtualCategoryId(
          virtualCategory.id, mappedCategoriesList,
        ));
      }

      const payload = { ...updatedCategory };
      payload.virtualParams = mapPositions(
        updatedCategory.virtualParams,
        res.data.data.virtual_params,
      );

      const newItem = unmapFromAttributesMatrix(payload, attributesMatrix);

      dispatch(updateFinished({
        success: true,
        hasErrors: false,
        item: newItem,
        originalItem: newItem,
      }));
    }

  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(updateFinished({
      error, hasErrors: true,
    }));
  });
};

export const createCategory = (category, params) => (dispatch, getState) => {
  const payload = cloneDeep(category);
  const transformedPath = params.isSync
    ? `${virtualCategoriesPath}?is_synchronous=${params.isSync}`
    : virtualCategoriesPath;
  const { list: attributesMatrix } = getState().attributesMatrix;

  if (payload.virtualParams.facets) delete payload.virtualParams.facets;
  const data = {
    ...payload,
    virtual_params: {
      ...payload.virtual_params,
      selected_categories: uniq(payload.virtualParams.selectedCategories),
      mapped_categories: uniq(payload.virtualParams.mappedCategories),
      ranking_rule_code: payload.virtualParams.rankingRuleCode || null,
      sort_rules: payload.virtualParams.sortRules.map(s => ({
        field: mapToAttributesMatrix(s.field, attributesMatrix),
        direction: s.direction.toLowerCase(),
      })),
      filters: mapFilters(payload.virtualParams.filters, attributesMatrix),
    },
  };

  dispatch(createStart());
  return axios.post(transformedPath, { data: decamelizeKeys(data) }).then((res) => {
    dispatch(createFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(createFinished({
      error: error.response.data, hasErrors: true,
    }));
  });
};


// FILTER PRODUCTS

export const setProductsNotInIds = productsNotInIds => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_PRODUCTS_NIN_IDS,
    payload: { productsNotInIds },
  });
};

export const setProductsInIds = productsInIds => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_PRODUCTS_IN_IDS,
    payload: { productsInIds },
  });
};

export const setSelectedProductList = selectedProductList => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_SELECTED_PRODUCT_LIST,
    payload: { selectedProductList },
  });
};

export const updateLoadedProductsIn = productsByIdsForIn => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_UPDATE_LOADED_PRODUCTS_IN,
    payload: { productsByIdsForIn },
  });
};

export const updateLoadedProductsNin = productsByIdsForNin => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_UPDATE_LOADED_PRODUCTS_NIN,
    payload: { productsByIdsForNin },
  });
};

export const completeProductList = (ids, key, pagination) => async (dispatch, getState) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_COMPLETE_PRODUCTS_LIST_START,
    payload: {
      completingCategoryProductsList: true,
      completedCategoryProductsList: false,
    },
  });

  const currentKey = key || 'productsByIds';
  const virtualCategoryPageState = getState().virtualCategoryPage;
  const currentTargetList = virtualCategoryPageState.filter.product[currentKey];
  const query = {
    filter: [{
      group: [{
        field: 'id',
        value: uniq(ids),
        operator: 'in',
      }],
    }],
    pagination,
  };

  const res = await getFrontendProductsByIds(query);
  const items = camelizeKeys(res);

  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_COMPLETE_PRODUCTS_LIST_FINISHED,
    payload: {
      completingCategoryProductsList: false,
      completedCategoryProductsList: true,
      [currentKey]: [...currentTargetList.map((l) => {
        const itemToSet = items.find(p => p.id === l.id);
        return itemToSet || l;
      })],
    },
  });
};

export const setIsProductDialogApplyFilterActive = isProductDialogApplyFilterActive => (
  dispatch,
) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_IS_APPLY_FILTER_ACTIVE,
    payload: { isProductDialogApplyFilterActive },
  });
};

export const fetchProductList = ({
  viewMode,
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  fullText = {},
  compoundSearch = null,
}) => (dispatch, getState) => {
  const sort = [{
    field: 'categories.position',
    order: 'asc',
  }];
  const { list } = getState().previewFilterAttribute;
  const mappedFilters = filter.map((f) => {
    const group = f.group.map(g => ({
      ...g,
      value: getValueByOperator({ value: g.value, operator: g.operator }),
      field: getFieldByOperator({
        field: g.field, operator: g.operator, previewFilterAttribute: list,
      }),
    }));

    return {
      ...f,
      group,
    };
  });

  const body = {
    viewMode,
    fields,
    pagination,
    fullText,
    sort,
    searchFilter,
  };

  if (!isEmpty(mappedFilters)) body.filter = mappedFilters;

  const params = {
    ...generateRequestBody(body),
  };

  if (compoundSearch && !isEmpty(compoundSearch) && compoundSearch.value) {
    params.body.compoundSearch = compoundSearch;
  }

  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCT_LIST_START,
    payload: {
      categoryFetchProductListStart: true,
      categoryFetchProductListFinished: false,
    },
  });

  return axios.post(
    params.path,
    decamelizeKeys(params.body),
  ).then((res) => {
    const {
      pages, total, aggregation,
    } = res.data;
    const result = res.data.data.map((p) => {
      if (p.variants) {
        return {
          ...p,
          variants: Object.keys(p.variants).map((v) => {
            const item = p.variants[v];
            return ({
              ...item,
              id: v,
            });
          }),
        };
      }
      return p;
    });

    dispatch({
      type: VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCT_LIST_FINISHED,
      payload: {
        list: mapExportableProducts(camelizeKeys(result)),
        aggregation,
        pages,
        total,
        updated: false,
        updating: false,
        categoryFetchProductListStart: false,
        categoryFetchProductListFinished: true,
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCT_LIST_FINISHED,
      payload: {
        error: error.response.data,
        hasErrors: true,
        list: [],
      },
    });
  });
};

export const setCompoundSearch = compoundSearch => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_COMPOUND_QUERY,
    payload: { compoundSearch },
  });
};

export const setLeftSidePagination = leftSidePagination => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_LEFT_SIDE_PAGINATION,
    payload: { leftSidePagination },
  });
};

export const fetchProductsByIds = (ids, key, pagination) => async (dispatch, getState) => {
  const virtualCategoryPageState = getState().virtualCategoryPage;
  const currentKey = key || 'productsByIds';
  const currentTargetList = virtualCategoryPageState.filter.product[currentKey];

  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCTS_BY_IDS_START,
    payload: {
      fetchProductsByIdsStarted: true,
      fetchProductsByIdsFinished: false,
    },
  });

  const condition = ids.length > 1
    ? { condition: 'or' }
    : {};

  return axios.post(
    `${productsFrontAppPath}/search`,
    {
      filter: [
        {
          ...condition,
          group: [{
            field: 'id',
            value: uniq(ids),
            operator: 'in',
          }],
        },
      ],
      pagination,
    },
  ).then((res) => {
    const items = mapExportableProducts(camelizeKeys(res.data.data));
    dispatch({
      type: VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCTS_BY_IDS_FINISHED,
      payload: {
        [currentKey]: [...currentTargetList.map((l) => {
          const itemToSet = items.find(p => p.id === l.id);
          return itemToSet || l;
        })],
        fetchProductsByIdsStarted: false,
        fetchProductsByIdsFinished: true,
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCTS_BY_IDS_FINISHED,
      payload: {
        error: error.response.data,
        hasErrors: true,
      },
    });
  });
};

export const clearProductState = () => (dispatch) => {
  dispatch({ type: VIRTUAL_CATEGORY_PAGE_CLEAR_CATEGORY_PRODUCT_STATE });
};

// SORT

export const setRankingRuleCode = rankingRuleCode => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_RANKING_RULE_CODE,
    payload: { rankingRuleCode },
  });
};

export const setSortingsErrors = sortingsErrors => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_SORTING_ERRORS,
    payload: { sortingsErrors },
  });
};

export const setSortRules = sortRules => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_SORT_RULES,
    payload: { sortRules },
  });
};

// CATEGORY
export const setShowSelectCategoriesDialog = showSelectCategoriesDialog => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_SHOW_CATEGORIES_DIALOG,
    payload: { showSelectCategoriesDialog },
  });
};

export const setMappedCategories = mappedCategories => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_MAPPED_CATEGORIES,
    payload: { mappedCategories },
  });
};

export const setSelectedCategories = selectedCategories => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_SELECTED_CATEGORIES,
    payload: { selectedCategories },
  });
};

export const setPhysicalCategorySearchQuery = physicalCategorySearchQuery => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_PHYSICAL_CATEGORY_SEARCH_QUERY,
    payload: { physicalCategorySearchQuery },
  });
};

export const setSelectedDialogCategories = selectedDialogCategories => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_SELECTED_DIALOG_CATEGORIES,
    payload: { selectedDialogCategories },
  });
};

// FACET

export const setFacets = facets => (dispatch) => {
  dispatch({
    type: VIRTUAL_CATEGORY_PAGE_SET_FACETS,
    payload: { facets },
  });
};

export const clearFromVirtualCategoryState = payload => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};

export const clearVirtualCategoryState = () => (dispatch) => {
  dispatch(clearStateFinished());
};