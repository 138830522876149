import {
  fetchRootCategories as fetchRootScopeCategories,
  fetchChildCategories as fetchChildScopeCategories,
  searchCategories as searchScopeCategories,
  clearSearchListState as clearScopeCategorySearchState,
  handleLoadScopeSubcategories,
  clearScopeCategoryTreeState,
  loadAllChildCategories,
  onToggleScopeCategoryTreeCollapse,
} from '../../../../actions/scopeCategory';


export const mapStateToProps = state => ({
  scopeCategory: state.scopeCategory,
});
export const actionCreators = {
  fetchChildScopeCategories,
  clearScopeCategorySearchState,
  handleLoadScopeSubcategories,
  fetchRootScopeCategories,
  clearScopeCategoryTreeState,
  searchScopeCategories,
  loadAllChildCategories,
  onToggleScopeCategoryTreeCollapse,
};

export default {
  mapStateToProps,
  actionCreators,
};
