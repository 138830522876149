import React, { Component } from 'react';

import PimPage from '../../pages/Home/index';

class StatefulHoc extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  render() {
    return <PimPage />;
  }
}

export default StatefulHoc;
