import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';
import { defaultPagination } from '../util/defaultData';

import {
  FETCH_SEARCH_LIST_BY_NAME_START,
  FETCH_SEARCH_LIST_BY_NAME_FINISHED,
  FIND_NAVIGATION_BY_PHRASE_START,
  FIND_NAVIGATION_BY_PHRASE_FINISHED,
  CAMPAIGN_FETCH_PRODUCT_LIST_START,
  CAMPAIGN_FETCH_PRODUCT_LIST_FINISHED,
  CLEAR_CAMPAIGN_PRODUCT_STATE,
  CAMPAIGN_FETCH_PRODUCTS_BY_IDS_START,
  CAMPAIGN_FETCH_PRODUCTS_BY_IDS_FINISHED,
  SEARCH_SEARCH_FILTERS_START,
  SEARCH_SEARCH_FILTERS_FINISHED,
  COMPLETE_SEARCH_PRODUCTS_LIST_FINISHED,
  COMPLETE_SEARCH_PRODUCTS_LIST_START,
  SEARCH_SET_SELECTED_PRODUCT_LIST,
  SEARCH_SET_PRODUCTS_IN_IDS,
  SEARCH_SET_PRODUCTS_NIN_IDS,
  SEARCH_UPDATE_LOADED_PRODUCTS_IN,
  SEARCH_UPDATE_LOADED_PRODUCTS_NIN,
  FETCH_NAVIGATION_TO_DUPLICATE_START,
  FETCH_NAVIGATION_TO_DUPLICATE_FINISHED,
  NAVIGATION_SEARCH_BY_PHRASES_START,
  NAVIGATION_SEARCH_BY_PHRASES_FINISHED,
  UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_STARTED,
  UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_FINISHED,
  NAVIGATION_SET_CATEGORY_FIELDS,
  NAVIGATION_SET_PRODUCT_CATEGORIES_ATTRIBUTES,
  NAVIGATION_SET_ATTRIBUTES_MATRIX_ATTRIBUTES,
  NAVIGATION_SET_PRODUCT_FILTER,
  NAVIGATION_SET_PRODUCT_COMPOUND_QUERY,
  NAVIGATION_SET_LEFT_SIDE_PAGINATION,
  NAVIGATION_SET_FILTERS,
  NAVIGATION_SET_IS_SWITCH_ON,
  NAVIGATION_SET_IS_APPLY_FILTER_ACTIVE,
  NAVIGATION_SET_MAPPED_CATEGORIES,
  NAVIGATION_SET_SELECTED_CATEGORIES,
  NAVIGATION_SET_PHYSICAL_CATEGORIES_IDS,
  NAVIGATION_SET_SELECTED_TREE_ITEMS,
  NAVIGATION_SET_SHOW_CATEGORIES_DIALOG,
  NAVIGATION_CLEAR_NAVIGATION_FILTER_STATE,
  NAVIGATION_SET_RANKING_RULE_CODE,
  NAVIGATION_SET_SORTING_ERRORS,
  NAVIGATION_SET_SORT_RULES,
  NAVIGATION_SET_EXISTED_SEARCH_PHRASE,
} from '../actions/types';

const initialState = {
  list: [],
  item: {},
  fetchingByName: false,
  fetchedByName: false,
  product: {
    list: [],
    selectedProductList: { in: [], nin: [] },
    productsInIds: [],
    productsNotInIds: [],
    productsByIdsForIn: [],
    productsByIdsForNin: [],
  },
  phrases: [],
  searchStarted: false,
  searchFinished: false,
  searchResults: [],
  suggestions: [],
  navigationToDuplicate: {},
  updateNavigationMappedCategoriesByVirtualCategoryIdStarted: false,
  updateNavigationMappedCategoriesByVirtualCategoryIdFinished: true,
  filter: {
    filters: [],
    filtersErrors: [],
    productFilter: [],
    isSwitchOn: false,
    productCategoriesAttributes: [{
      code: 'id',
      frontendInputType: 'selectProductsButton',
      id: 'attr_iHeYyv1MRdm1mG',
      isFilterable: true,
      isRequired: false,
      isSearchable: false,
      isSortable: false,
      isVariation: false,
      label: 'Products',
      value: 'id',
    }],
    attributesMatrixAttributes: [],
    product: {
      list: [],
      selectedProductList: { in: [], nin: [] },
      productsInIds: [],
      productsNotInIds: [],
      productsByIdsForIn: [],
      productsByIdsForNin: [],
      compoundSearch: { value: '' },
      leftSidePagination: defaultPagination,
      isProductDialogApplyFilterActive: false,
      categoryFetchProductListStart: false,
      categoryFetchProductListFinished: false,
      fetchProductsByIdsStarted: false,
      fetchProductsByIdsFinished: false,
    },
    category: {
      selectedTreeItems: [],
      showSelectCategoriesDialog: false,
      mappedCategories: [],
      selectedCategories: [],
      selectedDialogCategories: [],
      physicalCategorySearchQuery: '',
      showCategoriesDialog: false,
      physicalCategoriesIds: [],
    },
  },
  sort: {
    rankingRuleCode: null,
    sortingsErrors: [],
    sortRules: [],
  },
  facet: {
    facets: [],
  },
  isDataChanged: false,
  isNavigationSaving: false,
  errors: {},
  existedSearchPhrase: '',
};
const search = restTypes('search');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case search.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case search.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case search.fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };
    case search.fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case search.updateStart:
      return {
        ...state,
        ...restStates.updateStart,
      };
    case search.updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };
    case search.updateMultipleStart:
      return {
        ...state,
        ...restStates.updateMultipleStart,
      };
    case search.updateMultipleFinished:
      return {
        ...state,
        ...restStates.updateMultipleFinished,
        ...action.payload,
      };
    case search.createStart:
      return {
        ...state,
        ...restStates.createStart,
      };
    case search.createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        ...action.payload,
      };
    case FETCH_SEARCH_LIST_BY_NAME_START:
      return {
        ...state,
        fetchingByName: true,
        fetchedByName: false,
      };
    case FETCH_SEARCH_LIST_BY_NAME_FINISHED:
      return {
        ...state,
        ...action.payload,
        fetchingByName: false,
        fetchedByName: true,
      };
    case search.clearOneFinished:
      return {
        ...state,
        ...action.payload,
      };
    case search.deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
        ...action.payload,
      };
    case search.deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };
    case search.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case NAVIGATION_SEARCH_BY_PHRASES_START:
    case FIND_NAVIGATION_BY_PHRASE_START:
      return {
        ...state,
      };
    case NAVIGATION_SEARCH_BY_PHRASES_FINISHED:
    case FIND_NAVIGATION_BY_PHRASE_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case CAMPAIGN_FETCH_PRODUCT_LIST_START:
      return {
        ...state,
        ...action.payload,
        campaingFetchProductListStart: true,
        campaingFetchProductListFinished: false,
      };
    case CAMPAIGN_FETCH_PRODUCT_LIST_FINISHED:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
        },
        campaingFetchProductListStart: false,
        campaingFetchProductListFinished: true,
      };
    case CLEAR_CAMPAIGN_PRODUCT_STATE:
      return {
        ...state,
        product: initialState.product,
      };
    case CAMPAIGN_FETCH_PRODUCTS_BY_IDS_START:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
          fetchProductsByIdsStarted: true,
          fetchProductsByIdsFinished: false,
        },
      };
    case CAMPAIGN_FETCH_PRODUCTS_BY_IDS_FINISHED:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
          fetchProductsByIdsStarted: false,
          fetchProductsByIdsFinished: true,
        },
      };
    case SEARCH_SEARCH_FILTERS_START:
      return {
        ...state,
        searchStarted: true,
        searchFinished: false,
      };
    case SEARCH_SEARCH_FILTERS_FINISHED:
      return {
        ...state,
        searchStarted: false,
        searchFinished: true,
        searchResults: action.payload.list,
      };
    case COMPLETE_SEARCH_PRODUCTS_LIST_FINISHED:
    case COMPLETE_SEARCH_PRODUCTS_LIST_START:
    case SEARCH_SET_SELECTED_PRODUCT_LIST:
    case SEARCH_SET_PRODUCTS_IN_IDS:
    case SEARCH_SET_PRODUCTS_NIN_IDS:
    case SEARCH_UPDATE_LOADED_PRODUCTS_IN:
    case SEARCH_UPDATE_LOADED_PRODUCTS_NIN:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
        },
      };

    case FETCH_NAVIGATION_TO_DUPLICATE_START:
    case FETCH_NAVIGATION_TO_DUPLICATE_FINISHED:
    case UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_STARTED:
    case UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_FINISHED:
    case NAVIGATION_SET_CATEGORY_FIELDS:
    case NAVIGATION_SET_PRODUCT_CATEGORIES_ATTRIBUTES:
    case NAVIGATION_SET_ATTRIBUTES_MATRIX_ATTRIBUTES:
    case NAVIGATION_SET_FILTERS:
    case NAVIGATION_SET_PRODUCT_FILTER:
    case NAVIGATION_SET_PRODUCT_COMPOUND_QUERY:
    case NAVIGATION_SET_LEFT_SIDE_PAGINATION:
    case NAVIGATION_SET_IS_SWITCH_ON:
    case NAVIGATION_SET_IS_APPLY_FILTER_ACTIVE:
    case NAVIGATION_SET_MAPPED_CATEGORIES:
    case NAVIGATION_SET_SELECTED_CATEGORIES:
    case NAVIGATION_SET_PHYSICAL_CATEGORIES_IDS:
    case NAVIGATION_SET_SELECTED_TREE_ITEMS:
    case NAVIGATION_SET_SHOW_CATEGORIES_DIALOG:
    case NAVIGATION_CLEAR_NAVIGATION_FILTER_STATE:
    case NAVIGATION_SET_RANKING_RULE_CODE:
    case NAVIGATION_SET_SORTING_ERRORS:
    case NAVIGATION_SET_SORT_RULES:
    case NAVIGATION_SET_EXISTED_SEARCH_PHRASE:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
};
