import React, { memo, useContext } from 'react';
import List from '../../../../../../components/List';
import { headerList } from '../../../../../utils/headerList';
import { AbilityContext } from '../../../../../../components/AbilityContext';

const SubHeaderContent = memo(() => {
  const abilityContext = useContext(AbilityContext);

  return (
    <List
      dir="horizontal"
      className="justify-center"
      dataSource={headerList({ abilityContext })}
    />
  );
});

SubHeaderContent.propTypes = {};
SubHeaderContent.defaultProps = {};

export default SubHeaderContent;
