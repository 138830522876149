import React, { Component, memo } from 'react';
import { connect } from 'react-redux';
import {
  func, arrayOf, shape, bool,
} from 'prop-types';

import ParentLevelList from '../../components/ParentLevelList';

import {
  clearParentLevelChildren,
  toggleSelectedProduct,
  setAllProductsAsSelected,
  fetchProductList,
} from '../../../../actions/preview';

import {
  fetchActivityLogsByProductId,
} from '../../../../actions/product';

import {
  previewSelector,
} from './selectors/previewSelector';

import { fetchProductAttributeSets } from '../../../../actions/productAttribute';

import { mapPrefixToFilters } from '../../../../util/filterMapper';

class ParentContainerList extends Component {
  state = {};

  render() {
    const {
      attributes, certainProductAttributes, previewProps,
      productCommentsLoading, productComments, categoryUpdated,
      attributeSets, initialAttributesOptions, bulkUpdateActivityLogsFinished,
      listViewContainerProps,
    } = this.props;

    const onHeaderCellClick = (cell, order) => {
      this.props.fetchProductList({
        viewMode: 'frontendWithParams',
        filter: mapPrefixToFilters(previewProps.facetsPreFilters),
        pagination: listViewContainerProps.pagination,
        searchFilter: previewProps.searchFilter,
        fields: previewProps.fields,
        sort: [{
          field: cell.sortCode,
          order,
        }],
      });
    };

    const onProductSelect = (productItem) => {
      this.props.toggleSelectedProduct(productItem);
    };

    const onAllProductsSelect = (payload) => {
      this.props.setAllProductsAsSelected(payload);
    };

    return (
      <ParentLevelList
        listViewListProps={{
          productList: listViewContainerProps.items,
          disabled: listViewContainerProps.disabled,
          selectedEntity: listViewContainerProps.selectedEntity,
          onSortEnd: listViewContainerProps.onSortEnd,
          onEditClick: listViewContainerProps.onEditClick,
          pagination: listViewContainerProps.pagination,
          isEditMode: previewProps.isEditMode,
          productsCommentsCount: previewProps.productsCommentsCount,
          fetchActivityLogsByProductId: this.props.fetchActivityLogsByProductId,
          defaultImage: previewProps.defaultImage,
          selectedProducts: previewProps.selectedProducts,
          isHiddenProductsMode: previewProps.isHiddenProductsMode,
          productsFetching: previewProps.productsFetching,
          fetchProductAttributeSets: this.props.fetchProductAttributeSets,
          attributeSets,
          initialAttributesOptions,
          bulkUpdateActivityLogsFinished,
          attributes,
          certainProductAttributes,
          productCommentsLoading,
          productComments,
          onProductSelect,
          onAllProductsSelect,
          categoryUpdated,
          onHeaderCellClick,
        }}
      />
    );
  }
}

ParentContainerList.propTypes = {
  listViewContainerProps: shape().isRequired,
  previewProps: shape().isRequired,
  attributes: arrayOf(shape()).isRequired,
  certainProductAttributes: arrayOf(shape()).isRequired,
  productComments: arrayOf(shape()).isRequired,
  attributeSets: arrayOf(shape()).isRequired,
  initialAttributesOptions: arrayOf(shape()).isRequired,
  productCommentsLoading: bool.isRequired,
  categoryUpdated: bool.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  toggleSelectedProduct: func.isRequired,
  setAllProductsAsSelected: func.isRequired,
  fetchProductList: func.isRequired,
  fetchProductAttributeSets: func.isRequired,
  bulkUpdateActivityLogsFinished: bool.isRequired,
};

ParentContainerList.defaultProps = {};

const mapStateToProps = state => ({
  attributes: state.system.modes.preview,
  certainProductAttributes: state.productAttribute.certainProductAttributes,
  attributeSets: state.productAttribute.attributeSets,
  initialAttributesOptions: state.productAttribute.initialOptions,

  productCommentsLoading: state.product.fetchProductCommentsStart,
  productComments: state.product.productComments,
  previewProps: previewSelector(state.preview),

  categoryUpdated: state.virtualCategory.updated,
  bulkUpdateActivityLogsFinished: state.product.bulkUpdateActivityLogsFinished,
});

const mapDispatchToProps = {
  fetchActivityLogsByProductId,
  clearParentLevelChildren,
  toggleSelectedProduct,
  setAllProductsAsSelected,
  fetchProductList,
  fetchProductAttributeSets,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(ParentContainerList));
