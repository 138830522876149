import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  string, arrayOf, shape, func, bool, oneOfType, array, number,
} from 'prop-types';
import { isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import GroupItem from '../GroupItem';
import Field from '../../../../components/Field';
import IntlMessages from '../../../../util/IntlMessages';

const FilterGroup = (props) => {
  const {
    group, onChange, attributes, selectedTree, fetchAttributeOptions,
    fetchingAttributeOptions, filterGroupIndex, savedFiltersLength, filterTarget,
  } = props;

  const handleChange = (groupItem, groupIndex) => {
    const updatedGroup = [...group];
    let newGroup = { ...groupItem };
    if (groupItem.field === 'locked_attributes') {
      newGroup = {
        field: groupItem.field,
        operator: groupItem.operator,
      };
    }
    updatedGroup[groupIndex] = newGroup;

    onChange(updatedGroup);
  };

  const handleAddGroupItem = () => {
    const updatedGroup = [...group];
    updatedGroup.push({
      field: '',
      fieldOption: '',
      operator: 'eq',
    });
    onChange(updatedGroup);
  };

  const handleDeleteGroupItem = (itemIndex) => {
    const updatedGroup = [...group.filter((el, index) => index !== itemIndex)];
    onChange(updatedGroup);
  };

  const selectedMapperCats = [];
  for (let i = 0; i < props.selectedMapperCategories.length; i++){
    if (props.selectedMapperCategories[i]) {
      selectedMapperCats.push(props.selectedMapperCategories[i]);
    }
  }

  const classHasError = (filterTarget === 'repricer' && isEmpty(selectedTree))
  && isEmpty(selectedMapperCats) && !isEmpty(group) && !isEmpty(group[0].errors)
  && (group[0].errors.field || group[0].errors.fieldOption) ? 'form-group-card-has-error' : '';

  return (
    <Fragment>
      {group.map((g, index) => (
        <div className={`form-group-card ${classHasError}`} key={`search_filter_group_item_${group.indexOf(g)}`}>
          <span className="form-group-card-label"><IntlMessages id="title.groupOptions" /></span>
          <GroupItem
            groupItem={g}
            groups={group}
            itemIndex={index}
            onChange={handleChange}
            attributes={attributes}
            onDeleteItemClick={handleDeleteGroupItem}
            categoriesAttributesFetching={props.categoriesAttributesFetching}
            fetchAttributeOptions={fetchAttributeOptions}
            fetchingAttributeOptions={fetchingAttributeOptions}
            selectClassName={
              savedFiltersLength > 5 || index > 2 || filterGroupIndex > 2
              || props.previewFilter.list.length > 5
                ? 'dropup'
                : 'dropdown'
            }
            selectedMapperCategories={props.selectedMapperCategories}
            selectedTree={props.selectedTree}
            filterTarget={filterTarget}
          />
        </div>
      ))}

      <div className="buttons-row flex-grid-50">
        <Button
          color="primary"
          variant="outlined"
          className="btn-link btn-s text-normal"
          onClick={handleAddGroupItem}
        >
          <IntlMessages id="button.addGroup" />
        </Button>
        {(filterTarget === 'parent' || filterTarget === 'variation' || filterTarget === 'merchant' || filterTarget === 'repricer') && (
          <Badge color="primary" badgeContent={selectedTree.length} className="badge-s">
            <Field
              element="buttonMat"
              color="primary"
              variant="outlined"
              className="btn-link btn-s text-primary block"
              value={<IntlMessages id="text.selectCategories" />}
              onClick={props.onSelectCategoriesClick}
            />
          </Badge>
        )}
        {(filterTarget === 'mapper') && (
          <Badge color="primary" badgeContent={selectedMapperCats.length} className="badge-s">
            <Field
              element="buttonMat"
              color="primary"
              variant="outlined"
              className="btn-link btn-s text-primary block"
              value={<IntlMessages id="text.selectCategories" />}
              onClick={props.onSelectMapperCategoriesClick}
            />
          </Badge>
        )}
      </div>
    </Fragment>
  );
};

FilterGroup.propTypes = {
  onChange: func.isRequired,
  previewFilter: shape().isRequired,
  group: arrayOf(shape({
    field: string,
    operator: string,
    fieldOption: oneOfType([string, array]),
  })).isRequired,
  attributes: arrayOf(shape({
    elementType: string,
    label: string,
    value: string,
    options: arrayOf(shape({
      value: string,
      label: string,
    })),
  })).isRequired,
  onSelectCategoriesClick: func.isRequired,
  selectedTree: arrayOf(shape()).isRequired,
  categoriesAttributesFetching: bool.isRequired,
  fetchAttributeOptions: func.isRequired,
  fetchingAttributeOptions: bool,
  filterGroupIndex: number.isRequired,
  savedFiltersLength: number.isRequired,
};

FilterGroup.defaultProps = {
  fetchingAttributeOptions: false,
};

export const mapStateToProps = ({
  previewFilter,
}) => ({
  previewFilter,
});

export const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(FilterGroup);
