import React from 'react';
import { func } from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const ContextMenuContent = (props) => {
  const {
    onCreateSubcategory, onOpenNewCategory, onDuplicateCategoryClick,
  } = props;

  return (
    <div className="context-backdrop">
      <ListItem button onClick={onOpenNewCategory}>
        <ListItemIcon>
          <i className="zmdi zmdi-open-in-new zmdi-hc-lg" />
        </ListItemIcon>
        <ListItemText primary="Open category" />
      </ListItem>
      <ListItem button onClick={onCreateSubcategory}>
        <ListItemIcon>
          <i className="zmdi zmdi-plus zmdi-hc-lg" />
        </ListItemIcon>
        <ListItemText primary="Create subcategory" />
      </ListItem>
      <ListItem button onClick={onDuplicateCategoryClick}>
        <ListItemIcon>
          <i className="zmdi zmdi-copy zmdi-hc-lg" />
        </ListItemIcon>
        <ListItemText primary="Duplicate subcategory" />
      </ListItem>
    </div>
  );
};

ContextMenuContent.propTypes = {
  onOpenNewCategory: func.isRequired,
  onCreateSubcategory: func.isRequired,
  onDuplicateCategoryClick: func.isRequired,
};

export default ContextMenuContent;
