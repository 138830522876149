import React, { memo } from 'react';
import {
  shape,
} from 'prop-types';

import FilterItem from './FilterItem';

const FiltersList = memo((props) => {
  const {
    listProps,
  } = props;

  return (
    listProps.filters.map(f => (
      <FilterItem
        key={f.id}
        filter={f}
        itemProps={{
          filters: listProps.filters,
          attributesOptions: listProps.attributesOptions,
          attributeValueOptions: listProps.attributeValueOptions,
          errors: listProps.errors,
          handleFilterChange: listProps.handleFilterChange,
          loading: listProps.loading,
          disabled: listProps.disabled,
          fetchAttributeOptions: listProps.fetchAttributeOptions,
          fetchingAttributeOptions: listProps.fetchingAttributeOptions,
          handleAttributeRowDelete: listProps.handleAttributeRowDelete,
          handleSearchOptions: listProps.handleSearchOptions,
          setSelectedAttributeOption: listProps.setSelectedAttributeOption,
        }}
      />
    ))
  );
});

FiltersList.propTypes = {
  listProps: shape().isRequired,
};

FiltersList.defaultProps = {};

export default FiltersList;
