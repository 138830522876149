import React, { useState, useEffect } from 'react';
import Search from '../../components/Search';
import Pagination from '../../../../components/Pagination';
import Sorting from '../../../../components/Sorting';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import IntlMessages from '../../../../util/IntlMessages';
import Form from "../../../Rules/components/Form";
import DeleteRuleDialog from '../../../Rules/components/DeleteRuleDialog';
import { replaceVariationFilters } from "../../../../actions/rules";
import { initialTableScroll, moveTableScroll } from '../../../../util/table';
import PagePlaceholder from "../../../../components/PagePlaceholder";

export const RulesTable = ({
  rulesFields,
  rulesConditions,
  rulesConditionsOptions,
  actionOptions,
  discardSubsequentRulesOptions,
  getRule,
  deleteRule,
  getRulesData,
  rulesData,
  rule,
  rulesScroll,
  rulesCondition,
  itemsPerPages,
  itemsPerPage,
  formOpen,
  foundOptions,
  selectedProducts,
}) => {

  const [unsavedData, setUnsavedData] = useState([]);
  const [data, setData] = useState([]);
  const [allPages, setAllPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenedDeleteDialog, setIsOpenedDeleteDialog] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [currentRulesFields, setCurrentRulesFeelds] = useState([]);

  useEffect(() => {
    const formatData = [];
    const rulesFieldsKeys = Object.keys(rulesFields);
    const allRulesFieldsKeys = Object.keys(rulesFields);

    for (let i = 0; i < rulesFieldsKeys.length; i++) {
      if (rulesFields[rulesFieldsKeys[i]].field) {
        rulesFieldsKeys[i] = rulesFields[rulesFieldsKeys[i]].field;
      }
    }

    for (let i = 0; i < rulesData.length; i++) {
      if (rulesData[i]) {

        const formatDataItem = [];
        for (let j = 0; j < rulesFieldsKeys.length; j++) {
          if (rulesFieldsKeys[j].indexOf('.') !== -1) {
            const splitField = rulesFieldsKeys[j].split('.');
            if (rulesData[i][splitField[0]]) {
              formatDataItem[j] = rulesData[i][splitField[0]][splitField[1]];
            }

          } else {
            formatDataItem[j] = rulesData[i][rulesFieldsKeys[j]];
          }

        }
        formatData[i] = formatDataItem;
      }
    }

    let j = 0;
    const currentRulesFields = [];
    for (let i = 0; i < allRulesFieldsKeys.length; i++) {
      if (formatData && formatData[0] && formatData[0][i] !== undefined) {
        currentRulesFields[j] = rulesFields[allRulesFieldsKeys[i]];
        currentRulesFields[j].field = allRulesFieldsKeys[i];
        j++;
      }
    }
    setCurrentRulesFeelds(currentRulesFields);

    const currentFormatData = [];
    for (let i = 0; i < formatData.length; i++) {
      currentFormatData[i] = formatData[i].filter(function (el) {
        return el !== undefined;
      });
    }
    setData(currentFormatData);
    setUnsavedData(currentFormatData);

    setAllPages(Math.ceil(rulesScroll.total / itemsPerPage));
    let currentPage = Math.ceil(rulesScroll.offset / itemsPerPage) + 1;
    if (currentPage <= 0) {
      currentPage = 1;
    }
    setCurrentPage(currentPage);
  }, [
    rulesData, rulesScroll, rulesCondition, rulesFields, itemsPerPage
  ]);

  const handleEditRule = (e, id) => {
    getRule({id: id, rulesConditions: rulesConditions});
    replaceVariationFilters('rules');
  };

  const handleDeletingRule = (e, id) => {
    setIsOpenedDeleteDialog(true);
    setDeletingId(id);
  };

  const handleDeleteRule = () => {
    deleteRule({id: deletingId}).then(() => {
      getRulesData({limit: itemsPerPage, offset: 0});
    });
    setDeletingId(null);
    setIsOpenedDeleteDialog(false);
  }

  const handleCloseDeleteDialog = () => {
    setIsOpenedDeleteDialog(false);
  }

  const getTableRowColor = (item, itemKey, currentRulesFields) => {
    let color = '#FFFFFF';

    Object.keys(currentRulesFields).map((value, valueKey) => {
      const type = Object.values(currentRulesFields)[valueKey].type;
      const colors = Object.values(currentRulesFields)[valueKey].colors;
      if (type === 'select' && colors) {
        color = colors[item[valueKey]];
      }

      return color;
    });

    return color;
  };

  const RulesTitle = ((props) => {
    const { item, type, field, name, sortable, fixedWidth } = props;

    if (type === 'invisible') {
      return '';
    }

    let className = "mapper-no-wrap";
    if (fixedWidth === 'long') {
      className += " mapper-w-350";
    }
    if (fixedWidth === 'middle') {
      className += " mapper-w-155";
    }
    if (fixedWidth === 'short') {
      className += " mapper-w-75";
    }

    if (sortable === true) {
      className += " mapper-sortable";

      return (
        <Sorting
          getData={getRulesData}
          item={field ? field : item}
          condition={rulesCondition}
          name={name}
          className={className}
          itemsPerPage={itemsPerPage}
        />
      );
    }

    return (
      <TableCell><div className={className}>{ name }</div></TableCell>
    );
  });

  const RulesItem = ((props) => {
    const {
      valueKey, type, options, maxLength, item, value
    } = props;

    let tablevalue = '';

    if (type === 'checkbox' || type === 'flag') {
      tablevalue = value ? 'Yes' : 'No';
    } else if (type === 'amount') {
      const actionValue = item[valueKey - 1];
      if (actionValue.indexOf('amount') !== -1) {
        tablevalue = '$' + value;
      } else {
        tablevalue = value + '%';
      }
    } else if (type === 'select' && options) {
      tablevalue = value === true ? 'Yes' : 'No';
      const optionsKeys = Object.keys(options);
      for (let i = 0; i < optionsKeys.length; i++) {
        if (optionsKeys[i] === value) {
          tablevalue = options[optionsKeys[i]];
        }
      }
    } else {
      tablevalue = value || value === 0 ? value : 'N/A';
      if (maxLength && tablevalue.length > maxLength) {
        tablevalue = tablevalue.substring(0, maxLength) + '...';
      }
    }

    if (Array.isArray(tablevalue)) {
      let arrValue = '';
      for (let i = 0; i < tablevalue.length; i++) {
        arrValue += tablevalue[i];
        if (i < tablevalue.length - 1) {
          arrValue += ',';
        }
      }
      tablevalue = arrValue;
    }

    return (
      <TableCell className="mapper-td">
        {tablevalue}
      </TableCell>
    );
  });

  const RulesAction = (props) => {
    return (
      <TableCell className="mapper-td">
        <div className="rules-edit" onClick={ e => handleEditRule(e, props['id']) }>&#9998;</div>
        <div className="rules-delete" onClick={ e => handleDeletingRule(e, props['id']) }>&#10006;</div>
      </TableCell>
    );
  };

  initialTableScroll(
    'rulesTable',
    'rulesTableScroll',
    'rulesTableContainer',
    'rulesTableContainerScroll'
  );

  const moveScroll = () => {
    moveTableScroll('rulesTableContainer', 'rulesTableContainerScroll');
  };

  return (
    <>
      <Search
        rulesFields={rulesFields}
        itemsPerPage={itemsPerPage}
        unsavedData={unsavedData}
        data={data}
        scroll={rulesScroll}
        condition={rulesCondition}
      />

      <br/>

      <Form
        rulesFields={rulesFields}
        rulesConditions={rulesConditions}
        rulesConditionsOptions={rulesConditionsOptions}
        actionOptions={actionOptions}
        discardSubsequentRulesOptions={discardSubsequentRulesOptions}
        data={data}
        rule={rule}
        itemsPerPage={itemsPerPage}
        openEditForm={formOpen}
        condition={rulesCondition}
        scroll={rulesScroll}
        foundOptions={foundOptions}
        selectedProducts={selectedProducts}
      />

      <div id="rulesTableScrollDiv" className="mapper-table-scroll">
        <TableContainer
          id="rulesTableContainerScroll"
          className="product-table-container"
          onScroll={moveScroll}
        >
          <Table id="rulesTableScroll">
            <TableHead className="products-table-header">
              <TableRow><TableCell>&nbsp;</TableCell></TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </div>

      <TableContainer id="rulesTableContainer" className="product-table-container-no-scroll">
        <Table id="rulesTable" stickyHeader aria-label="sticky table" size="small">

          <TableHead className="products-table-header">
            <TableRow>
              {Object.keys(currentRulesFields).map((item, itemKey) => (
                <RulesTitle classNaqme="mapper-no-wrap"
                            key={itemKey}
                            item={item}
                            field={Object.values(currentRulesFields)[itemKey].field}
                            type={Object.values(currentRulesFields)[itemKey].type}
                            name={Object.values(currentRulesFields)[itemKey].name}
                            sortable={Object.values(currentRulesFields)[itemKey].sortable}
                            fixedWidth={Object.values(currentRulesFields)[itemKey].fixedWidth}
                />
              ))}
              <TableCell className="mapper-td">&nbsp;</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data && data.map((item, itemKey) => (
              <TableRow key={itemKey} style={{backgroundColor: getTableRowColor(item, itemKey, currentRulesFields)}}>
                {Object.keys(currentRulesFields).map((value, valueKey) => (
                  <RulesItem key={valueKey}
                             valueKey={valueKey}
                             type={Object.values(currentRulesFields)[valueKey].type}
                             options={Object.values(currentRulesFields)[valueKey].options}
                             maxLength={Object.values(currentRulesFields)[valueKey].maxLength}
                             item={item}
                             value={item[valueKey]}
                  />
                ))}
                <RulesAction id={item[0]} itemKey={itemKey} />
              </TableRow>
            ))}

            {data.length === 0 && (
              <TableRow>
                <TableCell className="rules-no-border">
                  <PagePlaceholder
                    icon={<i className="ti-face-sad text-primary text-lg" />}
                    title={<IntlMessages id="title.sorry" />}
                    subtitle={<IntlMessages id="title.noResultsFound" />}
                  />
                </TableCell>
              </TableRow>
            )}

          </TableBody>
        </Table>
      </TableContainer>

      <DeleteRuleDialog
        key="delete-rule"
        isOpened={isOpenedDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onDeleteRuleClick={handleDeleteRule}
      />

      <Pagination
        getData={getRulesData}
        dataCount={data ? data.length : 0}
        itemsPerPage={itemsPerPage}
        allPages={allPages}
        currentPage={currentPage}
        scroll={rulesScroll}
        condition={rulesCondition}
      />

    </>
  );
};

export default RulesTable;
