import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { uniq } from 'lodash';

import {
  SET_CATEGORY_TREE_ITEMS,
  SEARCH_PHYSICAL_CATEGORIES_START,
  SEARCH_PHYSICAL_CATEGORIES_FINISHED,
  CLEAR_PHYSICAL_CATEGORY_STATE,
  SEARCH_PHYSICAL_CATEGORIES_BY_IDS_START,
  SEARCH_PHYSICAL_CATEGORIES_BY_IDS_FINISHED,
} from './types';
import { physicalCategoriesSearchPath } from '../util/paths';
import { mapObjectToArray } from '../util/objectsToArray';
import { showErrorNotification } from '../util/api';

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';

export const setTreeItems = items => (dispatch) => {
  dispatch({
    type: SET_CATEGORY_TREE_ITEMS,
    payload: {
      items: camelizeKeys(items),
    },
  });
};

export const search = query => (dispatch) => {
  dispatch({
    type: SEARCH_PHYSICAL_CATEGORIES_START,
  });
  axios.post(physicalCategoriesSearchPath + '?ref=' + ref, decamelizeKeys(query)).then((res) => {
    dispatch({
      type: SEARCH_PHYSICAL_CATEGORIES_FINISHED,
      payload: { list: camelizeKeys(mapObjectToArray(res.data.data)) },
    });
  }, (error) => {
    dispatch({
      type: SEARCH_PHYSICAL_CATEGORIES_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  });
};

export const fetchCategoriesByIds = ids => async (dispatch) => {
  const pagination = { limit: 100 };
  const pages = Array.from(Array(Math.ceil(ids.length / pagination.limit)).keys());

  try {
    dispatch({
      type: SEARCH_PHYSICAL_CATEGORIES_BY_IDS_START,
    });

    const promises = await pages.map(async (page) => {
      const query = {
        filter: [{
          group: [{
            field: 'id',
            value: uniq(ids),
            operator: 'in',
          }],
        }],
        pagination: {
          page: page + 1,
          limit: pagination.limit,
        },
      };
      const categoriesResult = await axios.post(physicalCategoriesSearchPath + '?ref=' + ref, { ...query });
      const categories = camelizeKeys(categoriesResult.data.data);

      return categories;
    });

    const categoriesResult = await Promise.all(promises);
    const categories = categoriesResult.flat();

    dispatch({
      type: SEARCH_PHYSICAL_CATEGORIES_BY_IDS_FINISHED,
      payload: { list: categories },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: SEARCH_PHYSICAL_CATEGORIES_BY_IDS_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  }
};

export const clearPhysicalCategoryState = () => (dispatch) => {
  dispatch({ type: CLEAR_PHYSICAL_CATEGORY_STATE });
};

export default {
  setTreeItems,
  clearPhysicalCategoryState,
  fetchCategoriesByIds,
};
