import React from 'react';
import { isEmpty, uniq } from 'lodash';
import arrayToTree from 'array-to-tree';
import { TreeNode } from 'rc-tree';

export const generateTreeList = ({
  multiple,
  checkedKeys,
  activeCategory,
  session,
  ignoreScopeValidation,
  roles,
  checkable,
  isCategoriesTreeDisabled,
  localCategories,
  selected,
  isRadio,
  collapsed,
  loading,
  loadingId,
  disableNotSelected,
}) => {
  const switcherlessClassName = !checkable ? 'no-checkbox' : '';

  const sortedLocalCategories = localCategories.sort((a, b) => {
    if (a.id < b.id) { return -1; }
    if (a.id > b.id) { return 1; }
    return 0;
  });

  const mapSelected = (category) => {
    const selectedId = selected.find(sId => sId === category.id);
    return !!selectedId;
  };

  const createCategoriesTree = (categories) => {
    const levels = uniq(categories.map(c => c.level)).sort((a, b) => b - a);
    const withLevels = levels.map(l => ({
      level: l,
      categories: categories.filter(c => c.level === l),
    }));
    const treePayload = withLevels.reduce((acc, current) => {
      const lowerLevelItem = acc.find(item => item.level === (current.level + 1));
      const result = {
        ...current,
        categories: current.categories.map((c) => {
          const children = lowerLevelItem
            ? lowerLevelItem.categories.filter(item => item.parentId === c.id)
            : [];
          const itemIsSelected = isRadio ? selected.some(s => s === c.id) : mapSelected(c);

          return {
            ...c,
            value: c.id,
            label: c.name.en,
            selected: itemIsSelected,
            expanded: collapsed.includes(c.id),
            numChildren: children.length,
            children,
            disabled: disableNotSelected && !itemIsSelected,
          };
        }),
      };
      acc.push(result);
      return acc;
    }, []).map(item => item.categories).flat();

    const tree = arrayToTree(treePayload, {
      parentProperty: 'parentId',
      customID: 'id',
    });

    return tree || [];
  };

  const loop = (data) => {
    const result = data.map((item) => {
      const isExist = checkedKeys.some(k => k === item.id);
      const activeClassName = activeCategory === item.id ? 'selected' : '';
      const itemLoading = loading && loadingId === item.id;
      const loadingClassName = itemLoading ? 'loading' : '';
      const disabledClassName = item.isEnabled ? '' : 'not-enabled';
      let disabled = session.fetchedOne
        ? (!session.item.scope || !session.item.scope.some(c => c.id === item.id))
          && !session.item.roles.some(r => r === roles.admin.name || r === roles.influencer.name)
        : true;

      if (ignoreScopeValidation) disabled = false;
      if (item.hasChildren) {
        return (
          <TreeNode
            key={item.id}
            title={itemLoading ? 'loading...' : item.label}
            switcherIcon={<span className="material-icons">chevron_right</span>}
            disableCheckbox={!isEmpty(checkedKeys) && !isExist && !multiple}
            className={`${switcherlessClassName} ${activeClassName} ${loadingClassName} ${disabledClassName}`}
            selectable={!disabled}
            disabled={isCategoriesTreeDisabled || disabled || item.disabled}
          >
            {loop(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          disableCheckbox={!isEmpty(checkedKeys) && !isExist && !multiple}
          key={item.id}
          title={itemLoading ? 'loading...' : item.label}
          className={`no-switcher ${switcherlessClassName} ${activeClassName} ${loadingClassName} ${disabledClassName}`}
          selectable={!disabled}
          disabled={isCategoriesTreeDisabled || disabled || item.disabled}
          isLeaf
        />
      );
    });
    return result;
  };

  return (
    loop([...createCategoriesTree(sortedLocalCategories)])
  );
};

export default generateTreeList;
