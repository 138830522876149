import React from 'react';
import {
  func, string, node,
} from 'prop-types';
import { IconButton, Tooltip, Button } from '@material-ui/core';
import { Input, Badge } from 'reactstrap';
import Select from '../Select';
import Slider from '../Slider';
import Checkbox from '../Checkbox';
import ButtonMer from '../Button';
import AutoComplete from '../AutoComplete';
import DatePicker from '../DatePicker';
import AutoCompleteMultiple from '../AutoComplete/Multiple';
import { InputField as MatInput } from '../Input';
import SelectProductsButton from '../SelectProductsButton';

const Field = (props) => {
  const {
    onChange,
    onClick,
    onKeyPress,
    onSelect,
  } = props;
  switch (props.element) {
    case 'input':
      return <Input {...props} onChange={onChange} onKeyPress={onKeyPress} />;
    case 'dropdown':
    case 'select':
    case 'dropdown_swatch_color':
      return <Select {...props} onChange={onChange} />;
    case 'slider':
      return <Slider {...props} onChange={onChange} />;
    case 'textField':
      return <MatInput {...props} onChange={onChange} />;
    case 'checkbox':
      return <Checkbox {...props} onChange={onChange} />;
    case 'number':
    case 'text':
    case 'matInput':
      return <MatInput {...props} onChange={onChange} onKeyPress={onKeyPress} />;
    case 'buttonMat':
      return <Button {...props} onClick={onClick}>{props.value}</Button>;
    case 'button':
      return <ButtonMer {...props} onClick={onClick} />;
    case 'icon-button':
      if (props.tooltip) {
        return (
          <Tooltip title={props.tooltip.title} placement={props.tooltip.placement}>
            <div className="flex justify-center items-center">
              <IconButton {...props} onClick={onClick}>{props.icon}</IconButton>
            </div>
          </Tooltip>
        );
      }
      return <IconButton {...props} onClick={onClick}>{props.icon}</IconButton>;
    case 'message':
      return <Badge {...props}>{props.message}</Badge>;
    case 'autocomplete':
      return <AutoComplete {...props} onSelect={onSelect} />;
    case 'autocomplete-multiple':
      return <AutoCompleteMultiple {...props} onSelect={onSelect} />;
    case 'selectProductsButton':
      return <SelectProductsButton {...props} />;
    case 'datePicker':
      return <DatePicker {...props} />;
    default:
      return null;
  }
};

Field.propTypes = {
  onChange: func,
  onSelect: func,
  onClick: func,
  onKeyPress: func,
  element: string,
  icon: node,
  message: string,
};

Field.defaultProps = {
  onChange: null,
  onSelect: null,
  onClick: null,
  onKeyPress: null,
  element: '',
  icon: null,
  message: '',
};

export default Field;
