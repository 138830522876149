/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useScopeCategory = ({
  actions,
}) => {
  useEffect(() => {
    actions.fetchRootScopeCategories();
  }, []);

  useEffect(() => () => {
    actions.clearScopeCategoryTreeState();
  }, []);
};

export default useScopeCategory;
