export default (query) => {
  const splitedFilter = {
    productIds: [],
    queryString: [],
  };

  query.split(',').map(q => q.trim()).forEach((item) => {
    if (item.includes('prod_')) splitedFilter.productIds.push(item);
    if (!item.includes('prod_')) splitedFilter.queryString.push(item);
  });
  return splitedFilter;
};
