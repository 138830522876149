/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  func, shape, bool, string, arrayOf,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { Divider, CircularProgress } from '@material-ui/core';

import IntlMessages from '../../../../util/IntlMessages';

import Header from './Header';
import Designation from './Designation';
import Filter from './Filter';
import Sort from './Sort';
import Facet from './Facet';
import Footer from './Footer';

import {
  fetchNavigation,
  setNavigationFields,
} from '../../../../actions/previewNavigation';

const EditNavigationContent = memo((props) => {
  const {
    drawerState, navigationFetched, navigationItem, onClose, errors,
    selectedSearchFilter, selectedPromotion, existedSearchPhrase, facetFilter,
  } = props;

  const selectedNavigation = !isEmpty(selectedPromotion)
    ? selectedPromotion
    : selectedSearchFilter;

  useEffect(() => {
    if (drawerState.isOpen && selectedNavigation.id) {
      props.fetchNavigation(selectedNavigation.id);
    }
  }, [drawerState.isOpen]);

  const getUrl = ({ prefix = '', value, divider = '_' }) => `${value.includes(prefix)
    ? '' : prefix}${value ? value.toLowerCase().replace(/\s/g, divider)
    : ''}`;

  const handleChangeUrlKey = (e) => {
    const { name, value } = e.target;

    props.setNavigationFields({
      ...navigationItem,
      [name]: getUrl({
        value,
        divider: '-',
      }),
    });
  };

  const handleNavigationFieldChange = (e) => {
    const { name, value } = e.target;

    props.setNavigationFields({
      ...navigationItem,
      [name]: value,
    });
  };

  const handleSearchPhraseChange = (e) => {
    const { name, value } = e.target;
    const url = value ? value.split(', ') : [];
    props.setNavigationFields({
      ...navigationItem,
      [name]: url,
    });
  };

  return (
    navigationFetched ? (
      <div className="drawer-inner relative">
        <div className="drawer-scroll-container scroll-light">
          <Header
            id={navigationItem.id}
            name={navigationItem.name}
            type={navigationItem.type}
            onClose={onClose}
            handleNavigationTypeChange={handleNavigationFieldChange}
          />
          <Divider />
          <Designation
            name={navigationItem.name}
            searchPhrase={navigationItem.searchPhrase}
            urlKey={navigationItem.urlKey}
            type={navigationItem.type}
            onTextFieldChange={handleNavigationFieldChange}
            onSearchPhraseChange={handleSearchPhraseChange}
            onUrlKeyChange={handleChangeUrlKey}
            errors={errors}
            existedSearchPhrase={existedSearchPhrase}
            disabled={false} // TODO
          />
          <Divider className="dashed-pl-pr" />
          <Filter />
          <Divider className="dashed-pl-pr" />
          <Sort />
          <Divider className="dashed-pl-pr" />
          <Facet />
        </div>
        <Footer onClose={onClose} facetFilter={facetFilter} />
      </div>
    ) : (
      <div className="preview-drawer-loader-wrapper">
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className="progress-warning preview-drawer-loader custom-loader bottom"
          size={25}
          thickness={4}
        />
        <IntlMessages id="previewNavigation.text.loading" />
      </div>
    )
  );
});

EditNavigationContent.propTypes = {
  fetchNavigation: func.isRequired,
  onClose: func.isRequired,
  drawerState: shape().isRequired,
  selectedPromotion: shape().isRequired,
  selectedSearchFilter: shape().isRequired,
  errors: shape().isRequired,
  facetFilter: arrayOf(shape()).isRequired,
  navigationFetched: bool.isRequired,
  setNavigationFields: func.isRequired,
  existedSearchPhrase: string.isRequired,

  navigationItem: shape().isRequired,
};

EditNavigationContent.defaultProps = {};

const mapStateToProps = state => ({
  rankingsFetched: state.ranking.fetched,

  selectedPromotion: state.promotionWidget.selectedPromotion,
  selectedSearchFilter: state.preview.selectedSearchFilter,

  drawerState: state.quickEditDrawer.drawerState,
  navigationFetched: state.previewNavigation.fetchedOne,
  navigationItem: state.previewNavigation.item,
  errors: state.previewNavigation.errors,
  existedSearchPhrase: state.previewNavigation.existedSearchPhrase,

  isDataChanged: state.previewNavigation.isDataChanged,
});

const mapDispatchToProps = {
  fetchNavigation,
  setNavigationFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditNavigationContent);
