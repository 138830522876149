import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty, differenceWith, isEqual } from 'lodash';
import { decamelize, camelize } from 'humps';
import {
  func, shape, bool, object,
} from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';

import arrayMove from 'array-move';

import RctCollapsibleCard from '../../../components/RctCollapsibleCard/RctCollapsibleCard';
import PageTitleBar from '../../../components/PageTitleBar/PageTitleBar';
import ButtonGroup from '../../../components/ButtonGroup';
import Search from '../../../components/Search';
import Subheader from '../../../components/Subheader';

import Can from '../../../components/Can';
import { AbilityContext } from '../../../components/AbilityContext';

import IntlMessages from '../../../util/IntlMessages';
import buttonList from '../../../util/buttonList';
import { systemPath } from '../utils/paths';
import appPermissions from '../../../util/appPermissions';
import AttributesTable from './components/AttributesTable';

import {
  fetchAttributes,
  updateAttributes,
} from '../../../actions/system';

class Preview extends Component {
  static propTypes = {
    match: shape().isRequired,
    fetchAttributes: func.isRequired,
    updateAttributes: func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,

    system: shape({
      modes: object,
      fetched: bool,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      attributes: [],
      attrsChanged: {},
      showAlert: false,
      alertSettings: {
        title: 'alert.sureTitle',
        confirmBtnText: 'alert.leaveEditSystemPageTitle',
        body: 'alert.leaveEditSystemPageBody',
      },
      DnDUsed: false,
      searchValue: '',
      errors: {},
    };
  }

  componentDidMount() {
    this.props.fetchAttributes();
  }

  componentDidUpdate(prevProps) {
    const { updatedMultiple, updated, hasError } = this.props.system;
    const { DnDUsed } = this.state;

    if (prevProps.system.updatingMultiple && updatedMultiple) {
      this.handleAfterUpdateActions(hasError);
    }

    if (prevProps.system.updating && updated && DnDUsed) {
      this.handleAfterUpdateActions(hasError);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { fetched, modes } = nextProps.system;
    const exportable = document.location.href.indexOf('exportable') !== -1;

    if (fetched && !prevState.listRefreshed) {
      const values = exportable ? modes.export : modes.preview;

      return {
        listRefreshed: true,
        attributes: values,
        initialAttributes: values,
        exportable: exportable
      };
    }

    return null;
  }

  updateAttributesList = () => {
    this.props.fetchAttributes();
    this.setState({ listRefreshed: false, attrsChanged: {} });
  }

  handleAfterUpdateActions = (hasError) => {
    const { DnDUsed } = this.state;

    if (hasError) {
      this.handleNotificationError('Something went wrong');
    } else {
      this.updateAttributesList();

      if (DnDUsed) this.handleNotificationSuccess('Order of Attributes Updated Successfully!');
      else this.handleNotificationSuccess('Attributes Updated Successfully!');
    }

    if (this.state.closeAfterUpdate) {
      this.handleCloseAfterUpdate();
    }

    if (DnDUsed) {
      this.setState({ DnDUsed: false });
    }
  }

  handleCloseAfterUpdate = () => {
    this.setState({ closeAfterUpdate: false });
    this.goToSystemPage();
  }

  handleNotificationSuccess = (msg) => {
    NotificationManager.success(msg);
  }

  handleNotificationError = (msg) => {
    NotificationManager.error(msg);
  }

  handleNotificationWarning = (msg) => {
    NotificationManager.warning(msg);
  }

  goToSystemPage = () => this.props.history.push(systemPath);

  onChange = (data) => {
    const { event, row } = data;
    const {
      name, value, checked, type,
    } = event.target;
    const isCheckbox = type === 'checkbox';
    const attributes = new Array(...this.state.attributes);
    const changedAttribute = {
      ...attributes.find(a => a.key === row.key),
      [name]: isCheckbox ? checked : value,
      value: name === 'label'
        ? decamelize(camelize(value)).toLowerCase()
        : row.value,
    };

    const updatedAttributes = attributes.map((attr) => {
      let result = attr;

      if (attr.key === changedAttribute.key) result = changedAttribute;
      return result;
    });

    this.setState(prevState => ({
      attrsChanged: {
        ...prevState.attrsChanged,
        [row.key]: {
          [name]: isCheckbox ? checked : value,
        },
      },
      attributes: updatedAttributes,
    }));
  }

  onSearch = (e) => {
    const { value } = e.target;
    this.setState({ searchValue: value });
  }

  onSaveClick = () => {
    const { DnDUsed, attributes, initialAttributes, exportable } = this.state;
    const key = exportable ? 'export' : 'preview';
    const changes = differenceWith(initialAttributes, attributes, isEqual);
    const withEmptyLabels = attributes.filter(a => /^\s+$/.test(a.label) || !a.label);

    if (!isEmpty(withEmptyLabels)) {
      return this.setState({
        errors: {
          label: withEmptyLabels.map((l) => {
            const err = { [l.key]: true };
            return err;
          }),
        },
      });
    }

    if (!isEmpty(changes) || DnDUsed) {
      this.setState({ errors: {} });
      return this.props.updateAttributes(attributes, key);
    }

    return this.handleNotificationWarning('There are no changes');
  }

  onSaveAndCloseClick = () => {
    this.setState({ closeAfterUpdate: true });
    this.onSaveClick();
  }

  onCloseClick = () => {
    this.toggleAlert();
    this.setState({
      alertSettings: {
        title: 'alert.sureTitle',
        confirmBtnText: 'alert.leaveEditSystemPageTitle',
        body: 'alert.leaveEditSystemPageBody',
      },
    });
  };

  toggleAlert = () => this.setState(prevState => ({
    showAlert: !prevState.showAlert,
  }));

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { attributes } = this.state;
    const newItems = arrayMove(attributes, oldIndex, newIndex);

    const payload = newItems.map((attr) => {
      const data = attr;

      data.order = newItems.indexOf(attr);

      return data;
    });

    this.setState({ attributes: payload, DnDUsed: true });
  };

  render() {
    let listParams = {
      onCloseClick: this.onCloseClick,
    };
    if (this.context.can(
      appPermissions.system.permissions.update,
      appPermissions.system.name,
    )) {
      listParams = {
        ...listParams,
        onSaveAndCloseClick: this.onSaveAndCloseClick,
        onSaveClick: this.onSaveClick,
      };
    }

    const {
      attributes, showAlert, alertSettings, searchValue, exportable,
    } = this.state;
    const modesFetched = this.props.system.fetched;
    const showAttributesTable = modesFetched && attributes && attributes.length > 0;

    const noPermissions = !this.context.can(
      appPermissions.displayFields.permissions.update,
      appPermissions.displayFields.name,
    );

    const filteredAttributes = attributes.filter(a => a.label
      .toLowerCase().includes(searchValue.toLowerCase()));

    return (
      <div className="container-preview flex direction-column">
        <PageTitleBar
          title={<IntlMessages id="pageTitle.systemPreview" />}
          match={this.props.match}
        />
        <RctCollapsibleCard>
          <div className="content-btns">
            <ButtonGroup
              materialBtn
              className="btn-group-gray"
              list={buttonList(listParams)}
            />
          </div>
          <Can
            do={appPermissions.displayFields.permissions.view}
            on={appPermissions.displayFields.name}
          >
            <div className="content-search flex items-center">
              <div className="flex justify-center items-center">
                <Search
                  onChange={this.onSearch}
                  value={searchValue || ''}
                  className="search-system"
                  placeholder="Search attributes..."
                  errorMessage="text.errors.lessThan200Characters"
                  queryLengthLimit={170}
                />
              </div>
            </div>
            <div className="content-subheader">
              <Subheader title={<IntlMessages id="pageSubheader.systemPreview" />} />

              {!exportable ? (
                <>
                  <b><IntlMessages id="title.displayOnProduct" /></b>
                  &nbsp;&nbsp;&nbsp;
                  <a href="/system/preview?type=exportable"><b><IntlMessages id="title.isExportable" /></b></a>
                </>
              ) : (
                <>
                  <a href="/system/preview"><b><IntlMessages id="title.displayOnProduct" /></b></a>
                  &nbsp;&nbsp;&nbsp;
                  <b><IntlMessages id="title.isExportable" /></b>
                </>
              )}

              <br/><br/>

            </div>
            {showAttributesTable && (
              <AttributesTable
                attributes={searchValue ? filteredAttributes : attributes}
                onSortEnd={this.onSortEnd}
                onChange={this.onChange}
                errors={this.state.errors}
                disabled={noPermissions}
                exportable={exportable}
                {...this.props}
              />
            )}
          </Can>
        </RctCollapsibleCard>
        <SweetAlert
          warning
          btnSize="sm"
          show={showAlert}
          showCancel
          confirmBtnText={<IntlMessages id={alertSettings.confirmBtnText} />}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title={<IntlMessages id={alertSettings.title} />}
          onConfirm={this.goToSystemPage}
          onCancel={this.toggleAlert}
        >
          {<IntlMessages id={alertSettings.body} />}
        </SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  system: state.system,
});

const mapDispatchToProps = {
  fetchAttributes,
  updateAttributes,
};

Preview.contextType = AbilityContext;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Preview));
