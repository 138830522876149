import axios from 'axios';
import config from './config';
import { NotificationManager } from 'react-notifications';

export const showErrorNotification = (error, service) => {
  if (error.response && error.response.status === 503) {
    NotificationManager.error(service + ' Service is under Maintenance now, please try again later');
  } else if (error.response) {
    if (error.response.data.error.message) {
      NotificationManager.error(error.response.data.error.message);
    } else {
      NotificationManager.error(error.response.data.message);
    }
  } else if (error.message) {
    NotificationManager.error(error.message);
  }
};

const defaultApiConfig = {
  headers: { },
};

const createIMSClient = configuration => axios.create({ baseURL: config.imsApiPath, ...configuration });
export const IMSClient = createIMSClient(defaultApiConfig);

export const setIMSAuthInterceptor = (token) => {
  IMSClient.interceptors.request.use((configuration) => {
    configuration.headers.Authorization = token ? `Bearer ${token}` : '';
    return configuration;
  });
};

const createCDMSClient = configuration => axios.create({ baseURL: config.apiPath, ...configuration });
export const CDMSClient = createCDMSClient(defaultApiConfig);

const createPMACClient = configuration => axios.create({ baseURL: config.pmacApiPath, ...configuration });
export const PMACClient = createPMACClient(defaultApiConfig);

export const setPMACAuthInterceptor = (token) => {
  PMACClient.interceptors.request.use((configuration) => {
    configuration.headers.Authorization = token ? `Bearer ${token}` : '';
    return configuration;
  });
};

const createCAMSClient = configuration => axios.create({ baseURL: config.camsApiPath, ...configuration });
export const CAMSClient = createCAMSClient(defaultApiConfig);

export const setCAMSAuthInterceptor = (username, password) => {
  CAMSClient.interceptors.request.use((configuration) => {
    configuration.auth = {
      username: username,
      password: password,
    };
    return configuration;
  });
};

const createYotpoClient = configuration => axios.create(
  { baseURL: config.yotpoApiPath + '/widget/' + config.yotpoAppKey, ...configuration }
);
export const YotpoClient = createYotpoClient(defaultApiConfig);
