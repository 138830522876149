import React, { useContext } from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withRouter, Link } from 'react-router-dom';
import {
  shape, func, node, bool,
} from 'prop-types';

import Logo from '../Logo';
import List from '../List';
import { headerPreviewRightList } from '../../pages/utils/headerList';
import LanguageProvider from './LanguageProvider';

import { logOut } from '../../actions/auth';
import mainLogo from '../../assets/img/logo-small.png';
import { rootAppPath } from '../../util/paths';
import { AbilityContext } from '../AbilityContext';

const PimHeader = (props) => {
  const { fetching, updatingMultiple, updating } = props.pim;
  const isLoading = fetching || updatingMultiple || updating;
  const disabledClass = isLoading ? 'disabled' : '';

  const abilityContext = useContext(AbilityContext);
  const onLogoutClick = () => props.logOut();

  const { displayName } = props.session.item;
  const listParams = {
    onLogoutClick,
    userName: displayName || '',
    abilityContext,
  };
  const {
    content, controls, subHeaderContent
  } = props;
  const logo = {
    src: mainLogo,
    alt: '',
  };

  return (
    <AppBar position="static" className="rct-header">
      <Toolbar className="d-flex justify-content-between">
        <div key="1" className="d-flex align-items-center w-100 justify-between">
          <div className="site-logo">
            <Link to={rootAppPath}>
              <Logo logo={logo} />
            </Link>
          </div>
          {subHeaderContent && (
            <div key="2" className="d-flex align-center left-22">
              {subHeaderContent}
            </div>
          )}
          <div className="flex justify-center block">
            {content}
          </div>
          <div className="lists-group flex justify-end block">
            <div className="flex items-center text-nowrap">
              <List
                dir="horizontal"
                disable={isLoading}
                className="d-flex align-center list-item-clear"
                dataSource={headerPreviewRightList(listParams)}
                last={<LanguageProvider />}
              />
              <div className={`controls ${disabledClass}`}>
                {controls}
              </div>
            </div>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

PimHeader.propTypes = {
  session: shape({
    item: shape(),
  }).isRequired,
  pim: shape({
    fetching: bool,
    updatingMultiple: bool,
    updating: bool,
  }).isRequired,
  subHeaderContent: node,
  logOut: func.isRequired,
  content: node,
  controls: node,
};

PimHeader.defaultProps = {
  subHeaderContent: null,
  content: null,
  controls: null,
};

const mapStateToProps = state => ({
  pim: state.pim,
  settings: state.settings,
  navCollapsed: state.settings.navCollapsed,
  session: state.session,
});

const mapDispatchToProps = {
  logOut,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PimHeader));
