import React, {
  memo,
} from 'react';
import {
  string, func, arrayOf, shape,
} from 'prop-types';

import {
  Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,
  Paper,
} from '@material-ui/core';

const FiltersTargetController = memo((props) => {
  const {
    label, value, handleChange, options, className, name,
    displayMode,
  } = props;

  const onChange = e => handleChange(e.target.value);

  return (
    <FormControl className={`${className} filter-target-wrapper full-width pl-5 pr-5 pb-10 mb-5`} component="fieldset">
      <FormLabel className="radio-group-label" component="legend">{label}</FormLabel>
      <Paper className="filter-target-paper">
        <RadioGroup
          row
          className="radio-group"
          aria-label="filter target"
          name={name}
          value={value}
          onChange={onChange}
        >
          {
            options.map(o => (
              <FormControlLabel
                className="radio-group-item"
                key={o.value}
                value={o.value}
                control={<Radio disabled={displayMode === 'image_view'} color="primary" />}
                label={o.label}
              />
            ))
          }
        </RadioGroup>
      </Paper>
    </FormControl>
  );
});

FiltersTargetController.propTypes = {
  label: string,
  value: string,
  className: string,
  name: string,
  handleChange: func,
  options: arrayOf(shape()),
  displayMode: string.isRequired,
};

FiltersTargetController.defaultProps = {
  label: '',
  value: '',
  className: '',
  name: '',
  handleChange: null,
  options: [],
};

export default FiltersTargetController;
