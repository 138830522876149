import React from 'react';
import IntlMessages from "../../../util/IntlMessages";
import { Table, TableCell, TableContainer, TableBody, TableHead, TableRow } from "@material-ui/core";
import { movePricesTableScroll } from "../../../util/table";

export const History = ({productCommentsLoading, productComments}) => {
  if (productCommentsLoading) {
    return (
      <div className="comments no-comments">
        <IntlMessages id="preview.list.productComments.loading" />
      </div>
    );
  }

  const ProductChange = ({index, item, changes, prevItem = null}) => {
    const changesSubKeys = Object.keys(changes[item]);

    const returnValue = (val) => {
      const isObject = Object.prototype.toString.call(val) === '[object Object]';
      if (isObject === true) {
        return JSON.stringify(val);
      } else if (val === true) {
        return 'true';
      } else if (val === false) {
        return 'false';
      } else if (!val) {
        return 'null';
      }

      return val;
    }

    if (!changes || !changes[item]) {
      return (<></>);
    }

    if (changes[item]['was'] !== undefined && changes[item]['became'] !== undefined) {
      const wasValue = returnValue(changes[item]['was']);
      const becameValue = returnValue(changes[item]['became']);

      return (
        <p style={{margin: '0'}}>
          { prevItem ? prevItem + '.' : '' }{ item } was <b>{ wasValue }</b> became <b>{ becameValue }</b>
        </p>
      );
    } else if (changesSubKeys) {
      return (
        <>
          { changesSubKeys.map((changeItem, changeItemKey) => (
              <ProductChange
                key={'change-' + index + '-' + changeItemKey}
                item={changeItem}
                changes={changes[item]}
                prevItem={item}
              />
            ))
          }
        </>
      );
    } else {
      return (<></>);
    }
  };

  const ucFirst = (str) => {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
  };

  window.addEventListener('wheel', () => {
    movePricesTableScroll('historyTableHead');
  });

  window.addEventListener('click', () => {
    movePricesTableScroll('historyTableHead');
  });

  if (productComments.length > 0) {
    return (
      <div className="prices-table-scroll">
        <TableContainer className="product-table-container">
          <Table className="prices-table" id="historyTable" stickyHeader aria-label="sticky table" size="small">
            <TableHead id="historyTableHead" className="products-table-header mapper-sticky">
              <TableRow>
                <TableCell><IntlMessages id="product.edit.history.userID" /></TableCell>
                <TableCell><IntlMessages id="product.edit.history.affectedItems" /></TableCell>
                <TableCell><IntlMessages id="product.edit.history.action" /></TableCell>
                <TableCell><IntlMessages id="product.edit.history.changes" /></TableCell>
                <TableCell><IntlMessages id="product.edit.history.comment" /></TableCell>
                <TableCell><IntlMessages id="product.edit.history.createdAt" /></TableCell>
                <TableCell><IntlMessages id="product.edit.history.updatedAt" /></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                productComments.map((item, index) => {
                  const changesKeys = Object.keys(item.changes);

                  return (
                    <TableRow key={index}>
                      <TableCell>{ item.userId }</TableCell>
                      <TableCell style={{whiteSpace: 'nowrap'}}>
                        { item.affectedIds ? item.affectedIds.join(',') : 'N/A' }
                      </TableCell>
                      <TableCell>{ ucFirst(item.action) }</TableCell>
                      <TableCell style={{whiteSpace: 'nowrap'}}>
                        {
                          changesKeys.map((changeItem, changeItemKey) => (
                            <ProductChange
                              key={'change-' + index + '-' + changeItemKey}
                              item={changeItem}
                              changes={item.changes}
                            />
                          ))
                        }
                      </TableCell>
                      <TableCell>{ item.comment }</TableCell>
                      <TableCell style={{whiteSpace: 'nowrap'}}>{ item.createdAt }</TableCell>
                      <TableCell style={{whiteSpace: 'nowrap'}}>{ item.updatedAt }</TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  } else {
    return (
      <div className="comments no-comments">
        <IntlMessages id="preview.list.productComments.noComment" />
      </div>
    );
  }
}

export default History;
