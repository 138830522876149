import { pimSelector } from '../../pages/Home/selectors/pim';
import {
  setUpdatedProductsImagesOverrides,
  injectProducts,
  setProductsWithoutImages,
} from '../../../../actions/pim';

export const mapStateToProps = state => ({
  pim: pimSelector(state.pim),
  productsWithoutImages: state.pim.productsWithoutImages,
});

export const mapDispatchToProps = {
  setUpdatedProductsImagesOverrides,
  injectProducts,
  setProductsWithoutImages,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
