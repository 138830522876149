import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  shape,
} from 'prop-types';

import SweetAlert from 'react-bootstrap-sweetalert';
import swal from 'sweetalert';
import {
  uniqBy, isEmpty, uniq, isEqual, differenceWith,
} from 'lodash';
import { decamelize } from 'humps';
import { IconButton, CircularProgress } from '@material-ui/core';

import RctCollapsibleCard from '../../../components/RctCollapsibleCard/RctCollapsibleCard';
import PageTitleBar from '../../../components/PageTitleBar/PageTitleBar';
import ButtonGroup from '../../../components/ButtonGroup';
import UsersSubheader from './components/SubHeader';
import Table from '../../../components/Table';
import RctSectionLoader from '../../../components/RctSectionLoader/RctSectionLoader';

import IntlMessages from '../../../util/IntlMessages';
import buttonList from '../../../util/buttonList';
import {
  userTableValidations,
  newUserValidations,
  companyFieldValidations,
} from '../utils/validations';
import checkValidations from '../../../util/validator';

import { propTypes, defaultProps } from './propTypes';
import { mapStateToProps, mapDispatchToProps } from './connect';
import useUserEffects from './hooks/useUser';
import useTuneEffects from './hooks/useTune';
import useScopeCategoryEffects from './hooks/useScopeCategory';
import useStateStore from './hooks/useState';
import ScopeButton from './components/ScopeButton';
import RolesSelect from './components/RolesSelect';
import DisplayName from './components/DisplayName';
import NewUserDialog from './components/NewUserForm';
import EnabledCheckbox from './components/EnabledCheckbox';
import Info from './components/Info';
import { systemPath } from '../utils/paths';
import usersTableCells from '../utils/usersTableCells';
import UserScopeDialog from './components/ScopeDialog';
import Can from '../../../components/Can';
import appPermissions from '../../../util/appPermissions';
import { AbilityContext } from '../../../components/AbilityContext';
import roles from '../../../util/roles';
import { getEditInfluencerPath } from '../../Influencer/utils/paths';

const Users = (props) => {
  const state = useStateStore();

  useUserEffects(state, props);
  useScopeCategoryEffects(state, props);
  useTuneEffects(state, props);

  const abilityContext = useContext(AbilityContext);

  const toggleAlert = () => state.setShowAlert(!state.showAlert);
  const goToSystemPage = () => props.history.push(systemPath);
  const toggleAddUserDialog = () => state.setShowAddUserDialog(!state.showAddUserDialog);
  const handleAddUserClick = () => {
    const { usersChanged } = state;
    const { list } = props.user;
    if (usersChanged && !isEmpty(usersChanged)) {
      swal({
        title: 'Are you sure?',
        text: 'Changes will be discarded, do you want to continue?',
        icon: 'warning',
        dangerMode: true,
        buttons: true,
      })
        .then((willDiscard) => {
          if (willDiscard) {
            state.setUsers(list);
            state.setUsersChanged({});
            toggleAddUserDialog();
          }
        });
    } else toggleAddUserDialog();
  };

  const validateUsers = (users) => {
    const errors = {};
    let hasErrors = false;

    users.forEach((u) => {
      const err = checkValidations(userTableValidations, u);

      if (!isEmpty(err)) {
        errors[u.id] = err;
      }
    });

    if (!isEmpty(errors)) {
      hasErrors = true;
      state.setErrors(errors);
    } else {
      state.setErrors([]);
    }

    return hasErrors;
  };

  const handleSaveClick = () => {
    const { usersChanged, users } = state;
    const payload = [];

    Object.keys(usersChanged).forEach((uKey) => {
      payload.push({
        ...usersChanged[uKey],
        id: uKey,
      });
    });

    if (isEmpty(payload)) return;

    const errors = validateUsers(users);

    if (errors) return;
    props.updateUsers(payload);
  };

  const habdleUsersSearch = (e) => {
    const { value } = e.target;
    const { list } = props.user;

    const filteredUsers = list.filter(u => u.displayName
      .toLowerCase().includes(value.toLowerCase()));

    state.setSearchValue(value);
    state.setUsers(filteredUsers);
  };

  const onUserChange = (userId, e) => {
    const { users } = state;
    const {
      name, value, checked, type,
    } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;
    let userChanged = users.find(u => u.id === userId);
    userChanged = {
      ...userChanged,
      [name]: inputValue,
    };

    const payload = users.map((u) => {
      if (u.id === userId) {
        return userChanged;
      }
      return u;
    });

    const userToState = {
      ...state.usersChanged,
      [userId]: {
        ...state.usersChanged[userId],
        [name]: inputValue,
      },
    };
    state.setUsers(payload);
    const dif = differenceWith(payload, props.user.list, isEqual);

    if (!isEmpty(dif)) {
      state.setUsersChanged(userToState);
    } else {
      state.setUsersChanged([]);
    }
  };

  const handleScopeSelectClick = (userId) => {
    const currentUser = state.users.find(u => u.id === userId);

    state.setUserId(userId);
    state.setSelectedTreeItems(currentUser.scope || []);
    state.setShowScopeDialog(true);
  };

  const handleSaveAndCloseClick = () => {
    const { users } = state;
    const errors = validateUsers(users);

    if (errors) return;
    state.setCloseAfterSave(true);
    handleSaveClick();
  };

  const handleCloseClick = () => {
    const { usersChanged } = state;
    if (!isEmpty(usersChanged)) {
      toggleAlert();
      state.setAlertSettings({
        title: 'alert.sureTitle',
        confirmBtnText: 'alert.leaveEditRankingPageTitle',
        body: 'alert.leaveEditRankingPageBody',
      });
    }

    goToSystemPage();
  };

  const handleDeleteUserClick = (user) => {
    swal({
      title: 'Are you sure?',
      text: `The user ${user.displayName} will be permanently deleted`,
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    })
      .then((isConfirmed) => {
        if (isConfirmed) {
          state.setManagedUser(user);
          props.deleteUser(user.id);
        }
      });
  };

  const formatString = str => str
    .split(' ').map(word => word[0]
      .toUpperCase() + word.substr(1)).join(' ');

  const getRolesOptions = (userRoles) => {
    const rolesResult = Object.keys(userRoles).map((el) => {
      const str = userRoles[el].name;
      const res = {
        title: formatString(str),
        value: decamelize(el),
      };
      return res;
    });
    return rolesResult;
  };

  const getDeleteUserIcon = ({ user }) => (
    props.user.deleting && state.managedUser.id === user.id ? (
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className="progress-warning ml-10 custom-loader"
        size={15}
        thickness={4}
      />
    ) : (
      <IconButton
        className="btn-ico-md ml-10 text-danger"
        aria-label="Delete"
        disabled={!props.session.roles.admin}
        onClick={() => handleDeleteUserClick(user)}
      >
        <i className="zmdi zmdi-close" />
      </IconButton>
    )
  );

  const getTableRows = (users, noPermissions) => {
    const { errors } = state;
    const { session } = props;

    const currentUserIsAdmin = !!session.roles.admin;
    const rolesOptions = getRolesOptions(session.roles);

    const res = users.map((user) => {
      const userIsAdmin = !!user.roles.includes(roles.admin.name);
      const disableScopeButton = (currentUserIsAdmin && userIsAdmin) || noPermissions;
      const isInfluencer = user.roles.includes(roles.influencer.name);
      const linkIsdisabled = !props.session.roles.admin;
      const influencerPath = getEditInfluencerPath(user.id);

      const userData = {
        ...user,
        displayName: DisplayName({
          user,
          onChange: onUserChange,
          errors,
          disabled: noPermissions,
        }),
        userName: user.email,
        enabled: EnabledCheckbox({
          user,
          onChange: onUserChange,
          disabled: noPermissions,
        }),
        scope: ScopeButton({
          user,
          handleScopeSelectClick,
          disableScopeButton,
        }),
        roles: RolesSelect({
          user,
          onChange: onUserChange,
          errors,
          options: rolesOptions,
          disabled: noPermissions,
          isInfluencer,
          linkIsdisabled,
          influencerPath,
        }),
        delete: getDeleteUserIcon({
          user,
        }),
      };
      return userData;
    });

    return res;
  };

  const handleUpdateUserCategories = (formattedCategories) => {
    const { users, userId } = state;
    let userChanged = users.find(u => u.id === userId);

    userChanged = {
      ...userChanged,
      scope: formattedCategories,
    };

    const payload = users.map((u) => {
      if (u.id === userId) {
        return userChanged;
      }
      return u;
    });

    const userToState = {
      ...state.usersChanged,
      [userId]: {
        ...state.usersChanged[userId],
        scope: formattedCategories,
      },
    };

    state.setUsers(payload);
    state.setUsersChanged(userToState);
  };

  const handleTextFieldsChange = (e) => {
    const { value, name } = e.target;

    state.setNewUserForm({
      ...state.newUserForm,
      [name]: name === 'password' ? value.trim() : value,
    });
  };

  const handleRolesChange = (e) => {
    const {
      name, value, checked, type,
    } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    state.setNewUserForm({
      ...state.newUserForm,
      [name]: inputValue,
    });
  };

  const handleCompanyChange = (e) => {
    state.setCompanyName(e.target.value);
  };

  const validateNewUser = (newUser) => {
    let validationsList = { ...newUserValidations };
    let hasErrors = false;

    if (newUser.roles && newUser.roles.includes(roles.influencer.name)) {
      validationsList = { ...validationsList, ...companyFieldValidations };
    }

    const errors = checkValidations(
      validationsList,
      { ...newUser, companyName: state.companyName },
    );

    const isUserExist = state.users.some(user => user.email === state.newUserForm.email);
    if (isUserExist) {
      hasErrors = true;
      errors.email = [{
        message: 'system.users.create.email.used',
        type: 'email',
      }];
    }

    if (isEmpty(errors)) {
      state.setNewUserErrors(errors);
    } else {
      hasErrors = true;
      state.setNewUserErrors(errors);
    }


    return ({ hasErrors, errors });
  };

  const addNewUser = () => {
    const res = validateNewUser(state.newUserForm);

    if (!res.hasErrors) {
      state.setShowLoader(false);
      props.createUser({
        ...state.newUserForm,
        name: 'guest',
        enabled: true,
      });
    }
  };

  const closeScopeDialog = () => {
    state.setShowScopeDialog(false);
    props.clearScopeCategorySearchState();
    props.onToggleScopeCategoryTreeCollapse([]);
    state.setSelectedTreeItems([]);
  };

  const commitCheckedCategories = async (selected) => {
    if (!isEmpty(selected)) {
      const hasChanges = !isEqual(selected.sort(), state.selectedTreeItems.sort());
      const selectedIds = selected.some(s => typeof s === 'string')
        ? selected : selected.map(s => s.id);
      let categoriesFound = [];
      const filterBody = {
        filter: [{
          group: [{
            field: 'id',
            value: uniq(selectedIds),
            operator: 'in',
          }],
        }],
        pagination: {
          page: 1,
          limit: 500,
        },
      };

      if (!hasChanges) return state.setShowScopeDialog(false);

      if (!isEmpty(selectedIds)) {
        await props.searchScopeCategories(filterBody, (res) => {
          categoriesFound = res;
        });
      }

      const categoriesByIds = categoriesFound
        .filter(category => selected
          .includes(category.id));

      const uniqueCategoryPayload = uniqBy(categoriesByIds, 'id');

      const payload = uniqueCategoryPayload.filter(c => c.id).map((category) => {
        const selectedCategory = {
          id: category.id,
          name: category.name,
          pathByName: category.pathByName || '',
        };
        return selectedCategory;
      });

      handleUpdateUserCategories(payload);
    } else handleUpdateUserCategories([]);

    state.setShowScopeDialog(false);
    props.clearScopeCategorySearchState();
    return props.onToggleScopeCategoryTreeCollapse([]);
  };

  const handleSearchScopeCategory = (query) => {
    props.searchScopeCategories({
      full_text: {
        fields: [
          'name.en',
        ],
        value: query,
        match_type: 'must',
      },
      pagination: {
        page: 1,
        limit: 500,
      },
    });
  };

  const {
    users, showScopeDialog, showAlert,
    alertSettings, showLoader, usersChanged, selectedTreeItems,
  } = state;

  const { session } = props;
  const sessionFetched = props.session.fetchedOne;
  const { fetched } = props.user;

  let listParams = {
    onCloseClick: handleCloseClick,
  };

  if (abilityContext.can(
    appPermissions.users.permissions.update,
    appPermissions.users.name,
  )) {
    listParams = {
      ...listParams,
      onSaveAndCloseClick: handleSaveAndCloseClick,
      onSaveClick: handleSaveClick,
      buttonSettings: {
        saveAndClose: {
          material: {
            disabled: isEmpty(usersChanged),
          },
        },
        save: {
          material: {
            disabled: isEmpty(usersChanged),
          },
        },
      },
    };
  }

  let rows = [];
  let rolesOptions = [];
  const showInfo = usersChanged && !isEmpty(usersChanged);

  const noPermissions = !abilityContext.can(
    appPermissions.users.permissions.update,
    appPermissions.users.name,
  );

  if (fetched && sessionFetched && !isEmpty(users)) {
    rows = getTableRows(users, noPermissions);
    rolesOptions = getRolesOptions(session.roles);
  }

  return (
    <div className="container-users flex direction-column">
      {showLoader && <RctSectionLoader />}
      <PageTitleBar
        title={<IntlMessages id="pageTitle.systemUsers" />}
        match={props.match}
      />
      <RctCollapsibleCard>
        <div className="content-btns items-center justify-between block">
          <ButtonGroup
            materialBtn
            list={buttonList(listParams)}
          />
        </div>
      </RctCollapsibleCard>
      <RctCollapsibleCard>
        <Can
          do={appPermissions.users.permissions.view}
          on={appPermissions.users.name}
        >
          <UsersSubheader
            onSearch={habdleUsersSearch}
            searchValue={state.searchValue}
            onAddUserClick={handleAddUserClick}
            onSyncClick={props.syncUsers}
            usersSyncing={props.user.usersSyncing}
            content={(
              <>
                <Info showInfo={showInfo} />
                <div className="pl-15 users-info-message">
                  <IntlMessages id="systemPage.subheader.infoLabel.autoSync" />
                </div>
              </>
            )}
          />
          <div className="content-data m-15-rev">
            <NewUserDialog
              fields={state.newUserForm}
              companyName={state.companyName}
              rolesOptions={rolesOptions}
              onTextFieldsChange={handleTextFieldsChange}
              onRolesChange={handleRolesChange}
              onCompanyChange={handleCompanyChange}
              errors={state.newUserErrors}
              loading={
                props.user.creating
                || props.tune.affiliateCreating
                || props.tune.affiliateUserCreating
                || props.influencer.creating
              }
              dialog={{
                open: state.showAddUserDialog,
                onOk: addNewUser,
                onClose: toggleAddUserDialog,
              }}
            />
            <Table
              className="table-cell-xs"
              rows={rows}
              cells={usersTableCells}
              align="left"
            />
          </div>
        </Can>
      </RctCollapsibleCard>
      <SweetAlert
        warning
        btnSize="sm"
        show={showAlert}
        showCancel
        confirmBtnText={<IntlMessages id={alertSettings.confirmBtnText} />}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="success"
        title={<IntlMessages id={alertSettings.title} />}
        onConfirm={goToSystemPage}
        onCancel={toggleAlert}
      >
        {<IntlMessages id={alertSettings.body} />}
      </SweetAlert>
      <UserScopeDialog
        scopeCategory={props.scopeCategory}
        showScopeDialog={showScopeDialog}
        selectedTreeItems={selectedTreeItems}
        closeScopeDialog={closeScopeDialog}
        commitCheckedCategories={commitCheckedCategories}
        clearScopeCategorySearchState={props.clearScopeCategorySearchState}
        fetchChildScopeCategories={props.fetchChildScopeCategories}
        handleSearchScopeCategory={handleSearchScopeCategory}
        onToggleScopeCategoryTreeCollapse={props.onToggleScopeCategoryTreeCollapse}
        handleLoadScopeSubcategories={props.handleLoadScopeSubcategories}
        loadAllChildCategories={props.loadAllChildCategories}
        loading={
          props.scopeCategory.fetchChildrenStarted
          || props.scopeCategory.fetchingParentCategoriesByIds
        }
        ignoreScopeValidation
      />
    </div>
  );
};

Users.propTypes = {
  ...propTypes,
  session: shape().isRequired,
  user: shape().isRequired,
};
Users.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
