import React, { Component } from 'react';
import { connect } from 'react-redux';
import MatButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { FormGroup, Label, Input } from 'reactstrap';
import { isEmpty, uniq } from 'lodash';
import swal from 'sweetalert';
import { NotificationManager } from 'react-notifications';

import InputErrors from '../../../../components/InputErrors';
import ButtonGroup from '../../../../components/ButtonGroup';
import PageTitleBar from '../../../../components/PageTitleBar/PageTitleBar';
import IntlMessages from '../../../../util/IntlMessages';
import buttonList from '../../../../util/buttonList';
import checkValidations from '../../../../util/validator';
import AttributesRow from '../../components/AttributesRow';
import ScopeDialog from '../../../../components/ScopeDialog';
import Popover from '../../../../components/Popover';
import { newRankingValidations, rowAttributeValidations } from '../../utils/validations';
import { rankingsAppPath } from '../../../../util/paths';
import { propTypes, defaultProps } from './propTypes';
import { getEditRankingPath } from '../../utils/paths';
import { mapStateToProps, actionCreators } from './connect';
import { AbilityContext } from '../../../../components/AbilityContext';
import appPermissions from '../../../../util/appPermissions';

class New extends Component {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  constructor(props) {
    super(props);
    this.state = {
      collapsed: [],
      selectedTreeItems: [],
      newRanking: {
        name: '',
        rankAttributes: [{
          field: '',
          normalization: '',
          pushing: '',
          weight: 0,
          isLocked: false,
        }],
      },
      errors: {},
      attributesErrors: {},
      showScopeDialog: false,
      closePage: false,
      categoriesWithRankingRule: [],
    };
  }

  componentDidMount() {
    this.props.fetchRootScopeCategories();
  }

  componentDidUpdate(prevProps) {
    const { selectedTreeItems } = this.state;
    const { ranking } = this.props;
    const { ranking: prevRanking } = prevProps;

    if (ranking.created && prevRanking.creating) {
      const categories = this.mapRankingIdToScopeCategory(ranking.item.name);

      if (!isEmpty(selectedTreeItems)) {
        this.props.bulkUpdateVirtualCategories(categories, { mapToMatrix: false });
      }

      setTimeout(() => {
        this.postCreationAction();
      }, 1000);
    }

    if (prevProps.scopeCategory.fetchingScopeCategoriesByIds
    && this.props.scopeCategory.fetchedScopeCategoriesByIds) {
      this.checkForRankingRule(this.props.scopeCategory.categoriesByIds);
      this.updateSelectedCategories();
    }
  }

  componentWillUnmount() {
    this.props.clearCreateRankingState();
    this.props.clearScopeCategoriesState();
  }

  updateSelectedCategories = () => {
    this.setState({
      selectedTreeItems: this.props.scopeCategory.categoriesByIds,
    });
  }

  postCreationAction = () => {
    this.props.fetchRankingList();
    if (this.state.closePage) {
      this.goToRankingsPage();
    } else {
      this.props.history.push(getEditRankingPath(this.props.ranking.item.id));
    }
  }

  mapRankingIdToScopeCategory = (rankingName) => {
    const { selectedTreeItems } = this.state;
    return selectedTreeItems.map(s => ({
      id: s.id,
      isEnabled: true,
      includeInMenu: true,
      name: s.name,
      description: s.description,
      virtualParams: {
        ...s.virtualParams,
        rankingRuleCode: rankingName,
        sortRules: [],
      },
    }));
  }

  openScopeDialog = () => {
    this.setState({
      showScopeDialog: true,
    });
  }

  closeScopeDialog = () => {
    this.setState({
      showScopeDialog: false,
    });
    this.props.clearScopeCategorySearchState();
  }

  addNewAttribute = () => {
    const { newRanking } = this.state;
    newRanking.rankAttributes.push({
      field: '',
      normalization: '',
      pushing: '',
      weight: 0,
      isLocked: false,
      key: new Date().getTime(),
    });

    this.setState(prevState => ({
      ...prevState,
      newRanking,
    }));
  }

  goToRankingsPage = () => {
    this.props.history.push(rankingsAppPath);
  }

  onRankingClose = () => {
    swal({
      title: 'Are you sure?',
      text: 'If you leave this page then any unsaved changes will be lost.',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    })
      .then((willDiscard) => {
        if (willDiscard) {
          this.goToRankingsPage();
        }
      });
  }

  handleBeforeSaveClick = (closeAfterUpdate) => {
    const { categoriesWithRankingRule } = this.state;
    const { newRanking } = this.state;

    if (this.validate(newRanking)) {
      if (!isEmpty(categoriesWithRankingRule)) {
        const content = document.createElement('span');
        content.innerHTML = `These categories already have a ranking rule:
        <br><strong>${categoriesWithRankingRule.map(r => `${r.en}`).join(', ')}</strong>
        <br>Are you sure you want to overwrite the Ranking rule in these categories?`;

        return swal({
          title: 'Notice',
          content,
          icon: 'warning',
          dangerMode: true,
          buttons: true,
        })
          .then((willDiscard) => {
            if (willDiscard) {
              this.beforeSaveRanking(closeAfterUpdate);
            }
          });
      }
      return this.beforeSaveRanking(closeAfterUpdate);
    }
    return false;
  }

  saveRanking = (closeAfterUpdate) => {
    const { newRanking } = this.state;

    this.props.fetchRankingByName(newRanking.name, (isExist) => {
      if (!isExist) {
        this.props.createRank(newRanking, { isSync: true });
        if (closeAfterUpdate) {
          this.setState({
            closePage: true,
          });
        }
      } else {
        NotificationManager.error(<IntlMessages id="ranking.create.alert.error" />);
      }
    });
  }

  beforeSaveRanking = (closeAfterUpdate) => {
    const { selectedTreeItems } = this.state;
    const categoriesWithSortRules = selectedTreeItems
      .filter(c => !isEmpty(c.virtualParams.sortRules));

    if (!isEmpty(categoriesWithSortRules)) {
      const content = document.createElement('span');
      content.innerHTML = `This action will clear the sorting rules in the following categories:
      <br><strong>${categoriesWithSortRules.map(c => `${c.name.en}`).join(', ')}</strong>`;

      swal({
        title: 'Confirmation',
        content,
        icon: 'warning',
        dangerMode: true,
        buttons: true,
      })
        .then((isAccepted) => {
          if (isAccepted) {
            this.saveRanking(closeAfterUpdate);
          }
        });
    } else {
      this.saveRanking(closeAfterUpdate);
    }
  }

  handleAttributesErrors = (attributes) => {
    const attributesErrors = {};
    attributes.forEach((item, index) => {
      const errors = checkValidations(rowAttributeValidations, item);
      if (Object.keys(errors).length > 0) {
        attributesErrors[index] = errors;
      }
    });

    return attributesErrors;
  }

  count = names => names.reduce((a, b) => Object.assign(a, { [b]: (a[b] || 0) + 1 }), {});

  duplicates = dict => Object.keys(dict).filter(a => dict[a] > 1);

  validate = (data) => {
    const form = {
      isCompleted: data.rankAttributes.map(item => item.weight).reduce((a, b) => a + b, 0),
      name: data.name,
    };
    const errors = checkValidations(newRankingValidations, form);
    const items = data.rankAttributes.map(r => r.field);
    const countDuplicatedAttributes = this.duplicates(this.count(items)).length;
    if (countDuplicatedAttributes > 0) {
      errors.hasDuplicates = [{
        type: 'duplicated',
        message: 'rankings.errors.hasDuplicates',
      }];
    }

    const attributesErrors = this.handleAttributesErrors(data.rankAttributes);

    this.setState({
      errors,
      attributesErrors,
    });
    if (Object.keys(errors).length > 0 || Object.keys(attributesErrors).length > 0) {
      return false;
    }
    return true;
  }

  deleteAttribute = (rowIndex) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to permanently delete this attribute?',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    })
      .then((willDiscard) => {
        if (willDiscard) {
          this.deleteAttributeConfirmed(rowIndex);
        }
      });
  }

  deleteAttributeConfirmed = (rowIndex) => {
    const { newRanking } = this.state;
    const newChangedRanking = { ...newRanking };
    const splicedChangedRanking = {
      ...newRanking,
      rankAttributes: newChangedRanking.rankAttributes
        .filter((f, index) => index !== rowIndex),
    };

    this.setState(prevState => ({
      ...prevState,
      rowIndexToDelete: rowIndex,
      newRanking: splicedChangedRanking,
    }));
  }

  onSlide = ({ value, rowIndex }) => {
    const { newRanking } = this.state;
    const newChangedRanking = { ...newRanking };
    const newGroup = newChangedRanking.rankAttributes.map((g) => {
      let group = g;

      if (newChangedRanking.rankAttributes.indexOf(group) === rowIndex) {
        group = {
          ...group,
          weight: value,
        };
      }
      return group;
    });

    this.setState(prevState => ({
      newRanking: {
        ...prevState.newRanking,
        rankAttributes: newGroup,
      },
    }));
  };

  onChange = ({ event, rowIndex }) => {
    const { value, name } = event.target;
    const { newRanking } = this.state;
    const newChangedRanking = { ...newRanking };

    const newGroup = newChangedRanking.rankAttributes.map((g) => {
      let group = g;

      if (newChangedRanking.rankAttributes.indexOf(group) === rowIndex) {
        group = {
          ...group,
          [name]: value,
        };
      }
      return group;
    });

    this.setState(prevState => ({
      newRanking: {
        ...prevState.newRanking,
        rankAttributes: newGroup,
      },
    }));
  };

  updateRankingName = ({ target: { value } }) => {
    const { newRanking } = this.state;
    const newChangedRanking = { ...newRanking, name: value };

    newRanking.name = value;
    this.setState(prevState => ({
      ...prevState,
      newRanking: newChangedRanking,
      errors: {
        ...prevState.errors,
        name: '',
      },
    }));
  };

  checkForRankingRule = (categories) => {
    const categoriesWithRankingRule = categories
      .filter(cat => cat.virtualParams.rankingRuleCode)
      .map(cat => cat.name);

    this.setState({ categoriesWithRankingRule });
  }

  commitCheckedCategories = (selected) => {
    this.setState({
      showScopeDialog: false,
      selectedTreeItems: selected,
    });

    if (!isEmpty(selected)) {
      this.props.fetchScopeCategoriesByIds(uniq(selected));
    }
  }

  handleToggleCollapse = collapsed => this.setState({ collapsed });

  handleOnTreeSelect = (item) => {
    const { selectedTreeItems } = this.state;
    if (!selectedTreeItems.find(treeEl => treeEl.id === item.id)) {
      selectedTreeItems.push(item);
    } else {
      const index = selectedTreeItems.indexOf(
        selectedTreeItems.find(treeEl => treeEl.id === item.id),
      );
      selectedTreeItems.splice(index, 1);
    }
    this.setState({ selectedTreeItems });
  }

  handleSearchScopeCategory = (query) => {
    this.props.searchScopeCategories({
      full_text: {
        fields: [
          'name.en',
        ],
        value: query,
        match_type: 'must',
      },
      pagination: {
        page: 1,
        limit: 500,
      },
    });
  }

  getAttributesOptions = () => ([...this.props.rankingAttribute.list.map(aItem => ({
    ...aItem,
    disabled: this.state.newRanking.rankAttributes.some(item => item.field === aItem.value),
  }))] || [])

  render() {
    const {
      newRanking,
      showScopeDialog,
      errors,
      attributesErrors,
      selectedTreeItems,
    } = this.state;
    const {
      match, pushing, normalization, rankingAttribute, scopeCategory,
    } = this.props;
    const { creating, fetchRankingByNameStarted } = this.props.ranking;
    const noEventClass = creating ? 'no-event' : '';

    let listParams = {
      onCloseClick: this.onRankingClose,
      disabled: (
        creating
        || scopeCategory.fetchingScopeCategoriesByIds
        || fetchRankingByNameStarted
      ),
    };

    if (this.context.can(
      appPermissions.ranking.permissions.create,
      appPermissions.ranking.name,
    )) {
      listParams = {
        ...listParams,
        onSaveAndCloseClick: () => this.handleBeforeSaveClick(true),
        onSaveClick: () => this.handleBeforeSaveClick(false),
      };
    }

    return (
      <div className="container-rangings">
        <PageTitleBar
          title={<IntlMessages id="pageTitle.rankingNew" />}
          match={match}
        />
        <div className={`container-rangings ${noEventClass}`}>
          <ButtonGroup
            materialBtn
            className="btn-group-gray flex items-center"
            loading={creating}
            list={buttonList(listParams)}
          />
          <div className="form-dashed form-inline items-baseline">
            <FormGroup className="mb-10 mr-sm-10 mb-sm-0">
              <Label for="name" className="mr-sm-10"><IntlMessages id="rankings.nameLabel" /></Label>
              <div className="form-control-wrapper">
                <Input
                  className={errors.name ? 'has-error' : ''}
                  type="text"
                  name="name"
                  id="name"
                  onChange={this.updateRankingName}
                />
                {errors.name && <InputErrors className="errors-rel" errors={errors.name} />}
              </div>
            </FormGroup>
            <MatButton
              variant="contained"
              color="primary"
              className="text-white"
              onClick={this.openScopeDialog}
            >
              <IntlMessages id="rankings.scopeBtn" />
            </MatButton>
            {selectedTreeItems && !isEmpty(selectedTreeItems) && (
              <Popover
                buttonClassName="btn-clear ml-10"
                buttonText={<i className="ti-eye" />}
                content={(
                  <div className="popover-content">
                    {selectedTreeItems.map(sItem => (
                      sItem.name && (
                        <div key={sItem.id} className="popover-content-item">
                          {sItem.name.en}
                          {sItem.name.he && <div className="popover-content-subitem">{sItem.name.he}</div>}
                        </div>
                      )
                    ))}
                  </div>
                )}
              />
            )}
          </div>
          {
            newRanking && newRanking.rankAttributes.length > 0
            && newRanking.rankAttributes.map((row, index) => (
              <AttributesRow
                key={`${row.key}_attribute-row`}
                values={row}
                dataSource={{
                  totalWeigt: newRanking.rankAttributes
                    .map(item => item.weight)
                    .reduce((a, b) => a + b, 0),
                  normalizationList: normalization.list || [],
                  pushingList: pushing.list || [],
                  options: this.getAttributesOptions(),
                }}
                onChange={event => this.onChange({
                  event, row, rowIndex: newRanking.rankAttributes.indexOf(row),
                })}
                onSlide={value => this.onSlide({
                  value, row, rowIndex: newRanking.rankAttributes.indexOf(row),
                })}
                errors={attributesErrors ? attributesErrors[index] : []}
                content={(
                  newRanking.rankAttributes.length > 1 && (
                    <IconButton
                      aria-label="Delete"
                      className="btn-mui-sm ml-10 text-danger"
                      onClick={() => this.deleteAttribute(newRanking.rankAttributes.indexOf(row))}
                    >
                      <i className="ti-close" />
                    </IconButton>
                  )
                )}
              />
            ))
          }
          <div className="form-footer flex items-center">
            <MatButton
              variant="contained"
              color="primary"
              className="text-white"
              onClick={this.addNewAttribute}
              disabled={!isEmpty(newRanking.rankAttributes) && !isEmpty(rankingAttribute.list)
                && newRanking.rankAttributes.length === rankingAttribute.list.length}
            >
              <IntlMessages id="rankings.addAttribute" />
            </MatButton>
            {(errors.isCompleted || errors.hasDuplicates) && (
              <div className="form-errors-group ml-20">
                {errors.isCompleted && <InputErrors errors={errors.isCompleted} />}
                {errors.hasDuplicates && <InputErrors errors={errors.hasDuplicates} />}
              </div>
            )}
          </div>
        </div>
        <ScopeDialog
          dialog={{
            closeButtonTitle: <IntlMessages id="rankings.cancelScopeDialogButtonLabel" />,
            submitButtonTitle: <IntlMessages id="rankings.selectScopeDialogButtonLabel" />,
          }}
          search={{
            noResult: scopeCategory.searched
              && scopeCategory.searchResults && scopeCategory.searchResults.length === 0,
            placeholder: 'Search virtual categories ...',
            list: scopeCategory.searchResults,
            errorMessage: 'text.errors.lessThan500Characters',
            queryLengthLimit: 500,
          }}
          showScopeDialog={showScopeDialog}
          collapsed={scopeCategory.collapse}
          loadedKeys={scopeCategory.loadedKeys}
          selectedTreeItems={selectedTreeItems}
          treeItems={scopeCategory.treeItems}
          handleToggleCollapse={this.handleToggleCollapse}
          closeScopeDialog={this.closeScopeDialog}
          onSubmit={this.commitCheckedCategories}
          toogleTreeComponents={this.props.clearScopeCategorySearchState}
          fetchChildCategories={id => this.props.fetchChildScopeCategories(id)}
          onSearchSubmit={this.handleSearchScopeCategory}
          onToggleCategoryTreeCollapse={this.props.onToggleScopeCategoryTreeCollapse}
          handleLoadSubcategories={this.props.handleLoadScopeSubcategories}
          multiple
        />
      </div>
    );
  }
}

New.contextType = AbilityContext;

export default connect(mapStateToProps, actionCreators)(New);
