/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  func, shape, bool, arrayOf,
} from 'prop-types';
import { Divider, CircularProgress } from '@material-ui/core';

import IntlMessages from '../../../../util/IntlMessages';

import Header from './Header';
import Controls from './Controls';
import Designation from './Designation';
import Filter from './Filter';
import Sort from './Sort';
import Facet from './Facet';
import Footer from './Footer';

import {
  fetchVirtualCategory,
  setCategoryFields,
} from '../../../../actions/previewCategory';

const EditCategoryContent = memo((props) => {
  const {
    drawerState, selectedCategory, virtualCategory, onClose,
    virtualCategoryFetched, facetFilter,
  } = props;

  useEffect(() => {
    if (drawerState.isOpen && selectedCategory.id) {
      props.fetchVirtualCategory(selectedCategory.id);
    }
  }, [drawerState.isOpen]);

  const handleChangeController = (e) => {
    const { checked, name } = e.target;

    props.setCategoryFields({
      ...virtualCategory,
      [name]: checked,
    });
  };

  const handleNameChange = (e, lngKey) => {
    const { value, name } = e.target;

    props.setCategoryFields({
      ...virtualCategory,
      [name]: {
        ...virtualCategory[name],
        [lngKey]: value,
      },
    });
  };

  return (
    virtualCategoryFetched ? (
      <div className="drawer-inner relative">
        <div className="drawer-scroll-container scroll-light">
          <Header
            id={virtualCategory.id}
            name={virtualCategory.name.en}
            onClose={onClose}
          />
          <Divider />
          <Controls
            handleChangeController={handleChangeController}
            fields={{
              useInProductSearch: virtualCategory.useInProductSearch,
              useInDepartmentsTree: virtualCategory.useInDepartmentsTree,
              isShowSubcategoriesOnTop: virtualCategory.isShowSubcategoriesOnTop,
              isEnabled: virtualCategory.isEnabled,
              includeInMenu: virtualCategory.includeInMenu,
            }}
          />
          <Divider className="dashed-pl-pr" />
          <Designation
            name={virtualCategory.name}
            handleNameChange={handleNameChange}
            errors={{}} // TODO
          />
          <Divider className="dashed-pl-pr" />
          <Filter />
          <Divider className="dashed-pl-pr" />
          <Sort />
          <Divider className="dashed-pl-pr" />
          <Facet />
        </div>
        <Footer onClose={onClose} facetFilter={facetFilter} />
      </div>
    ) : (
      <div className="preview-drawer-loader-wrapper">
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className="progress-warning preview-drawer-loader custom-loader bottom"
          size={25}
          thickness={4}
        />
        <IntlMessages id="previewCategory.text.loading" />
      </div>
    )
  );
});

EditCategoryContent.propTypes = {
  fetchVirtualCategory: func.isRequired,
  onClose: func.isRequired,
  drawerState: shape().isRequired,
  selectedCategory: shape().isRequired,
  facetFilter: arrayOf(shape()).isRequired,
  virtualCategoryFetched: bool.isRequired,
  setCategoryFields: func.isRequired,

  virtualCategory: shape().isRequired,
};

EditCategoryContent.defaultProps = {};

const mapStateToProps = state => ({
  rankingsFetched: state.ranking.fetched,
  selectedCategory: state.virtualCategoryWidget.selectedCategory,
  drawerState: state.quickEditDrawer.drawerState,
  virtualCategory: state.previewCategory.item,
  virtualCategoryFetched: state.previewCategory.fetchedOne,
});

const mapDispatchToProps = {
  fetchVirtualCategory,
  setCategoryFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCategoryContent);
