/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from 'react';
import { withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';

// import { CircularProgress } from '@material-ui/core';

import Layout from '../../components/Layout';
import Sidebar from './components/Sidebar';
import List from '../../components/List';
import Edit from './containers/Edit';
import Create from './containers/Create';
import { AbilityContext } from '../../components/AbilityContext';
import ContextMenu from '../../components/CategoriesCheckboxTree/components/ContextMenu';

import ContextMenuContent from './components/ContextMenuContent';

import {
  getEditSearchFilterPath,
  newSearchFilterPath,
  editSearchFilterPath,
} from './utils/paths';
import appPermissions from '../../util/appPermissions';

import usePromotion from './hooks/usePromotion';
import useSearchEffects from './hooks/useSearch';
import useAttributesMatrixEffects from './hooks/useAttributesMatrix';
import useStateStore from './hooks/useState';
import useDefaultAttributeEffects from './hooks/useDefaultAttribute';

import { propTypes, defaultProps } from './propTypes';
import { mapStateToProps, actionCreators } from './connect';
import { headerList } from '../utils/headerList';
import {
  paginationLimit,
  getSearchSearchFiltersQuery,
  getSearchPromotionsQuery,
} from '../utils/queries';

let timeoutDelay = null;

const Search = (props) => {
  const abilityContext = useContext(AbilityContext);
  const { promotion, productAttribute } = props;
  const state = useStateStore();

  const [currentNavigationPage, setCurrentNavigationPage] = useState(1);
  const [currentPromotionPage, setCurrentPromotionPage] = useState(1);

  usePromotion(state, props);
  useSearchEffects(state, props);
  useAttributesMatrixEffects(state, props);
  useDefaultAttributeEffects(state, props);

  const navigateToSearchFilter = () => {
    props.history.push(newSearchFilterPath);
    props.clearFromProductCategoryState({
      categoriesAttributes: [],
      categoriesAttributesFetched: false,
    });
    props.clearNavigationVirtualFacet();
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    state.setValue(value);

    if (value.length > 500) {
      state.setSearchLimitError('text.errors.lessThan500Characters');
    } else if (value) {
      state.setSearchLimitError('');
      clearTimeout(timeoutDelay);
      timeoutDelay = setTimeout(() => {
        props.searchNavigationFilters(getSearchSearchFiltersQuery(value));
        props.searchPromotions(getSearchPromotionsQuery(value));
      }, 500);
    }
  };

  const handleRightClick = (event, itemId) => {
    event.preventDefault();
    const targetParams = event.target.getBoundingClientRect();
    state.setX(targetParams.left);
    state.setY(targetParams.top);
    state.setUseContext(true);
    state.setTargetHeight(targetParams.height);
    state.setTargetTreeNode(itemId);
  };

  const handleDuplicateNavigation = () => {
    const { targetTreeNode } = state;

    state.setUseContext(false);
    props.history.push(`${newSearchFilterPath}?duplicate_navigation_id=${targetTreeNode}`);
  };

  const handleGetPrevPage = (event, titleId) => {
    const { value } = event.target;
    state.setValue(value);

    if (titleId === 'text.searchFilters') {
      setCurrentNavigationPage(currentNavigationPage - 1);
      const {filter, pagination} = getSearchSearchFiltersQuery(value);

      const currentPagination = {
        page: currentNavigationPage - 1,
        limit: pagination.limit,
      };
      props.fetchNavigationList(filter, currentPagination);
    }

    if (titleId === 'text.promotionFilters') {
      setCurrentPromotionPage(currentPromotionPage - 1);
      const {filter, pagination} = getSearchPromotionsQuery(value);

      const currentPagination = {
        page: currentPromotionPage - 1,
        limit: pagination.limit,
      };
      props.fetchPromotionList(filter, currentPagination);
    }
  };

  const handleGetNextPage = (event, titleId) => {
    const { value } = event.target;
    state.setValue(value);

    if (titleId === 'text.searchFilters') {
      setCurrentNavigationPage(currentNavigationPage + 1);
      const {filter, pagination} = getSearchSearchFiltersQuery(value);

      const currentPagination = {
        page: currentNavigationPage + 1,
        limit: pagination.limit,
      };
      props.fetchNavigationList(filter, currentPagination);
    }
    
    if (titleId === 'text.promotionFilters') {
      setCurrentPromotionPage(currentPromotionPage + 1);
      const {filter, pagination} = getSearchPromotionsQuery(value);

      const currentPagination = {
        page: currentPromotionPage + 1,
        limit: pagination.limit,
      };
      props.fetchPromotionList(filter, currentPagination);
    }
  };

  const onSelectListItem = (item) => {
    props.history.push(getEditSearchFilterPath(item.id));
  };

  const sidebarGroupList = [{
    titleId: 'text.searchFilters', list: state.value ? [...props.navigation.searchResults] : [...props.navigation.list],
  }, {
    titleId: 'text.promotionFilters', list: state.value ? [...promotion.searchResults] : [...promotion.list],
  }];

  const contextStyles = {
    position: 'fixed',
    top: `${state.y + state.targetHeight}px`,
    left: `${state.x + 5}px`,
  };

  // const loading = props.promotion.fetching
  //   || props.navigation.fetching;

  const pagination = {
    navigation: {
      allPages: props.navigation.allPages,
      currentPage: currentNavigationPage
    },
    promotion: {
      allPages: props.promotion.allPages,
      currentPage: currentPromotionPage
    },
    paginationLimit: paginationLimit
  };

  return (
    <Layout
      horizontalMenu
      header={{
        className: 'header-mer',
        subHeaderContent: (
          <List
            dir="horizontal"
            className="justify-center"
            dataSource={headerList({ abilityContext })}
          />
        ),
      }}
      sidebar={{
        className: 'sidebar-mer sidebar-navigation-mer sidebar-scroll-secondary',
        content: (
          <Sidebar
            someProp="prop"
            hideTitle
            search={{
              value: state.value,
              onChange: handleSearchChange,
            }}
            pagination={pagination}
            handleGetPrevPage={handleGetPrevPage}
            handleGetNextPage={handleGetNextPage}
            navigateToSearchFilter={navigateToSearchFilter}
            groupList={sidebarGroupList}
            onSelectListItem={onSelectListItem}
            handleRightClick={handleRightClick}
            isNavigationsDisabled={
              props.navigation.fetchingOne || productAttribute.categoriesAttributesFetching
            }
            errors={{
              search: state.searchLimitError,
            }}
          />
        ),
      }}
      main={{
        className: 'main-mer grid-wide full-height',
        content: (
          <div className="full-height">
            {/* {loading && <div className="loader-main"><CircularProgress size={30} /></div>} */}
            {abilityContext.can(
              appPermissions.navigation.permissions.view,
              appPermissions.navigation.name,
            ) && <Route exact path={editSearchFilterPath} component={Edit} />}
            {abilityContext.can(
              appPermissions.navigation.permissions.create,
              appPermissions.navigation.name,
            ) && <Route exact path={newSearchFilterPath} component={Create} />}
            {state.useContext && (
              <ContextMenu
                contextStyles={contextStyles}
                content={(
                  <ContextMenuContent
                    onDuplicateNavigationClick={handleDuplicateNavigation}
                  />
                )}
              />
            )}
          </div>
        ),
      }}
    />
  );
};

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, actionCreators)(Search));
