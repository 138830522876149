import React from 'react';
import { func, shape, bool } from 'prop-types';
import Button from '@material-ui/core/Button';
import { isEmpty } from 'lodash';

import Popover from '../../../../components/Popover';
import IntlMessages from '../../../../util/IntlMessages';

const ScopeButton = ({
  user,
  handleScopeSelectClick,
  disableScopeButton,
}) => (
  <div className="flex">
    <Button
      variant="contained"
      color="primary"
      className="btn-xs no-wrap"
      onClick={() => handleScopeSelectClick(user.id)}
      disabled={disableScopeButton}
    >
      <IntlMessages id="text.selectScope" />
    </Button>
    {user && user.scope && !isEmpty(user.scope) && (
      <Popover
        key={user.id}
        buttonClassName="btn-clear ml-10"
        buttonText={<i className="ti-eye" />}
        disabled={disableScopeButton}
        content={(
          <div className="popover-content">
            {user.scope.map(c => (
              c.pathByName
                ? (
                  <div key={`${user.id}_${c.id}`} className="popover-content-item">
                    {c.pathByName.en}
                  </div>
                )
                : null
            ))}
          </div>
        )}
      />
    )}
  </div>
);

ScopeButton.propTypes = {
  user: shape(),
  handleScopeSelectClick: func,
  disableScopeButton: bool,
};

ScopeButton.defaultProps = {
  user: {},
  handleScopeSelectClick: null,
  disableScopeButton: false,
};

export default ScopeButton;
