import React, { memo } from 'react';
import { camelize } from 'humps';
import {
  shape,
} from 'prop-types';
import { isEmpty } from 'lodash';
import {
  Tooltip, Button, TableRow, TableCell, IconButton,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines } from '@fortawesome/free-solid-svg-icons';

import { sortableHandle } from 'react-sortable-hoc';

import Checkbox from '../../../../components/Checkbox';
import ShowMore from '../ShowMore';
import IntlMessages from '../../../../util/IntlMessages';

const ParentLevelItem = memo((props) => {
  const { sortableItemProps } = props;

  const attrLgSet = new Set(['description', 'description.en', 'title', 'title.en', 'locked_attributes']);
  const { childrenIds } = sortableItemProps.productItem;

  const DragHandle = sortableHandle(() => <FontAwesomeIcon size="lg" icon={faGripLines} />);

  const handleExpandProduct = () => {
    if (
      !sortableItemProps.attributeSets
        .some(set => set.id === sortableItemProps.productItem.attributeSet)
    ) {
      sortableItemProps.fetchProductAttributeSets(
        { ids: [sortableItemProps.productItem.attributeSet] },
      );
    }

    sortableItemProps.setCollapsed(
      sortableItemProps.collapsed.includes(sortableItemProps.productItem.id)
        ? sortableItemProps.collapsed.filter(c => c !== sortableItemProps.productItem.id)
        : [...sortableItemProps.collapsed, sortableItemProps.productItem.id],
    );
  };

  const { virtualParams } = sortableItemProps.selectedEntity;
  const { productsOverride } = virtualParams || {};
  const isPinned = productsOverride && productsOverride
    .some(pO => pO.productId === sortableItemProps.productItem.id);
  const hasComments = sortableItemProps.productsCommentsCount
    && sortableItemProps.productsCommentsCount
      .some(pcc => pcc.id === sortableItemProps.productItem.id);

  const imgUrl = sortableItemProps.parentValue.imgData && sortableItemProps.parentValue.imgData.url
    ? sortableItemProps.parentValue.imgData.url
    : sortableItemProps.defaultImage;

  const productPositionWasChanged = sortableItemProps.productsWithChangedPositions
    .includes(sortableItemProps.productItem.id);
  const positionIconClassName = productPositionWasChanged ? 'red' : '';

  const handleProductSelect = () => sortableItemProps
    .onProductSelect(sortableItemProps.productItem);
  const fetchComments = () => sortableItemProps
    .fetchActivityLogsByProductId(sortableItemProps.productItem.id);

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell key="cell-contoller" className="type-cell" align="left">
        <div className="flex relative icons-list items-center">
          <div className="product-item-type parent">
            <span>parent</span>
          </div>
          {!sortableItemProps.disabled && (
            isEmpty(sortableItemProps.collapsed) ? (
              <div className="flex ml-auto dnd-icon items-center icon-premium icon-xs no-bg">
                <DragHandle />
              </div>
            ) : (
              <Tooltip
                title={<IntlMessages id="preview.dnd.disabled" />}
                placement="right"
              >
                <div className="flex ml-auto dnd-icon items-center icon-premium bw icon-xs no-bg">
                  <FontAwesomeIcon size="lg" icon={faGripLines} />
                </div>
              </Tooltip>
            )
          )}
          <div className="flex edit-icon-wrapper items-center">
            <span className={`icon-xs icon-premium position-info list ${sortableItemProps.isEditMode ? 'with-margin' : ''}`}>
              {(
                ((sortableItemProps.pagination.page - 1) * sortableItemProps.pagination.limit)
                + sortableItemProps.productIndex + 1
              )}
            </span>
            {!sortableItemProps.isHiddenProductsMode && !sortableItemProps.productsFetching && (
              <div className="edit-icon">
                <Button
                  color="primary"
                  className="btn-xs extra float-right"
                  onClick={event => sortableItemProps.onEditClick({
                    event, productItem: sortableItemProps.productItem,
                  })}
                >
                  <IntlMessages id="preview.table.product.button.view.label" />
                </Button>
              </div>
            )}
            {(isPinned || productPositionWasChanged) && <i className={`icon-pin icon-premium icon-xs ${positionIconClassName}`} />}
            {
              hasComments && (
                <Tooltip
                  onClick={fetchComments}
                  title={(
                    <div className="comments">
                      {
                        sortableItemProps.productCommentsLoading
                          ? <IntlMessages id="preview.list.productComments.loading" />
                          : sortableItemProps.productComments.map((pc, index) => (
                            <div key={pc.id}>
                              {`${index + 1}. ${pc.comment}`}
                            </div>
                          ))
                      }
                    </div>
                  )}
                  placement="right"
                >
                  <i className="ti-comment-alt icon-premium icon-xs" />
                </Tooltip>
              )
            }
          </div>
          {!sortableItemProps.disabled && (
            <Checkbox
              className="flex pr-5 pl-5"
              checked={(
                sortableItemProps.selectedProducts
                  .some(product => product.id === sortableItemProps.parentValue.id)
                )}
              onChange={handleProductSelect}
              name={sortableItemProps.parentValue.title
                ? camelize(sortableItemProps.parentValue.title)
                : ''
              }
            />
          )}
          {!isEmpty(childrenIds) && !sortableItemProps.isHiddenProductsMode && (
            <div className="flex justify-center direction-column pr-5 pl-5">
              <IconButton className="icon-chevron" color="inherit" onClick={handleExpandProduct}>
                <i className={
                  `zmdi ${sortableItemProps.collapsed.includes(sortableItemProps.productItem.id) ? 'zmdi-chevron-down' : 'zmdi-chevron-right'}`}
                />
              </IconButton>
            </div>
          )}
        </div>
      </TableCell>
      <TableCell key="cell-image" align="left">
        <Tooltip
          className="img-tooltip"
          title={(
            <img
              className="product-item-image tooltip-inner"
              src={imgUrl}
              alt=""
            />
          )}
          placement="right"
        >
          <img
            className="product-item-image"
            src={imgUrl}
            alt=""
          />
        </Tooltip>
      </TableCell>

      {sortableItemProps.sortedAttributes.filter(attr => attr.enabled).map((attr) => {
        let itemValue = sortableItemProps.parentValue[camelize(attr.value)];
        let itemValueLength = itemValue ? itemValue.length : 0;
        if (attr.code === 'locked_attributes' && Array.isArray(itemValue)) {
          const stringValue = itemValue.join(', ');
          itemValueLength = stringValue.length;
          itemValue = stringValue;
        }
        const isShowMore = itemValueLength && itemValueLength > 90;
        const isCategoriesAttribute = attr.code === 'categories';

        const firstLetterClassName = attrLgSet.has(attr.code) ? 'first-letter-up' : '';
        const moreButtonClassName = attrLgSet.has(attr.code) ? 'three-dots-abs' : '';
        const categoriesClassName = isCategoriesAttribute ? 'categories-list' : '';
        const currentAttribute = sortableItemProps.certainProductAttributes
          .find(el => el.code === attr.value) || {};

        return (
          <TableCell key={`${sortableItemProps.parentValue.id}_${attr.code}`} align="left">
            <div
              className={`flex direction-column product-item-data card-item-inline relative ${categoriesClassName}`}
              key={attr.code}
            >
              <div className={`flex ${moreButtonClassName}`}>
                <span className="key hidden">{(currentAttribute.label ? currentAttribute.label.en : '') || (attr.label ? attr.label.en : '')}</span>
                {isShowMore
                  ? (
                    <ShowMore
                      attr={attr}
                      itemValue={itemValue || '-'}
                    />
                  )
                  : null}
              </div>
              <span className={`value relative ${firstLetterClassName}`}>
                {String(itemValue).slice(0, 90) || '-'}
              </span>
            </div>
          </TableCell>
        );
      })}
    </TableRow>
  );
});

ParentLevelItem.propTypes = {
  sortableItemProps: shape().isRequired,
};

ParentLevelItem.defaultProps = {};

export default ParentLevelItem;