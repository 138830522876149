import swal from 'sweetalert';
import { isEmpty } from 'lodash';

import movePositions from '../../../utils/movePositions';
import entityTypes from '../../../../../util/entityTypes';

const addProductsComment = (updateMultipleComments, products, comment) => {
  if (updateMultipleComments) {
    const updatedActivityLogs = [{
      affectedIds: products.map(p => p.id),
      entityType: entityTypes.catalogProductAttribute,
      comment,
    }];
    updateMultipleComments(updatedActivityLogs);
  }
};

export const handleRewritePosition = ({
  targetPosition,
  productsOverride,
  productWithSamePosition,
  productToMove,
}) => {
  if (productsOverride
    .some(p => p.productId === productToMove.id
      && p.position === targetPosition)) {
    return productsOverride;
  }

  const updatedOverrides = productsOverride
    .filter(pO => pO.productId !== productWithSamePosition.productId);
  const payload = [
    ...updatedOverrides,
    {
      position: targetPosition,
      productId: productToMove.id,
      visibilityStatus: productToMove.visibilityStatus,
    },
  ];
  return payload;
};

export const handleShiftPosition = ({
  targetPosition,
  productsOverride,
  productToMove,
}) => {
  const sortedOverrides = productsOverride.sort((a, b) => a.position - b.position);
  const productWasPinned = sortedOverrides.some(p => p.productId === productToMove.id);
  const shiftResult = sortedOverrides.map((pO, index) => {
    const prevProduct = sortedOverrides[index - 1];
    const condition = (pO.position === parseFloat(targetPosition))
      || (prevProduct && prevProduct.position
          && prevProduct.position === pO.position - 1
          && pO.position >= targetPosition);

    if (condition) {
      return {
        ...pO,
        position: pO.position + 1,
      };
    }
    return pO;
  });

  const updateShiftResult = shiftResult.map((sR) => {
    if (productWasPinned && sR.productId === productToMove.id) {
      return {
        position: targetPosition,
        productId: productToMove.id,
        visibilityStatus: productToMove.visibilityStatus,
      };
    }
    return sR;
  }).sort((a, b) => a.position - b.position);

  if (!productWasPinned) {
    return [
      ...shiftResult,
      {
        position: targetPosition,
        productId: productToMove.id,
        visibilityStatus: productToMove.visibilityStatus,
      },
    ].sort((a, b) => a.position - b.position);
  }
  return updateShiftResult.sort((a, b) => a.position - b.position);
};

export const moveProductToTop = ({
  productToMove,
  productsOverride,
  setProductOverride,
  prepareAndUpdateVirtualParams,
}) => {
  const targetPosition = 1;
  const sortedOverrides = productsOverride
    .filter(o => o.productId !== productToMove.id)
    .sort((a, b) => a.position - b.position);
  const productWithSamePosition = sortedOverrides.find(p => p.position === 1);

  if (productWithSamePosition) {
    swal({
      title: 'There is a product with same position',
      text: 'Are you sure you want to replace a product with position 1?',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        shift: {
          text: 'Shift',
          closeModal: true,
          value: 'shift',
        },
        replace: {
          text: 'Replace',
          closeModal: true,
          value: 'replace',
        },
        cancel: 'Cancel',
      },
    })
      .then((value) => {
        let payload = [...sortedOverrides];

        if (!value) return;
        if (value === 'replace') {
          payload = [...handleRewritePosition({
            targetPosition,
            productsOverride: sortedOverrides,
            productWithSamePosition,
            productToMove,
          })];
        }
        if (value === 'shift') {
          payload = [...handleShiftPosition({
            targetPosition,
            productsOverride: sortedOverrides,
            productToMove,
          })];
        }

        setProductOverride(payload);
        prepareAndUpdateVirtualParams(payload);
      });
  } else {
    const payload = [
      ...sortedOverrides,
      {
        position: targetPosition,
        productId: productToMove.id,
        visibilityStatus: productToMove.visibilityStatus,
      },
    ];

    setProductOverride(payload);
    prepareAndUpdateVirtualParams(payload);
  }
};

export const moveProductToBottom = ({
  productToMove,
  productsOverride,
  setProductOverride,
  prepareAndUpdateVirtualParams,
}) => {
  const sortedOverrides = productsOverride
    .filter(o => o.productId !== productToMove.id)
    .sort((a, b) => a.position - b.position);

  const payload = [
    ...sortedOverrides,
    {
      position: 20000,
      productId: productToMove.id,
      visibilityStatus: productToMove.visibilityStatus,
    },
  ];

  setProductOverride(payload);
  prepareAndUpdateVirtualParams(payload);
};

export const moveProductToPosition = ({
  productPinOrder,
  productsOverride,
  selectedProduct,
  setProductOverride,
  prepareAndUpdateVirtualParams,
}) => {
  const productWithSamePosition = productsOverride
    .find(pO => pO.position === productPinOrder);
  const sortedOverrides = productsOverride
    .filter(o => o.productId !== selectedProduct.id)
    .sort((a, b) => a.position - b.position);

  if (productWithSamePosition) {
    swal({
      title: 'There is a product with same position',
      text: `Are you sure you want to replace a product with position ${productPinOrder}?`,
      icon: 'warning',
      dangerMode: true,
      buttons: {
        shift: {
          text: 'Shift',
          closeModal: true,
          value: 'shift',
        },
        replace: {
          text: 'Replace',
          closeModal: true,
          value: 'replace',
        },
        cancel: 'Cancel',
      },
    })
      .then((answerValue) => {
        let payload = [...sortedOverrides];

        if (!productPinOrder) return;
        if (answerValue === 'replace') {
          payload = [...handleRewritePosition({
            targetPosition: productPinOrder,
            productsOverride: sortedOverrides,
            productWithSamePosition,
            productToMove: selectedProduct,
          })];
        }
        if (answerValue === 'shift') {
          payload = [...handleShiftPosition({
            targetPosition: productPinOrder,
            productsOverride: sortedOverrides,
            productToMove: selectedProduct,
          })];
        }

        setProductOverride(payload);
        prepareAndUpdateVirtualParams(payload);
      });
  } else {
    const payload = [
      ...sortedOverrides,
      {
        position: productPinOrder,
        productId: selectedProduct.id,
        visibilityStatus: selectedProduct.visibilityStatus,
      },
    ];

    setProductOverride(payload);
    prepareAndUpdateVirtualParams(payload);
  }
};

export const handleActionPopperOk = ({
  selectedPinAction,
  productPinOrder,
  selectedProduct,
  productsOverride,
  setProductPinOrderHeplerTextId,
  setSelectedPinAction,
  setActionPopoverState,
  setActionPopoverAnchor,
  setProductOverride,
  prepareAndUpdateVirtualParams,
}) => {
  const isOrderValid = productPinOrder.toString().length <= 10
    && productPinOrder.toString().length > 0;

  if (selectedPinAction) {
    switch (selectedPinAction) {
      case movePositions.top:
        moveProductToTop({
          productToMove: selectedProduct,
          productsOverride,
          setProductOverride,
          prepareAndUpdateVirtualParams,
        });
        break;

      case movePositions.bottom:
        moveProductToBottom({
          productToMove: selectedProduct,
          productsOverride,
          setProductOverride,
          prepareAndUpdateVirtualParams,
        });
        break;

      case movePositions.position:
        if (isOrderValid) {
          moveProductToPosition({
            productPinOrder,
            productsOverride,
            selectedProduct,
            setProductOverride,
            prepareAndUpdateVirtualParams,
          });
          setProductPinOrderHeplerTextId('');
        } else {
          setProductPinOrderHeplerTextId('text.errors.numberLengthLimit');
        }
        break;

      default:
        break;
    }
  }

  if (isOrderValid || selectedPinAction !== movePositions.position) {
    setSelectedPinAction('');
    setActionPopoverState(false);
    setActionPopoverAnchor(null);
  }
};

export const moveProductsToPosition = ({
  productsToMove,
  order,
  productsOverride,
  setProductOverride,
  prepareAndUpdateVirtualParams,
  updateMultipleComments,
  comment,
}) => {
  const positionsToCheck = Array.from(Array(productsToMove.length).keys()).map(p => p + order);
  const sortedOverrides = productsOverride
    .filter(o => productsToMove
      .every(p => p.id !== o.productId))
    .sort((a, b) => a.position - b.position);
  const productsWithSamePositions = sortedOverrides
    .filter(p => positionsToCheck
      .some(pos => pos === p.position));
  const positionsExisted = Array.from(Array(productsWithSamePositions.length).keys())
    .map(p => p + order);

  if (!isEmpty(productsWithSamePositions)) {
    swal({
      title: 'There are products with same positions',
      text: `Are you sure you want to replace products with  these positions: ${positionsExisted.join(', ')}?`,
      icon: 'warning',
      dangerMode: true,
      buttons: {
        shift: {
          text: 'Shift',
          closeModal: true,
          value: 'shift',
        },
        replace: {
          text: 'Replace',
          closeModal: true,
          value: 'replace',
        },
        cancel: 'Cancel',
      },
    })
      .then((value) => {
        let payload = [...sortedOverrides];

        if (!value) return;
        if (value === 'replace') {
          productsToMove.forEach((p) => {
            const targetPosition = productsToMove.indexOf(p) + order;
            payload = [
              ...payload.filter(pl => pl.position !== targetPosition),
              {
                position: targetPosition,
                productId: p.id,
                visibilityStatus: p.visibilityStatus,
              },
            ];
          });
        }
        if (value === 'shift') {
          productsToMove.forEach((p) => {
            const targetPosition = productsToMove.indexOf(p) + order;
            payload = [...handleShiftPosition({
              targetPosition,
              productsOverride: payload,
              productToMove: p,
            })];
          });
        }

        setProductOverride(payload);
        prepareAndUpdateVirtualParams(payload);
        addProductsComment(updateMultipleComments, productsToMove, comment);
      });
  } else {
    const productsToAdd = [...productsToMove.map(p => ({
      position: productsToMove.indexOf(p) + order,
      productId: p.id,
      visibilityStatus: p.visibilityStatus,
    }))];
    const payload = [
      ...sortedOverrides,
      ...productsToAdd,
    ];

    setProductOverride(payload);
    prepareAndUpdateVirtualParams(payload);
    addProductsComment(updateMultipleComments, productsToMove, comment);
  }
};

export const moveProductsToBottom = ({
  productsToMove,
  productsOverride,
  setProductOverride,
  prepareAndUpdateVirtualParams,
  updateMultipleComments,
  comment,
}) => {
  const sortedOverrides = productsOverride
    .filter(o => productsToMove
      .every(p => p.id !== o.productId))
    .sort((a, b) => a.position - b.position);
  const payload = [
    ...sortedOverrides,
    ...productsToMove.map(p => ({
      position: 20000,
      productId: p.id,
      visibilityStatus: p.visibilityStatus,
    })),
  ];

  setProductOverride(payload);
  prepareAndUpdateVirtualParams(payload);
  addProductsComment(updateMultipleComments, productsToMove, comment);
};

export default {
  handleActionPopperOk,
  handleRewritePosition,
  handleShiftPosition,
  moveProductToTop,
  moveProductToBottom,
  moveProductToPosition,
  moveProductsToPosition,
  moveProductsToBottom,
};
