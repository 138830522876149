/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

import {
  defaultProductAttribute,
} from '../../../../utils/defaultData';

export const useProductAttribute = ({
  attributesOptions,
  categoriesAttributesFetched,
  fetchedAttributeOptions,
  fetchedAttributesOptions,
  categoriesAttributes,
  setProductCategoriesAttributes,
  productCategoriesAttributes,
}) => {
  const getAttributeOptions = (attr) => {
    const attribute = attributesOptions.find(ao => ao.code === attr);
    return attribute ? attribute.options : [];
  };

  const mapAttributesOptions = attributes => attributes.map((a) => {
    if ((a.frontendInputType === 'select'
    || a.frontendInputType === 'dropdown'
    || a.frontendInputType.startsWith('dropdown_')) && isEmpty(a.options)) {
      return {
        ...a,
        options: getAttributeOptions(a.code),
      };
    }
    return a;
  });

  useEffect(() => {
    if (categoriesAttributesFetched) {
      const updatedAttributes = [
        ...categoriesAttributes, defaultProductAttribute,
      ];
      const updatedList = mapAttributesOptions(updatedAttributes);
      setProductCategoriesAttributes(updatedList);
    }
  }, [categoriesAttributesFetched]);

  useEffect(() => {
    if (fetchedAttributeOptions || fetchedAttributesOptions) {
      const hasProductId = productCategoriesAttributes.some(pC => pC.code === 'id');
      const updatedList = mapAttributesOptions([
        ...productCategoriesAttributes, !hasProductId
          ? defaultProductAttribute
          : [],
      ].flat());
      setProductCategoriesAttributes(updatedList);
    }
  }, [
    fetchedAttributeOptions,
    fetchedAttributesOptions,
  ]);
};

export default useProductAttribute;
