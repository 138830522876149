/* eslint-disable no-param-reassign */
import axios from 'axios';
import { decamelizeKeys, camelizeKeys } from 'humps';
import { isEmpty } from 'lodash';
import RestActions from '../util/rest/actions';
import {
  navigationsSearchPath,
  getNavigationsPath,
  navigationsPath,
} from '../util/paths';

import { showErrorNotification } from '../util/api';

import {
  mapToAttributesMatrix,
  mapFilters,
  unmapFromAttributesMatrix,
} from '../util/attributesMatrixMapping';

import {
  SEARCH_PROMOTIONS_START,
  SEARCH_PROMOTIONS_FINISHED,
  SET_SELECTED_PROMOTION,
} from './types';
import { mapPositions } from '../util/mapVirtualParamsPositions';

import { CDMSClient } from '../util/api';

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';

const restPromotionActions = new RestActions('promotion');

export const fetchPromotionList = (filter = {}, pagination = {}) => async (dispatch) => {
  dispatch(restPromotionActions.fetchAllStart());

  const fields = ['id', 'name'];

  const sort = [{
    field: 'created_at',
    order: 'desc',
  }];
  
  try {
    const res = await cdmsFetchPromotionList(decamelizeKeys({
      fields, filter, pagination, sort,
    }));

    const promotions = camelizeKeys(res.data.data);
    const allPages = res.data.pages;

    const result = promotions.map((c) => {
      const originalPromotion = res.data.data.find(p => p.id === c.id);
      const payload = { ...c };

      if (originalPromotion) {
        payload.virtualParams = mapPositions(
          c.virtualParams,
          [],
        );
      }
      return payload;
    });

    dispatch(restPromotionActions.fetchAllFinished({ list: result, allPages: allPages }));
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch(restPromotionActions.fetchAllFinished({ error: error.response.data, hasError: true }));
  }
};

export const cdmsFetchPromotionList = (payload) => CDMSClient.post(navigationsSearchPath + '?ref=' + ref, payload);

export const updatePromotionFilter = (navId, body, params = {}) => (dispatch, getState) => {
  const { mapToMatrix } = params;
  const { list: attributesMatrix } = getState().attributesMatrix;

  delete body.virtualParams.facets;

  const updatedBody = decamelizeKeys({
    data: {
      ...body,
      virtualParams: {
        ...body.virtualParams,
        filters: mapToMatrix
          ? mapFilters(body.virtualParams.filters, attributesMatrix)
          : body.virtualParams.filters,
        sortRules: body.virtualParams.sortRules.map(s => ({
          field: mapToMatrix ? mapToAttributesMatrix(s.field, attributesMatrix) : s.field,
          direction: s.direction.toLowerCase(),
        })),
      },
    },
  });

  if (!isEmpty(body.virtualParams.productsOverride)) {
    updatedBody.data.virtual_params.positions = body.virtualParams.productsOverride
      ? body.virtualParams.productsOverride.reduce((result, item) => {
        const payload = { ...result };

        payload[item.productId] = item.position;
        return payload;
      }, {})
      : body.virtualParams.positions;
  }

  if (!isEmpty(body.virtualParams.positions)
    && isEmpty(body.virtualParams.productsOverride)) {
    updatedBody.data.virtual_params.positions = body.virtualParams.positions;
  }

  if (body.virtualParams.productsOverride && isEmpty(body.virtualParams.productsOverride)) {
    delete updatedBody.data.virtual_params.positions;
  }

  let productsOverride = [];
  if (updatedBody.data.virtual_params.products_override) {
    productsOverride = updatedBody.data.virtual_params.products_override;
    delete updatedBody.data.virtual_params.products_override;
  }

  dispatch(restPromotionActions.updateStart());
  axios.put(`${getNavigationsPath(navId)}?is_synchronous=true`, updatedBody).then((res) => {
    const updatedPromotion = camelizeKeys(res.data.data);
    const payload = { ...updatedPromotion };
    payload.virtualParams = mapPositions(
      updatedPromotion.virtualParams,
      res.data.data.virtual_params,
    );

    dispatch(restPromotionActions.updateFinished({
      item: unmapFromAttributesMatrix(payload, attributesMatrix),
    }));

    payload.virtualParams.productsOverride = productsOverride;

    dispatch({
      type: SET_SELECTED_PROMOTION,
      payload: {
        selectedPromotion: payload,
      },
    });

  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restPromotionActions.updateFinished({ error: error.response.data, hasError: true }));
  });
};

export const createPromotionFilter = (body, params) => (dispatch, getState) => {
  const transformedPath = `${navigationsPath}?is_synchronous=${params.isSync}`;

  const { list: attributesMatrix } = getState().attributesMatrix;
  const updatedBody = {
    ...body,
    description: 'descriptionEn',
    isEnabled: true,
    searchPhrase: body.searchPhrase,
    virtualParams: {
      ...body.virtualParams,
      // facets,
      filters: mapFilters(body.virtualParams.filters, attributesMatrix),
      sortRules: body.virtualParams.sortRules.map(s => ({
        field: mapToAttributesMatrix(s.field, attributesMatrix),
        direction: s.direction.toLowerCase(),
      })),
    },
  };

  dispatch(restPromotionActions.createStart());
  axios.post(transformedPath, {
    data: decamelizeKeys(updatedBody),
  }).then((res) => {
    dispatch(restPromotionActions.createFinished({
      item: unmapFromAttributesMatrix(camelizeKeys(res.data.data), attributesMatrix),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restPromotionActions.createFinished({ error: error.response.data, hasError: true }));
  });
};


export const clearFromPromotionState = payload => (dispatch) => {
  dispatch(restPromotionActions.clearFromStateFinished(payload));
};

export const searchPromotions = query => (dispatch) => {
  dispatch({ type: SEARCH_PROMOTIONS_START });
  return axios.post(navigationsSearchPath + '?ref=' + ref, query).then((res) => {
    dispatch({
      type: SEARCH_PROMOTIONS_FINISHED,
      payload: {
        list: camelizeKeys(res.data.data),
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: SEARCH_PROMOTIONS_FINISHED,
      payload: {
        error: error.response.data, hasError: true,
      },
    });
  });
};


export default {
  fetchPromotionList,
  updatePromotionFilter,
  createPromotionFilter,
  searchPromotions,
};
