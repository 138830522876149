import React, { memo } from 'react';
import { connect } from 'react-redux';
import {
  func, shape, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';

import { toBase64 } from '../../utils/base64Parser';
import { getAvatarFromStorage } from '../../../../actions/influencer';
import Avatar from '../../components/Avatar';
import InputsRow from './InputsRow';
import TrackingInfo from './TrackingInfo';
import IntlMessages from '../../../../util/IntlMessages';

const TopContolSection = memo((props) => {
  const {
    storage,
    influencer,
    handleInfluencerChange,
    handleInfluencerPageChange,
    handleAvatarChange,
    avatarData,
    trackingInfo,
    textFieldsErrors,
    disabled,
  } = props;

  const onAvatarChange = async (e) => {
    const { files } = e.target;
    const file = files[0];
    const base64String = await toBase64(file);

    handleAvatarChange({
      url: base64String,
      name: file.name,
    });
  };

  return (
    <>
      {
        !isEmpty(trackingInfo.trackingUrl) && (
          <div className="flex tracking-info-container">
            <IntlMessages id="influencer.trackingInfo.title" />
            <TrackingInfo
              trackingInfo={trackingInfo}
            />
          </div>
        )
      }
      <div className="flex justify-center">
        <h3>{`Hello ${influencer.name}`}</h3>
      </div>
      <Avatar
        influencer={influencer}
        storage={storage}
        onChange={onAvatarChange}
        getAvatarFromStorage={props.getAvatarFromStorage}
        avatarData={avatarData}
        disabled={disabled}
      />
      <div className="inputs-row flex justify-center pt-20">
        <InputsRow
          influencer={influencer}
          handleInfluencerChange={handleInfluencerChange}
          handleInfluencerPageChange={handleInfluencerPageChange}
          textFieldsErrors={textFieldsErrors}
          disabled={disabled}
        />
      </div>
    </>
  );
});

TopContolSection.propTypes = {
  influencer: shape().isRequired,
  storage: shape().isRequired,
  avatarData: shape().isRequired,
  handleInfluencerChange: func.isRequired,
  handleInfluencerPageChange: func.isRequired,
  handleAvatarChange: func.isRequired,
  getAvatarFromStorage: func.isRequired,
  trackingInfo: shape().isRequired,
  textFieldsErrors: shape().isRequired,
  disabled: bool.isRequired,
};

const mapStateToProps = state => ({
  influencer: state.influencer.item,
  storage: state.influencer.storage,
  avatarData: state.influencer.avatarData,
  trackingInfo: state.influencer.item.trackingInfo,
  textFieldsErrors: state.influencer.textFieldsErrors,
});

const actionCreators = {
  getAvatarFromStorage,
};

export default connect(mapStateToProps, actionCreators)(TopContolSection);
