/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, memo } from 'react';
import { node, func } from 'prop-types';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

const ProductCardComponent = memo((props) => {
  const [scrollTop, setScrollTop] = useState(0);
  const { content } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
    props.onClose(scrollTop);
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const transferListScrollTop = document.getElementById('transferList').scrollTop;
    setScrollTop(transferListScrollTop);
    setAnchorEl(e.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        aria-label="show-more-product-details"
        onMouseOver={handleClick}
        className="show-more-button"
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Popover
        className="show-more-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DialogContent className="product-popover-content">
          <IconButton
            className="btn-abs btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          {content}
        </DialogContent>
      </Popover>
    </>
  );
});

ProductCardComponent.propTypes = {
  content: node,
  onClose: func,
};

ProductCardComponent.defaultProps = {
  content: null,
  onClose: null,
};

export default ProductCardComponent;
