export const defaultProductAttribute = {
  code: 'id',
  frontendInputType: 'selectProductsButton',
  id: 'attr_iHeYyv1MRdm1mG',
  isFilterable: true,
  isRequired: false,
  isSearchable: false,
  isSortable: false,
  isVariation: false,
  label: 'Products',
  value: 'id',
};

export const getProductCompoundSearchFilter = query => ({
  value: query,
});

export const defaultFields = ['id', 'price', 'sku', 'title', 'description', 'images', 'specifications', 'scores'];

export const defaultPagination = {
  page: 1,
  limit: 100,
};

export const getParentAttributesData = (parentVirtualParams, initialOptions) => {
  const parentOptions = [];
  const parentFilters = [];
  
  const parentVirtualFilters = parentVirtualParams ? parentVirtualParams.filters : null;
  if (!parentVirtualFilters) {
    return {};
  }

  const parentVirtualFiltersKeys = Object.keys(parentVirtualFilters);

  for (let i = 0; i < parentVirtualFiltersKeys.length; i++) {
    const parentField = parentVirtualFilters[parentVirtualFiltersKeys[i]].field
      ? parentVirtualFilters[parentVirtualFiltersKeys[i]].field.toString().replace('variation.facets.', '')
      : null;

    if (parentField === null) {
      continue;
    }

    const parentValues = parentVirtualFilters[parentVirtualFiltersKeys[i]].value;
    const parentFilter = {
      condition: 'and',
      group: [
        {
          field: parentField,
          value: parentValues,
          operator: parentVirtualFilters[parentVirtualFiltersKeys[i]].operator,
        }
      ],
    };

    for (let j = 0; j < parentValues.length; j++) {
      const parentOption = {
        value: parentValues[j],
        disabled: false,
      };

      for (let k = 0; k < initialOptions.length; k++) {
        if (initialOptions[k].attributeCode !== parentField || initialOptions[k].optionCode !== parentValues[j]) {
          continue;
        }

        parentOption.label = initialOptions[k].label ? initialOptions[k].label.en : null;
      }
      if (parentOption.label) {
        parentOptions.push(parentOption);
      }
    }
    parentFilters.push(parentFilter);
  }
  
  return { parentFilters, parentOptions };
};

export const isFilterInParentFilter = (parentFilters, attributeCodeValue) => {
  for (let i = 0; i < parentFilters.length; i++) {
    const group = parentFilters[i].group;
    for (let j = 0; j < group.length; j++) {
      if (group[j].field === attributeCodeValue.value) {
        return true;
      }
    }
  }

  return false;
};
