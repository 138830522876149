import { camelize } from 'humps';
import { isEmpty } from 'lodash';
import config from "../../../../../util/config";

const getValue = (p, o) => p.reduce((xs, x) => (((xs && xs[x]) || (xs && Number.isInteger(xs[x])))
  ? xs[x]
  : '-'), o);

export const generateParentProductData = (
  item,
  defaultImage,
  attributesOptions,
  attributes,
) => {
  if (!item) return {};

  const publishPrice = item.price && item.price !== Infinity
    ? `$${item.price}`
    : '-';
  const imgData = item.images ? item.images[0] : defaultImage;
  const rootValues = !isEmpty(attributes)
    ? attributes
      .filter(attr => attr.enabled && getValue(camelize(attr.code)
        .split('.'), item) !== undefined)
    : [];
  const value = {
    id: item.id,
    imgData,
    publishPrice,
  };

  rootValues.forEach((v) => {
    const itemValue = getValue(camelize(v.code).split('.'), item);
    const specificationValue = attributesOptions.find(o => o.optionCode === itemValue);

    value[camelize(v.value)] = specificationValue
      ? specificationValue.label.en
      : itemValue;
  });

  return value;
};

export const generateVariantProductData = (item, defaultImage, certainProductAttributes) => {
  const { finalPrice } = item.price || {};

  let zapMinPrice = 'N/A';
  let zapMaxPrice = 'N/A';
  let zapUrl = 'N/A';

  if (item.priceEnrichment && item.priceEnrichment.competitors && item.priceEnrichment.competitors.zap) {
    if (item.priceEnrichment.competitors.zap.price) {
      zapMinPrice = item.priceEnrichment.competitors.zap.price;
    }
    if (zapMinPrice > 0) {
      if (item.priceEnrichment.competitors.zap.currency === 'ILS') {
        zapMinPrice = '₪' + zapMinPrice;
      } else {
        zapMinPrice = '$' + zapMinPrice;
      }
    }

    if (item.priceEnrichment.competitors.zap.maxPrice) {
      zapMaxPrice = item.priceEnrichment.competitors.zap.maxPrice;
    }
    if (zapMaxPrice > 0) {
      if (item.priceEnrichment.competitors.zap.currency === 'ILS') {
        zapMaxPrice = '₪' + zapMaxPrice;
      } else {
        zapMaxPrice = '$' + zapMaxPrice;
      }
    }
  }
  if (item.zmid) {
    zapUrl = config.zapWebsitePath.replace('%id%', item.zmid[0]);
  }

  const imgData = item.images
    ? item.images[0]
    : defaultImage;

  const value = {
    productId: item.id,
    status: item.status,
    imgData,
    items: item.stock ? item.stock.qty : 0,
    price: `$${finalPrice || 0}`,
    zapMinPrice: zapMinPrice,
    zapMaxPrice: zapMaxPrice,
    zapUrl: zapUrl,
    lockedAttributes: item.lockedAttributes,
    pubAopi: item.pubAopi || 'N/A',
  };

  if (!isEmpty(item.upc)) {
    value.upc = item.upc.join(', ');
  } else {
    value.upc = '-';
  }

  if (!isEmpty(certainProductAttributes) && !isEmpty(item.variationAttributes)) {
    Object.keys(item.variationAttributes).forEach((attrKey) => {
      const currentAttribute = certainProductAttributes
        .find(attr => camelize(attr.code) === attrKey);

      if (Object.keys(item.variationAttributes).includes(`${attrKey}Orig`)) {
        item.variationAttributes[attrKey] = item.variationAttributes[`${attrKey}Orig`];
      }

      const val = item.variationAttributes[attrKey];

      if (currentAttribute) value[camelize(currentAttribute.label.en)] = val.en ? val.en : val;
    });
  }

  return value;
};
