import { createSelector } from 'reselect';

const searchRootSelector = state => state;

const stateSelector = createSelector(
  searchRootSelector,
  search => ({
    updating: search.updating,
    updated: search.updated,
  }),
);

export const searchSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  searchSelector,
};
