export default {
  fetchOneStart: {
    fetchingOne: true,
    fetchedOne: false,
  },
  fetchOneFinished: {
    fetchingOne: false,
    fetchedOne: true,
  },
  fetchAllStart: {
    fetching: true,
    fetched: false,
  },
  clearAllFinished: {
    clearingAll: false,
    clearedAll: true,
  },
  clearOneFinished: {
    clearingOne: false,
    clearedOne: false,
  },
  clearListFinished: {
    clearingList: false,
    clearedList: false,
  },
  clearStateFinished: {
    clearing: false,
    cleared: false,
  },
  setErrorFinished: {
    errorSetting: false,
    errorSetted: true,
  },
  clearErrorFinished: {
    errorClearing: false,
    errorCleared: true,
  },
  clearSearchListFinished: {
    clearingSearchList: false,
    clearedSearchList: false,
  },
  clearFromStateFinished: {
    clearingFromStateFinished: true,
  },
  fetchAllFinished: {
    fetching: false,
    fetched: true,
  },
  updateStart: {
    updating: true,
    updated: false,
  },
  updateFinished: {
    updating: false,
    updated: true,
  },
  updateMultipleStart: {
    updatingMultiple: true,
    updatedMultiple: false,
  },
  updateMultipleFinished: {
    updatingMultiple: false,
    updatedMultiple: true,
  },
  deleteStart: {
    deleting: true,
    deleted: false,
  },
  deleteFinished: {
    deleting: false,
    deleted: true,
  },
  createStart: {
    creating: true,
    created: false,
  },
  createFinished: {
    creating: false,
    created: true,
  },
  mergeSearchStart: {
    searching: true,
    searched: false,
  },
  mergeSearchFinished: {
    searching: false,
    searched: true,
  },
  mergeStart: {
    merging: true,
    merged: false,
  },
  mergeFinished: {
    merging: false,
    merged: true,
  },
};
