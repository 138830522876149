import { isEmpty, uniqBy, uniq } from 'lodash';

const handleOperator = ({ value, operator }) => {
  switch (operator) {
    case 'nin':
    case 'in':
      if (Array.isArray(value)) {
        return [...new Set(value.join(', ').replace(/\s/g, '').split(',').filter(f => f))];
      } return [...new Set(value.replace(/\s/g, '').split(',').filter(f => f))];

    default:
      return value;
  }
};

export const mapSelectedTreeItems = (filter) => {
  if (!isEmpty(filter.selectedTreeCategories)) {
    const uniqueSelectedList = uniqBy([...filter.selectedTreeCategories], 'id');
    const categoryList = uniqueSelectedList
      .filter(l => l.virtualParams)
      .map((l) => {
        const res = isEmpty(l.virtualParams.mappedCategories)
          ? l.virtualParams.selectedCategories
          : l.virtualParams.mappedCategories;
        if (isEmpty(res)) return [];
        return res;
      });

    const uniqueIds = uniq([...categoryList.flat()]);
    if (isEmpty(uniqueIds)) {
      return [];
    }
    return [{
      field: 'categories',
      operator: 'in',
      value: uniqueIds,
    }];
  }
  return [];
};

export const checkAndConverteDataByFieldOperator = (filters) => {
  const mappedFilters = filters.map(f => ({
    ...f,
    group: [
      ...f.group.map(g => ({
        ...g,
        fieldOption: g.fieldOption
          ? handleOperator({ value: g.fieldOption, operator: g.operator })
          : '',
      })),
      ...mapSelectedTreeItems(f),
    ],
  }));

  return mappedFilters;
};

export default {
  checkAndConverteDataByFieldOperator,
  mapSelectedTreeItems,
};
