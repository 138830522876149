import { createSelector } from 'reselect';

const previewFilterRootSelector = state => state;

export const previewFilterSelector = createSelector(
  previewFilterRootSelector,
  state => ({
    ...state,
  }),
);

export default {
  previewFilterSelector,
};
