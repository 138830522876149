import restInitState from '../util/rest/initState';

import {
  SET_YOTPO_DATA,
} from '../actions/types';

const initialState = {
  status: {},
  response: {},
};

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case SET_YOTPO_DATA:
      return {
        ...state,
        ...action.payload,
      };

    default: return state;
  }
};
