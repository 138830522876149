/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useDefaultAttribute = (state, props) => {
  useEffect(() => {
    if (!props.defaultAttribute.fetched && !props.defaultAttribute.fetching) {
      props.fetchListDefaultAttributes();
    }
  }, []);
};

export default useDefaultAttribute;
