/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  string, shape, func,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { camelizeKeys } from 'humps';
import axios from 'axios';
import { Popover } from '@material-ui/core';

import PopoverContent from './PopoverContent';
import { getCategoryPath } from '../../util/paths';

const TreeNodePopover = (props) => {
  const [category, setCategory] = useState({});
  const {
    hoveredCategoryId, popoverAnchor, handleClosePopper,
    anchorOrigin, transformOrigin,
  } = props;

  useEffect(() => {

    const fetchCategories = async () => {
      let result;
      try {
        result = await axios.get(getCategoryPath(props.hoveredCategoryId));
      } catch (error) {
        throw error;
      }

      const data = result ? result.data.data : [];
      const categoriesPayload = camelizeKeys({ ...data });

      setCategory(categoriesPayload);
    };

    if (props.hoveredCategoryId && category.id !== props.hoveredCategoryId) {
      fetchCategories();
    }
  }, [props.hoveredCategoryId]);

  const isOpen = !isEmpty(category) && !!hoveredCategoryId && hoveredCategoryId === category.id;

  return (
    <Popover
      open={isOpen}
      anchorEl={popoverAnchor}
      onClose={handleClosePopper}
      className="no-event"
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <PopoverContent
        category={category}
      />
    </Popover>
  );
};

TreeNodePopover.propTypes = {
  hoveredCategoryId: string.isRequired,
  popoverAnchor: shape(),
  handleClosePopper: func,
  anchorOrigin: shape(),
  transformOrigin: shape(),
};

TreeNodePopover.defaultProps = {
  popoverAnchor: null,
  handleClosePopper: null,
  anchorOrigin: {},
  transformOrigin: {},
};

export default TreeNodePopover;
