export const newRankingValidations = {
  name: [
    {
      type: 'isRequired',
      message: 'rankings.errors.cantBeBlank',
    }, {
      type: 'minSize',
      message: 'text.errors.moreThanThreeCharacters',
      condition: 3,
    }, {
      type: 'maxSize',
      message: 'text.errors.lessThanThirthyCharacters',
      condition: 30,
    },
  ],
  isCompleted: [
    {
      type: 'isCompleted',
      message: 'rankings.errors.overTotalMessage',
    },
  ],
};

export const rowAttributeValidations = {
  field: [
    {
      type: 'isRequired',
      message: 'rankings.errors.cantBeBlank',
    },
  ],
  normalization: [
    {
      type: 'isRequired',
      message: 'rankings.errors.cantBeBlank',
    },
  ],
  pushing: [
    {
      type: 'isRequired',
      message: 'rankings.errors.cantBeBlank',
    },
  ],
  weight: [
    {
      type: 'isRequired',
      message: 'rankings.errors.cantBeBlank',
    },
  ],
};
