export const mapExportableProducts = list => ([
  ...list.map((lItem) => {
    const specifications = {};

    Object.keys(lItem.specifications).forEach((sp) => {
      specifications[sp] = lItem.specifications[sp] && lItem.specifications[sp].en
        ? lItem.specifications[sp].en
        : lItem.specifications[sp];
    });

    return ({
      ...lItem,
      specifications,
    });
  }),
]);

export default mapExportableProducts;
