export default {
  businessPortal: {
    name: 'businessPortal',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  previewPortal: {
    name: 'previewPortal',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  pimPortal: {
    name: 'pimPortal',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  mapper: {
    name: 'mapper',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  repricing: {
    name: 'repricing',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  repricer: {
    name: 'repricer',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  activityLog: {
    name: 'activityLog',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  cams: {
    name: 'cams',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  rules: {
    name: 'rules',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  ranking: {
    name: 'ranking',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  category: {
    name: 'category',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  system: {
    name: 'system',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  search: {
    name: 'search',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  feeds: {
    name: 'feeds',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  navigation: {
    name: 'navigation',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  publish: {
    name: 'publish',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  preview: {
    name: 'preview',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  pim: {
    name: 'pim',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  users: {
    name: 'users',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  displayFields: {
    name: 'displayFields',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  bulkImport: {
    name: 'bulkImport',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
  influencer: {
    name: 'influencer',
    permissions: {
      create: 'create',
      delete: 'delete',
      update: 'update',
      view: 'view',
    },
  },
};
