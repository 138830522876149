/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment, useState, useEffect, memo,
} from 'react';
import { connect } from 'react-redux';
import {
  shape, func,
} from 'prop-types';
import swal from 'sweetalert';
import { uniqBy, uniq, isEmpty } from 'lodash';
import { camelize } from 'humps';
import { saveAs } from 'file-saver';

import MatButton from '@material-ui/core/Button';

import ExportDialog from './components/ExportDialog';
import ExportToEmailDialog from './components/ExportToEmailDialog';
import ImportDialog from './components/ImportDialog';

import {
  generateXLSX,
  getExportableProducts,
  importXLSXProducts,
  setImportFileName,
  clearProductManagementState,
} from '../../../../../../../../actions/productManagement';
import {
  updateMultipleProducts,
  clearFromProductState, exportToEmail,
} from '../../../../../../../../actions/preview';
import {
  update as updateVirtualCategory,
  clearFromVirtualCategoryState,
} from '../../../../../../../../actions/virtualCategory';
import {
  updatePromotionFilter,
  clearFromPromotionState,
} from '../../../../../../../../actions/promotion';
import {
  updateSearchFilter,
  clearFromSearchState,
} from '../../../../../../../../actions/search';

import {
  previewSelector,
} from './selectors/previewSelector';
import {
  promotionSelector,
} from './selectors/promotionSelector';
import {
  searchSelector,
} from './selectors/searchSelector';
import {
  virtualCategorySelector,
} from './selectors/virtualCategorySelector';
import {
  productManagementSelector,
} from './selectors/productManagementSelector';
import {
  systemSelector,
} from './selectors/systemSelector';
import {
  virtualCategoryWidgetSelector,
} from '../../../../../../selectors/virtualCategoryWidgetSelector';
import {
  promotionWidgetSelector,
} from '../../../../../../selectors/promotionWidgetSelector';

export const ProductManagementComponent = memo((props) => {
  const [showDialog, setShowDialog] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [showExportToEmailDialog, setShowExportToEmailDialog] = useState(false);
  const [exportEmail, setExportEmail] = useState('');
  const [exportEmailError, setExportEmailError] = useState('');
  const [exportFileDate, setExportFileDate] = useState('');
  const [files, setFiles] = useState([]);
  const [showPositionsCountError, setShowPositionsCountError] = useState(false);
  const [showImportContent, setShowImportContent] = useState(true);

  const {
    previewProps,
    promotionProps,
    searchProps,
    virtualCategoryProps,
    productManagementProps,
    systemProps,
    virtualCategoryWidgetProps,
    promotionWidgetProps,
    isHiddenProductsMode
  } = props;

  const exportType = 'email';

  const getEntityToUse = () => {
    const { selectedSearchFilter } = previewProps;
    let entityToUse = {};

    if (!isEmpty(virtualCategoryWidgetProps.selectedCategory)) {
      entityToUse = virtualCategoryWidgetProps.selectedCategory;
    }
    if (!isEmpty(promotionWidgetProps.selectedPromotion)) {
      entityToUse = promotionWidgetProps.selectedPromotion;
    }
    if (!isEmpty(selectedSearchFilter)) entityToUse = selectedSearchFilter;
    return entityToUse;
  };

  const selectedEntity = getEntityToUse();

  useEffect(() => {
    const entityUpdated = searchProps.updated
      || promotionProps.updated
      || virtualCategoryProps.updated;

    if (entityUpdated && productManagementProps.importedXLSXWithProducts) {
      setShowImportContent(false);
      setExportFileDate('');
    } else {
      setShowImportContent(true);
    }
  }, [
    searchProps.updated,
    promotionProps.updated,
    virtualCategoryProps.updated,
    productManagementProps.importedXLSXWithProducts,
  ]);

  useEffect(() => {
    if (showPositionsCountError) setShowPositionsCountError(false);
  }, [files]);

  const nestedAssign = (obj, keyPath, val) => {
    let incomingObject = obj;
    const lastKeyIndex = keyPath.length - 1;
    const length = Array.from(Array(lastKeyIndex).keys());

    length.forEach((item) => {
      const k = keyPath[item];
      if (!(k in incomingObject)) {
        incomingObject[k] = {};
      }
      incomingObject = incomingObject[k];
    });
    incomingObject[keyPath[lastKeyIndex]] = val;
  };

  const clearManagementState = () => {
    props.clearProductManagementState();
    props.clearFromProductState({
      updatingMultiple: false,
      updatedMultiple: false,
    });
    if (virtualCategoryProps.updated) {
      props.clearFromVirtualCategoryState({
        updated: false,
        updating: false,
      });
    }
    if (searchProps.updated) {
      props.clearFromSearchState({
        updated: false,
        updating: false,
      });
    }
    if (promotionProps.updated) {
      props.clearFromPromotionState({
        updated: false,
        updating: false,
      });
    }
  };

  const generateFiltersPayload = (filters, products) => {
    const hiddenProductsIds = products
      .filter(p => p.hidden === 1)
      .map(p => p.id);
    const hasNinFilter = filters.some(f => f.group
      .some(fG => fG.operator === 'nin' && fG.field === 'id'));

    let filtersPayload = [...filters];
    const ids = uniq([...hiddenProductsIds]);

    if (hasNinFilter) {
      filtersPayload = filters.map((f) => {
        const targetGroup = f.group
          .find(fG => fG.operator === 'nin' && fG.field === 'id');

        if (targetGroup) {
          return {
            ...f,
            group: [
              ...f.group.filter(g => g.field !== 'id'),
              {
                field: 'id',
                operator: 'nin',
                value: ids,
              },
            ],
          };
        }
        return f;
      });
    } else if (!isEmpty(ids)) {
      filtersPayload = [
        ...filters,
        {
          condition: 'and',
          group: [{
            field: 'id',
            operator: 'nin',
            value: ids,
          }],
        },
      ];
    }

    return filtersPayload
      .filter(f => f.group && f.group
        .every(g => g.field && !isEmpty(g.value)));
  };

  const generateImportPayload = () => {
    const { preview } = systemProps;
    const { parsedImportProducts, fileName, importedProducts } = productManagementProps;
    const nameArray = fileName.split('_');
    const fileDate = [...nameArray].splice(2).join('_');
    const productsBody = [...parsedImportProducts];
    const headers = parsedImportProducts[0];
    const { virtualParams } = selectedEntity;
    const { filters } = virtualParams || {};

    productsBody.shift();
    setExportFileDate(fileDate);

    const importPayload = productsBody.map((p) => {
      const values = {};

      p.forEach((value, index) => {
        const key = headers[index];

        values[key] = value;
      });
      return values;
    });
    const productsWithPositions = importPayload.filter(p => p.position);
    const overrides = productsWithPositions.map((p) => {
      const itemPayload = {
        position: p.position,
        productId: p.id,
      };
      return itemPayload;
    });

    const totalOverrides = [
      ...overrides,
    ];

    const body = productsBody.map((pArray) => {
      const currentProduct = importedProducts.find(p => p.id === pArray[0]);
      if (!currentProduct) return false;

      const payload = {
        scores: { ...currentProduct.scores },
        specifications: { ...currentProduct.specifications },
        id: currentProduct.id,
        title: currentProduct.title,
        description: currentProduct.description,
      };

      pArray.forEach((value, index) => {
        const valueIsExist = value !== '' && value !== undefined && value !== null;
        const key = headers[index];
        const currentAttribute = preview.find(attr => attr.value === key);

        if (valueIsExist) {
          if (currentAttribute) {
            nestedAssign(payload, camelize(currentAttribute.code).split('.'), value);
          } else payload[key] = value;
        }
      });
      return payload;
    }).filter(el => el);

    const mappedProducts = body.map((p) => {
      const item = p;

      item.title = { en: item.title };
      item.description = { en: item.description };
      return item;
    });

    const filtersPayload = generateFiltersPayload(filters, importPayload);

    return {
      products: uniqBy(mappedProducts, 'id'),
      totalOverrides,
      filters: filtersPayload,
    };
  };

  useEffect(() => {
    if (productManagementProps.importedXLSXWithProducts) {
      const { selectedEntityName } = previewProps;
      const params = { isSync: true, mapToMatrix: true };
      const { totalOverrides, filters } = generateImportPayload();
      const { virtualParams } = selectedEntity;
      const payload = {
        id: selectedEntity.id,
        virtualParams: {
          ...virtualParams,
          productsOverride: totalOverrides,
          filters,
        },
      };

      if (totalOverrides && totalOverrides.length > 500) {
        return setShowPositionsCountError(true);
      }

      if (selectedEntityName === 'category') {
        payload.isEnabled = selectedEntity.isEnabled;
        payload.includeInMenu = selectedEntity.includeInMenu;
        payload.useInProductSearch = selectedEntity.useInProductSearch;

        props.updateVirtualCategory(payload, params);
      } else if (selectedEntityName === 'promotion') {
        payload.type = selectedEntity.type;
        payload.urlKey = selectedEntity.urlKey;
        props.updatePromotionFilter(payload.id, payload);
      } else {
        payload.searchPhrase = selectedEntity.searchPhrase;
        payload.type = selectedEntity.type;
        props.updateSearchFilter(payload.id, payload);
      }
    }
    return setShowPositionsCountError(false);
  }, [productManagementProps.importedXLSXWithProducts]);

  const getValue = (p, o) => p.reduce((xs, x) => (((xs && xs[x]) || (xs && Number.isInteger(xs[x])))
    ? xs[x]
    : null), o);

  const getImgPayload = (imgs = []) => {
    const imgsUrlsList = imgs.map(img => img.url);
    return !isEmpty(imgsUrlsList) ? imgsUrlsList.join(', ') : '';
  };

  const filterByExportableFields = (products) => {
    const exportableFields = systemProps.export.filter(p => p.exportable);
    const filteredProducts = products.map((p) => {
      const fields = [
        p.id,
        p.position,
        p.hidden,
      ];

      exportableFields.forEach((f) => {
        const mappedFiedlValue = getValue(camelize(f.code).split('.'), p);

        const valueIsExist = mappedFiedlValue !== ''
          && mappedFiedlValue !== undefined
          && mappedFiedlValue !== null;

        if (valueIsExist) fields.push(mappedFiedlValue);
        else fields.push('');
      });
      return fields;
    });

    const payload = [
      ['id', 'position', 'hidden', ...exportableFields.map(f => f.value)],
      ...filteredProducts,
    ];
    return payload;
  };

  const getHiddenProductsParams = (filters) => {
    const hiddenProductsFilter = filters.find(f => f.group.some(fG => fG.operator === 'nin' && fG.field === 'id'));
    const hiddenProductsIds = hiddenProductsFilter
        ? hiddenProductsFilter.group.find(g => g.operator === 'nin' && g.field === 'id').value
        : [];

    return { hiddenProductsFilter, hiddenProductsIds };
  };

  const generateExportPayload = async () => {
    const { total, selectedEntityName } = previewProps;
    const { preview } = systemProps;
    const fields = preview
      .filter(p => p.exportable)
      .map(attr => attr.code);
    const limit = 200;
    const pagination = { page: 1, limit };
    const params = {
      categoryId: selectedEntity.id,
      urlKey: selectedEntity.urlKey,
      searchPhrase: selectedEntity.searchPhrase
        ? selectedEntity.searchPhrase[0]
        : [],
      entity: selectedEntityName,
    };
    const { virtualParams } = selectedEntity;
    const { filters, positions } = virtualParams || {};
    const { hiddenProductsIds } = getHiddenProductsParams(filters);

    const result = await props.getExportableProducts(
      params,
      hiddenProductsIds,
      fields,
      pagination,
      total,
    );

    const list = [...result.hiddenProducts, ...result.regularProducts];

    const productsPayload = list.map((p) => {
      const position = positions ? positions[p.id] : '';
      const isHidden = hiddenProductsIds.includes(p.id);
      const item = {
        ...p,
        position,
        hidden: isHidden ? 1 : 0,
        images: getImgPayload(p.images),
      };

      return item;
    }).filter(el => el);

    const payload = {
      fileName: `${selectedEntity.id}_${Date.now()}`,
      products: filterByExportableFields(productsPayload),
    };

    return payload;
  };

  const handleImportClick = () => {
    clearManagementState();
    setShowDialog(true);
    setShowPositionsCountError(false);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setFiles([]);
    clearManagementState();
    setExportFileDate('');
    setShowPositionsCountError(false);
  };

  const handleCloseExportDialog = () => {
    setShowExportDialog(false);
    setFiles([]);
    props.clearProductManagementState();
  };

  const handleExportClick = async () => {
    if (previewProps.total > 10000) {
      return swal({
        title: 'Too many products',
        text: 'There are too many products to export, the limit is 10000',
        icon: 'warning',
        dangerMode: true,
      });
    }

    if (!previewProps.total && !previewProps.hiddenProductsExist) {
      return swal({
        title: 'Not enough products',
        text: 'There are no products to export',
        icon: 'warning',
        dangerMode: true,
      });
    }

    setShowExportDialog(true);

    const exportPayload = await generateExportPayload();

    return props.generateXLSX(exportPayload);
  };

  const handleCloseExportToEmailDialog = () => {
    setShowExportToEmailDialog(false);
    setExportEmail('');
    setExportEmailError('');
  };

  const handleExportToEmailClick = async () => {
    setShowExportToEmailDialog(true);
    setExportEmail('');
    setExportEmailError('');
  };

  const handleSendExportToEmailClick = async () => {
    const fields = [];
    for (let i = 0; i < exportableFields.length; i++) {
      fields[i] = exportableFields[i].code;
    }

    const { virtualParams } = selectedEntity;
    const { filters } = virtualParams || {};
    const { hiddenProductsIds } = getHiddenProductsParams(filters);
    const filter = isHiddenProductsMode === true
        ? [ { group: [ { field: 'id', value: hiddenProductsIds, operator: 'in' } ] } ]
        : null;

    const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!exportEmail) {
      setExportEmailError('system.errors.cantBeBlank');
    } else if (!re.test(exportEmail)) {
      setExportEmailError('system.errors.pleaseProvideValidEmailAddress');
    } else {
      setShowExportToEmailDialog(false);
      setExportEmail('');
      setExportEmailError('');

      if (selectedEntity.urlKey && selectedEntity.type === 'promotion_url') {
        props.exportToEmail({urlKey: selectedEntity.urlKey, email: exportEmail, fields, filter});
      } else if (selectedEntity.phrase) {
        props.exportToEmail({phrase: selectedEntity.phrase, email: exportEmail, fields, filter});
      } else if (selectedEntity.id) {
        props.exportToEmail({id: selectedEntity.id, email: exportEmail, fields, filter});
      }

      return swal({
        title: 'Data export is queued',
        text: 'The data you selected will be sent to your E-mail: ' + exportEmail,
        icon: 'warning',
        dangerMode: true,
      });
    }
  };

  const handleDownloadClick = () => {
    const { xlsxDocument } = productManagementProps;
    const urlPrefix = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';

    saveAs(`${urlPrefix}${xlsxDocument.base64EncodedBook}`, `${xlsxDocument.fileName}.xlsx`);
  };

  const toBase64 = async file => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsBinaryString(file);
  });

  const handleSubmitClick = async () => {
    const file = files[0];
    const base64XLSXDocument = await toBase64(file);

    props.setImportFileName(file.name);
    props.importXLSXProducts(base64XLSXDocument);
  };

  const exportableFields = systemProps.export.filter(p => p.exportable);
  const entityUpdating = searchProps.updating
    || promotionProps.updating
    || virtualCategoryProps.updating;

  return (
    <Fragment>
      <MatButton
        onClick={handleImportClick}
        className="ml-25 preview-import-btn"
      >
        <i className="ti-import" />
      </MatButton>

      {exportType === 'file' && (
        <MatButton
          onClick={handleExportClick}
          className="ml-5 preview-import-btn"
        >
          <i className="ti-export" />
        </MatButton>
      )}

      {exportType === 'email' && (
        <MatButton
          onClick={handleExportToEmailClick}
          className="ml-5 preview-import-btn"
        >
          <i className="ti-export" />
        </MatButton>
      )}

      <ImportDialog
        showDialog={showDialog}
        setFiles={setFiles}
        files={files}
        setShowDialog={setShowDialog}
        handleSubmitClick={handleSubmitClick}
        handleCloseDialog={handleCloseDialog}
        importingXLSXWithProducts={productManagementProps.importingXLSXWithProducts}
        productsUpdated={previewProps.updatedMultiple}
        exportableFields={exportableFields}
        exportFileDate={exportFileDate}
        setExportFileDate={setExportFileDate}
        selectedEntity={selectedEntity}
        entityUpdating={entityUpdating}
        showPositionsCountError={showPositionsCountError}
        showImportContent={showImportContent}
        showLoader={entityUpdating || productManagementProps.importingXLSXWithProducts}
      />
      <ExportDialog
        showDialog={showExportDialog}
        handleCloseExportDialog={handleCloseExportDialog}
        handleDownloadClick={handleDownloadClick}
        generatedNewXLSX={productManagementProps.generatedNewXLSX}
        generatingNewXLSX={productManagementProps.generatingNewXLSX}
        xlsxDocument={productManagementProps.xlsxDocument}
        totalProductsCount={previewProps.total}
        hiddenProductsExist={previewProps.hiddenProductsExist}
      />
      <ExportToEmailDialog
        showDialog={showExportToEmailDialog}
        handleCloseDialog={handleCloseExportToEmailDialog}
        handleExportToEmail={handleSendExportToEmailClick}
        email={exportEmail}
        emailError={exportEmailError}
        setEmail={setExportEmail}
      />
    </Fragment>
  );
});

ProductManagementComponent.propTypes = {
  generateXLSX: func.isRequired,
  searchProps: shape().isRequired,
  promotionProps: shape().isRequired,
  previewProps: shape().isRequired,
  virtualCategoryProps: shape().isRequired,
  productManagementProps: shape().isRequired,
  systemProps: shape().isRequired,

  getExportableProducts: func.isRequired,
  importXLSXProducts: func.isRequired,
  setImportFileName: func.isRequired,
  clearProductManagementState: func.isRequired,
  clearFromProductState: func.isRequired,
  updateVirtualCategory: func.isRequired,
  clearFromVirtualCategoryState: func.isRequired,
  updatePromotionFilter: func.isRequired,
  updateSearchFilter: func.isRequired,
  clearFromSearchState: func.isRequired,
  clearFromPromotionState: func.isRequired,

  virtualCategoryWidgetProps: shape().isRequired,
  promotionWidgetProps: shape().isRequired,

  exportToEmail: func.isRequired,
};

ProductManagementComponent.defaultProps = {};

const mapStateToProps = state => ({
  systemProps: systemSelector(state.system),
  previewProps: previewSelector(state.preview),
  promotionProps: promotionSelector(state.promotion),
  searchProps: searchSelector(state.search),
  virtualCategoryProps: virtualCategorySelector(state.virtualCategory),
  productManagementProps: productManagementSelector(state.productManagement),
  virtualCategoryWidgetProps: virtualCategoryWidgetSelector(state.virtualCategoryWidget),
  promotionWidgetProps: promotionWidgetSelector(state.promotionWidget),
});

const mapDispatchToProps = {
  generateXLSX,
  getExportableProducts,
  importXLSXProducts,
  updateMultipleProducts,
  setImportFileName,
  clearProductManagementState,
  clearFromProductState,
  updateVirtualCategory,
  clearFromVirtualCategoryState,
  updatePromotionFilter,
  updateSearchFilter,
  clearFromSearchState,
  clearFromPromotionState,
  exportToEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductManagementComponent);
