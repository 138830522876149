import React, { useState, useEffect } from 'react';
import {
  func, shape,
} from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import IntlMessages from '../../../../util/IntlMessages';
import { getMappingData, getMappingCondition } from '../../../../actions/mapper';
import { updateSavedCategories } from "../../../../actions/user";
import FormDialog from '../../../../components/FormDialog';
import {
  getCatClassName,
  getMapperCategoriesStyles,
  selectCategories,
  getMarketCats,
  getNewMarketCats,
} from '../../../../util/mapper';

const MapperCategories = (props) => {
  const [showMarkedCategories, setShowMarkedCategories] = useState(false);
  const [mapCategories, setMapCategories] = useState([]);
  const [markedCategories, setMarkedCategories] = useState([]);
  const [selectedMarketplaceCategories, setSelectedMarketplaceCategories] = useState(null);
  const [showUnMarkCategoriesPopup, setShowUnMarkCategoriesPopup] = useState(false);

  const selectedCategories = props.selectedCategories;
  const setSelectedCategories = props.setSelectedCategories;

  const openedCategories = props.openedCategories;
  const setOpenedCategories = props.setOpenedCategories;

  useEffect(() => {
    if (props.mapperReset === true) {
      setSelectedCategories([]);
      sessionStorage.setItem('pim.selectedMapperCategories', null);
    }

    const getChildren = (items) => {
      const itemKeys = Object.keys(items);
      const children = [];
      for (let i = 0; i < itemKeys.length; i++) {
        children[i] = {
          id: items[itemKeys[i]].id,
          title: items[itemKeys[i]].title,
          level: items[itemKeys[i]].level,
          count: items[itemKeys[i]].products_count,
          successCount: items[itemKeys[i]].products_success_count,
          children: items[itemKeys[i]].children ? getChildren(items[itemKeys[i]].children) : []
        };
      }

      return children;
    }

    const mapCats = [];

    for (let i = 0; i < props.mapperCategories.length; i++) {
      const children = props.mapperCategories[i].children ? getChildren(props.mapperCategories[i].children) : [];
      mapCats[i] = {
        id: props.mapperCategories[i].id,
        title: props.mapperCategories[i].title,
        level: props.mapperCategories[i].level,
        count: props.mapperCategories[i].products_count,
        successCount: props.mapperCategories[i].products_success_count,
        children: children
      };
    }

    setMapCategories(mapCats);

    if (props.userSelectedMarketplaceCategories.length > 0) {
      setSelectedMarketplaceCategories(props.userSelectedMarketplaceCategories);
    } else if (selectedMarketplaceCategories === null) {
      if (props.mapperMarketplace.indexOf('zap') !== -1) {
        const selectedMarketplaceCats = props.sessionItem.selectedZapCategories
          ? props.sessionItem.selectedZapCategories
          : [];
        setSelectedMarketplaceCategories(selectedMarketplaceCats);
      }
      if (props.mapperMarketplace.indexOf('ksp') !== -1) {
        const selectedMarketplaceCats = props.sessionItem.selectedKspCategories
          ? props.sessionItem.selectedKspCategories
          : [];
        setSelectedMarketplaceCategories(selectedMarketplaceCats);
      }
    }

  }, [
    props, selectedMarketplaceCategories, setSelectedCategories,
  ]);

  for (let i = 0; i < openedCategories.length; i++) {
    setTimeout(() => {
      const cat = document.getElementById('cat-' + openedCategories[i]);
      if (cat) {
        cat.className = 'mapper-block';
      }
    }, 500);
  }

  const displayedCategories = showMarkedCategories ? markedCategories : mapCategories;

  const handleShowMarkedCategories = (e) => {
    const markedCats = getMarketCats([], mapCategories, selectedMarketplaceCategories);

    setMarkedCategories(markedCats);
    setShowMarkedCategories(true);

    const selMarketplaceCats = [];
    for (let i = 0; i < selectedCategories.length; i++) {
      if (selectedCategories[i] === true) {
        selMarketplaceCats.push(i);
      }
    }
    if (selectedMarketplaceCategories.length === selMarketplaceCats.length) {
      setTimeout(() => {
        const checkAll = document.getElementById('check-all');
        if (checkAll) {
          checkAll.checked = true;
        }
      }, 500);
    }
  }

  const handleHideMarkedCategories = (e) => {
    setShowMarkedCategories(false);
  }

  const handleCategoriesVisibleChange = (e, id) => {
    const div = document.getElementById('cat-' + id);
    if (div && div.className === 'mapper-none') {
      openedCategories.push(id);
      div.className = 'mapper-block';
    } else {
      const index = openedCategories.indexOf(id);
      if (index !== -1) {
        openedCategories.splice(index, 1);
      }
      div.className = 'mapper-none';
    }

    const uniqueOpenedCategories = openedCategories.reverse().filter(function (e, i, arr) {
      return arr.indexOf(e, i+1) === -1;
    }).reverse();

    setOpenedCategories(uniqueOpenedCategories);
    sessionStorage.setItem('pim.openedMapperCategories', uniqueOpenedCategories.join());
  };

  const handleSelectCategory = (e, id, children, finalLevel) => {
    const { target } = e;
    const selCategories = selectCategories(id, target.checked, selectedCategories, children, finalLevel);

    const selectedCategoriesKeys = Object.keys(selCategories);
    const realSelectedCategories = [];
    for (let i = 0; i < selectedCategoriesKeys.length; i++) {
      if (selCategories[selectedCategoriesKeys[i]] === true) {
        realSelectedCategories[selectedCategoriesKeys[i]] = true;
      }
    }

    setSelectedCategories(realSelectedCategories);
    sessionStorage.setItem('pim.selectedMapperCategories', Object.keys(realSelectedCategories).join());
    for (let i = 0; i < openedCategories.length; i++) {
      setTimeout(() => {
        const cat = document.getElementById('cat-' + openedCategories[i]);
        if (cat) {
          cat.className = 'mapper-block';
        }
      }, 100);
    }

    const realSelectedCategoriesKeys = Object.keys(realSelectedCategories);

    let isMarketplaceCategoriesChecked = true;
    for (let i = 0; i < selectedMarketplaceCategories.length; i++) {
      if (realSelectedCategoriesKeys.indexOf(selectedMarketplaceCategories[i].toString()) === -1) {
        isMarketplaceCategoriesChecked = false;
      }
    }

    const checkAll = document.getElementById('check-all');
    if (checkAll) {
      checkAll.checked = isMarketplaceCategoriesChecked !== false;
    }
  };

  const handleSelectAllMarketplaceCategories = (e) => {
    const { target } = e;
    const checkAll = document.getElementById('check-all');
    const checked = target.id === 'check-all' ? checkAll.checked : !checkAll.checked;

    for (let i = 0; i < selectedMarketplaceCategories.length; i++) {
      handleSelectCategory({target: {checked: checked}}, selectedMarketplaceCategories[i]);
    }
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const getNewMarkedCategories = (uniqueMarketplaceCategories) => {
    const newMarkedCategories = getNewMarketCats([], mapCategories, uniqueMarketplaceCategories);

    setSelectedCategories([]);
    sessionStorage.setItem('pim.selectedMapperCategories', null);
    setMarkedCategories(newMarkedCategories);
    setSelectedMarketplaceCategories(uniqueMarketplaceCategories);
  }

  const handleMarkCategories = () => {
    const selectedCategoriesKeys = Object.keys(selectedCategories);
    const newMarketplaceCategories = selectedMarketplaceCategories.concat(selectedCategoriesKeys);

    const uniqueMarketplaceCategories = newMarketplaceCategories.filter(onlyUnique);
    props.updateSavedCategories(props.sessionItem.id, uniqueMarketplaceCategories, props.mapperMarketplace);
    getNewMarkedCategories(uniqueMarketplaceCategories);
  };

  const handleShowUnMarkCategoriesPopup = () => {
    setShowUnMarkCategoriesPopup(true);
  };

  const handleHideUnMarkCategoriesPopup = () => {
    setShowUnMarkCategoriesPopup(false);
  };

  const handleUnMarkCategories = () => {
    setShowUnMarkCategoriesPopup(false);
    const unselectMarketplaceCategories = Object.keys(selectedCategories);
    const newMarketplaceCategories = [];
    for (let i = 0; i < selectedMarketplaceCategories.length; i++){
      if (unselectMarketplaceCategories.indexOf(selectedMarketplaceCategories[i]) === -1) {
        newMarketplaceCategories.push(selectedMarketplaceCategories[i]);
      }
    }

    const uniqueMarketplaceCategories = newMarketplaceCategories.filter(onlyUnique);
    props.updateSavedCategories(props.sessionItem.id, uniqueMarketplaceCategories, props.mapperMarketplace);
    getNewMarkedCategories(uniqueMarketplaceCategories);
  };

  const SidebarCategories = ((props) => {
    const {
      item, marketplace
    } = props;

    let className = 'mapper-categories';
    if (item.children.length === 0) {
      className += ' mapper-is-no-children';
    }

    if (showMarkedCategories === false && selectedMarketplaceCategories.indexOf(item.id.toString()) !== -1) {
      className += ' mapper-selected-marketplace-categories';
    }

    let level = item.level;
    if (showMarkedCategories === true) {
      level = 0;
    }
    let finalLevel = 3;
    if (marketplace.indexOf('ksp') !== -1) {
      finalLevel = 4;
    }

    if (level === finalLevel || showMarkedCategories === true) {
      const title = '(' + item.successCount + ' / ' + item.count + ') ' + item.title;

      return (
        <>
          <div
            onClick={e => handleSelectCategory(e, item.id, [], true)}
            className={className}
            style={ getMapperCategoriesStyles(item, marketplace, showMarkedCategories) }
          >
            <input id={'check-' + item.id}
                   type="checkbox"
                   className="mapper-checkbox"
                   defaultChecked={!!selectedCategories[item.id]}
            />
            {title}
          </div>
        </>
      );
    } else {
      const allOpenedCategories = [];
      for (let i = 0; i < openedCategories.length; i++) {
        allOpenedCategories[openedCategories[i]] = true;
      }

      return (
        <>
          <div
            onClick={e => handleCategoriesVisibleChange(e, item.id)}
            className={className}
            style={getMapperCategoriesStyles(item, marketplace)}
          >
            <input id={'check-' + item.id}
                   type="checkbox"
                   className="mapper-checkbox"
                   onClick={e => handleSelectCategory(e, item.id, item.children, false)}
                   defaultChecked={!!selectedCategories[item.id]}
            />
            {item.title}
          </div>

          <div id={'cat-' + item.id} className={ getCatClassName(allOpenedCategories, item) }>
            {item.children && item.children.map((child, childKey) => (
              <SidebarCategories key={'category-' + childKey}  item={child} marketplace={marketplace} level={level} />
            ))}
          </div>
        </>
      );
    }
  });

  return (
    <>
      <div className="mapper-wrapper">

        <div className="h5 sidebar-title">

          {showMarkedCategories ? (
            <span onClick={e => handleHideMarkedCategories(e)} className="mapper-categories-unselected">
                  <IntlMessages id="sidebar.mappersAllCategories" />
                </span>
          ) : (
            <span className="mapper-categories-selected">
                  <IntlMessages id="sidebar.mappersAllCategories" />
                </span>
          )}

          <span>&nbsp;</span>

          {showMarkedCategories ? (
            <span className="mapper-categories-selected">
              <IntlMessages id="sidebar.mappersMarkedCategories" />
            </span>
          ) : (
            <span onClick={e => handleShowMarkedCategories(e)} className="mapper-categories-unselected">
              <IntlMessages id="sidebar.mappersMarkedCategories" />
            </span>
          )}
        </div>

        <div className="sidebar-group">
          <div className="list-inline-item search-icon d-inline-block">
            <div className="search-input-lg block">

              {displayedCategories.map((item, itemKey) => (
                <SidebarCategories
                  key={'category-' + itemKey}
                  item={item}
                  marketplace={props.mapperMarketplace}
                />
              ))}

              {showMarkedCategories && (
                <>
                  <br />
                  <div
                    onClick={e => handleSelectAllMarketplaceCategories(e)}
                    className="mapper-level-0 mapper-categories mapper-is-no-children"
                  >
                    {displayedCategories.length > 0 && (
                      <>
                        <input id={'check-all'}
                               type="checkbox"
                               className="mapper-checkbox"
                               defaultChecked={false}
                        />
                        <IntlMessages id="label.selectUnselectAllCategories" />
                      </>
                    )}
                  </div>
                </>
              )}

            </div>

            {showMarkedCategories && displayedCategories.length === 0 && (
              <>
                <br />
                <IntlMessages id="sidebar.mappersNoMarkedCategories" />
                <br /><br />
              </>
            )}

            {!showMarkedCategories && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="text-white  mapper-w-175"
                disabled={selectedCategories.length === 0}
                onClick={handleMarkCategories}
              >
                <IntlMessages id="button.markSelected" />
              </Button>
            )}

          </div>
        </div>

        {showMarkedCategories && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="text-white mapper-w-175"
            disabled={selectedCategories.length === 0}
            onClick={handleShowUnMarkCategoriesPopup}
          >
            <IntlMessages id="button.unmarkSelected" />
          </Button>
        )}

      </div>

      <FormDialog
          title={<IntlMessages id="sidebar.mapper.removingCategoriesTitle" />}
          open={showUnMarkCategoriesPopup}
          hideCloseButton={false}
          className="dialog-inputs-custom dialog-content-visible"
          closeButtonTitle="Cancel"
          submitButtonTitle="Remove"
          maxWidth="xs"
          onClose={handleHideUnMarkCategoriesPopup}
          onSubmit={handleUnMarkCategories}
      >
        <IntlMessages id="sidebar.mapper.removingCategories" />
      </FormDialog>

    </>
  );
};

MapperCategories.propTypes = {
  getMappingData: func.isRequired,
  getMappingCondition: func.isRequired,
  sessionItem: shape().isRequired,
  userSelectedMarketplaceCategories: shape().isRequired,
  updateSavedCategories: func.isRequired,
};

MapperCategories.defaultProps = {
  sessionItem: {},
  userSelectedMarketplaceCategories: {},
};

const mapStateToProps = state => ({
  sessionItem: state.session.item,
  userSelectedMarketplaceCategories: state.user.userSelectedMarketplaceCategories,
});

const mapDispatchToProps = {
  getMappingData,
  getMappingCondition,
  updateSavedCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapperCategories);
