import React, { useState, memo } from 'react';
import {
  func, shape, bool, node, arrayOf, string,
} from 'prop-types';
import { isEmpty } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import { InputField as MatInput } from '../../../../../components/Input';
import IntlMessages from '../../../../../util/IntlMessages';
import Field from '../../../../../components/Field';

const PopperActionsForm = memo(({
  values,
  onMoveToChange,
  dataSource,
  content,
  onEditProduct,
  onUnpin,
  fetchingPureProduct,
  onOrderChange,
  handleHideProducts,
  selectedEntityUpdating,
  hiddenProductsIds,
  handleUnHideProducts,
  isHiddenProductsMode,
  setHiddenProductsFilter,
  handleUpdateEntityFilters,
  setIsHiddenProductsMode,
  selectedEntity,
  onApply,
}) => {
  const [productPositionLabel, setProductPositionLabel] = useState('');
  const [order, setOrder] = useState('');
  const currentProductIsHidden = hiddenProductsIds.includes(dataSource.product.id);
  const { virtualParams } = selectedEntity;
  const { productsOverride, filters } = virtualParams || {};

  const handleMoveToChange = (e) => {
    onMoveToChange(e);
    setProductPositionLabel(e.target.value);
  };

  const isPinned = () => {
    const { product } = dataSource;

    return productsOverride.some(pO => pO.productId === product.id);
  };

  const unpinPosition = (e) => {
    e.preventDefault();
    onUnpin(dataSource.product);
  };

  const handleOrderChange = (e) => {
    const { value } = e.target;

    const format = /^[0-9]*$/gm;

    if (!format.test(value)) {
      onOrderChange(order);
      setOrder(order);
    } else {
      onOrderChange(value);
      setOrder(value);
    }
  };

  const onHideProducts = (e) => {
    e.preventDefault();
    const { product } = dataSource;
    if (currentProductIsHidden) {
      handleUnHideProducts({
        products: [product],
        filters,
        setIsHiddenProductsMode,
        setHiddenProductsFilter,
        handleUpdateEntityFilters,
      });
    } else {
      handleHideProducts({
        products: [product],
        filters,
        setHiddenProductsFilter,
        handleUpdateEntityFilters,
      });
    }
  };

  const errorClass = !isEmpty(dataSource.orderHelperText.textId) ? 'has-error input-padding-clear' : '';
  const isEnabledApplyButton = productPositionLabel === 'position'
    ? (productPositionLabel && order)
    : productPositionLabel;

  return (
    <div className="popper-actions flex  form-compact flex-1 direction-column">
      <div className="form-group form-group-inline">
        <Field
          element="select"
          label="Move to"
          className="select-wrapper block"
          name="placment"
          hideStaticLabel
          inputProps={{
            name: 'placment',
            id: 'placment-select',
          }}
          value={productPositionLabel}
          disabled={fetchingPureProduct}
          options={dataSource.placementList || []}
          onChange={handleMoveToChange}
        />
        {productPositionLabel === 'position' && (
          <div className="flex ml-10">
            <MatInput
              label="num"
              name="order"
              className={`num-input ${errorClass}`}
              value={order}
              hideStaticLabel
              inputProps={{
                name: 'order',
                id: 'order-select',
              }}
              onChange={handleOrderChange}
            />
            <div className="form-group-inner self-end">
              {dataSource.orderHelperText.textId && (
                <Tooltip
                  placement="bottom-end"
                  title={(
                    <div className="tooltip-title-content-wrapper">
                      <IntlMessages id={dataSource.orderHelperText.textId} />
                      {`${dataSource.orderHelperText.lengthLimit}`}
                    </div>
                  )}
                >
                  <i className="ti-info-alt" />
                </Tooltip>
              )}
            </div>
          </div>
        )}
        <Button
          color="primary"
          className="btn-xs float-right self-end ml-10"
          disabled={!isEnabledApplyButton}
          onClick={onApply}
        >
          <IntlMessages id="paper.actions.apply.label" />
        </Button>
      </div>
      <div className="form-group">
        <Field
          element="buttonMat"
          type="button"
          className="btn-link btn-xs text-normal"
          value={<IntlMessages id="preview.button.viewDetails" />}
          onClick={onEditProduct}
          disabled={fetchingPureProduct || isHiddenProductsMode}
        />
      </div>
      <div className="from-group mb-15">
        <Field
          element="buttonMat"
          type="button"
          disabled={!isPinned() || fetchingPureProduct}
          className="btn-link btn-xs text-normal"
          value={<IntlMessages id="button.unpin" />}
          onClick={unpinPosition}
        />
      </div>
      <div className="from-group mb-15">
        <Field
          element="buttonMat"
          type="button"
          disabled={fetchingPureProduct || selectedEntityUpdating}
          className="btn-link btn-xs text-normal"
          value={(
            currentProductIsHidden
              ? <IntlMessages id="button.show" />
              : <IntlMessages id="button.hide" />
          )}
          onClick={onHideProducts}
        />
      </div>
      {false && (
        <div className="form-group checkbox-sm">
          <Field
            hideStaticLabel
            element="checkbox"
            label="Marked for Review"
            className="checkbox-wrapper"
            name="forReview"
            checked={false}
            inputProps={{
              name: 'forReview',
              id: 'forReview-select',
            }}
            value={values.forReview || ''}
          />
        </div>
      )}
      {content}
    </div>
  );
});

PopperActionsForm.propTypes = {
  onMoveToChange: func,
  onEditProduct: func,
  onOrderChange: func,
  onUnpin: func.isRequired,
  values: shape(),
  dataSource: shape(),
  selectedEntity: shape().isRequired,
  content: node,
  fetchingPureProduct: bool,
  handleHideProducts: func.isRequired,
  selectedEntityUpdating: bool,
  hiddenProductsIds: arrayOf(string).isRequired,
  handleUnHideProducts: func.isRequired,
  isHiddenProductsMode: bool.isRequired,
  setHiddenProductsFilter: func.isRequired,
  handleUpdateEntityFilters: func.isRequired,
  setIsHiddenProductsMode: func.isRequired,
  onApply: func,
};

PopperActionsForm.defaultProps = {
  onMoveToChange: null,
  onOrderChange: null,
  values: {},
  dataSource: {},
  content: null,
  onEditProduct: null,
  fetchingPureProduct: false,
  selectedEntityUpdating: false,
  onApply: false,
};

export default PopperActionsForm;
