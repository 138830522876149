export const filterGroupValidations = {
  field: [{
    type: 'isRequired',
    message: 'preview.errors.filterItemIsRequired',
  }, {
    type: 'maxSize',
    message: 'text.errors.lessThan200Characters',
    condition: 200,
  }],
  fieldOption: [{
    type: 'isRequired',
    message: 'preview.errors.filterItemIsRequired',
  }, {
    type: 'maxSize',
    message: 'text.errors.lessThan200Characters',
    condition: 200,
  }, {
    type: 'maxNumber',
    message: 'text.errors.numberShouldBeLessOrEqual',
    condition: 99999999,
  }],
  operator: [{
    type: 'isRequired',
    message: 'preview.errors.filterItemIsRequired',
  }],
};

export const groupConditionValidations = {
  groupCondition: [{
    type: 'isRequired',
    message: 'preview.errors.filterItemIsRequired',
  }],
};

export const filterNameValidations = {
  filter: [{
    type: 'isRequired',
    message: 'preview.errors.filterItemIsRequired',
  }],
};

export const actionAttributeValidations = {
  attribute: [{
    type: 'isRequired',
    message: 'pim.list.actionBar.validations.attributeCommentIsRequired',
  }],
  attributeValue: [{
    type: 'isRequired',
    message: 'pim.list.actionBar.validations.attributeValueCommentIsRequired',
  }],
};

export const productValidations = {
  description: [{
    type: 'textFieldIsRequired',
    message: 'pim.errors.fieldIsRequired',
  }],
  title: [{
    type: 'textFieldIsRequired',
    message: 'pim.errors.fieldIsRequired',
  }],
};

const getNameValidations = ({ minSize, maxSize }) => [
  {
    type: 'isRequired',
    message: 'text.helper.cantBeBlank',
  }, {
    type: 'minSize',
    message: `text.errors.moreThan${minSize}Characters`,
    condition: minSize,
  }, {
    type: 'maxSize',
    message: `text.errors.lessThan${maxSize}Characters`,
    condition: maxSize,
  },
];

export const productNameValidations = {
  en: getNameValidations({ minSize: 3, maxSize: 300 }),
  he: [{
    type: 'minSize',
    message: 'text.errors.moreThan3Characters',
    condition: 3,
  }, {
    type: 'maxSize',
    message: 'text.errors.lessThan300Characters',
    condition: 300,
  }],
};
