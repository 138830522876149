import { createSelector } from 'reselect';

const promotionWidgetRootSelector = state => state;

const stateSelector = createSelector(
  promotionWidgetRootSelector,
  state => ({
    selectedPromotion: state.selectedPromotion,
  }),
);

export const promotionWidgetSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  promotionWidgetSelector,
};
