/* eslint-disable no-param-reassign */
import axios from 'axios';
import { decamelizeKeys, camelizeKeys } from 'humps';
import { isEmpty } from 'lodash';
import { navigationsSearchPath } from 'util/paths';
import RestActions from 'util/rest/actions';
import { showErrorNotification } from '../util/api';

import { mapPositions } from 'util/mapVirtualParamsPositions';

import {
  SET_SELECTED_PROMOTION,
} from './types';

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';

const restPromotionActions = new RestActions('promotion_widget');

export const fetchPromotionList = (filter = {}, pagination = {}) => (dispatch) => {
  dispatch(restPromotionActions.fetchAllStart());

  const sort = [{
    field: 'created_at',
    order: 'desc',
  }];

  axios.post(navigationsSearchPath + '?ref=' + ref, decamelizeKeys({
    filter, pagination, sort,
  })).then((res) => {
    const promotions = res ? camelizeKeys(res.data.data) : [];
    const allPages = res ? res.data.pages : [];

    const result = promotions.map((c) => {
      const originalPromotion = res.data.data
        .find(p => p.id === c.id);
      const payload = { ...c };

      if (originalPromotion) {
        payload.virtualParams = mapPositions(
          c.virtualParams,
          originalPromotion.virtual_params,
        );
      }
      return payload;
    });
    dispatch(restPromotionActions.fetchAllFinished({ list: result, allPages: allPages }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restPromotionActions.fetchAllFinished({ error: error.response.data, hasError: true }));
  });
};

export const setSelectedPromotion = promotion => (dispatch) => {
  dispatch({
    type: SET_SELECTED_PROMOTION,
    payload: {
      selectedPromotion: promotion,
      promotionSelected: !isEmpty(promotion),
    },
  });
};

export const clearFromPromotionState = payload => (dispatch) => {
  dispatch(restPromotionActions.clearFromStateFinished(payload));
};

export const clearPromotionState = () => (dispatch) => {
  dispatch(restPromotionActions.clearAllFinished());
};

