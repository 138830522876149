import React, { useState } from 'react';
import { node } from 'prop-types';

const emptyFunc = () => {};

const PermissionsContext = React.createContext([{}, emptyFunc]);

const PermissionsProvider = (props) => {
  const [permissions, setPermissions] = useState({});
  return (
    <PermissionsContext.Provider value={[permissions, setPermissions]}>
      {props.children}
    </PermissionsContext.Provider>
  );
};

PermissionsProvider.propTypes = {
  children: node.isRequired,
};

export { PermissionsContext, PermissionsProvider };
