/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  func, bool, shape, arrayOf, string, oneOfType, node,
} from 'prop-types';
import { decamelize, camelizeKeys } from 'humps';
import { isEmpty, uniq, uniqBy } from 'lodash';
import { getCamelizeKey } from 'util/virtualFacetManagment';
import {
  Button, Typography, IconButton, CircularProgress, FormHelperText, Divider,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import {
  fetchInitialAttributeOptions,
  clearInitialAttributeOptions,
} from 'actions/virtualFacet';

import FormDialog from 'components/FormDialog';
import TooglePaper from 'components/TooglePaper';
import IntlMessages from 'util/IntlMessages';
import checkValidations from 'util/validator';

import Name from './components/Name';
import Controls from './components/Controls';
import BasedOn from './components/BasedOn';
import GroupedValues from './components/GroupedValues';

import {
  virtualFacetValidation,
  validateVirtualFacetGroupedValues,
  validateFacetName,
} from './utils/validations';

const FacetManagementDialog = (props) => {
  const [facetNameValue, setFacetNameValue] = useState({ en: '' });
  const [basedOnValue, setBasedOnValue] = useState([]);
  const [basedOnCodes, setBasedOnCodes] = useState([]);
  const [basedOnAttributes, setBasedOnAttributes] = useState([]);
  const [basedOnAttributeOptionsValue, setBasedOnAttributeOptionsValue] = useState([]);
  const [searchValuesOptionCodes, setSearchValuesOptionCodes] = useState([]);
  const [basedOnOperatorValue, setBasedOnOperatorValue] = useState('');
  const [groupedValueList, setGroupedValueList] = useState([]);
  const [groupedValueListType, setGroupedValueListType] = useState('');
  const [errors, setErrors] = useState([]);
  const [isFacetSet, setIsFacetSet] = useState(false);
  const [frontendInputType, setFrontendInputType] = useState('');
  const [isPromoted, setIsPromoted] = useState(false);

  const clearState = () => {
    setFacetNameValue({ en: '' });
    setBasedOnValue([]);
    setBasedOnCodes([]);
    setBasedOnAttributes([]);
    setBasedOnAttributeOptionsValue([]);
    setBasedOnOperatorValue('');
    setGroupedValueList([]);
    setErrors({});
    setGroupedValueListType('');
    setSearchValuesOptionCodes([]);
    setIsFacetSet(false);
    setIsPromoted(false);
  };

  useEffect(() => {
    if (
      !isEmpty(props.selected.values)
      && !isEmpty(props.selected.values.filter(e => e))
      && !isEmpty(props.initialOptions)
    ) {
      const currentAtributeOptions = props.selected.values
        .map(
          sv => uniqBy(
            [...props.initialOptions, ...props.selectedOptions], 'optionCode',
          ).find(io => io.optionCode === sv),
        ).filter(e => e);

      const mappedBasedOnAttributeOptionsValue = currentAtributeOptions.map(cao => cao.label.en);
      setBasedOnAttributeOptionsValue(mappedBasedOnAttributeOptionsValue);
      setSearchValuesOptionCodes(!isEmpty(props.selected.values) ? props.selected.values : []);
    }
  }, [
    props.fetchInitialAttributesOptionsFinished,
    props.selected,
  ]);

  useEffect(() => {
    setBasedOnAttributes(props.basedOn.attributeOptions);
  }, [props.basedOn.attributeOptions]);

  useEffect(() => {
    if (!isEmpty(props.selected) && !isFacetSet) {
      let groupedValues = { groups: [] };
      const groupedValuesKey = getCamelizeKey(props.selected.label.en);
      if (
        !isEmpty(props.selected.groupedValues)
        && props.selected.groupedValues[groupedValuesKey]
      ) {
        groupedValues = props.selected.groupedValues[groupedValuesKey];
        setGroupedValueListType(groupedValues.type);
        const mappedGroupedValues = groupedValues.groups.map(g => ({
          ...g,
          id: Math.random() * 10000,
          values: g.values.map(v => ({
            id: Math.random() * 10000,
            value: v,
          })),
        }));
        setGroupedValueList([...mappedGroupedValues]);
      }

      if (
        !props.fetchInitialAttributesOptionsStart
        && !isEmpty(props.selected)
      ) {
        const groupedValuesOptions = !isEmpty(groupedValues)
          ? groupedValues.groups.map(g => g.values).flat()
          : [];
        const codes = [...props.selected.attributes];
        const values = !isEmpty(props.selected.values) ? props.selected.values : [];
        const options = uniq([...values, ...groupedValuesOptions].flat());
        if (!isEmpty(options)) props.fetchInitialAttributeOptions(codes, options);
        setIsFacetSet(true);
      }
      setFacetNameValue(props.selected.label);
      setIsPromoted(props.selected.isPromoted || false);
      setBasedOnCodes(props.selected.attributes);
      setBasedOnOperatorValue(props.selected.operator);
      setBasedOnValue(props.selected.attributes);
    } else {
      clearState();
    }
  }, [props.selected]);

  const resetErrors = () => {
    if (!isEmpty(errors)) {
      setErrors({});
    }
  };

  const handleDialogClose = () => {
    clearState();
    props.onClose();
    props.clearInitialAttributeOptions();
  };

  const validateDialogFacet = () => {
    let groupsIsValid = true;

    const fields = {
      en: facetNameValue.en,
      basedOn: basedOnValue,
      type: groupedValueListType,
      basedOnOptions: basedOnAttributeOptionsValue,
      basedOnOperator: basedOnOperatorValue,
    };

    if (!isEmpty(basedOnOperatorValue) && isEmpty(basedOnAttributeOptionsValue)) {
      fields.basedOnOptions = basedOnAttributeOptionsValue;
    }

    if (isEmpty(basedOnOperatorValue) && !isEmpty(basedOnAttributeOptionsValue)) {
      fields.basedOnOperator = basedOnOperatorValue;
    }

    const formErrors = checkValidations(virtualFacetValidation, fields);
    const groupedValuesErrors = validateVirtualFacetGroupedValues(groupedValueList);

    if (isEmpty(groupedValueListType)) {
      delete formErrors.type;
    }

    if (!isEmpty(groupedValuesErrors)) {
      const groupLabels = groupedValuesErrors.map(g => g.label.messages).flat();
      const groupValues = groupedValuesErrors
        .map(g => g.values.map(gv => gv.messages).flat()).flat();

      groupsIsValid = isEmpty([...groupLabels, ...groupValues].flat());

      if (!groupsIsValid) {
        formErrors.groupedValues = groupedValuesErrors;
      } else {
        delete formErrors.groupedValues;
      }
    }

    if (isEmpty(basedOnOperatorValue) && isEmpty(basedOnAttributeOptionsValue)) {
      delete formErrors.basedOnOptions;
      delete formErrors.basedOnOperator;
    }
    setErrors(formErrors);

    if (!isEmpty(props.facetList.find(fl => fl.label.en === facetNameValue.en)) && props.mode !== 'edit') {
      const isExist = {
        type: 'isExist',
        message: 'facet.helper.alreadyExist',
      };

      !isEmpty(formErrors.en)
        ? formErrors.en.push(isExist)
        : formErrors.en = [isExist];
    }

    if (props.mode !== 'edit' && !isEmpty(facetNameValue.en)) {
      if (facetNameValue.en.toLowerCase().startsWith('display')) {
        const isStartWithDisplay = {
          type: 'isStartWithDisplay',
          message: 'facet.helper.displayIsConstant',
        };
        !isEmpty(formErrors.en)
          ? formErrors.en.push(isStartWithDisplay)
          : formErrors.en = [isStartWithDisplay];
      }
      const incorectFacetNameMessgae = validateFacetName(facetNameValue.en);

      if (incorectFacetNameMessgae) {
        !isEmpty(formErrors.en)
          ? formErrors.en.push(incorectFacetNameMessgae)
          : formErrors.en = [incorectFacetNameMessgae];
      }
    }


    if (!isEmpty(formErrors) || !groupsIsValid) {
      return false;
    }

    return true;
  };

  const handleGroupedValueListTypeChange = (selected) => {
    resetErrors();
    if (!isEmpty(selected)) {
      switch (selected.value) {
        case 'combined_values':
          setGroupedValueList(groupedValueList.map(gv => ({
            ...gv,
            values: gv.values.map(i => ({
              ...i,
              value: [i.value].filter(e => e),
            })),
          })));
          break;
        case 'hierarchy':
          setGroupedValueList(groupedValueList.map(gv => ({
            ...gv,
            values: gv.values.map(i => ({
              ...i,
              value: i.value[0] || '',
            })),
          })));
          break;

        default:
          break;
      }
      setGroupedValueListType(selected.value);
    }
  };

  const handleDialogSubmit = () => {
    resetErrors();
    const isValid = validateDialogFacet();

    if (isValid) {
      const facetKeyName = decamelize(facetNameValue.en).split(' ').join('_');
      const displayKey = facetKeyName.startsWith('display') ? facetKeyName : `display_${facetKeyName}`;

      let newFrontendInputType = frontendInputType;
      if (isEmpty(frontendInputType) && !isEmpty(basedOnCodes) && !isEmpty(props.facetList)) {
        newFrontendInputType = props.facetList.filter(f => !f.default).find(e => e).displayType;
      }

      const newFacetList = {
        [displayKey]: {
          label: facetNameValue,
          type: 'virtual_facet',
          displayType: newFrontendInputType,
          attributes: basedOnCodes,
          isPromoted,
        },
      };

      if (!isEmpty(basedOnOperatorValue)) {
        newFacetList[displayKey].operator = basedOnOperatorValue;
      }

      if (!isEmpty(basedOnAttributeOptionsValue)) {
        const codes = basedOnAttributeOptionsValue
          .map(label => [...props.initialOptions, ...props.selectedOptions]
            .find(o => o.label.en === label).optionCode);
        newFacetList[displayKey].values = !isEmpty(codes) ? codes : searchValuesOptionCodes;
      }

      const groupedValues = {
        [displayKey]: {
          type: groupedValueListType,
          groups: groupedValueList.map(v => ({
            label: v.label,
            values: v.values.map(i => i.value),
            isPromoted: v.isPromoted,
          })),
        },
      };

      const newFacet = {
        referencedEntityType: props.entityType,
        referencedEntityId: props.entityId,
        status: 'enabled',
        facetsList: newFacetList,
      };

      if (!isEmpty(props.eventName)) newFacet.id = props.selected.id;
      if (!isEmpty(props.eventName)) newFacet.id = props.selected.id;
      if (!isEmpty(groupedValues) && !isEmpty(groupedValueListType) && !isEmpty(groupedValueList)) {
        newFacet.groupedValues = groupedValues;
      }

      const camelizedFacet = camelizeKeys(newFacet);
      if (props.onOk) props.onOk(camelizedFacet);
    }
  };

  const handleNameChange = (data) => {
    resetErrors();
    setFacetNameValue(data);
  };

  const handleAddVirtualValue = () => {
    resetErrors();
    const newVirtualValue = {
      id: new Date().getUTCMilliseconds(),
      type: '',
      valuesDisplayLanguage: '',
      values: [{
        id: new Date().getUTCMilliseconds(),
        value: '',
      }],
      operator: null,
      label: {
        en: '',
      },
    };

    setGroupedValueList([...groupedValueList, newVirtualValue]);

    if (isEmpty(groupedValueListType)) setGroupedValueListType('hierarchy');
  };

  const handleVirtualValueChange = (updatedList) => {
    resetErrors();
    setGroupedValueList(updatedList);
  };

  const handleBasedOnOperatorChange = ({ target }) => {
    resetErrors();
    setBasedOnOperatorValue(target.value);
  };

  const handleBaseOnAttributeChange = ({ target }) => {
    resetErrors();
    setBasedOnValue(target.labels);
    setBasedOnCodes(target.value);
    const mappedOtions = props.basedOn.attributeOptions.map(ao => ({
      ...ao,
      disabled: target.value.includes(ao.value),
    }));
    setBasedOnAttributes(mappedOtions);
    setErrors({});
    if (!isEmpty(target.value) && !isEmpty(props.basedOn.attributeOptions)) {
      const inputType = target.value
        .map(v => props.basedOn.attributeOptions
          .find(a => a.value === v))[0].originalItem.frontendInputType;
      setFrontendInputType(inputType);

      const facetName = { en: '', he: '' };
      target.value.map((val) => {
        props.basedOn.attributeOptions.map((opt) => {
          if (val === opt.value) {
            facetName.en += (facetName.en) ? ', ' + opt.originalItem.label.en : opt.originalItem.label.en;
            facetName.he += (facetName.he) ? ', ' + opt.originalItem.label.he : opt.originalItem.label.he;
          }

          return true;
        });
        
        return true;
      });
      setFacetNameValue(facetName);

    } else {
      setFrontendInputType('');
    }
  };

  const handleBaseOnAttributeOptionsChange = ({ target }, optCodes) => {
    resetErrors();
    if (Array.isArray(optCodes)) {
      setSearchValuesOptionCodes(optCodes);
    }

    setBasedOnAttributeOptionsValue(target.labels);
  };

  const groupedValuesClass = !isEmpty(groupedValueList) ? 'facet-wrapper-secondary' : '';
  const groupedValuesErrorClass = !isEmpty(errors.type) ? 'facet-wrapper-error' : '';

  return (
    <FormDialog
      title={(
        <div className="flex justify-between">
          <Typography variant="h6">
            {
              props.header.useIntl && props.header.title
                ? <IntlMessages id={props.header.title} />
                : props.header.title
            }
          </Typography>
          <IconButton aria-label="close" onClick={handleDialogClose}>
            <Close fontSize="small" />
          </IconButton>
        </div>
      )}
      open={props.open}
      hideCloseButton
      className="dialog-facet-create"
      contentClassName="dialog-content-bordered dialog-content-md dialog-height-md"
      submitButtonTitle="Save"
      maxWidth="md"
      buttons={{
        submit: {
          variant: 'text',
          color: 'primary',
          className: 'btn-default',
          onClick: handleDialogSubmit,
          title: <IntlMessages id="facet.actions.apply.title" />,
        },
        close: {
          onClick: handleDialogClose,
        },
      }}
    >
      {props.fetchInitialAttributesOptionsStart && (
        <div className="loader-cover flex items-center justify-center">
          <CircularProgress
            variant="indeterminate"
            disableShrink
            size={20}
            thickness={4}
          />
        </div>
      )}

      <Controls
        isPromoted={isPromoted}
        onIsPromotedChange={setIsPromoted}
      />

      <Divider className="divider-dashed divider-gap-md" />

      <Name
        label={facetNameValue}
        onChange={handleNameChange}
        errors={errors.en}
        primaryFieldSettings={{
          disabled: !isEmpty(props.selected),
        }}
      />

      <BasedOn
        attributeSettings={{
          options: frontendInputType
            ? basedOnAttributes.filter(o => o.originalItem.frontendInputType === frontendInputType)
            : basedOnAttributes,
          codes: basedOnCodes,
          value: basedOnCodes,
          onChange: handleBaseOnAttributeChange,
          errors: errors.basedOn,
          disabled: !isEmpty(groupedValueList),
        }}
        operatorSettings={{
          value: basedOnOperatorValue,
          onChange: handleBasedOnOperatorChange,
          errors: errors.basedOnOperator,
        }}
        attributeOptionsSettings={{
          disabled: isEmpty(basedOnValue),
          value: basedOnAttributeOptionsValue,
          onChange: handleBaseOnAttributeOptionsChange,
          errors: errors.basedOnOptions,
        }}
      />

      <div className={`facet-grouped-values-type-wrapper facet-group ${groupedValuesClass} ${groupedValuesErrorClass}`}>
        {!isEmpty(groupedValueList) && (
          <>
            <div className="facet-group-inner facet-group facet-group-sm">
              <TooglePaper
                errors={errors.type}
                helper={{
                  text: <IntlMessages id="facet.selectedValue.helperText.title" />,
                  placement: 'right',
                }}
                label={<IntlMessages id="facet.type.toogle.title" />}
                value={groupedValueListType}
                list={[
                  {
                    value: 'hierarchy',
                    label: 'Hierarchy',
                    ariaLabel: 'hierarchy_label',
                  }, {
                    value: 'combined_values',
                    label: 'Combined',
                    ariaLabel: 'combined_label',
                  },
                ]}
                onChange={handleGroupedValueListTypeChange}
              />
            </div>

            <GroupedValues
              list={groupedValueList}
              errors={errors.groupedValues}
              attributeSettings={{
                options: basedOnAttributes,
                codes: basedOnCodes,
                value: basedOnValue,
              }}
              type={groupedValueListType}
              onChange={handleVirtualValueChange}
              isPromoted={isPromoted}
            />
          </>
        )}
        <div className="facet-group">
          <Button
            disableElevation
            variant="contained"
            color="primary"
            disabled={isEmpty(basedOnCodes)}
            onClick={handleAddVirtualValue}
          >
            <IntlMessages id="facet.attribute.addVirtualValue.title" />
          </Button>
          {isEmpty(basedOnCodes) && (
            <FormHelperText className="autocomplete-helper-text">
              <IntlMessages id="facet.facet.groupedFields.disabled.title" />
            </FormHelperText>
          )}
        </div>
      </div>
    </FormDialog>
  );
};

FacetManagementDialog.propTypes = {
  mode: string.isRequired,
  header: shape({
    title: oneOfType([string, node]),
    useIntl: bool,
  }),
  open: bool,
  onClose: func,
  onOk: func,
  basedOn: shape({
    attributeOptions: arrayOf(shape()),
  }),
  selected: shape({
    label: shape({
      en: string,
      he: string,
    }),
  }).isRequired,
  entityId: string,
  entityType: string,
  eventName: string,
  facetList: arrayOf(shape()),

  initialOptions: arrayOf(shape()).isRequired,
  selectedOptions: arrayOf(shape()).isRequired,
  fetchInitialAttributesOptionsFinished: bool.isRequired,
  fetchInitialAttributesOptionsStart: bool.isRequired,
  fetchInitialAttributeOptions: func.isRequired,
  clearInitialAttributeOptions: func.isRequired,
};

FacetManagementDialog.defaultProps = {
  header: {
    title: 'facet.dialog.createNew.title',
    useIntl: false,
  },
  open: false,
  onClose: null,
  onOk: null,
  basedOn: {
    attributeOptions: [],
  },
  entityId: '',
  entityType: 'virtual_facet',
  eventName: '',
  facetList: [],
};

const mapStateToProps = state => ({
  initialOptions: state.virtualFacet.initialOptions,
  selectedOptions: state.virtualFacet.selectedAttributesOptions,
  fetchInitialAttributesOptionsFinished:
    state.virtualFacet.fetchInitialAttributesOptionsFinished,
  fetchInitialAttributesOptionsStart:
    state.virtualFacet.fetchInitialAttributesOptionsStart,
});

const actionCreators = {
  fetchInitialAttributeOptions,
  clearInitialAttributeOptions,
};

export default connect(mapStateToProps, actionCreators)(FacetManagementDialog);
