export const defaultGroups = [
  {
    field: 'is_enabled',
    value: true,
    operator: 'eq',
  }, {
    field: 'type',
    value: 'parent',
    operator: 'eq',
  },
];

export const defaultProductAttribute = {
  code: 'id',
  frontendInputType: 'selectProductsButton',
  id: 'attr_iHeYyv1MRdm1mG',
  isFilterable: true,
  isRequired: false,
  isSearchable: false,
  isSortable: false,
  isVariation: false,
  label: 'Products',
  value: 'id',
};

export const defaultRootFilter = [{
  condition: 'and',
  group: defaultGroups,
}];

export const paginationLimit = 100;

export const defaultFields = ['id', 'price', 'sku', 'title', 'description', 'images', 'specifications', 'scores'];

export const defaultPagination = {
  page: 1,
  limit: paginationLimit,
};

export const defaultMaxPagination = {
  page: 1,
  limit: 25,
};

export const defaultFilterFields = ['price', 'sku', 'title.en.keyword', 'description.en.keyword'];

export const getProductCompoundSearchFilter = query => ({
  value: query,
});

export const getProductFilterGroups = query => defaultFilterFields.map(field => ({
  field,
  value: `*${query}*`,
  operator: 'like',
}));

export const searchPhraseFilter = [{
  condition: 'and',
  group: [
    {
      field: 'type',
      value: 'search_phrase',
      operator: 'eq',
    },
  ],
}];

export const searchPromotionFilter = [{
  condition: 'and',
  group: [
    {
      field: 'type',
      value: 'promotion_url',
      operator: 'eq',
    },
  ],
}];
