import React from 'react';
import { connect } from 'react-redux';
import {
  string, shape, oneOfType, func, arrayOf, object,
} from 'prop-types';
import { isEmpty, uniq, uniqBy } from 'lodash';

import { FormHelperText } from '@material-ui/core';

import AutoComplete from 'components/AutoComplete';
import AutoCompleteMultiple from 'components/AutoComplete/Multiple';
import IntlMessages from 'util/IntlMessages';
import operators from 'util/constants/operators';

import {
  searchAttributeOptions,
  clearFoundAttributeOptions,
  setSelectedAttributesOptions,
} from 'actions/virtualFacet';
import renderHelper from '../utils/renderHelper';

const getBasedOnOptions = (options = [], selectedAttributes = []) => {
  const currentBasedOnOptions = selectedAttributes.map(
    o => options.filter(sa => sa.attributeCode === o),
  ).flat();
  const autocompleteOptions = uniq([...currentBasedOnOptions]).filter(e => e);
  return !isEmpty(autocompleteOptions)
    ? autocompleteOptions.map((o) => {
      const optionLabel = o.label && o.label.en ? o.label.en : o.label;
      return ({
        label: optionLabel,
        value: optionLabel,
      });
    })
    : [];
};


const BasedOn = (props) => {
  let timeout = null;

  const handleBasedQueryChange = () => { };

  const handleAttributeOptionsQueryChange = (query) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      props.searchAttributeOptions(props.attributeSettings.codes, query);
    }, 100);
  };

  const handleAttributeOptionsChange = (data) => {
    const selectedOptions = props.foundOptions.filter(
      fo => data.target.labels.includes(fo.label.en),
    );

    const optCodes = props.attributeOptionsSettings
      .onChange(data, selectedOptions.map(s => s.optionCode));
    props.attributeOptionsSettings.onChange(data, optCodes);
    props.setSelectedAttributesOptions(selectedOptions);
  };

  const handleMultipleAutocompleteBlur = () => props.clearFoundAttributeOptions();

  const availableOperators = operators.filter(go => go.availableFor.includes('createFacet'));

  const attributeOptionList = uniqBy([...props.selectedAttributesOptions, ...props.foundOptions, ...props.initialOptions], 'optionCode');
  const hasError = !isEmpty(props.attributeSettings.errors);
  const disabledClass = props.attributeSettings.disabled ? 'field-disabled' : '';

  return (
    <div className="facet-attribute-wrapper facet-group facet-group-inline">
      <div className="facet-group-inner">
        <AutoCompleteMultiple
          error={hasError}
          helperText={hasError ? renderHelper(props.attributeSettings.errors) : ''}
          onBlur={handleMultipleAutocompleteBlur}
          className={`autocomplete-select-control-wrapper autocomplete-value-xs select-product-attribute-wrapper ${disabledClass}`}
          label={<IntlMessages id="facet.attribute.basedOn.title" />}
          name="basedOn"
          isDisabled={props.attributeSettings.disabled}
          value={props.attributeSettings.value}
          inputValue={props.attributeSettings.query}
          closeMenuOnSelect={false}
          options={props.attributeSettings.options.map(e => ({ ...e, label: `${e.value} (${e.label})` }))}
          onChange={props.attributeSettings.onChange}
          handleSearchOptions={handleBasedQueryChange}
          useAdvancedOptions
        />
        {props.attributeSettings.disabled && (
          <FormHelperText className="autocomplete-helper-text">
            <IntlMessages id="facet.attribute.basedOn.disabled.title" />
          </FormHelperText>
        )}
      </div>
      <div className="facet-group-inner facet-group-inner-sm">
        <AutoComplete
          useAdvancedOptions
          className="select-autocomplete-wrapper"
          label={<IntlMessages id="facet.attribute.operator.title" />}
          name="operator"
          inputProps={{
            name: 'operator',
          }}
          value={availableOperators.find(ao => ao.value === props.operatorSettings.value)}
          options={availableOperators.map(o => ({
            label: o.label,
            value: o.value,
            disabled: false,
          }))}
          hideStaticLabel
          onChange={props.operatorSettings.onChange}
          disabled={props.operatorSettings.disabled}
          error={!isEmpty(props.operatorSettings.errors)}
          helperText={!isEmpty(props.operatorSettings.errors) ? renderHelper(props.operatorSettings.errors) : ''}
        />
      </div>
      <div className="facet-group-inner">
        <div className="facet-group-inner">
          <AutoCompleteMultiple
            onBlur={handleMultipleAutocompleteBlur}
            className="autocomplete-select-control-wrapper autocomplete-value-xs select-product-attribute-wrapper"
            label={<IntlMessages id="facet.attribute.serachValue.title" />}
            name="attributeOption"
            disabled={props.attributeOptionsSettings.disabled}
            value={props.attributeOptionsSettings.value}
            inputValue={props.attributeOptionsSettings.query}
            closeMenuOnSelect={false}
            options={getBasedOnOptions(attributeOptionList, props.attributeSettings.value)}
            onChange={handleAttributeOptionsChange}
            handleSearchOptions={handleAttributeOptionsQueryChange}
            error={!isEmpty(props.attributeOptionsSettings.errors)}
            helperText={
              !isEmpty(props.attributeOptionsSettings.errors)
                ? renderHelper(props.attributeOptionsSettings.errors)
                : ''
            }
          />
        </div>
      </div>
    </div>
  );
};

BasedOn.propTypes = {
  attributeSettings: shape({
    query: string,
    errors: arrayOf(object),
    value: arrayOf(oneOfType([string, object])),
    codes: arrayOf(oneOfType([string, object])),
    options: arrayOf(shape({
      value: string,
      label: oneOfType([string, object]),
    })),
  }),
  operatorSettings: shape({
    query: string,
    value: oneOfType([string, object]),
    options: arrayOf(shape({
      value: string,
      label: string,
    })),
  }),
  attributeOptionsSettings: shape({
    query: string,
    value: arrayOf(oneOfType([string, object])),
    options: arrayOf(shape({
      value: string,
      label: string,
    })),
  }),
  onChange: func,

  initialOptions: arrayOf(shape()).isRequired,
  foundOptions: arrayOf(shape()).isRequired,

  searchAttributeOptions: func.isRequired,
  clearFoundAttributeOptions: func.isRequired,
  selectedAttributesOptions: arrayOf(shape()).isRequired,
  setSelectedAttributesOptions: func.isRequired,
};

BasedOn.defaultProps = {
  attributeSettings: {},
  operatorSettings: {
    value: null,
  },
  attributeOptionsSettings: {},
  onChange: null,
};

const mapStateToProps = state => ({
  initialOptions: state.virtualFacet.initialOptions,
  foundOptions: state.virtualFacet.foundOptions,
  selectedAttributesOptions: state.virtualFacet.selectedAttributesOptions,
  fetchInitialAttributesOptionsFinished:
    state.virtualFacet.fetchInitialAttributesOptionsFinished,
  fetchInitialAttributesOptionsStart:
    state.virtualFacet.fetchInitialAttributesOptionsStart,
});

const actionCreators = {
  searchAttributeOptions,
  clearFoundAttributeOptions,
  setSelectedAttributesOptions,
};


export default connect(mapStateToProps, actionCreators)(BasedOn);
