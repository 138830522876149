export default (value, fieldName) => {
  switch (fieldName) {
    case 'status':
      return value;

    case 'qty':
      return Math.abs(Math.round(value));

    case 'finalPrice':
      return +value <= 0 ? 1 : +value;

    case 'minPrice':
      return +value <= 0 ? 1 : +value;

    default:
      return +value < 0 ? 0 : +value;
  }
};
