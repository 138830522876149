export const getSlicedList = (options, limit, showHint = true) => {
  const newOptions = !limit && limit > options.length
    ? options
    : options.slice(0, limit);

  if (limit < options.length && !newOptions.some(o => o.value === 'hasMoreOptions') && showHint) {
    const hasMoreOptions = {
      value: 'hasMoreOptions',
      label: 'More values are available - use search',
      isDisabled: true,
      disabled: true,
    };
    newOptions.push(hasMoreOptions);
    return newOptions;
  }

  return newOptions;
};

export const getGroupedOptions = (options, limit) => {
  const result = [
    {
      label: '',
      options: getSlicedList(options, limit, false),
    },
  ];

  if (limit < options.length) {
    result.push({
      label: '',
      options: [{
        value: 'hasMoreOptions',
        label: 'More values are available - use search',
        isDisabled: true,
      }],
    });
  }
  return result;
};

export default {
  getGroupedOptions,
  getSlicedList,
};
