import { createSelector } from 'reselect';

const previewRootSelector = state => state;

const stateSelector = createSelector(
  previewRootSelector,
  previewCategory => ({
    virtualCategoryItem: previewCategory.item,
    filterCategory: previewCategory.filter.category,
    facetCategory: previewCategory.facet,
    virtualCategoryFetched: previewCategory.fetchedOne,
    virtualCategoryUpdated: previewCategory.updated,
  }),
);

export const previewCategorySelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  previewCategorySelector,
};
