import { createSelector } from 'reselect';

const pimRootSelector = state => state;

const stateSelector = createSelector(
  pimRootSelector,
  state => ({
    selectedAction: state.selectedAction,
    containerStyles: state.containerStyles,
    selectedProducts: state.selected,
    globalFields: state.globalFields,
    savedFilterFields: state.savedFilterFields,
    filterTarget: state.filterTarget,
  }),
);

export const pimSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  pimSelector,
};
