import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import {
  string, shape, arrayOf, bool, func, number,
} from 'prop-types';

import ListVertical from 'components/List/ListVertical';

import usePromotion from './hooks/usePromotion';
import { getEditCategoryPath } from './utils/paths';
import { mapStateToProps, mapDispatchToProps } from './connect';
import { getSearchPromotionsQuery, paginationLimit } from "../../pages/utils/queries";
import useStateStore from "../../pages/Navigation/hooks/useState";
import SearchForm from "../../components/Header/SearchForm";

const PromotionWidget = (props) => {
  const [currentPromotionPage, setCurrentPromotionPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');

  const state = useStateStore();
  usePromotion(props, paginationLimit);

  const onPromotionSelect = (promotion) => {
    if (props.useRedirect) {
      props.history.push(getEditCategoryPath(promotion.id));
    }

    const currentPropmotion = !isEmpty(props.promotion.list)
      ? props.promotion.list.find(item => item.id === promotion.id)
      : {};

    props.setVirtualFacetFilter({});
    props.setSelectedPromotion({
      ...currentPropmotion,
      ...promotion,
    });
  };

  const handleGetPrevPage = (event) => {
    const { value } = event.target;
    state.setValue(value);

    setCurrentPromotionPage(currentPromotionPage - 1);
    const { filter } = getSearchPromotionsQuery(value);

    const currentPagination = {
      page: currentPromotionPage - 1,
      limit: paginationLimit,
    };
    props.fetchPromotionList(filter, currentPagination);
  };

  const handleGetNextPage = (event) => {
    const { value } = event.target;
    state.setValue(value);

    setCurrentPromotionPage(currentPromotionPage + 1);
    const { filter } = getSearchPromotionsQuery(value);

    const currentPagination = {
      page: currentPromotionPage + 1,
      limit: paginationLimit,
    };
    props.fetchPromotionList(filter, currentPagination);
  };

  const pagination = {
    promotion: {
      allPages: props.promotion.allPages,
      currentPage: currentPromotionPage
    },
    paginationLimit: paginationLimit
  };

  const handleSearchChange = (e) => {
    const { target } = e;
    setSearchValue(target.value);

    setCurrentPromotionPage(1);
    const {filter} = getSearchPromotionsQuery(target.value);
    if (target.value && filter[0] && filter[0].group) {
      filter[0].group.push({
        field: 'name',
        operator: 'like',
        value: '*' + target.value + '*',
      });
    }

    const currentPagination = {
      page: 1,
      limit: paginationLimit,
    };
    props.fetchPromotionList(filter, currentPagination);
  };

  return (
    <div>
      <div className="sidebar-sticky-block" style={{padding: '5px'}}>
        <SearchForm
          value={searchValue}
          onChange={e => handleSearchChange(e)}
          className="block"
        />
      </div>
      <div className="list-vertical-wrapper">
        <ListVertical
          gap={8}
          className="item-dotted"
          onClick={onPromotionSelect}
          titleId="text.promotionFilters"
          pagination={pagination}
          handleGetPrevPage={handleGetPrevPage}
          handleGetNextPage={handleGetNextPage}
          hideDivider
          list={!isEmpty(props.promotion.list)
            ? props.promotion.list.map(l => ({
              id: l.id,
              label: l.name,
              isActive: l.id === props.promotion.selectedPromotionId,
            }))
            : []}
        />
      </div>
    </div>
  );
};

PromotionWidget.propTypes = {
  history: shape().isRequired,
  useRedirect: bool,
  promotion: shape({
    fetched: bool,
    list: arrayOf(shape({
      id: string,
    })),
    allPages: number,
  }).isRequired,

  setVirtualFacetFilter: func.isRequired,
  setSelectedPromotion: func.isRequired,
  clearPromotionState: func.isRequired,
};

PromotionWidget.defaultProps = {
  useRedirect: false,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromotionWidget));
