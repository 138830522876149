/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { shape, func, bool } from 'prop-types';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardActionArea, CardActions, CardContent, Button, Typography,
} from '@material-ui/core';

import validateAll from '../../../../util/validator';
import IntlMessages from '../../../../util/IntlMessages';
import { textFieldsValidations, validateFilters } from '../../utils/validations';
import InfluencersContainer from '../index';

import {
  updateInfluencer,
  uploadAvatar,
  uploadMainBanner,
  uploadDealBanner,
  fetchInfluencer,
  setTextFieldsErrors,
  updateInfluencerFields,
} from '../../../../actions/influencer';

import {
  createTuneOffer,
  createTuneGoal,
  generateTuneTrackingUrls,
} from '../../../../actions/tune';

import {
  fetchInitialAttributeOptions,
} from '../../../../actions/productAttribute';

import {
  systemAppPath,
} from '../../../../util/paths';

import useTuneEffects from './hooks/useTune';
import useInfluencerEffects from './hooks/useInfluencer';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

const InfluencerEdit = memo((props) => {
  const classes = useStyles();
  const {
    influencer, avatarData, mainBannerData, dealBannerData,
    tuneSettings,
  } = props;

  useTuneEffects(props);
  useInfluencerEffects(props);

  useEffect(() => {
    props.fetchInfluencer(props.match.params.id);
  }, []);

  const handleCreateTuneOffer = () => {
    props.createTuneOffer({
      data: {
        name: `${influencer.page.storeName} offer`,
        previewUrl: `${influencer.page.storeLink}/offer`,
        offerUrl: `${influencer.page.storeLink}?affiliate_id={affiliate_id}&offer_id={offer_id}`,
        defaultGoalName: `${influencer.page.storeName} goal`,
        advertiserId: tuneSettings.offer.advertiserId,
        revenueType: tuneSettings.offer.revenueType,
        payoutType: tuneSettings.offer.payoutType,
        percentPayout: tuneSettings.offer.percentPayout,
        maxPercentPayout: tuneSettings.offer.maxPercentPayout,
        expirationDate: tuneSettings.offer.expirationDate,
        protocol: tuneSettings.offer.protocol,
        hasGoalsEnabled: tuneSettings.offer.hasGoalsEnabled,
      },
    });
  };

  const validateInfluencer = () => {
    const textFieldErrors = validateAll(textFieldsValidations, {
      storeName: influencer.page.storeName,
      name: influencer.name,
      dealName: influencer.page.deal.name,
    });

    const topTenFiltersErrors = validateFilters(influencer.page.topTen.filters);
    const dealFiltersErrors = validateFilters(influencer.page.deal.filters);
    const productSectionFiltersErrors = validateFilters(influencer.page.productSection.filters);
    const filtersWithErrors = (
      topTenFiltersErrors.some(f => f.errors)
      || dealFiltersErrors.some(f => f.errors)
      || productSectionFiltersErrors.some(f => f.errors)
    );

    props.updateInfluencerFields({
      ...influencer,
      page: {
        ...influencer.page,
        topTen: {
          ...influencer.page.topTen,
          filters: topTenFiltersErrors,
        },
        deal: {
          ...influencer.page.deal,
          filters: dealFiltersErrors,
        },
        productSection: {
          ...influencer.page.productSection,
          filters: productSectionFiltersErrors,
        },
      },
    });

    props.setTextFieldsErrors(textFieldErrors);

    return { textFieldErrors, filtersWithErrors };
  };

  const handleSubmitChanges = () => {
    const { textFieldErrors, filtersWithErrors } = validateInfluencer();

    if (!isEmpty(textFieldErrors) || filtersWithErrors) return;


    if (isEmpty(influencer.tuneOffer)) {
      handleCreateTuneOffer();
    } else {
      props.updateInfluencer(influencer);
    }

    if (!isEmpty(avatarData)) {
      props.uploadAvatar({
        nameWithPath: avatarData.nameWithPath,
        url: avatarData.url,
        influencerId: influencer.id,
      });
    }

    if (!isEmpty(mainBannerData)) {
      props.uploadMainBanner({
        nameWithPath: mainBannerData.nameWithPath,
        url: mainBannerData.url,
        influencerId: influencer.id,
      });
    }

    if (!isEmpty(dealBannerData)) {
      props.uploadDealBanner({
        nameWithPath: dealBannerData.nameWithPath,
        url: dealBannerData.url,
        influencerId: influencer.id,
      });
    }
  };

  const handleClosePreview = () => {
    props.history.push(`${systemAppPath}/users`);
  };

  if (props.influencerFetched && isEmpty(props.influencer)) {
    return (
      <div className="influencer-no-content">
        <Card className={`${classes.root} card`}>
          <CardActionArea>
            <CardContent className="card-content">
              <i className="ti-face-sad text-primary text-lg" />
              <Typography gutterBottom variant="h5" component="h2">
                <IntlMessages id="influencer.nocontent.title" />
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <IntlMessages id="influencer.nocontent.body" />
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions className="card-actions">
            <Button size="small" color="primary" onClick={handleClosePreview}>
              Go to the system page
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }

  return (
    <InfluencersContainer
      className="container-influencer edit"
      contentContainerClassName="influencers-content"
      handleSubmitChanges={handleSubmitChanges}
    />
  );
});

InfluencerEdit.propTypes = {
  influencer: shape().isRequired,
  avatarData: shape().isRequired,
  mainBannerData: shape().isRequired,
  dealBannerData: shape().isRequired,
  tuneSettings: shape().isRequired,
  match: shape().isRequired,
  history: shape().isRequired,
  uploadMainBanner: func.isRequired,
  updateInfluencer: func.isRequired,
  uploadAvatar: func.isRequired,
  uploadDealBanner: func.isRequired,
  createTuneOffer: func.isRequired,
  fetchInfluencer: func.isRequired,
  setTextFieldsErrors: func.isRequired,
  updateInfluencerFields: func.isRequired,
  influencerFetched: bool.isRequired,
};

const mapStateToProps = state => ({
  influencer: state.influencer.item,
  avatarData: state.influencer.avatarData,
  mainBannerData: state.influencer.mainBannerData,
  dealBannerData: state.influencer.dealBannerData,
  tuneSettings: state.tune.settings,
  createdOffer: state.tune.createdOffer,
  createdGoal: state.tune.createdGoal,
  offerCreated: state.tune.offerCreated,
  goalCreated: state.tune.goalCreated,
  trackingGenerated: state.tune.trackingGenerated,
  generatedTrackingUrls: state.tune.generatedTrackingUrls,
  createdAffiliate: state.tune.createdAffiliate,
  influencerUpdated: state.influencer.updated,
  influencerFetched: state.influencer.fetchedOne,
});

const actionCreators = {
  updateInfluencer,
  uploadAvatar,
  uploadMainBanner,
  uploadDealBanner,
  createTuneOffer,
  createTuneGoal,
  generateTuneTrackingUrls,
  fetchInfluencer,
  setTextFieldsErrors,
  updateInfluencerFields,
  fetchInitialAttributeOptions,
};

export default connect(mapStateToProps, actionCreators)(InfluencerEdit);
