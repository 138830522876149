import React from 'react';
import {
  bool, func, node, string,
} from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import Field from '../Field';
import IntlMessages from '../../util/IntlMessages';
import operators from '../../util/constants/operators';

const DefaultRow = (props) => {
  const onOperatorSelect = (event, element) => {
    props.onOperatorSelect({
      event,
      element,
      rowIndex: 0,
    });
  };

  return (
    <div className={`flex form-group-m filter-attributes-row mt-15 ml-0 mr-0 ${props.className}`}>
      <div className="form-group block ml-0">
        <Field
          element="autocomplete"
          className="select-autocomplete-wrapper"
          label="Attribute code"
          name="field"
          inputProps={{
            name: 'field',
            id: 'attributeCode-select',
          }}
          disabled
          value={{ value: 'categories', label: 'Categories' }}
          options={[{ value: 'categories', label: 'Categories' }]}
          hideStaticLabel
        />
      </div>
      <div className="form-group block">
        <Field
          element="autocomplete"
          className="select-autocomplete-wrapper"
          label="Operator"
          name="operator"
          inputProps={{
            name: 'operator',
            id: 'operator-select',
          }}
          disabled
          value={{ value: 'eq', label: 'Equal' }}
          options={operators || []}
          hideStaticLabel
          onChange={onOperatorSelect}
        />
      </div>
      {props.selectCategoriesButton}
      <Field
        element="icon-button"
        className={`btn-mui-sm ${props.isSwitchOn ? 'text-success' : 'text-default'}`}
        groupclass="flex-0 ml-0"
        disabled={props.disabled}
        tooltip={{
          title: props.isSwitchOn
            ? <IntlMessages id="popover.categoryAffect" />
            : <IntlMessages id="popoverAffect.categoryDoNotAffect" />,
          placement: 'bottom-end',
        }}
        icon={(
          <FontAwesomeIcon
            size="lg"
            icon={props.isSwitchOn ? faToggleOn : faToggleOff}
          />
        )}
        onClick={props.onSwitchClick}
      />
    </div>
  );
};

DefaultRow.propTypes = {
  isSwitchOn: bool,
  selectCategoriesButton: node,
  onSwitchClick: func,
  onOperatorSelect: func,
  className: string,
  disabled: bool,
};

DefaultRow.defaultProps = {
  isSwitchOn: false,
  selectCategoriesButton: null,
  onSwitchClick: false,
  onOperatorSelect: null,
  className: '',
  disabled: false,
};

export default DefaultRow;
