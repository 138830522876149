import React, { useState } from 'react';
import {
  string, shape, arrayOf, func,
} from 'prop-types';
import {
  Collapse, List, ListItem, ListItemIcon, Checkbox, ListItemText,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const CollapsibleList = (props) => {
  const [open, setOpen] = useState(false);
  const {
    options, title, onChange,
  } = props;

  const handleClick = () => setOpen(!open);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItem button onClick={handleClick}>
        <ListItemText className="combined-facet-group-title" primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" className="facet-options-list" disablePadding>
          {options.map((o) => {
            const handleChange = () => onChange(o.value);

            return (
              <ListItem key={o.key} button onClick={handleChange}>
                <ListItemIcon>
                  <Checkbox
                    edge="end"
                    checked={o.checked}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={o.label} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};

CollapsibleList.propTypes = {
  options: arrayOf(shape()).isRequired,
  title: string.isRequired,
  onChange: func.isRequired,
};

CollapsibleList.defaultProps = {};

export default CollapsibleList;
