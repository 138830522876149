import React from 'react';
import { isEmpty } from 'lodash';
import IntlMessages from "../../../util/IntlMessages";
import Pagination from '../../../components/Pagination';
import noimage from '../../../assets/img/user-no-image.png';

export const Review = ({response, getProductReviews, productId}) => {

  const Scores = ({score, createdAt}) => {
    const date = new Date(createdAt);

    return (
      <div style={{position: 'relative'}}>
        <div className="reviews-score">
          {score >= 1 ? (<>&#9733;</>) : (<>&#9734;</>) }
          {score >= 2 ? (<>&#9733;</>) : (<>&#9734;</>) }
          {score >= 3 ? (<>&#9733;</>) : (<>&#9734;</>) }
          {score >= 4 ? (<>&#9733;</>) : (<>&#9734;</>) }
          {score >= 5 ? (<>&#9733;</>) : (<>&#9734;</>) }
        </div>
        <div className="reviews-created">
          {
            date.toLocaleString('en-US', { month: 'long' }) + ' ' +
            date.getDate() + ', ' +
            date.getFullYear()
          }
        </div>
      </div>
    );
  };

  const Stars = ({number, item, count}) => {
    const width = item / count * 100;

    return (
      <>
        <div className="star-title">
          <span className="reviews-score"><span className="reviews-score-number">{5 - number}</span>&nbsp;&#9733;</span>
        </div>
        <div className="star-content">
          <div className="star-content-filled" style={{width: width}}>&nbsp;</div>
          <div className="star-content-blank" style={{width: 100 - width}}>&nbsp;</div>
        </div>
        <div className="star-result">
          &nbsp;&nbsp;
          ({item})
        </div>
        <div className="clear-both" />
      </>
    );
  };

  const Summary = ({score, count, stars}) => {
    return (
      <div className="reviews-summary">
        <div className="summary-score-title"><IntlMessages id="components.summary" /></div>
        <div className="summary-score">{Math.round(score)}</div>
        <div className="summary-count"><IntlMessages id="reviews.outOf" /> 5</div>
        <div className="summary-scores">
          {score >= 1 ? (<>&#9733;</>) : (<>&#9734;</>) }
          {score >= 2 ? (<>&#9733;</>) : (<>&#9734;</>) }
          {score >= 3 ? (<>&#9733;</>) : (<>&#9734;</>) }
          {score >= 4 ? (<>&#9733;</>) : (<>&#9734;</>) }
          {score >= 5 ? (<>&#9733;</>) : (<>&#9734;</>) }
        </div>
        <div className="summary-count-all">{count} Ratings</div>
        <div className="stars-container">
          {stars.reverse().map((item, itemKey) => (
            <Stars
              key={'star-' + itemKey}
              item={item}
              number={itemKey}
              stars={stars}
              count={count}
            />
          ))}
        </div>
      </div>
    );
  };

  const ResponseItem = ({reviews, item}) => {
    const review = reviews[item];
    if (!review) {
      return (<></>);
    }

    return (
      <div className="container" style={{paddingTop: '10px'}}>
        <div className="row">
          <div className="col-1" style={{padding: 0}}>
            {review.user.social_image ? (
              <img className="reviews-image" src={review.user.social_image} alt={review.user.display_name} />
            ) : (
              <img className="reviews-image" src={noimage} alt={review.user.display_name} />
            )}
            {review.verified_buyer && (
              <div style={{width: '-webkit-fill-available'}}>
                <div className="reviews-circle-checkmark">&nbsp;&#10004;</div>
              </div>
            )}
          </div>
          <div className="col-9">
            <div className="reviews-name">
              <strong>{review.user.display_name}</strong>
              {review.verified_buyer === true && (
                <span className="verified-buyer">
                  &nbsp;
                  (<IntlMessages id="reviews.verifiedBuyer" />)
                </span>
              )}
            </div>
            <Scores score={review.score} createdAt={review.created_at} />
          </div>
          <div className="col-2">
            <div className="votes">
              <div className="votes-down">
                <span role="img" aria-label="donut" className="votes-emoji">&#128078;</span>
                <br/>
                {review.votes_down}
              </div>
            </div>
            <div className="votes">
              <div className="votes-up">
                <span role="img" aria-label="donut" className="votes-emoji">&#128077;</span>
                <br/>
                {review.votes_up}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-1" style={{borderBottom: '1px solid #e6e6e6'}}>&nbsp;</div>
          <div className="col-11" style={{borderBottom: '1px solid #e6e6e6'}}>
            <div className="reviews-content">
              <strong>{review.title}</strong>
              <div>{review.content}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!isEmpty(response.reviews)) {
    const stars = [];
    if (response.bottomline) {
      const starKeys = Object.keys(response.bottomline.star_distribution);
      for (let i = 0 ; i < starKeys.length; i++) {
        stars[starKeys[i]] = response.bottomline.star_distribution[starKeys[i]];
      }
    }

    const scroll = {
      offset: response.pagination.page,
      total: response.pagination.total,
    };

    return (
      <div className="reviews-container">
        <div className="row">
          <div className="col-9">
            <div className="reviews-content">
              {Object.keys(response.reviews).map((item, itemKey) => (
                <ResponseItem
                  key={'response-' + itemKey}
                  classNaqme="mapper-no-wrap"
                  item={item}
                  reviews={response.reviews}
                />
              ))}
            </div>
          </div>
          <div className="col-3">
            {response.bottomline && (
              <Summary
                score={response.bottomline.average_score}
                count={response.bottomline.total_review}
                stars={stars}
              />
            )}
          </div>
        </div>
        {response.pagination.total > response.pagination.per_page && (
          <Pagination
            getData={getProductReviews}
            dataCount={response.pagination.total}
            itemsPerPage={response.pagination.per_page}
            allPages={Math.ceil(response.pagination.total / response.pagination.per_page)}
            currentPage={response.pagination.page}
            scroll={scroll}
            condition={{}}
            type="yotpo"
            productId={productId}
          />
        )}
      </div>
    )
  } else {
    return (
      <div className="comments no-comments">
        <IntlMessages id="preview.list.productReviews.noReviews" />
      </div>
    );
  }
}

export default Review;
