import React from 'react';
import {
  shape,
} from 'prop-types';
import { isEmpty, uniq } from 'lodash';
import { Tooltip } from '@material-ui/core';

import Field from '../../../../../components/Field';

import { actions } from '../../../utils/actionBarConsts';
import IntlMessages from '../../../../../util/IntlMessages';

const ActionBarForm = (props) => {
  let timeout = null;
  const {
    attributeErrors, selectedAction, actionOptionsList, attribute, availableAttributeList,
    loading, errors, pimModeAttributes, foundOptions, attributeValue,
    searchAttributeOptionsStart, fetchingAttributeOptions, commentMessagesOptions,
    systemComment, comment,
  } = props.formProps;

  const {
    setSelectedAction, clearFoundAttributeOptions, handleChangeAttribute, setAttributeValue,
    setSystemComment, setComment, searchAttributeOptions,
  } = props.actions;

  const handleChangeAttributeValue = (e) => {
    if (attribute.elementType === 'number') {
      setAttributeValue(+e.target.value);
    } else {
      setAttributeValue(e.target.value);
    }
  };

  const handleAutocompleteChange = (query) => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      const name = attribute.value;
      searchAttributeOptions(name, query);
    }, 100);
  };

  const getAttributeOptions = (currentAtribute) => {
    const globalAttribute = pimModeAttributes
      .find(a => a.code === currentAtribute.value);

    if (globalAttribute && !isEmpty(globalAttribute.options) && currentAtribute.elementType === 'select') {
      return globalAttribute.options;
    }


    const autocompleteOptions = uniq([...foundOptions]);
    return !isEmpty(autocompleteOptions)
      ? autocompleteOptions.map((o) => {
        const optionLabel = o.label && o.label.en ? o.label.en : o.label;
        return ({
          label: optionLabel,
          value: o.optionCode,
        });
      })
      : [];
  };

  const handleChangeSystemComment = e => setSystemComment(e.target.value);

  const handleChangeComment = e => setComment(e.target.value);

  const renderAttributeError = () => (
    <Tooltip
      placement="top-end"
      title={(
        <div className="tooltip-title-content-wrapper">
          {
            attributeErrors && !isEmpty(attributeErrors) && attributeErrors.attributeValue
              ? <IntlMessages id={attributeErrors.attributeValue[0].message} />
              : null
          }
        </div>
      )}
    >
      <i className="ti-info-alt" />
    </Tooltip>
  );

  const actionValue = actionOptionsList.find(o => o.value === selectedAction);

  const actionErrorClass = !isEmpty(attributeErrors) && attributeErrors.attribute ? 'has-errors' : '';
  const attributeErrorClass = !isEmpty(attributeErrors) && attributeErrors.attribute ? 'has-error' : '';
  const textFieldErrorClassName = !isEmpty(attributeErrors) && attributeErrors.attributeValue ? 'has-error' : '';
  const textFieldETypeClassName = attribute.elementType === 'text' ? 'input-text' : '';
  const commentErrorClass = !isEmpty(errors.comment) ? 'has-error input-padding-clear' : '';
  const systemCommentErrorClass = !isEmpty(errors.systemComment) ? 'has-error input-padding-clear' : '';

  const attributeOptionsList = availableAttributeList.map(o => ({
    label: o.label,
    value: o.value,
  }));

  const selectedAttributeValue = attribute ? {
    label: attribute.label,
    value: attribute.value,
  } : '';

  const isTextField = attribute
    && attribute.value
    && (
      attribute.elementType.startsWith('text')
      || attribute.elementType === 'text'
    );

  const isSelect = attribute
    && attribute.value
    && (
      attribute.elementType.startsWith('dropdown')
      || attribute.elementType === 'select'
    );

  const selected = attribute.options
    ? attribute.options.find(o => o.value === attributeValue)
    : {};

  const attributeSelectedValue = !isEmpty(selected)
    ? { label: selected.label, value: selected.value }
    : attributeValue;

  const handleActionChange = e => setSelectedAction(e.target.value);

  return (
    <div className="form-wrapper form-inline">
      <Field
        element="autocomplete"
        onChange={handleActionChange}
        onBlur={clearFoundAttributeOptions}
        options={actionOptionsList}
        value={actionValue || ''}
        className={`select-wrapper select-bordered top-oriented min-w-sm form-group block ml-0 ${actionErrorClass}`}
        hideStaticLabel
        containerClassName="action-bar-autocomplete"
        searchable
        menuPlacement="top"
        emptyPlaceholder="Action"
        name="action"
        key="action"
        label="Action"
        inputProps={{
          name: 'action',
          id: 'action-select',
        }}
      />
      {
        selectedAction && selectedAction === actions.edit && (
          <Field
            type={attribute.elementType}
            element="autocomplete"
            onChange={handleChangeAttribute}
            options={attributeOptionsList}
            value={selectedAttributeValue}
            className={`select-wrapper select-bordered top-oriented min-w-sm form-group block ml-0 ${attributeErrorClass}`}
            hideStaticLabel
            containerClassName="action-bar-autocomplete"
            useAdvancedOptions
            searchable
            onBlur={clearFoundAttributeOptions}
            menuPlacement="top"
            emptyPlaceholder="Attribute"
            name="attribute"
            key="attribute"
            label="Attribute"
            inputProps={{
              name: 'attribute',
              id: 'attribute-name-select',
            }}
            loading={loading}
            disabled={loading}
            loaderClassName="background-gray"
            helperText={
              attributeErrors && !isEmpty(attributeErrors) && attributeErrors.attribute
                ? <IntlMessages id={attributeErrors.attribute[0].message} />
                : null
            }
            errors={errors && !isEmpty(attributeErrors) ? attributeErrors.attribute : {}}
          />
        )
      }
      {
        isSelect && (
          <Field
            type={attribute.elementType}
            element={attribute.elementType.startsWith('dropdown') || attribute.elementType === 'select'
              ? 'autocomplete'
              : attribute.elementType}
            onChange={handleChangeAttributeValue}
            handleSearchOptions={handleAutocompleteChange}
            options={getAttributeOptions(attribute)}
            value={
              getAttributeOptions(attribute)
                .find(
                  fo => fo.value === attributeSelectedValue
                  || fo.value === attributeSelectedValue.value,
                )
            }
            loadOptions={searchAttributeOptionsStart}
            className={
              `select-wrapper select-bordered action-bar-input action-bar-autocomplete top-oriented min-w-sm form-group ml-0
              ${!isEmpty(attributeErrors) && attributeErrors.attributeValue ? 'has-error' : ''}
              ${attribute.elementType === 'text' ? 'input-text' : ''}
            `}
            wrapperClassName="action-bar-text"
            hideStaticLabel
            containerClassName="action-bar-autocomplete"
            searchable
            menuPlacement="top"
            emptyPlaceholder="Attribute"
            name="Attribute value"
            key="attribute-value"
            label="Attribute value"
            inputProps={{
              name: 'Attribute value',
              id: 'attribute-value-select',
            }}
            loading={fetchingAttributeOptions}
            disabled={fetchingAttributeOptions}
            loaderClassName="background-gray"
            suffix={!isEmpty(errors.comment) && renderAttributeError()}
          />
        )
      }
      {
        isTextField && (
          <Field
            wrapperClassName={`select-bordered action-bar-input text-input-lg action-bar-autocomplete form-group ml-0 ${textFieldErrorClassName} ${textFieldETypeClassName}`}
            type={attribute.elementType}
            element={attribute.elementType}
            onChange={handleChangeAttributeValue}
            value={attributeSelectedValue}
            emptyPlaceholder="Attribute"
            name="Attribute value"
            key="attribute-value"
            label="Attribute value"
            suffix={!isEmpty(errors.comment) && renderAttributeError()}
          />
        )
      }
      {
        selectedAction && ([
          <Field
            type={attribute.elementType}
            element="autocomplete"
            onChange={handleChangeSystemComment}
            options={commentMessagesOptions}
            value={systemComment
              ? { label: systemComment, value: systemComment }
              : ''}
            className={
              `select-wrapper select-bordered action-bar-input top-oriented min-w-sm form-group block ml-0 ${systemCommentErrorClass}
            `}
            wrapperClassName="action-bar-text"
            hideStaticLabel
            containerClassName="action-bar-autocomplete"
            searchable
            menuPlacement="top"
            emptyPlaceholder="System comment"
            name="System comment"
            key="system-comment"
            label="System comment"
            inputProps={{
              name: 'System comment',
              id: 'system-comment-select',
            }}
          />,
          <Field
            element="text"
            onChange={handleChangeComment}
            value={comment}
            className={`min-w-sm input-bordered form-group input-text ${commentErrorClass}`}
            wrapperClassName="action-bar-text mr-10"
            emptyPlaceholder="Comment"
            name="comment"
            key="comment"
            label="Comment"
            suffix={!isEmpty(errors.comment) && (
              <Tooltip
                placement="top-end"
                title={(
                  <div className="tooltip-title-content-wrapper">
                    {errors.comment.map(e => <IntlMessages id={e.message} key={e.message} />)}
                  </div>
                )}
              >
                <i className="ti-info-alt" />
              </Tooltip>
            )}
          />,
        ])
      }
    </div>
  );
};

ActionBarForm.propTypes = {
  formProps: shape().isRequired,
  actions: shape().isRequired,
};

ActionBarForm.defaultProps = {};

export default ActionBarForm;
