import { useState } from 'react';

export const useStateHook = () => {
  const [collapsed, setCollapsed] = useState({ data: [], isOpen: false });

  const state = {
    collapsed,
  };

  const setState = {
    setCollapsed,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
