import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

const initialState = {};
const previewFilterAttributeRestTypes = restTypes('preview_filter_attribute');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case previewFilterAttributeRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case previewFilterAttributeRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        list: action.payload,
      };
    default: return state;
  }
};
