import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  UPLOAD_XML_CATEGORIES_START,
  UPLOAD_XML_CATEGORIES_FINISHED,
  REMOVE_VIRTUAL_CATEGORIES_START,
  REMOVE_VIRTUAL_CATEGORIES_FINISHED,
  CREATE_BULK_VIRTUAL_CATEGORIES_START,
  CREATE_BULK_VIRTUAL_CATEGORIES_FINISHED,
  CLEAR_CATEGORY_IMPORT_STATE,
  UPDATE_BULK_VIRTUAL_CATEGORIES_START,
  UPDATE_BULK_VIRTUAL_CATEGORIES_FINISHED,
} from '../actions/types';

const initialState = {
  xlsxUploading: false,
  xlsxUploaded: false,
  bulkVirtualCategoriesDeleting: false,
  bulkVirtualCategoriesDeleted: false,
  bulkVirtualCategoriesCreating: false,
  bulkVirtualCategoriesCreated: false,
  bulkVirtualCategoriesUpdating: false,
  bulkVirtualCategoriesUpdated: false,
  hasErrors: false,
  error: {},
};

const categoryImport = restTypes('category_import');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case categoryImport.createStart:
      return {
        ...state,
        ...restStates.createStart,
      };
    case categoryImport.createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        ...action.payload,
      };
    case UPLOAD_XML_CATEGORIES_START:
      return {
        ...state,
        xlsxUploading: true,
        xlsxUploaded: false,
      };
    case UPLOAD_XML_CATEGORIES_FINISHED:
      return {
        ...state,
        xlsxUploading: false,
        xlsxUploaded: true,
        ...action.payload,
      };
    case REMOVE_VIRTUAL_CATEGORIES_START:
      return {
        ...state,
        bulkVirtualCategoriesDeleting: true,
        bulkVirtualCategoriesDeleted: false,
      };
    case REMOVE_VIRTUAL_CATEGORIES_FINISHED:
      return {
        ...state,
        bulkVirtualCategoriesDeleting: false,
        bulkVirtualCategoriesDeleted: true,
        ...action.payload,
      };
    case CREATE_BULK_VIRTUAL_CATEGORIES_START:
      return {
        ...state,
        bulkVirtualCategoriesCreating: true,
        bulkVirtualCategoriesCreated: false,
      };
    case CREATE_BULK_VIRTUAL_CATEGORIES_FINISHED:
      return {
        ...state,
        bulkVirtualCategoriesCreating: false,
        bulkVirtualCategoriesCreated: true,
        ...action.payload,
      };
    case UPDATE_BULK_VIRTUAL_CATEGORIES_START:
      return {
        ...state,
        bulkVirtualCategoriesUpdating: true,
        bulkVirtualCategoriesUpdated: false,
      };
    case UPDATE_BULK_VIRTUAL_CATEGORIES_FINISHED:
      return {
        ...state,
        bulkVirtualCategoriesUpdating: false,
        bulkVirtualCategoriesUpdated: true,
        ...action.payload,
      };
    case CLEAR_CATEGORY_IMPORT_STATE:
      return {
        ...initialState,
      };
    default: return state;
  }
};
