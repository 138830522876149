import { useState } from 'react';

export const useStateHook = () => {
  const [initialAttributesFetched, setInitialAttributesFetched] = useState(false);

  const state = {
    initialAttributesFetched,
  };

  const setState = {
    setInitialAttributesFetched,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
