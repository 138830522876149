/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import mappedProductList from '../../../../utils/mappedProductList';

export const useProduct = ({
  setSelectedProductList,
  productsByIdsForNin,
  productsByIdsForIn,
  fetchProductsByIdsFinished,
}) => {
  useEffect(() => {
    setSelectedProductList({
      nin: [...mappedProductList(productsByIdsForNin)],
      in: [...mappedProductList(productsByIdsForIn)],
    });
  }, [
    productsByIdsForNin,
    productsByIdsForIn,
  ]);

  useEffect(() => {
    if (fetchProductsByIdsFinished) {
      setSelectedProductList({
        nin: [...mappedProductList(productsByIdsForNin)],
        in: [...mappedProductList(productsByIdsForIn)],
      });
    }
  }, [fetchProductsByIdsFinished]);
};

export default useProduct;
