/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState, useEffect, Fragment,
} from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { SortableContainer } from 'react-sortable-hoc';
import {
  string, shape, bool, func,
} from 'prop-types';

import Checkbox from '../../../../components/Checkbox';
import InfoPupup from '../InfoPupup';
import IntlMessages from '../../../../util/IntlMessages';
import {
  generateParentProductData,
  generateMerchantProductData,
} from '../../pages/Home/utils/productDataGenerator';
import ParentLevelItem from '../ParentLevelItem';
import VariantContainer from '../../containers/VariantContainer';

const SortableList = SortableContainer((props) => {
  const { sortableItemProps } = props;
  const [collapsed, setCollapsed] = useState([]);
  const [variantsCollapsed, setVariantsCollapsed] = useState([]);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [tableHeaderWidth, setTableHeaderWidth] = useState(0);
  const [productCategories, setProductCategories] = useState([]);
  const [popupAnchor, setPopupAnchor] = useState(null);

  const sortedAttributes = sortableItemProps.attributes.sort((a, b) => a.order - b.order);
  const tableHeaderNode = document.querySelector('.products-table-header') || {};

  useEffect(() => {
    if (tableHeaderNode.offsetWidth && tableHeaderWidth !== tableHeaderNode.offsetWidth) {
      const variantRowPadding = 66;
      setTableHeaderWidth(tableHeaderNode.offsetWidth - variantRowPadding);
    }
  }, [tableHeaderNode.offsetWidth]);

  useEffect(() => {
    const alreadyFetched = productCategories
      .every(cId => sortableItemProps.productsPhysicalCategoryList
        .some(cat => cat.id === cId));
    if (!isEmpty(productCategories) && !alreadyFetched) {
      sortableItemProps.fetchPimProductPhysicalCategoriesByIds(productCategories);
    }
  }, [productCategories]);

  const handleCellClick = (cell) => {
    if (cell.isSortable) {
      const newOrder = order === 'asc' && cell.code === orderBy
        ? 'desc'
        : 'asc';
      setOrder(newOrder);
      setOrderBy(cell.code);
      sortableItemProps.onHeaderCellClick(cell, newOrder);
    }
  };

  const showSelectAllCheckbox = (
    !sortableItemProps.disabled
    && !isEmpty(sortableItemProps.productList)
  );
  const defaultFields = showSelectAllCheckbox
    ? [{
      code: 1,
      label: '',
    }] : [{
      code: 1,
      label: '',
    }, {
      code: 2,
      label: '',
    }];

  const filteredAttributes = sortedAttributes
    .filter(attr => attr.enabled).map(attr => ({
      label: attr.label,
      code: attr.code,
      isSortable: attr.isSortable,
      sortCode: attr.sortCode,
    }));
  const attributesList = sortableItemProps.isCustomFields
    ? [...defaultFields, ...filteredAttributes
      .filter(attr => sortableItemProps.searchFields.includes(attr.code))]
    : [...defaultFields, ...filteredAttributes];
  return (
    <>
      <TableContainer className="product-table-container">
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead className="products-table-header">
            <TableRow>
              {showSelectAllCheckbox && (
                <TableCell
                  key="check-all"
                  align="left"
                >
                  <div className="flex items-center select-all-checkbox">
                    <Checkbox
                      checked={sortableItemProps.allProductsAreChecked}
                      onChange={sortableItemProps.handleAllProductsSelect}
                    />
                    <IntlMessages id="preview.productList.selectAllLabel" />
                  </div>
                </TableCell>
              )}
              {
                !isEmpty(sortableItemProps.productList) && attributesList.map((f) => {
                  const onCellClick = () => handleCellClick(f);

                  return (
                    <TableCell
                      key={f.code}
                      align="left"
                    >
                      <TableSortLabel
                        active={orderBy === f.code}
                        direction={orderBy === f.code ? order : 'asc'}
                        onClick={onCellClick}
                        hideSortIcon={!f.isSortable}
                      >
                        {f.label}
                      </TableSortLabel>
                    </TableCell>
                  );
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              sortableItemProps.productList.map((p) => {
                const productValue = p.type === 'parent'
                  ? generateParentProductData(
                    p,
                    sortableItemProps.defaultImage,
                    sortableItemProps.initialAttributesOptions,
                    sortedAttributes,
                    sortableItemProps.currency,
                  )
                  : generateMerchantProductData(
                    p,
                    sortableItemProps.defaultImage,
                    sortableItemProps.initialAttributesOptions,
                    sortedAttributes,
                    sortableItemProps.currency,
                  );

                const merchantTrClassName = productValue.profitLost > 0 ? 'tr-success' : 'tr-danger';
                const trClassName = productValue.profitLost ? merchantTrClassName : '';

                const sortedAttributesList = sortableItemProps.isCustomFields
                  ? sortedAttributes
                    .filter(attr => sortableItemProps.searchFields.includes(attr.code))
                  : sortedAttributes;

                return (
                  <Fragment key={p.id}>
                    <TableRow hover tabIndex={-1} className={trClassName}>
                      <ParentLevelItem
                        itemProps={{
                          collapsed,
                          setCollapsed,
                          setPopupAnchor,
                          setProductCategories,
                          productItem: p,
                          parentValue: productValue,
                          disabled: sortableItemProps.disabled,
                          sortedAttributes: sortedAttributesList,
                          onProductSelect: sortableItemProps.onProductSelect,
                          onEditClick: sortableItemProps.onEditClick,
                          selectedProducts: sortableItemProps.selectedProducts,
                          handleExpandParentLevel: sortableItemProps.handleExpandParentLevel,
                          defaultImage: sortableItemProps.defaultImage,
                          certainProductAttributes: sortableItemProps.certainProductAttributes,
                          productsCommentsCount: sortableItemProps.productsCommentsCount,
                          onUnlockAttributesClick: sortableItemProps.onUnlockAttributesClick,
                          productCommentsLoading: sortableItemProps.productCommentsLoading,
                          productComments: sortableItemProps.productComments,
                          fetchActivityLogsByProductId:
                            sortableItemProps.fetchActivityLogsByProductId,
                          attributeSets: sortableItemProps.attributeSets,
                          fetchProductAttributeSets: sortableItemProps.fetchProductAttributeSets,
                          commentMessages: sortableItemProps.commentMessages,
                          bulkDeleteProductLockedAttributesStarted:
                            sortableItemProps.bulkDeleteProductLockedAttributesStarted,
                          bulkDeleteProductLockedAttributesFinished:
                            sortableItemProps.bulkDeleteProductLockedAttributesFinished,
                          multipleUpdateUserActivityLogs:
                            sortableItemProps.multipleUpdateUserActivityLogs,
                          currency: sortableItemProps.currency,
                          urlKey: p.urlKey,
                          fromRules: sortableItemProps.fromRules,
                        }}
                      />
                    </TableRow>
                    {
                      collapsed.includes(p.id) && !isEmpty(p.variants) && (
                        <TableRow key={`${p.id}_variants`}>
                          <TableCell>
                            {sortableItemProps.fetchParentLevelChildrenProductsStarted
                              && sortableItemProps.lastParentChildrenLoaded === p.id
                              && <span className="loading">Loading...</span>}

                            <VariantContainer
                              variants={p.variants}
                              parent={p}
                              variantsCollapsed={variantsCollapsed}
                              setVariantsCollapsed={setVariantsCollapsed}
                              onEditClick={sortableItemProps.onEditClick}
                              tableHeaderWidth={tableHeaderWidth}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    }
                  </Fragment>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      <InfoPupup
        anchor={popupAnchor}
        loading={sortableItemProps.productsPhysicalCategoryListFetching}
        categories={
          sortableItemProps.productsPhysicalCategoryList
            .filter(c => productCategories.includes(c.id))
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    </>
  );
});

const SortableTableList = props => (
  <SortableList
    axis={props.axis}
    useDragHandle={props.useDragHandle}
    onSortEnd={props.onSortEnd}
    sortableItemProps={props.sortableItemProps}
  />
);

SortableTableList.propTypes = {
  sortableItemProps: shape().isRequired,
  axis: string.isRequired,
  onSortEnd: func.isRequired,
  useDragHandle: bool.isRequired,
};

SortableTableList.defaultProps = {};

export default SortableTableList;
