/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { decamelize } from 'humps';
import { isEmpty, uniq } from 'lodash';

import { actions } from '../../../utils/actionBarConsts';

const pagination = { limit: 500 };

const useProductAttributes = ({
  fetchProductAttributeSets,
  fetchCertainProductAttributes,
  clearCategoriesAttributesState,
  selectedAction,
  selected,
  list,
  edit,
  previewAttributes,
  fetchParentLevelChildrenProductsFinished,
}) => {
  useEffect(() => {
    if (selectedAction === actions.edit) {
      if (!isEmpty(selected)) {
        const parentProducts = list.filter(
          s => !!selected.find(i => i.id === s.id),
        );

        if (!isEmpty(parentProducts)) {
          const attributeSets = parentProducts.map(p => p.attributeSet);
          const setsList = uniq([...attributeSets]).filter(s => s);
          const fields = [
            'code',
            'frontend_input_type',
            'label',
            'options',
            'is_filterable',
            'is_editable',
          ];

          if (!isEmpty(setsList)) {
            fetchProductAttributeSets({ ids: setsList, pagination, fields });
          }
        }
      }
    }
  }, [selectedAction, selected]);

  useEffect(() => {
    if (!isEmpty(edit)) {
      const { attributeSet } = edit;
      const fields = [
        'code',
        'frontend_input_type',
        'label',
        'options',
        'is_filterable',
        'is_editable',
      ];

      if (!isEmpty(attributeSet)) {
        fetchProductAttributeSets({ ids: [attributeSet], pagination, fields });
      }
    }
  }, [edit.id]);

  useEffect(() => {
    if (!isEmpty(previewAttributes)) {
      const firebaseAttributes = previewAttributes;
      const productAttributesFields = firebaseAttributes.filter(attr => attr.isProductAttribute);
      const fields = [
        'code',
        'frontend_input_type',
        'label',
        'id',
      ];

      const condition = productAttributesFields.length > 1 ? { condition: 'or' } : {};
      const filter = [
        {
          ...condition,
          group: productAttributesFields.map(attr => ({
            field: 'code',
            value: attr.value,
            operator: 'like',
          })),
        },
      ];
      if (!isEmpty(productAttributesFields)) {
        fetchCertainProductAttributes(filter, fields, pagination);
      }
    }
  }, [previewAttributes]);

  useEffect(() => {
      const variationAttributes = list
        .map(el => (el.variants || [])
          .map(v => v.variationAttributes)).flat();

      const codeNames = [...new Set(variationAttributes
        .map(attr => Object.keys(attr)
          .map(k => k)).flat())];

      const fields = [
        'code',
        'frontend_input_type',
        'label',
        'id',
      ];

      const condition = codeNames.length > 1 ? { condition: 'or' } : {};

      const filter = [{
        ...condition,
        group: codeNames.map(name => ({
          field: 'code',
          value: decamelize(name),
          operator: 'like',
        })),
      }];

      if (filter[0].group.length > 0) {
        fetchCertainProductAttributes(filter, fields, pagination);
      }
  }, [list]);

  useEffect(() => () => {
    clearCategoriesAttributesState();
  }, []);
};

export default useProductAttributes;
