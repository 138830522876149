import React from 'react';
import { string, oneOfType, node } from 'prop-types';

export const Empty = props => (
  <div className={`empty-wrapper ${props.className}`}>
    <div className="empty-header">
      {props.icon}
    </div>
    <div className="empty-body">
      {props.title && <div className="empty-title">{props.title}</div>}
      {props.subtitle && <div className="empty-title">{props.subtitle}</div>}
    </div>
  </div>
);

Empty.propTypes = {
  className: string,
  icon: oneOfType([node, string]),
  title: oneOfType([node, string]),
  subtitle: oneOfType([node, string]),
};

Empty.defaultProps = {
  className: '',
  icon: null,
  title: null,
  subtitle: null,
};

export default Empty;
