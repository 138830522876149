import axios from 'axios';
import { camelizeKeys } from 'humps';
import { isEmpty, remove } from 'lodash';
import RestActions from '../util/rest/actions';
import { showErrorNotification } from '../util/api';

import {
  FETCH_CHILD_VIRTUAL_CATEGORIES_START,
  FETCH_CHILD_VIRTUAL_CATEGORIES_FINISHED,
  FETCH_ROOT_VIRTUAL_CATEGORIES_START,
  FETCH_ROOT_VIRTUAL_CATEGORIES_FINISHED,
  SET_CATEGORY_TREE_ITEMS,
  SET_SELECTED_CATEGORY,
  VIRTUAL_CATEGORY_WIDGET_SEARCH_VIRTUAL_CATEGORIES_START,
  VIRTUAL_CATEGORY_WIDGET_SEARCH_VIRTUAL_CATEGORIES_FINISHED,
  TOGGLE_CATEGORY_TREE_COLLAPSE,
  TOGGLE_CATEGORY_TREE_COLLAPSE_ITEM,
  COLLAPSE_INITIAL_CATEGORIES_TREE,
  FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_START,
  FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_FINISHED,
  HANDLE_LOAD_SUBCATEGORIES,
  CLEAR_VIRTUAL_CATEGORY_TREE_STATE,
  CLEAR_CATEGORY_PRODUCT_STATE,
} from './types';
import {
  getCategoryPath,
  categoriesTreePath,
  getCategoryTreePath,
  categoriesSearchPath,
} from '../util/paths';

import { mapObjectToArray } from '../util/objectsToArray';
import { mapPositions } from '../util/mapVirtualParamsPositions';

import { CDMSClient } from '../util/api';

const restVirtualCategoryWidgetActions = new RestActions('virtual_category_widget');

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';

export const onToggleVirtualCategoryTreeCollapse = collapse => (dispatch) => {
  dispatch({ type: TOGGLE_CATEGORY_TREE_COLLAPSE, payload: collapse });
};

export const onToggleCategoryTreeCollapseItem = catId => (dispatch) => {
  dispatch({ type: TOGGLE_CATEGORY_TREE_COLLAPSE_ITEM, payload: catId });
};

export const handleLoadVirtualSubcategories = loadedKeys => (dispatch) => {
  dispatch({ type: HANDLE_LOAD_SUBCATEGORIES, payload: { loadedKeys } });
};

export const clearVirtualCategoryTreeState = () => (dispatch) => {
  dispatch({ type: CLEAR_VIRTUAL_CATEGORY_TREE_STATE });
};

export const clearState = () => (dispatch) => {
  dispatch(restVirtualCategoryWidgetActions.clearAllFinished());
};

export const clearErrorState = () => (dispatch) => {
  dispatch(restVirtualCategoryWidgetActions.clearErrorFinished({ error: {}, hasErrors: false }));
};

export const setTreeItems = items => (dispatch) => {
  dispatch({
    type: SET_CATEGORY_TREE_ITEMS,
    payload: {
      items: camelizeKeys(items),
    },
  });
};

export const clearVirtualCategoryWidgetState = () => (dispatch) => {
  dispatch(restVirtualCategoryWidgetActions.clearOneFinished());
};

export const clearSearchListState = () => (dispatch) => {
  dispatch(restVirtualCategoryWidgetActions.clearSearchListFinished());
};

export const fetchRootCategories = (cb, params = {}) => async (dispatch) => {
  dispatch({ type: FETCH_ROOT_VIRTUAL_CATEGORIES_START });
  const path = `${categoriesTreePath}?is_virtual=1&show_disabled=${params.showDisabled === false ? '0' : '1'}`;
  try {
    const res = await cdmsFetchRootCategories(path);
    const rootCategories = camelizeKeys(res.data.data);

    dispatch({
      type: FETCH_ROOT_VIRTUAL_CATEGORIES_FINISHED,
      payload: { list: rootCategories },
    });

    if (cb) cb();

  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_ROOT_VIRTUAL_CATEGORIES_FINISHED,
      payload: {
        error: error && error.response && error.response.data, hasError: true,
      },
    });
  }
};

export const cdmsFetchRootCategories = (path) => CDMSClient.get(path);

export const fetchChildCategories = (rootId, params = {}) => (dispatch, getState) => {
  const { treeItems } = getState().virtualCategoryWidget;
  const path = `${getCategoryTreePath(rootId)}?show_disabled=${params.showDisabled === false ? '0' : '1'}`;

  dispatch({
    type: FETCH_CHILD_VIRTUAL_CATEGORIES_START,
    payload: {
      fetchChildrenStarted: true,
      fetchChildrenFinished: false,
    },
  });
  return axios.get(path).then((res) => {
    const childCategories = camelizeKeys(res.data.data);

    remove(treeItems, ti => ti.parentId === rootId);

    dispatch({
      type: FETCH_CHILD_VIRTUAL_CATEGORIES_FINISHED,
      payload: {
        treeItems: [...childCategories, ...treeItems],
        rootId,
        fetchChildrenStarted: false,
        fetchChildrenFinished: true,
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_CHILD_VIRTUAL_CATEGORIES_FINISHED,
      payload: {
        error: error.response.data, hasError: true,
      },
    });
  });
};

export const fetchMultipleChildScopeCategories = items => (dispatch) => {
  dispatch({ type: FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_START });

  Promise.all(items.map(async (item) => {
    await dispatch(fetchChildCategories(item));
  })).then(() => {
    dispatch({ type: FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_FINISHED });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_FINISHED,
      error,
      hasError: true,
    });
  });
};

export const fetchRecursiveParentCategory = cId => (dispatch) => {
  dispatch({ type: COLLAPSE_INITIAL_CATEGORIES_TREE });

  return axios.get(getCategoryPath(cId)).then((res) => {
    const cat = camelizeKeys(res.data.data);
    if (cat && cat.hasChildren) {
      dispatch(onToggleCategoryTreeCollapseItem(cat.id));
    }

    if (cat && cat.parentId) dispatch(fetchRecursiveParentCategory(cat.parentId));
  });
};

export const searchCategories = query => (dispatch) => {
  dispatch({ type: VIRTUAL_CATEGORY_WIDGET_SEARCH_VIRTUAL_CATEGORIES_START });
  return axios.post(categoriesSearchPath + '?ref=' + ref, query).then((res) => {
    const searchResults = camelizeKeys(mapObjectToArray(res.data.data));
    const result = searchResults.map((c) => {
      const originalCategory = mapObjectToArray(res.data.data)
        .find(category => category.id === c.id);
      const payload = { ...c };

      if (originalCategory) {
        payload.virtualParams = mapPositions(
          c.virtualParams,
          originalCategory.virtual_params,
        );
      }
      return payload;
    });
    dispatch({
      type: VIRTUAL_CATEGORY_WIDGET_SEARCH_VIRTUAL_CATEGORIES_FINISHED,
      payload: {
        list: result,
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: VIRTUAL_CATEGORY_WIDGET_SEARCH_VIRTUAL_CATEGORIES_FINISHED,
      payload: {
        error: error.response.data, hasError: true,
      },
    });
  });
};

export const clearFromVirtualCategoryWidgetState = payload => (dispatch) => {
  dispatch(restVirtualCategoryWidgetActions.clearFromStateFinished(payload));
};

export const clearProductState = () => (dispatch) => {
  dispatch({ type: CLEAR_CATEGORY_PRODUCT_STATE });
};

export const setSelectedCategory = category => (dispatch) => {
  dispatch({
    type: SET_SELECTED_CATEGORY,
    payload: {
      selectedCategory: category,
      categorySelected: !isEmpty(category),
    },
  });
};
