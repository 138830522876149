import { camelize } from 'humps';
import { isEmpty } from 'lodash';

const getValue = (p, o) => p.reduce((xs, x) => (((xs && xs[x]) || (xs && Number.isInteger(xs[x])))
  ? xs[x]
  : '-'), o);

export const generateParentProductData = (item, defaultImage, attributes) => {
  const publishPrice = item.price && item.price !== Infinity
    ? `$${item.price}`
    : '-';
  const imgData = item.images ? item.images[0] : defaultImage;
  const rootValues = !isEmpty(attributes)
    ? attributes
      .filter(attr => attr.enabled && getValue(camelize(attr.code)
        .split('.'), item) !== undefined)
    : [];
  const value = {
    id: item.id,
    imgData,
    publishPrice,
  };

  rootValues.forEach((v) => {
    const itemValue = getValue(camelize(v.code).split('.'), item);

    value[camelize(v.value)] = itemValue;
  });

  return value;
};

export default generateParentProductData;
