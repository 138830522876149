import React from 'react';
import {
  string,
} from 'prop-types';
import {
  Typography, Link,
} from '@material-ui/core';

const LinkWithLabel = (props) => {
  const {
    url, label, className,
  } = props;

  return (
    <div className={`flex direction-column link-with-label ${className}`}>
      <Typography variant="caption" display="block" gutterBottom>
        {label}
      </Typography>
      <Link target="_blank" rel="noopener" href={url} variant="body2" underline="none">
        {url}
      </Link>
    </div>
  );
};

LinkWithLabel.propTypes = {
  label: string,
  url: string,
  className: string,
};

LinkWithLabel.defaultProps = {
  label: '',
  url: '',
  className: '',
};

export default LinkWithLabel;
