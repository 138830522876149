import { createSelector } from 'reselect';

const previewCategoryRootSelector = state => state;

const stateSelector = createSelector(
  previewCategoryRootSelector,
  state => ({
    updated: state.updated,
  }),
);

export const previewCategorySelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  previewCategorySelector,
};
