import React, { memo } from 'react';
import {
  func, shape, number, bool, string,
} from 'prop-types';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { paginationLimit } from '../../../../utils/defaultData';

const ProductsPagination = memo(props => (
  <div className={`pagination justify-end ${!props.flexAuto ? 'third-width' : ''} ${props.className}`}>
    {props.total > paginationLimit && (
      <ReactPaginate
        pageCount={props.pages}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        breakLabel={<span>...</span>}
        nextLabel={<FontAwesomeIcon size="lg" icon={faAngleRight} />}
        previousLabel={<FontAwesomeIcon size="lg" icon={faAngleLeft} />}
        breakClassName="break-me"
        activeClassName="active"
        containerClassName="pagination flex justify-center"
        onPageChange={props.onPaginate}
        pageClassName="page-btn"
        forcePage={props.pagination.page - 1}
      />
    )}
  </div>
));

ProductsPagination.propTypes = {
  onPaginate: func.isRequired,
  flexAuto: bool,
  className: string,
  total: number,
  pages: number,
  pagination: shape({
    page: number,
  }),
};

ProductsPagination.defaultProps = {
  pagination: {},
  className: '',
  flexAuto: false,
  total: 0,
  pages: 0,
};

export default ProductsPagination;
