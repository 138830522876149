import React from 'react';
import { Link } from 'react-router-dom';
import {
  func, shape, number, arrayOf, bool, string,
} from 'prop-types';
import { Tooltip } from '@material-ui/core';

import IntlMessages from '../../../../util/IntlMessages';
import Select from '../../../../components/Select';

const RolesSelect = ({
  user, options, onChange, errors, disabled, isInfluencer,
  linkIsdisabled, influencerPath,
}) => (
  <div className="flex items-center">
    <Select
      value={user.roles || []}
      options={options}
      onChange={e => onChange(user.id, e)}
      name="roles"
      multiple
      selectClassName="simple-select"
      className="select-wrapper select-underline-hide select-wrap"
      errors={errors[user.id]}
      id={user.id}
      disabled={disabled}
    />
    {
      isInfluencer && (
        <Tooltip
          className="ml-15"
          title={<IntlMessages id="system.users.link.influencer" />}
          placement="top"
        >
          <Link disabled={linkIsdisabled} to={influencerPath} target="_blank">
            <i className="ti-new-window" />
          </Link>
        </Tooltip>
      )
    }
  </div>
);

RolesSelect.propTypes = {
  options: arrayOf(shape()),
  user: shape({
    id: number,
    roles: arrayOf(shape()),
  }),
  onChange: func,
  errors: shape(),
  disabled: bool,
  isInfluencer: bool.isRequired,
  linkIsdisabled: bool.isRequired,
  influencerPath: string.isRequired,
};

RolesSelect.defaultProps = {
  user: {},
  errors: {},
  onChange: null,
  options: [],
  disabled: false,
};

export default RolesSelect;
