import React, { memo, Fragment } from 'react';
import {
  func, bool
} from 'prop-types';

import FormDialog from '../../../../../../../../../components/FormDialog';
import IntlMessages from '../../../../../../../../../util/IntlMessages';
import { Card, CardContent } from "@material-ui/core";

const ExportToEmailDialog = memo((props) => {
  const {
    showDialog,
    handleCloseDialog,
    handleExportToEmail,
    email,
    emailError,
    setEmail,
  } = props;

  const handleSetEmail = (e) => {
    const { target } = e;
    setEmail(target.value);
  };

  return (
    <FormDialog
      title={<IntlMessages id="previewPage.exportToEmailDialog.header" />}
      open={showDialog}
      hideCloseButton={false}
      className="dialog-inputs-custom dialog-content-visible"
      closeButtonTitle="Close"
      submitButtonTitle="Submit"
      maxWidth="xs"
      onClose={handleCloseDialog}
      onSubmit={handleExportToEmail}
    >
      <Fragment>
        <Card>
          <div className="preview-export-description">
            <IntlMessages id="export.description" />
          </div>

          <CardContent className="flex items-center">
            <input
              type="text"
              onChange={e => handleSetEmail(e)}
              name="exportEmail"
              defaultValue={email}
              placeholder="E-mail"
              className="preview-export-products-input"
            />
          </CardContent>

          {emailError && (
            <div className="preview-export-error">
              <IntlMessages id={emailError} />
            </div>
          )}

        </Card>
      </Fragment>
    </FormDialog>
  );

});

ExportToEmailDialog.propTypes = {
  showDialog: bool,
  handleCloseDialog: func,
  handleExportToEmail: func,
  setEmail: func,
};

ExportToEmailDialog.defaultProps = {
  showToEmailDialog: false,
  email: '',
  emailError: '',
};

export default ExportToEmailDialog;
