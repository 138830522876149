import React from 'react';
import {
  string, number, bool, func, arrayOf, object, oneOfType, node, array,
} from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import Field from '../Field';
import InputErrors from '../InputErrors';

const fieldElement = (field, onChange, onKeyPress, onSelect, disabled, gap, index) => {
  if (Array.isArray(field)) {
    return (
      <div className="form-group-cell" key={`${index || 'd'}_${index + 1 || '_'}`}>
        {
          field.map(f => (
            <div key={`${f.name}_${field.indexOf(f) || 'd'}_${f.type || '_'}`} className={`form-group ${gap ? `mt${gap}` : ''} ${f.groupclass || ''}`}>
              {f.label && !f.hideStaticLabel && <div htmlFor={f.name} className="form-group-label">{f.label}</div>}
              <Field
                onChange={onChange}
                onKeyPress={onKeyPress}
                onSelect={onSelect}
                disabled={disabled}
                {...f}
              />
              {f.errors && f.errors.length > 0
              && !f.hideFormErrors && <InputErrors errors={f.errors} />}
            </div>
          ))
        }
      </div>
    );
  }
  return (
    <div key={`${field.name}_${index || 'd'}_${field.type || '_'}`} className={`form-group ${gap ? `mt${gap}` : ''} ${field.groupclass || ''}`}>
      {field.label && !field.hideStaticLabel && <div htmlFor={field.name} className="form-group-label">{field.label}</div>}
      {
        // eslint-disable-next-line no-nested-ternary
        field.tooltip
          ? (
            <Tooltip title={field.tooltip.title} placement={field.tooltip.placement}>
              <Field
                onChange={onChange}
                onKeyPress={onKeyPress}
                onSelect={onSelect}
                disabled={disabled}
                {...field}
              />
            </Tooltip>
          )
          : field.deleteIcon ? (
            <div className="flex relative">
              {field.deleteIcon}
              <Field
                onChange={onChange}
                onKeyPress={onKeyPress}
                onSelect={onSelect}
                disabled={disabled}
                {...field}
              />
            </div>
          ) : (
            <Field
              onChange={onChange}
              onKeyPress={onKeyPress}
              onSelect={onSelect}
              disabled={disabled}
              {...field}
            />
          )
      }
      {field.errors && field.errors.length > 0
      && !field.hideFormErrors && <InputErrors errors={field.errors} />}
    </div>
  );
};

const Form = ({
  className,
  onChange,
  onKeyPress,
  onSelect,
  children,
  fields,
  title,
  subtitle,
  gap,
  disabled,
  formInlineGroupClassName,
}) => (
  (fields && fields.length > 0) || (children && children.length > 0) ? (
    <form onSubmit={null} className={`${className} ${disabled ? 'form-disabled' : ''}`}>

      {title && <div className="form-header">{title}</div>}
      {subtitle && <div className="form-subheader h5">{subtitle}</div>}

      {fields && fields.length > 0

        ? fields.map(field => (
          // eslint-disable-next-line no-nested-ternary
          Array.isArray(field) && field.length > 0
            ? (
              <div key={`${field.key}_${fields.indexOf(field)}`} className={`form-inline mt-${gap} ${field.some(f => f.hasErrors) ? 'with-errors' : ''} ${formInlineGroupClassName} ${field.find(f => f.inputCombine) ? 'form-inline-combine' : 'form-inline-divide'}`}>
                {field.map((f, index) => (
                  // eslint-disable-next-line no-nested-ternary
                  !f.hideComponent
                    ? Array.isArray(f)
                      ? fieldElement(f, onChange, onKeyPress, onSelect, disabled) // f -> Array
                      : fieldElement(f, onChange, onKeyPress, onSelect, disabled, gap, index)
                    : null
                ))}
              </div>
            )
            : field && !field.hideComponent
              ? fieldElement(
                field, onChange, onKeyPress, onSelect, disabled, gap, fields.indexOf(field),
              )
              : null
        ))

        : (children && children.length > 0 && children.map(child => (
          child.length > 0
            ? (
              <div key={children.indexOf(child)} className={`form-inline mt${gap} ${child.find(c => c.props.inputCombine) ? 'form-inline-combine' : 'form-inline-divide'}`}>
                {child.map(c => (
                  <div key={child.indexOf(c)} className="form-group">
                    {c}
                  </div>
                ))}
              </div>
            )
            : <div key={child.props.name || child.props.value} className={`form-group mt${gap}`}>{child}</div>
        ))) || <div className={`form-group mt${gap}`}>{children}</div>
      }

    </form>
  ) : null
);

Form.propTypes = {
  className: string,
  fields: arrayOf(
    oneOfType([arrayOf(object), object, array]),
  ),
  formInlineGroupClassName: string,
  onChange: func,
  onKeyPress: func,
  onSelect: func,
  children: oneOfType([arrayOf(node), node]),
  gap: number,
  title: oneOfType([string, node]),
  subtitle: oneOfType([string, node]),
  disabled: bool,
};

Form.defaultProps = {
  className: '',
  fields: [],
  children: [],
  formInlineGroupClassName: '',
  onChange: () => { },
  onKeyPress: () => { },
  onSelect: null,
  gap: null,
  title: null,
  subtitle: null,
  disabled: false,
};

export default Form;
