import React from 'react';
import { func, string } from 'prop-types';
import { Input } from 'reactstrap';
import IntlMessages from '../../util/IntlMessages';

const SearchForm = props => (
  <div className="search-wrapper">
    <Input
      type="search"
      className="search-input-lg"
      onChange={props.onChange}
      placeholder="Search..."
      value={props.value}
    />
    {props.error && <span className="error"><IntlMessages id={props.error} /></span>}
  </div>
);

SearchForm.propTypes = {
  value: string,
  onChange: func,
  error: string,
};

SearchForm.defaultProps = {
  value: '',
  onChange: null,
  error: '',
};

export default SearchForm;
