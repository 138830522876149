import React, { memo, useContext } from 'react';
import {
  func, bool, shape, arrayOf,
} from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import swal from '@sweetalert/with-react';

import {
  fetchVirtualCategoryVirtualFacetList,
  setVirtualCategoryVirtualFacetToDeleteId,
  setVirtualCategoryVirtualFacetList,
} from 'actions/virtualCategoryVirtualFacet';

import { setFacets } from 'actions/virtualCategoryPage';

import FacetComponent from 'components/Facet';
import { AbilityContext } from 'components/AbilityContext';

import appPermissions from 'util/appPermissions';
import { getFilterableAttributes } from 'util/attributesMappers';
import { getFacetPayload } from 'util/virtualFacetManagment';

import { virtualCategoryPageSelector } from './selectors/virtualCategoryPage';
import { productAttributeSelector } from './selectors/productAttribute';
import { categoryFacetSelector } from './selectors/categoryFacet';

import useFacetEffects from './hooks/useFacet';
import virtualCategoryVirtualFacet from './hooks/virtualCategoryVirtualFacet';


const Facet = memo((props) => {
  const abilityContext = useContext(AbilityContext);

  useFacetEffects({
    virtualCategoryFetched: props.virtualCategoryPageState.virtualCategoryFetched,
    virtualCategoryItem: props.virtualCategoryPageState.virtualCategoryItem,
    setFacets: props.setFacets,
  });

  virtualCategoryVirtualFacet({
    categoryItemId: props.virtualCategoryPageState.virtualCategoryItem.id,
    categoryFetched: props.virtualCategoryPageState.virtualCategoryFetched,
    categoryUpdated: props.virtualCategoryPageState.virtualCategoryUpdated,

    fetchCategoryVirtualFacetList: props.fetchVirtualCategoryVirtualFacetList,
    categoryVirtualFacetListFetched: props.categoryFacet.fetched,
    categoryVirtualFacetUpdated: props.categoryFacet.updated,
    categoryVirtualFacetDeleted: props.categoryFacet.deleted,
    categoryVirtualFacetCreated: props.categoryFacet.created,
    categoryVirtualFacetFetching: props.categoryFacet.fetching,
  });

  const {
    virtualCategoryItem,
    filterCategory,
  } = props.virtualCategoryPageState;

  const {
    categoriesAttributesFetching,
    categoriesAttributes,
  } = props.productAttributeState;

  const {
    rankingFetching,
    defaultAttributes,
  } = props;

  const noPermissions = !abilityContext.can(
    appPermissions.category.permissions.update,
    appPermissions.category.name,
  );

  const loading = rankingFetching
    || props.categoryFacet.creating
    || props.categoryFacet.updating
    || props.categoryFacet.deleting
    || props.fetchingCategoriesByIds
    || categoriesAttributesFetching;

  const getFacetAttributes = () => getFilterableAttributes(categoriesAttributes);

  const getCategoryFacets = () => {
    const facetAttributes = getFacetAttributes();
    let categoryFacets = [];
    if (
      !isEmpty(props.categoryFacet.list)
      && !isEmpty(categoriesAttributes)
      && !isEmpty(facetAttributes)
    ) {
      categoryFacets = props.categoryFacet.list.map((cF) => {
        if (facetAttributes.some(pASL => pASL.code === cF)) {
          return facetAttributes.find(pASL => pASL.code === cF);
        }
        return false;
      }).filter(a => a);
    }

    const defaultAttributesList = defaultAttributes || [];
    const filteredFacets = categoryFacets.filter(
      cf => !defaultAttributesList.some(da => da.field === cf.code),
    );
    return [...defaultAttributesList, ...filteredFacets, ...props.categoryFacet.list];
  };

  const handleFacetChanges = (facets) => {
    const mappedFacets = facets.filter(f => !f.code && !f.default);
    // eslint-disable-next-line no-param-reassign
    mappedFacets.forEach(i => delete i.groupedValues);
    const newLsit = [{
      ...props.categoryFacet.item,
      facetsList: mappedFacets,
    }];
    props.setVirtualCategoryVirtualFacetList(newLsit);
  };

  const preHandleFacetDelete = () => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to permanently delete virtual facets?',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    }).then((willDiscard) => {
      if (willDiscard) {
        props.setVirtualCategoryVirtualFacetToDeleteId(props.categoryFacet.item.id);
        props.setVirtualCategoryVirtualFacetList([]);
      }
    });
  };

  const handleFacetItemDelete = (newList) => {
    handleFacetChanges(newList);
    if (isEmpty(newList.filter(l => !l.default))) {
      props.setVirtualCategoryVirtualFacetToDeleteId(props.categoryFacet.item.id);
    } else {
      props.setVirtualCategoryVirtualFacetToDeleteId('');
    }
  };

  const handleFacetManagementDialogOk = (facet) => {
    const facetPayload = getFacetPayload({
      prevFacet: facet,
      useRev: true,
      nextFacet: {
        item: props.categoryFacet.item,
        list: props.categoryFacet.list,
      },
    });
    props.setVirtualCategoryVirtualFacetList(facetPayload);
  };

  const disabledClass = loading ? 'disabled' : '';

  return (
    <div className={`${disabledClass}`}>
      <FacetComponent
        key={virtualCategoryItem.id}
        entity={{
          type: 'virtual_category',
          id: virtualCategoryItem.id,
        }}
        loading={loading}
        inputLabel="Select"
        list={getFacetAttributes()}
        defaultAttributes={defaultAttributes}
        disabled={isEmpty(filterCategory.mappedCategories) || noPermissions}
        table={{
          tBody: getCategoryFacets(),
        }}
        onAdd={handleFacetChanges}
        onDeleteButton={{
          disabled: isEmpty(props.categoryFacet.list)
            && isEmpty(props.categoryFacet.virtualFacetIdToDelete),
          onClick: preHandleFacetDelete,
        }}
        onItemDelete={handleFacetItemDelete}
        onDragEnd={handleFacetChanges}
        newFacet={{
          onOk: handleFacetManagementDialogOk,
        }}
      />
    </div>
  );
});

Facet.propTypes = {
  defaultAttributes: arrayOf(shape()).isRequired,
  virtualCategoryPageState: shape().isRequired,
  productAttributeState: shape().isRequired,

  rankingFetching: bool.isRequired,

  setFacets: func.isRequired,

  categoryFacet: shape().isRequired,

  fetchVirtualCategoryVirtualFacetList: func.isRequired,

  setVirtualCategoryVirtualFacetToDeleteId: func.isRequired,
  setVirtualCategoryVirtualFacetList: func.isRequired,

  fetchingCategoriesByIds: bool.isRequired,
};

Facet.defaultProps = {};

const mapStateToProps = state => ({
  virtualCategoryPageState: virtualCategoryPageSelector(state.virtualCategoryPage),
  productAttributeState: productAttributeSelector(state.productAttribute),
  rankingFetching: state.ranking.fetching,
  defaultAttributes: state.defaultAttribute.attributes.categories,

  categoryFacet: categoryFacetSelector(state.virtualCategoryVirtualFacet),

  categoryVirtualFacetList: state.virtualCategoryVirtualFacet.list,
  categoryVirtualFacetListFetched: state.virtualCategoryVirtualFacet.fetched,
  categoryVirtualFacetDeleted: state.virtualCategoryVirtualFacet.deleted,
  categoryVirtualFacetUpdating: state.virtualCategoryVirtualFacet.updating,
  categoryVirtualFacetUpdated: state.virtualCategoryVirtualFacet.updated,
  categoryVirtualFacetCreated: state.virtualCategoryVirtualFacet.created,
  categoryVirtualFacetCreating: state.virtualCategoryVirtualFacet.creating,

  fetchingCategoriesByIds: state.physicalCategory.fetchingCategoriesByIds,
});

const mapDispatchToProps = {
  setFacets,
  fetchVirtualCategoryVirtualFacetList,
  setVirtualCategoryVirtualFacetToDeleteId,
  setVirtualCategoryVirtualFacetList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Facet);
