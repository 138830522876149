import { useState } from 'react';

export const useStateHook = () => {
  const [searchList, setSearchList] = useState([]);
  const [searchLimitError, setSearchLimitError] = useState('');
  const [value, setValue] = useState('');
  const [useContext, setUseContext] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [targetHeight, setTargetHeight] = useState(0);
  const [targetTreeNode, setTargetTreeNode] = useState({});

  const state = {
    searchList,
    searchLimitError,
    value,
    useContext,
    x,
    y,
    targetHeight,
    targetTreeNode,
  };

  const setState = {
    setSearchList,
    setSearchLimitError,
    setValue,
    setUseContext,
    setX,
    setY,
    setTargetHeight,
    setTargetTreeNode,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
