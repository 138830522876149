import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';
import { defaultPagination } from '../util/defaultData';

import {
  FETCH_NAVIGATION_LIST_BY_NAME_START,
  FETCH_NAVIGATION_LIST_BY_NAME_FINISHED,
  SEARCH_NAVIGATION_FILTERS_START,
  SEARCH_NAVIGATION_FILTERS_FINISHED,
  FIND_NAVIGATION_BY_PHRASE_START,
  FIND_NAVIGATION_BY_PHRASE_FINISHED,
  FETCH_NAVIGATION_TO_DUPLICATE_START,
  FETCH_NAVIGATION_TO_DUPLICATE_FINISHED,
  UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_STARTED,
  UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_FINISHED,
  NAVIGATION_CLEAR_CATEGORY_PRODUCT_STATE,
  NAVIGATION_UPDATE_LOADED_PRODUCTS_IN,
  NAVIGATION_UPDATE_LOADED_PRODUCTS_NIN,
  NAVIGATION_COMPLETE_PRODUCTS_LIST_START,
  NAVIGATION_COMPLETE_PRODUCTS_LIST_FINISHED,
  NAVIGATION_SEARCH_BY_PHRASES_START,
  NAVIGATION_SEARCH_BY_PHRASES_FINISHED,
  NAVIGATION_FETCH_PRODUCT_LIST_START,
  NAVIGATION_FETCH_PRODUCT_LIST_FINISHED,
  NAVIGATION_FETCH_PRODUCTS_BY_IDS_START,
  NAVIGATION_FETCH_PRODUCTS_BY_IDS_FINISHED,
  NAVIGATION_SET_SELECTED_PRODUCT_LIST,
  NAVIGATION_SET_PRODUCTS_IN_IDS,
  NAVIGATION_SET_PRODUCTS_NIN_IDS,
  NAVIGATION_SET_CATEGORY_FIELDS,
  NAVIGATION_SET_PRODUCT_CATEGORIES_ATTRIBUTES,
  NAVIGATION_SET_ATTRIBUTES_MATRIX_ATTRIBUTES,
  NAVIGATION_SET_PRODUCT_FILTER,
  NAVIGATION_SET_PRODUCT_COMPOUND_QUERY,
  NAVIGATION_SET_LEFT_SIDE_PAGINATION,
  NAVIGATION_SET_FILTERS,
  NAVIGATION_SET_IS_SWITCH_ON,
  NAVIGATION_SET_IS_APPLY_FILTER_ACTIVE,
  NAVIGATION_SET_MAPPED_CATEGORIES,
  NAVIGATION_SET_SELECTED_CATEGORIES,
  NAVIGATION_SET_PHYSICAL_CATEGORIES_IDS,
  NAVIGATION_SET_SELECTED_TREE_ITEMS,
  NAVIGATION_SET_SHOW_CATEGORIES_DIALOG,
  NAVIGATION_CLEAR_NAVIGATION_FILTER_STATE,
  NAVIGATION_SET_RANKING_RULE_CODE,
  NAVIGATION_SET_SORTING_ERRORS,
  NAVIGATION_SET_SORT_RULES,
  NAVIGATION_SET_EXISTED_SEARCH_PHRASE,
  NAVIGATION_SET_ERRORS,
  NAVIGATION_SET_FILTERS_ERRORS,
  NAVIGATION_SET_IS_DATA_CHANGED,
  NAVIGATION_SET_IS_CATEGORY_SAVING,
  NAVIGATION_SET_FACETS,
  CLEAR_NAVIGATION_SORT,
} from '../actions/types';

const initialState = {
  list: [],
  item: {},
  originalItem: {},
  fetchingByName: false,
  fetchedByName: false,
  product: {
    list: [],
    selectedProductList: { in: [], nin: [] },
    productsInIds: [],
    productsNotInIds: [],
    productsByIdsForIn: [],
    productsByIdsForNin: [],
  },
  phrases: [],
  searchStarted: false,
  searchFinished: false,
  searchResults: [],
  suggestions: [],
  navigationToDuplicate: {},
  updateNavigationMappedCategoriesByVirtualCategoryIdStarted: false,
  updateNavigationMappedCategoriesByVirtualCategoryIdFinished: true,
  filter: {
    filters: [],
    filtersErrors: [],
    productFilter: [],
    isSwitchOn: false,
    productCategoriesAttributes: [{
      code: 'id',
      frontendInputType: 'selectProductsButton',
      id: 'attr_iHeYyv1MRdm1mG',
      isFilterable: true,
      isRequired: false,
      isSearchable: false,
      isSortable: false,
      isVariation: false,
      label: 'Products',
      value: 'id',
    }],
    attributesMatrixAttributes: [],
    product: {
      list: [],
      selectedProductList: { in: [], nin: [] },
      productsInIds: [],
      productsNotInIds: [],
      productsByIdsForIn: [],
      productsByIdsForNin: [],
      compoundSearch: { value: '' },
      leftSidePagination: defaultPagination,
      isProductDialogApplyFilterActive: false,
      categoryFetchProductListStart: false,
      categoryFetchProductListFinished: false,
      fetchProductsByIdsStarted: false,
      fetchProductsByIdsFinished: false,
    },
    category: {
      selectedTreeItems: [],
      showSelectCategoriesDialog: false,
      mappedCategories: [],
      selectedCategories: [],
      selectedDialogCategories: [],
      physicalCategorySearchQuery: '',
      showCategoriesDialog: false,
      physicalCategoriesIds: [],
    },
  },
  sort: {
    rankingRuleCode: null,
    sortingsErrors: [],
    sortRules: [],
  },
  facet: {
    facets: [],
  },
  isDataChanged: false,
  isNavigationSaving: false,
  errors: {},
  existedSearchPhrase: '',
  allPages: 0,
};
const navigation = restTypes('navigation');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case navigation.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case navigation.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case navigation.fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };
    case navigation.fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case navigation.updateStart:
      return {
        ...state,
        ...restStates.updateStart,
      };
    case navigation.updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };
    case navigation.updateMultipleStart:
      return {
        ...state,
        ...restStates.updateMultipleStart,
      };
    case navigation.updateMultipleFinished:
      return {
        ...state,
        ...restStates.updateMultipleFinished,
        ...action.payload,
      };
    case navigation.createStart:
      return {
        ...state,
        ...restStates.createStart,
      };
    case navigation.createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        ...action.payload,
      };
    case FETCH_NAVIGATION_LIST_BY_NAME_START:
      return {
        ...state,
        fetchingByName: true,
        fetchedByName: false,
      };
    case FETCH_NAVIGATION_LIST_BY_NAME_FINISHED:
      return {
        ...state,
        ...action.payload,
        fetchingByName: false,
        fetchedByName: true,
      };
    case navigation.clearOneFinished:
      return {
        ...state,
        ...action.payload,
      };
    case navigation.deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
        ...action.payload,
      };
    case navigation.deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };
    case navigation.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case NAVIGATION_SEARCH_BY_PHRASES_START:
    case FIND_NAVIGATION_BY_PHRASE_START:
      return {
        ...state,
      };
    case NAVIGATION_SEARCH_BY_PHRASES_FINISHED:
    case FIND_NAVIGATION_BY_PHRASE_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case NAVIGATION_FETCH_PRODUCT_LIST_START:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            ...action.payload,
          },
        },
        campaingFetchProductListStart: true,
        campaingFetchProductListFinished: false,
      };
    case NAVIGATION_FETCH_PRODUCT_LIST_FINISHED:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            ...action.payload,
          },
        },
        campaingFetchProductListStart: false,
        campaingFetchProductListFinished: true,
      };

    case NAVIGATION_FETCH_PRODUCTS_BY_IDS_START:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            ...action.payload,
            fetchProductsByIdsStarted: true,
            fetchProductsByIdsFinished: false,
          },
        },
      };
    case NAVIGATION_FETCH_PRODUCTS_BY_IDS_FINISHED:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            ...action.payload,
            fetchProductsByIdsStarted: false,
            fetchProductsByIdsFinished: true,
          },
        },
      };

    case SEARCH_NAVIGATION_FILTERS_START:
      return {
        ...state,
        searchStarted: true,
        searchFinished: false,
      };
    case SEARCH_NAVIGATION_FILTERS_FINISHED:
      return {
        ...state,
        searchStarted: false,
        searchFinished: true,
        searchResults: action.payload.list,
      };

    case FETCH_NAVIGATION_TO_DUPLICATE_START:
    case FETCH_NAVIGATION_TO_DUPLICATE_FINISHED:
    case UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_STARTED:
    case UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_FINISHED:
    case NAVIGATION_SET_CATEGORY_FIELDS:
      return {
        ...state,
        ...action.payload,
      };


    case NAVIGATION_SET_FILTERS_ERRORS:
    case NAVIGATION_SET_PRODUCT_CATEGORIES_ATTRIBUTES:
    case NAVIGATION_SET_ATTRIBUTES_MATRIX_ATTRIBUTES:
    case NAVIGATION_SET_FILTERS:
    case NAVIGATION_SET_IS_SWITCH_ON:
    case NAVIGATION_SET_PRODUCT_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };

    case NAVIGATION_UPDATE_LOADED_PRODUCTS_IN:
    case NAVIGATION_UPDATE_LOADED_PRODUCTS_NIN:
    case NAVIGATION_COMPLETE_PRODUCTS_LIST_START:
    case NAVIGATION_COMPLETE_PRODUCTS_LIST_FINISHED:
    case NAVIGATION_SET_PRODUCTS_NIN_IDS:
    case NAVIGATION_SET_PRODUCTS_IN_IDS:
    case NAVIGATION_SET_IS_APPLY_FILTER_ACTIVE:
    case NAVIGATION_SET_PRODUCT_COMPOUND_QUERY:
    case NAVIGATION_SET_LEFT_SIDE_PAGINATION:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            ...action.payload,
          },
        },
      };

    case NAVIGATION_SET_SELECTED_PRODUCT_LIST:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            selectedProductList: {
              ...action.payload.selectedProductList,
            },
          },
        },
      };

    case NAVIGATION_SET_SHOW_CATEGORIES_DIALOG:
    case NAVIGATION_SET_MAPPED_CATEGORIES:
    case NAVIGATION_SET_SELECTED_CATEGORIES:
    case NAVIGATION_SET_SELECTED_TREE_ITEMS:
    case NAVIGATION_SET_PHYSICAL_CATEGORIES_IDS:
      return {
        ...state,
        filter: {
          ...state.filter,
          category: {
            ...state.filter.category,
            ...action.payload,
          },
        },
      };

    case NAVIGATION_SET_RANKING_RULE_CODE:
    case NAVIGATION_SET_SORTING_ERRORS:
    case NAVIGATION_SET_SORT_RULES:
      return {
        ...state,
        sort: {
          ...state.sort,
          ...action.payload,
        },
      };

    case NAVIGATION_CLEAR_CATEGORY_PRODUCT_STATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...initialState.filter.product,
          },
        },
      };

    case CLEAR_NAVIGATION_SORT:
      return {
        ...state,
        sort: {
          ...initialState.sort,
        },
      };

    case NAVIGATION_CLEAR_NAVIGATION_FILTER_STATE:
      return {
        ...state,
        filter: {
          ...initialState.filter,
          attributesMatrixAttributes: state.filter.attributesMatrixAttributes,
        },
      };

    case NAVIGATION_SET_IS_DATA_CHANGED:
    case NAVIGATION_SET_IS_CATEGORY_SAVING:
    case NAVIGATION_SET_EXISTED_SEARCH_PHRASE:
    case NAVIGATION_SET_ERRORS:
      return {
        ...state,
        ...action.payload,
      };

    case NAVIGATION_SET_FACETS:
      return {
        ...state,
        facet: {
          ...state.facet,
          ...action.payload,
        },
      };

    default: return state;
  }
};
