/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty, uniq } from 'lodash';
import {
  func, shape, array, bool,
} from 'prop-types';
import { FormHelperText } from '@material-ui/core';

import {
  fetchCombinedFacetsOptions,
  fetchHierarchicalFacetsOptions,
} from 'actions/categoryFacet';

import { fetchFacetsAttributeOptions } from 'actions/productAttribute';
import {
  fetchProductList,
  setVirtualFacetFilter,
  setFacetsPreFilters,
} from 'actions/preview';

import IntlMessages from 'util/IntlMessages';

import CombinedFacet from './CombinedFacet';
import HierarchicalFacet from './HierarchicalFacet';
import RegularFacet from './RegularFacet';
import DefaultFacet from './DefaultFacet';

import {
  mapPrefixToFilters,
  mergeFilters,
} from '../utils/mappers';

import { defaultPagination } from '../../../utils/defaultData';

const Facet = (props) => {
  useEffect(() => {
    if (props.categoryFacet.fetched) {
      if (!isEmpty(props.categoryFacet.combinedFacets)) {
        const codes = [];
        const options = [];

        props.categoryFacet.combinedFacets.forEach((facet) => {
          codes.push(facet.facetData.attributes);

          facet.groups.forEach((facetGroup) => {
            options.push(facetGroup.values.flat());
          });
        });

        if (!isEmpty(codes) && !isEmpty(options)) {
          props.fetchCombinedFacetsOptions(
            uniq(codes.flat()), uniq(options.flat()),
          );
        }
      }

      if (!isEmpty(props.categoryFacet.hierarchicalFacets)) {
        const codes = [];
        const options = [];

        props.categoryFacet.hierarchicalFacets.forEach((facet) => {
          codes.push(facet.facetData.attributes);

          facet.groups.forEach((facetGroup) => {
            options.push(facetGroup.values.flat());
          });
        });

        if (!isEmpty(codes) && !isEmpty(options)) {
          props.fetchHierarchicalFacetsOptions(
            uniq(codes.flat()), uniq(options.flat()),
          );
        }
      }
    }
  }, [props.categoryFacet.fetched]);

  useEffect(() => {
    if (props.categoryFacet.fetched) {
      const checkboxFacets = props.categoryFacet.list
        .filter(f => f.inputType === 'checkbox');
      const options = [];

      checkboxFacets.forEach((f) => {
        const facetOptions = f.options.map(o => o.value);
        options.push(facetOptions);
      });

      const codesPayload = uniq(checkboxFacets.map(f => f.key).flat());
      const optionsPayload = uniq(options.flat());

      if (!isEmpty(codesPayload) && !isEmpty(optionsPayload)) {
        props.fetchFacetsAttributeOptions(codesPayload, optionsPayload);
      }
    }
  }, [props.categoryFacet.fetched]);

  const defaultFacetsList = [];

  if (!isEmpty(props.categoryFacet.list)) {
    const defaultFacets = props.categoryFacet.list
      .filter(f => props.defaultAttribute.attributes.categories.some(dA => dA.field === f.key && f.key === 'final_price'));

    defaultFacets.forEach((facetItem, index) => {
      if (facetItem) {
        const attributeWithOrder = {
          ...facetItem,
          order: index,
        };
        defaultFacetsList.push(attributeWithOrder);
      }
    });
  }

  const getVirtualFacets = () => {
    const virtualFacets = {
      filters: props.preview.virtualFacetFilter,
    };
    return virtualFacets;
  };

  const fetchFacetsProducts = (filtersList, virtualFacets = {}) => {
    const filterPayload = [{
      condition: 'or',
      group: filtersList,
    }];

    const newRootFilter = mapPrefixToFilters(mergeFilters(
      filterPayload.filter(f => !isEmpty(f.group)),
      props.preview.rootFilter,
    ));

    props.fetchProductList({
      viewMode: 'frontendWithParams',
      compoundSearch: props.preview.compoundSearch,
      filter: newRootFilter,
      pagination: defaultPagination,
      searchFilter: props.preview.searchQuery,
      virtualFacets,
    });
  };

  const handleChangeFacetCheckbox = (newFilter) => {
    const virtualFacets = getVirtualFacets();
    const filterList = newFilter.filter(f => !isEmpty(f.value));
    props.setFacetsPreFilters(filterList);
    fetchFacetsProducts(filterList, virtualFacets);
  };

  const handleChangeFacetFilter = (newFilter) => {
    const virtualFacets = {
      filters: newFilter,
    };

    props.setVirtualFacetFilter(newFilter);
    fetchFacetsProducts(props.preview.facetsPreFilters, virtualFacets);
  };

  const handleChangeFacetSlider = (newFilter) => {
    const virtualFacets = getVirtualFacets();
    const filterList = newFilter.filter(f => !isEmpty(f.value));
    props.setFacetsPreFilters(filterList);
    fetchFacetsProducts(filterList, virtualFacets);
  };

  const facetListParams = {
    initialCombinedFacetsOptions: props.categoryFacet.initialCombinedFacetsOptions,
    initialHierarchicalFacetsOptions: props.categoryFacet.initialHierarchicalFacetsOptions,
    fetchCombinedFacetsOptions: props.fetchCombinedFacetsOptions,
    fetchHierarchicalFacetsOptions: props.fetchHierarchicalFacetsOptions,
    handleChangeFacetCheckbox,
    onSliderChange: handleChangeFacetSlider,
    handleChangeFacetFilter,
    virtualFacetFilter: props.virtualFacetFilter,
  };


  const showFacet = !isEmpty(defaultFacetsList) && !props.isHiddenProductsMode
    && defaultFacetsList.some(of => !isEmpty(of.options));

  return showFacet && props.categoryFacet.fetched
    ? (
      <div className="facet-content-wrapper">
        {props.categoryFacet.combinedFacets.map(f => (
          <CombinedFacet
            key={f.key}
            facet={f}
            facetListParams={facetListParams}
          />
        ))}
        {props.categoryFacet.hierarchicalFacets.map(f => (
          <HierarchicalFacet
            key={f.key}
            facet={f}
            facetListParams={facetListParams}
          />
        ))}
        {props.categoryFacet.regularFacets.map(f => (
          <RegularFacet
            key={f.key}
            facet={f}
            facetListParams={facetListParams}
          />
        ))}
        {defaultFacetsList.map(f => (
          <DefaultFacet
            key={f.key}
            facet={f}
            filter={props.preview.facetsPreFilters}
            facetListParams={facetListParams}
          />
        ))}
      </div>
    )
    : (
      <FormHelperText className="pl-15">
        <IntlMessages id="facet.helper.emptyFacetOptions" />
      </FormHelperText>
    );
};

Facet.propTypes = {
  categoryFacet: shape({
    list: array,
  }).isRequired,
  defaultAttribute: shape({
    attributes: shape(),
  }).isRequired,
  preview: shape({
    selectedPromotion: shape(),
  }).isRequired,

  isHiddenProductsMode: bool.isRequired,
  virtualFacetFilter: shape().isRequired,

  fetchFacetsAttributeOptions: func.isRequired,
  fetchCombinedFacetsOptions: func.isRequired,
  fetchHierarchicalFacetsOptions: func.isRequired,
  setFacetsPreFilters: func.isRequired,
  fetchProductList: func.isRequired,
  setVirtualFacetFilter: func.isRequired,
};

const mapStateToProps = state => ({
  preview: state.preview,
  defaultAttribute: state.defaultAttribute,
  isHiddenProductsMode: state.preview.isHiddenProductsMode,
  virtualFacetFilter: state.preview.virtualFacetFilter,
  categoryFacet: state.categoryFacet,
  selectedCategoryWidget: state.virtualCategoryWidget.selectedCategory,
});

const mapDispatchToProps = {
  fetchFacetsAttributeOptions,
  fetchCombinedFacetsOptions,
  fetchHierarchicalFacetsOptions,
  setFacetsPreFilters,
  fetchProductList,
  setVirtualFacetFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Facet);
