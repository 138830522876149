import restInitState from '../util/rest/initState';

import {
  TUNE_FETCH_SETTINGS_START,
  TUNE_FETCH_SETTINGS_FINISHED,
  TUNE_CREATE_NEW_AFFILIATE_START,
  TUNE_CREATE_NEW_AFFILIATE_FINISHED,
  TUNE_CREATE_NEW_AFFILIATE_USER_START,
  TUNE_CREATE_NEW_AFFILIATE_USER_FINISHED,
  TUNE_CREATE_OFFER_START,
  TUNE_CREATE_OFFER_FINISHED,
  TUNE_CREATE_GOAL_START,
  TUNE_CREATE_GOAL_FINISHED,
  TUNE_GENERATE_TRACKING_URLS_START,
  TUNE_GENERATE_TRACKING_URLS_FINISHED,
} from '../actions/types';

const initialState = {
  createdAffiliate: {},
  createdAffiliateUser: {},
  createdOffer: {},
  createdGoal: {},
  generatedTrackingUrls: {},
  settings: {},
  affiliateCreating: false,
  affiliateCreated: false,
  affiliateUserCreating: false,
  affiliateUserCreated: false,
  settingsFetching: false,
  settingsFetched: false,
  offerCreating: false,
  offerCreated: false,
  goalCreating: false,
  goalCreated: false,
  trackingGenerating: false,
  trackingGenerated: false,
};

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case TUNE_CREATE_NEW_AFFILIATE_START:
    case TUNE_CREATE_NEW_AFFILIATE_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case TUNE_CREATE_NEW_AFFILIATE_USER_START:
    case TUNE_CREATE_NEW_AFFILIATE_USER_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case TUNE_FETCH_SETTINGS_START:
    case TUNE_FETCH_SETTINGS_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case TUNE_CREATE_OFFER_START:
    case TUNE_CREATE_OFFER_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case TUNE_CREATE_GOAL_START:
    case TUNE_CREATE_GOAL_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case TUNE_GENERATE_TRACKING_URLS_START:
    case TUNE_GENERATE_TRACKING_URLS_FINISHED:
      return {
        ...state,
        ...action.payload,
      };

    default: return state;
  }
};
