export const getCheckCategoryNameFilter = (pathByNameEn, pathByNameHe) => ({
  filter: [{
    condition: 'or',
    group: [{
      field: 'path_by_name.en.keyword',
      operator: 'eq',
      value: pathByNameEn,
    }, {
      field: 'path_by_name.he.keyword',
      operator: 'eq',
      value: pathByNameHe,
    }],
  }],
});

export default {
  getCheckCategoryNameFilter,
};
