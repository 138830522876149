import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  SET_SELECTED_SAVED_FILTER,
  SET_SAVED_FILTERS,
} from '../actions/types';

const initialState = {
  selectedSavedFilter: {
    parent: [], variation: [], merchant: [], feed: [], rule: [], repricing: [], mapper: [], activityLog: [], cams: [], repricer: []
  },
};
const previewFilterRestTypes = restTypes('preview_filter');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case previewFilterRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case previewFilterRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case previewFilterRestTypes.updateMultipleStart:
      return {
        ...state,
        ...restStates.updateMultipleStart,
      };
    case previewFilterRestTypes.updateMultipleFinished:
      return {
        ...state,
        ...restStates.updateMultipleFinished,
      };
    case SET_SELECTED_SAVED_FILTER:
    case SET_SAVED_FILTERS:
      return {
        ...state,
        ...action.payload,
      };

    default: return state;
  }
};
