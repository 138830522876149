/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useRankingEffect = ({
  rankingFetched, fetchRankingList,
}) => {
  useEffect(() => {
    if (!rankingFetched) {
      fetchRankingList();
    }
  }, [rankingFetched]);
};

export default useRankingEffect;
