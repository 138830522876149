import React, { Fragment } from 'react';
import {
  oneOfType, string, node,
} from 'prop-types';
import { Alert } from 'reactstrap';

const AlertComponent = ({
  color, className, header, body, footer,
}) => (
  <Alert className={className} color={color}>
    {header && <h4 className="alert-header">{header}</h4>}
    {body && <div className="alert-body">{body}</div>}
    {footer && (
      <Fragment>
        <hr />
        <div className="alert-footer mb-0">{footer}</div>
      </Fragment>
    )}
  </Alert>
);

AlertComponent.propTypes = {
  color: string,
  className: string,
  header: oneOfType([string, node]),
  body: oneOfType([string, node]),
  footer: oneOfType([string, node]),
};

AlertComponent.defaultProps = {
  color: '',
  className: '',
  header: null,
  body: null,
  footer: null,
};

export default AlertComponent;
