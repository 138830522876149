import { createSelector } from 'reselect';

const virtualCategoryWidgetRootSelector = state => state;

const stateSelector = createSelector(
  virtualCategoryWidgetRootSelector,
  state => ({
    treeItems: state.treeItems,
    selectedCategoryId: state.selectedCategory.id,
    collapse: state.collapse,
    loadedKeys: state.loadedKeys,
  }),
);

export const virtualCategoryWidgetSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  virtualCategoryWidgetSelector,
};
