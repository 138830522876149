/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  memo, useContext, useState, useEffect,
} from 'react';
import {
  func, bool, shape, string, arrayOf,
} from 'prop-types';
import { connect } from 'react-redux';
import {
  isEqual, isEmpty, uniq, cloneDeep,
} from 'lodash';
import swal from '@sweetalert/with-react';
import { NotificationManager } from 'react-notifications';
import { CircularProgress } from '@material-ui/core';

import {
  createPreviewNavigationVirtualFacet,
  updatePreviewNavigationVirtualFacet,
  deletePreviewNavigationVirtualFacet,
  setPreviewNavigationVirtualFacetToDeleteId,
} from 'actions/previewNavigationVirtualFacet';

import { getCamelizeKey } from 'util/virtualFacetManagment';

import {
  setSelectedProductList,
  setIsNavigationSaving,
  setErrors,
  setSortingsErrors,
  setFiltersErrors,
  searchNavigationsByPhrases,
  setExistedSearchPhrase,
  fetchSearchListByName,
  updateSearchFilter,
  clearFromPreviewNavigationState,
} from 'actions/previewNavigation';

import { fetchPromotionList } from 'actions/promotion';
import { fetchProductList, setSelectedSearchFilter } from 'actions/preview';
import { setSelectedPromotion } from 'actions/promotionWidget';


import { navigationFacetSelector } from './selectors/navigationFacet';

import { AbilityContext } from '../../../../../components/AbilityContext';
import ButtonGroup from '../../../../../components/ButtonGroup';

import { rankingRule } from '../../../../../util/constants/constants';
import IntlMessages from '../../../../../util/IntlMessages';
import { mapFiltersByOperator } from '../../../../../util/mapFiltersByOperator';
import checkValidations from '../../../../../util/validator';
import { defaultPagination } from '../../../../../util/defaultData';

import appPermissions from '../../../../../util/appPermissions';
import buttonList from '../../../../../util/buttonList';

import {
  mapFilters,
} from '../../../../../util/attributesMatrixMapping';

import {
  searchPhraseFilterValidations, validateSortings,
  validateFilters, searchPromotionFilterValidations,
} from '../../../utils/validations';

import {
  promotionFilter, promotionPagination,
} from './utils/defaultPromotionData';

const Footer = memo((props) => {
  const [saveAndClose, setSaveAndClose] = useState(false);
  const abilityContext = useContext(AbilityContext);

  const {
    navigationItem,
    originalNavigationItem,
    previewNavigationSort,
    navigationUpdating,
    previewNavigationFilter,
    previewNavigationFacet,
    onClose,
    isNavigationSaving,
    navigationUpdated,
    previewSearchQuery,
    previewCompoundSearch,
    attributesMatrixList,
    facetFilter,
  } = props;

  const handleNotificationSuccess = msg => NotificationManager.success(msg);
  const handleNotificationWarning = msg => NotificationManager.warning(msg);
  const handleNotificationError = msg => NotificationManager.error(msg);

  const handleSetSelectedNavigation = (isPromotion) => {
    const body = {
      ...navigationItem,
      virtualParams: {
        ...navigationItem.virtualParams,
        filters: mapFilters(
          navigationItem.virtualParams.filters,
          attributesMatrixList,
        ),
      },
    };

    if (isPromotion) props.setSelectedPromotion(body);
    else props.setSelectedSearchFilter(body);
  };

  useEffect(() => {
    if (
      navigationUpdated
      || (props.navigationFacet.created || props.navigationFacet.updated)
    ) {
      const isPromotion = navigationItem.type === 'promotion_url';
      handleNotificationSuccess(<IntlMessages id="previewNavigation.navigationUpdated.success" />);
      handleNotificationWarning(
        <IntlMessages id="category.update.alert.warning.notification" />,
      );
      props.setIsNavigationSaving(false);

      props.fetchProductList({
        viewMode: 'frontendWithParams',
        filter: facetFilter,
        compoundSearch: previewCompoundSearch,
        pagination: defaultPagination,
        searchFilter: previewSearchQuery,
      });

      handleSetSelectedNavigation(isPromotion);

      if (isPromotion) props.fetchPromotionList(promotionFilter, promotionPagination);
      if (saveAndClose) onClose();
      setSaveAndClose(false);
      props.clearFromPreviewNavigationState({
        updated: false,
      });
    }
  }, [
    navigationUpdated,
    props.navigationFacet.created,
    props.navigationFacet.updated,
  ]);

  const checkChanges = () => {
    const sortRules = previewNavigationSort.sortRules.map(sr => ({
      field: sr.field,
      direction: sr.defaultValue.toLowerCase(),
    })).filter(el => el.field !== rankingRule);
    const isNavigationChanged = (
      !isEqual(
        navigationItem,
        originalNavigationItem,
      )
      || !isEqual(
        previewNavigationFilter.filters,
        originalNavigationItem.virtualParams.filters,
      )
      || !isEqual(
        sortRules,
        originalNavigationItem.virtualParams.sortRules,
      )
      || !isEqual(
        previewNavigationFilter.category.mappedCategories,
        originalNavigationItem.virtualParams.mappedCategories,
      )
      || !isEqual(
        previewNavigationSort.rankingRuleCode,
        originalNavigationItem.virtualParams.rankingRuleCode,
      )
      || !isEqual(
        previewNavigationFilter.category.selectedCategories,
        originalNavigationItem.virtualParams.selectedCategories,
      )
    );
    const isFacetsChanged = !isEqual(
      props.navigationFacet.list,
      props.navigationFacet.origList,
    );

    return { isNavigationChanged, isFacetsChanged };
  };

  const handleOnClose = () => {
    const { isNavigationChanged, isFacetsChanged } = checkChanges();

    if (isNavigationChanged || isFacetsChanged) {
      swal({
        title: 'Are you sure?',
        text: 'If you leave this page then any unsaved changes will be lost.',
        icon: 'warning',
        dangerMode: true,
        buttons: true,
      }).then((willDiscard) => {
        if (willDiscard) {
          props.setSelectedProductList({ in: [], nin: [] });
          onClose();
        }
      });
    } else {
      props.setSelectedProductList({ in: [], nin: [] });
      onClose();
    }
  };

  const checkSortings = () => {
    let sortingsErrors = [];
    if (!isEmpty(previewNavigationSort.sortRules)) {
      sortingsErrors = validateSortings(previewNavigationSort.sortRules);
    }

    if (previewNavigationSort.sortRules.some(p => p.field === rankingRule)
      && !previewNavigationSort.rankingRuleCode) {
      sortingsErrors = [{ rankingRuleCode: 'helper.fieldCantNotBeBlank' }];
    }

    if (
      (
        !isEmpty(sortingsErrors) && sortingsErrors
          .some(s => s.field === '') && sortingsErrors
          .map(s => s.field === '')
          .filter(el => el).length === sortingsErrors.length
      )
      || (previewNavigationSort.sortRules.some(p => p.field === rankingRule)
        && previewNavigationSort.rankingRuleCode)
    ) {
      sortingsErrors = [];
    }
    return sortingsErrors;
  };

  const getSearchPhrase = searchPhrase => (searchPhrase && Array.isArray(searchPhrase)
    ? searchPhrase
    : searchPhrase.split(',')
      .filter(el => el)
      .map(el => el.trim()));

  const checkSearchFilterValidations = () => {
    let validationRule = { ...searchPhraseFilterValidations };
    if (navigationItem.type === 'promotion_url') {
      validationRule = { ...searchPromotionFilterValidations };
      if (!navigationItem.urlKey || navigationItem.urlKey.length === 1) {
        validationRule.urlKey = validationRule.urlKey.filter(r => r.type === 'minSize');
      }
    }

    if (!navigationItem.name) {
      validationRule.name = validationRule.name.filter(r => r.type === 'isRequired');
    }

    if (navigationItem.name && navigationItem.name.length < 3) {
      validationRule.name = validationRule.name.filter(r => r.type === 'minSize');
    }

    const errors = checkValidations(validationRule, navigationItem);
    const sortingsErrors = checkSortings();

    let filtersErrors = validateFilters(previewNavigationFilter.filters);

    props.setErrors(errors);
    props.setSortingsErrors(sortingsErrors);
    props.setFiltersErrors(filtersErrors);

    const isFiltersErrorsGroupEmpty = filtersErrors.map(f => f.group.map(g => isEmpty(g))).flat();

    if (!isFiltersErrorsGroupEmpty.some(f => !f)) {
      filtersErrors = [];
      props.setFiltersErrors([]);
    }

    if (!isEmpty(errors) || !isEmpty(sortingsErrors) || !isEmpty(filtersErrors)) {
      return false;
    }

    return true;
  };

  const handleFacetDelete = () => {
    if (props.navigationFacet.virtualFacetIdToDelete) {
      props.deletePreviewNavigationVirtualFacet(props.navigationFacet.virtualFacetIdToDelete);
    }
  };

  const virtualFacetManagement = (navigationFacet) => {
    if (
      isEmpty(props.navigationFacet.list)
      || (!navigationFacet.id && !props.navigationFacet.virtualFacetIdToDelete)
    ) {
      if (isEmpty(navigationFacet.groupedValues)) {
        // eslint-disable-next-line no-param-reassign
        delete navigationFacet.groupedValues;
      }
      props.createPreviewNavigationVirtualFacet(navigationFacet);
    } else {
      const payload = {
        ...navigationFacet,
        facetsList: {
          ...navigationFacet.facetsList,
        },
      };

      delete payload.id;
      delete payload.updatedAt;
      delete payload.createdAt;

      if (isEmpty(payload.groupedValues)) {
        delete payload.groupedValues;
      }

      if (!isEmpty(props.navigationFacet.list)) {
        const navigationFacetId = navigationFacet.id
          || props.navigationFacet.virtualFacetIdToDelete;

        props.updatePreviewNavigationVirtualFacet(navigationFacetId, payload);
      }
    }
  };

  const handleOnSaveClick = async ({ closeAfterUpdate }) => {
    props.setIsNavigationSaving(true);
    const searchPhrases = navigationItem.searchPhrase
      ? getSearchPhrase(navigationItem.searchPhrase) : [];

    let filteredSearchPhrases = [];

    if (!isEmpty(navigationItem.searchPhrase)) {
      filteredSearchPhrases = uniq(searchPhrases.filter(
        vsp => !navigationItem.searchPhrase.find(isp => isp === vsp),
      ));
    } else {
      filteredSearchPhrases = uniq(searchPhrases);
    }

    const res = await props.searchNavigationsByPhrases(filteredSearchPhrases);

    const existSearchPhrases = uniq(
      res.map(r => searchPhrases.find(
        sp => r.searchPhrase.find(rsp => rsp === sp),
      )).filter(e => e),
    );
    if (!isEmpty(existSearchPhrases)) {
      const str = existSearchPhrases.join(', ');
      const message = existSearchPhrases.length > 1
        ? `'${str}' phrases are already exist`
        : `'${str}' phrase is already exist`;
      props.setExistedSearchPhrase(message);
      props.setIsNavigationSaving(false);
    }

    if (!isNavigationSaving && isEmpty(existSearchPhrases)) {
      props.setExistedSearchPhrase('');
      props.setIsNavigationSaving(true);

      const { isNavigationChanged, isFacetsChanged } = checkChanges();

      if (!isNavigationChanged && !isFacetsChanged) {
        props.setIsNavigationSaving(false);
        return handleNotificationWarning(
          <IntlMessages id="search.create.alert.warning" />,
        );
      }

      setSaveAndClose(closeAfterUpdate);
      const navigationData = { ...navigationItem };
      const searchDataParams = {};

      if (navigationData.type === 'search_phrase') {
        navigationData.searchPhrase = navigationItem.searchPhrase
          ? getSearchPhrase(navigationItem.searchPhrase) : null;

        delete navigationData.urlKey;
      }

      if (navigationData.type === 'promotion_url') {
        delete navigationData.searchPhrase;
      }

      searchDataParams.mappedCategories = uniq(
        previewNavigationFilter.category.mappedCategories,
      );
      searchDataParams.selectedCategories = uniq(
        previewNavigationFilter.category.selectedCategories,
      );
      searchDataParams.rankingRuleCode = previewNavigationSort.rankingRuleCode || null;
      searchDataParams.facets = previewNavigationFacet.facets;
      searchDataParams.filters = mapFiltersByOperator(previewNavigationFilter.filters);

      if (navigationData.virtualParams && navigationData.virtualParams.positions) {
        searchDataParams.positions = navigationData.virtualParams.positions;
      }

      searchDataParams.sortRules = !isEmpty(previewNavigationSort.sortRules)
        ? previewNavigationSort.sortRules.map(s => ({
          field: s.field,
          direction: s.defaultValue.toLowerCase(),
        })).filter(s => s.field !== rankingRule) : [];
      navigationData.virtualParams = {
        ...navigationData.virtualParams,
        ...searchDataParams,
      };

      const isValid = checkSearchFilterValidations();

      if (
        !isEmpty(props.navigationFacet.list)
        && isFacetsChanged
      ) {
        const categoryFacet = cloneDeep(props.navigationFacet.item);

        const reducedFacet = categoryFacet.facetsList
          .reduce((acc, cur) => ({ ...acc, [getCamelizeKey(cur.label.en)]: cur }), {});

        categoryFacet.facetsList = reducedFacet;
        virtualFacetManagement(categoryFacet);
      }

      if (
        !isEmpty(props.navigationFacet.virtualFacetIdToDelete)
        && isEmpty(props.navigationFacet.list)
      ) {
        handleFacetDelete();
      } else props.setPreviewNavigationVirtualFacetToDeleteId('');


      if (isValid && isNavigationChanged) {
        props.setErrors({});
        const getCheckSearchNameFilter = name => ({
          filter: [{
            group: [{
              field: 'name.keyword',
              value: name,
              operator: 'eq',
            }],
          }],
        });

        props.fetchSearchListByName(getCheckSearchNameFilter(navigationItem.name),
          defaultPagination, (itemAlreadyExist) => {
            const updateParams = { mapToMatrix: true };

            if (navigationData.name !== originalNavigationItem.name && itemAlreadyExist) {
              props.setIsNavigationSaving(false);
              return handleNotificationError(<IntlMessages id="search.create.alert.error" />);
            }
            return props.updateSearchFilter(navigationData.id, navigationData, updateParams);
          });
      } else {
        props.setIsNavigationSaving(false);
      }
    }
    return true;
  };

  const loading = isNavigationSaving
    || navigationUpdating
    || props.navigationFacet.fetching;

  const getListParams = () => {
    let listParams = {
      onCloseClick: handleOnClose,
      disabled: loading,
    };

    const checkPermissions = (permission) => {
      if (abilityContext.can(
        permission,
        appPermissions.navigation.name,
      )) {
        return true;
      }
      return false;
    };

    if (checkPermissions(appPermissions.navigation.permissions.update)) {
      listParams = {
        ...listParams,
        buttons: {
          success: {
            className: 'button-primary',
          },
        },
        onSaveAndCloseClick: () => handleOnSaveClick({ closeAfterUpdate: true }),
        onSaveClick: handleOnSaveClick,
      };
    }

    return buttonList(listParams);
  };

  const buttonOrder = ['close', 'saveAndClose', 'save'];
  const orderedButtons = getListParams()
    .sort((a, b) => buttonOrder.indexOf(a.key) - buttonOrder.indexOf(b.key));

  return (
    <div className="relative preview-drawer-footer">
      {loading && (
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className="progress-warning custom-loader bottom"
          size={20}
          thickness={4}
        />
      )}
      <ButtonGroup
        materialBtn
        className="btn-group-white flex items-center"
        list={orderedButtons}
      />
    </div>
  );
});

Footer.propTypes = {
  navigationItem: shape().isRequired,
  previewNavigationSort: shape().isRequired,
  originalNavigationItem: shape().isRequired,
  previewNavigationFilter: shape().isRequired,
  previewNavigationFacet: shape().isRequired,
  previewSearchQuery: shape().isRequired,
  attributesMatrixList: arrayOf(shape()).isRequired,
  facetFilter: arrayOf(shape()).isRequired,

  createPreviewNavigationVirtualFacet: func.isRequired,
  updatePreviewNavigationVirtualFacet: func.isRequired,
  deletePreviewNavigationVirtualFacet: func.isRequired,

  navigationUpdating: bool.isRequired,
  isNavigationSaving: bool.isRequired,
  navigationUpdated: bool.isRequired,
  previewCompoundSearch: string.isRequired,

  onClose: func.isRequired,
  setSelectedProductList: func.isRequired,
  setIsNavigationSaving: func.isRequired,
  setErrors: func.isRequired,
  setSortingsErrors: func.isRequired,
  setFiltersErrors: func.isRequired,
  searchNavigationsByPhrases: func.isRequired,
  setExistedSearchPhrase: func.isRequired,
  fetchSearchListByName: func.isRequired,
  updateSearchFilter: func.isRequired,
  clearFromPreviewNavigationState: func.isRequired,
  fetchPromotionList: func.isRequired,
  fetchProductList: func.isRequired,
  setSelectedPromotion: func.isRequired,
  setSelectedSearchFilter: func.isRequired,

  navigationFacet: shape().isRequired,
  setPreviewNavigationVirtualFacetToDeleteId: shape().isRequired,
};

Footer.defaultProps = {};

const mapStateToProps = state => ({
  navigationItem: state.previewNavigation.item,
  originalNavigationItem: state.previewNavigation.originalItem,
  navigationUpdating: state.previewNavigation.updating,
  navigationUpdated: state.previewNavigation.updated,
  previewNavigationSort: state.previewNavigation.sort,
  previewNavigationFilter: state.previewNavigation.filter,
  previewNavigationFacet: state.previewNavigation.facet,
  isNavigationSaving: state.previewNavigation.isNavigationSaving,

  previewSearchQuery: state.preview.searchQuery,
  previewCompoundSearch: state.preview.compoundSearch,
  previewSelectedEntityName: state.preview.selectedEntityName,

  navigationFacet: navigationFacetSelector(state.previewNavigationVirtualFacet),

  attributesMatrixList: state.attributesMatrix.list,
});

const mapDispatchToProps = {
  setSelectedProductList,
  setIsNavigationSaving,
  setErrors,
  setSortingsErrors,
  setFiltersErrors,
  searchNavigationsByPhrases,
  setExistedSearchPhrase,
  fetchSearchListByName,
  updateSearchFilter,
  clearFromPreviewNavigationState,
  fetchPromotionList,
  fetchProductList,
  setSelectedPromotion,
  setSelectedSearchFilter,
  createPreviewNavigationVirtualFacet,
  updatePreviewNavigationVirtualFacet,
  deletePreviewNavigationVirtualFacet,
  setPreviewNavigationVirtualFacetToDeleteId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
