import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  CLEAR_USER_STATE,
  USERS_SYNC_START,
  USERS_SYNC_FINISHED,
  CLEAR_FROM_USER_STATE,
} from '../actions/types';

const initialState = {};
const userRestTypes = restTypes('user');
const {
  fetchAllStart,
  fetchAllFinished,
  updateStart,
  updateFinished,
  updateMultipleStart,
  updateMultipleFinished,
  createStart,
  createFinished,
  deleteStart,
  deleteFinished,
} = userRestTypes;

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        list: action.payload,
      };
    case createStart:
      return {
        ...state,
        ...restStates.createStart,
        createdUser: {},
      };
    case createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        ...action.payload,
        hasError: action.payload && action.payload.hasError ? action.payload.hasError : false,
      };
    case updateStart:
      return {
        ...state,
        ...restStates.updateStart,
      };
    case updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        hasError: action.payload ? action.payload.hasError : false,
      };

    case updateMultipleStart:
      return {
        ...state,
        ...restStates.updateMultipleStart,
      };

    case updateMultipleFinished:
      return {
        ...state,
        ...restStates.updateMultipleFinished,
        hasError: action.payload.hasError,
      };
    case deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
      };
    case deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
      };
    case CLEAR_USER_STATE:
      return {
        ...initialState,
        ...restInitState,
      };
    case USERS_SYNC_START:
      return {
        ...state,
        ...action.payload,
      };
    case USERS_SYNC_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_FROM_USER_STATE:
      return {
        ...state,
        ...action.payload,
      };

    default: return state;
  }
};
