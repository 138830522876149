import {
  fetchPromotionList,
  clearFromPromotionState,
  setSelectedPromotion,
  clearPromotionState,
} from 'actions/promotionWidget';

import {
  setVirtualFacetFilter,
} from 'actions/preview';

import { promotionSelector } from './selectors/promotion';

export const mapStateToProps = state => ({
  promotion: promotionSelector(state.promotionWidget),
});

export const mapDispatchToProps = {
  fetchPromotionList,
  clearFromPromotionState,
  clearPromotionState,
  setSelectedPromotion,

  setVirtualFacetFilter,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
