import {
  QUICK_EDIT_SET_DRAWER_STATE,
} from '../actions/types';

const initialState = {
  drawerState: {
    isOpen: false,
  },
};

export default (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case QUICK_EDIT_SET_DRAWER_STATE:
      return {
        ...state,
        ...action.payload,
      };

    default: return state;
  }
};
