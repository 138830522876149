import React, { useState } from 'react';
import { shape, arrayOf } from 'prop-types';
import {
  List, ListItem, ListItemIcon, Checkbox, ListItemText,
} from '@material-ui/core';
import { isEmpty } from 'lodash';

import Slider from 'components/Slider';

const DefaultFacet = (props) => {
  const [sliderValue, setSliderValue] = useState([]);

  const {
    facet, filter, facetListParams,
  } = props;

  const handleChangeSliderValue = (value) => {
    const sameFilter = filter.find(f => f.field === facet.id);
    const priceFilter = [{
      field: facet.id,
      operator: 'between',
      value: {
        from: value[0],
        to: value[1],
      },
    }];
    const newFilter = sameFilter
      ? [...filter.filter(f => f.field !== facet.id), ...priceFilter]
      : [...filter, ...priceFilter];
    facetListParams.onSliderChange(newFilter);
    setSliderValue([value[0], value[1]]);
  };

  const handleCheckboxChange = (value) => {
    const facetCodes = [facet.key];
    const filtersList = facetCodes.map((code) => {
      const sameFilter = filter.find(f => f.field === code);
      const optionAlreadySelected = sameFilter
        ? sameFilter.value.includes(value)
        : false;

      const sameFilterValue = optionAlreadySelected
        ? [...sameFilter.value].filter(v => v !== value)
        : [value];

      const getFilterValue = () => {
        if (sameFilter && optionAlreadySelected) return sameFilterValue;
        if (sameFilter && !optionAlreadySelected) return [...sameFilter.value, value];
        return [value];
      };

      const newFilterValue = getFilterValue();

      return {
        field: code,
        operator: 'in',
        value: newFilterValue,
      };
    });
    const filteredList = [...filter].filter(f => !facetCodes.includes(f.field));
    const payload = [...filteredList, ...filtersList];

    facetListParams.handleChangeFacetCheckbox(payload);
  };

  const getFacetContent = () => {
    const contentClassName = `${facet.inputType}-wrapper`;

    switch (facet.inputType) {
      case 'checkbox':
        return (
          <div className={contentClassName}>
            <List component="div" className="facet-options-list" disablePadding>
              {facet.options.map((o) => {
                const handleChange = () => handleCheckboxChange(o.value);

                return (
                  <ListItem key={o.key} button onClick={handleChange}>
                    <ListItemIcon>
                      <Checkbox
                        edge="end"
                        checked={(
                          filter && filter
                            .some(f => f
                              && f.value
                              && Array.isArray(f.value)
                              && f.value.includes(o.value))
                        )}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={o.label} />
                  </ListItem>
                );
              })}
            </List>
          </div>
        );
      case 'slider':
        return facet.minValue !== Infinity && facet.maxValue !== -Infinity && (
          <div className={contentClassName}>
            <Slider
              name={facet.name}
              onChange={handleChangeSliderValue}
              rangeValue={!isEmpty(sliderValue)
                ? sliderValue
                : [facet.minValue, facet.maxValue]
              }
              min={facet.minValue}
              max={facet.maxValue}
              useRange
              {...props}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="checkbox-sm default-facet">
      <h4 className="facet-title">{facet.label}</h4>
      <div className="default-facet-group" key={`hierarchical_facet_${facet.id}`}>
        {getFacetContent()}
      </div>
    </div>
  );
};

DefaultFacet.propTypes = {
  facet: shape().isRequired,
  facetListParams: shape().isRequired,
  filter: arrayOf(shape()).isRequired,
};

DefaultFacet.defaultProps = {};

export default DefaultFacet;
