/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const usePreviewFilter = ({
  previewFilterProps, actions,
}) => {
  useEffect(() => () => {
    if (previewFilterProps.list.some(l => l.isActive)) {
      const updatedList = previewFilterProps.list.map(l => ({
        ...l,
        isActive: false,
      }));
      actions.updatePreviewFilters(updatedList);
    }
  }, []);

  useEffect(() => {
    if (!previewFilterProps.fetched && !previewFilterProps.fetching) {
      actions.fetchPreviewFilters();
    }
  }, [previewFilterProps.fetched]);

  useEffect(() => {
    if (previewFilterProps.updatedMultiple) {
      actions.fetchPreviewFilters();
    }
  }, [previewFilterProps.updatedMultiple]);
};

export default usePreviewFilter;
