/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { uniq, isEmpty } from 'lodash';
import { decamelizeKeys, camelizeKeys } from 'humps';
import { NotificationManager } from 'react-notifications';
import RestActions from '../util/rest/actions';
import { showErrorNotification } from '../util/api';
import { setIsLoading } from './global';

import {
  PRODUCT_SET_ERRORS,
  PRODUCT_SET_FIELDS,
  PRODUCT_FETCH_CHILD_PRODUCTS_START,
  PRODUCT_FETCH_CHILD_PRODUCTS_FINISHED,
  PRODUCT_FETCH_PRODUCT_COMMENTS_START,
  PRODUCT_FETCH_PRODUCT_COMMENTS_FINISHED,
  PRODUCT_SET_PRODUCT_FOR_EDIT,
  PRODUCT_SET_PRODUCT_CUSTOM_COLUMNS,
  PRODUCT_FINISH_PRODUCT_EDIT,
  PRODUCT_FETCH_PURE_PRODUCT_START,
  PRODUCT_FETCH_PURE_PRODUCT_FINISHED,
  PRODUCT_BULK_UPDATE_ACTIVITY_LOGS_START,
  PRODUCT_BULK_UPDATE_ACTIVITY_LOGS_FINISHED,
  PRODUCT_SET_IS_SAVE_AND_CLOSE,
  PRODUCT_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START,
  PRODUCT_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED,
  PRODUCT_SET_SPECIFICATIONS_ERRORS,
} from './types';

import {
  getActivityLogsByProductId,
  bulkUpdateActivityLogs,
} from '../services/API/activityLog';
import { getBackendProductsByIds } from '../services/API/product';

import {
  productsSearchAppPath,
  getProductsAppPath,
  getDeleteProductLockedAttributesPath,
  getImsUnlockAttributesPath
} from '../util/paths';

import { mapUserDetailsToComment } from '../util/activityLog';
import { IMSClient, CDMSClient, PMACClient } from '../util/api';
import { getResultCondition } from "./pim";
import { mappingResult, mappingAssign } from "./mapper";
import config from "../util/config";

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';
export const cdmsUpdateProduct = (id, updatedPayload) => CDMSClient.put(`/products/${id}`, updatedPayload);
export const cdmsGetProduct = id => CDMSClient.get(`/products/${id}`);
export const cdmsGetProducts = (data) => CDMSClient.post('/products/search?ref=' + ref, data);
export const cdmsMegreProducts = (data, id) => CDMSClient.post(`/products_merge/${id}`, data);
export const cdmsSuggestProducts = (id) => CDMSClient.get(`products_merge_suggest/${id}`);
export const pmacEnrichmentRetriggerData = (data) => PMACClient.put('/enrichment_retrigger_data/bulk', data);
export const imsAddImages = (id, images) => IMSClient.post('/images/bulk', {
  data: [{
    urls: images,
    entity_id: id
  }]
});
export const imsDeleteImages = (images) => IMSClient.delete('/images/bulk', {
  data: {
    data: images
  }
});
export const cdmsDeleteImages = (id, images) => CDMSClient.delete('/products/' + id + '/images', {
  data: {data: {
      images: images
    }}
});
export const imsUpdateProductQty = (prodId, qty) => IMSClient.put('/inventory/bulk', {
  data: [{
    gid: prodId,
    qty
  }]
});
export const imsUnlockAttributes = prodId => IMSClient.delete(getImsUnlockAttributesPath(prodId));
export const cdmsUnlockAttributes = (id, attrs) => CDMSClient.put('/products/' + id + '/locked_attributes', {
  data: {
    locked_attributes: attrs
  }
});
export const cdmsGetProductsByWebId = (id, fields) => CDMSClient.post('/products/search?ref=' + ref, {
  filter: [ { group: [ { field: 'pub_aopi', value: id, operator: 'eq' } ] } ],
  fields: fields,
});
export const cdmsUpdateProductStatus = (parentId, data) =>
  CDMSClient.put('/products/' + parentId + '/stock_status_by_parent', data);

const productActions = new RestActions('product');

const {
  fetchStart,
  fetchFinished,
  updateStart,
  updateFinished,
  mergeStart,
  mergeFinished,
  mergeSearchStart,
  mergeSearchFinished
} = productActions;

export const setErrors = errors => (dispatch) => {
  dispatch({
    type: PRODUCT_SET_ERRORS,
    payload: { errors },
  });
};

export default {
  setErrors,
};

export const setFields = fields => (dispatch) => {
  dispatch({
    type: PRODUCT_SET_FIELDS,
    payload: { fields },
  });
};

export const fetchChildProductList = (filter, pagination) => (dispatch) => {
  dispatch({
    type: PRODUCT_FETCH_CHILD_PRODUCTS_START,
    payload: {
      childProductsFetching: true,
      childProductsFetched: false,
    },
  });

  return axios.post(
    `${productsSearchAppPath}`,
    decamelizeKeys({ filter, pagination }),
  ).then((res) => {
    const { data } = res.data;

    dispatch({
      type: PRODUCT_FETCH_CHILD_PRODUCTS_FINISHED,
      payload: {
        childList: camelizeKeys(data),
        childProductsFetching: false,
        childProductsFetched: true,
      },
    });
  }).catch(error => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: PRODUCT_FETCH_CHILD_PRODUCTS_FINISHED,
      payload: { error: error.response.data, hasErrors: true },
    });
  });
};

export const fetchActivityLogsByProductId = productId => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_FETCH_PRODUCT_COMMENTS_START,
      payload: {
        fetchProductCommentsStart: true,
        fetchProductCommentsFinished: false,
        productComments: [],
      },
    });
    const res = await getActivityLogsByProductId(productId);
    dispatch({
      type: PRODUCT_FETCH_PRODUCT_COMMENTS_FINISHED,
      payload: {
        productComments: camelizeKeys(res),
        fetchProductCommentsStart: false,
        fetchProductCommentsFinished: true,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: PRODUCT_FETCH_PRODUCT_COMMENTS_FINISHED,
      payload: {
        error: error.response.data,
        hasErrors: true,
        fetchProductCommentsStart: false,
        fetchProductCommentsFinished: true,
      },
    });
  }
};

export const nextProductEdit = () => (dispatch) => {
  dispatch({
    type: PRODUCT_FINISH_PRODUCT_EDIT,
    payload: {
      editInProgress: false,
      edit: {},
      item: {},
      info: {},
      fetchedOne: false,
      fetchingOne: false,
      nextProductLoading: true,
      pimOptions: [],
    },
  });
};

export const finishProductEdit = () => (dispatch) => {
  dispatch({
    type: PRODUCT_FINISH_PRODUCT_EDIT,
    payload: {
      editInProgress: false,
      edit: {},
      item: {},
      info: {},
      fetchedOne: false,
      fetchingOne: false,
      nextProductLoading: false,
      pimOptions: [],
    },
  });
};

export const setProductForEdit = payload => (dispatch) => {
  dispatch({
    type: PRODUCT_SET_PRODUCT_FOR_EDIT,
    payload,
  });
};

export const setProductCustomColumns = pimOptions => (dispatch) => {
  dispatch({
    type: PRODUCT_SET_PRODUCT_CUSTOM_COLUMNS,
    payload: {
      pimOptions: pimOptions
    },
  });
};

export const fetchProduct = (id, loadedInfo = null) => async (dispatch) => {
  dispatch(setIsLoading(true));
  dispatch(fetchStart());

  try {
    const res = await cdmsGetProduct(id);
    const { specifications, ...response } = res.data.data;
    const product = camelizeKeys(response);

    const info = loadedInfo !== null ? loadedInfo : { parent: [], variations: [], merchants: [] };

    if (isEmpty(info.parent) && isEmpty(info.variations) && isEmpty(info.merchants)) {
      let childrenIds = [];
      if (product.type === 'parent') {
        info.parent = {
          id: product.id,
          title: product.title,
          images: product.images,
          totalQty: product.totalQty,
        };
        childrenIds = product.childrenIds;
      }

      if (product.type === 'variation' || product.type === 'merchant') {
        let productParentId = product.parentId;
        if (product.type === 'merchant') {
          const variationFilter = [{group: [{field: 'id', value: product.parentId, operator: 'eq'}]}];
          const variationFields = ['parent_id'];
          if (variationFields.length > 0) {
            try {
              const variationRes = await cdmsGetProducts({ filter: variationFilter, fields: variationFields });
              const variationData = camelizeKeys(variationRes.data.data);
              productParentId = variationData[0].parentId;
            } catch (error) {
              showErrorNotification(error, 'CDMS');
            }
          }
        }

        if (productParentId) {
          const parentFilter = [{group: [{field: 'id', value: productParentId, operator: 'eq'}]}];
          const parentFields = ['id', 'children_ids', 'images', 'title', 'total_qty'];
          try {
            const parentRes = await cdmsGetProducts({ filter: parentFilter, fields: parentFields });
            const parentData = camelizeKeys(parentRes.data.data);
            info.parent = {
              id: parentData[0].id,
              title: parentData[0].title,
              images: parentData[0].images,
              totalQty: parentData[0].totalQty,
            };
            childrenIds = parentData[0].childrenIds;
          } catch (error) {
            showErrorNotification(error, 'CDMS');
          }
        }
      }
      if (childrenIds.length > 0 ){
        const variationsFilter = [{ group: [ { field: 'id', value: childrenIds, operator: 'in' } ] }];
        const variationsFields = [
          'id', 'children_ids', 'variation_attributes', 'images', 'price', 'frontend_price', 'stock', 'is_domestic'
        ];
        const variationsSort = [ { field: 'updated_at', order: 'desc' } ];
        try {
          const variationsRes = await cdmsGetProducts({
            filter: variationsFilter, fields: variationsFields, sort: variationsSort
          });
          const variationsData = camelizeKeys(variationsRes.data.data);
          const merchantsIds = [];
          for (let i = 0; i < variationsData.length; i++) {
            info.variations[i] = {
              id: variationsData[i].id,
              images: variationsData[i].images,
              childrenIds: variationsData[i].childrenIds,
              variationAttributes: variationsData[i].variationAttributes,
              price: variationsData[i].price,
              stock: variationsData[i].stock,
              frontendPrice: variationsData[i].frontendPrice,
              isDomestic: variationsData[i].isDomestic,
            };
            for (let j = 0; j < variationsData[i].childrenIds.length; j++) {
              merchantsIds.push(variationsData[i].childrenIds[j]);
            }
          }

          if (merchantsIds.length > 0) {
            const merchantsFilter = [{ group: [ { field: 'id', value: merchantsIds, operator: 'in' } ] }];
            const merchantsFields = ['id', 'parent_id', 'title', 'vendor_name', 'scores'];
            const merchantsSort = [ { field: 'scores.buybox', order: 'desc' } ];
            try {
              const merchantsRes = await cdmsGetProducts(
                { filter: merchantsFilter, fields: merchantsFields, sort: merchantsSort }
              );
              const merchantsData = camelizeKeys(merchantsRes.data.data);
              for (let i = 0; i < merchantsData.length; i++) {
                info.merchants[i] = {
                  id: merchantsData[i].id,
                  title: merchantsData[i].title,
                  vendorName: merchantsData[i].vendorName,
                  parentId: merchantsData[i].parentId,
                  scores: merchantsData[i].scores,
                };
              }
            } catch (error) {
              showErrorNotification(error, 'CDMS');
            }
          }
        } catch (error) {
          showErrorNotification(error, 'CDMS');
        }
      }
    }

    if (product.type === 'variation' && product.pubAopi) {
      const filter = [{ group: [ { field: 'pub_aopi', value: product.pubAopi, operator: 'eq' } ] }];
      const sort = [ { field: 'last_sync', order: 'desc' } ];

      try {
        const condition = getResultCondition(filter, sort, 1, 0);
        const mappingRes = await mappingResult(condition);
        const mappingData = mappingRes.data.data;
        dispatch(fetchFinished({ info, item: { ...product, specifications, mappingData } }));
      } catch (error) {
        showErrorNotification(error, 'PMAC');
        dispatch(fetchFinished({ info, item: { ...product, specifications } }));
      }
    } else {
      dispatch(fetchFinished({ info, item: { ...product, specifications } }));
    }

    if (loadedInfo !== null) {
      setTimeout(() => {
        dispatch(setIsLoading(false));
      }, 1000);
    }

    return product;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch(fetchFinished({
      error: error.response.data, hasErrors: true,
    }));
    dispatch(setIsLoading(false));
  }
};

export const fetchPureProduct = id => (dispatch) => {
  dispatch({
    type: PRODUCT_FETCH_PURE_PRODUCT_START,
    payload: {
      fetchingPureProduct: true,
      fetchedPureProduct: false,
      item: {},
    },
  });
  return axios.get(
    getProductsAppPath(id),
  ).then((res) => {
    const product = camelizeKeys(res.data.data);

    dispatch({
      type: PRODUCT_FETCH_PURE_PRODUCT_FINISHED,
      payload: {
        fetchingPureProduct: false,
        fetchedPureProduct: true,
        item: product,
      },
    });
    return product;
  }).catch(error => {
    showErrorNotification(error, 'CDMS');
  });
};

export const getMergingProductById = ({ id }) => async (dispatch) => {
  dispatch(mergeSearchStart());

  try {
    const res = await cdmsGetProduct(id);
    const data = res.data.data;

    dispatch(mergeSearchFinished({ mergeSearchedProduct: camelizeKeys(data) }));
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const mergeProducts = ({ sendingData, id }) => async (dispatch) => {
  dispatch(mergeStart());

  try {
    const res = await cdmsMegreProducts(sendingData, id);
    const { data } = res.data;

    NotificationManager.success('The Products has been successfully merged');

    dispatch(mergeFinished({ productsMerged: camelizeKeys(data) }));
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const getSuggestProducts = id => async (dispatch) => {
  dispatch(mergeSearchStart());

  try {
    const res = await cdmsSuggestProducts(id);
    const { data } = res.data;

    dispatch(mergeSearchFinished({ mergeSuggestProducts: camelizeKeys(data) }));
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const clearMergeProducts = () => async (dispatch) => {
  dispatch(mergeSearchFinished({
    mergeSearchedProduct: [],
    mergeSuggestProducts: [],
  }));
};

export const updateProduct = ({
  payload, comment, id, ignoreDecamelization,
}) => async (dispatch) => {
  dispatch(updateStart());

  const commentMessage = comment ? { comment } : {};
  const updatedPayload = {
    data: ignoreDecamelization ? payload : decamelizeKeys(payload),
    ...commentMessage,
  };

  if (updatedPayload.data.images) {
    const productImages = [];
    let j = 0;
    for (let i = 0; i < updatedPayload.data.images.length; i++) {
      if (updatedPayload.data.images[i].public_id !== null) {
        productImages[j] = updatedPayload.data.images[i];
        j++;
      }
    }
    updatedPayload.data.images = productImages;
  }

  if (updatedPayload.data.removing_locked_attributes) {
    let isCDMSRequest = true;
    const removingLockedAttributes = updatedPayload.data.removing_locked_attributes
    for (let i = 0; i < removingLockedAttributes.length; i++) {
      if (removingLockedAttributes[i] === 'stock.qty') {
        try {
          const { data: { errors: someErrors } } = await imsUnlockAttributes(id);
          isCDMSRequest = !(someErrors && someErrors.length > 0);
        } catch (error) {
          isCDMSRequest = false;
          showErrorNotification(error, 'IMS');
        }
      }
    }
    if (isCDMSRequest === true) {
      try {
        await cdmsUnlockAttributes(id, removingLockedAttributes);
      } catch (error) {
        showErrorNotification(error, 'CDMS');
      }
    }

    delete updatedPayload.data.removing_locked_attributes;
  }

  try {
    const productRes = await cdmsGetProduct(id);
    const productData = productRes.data.data;

    const availableData = productData.images ? productData.images : null;
    const sendingData = updatedPayload.data.images ? updatedPayload.data.images : null;
    const imagesList = getImagesList(availableData, sendingData);

    const addingImagesData = imagesList.addingImagesData;
    const deletingImagesData = imagesList.deletingImagesData;

    if (addingImagesData.length > 0) {
      const addingRes = await imsAddImages(id, addingImagesData);
      const addingData = (addingRes && addingRes.data) ?  addingRes.data.data : null;

      if (updatedPayload.data.images) {
        for (let i = 0; i < updatedPayload.data.images.length; i++) {
          if (addingData[updatedPayload.data.images[i].url]) {
            const addData = addingData[updatedPayload.data.images[i].url];

            const newUrl = addData.url ? addData.url : null;
            const newPublicId = addData.public_id ? addData.public_id : null;
            if (newUrl && newPublicId) {
              updatedPayload.data.images[i].url = newUrl;
              updatedPayload.data.images[i].public_id = newPublicId;
            } else {
              delete updatedPayload.data.images[i];
            }
          }
        }
      }
    }

    if (deletingImagesData.length > 0) {
      await imsDeleteImages(deletingImagesData);
      await cdmsDeleteImages(id, deletingImagesData);
    }

    const res = await cdmsUpdateProduct(id, updatedPayload);
    const { data } = res.data;

    if (data.type === 'variation' && data.pub_aopi && data.zmid) {
      try {
        const condition = {
          data: {
            model_id: parseInt(data.zmid[0]),
            marketplace: 'zap',
            product_data: {
              pub_aopi: data.pub_aopi
            }
          }
        };

        const mappingRes = await mappingAssign(condition);
        const mappingData = [mappingRes.data.data];

        dispatch(updateFinished({
          edit: camelizeKeys(data),
          item: { ...camelizeKeys(data), mappingData },
        }));

      } catch (error) {
        showErrorNotification(error, 'PMAC');
        const mappingData = [];

        dispatch(updateFinished({
          edit: camelizeKeys(data),
          item: { ...camelizeKeys(data), mappingData },
          hasErrors: true
        }));
      }
    } else {
      dispatch(updateFinished({edit: camelizeKeys(data), item: camelizeKeys(data)}));
    }
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    try {
      const res = await cdmsGetProduct(id);
      const { data } = res.data;
      dispatch(updateFinished({ edit: camelizeKeys(data), item: camelizeKeys(data), hasErrors: true }));
    } catch (error) {
      showErrorNotification(error, 'CDMS');
      dispatch(updateFinished({ hasErrors: true }));
    }
  }
};

export const bulkUpdateProductStatus = (productId, status) => async () => {
  try {
    const payload = {
      data: { stock_status: status},
    };

    const res = await cdmsUpdateProductStatus(productId, payload);
    const { data } = res.data;

    const failedIds = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].stock.status !== status) {
        failedIds.push(data[i].id);
      }
    }

    if (failedIds.length > 0) {
      NotificationManager.warning(
        'Statuses have not been changed for the following products: ' + failedIds.join(', '), null, 0
      );
    } else {
      NotificationManager.success('Statuses have been successfully changed');
    }
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
}

const getImagesList = (availableData, sendingData) => {
  let addingImagesData = [];
  let deletingImagesData = [];
  let count = 0;
  if (availableData && sendingData) {
    for (let i = 0; i < availableData.length; i++) {
      let isImageDeteting = true;
      for (let j = 0; j < sendingData.length; j++) {
        if (availableData[i] && sendingData[j] &&
          availableData[i].url === sendingData[j].url) {
          isImageDeteting = false;
        }
      }

      if (isImageDeteting === true && availableData[i].url !== null) {
        deletingImagesData[count] = availableData[i].url;
        count++;
      }
    }

    count = 0;
    for (let i = 0; i < sendingData.length; i++) {
      let isImageAdding = true;
      for (let j = 0; j < availableData.length; j++) {
        if (sendingData[i] && availableData[j] &&
          sendingData[i].url === availableData[j].url) {
          isImageAdding = false;
        }
      }

      if (isImageAdding === true && sendingData[i].url !== null) {
        addingImagesData[count] = sendingData[i].url;
        count++;
      }
    }

  } else if (sendingData) {
    count = 0;
    for (let i = 0; i < sendingData.length; i++) {
      if (sendingData[i].url !== null) {
        addingImagesData[count] = sendingData[i].url;
        count++;
      }
    }
  }

  return {
    deletingImagesData: deletingImagesData,
    addingImagesData: addingImagesData
  };
};

export const getProductPage = (pubAopi) => {
  cdmsGetProductsByWebId(pubAopi, ['parent_id']).then((res) => {
    const { data } = res.data;

    const parentId = data[0] ? data[0].parent_id : null;
    if (parentId === null) {
      NotificationManager.error('Sorry. Product not found.');

      return false;
    }
    cdmsGetProduct(parentId).then((res) => {
      const { data } = res.data;

      const urlKey = data.url_key ? data.url_key.en : null;
      if (urlKey === null) {
        NotificationManager.error('Sorry. Product not found.');

        return false;
      }
      const productUrl = config.websitePath + '/' + urlKey + '/' + data.id;

      window.open(productUrl);
    }).catch(error => {
      showErrorNotification(error, 'CDMS');
    });
  }).catch(error => {
    showErrorNotification(error, 'CDMS');
  });
};

export const multipleUpdateActivityLogs = logs => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_BULK_UPDATE_ACTIVITY_LOGS_START,
      payload: {
        bulkUpdateActivityLogsStart: true,
        bulkUpdateActivityLogsFinished: false,
      },
    });
    await bulkUpdateActivityLogs(decamelizeKeys(logs));
    dispatch({
      type: PRODUCT_BULK_UPDATE_ACTIVITY_LOGS_FINISHED,
      payload: {
        bulkUpdateActivityLogsStart: false,
        bulkUpdateActivityLogsFinished: true,
      },
    });
    return true;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: PRODUCT_BULK_UPDATE_ACTIVITY_LOGS_FINISHED,
      payload: {
        error: error.response ? error.response.data : '',
        hasErrors: true,
        bulkUpdateActivityLogsStart: false,
        bulkUpdateActivityLogsFinished: false,
      },
    });
    return false;
  }
};

export const multipleUpdateUserActivityLogs = logs => async (dispatch, getState) => {
  const { session: { item } } = getState();
  const userLogs = logs.map(l => ({
    ...l,
    comment: mapUserDetailsToComment(l.comment, item),
  }));
  await multipleUpdateActivityLogs(userLogs)(dispatch);
};

export const setIsSaveAndClose = isSaveAndClose => (dispatch) => {
  dispatch({
    type: PRODUCT_SET_IS_SAVE_AND_CLOSE,
    payload: { isSaveAndClose },
  });
};

export const bulkDeleteProductLockedAttributes = ({
  productIds, fields,
}) => async (dispatch) => {
  dispatch({
    type: PRODUCT_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START,
    payload: {
      bulkDeleteProductLockedAttributesStarted: true,
      bulkDeleteProductLockedAttributesFinished: false,
    },
  });

  try {
    await axios.all(productIds
      .map(id => axios.delete(getDeleteProductLockedAttributesPath(id))));

    const query = {
      filter: [{
        group: [{
          field: 'id',
          value: uniq(productIds),
          operator: 'in',
        }],
      }],
      pagination: {
        page: 1,
        limit: 500,
      },
    };

    if (!isEmpty(fields)) {
      query.fields = uniq([...fields, 'images', 'children_ids', 'type']);
    }

    const updatedProducts = await getBackendProductsByIds(query);

    dispatch({
      type: PRODUCT_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED,
      payload: {
        bulkDeleteProductLockedAttributesStarted: false,
        bulkDeleteProductLockedAttributesFinished: true,
        bulkDeleteSucess: true,
        edit: camelizeKeys(updatedProducts[0]),
      },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: PRODUCT_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED,
      payload: {
        bulkDeleteProductLockedAttributesStarted: false,
        bulkDeleteProductLockedAttributesFinished: true,
        error: error.response.data,
        hasErrors: true,
      },
    });
  }
};

export const sendEnrichmentRetriggerData = (productId, fields) => async () => {
  try {
    const payload = { data: [{ id: productId, fields: fields }] }
    await pmacEnrichmentRetriggerData(payload);

    NotificationManager.success('The request was sent successfully. Please refresh the page after a while');
  } catch (error) {
    showErrorNotification(error, 'PMAC');
  }
};

export const setProductSpecificationErrors = specificationErrors => (dispatch) => {
  dispatch({
    type: PRODUCT_SET_SPECIFICATIONS_ERRORS,
    payload: { specificationErrors },
  });
};
