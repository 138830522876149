import React, { Fragment, memo } from 'react';
import {
  func, bool, arrayOf, shape, string,
} from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';

import MatButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';

import ImportContent from './ImportContent';
import IntlMessages from '../../../../../../../../../util/IntlMessages';

export const ImportDialogComponent = memo(props => (
  <Dialog
    open={props.showDialog}
    aria-labelledby="simple-dialog-title"
    disableBackdropClick
  >
    <DialogTitle id="simple-dialog-title">
      <IntlMessages id="previewPage.importDialog.header" />
    </DialogTitle>
    <DialogContent>
      {props.showImportContent && (
        <Fragment>
          <ImportContent
            setFiles={props.setFiles}
            files={props.files}
            setShowDialog={props.setShowDialog}
            setExportFileDate={props.setExportFileDate}
          />
          {props.showPositionsCountError && (
            <Alert severity="error" className="import-error-content">
              <AlertTitle>Products positions error</AlertTitle>
              <IntlMessages id="previewPage.importDialog.positionsCountError" />
              <IntlMessages id="previewPage.importDialog.positionsCountErrorLimit" />
            </Alert>
          )}
          {props.exportFileDate
            && (
            <div className="flex">
              <IntlMessages id="previewPage.importDialog.lastExportDate" />
              <span className="pl-10 text-ultra-gray">
                {moment(new Date(parseFloat(props.exportFileDate))).format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            </div>
            )}
        </Fragment>
      )}
      {!props.showImportContent && (
        <Fragment>
          <Alert severity="success">
            <AlertTitle>
              <IntlMessages id="previewPage.importDialog.updatedAttributesLabel" />
              <span className="pl-5 font-weight-semi-bold">2</span>
            </AlertTitle>
            <span className="pl-10 updated-attributes-label">Position</span>
            <span className="pl-10 updated-attributes-label">Visibility</span>
          </Alert>
          {/* <span className="pl-5 font-weight-semi-bold">
            {props.exportableFields.length + 1}
          </span> */}
          <div className="flex direction-column updated-attributes pt-10">
            {/* {props.exportableFields.map((f) => {
              const field = f.code.split('.')
                .map(el => el.replace(/_/g, ' '))
                .join(' > ');

              return (
                <span
                  className="pl-10 updated-attributes-label capitalize"
                  key={f.key}
                >
                  {field}
                </span>
              );
            })} */}
          </div>
        </Fragment>
      )}
    </DialogContent>
    <DialogActions>
      {props.showLoader && (
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className="progress-warning loader import-loader bottom"
          size={20}
          thickness={4}
        />
      )}
      {props.showImportContent && (
        <MatButton
          variant="contained"
          color="primary"
          className="text-white"
          onClick={props.handleSubmitClick}
          disabled={isEmpty(props.files)
            || props.importingXLSXWithProducts
            || props.entityUpdating
            || props.showPositionsCountError}
        >
          <IntlMessages id="previewPage.importDialog.uploadButton" />
        </MatButton>
      )}
      <MatButton
        variant="contained"
        color="secondary"
        className="text-white"
        onClick={props.handleCloseDialog}
        disabled={props.importingXLSXWithProducts
          || props.entityUpdating}
      >
        <IntlMessages id="previewPage.importDialog.closeButton" />
      </MatButton>
    </DialogActions>
  </Dialog>
));

ImportDialogComponent.propTypes = {
  showDialog: bool.isRequired,
  setFiles: func.isRequired,
  setShowDialog: func.isRequired,
  handleSubmitClick: func.isRequired,
  handleCloseDialog: func.isRequired,
  files: arrayOf(shape()).isRequired,
  showImportContent: bool,
  showPositionsCountError: bool,
  showLoader: bool,
  importingXLSXWithProducts: bool,
  entityUpdating: bool,
  exportFileDate: string.isRequired,
  setExportFileDate: func.isRequired,
};

ImportDialogComponent.defaultProps = {
  showImportContent: false,
  showPositionsCountError: false,
  showLoader: false,
  importingXLSXWithProducts: false,
  entityUpdating: false,
};

export default ImportDialogComponent;
