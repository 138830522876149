import axios from 'axios';
import { setIsLoading } from '../actions/global';
import { dispatch } from '../store';
import {physicalCategoriesSearchPath} from './paths';

let requestsCount = 0;

const skipLoadingRoutes = [
  physicalCategoriesSearchPath,
];

axios.interceptors.request.use((config) => {
  if (skipLoadingRoutes.some(route => config.url.includes(route))) return config;
  requestsCount += 1;
  dispatch(setIsLoading(true));
  return config;
}, error => Promise.reject(error));

axios.interceptors.response.use((response) => {
  requestsCount -= 1;
  // when all started requests is done
  if (requestsCount <= 0) {
    dispatch(setIsLoading(false));
  }
  return response;
}, (error) => {
  dispatch(setIsLoading(false));
  Promise.reject(error);
});
