import React, { memo } from 'react';
import { connect } from 'react-redux';
import {
  string,
  bool,
} from 'prop-types';
import Loader from '../Loader';

import classes from './styles.module.scss';

const LoaderBlock = ({ text, isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className={classes.root}>
      <Loader className={classes.loader} />
      <div className={classes.message}>
        {text}
      </div>
    </div>
  );
};

LoaderBlock.propTypes = {
  text: string,
  isLoading: bool,
};

LoaderBlock.defaultProps = {
  text: 'FETCHING DATA',
  isLoading: false,
};

const mapStateToProps = state => ({
  isLoading: state.global.isLoading,
});

export default memo(connect(mapStateToProps)(LoaderBlock));
