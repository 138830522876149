import axios from 'axios';
import { camelizeKeys } from 'humps';
import {
  uploadXLSXCloudFuncPath,
  bulkCreateCategoriesCloudFuncPath,
  bulkUpdateCategoriesCloudFuncPath,
  categoriesSearchPath,
  getCategoryPath,
  apiPath,
} from '../util/paths';
import { showErrorNotification } from '../util/api';

import {
  UPLOAD_XML_CATEGORIES_START,
  UPLOAD_XML_CATEGORIES_FINISHED,
  REMOVE_VIRTUAL_CATEGORIES_START,
  REMOVE_VIRTUAL_CATEGORIES_FINISHED,
  CREATE_BULK_VIRTUAL_CATEGORIES_START,
  CREATE_BULK_VIRTUAL_CATEGORIES_FINISHED,
  CLEAR_CATEGORY_IMPORT_STATE,
  UPDATE_BULK_VIRTUAL_CATEGORIES_START,
  UPDATE_BULK_VIRTUAL_CATEGORIES_FINISHED,
} from './types';

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';

const getFBAuthHeader = (cdmsToken) => {
  const token = localStorage.getItem('fb_token');
  const authHeader = {
    Authorization: `Bearer ${token}`,
    'CDMS-Authorization': `Bearer ${cdmsToken}`,
  };
  return authHeader;
};

export const bulkRemoveCategories = () => async (dispatch) => {
  const temporaryFilter = {
    filter: [
      {
        condition: 'and',
        group: [
          {
            field: 'parent_id',
            value: 'NULL',
            operator: 'eq',
          },
        ],
      },
    ],
    pagination: {
      limit: 500,
    },
    fields: [
      'name',
      'has_children',
      'parent_id',
    ],
  };

  try {
    dispatch({ type: REMOVE_VIRTUAL_CATEGORIES_START });
    const path = `${categoriesSearchPath}?ref=` + ref;
    const allVirtualCategories = await axios.post(
      path,
      { ...temporaryFilter },
    );

    const { data } = camelizeKeys(allVirtualCategories.data);
    const temporaryFilteredIds = data
      .map(cat => cat.id);

    Promise.all(temporaryFilteredIds.map(async (catId) => {
      await axios.delete(
        getCategoryPath(catId),
      );
    }));

    dispatch({ type: REMOVE_VIRTUAL_CATEGORIES_FINISHED });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    throw error;
  }
};

export const bulkCreateCategories = mappingsTransformed => async (dispatch, getState) => {
  const cdmsToken = getState().serviceConfig.item.token;
  try {
    dispatch({ type: CREATE_BULK_VIRTUAL_CATEGORIES_START });

    const bulkCreateCategoriesResult = await axios.post(
      bulkCreateCategoriesCloudFuncPath,
      { mappingsTransformed },
      {
        headers: {
          'api-path': apiPath,
          ...getFBAuthHeader(cdmsToken),
        },
      },
    );

    const { result, success } = bulkCreateCategoriesResult.data.result;

    dispatch({
      type: CREATE_BULK_VIRTUAL_CATEGORIES_FINISHED,
      payload: {
        createdTree: result,
        hasErrors: !success,
        error: result.error ? result.error.error : {},
      },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: CREATE_BULK_VIRTUAL_CATEGORIES_FINISHED,
      payload: { createdTree: [], error: error.response.data.error, hasErrors: true },
    });
    throw error;
  }
};

export const uploadXMLWithCategories = xmlBase64String => async (dispatch, getState) => {
  const cdmsToken = getState().serviceConfig.item.token;
  try {
    await dispatch(bulkRemoveCategories());

    dispatch({ type: UPLOAD_XML_CATEGORIES_START });
    const xlsxParseResult = await axios.post(
      uploadXLSXCloudFuncPath, {
        xmlBase64String,
      },
      {
        headers: {
          ...getFBAuthHeader(cdmsToken),
        },
      },
    );
    const { categories } = camelizeKeys(xlsxParseResult.data.result);

    dispatch({
      type: UPLOAD_XML_CATEGORIES_FINISHED,
      payload: { mappingsTransformed: categories },
    });

    await dispatch(bulkCreateCategories(categories));
  } catch (error) {
    showErrorNotification(error, 'Magento');
    throw error;
  }
};

export const clearCategoryImportState = () => (dispatch) => {
  dispatch({
    type: CLEAR_CATEGORY_IMPORT_STATE,
  });
};

export const bulkUpdateCategories = mappingsTransformed => async (dispatch, getState) => {
  const cdmsToken = getState().serviceConfig.item.token;
  try {
    dispatch({ type: UPDATE_BULK_VIRTUAL_CATEGORIES_START });

    const bulkUpdateCategoriesResult = await axios.post(
      bulkUpdateCategoriesCloudFuncPath,
      { mappingsTransformed },
      {
        headers: {
          'api-path': apiPath,
          ...getFBAuthHeader(cdmsToken),
        },
      },
    );

    const { result, success } = bulkUpdateCategoriesResult.data;

    dispatch({
      type: UPDATE_BULK_VIRTUAL_CATEGORIES_FINISHED,
      payload: {
        updatedTree: result,
        hasErrors: !success,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'Magento');
    dispatch({
      type: UPDATE_BULK_VIRTUAL_CATEGORIES_FINISHED,
      payload: { updatedTree: [], error: error.response.data.error, hasErrors: true },
    });
    throw error;
  }
};

export const bulkUpdateHebrewNames = xmlBase64String => async (dispatch, getState) => {
  const cdmsToken = getState().serviceConfig.item.token;
  dispatch({ type: UPLOAD_XML_CATEGORIES_START });
  const xlsxParseResult = await axios.post(
    uploadXLSXCloudFuncPath, {
      xmlBase64String,
    },
    {
      headers: {
        ...getFBAuthHeader(cdmsToken),
      },
    },
  );
  const { categories } = camelizeKeys(xlsxParseResult.data.result);

  dispatch({
    type: UPLOAD_XML_CATEGORIES_FINISHED,
    payload: { mappingsTransformed: categories },
  });

  await dispatch(bulkUpdateCategories(categories));
};

export default {
  uploadXMLWithCategories,
  clearCategoryImportState,
  bulkUpdateHebrewNames,
};
