import axios from 'axios';
import { isEmpty, uniq } from 'lodash';
import { camelizeKeys, decamelizeKeys } from 'humps';
import RestActions from '../util/rest/actions';
import {
  productsBulkAppPath,
  productsFrontAppPath,
  getDeleteProductLockedAttributesPath,
  getCategoryPath,
} from '../util/paths';
import { CDMSClient, showErrorNotification } from '../util/api';

import {
  getValueByOperator,
  getFieldByOperator,
} from '../util/mapFiltersByOperator';

import {
   getCountProductsActivitiesByProductIds,
} from '../services/API/activityLog';

import { generateRequestBody } from '../util/productPayload';
import { getBackendProductsByIds } from '../services/API/product';

import {
  unmapFromAttributesMatrix,
} from '../util/attributesMatrixMapping';
import normalizeFilters from '../util/normalizeFilters';
import { mapPositions } from '../util/mapVirtualParamsPositions';

import { setSelectedCategory as setSelectedWidgetCategory } from './virtualCategoryWidget';

import {
  PREVIEW_SET_SELECTED_PRODUCTS,
  PREVIEW_SET_PRODUCT_FOR_EDIT,
  PREVIEW_CLEAR_PRODUCT_STATE,
  PREVIEW_CLEAR_PARENT_LEVEL_CHILDREN_PRODUCTS,
  PREVIEW_CLEAR_VARIANT_LEVEL_CHILDREN_PRODUCTS,
  PREVIEW_SET_ALL_PRODUCTS_AS_SELECTED,
  PREVIEW_FETCH_PRODUCT_BY_IDS_START,
  PREVIEW_FETCH_PRODUCT_BY_IDS_FINISHED,
  PREVIEW_SET_PRODUCTS_LIST_EMPTY,
  PREVIEW_INJECT_UPDATED_PRODUCTS,
  PREVIEW_FETCH_PRODUCTS_COMMENTS_COUNT_START,
  PREVIEW_FETCH_PRODUCTS_COMMENTS_COUNT_FINISHED,
  PREVIEW_SET_SELECTED_PROMOTION,
  PREVIEW_SET_SELECTED_SEARCH_FILTER,
  PREVIEW_SET_SELECTED_ENTITY_NAME,
  PREVIEW_SET_UPDATED_PRODUCTS_OVERRIDES,
  PREVIEW_SET_UPDATED_PRODUCTS_IMAGES_OVERRIDES,
  SET_PREVIEW_LIST_DIRECTION,
  PREVIEW_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START,
  REVIEW_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED,
  FETCH_SELECTED_CATEGORY_FINISHED,
  FETCH_SELECTED_CATEGORY_START,
  PREVIEW_SET_SELECTED_CATEGORY,
  PREVIEW_SET_CONTAINER_STYLE,
  PREVIEW_SET_EDIT_MODE,
  PREVIEW_SET_PAGINATION,
  PREVIEW_SET_SEARCH_QUERY,
  PREVIEW_SET_DISPLAY_MODE,
  PREVIEW_SET_HIDDEN_PRODUCTS_MODE,
  PREVIEW_SET_COMPOUND_SEARCH,
  PREVIEW_SET_SELECTED_ACTION,
  PREVIEW_SET_VIRTUAL_FACET_FILTER,
  PREVIEW_SET_GLOBAL_SEARCH_VALUE,
  PREVIEW_SET_HIDDEN_PRODUCTS_FILTER,
  PREVIEW_SET_HIDDEN_PRODUCTS_IDS,
  PREVIEW_SET_HIDDEN_PRODUCTS_IS_EXIST,
  PREVIEW_SET_FACETS_PRE_FILTERS,
  PREVIEW_SET_SEARCH_VALUE,
  PREVIEW_SET_MODE,
  PREVIEW_SET_PRODUCTS_OVERRIDE,
  PREVIEW_SET_ACTION_POPOVER_STATE,
  PREVIEW_SET_ACTION_POPOVER_ANCHOR,
  PREVIEW_SET_SELECTED_PRODUCT,
  PREVIEW_SET_PRODUCT_PIN_ORDER_HELPER_TEXT_ID,
  PREVIEW_SET_SELECTED_PIN_ACTION,
  PREVIEW_SET_PRODUCT_PIN_ORDER,
} from './types';

const productActions = new RestActions('preview');

const {
  fetchAllStart,
  fetchAllFinished,
  updateMultipleStart,
  updateMultipleFinished,
  clearFromStateFinished,
} = productActions;

export const fetchProductList = ({
  viewMode,
  filter = [],
  pagination = {},
  searchFilter = {},
  fields = [],
  fullText = {},
  compoundSearch = null,
  ignoreFacets = true,
  virtualFacets = {},
}) => (dispatch, getState) => {
  const { list } = getState().previewFilterAttribute;
  const mappedFilters = filter.map((f) => {
    const group = f.group.map(g => ({
      ...g,
      value: getValueByOperator({ value: g.value, operator: g.operator }),
      field: getFieldByOperator({
        field: g.field, operator: g.operator, previewFilterAttribute: list,
      }),
    }));

    return {
      ...f,
      group,
    };
  });

  const params = {
    ...generateRequestBody({
      viewMode,
      filter: mappedFilters,
      fields,
      pagination,
      fullText,
      searchFilter,
      virtualFacets,
    }),
  };

  if (isEmpty(filter)) {
    delete params.body.filter;
  }

  if (isEmpty(virtualFacets) || isEmpty(virtualFacets.filters)) {
    delete params.body.virtual_facets;
  }

  if (compoundSearch && !isEmpty(compoundSearch) && compoundSearch.value) {
    params.body.compoundSearch = compoundSearch;
  }

  dispatch(fetchAllStart());

  return axios.post(
    params.path,
    decamelizeKeys(params.body),
  ).then((res) => {
    const {
      pages, total, aggregation, aggregation_values, virtual_facet,
    } = res.data;
    const result = res.data.data.map((p) => {
      const specifications = {};
      const variants = [];

      if (p.specifications) {
        Object.keys(p.specifications).forEach((sKey) => {
          specifications[sKey] = p.specifications[sKey].en;
        });
      }

      if (p.variants) {
        const variantsKeys = Object.keys(p.variants);
        variantsKeys.forEach((v) => {
          const item = p.variants[v];
          variants.push(item);
        });
      }

      return {
        ...p,
        specifications,
        variants,
      };
    });

    dispatch(fetchAllFinished(
      {
        list: camelizeKeys(result),
        aggregation,
        aggregationValues: aggregation_values,
        virtualFacet: virtual_facet,
        pages,
        total,
        updated: false,
        updating: false,
        ignoreFacets,
      },
    ));
  }, (error) => {
    dispatch(fetchAllFinished({
      error: error.response.data, hasErrors: true, list: [],
    }));
  });
};

export const toggleSelectedProduct = payload => (dispatch, getState) => {
  const { selected } = getState().preview;
  const selectedProducts = selected.some(s => s.id === payload.id)
    ? selected.filter(s => s.id !== payload.id)
    : [
      ...selected,
      payload,
    ];
  dispatch({
    type: PREVIEW_SET_SELECTED_PRODUCTS,
    payload: selectedProducts,
  });
};


export const setSelectedProducts = payload => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_SELECTED_PRODUCTS,
    payload,
  });
};

export const setAllProductsAsSelected = products => (dispatch, getState) => {
  const { selected } = getState().preview;
  const allProductsAreSelected = products.every(p => selected.some(pr => pr.id === p.id));
  const payload = {
    selected: allProductsAreSelected ? [] : products,
  };

  dispatch({
    type: PREVIEW_SET_ALL_PRODUCTS_AS_SELECTED,
    payload,
  });
};

export const setProductForEdit = payload => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_PRODUCT_FOR_EDIT,
    payload,
  });
};

export const clearProductState = () => (dispatch) => {
  dispatch({ type: PREVIEW_CLEAR_PRODUCT_STATE });
};

export const clearParentLevelChildren = (parent, list) => (dispatch) => {
  const updatedList = list.map((product) => {
    const current = product;

    if (current.id === parent.id) {
      delete current.variants;
    }
    return current;
  });

  dispatch({
    type: PREVIEW_CLEAR_PARENT_LEVEL_CHILDREN_PRODUCTS,
    payload: { list: updatedList },
  });
};

export const clearVariantLevelChildren = (variant, list) => (dispatch) => {
  const parent = list.find(product => product.id === variant.parentId);

  const updatedVariants = parent.variants.map((v) => {
    const currentVariant = v;

    if (currentVariant.id === variant.id) {
      delete currentVariant.merchants;
    }

    return currentVariant;
  });

  const updatedParent = {
    ...parent,
    variants: updatedVariants,
  };

  const updatedList = list.map((product) => {
    const current = product;

    if (current.id === updatedParent.id) {
      delete current.merchants;
    }
    return current;
  });

  dispatch({
    type: PREVIEW_CLEAR_VARIANT_LEVEL_CHILDREN_PRODUCTS,
    payload: { list: updatedList },
  });
};

export const clearFromProductState = payload => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};

export const updateMultipleProducts = (products, comment) => (dispatch) => {
  const updateComment = comment ? { comment } : {};
  dispatch(updateMultipleStart());
  return axios.put(
    productsBulkAppPath,
    { data: [...decamelizeKeys(products)], ...updateComment },
  ).then(() => {
    dispatch(updateMultipleFinished());
  }, (error) => {
    dispatch(updateMultipleFinished({
      error: error.response.data, hasErrors: true,
    }));
  });
};

export const fetchProductsByIdsStart = payload => (dispatch) => {
  dispatch({
    type: PREVIEW_FETCH_PRODUCT_BY_IDS_START,
    payload,
  });
};

export const fetchProductsByIdsFinished = payload => (dispatch) => {
  dispatch({
    type: PREVIEW_FETCH_PRODUCT_BY_IDS_FINISHED,
    payload,
  });
};

export const fetchProductsByIds = (ids, key) => (dispatch) => {
  const currentKey = key || 'productsByIds';

  dispatch(fetchProductsByIdsStart());

  const condition = ids.length > 1
    ? { condition: 'or' }
    : {};

  return axios.post(
    `${productsFrontAppPath}/search`,
    {
      filter: [
        {
          ...condition,
          group: [{
            field: 'id',
            value: uniq(ids),
            operator: 'in',
          }],
        },
      ],
      pagination: {
        page: 1,
        limit: ids.length,
      },
    },
  ).then((res) => {
    dispatch(fetchProductsByIdsFinished({
      [currentKey]: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    dispatch(fetchProductsByIdsFinished({
      error: error.response.data, hasErrors: true,
    }));
  });
};

export const setProductsListEmpty = () => (dispatch) => {
  dispatch({ type: PREVIEW_SET_PRODUCTS_LIST_EMPTY });
};

export const injectProducts = (
  products,
  updatedProduct,
) => (dispatch, getState) => {
  const { list } = getState().preview;
  const variations = list.filter(p => p.variants).map(p => p.variants).flat();
  const productsList = [...list, ...variations];

  const newList = productsList.map((p) => {
    let item = p;
    const newProduct = products.find(product => product.id === p.id);

    if (newProduct) {
      item = newProduct;
    }

    return item;
  });

  const structuredList = newList.filter(p => !p.parentId).map(p => ({
    ...p,
    variants: newList.filter(v => v.parentId === p.id),
  }));

  const data = {
    list: structuredList,
  };

  if (!isEmpty(updatedProduct)) {
    data.item = updatedProduct;
  }

  return dispatch({
    type: PREVIEW_INJECT_UPDATED_PRODUCTS,
    payload: data,
  });
};

export const fetchCountProductsActivitiesByProductIds = productIds => async (
  dispatch,
  getState,
) => {
  const { productsCommentsCount } = getState().preview;

  try {
    dispatch({
      type: PREVIEW_FETCH_PRODUCTS_COMMENTS_COUNT_START,
      payload: {},
    });
    const res = await getCountProductsActivitiesByProductIds(productIds);

    const filteredCurrentProductsCommentsCount = productsCommentsCount
      .filter(c => res.every(r => r.id !== c.id));

    dispatch({
      type: PREVIEW_FETCH_PRODUCTS_COMMENTS_COUNT_FINISHED,
      payload: { productsCommentsCount: [...filteredCurrentProductsCommentsCount, ...res] },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: PREVIEW_FETCH_PRODUCTS_COMMENTS_COUNT_FINISHED,
      payload: { error: error.response.data, hasErrors: true },
    });
  }
};

export const setSelectedPromotion = promotion => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_SELECTED_PROMOTION,
    payload: { selectedPromotion: promotion },
  });
};

export const setSelectedSearchFilter = searchFilter => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_SELECTED_SEARCH_FILTER,
    payload: { selectedSearchFilter: searchFilter },
  });
};

export const setSelectedEntity = entity => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_SELECTED_ENTITY_NAME,
    payload: { selectedEntityName: entity },
  });
};

export const setUpdatedProductsOverrides = productsOverride => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_UPDATED_PRODUCTS_OVERRIDES,
    payload: { productsOverride },
  });
};

export const setUpdatedProductsImagesOverrides = productsImagesOverride => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_UPDATED_PRODUCTS_IMAGES_OVERRIDES,
    payload: { productsImagesOverride },
  });
};

export const setPreviewListDirection = contentDirection => (dispatch) => {
  dispatch({
    type: SET_PREVIEW_LIST_DIRECTION,
    payload: { dir: contentDirection },
  });
};

export const bulkDeleteProductLockedAttributes = ({
  productIds, singleUpdate,
}) => async (dispatch) => {
  dispatch({
    type: PREVIEW_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_START,
    payload: {
      bulkDeleteProductLockedAttributesStarted: true,
      bulkDeleteProductLockedAttributesFinished: false,
    },
  });

  try {
    await axios.all(productIds
      .map(id => axios.delete(getDeleteProductLockedAttributesPath(id))));

    const query = {
      filter: [{
        group: [{
          field: 'id',
          value: uniq(productIds),
          operator: 'in',
        }],
      }],
      pagination: {
        page: 1,
        limit: 500,
      },
    };

    const updatedProducts = await getBackendProductsByIds(query);
    dispatch(injectProducts(
      camelizeKeys(updatedProducts),
      singleUpdate && camelizeKeys(updatedProducts[0]),
    ));

    dispatch({
      type: REVIEW_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED,
      payload: {
        bulkDeleteProductLockedAttributesStarted: false,
        bulkDeleteProductLockedAttributesFinished: true,
        bulkDeleteSucess: true,
        edit: singleUpdate ? camelizeKeys(updatedProducts[0]) : {},
      },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: REVIEW_BULK_DELETE_PRODUCT_LOCKED_ATTRIBUTES_FINISHED,
      payload: {
        bulkDeleteProductLockedAttributesStarted: false,
        bulkDeleteProductLockedAttributesFinished: true,
        error: error.response.data,
        hasErrors: true,
      },
    });
  }
};

export const setSelectedCategory = category => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_SELECTED_CATEGORY,
    payload: { selectedCategory: category },
  });
};

export const fetchSelectedCategory = id => (dispatch, getState) => {
  const { list: attributesMatrix } = getState().attributesMatrix;
  dispatch({
    type: FETCH_SELECTED_CATEGORY_START,
    payload: {
      fetchingSelectedCategory: true,
      fetchedSelectedCategory: false,
      loadingItemId: id,
    },
  });
  return axios.get(
    getCategoryPath(id),
  ).then((res) => {
    const category = normalizeFilters(camelizeKeys(res.data.data));
    const payload = { ...category };

    payload.virtualParams = mapPositions(
      category.virtualParams,
      res.data.data.virtual_params,
    );

    const result = unmapFromAttributesMatrix(payload, attributesMatrix);

    dispatch({
      type: FETCH_SELECTED_CATEGORY_FINISHED,
      payload: {
        fetchingSelectedCategory: false,
        fetchedSelectedCategory: true,
        loadingItemId: '',
      },
    });
    dispatch(setSelectedWidgetCategory(result));
  }, (error) => {
    dispatch({
      type: FETCH_SELECTED_CATEGORY_FINISHED,
      payload: {
        error: error.response.data,
        hasErrors: true,
      },
    });
  });
};

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';
export const exportToEmailById = (id, condition) =>
  CDMSClient.post('/frontend_products_export/search?category_id=' + id + '&ref=' + ref, condition);
export const exportToEmailByUrl = (url, condition) =>
  CDMSClient.post('/frontend_products_export/navigation_by_url_key?url_key=' + url, condition);
export const exportToEmailByPhrase = (phrase, condition) =>
  CDMSClient.post('/frontend_products_export/navigation_by_phrase' + phrase, condition);
export const exportToEmailByFilter = (filterCondition) =>
  CDMSClient.post('/frontend_products_export/search?ref=' + ref, filterCondition);

export const exportToEmail = ({id, urlKey, phrase, email, fields, filter = null}) => async () => {
  const condition = { fields: fields, email: email};
  try {
    if (filter !== null) {
      const filterCondition = { fields: fields, filter: filter, email: email};
      await exportToEmailByFilter(filterCondition);
    } else if (id) {
      await exportToEmailById(id, condition);
    } else if (urlKey) {
      await exportToEmailByUrl(urlKey, condition);
    } else if (phrase) {
      await exportToEmailByPhrase(phrase, condition);
    }
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const setContainerStyles = styles => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_CONTAINER_STYLE,
    payload: { containerStyles: styles },
  });
};

export const setEditMode = isEditMode => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_EDIT_MODE,
    payload: { isEditMode },
  });
};

export const setPagination = pagination => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_PAGINATION,
    payload: { pagination },
  });
};

export const setSearchQuery = searchQuery => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_SEARCH_QUERY,
    payload: { searchQuery },
  });
};

export const setDisplayMode = displayMode => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_DISPLAY_MODE,
    payload: { displayMode },
  });
};

export const setIsHiddenProductsMode = isHiddenProductsMode => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_HIDDEN_PRODUCTS_MODE,
    payload: { isHiddenProductsMode },
  });
};

export const setCompoundSearch = compoundSearch => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_COMPOUND_SEARCH,
    payload: { compoundSearch },
  });
};

export const setSelectedAction = selectedAction => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_SELECTED_ACTION,
    payload: { selectedAction },
  });
};

export const setVirtualFacetFilter = virtualFacetFilter => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_VIRTUAL_FACET_FILTER,
    payload: { virtualFacetFilter },
  });
};

export const setGlobalSearchValue = globalSearchValue => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_GLOBAL_SEARCH_VALUE,
    payload: { globalSearchValue },
  });
};

export const setHiddenProductsFilter = hiddenProductsFilter => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_HIDDEN_PRODUCTS_FILTER,
    payload: { hiddenProductsFilter },
  });
};

export const setHiddenProductsIds = hiddenProductsIds => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_HIDDEN_PRODUCTS_IDS,
    payload: { hiddenProductsIds },
  });
};

export const setHiddenProductsExist = hiddenProductsExist => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_HIDDEN_PRODUCTS_IS_EXIST,
    payload: { hiddenProductsExist },
  });
};

export const setFacetsPreFilters = facetsPreFilters => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_FACETS_PRE_FILTERS,
    payload: { facetsPreFilters },
  });
};

export const setSearchValue = searchValue => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_SEARCH_VALUE,
    payload: { searchValue },
  });
};

export const setMode = mode => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_MODE,
    payload: { mode },
  });
};

export const setProductOverride = productOverride => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_PRODUCTS_OVERRIDE,
    payload: { productOverride },
  });
};

export const setActionPopoverState = showPopover => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_ACTION_POPOVER_STATE,
    payload: { showPopover },
  });
};

export const setActionPopoverAnchor = actionPopoverAnchor => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_ACTION_POPOVER_ANCHOR,
    payload: { actionPopoverAnchor },
  });
};

export const setSelectedProduct = selectedProduct => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_SELECTED_PRODUCT,
    payload: { selectedProduct },
  });
};

export const setProductPinOrderHeplerTextId = productPinOrderHeplerTextId => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_PRODUCT_PIN_ORDER_HELPER_TEXT_ID,
    payload: { productPinOrderHeplerTextId },
  });
};

export const setSelectedPinAction = selectedPinAction => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_SELECTED_PIN_ACTION,
    payload: { selectedPinAction },
  });
};

export const setProductPinOrder = productPinOrder => (dispatch) => {
  dispatch({
    type: PREVIEW_SET_PRODUCT_PIN_ORDER,
    payload: { productPinOrder },
  });
};
