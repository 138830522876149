import React from 'react';
import {
  func, arrayOf, string, shape,
} from 'prop-types';

import { Scrollbars } from 'react-custom-scrollbars';
import { Button } from '@material-ui/core';

import SearchForm from '../../../../components/Header/SearchForm';
import ListVertical from '../../../../components/List/ListVertical';
import IntlMessages from '../../../../util/IntlMessages';
import Can from '../../../../components/Can';
import appPermissions from '../../../../util/appPermissions';

const Sidebar = (props) => {
  const {
    rankingList, onSelectListItem, navigateToNewRanking, search,
  } = props;
  return (
    <>
      <div className="h5 sidebar-title">
        <IntlMessages id="sidebar.rankingsTitle" />
      </div>
      <Can
        do={appPermissions.ranking.permissions.create}
        on={appPermissions.ranking.name}
      >
        <div className="sidebar-group sidebar-group-fixed">
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="text-white block"
            onClick={navigateToNewRanking}
          >
            <IntlMessages id="button.newRanking" />
          </Button>
        </div>
      </Can>
      <Can
        do={appPermissions.ranking.permissions.view}
        on={appPermissions.ranking.name}
      >
        <div className="sidebar-group">
          <div className="list-inline-item search-icon d-inline-block">
            <SearchForm
              value={search.value}
              onChange={search.onChange}
              className="block"
            />
          </div>
        </div>
        <Scrollbars
          className="rct-scroll"
          autoHide
          autoHideDuration={100}
          style={{ height: 'calc(100vh - 210px)' }}
        >
          <div className="sidebar-group">
            <div className="sidebar-list">
              <ListVertical
                className="sidebar-list-item"
                onClick={onSelectListItem}
                list={rankingList.map(l => ({
                  id: l.id,
                  label: l.name,
                }))}
              />
            </div>
          </div>
        </Scrollbars>
      </Can>
    </>
  );
};


Sidebar.propTypes = {
  navigateToNewRanking: func.isRequired,
  onSelectListItem: func,
  rankingList: arrayOf(shape({
    id: string,
    name: string,
  })),
  search: shape({
    onChange: func,
    value: string,
  }),
};

Sidebar.defaultProps = {
  onSelectListItem: null,
  rankingList: [],
  search: {
    onChange: null,
    value: '',
  },
};

export default Sidebar;
