import React from 'react';
import config from '../../util/config';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  string, shape, bool, node, oneOfType,
} from 'prop-types';

import IntlMessages from '../../util/IntlMessages';

const getDisplayString = (sub) => {
  const arr = sub.split('-');
  if (arr.length > 1) {
    return <IntlMessages id={`sidebar.${arr[0].charAt(0) + arr[0].slice(1) + arr[1].charAt(0).toUpperCase() + arr[1].slice(1)}`} />;
  }
  return <IntlMessages id={`sidebar.${sub.charAt(0) + sub.slice(1)}`} />;
};

const getUrlString = (path, sub, index) => {
  if (index === 0) return '/';
  return `/${path.split(sub)[0]}${sub}`;
};

const PageTitleBar = ({
  title, match, enableBreadCrumb, showIcon, titleClassName, name, urlPath, id, pageTitle
}) => {

  const PageTitle = () => {
    const linktoFE = !isEmpty(urlPath) ? config.websitePath + '/' + urlPath + '/' + match.params.categoryId : '';
    if (name && linktoFE) {
      return (
        <>
          <span><IntlMessages id={pageTitle} /></span>
          <span class="separator flex justify-center items-center"> &gt; </span>
          <span>{name.en}</span>
          <span class="header-line-id flex justify-center items-center pl-5">
            (<a target="_blank" rel="noopener noreferrer" href={linktoFE} className="no-wrap">{id}</a>)
          </span>
        </>
      )
    } else {
      return title;
    }
  };

  const path = match.path.substr(1);
  const subPath = path.split('/');
  return (
    <div className="page-title d-flex justify-content-between align-items-center">
      {title && (
        <div className={`page-title-wrap ${titleClassName || ''}`}>
          {showIcon && <i className="ti-angle-left" />}
          <h3 className="h5 flex section-title-main">
            <PageTitle />
          </h3>
        </div>
      )}
      {enableBreadCrumb && (
        <Breadcrumb className="mb-0 tour-step-7" tag="nav">
          {subPath.map((sub, index) => (
            <BreadcrumbItem
              active={subPath.length === index + 1}
              tag={subPath.length === index + 1 ? 'span' : Link}
              key={index}
              to={getUrlString(path, sub, index)}
            >
              {getDisplayString(sub)}
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      )}
    </div>
  );
};

PageTitleBar.propTypes = {
  title: oneOfType([string, node]),
  match: shape(),
  enableBreadCrumb: bool,
  showIcon: bool,
  titleClassName: string,
};

PageTitleBar.defaultProps = {
  title: '',
  match: {},
  enableBreadCrumb: false,
  showIcon: false,
  titleClassName: '',
};

export default PageTitleBar;
