/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useCategory = (state, props) => {
  useEffect(() => {
    props.fetchVirtualRootCategories();
  }, []);

  useEffect(() => () => {
    props.clearState();
  }, []);
};

export default useCategory;
