/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react';
import {
  shape,
} from 'prop-types';

import {
  TableBody, TableCell, TableRow,
} from '@material-ui/core';

import { isEmpty } from 'lodash';

import { SortableElement } from 'react-sortable-hoc';
import ParentLevelItem from '../ParentLevelItem';

import {
  generateParentProductData,
} from '../../pages/Home/utils/productDataGenerator';

import VariantContainer from '../../containers/VariantContainer';

const SortableItem = SortableElement(props => (
  <ParentLevelItem sortableItemProps={props.sortableItemProps} />
));

const SortableList = (props) => {
  const { sortableItemProps } = props;

  return (
    <TableBody>
      {
        sortableItemProps.productList.map((p, index) => {
          const productValue = generateParentProductData(
            p,
            sortableItemProps.defaultImage,
            sortableItemProps.initialAttributesOptions,
            sortableItemProps.sortedAttributes,
          );

          return (
            <Fragment key={p.id}>
              <SortableItem
                index={index}
                sortableItemProps={{
                  ...sortableItemProps,
                  parentValue: productValue,
                  productItem: p,
                  productIndex: index,
                }}
              />
              {
                sortableItemProps.collapsed.includes(p.id) && !isEmpty(p.variants) && (
                  <TableRow key={`${p.id}_variants`}>
                    <TableCell>
                      <VariantContainer
                        parent={p}
                        variants={p.variants}
                        productComments={sortableItemProps.productComments}
                        onMouseEnter={sortableItemProps.onMouseEnter}
                        onMouseLeave={sortableItemProps.onMouseLeave}
                        onEditClick={sortableItemProps.onEditClick}
                        isEditMode={sortableItemProps.isEditMode}
                        tableHeaderWidth={sortableItemProps.tableHeaderWidth}
                        selectedProducts={sortableItemProps.selectedProducts}
                      />
                    </TableCell>
                  </TableRow>
                )
              }
            </Fragment>
          );
        })
      }
    </TableBody>
  );
};

SortableList.propTypes = {
  sortableItemProps: shape().isRequired,
};

SortableList.defaultProps = {};

export default SortableList;
