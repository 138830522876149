/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useContext } from 'react';
import { connect } from 'react-redux';
import {
  func, shape, bool, arrayOf,
} from 'prop-types';
import { isEmpty, uniqBy, uniq } from 'lodash';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

import { virtualCategoryPageSelector } from './selectors/virtualCategoryPage';
import { productAttributeSelector } from './selectors/productAttribute';
import { physicalCategorySelector } from './selectors/physicalCategory';
import { attributesMatrixSelector } from './selectors/attributesMatrix';

import useAttributesMatrixEffects from './hooks/useAttributesMatrix';
import useRankingEffects from './hooks/useRanking';
import useProductAttributeEffects from './hooks/useProductAttribute';
import useProductEffects from './hooks/useProduct';
import usePhysicalCategoryEffects from './hooks/usePhysicalCategory';
import useFilterEffects from './hooks/useFilter';

import FilterBy from 'components/FilterBy';
import SelectCategories from './SelectCategories';
import ProductsPagination from 'components/ProductsPagination';
import { AbilityContext } from 'components/AbilityContext';

import appPermissions from 'util/appPermissions';
import {
  getFilterableAttributes, getUniqueAttributes,
} from 'util/attributesMappers';
import {
  getFilterMatrixAttributes,
} from 'util/attributesMatrixMapping';
import getFilterWithoutProductFilter from 'util/getFilterWithoutProductFilter';
import { mapFrontEndPrefixToFilters } from 'util/filterMapper';
import getMappedProductList from 'util/getMappedProductList';
import getSplitedFilterByQuery from 'util/getSplitedFilterByQuery';
import { defaultPagination } from 'util/defaultData';
import IntlMessages from 'util/IntlMessages';
import {
  searchPhysicalCategoriesParams,
} from 'util/categoriesSearchParams';

import {
  defaultProductAttribute, getParentAttributesData,
} from '../../utils/defaultData';
import mapProductList from '../../utils/mapProductList';

import {
  setProductCategoriesAttributes,
  setAttributesMatrixAttributes,
  setProductsNotInIds,
  setProductsInIds,
  setSelectedProductList,
  updateLoadedProductsIn,
  updateLoadedProductsNin,
  setFilters,
  setProductFilter,
  fetchProductList,
  setCompoundSearch,
  fetchProductsByIds,
  completeProductList,
  setLeftSidePagination,
  setIsSwitchOn,
  setIsProductDialogApplyFilterActive,
  setShowSelectCategoriesDialog,
  setMappedCategories,
  setSelectedCategories,
  setPhysicalCategorySearchQuery,
  setSelectedDialogCategories,
} from 'actions/virtualCategoryPage';

import {
  fetchAttributeOptions,
  fetchAttributesOptions,
  fetchCategoriesAttributes,
  clearCategoriesAttributesState,
} from 'actions/productAttribute';

import {
  search as searchPhysicalCategories,
  fetchCategoriesByIds as fetchPhysicalCategoriesByIds,
} from 'actions/physicalCategory';

import {
  fetchList as fetchRankingList,
} from 'actions/ranking';

const Filter = memo((props) => {
  usePhysicalCategoryEffects({
    fetchedPhysicalCategoriesByIds: props.physicalCategoryState.fetchedPhysicalCategoriesByIds,
    virtualCategoryItem: props.virtualCategoryPageState.virtualCategoryItem,
    physicalCategoriesByIds: props.physicalCategoryState.physicalCategoriesByIds,
    setMappedCategories: props.setMappedCategories,
    setSelectedCategories: props.setSelectedCategories,
  });
  useAttributesMatrixEffects({
    attributesOptions: props.productAttributeState.attributesOptions,
    attributesMatrixFetched: props.attributesMatrixState.attributesMatrixFetched,
    attributesMatrixList: props.attributesMatrixState.attributesMatrixList,
    setAttributesMatrixAttributes: props.setAttributesMatrixAttributes,
    virtualCategoryFetched: props.virtualCategoryPageState.virtualCategoryFetched,
    fetchedAttributeOptions: props.productAttributeState.fetchedAttributeOptions,
    fetchedAttributesOptions: props.productAttributeState.fetchedAttributesOptions,
    attributesMatrixAttributes: props.virtualCategoryPageState.attributesMatrixAttributes,
  });
  useRankingEffects({
    rankingFetched: props.rankingFetched,
    fetchRankingList: props.fetchRankingList,
  });
  useFilterEffects({
    virtualCategoryItem: props.virtualCategoryPageState.virtualCategoryItem,
    virtualCategoryFetched: props.virtualCategoryPageState.virtualCategoryFetched,
    setFilters: props.setFilters,
    updateLoadedProductsIn: props.updateLoadedProductsIn,
    updateLoadedProductsNin: props.updateLoadedProductsNin,
    setProductsInIds: props.setProductsInIds,
    setProductsNotInIds: props.setProductsNotInIds,
    setIsSwitchOn: props.setIsSwitchOn,
  });
  useProductAttributeEffects({
    attributesOptions: props.productAttributeState.attributesOptions,
    categoriesAttributesFetched: props.productAttributeState.categoriesAttributesFetched,
    fetchedAttributeOptions: props.productAttributeState.fetchedAttributeOptions,
    fetchedAttributesOptions: props.productAttributeState.fetchedAttributesOptions,
    categoriesAttributes: props.productAttributeState.categoriesAttributes,
    setProductCategoriesAttributes: props.setProductCategoriesAttributes,
    productCategoriesAttributes: props.virtualCategoryPageState.productCategoriesAttributes,
    virtualCategoryItem: props.virtualCategoryPageState.virtualCategoryItem,
    fetchAttributesOptions: props.fetchAttributesOptions,
    filters: props.virtualCategoryPageState.filters,
    virtualCategoryFetched: props.virtualCategoryPageState.virtualCategoryFetched,
    fetchCategoriesAttributes: props.fetchCategoriesAttributes,
    fetchPhysicalCategoriesByIds: props.fetchPhysicalCategoriesByIds,
  });
  useProductEffects({
    setSelectedProductList: props.setSelectedProductList,
    productsByIdsForNin: props.virtualCategoryPageState.filterProduct.productsByIdsForNin,
    productsByIdsForIn: props.virtualCategoryPageState.filterProduct.productsByIdsForIn,
  });

  const {
    productAttributeState,
    virtualCategoryPageState,
    initialOptions,
  } = props;

  const {
    productCategoriesAttributes,
    attributesMatrixAttributes,
    filters,
    filterProduct,
    filtersErrors,
    isSwitchOn,
    productFilter,
    filterCategory,
  } = virtualCategoryPageState;

  const abilityContext = useContext(AbilityContext);

  const noPermissions = !abilityContext.can(
    appPermissions.category.permissions.update,
    appPermissions.category.name,
  );

  const filtersLoading = (
    virtualCategoryPageState.virtualCategoryFetching
    || productAttributeState.categoriesAttributesFetching
    || productAttributeState.fetchingAttributeOptions
    || productAttributeState.fetchingAttributesOptions
  );

  const searchProductsWithAppliedFilters = (isApplyFilterChecked) => {
    const newFilters = getFilterWithoutProductFilter(filters);
    const filtersWithPrefix = mapFrontEndPrefixToFilters(newFilters);

    const productSearchFilter = isApplyFilterChecked
      ? [...filtersWithPrefix, ...productFilter]
      : [...productFilter];

    props.setLeftSidePagination(defaultPagination);
    props.fetchProductList({
      viewMode: 'frontendWithParams',
      compoundSearch: filterProduct.compoundSearch,
      pagination: defaultPagination,
      searchFilter: {},
      filter: productSearchFilter,
    });
  };

  const handleProductDialogApplyFilterChange = (e) => {
    const { checked } = e.target;
    props.setIsProductDialogApplyFilterActive(checked);
    searchProductsWithAppliedFilters(checked);
  };

  const getSearchSuffix = () => (
    <FormControlLabel
      className="label-clear checkbox-sm"
      control={(
        <Checkbox
          checked={filterProduct.isProductDialogApplyFilterActive}
          onChange={handleProductDialogApplyFilterChange}
          color="primary"
        />
      )}
      label={<IntlMessages id="virtualCategoryPage.selectProductsDialog.search.checkbox.applyFilter" />}
    />
  );

  const handleFiltersChange = (data) => {
    const { productsByIdsForIn, productsByIdsForNin } = filterProduct;
    const {
      filters: newFilters, filter, operator, isProduct, removedFilter,
    } = data;
    const operatorSet = new Set(newFilters.map(f => f.group.map(g => g.operator)).flat());
    const productFilterRemoved = removedFilter && removedFilter.group.find(g => g.field === 'id');

    if (productFilterRemoved && productFilterRemoved.operator === 'nin') {
      props.setProductsNotInIds([]);
      props.updateLoadedProductsNin([]);
      props.setSelectedProductList({
        nin: [],
        in: [...mapProductList(productsByIdsForNin)],
      });
    }
    if (productFilterRemoved && productFilterRemoved.operator === 'in') {
      props.setProductsInIds([]);
      props.updateLoadedProductsIn([]);
      props.setSelectedProductList({
        nin: [...mapProductList(productsByIdsForIn)],
        in: [],
      });
    }

    props.setFilters(newFilters);

    if (
      isProduct
      && (isEmpty(productsByIdsForIn) || isEmpty(productsByIdsForNin))
      && !(operatorSet.has('in') && operatorSet.has('nin'))
    ) {
      const productIds = filter.options.flat();
      if (operator === 'nin') {
        props.setProductsNotInIds(productIds);
        props.setProductsInIds([]);
      }
      if (operator === 'in') {
        props.setProductsInIds(productIds);
        props.setProductsNotInIds([]);
      }

      props.setSelectedProductList({
        nin: [...mapProductList(productsByIdsForIn)],
        in: [...mapProductList(productsByIdsForNin)],
      });
      props.updateLoadedProductsIn(productsByIdsForNin);
      props.updateLoadedProductsNin(productsByIdsForIn);
    }
  };

  const handleSelectProductsDialogOpen = () => {
    let newProductFilter = [];
    let productFilters = [];

    const newArray = [
      ...filterCategory.selectedCategories,
      ...filterCategory.mappedCategories,
    ].map(c => c.id);
    const physicalCategoriesIds = [...newArray];

    if (filterProduct.isProductDialogApplyFilterActive) {
      productFilters = mapFrontEndPrefixToFilters(getFilterWithoutProductFilter(filters));
    }

    if (!isEmpty(physicalCategoriesIds) && isSwitchOn) {
      newProductFilter = [{
        group: [{
          field: 'categories',
          value: physicalCategoriesIds,
          operator: 'in',
        }],
      }];
    }
    props.setProductFilter(newProductFilter);
    props.fetchProductList({
      viewMode: 'frontendWithParams',
      compoundSearch: filterProduct.compoundSearch,
      pagination: filterProduct.leftSidePagination,
      filter: [...newProductFilter.flat(), ...productFilters].flat(),
    });
  };

  const handleSelectProductsDialogClose = () => {
    const updatedFilters = filters.map(f => ({
      ...f,
      group: f.group.map((g) => {
        const isProductFilter = g.field === 'id';
        if (isProductFilter) {
          return {
            ...g,
            value: !isEmpty(filterProduct.selectedProductList[g.operator])
              ? filterProduct.selectedProductList[g.operator].map(p => p.id)
              : [],
          };
        }
        return g;
      }),
    }));
    props.setCompoundSearch({ value: '' });
    props.setFilters(updatedFilters);
  };

  const handleSearchSubmit = (query) => {
    const splitedFilter = getSplitedFilterByQuery(query);
    const compoundSearch = { value: splitedFilter.queryString.join(', ') };

    props.setCompoundSearch(compoundSearch);
    props.setLeftSidePagination(defaultPagination);

    const productsGroupFilter = !isEmpty(splitedFilter.productIds)
      ? [{
        group: [{
          field: 'id',
          value: uniq(splitedFilter.productIds),
          operator: 'in',
        }],
      }]
      : [];

    const newFilters = getFilterWithoutProductFilter(filters);
    const filtersWithPrefix = mapFrontEndPrefixToFilters(newFilters);

    const productSearchFilter = filterProduct.isProductDialogApplyFilterActive
      ? [...filtersWithPrefix, ...productFilter]
      : [...productFilter];

    props.fetchProductList({
      viewMode: 'frontendWithParams',
      compoundSearch,
      pagination: defaultPagination,
      searchFilter: {},
      filter: [...productSearchFilter, ...productsGroupFilter].flat(),
    });
  };

  const handleSelectedListChange = (selectedProductList, key) => {
    props.setSelectedProductList({
      ...filterProduct.selectedProductList,
      [key]: selectedProductList,
    });
    if (key === 'in') {
      props.setProductsInIds(selectedProductList.map(p => p.id));
      props.updateLoadedProductsIn(selectedProductList);
    } else {
      props.setProductsNotInIds(selectedProductList.map(p => p.id));
      props.updateLoadedProductsNin(selectedProductList);
    }
  };

  const handleDialogSelectClick = () => {
    const { selectedProductList } = filterProduct;
    if (!isEmpty(selectedProductList.in) || !isEmpty(selectedProductList.nin)) {
      const newFilters = [...filters.map((f) => {
        if (f.group && !isEmpty(f.group)) {
          return ({
            ...f,
            condition: f.group && f.group.length > 1 ? 'or' : 'and',
            group: f.group.map((g) => {
              if (g.field === 'id') {
                return ({
                  ...g,
                  value: uniq(selectedProductList[g.operator].map(p => p.id)),
                });
              } return g;
            }),
          });
        } return f;
      })];
      props.setFilters([...newFilters]);
    }
  };

  const onLeftSidePaginate = ({ selected }) => {
    const updatedPagination = {
      ...filterProduct.leftSidePagination,
      page: selected + 1,
    };

    const newFilters = getFilterWithoutProductFilter(filters);
    const filtersWithPrefix = mapFrontEndPrefixToFilters(newFilters);

    const productSearchFilter = filterProduct.isProductDialogApplyFilterActive
      ? [...filtersWithPrefix, ...productFilter]
      : [...productFilter];

    props.setLeftSidePagination(updatedPagination);
    props.fetchProductList({
      viewMode: 'frontendWithParams',
      compoundSearch: filterProduct.compoundSearch,
      filter: productSearchFilter,
      pagination: updatedPagination,
      searchFilter: {},
    });
  };

  const toggleSelectCategoriesDialog = () => {
    props.setShowSelectCategoriesDialog(!filterCategory.showSelectCategoriesDialog);
  };

  const updateSelectedCategory = () => {
    let newProductFilter = [];
    const { mappedCategories, selectedCategories } = filterCategory;
    const mergedCategories = [...mappedCategories, ...selectedCategories];
    const mergedCategoriesIds = mergedCategories.map(c => c.id);

    if (!isEmpty(mergedCategories)) {
      props.fetchCategoriesAttributes(uniq(mergedCategories.map(mc => mc.id)));
    } else {
      props.clearCategoriesAttributesState();
    }

    if (!isEmpty(mergedCategoriesIds)) {
      newProductFilter = [{
        group: [{
          field: 'categories',
          value: mergedCategoriesIds,
          operator: 'in',
        }],
      }];
    }
    if (isEmpty(mergedCategoriesIds)) {
      props.setProductCategoriesAttributes([defaultProductAttribute]);
    }
    props.setProductFilter(newProductFilter);
    toggleSelectCategoriesDialog();
  };

  const handleOnSelectCategoriesSearchSubmit = (query) => {
    if (query) {
      const queryItems = query.split(',').map(q => q.trim()).filter(i => i !== '');
      props.searchPhysicalCategories(searchPhysicalCategoriesParams(queryItems));
    }
    props.setPhysicalCategorySearchQuery(query);
  };

  const handleOnTransferChange = ({ targetList }) => {
    if (isSwitchOn) {
      props.setMappedCategories(targetList);
    } else {
      props.setSelectedCategories(targetList);
    }
    props.setSelectedDialogCategories(targetList);
  };

  const handleSwitchClick = (isOn) => {
    if (isOn) {
      props.setMappedCategories(filterCategory.selectedCategories);
    } else {
      props.setSelectedCategories(filterCategory.mappedCategories);
    }
    props.setIsSwitchOn(isOn);
  };

  const {
    productsByIdsForIn,
    productsByIdsForNin,
    completedCategoryProductsList,
    fetchProductsByIdsFinished,
  } = filterProduct;

  const listIn = uniqBy([
    ...productsByIdsForIn,
  ], 'id');

  const listNin = uniqBy([
    ...productsByIdsForNin,
  ], 'id');

  const switchList = completedCategoryProductsList
    && fetchProductsByIdsFinished;

  const mappedListIn = switchList ? getMappedProductList(listIn) : mapProductList(listIn);
  const mappedListNin = switchList ? getMappedProductList(listNin) : mapProductList(listNin);

  const attributesList = uniqBy([
    defaultProductAttribute,
    ...getUniqueAttributes(
      getFilterableAttributes(productCategoriesAttributes),
      getFilterMatrixAttributes(attributesMatrixAttributes),
    ),
    ...getFilterMatrixAttributes(attributesMatrixAttributes),
  ], 'code');

  const parentAttributesData = getParentAttributesData(
    props.virtualCategoryPageState.virtualCategoryItem.parentVirtualParams,
    initialOptions
  );
  const parentFilters = parentAttributesData ? parentAttributesData.parentFilters : [];
  const parentOptions = parentAttributesData ? parentAttributesData.parentOptions : [];

  return (
    <FormGroup className="">
      {parentFilters && parentFilters.length > 0 && (
        <>
          <FilterBy
            key="parent-filter"
            filterContentClass="direction-column"
            disabled={true}
            isParent={true}
            parentOptions={parentOptions}
            hasPermissions={false}
            loading={filtersLoading}
            productAttributes={attributesList}
            fetchAttributeOptions={props.fetchAttributeOptions}
            fetchAttributesOptions={props.fetchAttributesOptions}
            filters={parentFilters}
            errors={filtersErrors}
            selectProductsSettings={{}}
          />
          <br/><br/>
        </>
      )}

      <FilterBy
        key="filter"
        filterContentClass="direction-column"
        disabled={noPermissions || filtersLoading}
        hasPermissions={!noPermissions}
        loading={filtersLoading}
        productAttributes={attributesList}
        fetchAttributeOptions={props.fetchAttributeOptions}
        fetchAttributesOptions={props.fetchAttributesOptions}
        filters={filters}
        parentFilters={parentFilters}
        onChange={handleFiltersChange}
        errors={filtersErrors}
        showAddFilterButton
        selectCategoriesButton={(
          <SelectCategories
            showSelectCategoriesDialog={filterCategory.showSelectCategoriesDialog}
            onUpdateSelectedCategory={updateSelectedCategory}
            searched={props.physicalCategoryState.searched}
            searchResults={props.physicalCategoryState.searchResults}
            onSelectCategoriesSearchSubmit={handleOnSelectCategoriesSearchSubmit}
            mappedCategories={[...filterCategory.mappedCategories]}
            selectedCategories={[...filterCategory.selectedCategories]}
            onTransferChange={handleOnTransferChange}
            onToggleSelectCategoriesDialog={toggleSelectCategoriesDialog}
            physicalCategorySearchQuery={filterCategory.physicalCategorySearchQuery}
            errorMessage="text.errors.lessThan500Characters"
            queryLengthLimit={500}
            disabled={noPermissions}
          />
        )}
        selectProductsSettings={{
          onOpen: handleSelectProductsDialogOpen,
          onClose: handleSelectProductsDialogClose,
          sourceList: mapProductList(filterProduct.list) || [],
          listIn: mappedListIn || [],
          listNin: mappedListNin || [],
          listInIds: filterProduct.productsInIds || [],
          listNinIds: filterProduct.productsNotInIds || [],
          completingCategoryProductsList: filterProduct.completingCategoryProductsList,
          fetchProductsByIdsStarted: filterProduct.fetchProductsByIdsStarted,
          fetchProductsByIds: props.fetchProductsByIds,
          onSearchSubmit: handleSearchSubmit,
          onSelectedListChange: handleSelectedListChange,
          onDialogSelectClick: handleDialogSelectClick,
          completeProductList: props.completeProductList,
          loading: {
            leftList: filterProduct.categoryFetchProductListStart,
            rightList: filterProduct.fetchProductsByIdsStarted,
          },
          leftPagination: (
            <ProductsPagination
              containerClassName="pagination-sm pagination-abs"
              onPaginate={onLeftSidePaginate}
              product={filterProduct}
              pagination={filterProduct.leftSidePagination}
            />
          ),
          search: {
            suffix: getSearchSuffix(),
          },
        }}
        onSwitchClick={handleSwitchClick}
        isSwitchOn={isSwitchOn}
      />
    </FormGroup>
  );
});

Filter.propTypes = {
  setProductCategoriesAttributes: func.isRequired,
  fetchAttributeOptions: func.isRequired,
  fetchAttributesOptions: func.isRequired,
  setFilters: func.isRequired,
  setProductsNotInIds: func.isRequired,
  setProductsInIds: func.isRequired,
  setSelectedProductList: func.isRequired,
  updateLoadedProductsIn: func.isRequired,
  updateLoadedProductsNin: func.isRequired,
  setProductFilter: func.isRequired,
  fetchProductList: func.isRequired,
  setCompoundSearch: func.isRequired,
  fetchProductsByIds: func.isRequired,
  completeProductList: func.isRequired,
  setLeftSidePagination: func.isRequired,
  setIsSwitchOn: func.isRequired,
  setIsProductDialogApplyFilterActive: func.isRequired,
  fetchCategoriesAttributes: func.isRequired,
  clearCategoriesAttributesState: func.isRequired,
  setShowSelectCategoriesDialog: func.isRequired,
  setMappedCategories: func.isRequired,
  setSelectedCategories: func.isRequired,
  searchPhysicalCategories: func.isRequired,
  setPhysicalCategorySearchQuery: func.isRequired,
  setSelectedDialogCategories: func.isRequired,
  fetchRankingList: func.isRequired,
  fetchPhysicalCategoriesByIds: func.isRequired,

  virtualCategoryPageState: shape().isRequired,
  productAttributeState: shape().isRequired,
  physicalCategoryState: shape().isRequired,
  attributesMatrixState: shape().isRequired,

  rankingFetched: bool.isRequired,
  setAttributesMatrixAttributes: func.isRequired,
  initialOptions: arrayOf(shape()).isRequired,
};

Filter.defaultProps = {};

const mapStateToProps = state => ({
  virtualCategoryPageState: virtualCategoryPageSelector(state.virtualCategoryPage),
  productAttributeState: productAttributeSelector(state.productAttribute),
  physicalCategoryState: physicalCategorySelector(state.physicalCategory),
  attributesMatrixState: attributesMatrixSelector(state.attributesMatrix),
  rankingFetched: state.ranking.fetchedOne,
  initialOptions: state.productAttribute.initialOptions,
});

const mapDispatchToProps = {
  setProductCategoriesAttributes,
  setAttributesMatrixAttributes,
  fetchAttributeOptions,
  fetchAttributesOptions,
  setFilters,
  setProductsNotInIds,
  setProductsInIds,
  setSelectedProductList,
  updateLoadedProductsIn,
  updateLoadedProductsNin,
  setProductFilter,
  fetchProductList,
  setCompoundSearch,
  fetchProductsByIds,
  completeProductList,
  setLeftSidePagination,
  setIsSwitchOn,
  setIsProductDialogApplyFilterActive,
  fetchCategoriesAttributes,
  clearCategoriesAttributesState,
  setShowSelectCategoriesDialog,
  setMappedCategories,
  setSelectedCategories,
  searchPhysicalCategories,
  setPhysicalCategorySearchQuery,
  setSelectedDialogCategories,
  fetchRankingList,
  fetchPhysicalCategoriesByIds,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
