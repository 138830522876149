import React, { Component } from 'react';
import swal from 'sweetalert';

import { connect } from 'react-redux';
import MatButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { NotificationManager } from 'react-notifications';
import { FormGroup, Label, Input } from 'reactstrap';
import {
  isEmpty, sortBy, isEqual, uniq,
} from 'lodash';

import ButtonGroup from '../../../../components/ButtonGroup';
import RctCollapsibleCard from '../../../../components/RctCollapsibleCard/RctCollapsibleCard';
import PageTitleBar from '../../../../components/PageTitleBar/PageTitleBar';
import IntlMessages from '../../../../util/IntlMessages';
import InputErrors from '../../../../components/InputErrors';
import Popover from '../../../../components/Popover';
import buttonList from '../../../../util/buttonList';
import { rankingsAppPath } from '../../../../util/paths';
import AttributesRow from '../../components/AttributesRow';
import ScopeDialog from '../../../../components/ScopeDialog';
import Can from '../../../../components/Can';

import checkValidations from '../../../../util/validator';
import { newRankingValidations, rowAttributeValidations } from '../../utils/validations';
import { propTypes, defaultProps } from './propTypes';
import { mapStateToProps, actionCreators } from './connect';
import { AbilityContext } from '../../../../components/AbilityContext';
import appPermissions from '../../../../util/appPermissions';

const addRankGroupItemKey = (rank) => {
  const updatedRank = { ...rank };
  if (rank && rank.group) {
    updatedRank.group = rank.group.map((g, index) => ({
      ...g,
      key: index,
    }));
  }
  return updatedRank;
};

class Edit extends Component {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  constructor(props) {
    super(props);
    this.state = {
      collapsed: [],
      selectedTreeItems: [],
      changedRanking: {
        name: '',
        group: [{
          field: '',
          normalization: '',
          pushing: '',
          weight: 0,
          isLocked: false,
        }],
      },
      errors: {},
      attributesErrors: {},
      showScopeDialog: false,
      closePage: false,
      categoriesWithRankingRule: [],
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.props.fetchRanking(match.params.rankingId);
    this.props.fetchRootScopeCategories();
  }

  componentDidUpdate(prevProps) {
    const prevRankingId = prevProps.match.params.rankingId;
    const { rankingId } = this.props.match.params;
    const { ranking: prevRanking } = prevProps;
    const { ranking } = this.props;

    if (prevRankingId && rankingId
      && prevRankingId !== rankingId
      && !ranking.fetchingOne) {
      this.props.fetchRanking(rankingId);
    }

    if (prevRankingId && rankingId
      && prevRankingId !== rankingId) {
      this.props.clearFromScopeCategoryState({
        searchFilterResults: [],
        fetched: false,
        treeItems: [],
        fetchedScopeCategoriesByIds: false,
        categoriesByIds: [],
        collapse: [],
        loadedKeys: [],
      });
      this.props.fetchRootScopeCategories();
    }

    if (ranking.fetchedOne && prevProps.ranking.fetchingOne) {
      this.props.fetchRankingCategories(ranking.item.name);
    }

    if (ranking.hasErrors && !prevRanking.hasErrors) {
      const title = ranking.error.response.statusText;
      this.handleNotificationWarning(<IntlMessages id="rankings.update.alert.reject" />, title);
    }

    if (ranking.updated && prevRanking.updating) {
      this.props.fetchRankingCategories(ranking.item.name);
      setTimeout(() => {
        this.postUpdatingAction();
      }, 1000);
    }

    if (ranking.bulkUpdateCategoriesFinished && prevRanking.bulkUpdateCategoriesStart) {
      this.props.fetchRankingCategories(ranking.item.name);
    }

    if (ranking.deleted && !prevRanking.deleted) {
      this.handleNotificationSuccess(<IntlMessages id="rankings.delete.alert.success" />);
      this.props.fetchRankingList();
      this.goToRankingsPage();
    }

    if (prevProps.scopeCategory.fetchingScopeCategoriesByIds
    && this.props.scopeCategory.fetchedScopeCategoriesByIds) {
      this.checkForRankingRule(this.props.scopeCategory.categoriesByIds);
      this.updateSelectedCategories();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { ranking, rankingCategory } = nextProps;
    const {
      rankingItemFetched,
      rankingItemUpdated,
      rankingCategoriesFetched,
    } = prevState;

    let updatedState = {};

    if (!ranking.fetchedOne && rankingItemFetched) {
      updatedState.rankingItemFetched = false;
    }

    if (ranking.fetchedOne && rankingItemFetched !== ranking.fetchedOne) {
      updatedState = {
        ...updatedState,
        changedRanking: addRankGroupItemKey({ ...ranking.item }),
        rankingItemFetched: ranking.fetchedOne,
      };
    }

    if (!ranking.updated && rankingItemUpdated) {
      updatedState = {
        ...updatedState,
        rankingItemUpdated: false,
      };
    }

    if (ranking.updated && !rankingItemUpdated) {
      updatedState = {
        ...updatedState,
        changedRanking: addRankGroupItemKey({ ...ranking.item }),
        rankingItemUpdated: true,
      };
    }

    if (!rankingCategory.fetched && rankingCategoriesFetched) {
      updatedState = {
        ...updatedState,
        rankingCategoriesFetched: false,
      };
    }

    if (rankingCategory.fetched && !rankingCategoriesFetched) {
      updatedState = {
        ...updatedState,
        selectedTreeItems: [...rankingCategory.list],
        rankingCategoriesFetched: true,
      };
    }

    return Object.keys(updatedState).length > 0 ? updatedState : null;
  }

  componentWillUnmount() {
    const { itemToDelete } = this.state;
    this.props.clearFetchedRankingState();
    this.props.clearUpdatedRankingState();
    this.props.clearDeletedRankingState();
    this.props.clearScopeCategoriesState();
    if (itemToDelete) {
      this.props.deleteRankFromState(itemToDelete.id);
    }
  }

  updateSelectedCategories = () => {
    this.setState({
      selectedTreeItems: this.props.scopeCategory.categoriesByIds,
    });
  }

  postUpdatingAction = () => {
    this.handleNotificationSuccess(<IntlMessages id="rankings.update.alert.success" />);
    this.props.fetchRankingList();
    if (this.state.closePage) {
      this.goToRankingsPage();
    }
  }

  mapRankingIdToScopeCategory = (
    rankingName,
    categories,
  ) => categories.map(s => ({
    id: s.id,
    name: s.name,
    description: s.description,
    isEnabled: true,
    includeInMenu: true,
    virtualParams: {
      ...s.virtualParams,
      rankingRuleCode: rankingName,
      sortRules: [],
    },
  }));

  handleNotificationSuccess = (msg, title = null) => {
    NotificationManager.success(msg, title);
  }

  handleNotificationWarning = (msg, title = null) => {
    NotificationManager.warning(msg, title);
  }

  openScopeDialog = () => {
    this.setState({
      showScopeDialog: true,
    });
  }

  closeScopeDialog = () => {
    this.setState({
      showScopeDialog: false,
    });
    this.props.clearScopeCategorySearchState();
  }

  addNewAttribute = () => {
    const { changedRanking } = this.state;
    const newChangedRanking = { ...changedRanking };
    const fulfilledRanking = {
      ...newChangedRanking,
      group: [
        ...newChangedRanking.group,
        {
          field: '',
          normalization: '',
          pushing: '',
          weight: 0,
          isLocked: false,
          key: new Date().getTime(),
        },
      ],
    };

    this.setState({
      changedRanking: fulfilledRanking,
    });
  }

  goToRankingsPage = () => {
    this.props.history.push(rankingsAppPath);
  }

  onRankingClose = () => {
    const { changedRanking } = this.state;

    if (isEqual(changedRanking, this.props.ranking.item)) {
      this.goToRankingsPage();
    } else {
      swal({
        title: 'Are you sure?',
        text: 'If you leave this page then any unsaved changes will be lost.',
        icon: 'warning',
        dangerMode: true,
        buttons: true,
      })
        .then((willDiscard) => {
          if (willDiscard) {
            this.goToRankingsPage();
          }
        });
    }
  }

  handleBeforeSaveClick = (boolean) => {
    const { categoriesWithRankingRule } = this.state;

    if (!isEmpty(categoriesWithRankingRule)) {
      const content = document.createElement('span');
      content.innerHTML = `These categories already have a ranking rule:
      <br><strong>${categoriesWithRankingRule.map(r => `${r.en}`).join(', ')}</strong>
      <br>Are you sure you want to overwrite the Ranking rule in these categories?`;

      return swal({
        title: 'Notice',
        content,
        icon: 'warning',
        dangerMode: true,
        buttons: true,
      })
        .then((willDiscard) => {
          if (willDiscard) {
            this.saveRanking(boolean);
          }
        });
    }
    return this.saveRanking(boolean);
  }

  handleNotificationWarning = msg => NotificationManager.warning(msg);

  handleSave = ({
    excludedCategories, includedCategories, changedRanking, closeAfterUpdate,
  }) => {
    const rankingName = changedRanking.name;
    const categories = this.mapRankingIdToScopeCategory(
      rankingName,
      includedCategories,
    );

    if (!isEmpty(excludedCategories)) {
      const payload = excludedCategories.map(s => ({
        id: s.id,
        isEnabled: true,
        includeInMenu: true,
        name: s.name,
        description: s.description,
        virtualParams: {
          ...s.virtualParams,
          rankingRuleCode: null,
        },
      }));
      this.props.bulkUpdateVirtualCategories(payload, { mapToMatrix: false });
    }

    if (!isEmpty(includedCategories)) {
      this.props.bulkUpdateVirtualCategories(categories, { mapToMatrix: false });
    }
    this.props.updateRank(changedRanking, { isSync: true });

    if (closeAfterUpdate) {
      this.setState({
        closePage: true,
      });
    }
  }

  handleBeforeSave = ({
    includedCategories, excludedCategories, changedRanking, closeAfterUpdate,
  }) => {
    const categoriesWithSortRules = includedCategories
      .filter(c => !isEmpty(c.virtualParams.sortRules));

    if (!isEmpty(categoriesWithSortRules)) {
      const content = document.createElement('span');
      content.innerHTML = `This action will clear the sorting rules in the following categories:
      <br><strong>${categoriesWithSortRules.map(c => `${c.name.en}`).join(', ')}</strong>`;

      swal({
        title: 'Confirmation',
        content,
        icon: 'warning',
        dangerMode: true,
        buttons: true,
      })
        .then((isAccepted) => {
          if (isAccepted) {
            this.handleSave({
              includedCategories,
              excludedCategories,
              changedRanking,
              closeAfterUpdate,
            });
          }
        });
    } else {
      this.handleSave({
        includedCategories,
        excludedCategories,
        changedRanking,
        closeAfterUpdate,
      });
    }
  }

  saveRanking = (closeAfterUpdate) => {
    const { changedRanking, selectedTreeItems } = this.state;
    const initialCategories = this.props.rankingCategory.list;
    const excludedCategories = initialCategories
      .filter(item => selectedTreeItems
        .every(c => c.id !== item.id));
    const includedCategories = selectedTreeItems
      .filter(item => initialCategories
        .every(c => item.id !== c.id));
    const rankingWasChanged = !isEqual(
      sortBy(changedRanking),
      sortBy(this.props.ranking.item),
    ) || !isEmpty(excludedCategories)
      || !isEmpty(includedCategories);

    if (!rankingWasChanged) {
      return this.handleNotificationWarning(
        <IntlMessages id="ranking.create.alert.warning" />,
      );
    }

    if (this.validate(changedRanking)) {
      if (changedRanking.name === this.props.ranking.item.name) {
        this.handleBeforeSave({
          includedCategories, excludedCategories, changedRanking, closeAfterUpdate,
        });
      } else {
        this.props.fetchRankingByName(changedRanking.name, (isExist) => {
          if (!isExist) {
            const categories = this.mapRankingIdToScopeCategory(
              changedRanking.name,
              initialCategories,
            );

            if (isEmpty(includedCategories) && !isEmpty(categories)) {
              this.props.bulkUpdateVirtualCategories(categories, { mapToMatrix: false });
            }

            this.handleBeforeSave({
              includedCategories, excludedCategories, changedRanking, closeAfterUpdate,
            });
          } else {
            NotificationManager.error(<IntlMessages id="ranking.update.alert.error" />);
          }
        });
      }
    }
    return true;
  }

  handleAttributesErrors = (attributes) => {
    const attributesErrors = {};
    attributes.forEach((item, index) => {
      const errors = checkValidations(rowAttributeValidations, item);
      if (Object.keys(errors).length > 0) {
        attributesErrors[index] = errors;
      }
    });

    return attributesErrors;
  }

  count = names => names.reduce((a, b) => Object.assign(a, { [b]: (a[b] || 0) + 1 }), {});

  checkOnDuplicates = dict => Object.keys(dict).filter(a => dict[a] > 1);

  validate = (data) => {
    const form = {
      isCompleted: data.group.map(item => item.weight).reduce((a, b) => a + b, 0),
      name: data.name,
    };
    const errors = checkValidations(newRankingValidations, form);

    const items = data.group.map(r => r.field);
    const countDuplicatedAttributes = this.checkOnDuplicates(this.count(items)).length;
    if (countDuplicatedAttributes > 0) {
      errors.hasDuplicates = [{
        type: 'duplicated',
        message: 'rankings.errors.hasDuplicates',
      }];
    }

    const attributesErrors = this.handleAttributesErrors(data.group);

    this.setState({
      errors,
      attributesErrors,
    });
    if (Object.keys(errors).length > 0 || Object.keys(attributesErrors).length > 0) {
      return false;
    }
    return true;
  }

  deleteRanking = async () => {
    const { ranking: { item } } = this.props;

    const pagination = { page: 1, limit: 500 };
    const fields = ['name', 'virtual_params'];
    const navFields = ['name', 'search_phrase', 'url_key', 'virtual_params', 'type'];
    const filter = [{
      group: [{
        field: 'virtual_params.ranking_rule_code',
        operator: 'eq',
        value: item.name,
      }],
    }];
    const categoriesQuery = {
      pagination,
      fields,
      filter,
    };
    const navigationsQuery = {
      pagination,
      fields: navFields,
      filter,
    };

    const relatedCategories = await this.props.searchCategoriesByRankingRule(categoriesQuery);
    const relatedNavigations = await this.props.searchNavigationsByRankingRule(navigationsQuery);
    const categoriesNames = relatedCategories.map(c => c.name.en);
    const navigationsNames = relatedNavigations.map(c => c.name);

    const categoriesPayload = relatedCategories.map(c => ({
      id: c.id,
      virtualParams: {
        ...c.virtualParams,
        rankingRuleCode: null,
      },
    }));

    const searchFiltersPayload = relatedNavigations
      .filter(n => n.type === 'search_phrase').map(n => ({
        id: n.id,
        searchPhrase: n.searchPhrase,
        type: n.type,
        name: n.name,
        virtualParams: {
          ...n.virtualParams,
          rankingRuleCode: null,
        },
      }));
    const promotionsPayload = relatedNavigations
      .filter(n => n.type === 'promotion_url').map(n => ({
        id: n.id,
        urlKey: n.urlKey,
        type: n.type,
        name: n.name,
        virtualParams: {
          ...n.virtualParams,
          rankingRuleCode: null,
        },
      }));
    const navigationsPayload = [...searchFiltersPayload, ...promotionsPayload];

    swal({
      title: 'Are you sure?',
      text: `
        Are you sure you want to permanently delete this Ranking?
        This action will affect all related categories and navigations.

        ${!isEmpty(categoriesNames) ? `Affected categories: ${categoriesNames.join(', ')}` : ''}
        ${!isEmpty(navigationsNames) ? `Affected navigations: ${navigationsNames.join(', ')}` : ''}
      `,
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    })
      .then((isAccepted) => {
        if (isAccepted) {
          if (!isEmpty(categoriesPayload)) {
            this.props.bulkUpdateVirtualCategories(categoriesPayload, { mapToMatrix: false });
          }
          if (!isEmpty(navigationsPayload)) {
            this.props.bulkUpdateNavigations(navigationsPayload, { mapToMatrix: false });
          }
          this.deleteRankingAccepted(item);
        }
      });
  }

  deleteRankingAccepted = (itemToDelete) => {
    this.props.deleteRank(itemToDelete.id);
  }

  deleteAttribute = (rowIndex) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to permanently delete this attribute?',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    })
      .then((willDiscard) => {
        if (willDiscard) {
          this.deleteAttributeConfirmed(rowIndex);
        }
      });
  }

  deleteAttributeConfirmed = (rowIndexToDelete) => {
    const { changedRanking } = this.state;
    const newChangedRanking = { ...changedRanking };
    const splicedChangedRanking = {
      ...changedRanking,
      group: newChangedRanking.group
        .filter((f, index) => index !== rowIndexToDelete),
    };

    this.setState(prevState => ({
      ...prevState,
      rowIndexToDelete: null,
      changedRanking: splicedChangedRanking,
    }));
  }

  onSlide = ({ value, rowIndex }) => {
    const { changedRanking } = this.state;
    const newChangedRanking = { ...changedRanking };
    const newGroup = newChangedRanking.group.map((g) => {
      let group = g;

      if (newChangedRanking.group.indexOf(group) === rowIndex) {
        group = {
          ...group,
          weight: value,
        };
      }
      return group;
    });

    this.setState(prevState => ({
      changedRanking: {
        ...prevState.changedRanking,
        group: newGroup,
      },
    }));
  };

  onChange = ({ event, rowIndex }) => {
    const { value, name } = event.target;
    const { changedRanking } = this.state;
    const newChangedRanking = { ...changedRanking };

    const newGroup = newChangedRanking.group.map((g) => {
      let group = g;

      if (newChangedRanking.group.indexOf(group) === rowIndex) {
        group = {
          ...group,
          [name]: value,
        };
      }
      return group;
    });

    this.setState(prevState => ({
      changedRanking: {
        ...prevState.changedRanking,
        group: newGroup,
      },
    }));
  };

  updateRankingName = ({ target: { value } }) => {
    const { changedRanking } = this.state;
    const newChangedRanking = { ...changedRanking, name: value };

    this.setState(prevState => ({
      changedRanking: newChangedRanking,
      errors: {
        ...prevState.errors,
        name: '',
      },
    }));
  };

  checkForRankingRule = (categories) => {
    const categoriesWithRankingRule = categories
      .filter(cat => cat.virtualParams.rankingRuleCode)
      .map(cat => cat.name);

    this.setState({ categoriesWithRankingRule });
  }

  processCategoriesSelection = (selected) => {
    this.setState({
      showScopeDialog: false,
      selectedTreeItems: selected,
    }, () => {
      const initialCategories = this.props.rankingCategory.list;
      const { selectedTreeItems } = this.state;

      if (!isEqual(
        initialCategories,
        selectedTreeItems,
      )) {
        if (!isEmpty(selected)) {
          this.props.fetchScopeCategoriesByIds(uniq(selected));
        }
      }
    });
  };

  commitCheckedCategories = (selected) => {
    const { selectedTreeItems } = this.state;

    const categoriesWereUnselected = !selectedTreeItems.every(c => selected.includes(c));

    if (categoriesWereUnselected) {
      swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to permanently delete the Ranking Rule from the unselected categories?',
        icon: 'warning',
        dangerMode: true,
        buttons: true,
      })
        .then((isAccepted) => {
          if (isAccepted) {
            this.processCategoriesSelection(selected);
          } else {
            this.processCategoriesSelection(selectedTreeItems.map(c => c.id));
          }
        });
    } else {
      this.processCategoriesSelection(selected);
    }
  }

  handleToggleCollapse = collapsed => this.setState({ collapsed });

  handleSearchScopeCategory = (query) => {
    this.props.searchScopeCategories({
      full_text: {
        fields: [
          'name.en',
        ],
        value: query,
        match_type: 'must',
      },
      pagination: {
        page: 1,
        limit: 500,
      },
    });
  };

  getAttributesOptions = () => ([...this.props.rankingAttribute.list.map(aItem => ({
    ...aItem,
    disabled: this.state.changedRanking.group.some(item => item.field === aItem.value),
  }))] || []);

  getHeaderLine = () => {
    const { name, id } = this.props.ranking.item;

    if (id && name) {
      return (
        <>
          <span className="separator flex justify-center items-center"> &gt; </span>
          <>
            <span>{name}</span>
            <span className="header-line-id flex justify-center items-center pl-5">{`(${id})`}</span>
          </>
        </>
      );
    }
    return '';
  };

  render() {
    const {
      showScopeDialog,
      errors,
      rankingItemFetched,
      attributesErrors,
      selectedTreeItems,
      changedRanking,
    } = this.state;
    const ranking = { ...changedRanking };
    const {
      match, pushing, normalization, rankingAttribute, scopeCategory,
      bulkUpdateCategoriesStart, bulkUpdateNavigationsStart,
    } = this.props;

    const {
      updating, deleting, fetchRankingByNameStarted, fetchingOne,
    } = this.props.ranking;

    let listParams = {
      onCloseClick: this.onRankingClose,
      disabled: (
        updating
        || deleting
        || scopeCategory.fetchingScopeCategoriesByIds
        || fetchRankingByNameStarted
      ),
    };

    if (this.context.can(
      appPermissions.ranking.permissions.update,
      appPermissions.ranking.name,
    )) {
      listParams = {
        ...listParams,
        onSaveAndCloseClick: () => this.handleBeforeSaveClick(true),
        onSaveClick: () => this.handleBeforeSaveClick(false),
      };
    }

    if (this.context.can(
      appPermissions.ranking.permissions.delete,
      appPermissions.ranking.name,
    )) {
      listParams = {
        ...listParams,
        onDeleteClick: this.deleteRanking,
      };
    }
    const noPermissions = !this.context.can(
      appPermissions.ranking.permissions.update,
      appPermissions.ranking.name,
    );

    const noEventClass = (
      updating
      || fetchingOne
      || updating
      || deleting
      || bulkUpdateCategoriesStart
      || bulkUpdateNavigationsStart
    ) ? 'no-event disabled' : '';

    return (
      <div className={`container-rangings ${noEventClass}`}>
        <PageTitleBar
          title={(
            <>
              <IntlMessages id="pageTitle.rankingEdit" />
              {fetchingOne ? (
                <span className="page-title-loading flex justify-center items-center">Loading...</span>
              ) : (
                this.getHeaderLine()
              )}
            </>
          )}

          match={match}
        />
        <RctCollapsibleCard>
          <ButtonGroup
            materialBtn
            className="btn-group-gray flex items-center"
            loading={
              updating || fetchingOne || bulkUpdateCategoriesStart || bulkUpdateNavigationsStart
            }
            list={buttonList(listParams)}
          />
          {rankingItemFetched && (
            <div className="form-dashed form-inline items-baseline">
              <FormGroup className="mb-10 mr-sm-10 mb-sm-0">
                <Label for="name" className="mr-sm-10"><IntlMessages id="rankings.nameLabel" /></Label>
                <div className="form-control-wrapper">
                  <Input
                    className={errors.name ? 'has-error' : ''}
                    type="text"
                    name="name"
                    id="name"
                    value={ranking.name}
                    onChange={this.updateRankingName}
                    disabled={noPermissions}
                  />
                  {errors.name && <InputErrors className="errors-rel" errors={errors.name} />}
                </div>
              </FormGroup>
              <Can
                do={appPermissions.ranking.permissions.update}
                on={appPermissions.ranking.name}
              >
                <MatButton
                  variant="contained"
                  color="primary"
                  className="text-white"
                  onClick={this.openScopeDialog}
                >
                  <IntlMessages id="rankings.scopeBtn" />
                </MatButton>
              </Can>
              {selectedTreeItems && !isEmpty(selectedTreeItems) && (
                <Popover
                  buttonClassName="btn-clear ml-10"
                  buttonText={<i className="ti-eye" />}
                  content={(
                    <div className="popover-content">
                      {selectedTreeItems.map(sItem => (
                        sItem.name && (
                          <div key={sItem.id} className="popover-content-item">
                            {sItem.name.en}
                            <div className="popover-content-subitem">{sItem.name.he}</div>
                          </div>
                        )
                      ))}
                    </div>
                  )}
                />
              )}
            </div>
          )}
          {
            rankingItemFetched && ranking && ranking.group && ranking.group.length > 0
            && ranking.group.map((row, index) => (
              <AttributesRow
                disabled={noPermissions}
                key={`${row.key}_attribute-row`}
                values={row}
                dataSource={{
                  totalWeigt: ranking.group
                    .map(item => item.weight).reduce((a, b) => a + b, 0),
                  normalizationList: normalization.list || [],
                  pushingList: pushing.list || [],
                  options: this.getAttributesOptions(),
                }}
                onChange={event => this.onChange({
                  event, row, rowIndex: ranking.group.indexOf(row),
                })}
                onSlide={value => this.onSlide({
                  value, row, rowIndex: ranking.group.indexOf(row),
                })}
                errors={attributesErrors ? attributesErrors[index] : {}}
                content={(
                  ranking.group.length > 1 && (
                    <IconButton
                      aria-label="Delete"
                      className="btn-mui-sm ml-10 text-danger"
                      disabled={noPermissions}
                      onClick={
                        () => this.deleteAttribute(ranking.group.indexOf(row))
                      }
                    >
                      <i className="ti-close" />
                    </IconButton>
                  )
                )}
              />
            ))
          }
          <div className="form-footer flex items-center">
            <Can
              do={appPermissions.ranking.permissions.update}
              on={appPermissions.ranking.name}
            >
              <MatButton
                variant="contained"
                color="primary"
                className="text-white"
                onClick={this.addNewAttribute}
                disabled={!isEmpty(ranking.group) && !isEmpty(rankingAttribute.list)
                  && ranking.group.length === rankingAttribute.list.length}
              >
                <IntlMessages id="rankings.addAttribute" />
              </MatButton>
              {(errors.isCompleted || errors.hasDuplicates) && (
                <div className="form-errors-group ml-20">
                  {errors.isCompleted && <InputErrors errors={errors.isCompleted} />}
                  {errors.hasDuplicates && <InputErrors errors={errors.hasDuplicates} />}
                </div>
              )}
            </Can>
          </div>
        </RctCollapsibleCard>
        <ScopeDialog
          dialog={{
            closeButtonTitle: <IntlMessages id="rankings.cancelScopeDialogButtonLabel" />,
            submitButtonTitle: <IntlMessages id="rankings.selectScopeDialogButtonLabel" />,
          }}
          search={{
            noResult: scopeCategory.searched && scopeCategory.searchResults.length === 0,
            placeholder: 'Search virtual categories ...',
            list: scopeCategory.searchResults,
            errorMessage: 'text.errors.lessThan500Characters',
            queryLengthLimit: 500,
          }}
          showScopeDialog={showScopeDialog}
          collapsed={scopeCategory.collapse}
          loadedKeys={scopeCategory.loadedKeys}
          selectedTreeItems={selectedTreeItems}
          treeItems={scopeCategory.treeItems}
          handleToggleCollapse={this.handleToggleCollapse}
          closeScopeDialog={this.closeScopeDialog}
          onSubmit={this.commitCheckedCategories}
          toogleTreeComponents={this.props.clearScopeCategorySearchState}
          fetchChildCategories={this.props.fetchChildScopeCategories}
          onSearchSubmit={this.handleSearchScopeCategory}
          onToggleCategoryTreeCollapse={this.props.onToggleScopeCategoryTreeCollapse}
          handleLoadSubcategories={this.props.handleLoadScopeSubcategories}
          loading={scopeCategory.fetchChildrenStarted
            || scopeCategory.fetchingParentCategoriesByIds}
          multiple
        />
      </div>
    );
  }
}

Edit.contextType = AbilityContext;

export default connect(mapStateToProps, actionCreators)(Edit);
