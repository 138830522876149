/* eslint-disable no-param-reassign */
import axios from 'axios';
import { decamelizeKeys, camelizeKeys } from 'humps';
import { isEmpty, uniq, cloneDeep } from 'lodash';

import RestActions from '../util/rest/actions';
import {
  navigationsSearchPath,
  getNavigationsPath,
  navigationsPath,
  productsFrontAppPath,
  bulkNavigationsUpdatePath,
  categoriesSearchPath,
} from '../util/paths';

import {
  getValueByOperator,
  getFieldByOperator,
} from '../util/mapFiltersByOperator';

import {
  getFrontendProductsByIds,
} from '../services/API/product';
import { showErrorNotification } from '../util/api';

import {
  FETCH_NAVIGATION_LIST_BY_NAME_START,
  FETCH_NAVIGATION_LIST_BY_NAME_FINISHED,
  SEARCH_NAVIGATION_FILTERS_START,
  SEARCH_NAVIGATION_FILTERS_FINISHED,

  FIND_NAVIGATION_BY_PHRASE_START,
  FIND_NAVIGATION_BY_PHRASE_FINISHED,
  FETCH_NAVIGATION_TO_DUPLICATE_START,
  FETCH_NAVIGATION_TO_DUPLICATE_FINISHED,
  NAVIGATION_SEARCH_BY_PHRASES_START,
  NAVIGATION_SEARCH_BY_PHRASES_FINISHED,
  UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_STARTED,
  UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_FINISHED,
  NAVIGATION_CLEAR_CATEGORY_PRODUCT_STATE,
  NAVIGATION_UPDATE_LOADED_PRODUCTS_IN,
  NAVIGATION_UPDATE_LOADED_PRODUCTS_NIN,
  NAVIGATION_COMPLETE_PRODUCTS_LIST_START,
  NAVIGATION_COMPLETE_PRODUCTS_LIST_FINISHED,
  NAVIGATION_FETCH_PRODUCT_LIST_START,
  NAVIGATION_FETCH_PRODUCT_LIST_FINISHED,
  NAVIGATION_FETCH_PRODUCTS_BY_IDS_START,
  NAVIGATION_FETCH_PRODUCTS_BY_IDS_FINISHED,
  NAVIGATION_SET_SELECTED_PRODUCT_LIST,
  NAVIGATION_SET_PRODUCTS_IN_IDS,
  NAVIGATION_SET_PRODUCTS_NIN_IDS,
  NAVIGATION_SET_CATEGORY_FIELDS,
  NAVIGATION_SET_PRODUCT_CATEGORIES_ATTRIBUTES,
  NAVIGATION_SET_ATTRIBUTES_MATRIX_ATTRIBUTES,
  NAVIGATION_SET_FILTERS,
  NAVIGATION_SET_PRODUCT_FILTER,
  NAVIGATION_SET_PRODUCT_COMPOUND_QUERY,
  NAVIGATION_SET_LEFT_SIDE_PAGINATION,
  NAVIGATION_SET_IS_SWITCH_ON,
  NAVIGATION_SET_IS_APPLY_FILTER_ACTIVE,
  NAVIGATION_SET_MAPPED_CATEGORIES,
  NAVIGATION_SET_SELECTED_CATEGORIES,
  NAVIGATION_SET_PHYSICAL_CATEGORIES_IDS,
  NAVIGATION_SET_SELECTED_TREE_ITEMS,
  NAVIGATION_SET_SHOW_CATEGORIES_DIALOG,
  NAVIGATION_CLEAR_NAVIGATION_FILTER_STATE,
  NAVIGATION_SET_RANKING_RULE_CODE,
  NAVIGATION_SET_SORTING_ERRORS,
  NAVIGATION_SET_SORT_RULES,
  NAVIGATION_SET_EXISTED_SEARCH_PHRASE,
  NAVIGATION_SET_ERRORS,
  NAVIGATION_SET_FILTERS_ERRORS,
  NAVIGATION_SET_IS_DATA_CHANGED,
  NAVIGATION_SET_IS_CATEGORY_SAVING,
  NAVIGATION_SET_FACETS,
  CLEAR_NAVIGATION_SORT,
} from './types';

import {
  mapToAttributesMatrix,
  mapFilters,
  unmapFromAttributesMatrix,
} from '../util/attributesMatrixMapping';
import normalizeFilters from '../util/normalizeFilters';
import { generateRequestBody } from '../util/productPayload';
import { mapPositions } from '../util/mapVirtualParamsPositions';
import { mapExportableProducts } from '../util/mapExportableProducts';
import { mapCampaignPrefixToFilters } from '../util/filterMapper';

import { CDMSClient } from '../util/api';

const restNavigationActions = new RestActions('navigation');

export const fetchNavigationList = (filter = {}, pagination = {}) => async (dispatch) => {
  dispatch(restNavigationActions.fetchAllStart());

  const fields = ['id', 'name'];

  const sort = [{
    field: 'created_at',
    order: 'desc',
  }];
  
  try {
    const res = await cdmsFetchNavigationList(decamelizeKeys({
      fields, filter, pagination, sort,
    }));

    const navigations = camelizeKeys(res.data.data);
    const allPages = res.data.pages;

    const result = navigations.map((c) => {
      const originalNavigation = res.data.data.find(p => p.id === c.id);
      const payload = { ...c };

      if (originalNavigation) {
        payload.virtualParams = mapPositions(
          c.virtualParams,
          [],
        );
      }
      return payload;
    });

    dispatch(restNavigationActions.fetchAllFinished({ list: result, allPages: allPages }));
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch(restNavigationActions.fetchAllFinished({ error: error.response.data, hasError: true }));
  }
};

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';
export const cdmsFetchNavigationList = (payload) => CDMSClient.post(navigationsSearchPath + '?ref=' + ref, payload);

export const fetchSearchFilter = navId => (dispatch, getState) => { // TODO delete this method -> fetchNavigation is the new one
  const { list: attributesMatrix } = getState().attributesMatrix;

  dispatch(restNavigationActions.fetchStart());
  axios.get(getNavigationsPath(navId)).then((res) => {
    const data = normalizeFilters(camelizeKeys(res.data.data));
    const payload = { ...data };
    payload.virtualParams = mapPositions(
      data.virtualParams,
      res.data.data.virtual_params,
    );

    dispatch(restNavigationActions.fetchFinished({
      item: unmapFromAttributesMatrix(payload, attributesMatrix),
      originalItem: unmapFromAttributesMatrix(payload, attributesMatrix),
    }));
  }, (error) => {
    dispatch(restNavigationActions.fetchFinished({ error: error.response.data, hasError: true }));
  });
};

export const fetchNavigation = navId => (dispatch, getState) => {
  const { list: attributesMatrix } = getState().attributesMatrix;

  dispatch(restNavigationActions.fetchStart());
  axios.get(getNavigationsPath(navId)).then((res) => {
    const data = normalizeFilters(camelizeKeys(res.data.data));
    const payload = { ...data };
    payload.virtualParams = mapPositions(
      data.virtualParams,
      res.data.data.virtual_params,
    );

    const navigationItem = unmapFromAttributesMatrix(payload, attributesMatrix);

    dispatch(restNavigationActions.fetchFinished({
      item: navigationItem,
      originalItem: navigationItem,
    }));
  }, (error) => {
    dispatch(restNavigationActions.fetchFinished({ error: error.response.data, hasError: true }));
  });
};

export const setNavigationFields = item => async (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_CATEGORY_FIELDS,
    payload: { item },
  });
};

export const fetchNavigationListByName = (filter, pagination, cb) => (dispatch) => {
  dispatch({ type: FETCH_NAVIGATION_LIST_BY_NAME_START });
  axios.post(navigationsSearchPath + '?ref=' + ref, decamelizeKeys({ ...filter, pagination })).then((res) => {
    const itemAlreadyExist = res.data.data && res.data.data.length > 0;

    dispatch({
      type: FETCH_NAVIGATION_LIST_BY_NAME_FINISHED,
      payload: { itemAlreadyExist },
    });
    if (cb) cb(itemAlreadyExist);
  }, (error) => {
    dispatch({
      type: FETCH_NAVIGATION_LIST_BY_NAME_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  });
};

// FACET

export const setFacets = facets => (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_FACETS,
    payload: { facets },
  });
};

export const searchNavigationsByPhrases = (searchPhrases = []) => async (dispatch) => {
  const filter = !isEmpty(searchPhrases)
    ? ([{
      group: [{
        field: 'search_phrase',
        value: searchPhrases,
        operator: 'in',
      }],
    }])
    : [];

  dispatch({
    type: NAVIGATION_SEARCH_BY_PHRASES_START,
    payload: {
      searchByPhrasesStarting: true,
      searchByPhrasesFinished: false,
    },
  });
  if (isEmpty(filter)) return [];
  try {
    const res = await axios.post(
      navigationsSearchPath + '?ref=' + ref,
      decamelizeKeys({ filter }),
    );
    dispatch({
      type: NAVIGATION_SEARCH_BY_PHRASES_FINISHED,
      payload: {
        phrases: camelizeKeys(res.data.data),
        searchByPhrasesStarting: false,
        searchByPhrasesFinished: true,
      },
    });
    return camelizeKeys(res.data.data);
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: NAVIGATION_SEARCH_BY_PHRASES_FINISHED,
      payload: {
        error: error.response.data,
        hasError: true,
        searchByPhrasesStarting: false,
        searchByPhrasesFinished: false,
      },
    });
    return error;
  }
};

export const updateSearchFilter = (navId, body, params = {}) => (dispatch, getState) => {
  const { mapToMatrix } = params;
  const { list: attributesMatrix } = getState().attributesMatrix;
  const updatedBody = decamelizeKeys({
    data: {
      ...body,
      virtualParams: {
        ...body.virtualParams,
        // facets,
        filters: mapToMatrix
          ? mapFilters(body.virtualParams.filters, attributesMatrix)
          : body.virtualParams.filters,
        sortRules: body.virtualParams.sortRules.map(s => ({
          field: mapToMatrix
            ? mapToAttributesMatrix(s.field, attributesMatrix)
            : s.field,
          direction: s.direction.toLowerCase(),
        })),
      },
    },
  });

  if (!isEmpty(body.virtualParams.productsOverride)) {
    updatedBody.data.virtual_params.positions = body.virtualParams.productsOverride
      ? body.virtualParams.productsOverride.reduce((result, item) => {
        const payload = { ...result };

        payload[item.productId] = item.position;
        return payload;
      }, {})
      : body.virtualParams.positions;
  }

  if (!isEmpty(body.virtualParams.positions)
    && isEmpty(body.virtualParams.productsOverride)) {
    updatedBody.data.virtual_params.positions = body.virtualParams.positions;
  }

  if (body.virtualParams.productsOverride && isEmpty(body.virtualParams.productsOverride)) {
    delete updatedBody.data.virtual_params.positions;
  }

  if (updatedBody.data.virtual_params.products_override) {
    delete updatedBody.data.virtual_params.products_override;
  }

  delete updatedBody.data.virtual_params.facets; // TODO remove

  dispatch(restNavigationActions.updateStart());
  axios.put(`${getNavigationsPath(navId)}?is_synchronous=true`, updatedBody).then((res) => {
    const updatedNavigation = camelizeKeys(res.data.data);
    const payload = { ...updatedNavigation };
    payload.virtualParams = mapPositions(
      updatedNavigation.virtualParams,
      res.data.data.virtual_params,
    );

    dispatch(restNavigationActions.updateFinished({
      item: unmapFromAttributesMatrix(payload, attributesMatrix),
      originalItem: unmapFromAttributesMatrix(payload, attributesMatrix),
    }));
  }, (error) => {
    dispatch(restNavigationActions.updateFinished({ error: error.response.data, hasError: true }));
  });
};

export const createNavigationFilter = (body, params) => (dispatch, getState) => {
  const payload = cloneDeep(body);
  const transformedPath = `${navigationsPath}?is_synchronous=${params.isSync}`;

  if (payload.virtualParams.facets) delete payload.virtualParams.facets;

  const { list: attributesMatrix } = getState().attributesMatrix;

  const updatedBody = {
    ...payload,
    description: 'descriptionEn',
    isEnabled: true,
    searchPhrase: payload.searchPhrase,
    virtualParams: {
      ...payload.virtualParams,
      filters: mapFilters(payload.virtualParams.filters, attributesMatrix),
      sortRules: payload.virtualParams.sortRules.map(s => ({
        field: mapToAttributesMatrix(s.field, attributesMatrix),
        direction: s.direction.toLowerCase(),
      })),
    },
  };

  dispatch(restNavigationActions.createStart());
  axios.post(transformedPath, {
    data: decamelizeKeys(updatedBody),
  }).then((res) => {
    dispatch(restNavigationActions.createFinished({
      item: unmapFromAttributesMatrix(camelizeKeys(res.data.data), attributesMatrix),
      originalItem: unmapFromAttributesMatrix(camelizeKeys(res.data.data), attributesMatrix),
    }));
  }, (error) => {
    dispatch(restNavigationActions.createFinished({ error: error.response.data, hasError: true }));
  });
};

export const deleteNavigation = id => (dispatch) => {
  dispatch(restNavigationActions.deleteStart());
  return axios.delete(getNavigationsPath(id)).then(() => {
    dispatch(restNavigationActions.deleteFinished());
  }, (error) => {
    dispatch(restNavigationActions.deleteFinished({
      error: error.response.data,
      hasError: true,
    }));
  });
};

export const clearNavigationItemState = () => (dispatch) => {
  dispatch(restNavigationActions.clearOneFinished({
    item: {},
    originalItem: {},
    fetchedOne: false,
    fetchingOne: false,
  }));
};

export const clearFromNavigationState = payload => (dispatch) => {
  dispatch(restNavigationActions.clearFromStateFinished(payload));
};

export const findNavigationByPhrase = (filter, fullText, pagination) => (dispatch) => {
  dispatch({ type: FIND_NAVIGATION_BY_PHRASE_START });
  axios.post(
    navigationsSearchPath + '?ref=' + ref,
    decamelizeKeys({ filter, fullText, pagination }),
  ).then((res) => {
    dispatch({
      type: FIND_NAVIGATION_BY_PHRASE_FINISHED,
      payload: { suggestions: camelizeKeys(res.data.data) },
    });
  }, (error) => {
    dispatch({
      type: FIND_NAVIGATION_BY_PHRASE_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  });
};

export const fetchProductList = ({
  viewMode,
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  fullText = {},
  compoundSearch = null,
}) => (dispatch, getState) => {
  const sort = [{
    field: 'categories.position',
    order: 'asc',
  }];
  const { list } = getState().previewFilterAttribute;
  const mappedFilters = filter.map((f) => {
    const group = f.group.map(g => ({
      ...g,
      value: getValueByOperator({ value: g.value, operator: g.operator }),
      field: getFieldByOperator({
        field: g.field, operator: g.operator, previewFilterAttribute: list,
      }),
    }));

    return {
      ...f,
      group,
    };
  });

  const params = {
    ...generateRequestBody({
      viewMode,
      filter: mapCampaignPrefixToFilters(mappedFilters),
      fields,
      pagination,
      fullText,
      sort,
      searchFilter,
    }),
  };

  if (compoundSearch && !isEmpty(compoundSearch) && compoundSearch.value) {
    params.body.compoundSearch = compoundSearch;
  }

  dispatch({ type: NAVIGATION_FETCH_PRODUCT_LIST_START });
  if (params.body.filter.length === 0) {
    delete(params.body.filter);
  }
  return axios.post(
    params.path,
    decamelizeKeys(params.body),
  ).then((res) => {
    const {
      pages, total, aggregation,
    } = res.data;
    const result = res.data.data.map((p) => {
      if (p.variants) {
        return {
          ...p,
          variants: Object.keys(p.variants).map((v) => {
            const item = p.variants[v];
            return ({
              ...item,
              id: v,
            });
          }),
        };
      }
      return p;
    });

    dispatch({
      type: NAVIGATION_FETCH_PRODUCT_LIST_FINISHED,
      payload: {
        list: mapExportableProducts(camelizeKeys(result)),
        aggregation,
        pages,
        total,
        updated: false,
        updating: false,
      },
    });
  }, (error) => {
    dispatch({
      type: NAVIGATION_FETCH_PRODUCT_LIST_FINISHED,
      payload: {
        error: error.response.data,
        hasErrors: true,
        list: [],
      },
    });
  });
};

export const clearProductState = () => (dispatch) => {
  dispatch({ type: NAVIGATION_CLEAR_CATEGORY_PRODUCT_STATE });
};

export const clearNavigationSortState = () => (dispatch) => {
  dispatch({ type: CLEAR_NAVIGATION_SORT });
};

export const fetchProductsByIdsStart = payload => (dispatch) => {
  dispatch({
    type: NAVIGATION_FETCH_PRODUCTS_BY_IDS_START,
    payload,
  });
};

export const fetchProductsByIdsFinished = payload => (dispatch) => {
  dispatch({
    type: NAVIGATION_FETCH_PRODUCTS_BY_IDS_FINISHED,
    payload,
  });
};

export const searchNavigationFilters = query => (dispatch) => {
  dispatch({ type: SEARCH_NAVIGATION_FILTERS_START });
  return axios.post(navigationsSearchPath + '?ref=' + ref, query).then((res) => {
    dispatch({
      type: SEARCH_NAVIGATION_FILTERS_FINISHED,
      payload: {
        list: camelizeKeys(res.data.data),
      },
    });
  }, (error) => {
    dispatch({
      type: SEARCH_NAVIGATION_FILTERS_FINISHED,
      payload: {
        error: error.response.data, hasError: true,
      },
    });
  });
};

export const fetchProductsByIds = (ids, key, pagination) => async (dispatch, getState) => {
  const navigationState = getState().navigation;
  const currentKey = key || 'productsByIds';
  const currentTargetList = navigationState.filter.product[currentKey];
  dispatch(fetchProductsByIdsStart());

  const condition = ids.length > 1
    ? { condition: 'or' }
    : {};

  return axios.post(
    `${productsFrontAppPath}/search`,
    {
      filter: [
        {
          ...condition,
          group: [{
            field: 'id',
            value: uniq(ids),
            operator: 'in',
          }],
        },
      ],
      pagination,
    },
  ).then((res) => {
    const items = mapExportableProducts(camelizeKeys(res.data.data));
    dispatch(fetchProductsByIdsFinished({
      [currentKey]: [...currentTargetList.map((l) => {
        const itemToSet = items.find(p => p.id === l.id);
        return itemToSet || l;
      })],
    }));
  }, (error) => {
    dispatch(fetchProductsByIdsFinished({
      error: error.response.data, hasErrors: true,
    }));
  });
};

export const completeProductList = (ids, key, pagination) => async (dispatch, getState) => {
  dispatch({
    type: NAVIGATION_COMPLETE_PRODUCTS_LIST_START,
    payload: {
      completingCategoryProductsList: true,
      completedCategoryProductsList: false,
    },
  });

  const currentKey = key || 'productsByIds';
  const navigationState = getState().navigation;
  const currentTargetList = navigationState.filter.product[currentKey];
  const query = {
    filter: [{
      group: [{
        field: 'id',
        value: uniq(ids),
        operator: 'in',
      }],
    }],
    pagination,
  };

  const res = await getFrontendProductsByIds(query);
  const items = camelizeKeys(res);

  dispatch({
    type: NAVIGATION_COMPLETE_PRODUCTS_LIST_FINISHED,
    payload: {
      completingCategoryProductsList: false,
      completedCategoryProductsList: true,
      [currentKey]: [...currentTargetList.map((l) => {
        const itemToSet = items.find(p => p.id === l.id);
        return itemToSet || l;
      })],
    },
  });
};

export const setSelectedProductList = payload => (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_SELECTED_PRODUCT_LIST,
    payload: {
      selectedProductList: payload,
    },
  });
};

export const setProductsInIds = payload => (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_PRODUCTS_IN_IDS,
    payload: {
      productsInIds: payload,
    },
  });
};

export const setProductsNotInIds = payload => (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_PRODUCTS_NIN_IDS,
    payload: {
      productsNotInIds: payload,
    },
  });
};

export const updateLoadedProductsIn = payload => (dispatch) => {
  dispatch({
    type: NAVIGATION_UPDATE_LOADED_PRODUCTS_IN,
    payload: {
      productsByIdsForIn: payload,
    },
  });
};

export const updateLoadedProductsNin = payload => (dispatch) => {
  dispatch({
    type: NAVIGATION_UPDATE_LOADED_PRODUCTS_NIN,
    payload: {
      productsByIdsForNin: payload,
    },
  });
};

export const fetchNavigationToDuplicate = navId => (dispatch, getState) => {
  const { list: attributesMatrix } = getState().attributesMatrix;

  dispatch({
    type: FETCH_NAVIGATION_TO_DUPLICATE_START,
    fetchNavigationToDuplicateStarted: true,
    fetchNavigationToDuplicateFinished: false,
  });
  axios.get(getNavigationsPath(navId)).then((res) => {
    const data = normalizeFilters(camelizeKeys(res.data.data));
    const payload = { ...data };
    payload.virtualParams = mapPositions(
      data.virtualParams,
      res.data.data.virtual_params,
    );

    dispatch({
      type: FETCH_NAVIGATION_TO_DUPLICATE_FINISHED,
      payload: {
        fetchNavigationToDuplicateStarted: false,
        fetchNavigationToDuplicateFinished: true,
        navigationToDuplicate: unmapFromAttributesMatrix(payload, attributesMatrix),
      },
    });
  }, (error) => {
    dispatch({
      type: FETCH_NAVIGATION_TO_DUPLICATE_FINISHED,
      payload: {
        error: error.response.data,
        hasError: true,
      },
    });
  });
};

export const updateNavigationsMappedcategoriesByVirtualCategoryId = (
  virtualCategoryId, mappedCategories,
) => async (dispatch) => {
  dispatch({
    type: UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_STARTED,
    payload: {
      updateNavigationMappedCategoriesByVirtualCategoryIdStarted: false,
      updateNavigationMappedCategoriesByVirtualCategoryIdFinished: true,
      hasErrors: true,
    },
  });
  const navigationsByVirtualCategoryIdFilter = [{
    group: [
      {
        field: 'virtual_params.selected_categories',
        value: [virtualCategoryId],
        operator: 'in',
      },
    ],
  }];

  let navigationsVirtualCategories = [];
  const virtualCategoriesMappedCategories = {};

  try {
    const navigationsSearchResult = await axios.post(navigationsSearchPath + '?ref=' + ref, decamelizeKeys({
      filter: navigationsByVirtualCategoryIdFilter,
    }));
    const navigations = camelizeKeys(navigationsSearchResult.data.data);

    if (isEmpty(navigations)) {
      return dispatch({
        type: UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_FINISHED,
        payload: {
          updateNavigationMappedCategoriesByVirtualCategoryIdStarted: false,
          updateNavigationMappedCategoriesByVirtualCategoryIdFinished: true,
        },
      });
    }

    navigations.forEach((n) => {
      const navigationVirtualCategories = n.virtualParams.selectedCategories
        .filter(c => c !== virtualCategoryId);
      navigationsVirtualCategories = [
        ...navigationsVirtualCategories,
        ...navigationVirtualCategories,
      ];
    });
    if (!isEmpty(navigationsVirtualCategories)) {
      const uniqNavigationsVirtualCategories = uniq(navigationsVirtualCategories);
      const virtualCategoriesByIdsFilter = [{
        group: [
          {
            field: 'id',
            value: [...uniqNavigationsVirtualCategories],
            operator: 'in',
          },
        ],
      }];

      const categoriesSearchResults = await axios.post(categoriesSearchPath + '?ref=' + ref, decamelizeKeys({
        filter: virtualCategoriesByIdsFilter,
      }));

      const virtualCategories = camelizeKeys(categoriesSearchResults.data.data);

      virtualCategories.forEach((vc) => {
        virtualCategoriesMappedCategories[vc.id] = vc.virtualParams.mappedCategories;
      });
    }

    const navigationsMappedCategories = [];
    navigations.forEach((n) => {
      const navigationMappedCategories = n.virtualParams.selectedCategories
        .filter(sc => sc !== virtualCategoryId)
        .map(s => virtualCategoriesMappedCategories[s]).flat();
      const { positions } = navigationsSearchResult.data.data.find(nav => nav.id === n.id)
        .virtual_params;
      let positionObj = {};
      if (!isEmpty(positions)) {
        positionObj = { positions };
      }

      delete n.virtualParams.facets;

      const searchPhrase = n.type === 'search_phrase' ? { searchPhrase: n.searchPhrase } : {};
      const urlKey = n.type === 'promotion_url' ? { urlKey: n.urlKey } : {};
      navigationsMappedCategories.push({
        id: n.id,
        type: n.type,
        ...searchPhrase,
        ...urlKey,
        virtualParams: {
          ...n.virtualParams,
          ...positionObj,
          mappedCategories: uniq([
            ...navigationMappedCategories, ...mappedCategories,
          ].filter(cId => cId)),
        },
      });
    });

    await axios.put(bulkNavigationsUpdatePath, {
      data: decamelizeKeys(navigationsMappedCategories),
    });
    dispatch({
      type: UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_FINISHED,
      payload: {
        updateNavigationMappedCategoriesByVirtualCategoryIdStarted: false,
        updateNavigationMappedCategoriesByVirtualCategoryIdFinished: true,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: UPDATE_NAVIGATIONS_MAPPED_CATEGORIES_BY_VIRTUAL_CATEGORY_ID_FINISHED,
      payload: {
        updateNavigationMappedCategoriesByVirtualCategoryIdStarted: false,
        updateNavigationMappedCategoriesByVirtualCategoryIdFinished: true,
        hasErrors: true,
      },
    });
  }
};

export const setIsDataChanged = isDataChanged => async (dispatch) => { // TODO mb delete
  dispatch({
    type: NAVIGATION_SET_IS_DATA_CHANGED,
    payload: { isDataChanged },
  });
};

export const setIsNavigationSaving = isNavigationSaving => async (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_IS_CATEGORY_SAVING,
    payload: { isNavigationSaving },
  });
};

// FILTER

export const setProductCategoriesAttributes = productCategoriesAttributes => async (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_PRODUCT_CATEGORIES_ATTRIBUTES,
    payload: { productCategoriesAttributes },
  });
};

export const setAttributesMatrixAttributes = attributesMatrixAttributes => async (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_ATTRIBUTES_MATRIX_ATTRIBUTES,
    payload: { attributesMatrixAttributes },
  });
};

export const setFilters = filters => async (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_FILTERS,
    payload: { filters },
  });
};

export const setProductFilter = productFilter => async (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_PRODUCT_FILTER,
    payload: { productFilter },
  });
};

export const setFiltersErrors = filtersErrors => async (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_FILTERS_ERRORS,
    payload: { filtersErrors },
  });
};

export const setCompoundSearch = compoundSearch => (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_PRODUCT_COMPOUND_QUERY,
    payload: { compoundSearch },
  });
};

export const setLeftSidePagination = leftSidePagination => (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_LEFT_SIDE_PAGINATION,
    payload: { leftSidePagination },
  });
};

export const setIsSwitchOn = isSwitchOn => async (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_IS_SWITCH_ON,
    payload: { isSwitchOn },
  });
};

export const setIsProductDialogApplyFilterActive = isProductDialogApplyFilterActive => (
  dispatch,
) => {
  dispatch({
    type: NAVIGATION_SET_IS_APPLY_FILTER_ACTIVE,
    payload: { isProductDialogApplyFilterActive },
  });
};

// CATEGORY

export const setMappedCategories = mappedCategories => (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_MAPPED_CATEGORIES,
    payload: { mappedCategories },
  });
};

export const setSelectedCategories = selectedCategories => (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_SELECTED_CATEGORIES,
    payload: { selectedCategories },
  });
};

export const setPhysicalCategoriesIds = physicalCategoriesIds => (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_PHYSICAL_CATEGORIES_IDS,
    payload: { physicalCategoriesIds },
  });
};

export const setSelectedTreeItems = selectedTreeItems => (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_SELECTED_TREE_ITEMS,
    payload: { selectedTreeItems },
  });
};

export const setShowCategoriesDialog = showCategoriesDialog => (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_SHOW_CATEGORIES_DIALOG,
    payload: { showCategoriesDialog },
  });
};

export const clearNavigationFilterState = () => (dispatch) => {
  dispatch({ type: NAVIGATION_CLEAR_NAVIGATION_FILTER_STATE });
};

// SORT

export const setRankingRuleCode = rankingRuleCode => (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_RANKING_RULE_CODE,
    payload: { rankingRuleCode },
  });
};

export const setSortingsErrors = sortingsErrors => (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_SORTING_ERRORS,
    payload: { sortingsErrors },
  });
};

export const setSortRules = sortRules => (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_SORT_RULES,
    payload: { sortRules },
  });
};

export const setExistedSearchPhrase = existedSearchPhrase => (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_EXISTED_SEARCH_PHRASE,
    payload: { existedSearchPhrase },
  });
};

export const setErrors = errors => async (dispatch) => {
  dispatch({
    type: NAVIGATION_SET_ERRORS,
    payload: { errors },
  });
};
