/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useDefaultAttribute = ({
  fetchListDefaultAttributes,
}) => {
  useEffect(() => {
    fetchListDefaultAttributes();
  }, []);
};

export default useDefaultAttribute;
