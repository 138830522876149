import React, { memo } from 'react';
import {
  func, shape, bool,
} from 'prop-types';
import { TextField } from '@material-ui/core';

import LinkWithLabel from '../../components/LinkWithLabel';
import IntlMessages from '../../../../util/IntlMessages';

const InputsRow = memo((props) => {
  const {
    handleInfluencerChange,
    handleInfluencerPageChange,
    influencer,
    textFieldsErrors,
    disabled,
  } = props;

  return (
    <>
      <LinkWithLabel
        label="Store link"
        url={influencer.page.storeLink}
        className="input-link"
      />
      <TextField
        value={influencer.page.storeName}
        name="storeName"
        onChange={handleInfluencerPageChange}
        label="Store name"
        helperText={textFieldsErrors.storeName && (
          <IntlMessages id={textFieldsErrors.storeName[0].message} />
        )}
        disabled={disabled}
      />
      <TextField
        value={influencer.name}
        onChange={handleInfluencerChange}
        name="name"
        label="Influencer name"
        helperText={textFieldsErrors.name && (
          <IntlMessages id={textFieldsErrors.name[0].message} />
        )}
        disabled={disabled}
      />
    </>
  );
});

InputsRow.propTypes = {
  influencer: shape().isRequired,
  textFieldsErrors: shape().isRequired,
  handleInfluencerPageChange: func.isRequired,
  handleInfluencerChange: func.isRequired,
  disabled: bool.isRequired,
};

export default InputsRow;
