import { createSelector } from 'reselect';

const virtualCategoryRootSelector = state => state;

export const virtualCategorySelector = createSelector(
  virtualCategoryRootSelector,
  state => ({
    ...state,
  }),
);

export default {
  virtualCategorySelector,
};
