import React from 'react';
import {
  shape, string, func, arrayOf, bool,
} from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Button, Tooltip,
  CircularProgress,
} from '@material-ui/core';

import Field from '../../../../components/Field';
import IntlMessages from '../../../../util/IntlMessages';
import roles from '../../../../util/roles';

const NewUserForm = (props) => {
  const {
    fields, errors, onTextFieldsChange, onRolesChange, rolesOptions,
    dialog, onCompanyChange, companyName, loading,
  } = props;

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={dialog.open}
      onClose={dialog.onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle><IntlMessages id="text.addNewUser" /></DialogTitle>
      <DialogContent>
        <div className="form-user-new">
          <Field
            element="text"
            onChange={onTextFieldsChange}
            value={fields.displayName}
            wrapperClassName="direction-column"
            name="displayName"
            key="displayName"
            id="displayName"
            label="Display Name"
            className={`flex mb-5 ${errors.displayName ? 'has-error' : ''}`}
            errors={errors.displayName}
            disabled={loading}
          />
          <Field
            element="text"
            type="email"
            onChange={onTextFieldsChange}
            value={fields.email}
            wrapperClassName="direction-column"
            name="email"
            key="email"
            id="email"
            label="User Email"
            className={`flex mb-5 ${errors.email ? 'has-error' : ''}`}
            errors={errors.email}
            disabled={loading}
          />
          <div className="form-group flex items-center">
            <Field
              element="text"
              type="password"
              onChange={onTextFieldsChange}
              value={fields.password}
              wrapperClassName="flex-1 direction-column"
              name="password"
              key="password"
              id="password"
              label={<IntlMessages id="text.userPassword" />}
              className={`flex mb-5 ${errors.password ? 'has-error' : ''}`}
              errors={errors.password}
              disabled={loading}
            />
            <Tooltip
              className="ml-10"
              placement="bottom-end"
              title={(
                <div className="flex direction-column">
                  <IntlMessages id="system.errors.passwordShouldBeAtLeastSixCharacters" />
                  <IntlMessages id="system.errors.passwordAdvice" />
                </div>
              )}
            >
              <i className="ti-info-alt" />
            </Tooltip>
          </div>
          <div className="form-group">
            <Field
              element="select"
              value={fields.roles || []}
              options={rolesOptions || []}
              onChange={onRolesChange}
              name="roles"
              key="roles"
              id="roles"
              label="User roles"
              multiple
              className={`select-wrap ${errors.roles ? 'has-error' : ''}`}
              errors={errors.roles}
              disabled={loading}
            />
          </div>
          {fields.roles && fields.roles.includes(roles.influencer.name) && (
            <div className="form-group">
              <Field
                element="text"
                onChange={onCompanyChange}
                value={companyName}
                wrapperClassName="direction-column"
                name="companyName"
                key="companyName"
                id="companyName"
                label="Company"
                className={`flex mb-5 ${errors.companyName ? 'has-error' : ''}`}
                errors={errors.companyName}
                disabled={loading}
              />
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {loading && (
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className="progress-warning custom-loader"
            size={15}
            thickness={4}
          />
        )}
        <Button disabled={loading} onClick={dialog.onClose} color="primary">
          <IntlMessages id="button.cancel" />
        </Button>
        <Button disabled={loading} onClick={dialog.onOk} color="primary">
          <IntlMessages id="button.add" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewUserForm.propTypes = {
  dialog: shape({
    onClose: func,
    onOk: func,
    open: bool,
  }),
  fields: shape({
    displayName: string,
  }),
  rolesOptions: arrayOf(shape()),
  errors: shape({}),
  onTextFieldsChange: func,
  onRolesChange: func,
  onCompanyChange: func,
  companyName: string,
  loading: bool,
};

NewUserForm.defaultProps = {
  dialog: {
    onClose: null,
    onOk: null,
    open: false,
  },
  fields: {
    displayName: '',
    userName: '',
    roles: [],
  },
  errors: {},
  onTextFieldsChange: null,
  onRolesChange: null,
  onCompanyChange: null,
  rolesOptions: [],
  companyName: '',
  loading: false,
};

export default NewUserForm;
