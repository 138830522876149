import React from 'react';
import {
  func, shape, number, string, bool,
} from 'prop-types';
import Input from '../../../../components/Input';

const DisplayName = ({
  user, onChange, errors, disabled,
}) => (
  <Input
    className="block"
    value={user.displayName}
    onChange={e => onChange(user.id, e)}
    name="displayName"
    wrapperClassName="input-underline-hide"
    errors={errors[user.id]}
    id={user.id}
    disabled={disabled}
  />
);

DisplayName.propTypes = {
  user: shape({
    id: number,
    displayName: string,
  }),
  onChange: func,
  errors: shape(),
  disabled: bool,
};

DisplayName.defaultProps = {
  user: {},
  errors: {},
  onChange: null,
  disabled: false,
};

export default DisplayName;
