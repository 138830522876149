import React, { memo } from 'react';
import { func, shape } from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import { IconButton, Tooltip } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import IntlMessages from '../../../../../../util/IntlMessages';

import {
  removeImages,
  updateMultipleProducts,
  setIsEditMode,
} from '../../../../../../actions/pim';

import {
  pimSelector,
} from './selectors/pimSelector';
import {
  productSelector,
} from './selectors/productSelector';

const handleEmptyOnChange = () => { };

const substrUrl = diff => diff.map(d => ({
  ...d,
  images: d.images.map(i => ({
    ...i,
    url: i.url.indexOf('?') !== -1
      ? i.url.substring(0, i.url.indexOf('?'))
      : i.url,
  })),
}));

const handleNotificationWarning = msg => NotificationManager.warning(msg);

const HeaderControls = memo((props) => {
  const { pimProps, productProps, sessionItem } = props;

  const isShowEditModeCheckbox = false;

  const onEditModeChange = () => props.setIsEditMode(!pimProps.isEditMode);

  const checkProductsImagesOverride = () => {
    const { productsImagesOverride, list } = pimProps;
    const parentProductList = [...list];
    const variantsProductList = list
      .filter(p => !isEmpty(p.variants))
      .map(p => p.variants).flat();

    const origProductsImagesOrder = [...parentProductList, ...variantsProductList]
      .filter(l => productsImagesOverride.some(p => p.id === l.id))
      .map(p => ({ id: p.id, images: p.images }));

    const mappedProductsImagesOverride = substrUrl(productsImagesOverride);

    const isArrayEqual = isEqual(origProductsImagesOrder, mappedProductsImagesOverride);
    const diff = !isEmpty(origProductsImagesOrder)
      ? origProductsImagesOrder.map((originalItem) => {
        const updatedItem = mappedProductsImagesOverride.find(pio => pio.id === originalItem.id);
        if (!isEqual([originalItem], [updatedItem])) {
          return updatedItem;
        } return [];
      }).flat()
      : mappedProductsImagesOverride;

    return ({ isEqual: isArrayEqual, diff });
  };

  const handleSaveChangesClick = () => {
    const { productsImagesOverride } = pimProps;

    const { isEqual: isArrayEqual, diff } = checkProductsImagesOverride();

    if (!isEmpty(productsImagesOverride) && !isArrayEqual) {
      const { displayName, id, email } = sessionItem;
      const sessionUser = `${displayName}, ${email}, [user id: ${id}]`;
      const comment = `${displayName} changed products images order (${productsImagesOverride.map(pio => pio.id)}). User details: ${sessionUser}`;
      const productsWithImages = diff.filter(p => !isEmpty(p.images));

      if (!isEmpty(pimProps.productsWithoutImages)) {
        props.removeImages(pimProps.productsWithoutImages);
      }

      if (!isEmpty(productsWithImages)) {
        props.updateMultipleProducts(productsWithImages, comment);
      }

      return true;
    }

    handleNotificationWarning(<IntlMessages id="pim.save.alert.noChanges" />);
    return false;
  };

  const isLoading = (
    pimProps.fetchProductsByIdsStarted
    || productProps.updating
    || productProps.fetchProductCommentsStart
    || productProps.bulkUpdateActivityLogsStart
    || pimProps.updatingMultiple
    || pimProps.fetchProductsCommentsCountStart
  );

  const isHeaderControlsDisabled = isEmpty(pimProps.productsImagesOverride) || isLoading;

  if (isShowEditModeCheckbox === false) {
    return (<></>);
  }

  return (
    <div className="flex justify-end items-center min-h-xs pl-10">
      {
        pimProps.isEditMode
        && (
          <Tooltip title={<IntlMessages id="button.saveChanges" />}>
            <div className="tooltip-container">
              <IconButton
                color="primary"
                className="btn-link btn-xs text-normal"
                onClick={handleSaveChangesClick}
                disabled={isHeaderControlsDisabled}
              >
                <Save fontSize="small" />
              </IconButton>
            </div>
          </Tooltip>
        )
      }
      <Tooltip
        title={
          !pimProps.isEditMode
            ? <IntlMessages id="header.switch.editMode.off" />
            : <IntlMessages id="header.switch.editMode.on" />
        }
      >
        <label
          role="presentation"
          htmlFor="mer-switch"
          className="mer-switch sm ml-10"
          onClick={onEditModeChange}
          onKeyDown={onEditModeChange}
        >
          <input
            type="checkbox"
            name="mer-switch"
            checked={pimProps.isEditMode}
            onChange={handleEmptyOnChange}
          />
          <span />
        </label>
      </Tooltip>
    </div>
  );
});

HeaderControls.propTypes = {
  pimProps: shape().isRequired,
  sessionItem: shape().isRequired,
  productProps: shape().isRequired,
  removeImages: func.isRequired,
  updateMultipleProducts: func.isRequired,
  setIsEditMode: func.isRequired,
};

HeaderControls.defaultProps = {};

const mapStateToProps = state => ({
  pimProps: pimSelector(state.pim),
  productProps: productSelector(state.product),
  sessionItem: state.session.item,
});

const mapDispatchToProps = {
  removeImages,
  updateMultipleProducts,
  setIsEditMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderControls);
