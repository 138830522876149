/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import {
  string, shape, func, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { camelize } from 'humps';
import { connect } from 'react-redux';

import { Card, CardContent, IconButton } from '@material-ui/core';

import {
  setGlobalFields,
} from '../../../../actions/pim';

import IntlMessages from '../../../../util/IntlMessages';
import Field from '../../../../components/Field';

const PredefinedFilter = memo((props) => {
  const {
    onChange, setIsPredefinedFilterRemoved, filterTarget, pimModeAttributes, prevFilterTarget,
    showPredefinedFilter, setShowPredefinedFilter, sortedFilters, globalFields, settingsAreApplied,
    setPredefinedFilter, predefinedFilter, error,
  } = props;

  useEffect(() => {
    const payload = {
      ...globalFields,
      [filterTarget]: [],
    };

    if (!settingsAreApplied[filterTarget] && !isEmpty(globalFields[filterTarget] && prevFilterTarget)) {
      if (payload[filterTarget].length > 0) {
        props.setGlobalFields(payload);
        setPredefinedFilter(payload);
        sessionStorage.setItem('pim.predefinedFilter', JSON.stringify(payload));
      }
    }

    const activatePredefinedFilter = !isEmpty(predefinedFilter[filterTarget])
      && settingsAreApplied[filterTarget];

    setShowPredefinedFilter(activatePredefinedFilter);
  }, [filterTarget]);

  useEffect(() => {
    const storedPredefinedFilter = sessionStorage.getItem('pim.predefinedFilter');
    const storedFilterTarget = sessionStorage.getItem('pim.filterTarget');

    if (storedPredefinedFilter) {
      props.setGlobalFields(JSON.parse(storedPredefinedFilter));
      setPredefinedFilter(JSON.parse(storedPredefinedFilter));

      if (!isEmpty(JSON.parse(storedPredefinedFilter)[storedFilterTarget || filterTarget])) {
        setShowPredefinedFilter(true);
      }
    }
  }, []);

  const getAvailableSearchAttributes = () => {
    if ((filterTarget === 'activityLog' || filterTarget === 'repricer') && !isEmpty(sortedFilters)) {
      const sortedFiltersKeys = Object.keys(sortedFilters);
      const result = [];
      for (let i = 0; i < sortedFiltersKeys.length; i++) {
        result.push({
          label: sortedFilters[sortedFiltersKeys[i]].name,
          value: sortedFilters[sortedFiltersKeys[i]].field ? sortedFilters[sortedFiltersKeys[i]].field : sortedFiltersKeys[i],
          disabled: false
        });
      }

      return result;
    }

    if (isEmpty(pimModeAttributes[filterTarget])) return [];

    const result = pimModeAttributes[filterTarget].map((opt) => {
      const option = {
        label: opt.label,
        value: opt.code,
        disabled: globalFields[filterTarget]
          .some(sF => sF.field && camelize(sF.field) === camelize(opt.value)),
      };

      return option;
    });

    return result;
  };

  const handleChange = (e) => {
    const updatedPredefinedFilter = [e.target.value];
    const payload = {
      ...globalFields,
      [filterTarget]: updatedPredefinedFilter,
    };

    setPredefinedFilter(payload);
    onChange();
  };

  const handleAddFieldItem = () => {
    setShowPredefinedFilter(!showPredefinedFilter);

    const payload = {
      ...globalFields,
      [filterTarget]: [],
    };

    props.setGlobalFields(payload);
    setPredefinedFilter(payload);
    sessionStorage.setItem('pim.predefinedFilter', JSON.stringify(payload));
    setIsPredefinedFilterRemoved(showPredefinedFilter);
  };

  const className = !isEmpty(globalFields[filterTarget]) ? 'no-border-bottom' : '';
  const errorClassName = error ? 'has-error' : '';

  return (
    <div className="form-group-field">
      <div className={`form-group-title pt-10 pb-10  ${className}`}>
        <span className="title">
          <IntlMessages id="preview.predefinedFilter" />
        </span>
        <IconButton
          aria-label="Add"
          color="primary"
          className={`btn-mui-xs pos-rel ${showPredefinedFilter
            ? 'text-danger'
            : 'text-success'}`}
          onClick={handleAddFieldItem}
        >
          {showPredefinedFilter
            ? <i className="ti-minus" />
            : <i className="ti-plus" />}
        </IconButton>
      </div>
      {showPredefinedFilter && (
        <Card className={`card-xs ${errorClassName}`}>
          <CardContent className="flex items-center">
            <Field
              element="select"
              onChange={handleChange}
              value={predefinedFilter[filterTarget]}
              options={getAvailableSearchAttributes()}
              name="filters"
              label="Filters"
              className="select-xs select-btn flex-1"
            />
          </CardContent>
        </Card>
      )}
    </div>
  );
});

PredefinedFilter.propTypes = {
  onChange: func.isRequired,
  setShowPredefinedFilter: func.isRequired,
  showPredefinedFilter: bool.isRequired,
  setIsPredefinedFilterRemoved: func.isRequired,
  filterTarget: string.isRequired,
  prevFilterTarget: string.isRequired,
  predefinedFilter: shape().isRequired,

  globalFields: shape().isRequired,
  setGlobalFields: func.isRequired,
  setPredefinedFilter: func.isRequired,
  settingsAreApplied: shape().isRequired,
  pimModeAttributes: shape().isRequired,
  error: bool,
};

PredefinedFilter.defaultProps = {
  error: false,
};

export const mapDispatchToProps = {
  setGlobalFields,
};

export const mapStateToProps = state => ({
  globalFields: state.pim.globalFields,
  filterTarget: state.pim.filterTarget,
  prevFilterTarget: state.pim.prevFilterTarget,
  pimModeAttributes: state.system.modes.pim,
});

export default connect(mapStateToProps, mapDispatchToProps)(PredefinedFilter);
