import {
  fetchRootCategories as fetchVirtualRootCategories,
  clearVirtualCategoryTreeState,
  fetchRecursiveParentCategory,
  fetchChildCategories as fetchVirtualChildCategories,
  handleLoadVirtualSubcategories,
  onToggleVirtualCategoryTreeCollapse,
  setSelectedCategory,
  clearState,
} from 'actions/virtualCategoryWidget';

import {
  setShowContextMenu,
  setContextMenuStyles,
  setTargetTreeNode,
} from 'actions/contextMenu';

import {
  fetchSelectedCategory,
  setVirtualFacetFilter,
} from 'actions/preview';

import { virtualCategoryWidgetSelector } from './selectors/virtualCategoryWidget';
import { contextMenuSelector } from './selectors/contextMenu';

export const mapStateToProps = state => ({
  virtualCategoryWidget: virtualCategoryWidgetSelector(state.virtualCategoryWidget),
  contextMenu: contextMenuSelector(state.contextMenu),

  loadingId: state.virtualCategory.loadingId,
});

export const mapDispatchToProps = {
  fetchVirtualRootCategories,
  fetchVirtualChildCategories,
  handleLoadVirtualSubcategories,
  onToggleVirtualCategoryTreeCollapse,
  clearVirtualCategoryTreeState,
  fetchRecursiveParentCategory,
  setSelectedCategory,
  clearState,
  setShowContextMenu,
  setContextMenuStyles,
  setTargetTreeNode,

  setVirtualFacetFilter,
  fetchSelectedCategory,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
