import { useState } from 'react';

export const useStateHook = (props) => {
  const { attributes } = props;
  const [productSortingAttributes, setProductSortingAttributes] = useState(
    attributes
      ? attributes.productSorting
      : [],
  );
  const [isRankingRule, setIsRankingRule] = useState(false);

  const state = {
    isRankingRule,
    productSortingAttributes,
  };

  const setState = {
    setProductSortingAttributes,
    setIsRankingRule,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
