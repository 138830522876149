import { isEmpty } from 'lodash';

export const mapProductList = productList => (productList && productList.length > 0
  ? productList.map(product => ({
    ...product,
    image: product.images && product.images.length > 0 ? product.images[0] : {},
    disabled: false,
    checked: false,
    attribute: {
      title: product.title || '',
      price: `$${product.minPrice || ''}`,
      description: product.description || '',
      productId: product.id,
    },
  }))
  : []);

export const getFilterWithoutProductFilter = filters => filters.map(nf => nf.group.map((nfg) => {
  if (!isEmpty(nfg.value)) {
    if (typeof nfg.value === 'string') {
      return nf;
    }
    if (Array.isArray(nfg.value) && !nfg.value.map(v => v.includes('prod_')).includes(true)) {
      return nf;
    }
  }
  return false;
})).flat().filter(e => e);

export const mapFrontEndPrefix = (field) => {
  if (field.startsWith('ao_')) {
    return `variation.facets.${field}`;
  }
  return field;
};

export const mapFrontEndPrefixToFilters = filters => (filters.map(f => ({
  ...f,
  group: f.group.map(g => ({
    ...g,
    field: mapFrontEndPrefix(g.field),
  })),
})));

export const getSplitedFilterByQuery = (query) => {
  const splitedFilter = {
    productIds: [],
    queryString: [],
  };

  query.split(',').map(q => q.trim()).forEach((item) => {
    if (item.includes('prod_')) splitedFilter.productIds.push(item);
    if (!item.includes('prod_')) splitedFilter.queryString.push(item);
  });
  return splitedFilter;
};

export const getProductCompoundSearchFilter = query => ({
  value: query,
});
