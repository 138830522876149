import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './pages/Home/hoc';
import {
  rootPath,
} from './utils/paths';
import { AbilityContext } from '../../components/AbilityContext';
import appPermissions from '../../util/appPermissions';

const Preview = () => {
  const abilityContext = useContext(AbilityContext);

  return (
    <Switch>
      {abilityContext.can(
        appPermissions.previewPortal.permissions.view,
        appPermissions.previewPortal.name,
      ) && <Route path={rootPath} component={Home} />}
    </Switch>
  );
};

export default Preview;
