import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import RestActions from '../util/rest/actions';
import { showErrorNotification } from '../util/api';

import {
  virtualFacetPath,
  virtualFacetSearchPath,
  getVirtualFacetPath,
} from '../util/paths';

import {
  SET_VIRTUAL_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE,
} from './types';

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';
const restVirtualCategoryVirtualFacetActions = new RestActions('virtual_category_virtual_facet');

export const fetchVirtualCategoryVirtualFacet = id => (dispatch) => {
  dispatch(restVirtualCategoryVirtualFacetActions.fetchStart());
  return axios.get(getVirtualFacetPath(id)).then((res) => {
    dispatch(restVirtualCategoryVirtualFacetActions.fetchFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restVirtualCategoryVirtualFacetActions.fetchFinished({
      error: error.response.data,
      hasError: true,
      item: {},
    }));
  });
};

export const fetchVirtualCategoryVirtualFacetList = params => (dispatch) => {
  dispatch(restVirtualCategoryVirtualFacetActions.fetchAllStart());
  return axios.post(virtualFacetSearchPath + '?ref=' + ref, params).then((res) => {
    dispatch(restVirtualCategoryVirtualFacetActions.fetchAllFinished({
      list: camelizeKeys(res.data.data),
      origList: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restVirtualCategoryVirtualFacetActions.fetchAllFinished({
      error: error.response.data,
      hasError: true,
      list: [],
      origList: [],
    }));
  });
};

export const deleteVirtualCategoryVirtualFacet = (id, isSync = true) => (dispatch) => {
  const transformedPath = isSync
    ? `${getVirtualFacetPath(id)}?is_synchronous=${isSync}`
    : getVirtualFacetPath(id);
  dispatch(restVirtualCategoryVirtualFacetActions.deleteStart());
  return axios.delete(transformedPath).then(() => {
    dispatch(
      restVirtualCategoryVirtualFacetActions.deleteFinished({ lastDeletedVirtualFacetId: id }),
    );
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restVirtualCategoryVirtualFacetActions.deleteFinished({
      error: error.response.data,
      hasError: true,
      lastDeletedVirtualFacetId: '',
    }));
  });
};

export const updateVirtualCategoryVirtualFacet = (
  facetId, payload, isSync = true,
) => (dispatch) => {
  const transformedPath = isSync
    ? `${getVirtualFacetPath(facetId)}?is_synchronous=${isSync}`
    : getVirtualFacetPath(facetId);
  const body = {
    data: payload,
  };
  dispatch(restVirtualCategoryVirtualFacetActions.updateStart());
  return axios.put(transformedPath, decamelizeKeys(body)).then((res) => {
    dispatch(restVirtualCategoryVirtualFacetActions.updateFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restVirtualCategoryVirtualFacetActions.updateFinished({
      error: error.response.data,
      hasError: true,
    }));
  });
};

export const createVirtualCategoryVirtualFacet = payload => (dispatch) => {
  const body = {
    data: payload,
  };
  dispatch(restVirtualCategoryVirtualFacetActions.createStart());
  return axios.post(virtualFacetPath, decamelizeKeys(body)).then((res) => {
    dispatch(restVirtualCategoryVirtualFacetActions.createFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restVirtualCategoryVirtualFacetActions.createFinished({
      error: error.response.data,
      hasError: true,
    }));
  });
};

export const setVirtualCategoryVirtualFacetToDeleteId = id => (dispatch) => {
  dispatch({
    type: SET_VIRTUAL_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE,
    payload: {
      virtualFacetIdToDelete: id,
    },
  });
};

export const setVirtualCategoryVirtualFacetItem = facet => (dispatch) => {
  dispatch(restVirtualCategoryVirtualFacetActions.updateStart());
  dispatch(restVirtualCategoryVirtualFacetActions.updateFinished({ item: facet }));
};

export const setVirtualCategoryVirtualFacetList = list => (dispatch) => {
  dispatch(restVirtualCategoryVirtualFacetActions.fetchAllStart());
  dispatch(restVirtualCategoryVirtualFacetActions.fetchAllFinished({ list }));
};

export const clearVirtualCategoryVirtualFacet = () => (dispatch) => {
  dispatch(restVirtualCategoryVirtualFacetActions.clearStateFinished());
};
