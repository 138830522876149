import { camelizeKeys } from 'humps';
import { showErrorNotification } from '../util/api';

import {
  AUTOCOMPLETE_START,
  AUTOCOMPLETE_FINISHED,
} from './types';

import { CDMSClient, IMSClient } from '../util/api';

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';
export const categoriesAutocomplete = condition =>
  CDMSClient.post('/virtual_categories/search?lang=en&ref=' + ref, condition);
export const brandsAutocomplete = condition =>
  CDMSClient.post('/product_attribute_options/search?ref=' + ref, condition);
export const merchantsAutocomplete = condition =>
  IMSClient.post('/merchants/search', condition);

export const getCategoriesAutocomplete = (value, limit) => async (dispatch) => {
  dispatch({
    type: AUTOCOMPLETE_START,
    payload: {
      searchAttributeOptionsStart: true,
      searchAttributeOptionsFinished: false,
    },
  });

  try {
    const condition = {
      filter: [{
        group: [{
          field: 'name.en',
          value: value + "*",
          operator: 'like'
        }]
      }],
      fields: ['name', 'path_by_name', 'id'],
      pagination: {
        page: 1,
        limit: limit
      }
    }
    const res = await categoriesAutocomplete(condition);
    const foundOptions = camelizeKeys(res.data.data);

    dispatch({
      type: AUTOCOMPLETE_FINISHED,
      payload: {
        searchAttributeOptionsStart: false,
        searchAttributeOptionsFinished: true,
        foundOptions,
      },
    });

  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: AUTOCOMPLETE_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  }
};

export const getBrandsAutocomplete = (value, limit) => async (dispatch) => {
  dispatch({
    type: AUTOCOMPLETE_START,
    payload: {
      searchAttributeOptionsStart: true,
      searchAttributeOptionsFinished: false,
    },
  });

  try {
    const condition = {
      filter: [{
        condition: 'and',
        group: [{
          field: 'label.en.keyword',
          operator: 'like',
          value: value + "*",
        }, {
          field: 'attribute_code',
          operator: 'like',
          value: 'ao_brand_name',
        }],
      }],
      fields: ['attribute_code', 'option_code', 'label'],
      pagination: {
        page: 1,
        limit: limit
      }
    };
    const res = await brandsAutocomplete(condition);
    const foundOptions = camelizeKeys(res.data.data);

    dispatch({
      type: AUTOCOMPLETE_FINISHED,
      payload: {
        searchAttributeOptionsStart: false,
        searchAttributeOptionsFinished: true,
        foundOptions,
      },
    });

  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: AUTOCOMPLETE_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  }
};

export const getMerchantsAutocomplete = (value, limit) => async (dispatch) => {
  dispatch({
    type: AUTOCOMPLETE_START,
    payload: {
      searchAttributeOptionsStart: true,
      searchAttributeOptionsFinished: false,
    },
  });

  try {
    const condition = {
      filter: [{
        condition: 'and',
        group: [{
          field: 'name',
          operator: 'like',
          value: value + "*",
        }, {
          field: 'is_active',
          operator: 'eq',
          value: 1,
        }],
      }],
      fields: ['name', 'merchant_id'],
      pagination: {
        page: 1,
        limit: limit
      }
    };
    const res = await merchantsAutocomplete(condition);
    const foundOptions = camelizeKeys(res.data.data);

    dispatch({
      type: AUTOCOMPLETE_FINISHED,
      payload: {
        searchAttributeOptionsStart: false,
        searchAttributeOptionsFinished: true,
        foundOptions,
      },
    });

  } catch (error) {
    showErrorNotification(error, 'IMS');
    dispatch({
      type: AUTOCOMPLETE_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  }
};
