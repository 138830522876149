import React, { useState } from 'react';
import { connect } from 'react-redux';
import { func, shape, bool, string } from 'prop-types';
import { Form, FormGroup, Input } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import QueueAnim from 'rc-queue-anim';

import IntlMessages from '../../../util/IntlMessages';
import smallLogo from '../../../assets/img/logo-small.png';

import {
  requestResetUserPasswordInFirebase,
} from '../../../actions/auth';
import { rootAppPath } from '../../../util/paths';


const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    props.requestResetUserPasswordInFirebase(email);
  };

  return (
    <QueueAnim type="bottom" duration={2000}>
      <div className="rct-session-wrapper flex justify-center items-center">
        <div className="session-inner-wrapper justify-center items-center">
          <div className="container">
            <div className="row row-eq-height justify-center">
              <div className="col-sm-7 col-md-7 col-lg-8">
                <div className="session-body text-center">
                  <div className="session-head mb-30">
                    <div className="site-logo">
                      <Link to={rootAppPath} className="logo-normal header-brand">
                        <img src={smallLogo} alt="site logo" />
                      </Link>
                    </div>
                  </div>
                  {
                    !props.authUser.resetFinished || props.authUser.hasError
                      ? (
                        <Form>
                          <FormGroup className="has-wrapper">
                            <Input
                              type="email"
                              name="email"
                              id="email"
                              value={email}
                              className="has-input input-lg"
                              placeholder="Enter email address"
                              onChange={onChangeEmail}
                            />
                            {
                              props.authUser.hasError && (
                              <span>
                                { props.authUser.errorMessage }
                              </span>
                              )
                            }
                            <span className="has-icon"><i className="ti-email" /></span>
                          </FormGroup>
                          <FormGroup>
                            <Button
                              variant="contained"
                              className="btn-info text-white btn-block btn-large w-100"
                              onClick={handleSubmit}
                            >
                              <IntlMessages id="auth.forgotPassword.resetPasswordButton" />
                            </Button>
                          </FormGroup>
                        </Form>
                      ) : <IntlMessages id="auth.forgotPassword.resetPasswordSuccessMessage" />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </QueueAnim>
  );
};

ForgotPassword.propTypes = {
  requestResetUserPasswordInFirebase: func.isRequired,
  authUser: shape({
    hasError: bool,
    errorMessage: string,
  }).isRequired,
};

const mapDispatchToProps = {
  requestResetUserPasswordInFirebase,
};

const mapStateToProps = ({ authUser }) => ({
  authUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
