import { isEmpty } from 'lodash';

const getClearFilter = (filter) => {
  const clearFilter = [];
  for (let i = 0; i < filter.length; i++) {
    if (filter[i].group) {
      for (let j = 0; j < filter[i].group.length; j++) {
        if (!filter[i].group[j].value && filter[i].group[j].fieldOption) {
          filter[i].group[j].value = filter[i].group[j].fieldOption;
        }
        if (filter[i].group[j].field) {
          clearFilter.push(filter[i]);
        }
      }
    }
  }

  return clearFilter;
};

export const checkFilterGroups = (filter) => {
  return getClearFilter(filter)
    .some(f => f.group
      .some(g => Object.keys(g)
        .some(gKey => isEmpty(g[gKey]))));
};

export const getFilterByTitle = (keyword, filter, fullTextListModeSearch = null) => {
  filter = getClearFilter(filter);

  let fullText = false;
  if (keyword && keyword.substr(0, 1) === '"' && keyword.slice(-1) === '"') {
    keyword = keyword.slice(1, -1);

    const filterGroup = {
      field: 'title.en.keyword',
      value: keyword,
      operator: 'eq'
    };

    if (filter[0] && filter[0].group) {
      filter[0]['group'][filter[0]['group'].length] = filterGroup;
    } else {
      filter[0] = {
        group : { 0: filterGroup }
      }
    }
  } else if (keyword) {
    fullText = {
      fields: ['title.en', 'description.en'],
      matchType: 'must',
      value: keyword
    };
  } else {
    fullText = fullTextListModeSearch;
  }

  return {
    fullText: fullText,
    filter,
  };
};
