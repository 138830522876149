import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  FETCH_CATEGORIES_ATTRIBUTES_START,
  FETCH_CATEGORIES_ATTRIBUTES_FINISHED,
  CLEAR_CATEGORIES_ATTRIBUTES_STATE,
  CLEAR_PRODUCT_ATTRIBUTE_STATE,
  FETCH_PRODUCT_ATTRIBUTES_START,
  FETCH_PRODUCT_ATTRIBUTES_FINISHED,
  UPDATE_PRODUCT_ATTRIBUTE_LIST,
  FETCH_CERTAIN_PRODUCT_ATTRIBUTES_START,
  FETCH_CERTAIN_PRODUCT_ATTRIBUTES_FINISHED,
  FETCH_ATTRIBUTE_OPTIONS_START,
  FETCH_ATTRIBUTE_OPTIONS_FINISHED,
  FETCH_ATTRIBUTES_OPTIONS_START,
  FETCH_ATTRIBUTES_OPTIONS_FINISHED,
  FETCH_PRODUCT_ATTRIBUTES_FOR_VARIATIONS_START,
  FETCH_PRODUCT_ATTRIBUTES_FOR_VARIATIONS_FINISHED,
  FETCH_PRODUCT_ATTRIBUTE_SETS_START,
  FETCH_PRODUCT_ATTRIBUTE_SETS_FINISHED,
  SEARCH_ATTRIBUTE_OPTIONS_START,
  SEARCH_ATTRIBUTE_OPTIONS_FINISHED,
  FETCH_INITIAL_ATTRIBUTES_OPTIONS_START,
  FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
  SET_SELECTED_ATTRIBUTE_OPTION,
  CLEAR_FOUND_ATTRIBUTE_OPTIONS,
  SET_SELECTED_ATTRIBUTES_OPTIONS,
  FETCH_FACETS_ATTRIBUTES_OPTIONS_START,
  FETCH_FACETS_ATTRIBUTES_OPTIONS_FINISHED,
  CLEAR_INITIAL_ATTRIBUTE_OPTIONS, AUTOCOMPLETE_START, AUTOCOMPLETE_FINISHED,
} from '../actions/types';

const initialState = {
  categoriesAttributes: [],
  productAttributes: [],
  categoriesAttributesFetched: false,
  categoriesAttributesFetching: false,
  productAttributesFetching: false,
  productAttributesFetched: false,
  productAttributesSearchStarted: false,
  productAttributesSearchFinished: false,
  certainProductAttributes: [],
  fetchingAttributeOptions: false,
  fetchedAttributeOptions: false,
  fetchingAttributesOptions: false,
  fetchedAttributesOptions: false,
  attributesOptions: [],
  optionsLoadingCode: '',
  productAttributesForVariations: [],
  attributeSets: [],
  searchAttributeOptionsStart: false,
  searchAttributeOptionsFinished: false,
  foundOptions: [],
  searchOptions: [],
  fetchInitialAttributesOptionsStart: false,
  fetchInitialAttributesOptionsFinished: false,
  initialOptions: [],
  selectedAttributesOptions: [],
  fetchFacetsAttributesOptionsStart: false,
  fetchFacetsAttributesOptionsFinished: true,
  facetsOptions: [],
};
const productAttribute = restTypes('product_attribute');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case productAttribute.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case productAttribute.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case productAttribute.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case FETCH_CATEGORIES_ATTRIBUTES_START:
      return {
        ...state,
        categoriesAttributesFetching: true,
        categoriesAttributesFetched: false,
      };
    case FETCH_CATEGORIES_ATTRIBUTES_FINISHED:
      return {
        ...state,
        categoriesAttributesFetching: false,
        categoriesAttributesFetched: true,
        categoriesAttributes: action.payload.list || [],
      };
    case CLEAR_CATEGORIES_ATTRIBUTES_STATE:
      return {
        ...state,
        categoriesAttributesFetching: false,
        categoriesAttributesFetched: false,
        categoriesAttributes: [],
      };
    case CLEAR_PRODUCT_ATTRIBUTE_STATE:
      return { ...restInitState, ...initialState };
    case FETCH_PRODUCT_ATTRIBUTES_START:
      return {
        ...state,
        productAttributesFetching: true,
        productAttributesFetched: false,
      };
    case FETCH_PRODUCT_ATTRIBUTES_FINISHED:
      return {
        ...state,
        productAttributesFetching: false,
        productAttributesFetched: true,
        ...action.payload,
      };
    case UPDATE_PRODUCT_ATTRIBUTE_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_CERTAIN_PRODUCT_ATTRIBUTES_START:
      return {
        ...state,
      };
    case FETCH_CERTAIN_PRODUCT_ATTRIBUTES_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_ATTRIBUTE_OPTIONS_START:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_ATTRIBUTE_OPTIONS_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_ATTRIBUTES_OPTIONS_START:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_ATTRIBUTES_OPTIONS_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_PRODUCT_ATTRIBUTES_FOR_VARIATIONS_START:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_PRODUCT_ATTRIBUTES_FOR_VARIATIONS_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_PRODUCT_ATTRIBUTE_SETS_START:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_PRODUCT_ATTRIBUTE_SETS_FINISHED:
      return {
        ...state,
        ...action.payload,
      };

    case AUTOCOMPLETE_START:
    case AUTOCOMPLETE_FINISHED:
    case SEARCH_ATTRIBUTE_OPTIONS_START:
    case CLEAR_INITIAL_ATTRIBUTE_OPTIONS:
    case SEARCH_ATTRIBUTE_OPTIONS_FINISHED:
    case CLEAR_FOUND_ATTRIBUTE_OPTIONS:
    case FETCH_INITIAL_ATTRIBUTES_OPTIONS_START:
    case FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED:
    case SET_SELECTED_ATTRIBUTE_OPTION:
    case SET_SELECTED_ATTRIBUTES_OPTIONS:
    case FETCH_FACETS_ATTRIBUTES_OPTIONS_START:
    case FETCH_FACETS_ATTRIBUTES_OPTIONS_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
};
