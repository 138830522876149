import React, { memo } from 'react';
import {
  string, node, oneOfType, func, shape, bool,
} from 'prop-types';
import { TextField } from '@material-ui/core';

import IntlMessages from '../../../../util/IntlMessages';
import LinkWithLabel from '../LinkWithLabel';

const TopTenInfoRow = memo((props) => {
  const {
    label, url, title, value, name, onChange, inputLabel,
    textFieldsErrors, disabled,
  } = props;

  return (
    <div className="flex justify-center items-center">
      <LinkWithLabel
        label={label}
        url={url}
      />
      {onChange && (
        <div className="info-row-input">
          <TextField
            disabled={disabled}
            value={value}
            name={name}
            onChange={onChange}
            label={inputLabel}
            helperText={textFieldsErrors.dealName && (
              <IntlMessages id={textFieldsErrors.dealName[0].message} />
            )}
          />
        </div>
      )}
      <div className="topten-title">
        {title}
      </div>
    </div>
  );
});

TopTenInfoRow.propTypes = {
  label: string.isRequired,
  url: string.isRequired,
  value: string,
  name: string,
  inputLabel: string,
  title: oneOfType([string, node]).isRequired,
  textFieldsErrors: shape(),
  onChange: func,
  disabled: bool.isRequired,
};

TopTenInfoRow.defaultProps = {
  onChange: null,
  value: '',
  name: '',
  inputLabel: '',
  textFieldsErrors: {},
};

export default TopTenInfoRow;
