/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { func, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../../components/Layout';
import List from '../../components/List';
import SidebarContent from '../Pim/pages/Home/components/SidebarContent';
import CamsTable from './components/CamsTable';
import { headerList } from '../utils/headerList';
import { AbilityContext } from '../../components/AbilityContext';
import { fetchAttributes } from '../../actions/system';
import { getProductDataMerchantStatus } from "../../actions/cams";
import {camsFirstLoad, getCamsFields} from "../../util/cams";

export const CamsMerchantStatus = (props) => {
  const abilityContext = useContext(AbilityContext);

  const [firstLoad, setFirstLoad] = useState(false);
  const [camsData, setCamsData] = useState({});

  useEffect(() => {
    props.fetchAttributes();
    if (props.camsState.cams && props.camsState.cams.data) {
      const data = props.camsState.cams.data;
      setCamsData(data);
    }
  }, [
    props.camsState,
  ]);

  const {sortedFields, sortedSearchFields, currentFields} = getCamsFields(props.camsFields);
  camsFirstLoad(firstLoad, setFirstLoad, props.getProductDataMerchantStatus);

  return (
    <Layout
      horizontalMenu
      header={{
        className: 'header-mer',
        subHeaderContent: (
          <List
            dir="horizontal"
            className="justify-center"
            dataSource={headerList({ abilityContext })}
          />
        ),
      }}
      sidebar={{
        className: `sidebar-mer sidebar-ranking-mer sidebar-scroll-secondary`,
        content: <SidebarContent
          sortedFields={sortedFields}
          sortedSearchFields={sortedSearchFields}
          condition={props.camsState.cams && props.camsState.cams.condition ? props.camsState.cams.condition : []}
          scroll={props.camsState.cams && props.camsState.cams.scroll ? props.camsState.cams.scroll : []}
          getCamsData={props.getProductDataMerchantStatus}
          itemsPerPage={10}
        />,
      }}
      main={{
        className: 'main-mer main-ranking-mer',
        content: (
          <CamsTable
            fields={currentFields}
            data={camsData}
            camsScroll={props.camsState.cams && props.camsState.cams.scroll ? props.camsState.cams.scroll : []}
            camsCondition={props.camsState.cams && props.camsState.cams.condition ? props.camsState.cams.condition : []}
          />
        )
      }}
    />
  );
};

CamsMerchantStatus.propTypes = {
  camsFields: shape(),
  fetchAttributes: func.isRequired,
  getProductDataMerchantStatus: func.isRequired,
  camsState: shape().isRequired,
};

CamsMerchantStatus.defaultProps = {
  camsFields: {},
};

const stateToProps = state => ({
  camsFields: state.system.modes.cams ? state.system.modes.cams.camsMerchantStatusFields : {},
  camsState: state.cams,
});

const dispatchToProps = {
  fetchAttributes,
  getProductDataMerchantStatus,
};

export default withRouter(connect(stateToProps, dispatchToProps)(CamsMerchantStatus));
