import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';
import { defaultPagination } from '../util/defaultData';

import {
  VIRTUAL_CATEGORY_PAGE_SET_CATEGORY_FIELDS,
  VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_CATEGORIES_ATTRIBUTES,
  VIRTUAL_CATEGORY_PAGE_SET_ATTRIBUTES_MATRIX_ATTRIBUTES,
  VIRTUAL_CATEGORY_PAGE_SET_FILTERS,
  VIRTUAL_CATEGORY_PAGE_SET_FILTERS_ERRORS,
  VIRTUAL_CATEGORY_PAGE_SET_PRODUCTS_NIN_IDS,
  VIRTUAL_CATEGORY_PAGE_SET_PRODUCTS_IN_IDS,
  VIRTUAL_CATEGORY_PAGE_SET_SELECTED_PRODUCT_LIST,
  VIRTUAL_CATEGORY_PAGE_UPDATE_LOADED_PRODUCTS_IN,
  VIRTUAL_CATEGORY_PAGE_UPDATE_LOADED_PRODUCTS_NIN,
  VIRTUAL_CATEGORY_PAGE_COMPLETE_PRODUCTS_LIST_START,
  VIRTUAL_CATEGORY_PAGE_COMPLETE_PRODUCTS_LIST_FINISHED,
  VIRTUAL_CATEGORY_PAGE_SET_IS_APPLY_FILTER_ACTIVE,
  VIRTUAL_CATEGORY_PAGE_SET_IS_SWITCH_ON,
  VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_FILTER,
  VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCT_LIST_START,
  VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCT_LIST_FINISHED,
  VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_COMPOUND_QUERY,
  VIRTUAL_CATEGORY_PAGE_SET_LEFT_SIDE_PAGINATION,
  VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCTS_BY_IDS_START,
  VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCTS_BY_IDS_FINISHED,
  VIRTUAL_CATEGORY_PAGE_SET_SHOW_CATEGORIES_DIALOG,
  VIRTUAL_CATEGORY_PAGE_SET_MAPPED_CATEGORIES,
  VIRTUAL_CATEGORY_PAGE_SET_SELECTED_CATEGORIES,
  VIRTUAL_CATEGORY_PAGE_SET_PHYSICAL_CATEGORY_SEARCH_QUERY,
  VIRTUAL_CATEGORY_PAGE_SET_SELECTED_DIALOG_CATEGORIES,
  VIRTUAL_CATEGORY_PAGE_CLEAR_CATEGORY_PRODUCT_STATE,
  VIRTUAL_CATEGORY_PAGE_SET_RANKING_RULE_CODE,
  VIRTUAL_CATEGORY_PAGE_SET_SORTING_ERRORS,
  VIRTUAL_CATEGORY_PAGE_SET_SORT_RULES,
  VIRTUAL_CATEGORY_PAGE_SET_FACETS,
  VIRTUAL_CATEGORY_PAGE_SET_IS_DATA_CHANGED,
  VIRTUAL_CATEGORY_PAGE_SET_IS_CATEGORY_SAVING,
  VIRTUAL_CATEGORY_PAGE_SET_ERRORS,
  VIRTUAL_CATEGORY_PAGE_SET_ISDUPLICATED_VIRTUAL_CATEGORY,
} from '../actions/types';

const initialState = {
  originalItem: {},
  drawerState: {
    isOpen: false,
  },
  filter: {
    filters: [],
    filtersErrors: [],
    productFilter: [],
    isSwitchOn: false,
    productCategoriesAttributes: [{
      code: 'id',
      frontendInputType: 'selectProductsButton',
      id: 'attr_iHeYyv1MRdm1mG',
      isFilterable: true,
      isRequired: false,
      isSearchable: false,
      isSortable: false,
      isVariation: false,
      label: 'Products',
      value: 'id',
    }],
    attributesMatrixAttributes: [],
    product: {
      list: [],
      selectedProductList: { in: [], nin: [] },
      productsInIds: [],
      productsNotInIds: [],
      productsByIdsForIn: [],
      productsByIdsForNin: [],
      compoundSearch: { value: '' },
      leftSidePagination: defaultPagination,
      isProductDialogApplyFilterActive: false,
      categoryFetchProductListStart: false,
      categoryFetchProductListFinished: false,
      fetchProductsByIdsStarted: false,
      fetchProductsByIdsFinished: false,
    },
    category: {
      showSelectCategoriesDialog: false,
      mappedCategories: [],
      selectedCategories: [],
      selectedDialogCategories: [],
      physicalCategorySearchQuery: '',
    },
  },
  sort: {
    rankingRuleCode: null,
    sortingsErrors: [],
    sortRules: [],
  },
  facet: {
    facets: [],
  },
  item: {
    name: {
      en: '',
      he: '',
    },
    useInProductSearch: false,
    useInDepartmentsTree: false,
    isEnabled: false,
    includeInMenu: false,
    isShowSubcategoriesOnTop: false,
    virtualParams: {
      filters: {},
      sorts: {}
    }
  },
  isDataChanged: false,
  isCategorySaving: false,
  errors: [],
  isDuplicatedCategory: false,
};
const virtualCategoryPageRestTypes = restTypes('virtual-category-page');
const {
  fetchOneStart,
  fetchOneFinished,
  updateStart,
  updateFinished,
  clearFromStateFinished,
  deleteStart,
  deleteFinished,
  clearStateFinished,
  createStart,
  createFinished,
} = virtualCategoryPageRestTypes;

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };
    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case updateStart:
      return {
        ...state,
        ...restStates.updateStart,
        ...action.payload,
      };
    case updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };
    case createStart:
      return {
        ...state,
        ...restStates.createStart,
        ...action.payload,
      };
    case createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        ...action.payload,
      };
    case VIRTUAL_CATEGORY_PAGE_SET_CATEGORY_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    case clearStateFinished:
      return {
        ...restInitState,
        ...initialState,
      };
    case clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
        ...action.payload,
      };
    case deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };

    case VIRTUAL_CATEGORY_PAGE_SET_IS_DATA_CHANGED:
    case VIRTUAL_CATEGORY_PAGE_SET_IS_CATEGORY_SAVING:
    case VIRTUAL_CATEGORY_PAGE_SET_ERRORS:
      return {
        ...state,
        ...action.payload,
      };

    case VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_CATEGORIES_ATTRIBUTES:
    case VIRTUAL_CATEGORY_PAGE_SET_ATTRIBUTES_MATRIX_ATTRIBUTES:
    case VIRTUAL_CATEGORY_PAGE_SET_FILTERS:
    case VIRTUAL_CATEGORY_PAGE_SET_FILTERS_ERRORS:
    case VIRTUAL_CATEGORY_PAGE_SET_IS_SWITCH_ON:
    case VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };

    case VIRTUAL_CATEGORY_PAGE_SET_PRODUCTS_NIN_IDS:
    case VIRTUAL_CATEGORY_PAGE_SET_PRODUCTS_IN_IDS:
    case VIRTUAL_CATEGORY_PAGE_SET_SELECTED_PRODUCT_LIST:
    case VIRTUAL_CATEGORY_PAGE_UPDATE_LOADED_PRODUCTS_IN:
    case VIRTUAL_CATEGORY_PAGE_UPDATE_LOADED_PRODUCTS_NIN:
    case VIRTUAL_CATEGORY_PAGE_COMPLETE_PRODUCTS_LIST_START:
    case VIRTUAL_CATEGORY_PAGE_COMPLETE_PRODUCTS_LIST_FINISHED:
    case VIRTUAL_CATEGORY_PAGE_SET_IS_APPLY_FILTER_ACTIVE:
    case VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCT_LIST_START:
    case VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCT_LIST_FINISHED:
    case VIRTUAL_CATEGORY_PAGE_SET_PRODUCT_COMPOUND_QUERY:
    case VIRTUAL_CATEGORY_PAGE_SET_LEFT_SIDE_PAGINATION:
    case VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCTS_BY_IDS_START:
    case VIRTUAL_CATEGORY_PAGE_FETCH_PRODUCTS_BY_IDS_FINISHED:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            ...action.payload,
          },
        },
      };

    case VIRTUAL_CATEGORY_PAGE_SET_SHOW_CATEGORIES_DIALOG:
    case VIRTUAL_CATEGORY_PAGE_SET_MAPPED_CATEGORIES:
    case VIRTUAL_CATEGORY_PAGE_SET_SELECTED_CATEGORIES:
    case VIRTUAL_CATEGORY_PAGE_SET_PHYSICAL_CATEGORY_SEARCH_QUERY:
    case VIRTUAL_CATEGORY_PAGE_SET_SELECTED_DIALOG_CATEGORIES:
      return {
        ...state,
        filter: {
          ...state.filter,
          category: {
            ...state.filter.category,
            ...action.payload,
          },
        },
      };

    case VIRTUAL_CATEGORY_PAGE_SET_RANKING_RULE_CODE:
    case VIRTUAL_CATEGORY_PAGE_SET_SORTING_ERRORS:
    case VIRTUAL_CATEGORY_PAGE_SET_SORT_RULES:
      return {
        ...state,
        sort: {
          ...state.sort,
          ...action.payload,
        },
      };

    case VIRTUAL_CATEGORY_PAGE_CLEAR_CATEGORY_PRODUCT_STATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...initialState.filter.product,
          },
        },
      };

    case VIRTUAL_CATEGORY_PAGE_SET_FACETS:
      return {
        ...state,
        facet: {
          ...state.facet,
          ...action.payload,
        },
      };
    case VIRTUAL_CATEGORY_PAGE_SET_ISDUPLICATED_VIRTUAL_CATEGORY: 
      return {
        ...state,
        ...action.payload,
      }

    default: return state;
  }
};
