import React, { useState, useEffect, memo } from 'react';
import {
  string, shape, arrayOf, bool, func, number,
} from 'prop-types';
import { isEmpty } from 'lodash';
import Grid from '@material-ui/core/Grid';
import arrayMove from 'array-move';
import { camelize } from 'humps';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import VariantLevelItem from '../VariantLevelItem';
import { generateVariantProductData } from '../../pages/Home/utils/productDataGenerator';

const SortableItem = SortableElement(props => <VariantLevelItem {...props} />);

const SortableList = SortableContainer((props) => {
  const {
    variants, disabled, defaultImage, certainProductAttributes,
    onProductSelect, productsCommentsCount, tableHeaderWidth,
    isEditMode, selectedProducts, onEditClick,
    productAttributes,
  } = props;

  return (
    <div style={{ minWidth: tableHeaderWidth }} className="product-list">
      <Grid container spacing={0} className="justify-center">
        {variants && variants.map((item, index) => {
          const value = generateVariantProductData(item, defaultImage, certainProductAttributes);

          if (!isEmpty(productAttributes) && !isEmpty(item.variationAttributes)) {
            Object.keys(item.variationAttributes).forEach((attrKey) => {
              const currentAttribute = productAttributes
                .find(attr => camelize(attr.code) === attrKey);
              const val = item.variationAttributes[attrKey];

              if (currentAttribute) value[camelize(currentAttribute.label.en)] = val.en ? val.en : val;
            });
          }

          return (
            <SortableItem
              key={item.id}
              index={index}
              variantValue={value}
              itemsCount={variants.length}
              disabled={disabled}
              variantProductItem={item}
              certainProductAttributes={certainProductAttributes}
              onProductSelect={onProductSelect}
              isEditMode={isEditMode}
              selectedProducts={selectedProducts}
              defaultImage={defaultImage}
              onEditClick={onEditClick}
              hasComments={productsCommentsCount.some(pcc => pcc.id === item.id)}
              productCommentsLoading={props.productCommentsLoading}
              productComments={props.productComments}
              fetchActivityLogsByProductId={props.fetchActivityLogsByProductId}
            />
          );
        })}
      </Grid>
    </div>
  );
});

const VariantLevelList = memo((props) => {
  const {
    className, variants, certainProductAttributes, disabled, defaultImage,
    onProductSelect, isEditMode, productsCommentsCount,
    selectedProducts, onEditClick, tableHeaderWidth,
    productAttributes,
  } = props;
  const [localItems, setItems] = useState(variants);

  useEffect(
    () => setItems(variants),
    [variants],
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = arrayMove(localItems, oldIndex, newIndex);
    setItems(newItems);
  };

  return (
    <div className={`dnd-wrapper level-variant product list ignore-parent ${className}`}>
      <SortableList
        axis="y"
        useDragHandle
        productList={localItems}
        onSortEnd={onSortEnd}
        certainProductAttributes={certainProductAttributes}
        variants={variants}
        disabled={disabled}
        defaultImage={defaultImage}
        onProductSelect={onProductSelect}
        isEditMode={isEditMode}
        selectedProducts={selectedProducts}
        onEditClick={onEditClick}
        productCommentsLoading={props.productCommentsLoading}
        productComments={props.productComments}
        fetchActivityLogsByProductId={props.fetchActivityLogsByProductId}
        productAttributes={productAttributes}
        productsCommentsCount={productsCommentsCount}
        tableHeaderWidth={tableHeaderWidth}
      />
    </div>
  );
});

VariantLevelList.propTypes = {
  selectedProducts: arrayOf(shape()).isRequired,
  variants: arrayOf(shape()).isRequired,
  certainProductAttributes: arrayOf(shape()).isRequired,
  productAttributes: arrayOf(shape()).isRequired,
  productComments: arrayOf(shape()).isRequired,
  productsCommentsCount: arrayOf(shape()).isRequired,
  tableHeaderWidth: number.isRequired,
  className: string,
  disabled: bool,
  defaultImage: string.isRequired,
  isEditMode: bool.isRequired,
  productCommentsLoading: bool.isRequired,
  onEditClick: func.isRequired,
  onProductSelect: func.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
};

VariantLevelList.defaultProps = {
  className: '',
  disabled: false,
};

export default VariantLevelList;
