/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const usePromotion = (props, paginationLimit) => {
  const pagination = {
    page: 1,
    limit: paginationLimit,
  };
  const promotionFilter = [{
    group: [{
      field: 'type',
      value: 'promotion_url',
      operator: 'eq',
    }],
  }];

  useEffect(() => {
    props.fetchPromotionList(promotionFilter, pagination);
  }, []);


  useEffect(() => (() => {
    props.clearPromotionState();
  }), []);
};

export default usePromotion;
