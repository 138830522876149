/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  func, string, bool, node, shape, arrayOf, oneOfType, number,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import Chip from '@material-ui/core/Chip';
import Select, { components } from 'react-select';
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import CircularProggress from '../CircularProggress';
import { getTheme } from './utils/settings';
import { getSlicedList } from './utils/getSlicedList';
import IntlMessages from '../../util/IntlMessages';

const styles = theme => getTheme(theme);

const IntegrationReactSelect = (props) => {
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!isEmpty(props.options)) {
      setOptions(getSlicedList(props.options, props.limitsToShow));
    } else {
      setOptions(props.options);
    }
  }, [props.options, props.limitsToShow]);

  const handleInputChange = (searchQuery) => {
    setQuery(searchQuery);
    if (!isEmpty(searchQuery)) {
      const filteredOptions = props.options.filter(
        o => o.label.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      if (props.handleSearchOptions) props.handleSearchOptions(searchQuery);
      setOptions(getSlicedList(filteredOptions, props.limitsToShow));
    } else {
      setOptions(getSlicedList(props.options, props.limitsToShow));
    }
  };

  const handleOnChange = (data) => {
    if (props.onChange) {
      props.onChange({
        target: {
          ...data,
          element: 'autocompleteSingle',
        },
      });
    }
  };

  const handleFocus = () => {
    if (!isFocused) setIsFocused(true);
  };

  const handleBlur = () => {
    if (props.onBlur) props.onBlur();
    if (isFocused) setIsFocused(false);
  };

  const alignClassName = props.prefix || props.suffix ? 'flex items-end' : '';
  const focusClassName = isFocused ? 'focus' : '';

  const { useAdvancedOptions } = props;

  const formatOptionLabel = ({ value, label, disabled }) => {
    const infoOption = value === 'hasMoreOptions';
    const infoOptionClass = infoOption ? 'info-option' : '';

    return (
      <div className={`option-component flex ${infoOptionClass} ${disabled ? 'disabled' : ''}`}>
        <div>{label}</div>
        {!infoOption && (
          <div className={`option-abbreviature ${useAdvancedOptions ? '' : 'hidden'}`}>
            {`- ${value}`}
          </div>
        )}
      </div>
    );
  };

  const inputProps = {
    value: props.value || '',
    inputValue: query,
    onChange: data => handleOnChange({ ...data, name: props.inputProps.name }),
    onInputChange: handleInputChange,
    placeholder: props.placeholder,
    name: props.inputProps.name || props.name,
    className: `react-single-wrap ${props.className}`,
    simpleValue: true,
    useAdvancedOptions: props.useAdvancedOptions,
    options,
    startAdornment: props.suffix,
    isDisabled: props.disabled,
  };

  const NoOptionsMessage = (messageProps) => {
    const noOptionsProps = {
      ...messageProps,
      children: <IntlMessages id="autocomplete.single.noOptions.label" />,
    };

    if (isEmpty(query) && isEmpty(options)) {
      const newProps = {
        ...messageProps,
        children: <IntlMessages id="autocomplete.single.typeSomething.label" />,
      };
      return <components.NoOptionsMessage {...newProps} />;
    }

    if (!isEmpty(query) && isEmpty(options) && props.loadOptions) {
      const newProps = {
        ...messageProps,
        children: <IntlMessages id="autocomplete.single.loading.label" />,
      };
      return <components.NoOptionsMessage {...newProps} />;
    }
    return <components.NoOptionsMessage {...noOptionsProps} />;
  };

  const errorClass = props.error ? 'autocomplete-error' : '';

  return (
    <div className={`autocomplete-select-wrapper ${props.containerClassName} ${alignClassName} ${errorClass}`}>
      {props.prefix && <div className={`prefix ${props.prefixClassName}`}>{props.prefix}</div>}
      <div className={`autocomplete-select-control-wrapper block ${focusClassName} ${props.disabled ? 'disabled' : ''}`}>
        <FormControl hiddenLabel={!props.label} fullWidth disabled={props.disabled}>
          {props.loading && (
            <CircularProggress
              className={`select-loader value-loader bottom ${props.loaderClassName}`}
              loading={props.loading}
              size={15}
              variant="indeterminate"
              disableShrink
              thickness={4}
            />
          )}
          <InputLabel
            className={!isEmpty(props.value) || query || isFocused ? 'select-label-up' : ''}
            htmlFor={props.inputProps.id}
          >
            {props.label}
          </InputLabel>

          {props.onDelete && (
              <div className="select-remove-div" onClick={props.onDelete}> &#x2717; </div>
          )}

          <Select
            isOptionDisabled={option => option.disabled}
            menuPlacement={props.menuPlacement}
            components={{ NoOptionsMessage }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            valueComponent={(valueProps) => {
              const { value, children, onRemove } = valueProps;
              const onDelete = (event) => {
                event.preventDefault();
                event.stopPropagation();
                onRemove(value);
              };
              if (onRemove) {
                return (
                  <Chip
                    tabIndex={-1}
                    label={children}
                    className="chip-wrapper"
                    deleteIcon={<CancelIcon onTouchEnd={onDelete} />}
                    onDelete={onDelete}
                  />
                );
              }
              return <div className="Select-value">{children}</div>;
            }}
            {...inputProps}
            formatOptionLabel={formatOptionLabel}
          />
        </FormControl>
        {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
      </div>
      {props.suffix && <div className={`suffix ${props.suffixClassName}`}>{props.suffix}</div>}
    </div>
  );
};

IntegrationReactSelect.propTypes = {
  disabled: bool,
  label: oneOfType([node, string]),
  helperText: oneOfType([node, string]),
  inputProps: shape(),
  name: string,
  placeholder: string,
  className: string,
  wrapperClassName: string,
  value: oneOfType([shape(), string, bool]),
  options: arrayOf(shape),
  onChange: func,
  onDelete: func,
  limitsToShow: number,
  prefix: oneOfType([node, string]),
  suffix: oneOfType([node, string]),
  containerClassName: string,
  suffixClassName: string,
  prefixClassName: string,
  loaderClassName: string,
  loading: bool,
  useAdvancedOptions: bool,
  loadOptions: bool,
  menuPlacement: string,
  handleSearchOptions: func,
  onBlur: func,
  error: bool,
};

IntegrationReactSelect.defaultProps = {
  disabled: false,
  label: '',
  helperText: '',
  inputProps: null,
  name: '',
  placeholder: '',
  className: '',
  wrapperClassName: '',
  value: '',
  options: [],
  onChange: null,
  onDelete: null,
  limitsToShow: 100,
  prefix: null,
  suffix: null,
  containerClassName: '',
  suffixClassName: '',
  prefixClassName: '',
  loaderClassName: '',
  loading: false,
  loadOptions: false,
  useAdvancedOptions: false,
  menuPlacement: 'auto',
  handleSearchOptions: null,
  onBlur: null,
  error: false,
};

export default withStyles(styles)(IntegrationReactSelect);
