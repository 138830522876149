/**
 * Language Select Dropdown
 */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DropdownToggle, DropdownMenu, Dropdown } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import $ from 'jquery';
import Tooltip from '@material-ui/core/Tooltip';
import {
  func, array, shape,
} from 'prop-types';
// actions
import { setLanguage, rtlLayoutAction } from '../../actions';
import { rootAppPath } from '../../util/paths';

const LanguageProvider = (props) => {
  const [langDropdownOpen, setLangDropdownOpen] = useState(false);

  const toggle = () => {
    setLangDropdownOpen(!langDropdownOpen);
  };

  const rtlLayoutHanlder = (isTrue) => {
    if (isTrue) {
      $('html').attr('dir', 'rtl');
      $('body').addClass('rtl');
    } else {
      $('html').attr('dir', 'ltr');
      $('body').removeClass('rtl');
    }
    props.rtlLayoutAction(isTrue);
  };

  const onChangeLanguage = (lang) => {
    setLangDropdownOpen(false);
    props.setLanguage(lang);

    if (lang.locale === 'ar' || lang.locale === 'he') {
      rtlLayoutHanlder(true);
    } else {
      rtlLayoutHanlder(false);
    }
  };

  const { locale, languages } = props.settings;

  return (
    <Dropdown nav className="list-inline-item language-dropdown tour-step-5" isOpen={langDropdownOpen} toggle={toggle}>
      <DropdownToggle caret nav className="header-icon language-icon">
        <Tooltip title="Languages" placement="bottom">
          {locale.icon && <img src={require(`../../assets/flag-icons/${locale.icon}.png`)} className="mr-10" width="25" height="16" alt="lang-icon" />}
        </Tooltip>
      </DropdownToggle>
      <DropdownMenu>
        <div className="dropdown-content">
          <div className="dropdown-top d-flex justify-content-between rounded-top bg-primary">
            <span className="text-white font-weight-bold">Languages</span>
          </div>
          <Scrollbars className="rct-scroll" autoHeight autoHeightMin={100} autoHeightMax={280}>
            <ul className="list-unstyled mb-0 dropdown-list">
              {languages.map(language => (
                <li
                  role="presentation"
                  key={language}
                  onKeyUp={() => onChangeLanguage(language)}
                  onClick={() => onChangeLanguage(language)}
                >
                  <a href={rootAppPath}>
                    {language.icon && (
                      <img
                        src={require(`../../assets/flag-icons/${language.icon}.png`)}
                        className="mr-10"
                        width="25"
                        height="16"
                        alt="lang-icon"
                      />
                    )}
                    {language.name}
                  </a>
                </li>
              ))}
            </ul>
          </Scrollbars>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

LanguageProvider.propTypes = {
  setLanguage: func.isRequired,
  rtlLayoutAction: func.isRequired,

  settings: shape({
    locale: shape(),
    languages: array,
  }).isRequired,
};

LanguageProvider.defaultProps = {};

const mapStateToProps = state => ({
  settings: state.settings,
});

const mapDispatchToProps = {
  setLanguage,
  rtlLayoutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageProvider);
