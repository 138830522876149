export default {
  item: {},
  list: [],
  searchResults: [],
  search: [],
  errors: [],
  hasErrors: false,
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  updatingMultiple: false,
  updatedMultiple: false,
  fetchingOne: false,
  fetchedOne: false,
  deleting: false,
  deleted: false,
  creating: false,
  created: false,
  clearingFromStateFinished: false,
};
