const config = {
  env: process.env.REACT_APP_ENV,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  apiPath: process.env.REACT_APP_API_PATH,
  functionPath: process.env.REACT_APP_FUNCTION_PATH,
  currencyRatesPath: process.env.REACT_APP_CURRENCY_RATES,
  imsApiPath: process.env.REACT_APP_IMS_API_PATH,
  pmacApiPath: process.env.REACT_APP_PMAC_API_PATH,
  websitePath: process.env.REACT_APP_WEBSITE_PATH,
  zapWebsitePath: process.env.REACT_APP_ZAP_WEBSITE_PATH,
  kspWebsitePath: process.env.REACT_APP_KSP_WEBSITE_PATH,
  camsApiPath: process.env.REACT_APP_CAMS_API_PATH,
  yotpoAppKey: process.env.REACT_APP_YOTPO_APP_KEY,
  yotpoApiPath: process.env.REACT_APP_YOTPO_API_BASE_URL,
};

export default config;
