import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const influencerItemSelector = item => item;

const filtersSelector = createSelector(
  influencerItemSelector,
  item => (!isEmpty(item) && item.page.productSection.filters
    ? item.page.productSection.filters
    : []),
);

export const productsFiltersSelector = createSelector(
  filtersSelector,
  productsFilters => (productsFilters),
);

export default {
  productsFiltersSelector,
};
