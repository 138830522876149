import {
  bulkUpdateVirtualCategories,
} from '../../../../actions/virtualCategory';
import {
  createRank,
  clearCreateRankingState,
  fetchList as fetchRankingList,
  fetchRankingByName,
} from '../../../../actions/ranking';
import {
  fetchRootCategories as fetchRootScopeCategories,
  fetchChildCategories as fetchChildScopeCategories,
  clearScopeCategoriesState,
  searchCategories as searchScopeCategories,
  clearSearchListState as clearScopeCategorySearchState,
  onToggleScopeCategoryTreeCollapse,
  handleLoadScopeSubcategories,
  loadAllChildCategories,
  fetchScopeCategoriesByIds,
} from '../../../../actions/scopeCategory';
import {
  normalizationSelector,
  pushingSelector,
  rankingAttributeSelector,
} from '../../selectors/appSettings';

export const mapStateToProps = ({
  appSettings, virtualCategory, ranking, scopeCategory,
}) => ({
  rankingAttribute: rankingAttributeSelector(appSettings.settings),
  normalization: normalizationSelector(appSettings.settings),
  pushing: pushingSelector(appSettings.settings),
  appSettings,
  virtualCategory,
  ranking,
  scopeCategory,
});

export const actionCreators = {
  fetchChildScopeCategories,
  clearCreateRankingState,
  createRank,
  fetchRankingList,
  fetchRootScopeCategories,
  clearScopeCategoriesState,
  searchScopeCategories,
  bulkUpdateVirtualCategories,
  clearScopeCategorySearchState,
  onToggleScopeCategoryTreeCollapse,
  handleLoadScopeSubcategories,
  loadAllChildCategories,
  fetchRankingByName,
  fetchScopeCategoriesByIds,
};

export default {
  mapStateToProps,
  actionCreators,
};
