import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

const initialState = {
  attributes: {
    categories: [],
    search: [],
  },
};
const defaultAttributeRestTypes = restTypes('default_attribute');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case defaultAttributeRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case defaultAttributeRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        attributes: action.payload,
      };
    default: return state;
  }
};
