import {
  func, shape, bool, number, arrayOf, oneOfType, string,
} from 'prop-types';

export const propTypes = {
  history: shape().isRequired,
  ranking: shape({
    fetched: bool,
    list: arrayOf(shape({
      id: oneOfType([string, number]),
    })),
  }).isRequired,
  fetchPushingList: func.isRequired,
  fetchRankingList: func.isRequired,
  fetchRanking: func.isRequired,
  clearVirtualCategoryTreeState: func.isRequired,
  clearScopeCategoryTreeState: func.isRequired,
};

export const defaultProps = {

};

export default {
  propTypes,
  defaultProps,
};
