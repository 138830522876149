export default ({
  influencerId, name, affiliateId, storeLink,
}) => ({
  id: influencerId,
  name,
  affiliateId,
  photoUrl: '',
  trackingInfo: {
    offerUrl: '',
    trackingUrl: '',
    offerPostbackUrl: '',
    goalPostbackUrl: '',
  },
  page: {
    storeName: '',
    bannerImageUrl: '',
    storeLink,
    topTen: {
      key: 'topten_section',
      virtualCategories: [],
      filters: [],
      selectedProductsFilters: [],
    },
    deal: {
      key: 'deal_section',
      name: '',
      bannerImageUrl: '',
      filters: [],
      selectedProductsFilters: [],
    },
    productSection: {
      key: 'product_section',
      filters: [],
      selectedProductsFilters: [],
    },
  },
});
