import {
  shape, arrayOf, string, func, bool, number, oneOfType,
} from 'prop-types';

export const propTypes = {
  match: shape().isRequired,
  history: shape().isRequired,
  rankingAttribute: shape({
    list: arrayOf(shape({
      title: string,
      value: string,
    })),
  }).isRequired,
  pushing: shape({
    list: arrayOf(shape({
      title: string,
      value: string,
    })),
  }).isRequired,
  ranking: shape({
    item: shape({
      id: string,
    }),
    fetchedOne: bool,
  }).isRequired,
  normalization: shape({
    list: arrayOf(shape({
      title: string,
      value: string,
    })),
  }).isRequired,
  rankingCategory: shape({
    list: arrayOf(shape({
      id: string,
      parentId: string,
      name: oneOfType([string, shape()]),
    })),
  }).isRequired,
  scopeCategory: shape({
    treeItems: arrayOf(shape({
      id: oneOfType([number, string]),
    })),
    searchResults: arrayOf(shape({
      id: oneOfType([number, string]),
    })),
  }).isRequired,
  fetchRanking: func.isRequired,
  fetchRootScopeCategories: func.isRequired,
  fetchChildScopeCategories: func.isRequired,
  clearFetchedRankingState: func.isRequired,
  clearUpdatedRankingState: func.isRequired,
  clearDeletedRankingState: func.isRequired,
  searchScopeCategories: func.isRequired,
  updateRank: func.isRequired,
  deleteRank: func.isRequired,
  fetchRankingList: func.isRequired,
  fetchRankingCategories: func.isRequired,
  clearScopeCategoriesState: func.isRequired,
  clearScopeCategorySearchState: func.isRequired,
  deleteRankFromState: func.isRequired,
  onToggleScopeCategoryTreeCollapse: func.isRequired,
  handleLoadScopeSubcategories: func.isRequired,
  bulkUpdateVirtualCategories: func.isRequired,
  fetchRankingByName: func.isRequired,
};

export const defaultProps = {

};

export default {
  propTypes,
  defaultProps,
};
