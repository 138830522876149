export const actionOptions = [{
  label: 'Restore',
  value: 'restore',
}, {
  label: 'Remove',
  value: 'remove',
}, {
  label: 'Edit',
  value: 'edit',
}, {
  label: 'Mark as reviewed',
  value: 'mark_as_reviewed',
}, {
  label: 'Mark as not reviewed',
  value: 'mark_as_not_reviewed',
}, {
  label: 'Unlock attributes',
  value: 'unlock_attributes',
}, {
  label: 'Add badge',
  value: 'add_badge',
  disabled: true,
}];

export const actions = {
  restore: 'restore',
  remove: 'remove',
  edit: 'edit',
  markAsReviewed: 'mark_as_reviewed',
  markAsNotReviewed: 'mark_as_not_reviewed',
  unlockAttributes: 'unlock_attributes',
  addBadge: 'add_badge',
};

export const productStatuses = {
  visibleGlobal: 'visible_global',
  notVisible: 'not_visible',
  notReviewed: 'not_reviewed',
  reviewed: 'reviewed',
};

export default {
  actionOptions,
  actions,
  productStatuses,
};
