import React from 'react';
import {
  arrayOf, shape,
} from 'prop-types';
import { isEmpty } from 'lodash';
import {
  Grid,
} from '@material-ui/core';

import ProductItem from './ProductItem';

const ProductsListExample = (props) => {
  const {
    productsList,
  } = props;

  const parseItem = product => ({
    id: product.id,
    images: product.images,
    status: product.status,
    fields: [{
      id: 1,
      label: 'Brand',
      value: product.specifications.aoBrandName,
    }, {
      id: 2,
      label: 'Title',
      value: product.title.en,
    }, {
      id: 3,
      label: 'Description',
      value: product.description.en,
    }],
  });

  return (
    <div className="products-list related flex flex-wrap">
      {!isEmpty(productsList) && (
        <Grid container spacing={0}>
          {
            productsList.map((cP) => {
              const item = parseItem(cP);

              return (
                <ProductItem key={cP.id} productItem={item} />
              );
            })
          }
        </Grid>
      )}
    </div>
  );
};

ProductsListExample.propTypes = {
  productsList: arrayOf(shape()).isRequired,
};

ProductsListExample.defaultProps = {};

export default ProductsListExample;
