/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useState, useContext
} from 'react';
import PropTypes, { func, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../../components/Layout';
import List from '../../components/List';
import SidebarContent from '../Pim/pages/Home/components/SidebarContent';
import RepricerTable from './components/RepricerTable';
import { setFilterTarget } from '../../actions/pim';
import { getRepricerData } from '../../actions/repricer';
import { headerList } from '../utils/headerList';
import { AbilityContext } from '../../components/AbilityContext';
import { fetchAttributes } from '../../actions/system';
import useScopeCategoryEffects from '../Pim/pages/Home/hooks/useScopeCategory';
import {
  fetchRootCategories as fetchRootScopeCategories,
  clearScopeCategoryTreeState,
} from '../../actions/scopeCategory';
import { sortFields } from "../../util/fields";

export const Repricer = (props) => {
  const abilityContext = useContext(AbilityContext);
  const [locationHref, setLocationHref] = useState('');
  const [firstLoad, setFirstLoad] = useState(false);
  const [repricerData, setRepricerData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const repricerFields = props.repricerFields;
  const sortedFields = sortFields(repricerFields, 'order');
  const repricerSearchFields = props.repricerSearchFields;
  const sortedSearchFields = sortFields(repricerSearchFields, 'searchableOrder');

  const sortedPredefinedFilters = props.repricerPredefinedFilters;

  if (locationHref !== document.location.href) {
    setFirstLoad(false);
    setLocationHref(document.location.href);
  }

  useEffect(() => {
    props.fetchAttributes();
    props.setFilterTarget('repricer');
    if (props.repricerData.data) {
      setRepricerData(props.repricerData.data);
    }
    if (props.repricerData.itemsPerPage) {
      setItemsPerPage(props.repricerData.itemsPerPage);
    }
  }, [
    props.repricerData, props.setFilterTarget
  ]);

  useScopeCategoryEffects({
    actions: {
      fetchRootScopeCategories: props.fetchRootScopeCategories,
      clearScopeCategoryTreeState: props.clearScopeCategoryTreeState,
    },
  });

  if (firstLoad === false) {
    const storedSearchFilters = sessionStorage.getItem('pim.searchFilters');
    const parsedStoredSearchFilters = JSON.parse(storedSearchFilters) || { repricer: [] };
    if (parsedStoredSearchFilters.repricer.length === 0) {
      setTimeout(function () {
        props.getRepricerData({ limit: itemsPerPage, offset: 0 });
      }, 1000);
    }
    setFirstLoad(true);
  }

  return (
    <Layout
      horizontalMenu
      header={{
        className: 'header-mer',
        subHeaderContent: (
          <List
            dir="horizontal"
            className="justify-center"
            dataSource={headerList({ abilityContext })}
          />
        ),
      }}
      sidebar={{
        className: 'sidebar-mer sidebar-ranking-mer sidebar-scroll-secondary',
        content: <SidebarContent
          sortedFields={sortedFields}
          sortedSearchFields={sortedSearchFields}
          sortedFilters={sortedPredefinedFilters}
          condition={props.repricerData.condition}
          scroll={props.repricerData.scroll}
          getRepricerData={props.getRepricerData}
          reset={props.repricerData.reset}
          itemsPerPage={itemsPerPage}
        />,
      }}
      main={{
        className: 'main-mer grid-wide',
        content: (
          <RepricerTable
            getRepricerData={props.getRepricerData}
            repricerFields={sortedFields}
            repricerData={repricerData}
            repricerScroll={props.repricerData.scroll ? props.repricerData.scroll : []}
            repricerCondition={props.repricerData.condition ? props.repricerData.condition : []}
            currency={props.currency}
            itemsPerPage={itemsPerPage}
          />
        )
      }}
    />
  );
};

Repricer.propTypes = {
  setFilterTarget: func.isRequired,
  getRepricerData: func.isRequired,
  repricerData: shape().isRequired,
  repricerFields: shape(),
  repricerPredefinedFilters: shape(),
  repricerSearchFields: shape(),
  currency: PropTypes.object.isRequired,
  fetchRootScopeCategories: func.isRequired,
  clearScopeCategoryTreeState: func.isRequired,
};

Repricer.defaultProps = {
  repricerFields: {},
  repricerPredefinedFilters: {},
  repricerSearchFields: {},
  currency: {},
};

const stateToProps = state => ({
  repricerFields: (document.location.href.indexOf('repricer') !== -1)
    ? state.system.modes.repricer ? state.system.modes.repricer.fields : {}
    : state.system.modes.repricerIssues ? state.system.modes.repricerIssues.fields : {},

  repricerPredefinedFilters: (document.location.href.indexOf('repricer') !== -1)
    ? state.system.modes.repricer ? state.system.modes.repricer.sortedPredefinedFilters : {}
    : state.system.modes.repricerIssues ? state.system.modes.repricerIssues.sortedPredefinedFilters : {},

  repricerSearchFields: (document.location.href.indexOf('repricer') !== -1)
    ? state.system.modes.repricer ? state.system.modes.repricer.sortedSearchFields : {}
    : state.system.modes.repricer ? state.system.modes.repricer.sortedSearchFields : {},

  repricerData: state.pim.repricer,
  currency: state.currency.item,
});

const dispatchToProps = {
  setFilterTarget,
  getRepricerData,
  fetchAttributes,
  fetchRootScopeCategories,
  clearScopeCategoryTreeState,
};

export default withRouter(connect(stateToProps, dispatchToProps)(Repricer));
