import { createSelector } from 'reselect';

const productAttributeRootSelector = state => state;

const stateSelector = createSelector(
  productAttributeRootSelector,
  state => ({
    certainProductAttributes: state.certainProductAttributes,
    attributeSets: state.attributeSets,
    initialOptions: state.initialOptions,
  }),
);

export const productAttributeSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  productAttributeSelector,
};
