import React, { Fragment } from 'react';
import {
  node, bool, shape, func, arrayOf, string,
} from 'prop-types';
import MatButton from '@material-ui/core/Button';
import swal from 'sweetalert';
import CircularProgress from '@material-ui/core/CircularProgress';

import IntlMessages from '../../util/IntlMessages';
import { ascDescOptions, rankingRule } from '../../util/constants/constants';
import useStateStore from './hooks/useState';
import useProductSortingAttributesEffects from './hooks/useProductSortingAttributes';
import Body from './SortByBody';
import removeUselessErrors from './utils/removeUselessErrors';

const SortBy = (props) => {
  const state = useStateStore(props);
  useProductSortingAttributesEffects(state, props);

  const {
    content, formFooter, attributes, errors, disabled, sortContentClass,
  } = props;

  const propsSortings = [...attributes.categories];
  propsSortings.push({
    code: 'ranking_rule',
    frontendInputType: 'text',
    isSortable: true,
    isRequired: true,
    label: 'Ranking rule',
  });
  const filteredPropsSortings = propsSortings.filter(s => s.isSortable);

  if (state.productSortingAttributes && state.productSortingAttributes
    .some(p => p.field === 'ranking_rule') && attributes.productSorting.length === 2) {
    state.productSortingAttributes.shift();
  }

  const onlyExistedCodes = filteredPropsSortings.filter(attr => attr.code);

  const attributeCodeOptions = onlyExistedCodes.map((attr) => {
    const option = {
      title: attr.label.en || attr.label,
      label: attr.label.en || attr.label,
      value: attr.value || attr.code,
      disabled: attr.disabled
        || !!state.productSortingAttributes.find(s => s.field === attr.code),
    };
    return option;
  });

  const addSorting = () => {
    const { productSortingAttributes } = state;
    const newSorting = {
      field: '',
      defaultValue: ascDescOptions.asc.value,
      frontendInputType: 'dropdown',
      isFilterable: false,
      isRequired: true,
      isSearchable: false,
      isSortable: true,
      isVariation: false,
      label: { en: '', he: '' },
      key: new Date().getMilliseconds(),
      options: Object.values(ascDescOptions),
    };
    const supplementedSortings = [
      ...productSortingAttributes,
      newSorting,
    ];
    const payload = removeUselessErrors(supplementedSortings);
    state.setProductSortingAttributes(payload);
    props.onChange(payload);
  };

  const handleSortSelectChange = ({ isRankingRule, productSortingAttributes }) => {
    state.setProductSortingAttributes(productSortingAttributes);
    state.setIsRankingRule(isRankingRule);
    props.onChange(productSortingAttributes);
  };

  const handleRowRemove = ({ isRankingRule, productSortingAttributes }) => {
    swal({
      title: 'Delete sort by attribute',
      text: 'Are you sure you want to permanently delete this attribute?',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    })
      .then((willDiscard) => {
        if (willDiscard) {
          state.setProductSortingAttributes(productSortingAttributes);
          state.setIsRankingRule(isRankingRule);
          props.onChange(productSortingAttributes);
        }
      });
  };

  const handleSortEnd = (updatedList) => {
    props.onChange(updatedList);
  };

  const show = state.productSortingAttributes.some(p => p.field !== rankingRule)
  || state.productSortingAttributes.length === 0;

  const mappRankingOptions = options => options.map(r => ({
    title: r.name,
    label: r.name,
    value: r.name,
  }));

  const isSeparate = props.separate ? '' : 'form-dashed pt-20';

  return (
    <div className={`category-filter-wrapper block ${isSeparate}`}>
      <div className={`flex items-start relative ${sortContentClass}`}>
        <div className="form-label">
          <IntlMessages id="form.sortByLabel" />
        </div>
        <Fragment>
          <Body
            rows={state.productSortingAttributes}
            key={JSON.stringify(attributeCodeOptions)}
            attrOptions={attributeCodeOptions}
            rankingOptions={mappRankingOptions(attributes.rankingOptions)}
            onSortSelectChange={handleSortSelectChange}
            onRowRemove={handleRowRemove}
            onRankingChange={props.onRankingChange}
            rankingRuleCode={props.rankingRuleCode}
            onSortEnd={handleSortEnd}
            errors={errors}
            loading={props.loading}
            disabled={disabled}
          />
          {content}
        </Fragment>
      </div>
      {show && (
        <div className="relative flex mt-15">
          <MatButton
            variant="contained"
            color="primary"
            className="text-white"
            onClick={addSorting}
            disabled={props.loading || !props.visible || disabled}
          >
            <IntlMessages id="button.addSortLabel" />
          </MatButton>
          {props.loading && (
            <CircularProgress
              variant="indeterminate"
              disableShrink
              className="progress-warning search-filter-loader loader bottom"
              size={15}
              thickness={4}
            />
          )}
        </div>
      )}
      {formFooter}
    </div>
  );
};

SortBy.propTypes = {
  attributes: shape(),
  content: node,
  formFooter: node,
  errors: arrayOf(shape()),
  onChange: func.isRequired,
  onRankingChange: func.isRequired,
  rankingRuleCode: string,
  loading: bool,
  visible: bool,
  disabled: bool,
  separate: bool,
  sortContentClass: string,
};

SortBy.defaultProps = {
  attributes: {},
  content: null,
  formFooter: null,
  errors: [],
  rankingRuleCode: '',
  loading: false,
  visible: false,
  disabled: false,
  separate: false,
  sortContentClass: '',
};

export default SortBy;
