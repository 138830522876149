import React, { useState, useEffect } from 'react';
import { shape } from 'prop-types';
import { decamelize } from 'humps';
import { isEmpty } from 'lodash';
import {
  List, ListItem, ListItemIcon, Checkbox, ListItemText,
} from '@material-ui/core';

import IntlMessages from 'util/IntlMessages';

const RegularFacet = (props) => {
  const [options, setOptions] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const {
    facet, facetListParams,
  } = props;

  useEffect(() => {
    const splicedList = [...facet.options].splice(0, 5);
    if (!isEmpty(facet.options)) setOptions(splicedList);
  }, [facet.options]);

  const key = decamelize(facet.key);
  const currentKeyValue = facetListParams.virtualFacetFilter[key];

  const handleCheckboxChange = (value) => {
    const itemAlreadyExist = currentKeyValue && currentKeyValue.includes(value);

    const sameFilterValue = itemAlreadyExist
      ? [...currentKeyValue].filter(v => v !== value)
      : [value];

    const getFilterValue = () => {
      if (currentKeyValue && itemAlreadyExist) return sameFilterValue;
      if (currentKeyValue && !itemAlreadyExist) return [...currentKeyValue, value];
      return [value];
    };

    const newFilterValue = getFilterValue();

    const filterItem = !isEmpty(newFilterValue)
      ? {
        [key]: newFilterValue,
      } : {};

    const existedValues = { ...facetListParams.virtualFacetFilter };

    if (existedValues[key]) {
      delete existedValues[key];
    }

    facetListParams.handleChangeFacetFilter({
      ...existedValues,
      ...filterItem,
    });
  };

  const handleToggleExpand = () => {
    const splicedList = [...facet.options].splice(0, 5);
    if (expanded) setOptions(splicedList);
    else setOptions(facet.options);
    setExpanded(!expanded);
  };

  return (
    <div className="checkbox-sm default-facet">
      <h4 className="facet-title">{facet.label}</h4>
      <div className="default-facet-group" key={`hierarchical_facet_${facet.id}`}>
        <div className="checkbox-wrapper">
          <List component="div" className="facet-options-list" disablePadding>
            {!isEmpty(options) ? (
              <>
                {options.map((o) => {
                  const handleChange = () => handleCheckboxChange(o.value);

                  return (
                    <ListItem key={o.key} button onClick={handleChange}>
                      <ListItemIcon>
                        <Checkbox
                          edge="end"
                          checked={!isEmpty(currentKeyValue) && currentKeyValue.includes(o.value)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={o.label} />
                    </ListItem>
                  );
                })}
                {facet.options.length > 5 && (
                  expanded ? (
                    <ListItem key="showLess" button onClick={handleToggleExpand}>
                      <ListItemIcon />
                      <ListItemText primary={(
                        <span className="label-less">
                          <IntlMessages id="facet.helper.showLess" />
                        </span>
                      )}
                      />
                    </ListItem>
                  ) : (
                    <ListItem key="showMore" button onClick={handleToggleExpand}>
                      <ListItemIcon />
                      <ListItemText primary={(
                        <span className="label-more">
                          <IntlMessages id="facet.helper.showMore" />
                        </span>
                      )}
                      />
                    </ListItem>
                  )
                )}
              </>
            ) : (
              <ListItem key="no-facets" button disabled>
                <ListItemText primary={<IntlMessages id="facet.helper.emptyFacetOptions" />} />
              </ListItem>
            )}
          </List>
        </div>
      </div>
    </div>
  );
};

RegularFacet.propTypes = {
  facet: shape().isRequired,
  facetListParams: shape().isRequired,
};

RegularFacet.defaultProps = {};

export default RegularFacet;
