import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  func,
  shape,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import { SortableElement } from 'react-sortable-hoc';

import ParentLevelGridItem from '../ParentLevelGridItem';
import {
  generateParentProductData,
} from '../../pages/Home/utils/productDataGenerator';
import { updateSavedProducts } from "../../../../actions/user";
import { updateProduct } from '../../../../actions/product';

const SortableItem = SortableElement(props => (
  <ParentLevelGridItem
    disabled={props.disabled}
    gridItemProps={props.gridItemProps}
    key={props.key}
    index={props.index}
    handleSelectProduct={props.handleSelectProduct}
    handleUnSelectProduct={props.handleUnSelectProduct}
    handleUnSelectAllProducts={props.handleUnSelectAllProducts}
    selectedProducts={props.selectedProducts}
    updateProduct={props.updateProduct}
  />
));

const SortableList = (props) => {
  const {
    hocSortableListProps,
  } = props;
  const sortedAttributes = hocSortableListProps.attributes
    .sort((a, b) => a.order - b.order);

  const [selectedProducts, setSelectedProducts] = useState(null);

  useEffect(() => {
    const selectedProducts = props.sessionItem.selectedPreviewProducts ? props.sessionItem.selectedPreviewProducts : [];
    setSelectedProducts(selectedProducts);
  }, [props.sessionItem.selectedPreviewProducts]);

  const handleSelectProduct = (e, id) => {
    selectedProducts.push(id);
    setSelectedProducts(selectedProducts);
    props.updateSavedProducts(props.sessionItem.id, selectedProducts);
  }

  const handleUnSelectProduct = (e, id) => {
    const index = selectedProducts.indexOf(id);
    if (index > -1) {
      selectedProducts.splice(index, 1);
    }
    setSelectedProducts(selectedProducts);
    props.updateSavedProducts(props.sessionItem.id, selectedProducts);
  }

  const handleUnSelectAllProducts = () => {
    const productList = hocSortableListProps.productList;
    for (let i = 0; i < productList.length; i++) {
      const productValue = generateParentProductData(
        productList[i],
        hocSortableListProps.defaultImage,
        hocSortableListProps.initialAttributesOptions,
        sortedAttributes,
      );
      const id = productValue.id;
      const index = selectedProducts.indexOf(id);
      if (index > -1) {
        selectedProducts.splice(index, 1);
      }
    }
    setSelectedProducts(selectedProducts);

    props.updateSavedProducts(props.sessionItem.id, selectedProducts);
  }

  return (
    <div className="product-list grid-view-container">
      <Grid container spacing={0} className={`grid-wrapper-${hocSortableListProps.grid}`}>
        {hocSortableListProps.productList.map((item, index) => {
          const productValue = generateParentProductData(
            item,
            hocSortableListProps.defaultImage,
            hocSortableListProps.initialAttributesOptions,
            sortedAttributes,
          );

          return (
            <SortableItem
              key={item.id}
              index={index}
              handleSelectProduct={handleSelectProduct}
              handleUnSelectProduct={handleUnSelectProduct}
              handleUnSelectAllProducts={handleUnSelectAllProducts}
              selectedProducts={selectedProducts}
              disabled={hocSortableListProps.disabled}
              gridItemProps={{
                value: productValue,
                item,
                onSettingsCLick: hocSortableListProps.onSettingsCLick,
                sortedAttributes,
                grid: hocSortableListProps.grid,
                selectedEntity: hocSortableListProps.selectedEntity,
                defaultImage: hocSortableListProps.defaultImage,
                disabled: hocSortableListProps.disabled,
                isEditMode: hocSortableListProps.isEditMode,
                fetchActivityLogsByProductId: hocSortableListProps.fetchActivityLogsByProductId,
                productsCommentsCount: hocSortableListProps.productsCommentsCount,
                productComments: hocSortableListProps.productComments,
                productCommentsLoading: hocSortableListProps.productCommentsLoading,
                pagination: hocSortableListProps.pagination,
                edidActionsAreDisabled: hocSortableListProps.edidActionsAreDisabled,
                certainProductAttributes: hocSortableListProps.certainProductAttributes,
              }}
            />
          );
        })}
      </Grid>
    </div>
  );
};

SortableList.propTypes = {
  hocSortableListProps: shape().isRequired,
  sessionItem: shape().isRequired,
  updateSavedProducts: func.isRequired,
  updateProduct: func.isRequired,
};

SortableList.defaultProps = {
  sessionItem: {},
};

const mapStateToProps = state => ({
  sessionItem: state.session.item,
});

const mapDispatchToProps = {
  updateSavedProducts,
  updateProduct,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SortableList));
