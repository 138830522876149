import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  SET_SELECTED_PROMOTION,
} from '../actions/types';

const initialState = {
  list: [],
  item: {},
  searchStarted: false,
  searchFinished: false,
  searchResults: [],
  selectedPromotion: {},
};
const promotion = restTypes('promotion_widget');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case promotion.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case promotion.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case promotion.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case promotion.clearAllFinished:
      return {
        ...initialState,
        ...restInitState,
      };
    case SET_SELECTED_PROMOTION:
      return {
        ...state,
        ...action.payload,
      };

    default: return state;
  }
};
