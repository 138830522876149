/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useState, useEffect, useRef, memo,
} from 'react';
import {
  shape, arrayOf, bool, func, string,
} from 'prop-types';
import { VariableSizeList } from 'react-window';
import { camelize } from 'humps';

import Row from './Row';

const List = memo((props) => {
  const {
    productList, defaultImage, attributes, certainProductAttributes,
    productAttributesForVariations, disabled, searchFields,
    fetchProductAttributesForVariations, isEditMode, productComments,
    pagination, disableEdit, productsCommentsCount,
    fetchActivityLogsByProductId, productCommentsLoading,
  } = props;
  const [collapsed, setCollapsed] = useState([]);
  const [variantsCollapsed, setVariantsCollapsed] = useState([]);
  const sortedAttributes = attributes.sort((a, b) => a.order - b.order);

  const calculateItemSize = (item) => {
    const itemVariants = item.variants || [];
    const variantsMerchants = itemVariants
      ? itemVariants.filter(v => variantsCollapsed.includes(v.id))
        .map(v => v.merchants).flat().filter(m => m)
      : [];

    const result = [...itemVariants, ...variantsMerchants].length + 1;
    return result * 121;
  };

  const getItemSize = (index) => {
    const size = collapsed.includes(productList[index].id)
      ? (calculateItemSize(productList[index]))
      : 120;
    return size;
  };

  const useResetCache = (data) => {
    const ref = useRef();
    useEffect(() => {
      ref.current.resetAfterIndex(0);
    }, [data]);
    return ref;
  };

  const getValue = (p, o) => p.reduce((xs, x) => (((xs && xs[x]) || (xs && Number.isInteger(xs[x])))
    ? xs[x]
    : null), o);

  const getVal = (item) => {
    const imgData = item.images ? item.images[0] : defaultImage;
    const rootValues = attributes
      .filter(attr => getValue(camelize(attr.code)
        .split('.'), item) !== undefined);
    const value = {
      id: item.id,
      imgData,
      brand: item.specifications.aoBrandNameOrig,
    };

    rootValues.forEach((v) => {
      const itemValue = getValue(camelize(v.code).split('.'), item);

      value[camelize(v.value)] = itemValue;
    });

    return value;
  };

  const propsPayload = {
    collapsed,
    setCollapsed,
    variantsCollapsed,
    setVariantsCollapsed,
    handleExpandParentLevel: props.handleExpandParentLevel,
    categoriesAttributes: props.categoriesAttributes,
    itemsCount: productList.length,
    list: productList,
    disabled,
    getVal,
    sortedAttributes,
    certainProductAttributes,
    productAttributesForVariations,
    fetchProductAttributesForVariations,
    isEditMode,
    pagination,
    searchFields,
    productComments,
    disableEdit,
    productsCommentsCount,
    fetchActivityLogsByProductId,
    productCommentsLoading,
    defaultImage,
  };

  const ref = useResetCache(propsPayload);

  const getStyles = () => {
    let width = 0;
    let height = 0;
    const element = document.getElementById('scrollContainer');
    if (element) {
      width = document.getElementById('scrollContainer').clientWidth;
      height = document.getElementById('scrollContainer').clientHeight;
    }

    return { width, height };
  };

  const { width, height } = getStyles();
  return (
    <VariableSizeList
      className="pim-list mt-0 images-view"
      ref={ref}
      height={height}
      width={width}
      itemSize={getItemSize}
      itemCount={productList.length}
      itemData={propsPayload}
    >
      {Row}
    </VariableSizeList>
  );
});


List.propTypes = {
  productList: arrayOf(shape()),
  defaultImage: string.isRequired,
  attributes: arrayOf(shape()),
  certainProductAttributes: arrayOf(shape()),
  productAttributesForVariations: arrayOf(shape()),
  fetchProductAttributesForVariations: func,
  isEditMode: bool,
  handleExpandParentLevel: func,
  categoriesAttributes: func,
  pagination: shape().isRequired,
  disabled: bool.isRequired,
  searchFields: arrayOf(string).isRequired,
  productComments: arrayOf(shape()).isRequired,
  productsCommentsCount: arrayOf(shape()).isRequired,
  disableEdit: bool.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  productCommentsLoading: bool.isRequired,
};

List.defaultProps = {
  productList: [],
  attributes: [],
  certainProductAttributes: [],
  productAttributesForVariations: [],
  fetchProductAttributesForVariations: null,
  isEditMode: true,
  handleExpandParentLevel: null,
  categoriesAttributes: null,
};

export default List;
