/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import {
  func, shape,
} from 'prop-types';
import { decamelize } from 'humps';

import Checkbox from '../../../../components/Checkbox';

const Controls = memo((props) => {
  const {
    fields,
    handleChangeController,
  } = props;

  const getLabel = key => decamelize(key).replace(/_/g, ' ');

  return (
    <div className="preview-drawer-controls">
      {Object.keys(fields).map(fKey => (
        <Checkbox
          key={fKey}
          className="checkbox-md mr-15"
          onChange={handleChangeController}
          checked={fields[fKey]}
          label={<span className="capitalize">{getLabel(fKey)}</span>}
          name={fKey}
        />
      ))}
    </div>
  );
});

Controls.propTypes = {
  fields: shape(),
  handleChangeController: func,
};

Controls.defaultProps = {
  fields: {},
  handleChangeController: null,
};

export default Controls;
