/**
 * Rct Collapsible Card
 */
import React from 'react';
import {
  string, bool, node, oneOfType,
} from 'prop-types';
import { Badge } from 'reactstrap';

const RctCollapsibleCard = props => (
  <div className={`rct-block ${props.className}`}>
    {props.heading && (
      <div className="rct-block-header">{props.heading}</div>
    )}
    {props.badge && (
      <h4>
        <Badge className="p-1 ml-10" color={props.badge.class}>{props.badge.name}</Badge>
      </h4>
    )}
    <div
      className={`rct-block-content ${props.fullBlock ? 'rct-full-block' : ''}`}
    >
      {props.children}
    </div>
  </div>
);

RctCollapsibleCard.propTypes = {
  className: string,
  fullBlock: bool,
  heading: oneOfType([node, string]),
  badge: oneOfType([node, string]),
  children: oneOfType([node, string]),
};

RctCollapsibleCard.defaultProps = {
  className: '',
  fullBlock: false,
  badge: null,
  heading: null,
  children: null,
};

export default RctCollapsibleCard;
