import React, { memo, useState } from 'react';
import {
  func, shape, arrayOf, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import {
  handleActionPopperOk,
} from '../../../../utils/pinActions';
import placementList from '../../../../../../../../util/constants/placementList';
import PopperActionsForm from '../../../../../components/Forms/PopperActionsForm';
import PopperFooterActions from '../../../../../components/Forms/PopperFooterActions';
import IntlMessages from '../../../../../../../../util/IntlMessages';
import Popper from '../../../../../../../../components/Popper';
import {
  handleHideProducts,
  handleUnHideProducts,
} from '../../../../utils/visibilityActions';
import { commentValidations, systemCommentValidations } from './utils/validations';
import validateAll from '../../../../../../../../util/validator';
import entityTypes from '../../../../../../../../util/entityTypes';

import {
  previewSelector,
} from './selectors/previewSelector';

import {
  productSelector,
} from './selectors/productSelector';

import {
  setActionPopoverState,
  setActionPopoverAnchor,
  setProductPinOrderHeplerTextId,
  setSelectedPinAction,
  setProductPinOrder,
  setProductOverride,
  setHiddenProductsFilter,
  setIsHiddenProductsMode,
} from '../../../../../../../../actions/preview';

import {
  setProductForEdit,
  fetchProduct,
  multipleUpdateUserActivityLogs,
  fetchActivityLogsByProductId,
} from '../../../../../../../../actions/product';

import {
  update as updateCategory,
} from '../../../../../../../../actions/virtualCategory';

import {
  updatePromotionFilter,
} from '../../../../../../../../actions/promotion';

import {
  updateSearchFilter,
} from '../../../../../../../../actions/search';

import {
  virtualCategoryWidgetSelector,
} from '../../../../../../selectors/virtualCategoryWidgetSelector';
import {
  promotionWidgetSelector,
} from '../../../../../../selectors/promotionWidgetSelector';

const ProductActionsComponent = memo((props) => {
  const {
    previewProps, virtualCategoryUpdating, searchUpdating, promotionUpdating, productProps,
    virtualCategoryWidgetProps, promotionWidgetProps,
  } = props;

  const [comment, setComment] = useState('');
  const [systemComment, setSystemComment] = useState('');
  const [errors, setErrors] = useState({});

  const getEntityToUse = () => {
    const { selectedSearchFilter } = previewProps;
    let entityToUse = {};

    if (!isEmpty(virtualCategoryWidgetProps.selectedCategory)) {
      entityToUse = virtualCategoryWidgetProps.selectedCategory;
    }
    if (!isEmpty(promotionWidgetProps.selectedPromotion)) {
      entityToUse = promotionWidgetProps.selectedPromotion;
    }
    if (!isEmpty(selectedSearchFilter)) entityToUse = selectedSearchFilter;
    return entityToUse;
  };

  const selectedEntity = getEntityToUse();

  const selectedEntityUpdating = virtualCategoryUpdating
    || searchUpdating
    || promotionUpdating
    || previewProps.updating
    || previewProps.updatingMultiple;

  const { virtualParams } = selectedEntity;
  const { productsOverride } = virtualParams || {};

  const dataSource = {
    product: previewProps.selectedProduct,
    placementList,
    orderHelperText: {
      textId: previewProps.productPinOrderHeplerTextId,
      lengthLimit: 10,
    },
  };

  const cleanState = () => {
    setErrors({});
    setComment('');
    setSystemComment('');
  };

  const closeActionPopper = () => {
    props.setActionPopoverState(false);
    props.setActionPopoverAnchor(null);
    props.setProductPinOrderHeplerTextId('');
    props.setProductPinOrder('');
  };

  const handleAddComment = async () => {
    const updatedActivityLogs = [{
      affectedIds: [dataSource.product.id],
      entityType: entityTypes.catalogProductAttribute,
      comment: `${systemComment}@${comment}`,
    }];
    await props.multipleUpdateUserActivityLogs(updatedActivityLogs);
  };

  const validateData = () => {
    const commentErrors = validateAll({
      ...commentValidations,
      ...systemCommentValidations,
    }, { comment, systemComment });
    if (!isEmpty(commentErrors)) {
      setErrors(commentErrors);
      return false;
    }
    setErrors({});
    return true;
  };

  const unHideProduct = async (unHideData) => {
    const isValid = validateData();
    if (isValid) {
      await handleAddComment();
      cleanState();
      handleUnHideProducts(unHideData);
    }
  };

  const hideProducts = async (hideData) => {
    const isValid = validateData();
    if (isValid) {
      await handleAddComment();
      cleanState();
      handleHideProducts(hideData);
    }
  };

  const handleCloseActionPopper = () => {
    cleanState();
    closeActionPopper();
  };

  const handleUpdateEntityFilters = ({
    filters: newFilters, productsOverride: newProductsOverride,
  }) => {
    const { selectedEntityName } = previewProps;
    const params = { isSync: true, mapToMatrix: true };

    const payload = {
      id: selectedEntity.id,
      virtualParams: {
        ...virtualParams,
        filters: newFilters || virtualParams.filters,
        productsOverride: newProductsOverride || virtualParams.productsOverride,
      },
    };

    props.setActionPopoverState(false);
    props.setActionPopoverAnchor(null);

    if (selectedEntityName === 'category') {
      payload.isEnabled = selectedEntity.isEnabled;
      payload.includeInMenu = selectedEntity.includeInMenu;
      payload.useInProductSearch = selectedEntity.useInProductSearch;

      props.updateCategory(payload, params);
    } else if (selectedEntityName === 'promotion') {
      payload.type = selectedEntity.type;
      payload.urlKey = selectedEntity.urlKey;

      props.updatePromotionFilter(payload.id, payload);
    } else {
      payload.searchPhrase = selectedEntity.searchPhrase;
      payload.type = selectedEntity.type;

      props.updateSearchFilter(payload.id, payload);
    }
  };

  const handleUpdateCategory = (mewProductsOverride) => {
    const payload = {
      id: virtualCategoryWidgetProps.selectedCategory.id,
      isEnabled: virtualCategoryWidgetProps.selectedCategory.isEnabled,
      includeInMenu: virtualCategoryWidgetProps.selectedCategory.includeInMenu,
      useInProductSearch: virtualCategoryWidgetProps.selectedCategory.useInProductSearch,
      virtualParams: {
        ...virtualCategoryWidgetProps.selectedCategory.virtualParams,
        productsOverride: mewProductsOverride,
      },
    };
    const params = { isSync: true, mapToMatrix: true };

    props.updateCategory(payload, params);
  };

  const handleUpdateSearchFilter = (mewProductsOverride) => {
    const { selectedSearchFilter } = previewProps;
    const payload = {
      id: selectedSearchFilter.id,
      type: selectedSearchFilter.type,
      searchPhrase: selectedSearchFilter.searchPhrase,
      virtualParams: {
        ...selectedSearchFilter.virtualParams,
        productsOverride: mewProductsOverride,
      },
    };

    props.updateSearchFilter(selectedSearchFilter.id, payload);
  };

  const handleUpdatePromotion = (mewProductsOverride) => {
    const payload = {
      id: promotionWidgetProps.selectedPromotion.id,
      type: promotionWidgetProps.selectedPromotion.type,
      urlKey: promotionWidgetProps.selectedPromotion.urlKey,
      virtualParams: {
        ...promotionWidgetProps.selectedPromotion.virtualParams,
        productsOverride: mewProductsOverride,
      },
    };

    props.updatePromotionFilter(promotionWidgetProps.selectedPromotion.id, payload);
  };

  const prepareAndUpdateVirtualParams = (newProductsOverride) => {
    if (!isEmpty(promotionWidgetProps.selectedPromotion)) {
      handleUpdatePromotion(newProductsOverride);
    }
    if (!isEmpty(previewProps.selectedSearchFilter)) {
      handleUpdateSearchFilter(newProductsOverride);
    }
    if (!isEmpty(virtualCategoryWidgetProps.selectedCategory)) {
      handleUpdateCategory(newProductsOverride);
    }
  };

  const handleDoneActionFormClick = async () => {
    const isValid = validateData();
    if (isValid && previewProps.selectedPinAction) {
      await handleAddComment();
      handleActionPopperOk({
        selectedPinAction: previewProps.selectedPinAction,
        productPinOrder: previewProps.productPinOrder,
        selectedProduct: previewProps.selectedProduct,
        productsOverride,
        setProductPinOrderHeplerTextId: props.setProductPinOrderHeplerTextId,
        setSelectedPinAction: props.setSelectedPinAction,
        setActionPopoverState: props.setActionPopoverState,
        setActionPopoverAnchor: props.setActionPopoverAnchor,
        setProductOverride: props.setProductOverride,
        prepareAndUpdateVirtualParams,
      });
      cleanState();
    }

    if (isValid && !previewProps.selectedPinAction) {
      handleCloseActionPopper();
    }
  };

  const unpinPosition = (productToUpdate) => {
    const sortedOverrides = productsOverride.sort((a, b) => a.position - b.position);
    const payload = sortedOverrides.filter(pO => pO.productId !== productToUpdate.id);

    prepareAndUpdateVirtualParams(payload);
    props.setActionPopoverState(false);
    props.setActionPopoverAnchor(null);
  };

  const handleUnpin = async (unpinData) => {
    const isValid = validateData();
    if (isValid) {
      await handleAddComment();
      cleanState();
      unpinPosition(unpinData);
    }
  };

  const handleOrderChange = (value) => {
    props.setProductPinOrder(parseFloat(value));
  };

  const handleEditProduct = (forceSelected) => {
    const productToEdit = forceSelected && forceSelected.id
      ? forceSelected
      : previewProps.selectedProduct;

    props.setActionPopoverState(false);
    props.setActionPopoverAnchor(null);

    if (previewProps.displayMode === 'list') {
      props.fetchProduct(productToEdit.id);
    } else {
      props.setProductForEdit({
        edit: productProps.item,
        editInProgress: true,
      });
    }
    props.fetchActivityLogsByProductId(productToEdit.id);
  };

  const handleMoveToChange = ({ target }) => {
    props.setSelectedPinAction(target.value);
  };

  const handleChangeComment = e => setComment(e.target.value);
  const handleSystemCommentChange = e => setSystemComment(e.target.value);

  return (
    <Popper
      visible={previewProps.showPopover}
      anchorEl={previewProps.actionPopoverAnchor}
      className="pos-fixed popper-md"
      title={<IntlMessages id="title.actions" />}
      fetchingPureProduct={productProps.fetchingPureProduct}
      selectedEntityUpdating={selectedEntityUpdating}
      content={(
        <PopperActionsForm
          dataSource={dataSource}
          fetchingPureProduct={productProps.fetchingPureProduct}
          onEditProduct={handleEditProduct}
          onMoveToChange={handleMoveToChange}
          onOrderChange={handleOrderChange}
          onUnpin={handleUnpin}
          handleHideProducts={hideProducts}
          selectedEntityUpdating={selectedEntityUpdating}
          hiddenProductsIds={previewProps.hiddenProductsIds}
          handleUnHideProducts={unHideProduct}
          isHiddenProductsMode={previewProps.isHiddenProductsMode}
          setHiddenProductsFilter={props.setHiddenProductsFilter}
          handleUpdateEntityFilters={handleUpdateEntityFilters}
          setIsHiddenProductsMode={props.setIsHiddenProductsMode}
          selectedEntity={selectedEntity}
          onApply={handleDoneActionFormClick}
        />
      )}
      actions={(
        <PopperFooterActions
          onCommentChange={handleChangeComment}
          onSystemCommentChange={handleSystemCommentChange}
          comment={comment}
          systemComment={systemComment}
          errors={errors}
          commentMessagesOptions={
            props.commentMessages
              .filter(cm => cm.merchandisingPreviewListEditActionBarCommentVisibility)
              .map(cm => ({
                ...cm, value: cm.title,
              }))
          }
        />
      )}
      onClose={handleCloseActionPopper}
    />
  );
});

ProductActionsComponent.propTypes = {
  commentMessages: arrayOf(shape()).isRequired,
  previewProps: shape().isRequired,
  productProps: shape().isRequired,
  virtualCategoryWidgetProps: shape().isRequired,
  promotionWidgetProps: shape().isRequired,
  setActionPopoverState: func.isRequired,
  setActionPopoverAnchor: func.isRequired,
  setProductPinOrderHeplerTextId: func.isRequired,
  fetchProduct: func.isRequired,
  setProductForEdit: func.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  setSelectedPinAction: func.isRequired,
  setProductPinOrder: func.isRequired,
  updateCategory: func.isRequired,
  updatePromotionFilter: func.isRequired,
  updateSearchFilter: func.isRequired,
  setProductOverride: func.isRequired,
  setHiddenProductsFilter: func.isRequired,
  setIsHiddenProductsMode: func.isRequired,
  virtualCategoryUpdating: bool.isRequired,
  searchUpdating: bool.isRequired,
  promotionUpdating: bool.isRequired,
  multipleUpdateUserActivityLogs: func.isRequired,
};

ProductActionsComponent.defaultProps = {};

const mapStateToProps = state => ({
  previewProps: previewSelector(state.preview),
  productProps: productSelector(state.product),
  virtualCategoryUpdating: state.virtualCategory.updating,
  searchUpdating: state.search.updating,
  promotionUpdating: state.promotion.updating,
  commentMessages: state.commentMessage.list,
  virtualCategoryWidgetProps: virtualCategoryWidgetSelector(state.virtualCategoryWidget),
  promotionWidgetProps: promotionWidgetSelector(state.promotionWidget),
});

const mapDispatchToProps = {
  setActionPopoverState,
  setActionPopoverAnchor,
  setProductPinOrderHeplerTextId,
  fetchProduct,
  setProductForEdit,
  fetchActivityLogsByProductId,
  setSelectedPinAction,
  setProductPinOrder,
  updateCategory,
  updatePromotionFilter,
  updateSearchFilter,
  setProductOverride,
  setHiddenProductsFilter,
  setIsHiddenProductsMode,
  multipleUpdateUserActivityLogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductActionsComponent);
