import React from 'react';
import {
  func, shape, arrayOf,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import { checkFilterGroups, getFilterByTitle } from 'pages/utils/checkFilterGroups';

import Search from '../../../../components/Search';

import {
  pimSelector,
} from './selectors/pimSelector';

import {
  setHelperText,
  setPaginationHelperTextId,
  fetchProductList,
  setProductsListEmpty,
  setSelectedProducts,
  setPagination,
  setFullTextListModeSearch,
  setUpdatedProductsImagesOverrides,
} from '../../../../actions/pim';

import {
  mapPrefixToFilters,
  mergeFields,
} from '../../pages/Home/utils/mappers';

import { checkAndConverteDataByFieldOperator } from '../../utils/checkAndConverteDataByFieldOperator';

import { getCheckedSidebarFilter } from '../../../utils/getCheckedSidebarFilter';
import { mapFiltersByOperator } from '../../../../util/mapFiltersByOperator';
import { getDefaultRootFilter, defaultPagination } from '../../utils/defaultData';

const PreviewSearch = (props) => {
  const { pimProps, previewFilterAttributeList } = props;
  const operatorsWithoutValue = new Set(['exists', 'not_exists']);

  const mapListModeSearchFilters = (filters) => {
    const filteredFilters = filters.filter(f => !f.group.some(g => g.value === 'parent'));
    const mappedFilters = mapFiltersByOperator(filteredFilters, previewFilterAttributeList);

    return mapPrefixToFilters([...mappedFilters, ...getDefaultRootFilter(pimProps.filterTarget)]);
  };

  const fetchProducts = ({
    savedFilterFilters,
    globalFilters,
    savedFilterFields,
    globalFields,
    sort,
    searchFilter,
    fullText,
    pagination,
  }) => {
    const filtersPayload = [
      ...savedFilterFilters,
      ...globalFilters,
    ];

    const fieldsPayload = [
      ...savedFilterFields,
      ...globalFields,
    ];

    let targetSort = {};

    if (!isEmpty(sort)) {
      targetSort = {
        sort: [{
          field: sort.field,
          order: sort.order,
        }],
      };
    }

    props.setHelperText('');
    props.setPaginationHelperTextId('');

    const convertedFilters = checkAndConverteDataByFieldOperator(
      [...filtersPayload],
    );

    const mappedFilters = mapListModeSearchFilters(convertedFilters);
    const filter = mappedFilters.filter(f => f.group && f.group
      .every(g => g.field && (!isEmpty(g.value) || operatorsWithoutValue.has(g.operator))));

    const fullTextValue = document.getElementById('search-input-lg').value;
    const allFilters = getFilterByTitle(fullTextValue, filter, fullTextValue);

    return props.fetchProductList({
      viewMode: 'backend',
      filter: allFilters.filter,
      pagination,
      searchFilter,
      fields: pimProps.isCustomFieldsMode
        ? mergeFields(fieldsPayload, [])
        : [],
      fullText: (allFilters.fullText ? allFilters.fullText : {}),
      ...targetSort,
    });
  };

  const handleListViewSearchByQuery = (fullText) => {
    let filterValid = true;
    const productFilter = pimProps.globalFilters[pimProps.filterTarget];
    const filterGroups = getCheckedSidebarFilter(productFilter);
    const filterList = filterGroups || productFilter;
    const hasErrors = checkFilterGroups(filterList);
    filterList.forEach((f) => {
      if (isEmpty(f.group) && isEmpty(f.selectedTreeCategories)) {
        filterValid = false;
      }
    });

    if ((hasErrors && !isEmpty(filterGroups)) || !filterValid) {
      props.setHelperText('pim.header.search.message.checkSidebar');
      return false;
    }

    if (
      isEmpty(pimProps.savedFilterFilters[pimProps.filterTarget])
      && isEmpty(fullText)
      && isEmpty(pimProps.savedFilterFields[pimProps.filterTarget])
    ) {
      props.setProductsListEmpty();
      props.setSelectedProducts([]);
      return false;
    }

    props.setHelperText('');
    props.setPagination(defaultPagination);
    fetchProducts({
      savedFilterFilters: pimProps.savedFilterFilters[pimProps.filterTarget],
      globalFilters: pimProps.globalFilters[pimProps.filterTarget],
      savedFilterFields: pimProps.savedFilterFields[pimProps.filterTarget],
      globalFields: pimProps.globalFields[pimProps.filterTarget],
      sort: pimProps.sort[pimProps.filterTarget],
      searchFilter: pimProps.searchQuery,
      fullText,
      pagination: defaultPagination,
    });

    return true;
  };

  const handleListViewSearchChange = (e) => {
    let fullText = {};
    if (!isEmpty(e.target.value)) {
      fullText = {
        fields: ['title.en', 'description.en'],
        value: e.target.value,
        matchType: 'must',
      };
      props.setFullTextListModeSearch(fullText);
    } else {
      props.setFullTextListModeSearch({});
      handleListViewSearchByQuery({});
    }
  };

  const handleListViewSearch = () => {
    if (!isEmpty(pimProps.productsImagesOverride)) {
      props.setUpdatedProductsImagesOverrides([]);
    }
    if (
      !isEmpty(pimProps.savedFilterFilters[pimProps.filterTarget])
      || !isEmpty(pimProps.globalFilters[pimProps.filterTarget])
      || !isEmpty(pimProps.globalFields[pimProps.filterTarget])
      || !isEmpty(pimProps.savedFilterFields[pimProps.filterTarget])
      || !isEmpty(pimProps.fullTextListModeSearch)
    ) {
      handleListViewSearchByQuery(pimProps.fullTextListModeSearch);
    } else {
      props.setProductsListEmpty();
    }
  };

  const handleListViewSearchKeyPress = (e) => {
    const { charCode } = e;

    if (charCode === 13) handleListViewSearch();
  };

  return (
    <Search
      onChange={handleListViewSearchChange}
      onSearch={handleListViewSearch}
      onSearchKeyPress={handleListViewSearchKeyPress}
      isShowSearchButton
      value={pimProps.fullTextListModeSearch.value || ''}
      key="listViewSearch"
      className="search-preview"
      wrapperClassName="product-header-search flex"
      errorMessage="text.errors.lessThan200Characters"
      queryLengthLimit={170}
      placeholder="Search by title, description"
      helperText={pimProps.helperText}
    />
  );
};

PreviewSearch.propTypes = {
  pimProps: shape().isRequired,
  previewFilterAttributeList: arrayOf(shape()).isRequired,

  setHelperText: func.isRequired,
  setPaginationHelperTextId: func.isRequired,
  fetchProductList: func.isRequired,
  setProductsListEmpty: func.isRequired,
  setSelectedProducts: func.isRequired,
  setPagination: func.isRequired,
  setFullTextListModeSearch: func.isRequired,
  setUpdatedProductsImagesOverrides: func.isRequired,
};

PreviewSearch.defaultProps = {};

const mapStateToProps = state => ({
  pimProps: pimSelector(state.pim),
  previewFilterAttributeList: state.previewFilterAttribute.list,
});

const actionCreators = {
  setHelperText,
  setPaginationHelperTextId,
  fetchProductList,
  setProductsListEmpty,
  setSelectedProducts,
  setPagination,
  setFullTextListModeSearch,
  setUpdatedProductsImagesOverrides,
};

export default connect(mapStateToProps, actionCreators)(PreviewSearch);
