// Core
import React from 'react';
import { bool, string, func } from 'prop-types';
// UI
import {
  Box,
  Dialog, DialogContent, DialogTitle, Typography, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const ChangesDialog = props => (
  <Dialog fullWidth onClose={props.close} aria-labelledby="customized-dialog-title" open={props.open}>

    <Box position="absolute" top={5} right={5}>
      <IconButton onClick={props.close}>
        <CloseIcon />
      </IconButton>
    </Box>
    {/*  Header */}
    <DialogTitle id="customized-dialog-title" onClose={props.close}>
      { props.header }
    </DialogTitle>

    <DialogContent dividers>
      <Typography gutterBottom>
        <pre>
          { props.changes || 'No data' }
        </pre>
      </Typography>
    </DialogContent>

  </Dialog>
);

ChangesDialog.propTypes = {
  open: bool.isRequired,
  close: func.isRequired,
  changes: string.isRequired,
  header: string.isRequired,
};

export default ChangesDialog;
