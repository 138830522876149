import config from './config';

export const { apiPath, functionPath, currencyRatesPath } = config;

// APP paths
export const rootAppPath = '/';
export const rankingsAppPath = '/rankings';
export const signinAppPath = '/signin';
export const forgotPasswordAppPath = '/forgot-password';
export const changePasswordAppPath = '/change-password';
export const signupAppPath = '/signup';
export const dashboardAppPAth = '/dashboard';
export const callbackAppPath = '/callback';
export const notFoundAppPath = '/session/404';
export const serverErrorAppPath = '/session/500';
export const categoriesAppPath = '/categories';
export const getCategoryEditAppPath = id => `${categoriesAppPath}/${id}/edit`;
export const searchAppPath = '/search';
export const feedsAppPath = '/feeds';
export const publicationsAppPath = '/publications';
export const systemAppPath = '/system';
export const previewAppPath = '/preview';
export const pimAppPath = '/pim';
export const mapperAppPath = '/mapper';
export const repricingAppPath = '/repricing';
export const activityLogAppPath = '/activity_log';
export const camsAppPath = '/cams';
export const camsErrorsAppPath = '/errors';
export const camsMerchantStatusAppPath = '/merchant-status';
export const rulesAppPath = '/rules';
export const profileAppPath = '/profile';
export const logoutAppPath = '/logout';
export const productAppPath = '/products';
export const influencersAppPath = '/influencers';
export const businessPortalAppPath = '/';
export const repricerAppPath = '/repricer';
export const repricerIssuesAppPath = '/issues';

// API pathes
export const rankingsPath = `${apiPath}/product_rankings`;
export const rankingsSearchPath = `${rankingsPath}/search`;
export const getRankingPath = id => `${rankingsPath}/${id}`;
export const rankingAttributesPath = 'ranking_attributes';
export const productsSearchAppPath = `${apiPath}/products/search`;
export const getDeleteProductLockedAttributesPath = (productId, isSynchronous = true) => `${apiPath}/products/${productId}/locked_attributes?is_synchronous=${isSynchronous}`;
export const productsAppPath = `${apiPath}/products`;
export const productsFrontAppPath = `${apiPath}/frontend_products`;
export const activityLogsFrontAppPath = `${apiPath}/activity_logs`;
export const productsFrontSearchAppPath = `${apiPath}/frontend_products/search`;
export const productsFrontByUrlKeyAppPath = `${apiPath}/frontend_products/navigation_by_url_key`;
export const productsFrontByPhraseAppPath = `${apiPath}/frontend_products/navigation_by_phrase`;
export const productsBulkAppPath = `${apiPath}/products/bulk`;
export const getProductsAppPath = id => `${apiPath}/products/${id}`;
export const getDeleteProductImagesPath = id => `${getProductsAppPath(id)}/images`;
export const normalizationPath = '/normalizations';
export const settingsPath = '/project_settings';
export const pushingPath = '/pushings';
export const attributesMatrixPath = '/attributes_matrix';
export const defaultAttributesPath = '/project_settings/default_attributes';
export const previewFiltersAppPath = '/preview_filters';
export const facetInputTypesPath = '/facet_input_types';
export const virtualCategoriesPath = `${apiPath}/virtual_categories`;
export const physicalCategoriesPath = `${apiPath}/categories`;
export const categoriesBulkPath = `${virtualCategoriesPath}/bulk`;
export const categoriesSearchPath = `${virtualCategoriesPath}/search`;
export const physicalCategoriesSearchPath = `${physicalCategoriesPath}/search`;
export const getCategoryPath = id => `${virtualCategoriesPath}/${id}`;
export const getPhysicalCategoryPath = id => `${physicalCategoriesPath}/${id}`;
export const categoriesTreePath = `${apiPath}/virtual_categories_tree`;
export const physicalCategoriesTreePath = `${apiPath}/categories_tree`;
export const getCategoriesExpandTreePath = id => `${apiPath}/virtual_categories_tree/${id}`;
export const getPhysicalCategoriesExpandTreePath = id => `${apiPath}/categories_tree/${id}`;
export const getCategoryTreePath = id => `${apiPath}/virtual_categories_tree/${id}`;
export const getPhysicalCategoryTreePath = id => `${apiPath}/categories_tree/${id}`;
export const attributesPath = `${apiPath}/product_attributes`;
export const attributesSearchPath = `${attributesPath}/search`;
export const attributesOptionsPath = `${apiPath}/product_attributes_options`;
export const previewFilterAttributesPath = '/preview_filter_attributes';
export const previewFieldAttributesPath = '/preview_fields_attributes';
export const navigationsSearchPath = `${apiPath}/navigations/search`;
export const navigationsPath = `${apiPath}/navigations`;
export const navigationsBulkPath = `${navigationsPath}/bulk`;
export const getNavigationsPath = navId => `${apiPath}/navigations/${navId}`;
export const bulkNavigationsUpdatePath = `${apiPath}/navigations/bulk`;
export const activityLog = `${apiPath}/activity_log`;
export const activityLogSearch = `${activityLog}/search`;
export const activityLogBuklUpdate = `${activityLog}/bulk`;
export const porductAttributeSetsPath = `${apiPath}/product_attribute_sets`;
export const porductAttributeSetsSearchPath = `${porductAttributeSetsPath}/search`;
export const commentMessagesPath = '/comment_messages';
export const productAttributesOptionsSearchPath = `${apiPath}/product_attribute_options/search`;

export const virtualFacetPath = `${apiPath}/virtual_facets`;
export const virtualFacetSearchPath = `${virtualFacetPath}/search`;
export const getVirtualFacetPath = id => `${virtualFacetPath}/${id}`;

// Function paths
export const uploadXLSXCloudFuncPath = `${functionPath}/category/xlsx`;
export const bulkCreateCategoriesCloudFuncPath = `${functionPath}/category/bulk`;
export const bulkUpdateCategoriesCloudFuncPath = `${functionPath}/category/bulk-update`;
export const importProductsXLSXCloudFuncPath = `${functionPath}/product/import`;
export const generateXLSXCloudFuncPath = `${functionPath}/product/export`;
export const syncUsersCloudFuncPath = `${functionPath}/user/sync`;
export const deleteUserCloudFuncPath = `${functionPath}/user/delete`;

// Tune functions paths
export const createAffiliateCloudFuncPath = `${functionPath}/tune/affiliates`;
export const createAffiliateUserCloudFuncPath = `${functionPath}/tune/affiliate_users`;
export const createOfferCloudFuncPath = `${functionPath}/tune/offers`;
export const createGoalCloudFuncPath = `${functionPath}/tune/goals`;
export const generateTrackingLinkCloudFuncPath = `${functionPath}/tune/tracking_links`;

// currency
export const currencyExchangeApiPath = `${functionPath}/proxy/api?url=${currencyRatesPath}`;

// IMS
export const getImsUnlockAttributesPath = id => `/products/${id}/locked_attributes`;

// CAMS
export const addProductDataActionLogPath = `/cams-logs-stream/_doc`;
export const getProductDataStatusPath = `/cams-status/_search`;
export const getProductDataActionPath = `/cams-product-actions/_search`;
export const getDataActionsPath = `/cams-actions/_search`;

// Feeds Manager
export const getFeedsActionsPath = '/feeds/search';
export const updateFeedsActionsPath = id => `/feeds/${id}`;