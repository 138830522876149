import React from 'react';
import { VariationAttribute } from './VariationAttribute';
import { MerchantInfo } from './MerchantInfo';
import IntlMessages from "../../../util/IntlMessages";

export const VariationInfo = (
  {item, index, productId, merchants, productTitle, productImageUrl, productAttributeProps, editProduct}
) => {

  const attrKeys = Object.keys(item.variationAttributes);
  const variationImageUrl = item.images && item.images[0] && item.images[0].url
    ? item.images[0].url
    : productImageUrl;

  const variantClass = item.id === productId ? ' active' : '';
  let productClass = item.id === productId ? ' variant-active' : '';
  for (let i = 0; i < merchants.length; i++) {
    if (merchants[i].id === productId && variantClass) {
      productClass = ' variant-active';
    }
  }

  return (
    <div className={'product-edit-dialog-top-buttons variant' + productClass}>
      <div className="product-edit-parent-product-left">
        <div>
          <div><IntlMessages id="components.variant" /> {index + 1}</div>
          <img  width="75px" alt={ productTitle } src={ variationImageUrl } />
        </div>
      </div>
      <div className="product-edit-parent-product-middle">
        {
          attrKeys && attrKeys.map((attrKey, attrIndex) => (
            <VariationAttribute
              key={attrIndex + '-' + attrKey}
              item={attrKey}
              attributes={item.variationAttributes}
              productAttributeProps={productAttributeProps}
            />
          ))
        }
        { attrKeys.length === 0 && (
          <IntlMessages id="product.edit.noAttributes" />
        )}

        {item.stock && (
          <div className="product-edit-qty-normal"><IntlMessages id="components.quantity" />: { item.stock.qty }</div>
        )}

      </div>
      <div onClick={() => editProduct(item.id)} className="product-edit-parent-product-right">
        <div className={'product-edit-variant-edit-link' + variantClass}>
          <div className="product-edit-variant-edit-arrow">
            &#8250;
          </div>
        </div>
      </div>

      <div style={{clear: 'both'}}/>

      <div style={{textAlign: 'left', paddingLeft: '15px'}}><IntlMessages id="components.merchants" /></div>
      {merchants && merchants.map((merchantItem, merchantItemKey) => (
        <MerchantInfo
          key={'merchant-' + merchantItemKey}
          editProduct={editProduct}
          item={merchantItem}
          productId={productId}
          index={merchantItemKey}
          variation={item}
          onlyName={false}
        />
      ))}
      {merchants && merchants.length === 0 && (
        <IntlMessages id="components.noMerchants" />
      )}
    </div>
  );
};
