import { systemAppPath } from '../../../util/paths';

export const usersPath = `${systemAppPath}/users`;
export const previewPath = `${systemAppPath}/preview`;
export const importPath = `${systemAppPath}/bulk-import`;
export const systemPath = systemAppPath;

export default {
  usersPath,
  systemPath,
};
