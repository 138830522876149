/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useState, useContext
} from 'react';
import { func, shape} from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../../components/Layout';
import List from '../../components/List';
import SidebarContent from '../Pim/pages/Home/components/SidebarContent';
import FeedsTable from './components/FeedsTable';
import { setFilterTarget } from '../../actions/pim';
import { getFeedsData, updateFeedsData } from '../../actions/feeds';
import { headerList } from '../utils/headerList';
import { AbilityContext } from '../../components/AbilityContext';
import { fetchAttributes } from '../../actions/system';
import { sortFields } from "../../util/fields";

export const Feeds = (props) => {
  const abilityContext = useContext(AbilityContext);
  const [firstLoad, setFirstLoad] = useState(false);
  const [feedsData, setFeedsData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  useEffect(() => {
    props.fetchAttributes();
    props.setFilterTarget('feed');
    if (props.feedsData.data) {
      setFeedsData(props.feedsData.data);
    }
    if (props.feedsData.itemsPerPage) {
      setItemsPerPage(props.feedsData.itemsPerPage);
    }
  }, [
    props.feedsData, props.setFilterTarget
  ]);

  const feedsFields = props.feedsFields;
  const sortedFields = sortFields(feedsFields, 'order');
  const sortedSearchFields = sortFields(feedsFields, 'searchableOrder');

  if (firstLoad === false) {
    const storedSearchFilters = sessionStorage.getItem('pim.searchFilters');
    const storedSearchFields = sessionStorage.getItem('pim.searchFields');
    const parsedStoredSearchFilters = JSON.parse(storedSearchFilters) || { feed: [] };
    const parsedStoredSearchFields = JSON.parse(storedSearchFields) || { feed: [] };
    if (parsedStoredSearchFilters.feed.length === 0 && parsedStoredSearchFields.feed.length === 0) {
      setTimeout(() => {
        props.getFeedsData({ limit: itemsPerPage, offset: 0});
      }, 1000);
    }
    setFirstLoad(true);
  }

  return (
    <Layout
      horizontalMenu
      header={{
        className: 'header-mer',
        subHeaderContent: (
          <List
            dir="horizontal"
            className="justify-center"
            dataSource={headerList({ abilityContext })}
          />
        ),
      }}
      sidebar={{
        className: `sidebar-mer sidebar-ranking-mer sidebar-scroll-secondary`,
        content: <SidebarContent
            sortedFields={sortedFields}
            sortedSearchFields={sortedSearchFields}
            condition={props.feedsData.condition}
            scroll={props.feedsData.scroll}
            getFeedsData={props.getFeedsData}
            reset={props.feedsData.reset}
            itemsPerPage={itemsPerPage}
        />,
      }}
      main={{
        className: 'main-mer main-ranking-mer',
        content: (
          <FeedsTable
            feedsFields={sortedFields}
            feedsData={feedsData}
            feedsScroll={props.feedsData.scroll}
            feedsCondition={props.feedsData.condition}
            getFeedsData={props.getFeedsData}
            updateFeedsData={props.updateFeedsData}
            itemsPerPage={itemsPerPage}
          />
        )
      }}
    />
  );
};

Feeds.propTypes = {
  setFilterTarget: func.isRequired,
  getFeedsData: func.isRequired,
  updateFeedsData: func.isRequired,
  feedsData: shape().isRequired,
  feedsFields: shape(),
};

Feeds.defaultProps = {
  feedsFields: {},
};

const stateToProps = state => ({
  feedsFields: state.system.modes.feeds ? state.system.modes.feeds.fields : {},
  feedsData: state.feeds,
});

const dispatchToProps = {
  setFilterTarget,
  getFeedsData,
  updateFeedsData,
  fetchAttributes,
};

export default withRouter(connect(stateToProps, dispatchToProps)(Feeds));
