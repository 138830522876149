import { createSelector } from 'reselect';

const virtualCategoryPageRootSelector = state => state;

const stateSelector = createSelector(
  virtualCategoryPageRootSelector,
  virtualCategoryPage => ({
    virtualCategoryItem: virtualCategoryPage.item,
    originalVirtualCategoryItem: virtualCategoryPage.originalItem,
    virtualCategoryUpdating: virtualCategoryPage.updating,
    virtualCategoryUpdated: virtualCategoryPage.updated,
    virtualCategoryDeleting: virtualCategoryPage.deleting,
    virtualCategoryDeleted: virtualCategoryPage.deleted,
    virtualCategoryCreating: virtualCategoryPage.creating,
    virtualCategoryCreated: virtualCategoryPage.created,
    virtualCategorySort: virtualCategoryPage.sort,
    virtualCategoryFilter: virtualCategoryPage.filter,
    virtualCategoryFacet: virtualCategoryPage.facet,
    isCategorySaving: virtualCategoryPage.isCategorySaving,
    isDuplicatedCategory: virtualCategoryPage.isDuplicatedCategory,
  }),
);

export const virtualCategoryPageSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  virtualCategoryPageSelector,
};
