import { createSelector } from 'reselect';
import { isEmpty, cloneDeep } from 'lodash';

const navigationFacetListSelector = state => state.list;
const navigationFacetItemSelector = state => state.list;
const navigationOrigFacetItemSelector = state => state.origList;
const navigationFacetRootSelector = state => state;

const mapFacetsList = (list) => {
  const clonnedList = cloneDeep(list);
  const virtualFacets = !isEmpty(clonnedList)
    && !isEmpty(clonnedList[0].facetsList)
    ? Object.keys(clonnedList[0].facetsList).map(key => ({
      groupedValues: clonnedList[0].groupedValues,
      ...clonnedList[0].facetsList[key],
    }))
    : [];
  return virtualFacets;
};

const origListSelector = createSelector(
  navigationOrigFacetItemSelector,
  origList => (!isEmpty(origList)
    ? mapFacetsList(origList)
    : []),
);

const listSelector = createSelector(
  navigationFacetListSelector,
  list => (!isEmpty(list)
    ? mapFacetsList(list)
    : []),
);

const itemSelector = createSelector(
  navigationFacetItemSelector,
  item => (!isEmpty(item) ? ({
    ...[...item].shift(),
  }) : {}),
);

export const navigationFacetSelector = createSelector(
  listSelector,
  origListSelector,
  itemSelector,
  navigationFacetRootSelector,
  (list, origList, item, state) => ({
    ...state, list, origList, item,
  }),
);

export default {
  navigationFacetSelector,
};
