/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import {
  string, shape, func, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { camelize } from 'humps';
import { connect } from 'react-redux';

import { Card, CardContent, IconButton } from '@material-ui/core';

import {
  setGlobalFields,
} from '../../../../actions/pim';

import IntlMessages from '../../../../util/IntlMessages';
import Field from '../../../../components/Field';

const SearchFields = memo((props) => {
  const {
    onChange, setIsFieldRemoved, filterTarget, pimModeAttributes, prevFilterTarget,
    showSearchFields, setShowSearchFields, sortedFields, globalFields, settingsAreApplied,
    setSearchFields, searchFields, error,
  } = props;

  useEffect(() => {
    const payload = {
      ...globalFields,
      [filterTarget]: [],
    };

    if (!settingsAreApplied[filterTarget] && !isEmpty(globalFields[filterTarget] && prevFilterTarget)) {
      if (payload[filterTarget].length > 0) {
        props.setGlobalFields(payload);
        setSearchFields(payload);
        sessionStorage.setItem('pim.searchFields', JSON.stringify(payload));
      }
    }

    const activateSearchFields = !isEmpty(globalFields[filterTarget])
      && settingsAreApplied[filterTarget];

    setShowSearchFields(activateSearchFields);
  }, [filterTarget]);

  useEffect(() => {
    const storedSearchFields = sessionStorage.getItem('pim.searchFields');
    const storedFilterTarget = sessionStorage.getItem('pim.filterTarget');

    if (storedSearchFields) {
      props.setGlobalFields(JSON.parse(storedSearchFields));
      setSearchFields(JSON.parse(storedSearchFields));

      if (!isEmpty(JSON.parse(storedSearchFields)[storedFilterTarget || filterTarget])) {
        setShowSearchFields(true);
      }
    }
  }, []);

  const getAvailableSearchAttributes = () => {
    if ((filterTarget === 'feed' ||
        filterTarget === 'rule' ||
        filterTarget === 'repricing' ||
        filterTarget === 'mapper' ||
        filterTarget === 'activityLog' ||
        filterTarget === 'repricer' ||
        filterTarget === 'cams')
      && !isEmpty(sortedFields)) {
      const sortedFieldsKeys = Object.keys(sortedFields);
      const result = [];
      for (let i = 0; i < sortedFieldsKeys.length; i++) {
        result.push({
          label: sortedFields[sortedFieldsKeys[i]].name,
          value: sortedFields[sortedFieldsKeys[i]].field ? sortedFields[sortedFieldsKeys[i]].field : sortedFieldsKeys[i],
          disabled: false
        });
      }

      return result;
    }

    if (isEmpty(pimModeAttributes[filterTarget])) return [];

    return pimModeAttributes[filterTarget].map((opt) => {
      return {
        label: opt.label,
        value: opt.code,
        disabled: globalFields[filterTarget].some(sF => sF.field && camelize(sF.field) === camelize(opt.value)),
      };
    });
  };

  const handleChange = (e) => {
    const updatedSearchFields = [...e.target.value];
    const payload = {
      ...globalFields,
      [filterTarget]: updatedSearchFields,
    };

    setSearchFields(payload);
    onChange();
  };

  const handleAddFieldItem = () => {
    setShowSearchFields(!showSearchFields);

    const payload = {
      ...globalFields,
      [filterTarget]: [],
    };

    props.setGlobalFields(payload);
    setSearchFields(payload);
    sessionStorage.setItem('pim.searchFields', JSON.stringify(payload));
    setIsFieldRemoved(showSearchFields);
  };

  const className = !isEmpty(globalFields[filterTarget]) ? 'no-border-bottom' : '';
  const errorClassName = error ? 'has-error' : '';

  return (
    <div className="form-group-field">
      <div className={`form-group-title pt-10 pb-10  ${className}`}>
        <span className="title">
          <IntlMessages id="preview.searchFields" />
        </span>
        <IconButton
          aria-label="Add"
          color="primary"
          className={`btn-mui-xs pos-rel ${showSearchFields
            ? 'text-danger'
            : 'text-success'}`}
          onClick={handleAddFieldItem}
        >
          {showSearchFields
            ? <i className="ti-minus" />
            : <i className="ti-plus" />}
        </IconButton>
      </div>
      {showSearchFields && (
        <Card className={`card-xs ${errorClassName}`}>
          <CardContent className="flex items-center">
            <Field
              element="select"
              onChange={handleChange}
              value={searchFields[filterTarget]}
              options={getAvailableSearchAttributes()}
              name="fields"
              label="Fields"
              className="select-xs select-btn flex-1"
              multiple
            />
          </CardContent>
        </Card>
      )}
    </div>
  );
});

SearchFields.propTypes = {
  onChange: func.isRequired,
  setShowSearchFields: func.isRequired,
  showSearchFields: bool.isRequired,
  setIsFieldRemoved: func.isRequired,
  filterTarget: string.isRequired,
  prevFilterTarget: string.isRequired,
  searchFields: shape().isRequired,

  globalFields: shape().isRequired,
  setGlobalFields: func.isRequired,
  setSearchFields: func.isRequired,
  settingsAreApplied: shape().isRequired,
  pimModeAttributes: shape().isRequired,
  error: bool,
};

SearchFields.defaultProps = {
  error: false,
};

export const mapDispatchToProps = {
  setGlobalFields,
};

export const mapStateToProps = state => ({
  globalFields: state.pim.globalFields,
  filterTarget: state.pim.filterTarget,
  prevFilterTarget: state.pim.prevFilterTarget,
  pimModeAttributes: state.system.modes.pim,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFields);
