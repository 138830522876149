/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { NotificationManager } from 'react-notifications';
import IntlMessages from '../../../../../util/IntlMessages';
import { getDefaultRootFilter } from '../../../utils/defaultData';

const usePim = ({
  pimProps, productProps, actions,
}) => {
  useEffect(() => () => {
    actions.clearProductState();
    actions.clearVirtualCategoryTreeState();
    actions.clearVirtualCategoryState();
  }, []);

  useEffect(
    () => actions.setFields(productProps.edit),
    [productProps.editInProgress],
  );

  useEffect(() => {
    if (productProps.isSaveAndClose) {
      actions.finishProductEdit();
    }
  }, [productProps.updated]);

  useEffect(() => {
    if (
      pimProps.bulkDeleteProductLockedAttributesFinished
      || productProps.bulkDeleteProductLockedAttributesFinished
    ) {
      const productIds = pimProps.list.map(p => p.id);
      if (!isEmpty(productIds)) {
        actions.fetchCountProductsActivitiesByProductIds(productIds);
      }
    }
  }, [
    pimProps.bulkDeleteProductLockedAttributesFinished,
    productProps.bulkDeleteProductLockedAttributesFinished,
  ]);

  useEffect(() => {
    if (pimProps.updatedMultiple) {
      const productIds = pimProps.list.map(p => p.id);
      const productItem = {};

      actions.setSelectedProducts([]);
      actions.injectProducts(
        pimProps.updatedMultipleList,
        productItem,
        pimProps.filterTarget,
      );

      if (!isEmpty(productIds)) {
        actions.fetchCountProductsActivitiesByProductIds(productIds);
      }

      NotificationManager.success(<IntlMessages id="pim.save.alert.productsUpdated" />);
    }
  }, [pimProps.updatedMultiple]);

  useEffect(() => {
    if (productProps.bulkUpdateActivityLogsFinished) {
      const productIds = pimProps.list.map(p => p.id);

      if (!isEmpty(productIds)) {
        actions.fetchCountProductsActivitiesByProductIds(productIds);
      }
    }
  }, [productProps.bulkUpdateActivityLogsFinished]);


  useEffect(() => {
    if (pimProps.updatedMultiple && productProps.fetchProductsCommentsCountFinished) {
      actions.setUpdatedProductsImagesOverrides([]);
      actions.clearFromProductState({
        updatedMultiple: false,
      });
    }

    if (productProps.removeImagesFinished) {
      const { productsWithoutImages, list } = pimProps;
      const targetItems = list
        .filter(item => productsWithoutImages.some(p => p.id === item.id))
        .map(item => ({
          ...item,
          images: [],
        }));

      actions.setUpdatedProductsImagesOverrides([]);
      actions.clearFromProductState({
        updatedMultiple: false,
        removeImagesFinished: false,
      });
      actions.injectProducts(targetItems, {}, pimProps.filterTarget);
      NotificationManager.success(<IntlMessages id="pim.save.alert.productsUpdated" />);
    }
  }, [
    pimProps.updatedMultiple,
    productProps.fetchProductsCommentsCountFinished,
    pimProps.removeImagesFinished,
  ]);

  useEffect(() => {
    if (productProps.updated) {
      if (!isEmpty(productProps.updatedProduct)) {
        actions.injectProducts(
          [productProps.updatedProduct],
          productProps.updatedProduct,
          pimProps.filterTarget,
        );
        actions.fetchCountProductsActivitiesByProductIds([productProps.updatedProduct.id]);
      }
    }
  }, [productProps.updated]);

  useEffect(() => {
    if (productProps.fetchedOne) {
      actions.setProductForEdit({
        edit: productProps.item,
        editInProgress: true,
      });
    }
  }, [productProps.fetchedOne]);

  useEffect(() => {
    const { fetchedPureProduct, item } = productProps;
    if (fetchedPureProduct) actions.setSelectedProduct(item);
  }, [productProps.fetchedPureProduct]);

  useEffect(() => {
    if (!isEmpty(pimProps.rootFilter)) {
      actions.setRootFilter(getDefaultRootFilter(pimProps.filterTarget));
    }

    actions.setProductsListEmpty();
  }, [pimProps.filterTarget]);

  useEffect(() => {
    if (productProps.bulkDeleteProductLockedAttributesFinished) {
      actions.fetchActivityLogsByProductId(productProps.edit.id);
    }
  }, [productProps.bulkDeleteProductLockedAttributesFinished]);
};

export default usePim;
