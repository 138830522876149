import { createSelector } from 'reselect';

const productRootSelector = state => state;

const stateSelector = createSelector(
  productRootSelector,
  state => ({
    productToEdit: state.edit,
    productUpdated: state.updated,
    fields: state.fields,
    productComments: state.productComments,
  }),
);

export const productSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  productSelector,
};
