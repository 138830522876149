import { isEmpty } from 'lodash';

export default (camelizedData) => {
  const attributeSets = {};
  const sameAttributes = [];
  let differentAttributes = [];
  const mappedArray = [];

  Object.keys(camelizedData).forEach((o) => {
    camelizedData[o].forEach((i) => {
      if (typeof i === 'object') {
        mappedArray.push(i);
        if (!isEmpty(attributeSets[o])) {
          attributeSets[o].push(i);
        } else {
          attributeSets[o] = [i];
        }
      }
    });
  });
  const uniqueKeysFromArray = mappedArray.map(a => a.code);
  const unique = [...new Set(uniqueKeysFromArray)];

  const getAllAttributeOptions = (attribute, attrSets) => {
    let options = [];

    Object.keys(attrSets).forEach((as) => {
      const setOptions = attrSets[as].find(item => item.code === attribute.code).options;
      if (!isEmpty(setOptions)) {
        options = [...options, ...setOptions];
      }
    });
    return options;
  };

  unique.forEach((u) => {
    let attributeToAdd = {};
    let isSame = true;

    Object.keys(attributeSets).forEach((as) => {
      const attribute = attributeSets[as].find(a => a.code === u);
      if (isEmpty(attributeToAdd)) {
        attributeToAdd = { ...attribute };
      }

      if (attribute && attributeToAdd.isFilterable === attribute.isFilterable
        && attributeToAdd.isSortable === attribute.isSortable) {
        attributeToAdd = { ...attribute };
      } else {
        isSame = false;
      }
    });

    if (isSame) {
      let options = [];
      if (attributeToAdd.frontendInputType === 'dropdown' && attributeToAdd.isFilterable) {
        options = getAllAttributeOptions(attributeToAdd, attributeSets);
        attributeToAdd.options = [...options];
      }
      sameAttributes.push(attributeToAdd);
    } else {
      differentAttributes.push(attributeToAdd);
    }
    differentAttributes = [...differentAttributes.map(da => ({
      ...da,
      disabled: false, // TODO: it was true to be able to disable these attributes
    }))];
  });

  const mappedSameAttributes = sameAttributes.map(s => (s.code ? ({ ...s, value: s.code }) : s));
  const mappedDifferentAttributes = differentAttributes.map(
    d => (d.code ? ({ ...d, value: d.code }) : d),
  );
  return [...mappedSameAttributes, ...mappedDifferentAttributes];
};
