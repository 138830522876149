import { createSelector } from 'reselect';

const pimRootSelector = state => state;

const stateSelector = createSelector(
  pimRootSelector,
  state => ({
    savedFilterFilters: state.savedFilterFilters,
    globalFilters: state.globalFilters,
    savedFilterFields: state.savedFilterFields,
    globalFields: state.globalFields,
    sort: state.sort,
    searchQuery: state.searchQuery,
    filterTarget: state.filterTarget,
    isCustomFieldsMode: state.isCustomFieldsMode,
    globalSearchValue: state.globalSearchValue,
    helperText: state.helperText,
    productsImagesOverride: state.productsImagesOverride,
    fullTextListModeSearch: state.fullTextListModeSearch,
  }),
);

export const pimSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  pimSelector,
};
