import axios from 'axios';
import humps from 'humps';
import RestActions from '../util/rest/actions';
import { mapObjectToArray } from '../util/objectsToArray';
import { showErrorNotification } from '../util/api';

import {
  categoriesSearchPath,
} from '../util/paths';

import {} from './types';

const restRankingCategoriesActions = new RestActions('ranking_category');

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';

export const fetchRankingCategories = rankingName => (dispatch) => {
  dispatch(restRankingCategoriesActions.fetchAllStart());
  const searchObj = {
    filter: [{
      group: [{
        field: 'virtual_params.ranking_rule_code',
        value: rankingName,
        operator: 'eq',
      }],
    }],
    pagination: {
      page: 1,
      limit: 500,
    },
  };
  axios.post(categoriesSearchPath + '?ref=' + ref, searchObj).then((res) => {
    dispatch(restRankingCategoriesActions.fetchAllFinished({
      list: humps.camelizeKeys(mapObjectToArray(res.data.data)),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restRankingCategoriesActions.fetchAllFinished({
      error: error.response.data, hasErrors: true,
    }));
  });
};

export default {
  fetchRankingCategories,
};
