export const paginationLimit = 25;

export const getSearchSearchFiltersQuery = value => ({
  filter: [{
    condition: 'and',
    group: [{
      field: 'type',
      value: 'search_phrase',
      operator: 'eq',
    }],
  }],
  full_text: {
    fields: ['name'],
    value,
  },
  pagination: {
    page: 1,
    limit: paginationLimit,
  },
});

export const getSearchPromotionsQuery = value => ({
  filter: [{
    condition: 'and',
    group: [{
      field: 'type',
      value: 'promotion_url',
      operator: 'eq',
    }],
  }],
  full_text: {
    fields: ['name'],
    value,
  },
  pagination: {
    page: 1,
    limit: paginationLimit,
  },
});

export default {
  getSearchSearchFiltersQuery,
  getSearchPromotionsQuery,
};
