import React, { memo } from 'react';
import {
  func, bool, shape, arrayOf, string,
} from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import swal from '@sweetalert/with-react';

import {
  fetchNavigationVirtualFacetList,
  setNavigatioVirtualFacetIdToDelete,
  setNavigationVirtualFacetItem,
  setNavigationVirtualFacetList,
  clearFromNavigationVirtualFacetState,
} from 'actions/navigationVirtualFacet';

import FacetComponent from 'components/Facet';

import {
  getFilterableAttributes,
} from 'util/attributesMappers';

import { setFacets } from 'actions/navigation';

import { getFacetPayload } from 'util/virtualFacetManagment';
import { navigationFacetSelector } from './selectors/navigationFacet';

import useFacetEffects from './hooks/useFacet';
import useNavigationVirtualFacet from './hooks/useNavigationVirtualFacet';


const Facet = memo((props) => {
  useFacetEffects({
    navigationFetched: props.navigationFetched,
    navigationItem: props.navigationItem,
    setFacets: props.setFacets,
  });

  useNavigationVirtualFacet({
    navigationItemId: props.navigationItem.id,
    navigationFetched: props.navigationFetched,
    navigationUpdated: props.navigationUpdated,
    navigationCreated: props.navigationCreated,

    navigationVirtualFacetUpdated: props.navigationFacet.updated,
    navigationVirtualFacetDeleted: props.navigationFacet.deleted,
    navigationVirtualFacetCreated: props.navigationFacet.created,
    navigationVirtualFacetFetching: props.navigationFacet.fetching,

    fetchNavigationVirtualFacetList: props.fetchNavigationVirtualFacetList,
    clearFromNavigationVirtualFacetState: props.clearFromNavigationVirtualFacetState,

    viewMode: props.viewMode,
  });

  const {
    navigationItem,
    rankingFetching,
    categoriesAttributesFetching,
    categoriesAttributes,
    defaultAttributes,
  } = props;

  const loading = rankingFetching || categoriesAttributesFetching;

  const getFacetAttributes = () => getFilterableAttributes(categoriesAttributes);

  const getCategoryFacets = () => {
    const facetAttributes = getFacetAttributes();
    let navigationFacets = [];
    if (
      !isEmpty(props.navigationFacet.list)
      && !isEmpty(categoriesAttributes)
      && !isEmpty(facetAttributes)
    ) {
      navigationFacets = props.navigationFacet.list.map((cF) => {
        if (facetAttributes.some(pASL => pASL.code === cF)) {
          return facetAttributes.find(pASL => pASL.code === cF);
        }
        return false;
      }).filter(a => a);
    }

    const defaultAttributesList = defaultAttributes || [];
    const filteredFacets = navigationFacets.filter(
      cf => !defaultAttributesList.some(da => da.field === cf.code),
    );
    return [...defaultAttributesList, ...filteredFacets, ...props.navigationFacet.list];
  };

  const handleFacetChanges = (facets) => {
    const mappedFacets = facets.filter(f => !f.code && !f.default);
    // eslint-disable-next-line no-param-reassign
    mappedFacets.forEach(i => delete i.groupedValues);
    const newLsit = [{
      ...props.navigationFacet.item,
      facetsList: mappedFacets,
    }];
    props.setNavigationVirtualFacetList(newLsit);
  };

  const preHandleFacetDelete = () => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to permanently delete virtual facets?',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    }).then((willDiscard) => {
      if (willDiscard) {
        props.setNavigatioVirtualFacetIdToDelete(props.navigationFacet.item.id);
        props.setNavigationVirtualFacetList([]);
      }
    });
  };


  const handleFacetItemDelete = (newList) => {
    handleFacetChanges(newList);
    if (isEmpty(newList.filter(l => !l.default))) {
      props.setNavigatioVirtualFacetIdToDelete(props.navigationFacet.item.id);
    } else {
      props.setNavigatioVirtualFacetIdToDelete('');
    }
  };

  const handleFacetManagementDialogOk = (facet) => {
    const facetPayload = getFacetPayload({
      prevFacet: facet,
      useRev: true,
      nextFacet: {
        item: props.navigationFacet.item,
        list: props.navigationFacet.list,
      },
    });
    props.setNavigationVirtualFacetList(facetPayload);
  };

  return (
    <div className="relative preview-drawer-facet">
      <FacetComponent
        key={navigationItem.id}
        entity={{
          type: 'navigation',
          id: navigationItem.id,
        }}
        loading={loading}
        inputLabel="Select"
        list={getFacetAttributes()}
        defaultAttributes={defaultAttributes}
        disabled={false}
        table={{
          tBody: getCategoryFacets(),
        }}
        onAdd={handleFacetChanges}
        onDeleteButton={{
          disabled: isEmpty(props.navigationFacet.list)
            && isEmpty(props.navigationFacet.virtualFacetIdToDelete),
          onClick: preHandleFacetDelete,
        }}
        onItemDelete={handleFacetItemDelete}
        onDragEnd={handleFacetChanges}
        newFacet={{
          onOk: handleFacetManagementDialogOk,
        }}
      />
    </div>
  );
});

Facet.propTypes = {
  viewMode: string,

  navigationItem: shape().isRequired,
  categoriesAttributes: arrayOf(shape()).isRequired,
  defaultAttributes: arrayOf(shape()).isRequired,

  rankingFetching: bool.isRequired,
  categoriesAttributesFetching: bool.isRequired,
  navigationFetched: bool.isRequired,
  navigationUpdated: bool.isRequired,
  navigationCreated: bool.isRequired,

  setFacets: func.isRequired,

  fetchNavigationVirtualFacetList: func.isRequired,
  setNavigatioVirtualFacetIdToDelete: func.isRequired,
  setNavigationVirtualFacetList: func.isRequired,
  clearFromNavigationVirtualFacetState: func.isRequired,

  navigationFacet: shape().isRequired,
};

Facet.defaultProps = {
  viewMode: '',
};

const mapStateToProps = state => ({
  navigationItem: state.navigation.item,
  filterCategory: state.navigation.filter.category,
  navigationFetched: state.navigation.fetchedOne,
  navigationUpdated: state.navigation.updated,
  navigationCreated: state.navigation.created,

  rankingFetching: state.ranking.fetching,

  categoriesAttributesFetching: state.productAttribute.categoriesAttributesFetching,
  categoriesAttributes: state.productAttribute.categoriesAttributes,

  defaultAttributes: state.defaultAttribute.attributes.categories,

  navigationFacet: navigationFacetSelector(state.navigationVirtualFacet),
});

const mapDispatchToProps = {
  setFacets,

  fetchNavigationVirtualFacetList,
  setNavigatioVirtualFacetIdToDelete,
  setNavigationVirtualFacetItem,
  setNavigationVirtualFacetList,
  clearFromNavigationVirtualFacetState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Facet);
