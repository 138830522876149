import { useState } from 'react';

export const useStateHook = (props) => {
  const [open, setOpen] = useState(false);
  const [stateOptions, setStateOptions] = useState(props.options);

  const state = {
    open,
    stateOptions,
  };

  const setState = {
    setOpen,
    setStateOptions,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
