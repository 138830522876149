import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import Main from './containers';
import ContainerNew from './containers/New';
import ContainerEdit from './containers/Edit';
import {
  rootPath,
  editPath,
  newPath,
} from './utils/paths';
import { AbilityContext } from '../../components/AbilityContext';
import appPermissions from '../../util/appPermissions';

const Influencer = () => {
  const abilityContext = useContext(AbilityContext);

  return (
    <Switch>
      {abilityContext.can(
        appPermissions.influencer.permissions.view,
        appPermissions.influencer.name,
      ) && <Route exact path={rootPath} component={Main} />}
      {abilityContext.can(
        appPermissions.influencer.permissions.update,
        appPermissions.influencer.name,
      ) && <Route exact path={editPath} component={ContainerEdit} />}
      {abilityContext.can(
        appPermissions.influencer.permissions.create,
        appPermissions.influencer.name,
      ) && <Route exact path={newPath} component={ContainerNew} />}
    </Switch>
  );
};

export default Influencer;
