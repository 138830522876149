import {
  SET_CONTEXT_MENU_TARGET_TREE_NODE,
  SET_CONTEXT_MENU_SHOW,
  SET_CONTEXT_MENU_STYLES,
} from './types';

export const setTargetTreeNode = targetTreeNode => (dispatch) => {
  dispatch({
    type: SET_CONTEXT_MENU_TARGET_TREE_NODE,
    payload: { targetTreeNode },
  });
};

export const setShowContextMenu = showContextMenu => (dispatch) => {
  dispatch({
    type: SET_CONTEXT_MENU_SHOW,
    payload: { showContextMenu },
  });
};

export const setContextMenuStyles = contextMenuStyles => (dispatch) => {
  dispatch({
    type: SET_CONTEXT_MENU_STYLES,
    payload: { contextMenuStyles },
  });
};
