import React from 'react';
import {
  shape,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { Button } from '@material-ui/core';

import IntlMessages from '../../../../../util/IntlMessages';

const ActionControls = (props) => {
  const {
    selectedAction, selectedProducts,
  } = props.controlsProps;

  const {
    handleSubmitActionBar, handleCancelActionBar,
  } = props.actions;

  return (
    <div className="pl-10 pr-10 action-buttons">
      <Button
        variant="outlined"
        onClick={handleSubmitActionBar}
        color="primary"
        disabled={!selectedAction || isEmpty(selectedProducts)}
      >
        <IntlMessages id="preview.list.actionBar.save" />
      </Button>
      <Button
        variant="outlined"
        onClick={handleCancelActionBar}
        className="pl-10 pr-10"
        color="default"
        disabled={!selectedAction || isEmpty(selectedProducts)}
      >
        <IntlMessages id="preview.list.actionBar.cancelButton" />
      </Button>
    </div>
  );
};

ActionControls.propTypes = {
  controlsProps: shape().isRequired,
  actions: shape().isRequired,
};

ActionControls.defaultProps = {};

export default ActionControls;
