import React, { memo, useReducer, useState, useEffect } from 'react';
import { camelize } from 'humps';
import {
  shape, number, func,
} from 'prop-types';

import { isEmpty } from 'lodash';

import {
  Tooltip, Card, CardMedia, Typography, CardContent,
  Grid, IconButton,
} from '@material-ui/core';

import IntlMessages from '../../../../util/IntlMessages';
import Can from '../../../../components/Can';
import appPermissions from '../../../../util/appPermissions';
import FullCardPopover from '../FullCardPopover';
import Checkbox from "../../../../components/Checkbox";
import { updateProduct } from '../../../../actions/product';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const ParentLevelGridItem = memo((props) => {
  const {
    gridItemProps, index: productIndex,
  } = props;

  const [isPromotable, setIsPromotable] = useState([]);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    const promotable = [];
    if (props.promotable) {
      for (let i = 0; i < props.promotable.length; i++){
        promotable[props.promotable[i]] = true;
      }
    }
    setIsPromotable(promotable);
  }, [
    props.promotable
  ]);

  const { virtualParams } = gridItemProps.selectedEntity;
  const { productsOverride } = virtualParams || {};
  const isPinned = productsOverride && productsOverride
    .some(pO => pO.productId === gridItemProps.item.id);
  const editClassName = gridItemProps.isEditMode ? 'edit' : '';
  const hasComments = gridItemProps.productsCommentsCount
    && gridItemProps.productsCommentsCount
      .some(pcc => pcc.id === gridItemProps.item.id);

  const renderFullContent = () => (
    <Card className={`product-item-card-full block ${editClassName}`}>
      <CardContent className="product-item-card-content">
        <Typography variant="body2" color="textSecondary" component="div" className="flex ">
          {
            gridItemProps.sortedAttributes.map((attr) => {
              const { enabled } = attr;
              const itemValue = gridItemProps.value[camelize(attr.value)];
              const currentAttribute = gridItemProps.certainProductAttributes
                .find(el => el.code === attr.value) || {};
              const sizeClass = !itemValue || String(itemValue).length < 90
                ? 'flex-four'
                : 'flex-one';

              return (
                enabled !== false
                && (
                  <div className={`flex pr-10 pl-10 product-item-data ${sizeClass}`} key={attr.key}>
                    <span className="pr-5 key">{(currentAttribute.label ? currentAttribute.label.en : '') || attr.label}</span>
                    <span className="pl-5 value">{itemValue && String(itemValue).trim() ? itemValue : '-'}</span>
                  </div>
                ));
            })
          }
        </Typography>
      </CardContent>
    </Card>
  );

  const handleChangePromotableValue = (e, id) => {
    isPromotable[id] = e.target.checked;
    setIsPromotable(isPromotable);
    const payload = { is_promotable: e.target.checked };
    props.updateProduct({ payload: payload, id: id });
    forceUpdate();
  }

  const imgUrl = gridItemProps.value.imgData && gridItemProps.value.imgData.url
    ? gridItemProps.value.imgData.url
    : gridItemProps.defaultImage;

  return (
    <>
      {productIndex === 0 && (
        <div onClick={props.handleUnSelectAllProducts} className="preview-product-remove-stars">
          <IntlMessages id="label.removeProductStars" />
        </div>
      )}

      <Grid key={gridItemProps.value.id} item xs={gridItemProps.grid} className="pos-rel product-grid-item">
        {!gridItemProps.isEditMode
          && !isEmpty(gridItemProps.sortedAttributes.filter(attr => attr.enabled))
          && (
            <FullCardPopover
              product={gridItemProps.item}
              content={renderFullContent()}
            />
          )}
        <div className="product-item relative">
          <Card className={`product-item-card ${editClassName}`}>
            <div className="icon-actions">
              {productIndex && (
                <span className="icon-xs icon-premium position-info">
                  {(
                    ((gridItemProps.pagination.page - 1) * gridItemProps.pagination.limit) + productIndex + 1
                  )}
                </span>
              )}

              <span style={{position: 'relative', top: '-5px'}}>
                <Checkbox
                  ignored={ignored}
                  checked={isPromotable[gridItemProps.value.id] === true}
                  onChange={(e) => handleChangePromotableValue(e, gridItemProps.value.id)}
                />
              </span>

              {props.selectedProducts.indexOf(gridItemProps.value.id) !== -1
                ? ( <span
                  onClick={e => props.handleUnSelectProduct(e, gridItemProps.value.id)}
                  className="preview-product-star">★</span> )
                : ( <span
                  onClick={e => props.handleSelectProduct(e, gridItemProps.value.id)}
                  className="preview-product-star">☆</span> )
              }

              {isPinned && <i className="icon-pin icon-premium icon-xs" />}
              {
                hasComments && (
                  <Tooltip
                    onClick={() => gridItemProps.fetchActivityLogsByProductId(gridItemProps.item.id)}
                    title={(
                      <div className="comments">
                        {
                          gridItemProps.productCommentsLoading
                            ? <IntlMessages id="preview.list.productComments.loading" />
                            : gridItemProps.productComments.map((pc, index) => (
                              <div key={pc.id}>
                                {`${index + 1}. ${pc.comment}`}
                              </div>
                            ))
                        }
                      </div>
                    )}
                    placement="right"
                  >
                    <i className="ti-comment-alt icon-premium icon-xs" />
                  </Tooltip>
                )
              }
            </div>
            <CardMedia
              className="product-item-image"
              image={imgUrl}
              title=""
            />
            <Can
              do={appPermissions.preview.permissions.update}
              on={appPermissions.preview.name}
            >
              <div className="product-item-card-actions">
                <IconButton
                  size="small"
                  color="primary"
                  aria-label="Add"
                  disabled={gridItemProps.edidActionsAreDisabled}
                  onClick={event => gridItemProps.onSettingsCLick(
                    { event, item: gridItemProps.item },
                  )}
                >
                  <i className="ti-settings icon-settings" />
                </IconButton>
              </div>
            </Can>
            <CardContent className="product-item-card-content">
              <Typography variant="body2" color="textSecondary" component="div">
                {
                  gridItemProps.sortedAttributes.map((attr) => {
                    const { enabled } = attr;
                    const itemValue = gridItemProps.value[camelize(attr.value)];
                    const currentAttribute = gridItemProps.certainProductAttributes
                      .find(el => el.code === attr.value) || {};

                    return (
                      enabled !== false
                      && (
                        <div className="flex pr-10 pl-10 product-item-data" key={attr.key}>
                          <span className="pr-5 key">{(currentAttribute.label ? currentAttribute.label.en : '')
                          || attr.label}</span>
                          <span className="pl-5 value">{itemValue || '-'}</span>
                        </div>
                      ));
                  })
                }
              </Typography>
            </CardContent>
          </Card>
        </div>
      </Grid>
    </>
  );
});

ParentLevelGridItem.propTypes = {
  gridItemProps: shape().isRequired,
  index: number.isRequired,
  updateProduct: func,
};

ParentLevelGridItem.defaultProps = {};

const dispatchToProps = {
  updateProduct,
};

const stateToProps = state => ({ });

export default withRouter(connect(stateToProps, dispatchToProps)(ParentLevelGridItem));
