import RestActions from '../util/rest/actions';

const restAPIErrorActions = new RestActions('error');

export const setAPIError = errors => (dispatch) => {
  dispatch(restAPIErrorActions.setErrorFinished(errors));
};

export const clearAPIError = () => (dispatch) => {
  dispatch(restAPIErrorActions.clearErrorFinished({ errors: {} }));
};

export default {
  setAPIError,
  clearAPIError,
};
