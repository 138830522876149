/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  node, bool, string, func, number,
} from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { collapsedSidebarAction } from '../../actions';

const Sidebar = (props) => {
  useEffect(() => {
    if (props.windowWidth <= 1200) {
      props.collapsedSidebarAction(false);
    }
  }, [props.windowWidth]);

  const {
    isDarkSidenav, sidebarContent, className,
  } = props;

  return (
    <div className={`rct-sidebar ${className}`}>
      <div className={`rct-sidebar-content ${isDarkSidenav ? 'sidebar-overlay-dark' : 'sidebar-overlay-light sidebar-overlay-gray'}`}>
        <div className="rct-sidebar-wrap">
          {sidebarContent}
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  sidebarContent: node,
  isDarkSidenav: bool,
  className: string,
  windowWidth: number,
  collapsedSidebarAction: func.isRequired,
};

Sidebar.defaultProps = {
  sidebarContent: null,
  isDarkSidenav: false,
  className: '',
  windowWidth: 0,
};

const mapStateToProps = state => ({
  enableSidebarBackgroundImage: state.settings.enableSidebarBackgroundImage,
  selectedSidebarImage: state.settings.selectedSidebarImage,
  collapsedSidebar: state.settings.collapsedSidebar,
  isDarkSidenav: state.settings.isDarkSidenav,
});

const mapDispatchToProps = {
  collapsedSidebarAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
