import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const navigationListSelector = state => state.list;
const navigationSuggestionsSelector = state => state.suggestions;
const navigationItemSelector = state => state.item;
const navigationRootSelector = state => state;

const mapProductsOverride = (virtualParams) => {
  if (!virtualParams) return {};
  return ({
    ...virtualParams,
    productsOverride: virtualParams.positions
      ? Object.keys(virtualParams.positions)
        .map(p => ({ productId: p, position: virtualParams.positions[p] }))
      : [],
  });
};

const listSelector = createSelector(
  navigationListSelector,
  list => (!isEmpty(list) ? list.reduce((acc, current) => {
    acc.push({
      ...current,
      virtualParams: mapProductsOverride(current.virtualParams),
    });
    return acc;
  }, []) : []),
);

const suggestionsSelector = createSelector(
  navigationSuggestionsSelector,
  suggestions => (!isEmpty(suggestions) ? suggestions.reduce((acc, current) => {
    acc.push({
      ...current,
      virtualParams: mapProductsOverride(current.virtualParams),
    });
    return acc;
  }, []) : []),
);

const itemSelector = createSelector(
  navigationItemSelector,
  item => (!isEmpty(item) ? ({
    ...item,
    virtualParams: mapProductsOverride(item.virtualParams),
  }) : {}),
);

export const navigationSelector = createSelector(
  listSelector,
  itemSelector,
  suggestionsSelector,
  navigationRootSelector,
  (list, item, suggestions, state) => ({
    ...state,
    list,
    item,
    suggestions,
    updated: state.updated,
  }),
);

export default {
  navigationSelector,
};
