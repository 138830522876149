/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  string, shape, arrayOf, bool, func, node,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { ClickAwayListener } from '@material-ui/core';

import CategoriesCheckboxTree from 'components/CategoriesCheckboxTree';
import ListCheckbox from 'components/ListCheckbox';
import ContextMenu from 'components/CategoriesCheckboxTree/components/ContextMenu';

import useCategory from './hooks/useCategory';
import useStateStore from './hooks/useState';
import { mapStateToProps, mapDispatchToProps } from './connect';

const CategoryWidget = (props) => {
  const state = useStateStore();
  useCategory(state, props);

  const handleOnSelect = (id) => {
    props.setVirtualFacetFilter({});
    if (props.onSelectTreeItem) {
      props.onSelectTreeItem(id);
    }

    const selectedCategory = !isEmpty(props.virtualCategoryWidget.treeItems)
      ? props.virtualCategoryWidget.treeItems.find(item => item.id === id)
        || props.checkboxList.list.find(item => item.id === id)
      : {};

    props.fetchSelectedCategory(id);
    props.setSelectedCategory(selectedCategory);
  };

  const handleToggleCollapse = payload => state.setCollapsed({
    data: payload, isOpen: !state.collapsed.isOpen,
  });

  const handleSelectListItem = () => {
    props.onSelectVirtualCategory(props.virtualCategoryWidget.selectedCategoryId);
  };

  const handleItemRightClick = ({ event, node: targetNode, extraSpace = 0 }) => {
    const targetParams = event.target.getBoundingClientRect();
    const contextStyles = {
      position: 'fixed',
      top: `${targetParams.top - targetParams.height - extraSpace}px`,
      left: `${targetParams.left + 10}px`,
    };
    props.setContextMenuStyles(contextStyles);
    props.setShowContextMenu(true);
    props.setTargetTreeNode(targetNode);
    event.preventDefault();
  };

  const handleClickAway = () => {
    props.setShowContextMenu(false);
    props.setContextMenuStyles({ left: 0, top: 0, position: 'fixed' });
    props.setTargetTreeNode(null);
  };

  return (
    <>
      {!props.checkboxList.use && (
        <CategoriesCheckboxTree
          isCategoriesTreeDisabled={props.disabled}
          className="tree-wrapper-secondary sidebar-tree-checkbox-hide"
          activeCategory={props.virtualCategoryWidget.selectedCategoryId}
          selected={[]}
          collapsed={props.virtualCategoryWidget.collapse}
          onSelect={handleOnSelect}
          onExpand={handleToggleCollapse}
          loadChildren={props.fetchVirtualChildCategories}
          showSecondaryTitle={false}
          loading={props.loading}
          loadingId={props.loadingId}

          categories={props.virtualCategoryWidget.treeItems}
          onSelectListItem={handleSelectListItem}
          onToggleCategoryTreeCollapse={props.onToggleVirtualCategoryTreeCollapse}
          handleLoadSubcategories={props.handleLoadVirtualSubcategories}
          loadedKeys={props.virtualCategoryWidget.loadedKeys}

          handleRightClick={props.contextMenuSettings.use ? handleItemRightClick : null}
        />
      )}

      {props.checkboxList.use && (
        <ListCheckbox
          disabled={props.disabled}
          className="list-checkbox-wrapper list-checkbox-sm"
          list={props.checkboxList.list}
          handleOnClick={handleOnSelect}
          handleRightClick={props.contextMenuSettings.use ? handleItemRightClick : null}
        />
      )}
      {props.contextMenu.showContextMenu && props.contextMenuSettings.use && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="context-wrapper">
            <ContextMenu
              contextStyles={props.contextMenu.contextMenuStyles}
              content={props.contextMenuSettings.content}
            />
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};

CategoryWidget.propTypes = {
  disabled: bool,
  loading: bool,
  checkboxList: shape({
    use: bool,
    list: arrayOf(shape()),
  }),
  loadingId: string,
  virtualCategoryWidget: shape({
    fetched: bool,
    list: arrayOf(shape({
      id: string,
    })),
  }).isRequired,

  onSelectVirtualCategory: func,
  fetchSelectedCategory: func,
  fetchVirtualChildCategories: func.isRequired,
  setSelectedCategory: func.isRequired,
  onToggleVirtualCategoryTreeCollapse: func.isRequired,
  handleLoadVirtualSubcategories: func.isRequired,
  setShowContextMenu: func.isRequired,
  setTargetTreeNode: func.isRequired,
  setContextMenuStyles: func.isRequired,
  onSelectTreeItem: func,
  setVirtualFacetFilter: func.isRequired,

  contextMenuSettings: shape({
    use: bool,
    content: node,
  }),

  contextMenu: shape({
    showContextMenu: bool,
    contextMenuStyles: shape(),
  }).isRequired,
};

CategoryWidget.defaultProps = {
  disabled: false,
  checkboxList: {
    use: false,
    list: [],
  },
  onSelectTreeItem: null,
  loading: false,
  loadingId: '',
  onSelectVirtualCategory: null,
  fetchSelectedCategory: null,

  contextMenuSettings: {
    bool: false,
    content: null,
  },
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryWidget));
