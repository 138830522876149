import React, { memo } from 'react';
import {
  shape,
} from 'prop-types';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { paginationLimit } from '../../../../utils/defaultData';
import IntlMessages from '../../../../../../util/IntlMessages';

const ProductsPagination = memo((props) => {
  const {
    onPaginate, pages, total, pagination, helperTextId, disabled,
  } = props.paginationSettings;

  const handlePagination = (data) => {
    onPaginate(data);
  };

  return (
    <div className={`pagination justify-end ${helperTextId || disabled ? 'disabled' : ''}`}>
      {total > paginationLimit && (
        <>
          <ReactPaginate
            pageCount={pages}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            breakLabel={<span>...</span>}
            nextLabel={<FontAwesomeIcon size="lg" icon={faAngleRight} />}
            previousLabel={<FontAwesomeIcon size="lg" icon={faAngleLeft} />}
            breakClassName="break-me"
            activeClassName="active"
            containerClassName="pagination flex justify-center"
            onPageChange={handlePagination}
            pageClassName="page-btn"
            forcePage={pagination.page - 1}
            disabled={disabled}
          />
          {helperTextId && (
            <span className="pagination-halper">
              <IntlMessages id={helperTextId} />
            </span>
          )}
        </>
      )}
    </div>
  );
});

ProductsPagination.propTypes = {
  paginationSettings: shape().isRequired,
};

ProductsPagination.defaultProps = {};

export default ProductsPagination;
