import { createSelector } from 'reselect';

const virtualCategoryPageRootSelector = state => state;

const stateSelector = createSelector(
  virtualCategoryPageRootSelector,
  virtualCategoryPage => ({
    virtualCategoryFetching: virtualCategoryPage.fetchingOne,
    virtualCategoryFetched: virtualCategoryPage.fetchedOne,
    virtualCategoryItem: virtualCategoryPage.item,
    productCategoriesAttributes: virtualCategoryPage.filter.productCategoriesAttributes,
    attributesMatrixAttributes: virtualCategoryPage.filter.attributesMatrixAttributes,
    filters: virtualCategoryPage.filter.filters,
    filtersErrors: virtualCategoryPage.filter.filtersErrors,
    isSwitchOn: virtualCategoryPage.filter.isSwitchOn,
    productFilter: virtualCategoryPage.filter.productFilter,
    filterProduct: virtualCategoryPage.filter.product,
    filterCategory: virtualCategoryPage.filter.category,
  }),
);

export const virtualCategoryPageSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  virtualCategoryPageSelector,
};
