/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  func, shape, string, oneOfType, bool, array,
} from 'prop-types';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { camelizeKeys } from 'humps';
import { Divider, CircularProgress } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import {
  setCategoryFields,
  clearVirtualCategoryState,
  fetchDuplicateVirtualCategory,
  setIsDuplicatedCategory,
} from 'actions/virtualCategoryPage';

import { clearVirtualCategoryVirtualFacet } from 'actions/virtualCategoryVirtualFacet';
import { clearPhysicalCategoryState } from 'actions/physicalCategory';
import { clearScopeCategoriesState } from 'actions/scopeCategory';

import RctCollapsibleCard from 'components/RctCollapsibleCard/RctCollapsibleCard';
import { categoriesAppPath } from 'util/paths';
import actionModes from '../../utils/actionModes';
import Header from '../../components/Header';
import Controls from '../../components/Controls';
import Actions from '../../components/Actions';
import Designation from '../../components/Designation';
import Filter from '../../components/Filter';
import Sort from '../../components/Sort';
import Facet from '../../components/Facet';

import ParentCategory from '../../components/ParentCategory';

class NewCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      parentCategoryId: '',
      duplicateCategoryId: '',
    };
  }

  componentDidMount() {
    const search = camelizeKeys(queryString.parse(window.location.search));
    const { rootCategory } = search;
    this.setState({
      parentCategoryId: rootCategory,
      mounted: true,
    });
  }

  componentDidUpdate() {
    const search = camelizeKeys(queryString.parse(window.location.search));
    const { rootCategory, duplicateCategoryId } = search;
    if (rootCategory !== this.state.parentCategoryId) {
      this.setState({
        parentCategoryId: rootCategory,
      });
    }

    if (duplicateCategoryId && duplicateCategoryId !== this.state.duplicateCategoryId) {
      this.setState({
        duplicateCategoryId,
      });
      this.props.setIsDuplicatedCategory(true);
      this.props.fetchDuplicateVirtualCategory(duplicateCategoryId);
    }

    if (!duplicateCategoryId && this.props.isDuplicatedCategory) {
      this.clearReduxState();
      this.setState({
        duplicateCategoryId: '',
      });
      this.props.setIsDuplicatedCategory(true);
    }
  }

  componentWillUnmount() {
    this.clearReduxState();
  }

  handleChangeController = (e) => {
    const { checked, name } = e.target;

    this.props.setCategoryFields({
      ...this.props.virtualCategory,
      [name]: checked,
    });
  };

  handleNameChange = (e, lngKey) => {
    const { value, name } = e.target;

    this.props.setCategoryFields({
      ...this.props.virtualCategory,
      [name]: {
        ...this.props.virtualCategory[name],
        [lngKey]: value,
      },
    });
  };

  handleOnClose = () => {
    this.props.history.push(categoriesAppPath);
  };

  getParentCategory = () => (this.state.parentCategoryId || this.props.virtualCategory.parentId);

  clearReduxState() {
    this.props.clearVirtualCategoryState();
    this.props.clearVirtualCategoryVirtualFacet();
    this.props.clearPhysicalCategoryState();
    this.props.clearScopeCategoriesState();
  }

  render() {
    const { virtualCategory } = this.props;

    return this.state.mounted
      ? (
        <>
          <Header match={this.props.match} pageTitle="virtualCategoryPage.pageTitle.categoryNew" />
          <RctCollapsibleCard>
            <Actions
              type="gray"
              onClose={this.handleOnClose}
              actionMode={actionModes.new}
              key="first"
            />
            <Controls
              handleChangeController={this.handleChangeController}
              fields={{
                useInProductSearch: virtualCategory.useInProductSearch,
                useInDepartmentsTree: virtualCategory.useInDepartmentsTree,
                isShowSubcategoriesOnTop: virtualCategory.isShowSubcategoriesOnTop,
                isEnabled: virtualCategory.isEnabled,
                includeInMenu: virtualCategory.includeInMenu,
              }}
            />
            <Divider className="divider-dashed divider-gap-md" />
            <Designation
              name={virtualCategory.name}
              handleNameChange={this.handleNameChange}
              errors={this.props.virtualCategoryErrors}
            >
              <div className="block form-group form-group-last">
                <ParentCategory
                  parentCategoryId={this.getParentCategory()}
                />
              </div>
            </Designation>

          </RctCollapsibleCard>
          <RctCollapsibleCard>
            <Filter />
          </RctCollapsibleCard>

          <RctCollapsibleCard>
            <Sort />
          </RctCollapsibleCard>

          {isEmpty(this.props.match.params.categoryId) && (
            <Facet key={this.props.match.params.path} />
          )}

          <RctCollapsibleCard>
            <Actions
              gap="0"
              onClose={this.handleOnClose}
              actionMode={actionModes.new}
              key="second"
            />
          </RctCollapsibleCard>
        </>
      )
      : (
        <div className="loader-main flex direction-column">
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className="progress-warning bottom mb-10"
            size={25}
            thickness={4}
          />
          <IntlMessages id="virtualCategoryPage.text.loading" />
        </div>
      );
  }
}

NewCategory.propTypes = {
  history: shape().isRequired,
  virtualCategoryErrors: oneOfType([shape(), array]).isRequired,
  match: shape({
    params: shape({
      categoryId: string,
    }),
  }).isRequired,
  isDuplicatedCategory: bool.isRequired,
  setCategoryFields: func.isRequired,
  virtualCategory: shape().isRequired,
  clearVirtualCategoryState: func.isRequired,
  clearVirtualCategoryVirtualFacet: func.isRequired,
  clearPhysicalCategoryState: func.isRequired,
  fetchDuplicateVirtualCategory: func.isRequired,
  setIsDuplicatedCategory: func.isRequired,
  clearScopeCategoriesState: func.isRequired,
};

const mapStateToProps = state => ({
  virtualCategory: state.virtualCategoryPage.item,
  virtualCategoryErrors: state.virtualCategoryPage.errors,
  isDuplicatedCategory: state.virtualCategoryPage.isDuplicatedCategory,
});

const actionCreators = {
  setCategoryFields,
  clearVirtualCategoryVirtualFacet,
  clearVirtualCategoryState,
  clearPhysicalCategoryState,
  clearScopeCategoriesState,
  fetchDuplicateVirtualCategory,
  setIsDuplicatedCategory,
};

export default connect(mapStateToProps, actionCreators)(NewCategory);
