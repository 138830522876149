import {
  func, shape, bool, arrayOf,
} from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

export const propTypes = {
  fetchUsers: func.isRequired,
  updateUsers: func.isRequired,

  user: shape({
    list: arrayOf(shape()),
    fetched: bool,
  }).isRequired,

  session: shape({
    roles: shape(),
  }).isRequired,

  virtualCategory: shape({
    list: arrayOf(shape()),
  }).isRequired,

  match: shape().isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  createUser: func.isRequired,
  fetchSession: func.isRequired,
  clearScopeCategoryTreeState: func.isRequired,
  clearUserState: func.isRequired,
  deleteUser: func.isRequired,
};

export const defaultProps = {

};

export default {
  propTypes,
  defaultProps,
};
