const restTypes = resource => ({
  fetchAllStart: `${resource.toUpperCase()}_FETCH_ALL_START`,
  fetchAllFinished: `${resource.toUpperCase()}_FETCH_ALL_FINISHED`,

  fetchOneStart: `${resource.toUpperCase()}_FETCH_ONE_START`,
  fetchOneFinished: `${resource.toUpperCase()}_FETCH_ONE_FINISHED`,

  updateStart: `${resource.toUpperCase()}_UPDATE_START`,
  updateFinished: `${resource.toUpperCase()}_UPDATE_FINISHED`,

  updateMultipleStart: `${resource.toUpperCase()}_UPDATE_MULTIPLE_START`,
  updateMultipleFinished: `${resource.toUpperCase()}_UPDATE_MULTIPLE_FINISHED`,

  deleteStart: `${resource.toUpperCase()}_DELETE_START`,
  deleteFinished: `${resource.toUpperCase()}_DELETE_FINISHED`,

  createStart: `${resource.toUpperCase()}_CREATE_START`,
  createFinished: `${resource.toUpperCase()}_CREATE_FINISHED`,

  clearAllFinished: `${resource.toUpperCase()}_CLEAR_ALL_FINISHED`,
  clearOneFinished: `${resource.toUpperCase()}_CLEAR_ONE_FINISHED`,
  clearListFinished: `${resource.toUpperCase()}_CLEAR_LIST_FINISHED`,
  clearStateFinished: `${resource.toUpperCase()}_CLEAR_STATE_FINISHED`,
  clearSearchListFinished: `${resource.toUpperCase()}_CLEAR_SEARCH_LIST_FINISHED`,
  clearFromStateFinished: `${resource.toUpperCase()}_CLEAR_FROM_STATE_FINISHED`,
  setErrorFinished: `${resource.toUpperCase()}_SET_ERROR_FINISHED`,
  clearErrorFinished: `${resource.toUpperCase()}_CLEAR_ERROR_FINISHED`,

  mergeSearchStart: `${resource.toUpperCase()}_MERGE_SEARCH_START`,
  mergeSearchFinished: `${resource.toUpperCase()}_MERGE_SEARCH_FINISHED`,
  mergeStart: `${resource.toUpperCase()}_MERGE_START`,
  mergeFinished: `${resource.toUpperCase()}_MERGE_FINISHED`,
});

export default restTypes;
