import React, { memo } from 'react';
import { connect } from 'react-redux';
import {
  func, shape, bool, arrayOf,
} from 'prop-types';
import { isEmpty } from 'lodash';

import { Badge, Tooltip, Drawer } from '@material-ui/core';

import IntlMessages from '../../../../util/IntlMessages';
import EditCategoryContent from '../../components/EditCategoryContent';
import EditNavigationContent from '../../components/EditNavigationContent';

import {
  toggleDrawer,
} from '../../../../actions/quickEditDrawer';

const QuickEditContainer = memo((props) => {
  const {
    selectedPromotion, selectedCategory, selectedSearchFilter, drawerState, isEditMode,
    facetFilter,
  } = props;

  const badgeCategoryLabel = !isEmpty(selectedCategory) ? <IntlMessages id="preview.category.badge.label" /> : '';
  const badgePromotonLabel = !isEmpty(selectedPromotion) ? <IntlMessages id="preview.promotion.badge.label" /> : '';
  const badgeSearchFilterLabel = !isEmpty(selectedSearchFilter) ? <IntlMessages id="preview.searchFilter.badge.label" /> : '';

  const isNavigationSelected = !isEmpty(selectedPromotion) || !isEmpty(selectedSearchFilter);

  const handleToggleDrawer = () => {
    const newDrawerState = {
      ...drawerState,
      isOpen: !drawerState.isOpen,
    };

    if (isEditMode) props.toggleDrawer(newDrawerState);
  };

  const disabledBadgeClass = !isEditMode ? 'badge-disabled' : '';

  return (
    <>
      <Tooltip
        title={badgeCategoryLabel || badgePromotonLabel || badgeSearchFilterLabel}
        placement="left"
      >
        <Badge
          className={`drawer-toggle-badge ${disabledBadgeClass}`}
          color="primary"
          badgeContent={<i className="ti-arrow-left" />}
          onClick={handleToggleDrawer}
        />
      </Tooltip>
      <Drawer
        anchor="right"
        className="preview-drawer category relative"
        open={drawerState.isOpen}
        onClose={handleToggleDrawer}
      >
        {!isEmpty(selectedCategory) && (
          <EditCategoryContent facetFilter={facetFilter} onClose={handleToggleDrawer} />
        )}
        {isNavigationSelected && (
          <EditNavigationContent facetFilter={facetFilter} onClose={handleToggleDrawer} />
        )}
      </Drawer>
    </>
  );
});

QuickEditContainer.propTypes = {
  selectedPromotion: shape().isRequired,
  selectedCategory: shape().isRequired,
  selectedSearchFilter: shape().isRequired,
  drawerState: shape().isRequired,
  facetFilter: arrayOf(shape()).isRequired,

  toggleDrawer: func.isRequired,
  isEditMode: bool.isRequired,
};

QuickEditContainer.defaultProps = {};

const mapStateToProps = state => ({
  selectedPromotion: state.promotionWidget.selectedPromotion,
  selectedCategory: state.virtualCategoryWidget.selectedCategory,
  selectedSearchFilter: state.preview.selectedSearchFilter,
  isEditMode: state.preview.isEditMode,

  drawerState: state.quickEditDrawer.drawerState,
});

const mapDispatchToProps = {
  toggleDrawer,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickEditContainer);
