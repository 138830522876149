import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { func, arrayOf, shape } from 'prop-types';
import { isEmpty } from 'lodash';
import Select from '../../../../../../components/Select';
import SearchAutocomplete from '../../../../../../components/SearchAutocomplete';

import viewOptions from '../../../../utils/viewOptions';
import IntlMessage from '../../../../../../util/IntlMessages';
import { defaultPagination } from '../../../../utils/defaultData';


import {
  headerContentSelector,
} from './selectors/headerContentSelector';

import {
  clearFromCategoryFacetState,
} from '../../../../../../actions/categoryFacet';

import {
  setMode,
  setSearchValue,
  setFacetsPreFilters,
  setSelectedSearchFilter,
  setSearchQuery,
  setProductsListEmpty,
  fetchProductList,
  setIsHiddenProductsMode,
  setHiddenProductsFilter,
  setHiddenProductsIds,
  setHiddenProductsExist,
  setPagination,
} from '../../../../../../actions/preview';

import { setSelectedCategory } from '../../../../../../actions/virtualCategoryWidget';
import { setSelectedPromotion } from '../../../../../../actions/promotionWidget';

import {
  findNavigationByPhrase,
} from '../../../../../../actions/search';

const HeaderContent = memo((props) => {
  let timeoutDelay = null;
  const { headerContentProps } = props;
  const [hasError, setHasError] = useState(false);

  const errorData = {
    errorMessage: 'text.errors.lessThan200Characters',
    queryLengthLimit: 170,
    placeholder: 'Search by search phrase',
  };

  const onModeChange = e => props.setMode(e.target.value);

  const handleSearchWithDelay = (filter, fullText) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      props.findNavigationByPhrase(filter, fullText, defaultPagination);
    }, 500);
  };

  const handleClearVisibilityState = () => {
    props.setIsHiddenProductsMode(false);
    props.setHiddenProductsFilter([]);
    props.setHiddenProductsIds([]);
    props.setHiddenProductsExist(false);
  };

  const onSearch = (e) => {
    const { value } = e.target;
    const filter = [
      {
        condition: 'and',
        group: [{
          field: 'type',
          value: 'search_phrase',
          operator: 'eq',
        }],
      },
    ];
    const fullText = {
      fields: [
        'search_phrase',
      ],
      value,
    };

    props.setSearchValue(e.target.value);

    if (e.target.value) {
      handleSearchWithDelay(filter, fullText);
    }

    if (isEmpty(e.target.value) && !isEmpty(headerContentProps.facetsPreFilters)) {
      props.setFacetsPreFilters([]);
    }

    if (!e.target.value) {
      if (
        isEmpty(props.selectedCategory)
        && isEmpty(headerContentProps.selectedPromotion)
      ) {
        const queryFilter = { ...headerContentProps.searchQuery };

        if (queryFilter.searchPhrase) delete queryFilter.searchPhrase;

        props.setSelectedSearchFilter({});
        props.setSearchQuery(queryFilter);
        props.clearFromCategoryFacetState({
          list: [],
        });
        handleClearVisibilityState();
        return props.setProductsListEmpty();
      }

      return props.fetchProductList({
        viewMode: 'frontend',
        filter: [],
        pagination: defaultPagination,
        searchFilter: {
          ...headerContentProps.searchQuery,
          categoryId: props.selectedCategory.id,
        },
      });
    }
    return false;
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (value && value.length > errorData.queryLengthLimit) {
      setHasError(true);
      return;
    }
    setHasError(false);
    onSearch(e);
  };

  const handleSearchPhraseClick = ({ name, value: phrase }) => {
    const currentNavigation = props.suggestions
      .find(s => s.name === name);
    const searchQuery = {
      ...headerContentProps.searchQuery,
      searchPhrase: phrase,
    };

    if (searchQuery.urlKey) {
      delete searchQuery.urlKey;
      props.setSelectedPromotion({});
      props.setFacetsPreFilters([]);
      props.setPagination(defaultPagination);
    }

    if (searchQuery.categoryId) {
      delete searchQuery.categoryId;
      props.setSelectedCategory({});
      props.setFacetsPreFilters([]);
      props.setPagination(defaultPagination);
    }

    if (currentNavigation) {
      props.setSelectedSearchFilter(currentNavigation);
    }

    props.setSearchQuery(searchQuery);
    props.setSearchValue(phrase);

    return props.fetchProductList({
      viewMode: 'frontend',
      filter: [],
      pagination: defaultPagination,
      searchFilter: searchQuery,
      ignoreFacets: false,
    });
  };

  return (
    <div className="flex block main-header">
      <Select
        value={headerContentProps.mode}
        options={viewOptions}
        onChange={onModeChange}
        name="mode"
        selectClassName="simple-select"
        className="select-wrapper select-underline-hide hidden"
      />

      <SearchAutocomplete
        value={headerContentProps.searchValue}
        onChange={handleSearchChange}
        className="search-preview"
        wrapperClassName="pl-10 block"
        suggestions={props.suggestions}
        placeholder={errorData.placeholder}
        onSearchPhraseClick={handleSearchPhraseClick}
      />
      {hasError && (
        <div className="errors errors-abs">
          <IntlMessage id={errorData.errorMessage} />
        </div>
      )}
    </div>
  );
});

HeaderContent.propTypes = {
  suggestions: arrayOf(shape()).isRequired,
  setMode: func.isRequired,

  setSearchValue: func.isRequired,
  setFacetsPreFilters: func.isRequired,
  setSelectedSearchFilter: func.isRequired,
  setSearchQuery: func.isRequired,
  setProductsListEmpty: func.isRequired,
  fetchProductList: func.isRequired,
  findNavigationByPhrase: func.isRequired,
  setIsHiddenProductsMode: func.isRequired,
  setHiddenProductsFilter: func.isRequired,
  setHiddenProductsIds: func.isRequired,
  setHiddenProductsExist: func.isRequired,
  setSelectedPromotion: func.isRequired,
  setPagination: func.isRequired,
  setSelectedCategory: func.isRequired,
  clearFromCategoryFacetState: func.isRequired,
  headerContentProps: shape().isRequired,
  selectedCategory: shape().isRequired,
};

HeaderContent.defaultProps = {};

const mapStateToProps = state => ({
  headerContentProps: headerContentSelector(state.preview),
  suggestions: state.search.suggestions,
  selectedCategory: state.virtualCategoryWidget.selectedCategory,
});

const actionCreators = {
  setMode,
  setSearchValue,
  setFacetsPreFilters,
  setSelectedSearchFilter,
  setSearchQuery,
  setProductsListEmpty,
  fetchProductList,
  findNavigationByPhrase,
  setIsHiddenProductsMode,
  setHiddenProductsFilter,
  setHiddenProductsIds,
  setHiddenProductsExist,
  setSelectedPromotion,
  setPagination,
  setSelectedCategory,
  clearFromCategoryFacetState,
};

export default connect(mapStateToProps, actionCreators)(HeaderContent);
