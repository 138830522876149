/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import mappedProductList from '../../../../utils/mapProductList';

const useProduct = ({
  setSelectedProductList,
  productsByIdsForNin,
  productsByIdsForIn,
}) => {
  useEffect(() => {
    setSelectedProductList({
      nin: [...mappedProductList(productsByIdsForNin)],
      in: [...mappedProductList(productsByIdsForIn)],
    });
  }, [
    productsByIdsForNin,
    productsByIdsForIn,
  ]);
};

export default useProduct;
