import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
  Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { setShowZapInfo } from '../../../../actions/pim';
import IntlMessages from '../../../../util/IntlMessages';
import { getPublishPrice, moneyFormat } from '../../pages/Home/utils/productDataGenerator';
import config from '../../../../util/config';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  closeButton: {}
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box className={classes.title}>
          <Typography variant="h6">
            { children }
          </Typography>
        </Box>
        <Box>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);


const ZapInfo = (props) => {
  const handleClose = () => props.setShowZapInfo(null);

  const competitorsKeys = Object.keys(props.competitors);
  if (competitorsKeys.length === 0) {
    return (
      <div>
        <Dialog maxWidth="lg"
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.showZapInfo}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <IntlMessages id="zap.info.title" />
          </DialogTitle>
          <DialogContent>
            <div><IntlMessages id="zap.info.noData" /></div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
  
  const productTitle = props.parentProduct.title ? props.parentProduct.title.en : '';
  const linkToZapPage = props.variationProduct.zmid ? 
    config.zapWebsitePath.replace('%id%', props.variationProduct.zmid[0]) : '/';

  let lastUpdate = '';
  let competitor = {};
  let place = 0;

  const positions = [];
  const prices = [];
  let inList = false;

  let k = 0;
  for (let i = 0; i < competitorsKeys.length; i++) {
    competitor = props.competitors[competitorsKeys[i]];
    for (let j = 0; j < competitor.lastSync.length; j++) {
      if (competitor.lastSync[j].pubAopi === props.variationProduct.pubAopi) {
        lastUpdate = competitor.lastSync[j].lastSync;
      }
    }
    if (!lastUpdate) {
      lastUpdate = competitor.updatedAt;
    }

    for (let j = 0; j < competitor.stores.length; j++) {
      if (competitor.stores[j].storeName === 'American Outlets') {
        place = competitor.stores[j].position;
        inList = true;
      }
      positions[k] = {
        title: competitor.stores[j].storeName,
        price: competitor.stores[j].price,
        position: competitor.stores[j].position
      };
      prices[k] = competitor.stores[j].price;
      k++;
    }
  }
  prices.sort(function(a, b) {
    return a - b;
  });

  const recommendedPrice = Math.min( ...prices);

  let publishPrice = 0;
  if (place === 0 && props.variationProduct.price.finalPrice) {
    publishPrice = getPublishPrice(
      props.variationProduct.price.finalPrice,
      props.currency.value,
      props.variationProduct.isDomestic
    );
    publishPrice = Math.ceil(publishPrice);

    prices.push(publishPrice);
    prices.sort(function(a, b) {
      return a - b;
    });

    for (let i = 0; i < prices.length; i++) {
      if (publishPrice === prices[i]) {
        place = i + 1;

        break;
      }
    }
  }

  if (!lastUpdate) {
    lastUpdate = props.variationProduct.updatedAt ? props.variationProduct.updatedAt : 'N/A';
  }

  const Position = (props) => {
    let className = '';
    if (props.item.price === props.recommendedPrice) {
      className = 'zap-green';
    }

    return (
      <TableRow>
        <TableCell className={className}>
          <IntlMessages id="zap.info.position" /> { parseInt(props.item.position) }
        </TableCell>
        <TableCell className={className}>{ props.item.title }</TableCell>
        <TableCell className={className}>{ moneyFormat(props.item.price, '₪') }</TableCell>
      </TableRow>
    );
  };

  return (
    <div>
      <Dialog maxWidth="lg"
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={props.showZapInfo}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <IntlMessages id="zap.info.title" />
        </DialogTitle>
        <DialogContent>
          
          <div className="zap-position">
            <IntlMessages id="zap.info.position" /> <span className="zap-red"># {place}</span>
          </div>
          {inList === false && publishPrice > 0 ? (
            <div className="zap-publish-price">
              <IntlMessages id="zap.info.publishPrice" />&nbsp;
              <span className="zap-red">{ moneyFormat(publishPrice, '₪') }</span>
            </div>
          ) : ('')}
          
          <TableContainer>
            <Table className="zap-table">
              <TableHead>
              <TableRow>
                <TableCell><IntlMessages id="zap.info.productName" /></TableCell>
                <TableCell colspan="2">{productTitle}</TableCell>
              </TableRow>
              </TableHead>
              <TableBody>

              {positions.map((item, key) => (
                <Position key={key} item={item} recommendedPrice={recommendedPrice} />
              ))}

              <TableRow>
                <TableCell colspan="2"><strong><IntlMessages id="zap.info.recommendingPricing" /></strong></TableCell>
                <TableCell className="zap-green">{ moneyFormat(recommendedPrice, '₪') }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colspan="2"><u><IntlMessages id="zap.info.strategy" /></u></TableCell>
                <TableCell><IntlMessages id="zap.info.strategyByRobot" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell colspan="2"><strong><IntlMessages id="zap.info.lastPriceUpdate" /></strong></TableCell>
                <TableCell className="zap-red">{moment.utc(lastUpdate).format('MM-DD-YYYY HH:mm')}</TableCell>
              </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className="zap-link">
            <a href={linkToZapPage} target="_blank" rel="noopener noreferrer">
              <IntlMessages id="zap.info.goToZapPage" />
            </a>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ZapInfo.propTypes = {
  variationProduct: PropTypes.object.isRequired,
  parentProduct: PropTypes.object.isRequired,
  showZapInfo: PropTypes.bool.isRequired,
  setShowZapInfo: PropTypes.func.isRequired,
  competitors: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired
};

ZapInfo.defaultProps = {
  variationProduct: {},
  parentProduct: {},
  showZapInfo: false,
  competitors: {},
  currency: {}
};

const mapStateToProps = state => ({
  variationProduct: state.pim.zapInfo.variationProduct,
  parentProduct: state.pim.zapInfo.parentProduct,
  showZapInfo: state.pim.zapInfo.showZapInfo,
  competitors: state.pim.zapInfo.competitors,
  currency: state.currency.item
});

const mapDispatchToProps = { setShowZapInfo };

export default connect(mapStateToProps, mapDispatchToProps)(ZapInfo);
