import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  GENERATE_NEW_XLSX_FILE_START,
  GENERATE_NEW_XLSX_FILE_FINISHED,
  IMPORT_PRODUCTS_WITH_NEW_POSITION_START,
  IMPORT_PRODUCTS_WITH_NEW_POSITION_FINISHED,
  SET_IMPORT_FILE_NAME,
  FETCH_IMPORTED_PRODUCTS_START,
  FETCH_IMPORTED_PRODUCTS_FINISHED,
  CLEAR_PRODUCT_MANAGEMENT_STATE,
} from '../actions/types';

const initialState = {
  xlsxDocument: {},
  importData: [],
  fileName: '',
  importedProducts: [],
};
const productManagementRestTypes = restTypes('product_management');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case productManagementRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case productManagementRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        list: action.payload,
      };
    case GENERATE_NEW_XLSX_FILE_START:
      return {
        ...state,
        generatingNewXLSX: true,
        generatedNewXLSX: false,
      };
    case GENERATE_NEW_XLSX_FILE_FINISHED:
      return {
        ...state,
        generatingNewXLSX: false,
        generatedNewXLSX: true,
        ...action.payload,
      };
    case IMPORT_PRODUCTS_WITH_NEW_POSITION_START:
      return {
        ...state,
        importingXLSXWithProducts: true,
        importedXLSXWithProducts: false,
      };
    case IMPORT_PRODUCTS_WITH_NEW_POSITION_FINISHED:
      return {
        ...state,
        importingXLSXWithProducts: false,
        importedXLSXWithProducts: true,
        ...action.payload,
      };
    case SET_IMPORT_FILE_NAME:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_IMPORTED_PRODUCTS_START:
      return {
        ...state,
        fetchingImportedProducts: true,
        fetchedImportedProducts: false,
      };
    case FETCH_IMPORTED_PRODUCTS_FINISHED:
      return {
        ...state,
        ...action.payload,
        fetchingImportedProducts: false,
        fetchedImportedProducts: true,
      };
    case CLEAR_PRODUCT_MANAGEMENT_STATE:
      return {
        ...initialState,
        ...restInitState,
      };
    default: return state;
  }
};
