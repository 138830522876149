import { snakeToCamelCase, camelToSnakeCase } from "util/changeReg"

export const checkChangedValues = (obj1, obj2, config) => {
    let newString = "";
    if (config.exceptions && !config.exceptions.some(item => item === objKey)) {
        for (var objKey in obj1) {
            if (typeof (obj1[objKey]) === "object") {
                const newKey = camelToSnakeCase(Array.isArray(obj1[objKey])? objKey :` ${ !config.string ? "" : config.string + "." }${objKey}`);
                if (obj2 && obj2[objKey]) {
                    newString += checkChangedValues(obj1[objKey], obj2[objKey], {...config, string: newKey, arary: true});
                } else {
                    newString += checkChangedValues(obj1[objKey], false, {...config, string: newKey, arary: true, new: true});
                }
            } else {
                const snakeKey = camelToSnakeCase(objKey)
                const string = config.stringExceptions.some(e => e !== config.string) ? config.string : "";
                if(config.new) {
                    newString += !!string ? `${string}.${snakeKey},` : ` ${snakeKey},`;
                } else {
                    const newKey = !obj2[objKey] ? snakeToCamelCase(objKey) : objKey
                    const firstValue = objKey === "url" ? obj1[objKey].split('?')[0] :obj1[objKey];
                    const secondValue = objKey === "url" ? obj2[newKey].split('?')[0] :obj2[newKey];
                    if (firstValue !== secondValue) newString += !!string  ? ` ${string}.${snakeKey},` : ` ${snakeKey},`;
                }
            }
        }
    }
    return newString;
}