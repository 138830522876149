import { createSelector } from 'reselect';

const previewRootSelector = state => state;

const stateSelector = createSelector(
  previewRootSelector,
  productAttribute => ({
    categoriesAttributesFetching: productAttribute.categoriesAttributesFetching,
    categoriesAttributesFetched: productAttribute.categoriesAttributesFetched,
    categoriesAttributes: productAttribute.categoriesAttributes,
    fetchingAttributeOptions: productAttribute.fetchingAttributeOptions,
    fetchingAttributesOptions: productAttribute.fetchingAttributesOptions,
    fetchedAttributeOptions: productAttribute.fetchedAttributeOptions,
    fetchedAttributesOptions: productAttribute.fetchedAttributesOptions,
    attributesOptions: productAttribute.attributesOptions,
  }),
);

export const productAttributeSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  productAttributeSelector,
};
