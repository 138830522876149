/* eslint-disable no-param-reassign */
import { camelizeKeys, decamelizeKeys } from 'humps';
import axios from 'axios';
import { uniq, isEmpty } from 'lodash';

import RestActions from '../util/rest/actions';
import { showErrorNotification } from '../util/api';

import {
  productsFrontAppPath,
  getNavigationsPath,
  navigationsSearchPath,
} from '../util/paths';

import {
  PREVIEW_NAVIGATION_SET_CATEGORY_FIELDS,
  PREVIEW_NAVIGATION_SET_PRODUCT_CATEGORIES_ATTRIBUTES,
  PREVIEW_NAVIGATION_SET_ATTRIBUTES_MATRIX_ATTRIBUTES,
  PREVIEW_NAVIGATION_SET_FILTERS,
  PREVIEW_NAVIGATION_SET_FILTERS_ERRORS,
  PREVIEW_NAVIGATION_SET_PRODUCTS_NIN_IDS,
  PREVIEW_NAVIGATION_SET_PRODUCTS_IN_IDS,
  PREVIEW_NAVIGATION_SET_SELECTED_PRODUCT_LIST,
  PREVIEW_NAVIGATION_UPDATE_LOADED_PRODUCTS_IN,
  PREVIEW_NAVIGATION_UPDATE_LOADED_PRODUCTS_NIN,
  PREVIEW_NAVIGATION_COMPLETE_PRODUCTS_LIST_START,
  PREVIEW_NAVIGATION_COMPLETE_PRODUCTS_LIST_FINISHED,
  PREVIEW_NAVIGATION_SET_IS_APPLY_FILTER_ACTIVE,
  PREVIEW_NAVIGATION_SET_IS_SWITCH_ON,
  PREVIEW_NAVIGATION_SET_PRODUCT_FILTER,
  PREVIEW_NAVIGATION_FETCH_PRODUCT_LIST_START,
  PREVIEW_NAVIGATION_FETCH_PRODUCT_LIST_FINISHED,
  PREVIEW_NAVIGATION_SET_PRODUCT_COMPOUND_QUERY,
  PREVIEW_NAVIGATION_SET_LEFT_SIDE_PAGINATION,
  PREVIEW_NAVIGATION_FETCH_PRODUCTS_BY_IDS_START,
  PREVIEW_NAVIGATION_FETCH_PRODUCTS_BY_IDS_FINISHED,
  PREVIEW_NAVIGATION_SET_SHOW_CATEGORIES_DIALOG,
  PREVIEW_NAVIGATION_SET_SELECTED_TREE_ITEMS,
  PREVIEW_NAVIGATION_SET_MAPPED_CATEGORIES,
  PREVIEW_NAVIGATION_SET_SELECTED_CATEGORIES,
  PREVIEW_NAVIGATION_CLEAR_CATEGORY_PRODUCT_STATE,
  PREVIEW_NAVIGATION_SET_RANKING_RULE_CODE,
  PREVIEW_NAVIGATION_SET_SORTING_ERRORS,
  PREVIEW_NAVIGATION_SET_SORT_RULES,
  PREVIEW_NAVIGATION_SET_FACETS,
  PREVIEW_NAVIGATION_SET_IS_DATA_CHANGED,
  PREVIEW_NAVIGATION_SET_IS_CATEGORY_SAVING,
  PREVIEW_NAVIGATION_SET_ERRORS,
  PREVIEW_NAVIGATION_SET_PHYSICAL_CATEGORIES_IDS,
  PREVIEW_NAVIGATION_CLEAR_NAVIGATION_FILTER_STATE,
  PREVIEW_NAVIGATION_SEARCH_BY_PHRASES_START,
  PREVIEW_NAVIGATION_SEARCH_BY_PHRASES_FINISHED,
  PREVIEW_NAVIGATION_SET_EXISTED_SEARCH_PHRASE,
  PREVIEW_NAVIGATION_FETCH_SEARCH_LIST_BY_NAME_START,
  PREVIEW_NAVIGATION_FETCH_SEARCH_LIST_BY_NAME_FINISHED,
  PREVIEW_NAVIGATION_CLEAR_NAVIGATION_FACET_STATE,
} from './types';

import normalizeFilters from '../util/normalizeFilters';
import { mapPositions } from '../util/mapVirtualParamsPositions';
import {
  unmapFromAttributesMatrix,
  mapToAttributesMatrix,
  mapFilters,
} from '../util/attributesMatrixMapping';

import {
  getValueByOperator,
  getFieldByOperator,
} from '../util/mapFiltersByOperator';
import { generateRequestBody } from '../util/productPayload';
import { mapExportableProducts } from '../util/mapExportableProducts';

import {
  getFrontendProductsByIds,
} from '../services/API/product';

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';

const restPreviewNavigationActions = new RestActions('preview-navigation');
const {
  fetchStart,
  fetchFinished,
  clearErrorFinished,
  updateStart,
  updateFinished,
  clearFromStateFinished,
} = restPreviewNavigationActions;

export const mapVirtualParams = (virtualParams, mapToMatrix, attributesMatrix) => {
  const positions = Array.isArray(virtualParams.positions) ? {} : virtualParams.positions;
  return ({
    ...virtualParams,
    filters: mapToMatrix ? [...mapFilters(virtualParams.filters, attributesMatrix)]
      : virtualParams.filters,
    sortRules: mapToMatrix ? virtualParams.sortRules.map(s => ({
      field: mapToAttributesMatrix(s.field, attributesMatrix),
      direction: s.direction.toLowerCase(),
    })) : virtualParams.sortRules,
    positions: virtualParams.productsOverride
      ? virtualParams.productsOverride.reduce((result, item) => {
        const payload = { ...result };

        payload[item.productId] = item.position;
        return payload;
      }, {})
      : positions,
  });
};

export const fetchNavigation = navId => (dispatch, getState) => {
  const { list: attributesMatrix } = getState().attributesMatrix;

  dispatch(fetchStart());
  axios.get(getNavigationsPath(navId)).then((res) => {
    const data = normalizeFilters(camelizeKeys(res.data.data));
    const payload = { ...data };
    payload.virtualParams = mapPositions(
      data.virtualParams,
      res.data.data.virtual_params,
    );

    const navigationItem = unmapFromAttributesMatrix(payload, attributesMatrix);

    dispatch(fetchFinished({
      item: navigationItem,
      originalItem: navigationItem,
    }));
  }, (error) => {
    dispatch(fetchFinished({ error: error.response.data, hasError: true }));
  });
};

export const setNavigationFields = item => async (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_CATEGORY_FIELDS,
    payload: { item },
  });
};

export const setIsDataChanged = isDataChanged => async (dispatch) => { // TODO mb delete
  dispatch({
    type: PREVIEW_NAVIGATION_SET_IS_DATA_CHANGED,
    payload: { isDataChanged },
  });
};

export const setIsNavigationSaving = isNavigationSaving => async (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_IS_CATEGORY_SAVING,
    payload: { isNavigationSaving },
  });
};

// FILTER

export const setProductCategoriesAttributes = productCategoriesAttributes => async (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_PRODUCT_CATEGORIES_ATTRIBUTES,
    payload: { productCategoriesAttributes },
  });
};

export const setAttributesMatrixAttributes = attributesMatrixAttributes => async (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_ATTRIBUTES_MATRIX_ATTRIBUTES,
    payload: { attributesMatrixAttributes },
  });
};

export const setFilters = filters => async (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_FILTERS,
    payload: { filters },
  });
};

export const setFiltersErrors = filtersErrors => async (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_FILTERS_ERRORS,
    payload: { filtersErrors },
  });
};

export const setIsSwitchOn = isSwitchOn => async (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_IS_SWITCH_ON,
    payload: { isSwitchOn },
  });
};

export const setProductFilter = productFilter => async (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_PRODUCT_FILTER,
    payload: { productFilter },
  });
};

export const setErrors = errors => async (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_ERRORS,
    payload: { errors },
  });
};

export const clearErrorState = () => (dispatch) => {
  dispatch(clearErrorFinished({ error: {}, hasErrors: false }));
};

export const updateSearchFilter = (navId, body, params = {}) => (dispatch, getState) => {
  const { mapToMatrix } = params;
  const { list: attributesMatrix } = getState().attributesMatrix;
  delete body.virtualParams.facets;
  const updatedBody = decamelizeKeys({
    data: {
      ...body,
      virtualParams: {
        ...body.virtualParams,
        // facets,
        filters: mapToMatrix
          ? mapFilters(body.virtualParams.filters, attributesMatrix)
          : body.virtualParams.filters,
        sortRules: body.virtualParams.sortRules.map(s => ({
          field: mapToMatrix
            ? mapToAttributesMatrix(s.field, attributesMatrix)
            : s.field,
          direction: s.direction.toLowerCase(),
        })),
      },
    },
  });

  if (!isEmpty(body.virtualParams.productsOverride)) {
    updatedBody.data.virtual_params.positions = body.virtualParams.productsOverride
      ? body.virtualParams.productsOverride.reduce((result, item) => {
        const payload = { ...result };

        payload[item.productId] = item.position;
        return payload;
      }, {})
      : body.virtualParams.positions;
  }

  if (!isEmpty(body.virtualParams.positions)
    && isEmpty(body.virtualParams.productsOverride)) {
    updatedBody.data.virtual_params.positions = body.virtualParams.positions;
  }

  if (body.virtualParams.productsOverride && isEmpty(body.virtualParams.productsOverride)) {
    delete updatedBody.data.virtual_params.positions;
  }

  if (updatedBody.data.virtual_params.products_override) {
    delete updatedBody.data.virtual_params.products_override;
  }

  delete updatedBody.data.virtual_params.facets; // TODO remove

  dispatch(updateStart());
  axios.put(`${getNavigationsPath(navId)}?is_synchronous=true`, updatedBody).then((res) => {
    const updatedNavigation = camelizeKeys(res.data.data);
    const payload = { ...updatedNavigation };
    payload.virtualParams = mapPositions(
      updatedNavigation.virtualParams,
      res.data.data.virtual_params,
    );

    const newItem = unmapFromAttributesMatrix(payload, attributesMatrix);

    dispatch(updateFinished({
      item: newItem,
      originalItem: newItem,
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(updateFinished({ error: error.response.data, hasError: true }));
  });
};

// FILTER PRODUCTS

export const setProductsNotInIds = productsNotInIds => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_PRODUCTS_NIN_IDS,
    payload: { productsNotInIds },
  });
};

export const setProductsInIds = productsInIds => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_PRODUCTS_IN_IDS,
    payload: { productsInIds },
  });
};

export const setSelectedProductList = selectedProductList => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_SELECTED_PRODUCT_LIST,
    payload: { selectedProductList },
  });
};

export const updateLoadedProductsIn = productsByIdsForIn => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_UPDATE_LOADED_PRODUCTS_IN,
    payload: { productsByIdsForIn },
  });
};

export const updateLoadedProductsNin = productsByIdsForNin => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_UPDATE_LOADED_PRODUCTS_NIN,
    payload: { productsByIdsForNin },
  });
};

export const completeProductList = (ids, key, pagination) => async (dispatch, getState) => {
  dispatch({
    type: PREVIEW_NAVIGATION_COMPLETE_PRODUCTS_LIST_START,
    payload: {
      completingCategoryProductsList: true,
      completedCategoryProductsList: false,
    },
  });

  const currentKey = key || 'productsByIds';
  const previewNavigationState = getState().previewNavigation;
  const currentTargetList = previewNavigationState.filter.product[currentKey];
  const query = {
    filter: [{
      group: [{
        field: 'id',
        value: uniq(ids),
        operator: 'in',
      }],
    }],
    pagination,
  };

  const res = await getFrontendProductsByIds(query);
  const items = camelizeKeys(res);

  dispatch({
    type: PREVIEW_NAVIGATION_COMPLETE_PRODUCTS_LIST_FINISHED,
    payload: {
      completingCategoryProductsList: false,
      completedCategoryProductsList: true,
      [currentKey]: [...currentTargetList.map((l) => {
        const itemToSet = items.find(p => p.id === l.id);
        return itemToSet || l;
      })],
    },
  });
};

export const setIsProductDialogApplyFilterActive = isProductDialogApplyFilterActive => (
  dispatch,
) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_IS_APPLY_FILTER_ACTIVE,
    payload: { isProductDialogApplyFilterActive },
  });
};

export const fetchProductList = ({
  viewMode,
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  fullText = {},
  compoundSearch = null,
}) => (dispatch, getState) => {
  const sort = [{
    field: 'categories.position',
    order: 'asc',
  }];
  const { list } = getState().previewFilterAttribute;
  const mappedFilters = filter.map((f) => {
    const group = f.group.map(g => ({
      ...g,
      value: getValueByOperator({ value: g.value, operator: g.operator }),
      field: getFieldByOperator({
        field: g.field, operator: g.operator, previewFilterAttribute: list,
      }),
    }));

    return {
      ...f,
      group,
    };
  });

  const body = {
    viewMode,
    fields,
    pagination,
    fullText,
    sort,
    searchFilter,
  };

  if (!isEmpty(mappedFilters)) body.filter = mappedFilters;

  const params = {
    ...generateRequestBody(body),
  };

  if (compoundSearch && !isEmpty(compoundSearch) && compoundSearch.value) {
    params.body.compoundSearch = compoundSearch;
  }

  dispatch({
    type: PREVIEW_NAVIGATION_FETCH_PRODUCT_LIST_START,
    payload: {
      categoryFetchProductListStart: true,
      categoryFetchProductListFinished: false,
    },
  });

  return axios.post(
    params.path,
    decamelizeKeys(params.body),
  ).then((res) => {
    const {
      pages, total, aggregation,
    } = res.data;
    const result = res.data.data.map((p) => {
      if (p.variants) {
        return {
          ...p,
          variants: Object.keys(p.variants).map((v) => {
            const item = p.variants[v];
            return ({
              ...item,
              id: v,
            });
          }),
        };
      }
      return p;
    });

    dispatch({
      type: PREVIEW_NAVIGATION_FETCH_PRODUCT_LIST_FINISHED,
      payload: {
        list: mapExportableProducts(camelizeKeys(result)),
        aggregation,
        pages,
        total,
        updated: false,
        updating: false,
        categoryFetchProductListStart: false,
        categoryFetchProductListFinished: true,
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: PREVIEW_NAVIGATION_FETCH_PRODUCT_LIST_FINISHED,
      payload: {
        error: error.response.data,
        hasErrors: true,
        list: [],
      },
    });
  });
};

export const setCompoundSearch = compoundSearch => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_PRODUCT_COMPOUND_QUERY,
    payload: { compoundSearch },
  });
};

export const setLeftSidePagination = leftSidePagination => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_LEFT_SIDE_PAGINATION,
    payload: { leftSidePagination },
  });
};

export const fetchProductsByIds = (ids, key, pagination) => async (dispatch, getState) => {
  const previewNavigationState = getState().previewNavigation;
  const currentKey = key || 'productsByIds';
  const currentTargetList = previewNavigationState.filter.product[currentKey];

  dispatch({
    type: PREVIEW_NAVIGATION_FETCH_PRODUCTS_BY_IDS_START,
    payload: {
      fetchProductsByIdsStarted: true,
      fetchProductsByIdsFinished: false,
    },
  });

  const condition = ids.length > 1
    ? { condition: 'or' }
    : {};

  return axios.post(
    `${productsFrontAppPath}/search`,
    {
      filter: [
        {
          ...condition,
          group: [{
            field: 'id',
            value: uniq(ids),
            operator: 'in',
          }],
        },
      ],
      pagination,
    },
  ).then((res) => {
    const items = mapExportableProducts(camelizeKeys(res.data.data));

    dispatch({
      type: PREVIEW_NAVIGATION_FETCH_PRODUCTS_BY_IDS_FINISHED,
      payload: {
        [currentKey]: [...currentTargetList.map((l) => {
          const itemToSet = items.find(p => p.id === l.id);
          return itemToSet || l;
        })],
        fetchProductsByIdsStarted: false,
        fetchProductsByIdsFinished: true,
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: PREVIEW_NAVIGATION_FETCH_PRODUCTS_BY_IDS_FINISHED,
      payload: {
        error: error.response.data,
        hasErrors: true,
      },
    });
  });
};

export const clearProductState = () => (dispatch) => {
  dispatch({ type: PREVIEW_NAVIGATION_CLEAR_CATEGORY_PRODUCT_STATE });
};

// SORT

export const setRankingRuleCode = rankingRuleCode => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_RANKING_RULE_CODE,
    payload: { rankingRuleCode },
  });
};

export const setSortingsErrors = sortingsErrors => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_SORTING_ERRORS,
    payload: { sortingsErrors },
  });
};

export const setSortRules = sortRules => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_SORT_RULES,
    payload: { sortRules },
  });
};

// CATEGORY

export const setMappedCategories = mappedCategories => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_MAPPED_CATEGORIES,
    payload: { mappedCategories },
  });
};

export const setSelectedCategories = selectedCategories => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_SELECTED_CATEGORIES,
    payload: { selectedCategories },
  });
};

export const setPhysicalCategoriesIds = physicalCategoriesIds => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_PHYSICAL_CATEGORIES_IDS,
    payload: { physicalCategoriesIds },
  });
};

export const setSelectedTreeItems = selectedTreeItems => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_SELECTED_TREE_ITEMS,
    payload: { selectedTreeItems },
  });
};

export const setShowCategoriesDialog = showCategoriesDialog => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_SHOW_CATEGORIES_DIALOG,
    payload: { showCategoriesDialog },
  });
};

export const clearNavigationFilterState = () => (dispatch) => {
  dispatch({ type: PREVIEW_NAVIGATION_CLEAR_NAVIGATION_FILTER_STATE });
};

export const clearNavigationFacetState = () => (dispatch) => {
  dispatch({ type: PREVIEW_NAVIGATION_CLEAR_NAVIGATION_FACET_STATE });
};

// FACET

export const setFacets = facets => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_FACETS,
    payload: { facets },
  });
};

export const searchNavigationsByPhrases = (searchPhrases = []) => async (dispatch) => {
  const filter = !isEmpty(searchPhrases)
    ? ([{
      group: [{
        field: 'search_phrase',
        value: searchPhrases,
        operator: 'in',
      }],
    }])
    : [];

  dispatch({ type: PREVIEW_NAVIGATION_SEARCH_BY_PHRASES_START });
  if (isEmpty(filter)) return [];
  try {
    const res = await axios.post(
      navigationsSearchPath + '?ref=' + ref,
      decamelizeKeys({ filter }),
    );
    dispatch({
      type: PREVIEW_NAVIGATION_SEARCH_BY_PHRASES_FINISHED,
      payload: { phrases: camelizeKeys(res.data.data) },
    });
    return camelizeKeys(res.data.data);
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: PREVIEW_NAVIGATION_SEARCH_BY_PHRASES_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
    return error;
  }
};

export const setExistedSearchPhrase = existedSearchPhrase => (dispatch) => {
  dispatch({
    type: PREVIEW_NAVIGATION_SET_EXISTED_SEARCH_PHRASE,
    payload: { existedSearchPhrase },
  });
};

export const fetchSearchListByName = (filter, pagination, cb) => (dispatch) => {
  dispatch({ type: PREVIEW_NAVIGATION_FETCH_SEARCH_LIST_BY_NAME_START });
  axios.post(navigationsSearchPath, decamelizeKeys({ ...filter, pagination })).then((res) => {
    const itemAlreadyExist = res.data.data && res.data.data.length > 0;

    dispatch({
      type: PREVIEW_NAVIGATION_FETCH_SEARCH_LIST_BY_NAME_FINISHED,
      payload: { itemAlreadyExist },
    });
    if (cb) cb(itemAlreadyExist);
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: PREVIEW_NAVIGATION_FETCH_SEARCH_LIST_BY_NAME_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  });
};

export const clearFromPreviewNavigationState = payload => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};
