export const productPriceFieldValidations = {
  finalPrice: [{
    type: 'priceValidation',
    message: 'merhcant.container.edit.price.finalPriceIsValid',
  }],
  ils: [{
    type: 'priceValidation',
    message: 'merhcant.container.edit.price.publishPriceIsValid',
  }],
};
