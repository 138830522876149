import axios from 'axios';
import {
  rankingsSearchPath,
} from '../../util/paths';

import { getRankingByNameQuery } from './queries/ranking';
import { showErrorNotification } from '../../util/api';

export const getRankingByName = async (rankingName) => {
  try {
    const result = await axios.post(rankingsSearchPath, getRankingByNameQuery(
      rankingName,
    ));
    return result.data.data[0];
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export default {
  getRankingByName,
};
