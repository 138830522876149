import { createSelector } from 'reselect';

const productRootSelector = state => state;

const stateSelector = createSelector(
  productRootSelector,
  state => ({
    productComments: state.productComments,
    fetchProductCommentsStart: state.fetchProductCommentsStart,
    edit: state.edit,
    item: state.item,
    pimOptions: state.pimOptions,
  }),
);

export const productSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  productSelector,
};
