/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  memo,
} from 'react';
import {
  shape,
} from 'prop-types';

import SortableTableList from './SortableTableList';

const DragAndDropList = memo((props) => {
  const { fromRules, listProps } = props;

  const onSortEnd = () => {};

  const handleAllProductsSelect = () => listProps.onAllProductsSelect(listProps.items);
  const allProductsAreChecked = listProps.items
    .every(item => listProps.selectedProducts
      .some(p => p.id === item.id));
  return (
    <SortableTableList
      axis="y"
      useDragHandle
      onSortEnd={onSortEnd}
      sortableItemProps={{
        productList: listProps.items,
        defaultImage: listProps.defaultImage,
        attributes: listProps.attributes,
        disabled: listProps.disabled,
        onProductSelect: listProps.onProductSelect,
        onAllProductsSelect: listProps.onAllProductsSelect,
        selectedProducts: listProps.selectedProducts,
        handleExpandParentLevel: listProps.handleExpandParentLevel,
        certainProductAttributes: listProps.certainProductAttributes,
        onEditClick: listProps.onEditClick,
        productsCommentsCount: listProps.productsCommentsCount,
        fetchActivityLogsByProductId: listProps.fetchActivityLogsByProductId,
        productComments: listProps.productComments,
        productCommentsLoading: listProps.productCommentsLoading,
        pagination: listProps.pagination,
        searchFields: listProps.searchFields,
        onUnlockAttributesClick: listProps.onUnlockAttributesClick,
        isEditMode: listProps.isEditMode,
        fetchParentLevelChildrenProductsStarted: listProps.fetchParentLevelChildrenProductsStarted,
        lastParentChildrenLoaded: listProps.lastParentChildrenLoaded,
        isCustomFields: listProps.isCustomFields,
        onHeaderCellClick: listProps.onHeaderCellClick,
        productsPhysicalCategoryList: listProps.productsPhysicalCategoryList,
        fetchPimProductPhysicalCategoriesByIds: listProps.fetchPimProductPhysicalCategoriesByIds,
        productsPhysicalCategoryListFetching: listProps.productsPhysicalCategoryListFetching,
        handleAllProductsSelect,
        allProductsAreChecked,
        attributeSets: listProps.attributeSets,
        fetchProductAttributeSets: listProps.fetchProductAttributeSets,
        commentMessages: listProps.commentMessages,
        bulkDeleteProductLockedAttributesFinished:
          listProps.bulkDeleteProductLockedAttributesFinished,
        bulkDeleteProductLockedAttributesStarted:
          listProps.bulkDeleteProductLockedAttributesStarted,
        multipleUpdateUserActivityLogs: listProps.multipleUpdateUserActivityLogs,
        initialAttributesOptions: listProps.initialAttributesOptions,
        currency: listProps.currency,
        fromRules: fromRules,
      }}
    />
  );
});

DragAndDropList.propTypes = {
  listProps: shape().isRequired,
};

DragAndDropList.defaultProps = {};

export default DragAndDropList;
