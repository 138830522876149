/* eslint-disable no-nested-ternary */
import {
  decamelize, camelize, camelizeKeys, decamelizeKeys,
} from 'humps';
import { isEmpty, uniq } from 'lodash';
import RestActions from '../util/rest/actions';
import { showErrorNotification } from '../util/api';

import {
  searchProductAttributesOptions,
} from '../services/API/productAttribute';

import {
  COMBINED_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START,
  COMBINED_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
  HIERARCHICAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START,
  HIERARCHICAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
} from './types';

const categoryFacetActions = new RestActions('category_facet');

const getParams = (options, codes) => {
  const fiteredOptions = options.filter(o => o.startsWith('optn_'));
  const group = [{
    field: 'option_code',
    operator: 'in',
    value: uniq(fiteredOptions.flat()),
  }, {
    field: 'attribute_code',
    operator: 'in',
    value: uniq(codes.flat()),
  }];

  const params = {
    filter: [{
      condition: 'and',
      group,
    }],
    fields: [
      'attribute_code',
      'option_code',
      'label',
    ],
    pagination: { page: 1, limit: fiteredOptions.length },
  };

  return params;
};

const getMin = arr => Math.min.apply(null, arr.filter(Boolean));
const getMax = arr => Math.max.apply(null, arr.filter(Boolean));

const getFacetLabel = (attribute, attributesMatrix) => {
  const isDefaultAttribute = attributesMatrix.find(am => am.field === attribute);
  const defaultAttributeLabel = isDefaultAttribute ? isDefaultAttribute.label : '';

  return isDefaultAttribute
    ? defaultAttributeLabel
    : decamelize(camelize(attribute)).replace('_', ' ');
};

const getFacetOptions = ({
  attribute, aggregation, aggregationValues,
}) => {
  const facetOptionsObject = aggregation[attribute];
  const facetOptions = Object.keys(facetOptionsObject).map(key => ({
    key,
    value: key,
    label: aggregationValues[key],
    count: aggregation[attribute][key],
  }));

  return facetOptions;
};

const getCombinedFacets = (allGroupedFacets, aggregation) => {
  const combinedFacets = allGroupedFacets.facetsList
    ? Object.keys(allGroupedFacets.facetsList).reduce((acc, current) => {
      const currentDecamelizedItem = decamelize(current);
      const facetData = allGroupedFacets.facetsList[current];
      const currentItem = allGroupedFacets.groupedValues && allGroupedFacets.groupedValues[current]
        ? allGroupedFacets.groupedValues[current]
        : {};
      const item = {
        ...currentItem,
        groups: !isEmpty(currentItem) ? currentItem.groups.map((g, index) => ({
          ...g,
          id: index,
          values: g.values.flat()
            .filter(v => aggregation[currentDecamelizedItem]
              && aggregation[currentDecamelizedItem][v]),
        })).filter(g => !isEmpty(g.values)) : [],
        key: current,
        facetData: {
          ...facetData,
          attributes: facetData.attributes,
        },
      };

      if (
        item
        && item.type === 'combined_values'
        && aggregation[currentDecamelizedItem]
        && !isEmpty(item.groups)
      ) acc.push(item);
      return acc;
    }, []) : [];
  return combinedFacets;
};

const getHierarchyFacets = (allGroupedFacets, aggregation) => {
  const hierarchyFacets = allGroupedFacets.facetsList
    ? Object.keys(allGroupedFacets.facetsList).reduce((acc, current) => {
      const currentDecamelizedItem = decamelize(current);
      const facetData = allGroupedFacets.facetsList[current];
      const currentItem = allGroupedFacets.groupedValues && allGroupedFacets.groupedValues[current]
        ? allGroupedFacets.groupedValues[current]
        : {};
      const item = {
        ...currentItem,
        groups: !isEmpty(currentItem) ? currentItem.groups.map((g, index) => ({
          ...g,
          id: index,
          values: g.values.flat()
            .filter(v => aggregation[currentDecamelizedItem]
              && aggregation[currentDecamelizedItem][v]),
        })).filter(g => !isEmpty(g.values)) : [],
        key: current,
        facetData: {
          ...facetData,
          attributes: facetData.attributes,
        },
      };

      if (
        item
        && item.type === 'hierarchy'
        && aggregation[currentDecamelizedItem]
        && !isEmpty(item.groups)
      ) acc.push(item);
      return acc;
    }, []) : [];
  return hierarchyFacets;
};

const getRegularFacets = (allGroupedFacets, aggregation, aggregationValues) => {
  const decamelizedFacetsList = decamelizeKeys(allGroupedFacets.facetsList);
  const regularFacets = Object.keys(decamelizedFacetsList)
    .filter(fKey => aggregation[fKey]
      && (!allGroupedFacets.groupedValues || !allGroupedFacets.groupedValues[camelize(fKey)]))
    .map(fKey => ({
      ...decamelizedFacetsList[fKey],
      label: decamelizedFacetsList[fKey].label.en,
      options: Object.keys(aggregation[fKey]).map(aggr => ({
        id: aggr,
        key: aggr,
        value: aggr,
        label: aggregationValues[aggr],
      })),
      key: fKey,
      inputType: 'checkbox',
    }));
  return regularFacets;
};

const mapProductFacets = ({
  aggregation,
  aggregationValues,
  attributesMatrix,
}) => {
  if (!isEmpty(aggregation)) {
    return Object.keys(aggregation).map((aKey) => {
      const attr = aKey.startsWith('variation')
        ? aKey.split('.').pop()
        : aKey.replace('display_', '');

      const facetLabel = getFacetLabel(attr, attributesMatrix);
      const facetOptions = getFacetOptions({ attribute: aKey, aggregation, aggregationValues });

      const facet = {
        id: aKey,
        key: attr,
        prefix: aKey.startsWith('variation')
          ? aKey.split('.').filter(k => k !== attr).join('.')
          : 'variation.facets',
        suffix: '',
        name: attr,
        label: facetLabel,
        options: facetOptions,
      };

      let minValue = getMin(Object.keys(aggregation[aKey]).map(keyPrice => Number(keyPrice)));
      let maxValue = getMax(Object.keys(aggregation[aKey]).map(keyPrice => Number(keyPrice))) + 50;

      if (Object.keys(aggregation[aKey]).length === 1 && aggregation[aKey][0]) {
        minValue = 0;
        maxValue = 100;
      }

      switch (aKey) {
        case 'variation.price.final_price':
          return ({
            ...facet,
            inputType: 'slider',
            minValue,
            maxValue,
          });

        default:
          return ({
            ...facet,
            inputType: 'checkbox',
          });
      }
    });
  }
  return [];
};

export const fetchCategoryFacetList = ({
  aggregation = {},
  aggregationValues = {},
  virtualFacet = {},
}, {
  isAttributesMatrix = false,
}) => (dispatch, getState) => {
  dispatch(categoryFacetActions.fetchAllStart());

  const aggsList = [];
  Object.keys(aggregation).forEach((k) => {
    if (k.includes('display_ao_')) {
      aggsList.push(k.replace('display_', ''));
    }
  });
  const { list: attributesMatrix } = getState().attributesMatrix;
  const mappedFacetsList = isAttributesMatrix ? mapProductFacets({
    aggregation,
    aggregationValues,
    attributesMatrix,
    virtualFacet,
  }) : [];


  const virtualFacetResponse = camelizeKeys(virtualFacet);
  const combinedFacets = getCombinedFacets(virtualFacetResponse, aggregation);
  const hierarchicalFacets = getHierarchyFacets(virtualFacetResponse, aggregation);
  const regularFacets = getRegularFacets(virtualFacetResponse, aggregation, aggregationValues);

  dispatch(categoryFacetActions.fetchAllFinished({
    list: mappedFacetsList,
    combinedFacets,
    hierarchicalFacets,
    regularFacets,
  }));
};

export const clearFromCategoryFacetState = payload => (dispatch) => {
  dispatch(categoryFacetActions.clearFromStateFinished(payload));
};

export const fetchCombinedFacetsOptions = (codes, options) => async (dispatch) => {
  const params = getParams(options, codes);

  dispatch({
    type: COMBINED_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START,
    payload: {
      fetchInitialAttributesOptionsStart: true,
      fetchInitialAttributesOptionsFinished: false,
    },
  });

  try {
    const result = await searchProductAttributesOptions(decamelizeKeys(params));
    const initialCombinedFacetsOptions = camelizeKeys(result);

    dispatch({
      type: COMBINED_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
      payload: {
        fetchInitialAttributesOptionsStart: false,
        fetchInitialAttributesOptionsFinished: true,
        initialCombinedFacetsOptions,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: COMBINED_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  }
};

export const fetchHierarchicalFacetsOptions = (codes, options) => async (dispatch) => {
  const params = getParams(options, codes);

  dispatch({
    type: HIERARCHICAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START,
    payload: {
      fetchInitialAttributesOptionsStart: true,
      fetchInitialAttributesOptionsFinished: false,
    },
  });

  try {
    const result = await searchProductAttributesOptions(decamelizeKeys(params));
    const initialHierarchicalFacetsOptions = camelizeKeys(result);

    dispatch({
      type: HIERARCHICAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
      payload: {
        fetchInitialAttributesOptionsStart: false,
        fetchInitialAttributesOptionsFinished: true,
        initialHierarchicalFacetsOptions,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: HIERARCHICAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  }
};
