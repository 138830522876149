import React, { Component, Fragment } from 'react';
import {
  func, oneOfType, arrayOf, node, shape,
} from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import { Snackbar, IconButton } from '@material-ui/core';

import Button from '../Button';

import { setAPIError } from '../../actions/error';
import { rootAppPath } from '../../util/paths';

class ErrorBoundary extends Component {
  static propTypes = {
    error: shape().isRequired,
    setAPIError: func.isRequired,
    children: oneOfType([
      arrayOf(node),
      node,
    ]),
  }

  static defaultProps = {
    children: [],
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.setInterceptors();
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      title: error.message,
      details: error.stack,
    };
  }

  reloadPage = () => document.location.reload();

  onHomePageClick = () => document.location.replace(rootAppPath);

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };

  setInterceptors = () => {
    const statuses = [400, 403, 500, 503];
    axios.interceptors.response.use(response => response, (error) => {
      let errorBody = {};
      if (error.response) {
        errorBody = {
          hasError: true,
          title: error.response.statusText,
          statusCode: error.response.status,
          message: error.response.data.error.message,
          extraDetails: {
            class: error.response.data.error.class || '',
            message: error.message || '',
            moreIinfo: error.response.data.error.more_info || '',
            responseURL: error.request.responseURL || '',
            stack: error.stack,
          },
          error,
        };
      } else {
        errorBody = {
          hasError: true,
          title: error.message,
          statusCode: '503',
          message: 'Service Unavailable',
          error,
        };
      }
      this.setState({
        open: true,
      });
      if (statuses.some(status => error.response && status === error.response.status)) {
        this.props.setAPIError({ errors: errorBody });
      }
      return Promise.reject(error);
    });
  }

  render() {
    const { open } = this.state;
    const { error } = this.props;
    let snackBar = null;
    if (error && error.errors.hasError) {
      const { title, statusCode, message } = error.errors;
      snackBar = (
        <Fragment>
          <Snackbar
            className="snackbar-main-wrapper"
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            open={open}
            autoHideDuration={600000}
            onClose={this.handleClose}
            message={(
              <div className="snackbar-wrapper">
                <div className="flex">
                  <i className="ti-alert text-lg text-danger mr-15" />
                  <div className="snackbar-right">
                    <span className="h5">{`${title} - ${statusCode}`}</span>
                    <p className="mb-0">{message}</p>
                    {error.errors.extraDetails && (
                      <details className="mt-10">
                        <summary>See More</summary>
                        <div className="details-row">
                          <b>class:</b>
                          {error.errors.extraDetails.class}
                        </div>
                        <div className="details-row">
                          <b>message:</b>
                          {error.errors.extraDetails.message}
                        </div>
                        <div className="details-row">
                          <b>more info:</b>
                          {error.errors.extraDetails.moreIinfo}
                        </div>
                        <div className="details-row">
                          <b>response url:</b>
                          {error.errors.extraDetails.responseURL}
                        </div>
                        <div className="details-row">
                          <b>stack:</b>
                          {error.errors.extraDetails.stack}
                        </div>
                      </details>
                    )}
                  </div>
                </div>
              </div>
            )}
            action={(
              <Fragment>
                <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleClose}>
                  <i className="zmdi zmdi-close" />
                </IconButton>
                <div className="pos-abs actions-abs flex block">
                  <Button
                    settings={{
                      value: 'Go home',
                      type: 'primary',
                      onClick: this.onHomePageClick,
                    }}
                  />
                  <Button
                    settings={{
                      value: 'Refresh page',
                      type: 'primary',
                      onClick: this.reloadPage,
                    }}
                  />
                </div>
              </Fragment>
            )}
          />
        </Fragment>
      );
    }

    return (
      <Fragment>
        {error.errors.hasError && snackBar}
        {!error.errors.hasError && this.props.children}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  error,
}) => ({
  error,
});

const actionCreators = {
  setAPIError,
};

export default connect(mapStateToProps, actionCreators)(ErrorBoundary);
