import React, { memo } from 'react';
import {
  func, shape, node, string, arrayOf,
} from 'prop-types';

import Form from '../../../../../components/Form';

const PopperActionsForm = memo(({
  content,
  comment,
  systemComment,
  onSystemCommentChange,
  onCommentChange,
  errors,
  commentMessagesOptions,
}) => (
  <div className="popper-actions popper-actions-footer flex items-center">
    <Form
      className="form-compact flex-1"
      gap={0}
      onSubmit={null}
      fields={[[{
        element: 'text',
        label: 'Comment',
        onChange: onCommentChange,
        value: comment,
        hideStaticLabel: true,
        className: 'input',
        name: 'comment',
        wrapperClassName: 'flex-wrap',
        errors,
        hideFormErrors: true,
        groupclass: 'mb-10',
      }, {
        element: 'select',
        label: 'System comment',
        onChange: onSystemCommentChange,
        options: commentMessagesOptions,
        value: systemComment,
        hideStaticLabel: true,
        className: 'block',
        name: 'systemComment',
        wrapperClassName: 'flex-wrap',
        errors,
        hideFormErrors: true,
        groupclass: 'mb-10',
      }]]}
    />
    {content}
  </div>
));

PopperActionsForm.propTypes = {
  content: node,
  onCommentChange: func.isRequired,
  comment: string,
  errors: shape({}),
  systemComment: string,
  commentMessagesOptions: arrayOf(shape({
    value: string,
    title: string,
  })),
  onSystemCommentChange: func.isRequired,
};

PopperActionsForm.defaultProps = {
  content: null,
  comment: '',
  systemComment: '',
  errors: {},
  commentMessagesOptions: [],
};

export default PopperActionsForm;
