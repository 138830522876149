import React from "react";
import { connect } from 'react-redux';

import { Box, IconButton, Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { initialReg } from "util/changeReg";
import {setPriceFormulaModal} from 'actions/pim.js'

import classes from './styles.module.scss';
import IntlMessages from "../../util/IntlMessages";

const ProductPriceModal = ({pim, setPriceFormulaModal}) => {
    const {isOpen, priceFormula} = pim.priceFormulaModal;

    const handleClose = () => {
        setPriceFormulaModal({isOpen: false, priceFormula: {}, loading: false})
    }

    const updatePriceFormula = (price) => {
        let currentPrice = []
        for (let variable in price) {
            currentPrice.push([initialReg(variable), price[variable]])
        }    
        return currentPrice;
    }


    return (
        <Dialog
            className={`product-dialog`}
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
              maxWidth={"lg"}
            >
            <DialogTitle disableTypography className={`dialog-title flex justify-between `}>
                <Box  className={classes.closeButton}>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <div className={classes.productPrice}> 
                    {priceFormula && priceFormula.cdms && (
                        <div className={classes.section}>
                            <span className={classes.title}>CDMS</span>
                            {updatePriceFormula(priceFormula.cdms).map((item, idx) => (<div className={classes.row} key={`${idx}_${item[0]}`}><span>{item[0]}</span> &nbsp;=&nbsp; <span>{item[1]}</span></div>))}
                        </div>
                    )}
                    {priceFormula && priceFormula.ims && (
                        <div className={classes.section}>
                            <span className={classes.title}>IMS</span>
                            {updatePriceFormula(priceFormula.ims).map((item, idx) => (<div className={classes.row} key={`${idx}_${item[0]}`}><span>{item[0]}</span> &nbsp;=&nbsp; <span>{item[1]}</span></div>))}
                        </div>
                    )}
                    {!priceFormula && (
                      <div className={classes.section}>
                          <IntlMessages id="no_data_about_price" />
                      </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    )
} 



const mapStateToProps = state => ({pim: state.pim});

const mapDispatchToProps = {
    setPriceFormulaModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceModal);
