import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const categoryListSelector = state => state.treeItems;
const categoryItemSelector = state => state.item;
const categoryRootSelector = state => state;

const mapProductsOverride = (virtualParams) => {
  if (!virtualParams) return {};
  return ({
    ...virtualParams,
    productsOverride: virtualParams.positions
      ? Object.keys(virtualParams.positions)
        .map(p => ({ productId: p, position: virtualParams.positions[p] }))
      : [],
  });
};

const listSelector = createSelector(
  categoryListSelector,
  treeItems => (!isEmpty(treeItems) ? treeItems.reduce((acc, current) => {
    acc.push({
      ...current,
      virtualParams: mapProductsOverride(current.virtualParams),
    });
    return acc;
  }, []) : []),
);

const itemSelector = createSelector(
  categoryItemSelector,
  item => (!isEmpty(item) ? ({
    ...item,
    virtualParams: mapProductsOverride(item.virtualParams),
  }) : {}),
);

export const virtualCategorySelector = createSelector(
  listSelector,
  itemSelector,
  categoryRootSelector,
  (treeItems, item, state) => ({
    treeItems,
    item,
    updated: state.updated,
  }),
);

export default {
  virtualCategorySelector,
};
