import React from 'react';
import {
  string, node, shape,
} from 'prop-types';

const ContextMenu = (props) => {
  const {
    className, content, contextStyles, refData,
  } = props;

  const contextTop = Number(contextStyles.top.substring(0, contextStyles.top.indexOf('p')));
  const contextFullHeight = contextTop + 246;
  const pageHeight = document.body.offsetHeight;

  if (contextFullHeight > pageHeight) {
    const contextNewTop = (contextTop - (246 / 2) - 50);
    contextStyles.top = `${contextNewTop}px`;
  }

  return (
    <div ref={refData} className={`context-menu-wrapper ${className}`} style={contextStyles}>
      {content}
    </div>
  );
};

ContextMenu.propTypes = {
  className: string,
  content: node,
  contextStyles: shape(),
};

ContextMenu.defaultProps = {
  className: '',
  content: null,
  contextStyles: {},
};

export default ContextMenu;
