import React, { useState } from 'react';
import { camelize, decamelize } from 'humps';
import { cloneDeep } from 'lodash';
import {
  shape, arrayOf, bool, func, string,
} from 'prop-types';
import {
  Grid, Card, CardMedia, CardContent, CardActions,
  Button, Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines } from '@fortawesome/free-solid-svg-icons';
import { sortableHandle } from 'react-sortable-hoc';
import { connect } from 'react-redux';

import config from '../../../../util/config';
import UnlockAttributesDialog from '../UnlockAttributesDialog';
import IntlMessages from '../../../../util/IntlMessages';
import Checkbox from '../../../../components/Checkbox';
import ShowMore from '../ShowMore';
import { setMerchantActivityDialog } from '../../../../actions/pim';

import OpenProductPriceModal from "components/ProductPrice/OpenProductPriceModal"
import MergeProductsDialog from "../MergeProductsDialog";
import FormDialog from "../../../../components/FormDialog";
import ProductActionsDialog from "../ProductActionsDialog";
import { bulkUpdateProductStatus } from "../../../../actions/product";

const MerchantLevelItem = (props) => {
  const {
    onProductSelect, merchantValue, onEditClick, defaultImage,
    selectedProducts, disabled, merchantProductItem, isEditMode,
    commentMessages, multipleUpdateUserActivityLogs,
    fetchActivityLogsByProductId, disableEdit, onUnlockAttributesClick,
    bulkDeleteProductLockedAttributesStarted, bulkDeleteProductLockedAttributesFinished,
  } = props;

  const [isOpenedUnlockDialog, setIsOpenUnlockDialog] = useState(false);
  const [isOpenedActionsPicker, setIsOpenedActionsPicker] = useState(false);
  const [isOpenedMergeDialog, setIsOpenMergeDialog] = useState(false);
  const [isOpenedProductActions, setIsOpenedProductActions] = useState(false);
  const [productActionType, setProductActionType] = useState(null);
  const [isOpenedSetInStockDialog, setIsOpenSetInStockDialog] = useState(false);
  const [isOpenedSetOOSDialog, setIsOpenSetOOSDialog] = useState(false);

  const DragHandle = sortableHandle(() => <FontAwesomeIcon size="lg" icon={faGripLines} />);

  const temporaryDisabled = true;
  const isReviewed = merchantProductItem.pimStatus && merchantProductItem.pimStatus.status === 'reviewed';

  window.addEventListener('click', () => {
    setIsOpenedActionsPicker(false);
    const merchantLevelDiv = document.getElementById('merchant-level-' + merchantProductItem.id);
    if (merchantLevelDiv) {
      merchantLevelDiv.style.height = '100%';
    }
  });

  const showActionsPicker = () => {
    const merchantLevelDiv = document.getElementById('merchant-level-' + merchantProductItem.id);
    if (isOpenedActionsPicker === false) {
      setTimeout(() => {
        setIsOpenedActionsPicker(true);
        if (merchantLevelDiv) {
          merchantLevelDiv.style.height = '300px';
        }
      }, 100);
    } else {
      setIsOpenedActionsPicker(false);
      if (merchantLevelDiv) {
        merchantLevelDiv.style.height = '100%';
      }
    }
  }

  const renderValue = (itemKey, itemValue, urlKey) => {
    if (itemKey === 'id' && urlKey) {
      const linkToProduct = `${config.websitePath}/${urlKey.en}/${itemValue}`;

      return (<a href={linkToProduct} target="_blank" rel="noopener noreferrer">{itemValue}</a>);
    }

    if (typeof itemValue === 'string' && itemValue.length > 60) {
      return `${itemValue.substring(0, 60)}...`;
    }

    itemValue = (itemValue && itemValue === '-') ? 'N/A' : itemValue;

    return itemValue;
  };

  const handleMerchantEditClick = e => onEditClick({ e, productItem: merchantProductItem });
  const imgUrl = merchantValue.imgData && merchantValue.imgData.url
    ? merchantValue.imgData.url
    : defaultImage;

  const fetchComments = () => {
    props.setMerchantActivityDialog(true);
    fetchActivityLogsByProductId(merchantProductItem.id);
  };

  const handleProductSelect = () => {
    onProductSelect(cloneDeep(merchantProductItem));
  };

  const handleCloseUnlockAttributesDialog = () => {
    setIsOpenUnlockDialog(false);
  };

  const handleUnlockAttributes = () => {
    onUnlockAttributesClick({ productItem: merchantProductItem });
  };

  const handleCloseMergeProductsDialog = () => {
    setIsOpenMergeDialog(false);
  };

  const handleCloseProductActionsDialog = () => {
    setIsOpenedProductActions(false);
  };

  const handleCloseSetInStockDialog = () => {
    setIsOpenSetInStockDialog(false);
  };

  const submitSetInStock = () => {
    props.bulkUpdateProductStatus(merchantProductItem.id, 'in_stock');
    setIsOpenSetInStockDialog(false);
  };

  const handleCloseSetOOSDialog = () => {
    setIsOpenSetOOSDialog(false);
  };

  const submitOOS = () => {
    props.bulkUpdateProductStatus(merchantProductItem.id, 'out_of_stock');
    setIsOpenSetOOSDialog(false);
  };


  const handleMergeProductsClick = () => {
    setIsOpenMergeDialog(true);
  };

  const handleSetInStock = () => {
    setIsOpenSetInStockDialog(true);
  };

  const handleSetOOS = () => {
    setIsOpenSetOOSDialog(true);
  };

  const handleRemoveProduct = () => {
    setProductActionType('remove');
    setIsOpenedProductActions(true);
  };

  const handleRestoreProduct = () => {
    setProductActionType('restore');
    setIsOpenedProductActions(true);
  };

  const handleMarkAsReviewed = () => {
    setProductActionType('markAsReviewed');
    setIsOpenedProductActions(true);
  };

  const handleMarkAsNotReviewed = () => {
    setProductActionType('markAsNotReviewed');
    setIsOpenedProductActions(true);
  };

  const ActionsPicker = ({type, productItem}) => {
    return (
      <div className="product-actions-picker">
        <div
          className="product-actions-picker-option"
          onClick={handleMerchantEditClick}
        >
          <IntlMessages id="preview.table.product.button.edit.label" />
        </div>

        {type === 'parent' && (
          <div
            className="product-actions-picker-option"
            onClick={handleSetInStock}
          >
            <IntlMessages id="pim.table.product.button.setInStock" />
          </div>
        )}
        {type === 'parent' && (
          <div
            className="product-actions-picker-option"
            onClick={handleSetOOS}
          >
            <IntlMessages id="pim.table.product.button.setOOS" />
          </div>
        )}
        {type !== 'parent' && (
          <div
            className="product-actions-picker-option"
            onClick={handleMergeProductsClick}
          >
            <IntlMessages id="pim.table.product.button.mergeProducts" />
          </div>
        )}
        <div
          className="product-actions-picker-option"
          onClick={fetchComments}
        >
          <IntlMessages id="pim.table.product.button.history" />
        </div>
        <div
          className="product-actions-picker-option"
          onClick={handleRemoveProduct}
        >
          <IntlMessages id="pim.table.product.button.remove" />
        </div>
        <div
          className="product-actions-picker-option"
          onClick={handleRestoreProduct}
        >
          <IntlMessages id="pim.table.product.button.restore" />
        </div>
        <div
          className="product-actions-picker-option"
          onClick={handleMarkAsReviewed}
        >
          <IntlMessages id="pim.table.product.button.markAsReviewed" />
        </div>
        <div
          className="product-actions-picker-option"
          onClick={handleMarkAsNotReviewed}
        >
          <IntlMessages id="pim.table.product.button.markAsNotReviewed" />
        </div>
        <div
          className="product-actions-picker-option"
          onClick={handleUnlockAttributes}
        >
          <IntlMessages id="pim.table.product.button.unlockAttributes" />
        </div>
      </div>
    );
  };

  return (
    <Grid item xs={12} className={props.className}>
      <div id={'merchant-level-' + merchantProductItem.id} className="product-item product-item-child flex items-center pr-20 pl-20">
        <div className={`product-item-type ${merchantProductItem.type}`}>
          <span>{merchantProductItem.type}</span>
        </div>
        <Card className="product-item-card-list without-dnd">
          <CardActions className="card-collapse-btn pr-0">
            {!disabled && isEditMode && (
              <div>
                <Checkbox
                  checked={selectedProducts.some(p => p.id === merchantProductItem.id)}
                  onChange={handleProductSelect}
                  name={merchantValue.title ? camelize(merchantValue.title) : ''}
                />
              </div>
            )}
          </CardActions>
          <Tooltip
            className="img-tooltip"
            title={(
              <img
                className="product-item-image tooltip-inner"
                src={imgUrl}
                alt=""
              />
            )}
            placement="right"
          >
            <CardMedia
              className="product-item-image"
              style={{marginLeft: '30px', marginRight: '25px'}}
              image={imgUrl}
            />
          </Tooltip>
          <CardContent className="flex full-width ml-0 mr-0">
            <div className="flex items-center full-width item-content pr-20">
              {
                  Object.keys(merchantValue).filter(
                    vKey => (
                      typeof merchantValue[vKey] === 'string' || typeof merchantValue[vKey] === 'number'
                    ),
                  ).map((itemKey) => {
                    const val = merchantValue[itemKey];
                    const itemValueLength = val ? val.toString().length : 0;
                    const title = decamelize(itemKey).replace('_', ' ').replace('_', ' ').replace('_', ' ');
                    const tinyClass = itemValueLength <= 25 ? 'tiny' : '';
                    return (itemKey === "price" && merchantProductItem.priceFormula ? 
                    <OpenProductPriceModal priceFormula={merchantProductItem.priceFormula}>
                      <div
                        className={`flex direction-column pr-20 pl-20 product-item-data ${tinyClass}`}
                        key={itemKey}
                      >
                        <div className="flex">
                          <span className="key capitalize">{title}</span>
                          {itemValueLength
                            && itemValueLength > 25
                            && !isEditMode
                            ? (
                              <ShowMore
                                attr={{ key: title }}
                                itemValue={val}
                              />
                            )
                            : null}
                        </div>
                        <span className={`value value-${itemKey}`}>
                          {renderValue(itemKey, val, props.urlKey)}
                        </span>
                      </div>
                    </OpenProductPriceModal> :
                      <div
                        className={`flex direction-column pr-20 pl-20 product-item-data ${tinyClass}`}
                        key={itemKey}
                      >
                        <div className="flex">
                          <span className="key capitalize">{title}</span>
                          {itemValueLength
                            && itemValueLength > 25
                            && !isEditMode
                            ? (
                              <ShowMore
                                attr={{ key: title }}
                                itemValue={val}
                              />
                            )
                            : null}
                        </div>
                        <span className={`value value-${itemKey}`}>
                          {renderValue(itemKey, val, props.urlKey)}
                        </span>
                      </div>
                    );
                  })
                }
            </div>
            <div className="flex edit-icon-wrapper items-center">
              {!disabled && !disableEdit && (
                <div className="edit-icon">
                  <Button
                    color="primary"
                    className="btn-xs extra float-right"
                    onClick={showActionsPicker}
                  >
                    <IntlMessages id="title.actions" />
                  </Button>
                </div>
              )}

              {isReviewed && (
                <Tooltip title={`by ${merchantProductItem.pimStatus.reviewedBy}`} placement="top">
                  <span className="badge-success badge-xs badge-rel badge-text secondary">
                    <IntlMessages id="pim.table.product.badge.reviewed.label" />
                  </span>
                </Tooltip>
              )}

            </div>

            {isOpenedActionsPicker && (
              <ActionsPicker type={merchantProductItem.type} productItem={merchantProductItem} />
            )}

            {!disabled && !temporaryDisabled && (
              <div className="flex ml-auto dnd-icon items-center">
                <DragHandle />
              </div>
            )}
          </CardContent>
        </Card>
      </div>
      <UnlockAttributesDialog
        key={`unlock-dialog-${merchantProductItem.id}`}
        isOpened={isOpenedUnlockDialog}
        onClose={handleCloseUnlockAttributesDialog}
        onUnlockAttributesClick={handleUnlockAttributes}
        commentMessages={commentMessages}
        bulkDeleteProductLockedAttributesStarted={bulkDeleteProductLockedAttributesStarted}
        bulkDeleteProductLockedAttributesFinished={bulkDeleteProductLockedAttributesFinished}
        onCommentsUpdate={multipleUpdateUserActivityLogs}
        product={merchantProductItem}
      />
      <MergeProductsDialog
        key={`merge-dialog-${merchantProductItem.id}`}
        isOpened={isOpenedMergeDialog}
        onClose={handleCloseMergeProductsDialog}
        onMergeProductsClick={handleMergeProductsClick}
        commentMessages={commentMessages}
        onCommentsUpdate={merchantProductItem.multipleUpdateUserActivityLogs}
        product={merchantProductItem.variantProductItem}
      />
      <FormDialog
        title={<IntlMessages id="pim.table.product.badge.setInStock.label" />}
        open={isOpenedSetInStockDialog}
        hideCloseButton={false}
        className="dialog-inputs-custom dialog-content-visible"
        closeButtonTitle="Close"
        submitButtonTitle="Submit"
        maxWidth="xs"
        onClose={handleCloseSetInStockDialog}
        onSubmit={submitSetInStock}
      >
        <IntlMessages id="alert.sureTitle" />
      </FormDialog>
      <FormDialog
        title={<IntlMessages id="pim.table.product.badge.setOOS.label" />}
        open={isOpenedSetOOSDialog}
        hideCloseButton={false}
        className="dialog-inputs-custom dialog-content-visible"
        closeButtonTitle="Close"
        submitButtonTitle="Submit"
        maxWidth="xs"
        onClose={handleCloseSetOOSDialog}
        onSubmit={submitOOS}
      >
        <IntlMessages id="alert.sureTitle" />
      </FormDialog>
      <ProductActionsDialog
        key={`product-actions-dialog-${merchantProductItem.id}`}
        type={productActionType}
        isOpened={isOpenedProductActions}
        onClose={handleCloseProductActionsDialog}
        commentMessages={commentMessages}
        product={merchantProductItem}
      />
    </Grid>
  );
};

MerchantLevelItem.propTypes = {
  commentMessages: arrayOf(shape()).isRequired,
  selectedProducts: arrayOf(shape()),
  merchantValue: shape().isRequired,
  merchantProductItem: shape().isRequired,
  productComments: arrayOf(shape({})).isRequired,
  disabled: bool,
  onProductSelect: func.isRequired,
  onEditClick: func.isRequired,
  defaultImage: string.isRequired,
  isEditMode: bool.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  multipleUpdateUserActivityLogs: func.isRequired,
  hasMerchantComments: bool,
  productCommentsLoading: bool,
  onUnlockAttributesClick: func.isRequired,
  disableEdit: bool,
  bulkDeleteProductLockedAttributesStarted: bool.isRequired,
  bulkDeleteProductLockedAttributesFinished: bool.isRequired,
  setMerchantActivityDialog: func.isRequired,
  bulkUpdateProductStatus: func.isRequired,
};

MerchantLevelItem.defaultProps = {
  disabled: false,
  selectedProducts: [],
  hasMerchantComments: false,
  productCommentsLoading: false,
  disableEdit: false,
};

const mapStateToProps = state => (state);

const mapDispatchToProps = {
  setMerchantActivityDialog,
  bulkUpdateProductStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantLevelItem);
