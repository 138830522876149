/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import {
  bool, func,
} from 'prop-types';
import FormDialog from '../../../../components/FormDialog';
import IntlMessages from '../../../../util/IntlMessages';

const UnlockAllAttributesDialog = memo((props) => {
  const {
    onUnlockAttributesClick,
    isOpened,
    onClose,
  } = props;

  const unlockAttributes = () => {
    onUnlockAttributesClick();
  };

  const closeUnlockAttributesDialog = () => {
    onClose();
  };

  return (
    <FormDialog
      title={<IntlMessages id="pim.unlockAllAttributesDialog.title" />}
      open={isOpened}
      hideCloseButton={false}
      className="dialog-inputs-custom dialog-content-visible"
      closeButtonTitle="Close"
      submitButtonTitle="Submit"
      maxWidth="xs"
      onClose={closeUnlockAttributesDialog}
      onSubmit={unlockAttributes}
    >
      &nbsp;
    </FormDialog>
  );
});

UnlockAllAttributesDialog.propTypes = {
  onUnlockAttributesClick: func.isRequired,
  onClose: func.isRequired,
  isOpened: bool.isRequired,
};

UnlockAllAttributesDialog.defaultProps = {};

export default UnlockAllAttributesDialog;
