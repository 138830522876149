import axios from 'axios';
import {
  porductAttributeSetsSearchPath,
  attributesSearchPath,
  productAttributesOptionsSearchPath,
} from '../../util/paths';
import { showErrorNotification } from '../../util/api';

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';

export const searchProductAttributeSets = async (query, urlSearchParams = '') => {
  const refUrlSearchParams = urlSearchParams ? urlSearchParams + '&ref=' + ref : '?ref=' + ref;
  try {
    const result = await axios.post(`${porductAttributeSetsSearchPath}${refUrlSearchParams}`, query);
    return result.data.data;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const searchProductAttributes = async (query, urlSearchParams = '') => {
  const refUrlSearchParams = urlSearchParams ? urlSearchParams + '&ref=' + ref : '?ref=' + ref;
  try {
    const result = await axios.post(`${attributesSearchPath}${refUrlSearchParams}`, query);
    return result.data.data;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const searchProductAttributesOptions = async (query) => {
  try {
    const result = await axios.post(productAttributesOptionsSearchPath + '?ref=' + ref, query);
    return result.data.data;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export default {
  searchProductAttributeSets,
  searchProductAttributes,
  searchProductAttributesOptions,
};
