export const commentFieldValidations = {
  systemComment: [{
    type: 'isRequired',
    message: 'preview.errors.filterItemIsRequired',
  }],
};

export const productValidations = {
  description: [{
    type: 'textFieldIsRequired',
    message: 'preview.errors.fieldIsRequired',
  }],
  title: [{
    type: 'textFieldIsRequired',
    message: 'preview.errors.fieldIsRequired',
  }],
};

const getNameValidations = ({ minSize, maxSize }) => [
  {
    type: 'isRequired',
    message: 'text.helper.cantBeBlank',
  }, {
    type: 'minSize',
    message: `text.errors.moreThan${minSize}Characters`,
    condition: minSize,
  }, {
    type: 'maxSize',
    message: `text.errors.lessThan${maxSize}Characters`,
    condition: maxSize,
  },
];

export const productNameValidations = {
  en: getNameValidations({ minSize: 3, maxSize: 300 }),
  he: [{
    type: 'minSize',
    message: 'text.errors.moreThan3Characters',
    condition: 3,
  }, {
    type: 'maxSize',
    message: 'text.errors.lessThan300Characters',
    condition: 300,
  }],
};

export const validateVideoUrl = (videos) => {
  if (!videos) {
    return [];
  }

  var objRE = /^[a-z]+:\/\//i;

  const videoErrors = [];
  for (let i = 0; i < videos.length; i++) {
    if (!videos[i].url) {
      videoErrors[i] = {
        'url': {'type': 'url', 'message': 'text.helper.cantBeBlank'}
      };
    }
    else if (!objRE.test(videos[i].url)) {
      videoErrors[i] = {
        'url': {'type': 'url', 'message': 'text.helper.notUrl'}
      };
    }

    if (videos[i].previewUrl && !objRE.test(videos[i].previewUrl)) {
      if (videoErrors[i].url) {
        videoErrors[i] = {
          'url': videoErrors[i].url,
          'preview_url': {'type': 'url', 'message': 'text.helper.notUrl'}
        };
      } else {
        videoErrors[i] = {
          'preview_url': {'type': 'url', 'message': 'text.helper.notUrl'}
        };
      }
    }
  }

  return videoErrors;
};
