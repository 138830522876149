import React, { memo, useState } from 'react';
import {
  shape,
} from 'prop-types';
import {
  Chip, Tooltip, Snackbar, IconButton,
} from '@material-ui/core';

const TrackingInfo = memo((props) => {
  const [open, setOpen] = useState(false);
  const {
    trackingInfo,
  } = props;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const copyToClipboard = () => {
    handleOpen();
    navigator.clipboard.writeText(trackingInfo.trackingUrl);
  };


  return (
    <div className="tracking-info flex justify-center items-center">
      <Tooltip title={trackingInfo.offerUrl} placement="top">
        <Chip
          label="Tracking url"
          component="a"
          href={trackingInfo.trackingUrl}
          size="small"
          clickable
          target="_blank"
          rel="noopener"
        />
      </Tooltip>
      <Tooltip title="Copy to clipboard" placement="top">
        <IconButton color="inherit" onClick={copyToClipboard}>
          <i className="ti-files icon-files" />
        </IconButton>
      </Tooltip>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={5000}
        message="Copied to clipboard"
      />
    </div>
  );
});

TrackingInfo.propTypes = {
  trackingInfo: shape().isRequired,
};

export default TrackingInfo;
