/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  func, shape, bool, string,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { Divider, CircularProgress } from '@material-ui/core';

import {
  setNavigationFields,
  clearNavigationFilterState,
  clearProductState,
  clearNavigationItemState,
  clearNavigationSortState,
} from 'actions/navigation';
import { clearNavigationVirtualFacet } from 'actions/navigationVirtualFacet';

import PageTitleBar from 'components/PageTitleBar/PageTitleBar';
import Select from 'components/Select';
import RctCollapsibleCard from 'components/RctCollapsibleCard/RctCollapsibleCard';
import { AbilityContext } from 'components/AbilityContext';

import appPermissions from 'util/appPermissions';
import IntlMessages from 'util/IntlMessages';
import navigationTypes from 'util/constants/navigationTypes';

import Designation from './Designation';
import Filter from './Filter';
import Sort from './Sort';
import Facet from './Facet';
import Actions from './Actions';

const CreateNavigation = memo((props) => {
  const { onClose, errors } = props;
  const abilityContext = useContext(AbilityContext);

  useEffect(() => {
    if (isEmpty(props.match.params.searchFilterId) && props.match.path.includes('new')) {
      props.clearNavigationFilterState();
      props.clearNavigationVirtualFacet();
      props.clearProductState();
      props.clearNavigationItemState();
      props.clearNavigationSortState();
    }
  }, [props.match.params.searchFilterId]);

  const getUrl = ({ prefix = '', value, divider = '_' }) => `${value.includes(prefix)
    ? '' : prefix}${value ? value.toLowerCase().replace(/\s/g, divider)
    : ''}`;

  const handleChangeUrlKey = (e) => {
    const { name, value } = e.target;

    props.setNavigationFields({
      ...props.navigationItem,
      [name]: getUrl({
        value,
        divider: '-',
      }),
    });
  };

  const handleNavigationFieldChange = (e) => {
    const { name, value } = e.target;

    props.setNavigationFields({
      ...props.navigationItem,
      [name]: value,
    });
  };

  const handleSearchPhraseChange = (e) => {
    const { name, value } = e.target;
    const url = value ? value.split(', ') : [];
    props.setNavigationFields({
      ...props.navigationItem,
      [name]: url,
    });
  };

  const getHeaderLine = () => {
    const { name, id } = props.navigationItem;

    if (id && name) {
      return (
        <>
          <span className="separator flex justify-center items-center"> &gt; </span>
          <>
            <span>{name}</span>
            <span className="header-line-id flex justify-center items-center pl-5">{`(${id})`}</span>
          </>
        </>
      );
    }
    return '';
  };

  const isSaving = props.isNavigationSaving
    || props.searchByPhrasesStarting
    || props.navigationUpdating
    || props.navigationCreating
    || props.navigationVirtualFacetCreating;

  const noPermissions = (!abilityContext.can(
    appPermissions.navigation.permissions.update,
    appPermissions.navigation.name,
    appPermissions.navigation.permissions.create,
    appPermissions.navigation.name,
  ));

  return (
    <div className={`drawer-scroll-container scroll-light rct-page-content ${isSaving ? 'disabled' : ''}`}>
      {isSaving && (
        <div className="loader-main flex direction-column">
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className="progress-warning bottom mb-10"
            size={25}
            thickness={4}
          />
        </div>
      )}
      <PageTitleBar
        titleClassName="block"
        match={props.match}
        title={(
          <div className="flex items-center justify-between block">
            <div className="flex">
              <IntlMessages id="pageTitle.navigationEdit" />
              {getHeaderLine()}
            </div>
            <Select
              value={props.navigationItem.type || navigationTypes[0].value}
              options={navigationTypes}
              onChange={handleNavigationFieldChange}
              name="type"
              selectClassName="simple-select select-xs"
              className="select-wrapper select-underline-hide"
              label={<IntlMessages id="text.searchType" />}
              disabled={noPermissions || isSaving}
            />
          </div>
        )}
      />
      <RctCollapsibleCard>
        <Actions type="gray" />
        <Divider className="divider-dashed divider-gap-md" />
        <Designation
          name={props.navigationItem.name}
          searchPhrase={props.navigationItem.searchPhrase}
          urlKey={props.navigationItem.urlKey}
          type={props.navigationItem.type}
          onTextFieldChange={handleNavigationFieldChange}
          onSearchPhraseChange={handleSearchPhraseChange}
          onUrlKeyChange={handleChangeUrlKey}
          errors={errors}
          existedSearchPhrase={props.existedSearchPhrase}
          disabled={false} // TODO
        />
      </RctCollapsibleCard>

      <RctCollapsibleCard className="p-0">
        <Filter viewMode="create" />
      </RctCollapsibleCard>

      <RctCollapsibleCard className="p-0">
        <Sort />
      </RctCollapsibleCard>

      <RctCollapsibleCard className="p-0">
        <Facet viewMode="create" />
      </RctCollapsibleCard>

      <RctCollapsibleCard>
        <Actions gap="0" onClose={onClose} />
      </RctCollapsibleCard>
    </div>
  );
});

CreateNavigation.propTypes = {
  onClose: func,
  match: shape().isRequired,
  errors: shape().isRequired,
  setNavigationFields: func.isRequired,
  existedSearchPhrase: string.isRequired,

  navigationItem: shape().isRequired,

  isNavigationSaving: bool.isRequired,
  navigationUpdating: bool.isRequired,
  navigationCreating: bool.isRequired,
  searchByPhrasesStarting: bool,

  navigationVirtualFacetCreating: bool.isRequired,
  clearNavigationFilterState: func.isRequired,
  clearNavigationVirtualFacet: func.isRequired,
  clearProductState: func.isRequired,
  clearNavigationItemState: func.isRequired,
  clearNavigationSortState: func.isRequired,
};

CreateNavigation.defaultProps = {
  onClose: null,
  searchByPhrasesStarting: false,
};

const mapStateToProps = state => ({
  selectedPromotion: state.promotionWidget.selectedPromotion,
  selectedSearchFilter: state.preview.selectedSearchFilter,

  drawerState: state.quickEditDrawer.drawerState,
  navigationItem: state.navigation.item,
  isNavigationSaving: state.navigation.isNavigationSaving,
  navigationUpdating: state.navigation.updating,
  navigationCreating: state.navigation.creating,
  searchByPhrasesStarting: state.navigation.searchByPhrasesStarting,
  errors: state.navigation.errors,
  existedSearchPhrase: state.navigation.existedSearchPhrase,
  isDataChanged: state.navigation.isDataChanged,

  navigationVirtualFacetCreating: state.navigationVirtualFacet.creating,
});

const mapDispatchToProps = {
  setNavigationFields,
  clearNavigationFilterState,
  clearNavigationVirtualFacet,
  clearProductState,
  clearNavigationItemState,
  clearNavigationSortState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateNavigation));
