/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import { isEmpty, uniq } from 'lodash';
import {
  arrayOf, shape, string, func, bool, number,
  oneOfType, node,
} from 'prop-types';
import { IconButton } from '@material-ui/core';
import { connect } from 'react-redux';

import {
  fetchAttributeOptions,
  fetchCategoriesAttributes,
  fetchInitialAttributeOptions,
} from '../../../../actions/productAttribute';

import {
  fetchRootCategories as fetchVirtualRootCategories,
} from '../../../../actions/virtualCategory';

import {
  setGlobalFilters,
} from '../../../../actions/pim';

import { checkAndConverteDataByFieldOperator } from '../../utils/checkAndConverteDataByFieldOperator';
import updateGroups from '../../utils/updateGroups';
import IntlMessages from '../../../../util/IntlMessages';
import FilterItem from '../FilterItem';
import { getProductIdFromURL, getFilteredDefaultAttributes, getAllGlobalFiltersPayload } from '../../utils/sidebar';

const defaultFilter = {
  groupCondition: '',
  group: [{
    field: '',
    value: '',
    operator: '',
  }],
};

const removeUnavailableFilterGroups = (groups, availableAttributes) => groups.filter(
  fg => availableAttributes.some(aa => aa.value === fg.field),
);

const formatAttributeOptions = (options) => {
  if (!isEmpty(options)) {
    return options.map(o => ({
      title: o.label || o.title,
      value: o.optionCode,
    }));
  }
  return null;
};

const getInputType = (inputType) => {
  const type = inputType.startsWith('dropdown_')
    ? 'dropdown'
    : inputType;
  return type;
};

const getAvailableFilterAttributes = (attributes, listFilterAttributes) => {
  let availableAttributes = [];
  if (!isEmpty(attributes)) {
    const filteredAttributes = attributes.filter(a => !listFilterAttributes.some(
      lfa => a.code === lfa.value,
    ));
    availableAttributes = filteredAttributes.filter(attr => attr.isFilterable)
      .map(attr => ({
        elementType: getInputType(attr.frontendInputType),
        label: attr.label.en,
        value: attr.value,
        options: formatAttributeOptions(attr.options),
      }));
  }
  if (!isEmpty(listFilterAttributes)) {
    const filterableDefaultAttributes = listFilterAttributes.filter(a => a.isFilterable);
    availableAttributes = [...availableAttributes, ...filterableDefaultAttributes];
  }
  return availableAttributes;
};

const RowViewFilter = (props) => {
  const {
    onChange, setFilterTarget, onRemoveSearchFilter, settingsAreApplied, prevFilterTarget, fetchingAttributeOptions,
    onSubmitScopeDialog, categoriesAttributesFetched, pimModeAttributes, globalFilters, filterTarget,
    categoriesAttributes, globalFields, currentFilter, fetchedScopeCategoriesByIds, categoriesByIds,
    selectedSavedFilter, sortedSearchFields, emptySearchState,
  } = props;

  useEffect(() => {
    const productId = getProductIdFromURL();
    if (productId !== null) {
      setFilterTarget('merchant');
      if (pimModeAttributes[filterTarget]) {
        const updatedFilters = [];
        const filteredDefaultPimAttributes = pimModeAttributes[filterTarget].filter(a => a.isFilterable);

        updatedFilters.push({
          groupCondition: 'and',
          group: [
            {
              field: 'id',
              value: '',
              operator: 'eq',
              fieldOption: productId,
              id: productId,
              errors: {}
            }
          ],
          attributes: filteredDefaultPimAttributes,
          key: new Date().getTime(),
          errors: {},
        });

        const payload = {
          parent: [],
          variation: [],
          [filterTarget]: updatedFilters,
          feed: [],
        };

        props.setGlobalFilters(payload);
        sessionStorage.setItem('pim.searchFilters', JSON.stringify(payload));

        setTimeout(() => {
          onChange();

          props.handleSubmitSearchParams({
            searchFilters: updatedFilters,
            searchFields: [],
            isExport: false
          });
        }, 500);
      }
    }
  }, [pimModeAttributes]);

  useEffect(() => {
    if (categoriesAttributesFetched) {
      const availableAttributes = getAvailableFilterAttributes(
        categoriesAttributes,
        pimModeAttributes[filterTarget] || [],
      );
      const updatedFilters = globalFilters[filterTarget].map((sf) => {
        if (sf.key === currentFilter) {
          return {
            ...sf,
            attributes: availableAttributes,
          };
        }
        return sf;
      });

      const payload = {
        ...globalFilters,
        [filterTarget]: updatedFilters,
      };

      const allGlobalFiltersPayload = getAllGlobalFiltersPayload(payload);
      props.setGlobalFilters(allGlobalFiltersPayload);
      sessionStorage.setItem('pim.searchFilters', JSON.stringify(allGlobalFiltersPayload));
    }
  }, [categoriesAttributesFetched]);

  useEffect(() => {
    if (fetchedScopeCategoriesByIds) {
      const categoryList = props.categoriesByIds
        .filter(l => l.virtualParams)
        .map((l) => {
          const res = isEmpty(l.virtualParams.mappedCategories)
            ? l.virtualParams.selectedCategories
            : l.virtualParams.mappedCategories;
          if (isEmpty(res)) return [];
          return res;
        });

      const uniqueIds = uniq([...categoryList.flat()]);
      const filterToUpdate = globalFilters[filterTarget]
        .find(sf => sf.key === currentFilter) || {};
      filterToUpdate.selectedTreeCategories = props.categoriesByIds;

      if (isEmpty(uniqueIds) && pimModeAttributes[filterTarget]) {
        filterToUpdate.attributes = pimModeAttributes[filterTarget].filter(a => a.isFilterable);
        filterToUpdate.group = removeUnavailableFilterGroups(
          filterToUpdate.group, pimModeAttributes[filterTarget],
        );
      }

      const updatedFilters = globalFilters[filterTarget].map((uf) => {
        if (uf.key === currentFilter) {
          return filterToUpdate;
        }
        return uf;
      });

      const defaultGroup = [{
        field: '',
        fieldOption: '',
        operator: '',
      }];
      const newFilters = updatedFilters.map(uF => ({
        ...uF,
        group: isEmpty(uF.group) ? defaultGroup : uF.group,
      }));

      const payload = {
        ...globalFilters,
        [filterTarget]: newFilters,
      };

      const allGlobalFiltersPayload = getAllGlobalFiltersPayload(payload);
      props.setGlobalFilters(allGlobalFiltersPayload);
      sessionStorage.setItem('pim.searchFilters', JSON.stringify(allGlobalFiltersPayload));
      if (!isEmpty(uniqueIds)) props.fetchCategoriesAttributes(uniqueIds);
    }
  }, [categoriesByIds, filterTarget]);

  useEffect(() => {
    const storedSearchFilters = sessionStorage.getItem('pim.searchFilters');
    const parsedStoredSearchFilters = JSON.parse(storedSearchFilters);

    const prevFilters = prevFilterTarget
      && parsedStoredSearchFilters
      && parsedStoredSearchFilters[prevFilterTarget];

    if (
      !settingsAreApplied[filterTarget]
      && !isEmpty(globalFilters[filterTarget])
    ) {
      const payload = {
        ...globalFilters,
        [filterTarget]: [],
      };

      const allGlobalFiltersPayload = getAllGlobalFiltersPayload(payload);
      props.setGlobalFilters(allGlobalFiltersPayload);
      sessionStorage.setItem('pim.searchFilters', JSON.stringify(allGlobalFiltersPayload));
    }

    if (
      settingsAreApplied[filterTarget]
      && isEmpty(selectedSavedFilter[filterTarget])
      && (
        !isEmpty(globalFilters[filterTarget])
        || !isEmpty(globalFields[filterTarget])
      )
    ) {
      const convertedFilters = checkAndConverteDataByFieldOperator(
        [...globalFilters[filterTarget]],
      );
      const mappedFilters = convertedFilters.map((s) => {
        const newGroups = updateGroups(s.group);
        return ({
          groupCondition: s.groupCondition,
          group: [...newGroups, ...s.groupSearchFields || []].filter(f => f),
        });
      }).flat();

      props.handleSubmitSearchParams({
        searchFilters: mappedFilters,
        searchFields: globalFields[filterTarget],
      });
    }

    if (
      prevFilterTarget
      && !settingsAreApplied[prevFilterTarget]
      && prevFilters
    ) {
      const payload = {
        ...globalFilters,
        [prevFilterTarget]: [],
      };

      const allGlobalFiltersPayload = getAllGlobalFiltersPayload(payload);
      props.setGlobalFilters(allGlobalFiltersPayload);
      sessionStorage.setItem('pim.searchFilters', JSON.stringify(allGlobalFiltersPayload));
    }
  }, [filterTarget]);

  useEffect(() => {
    const storedSearchFilters = sessionStorage.getItem('pim.searchFilters');
    const storedSearchFields = sessionStorage.getItem('pim.searchFields');
    const storedFilterTarget = sessionStorage.getItem('pim.filterTarget');
    const storedSelectedFilter = sessionStorage.getItem('pim.selectedFilter');
    const parsedStoredSelectedFilter = JSON.parse(storedSelectedFilter) || emptySearchState;
    const parsedStoredSearchFilters = JSON.parse(storedSearchFilters) || emptySearchState;
    const parsedStoredSearchFields = JSON.parse(storedSearchFields) || emptySearchState;
    const targetToUse = storedFilterTarget || filterTarget;

    if (
      isEmpty(parsedStoredSelectedFilter[targetToUse])
      && (
        !isEmpty(parsedStoredSearchFilters[targetToUse])
        || !isEmpty(parsedStoredSearchFields[targetToUse])
      )
    ) {
      props.handleSubmitSearchParams({
        searchFilters: parsedStoredSearchFilters ? parsedStoredSearchFilters[targetToUse] : [],
        searchFields: parsedStoredSearchFields ? parsedStoredSearchFields[targetToUse] : [],
      });
    }

    if (!isEmpty(storedSearchFilters)) {
      props.setGlobalFilters(parsedStoredSearchFilters);

      if (!isEmpty(parsedStoredSearchFilters[targetToUse])) {
        const operatorsWithoutValue = new Set(['exists', 'not_exists']);
        const filtersGroups = parsedStoredSearchFilters[targetToUse]
          .map(f => f.group).flat().map((g) => {
            if (!operatorsWithoutValue.has(g.operator)) {
              return ({
                code: g.field,
                value: Array.isArray(g.fieldOption) ? g.fieldOption : (g.fieldOption ? g.fieldOption.split() : ''),
              });
            }
            return ({
              code: g.field,
            });
          });

        const codes = uniq(filtersGroups.map(g => g.code).flat());
        const options = uniq(filtersGroups.map(g => g.value).flat()).filter(o => o);

        if (!isEmpty(codes) && !isEmpty(options)) {
          props.fetchInitialAttributeOptions(codes, options);
        }
      }
    }
  }, []);

  const handleFilterChange = (item, filterKey) => {
    const updatedFilters = globalFilters[filterTarget].map((f) => {
      if (f.key === filterKey) return item;
      return f;
    });

    const payload = {
      ...globalFilters,
      [filterTarget]: updatedFilters,
    };

    props.setGlobalFilters(payload);
    sessionStorage.setItem('pim.searchFilters', JSON.stringify(payload));
    onChange();
  };

  const onAddNewFilterClick = () => {
    const updatedFilters = [...globalFilters[filterTarget]];
    const filteredDefaultPimAttributes = getFilteredDefaultAttributes({
      filterTarget: filterTarget,
      pimModeAttributes: pimModeAttributes,
      sortedSearchFields: sortedSearchFields,
    });

    updatedFilters.push({
      ...defaultFilter,
      attributes: filteredDefaultPimAttributes,
      key: new Date().getTime(),
    });
    const payload = {
      ...globalFilters,
      [filterTarget]: updatedFilters,
    };

    props.setGlobalFilters(payload);
    sessionStorage.setItem('pim.searchFilters', JSON.stringify(payload));
    onChange();
  };

  const handleDeleteFilter = (item) => {
    const updatedFilters = [...globalFilters[filterTarget].filter(f => f.key !== item.key)];
    const payload = {
      ...globalFilters,
      [filterTarget]: updatedFilters,
    };

    props.setGlobalFilters(payload);
    sessionStorage.setItem('pim.searchFilters', JSON.stringify(payload));
    onChange();
    onRemoveSearchFilter();
  };

  const className = !isEmpty(globalFilters[filterTarget]) ? ' no-border-bottom' : '';

  return (
    <div className={`form-group-filter ${props.className}`}>
      <div className={`form-group-title pt-10 pb-10 ${className}`}>
        <span className="title">
          <IntlMessages id="preview.filter" />
        </span>
        <IconButton
          aria-label="Add"
          color="primary"
          className="btn-mui-xs text-success pos-rel"
          onClick={onAddNewFilterClick}
        >
          <i className="ti-plus" />
        </IconButton>
      </div>
      {globalFilters[filterTarget] && globalFilters[filterTarget].map((f, index) => {
        const groupEmpty = isEmpty(f.group) && (isEmpty(f.selectedTreeCategories) || !f.selectedTreeCategories);
        const formItemClass = groupEmpty ? 'group-empty' : '';
        return (
          <Fragment key={`search_filter_${f.key}`}>
            <FilterItem
              filter={f}
              className={formItemClass}
              filterGroupIndex={index}
              key={`search_filter_${f.key}`}
              onChange={handleFilterChange}
              onDeleteFilter={handleDeleteFilter}
              savedFiltersLength={globalFilters[filterTarget].length}
              categoriesAttributesFetching={
                props.categoriesAttributesFetching && currentFilter === f.key
              }
              fetchAttributeOptions={props.fetchAttributeOptions}
              fetchingAttributeOptions={fetchingAttributeOptions}
              onSubmitScopeDialog={onSubmitScopeDialog}
              fetchVirtualRootCategories={props.fetchVirtualRootCategories}
              selectedTreeCategories={f.selectedTreeCategories}
              selectClassName={props.selectClassName}
              helperText={groupEmpty && props.helperText}
              filterTarget={filterTarget}
              mapperCategories={props.mapperCategories}
              mapperMarketplace={props.mapperMarketplace}
              selectedCategories={props.selectedCategories}
              setSelectedCategories={props.setSelectedCategories}
              openedCategories={props.openedCategories}
              setOpenedCategories={props.setOpenedCategories}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

RowViewFilter.propTypes = {
  className: string,
  onChange: func.isRequired,
  setFilterTarget: func.isRequired,
  categoriesAttributesFetching: bool.isRequired,
  onRemoveSearchFilter: func.isRequired,
  fetchAttributeOptions: func.isRequired,
  fetchingAttributeOptions: bool,
  onSubmitScopeDialog: func.isRequired,
  fetchVirtualRootCategories: func.isRequired,
  currentFilter: number,
  selectClassName: string.isRequired,
  handleSubmitSearchParams: func.isRequired,

  globalFilters: shape().isRequired,
  filterTarget: string.isRequired,
  settingsAreApplied: shape().isRequired,
  setGlobalFilters: func.isRequired,
  prevFilterTarget: string.isRequired,
  categoriesAttributesFetched: bool.isRequired,
  categoriesAttributes: arrayOf(shape()).isRequired,
  fetchedScopeCategoriesByIds: bool.isRequired,
  categoriesByIds: arrayOf(shape()).isRequired,
  fetchCategoriesAttributes: func.isRequired,
  pimModeAttributes: shape().isRequired,
  globalFields: shape().isRequired,
  selectedSavedFilter: shape().isRequired,
  fetchInitialAttributeOptions: func.isRequired,
  helperText: oneOfType([node, string]),
  emptySearchState: shape().isRequired,
};

RowViewFilter.defaultProps = {
  className: '',
  fetchingAttributeOptions: false,
  currentFilter: null,
  helperText: null,
  emptySearchState: [],
};

export const mapDispatchToProps = {
  fetchAttributeOptions,
  fetchVirtualRootCategories,
  setGlobalFilters,
  fetchCategoriesAttributes,
  fetchInitialAttributeOptions,
};

export const mapStateToProps = state => ({
  globalFilters: state.pim.globalFilters,
  filterTarget: state.pim.filterTarget,
  categoriesAttributesFetching: state.productAttribute.categoriesAttributesFetching,
  fetchingAttributeOptions: state.productAttribute.fetchingAttributeOptions,
  prevFilterTarget: state.pim.prevFilterTarget,
  categoriesAttributesFetched: state.productAttribute.categoriesAttributesFetched,
  categoriesAttributes: state.productAttribute.categoriesAttributes,
  fetchedScopeCategoriesByIds: state.scopeCategory.fetchedScopeCategoriesByIds,
  categoriesByIds: state.scopeCategory.categoriesByIds,
  pimModeAttributes: state.system.modes.pim,
  globalFields: state.pim.globalFields,
  selectedSavedFilter: state.previewFilter.selectedSavedFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(RowViewFilter);
