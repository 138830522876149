/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  shape, arrayOf, string, func, bool,
} from 'prop-types';
import { isEmpty, uniq } from 'lodash';
import Button from '@material-ui/core/Button';

import { Form } from 'reactstrap';
import ScopeDialog from '../../../../../components/ScopeDialog';
import IntlMessages from '../../../../../util/IntlMessages';

const SelectCategories = (props) => {
  const { scopeCategory } = props.data;
  const {
    selectedTreeItems,
    setSelectedTreeItems,
    showCategoriesDialog,
    setShowCategoriesDialog,
  } = props;

  useEffect(() => {
    if (props.fetchedScopeCategoriesByIds) {
      const physicalCategoriesIds = props.scopeCategoriesByIds.map((c) => {
        const { mappedCategories } = c.virtualParams || {};
        return [...mappedCategories || []];
      });
      props.onSubmit(selectedTreeItems, physicalCategoriesIds.flat());
    }
  }, [props.fetchedScopeCategoriesByIds]);

  const getVirtualCategoriesIds = array => array.map((a) => {
    if (typeof a !== 'string') return a.id;
    return a;
  });

  const openSelectCategoriesButton = () => {
    setShowCategoriesDialog(true);
  };

  const closeSelectCategoriesButton = () => {
    setShowCategoriesDialog(false);
    props.clearSearchListState();
  };

  const submitCheckedCategories = (selected) => {
    const mappedList = getVirtualCategoriesIds(selected);

    setSelectedTreeItems(mappedList);

    if (!isEmpty(mappedList)) {
      const ids = uniq(mappedList);
      props.fetchScopeCategoriesByIds(ids);
    } else {
      props.onSubmit([], []);
    }

    closeSelectCategoriesButton();
  };

  const handleSearchScopeCategory = (query) => {
    props.searchScopeCategories({
      full_text: {
        fields: [
          'name.en',
        ],
        value: query,
        match_type: 'must',
      },
      pagination: {
        page: 1,
        limit: 500,
      },
    });
  };

  return (
    <Form inline className="form-group min-w-s">
      <Button
        className="no-wrap block"
        variant="contained"
        color="primary"
        disabled={props.disabled || scopeCategory.fetchingParentCategoriesByIds}
        onClick={openSelectCategoriesButton}
      >
        <IntlMessages id="label.selectCategoriesLabel" />
      </Button>

      <ScopeDialog
        dialog={{
          closeButtonTitle: <IntlMessages id="rankings.cancelScopeDialogButtonLabel" />,
          submitButtonTitle: <IntlMessages id="rankings.selectScopeDialogButtonLabel" />,
        }}
        search={{
          noResult: scopeCategory.searched && scopeCategory.searchResults.length === 0,
          placeholder: 'Search virtual categories...',
          list: scopeCategory.searchResults,
          errorMessage: 'text.errors.lessThan500Characters',
          queryLengthLimit: 500,
        }}
        showScopeDialog={showCategoriesDialog}
        collapsed={scopeCategory.collapse}
        loadedKeys={scopeCategory.loadedKeys}
        selectedTreeItems={selectedTreeItems}
        treeItems={scopeCategory.treeItems}
        closeScopeDialog={closeSelectCategoriesButton}
        onSubmit={submitCheckedCategories}
        toogleTreeComponents={props.clearSearchListState}
        fetchChildCategories={props.fetchChildCategories}
        onSearchSubmit={handleSearchScopeCategory}
        onToggleCategoryTreeCollapse={props.onToggleScopeCategoryTreeCollapse}
        handleLoadSubcategories={props.handleLoadScopeSubcategories}
        loading={scopeCategory.fetchingParentCategoriesByIds || scopeCategory.fetchChildrenStarted}
        multiple
      />
    </Form>
  );
};

SelectCategories.propTypes = {
  data: shape({
    scopeCategory: shape({
      collapse: arrayOf(string),
      treeItems: arrayOf(shape()),
      loadedKeys: arrayOf(string),
    }),
  }).isRequired,
  selectedTreeItems: arrayOf(string).isRequired,
  setSelectedTreeItems: func.isRequired,
  clearSearchListState: func.isRequired,
  fetchChildCategories: func.isRequired,
  searchScopeCategories: func.isRequired,
  onToggleScopeCategoryTreeCollapse: func.isRequired,
  handleLoadScopeSubcategories: func.isRequired,
  onSubmit: func.isRequired,
  disabled: bool.isRequired,
  fetchScopeCategoriesByIds: func.isRequired,
  setShowCategoriesDialog: func.isRequired,
  fetchedScopeCategoriesByIds: bool,
  showCategoriesDialog: bool.isRequired,
  scopeCategoriesByIds: arrayOf(shape()),
};

SelectCategories.defaultProps = {
  scopeCategoriesByIds: [],
  fetchedScopeCategoriesByIds: false,
};

export default SelectCategories;
