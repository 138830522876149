/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  arrayOf, shape, func, bool, string,
} from 'prop-types';
import { isEmpty, uniq } from 'lodash';
import { connect } from 'react-redux';

import {
  Card, CardContent, IconButton, Button,
} from '@material-ui/core';
import swal from '@sweetalert/with-react';
import { NotificationManager } from 'react-notifications';

import {
  fetchPreviewFilters,
  setSelectedSavedFilter,
  setSavedFilters,
  updatePreviewFilters,
} from '../../../../actions/previewFilter';

import {
  setSavedFilterFilters,
  setSavedFilterFields,
  setIsCustomFieldsMode,
  setGlobalFilters,
  setGlobalFields,
  fetchProductList,
  setFilterTarget,
  setProductsListEmpty,
  clearSelected,
  clearPimProductList,
  setPagination,
} from '../../../../actions/pim';

import NewFilterDialog from '../NewFilterDialog';
import IntlMessages from '../../../../util/IntlMessages';
import Can from '../../../../components/Can';
import appPermissions from '../../../../util/appPermissions';
import { checkAndConverteDataByFieldOperator } from '../../utils/checkAndConverteDataByFieldOperator';
import updateGroups from '../../utils/updateGroups';
import { getDefaultRootFilter, defaultPagination } from '../../utils/defaultData';
import { mapFiltersByOperator } from '../../../../util/mapFiltersByOperator';
import { mapPrefixToFilters } from '../../../../util/filterMapper';

import {
  mergeFilters, mergeFields, getNewFilter,
} from '../../pages/Home/utils/mappers';
import { checkFilterGroups } from '../../../utils/checkFilterGroups';

const SavedFilters = (props) => {
  const {
    updatedMultiple, selectedSavedFilter, savedFilters, globalFilters, prevFilterTarget, filterTarget, globalFields,
    isCustomFieldsMode, fetching, setSearchFields, searchFields, previewFilterAttributeList, searchQuery,
    fullTextListModeSearch, setShowSearchFields, setOpenNewFilterDialog, openNewFilterDialog, savedFilterFields,
    savedFilterFilters, sort, emptySearchState, getData,
  } = props;

  const getGroupCondition = filter => (
    filter.group.length > 1 ? {
      condition: filter.condition,
    } : {}
  );

  const checkFilters = (incomingFilters) => {
    const operatorsWithoutValue = new Set(['exists', 'not_exists']);
    return incomingFilters.filter(f => f.group && f.group
      .every(g => g.field && (!isEmpty(g.value) || operatorsWithoutValue.has(g.operator))));
  };

  const getConvertedFilters = () => {
    const convertedFilters = checkAndConverteDataByFieldOperator(
      [...globalFilters[filterTarget]],
    );
    const mappedFilters = convertedFilters.map((s) => {
      const newGroups = updateGroups(s.group);
      return ({
        groupCondition: s.groupCondition,
        group: [...newGroups, ...s.groupSearchFields || []].filter(f => f),
      });
    }).flat();
    return mappedFilters;
  };

  const processSavedFilter = (savedFilter) => {
    if (isEmpty(savedFilter)) {
      return {
        filters: [],
        fields: [],
        newFilterTarget: filterTarget,
      };
    }

    const newFilterTarget = savedFilter.filterTarget;
    const filters = savedFilter.filter.map(f => ({
      ...getGroupCondition(f),
      group: f.group.map(g => ({
        ...g,
        ...g.groupSearchFields || [],
      })),
    }));
    const fields = [...savedFilter.fields];

    props.setFilterTarget(newFilterTarget);

    return {
      filters,
      fields,
      newFilterTarget,
    };
  };

  const getSorts = (fT) => {
    const targetSort = sort[fT];
    let targetSortQuery = {};
    if (!isEmpty(targetSort)) {
      targetSortQuery = {
        sort: [{
          field: targetSort.field,
          order: targetSort.order,
        }],
      };
    }
    return targetSortQuery;
  };

  const emulateFilterSelection = (items, targetToUse) => {
    const mappedGlobalFilters = getConvertedFilters()
      .filter(f => f.group.every(g => g.field && g.fieldOption));
    const { filters, fields, newFilterTarget } = processSavedFilter(items[targetToUse]);
    const payload = { ...items, [newFilterTarget]: items[targetToUse] };

    props.setSavedFilterFilters({
      ...savedFilterFilters,
      [newFilterTarget]: filters,
    });
    props.setSavedFilterFields({
      ...savedFilterFields,
      [newFilterTarget]: fields,
    });

    props.setSelectedSavedFilter(payload);
    sessionStorage.setItem('pim.selectedFilter', JSON.stringify(payload));

    if (!isEmpty(fields)) {
      props.setIsCustomFieldsMode(true);
    } else {
      props.setIsCustomFieldsMode(false);
    }

    const filtersToSearch = mergeFilters(
      [
        ...filters,
        ...mappedGlobalFilters,
      ].filter(f => !isEmpty(f.group)),
      getDefaultRootFilter(newFilterTarget),
    );

    // eslint-disable-next-line no-nested-ternary
    const fieldsToSearch = isCustomFieldsMode || !isEmpty(fields)
      ? mergeFields(uniq([...fields]), globalFields[newFilterTarget])
      : !isEmpty(globalFields[newFilterTarget])
        ? mergeFields([], globalFields[newFilterTarget])
        : [];

    const mappedFilters = mapFiltersByOperator(
      mapPrefixToFilters(filtersToSearch),
      previewFilterAttributeList,
    );

    const newFilter = checkFilters(getNewFilter(mappedFilters));

    props.setPagination(defaultPagination);
    return getData({
      viewMode: 'backend',
      filter: newFilter,
      pagination: defaultPagination,
      searchFilter: searchQuery,
      fields: fieldsToSearch,
      fullText: fullTextListModeSearch,
      ...getSorts(newFilterTarget),
    });
  };

  useEffect(() => {
    const storedSelectedFilter = sessionStorage.getItem('pim.selectedFilter');
    const storedFilterTarget = sessionStorage.getItem('pim.filterTarget');
    const storedSearchFilters = sessionStorage.getItem('pim.searchFilters');
    const storedSearchFields = sessionStorage.getItem('pim.searchFields');
    const parsedItem = JSON.parse(storedSelectedFilter);
    const parsedStoredSearchFilters = JSON.parse(storedSearchFilters) || emptySearchState;
    const parsedStoredSearchFields = JSON.parse(storedSearchFields) || emptySearchState;
    const targetToUse = storedFilterTarget || filterTarget;

    if (!isEmpty(parsedItem)) {
      props.setSelectedSavedFilter(parsedItem);
      sessionStorage.setItem('pim.selectedFilter', JSON.stringify(parsedItem));

      props.setSavedFilterFilters({
        parent: !isEmpty(parsedItem.parent) ? parsedItem.parent.filter : [],
        variation: !isEmpty(parsedItem.variation) ? parsedItem.variation.filter : [],
        merchant: !isEmpty(parsedItem.merchant) ? parsedItem.merchant.filter : [],
        feed: !isEmpty(parsedItem.feed) ? parsedItem.feed.filter : [],
        rule: !isEmpty(parsedItem.rule) ? parsedItem.rule.filter : [],
        repricing: !isEmpty(parsedItem.repricing) ? parsedItem.repricing.filter : [],
        mapper: !isEmpty(parsedItem.mapper) ? parsedItem.mapper.filter : [],
        activityLog: !isEmpty(parsedItem.activityLog) ? parsedItem.activityLog.filter : [],
        cams: !isEmpty(parsedItem.cams) ? parsedItem.cams.filter : [],
        repricer: !isEmpty(parsedItem.repricer) ? parsedItem.repricer.filter : [],
      });
      props.setSavedFilterFields({
        parent: parsedItem.parent ? parsedItem.parent.fields : [],
        variation: parsedItem.variation ? parsedItem.variation.fields : [],
        merchant: parsedItem.merchant ? parsedItem.merchant.fields : [],
        feed: parsedItem.feed ? parsedItem.feed.fields : [],
        rule: parsedItem.rule ? parsedItem.rule.fields : [],
        repricing: parsedItem.repricing ? parsedItem.repricing.fields : [],
        mapper: parsedItem.mapper ? parsedItem.mapper.fields : [],
        activityLog: parsedItem.activityLog ? parsedItem.activityLog.fields : [],
        cams: parsedItem.cams ? parsedItem.cams.fields : [],
        repricer: parsedItem.repricer ? parsedItem.repricer.fields : [],
      });
    }

    if (parsedItem && !isEmpty(parsedItem[targetToUse])) {
      const {
        filters,
        fields,
        newFilterTarget,
      } = processSavedFilter(parsedItem[targetToUse]);

      if (!isEmpty(fields) || !isEmpty(parsedStoredSearchFields[targetToUse])) {
        props.setIsCustomFieldsMode(true);
      } else {
        props.setIsCustomFieldsMode(false);
      }

      if (!fetching) {
        const filtersToSearch = mergeFilters(
          checkAndConverteDataByFieldOperator([
            ...filters,
            ...parsedStoredSearchFilters[targetToUse],
          ]).filter(f => !isEmpty(f.group)),
          getDefaultRootFilter(newFilterTarget),
        );

        const fieldsToSearch = (
          isCustomFieldsMode
          || !isEmpty(fields)
          || !isEmpty(parsedStoredSearchFields[targetToUse])
        )
          ? mergeFields(
            uniq([...fields]), parsedStoredSearchFields[targetToUse],
          )
          : globalFields[newFilterTarget];

        const mappedFilters = mapFiltersByOperator(
          mapPrefixToFilters(filtersToSearch),
          previewFilterAttributeList,
        );
        const newFilter = checkFilters(getNewFilter(mappedFilters));
        const hasEmptyGroups = checkFilterGroups(newFilter);

        if (!hasEmptyGroups) {
          props.setPagination(defaultPagination);
          getData({
            viewMode: 'backend',
            filter: newFilter,
            pagination: defaultPagination,
            searchFilter: searchQuery,
            fields: fieldsToSearch,
            fullText: fullTextListModeSearch,
            ...getSorts(newFilterTarget),
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (updatedMultiple) {
      props.fetchPreviewFilters();
    }
  }, [updatedMultiple]);

  useEffect(() => {
    if (!isEmpty(selectedSavedFilter[filterTarget]) && prevFilterTarget) {
      emulateFilterSelection(selectedSavedFilter, filterTarget);
    }
  }, [filterTarget]);

  const getCondition = filter => (
    filter.group.length > 1 ? { condition: filter.groupCondition } : {}
  );

  const handleSavedFilterItemRemove = (filterToRemove) => {
    const isRemovingActiveFilter = filterToRemove.name === selectedSavedFilter[filterTarget].name;

    if (isRemovingActiveFilter) {
      const payload = {
        ...selectedSavedFilter,
        [filterTarget]: {},
      };

      props.clearPimProductList();
      props.setSelectedSavedFilter(payload);
      props.setSavedFilterFilters({
        ...savedFilterFilters,
        [filterTarget]: [],
      });
      props.setSavedFilterFields({
        ...savedFilterFields,
        [filterTarget]: [],
      });
      sessionStorage.setItem('pim.selectedFilter', JSON.stringify(payload));
    }

    props.updatePreviewFilters([
      ...savedFilters.filter(f => f.name !== filterToRemove.name),
    ]);
  };

  const deleteItem = (filter) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to permanently delete this filter?',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    })
      .then((isAccepted) => {
        if (isAccepted) {
          handleSavedFilterItemRemove(filter);
        }
      });
  };

  const handleFilterClick = (items, targetToUse) => {
    props.clearSelected();
    const operatorsWithoutValue = new Set(['exists', 'not_exists']);
    const convertedFilters = getConvertedFilters();
    const mappedGlobalFilters = convertedFilters
      .filter(f => f.group
        .every(g => g.field && (
          g.fieldOption || operatorsWithoutValue.has(g.operator) || g.fieldOption === undefined
        )));
    const isActiveFilterSelected = items[targetToUse]
      && items[targetToUse].name === selectedSavedFilter[filterTarget].name;
    const { filters, fields, newFilterTarget } = processSavedFilter(items[targetToUse]);
    const payload = { ...items, [newFilterTarget]: items[targetToUse] };

    props.setSavedFilterFilters({
      ...savedFilterFilters,
      [newFilterTarget]: !isActiveFilterSelected ? filters : [],
    });
    props.setSavedFilterFields({
      ...savedFilterFields,
      [newFilterTarget]: !isActiveFilterSelected ? fields : [],
    });

    props.setSelectedSavedFilter(payload);
    sessionStorage.setItem('pim.selectedFilter', JSON.stringify(payload));

    if (
      (!isActiveFilterSelected && !isEmpty(fields)) || !isEmpty(globalFields[newFilterTarget])
    ) {
      props.setIsCustomFieldsMode(true);
    } else {
      props.setIsCustomFieldsMode(false);
    }

    if (
      isActiveFilterSelected
      && isEmpty(checkFilters(globalFilters[newFilterTarget]))
      && isEmpty(globalFields[newFilterTarget])
    ) {
      const data = {
        ...selectedSavedFilter,
        [targetToUse]: {},
      };
      props.setSelectedSavedFilter(data);
      sessionStorage.setItem('pim.selectedFilter', JSON.stringify(data));
      props.setProductsListEmpty();
    }

    if (isActiveFilterSelected) {
      const filtersPayload = mergeFilters(
        [
          ...[],
          ...mappedGlobalFilters,
        ].filter(f => !isEmpty(f.group)),
        getDefaultRootFilter(newFilterTarget),
      );
      const mappedFilters = mapFiltersByOperator(
        mapPrefixToFilters(filtersPayload),
        previewFilterAttributeList,
      );
      const newFilter = checkFilters(getNewFilter(mappedFilters));

      const newSelectedFilter = {
        ...selectedSavedFilter,
        [newFilterTarget]: {},
      };
      props.setSelectedSavedFilter(newSelectedFilter);
      sessionStorage.setItem('pim.selectedFilter', JSON.stringify(newSelectedFilter));

      props.setPagination(defaultPagination);
      return getData({
        viewMode: 'backend',
        filter: newFilter,
        pagination: defaultPagination,
        searchFilter: searchQuery,
        fields: !isEmpty(globalFields[newFilterTarget])
          ? mergeFields(
            [], globalFields[newFilterTarget],
          ) : [],
        fullText: fullTextListModeSearch,
        ...getSorts(newFilterTarget),
      });
    }

    const filtersToSearch = mergeFilters(
      [
        ...filters,
        ...mappedGlobalFilters,
      ].filter(f => !isEmpty(f.group)),
      getDefaultRootFilter(newFilterTarget),
    );

    const fieldsToSearch = (
      isCustomFieldsMode
      && !isEmpty(globalFields[newFilterTarget])
    ) || !isEmpty(fields)
      ? mergeFields(
        uniq([...fields]), globalFields[newFilterTarget],
      )
      : globalFields[newFilterTarget];

    const mappedFilters = mapFiltersByOperator(
      mapPrefixToFilters(filtersToSearch),
      previewFilterAttributeList,
    );
    const newFilter = checkFilters(getNewFilter(mappedFilters));

    props.setPagination(defaultPagination);
    return getData({
      viewMode: 'backend',
      filter: newFilter,
      pagination: defaultPagination,
      searchFilter: searchQuery,
      fields: fieldsToSearch,
      fullText: fullTextListModeSearch,
      ...getSorts(newFilterTarget),
    });
  };

  const handleNotificationWarning = msg => NotificationManager.warning(msg);

  const handleNewFilterDialogOk = (data) => {
    const filters = [...savedFilters];
    const filterAlreadyExist = filters.some(f => f.name === data.filter);

    if (filterAlreadyExist) {
      return handleNotificationWarning(
        <IntlMessages id="text.filterExist" />,
      );
    }

    const fieldsList = [...globalFields[filterTarget], searchFields[filterTarget]].flat();

    const convertedFilters = checkAndConverteDataByFieldOperator(
      [...globalFilters[filterTarget]],
    );
    const newFilter = {
      name: data.filter,
      filter: convertedFilters && convertedFilters.length > 0
        ? convertedFilters.map(sF => ({
          group: sF.group.map((g) => {
            const operatorsWithoutValue = new Set(['exists', 'not_exists']);
            const value = g.fieldOption || g.value;
            let newGroup = {
              field: g.field,
              operator: g.operator,
            };

            if ((g.fieldOption || g.value) && !operatorsWithoutValue.has(g.operator)) {
              newGroup.value = value;
            }

            if (g.field === 'locked_attributes') {
              newGroup = {
                field: g.field,
                operator: g.operator,
              };
            }
            return newGroup;
          }),
          ...getCondition(sF),
          categories: sF.categories || [],
          groupSearchFields: sF.groupSearchFields || [],
        }))
        : [],
      fields: !isEmpty(fieldsList)
        ? fieldsList
        : [],
      filterTarget,
    };

    const savedFiltersPaylad = [...filters, newFilter];

    const searchFiltersPayload = {
      ...globalFilters,
      [filterTarget]: [],
    };

    const searchFieldsPayload = {
      ...globalFields,
      [filterTarget]: [],
    };

    props.setGlobalFields(searchFieldsPayload);
    props.setGlobalFilters(searchFiltersPayload);
    sessionStorage.setItem('pim.searchFields', JSON.stringify(searchFieldsPayload));
    sessionStorage.setItem('pim.searchFilters', JSON.stringify(searchFiltersPayload));

    setSearchFields({
      ...searchFields,
      [filterTarget]: [],
    });
    setShowSearchFields(false);

    const filterPayload = {
      ...selectedSavedFilter,
      [filterTarget]: newFilter,
    };

    setOpenNewFilterDialog(false);
    handleFilterClick(
      filterPayload, filterTarget,
    );

    props.setSavedFilters(savedFiltersPaylad);
    return props.updatePreviewFilters(savedFiltersPaylad);
  };

  const handleNewFilterDialogClose = () => setOpenNewFilterDialog(false);
  const targetSavedFilters = savedFilters.filter(f => f.filterTarget === filterTarget);
  const className = !isEmpty(targetSavedFilters) ? ' no-border-bottom' : '';
  const cardListItemClass = 'flex items-center justify-between form-group-row block quick-filter-item ';

  return (
    <div className="form-group-field">
      <div className={`form-group-title pt-10 pb-10 ${className}`}>
        <span className="title flex items-center justify-between block">
          <IntlMessages id="text.quickFilters" />
        </span>
      </div>
      {!isEmpty(targetSavedFilters) && (
        <Card className="card-xs quick-filter">
          <CardContent className="flex items-center direction-column">
            {targetSavedFilters.map((item) => {
              const isActive = item.name === selectedSavedFilter[filterTarget].name;
              const onDeleteItemClick = () => deleteItem(item);
              const onFilterClick = () => handleFilterClick({
                ...selectedSavedFilter,
                [filterTarget]: item,
              }, filterTarget);

              return (
                <div
                  className={`
                    ${cardListItemClass}
                    ${isActive ? 'quick-filter-item-active' : ''}
                  `}
                  key={item.name}
                >
                  <Button
                    className="d-block block text-left text-normal"
                    onClick={onFilterClick}
                    color="primary"
                  >
                    {item.name}
                  </Button>
                  <Can
                    do={appPermissions.pim.permissions.delete}
                    on={appPermissions.pim.name}
                  >
                    <IconButton
                      aria-label="Delete"
                      className="btn-mui-xs text-danger btn-top-right pos-rel"
                      onClick={onDeleteItemClick}
                    >
                      <i className="ti-close" />
                    </IconButton>
                  </Can>
                </div>
              );
            })}
          </CardContent>
        </Card>
      )}
      <NewFilterDialog
        open={openNewFilterDialog}
        list={savedFilters}
        onClose={handleNewFilterDialogClose}
        onOk={handleNewFilterDialogOk}
      />
    </div>
  );
};

SavedFilters.propTypes = {
  getData: func.isRequired,
  savedFilters: arrayOf(shape()).isRequired,
  selectedSavedFilter: shape().isRequired,
  globalFields: shape().isRequired,
  previewFilterAttributeList: arrayOf(shape()).isRequired,
  searchQuery: shape().isRequired,
  fullTextListModeSearch: shape().isRequired,
  globalFilters: shape().isRequired,
  sort: shape().isRequired,
  savedFilterFields: shape().isRequired,
  savedFilterFilters: shape().isRequired,
  searchFields: shape().isRequired,
  fetchPreviewFilters: func.isRequired,
  setSelectedSavedFilter: func.isRequired,
  setSavedFilterFilters: func.isRequired,
  setSavedFilterFields: func.isRequired,
  setIsCustomFieldsMode: func.isRequired,
  setSavedFilters: func.isRequired,
  setGlobalFilters: func.isRequired,
  setGlobalFields: func.isRequired,
  fetchProductList: func.isRequired,
  updatePreviewFilters: func.isRequired,
  setFilterTarget: func.isRequired,

  updatedMultiple: bool.isRequired,
  filterTarget: string.isRequired,
  isCustomFieldsMode: bool.isRequired,
  openNewFilterDialog: bool.isRequired,
  setOpenNewFilterDialog: func.isRequired,
  setProductsListEmpty: func.isRequired,
  fetching: bool.isRequired,
  prevFilterTarget: string.isRequired,
  setSearchFields: func.isRequired,
  setShowSearchFields: func.isRequired,
  clearSelected: func.isRequired,
  clearPimProductList: func.isRequired,
  setPagination: func.isRequired,
  emptySearchState: shape().isRequired,
};

SavedFilters.defaultProps = {
  emptySearchState: [],
};

export const mapDispatchToProps = {
  fetchPreviewFilters,
  setSelectedSavedFilter,
  setSavedFilterFilters,
  setSavedFilterFields,
  setIsCustomFieldsMode,
  setSavedFilters,
  setGlobalFilters,
  setGlobalFields,
  fetchProductList,
  updatePreviewFilters,
  setFilterTarget,
  setProductsListEmpty,
  clearSelected,
  clearPimProductList,
  setPagination,
};

export const mapStateToProps = state => ({
  savedFilters: state.previewFilter.list,
  updatedMultiple: state.previewFilter.updatedMultiple,
  selectedSavedFilter: state.previewFilter.selectedSavedFilter,

  filterTarget: state.pim.filterTarget,
  globalFilters: state.pim.globalFilters,
  sort: state.pim.sort,
  globalFields: state.pim.globalFields,
  isCustomFieldsMode: state.pim.isCustomFieldsMode,
  searchQuery: state.pim.searchQuery,
  fullTextListModeSearch: state.pim.fullTextListModeSearch,
  savedFilterFilters: state.pim.savedFilterFilters,
  savedFilterFields: state.pim.savedFilterFields,
  fetching: state.pim.fetching,
  prevFilterTarget: state.pim.prevFilterTarget,

  previewFilterAttributeList: state.previewFilterAttribute.list,
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedFilters);
