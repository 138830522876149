/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { NotificationManager } from 'react-notifications';

import roles from '../../../../util/roles';
import IntlMessages from '../../../../util/IntlMessages';
import getInfluencerSchema from '../../utils/influencerSchema';

export const useTune = (state, props) => {
  useEffect(() => {
    props.fetchTuneSettings();
  }, []);

  useEffect(() => {
    const { created, createdUser } = props.user;
    const shouldInitiateInfluencerFlow = createdUser
      && createdUser.roles.includes(roles.influencer.name);

    if (created && shouldInitiateInfluencerFlow) {
      const payload = {
        data: {
          status: 'active',
          company: state.companyName,
        },
      };

      props.createNewAffiliate(payload);
    }
  }, [props.user.created]);

  useEffect(() => {
    if (props.tune.affiliateCreated) {
      const { id } = props.tune.createdAffiliate;
      const {
        email, displayName, password,
      } = props.user.createdUser;
      const splittedName = displayName.split(' ');
      const payload = {
        data: {
          affiliateId: id,
          email,
          password,
          status: 'active',
          firstName: splittedName[0],
          lastName: splittedName[1],
        },
      };

      props.createNewAffiliateUser(payload);
    }
  }, [props.tune.affiliateCreated]);

  useEffect(() => {
    if (props.tune.affiliateCreated) {
      NotificationManager.success(
        <IntlMessages id="tune.notification.affiliateCreatedSuccessfully" />,
      );
    }
  }, [props.tune.affiliateCreated]);

  useEffect(() => {
    if (props.tune.affiliateUserCreated) {
      const { settings } = props.tune;
      const { createdUser } = props.user;
      const { id } = props.tune.createdAffiliate;
      const influencerPayload = getInfluencerSchema({
        influencerId: createdUser.id,
        name: createdUser.displayName,
        affiliateId: id,
        storeLink: settings.storeLink,
      });

      props.createInfluencer(influencerPayload);
      NotificationManager.success(
        <IntlMessages id="tune.notification.affiliateUserCreatedSuccessfully" />,
      );
      state.setShowAddUserDialog(false);
      state.setNewUserForm({});
      state.setNewUserErrors({});
    }
  }, [props.tune.affiliateUserCreated]);

  useEffect(() => {
    if (props.influencer.created) {
      state.setShowAddUserDialog(false);
      NotificationManager.success(
        <IntlMessages id="tune.notification.influencerCreatedSuccessfully" />,
      );
      props.fetchUsers();
    }
  }, [props.influencer.created]);
};

export default useTune;
