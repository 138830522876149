/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState, useEffect, memo,
} from 'react';
import {
  string, arrayOf, shape, func, bool,
} from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import {Button, Card, CardContent, IconButton} from '@material-ui/core';

import {
  isEmpty, uniq, cloneDeep,
} from 'lodash';
import RowViewFilter from '../RowViewFilter';
import PredefinedFilter from '../PredefinedFilter';
import SearchFields from '../SearchFields';
import SavedFilters from '../SavedFilters';
import FiltersTargetController from '../FiltersTargetController';
import { checkAndConverteDataByFieldOperator } from '../../utils/checkAndConverteDataByFieldOperator';
import updateGroups from '../../utils/updateGroups';
import IntlMessages from '../../../../util/IntlMessages';
import {
  filterGroupValidations,
  groupConditionValidations,
} from '../../utils/validations';
import validateAll from '../../../../util/validator';
import Can from '../../../../components/Can';
import appPermissions from '../../../../util/appPermissions';
import { mapFiltersByOperator } from '../../../../util/mapFiltersByOperator';
import { getDefaultRootFilter, defaultPagination } from '../../utils/defaultData';
import {
  mergeFilters, mergeFields, getNewFilter,
} from '../../pages/Home/utils/mappers';
import { mapPrefixToFilters } from '../../../../util/filterMapper';
import { getFilterByTitle } from '../../../utils/checkFilterGroups';
import swal from "sweetalert";
import { emptySearchState, getProductFilterOptions, getAllGlobalFiltersPayload } from '../../utils/sidebar';

const RowViewSidebar = memo((props) => {
  const {
    className,
    setFilterTarget,
    filterTarget,
    displayMode,
    selectedProducts,
    prevFilterTarget,
    globalFilters,
    globalFields,
    searchQuery,
    savedFilterFields,
    savedFilterFilters,
    fullTextListModeSearch,
    selectedSavedFilter,
    setGlobalFilters,
    setGlobalFields,
    setSelectedSavedFilter,
    setSavedFilterFields,
    setSavedFilterFilters,
    setProductsListEmpty,
    setFullTextListModeSearch,
    previewFilterAttribute,
    sort,
    fromRules,
    mapperCategories,
    mapperMarketplace,
  } = props;

  const [openNewFilterDialog, setOpenNewFilterDialog] = useState(false);
  const [firstLoad, setIsFirstLoad] = useState(true);
  const [isFieldRemoved, setIsFieldRemoved] = useState(false);
  const [isPredefinedFilterRemoved, setIsPredefinedFilterRemoved] = useState(false);
  const [isFilterRemoved, setIsFilterRemoved] = useState(false);
  const [currentFilter, setCurrentFilter] = useState();
  const [showSearchFields, setShowSearchFields] = useState(false);
  const [searchFields, setSearchFields] = useState(emptySearchState);
  const [searchFieldsIsEmpty, setSearchFieldsIsEmpty] = useState(false);

  const [showPredefinedFilter, setShowPredefinedFilter] = useState(false);
  const [predefinedFilter, setPredefinedFilter] = useState(emptySearchState);
  const [predefinedFilterIsEmpty, setPredefinedFilterIsEmpty] = useState(false);

  const [isFiltersEmpty, setIsFiltersEmpty] = useState(false);
  const [settingsAreApplied, setSettingsAreApplied] = useState(
    sessionStorage.getItem('pim.settingsAreApplied')
      ? JSON.parse(sessionStorage.getItem('pim.settingsAreApplied'))
      : {
        parent: false,
        variation: false,
        merchant: false,
        feed: false,
        rule: false,
        repricing: false,
        mapper: false,
        activityLog: false,
        repricer: false,
        cams: false
      },
  )
  const [showExport, setShowExport] = useState(false);
  const [exportEmail, setExportEmail] = useState('');
  const [errorExportEmail, setErrorExportEmail] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [openedCategories, setOpenedCategories] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      submitSettings({}, false, filterTarget);
    }, 500);
  }, []);

  useEffect(() => {
    if ((filterTarget !== 'parent' && filterTarget !== 'variation' && filterTarget !== 'merchant')
      && document.location.href.indexOf('pim') !== -1) {
      setFilterTarget('parent');
    } else if (document.location.href.indexOf('feed') !== -1) {
      setFilterTarget('feed');
    } else if (document.location.href.indexOf('rule') !== -1) {
      setFilterTarget('rule');
    } else if (document.location.href.indexOf('repricing') !== -1) {
      setFilterTarget('repricing');
    } else if (document.location.href.indexOf('mapper') !== -1) {
      setFilterTarget('mapper');
    } else if (document.location.href.indexOf('activity_log') !== -1) {
      setFilterTarget('activityLog');
    } else if (
      document.location.href.indexOf('cams') !== -1 ||
      document.location.href.indexOf('errors') !== -1 ||
      document.location.href.indexOf('merchant-status') !== -1
    ) {
      setFilterTarget('cams');
    } else if (document.location.href.indexOf('repricer') !== -1 || document.location.href.indexOf('issues') !== -1) {
      setFilterTarget('repricer');
    }
  }, [filterTarget]);

  useEffect(() => {
    if (filterTarget === 'mapper') {
      const selectedCategoriesString = sessionStorage.getItem('pim.selectedMapperCategories');
      if (selectedCategoriesString) {
        const selectedCats = selectedCategoriesString.split(',');
        const selectedCategories = [];
        for (let i = 0; i < selectedCats.length; i++) {
          selectedCategories[selectedCats[i]] = true;
        }
        setSelectedCategories(selectedCategories);
      }
    }
  }, [setSelectedCategories]);

  useEffect(() => {
    const openedCategoriesString = sessionStorage.getItem('pim.openedMapperCategories');
    if (openedCategoriesString) {
      const openedCats = openedCategoriesString.split(',');
      const openedCategories = [];
      for (let i = 0; i < openedCats.length; i++) {
        if (openedCategories.indexOf(parseInt(openedCats[i])) === -1) {
          openedCategories.push(parseInt(openedCats[i]));
        }
      }
      setOpenedCategories(openedCategories);
    }
  }, [setOpenedCategories]);

  useEffect(() => {
    if (!fromRules && filterTarget && prevFilterTarget) {
      sessionStorage.setItem('pim.filterTarget', filterTarget);
    }
  }, [filterTarget]);

  useEffect(() => {
    if (isFilterRemoved || isFieldRemoved || isPredefinedFilterRemoved) {
      submitSettings({}, false, filterTarget);
    }
  }, [isFilterRemoved, isFieldRemoved, isPredefinedFilterRemoved]);

  useEffect(() => {
    const storedFilterTarget = fromRules === true ? 'variation' : sessionStorage.getItem('pim.filterTarget');
    if (
      (storedFilterTarget === 'feed'
        || storedFilterTarget === 'rule'
        || storedFilterTarget === 'repricing'
        || storedFilterTarget === 'mapper'
        || storedFilterTarget === 'activityLog'
        || storedFilterTarget === 'repricer'
        || storedFilterTarget === 'cams'
      ) && document.location.href.indexOf('pim') !== -1) {
      setFilterTarget('parent');
    } else if (storedFilterTarget) {
      setFilterTarget(storedFilterTarget);
    }

    window.addEventListener('beforeunload', handleWindowBeforeUnload);

    return () => {
      handleClearEmptyFilters();
      window.removeEventListener('beforeunload', handleWindowBeforeUnload);
    };
  }, [filterTarget]);

  useEffect(() => {
    sessionStorage.setItem('pim.settingsAreApplied', JSON.stringify(settingsAreApplied));
  }, [settingsAreApplied[filterTarget]]);

  useEffect(() => {
    if (props.reset === true) {
      clearSettings();
    }
  }, [props.reset]);

  const realFilterTarget = (document.location.href.indexOf('pim') !== -1
    && filterTarget !== 'parent'
    && filterTarget !== 'variation'
    && filterTarget !== 'merchant')
    ? 'parent'
    : filterTarget;

  const selectedMapperCats = () => {
    const selMapperCats = [];
    const selectedCategoriesString = sessionStorage.getItem('pim.selectedMapperCategories');
    if (selectedCategoriesString) {
      const selectedCats = selectedCategoriesString.split(',');
      for (let i = 0; i < selectedCats.length; i++) {
        selMapperCats[selectedCats[i]] = true;
      }
    }

    return selMapperCats;
  }

  const getData = (filterOptions) => {
    filterOptions.condition = props.condition;
    filterOptions.scroll = props.scroll;
    filterOptions.itemsPerPage = props.itemsPerPage;

    if (document.location.href.indexOf('feed') !== -1) {
      const feedsFilterOptions = props.getFeedsFilterOptions(filterOptions);
      props.getFeedsData(feedsFilterOptions);
    } else if (document.location.href.indexOf('rule') !== -1) {
      const rulesFilterOptions = props.getRulesFilterOptions(filterOptions);
      props.getRulesData(rulesFilterOptions);
    } else if (document.location.href.indexOf('repricing') !== -1) {
      const repricingFilterOptions = props.getRepricingFilterOptions(filterOptions);
      props.getRepricingData(repricingFilterOptions);
    } else if (document.location.href.indexOf('mapper') !== -1) {
      const mapperFilterOptions = props.getMapperFilterOptions(filterOptions);
      mapperFilterOptions.marketplace = mapperMarketplace;
      mapperFilterOptions.categories = selectedMapperCats().length > 0 ? selectedMapperCats() : selectedCategories;
      props.getMappingData(mapperFilterOptions);
    } else if (document.location.href.indexOf('activity_log') !== -1) {
      const activityLogOptions = props.getActivityLogFilterOptions(filterOptions);
      props.getActivityLogData(activityLogOptions);
    } else if (
      document.location.href.indexOf('cams') !== -1 ||
      document.location.href.indexOf('errors') !== -1 ||
      document.location.href.indexOf('merchant-status') !== -1
    ) {
      const camsOptions = props.getCamsFilterOptions(filterOptions);
      props.getCamsData(camsOptions);
    } else if (document.location.href.indexOf('repricer') !== -1 || document.location.href.indexOf('issues') !== -1) {
      const repricerOptions = props.getRepricerFilterOptions(filterOptions);
      props.getRepricerData(repricerOptions);
    } else {
      const productOptions = getProductFilterOptions(filterOptions, realFilterTarget);
      props.fetchProductList(productOptions);
    }
  };

  const handleClearEmptyFilters = () => {
    const operatorsWithoutValue = new Set(['exists', 'not_exists']);
    const storedSearchFilters = sessionStorage.getItem('pim.searchFilters');
    const storedSettingsAreApplied = sessionStorage.getItem('pim.settingsAreApplied');
    const parsedStoredSearchFilters = JSON.parse(storedSearchFilters) || emptySearchState;
    const parsedStoredSettingsAreApplied = JSON.parse(storedSettingsAreApplied) || emptySearchState;
    const checkedFilters = {
      ...parsedStoredSearchFilters,
      [filterTarget]: parsedStoredSearchFilters[filterTarget].filter(f => f.group && f.group
          .every(g => g.field && (!isEmpty(g.fieldOption) || operatorsWithoutValue.has(g.operator)))),
    };

    const globalFiltersPayload = parsedStoredSettingsAreApplied[filterTarget]
      ? checkedFilters
      : {
        ...parsedStoredSearchFilters,
        [filterTarget]: [],
      };

    const allGlobalFiltersPayload = getAllGlobalFiltersPayload(globalFiltersPayload);
    sessionStorage.setItem('pim.searchFilters', JSON.stringify(allGlobalFiltersPayload));
  };

  const handleWindowBeforeUnload = () => {
    handleClearEmptyFilters();
  };

  const mapListModeSearchFilters = (filters) => {
    const filteredFilters = filters.filter(f => !f.group.some(g => g.value === 'parent'));
    const mappedFilters = mapFiltersByOperator(filteredFilters, previewFilterAttribute.list);

    return mapPrefixToFilters([...mappedFilters, ...getDefaultRootFilter(filterTarget)]);
  };

  const handleUnselectAllFilters = () => {
    setSavedFilterFields({
      ...savedFilterFields,
      [filterTarget]: [],
    });
    setSavedFilterFilters({
      ...savedFilterFilters,
      [filterTarget]: [],
    });
    setProductsListEmpty();
    setFullTextListModeSearch({});
  };

  const hasFilters = !isEmpty(globalFields[filterTarget])
    || !isEmpty(globalFilters[filterTarget])
    || !isEmpty(selectedSavedFilter[filterTarget])
    || !isEmpty(selectedCategories)
    || !isEmpty(predefinedFilter[filterTarget]);
  const hasCustomFilters = !isEmpty(searchFields[filterTarget])
    || !isEmpty(globalFilters[filterTarget])
    || !isEmpty(selectedCategories)
    || !isEmpty(predefinedFilter[filterTarget]);

  const getScrollBarStyle = () => ({
    height: 'calc(100vh - 120px)',
  });

  const validateFilterGroup = (groupItem, searchFilter) => {
    const operatorsWithoutValue = new Set(['exists', 'not_exists']);
    const clonnedGroupValidations = cloneDeep(filterGroupValidations);

    if (operatorsWithoutValue.has(groupItem.operator)) {
      delete clonnedGroupValidations.fieldOption;
    }

    let groupErrors = validateAll(clonnedGroupValidations, groupItem);

    const attributeElement = searchFilter.attributes.find(
      a => a.code && (a.code.includes(groupItem.field) || a.code === groupItem.field),
    );
    const attributeElementType = attributeElement ? attributeElement.elementType : '';
    const fieldOption = groupErrors && groupErrors.fieldOption;

    if (attributeElementType === 'number') {
      groupErrors.fieldOption = fieldOption && groupErrors.fieldOption.filter(fE => fE.type !== 'maxSize');
      if (!groupErrors.fieldOption) delete groupErrors.fieldOption;
    } else {
      groupErrors.fieldOption = fieldOption && groupErrors.fieldOption.filter(fE => fE.type !== 'maxNumber');
      if (!groupErrors.fieldOption || isEmpty(groupErrors.fieldOption)) {
        delete groupErrors.fieldOption;
      }
    }

    const { operator, field } = groupItem;
    let newGroup = groupItem;
    if (field === 'locked_attributes') {
      newGroup = { field, operator };
      delete clonnedGroupValidations.fieldOption;
      groupErrors = validateAll(clonnedGroupValidations, newGroup);
    }

    return {
      groupItem: newGroup,
      errors: groupErrors,
      hasErrors: !isEmpty(groupErrors),
    };
  };

  const validateFilters = () => {
    let hasErrors = false;
    const checkedFilters = globalFilters[filterTarget].map((sf) => {
      const filterErrors = validateAll(groupConditionValidations, {
        groupCondition: sf.groupCondition,
      });
      const group = [];
      sf.group.forEach((g) => {
        const checkedGroup = validateFilterGroup(g, sf);
        group.push({
          ...checkedGroup.groupItem,
          errors: checkedGroup.errors,
        });
        if (checkedGroup.hasErrors) hasErrors = true;
      });
      if (!isEmpty(filterErrors)) hasErrors = true;
      return {
        ...sf,
        group,
        errors: filterErrors,
      };
    });
    return {
      hasErrors,
      filters: checkedFilters,
    };
  };

  const handleChangeSearchFields = () => {
    setSettingsAreApplied({
      ...settingsAreApplied,
      [filterTarget]: false,
    });
  };

  const handleChangePredefinedFilter = () => {
    setSettingsAreApplied({
      ...settingsAreApplied,
      [filterTarget]: false,
    });
  };

  const handleChangeSearchFilters = () => {
    setSettingsAreApplied({
      ...settingsAreApplied,
      [filterTarget]: false,
    });
  };

  const getRealFilterTarget = (locationHref) => {
    if (locationHref.indexOf('mapper') !== -1) {
      return 'mapper';
    } else if (locationHref.indexOf('activityLog') !== -1) {
      return 'activityLog';
    } else if (locationHref.indexOf('rule') !== -1) {
      return 'rule';
    } else if (locationHref.indexOf('feed') !== -1) {
      return 'feed';
    } else if (locationHref.indexOf('repricer') !== -1) {
      return 'repricer';
    } else if (locationHref.indexOf('repricing') !== -1) {
      return 'repricing';
    } else if (locationHref.indexOf('cams') !== -1) {
      return 'cams';
    } else {
      return filterTarget;
    }
  };

  const handleSubmitSearchParams = (params, clearAll) => {
    if (params.isExport === undefined) {
      return false;
    }

    const searchBody = { ...searchQuery };

    const realFilterTarget = getRealFilterTarget(document.location.href);

    if (firstLoad === true) {
      const storedSearchFilters = sessionStorage.getItem('pim.searchFilters');
      const parsedStoredSearchFilters = JSON.parse(storedSearchFilters) || emptySearchState;

      params.searchFilters = parsedStoredSearchFilters[realFilterTarget];

      const storedSearchFields = sessionStorage.getItem('pim.searchFields');
      const parsedStoredSearchFields = JSON.parse(storedSearchFields) || emptySearchState;

      params.searchFields = parsedStoredSearchFields[realFilterTarget];

      setIsFirstLoad(false);
    }

    const searchFilters = mapListModeSearchFilters(
      checkAndConverteDataByFieldOperator(params.searchFilters),
    );

    const storedSearchFields = params.searchFields;
    let isCustomFieldsUpdated = false;

    if (
      !isEmpty(storedSearchFields)
      || !isEmpty(savedFilterFields[realFilterTarget])
      || !isEmpty(selectedCategories || selectedMapperCats())
    ) {
      isCustomFieldsUpdated = true;
    }
    props.setIsCustomFieldsMode(isCustomFieldsUpdated);
    props.setSearchQuery(searchBody);
    props.setPagination(defaultPagination);
    props.setGlobalFields({
      ...globalFields,
      [realFilterTarget]: storedSearchFields,
    });

    const filter = getNewFilter(searchFilters);

    if (
      clearAll
      || (
        (realFilterTarget === 'parent' || realFilterTarget === 'variation' || realFilterTarget === 'merchant')
        && isEmpty(savedFilterFilters[realFilterTarget])
        && isEmpty(params.searchFilters)
        && isEmpty(fullTextListModeSearch)
        && isEmpty(storedSearchFields)
        && isEmpty(savedFilterFields[realFilterTarget])
        && isEmpty(selectedCategories || selectedMapperCats())
      )
    ) {
      props.setProductsListEmpty();
      props.setSelectedProducts([]);
      setIsFiltersEmpty(false);
    } else {
      const targetSort = sort[realFilterTarget];
      let targetSortQuery = {};
      if (!isEmpty(targetSort)) {
        targetSortQuery = {
          sort: [{
            field: targetSort.field,
            order: targetSort.order,
          }],
        };
      }

      const storedSearchFilters = sessionStorage.getItem('pim.searchFilters');
      const parsedStoredSearchFilters = JSON.parse(storedSearchFilters) || emptySearchState;
      const hasEmptyFilter = (parsedStoredSearchFilters[realFilterTarget].length === 0)
        && (predefinedFilter[realFilterTarget].length === 0);

      const searchInput = document.getElementById('search-input-lg');
      const fullTextValue = searchInput ? searchInput.value : '';
      const allFilters = getFilterByTitle(fullTextValue, filter, fullTextListModeSearch);

      if (!hasEmptyFilter) {
        const filterOptions = {
          viewMode: 'backend',
          filter: mapListModeSearchFilters(
            mergeFilters(
              savedFilterFilters[realFilterTarget],
              allFilters.filter,
            ),
          ),
          pagination: defaultPagination,
          searchFilter: searchBody,
          fields: isCustomFieldsUpdated
              ? mergeFields(savedFilterFields[realFilterTarget], storedSearchFields)
              : [],
          fullText: (allFilters.fullText ? allFilters.fullText : {}),
          ...targetSortQuery,
          predefinedFilter: predefinedFilter[realFilterTarget],
          storedSearchFields: storedSearchFields,
          isExport: params.isExport,
          exportEmail: exportEmail,
        };

        getData(filterOptions);
      }
    }
  };

  const validateFilterSettings = () => {
    const checkedFilters = validateFilters();
    handleChangeSearchFilters(checkedFilters.filters);
    return ({ checkedFilters });
  };

  const validateSidebarFilters = () => {
    const res = validateFilterSettings();
    const globalFiltersPayload = {
      ...globalFilters,
      [filterTarget]: res.checkedFilters.filters,
    };

    const allGlobalFiltersPayload = getAllGlobalFiltersPayload(globalFiltersPayload);
    props.setGlobalFilters(allGlobalFiltersPayload);
    sessionStorage.setItem('pim.searchFilters', JSON.stringify(allGlobalFiltersPayload));

    setIsFieldRemoved(false);
    setIsFilterRemoved(false);
    setIsPredefinedFilterRemoved(false);
  };

  const submitSettings = (e, isExport, filterTarget) => {
    let filterValid = true;
    const res = validateFilterSettings();
    const hasFileds = !isEmpty(searchFields[filterTarget]);
    validateSidebarFilters();

    const convertedFilters = checkAndConverteDataByFieldOperator(
      [...globalFilters[filterTarget]],
    );

    const mappedFilters = convertedFilters.map((s) => {
      const newGroups = updateGroups(s.group);
      return ({
        groupCondition: s.groupCondition,
        group: [...newGroups, ...s.groupSearchFields || []].filter(f => f),
      });
    }).flat().filter(f => !isEmpty(f.group));

    if (searchFields[filterTarget].length > 0) {
      sessionStorage.setItem('pim.searchFields', JSON.stringify(searchFields));
    }

    if (!isEmpty(res.checkedFilters.filters)) {
      res.checkedFilters.filters.forEach((f) => {
        if (isEmpty(f.group) && !f.selectedTreeCategories) {
          setIsFiltersEmpty(true);
          filterValid = false;
        } else {
          setIsFiltersEmpty(false);
        }
      });
    }

    if (!hasFileds && showSearchFields) {
      setSearchFieldsIsEmpty(true);
      setPredefinedFilterIsEmpty(true);
    }
    if (
      (!res.checkedFilters.hasErrors || filterTarget === 'mapper' || filterTarget === 'repricer')
      && filterValid
      && (hasFileds || !showSearchFields)
    ) {
      setSearchFieldsIsEmpty(false);
      setPredefinedFilterIsEmpty(false);

      handleSubmitSearchParams({
        searchFilters: mappedFilters,
        searchFields: uniq(searchFields[filterTarget]),
        predefinedFilter: predefinedFilter[filterTarget],
        isExport: isExport,
        filterTarget: filterTarget,
      });

      if (!isEmpty(selectedCategories) || !isEmpty(searchFields[filterTarget])) {
        setSettingsAreApplied({
          ...settingsAreApplied,
          [filterTarget]: true,
        });
      }
    }

    setIsFieldRemoved(false);
    setIsFilterRemoved(false);
    setIsPredefinedFilterRemoved(false);
  };

  const handleRemoveSearchFilter = () => {
    setIsFilterRemoved(true);
    setIsFiltersEmpty(false);

    if (isEmpty(globalFilters[filterTarget])) {
      setSettingsAreApplied({
        ...settingsAreApplied,
        [filterTarget]: false,
      });
    }
  };

  const clearSettings = () => {
    const globalFiltersPayload = { ...globalFilters, [filterTarget]: [] };
    const globalFieldsPayload = { ...globalFields, [filterTarget]: [] };
    const selectedSavedFilterPayload = { ...selectedSavedFilter, [filterTarget]: [] };
    const settingsAreAppliedPayload = { ...settingsAreApplied, [filterTarget]: false };
    setGlobalFilters(globalFiltersPayload);
    setGlobalFields(globalFieldsPayload);
    setSelectedSavedFilter(selectedSavedFilterPayload);
    setSettingsAreApplied(settingsAreAppliedPayload);
    setShowSearchFields(false);
    setSearchFieldsIsEmpty(false);
    setIsFieldRemoved(false);
    setSelectedCategories([]);
    setPredefinedFilter([]);
    setPredefinedFilterIsEmpty(false);
    setShowPredefinedFilter(false);
    setIsPredefinedFilterRemoved(false);
    setPredefinedFilter({
      ...predefinedFilter,
      [filterTarget]: [],
    });
    props.setSelectedProducts([]);
    sessionStorage.setItem('pim.selectedMapperCategories', null);
    handleUnselectAllFilters();

    if (document.location.href.indexOf('feed') !== -1) {
      props.getFeedsData({limit: props.itemsPerPage, offset: 0});
    } else if (document.location.href.indexOf('rule') !== -1) {
      props.getRulesData({limit: props.itemsPerPage, offset: 0});
    } else if (document.location.href.indexOf('repricing') !== -1) {
      props.getRepricingData({limit: props.itemsPerPage, offset: 0});
    } else if (document.location.href.indexOf('mapper') !== -1) {
      props.getMappingData({limit: props.itemsPerPage, offset: 0, marketplace: mapperMarketplace});
    } else if (document.location.href.indexOf('activity_log') !== -1) {
      props.getActivityLogData({limit: props.itemsPerPage, offset: 0});
    } else if (
      document.location.href.indexOf('cams') !== -1 ||
      document.location.href.indexOf('errors') !== -1 ||
      document.location.href.indexOf('merchant-status') !== -1
    ) {
      props.getCamsData({from: 0, size: props.itemsPerPage, sort: [{ date: { order: 'desc' } }]});
    } else if (document.location.href.indexOf('repricer') !== -1 || document.location.href.indexOf('issues') !== -1) {
      props.getRepricerData({limit: props.itemsPerPage, offset: 0});
    }

    sessionStorage.setItem('pim.searchFields', JSON.stringify(globalFieldsPayload));
    sessionStorage.setItem('pim.searchFilters', JSON.stringify(globalFiltersPayload));
    sessionStorage.setItem('pim.selectedFilter', JSON.stringify(selectedSavedFilterPayload));
    sessionStorage.setItem('pim.settingsAreApplied', JSON.stringify(settingsAreAppliedPayload));

    setTimeout(() => {
      const searchInput = document.getElementById('search-input-lg');
      if (searchInput) {
        searchInput.value = '';
      }
    }, 500);
  };

  const saveFilterSettings = () => {
    const res = validateFilterSettings();
    const hasFileds = !isEmpty(searchFields[filterTarget]);
    if (!hasFileds && showSearchFields) setSearchFieldsIsEmpty(true);
    validateSidebarFilters();

    if (!res.checkedFilters.hasErrors && (hasFileds || !showSearchFields)) {
      setOpenNewFilterDialog(true);
      setSearchFieldsIsEmpty(false);
    }
  };

  const handleSubmitScopeDialog = (selectedList, filterKey) => {
    const uniqueSelectedList = uniq([...selectedList]);

    if (!isEmpty(uniqueSelectedList)) {
      props.fetchScopeCategoriesByIds(uniqueSelectedList);
    } else {
      props.clearFromScopeCategoryState({
        categoriesByIds: [],
      });
    }
    setCurrentFilter(filterKey);
  };

  const handleChangeFilterTarget = (target) => {
    if (!isEmpty(selectedProducts)) props.setSelectedProducts([]);
    setFilterTarget(target);
    setTimeout(() => {
      submitSettings({}, false, target);
    }, 1000);
  };

  const handleShowExport = (e) => {
    setExportEmail('');
    setErrorExportEmail('');
    if (showExport === false) {
      setShowExport(true);
    } else {
      setShowExport(false);
    }
  }

  const handleChangeExportEmail = (e) => {
    const { target } = e;
    setExportEmail(target.value);
  }

  const handleExport = (e) => {
    const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!exportEmail || !re.test(exportEmail)) {
      setErrorExportEmail('has-error');
    } else {
      setErrorExportEmail('');
      submitSettings(e, true, filterTarget);
      setExportEmail('');
      setShowExport('');

      return swal({
        title: 'Data export is queued',
        text: 'The data you selected will be sent to your E-mail: ' + exportEmail,
        icon: 'warning',
        dangerMode: true,
      });
    }
  }

  const searchHelperText = isFiltersEmpty && filterTarget !== 'mapper'
    ? (
      <span className="helper error text-danger">
        <IntlMessages id="pim.filter.isEmpty" />
      </span>
    )
    : '';

  const filterTargetOptions = [{
    label: 'Parent',
    value: 'parent',
  }, {
    label: 'Variation',
    value: 'variation',
  }, {
    label: 'Merchant',
    value: 'merchant',
  }];

  return (
    <>
      <Scrollbars
        className="rct-scroll shadow-bottom block-selected-item"
        autoHide
        autoHideDuration={100}
        style={getScrollBarStyle()}
      >
        <div className={`pim-sidebar preview-sidebar ${className}`}>
          {
            !fromRules
            && filterTarget !== 'feed'
            && filterTarget !== 'rule'
            && filterTarget !== 'repricing'
            && filterTarget !== 'mapper'
            && filterTarget !== 'activityLog'
            && filterTarget !== 'cams'
            && filterTarget !== 'repricer'
            && (
            <FiltersTargetController
              label="Filter target"
              value={filterTarget}
              options={filterTargetOptions}
              className="filter-target-controller"
              handleChange={handleChangeFilterTarget}
              displayMode={displayMode}
            />
          )}
          <SavedFilters
            openNewFilterDialog={openNewFilterDialog}
            setOpenNewFilterDialog={setOpenNewFilterDialog}
            setSearchFields={setSearchFields}
            searchFields={searchFields}
            setShowSearchFields={setShowSearchFields}
            emptySearchState={emptySearchState}
            getData={getData}
          />
          <RowViewFilter
            onChange={handleChangeSearchFilters}
            selectClassName={
              globalFilters[filterTarget] && globalFilters[filterTarget].length < 3
                ? 'dropdown'
                : 'dropup'
            }
            setFilterTarget={setFilterTarget}
            helperText={searchHelperText}
            onRemoveSearchFilter={handleRemoveSearchFilter}
            onSubmitScopeDialog={handleSubmitScopeDialog}
            currentFilter={currentFilter}
            settingsAreApplied={settingsAreApplied}
            handleSubmitSearchParams={handleSubmitSearchParams}
            sortedSearchFields={props.sortedSearchFields}
            mapperCategories={mapperCategories}
            mapperMarketplace={mapperMarketplace}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            openedCategories={openedCategories}
            setOpenedCategories={setOpenedCategories}
            emptySearchState={emptySearchState}
          />
          {(filterTarget === 'activityLog' || filterTarget === 'repricer') && (
            <PredefinedFilter
              onChange={handleChangePredefinedFilter}
              showPredefinedFilter={showPredefinedFilter}
              setShowPredefinedFilter={setShowPredefinedFilter}
              sortedFilters={props.sortedFilters}
              setIsPredefinedFilterRemoved={setIsPredefinedFilterRemoved}
              settingsAreApplied={settingsAreApplied}
              setPredefinedFilter={setPredefinedFilter}
              predefinedFilter={predefinedFilter}
              error={predefinedFilterIsEmpty}
            />
          )}
          {filterTarget !== 'repricer' && (
            <SearchFields
              onChange={handleChangeSearchFields}
              showSearchFields={showSearchFields}
              setShowSearchFields={setShowSearchFields}
              sortedFields={props.sortedFields}
              setIsFieldRemoved={setIsFieldRemoved}
              settingsAreApplied={settingsAreApplied}
              setSearchFields={setSearchFields}
              searchFields={searchFields}
              error={searchFieldsIsEmpty}
            />
          )}
          {document.location.href.indexOf('pim') !== -1 && (
            <div className="form-group-field">
              <div className={`form-group-title pt-10 pb-10  ${className}`}>
                <span className="title">
                  <IntlMessages id="preview.productList.exportProductsLabel" />
                </span>
                <IconButton
                  aria-label="Add"
                  color="primary"
                  className={`btn-mui-xs pos-rel ${showExport
                    ? 'text-danger'
                    : 'text-success'}`}
                  onClick={e => handleShowExport(e)}
                >
                  {showExport
                    ? <i className="ti-minus" />
                    : <i className="ti-plus" />
                  }
                </IconButton>
              </div>
              {showExport && (
                <Card className={`card-xs ${errorExportEmail}`}>
                  <>
                    <div className="export-description">
                      <IntlMessages id="export.description" />
                    </div>
                    <CardContent className="flex items-center">
                      <input
                        type="text"
                        onChange={e => handleChangeExportEmail(e)}
                        name="exportEmail"
                        defaultValue={exportEmail}
                        placeholder="E-mail"
                        className="export-products-input"
                      />
                    </CardContent>
                    <CardContent className="flex items-center">
                      <Button
                        variant="contained"
                        color="primary"
                        className="text-white block btn-xs"
                        onClick={e => handleExport(e)}
                        disabled={!hasCustomFilters}
                      >
                        <IntlMessages id="button.export" />
                      </Button>
                    </CardContent>
                  </>
                </Card>
              )}
            </div>
          )}
        </div>
        <div className="sidebar-group sidebar-group-fixed direction-column">
          <div className="flex buttons-row">
            <Can
              do={appPermissions.pim.permissions.update}
              on={appPermissions.pim.name}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="text-white block btn-xs"
                onClick={saveFilterSettings}
                disabled={!hasCustomFilters}
              >
                <IntlMessages id="button.save" />
              </Button>
            </Can>
            <Button
              variant="contained"
              color="primary"
              className="text-white block btn-xs"
              onClick={e => submitSettings(e, false, filterTarget)}
              disabled={!hasCustomFilters}
            >
              <IntlMessages id="button.apply" />
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="text-white block btn-xs"
              onClick={clearSettings}
              disabled={!hasFilters}
            >
              <IntlMessages id="button.clear" />
            </Button>
          </div>
        </div>
      </Scrollbars>
    </>
  );
});

RowViewSidebar.propTypes = {
  className: string,
  selectedProducts: arrayOf(shape()).isRequired,
  globalFilters: shape().isRequired,
  globalFields: shape().isRequired,
  selectedSavedFilter: shape().isRequired,
  searchQuery: shape().isRequired,
  previewFilterAttribute: arrayOf(shape()).isRequired,
  savedFilterFields: shape().isRequired,
  savedFilterFilters: shape().isRequired,
  fullTextListModeSearch: shape().isRequired,
  sort: shape().isRequired,

  fetchScopeCategoriesByIds: func.isRequired,
  clearFromScopeCategoryState: func.isRequired,
  setFilterTarget: func.isRequired,
  filterTarget: string.isRequired,
  prevFilterTarget: string.isRequired,
  displayMode: string.isRequired,
  setSelectedProducts: func.isRequired,
  setGlobalFilters: func.isRequired,
  setGlobalFields: func.isRequired,
  setSelectedSavedFilter: func.isRequired,
  setSavedFilterFields: func.isRequired,
  setSavedFilterFilters: func.isRequired,
  setProductsListEmpty: func.isRequired,
  setFullTextListModeSearch: func.isRequired,
  setIsCustomFieldsMode: func.isRequired,
  setSearchQuery: func.isRequired,
  setPagination: func.isRequired,
  fetchProductList: func.isRequired,
  getFeedsData: func,
  getFeedsFilterOptions: func,
  getRulesData: func,
  getRulesFilterOptions: func,
  getRepricingData: func,
  getRepricingFilterOptions: func,
  getMappingData: func,
  getMapperFilterOptions: func,
  getActivityLogData: func,
  getActivityLogFilterOptions: func,
  getRepricerData: func,
  getRepricerFilterOptions: func,
  getCamsData: func,
  getCamsFilterOptions: func,
  sortedSearchFields: shape(),
  fromRules: bool,
  mapperCategories: shape(),
};

RowViewSidebar.defaultProps = {
  className: '',
};

export default RowViewSidebar;
