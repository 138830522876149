import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ToggleButtonGroup } from '@material-ui/lab';

export const useStyles = makeStyles(() => ({
  label: {
    fontSize: '14px',
  },
  paper: {
    display: 'flex',
    alignItems: 'baseline',
    backgroundColor: 'transparent',
  },
  buttonGroup: {
    marginLeft: '10px',
    backgroundColor: 'transparent',
  },
}));

export const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {
    margin: 0,
    height: '27px',
    minWidth: '28px',
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

export default {
  useStyles,
  StyledToggleButtonGroup,
};
