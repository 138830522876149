/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import {
  func, string,
} from 'prop-types';
import { IconButton } from '@material-ui/core';

import Select from '../../../../components/Select';
import navigationTypes from '../../../../util/constants/navigationTypes';

const DrawerHeader = memo((props) => {
  const {
    id, name, onClose, type, handleNavigationTypeChange,
  } = props;

  return (
    <div className="preview-drawer-header">
      <span className="preview-drawer-header-title">{name}</span>
      <div className="type-selector-wrapper">
        <span className="preview-drawer-header-subtitle">{id}</span>
        <span className="vertical-separator">|</span>
        <Select
          value={type}
          options={navigationTypes}
          onChange={handleNavigationTypeChange}
          name="type"
          selectClassName="simple-select select-xs"
          className="select-wrapper select-underline-hide"
          disabled
        />
      </div>
      <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
        <i className="ti-close" />
      </IconButton>
    </div>
  );
});

DrawerHeader.propTypes = {
  id: string,
  name: string,
  type: string,
  onClose: func.isRequired,
  handleNavigationTypeChange: func.isRequired,
};

DrawerHeader.defaultProps = {
  id: '',
  name: '',
  type: '',
};

export default DrawerHeader;
