import React, { useState } from 'react';
import {
  func
} from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import IntlMessages from '../../../../util/IntlMessages';
import { getRepricingData, exportRepricingData } from '../../../../actions/repricer';
import { Button, IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';

export const getSearchValue = (searchValue, field) => {
  let operator = 'eq';

  if (field === 'title.en.keyword') {
    if (searchValue.substring(0, 1) === '"' && searchValue.slice(-1) === '"') {
      searchValue = searchValue.slice(1,-1);
    } else {
      searchValue = '*' + searchValue + '*';
      operator = 'like';
    }
  }

  return {
    'field' : field,
    'value' : searchValue,
    'operator' : operator
  };
};

export const Search = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchError, setSearchError] = useState('');

  function explode(delimiter, string)
  {
    const emptyArray = {0: ''};

    if ( arguments.length !== 2
      || typeof arguments[0] === 'undefined'
      || typeof arguments[1] === 'undefined' )
    {
      return null;
    }

    if ( delimiter === ''
      || delimiter === false
      || delimiter === null )
    {
      return false;
    }

    if ( typeof delimiter === 'function'
      || typeof delimiter === 'object'
      || typeof string === 'function'
      || typeof string === 'object' )
    {
      return emptyArray;
    }

    if ( delimiter === true ) {
      delimiter = '1';
    }

    return string.toString().split (delimiter.toString());
  }

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      getMapperData(e.target.value, 0);
    }
  };

  const getSearch = (e) => {
    getMapperData(searchValue, 0);
  };

  const getReset = (e) => {
    setSearchValue('');
    setSearchError('');
    props.getRepricingData({limit: props.mapperItemsPerPage, offset: 0, reset: true});
  };

  const simpleSearch = (searchValue, offset, sort) => {
    let formatFilter = [];
    if (props.mapCondition.filter) {
      for (let i = 0; i < props.mapCondition.filter.length; i++) {
        let count = 0;
        for (let j = 0; j < props.mapCondition.filter[i].group.length; j++) {
          const group = props.mapCondition.filter[i].group;
          if (group[j] && group[j].field !== 'title.en.keyword' && group[j].field !== 'pub_aopi' && group[j].field !== 'id') {
            if (!formatFilter[i]) {
              formatFilter[i] = {};
            }
            if (!formatFilter[i].group) {
              formatFilter[i].group = [];
            }
            formatFilter[i].group[count] = props.mapCondition.filter[i].group[j];
            count++;
          }
        }
      }
    }

    if (searchValue) {
      if (formatFilter.length > 0) {
        for (let i = 0; i < formatFilter.length; i++) {
          formatFilter[i].group[formatFilter[i].group.length] = getSearchValue(searchValue, 'title.en.keyword');
          formatFilter[i].group[formatFilter[i].group.length] = getSearchValue(searchValue, 'pub_aopi');
          formatFilter[i].group[formatFilter[i].group.length] = getSearchValue(searchValue, 'id');
          formatFilter[i].condition = 'or';
        }

      } else {
        formatFilter[0] = {
          'group': [
            getSearchValue(searchValue, 'title.en.keyword'),
            getSearchValue(searchValue, 'pub_aopi'),
            getSearchValue(searchValue, 'id')
          ],
          'condition' : 'or'
        };
      }
    }

    if (formatFilter && formatFilter.length === 0) {
      formatFilter = null;
    }

    props.getRepricingData({limit: props.mapperItemsPerPage, offset: offset, filter: formatFilter, sort: sort});
  };

  const prepareSearchString = (searchString, operator = 'eq') => {
    const res = explode(':', searchString);

    let field = res[0] ? res[0].trim() : '';
    let value = res[1] ? res[1].trim() : '';

    if (field === '' || value === '') {
      let error = 'Search parsing error';
      if (field) {
        error += ': field "' + field + '" has no value';
      } else if (value) {
        error += ': value "' + value + '" has no field';
      }

      return {
        'error': error
      };
    }

    let error = true;
    const repricingFieldsKeys = Object.keys(props.repricingFields);
    for (let i = 0; i < repricingFieldsKeys.length; i++) {
      if (field === 'categories' || repricingFieldsKeys[i] === field) {
        error = false;
      }
    }
    if (error) {
      return {
        'error': 'Search parsing error: field "' + field + '" does not exist'
      };
    }

    if (field === 'title.en.keyword') {
      value = '%' + value + '%';
      operator = 'like';
    }

    return {
      'field': field,
      'value': value,
      'operator' : operator
    };
  };

  const smartSearch = (searchValue, offset, sort) => {
    if (props.mapCondition.filter) {
      for (let i = 0; i < props.mapCondition.filter.length; i++) {
        for (let j = 0; j < props.mapCondition.filter[i].group.length; j++) {
          const group = props.mapCondition.filter[i].group;
          if (group[j] && group[j].field === 'categories') {
            searchValue += ' && categories: ' + group[j].value;
          }
        }
      }
    }

    let error = false;

    const formatFilter = [];
    const orArray = explode('||', searchValue);
    for (let i = 0; i < orArray.length; i++) {
      if (orArray[i].indexOf('&&') === -1) {
        let preparedSearch = prepareSearchString(orArray[i]);
        if (preparedSearch.error) {
          error = true;
          setSearchError(preparedSearch.error);
        }

        formatFilter[i] = {
          'group': [preparedSearch]
        };

      } else {
        const andArray = explode('&&', orArray[i]);
        let group = [];
        for (let j = 0; j < andArray.length; j++) {
          let preparedSearch = prepareSearchString(andArray[j]);
          if (preparedSearch.error) {
            error = true;
            setSearchError(preparedSearch.error);
          }

          group[j] = preparedSearch;
        }

        formatFilter[i] = {
          'group': group
        };
      }
    }

    if (error === false) {
      props.getRepricingData({limit: props.mapperItemsPerPage, offset: offset, filter: formatFilter, sort: sort});
    }
  };

  const getMapperData = (searchValue, offset) => {
    setSearchError('');
    const sort = props.mapCondition.sort ? props.mapCondition.sort : null;

    if (searchValue.indexOf(':') !== -1 || searchValue.indexOf('&&') !== -1 || searchValue.indexOf('||') !== -1) {
      smartSearch(searchValue, offset, sort);
    } else {
      simpleSearch(searchValue, offset, sort);
    }
  };

  const exportRepricingData = (e) => {
    const filter = props.mapCondition.filter ? props.mapCondition.filter : null;
    const sort = props.mapCondition.sort ? props.mapCondition.sort : null;

    props.exportRepricingData({filter: filter, sort: sort});
  };

  return (
    <>
      <div className="product-heading product-header flex justify-between items-center">
        <div className="mapper-search-wrapper float-right">

          <div className="search-input-lg block">
            <OutlinedInput
              id="search-input-lg"
              type="search"
              className="search-input-lg float-left mapper-search-form"
              placeholder="Search"
              onChange={handleSearchChange}
              disabled=""
              value={searchValue}
              onKeyPress={handleSearchKeyPress}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={getSearch}
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )}
            />

          </div>

        </div>

        <Button
          variant="contained"
          color="primary"
          size="medium"
          className="text-white float-right"
          onClick={getReset}
        >
          <IntlMessages id="buttons.reset" />
        </Button>

        &nbsp;&nbsp;&nbsp;

        <Button
          variant="contained"
          color="primary"
          size="medium"
          className="text-white green-color float-right"
          onClick={exportRepricingData}
        >
          <IntlMessages id="buttons.export" />
        </Button>

      </div>

      <div className="mapper-search-error">{ searchError }</div>

    </>
  );
};

Search.propTypes = {
  getRepricingData: func.isRequired,
  exportRepricingData: func.isRequired,
};

Search.defaultProps = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  getRepricingData,
  exportRepricingData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
