/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useTune = (props) => {
  useEffect(() => {
    const {
      influencer,
      tuneSettings,
      createdOffer,
    } = props;

    if (props.offerCreated) {
      props.createTuneGoal({
        data: {
          name: `${influencer.page.storeName} goal`,
          offerId: createdOffer.id,
          payoutType: tuneSettings.goal.payoutType,
          percentPayout: tuneSettings.goal.percentPayout,
          maxPercentPayout: tuneSettings.goal.maxPercentPayout,
          status: tuneSettings.goal.status,
          protocol: tuneSettings.goal.protocol,
        },
      });
      props.generateTuneTrackingUrls({
        data: {
          offerId: createdOffer.id,
          affiliateId: influencer.affiliateId,
        },
      });
    }
  }, [props.offerCreated]);
};

export default useTune;
