export default {
  admin: {
    name: 'admin',
    title: 'Admin',
  },
  influencer: {
    name: 'influencer',
    title: 'Influencer',
  },
  reader: {
    name: 'reader',
    title: 'Reader',
  },
};
