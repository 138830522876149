/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useRankingEffect = ({
  virtualCategoryFetched, virtualCategoryItem, setFacets,
}) => {
  useEffect(() => {
    if (virtualCategoryFetched) {
      setFacets(virtualCategoryItem.virtualParams.facets);
    }
  }, [virtualCategoryFetched]);
};

export default useRankingEffect;
