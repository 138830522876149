import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  string, shape, node, bool,
} from 'prop-types';

import Header from '../Header/previewHeader';
import GlobalLoader from '../GlobalLoader';

const RootPreviewLayout = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => () => {
    window.removeEventListener('resize', updateDimensions);
  }, []);

  const className = windowWidth > 1199 && props.horizontalMenu ? 'sidebar-in' : 'sidebar-out';

  return (
    <div className={`app ${props.wrapperClassName}`}>
      <div className={`app-main-container crm-layout sidebar-preview ${className}`}>
        <div className={`app-container ${props.containerWrapperClassName}`}>
          <div className="rct-app-content">
            <div className="app-header">
              <Header
                className={props.header.className}
                content={props.header.content}
                controls={props.header.controls}
                subHeaderContent={props.header.subHeaderContent}
                horizontalMenu={props.horizontalMenu}
              />
            </div>
            <div className="rct-page rct-page-grid">
              <div className={`rct-sidebar sidebar-wrapper ${props.sidebar.className || ''}`}>
                {props.sidebar.content}
              </div>
              <div className={`main-content relative ${props.main.className || ''}`}>
                {props.main.content}
              </div>
            </div>
          </div>
        </div>
      </div>
      <GlobalLoader />
    </div>
  );
};

RootPreviewLayout.propTypes = {
  className: string,
  header: shape({
    content: node,
    className: string,
  }),
  sidebar: shape({
    className: string,
    content: node,
  }),
  main: shape(),
  horizontalMenu: bool,
  wrapperClassName: string,
  containerWrapperClassName: string,
};

RootPreviewLayout.defaultProps = {
  className: '',
  header: {},
  sidebar: {},
  main: {},
  horizontalMenu: false,
  wrapperClassName: '',
  containerWrapperClassName: '',
};

export default withRouter(RootPreviewLayout);
