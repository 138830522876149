/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { NotificationManager } from 'react-notifications';

const usePim = ({
  pimProps, actions,
}) => {
  useEffect(() => {
    if (pimProps.bulkDeleteProductLockedAttributesFinished && !isEmpty(pimProps.selectedProducts)) {
      actions.setSelectedProducts([]);
    }
  }, [pimProps.bulkDeleteProductLockedAttributesFinished]);

  useEffect(() => {
    if (pimProps.bulkDeleteProductLockedAttributesError) {
      NotificationManager.error(pimProps.bulkDeleteProductLockedAttributesError);
    }
  }, [pimProps.bulkDeleteProductLockedAttributesError]);
};

export default usePim;
