/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  shape, func, node, string,
} from 'prop-types';
import { Drawer } from '@material-ui/core';

import SearchForm from 'components/Header/SearchForm';
import Can from 'components/Can';
import appPermissions from 'util/appPermissions';

const MainSidebar = props => (
  <Can
    do={appPermissions.preview.permissions.view} // TODO: Should be managed for different views as a reusable component
    on={appPermissions.preview.name}
  >
    <Drawer
      className={`sidebar-wrapper scroll-light ${props.className}`}
      variant="permanent"
      anchor="left"
    >
      <div className="sidebar-sticky-block">
        {props.search.onChange && (
          <SearchForm
            value={props.search.value}
            onChange={props.search.onChange}
            className="block"
            error={props.search.errors}
          />
        )}
        {props.actions.content && (
          <div className={`sidebar-group sidebar-group-${props.actions.position}`}>
            {props.actions.content}
          </div>
        )}
      </div>
      {props.children}
    </Drawer>
  </Can>
);

MainSidebar.propTypes = {
  className: string,
  children: node,
  search: shape({
    onChange: func,
    errors: shape(),
  }),
  actions: shape({
    position: string,
    content: node,
  }),
};

MainSidebar.defaultProps = {
  className: 'sidebar-inner-wrapper-md',
  children: null,
  search: {},
  actions: {
    position: 'relative',
    content: null,
  },
};

export default MainSidebar;
