import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import IntlMessages from '../../../../util/IntlMessages';
import Pagination from "../../../../components/Pagination";
import PagePlaceholder from "../../../../components/PagePlaceholder";
import { initialTableScroll, moveTableScroll } from "../../../../util/table";

const CamsTable = ({
  getCamsData,
  fields,
  data,
  camsActions,
  camsActionsOptions,
  camsScroll,
  camsCondition,
  itemsPerPages,
  itemsPerPage,
}) => {
  const [camsData, setCamsData] = useState([]);
  const [allPages, setAllPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const formatData = [];
    if (data.hits && data.hits.hits) {
      for (let i = 0; i < data.hits.hits.length; i++) {
        formatData[i] = data.hits.hits[i]._source;
      }
    }
    setCamsData(formatData);
    setAllPages(Math.ceil(camsScroll.total / itemsPerPage));
    const currentPage = Math.ceil(camsScroll.offset / itemsPerPage) + 1;
    setCurrentPage(currentPage);
  }, [
    data, camsScroll, itemsPerPage
  ]);

  const tableData = [];
  for (let i = 0; i < camsData.length; i++) {
    const dates = [];
    const actions = [];
    const errors = [];

    for (let j = 0; j < camsActions.length; j++) {
      const prodStat = camsData[i].product_status[camsActions[j]];
      if (prodStat && prodStat.status === 'error') {
        dates.push(prodStat.date);
        actions.push(camsActions[j]);
        errors.push(prodStat.error_reason);
      }
    }

    tableData[i] = {
      affected_id: camsData[i].affected_id,
      action: actions,
      date: dates,
      info: camsData[i].info,
      error: errors,
    }
  }

  const getTableRowColor = (itemKey) => {
    return itemKey%2 ? '#ffffff' : '#DFDFDF';
  };

  const CamsTitle = (props) => {
    return (
      <TableCell className="cams-no-wrap mapper-td" style={{width: props.width}}>
        <strong>{props.name}</strong>
      </TableCell>
    );
  };

  const CamsArrayItem = (props) => {
    let val = props.item ? props.item.toString() : '';
    if (!val) {
      return (<></>);
    }

    if (props.type === 'date') {
      val = val.replace('T', ' ').substr(0, 19);
    } else if (props.type === 'camelize') {
      val = val.replace(/_/g, ' ');
      val = val.charAt(0).toUpperCase() + val.slice(1);
    }

    if (props.count === props.itemkey + 1) {
      return (
        <div className="cams-arr-div"><strong>{ val }</strong></div>
      );
    }

    return (
      <div className="cams-arr-div">{ val }</div>
    );
  };

  const CamsItem = (props) => {
    let tableValue = props.parent ? props.item[props.parent][props.value] : props.item[props.value];

    if (Array.isArray(tableValue)) {
      return (
        <TableCell className="cams-errors-td">
          {tableValue.map((item, itemKey) => (
            <CamsArrayItem
              key={'arr_' + itemKey}
              count={tableValue.length}
              itemkey={itemKey}
              item={item}
              type={props.type}
            />
          ))}
        </TableCell>
      );
    }

    if (props.type === 'date') {
      tableValue = tableValue.toString().replace('T', ' ').substr(0, 19);
    } else if (props.type === 'camelize') {
      tableValue = tableValue.replace(/_/g, ' ');
      tableValue = tableValue.charAt(0).toUpperCase() + tableValue.slice(1);
    }

    return (
      <TableCell className="cams-errors-td">{ tableValue }</TableCell>
    );
  };

  initialTableScroll(
    'camsTable',
    'camsTableScroll',
    'camsTableContainer',
    'camsTableContainerScroll'
  );

  const moveScroll = () => {
    moveTableScroll('camsTableContainer', 'camsTableContainerScroll');
  };

  return (
    <>
      {tableData && tableData.length > 0 ? (
        <>
          <div id="camsTableScrollDiv" className="mapper-table-scroll">
            <TableContainer
              id="camsTableContainerScroll"
              className="product-table-container"
              onScroll={moveScroll}
            >
              <Table id="camsTableScroll">
                <TableHead className="products-table-header">
                  <TableRow><TableCell>&nbsp;</TableCell></TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </div>

          <TableContainer id="camsTableContainer" className="product-table-container-no-scroll">
            <Table id="camsTable" stickyHeader aria-label="sticky table" size="small">
              <TableHead className="products-table-header">
                <TableRow>
                  {Object.keys(fields).map((item, itemKey) => (
                    <CamsTitle
                      key={'title_' + itemKey}
                      itemkey={itemKey}
                      item={item}
                      condition={camsCondition}
                      itemsPerPage={itemsPerPage}
                      type={Object.values(fields)[itemKey].type}
                      name={Object.values(fields)[itemKey].name}
                      width={Object.values(fields)[itemKey].width}
                    />
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {tableData && tableData.map((item, itemKey) => (
                  <TableRow key={'row_' + itemKey} style={{backgroundColor: getTableRowColor(itemKey)}}>
                    {Object.keys(fields).map((value, valueKey) => (
                      <CamsItem
                        key={'item_' + itemKey + '_' + valueKey}
                        type={Object.values(fields)[valueKey].type}
                        parent={Object.values(fields)[valueKey].parent}
                        item={item}
                        itemKey={itemKey}
                        value={value}
                        valueKey={valueKey}
                      />
                    ))}
                  </TableRow>
                ))}
              </TableBody>

            </Table>
          </TableContainer>
        </>
      ) : (
        <TableRow>
          <TableCell className="feed-no-border">
            <PagePlaceholder
              icon={<i className="ti-face-sad text-primary text-lg" />}
              title={<IntlMessages id="title.sorry" />}
              subtitle={<IntlMessages id="title.noResultsFound" />}
            />
          </TableCell>
        </TableRow>
      )}

      <Pagination
        type="cams"
        getData={getCamsData}
        dataCount={tableData ? tableData.length : 0}
        itemsPerPages={itemsPerPages}
        itemsPerPage={itemsPerPage}
        allPages={allPages}
        currentPage={currentPage}
        scroll={camsScroll}
        condition={camsCondition}
        camsActionsOptions={camsActionsOptions}
      />
    </>
  );
};

export default CamsTable;
