import React, { useState, useEffect } from 'react';
import Search from '../../components/Search';
import Pagination from '../../../../components/Pagination';
import Sorting from '../../../../components/Sorting';
import {Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Checkbox} from '@material-ui/core';
import IntlMessages from '../../../../util/IntlMessages';
import JsonPopup from "../JsonPopup";
import EditFormDialog from "../EditFormDialog";
import { initialTableScroll, moveTableScroll } from '../../../../util/table';
import PagePlaceholder from "../../../../components/PagePlaceholder";

export const FeedsTable = ({
  feedsFields,
  feedsData,
  feedsScroll,
  feedsCondition,
  getFeedsData,
  updateFeedsData,
  itemsPerPage,
}) => {

  const [unsavedData, setUnsavedData] = useState([]);
  const [data, setData] = useState([]);
  const [allPages, setAllPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenedJsonPopup, setIsOpenedJsonPopup] = useState(false);
  const [jsonValue, setJsonValue] = useState({});
  const [isOpenedEditForm, setIsOpenedEditForm] = useState(false);
  const [editingFeed, setEditingFeed] = useState(0);
  const [editField, setEditField] = useState(null);
  const [editFieldValue, setEditFieldValue] = useState(null);
  const [currentFeedsFields, setCurrentFeedsFeelds] = useState([]);

  useEffect(() => {
    const formatData = [];
    const feedsFieldsKeys = Object.keys(feedsFields);

    for (let i = 0; i < feedsFieldsKeys.length; i++) {
      if (feedsFields[feedsFieldsKeys[i]].field) {
        feedsFieldsKeys[i] = feedsFields[feedsFieldsKeys[i]].field;
      }
    }

    for (let i = 0; i < feedsData.length; i++) {
      if (feedsData[i]) {
        const formatDataItem = [];
        for (let j = 0; j < feedsFieldsKeys.length; j++) {
          if (feedsFieldsKeys[j].indexOf('.') !== -1) {
            const splitField = feedsFieldsKeys[j].split('.');
            if (feedsData[i][splitField[0]]) {
              formatDataItem[j] = feedsData[i][splitField[0]][splitField[1]];
            }
          } else {
            formatDataItem[j] = feedsData[i][feedsFieldsKeys[j]];
          }
        }
        formatData[i] = formatDataItem;
      }
    }

    let j = 0;
    const currentFeedsFields = [];
    for (let i = 0; i < feedsFieldsKeys.length; i++) {
      if (formatData && formatData[0] && formatData[0][i] !== undefined) {
        currentFeedsFields[j] = feedsFields[feedsFieldsKeys[i]];
        currentFeedsFields[j].field = feedsFieldsKeys[i];
        j++;
      }
    }
    setCurrentFeedsFeelds(currentFeedsFields);

    const currentFormatData = [];
    for (let i = 0; i < formatData.length; i++) {
      currentFormatData[i] = formatData[i].filter(function (el) {
        return el !== undefined;
      });
    }
    setData(currentFormatData);
    setUnsavedData(currentFormatData);

    setAllPages(Math.ceil(feedsScroll.total / itemsPerPage));
    let currentPage = Math.ceil(feedsScroll.offset / itemsPerPage) + 1;
    if (currentPage <= 0) {
      currentPage = 1;
    }
    setCurrentPage(currentPage);
  }, [
    feedsData, feedsScroll, feedsCondition, feedsFields, itemsPerPage
  ]);

  const getTableRowColor = (item) => {
    let color = '#ffffff';

    Object.keys(currentFeedsFields).map((value, valueKey) => {
      const type = Object.values(currentFeedsFields)[valueKey].type;
      if (type === 'flag') {
        const val = item[valueKey] ? 1 : 0;
        const colors = Object.values(currentFeedsFields)[valueKey].colors;
        if (colors) {
          color = colors[val];
        }
      }

      return color;
    });

    return color;
  };

  const handleOpenJsonValue = (value) => {
    setJsonValue(value);
    setIsOpenedJsonPopup(true);
  };

  const handleCloseJsonPopup = () => {
    setJsonValue({});
    setIsOpenedJsonPopup(false);
  };

  const handleChangeFlag = (id, value) => {
    const condition = { data: { active: !value } };
    updateFeedsData(id, condition).then(() => {
      const filter = feedsCondition.filter;
      const sort = feedsCondition.sort;
      const offset = feedsScroll.offset - itemsPerPage > 0 ? feedsScroll.offset - itemsPerPage : 0;

      setTimeout(() => {
        getFeedsData({itemsPerPage, offset, filter, sort});
      }, 500);
    });
  };

  const handleCloseEditFormDialog = () => {
    setIsOpenedEditForm(false);
  };

  const handleChangeFeedValue = (id, value, field) => {
    setEditingFeed(id);
    setIsOpenedEditForm(true);
    setEditField(field);
    setEditFieldValue(value);
  };

  const FeedTitle = ((props) => {
    const { item, field, name, sortable, width } = props;

    if (sortable === true) {
      const className = "mapper-sortable";
      return (
        <Sorting
          getData={getFeedsData}
          item={field ? field : item}
          condition={feedsCondition}
          name={name}
          className={className}
          width={width}
          itemsPerPage={itemsPerPage}
        />
      );
    }

    return (
      <TableCell><div style={{ width: width, position: 'relative', top: '3px' }}>{ name }</div></TableCell>
    );
  });

  const FeedItem = ((props) => {
    const {
      type, item, maxLength, value
    } = props;

    let tablevalue = '';

    if (type === 'flag') {
      return (
        <TableCell className="mapper-td">
          <div className="merchant-info-link">
            <Checkbox
              color="secondary"
              onChange={() => handleChangeFlag(item[0], value)}
              defaultChecked={value}
              value="1"
            />
          </div>
        </TableCell>
      );
    } else if (type === 'schedule') {
      return (
        <TableCell className="mapper-td">
          <div className="merchant-info-link" onClick={() => handleChangeFeedValue(item[0], value, type)}>
            { value ? value : 'N/A' }
          </div>
        </TableCell>
      );
    } else if (type === 'filters') {
      return (
        <TableCell className="mapper-td">
          <div className="merchant-info-link" onClick={() => handleChangeFeedValue(item[0], value, type)}>
            <IntlMessages id="widgets.editFilters" />
          </div>
        </TableCell>
      );
    } else if (type === 'json' && value && value.time) {
      return (
        <TableCell className="mapper-td">
          <div className="merchant-info-link" onClick={() => handleOpenJsonValue(value)}>
            <IntlMessages id="widgets.showDetails" />
          </div>
        </TableCell>
      );
    } else {
      tablevalue = (value && value.length !== 0) || value === 0 ? value : 'N/A';
      if (maxLength && tablevalue.length > maxLength) {
        tablevalue = tablevalue.substring(0, maxLength) + '...';
      }
    }

    if (Array.isArray(tablevalue)) {
      let arrValue = '';
      for (let i = 0; i < tablevalue.length; i++) {
        arrValue += tablevalue[i];
        if (i < tablevalue.length - 1) {
          arrValue += ',';
        }
      }
      tablevalue = arrValue;
    }

    return (
      <TableCell className="mapper-td">
        {tablevalue.toString()}
      </TableCell>
    );
  });

  initialTableScroll(
    'feedTable',
    'feedTableScroll',
    'feedTableContainer',
    'feedTableContainerScroll'
  );

  const moveScroll = () => {
    moveTableScroll('feedTableContainer', 'feedTableContainerScroll');
  };

  return (
    <>
      <Search
        feedsFields={feedsFields}
        itemsPerPage={itemsPerPage}
        unsavedData={unsavedData}
        data={data}
        scroll={feedsScroll}
        condition={feedsCondition}
      />

      <br/>

      <div id="feedTableScrollDiv" className="mapper-table-scroll">
        <TableContainer
          id="feedTableContainerScroll"
          className="product-table-container"
          onScroll={moveScroll}
        >
          <Table id="feedTableScroll">
            <TableHead className="products-table-header">
              <TableRow><TableCell>&nbsp;</TableCell></TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </div>

      <TableContainer id="feedTableContainer" className="product-table-container-no-scroll">
        <Table id="feedTable" stickyHeader aria-label="sticky table" size="small">

          <TableHead className="products-table-header">
            <TableRow>
              {Object.keys(currentFeedsFields).map((item, itemKey) => (
                <FeedTitle
                  classNaqme="mapper-no-wrap"
                  key={itemKey}
                  item={item}
                  field={Object.values(currentFeedsFields)[itemKey].field}
                  name={Object.values(currentFeedsFields)[itemKey].name}
                  sortable={Object.values(currentFeedsFields)[itemKey].sortable}
                  width={Object.values(currentFeedsFields)[itemKey].width}
                />
              ))}
              <TableCell className="mapper-td">&nbsp;</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data && data.map((item, itemKey) =>
              <TableRow key={itemKey} style={{backgroundColor: getTableRowColor(item)}}>
                {Object.keys(currentFeedsFields).map((value, valueKey) => (
                  <FeedItem
                    key={valueKey}
                    item={item}
                    type={Object.values(currentFeedsFields)[valueKey].type}
                    maxLength={Object.values(currentFeedsFields)[valueKey].maxLength}
                    value={item[valueKey]}
                  />
                ))}
              </TableRow>
            )}

            {data.length === 0 && (
              <TableRow>
                <TableCell className="feed-no-border">
                  <PagePlaceholder
                    icon={<i className="ti-face-sad text-primary text-lg" />}
                    title={<IntlMessages id="title.sorry" />}
                    subtitle={<IntlMessages id="title.noResultsFound" />}
                  />
                </TableCell>
              </TableRow>
            )}

          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        getData={getFeedsData}
        dataCount={data ? data.length : 0}
        itemsPerPage={itemsPerPage}
        allPages={allPages}
        currentPage={currentPage}
        scroll={feedsScroll}
        condition={feedsCondition}
      />

      <JsonPopup
        key="json-popup"
        isOpened={isOpenedJsonPopup}
        onClose={handleCloseJsonPopup}
        value={jsonValue}
      />

      <EditFormDialog
        key="edit-form-dialog"
        isOpened={isOpenedEditForm}
        onClose={handleCloseEditFormDialog}
        editingFeed={editingFeed}
        editField={editField}
        editFieldValue={editFieldValue}
        itemsPerPage={itemsPerPage}
        scroll={feedsScroll}
        condition={feedsCondition}
        getFeedsData={getFeedsData}
        updateFeedsData={updateFeedsData}
      />

    </>
  );
};

export default FeedsTable;
