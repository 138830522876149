/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useContext } from 'react';
import {
  func, bool, shape, arrayOf,
} from 'prop-types';
import { FormGroup } from '@material-ui/core';
import { connect } from 'react-redux';

import SortBy from 'components/SortBy';
import { AbilityContext } from 'components/AbilityContext';

import appPermissions from 'util/appPermissions';
import {
  getSortsMatrixAttributes,
} from 'util/attributesMatrixMapping';

import {
  setSortRules,
  setRankingRuleCode,
  setSortingsErrors,
} from 'actions/navigation';

import useSortingEffects from './hooks/useSorting';

const Sort = memo((props) => {
  const abilityContext = useContext(AbilityContext);

  useSortingEffects({
    navigationFetched: props.navigationFetched,
    navigationItem: props.navigationItem,
    setSortRules: props.setSortRules,
    setRankingRuleCode: props.setRankingRuleCode,
  });

  const {
    rankingFetching,
    categoriesAttributesFetching,
    navigationSort,
    productCategoriesAttributes,
    attributesMatrixList,
    rankingsList,
  } = props;

  const noPermissions = !abilityContext.can(
    appPermissions.category.permissions.update,
    appPermissions.category.name,
  );

  const loading = rankingFetching || categoriesAttributesFetching;

  const handleSortByChange = (payload) => {
    props.setSortRules(payload);
    props.setRankingRuleCode(null);
    props.setSortingsErrors([]);
  };

  const handleRankingChange = rankingRuleCode => props.setRankingRuleCode(rankingRuleCode);

  return (
    <FormGroup className="relative preview-drawer-sort">
      <SortBy
        separate
        sortContentClass="direction-column"
        disabled={noPermissions}
        loading={loading}
        rankingRuleCode={navigationSort.rankingRuleCode}
        errors={navigationSort.sortingsErrors}
        onChange={handleSortByChange}
        onRankingChange={handleRankingChange}
        visible
        attributes={{
          categories: [
            ...productCategoriesAttributes,
            ...getSortsMatrixAttributes(attributesMatrixList),
          ],
          productSorting: navigationSort.sortRules,
          rankingOptions: rankingsList,
        }}
      />
    </FormGroup>
  );
});

Sort.propTypes = {
  rankingFetching: bool.isRequired,
  categoriesAttributesFetching: bool.isRequired,
  navigationFetched: bool.isRequired,

  navigationSort: shape().isRequired,
  navigationItem: shape().isRequired,
  productCategoriesAttributes: arrayOf(shape()).isRequired,
  attributesMatrixList: arrayOf(shape()).isRequired,
  rankingsList: arrayOf(shape()).isRequired,

  setSortRules: func.isRequired,
  setRankingRuleCode: func.isRequired,
  setSortingsErrors: func.isRequired,
};

Sort.defaultProps = {};

const mapStateToProps = state => ({
  navigationSort: state.navigation.sort,
  productCategoriesAttributes: state.navigation.filter.productCategoriesAttributes,
  navigationFetched: state.navigation.fetchedOne,
  navigationItem: state.navigation.item,

  rankingFetching: state.ranking.fetching,
  rankingsList: state.ranking.list,

  categoriesAttributesFetching: state.productAttribute.categoriesAttributesFetching,

  attributesMatrixList: state.attributesMatrix.list,
});

const mapDispatchToProps = {
  setSortRules,
  setRankingRuleCode,
  setSortingsErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sort);
