/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, memo, Fragment } from 'react';
import {
  shape, string, oneOfType, array, node,
} from 'prop-types';
import Popover from '@material-ui/core/Popover';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';

const ShowMoreComponent = memo((props) => {
  const { attr, itemValue } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [expandKey, setExpandKey] = useState('');

  const handleExpand = () => {};

  const handleClose = () => {
    setAnchorEl(null);
    setExpandKey('');
  };

  const ShowMorePopover = ({ value }) => (
    <Popover
      className="show-more-popover show-more-popover-list no-event"
      open={!!expandKey}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <DialogContent className="product-popover-content small">
        {value}
      </DialogContent>
    </Popover>
  );

  const onMouseLeave = () => {
    setAnchorEl(null);
    setExpandKey('');
  };

  const onMouseEnter = (e, key) => {
    setAnchorEl(e.target);
    setExpandKey(key);
  };

  return (
    <Fragment>
      <IconButton
        size="small"
        color="primary"
        aria-label="Add"
        className="product-item-label-more-btn"
        onClick={handleExpand}
        onMouseEnter={e => onMouseEnter(e, attr.key)}
        onMouseLeave={onMouseLeave}
      >
        <i className={`product-item-label-more ${expandKey === attr.key ? 'ti-more-alt' : 'ti-more'}`} />
      </IconButton>
      {expandKey === attr.key && <ShowMorePopover value={itemValue} />}
    </Fragment>
  );
});

ShowMoreComponent.propTypes = {
  value: string,
  itemValue: oneOfType([string, array, node]),
  attr: shape(),
};

ShowMoreComponent.defaultProps = {
  value: '',
  attr: {},
  itemValue: '',
};

export default ShowMoreComponent;
