import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

import RestActions from '../util/rest/actions';
import { showErrorNotification } from '../util/api';

import {
  virtualFacetPath,
  virtualFacetSearchPath,
  getVirtualFacetPath,
} from '../util/paths';

import {
  SET_PREVIEW_NAVIGATION_VIRTUAL_FACET_ID_TO_DELETE,
} from './types';

const restPreviewNavigationVirtualFacetActions = new RestActions('preview_navigation_virtual_facet');

export const fetchPreviewNavigationVirtualFacet = id => (dispatch) => {
  dispatch(restPreviewNavigationVirtualFacetActions.fetchStart());
  return axios.get(getVirtualFacetPath(id)).then((res) => {
    dispatch(restPreviewNavigationVirtualFacetActions.fetchFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restPreviewNavigationVirtualFacetActions.fetchFinished({
      error: error.response.data,
      hasError: true,
      item: {},
    }));
  });
};

export const fetchPreviewNavigationVirtualFacetList = params => (dispatch) => {
  dispatch(restPreviewNavigationVirtualFacetActions.fetchAllStart());
  return axios.post(virtualFacetSearchPath, params).then((res) => {
    dispatch(restPreviewNavigationVirtualFacetActions.fetchAllFinished({
      list: camelizeKeys(res.data.data),
      origList: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restPreviewNavigationVirtualFacetActions.fetchAllFinished({
      error: error.response.data,
      hasError: true,
      list: [],
      origList: [],
    }));
  });
};

export const deletePreviewNavigationVirtualFacet = (id, isSync = true) => (dispatch) => {
  const transformedPath = isSync
    ? `${getVirtualFacetPath(id)}?is_synchronous=${isSync}`
    : getVirtualFacetPath(id);
  dispatch(restPreviewNavigationVirtualFacetActions.deleteStart());
  return axios.delete(transformedPath).then(() => {
    dispatch(
      restPreviewNavigationVirtualFacetActions.deleteFinished({ lastDeletedVirtualFacetId: id }),
    );
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restPreviewNavigationVirtualFacetActions.deleteFinished({
      error: error.response.data,
      hasError: true,
      lastDeletedVirtualFacetId: '',
    }));
  });
};

export const updatePreviewNavigationVirtualFacet = (
  facetId, payload, isSync = true,
) => (dispatch) => {
  const transformedPath = isSync
    ? `${getVirtualFacetPath(facetId)}?is_synchronous=${isSync}`
    : getVirtualFacetPath(facetId);
  const body = {
    data: payload,
  };
  dispatch(restPreviewNavigationVirtualFacetActions.updateStart());
  return axios.put(transformedPath, decamelizeKeys(body)).then((res) => {
    dispatch(restPreviewNavigationVirtualFacetActions.updateFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restPreviewNavigationVirtualFacetActions.updateFinished({
      error: error.response.data,
      hasError: true,
    }));
  });
};

export const createPreviewNavigationVirtualFacet = payload => (dispatch) => {
  const body = {
    data: payload,
  };
  dispatch(restPreviewNavigationVirtualFacetActions.createStart());
  return axios.post(virtualFacetPath, decamelizeKeys(body)).then((res) => {
    dispatch(restPreviewNavigationVirtualFacetActions.createFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restPreviewNavigationVirtualFacetActions.createFinished({
      error: error.response.data,
      hasError: true,
    }));
  });
};

export const setPreviewNavigationVirtualFacetToDeleteId = id => (dispatch) => {
  dispatch({
    type: SET_PREVIEW_NAVIGATION_VIRTUAL_FACET_ID_TO_DELETE,
    payload: {
      virtualFacetIdToDelete: id,
    },
  });
};

export const setPreviewNavigationVirtualFacetItem = facet => (dispatch) => {
  dispatch(restPreviewNavigationVirtualFacetActions.updateStart());
  dispatch(restPreviewNavigationVirtualFacetActions.updateFinished({ item: facet }));
};

export const setPreviewNavigationVirtualFacetList = list => (dispatch) => {
  dispatch(restPreviewNavigationVirtualFacetActions.fetchAllStart());
  dispatch(restPreviewNavigationVirtualFacetActions.fetchAllFinished({ list }));
};

export const clearPreviewNavigationVirtualFacet = () => (dispatch) => {
  dispatch(restPreviewNavigationVirtualFacetActions.clearStateFinished());
};
