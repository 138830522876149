import { createSelector } from 'reselect';

const attributesMatrixRootSelector = state => state;

const stateSelector = createSelector(
  attributesMatrixRootSelector,
  state => ({
    fetching: state.fetching,
    fetched: state.fetched,
    list: state.list,
  }),
);

export const attributesMatrixSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  attributesMatrixSelector,
};
