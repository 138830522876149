import { isEmpty } from 'lodash';

export const searchParams = data => ({
  full_text: {
    fields: [
      'name.en',
    ],
    value: data.full_text.value,
    match_type: 'must',
  },
  pagination: {
    page: 1,
    limit: 500,
  },
});

export const searchPhysicalCategoriesParams = (data) => {
  // eslint-disable-next-line no-restricted-globals
  const ids = data.filter(qI => !isNaN(qI));
  const result = {};
  // eslint-disable-next-line no-restricted-globals
  const query = data.filter(qI => isNaN(qI));
  const queryIn = !isEmpty(ids) ? ({
    field: 'amazon_node_id',
    value: ids.map(c => c).flat(),
    operator: 'in',
  }) : {};

  let virtualFilter = {};

  if (!isEmpty(queryIn)) {
    virtualFilter = {
      condition: 'and',
      group: [queryIn],
    };
    result.filter = [virtualFilter];
  }

  return ({
    full_text: {
      fields: [
        'name.en',
      ],
      value: query.join(' '),
      match_type: 'must',
    },
    ...result,
    pagination: {
      page: 1,
      limit: 500,
    },
  });
};
