/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import {
  string, bool, shape, arrayOf, func,
} from 'prop-types';
import { isEmpty, uniq, uniqBy } from 'lodash';
import { Divider, FormControlLabel, Checkbox } from '@material-ui/core';

import {
  topTenFiltersSelector,
  topTenSelectedCategoriesSelector,
  topTenMappedFiltersSelector,
  topTenSelectedProductsFilterSelector,
} from '../../selectors';

import SectionInfoRow from '../../components/SectionInfoRow';
import AttributesFilter from '../../components/AttributesFilter';
import SelectVirtualCategories from '../../components/SelectVirtualCategories';
import SelectProducts from '../../components/SelectProducts';
import ProductsListExample from '../../components/ProductsListExample';
import ProductsPagination from '../../../../components/ProductsPagination';

import {
  clearSearchListState,
  fetchChildCategories,
  searchCategories as searchScopeCategories,
  onToggleScopeCategoryTreeCollapse,
  handleLoadScopeSubcategories,
  fetchScopeCategoriesByIds,
  fetchRootCategories,
} from '../../../../actions/scopeCategory';
import {
  setSelectedVirtualCategories,
  updateTopTenFilters,
  setSelectedCategoriesAttributes,
  fetchProductList,
  fetchTopTenProductsByIds,
  completeTopTenProductList,
  setTopTenProductFilter,
  setSelectedTopTenProductList,
  setProductsTopTenInIds,
  updateTopTenLoadedProductsIn,
  setSelectedTopTenProductsFilters,
  fetchExampleProducts,
  updateInfluencerFields,
  clearInfluencerProductState,
} from '../../../../actions/influencer';
import {
  fetchCategoriesAttributes,
  fetchAttributeOptions,
  fetchAttributesOptions,
} from '../../../../actions/productAttribute';

import { defaultPagination } from '../../utils/defaultData';
import {
  mapProductList,
  getFilterWithoutProductFilter,
  mapFrontEndPrefixToFilters,
  getSplitedFilterByQuery,
  getProductCompoundSearchFilter,
} from '../../utils/mappers';
import IntlMessages from '../../../../util/IntlMessages';

const TopTenContainer = memo((props) => {
  const [showCategoriesDialog, setShowCategoriesDialog] = useState(false);
  const [compoundSearch, setCompoundSearch] = useState(null);
  const [leftSidePagination, setLeftSidePagination] = useState(defaultPagination);

  const {
    influencer, fetchingParentCategoriesByIds, searched, searchResults,
    collapse, loadedKeys, treeItems, fetchChildrenStarted, scopeCategoriesByIds,
    fetchedScopeCategoriesByIds, fetchRootFinished, virtualCategories, topTenFilters,
    categoriesAttributesFetched, categoriesAttributes, fetchedOne, productList,
    fetchingScopeCategoriesByIds, categoriesAttributesFetching,
    fetchingAttributeOptions, influencerProduct,
    mappedFilters, selectedProductsFilters, disabled,
  } = props;

  useEffect(() => {
    const productsInFilter = selectedProductsFilters.find(f => f.field === 'id');
    const productsInIds = productsInFilter ? productsInFilter.value : [];

    if (fetchedOne && !isEmpty(productsInIds)) {
      props.updateTopTenLoadedProductsIn([...productsInIds.map(p => ({ id: p }))]);
      props.setProductsTopTenInIds(productsInIds);
      props.fetchExampleProducts(productsInIds);
    }
  }, [fetchedOne]);

  useEffect(() => {
    if (showCategoriesDialog && !fetchRootFinished) props.fetchRootCategories();
  }, [showCategoriesDialog]);

  useEffect(() => {
    if (fetchedOne && !isEmpty(virtualCategories) && !fetchingScopeCategoriesByIds) {
      props.fetchScopeCategoriesByIds(virtualCategories);
    }
  }, [fetchedOne, virtualCategories]);

  useEffect(() => {
    if (fetchedScopeCategoriesByIds) {
      const physicalCategoriesIds = scopeCategoriesByIds
        .map(c => c.virtualParams.mappedCategories).flat();
      if (!isEmpty(physicalCategoriesIds)) {
        props.fetchCategoriesAttributes(uniq(physicalCategoriesIds));
      }
    }
  }, [fetchedScopeCategoriesByIds]);

  useEffect(() => {
    if (categoriesAttributesFetched) {
      const searchTriggers = ['brand', 'color', 'gender', 'size', 'activity', 'theme'];
      const filteredAttributesList = categoriesAttributes
        .filter(attr => searchTriggers
          .some(trigger => attr.code.startsWith(`ao_${trigger}`)));
      const filtersFields = topTenFilters.map(f => f.field).flat();
      const codesToFetch = filteredAttributesList
        .filter(a => filtersFields.includes(a.value)).map(a => a.value).flat();

      props.setSelectedCategoriesAttributes(filteredAttributesList);

      if (!isEmpty(codesToFetch)) {
        props.fetchAttributesOptions(uniq(codesToFetch));
      }
    }
  }, [categoriesAttributesFetched]);

  const handleSubmitSelectedCategories = (categories) => {
    props.setSelectedVirtualCategories(categories);
  };

  const onLeftSidePaginate = ({ selected }) => {
    const updatedPagination = {
      ...leftSidePagination,
      page: selected + 1,
    };

    const newFilters = getFilterWithoutProductFilter(mappedFilters);
    const filtersWithPrefix = mapFrontEndPrefixToFilters(newFilters);

    const productSearchFilter = [...filtersWithPrefix, ...influencerProduct.topTen.productFilter];

    setLeftSidePagination(updatedPagination);
    props.fetchProductList({
      viewMode: 'frontendWithParams',
      compoundSearch,
      filter: productSearchFilter,
      pagination: updatedPagination,
      searchFilter: {},
    });
  };

  const handleSearchSubmit = (query) => {
    const splitedFilter = getSplitedFilterByQuery(query);
    const newCompoundSearch = getProductCompoundSearchFilter(splitedFilter.queryString.join(', '));
    const physicalCategoriesIds = scopeCategoriesByIds
      .map(c => c.virtualParams.mappedCategories).flat();
    let newProductFilter = [];

    setCompoundSearch(newCompoundSearch);
    setLeftSidePagination(defaultPagination);

    const productsGroupFilter = !isEmpty(splitedFilter.productIds)
      ? [{
        group: [{
          field: 'id',
          value: uniq(splitedFilter.productIds),
          operator: 'in',
        }],
      }]
      : [];

    if (!isEmpty(physicalCategoriesIds)) {
      newProductFilter = [{
        group: [{
          field: 'categories',
          value: physicalCategoriesIds,
          operator: 'in',
        }],
      }];
    }

    const newFilters = getFilterWithoutProductFilter(mappedFilters);
    const filtersWithPrefix = mapFrontEndPrefixToFilters(newFilters);

    const productSearchFilter = [...filtersWithPrefix, ...influencerProduct.topTen.productFilter];

    props.fetchProductList({
      viewMode: 'frontendWithParams',
      compoundSearch: newCompoundSearch,
      pagination: defaultPagination,
      searchFilter: {},
      filter: [...productSearchFilter, ...newProductFilter, ...productsGroupFilter].flat(),
    });
  };

  const handleSelectProductsDialogOpen = () => {
    const physicalCategoriesIds = scopeCategoriesByIds
      .map(c => c.virtualParams.mappedCategories).flat();
    const productFilters = mapFrontEndPrefixToFilters(getFilterWithoutProductFilter(mappedFilters));
    let newProductFilter = [];

    if (!isEmpty(physicalCategoriesIds)) {
      newProductFilter = [{
        group: [{
          field: 'categories',
          value: physicalCategoriesIds,
          operator: 'in',
        }],
      }];
      props.setTopTenProductFilter([...newProductFilter]);
    }

    props.fetchProductList({
      viewMode: 'frontendWithParams',
      compoundSearch,
      pagination: leftSidePagination,
      filter: [...newProductFilter.flat(), ...productFilters].flat(),
    });
  };

  const handleSelectedListChange = (selectedProductList, key) => {
    props.setSelectedTopTenProductList({
      ...influencerProduct.topTen.selectedProductList,
      [key]: selectedProductList,
    });

    props.setProductsTopTenInIds(selectedProductList.map(p => p.id));
    props.updateTopTenLoadedProductsIn(selectedProductList);
  };

  const handleDialogSelectClick = () => {
    const { selectedProductList } = influencerProduct.topTen;

    if (!isEmpty(selectedProductList.in)) {
      const productsInIds = selectedProductList.in.map(p => p.id);
      const newFilters = [{
        id: new Date().toString(),
        field: 'id',
        operator: 'in',
        value: productsInIds,
      }];
      props.setSelectedTopTenProductsFilters(newFilters);
      props.fetchExampleProducts(productsInIds);
    }
  };

  const handleSelectProductsDialogClose = () => {};
  const handleProductDialogApplyFilterChange = () => {};

  const getSearchSuffix = () => (
    <FormControlLabel
      className="label-clear checkbox-sm"
      control={(
        <Checkbox
          checked
          disabled
          onChange={handleProductDialogApplyFilterChange}
          color="primary"
        />
      )}
      label={<IntlMessages id="influencer.selectProductsDialog.search.checkbox.applyFilter" />}
    />
  );

  const loading = (
    fetchingScopeCategoriesByIds
    || categoriesAttributesFetching
  );

  const listIn = uniqBy([
    ...influencerProduct.topTen.productsByIdsForIn,
  ], 'id');

  const mappedListIn = mapProductList(listIn);

  return (
    <>
      <SectionInfoRow
        label="Top ten link"
        url={`americanoutlets.com/influencer/${influencer.page.storeName}/topten`}
        title={<IntlMessages id="influencer.topTen.title" />}
      />
      <Divider className="influencers-row-divider" />
      <SelectVirtualCategories
        selectedTreeItems={virtualCategories}
        fetchRootFinished={fetchRootFinished}
        showCategoriesDialog={showCategoriesDialog}
        setShowCategoriesDialog={setShowCategoriesDialog}
        fetchingParentCategoriesByIds={fetchingParentCategoriesByIds}
        searched={searched}
        searchResults={searchResults}
        collapse={collapse}
        loadedKeys={loadedKeys}
        treeItems={treeItems}
        fetchChildrenStarted={fetchChildrenStarted}
        scopeCategoriesByIds={scopeCategoriesByIds}
        fetchedScopeCategoriesByIds={fetchedScopeCategoriesByIds}
        loading={loading}
        influencer={influencer}
        clearSearchListState={props.clearSearchListState}
        fetchChildCategories={props.fetchChildCategories}
        searchScopeCategories={props.searchScopeCategories}
        onToggleScopeCategoryTreeCollapse={props.onToggleScopeCategoryTreeCollapse}
        handleLoadScopeSubcategories={props.handleLoadScopeSubcategories}
        fetchScopeCategoriesByIds={props.fetchScopeCategoriesByIds}
        updateInfluencerFields={props.updateInfluencerFields}
        clearInfluencerProductState={props.clearInfluencerProductState}
        onSubmit={handleSubmitSelectedCategories}
        errorMessage="text.errors.lessThan500Characters"
        queryLengthLimit={500}
        disabled={disabled}
      />
      <div className="categories-info-message">
        <IntlMessages id="influencer.topTen.categories.message" />
      </div>
      <Divider className="influencers-row-divider" />
      <div className="section-subtitle">
        <IntlMessages id="influencer.products.filters.subtitle" />
      </div>
      <AttributesFilter
        updateFilters={props.updateTopTenFilters}
        filters={topTenFilters}
        loading={loading}
        fetchAttributeOptions={props.fetchAttributeOptions}
        fetchingAttributeOptions={fetchingAttributeOptions}
        disabled={disabled}
      />
      <Divider className="influencers-row-divider" />
      <SelectProducts
        onOpen={handleSelectProductsDialogOpen}
        onClose={handleSelectProductsDialogClose}
        sourceList={mapProductList(productList) || []}
        listIn={mappedListIn}
        listNin={[]}
        listInIds={influencerProduct.topTen.productsInIds || []}
        listNinIds={[]}
        completingCategoryProductsList={influencerProduct.topTen.completingCategoryProductsList}
        fetchProductsByIdsStarted={influencerProduct.topTen.fetchProductsByIdsStarted}
        fetchProductsByIds={props.fetchTopTenProductsByIds}
        onSearchSubmit={handleSearchSubmit}
        onSelectedListChange={handleSelectedListChange}
        onDialogSelectClick={handleDialogSelectClick}
        completeProductList={props.completeTopTenProductList}
        operator="in"
        maxSelected={10}
        loading={{
          leftList: influencerProduct.influencerFetchProductListStart,
          rightList: influencerProduct.topTen.fetchProductsByIdsStarted,
        }}
        leftPagination={(
          <ProductsPagination
            containerClassName="pagination-sm pagination-abs"
            onPaginate={onLeftSidePaginate}
            product={{
              pages: influencerProduct.pages,
              total: influencerProduct.total,
            }}
            pagination={leftSidePagination}
          />
        )}
        search={{
          suffix: getSearchSuffix(),
        }}
        disabled={disabled}
      />
      <ProductsListExample
        productIds={influencerProduct.topTen.productsInIds}
        productsList={influencerProduct.exampleProductsList}
      />
    </>
  );
});

TopTenContainer.propTypes = {
  influencer: shape().isRequired,
  fetchingParentCategoriesByIds: bool.isRequired,
  searched: bool.isRequired,
  fetchChildrenStarted: bool.isRequired,
  fetchedScopeCategoriesByIds: bool.isRequired,
  fetchRootFinished: bool.isRequired,
  categoriesAttributesFetched: bool.isRequired,
  fetchingScopeCategoriesByIds: bool.isRequired,
  categoriesAttributesFetching: bool.isRequired,
  fetchingAttributeOptions: bool.isRequired,
  fetchedOne: bool.isRequired,
  disabled: bool.isRequired,
  searchResults: arrayOf(shape()).isRequired,
  collapse: arrayOf(string).isRequired,
  loadedKeys: arrayOf(string).isRequired,
  treeItems: arrayOf(shape()).isRequired,
  scopeCategoriesByIds: arrayOf(shape()).isRequired,
  virtualCategories: arrayOf(string).isRequired,
  topTenFilters: arrayOf(shape()).isRequired,
  categoriesAttributes: arrayOf(shape()).isRequired,
  productList: arrayOf(shape()).isRequired,
  influencerProduct: shape().isRequired,
  mappedFilters: arrayOf(shape()).isRequired,
  selectedProductsFilters: arrayOf(shape()).isRequired,

  clearSearchListState: func.isRequired,
  fetchChildCategories: func.isRequired,
  searchScopeCategories: func.isRequired,
  onToggleScopeCategoryTreeCollapse: func.isRequired,
  handleLoadScopeSubcategories: func.isRequired,
  fetchScopeCategoriesByIds: func.isRequired,
  fetchRootCategories: func.isRequired,
  setSelectedVirtualCategories: func.isRequired,
  updateTopTenFilters: func.isRequired,
  fetchCategoriesAttributes: func.isRequired,
  fetchAttributeOptions: func.isRequired,
  setSelectedCategoriesAttributes: func.isRequired,
  fetchProductList: func.isRequired,
  fetchTopTenProductsByIds: func.isRequired,
  completeTopTenProductList: func.isRequired,
  setTopTenProductFilter: func.isRequired,
  setSelectedTopTenProductList: func.isRequired,
  setProductsTopTenInIds: func.isRequired,
  updateTopTenLoadedProductsIn: func.isRequired,
  setSelectedTopTenProductsFilters: func.isRequired,
  fetchExampleProducts: func.isRequired,
  fetchAttributesOptions: func.isRequired,
  updateInfluencerFields: func.isRequired,
  clearInfluencerProductState: func.isRequired,
};

const mapStateToProps = state => ({
  influencer: state.influencer.item,
  virtualCategories: topTenSelectedCategoriesSelector(state.influencer.item),
  topTenFilters: topTenFiltersSelector(state.influencer.item),
  mappedFilters: topTenMappedFiltersSelector(state.influencer.item),
  selectedProductsFilters: topTenSelectedProductsFilterSelector(state.influencer.item),
  fetchedOne: state.influencer.fetchedOne,
  productList: state.influencer.product.list,
  influencerProduct: state.influencer.product,

  fetchingParentCategoriesByIds: state.scopeCategory.fetchingParentCategoriesByIds,
  searched: state.scopeCategory.searched,
  searchResults: state.scopeCategory.searchResults,
  collapse: state.scopeCategory.collapse,
  loadedKeys: state.scopeCategory.loadedKeys,
  treeItems: state.scopeCategory.treeItems,
  fetchChildrenStarted: state.scopeCategory.fetchChildrenStarted,
  scopeCategoriesByIds: state.scopeCategory.categoriesByIds,
  fetchedScopeCategoriesByIds: state.scopeCategory.fetchedScopeCategoriesByIds,
  fetchRootFinished: state.scopeCategory.fetchRootFinished,
  fetchingScopeCategoriesByIds: state.scopeCategory.fetchingScopeCategoriesByIds,

  categoriesAttributesFetched: state.productAttribute.categoriesAttributesFetched,
  categoriesAttributes: state.productAttribute.categoriesAttributes,
  categoriesAttributesFetching: state.productAttribute.categoriesAttributesFetching,
  fetchingAttributeOptions: state.productAttribute.fetchingAttributeOptions,
});

const actionCreators = {
  clearSearchListState,
  fetchChildCategories,
  searchScopeCategories,
  onToggleScopeCategoryTreeCollapse,
  handleLoadScopeSubcategories,
  fetchScopeCategoriesByIds,
  fetchRootCategories,
  setSelectedVirtualCategories,
  updateTopTenFilters,
  fetchCategoriesAttributes,
  setSelectedCategoriesAttributes,
  fetchAttributeOptions,
  fetchProductList,
  fetchTopTenProductsByIds,
  completeTopTenProductList,
  setTopTenProductFilter,
  setSelectedTopTenProductList,
  setProductsTopTenInIds,
  updateTopTenLoadedProductsIn,
  setSelectedTopTenProductsFilters,
  fetchExampleProducts,
  fetchAttributesOptions,
  updateInfluencerFields,
  clearInfluencerProductState,
};

export default connect(mapStateToProps, actionCreators)(TopTenContainer);
