import React, { memo } from 'react';
import {
  func, node, bool, string, oneOfType, shape,
} from 'prop-types';
import Popover from '@material-ui/core/Popover';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import IntlMessages from '../../util/IntlMessages';

const Popper = memo((props) => {
  const {
    onClose, onOk, content, visible, className, title,
    textContent, actions, id, anchorEl, selectedEntityUpdating,
  } = props;

  const handleClose = () => {
    if (onClose) {
      onClose('popperClosed');
    }
  };

  const handleOk = () => onOk('popperAccepted');

  return (
    visible && (
      <div className={`paper-wrapper ${className}`}>
        <Popover
          id={id}
          open={visible}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={className}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {title && (
            <DialogTitle className="paper-title">
              {title}
              <IconButton
                size="small"
                color="default"
                aria-label="Close"
                className="btn-mui-sm paper-action-close"
                onClick={handleClose}
              >
                <i className="ti-close" />
              </IconButton>
            </DialogTitle>
          )}
          <DialogContent className="paper-content popover-content">
            {textContent && <DialogContentText>{textContent}</DialogContentText>}
            {content}
          </DialogContent>
          <DialogActions className="paper-actions">
            {actions}
            {(props.fetchingPureProduct || selectedEntityUpdating) && (
              <CircularProgress
                variant="indeterminate"
                disableShrink
                className="progress-warning bottom"
                size={20}
                thickness={4}
              />
            )}
            {onOk && (
              <Button onClick={handleOk} color="primary">
                <IntlMessages id="paper.actions.close.label" />
              </Button>
            )}
          </DialogActions>
        </Popover>
      </div>
    )
  );
});

Popper.propTypes = {
  actions: oneOfType([string, node]),
  textContent: oneOfType([string, node]),
  title: oneOfType([string, node]),
  className: string,
  visible: bool,
  onClose: func,
  onOk: func,
  content: node,
  id: string,
  anchorEl: oneOfType([node, func, shape()]),
  fetchingPureProduct: bool,
  selectedEntityUpdating: bool,
};

Popper.defaultProps = {
  actions: null,
  textContent: null,
  title: null,
  className: '',
  visible: false,
  onClose: null,
  onOk: null,
  content: null,
  id: '',
  anchorEl: null,
  fetchingPureProduct: false,
  selectedEntityUpdating: false,
};

export default Popper;
