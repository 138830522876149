/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { camelizeKeys, decamelizeKeys } from 'humps';
import axios from 'axios';
import firebase from 'firebase/app';
import { showErrorNotification } from '../util/api';

import RestActions from '../util/rest/actions';
import config from '../util/config';

import {
  syncUsersCloudFuncPath,
  deleteUserCloudFuncPath,
  apiPath,
} from '../util/paths';

import {
  CLEAR_USER_STATE,
  USERS_SYNC_START,
  USERS_SYNC_FINISHED,
  CLEAR_FROM_USER_STATE,
} from './types';

const restUserActions = new RestActions('user');
const {
  fetchAllStart,
  fetchAllFinished,
  updateStart,
  updateFinished,
  updateMultipleStart,
  updateMultipleFinished,
  createStart,
  createFinished,
  deleteStart,
  deleteFinished,
} = restUserActions;

export const clearFromUserState = payload => (dispatch) => {
  dispatch({ type: CLEAR_FROM_USER_STATE, payload });
};

const getFBAuthHeader = (cdmsToken) => {
  const token = localStorage.getItem('fb_token');
  const authHeader = {
    Authorization: `Bearer ${token}`,
    'CDMS-Authorization': `Bearer ${cdmsToken}`,
  };
  return authHeader;
};

export const fetchUsers = () => async (dispatch) => {
  dispatch(fetchAllStart());
  const users = await firebase.database().ref('/users').once('value');
  const usersObject = users.val();
  const usersArray = [];

  Object.keys(usersObject).map(k => usersArray.push({ ...usersObject[k], id: k }));

  dispatch(fetchAllFinished(camelizeKeys(usersArray)));
};

export const updateUser = (id, user) => (dispatch) => {
  const payload = Object.assign({}, decamelizeKeys(user));

  delete payload.id;
  dispatch(updateStart());

  return firebase.database().ref(`users/${id}`).update({
    ...payload,
  })
    .then(res => dispatch(updateFinished(res)))
    .catch((error) => {
      showErrorNotification(error, 'Magento');
      dispatch(updateFinished({ error, hasError: true }));
    });
};

export const createUser = user => async (dispatch) => {
  const payload = Object.assign({}, decamelizeKeys(user));
  const secondaryApp = firebase.initializeApp(config, 'Secondary');

  dispatch(createStart());

  const authUser = await secondaryApp.auth()
    .createUserWithEmailAndPassword(payload.email, payload.password)
    .catch(err => dispatch(createFinished({ err, hasError: true })));

  await secondaryApp.auth().signOut();

  const { uid } = authUser.user;

  delete payload.password;

  return firebase.database().ref(`/users/${uid}`).set({
    ...payload,
    id: uid,
  })
    .then(() => {
      secondaryApp.delete();
      dispatch(createFinished({
        createdUser: {
          ...user,
          id: uid,
        },
      }));
    })
    .catch((error) => {
      showErrorNotification(error, 'Firebase');
      dispatch(createFinished({ error, hasError: true }));
    })
};

export const updateUsers = users => (dispatch) => {
  dispatch(updateMultipleStart());

  Promise.all(users.map(async (user) => {
    await dispatch(updateUser(user.id, user));
  })).then((res) => {
    dispatch(updateMultipleFinished({
      success: true,
      hasError: !!res.find(el => el),
    }));
  }).catch((error) => {
    dispatch(updateMultipleFinished({
      error,
      hasError: true,
    }));
  });
};

export const syncUsers = () => async (dispatch, getState) => {
  const cdmsToken = getState().serviceConfig.item.token;

  dispatch({
    type: USERS_SYNC_START,
    payload: {
      usersSyncing: true,
      usersSynced: false,
    },
  });

  const result = await axios.post(
    syncUsersCloudFuncPath,
    {},
    {
      headers: {
        'api-path': apiPath,
        ...getFBAuthHeader(cdmsToken),
      },
    },
  );

  dispatch({
    type: USERS_SYNC_FINISHED,
    payload: {
      usersSyncing: false,
      usersSynced: true,
      syncResult: result.data.result,
    },
  });
};

export const clearUserState = () => (dispatch) => {
  dispatch({ type: CLEAR_USER_STATE });
};

export const deleteUser = id => async (dispatch) => {
  try {
    dispatch(deleteStart());
    await axios.post(deleteUserCloudFuncPath, { uid: id }, {
      headers: {
        ...getFBAuthHeader(),
      },
    });

    return firebase.database().ref(`users/${id}`).remove()
      .then(res => dispatch(deleteFinished(res)))
      .catch((error) => {
        showErrorNotification(error, 'Magento');
        dispatch(deleteFinished({ error, hasError: true }));
      });
  } catch (error) {
    showErrorNotification(error, 'Magento');
    throw error;
  }
};

export const updateSavedProducts = (userId, products) => (dispatch) => {
  dispatch(updateStart());

  return firebase.database().ref(`users/${userId}`).update({
    selected_preview_products: products,
  })
      .then(res => dispatch(updateFinished(res)))
      .catch((error) => {
        showErrorNotification(error, 'Firebase');
        dispatch(updateFinished({ error, hasError: true }));
      });
};

export const updateSavedColumns = (userId, columns) => (dispatch) => {
  dispatch(updateStart());

  return firebase.database().ref(`users/${userId}`).update({
    selected_pim_columns: columns,
  })
    .then(res => dispatch(updateFinished(res)))
    .catch((error) => {
      showErrorNotification(error, 'Firebase');
      dispatch(updateFinished({ error, hasError: true }));
    });
};

export const updateSavedCategories = (userId, categories, marketplace) => (dispatch) => {
  dispatch(updateStart());

  const selectedCategories = {};
  if (marketplace.indexOf('zap') !== -1) {
    selectedCategories['selected_zap_categories'] = categories;
  }
  if (marketplace.indexOf('ksp') !== -1) {
    selectedCategories['selected_ksp_categories'] = categories;
  }

  return firebase.database().ref(`users/${userId}`).update(selectedCategories)
    .then(((res) => {

      dispatch({
        type: USERS_SYNC_FINISHED,
        payload: {
          userSelectedMarketplaceCategories: categories,
        },
      });

      dispatch(updateFinished(res));
    }))
    .catch((error) => {
      showErrorNotification(error, 'Firebase');
      dispatch(updateFinished({ error, hasError: true }));
    });
};
