import React, { memo } from 'react';
import { connect } from 'react-redux'
import { shape, string } from 'prop-types';

import PageTitleBar from 'components/PageTitleBar/PageTitleBar';
import IntlMessages from 'util/IntlMessages';

const Header = memo((props) => {
  const getHeaderLine = () => {
    const { name, id } = props.virtualCategory;

    if (id && name && name.en) {
      return (
        <>
          <span className="separator flex justify-center items-center"> &gt; </span>
          <>
            <span>{name.en}</span>
            <span className="header-line-id flex justify-center items-center pl-5">{`(${id})`}</span>
          </>
        </>
      );
    }
    return '';
  };

  return (
    <>
      <PageTitleBar
        title={(
          <>
            <IntlMessages id={props.pageTitle} />
            {
                getHeaderLine()
            }
          </>
        )}
        match={props.match}
        name={props.virtualCategory.name ? props.virtualCategory.name : ''}
        urlPath={props.virtualCategory.urlPath ? props.virtualCategory.urlPath : ''}
        id={props.virtualCategory.id ? props.virtualCategory.id : ''}
        pageTitle={props.pageTitle}
      />
    </>
  )
});

Header.propTypes = {
  match: shape().isRequired,
  virtualCategory: shape().isRequired,
  pageTitle: string.isRequired,
};

const mapStateToProps = state => ({
  virtualCategory: state.virtualCategoryPage.item,
});

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(Header);
