import React from 'react';
import {
  shape,
} from 'prop-types';
import { isEmpty } from 'lodash';

import IntlMessages from '../../util/IntlMessages';


const PopoverContent = (props) => {
  const getPhysicalCategories = (virtualParams) => {
    const { mappedCategories } = virtualParams;

    return !isEmpty(mappedCategories)
      ? mappedCategories.join(', ')
      : <IntlMessages id="categoriesTree.tooltip.noPhysicalCategories" />;
  };

  return (
    <div className="categories-tree-popover">
      {getPhysicalCategories(props.category.virtualParams)}
    </div>
  );
};

PopoverContent.propTypes = {
  category: shape(),
};

PopoverContent.defaultProps = {
  category: {},
};

export default PopoverContent;
