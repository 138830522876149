/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  memo, useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  func, arrayOf, shape, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';

import VariantLevelImageViewList from '../../components/VariantLevelImageViewList';
import { previewSelector } from '../../pages/Home/selectors/preview';

import {
  fetchCountProductsActivitiesByProductIds,
  setUpdatedProductsImagesOverrides,
  injectProducts,
} from '../../../../actions/preview';

import {
  fetchActivityLogsByProductId,
} from '../../../../actions/product';

const VariantImageViewContainer = memo((props) => {
  const {
    variants, parent, certainProductAttributes,
    product, isEditMode, attributes, productComments,
    fetchProductCommentsStart,
  } = props;

  useEffect(() => {
    const ids = variants.map(v => v.id);

    if (!isEmpty(ids)) {
      props.fetchCountProductsActivitiesByProductIds(ids);
    }
  }, [parent.id]);

  const sortedAttributes = attributes.sort((a, b) => a.order - b.order);

  return (
    <VariantLevelImageViewList
      variants={variants}
      parent={parent}
      certainProductAttributes={certainProductAttributes}
      productsCommentsCount={product.productsCommentsCount}
      fetchActivityLogsByProductId={props.fetchActivityLogsByProductId}
      productCommentsLoading={fetchProductCommentsStart}
      productComments={productComments}
      defaultImage={product.defaultImage}
      isEditMode={isEditMode}
      productsImagesOverride={product.productsImagesOverride}
      sortedAttributes={sortedAttributes}
      setUpdatedProductsImagesOverrides={props.setUpdatedProductsImagesOverrides}
      injectProducts={props.injectProducts}
      disabled={!isEditMode}
    />
  );
});

VariantImageViewContainer.propTypes = {
  variants: arrayOf(shape()).isRequired,
  parent: shape().isRequired,
  certainProductAttributes: arrayOf(shape()).isRequired,
  productComments: arrayOf(shape()).isRequired,
  product: shape().isRequired,
  attributes: arrayOf(shape()).isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  fetchCountProductsActivitiesByProductIds: func.isRequired,
  injectProducts: func.isRequired,
  isEditMode: bool.isRequired,
  setUpdatedProductsImagesOverrides: func.isRequired,
  fetchProductCommentsStart: bool.isRequired,
};

VariantImageViewContainer.defaultProps = {
};

const mapStateToProps = state => ({
  product: previewSelector(state.preview),
  certainProductAttributes: state.productAttribute.certainProductAttributes,
  attributes: state.system.modes.preview,
  isEditMode: state.preview.isEditMode,
  productComments: state.product.productComments,
  fetchProductCommentsStart: state.product.fetchProductCommentsStart,
});

const mapDispatchToProps = {
  fetchCountProductsActivitiesByProductIds,
  fetchActivityLogsByProductId,
  setUpdatedProductsImagesOverrides,
  injectProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(VariantImageViewContainer);
