export const mapPositions = (virtualParams, originalVirtualParams) => {
  const payload = { ...virtualParams };
  if (originalVirtualParams.positions) {
    payload.positions = originalVirtualParams.positions;
  }
  return payload;
};

export default {
  mapPositions,
};
