import React, { useContext } from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { withRouter, Link } from 'react-router-dom';
import {
  shape, func, node, bool,
} from 'prop-types';

import List from '../List';
import { headerPreviewRightList } from '../../pages/utils/headerList';
import LanguageProvider from './LanguageProvider';

import { collapsedSidebarAction } from '../../actions';
import { logOut } from '../../actions/auth';
import mainLogo from '../../assets/img/logo-small.png';
import { rootAppPath, mapperAppPath } from '../../util/paths';
import { AbilityContext } from '../AbilityContext';

const Header = (props) => {
  const abilityContext = useContext(AbilityContext);

  const onLogoutClick = () => props.logOut();

  const onToggleNavCollapsed = () => {
    const val = !props.navCollapsed;
    props.collapsedSidebarAction(val);
  };

  const { displayName } = props.session.item;
  const listParams = {
    onLogoutClick,
    userName: displayName || '',
    abilityContext,
  };
  const {
    content, horizontalMenu, subHeaderContent,
  } = props;

  let toggleMenu = true;
  if (document.location.pathname === mapperAppPath)  {
    toggleMenu = false;
  }

  return (
    <AppBar position="static" className="rct-header">
      <Toolbar className="d-flex justify-content-between direction-column w-100">
        <div key="1" className="d-flex align-items-center w-100">
          {(horizontalMenu && toggleMenu) && (
            <ul className="list-inline mb-0 navbar-left sidebar-toggle">
              <li className="list-inline-item">
                <Tooltip title="Sidebar Toggle" placement="bottom" onClick={onToggleNavCollapsed}>
                  <IconButton color="inherit" mini="true" aria-label="Menu" className="humburger p-0">
                    <MenuIcon />
                  </IconButton>
                </Tooltip>
              </li>
            </ul>
          )}
          {horizontalMenu && (
            <div className="site-logo">
              <Link to={rootAppPath} className="logo-normal header-brand">
                <img src={mainLogo} alt="site logo" />
              </Link>
            </div>
          )}
          {content}
          <div className="lists-group items-center flex justify-between w-100">
            {subHeaderContent && (
              <div key="2" className="d-flex align-center">
                {subHeaderContent}
              </div>
            )}
            <List
              dir="horizontal"
              className="d-flex align-center list-item-clear"
              dataSource={headerPreviewRightList(listParams)}
              last={<LanguageProvider />}
            />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  session: shape({
    item: shape(),
  }).isRequired,

  logOut: func.isRequired,
  collapsedSidebarAction: func.isRequired,
  subHeaderContent: node,
  content: node,
  horizontalMenu: bool,
  navCollapsed: bool,
};

Header.defaultProps = {
  content: null,
  subHeaderContent: null,
  horizontalMenu: false,
  navCollapsed: false,
};

const mapStateToProps = state => ({
  settings: state.settings,
  navCollapsed: state.settings.navCollapsed,
  session: state.session,
});

const mapDispatchToProps = {
  logOut,
  collapsedSidebarAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
