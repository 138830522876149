import React from 'react';
import { node } from 'prop-types';
import { isEmpty } from 'lodash';
import { Divider } from '@material-ui/core';

import Can from 'components/Can';
import ExpansionPanel from 'components/ExpansionPanel';
import IntlMessages from 'util/IntlMessages';
import appPermissions from 'util/appPermissions';

import Category from 'widgets/Category';
import Promotion from 'widgets/Promotion';

import SidebarWrapper from './SidebarWrapper';
import Facet from './Facet';

const getPanels = (groupList) => {
  if (!isEmpty(groupList)) {
    return groupList.map(group => ({
      titleId: group.titleId,
      title: (
        <div className="h5 sidebar-title">
          <IntlMessages id={group.titleId} />
        </div>
      ),
      details: group.details,
    }));
  }
  return [];
};

const Sidebar = props => (
  <Can
    do={appPermissions.preview.permissions.view}
    on={appPermissions.preview.name}
  >
    <SidebarWrapper>
      <ExpansionPanel
        className="expansion-secondary expansion-scoll-lg pt-10"
        panels={getPanels([
          {
            titleId: 'subheader.categories',
            details: <Category />,
          }, {
            titleId: 'subheader.promotions',
            details: <Promotion />,
          }, {
            titleId: 'subheader.facets',
            details: <Facet />,
          },
        ])}
      />
      {!isEmpty(props.children) && <Divider />}
      {props.children}
    </SidebarWrapper>
  </Can>
);

Sidebar.propTypes = {
  children: node,
};

Sidebar.defaultProps = {
  children: null,
};

export default Sidebar;
