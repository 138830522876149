/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';

const handleNotificationSuccess = (msg) => {
  NotificationManager.success(msg);
};

const handleNotificationWarninrg = (msg) => {
  NotificationManager.warning(msg);
};


const useNavigationVirtualFacet = ({
  navigationItemId,
  navigationFetched,
  fetchNavigationVirtualFacetList,
  navigationVirtualFacetDeleted,
  navigationVirtualFacetCreated,
  viewMode,
  navigationCreated,
  navigationVirtualFacetFetching,
  clearFromNavigationVirtualFacetState,
  navigationVirtualFacetUpdated,
}) => {
  const fetchList = () => {
    const params = {
      filter: [
        {
          group: [
            {
              field: 'referenced_entity_id',
              value: navigationItemId,
              operator: 'eq',
            },
            {
              field: 'status',
              value: 'enabled',
              operator: 'eq',
            },
          ],
        },
      ],
      pagination: {
        page: 1,
      },
    };

    setTimeout(() => {
      fetchNavigationVirtualFacetList(params);
    });
  };

  useEffect(() => {
    if (
      (navigationFetched || navigationCreated)
      && viewMode === 'edit'
      && !navigationVirtualFacetFetching
    ) {
      fetchList();
    }
  }, [
    navigationFetched,
    navigationVirtualFacetDeleted,
    navigationVirtualFacetCreated,
  ]);

  useEffect(() => {
    clearFromNavigationVirtualFacetState({
      created: false,
      deleted: false,
      updated: false,
    });
    if (viewMode === 'edit' && navigationFetched) {
      if (navigationVirtualFacetCreated) {
        fetchList();
        handleNotificationSuccess(<IntlMessages id="navigation.facet.alert.created" />);
        clearFromNavigationVirtualFacetState({
          created: false,
        });
      }

      if (navigationVirtualFacetDeleted) {
        fetchList();
        handleNotificationWarninrg(<IntlMessages id="navigation.facet.alert.deleted" />);
        clearFromNavigationVirtualFacetState({
          deleted: false,
        });
      }

      if (navigationVirtualFacetUpdated && !navigationVirtualFacetCreated) {
        fetchList();
        handleNotificationSuccess(<IntlMessages id="navigation.facet.alert.updated" />);
        clearFromNavigationVirtualFacetState({
          updated: false,
        });
      }
    }
  }, [
    navigationVirtualFacetDeleted,
    navigationVirtualFacetCreated,
    navigationVirtualFacetUpdated,
  ]);
};

export default useNavigationVirtualFacet;
