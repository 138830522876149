import { createSelector } from 'reselect';

const productManagementRootSelector = state => state;

const stateSelector = createSelector(
  productManagementRootSelector,
  productManagement => ({
    importedXLSXWithProducts: productManagement.importedXLSXWithProducts,
    parsedImportProducts: productManagement.parsedImportProducts,
    fileName: productManagement.fileName,
    importedProducts: productManagement.importedProducts,
    xlsxDocument: productManagement.xlsxDocument,
    importingXLSXWithProducts: productManagement.importingXLSXWithProducts,
    generatedNewXLSX: productManagement.generatedNewXLSX,
    generatingNewXLSX: productManagement.generatingNewXLSX,
  }),
);

export const productManagementSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  productManagementSelector,
};
