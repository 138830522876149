import React, { useState, useEffect, memo } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  arrayOf, shape, node, bool, string, func, oneOfType, number,
} from 'prop-types';
import { uniqBy, isEmpty } from 'lodash';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import List from './List';
import IntlMessages from '../../util/IntlMessages';
import { fetchProductList } from "../../actions/pim";
import { updateSavedProducts } from "../../actions/user";

const not = (a, b) => a.filter(value => b.indexOf(value) === -1);
const intersection = (a, b) => a.filter(value => b.indexOf(value) !== -1);
const union = (a, b) => [...a, ...not(b, a)];
const disabledLeft = (left, checked) => left.map(l => ({
  ...l,
  disabled: checked.some(lC => lC.id === l.id),
  checked: checked.some(lC => lC.id === l.id),
}));
const enabledLeft = (left, checked) => left.map((l) => {
  if (checked.some(c => c.id === l.id)) {
    return ({
      ...l,
      disabled: false,
      checked: false,
    });
  }
  return l;
});

const TransferList = memo((props) => {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(props.left);
  const [right, setRight] = useState(props.right);
  const [favorites, setFavorites] = useState(null);
  const [onlyFavorites, setOnlyFavorites] = useState(false);

  const favoriteProducts = props.sessionItem.selectedPreviewProducts ? props.sessionItem.selectedPreviewProducts : [];

  useEffect(() => {
    if (onlyFavorites === true) {
      setLeft(favorites);
    } else {
      setLeft(props.left);
    }
  }, [props.left, favorites, onlyFavorites]);

  useEffect(() => {
    setRight(props.right);
  }, [props.right]);

  if (favorites === null && favoriteProducts.length > 0) {
    props.fetchProductList({
      filter: [ { group: [ { field: 'id', value: favoriteProducts, operator: 'in' } ] } ]
    });
  }

  useEffect(() => {
    if (props.productList !== null) {
      const favorites = [];
      for (let i = 0; i < props.productList.length; i++) {
        favorites[i] = props.productList[i];
        favorites[i].attribute = {
          title: {
            en: favorites[i].title ? favorites[i].title.en : '',
            he: favorites[i].title ? favorites[i].title.he : '',
          },
          price: favorites[i].price,
          description: {
            en: favorites[i].description ? favorites[i].description.en : '',
            he: favorites[i].description ? favorites[i].description.he : '',
          },
          pubAopi: favorites[i].pubAopi,
        };
        favorites[i].image = favorites[i].images ? favorites[i].images[0] : '';
      }
      setFavorites(favorites);
    }
  }, [props.productList]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  let favoritesChecked = false;
  let unFavoritesChecked = false;
  let favoritesCheckedCount = 0;
  if (favorites !== null) {
    for (let i = 0; i < leftChecked.length; i++) {
      for (let j = 0; j < favorites.length; j++) {
        if (leftChecked[i].id === favorites[j].id) {
          favoritesChecked = true;
          favoritesCheckedCount++;
        }
      }
    }
  }
  if (leftChecked.length > 0) {
    unFavoritesChecked = true;
  }
  if (favoritesCheckedCount === leftChecked.length) {
    unFavoritesChecked = false;
  }

  const rightList = right.slice(
    (props.pagination.page - 1) * props.pagination.limit,
    props.pagination.page * props.pagination.limit,
  );

  const handleAddToFavorites = (e) => {
    for (let i = 0; i < leftChecked.length; i++) {
      favorites.push(leftChecked[i]);
    }
    setFavorites(favorites);

    const selectedProducts = [];
    for (let i = 0; i < favorites.length; i++) {
      selectedProducts.push(favorites[i].id);
    }

    props.updateSavedProducts(props.sessionItem.id, selectedProducts);
  };

  const handleRemoveFromFavorites = (e) => {
    if (favorites === null) {
      return false;
    }
    const newFavories = [];
    for (let i = 0; i < favorites.length; i++) {
      let isFav = true;
      for (let j = 0; j < leftChecked.length; j++) {
        if (favorites[i].id === leftChecked[j].id) {
          isFav = false;
        }
      }
      if (isFav === true) {
        newFavories.push(favorites[i]);
      }
    }
    setFavorites(newFavories);

    const selectedProducts = [];
    for (let i = 0; i < newFavories.length; i++) {
      selectedProducts.push(newFavories[i].id);
    }

    props.updateSavedProducts(props.sessionItem.id, selectedProducts);
  };

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) newChecked.push(value);
    else newChecked.splice(currentIndex, 1);

    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = (e, isFavorites) => {
    const { disableTransferToRight } = props;
    const targets = isFavorites === false ? leftChecked : favorites;

    let newRight = right.concat(targets);
    if (disableTransferToRight) {
      setLeft(disabledLeft(left, targets));
      newRight = uniqBy([...right, ...targets], 'id');
    } else {
      setLeft(not(left, targets));
      setRight(newRight);
    }

    setChecked(not(checked, targets));
    props.onSelectedListChange(newRight, props.filterOperatorKey);
  };

  const handleCheckedLeft = (e, isFavorites) => {
    const { disableTransferToRight } = props;
    const targets = isFavorites === false ? rightChecked : favorites;

    if (disableTransferToRight) setLeft(enabledLeft(left, targets));
    else setLeft(left.concat(targets));

    if (rightList.length === targets.length && props.pagination.page > 1) {
      props.onRightSidePaginate({ selected: props.pagination.page - 2 });
    }

    let newRights = [];
    if (isFavorites === true) {
      for (let i = 0; i < right.length; i++) {
        if (favoriteProducts.indexOf(right[i].id) === -1) {
          newRights.push(right[i]);
        }
      }
    } else {
      newRights = not(right, targets);
    }

    setRight(newRights);
    setChecked(not(checked, targets));
    props.onSelectedListChange(newRights, props.filterOperatorKey);
  };

  const handleShowOnlyFavorites = (e, onlyFavorites) => {
    setOnlyFavorites(onlyFavorites);

    if (onlyFavorites === true) {
      setLeft(favorites);
    } else {
      setLeft(props.left);
    }
  };

  const customList = (data) => {
    const {
      label, list, selected, classes, action, isLeftList, favorites,
    } = data;

    const favoritesInRight = [];
    if (isLeftList === false && favorites && favorites.length > 0) {
      for (let i = 0; i < list.length; i++) {
        for (let j = 0; j < favorites.length; j++) {
          if (favorites[j].id === list[i].id) {
            favoritesInRight.push(favorites[j]);
          }
        }
      }
    }

    const shadowClassName = !isEmpty(list) && list.length > 5 ? 'list-shadow bottom' : '';
    return (
      <Card className={`flex direction-column h-full list-secondary ${shadowClassName}`}>
        {isLeftList === true ? (
          <div style={{width: '100%'}}>
            <Button
              style={{float: 'left', width: '200px'}}
              variant="contained"
              color="primary"
              size="middle"
              className="text-white"
              disabled={!favorites || favorites.length === 0}
              onClick={e => handleCheckedRight(e, true)}
            >
              <IntlMessages id="transferDialog.addFavoriteProducts" />
              ({favorites ? favorites.length : 0})
              <KeyboardArrowRightIcon fontSize="small" />
            </Button>
            {onlyFavorites === true ? (
              <Button
                style={{float: 'right', width: '150px'}}
                variant="contained"
                color="primary"
                size="middle"
                className="text-white"
                disabled={!favorites || favorites.length === 0}
                onClick={e => handleShowOnlyFavorites(e, false)}
              >
                <IntlMessages id="transferDialog.showAllProducts" />
              </Button>
            ) : (
              <Button
                style={{float: 'right', width: '150px'}}
                variant="contained"
                color="primary"
                size="middle"
                className="text-white"
                disabled={!favorites || favorites.length === 0}
                onClick={e => handleShowOnlyFavorites(e, true)}
              >
                <IntlMessages id="transferDialog.showFavoriteProducts" />
              </Button>
            )}
            <div style={{clear: 'both'}} />
          </div>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="middle"
            className="text-white mapper-w-250"
            disabled={!favorites || favorites.length === 0}
            onClick={e => handleCheckedLeft(e, true)}
          >
            <IntlMessages id="transferDialog.removeFavoriteProducts" />
             ({favoritesInRight ? favoritesInRight.length : 0})
            <KeyboardArrowLeftIcon fontSize="small" />
          </Button>
        )}

        <CardHeader
          className={`transfer-list-card-header ${classes.cardHeaderClassName}`}
          avatar={(
            <Checkbox
              onClick={handleToggleAll(list)}
              checked={numberOfChecked(list) === list.length && list.length !== 0}
              indeterminate={numberOfChecked(list) !== list.length && numberOfChecked(list) !== 0}
              disabled={list.length === 0 || !list} // || props.maxSelected !== list.length
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          )}
          title={label}
          subheader={`${numberOfChecked(list)}/${list.length || 0} selected`}
          action={onlyFavorites === true ? null : action}
        />
        <Divider />
        <List
          list={list}
          checked={checked}
          selected={selected}
          onClick={handleToggle}
          disabledList={data.disableCheckbox}
          listItemWrapperClassName={classes.listItemWrapperClassName}
          listItemClassName={classes.listItemClassName}
          showMoreIcon={props.showMoreIcon}
          maxSelected={data.maxSelected}
          isLeftList={data.isLeftList}
          favorites={favorites}
          onSelectedListChange={props.onSelectedListChange}
          filterOperatorKey={props.filterOperatorKey}
        />
      </Card>
    );
  };

  const classes = {
    listItemWrapperClassName: props.listItemWrapperClassName,
    listItemClassName: props.listItemClassName,
    cardHeaderClassName: props.cardHeaderClassName,
  };

  const disableCheckbox = props.disableTransferToRight
    && left && leftChecked && left.length === leftChecked.length;

  const isDisabled = props.loading.leftList || props.loading.rightList
    ? 'disabled-list'
    : '';

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={`flex-wrap-no max-h-md ${isDisabled}`}
    >
      <Grid className="block h-full list-left" item>
        {customList({
          label: props.labels.left,
          list: left,
          selected: right,
          classes,
          action: props.leftPagination,
          disableCheckbox,
          maxSelected: props.maxSelected,
          isLeftList: true,
          favorites: favorites,
        })}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center" className="transfer-actions">
          <Button
            variant="contained"
            size="small"
            color="primary"
            className="btn-w-md"
            onClick={e => handleCheckedRight(e, false)}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            <IntlMessages id="transferDialog.buttonAdd" />
            <KeyboardArrowRightIcon fontSize="small" />
          </Button>
          <Button
            variant="contained"
            size="small"
            className="btn-w-md"
            onClick={e => handleCheckedLeft(e, false)}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            <KeyboardArrowLeftIcon fontSize="small" />
            <IntlMessages id="transferDialog.buttonRemove" />
          </Button>

          <br /><br /><br />

          <Button
            variant="contained"
            size="small"
            color="primary"
            className="btn-w-md"
            onClick={e => handleAddToFavorites(e)}
            disabled={unFavoritesChecked === false}
            aria-label="move selected right"
          >
            <IntlMessages id="transferDialog.buttonAddToFavorites" />
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            className="btn-w-md"
            onClick={e => handleRemoveFromFavorites(e)}
            disabled={favoritesChecked === false}
            aria-label="move selected left"
          >
            <IntlMessages id="transferDialog.buttonRemoveFromFavorites" />
          </Button>
        </Grid>
      </Grid>
      <Grid className="block-40 h-full list-right" item>
        {customList({
          label: props.labels.right,
          list: rightList,
          classes,
          action: props.rightPagination,
          isLeftList: false,
          favorites: favorites,
        })}
      </Grid>
    </Grid>
  );
});

TransferList.propTypes = {
  left: arrayOf(shape()),
  right: arrayOf(shape()),
  loading: shape({
    leftList: bool,
  }),
  labels: shape({
    left: oneOfType([node, string]),
    right: oneOfType([node, string]),
  }),
  leftPagination: node,
  rightPagination: node,
  pagination: shape().isRequired,
  disableTransferToRight: bool,
  listItemWrapperClassName: string,
  listItemClassName: string,
  cardHeaderClassName: string,
  onSelectedListChange: func,
  filterOperatorKey: string,
  showMoreIcon: bool,
  maxSelected: number,
  onRightSidePaginate: func,
  sessionItem: shape().isRequired,
  productList: shape().isRequired,
  fetchProductList: func,
  updateSavedProducts: func,
};

TransferList.defaultProps = {
  left: [],
  right: [],
  leftPagination: null,
  rightPagination: null,
  disableTransferToRight: false,
  listItemWrapperClassName: '',
  listItemClassName: '',
  cardHeaderClassName: '',
  onSelectedListChange: null,
  filterOperatorKey: '',
  loading: {
    leftList: false,
  },
  labels: {
    left: 'Choices',
    right: 'Choices',
  },
  showMoreIcon: false,
  maxSelected: null,
  onRightSidePaginate: null,
  sessionItem: {},
  productList: [],
};

const mapStateToProps = state => ({
  sessionItem: state.session.item,
  productList: state.pim.list,
});

const mapDispatchToProps = {
  fetchProductList,
  updateSavedProducts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransferList));
