/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useCategoryEffect = ({
  categoryItemId,
  categoryFetched,
  categoryUpdated,
  fetchCategoryVirtualFacetList,
  categoryVirtualFacetUpdated,
  categoryVirtualFacetDeleted,
  categoryVirtualFacetCreated,
  categoryVirtualFacetFetching,
}) => {
  useEffect(() => {
    if (
      (categoryFetched || categoryUpdated)
      && !categoryVirtualFacetFetching
    ) {
      const params = {
        filter: [
          {
            group: [
              {
                field: 'referenced_entity_id',
                value: categoryItemId,
                operator: 'eq',
              },
              {
                field: 'status',
                value: 'enabled',
                operator: 'eq',
              },
            ],
          },
        ],
        pagination: {
          page: 1,
        },
      };

      fetchCategoryVirtualFacetList(params);
    }
  }, [
    categoryItemId,
    categoryFetched,
    categoryVirtualFacetUpdated,
    categoryVirtualFacetDeleted,
    categoryVirtualFacetCreated,
  ]);
};

export default useCategoryEffect;
