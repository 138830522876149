/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  memo, useState, Fragment, useEffect,
} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  bool, func, shape, string, arrayOf,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { NotificationManager } from 'react-notifications';
import Tooltip from '@material-ui/core/Tooltip';
import Field from '../../../../components/Field';
import FormDialog from '../../../../components/FormDialog';
import IntlMessages from '../../../../util/IntlMessages';
import entityTypes from '../../../../util/entityTypes';
import {
  commentValidations,
  systemCommentValidations,
} from './utils/validations';
import validateAll from '../../../../util/validator';
import { getSuggestProducts, mergeProducts, clearMergeProducts } from '../../../../actions/product';
import TargetProduct from './TargetProduct';

const MergeProductsDialog = memo((props) => {
  const {
    onMergeProductsClick,
    isOpened,
    onClose,
    commentMessages,
    onCommentsUpdate,
    product,
  } = props;

  const MERGE_TYPES = {
    1: 'suggest',
    2: 'manual',
    3: 'new'
  };

  const [targetProduct, setTargetProduct] = useState('');
  const [suggestProducts, setSuggestProducts] = useState([]);
  const [comment, setComment] = useState('');
  const [updating, setUpdating] = useState(false);
  const [systemComment, setSystemComment] = useState('Merge Product');
  const [errors, setErrors] = useState({});
  const [mergeType, setMergeType] = useState(0);

  useEffect(() => {
    if (props.mergeSearchedProduct) {
      setTargetProduct(props.mergeSearchedProduct);
    }
    if (props.mergeSuggestProducts) {
      setSuggestProducts(props.mergeSuggestProducts);
    }
    if (props.productsMerged && updating) {
      setUpdating(false);
      updateComments();
    }
  }, [props]);

  const updateComments = () => {
    const updatedActivityLogs = [{
      affectedIds: [product.id],
      entityType: entityTypes.catalogProductAttribute,
      comment: `${systemComment}@${comment}`,
    }];
    onCommentsUpdate(updatedActivityLogs);
    setComment('');
    setSystemComment('Merge Product');
    onClose();
  };

  const handleChangeComment = e => setComment(e.target.value);

  const handleChangeSystemComment = e => setSystemComment(e.target.value);

  const validateComments = () => {
    let hasErrors = false;
    const commentErrors = validateAll({
      ...commentValidations, ...systemCommentValidations,
    }, { comment, systemComment });

    if (!isEmpty(commentErrors)) {
      hasErrors = true;
      setErrors(commentErrors);
    } else {
      setErrors({});
    }
    return hasErrors;
  };

  const handleGetSuggestProducts = (e) => {
    setTargetProduct('');
    setSuggestProducts('');
    setMergeType(MERGE_TYPES[1]);
    props.getSuggestProducts(product.id);
  };

  const handleGetManually = () => {
    setTargetProduct('');
    setSuggestProducts('');
    setMergeType(MERGE_TYPES[2]);
  };

  const handleCreateNewProduct = () => {
    setTargetProduct('');
    setSuggestProducts('');
    setMergeType(MERGE_TYPES[3]);
  };

  const handleSetTargetProduct = (e) => {
    const { target } = e;
    setTargetProduct({id: target.id});
  };

  const submitMergeProducts = () => {
    const data = {
      data: {
        target_id: targetProduct ? targetProduct.id : null
      },
      comment: comment
    };

    const hasErrors = validateComments();
    if (hasErrors) {
      NotificationManager.error('Select a product to merge and enter a comment');
      return false;
    }
    
    onMergeProductsClick();
    setUpdating(true);

    props.mergeProducts({sendingData: data, id: product.id});
    closeMergeProductsDialog();
  };

  const closeMergeProductsDialog = () => {
    props.clearMergeProducts();
    setComment('');
    setSystemComment('Merge Product');
    setTargetProduct('');
    setSuggestProducts('');
    setMergeType(0);
    onClose();
  };

  const Suggest = ({item, manually}) => {
    return (
      <>
        {(item.images && item.images[0]) &&
          <div className="suggest-image">
            <img alt={item.id} src={item.images[0].url} width="100px" />
          </div>
        }
        <div className={(item.images && item.images[0]) ? 'suggest-info' : 'suggest-image'}>
          {manually ? (
            <div>
              <IntlMessages id="pim.mergeProductsDialog.targetProduct" />: <strong>{item.id}</strong>
            </div>
          ) : (
            <div className="suggest-product" id={item.id} onClick={e => handleSetTargetProduct(e)}>
              {item.id}
            </div>
          )}
          {item.title && (
            <div className="mapper-w-200">
              <IntlMessages id="pim.mergeProductsDialog.productTitle" /> : {item.title.en}
              <br />
            </div>
          )}
          {item.merchantId && (
            <>
              <IntlMessages id="pim.mergeProductsDialog.merchantID" /> : {item.merchantId}
              <br />
            </>
          )}
          {item.title && (
            <>
              <IntlMessages id="pim.mergeProductsDialog.domestic" /> : {item.isDomestic ? 'Yes' : 'No'}
              <br />
              <IntlMessages id="pim.mergeProductsDialog.createdAt" /> : {item.createdAt}
              <br />
              Type : {item.type}
            </>
          )}
        </div>
        <div className="clear-both" />
        <hr />
      </>
    );
  };

  return (
    <FormDialog
      title={<IntlMessages id="pim.mergeProductsDialog.title" />}
      open={isOpened}
      hideCloseButton={false}
      className="dialog-inputs-custom dialog-content-visible"
      closeButtonTitle="Close"
      submitButtonTitle="Submit"
      maxWidth="xs"
      onClose={closeMergeProductsDialog}
      onSubmit={submitMergeProducts}
    >

      <label For="type_1">
        <input id="type_1" checked={mergeType === MERGE_TYPES[1]} type="radio" onClick={handleGetSuggestProducts} />
        &nbsp;&nbsp;&nbsp;
        <IntlMessages id="pim.mergeProductsDialog.bySuggesting" />
      </label>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <label For="type_2">
        <input id="type_2" checked={mergeType === MERGE_TYPES[2]} type="radio" onClick={handleGetManually} />
        &nbsp;&nbsp;&nbsp;
        <IntlMessages id="pim.mergeProductsDialog.manually" />
      </label>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <label For="type_3">
        <input id="type_3" checked={mergeType === MERGE_TYPES[3]} type="radio" onClick={handleCreateNewProduct} />
        &nbsp;&nbsp;&nbsp;
        <IntlMessages id="pim.mergeProductsDialog.newProduct" />
      </label>
      
      <hr />

      {(mergeType === MERGE_TYPES[1] && suggestProducts.length === 0) &&
        <>
          <div><IntlMessages id="pim.mergeProductsDialog.wait" /> ...</div>
        </>
      }

      {(mergeType === MERGE_TYPES[1] && suggestProducts) &&
        <>
          {(suggestProducts && suggestProducts.variations && suggestProducts.variations.length > 0) &&
            <>
            <h3>Variations</h3>
            <div className="suggest-container">
              <div className="suggest-parent">
                {suggestProducts && suggestProducts.variations && suggestProducts.variations.map((item, itemKey) => (
                  <Suggest item={item} />
                ))}
              </div>
            </div>
            </>
          }

          {(suggestProducts && suggestProducts.parents && suggestProducts.parents.length > 0) &&
            <>
            <hr />
            <h3>Parents</h3>
            <div className="suggest-container">
              <div className="suggest-parent">
                {suggestProducts && suggestProducts.parents && suggestProducts.parents.map((item, itemKey) => (
                  <Suggest item={item} />
                ))}
              </div>
            </div>
            </>
          }

          {(suggestProducts && suggestProducts.variations && suggestProducts.variations.length === 0 &&
          suggestProducts.parents && suggestProducts.parents.length === 0) &&
          <>
            <div><IntlMessages id="pim.mergeProductsDialog.sorry" /></div>
          </>
          }
        </>
      }

      {mergeType === MERGE_TYPES[2] &&
        <TargetProduct product={product} targetProduct={targetProduct} />
      }

      {targetProduct && targetProduct.id &&
        <>
          {mergeType === MERGE_TYPES[1] &&
            <hr />
          }
          <Suggest item={targetProduct} manually={true} />
        </>
      }

      {(mergeType === MERGE_TYPES[3] || (targetProduct && targetProduct.id)) &&
        <Fragment>
          <Field
            type="dropdown"
            element="autocomplete"
            onChange={handleChangeSystemComment}
            options={commentMessages.map(cm => ({
              ...cm, label: cm.title, value: cm.title,
            }))}
            value={systemComment
              ? { label: systemComment, value: systemComment }
              : ''}
            className={
              `select-wrapper select-bordered top-oriented min-w-sm form-group block ml-0
            `}
            hideStaticLabel
            searchable
            menuPlacement="top"
            emptyPlaceholder="System comment"
            name="System comment"
            key="system-comment"
            label="System comment"
            suffixClassName="suffix-abs"
            suffix={!isEmpty(errors.systemComment) && (
              <Tooltip
                placement="top-end"
                title={(
                  <div className="tooltip-title-content-wrapper flex direction-column">
                    {errors.systemComment.map(e => <IntlMessages id={e.message} key={e.message} />)}
                  </div>
                )}
              >
                <i className="ti-info-alt" />
              </Tooltip>
            )}
            inputProps={{
              name: 'System comment',
              id: 'system-comment-select',
            }}
          />
          <Field
            element="text"
            onChange={handleChangeComment}
            value={comment}
            className={`min-w-sm input-bordered form-group input-text
            `}
            emptyPlaceholder="Comment"
            name="comment"
            key="comment"
            label="Comment"
            suffix={!isEmpty(errors.comment) && (
              <Tooltip
                placement="top-end"
                title={(
                  <div className="tooltip-title-content-wrapper flex direction-column">
                    {errors.comment.map(e => <IntlMessages id={e.message} key={e.message} />)}
                  </div>
                )}
              >
                <i className="ti-info-alt" />
              </Tooltip>
            )}
          />
        </Fragment>
      }
    </FormDialog>
  );
});

MergeProductsDialog.propTypes = {
  getSuggestProducts: func,
  mergeProducts: func,
  clearMergeProducts: func,
  mergeSearchedProduct: shape(),
  mergeSuggestProducts: shape(),
  productsMerged: shape(),
  onMergeProductsClick: func.isRequired,
  onClose: func.isRequired,
  isOpened: bool.isRequired,
  commentMessages: arrayOf(shape({
    title: string,
  })).isRequired,
  onCommentsUpdate: func.isRequired,
  product: shape({
    id: string,
  }).isRequired,
  targetProduct: shape({
    id: string
  })
};

MergeProductsDialog.defaultProps = {};

const mapStateToProps = state => ({
  mergeSearchedProduct: state.product.mergeSearchedProduct,
  mergeSuggestProducts: state.product.mergeSuggestProducts,
  productsMerged: state.product.productsMerged
});

const mapDispatchToProps = {
  getSuggestProducts,
  mergeProducts,
  clearMergeProducts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MergeProductsDialog));
