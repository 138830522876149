/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import React from 'react';
import Loadable from 'react-loadable';

// rct page loader
import RctPageLoader from '../RctPageLoader/RctPageLoader';

// Session Page 404
export const AsyncSessionPage404Component = Loadable({
  loader: () => import('../../pages/NotFound'),
  loading: () => <RctPageLoader />,
});

export default {
  AsyncSessionPage404Component,
};
