/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext } from 'react';

import { withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { AbilityContext } from 'components/AbilityContext';
import appPermissions from 'util/appPermissions';
import { fetchListDefaultAttributes } from 'actions/defaultAttribute';
import { fetchList as fetchListAttributesMatrix } from 'actions/attributesMatrix';
import Layout from '../../components/Layout';
import List from '../../components/List';
import PagePlaceholder from '../../components/PagePlaceholder';

import Sidebar from './components/Sidebar';
import { headerList } from '../utils/headerList';
import EditCategoryContainer from './containers/Edit';
import NewCategoryContainer from './containers/New';

import {
  categoriesPath, newCategoryPath, editCategoryPath,
} from './utils/paths';

import useAttributeMatrixEffects from './hooks/useAttributesMatrix';
import useDefaultAttributeEffects from './hooks/useDefaultAttribute';


const Category = (props) => {
  const abilityContext = useContext(AbilityContext);

  useAttributeMatrixEffects(props);
  useDefaultAttributeEffects(props);

  return (
    <Layout
      horizontalMenu
      header={{
        className: 'header-mer',
        subHeaderContent: (
          <List
            dir="horizontal"
            className="justify-center"
            dataSource={headerList({ abilityContext })}
          />
        ),
      }}
      sidebar={{
        className: 'sidebar-mer sidebar-category-mer sidebar-tree-checkbox-hide sidebar-scroll-secondary',
        content: <Sidebar />,
      }}
      main={{
        className: 'main-mer main-category-mer',
        content: (
          <Fragment>
            {abilityContext.can(
              appPermissions.category.permissions.view,
              appPermissions.category.name,
            ) && (
              <Route
                exact
                path={categoriesPath}
                render={() => (
                  <PagePlaceholder
                    icon={<i className="ti-arrow-circle-left text-primary text-lg" />}
                    title="Select a category"
                    subtitle="Select one of the Catecory to the left to show more information."
                  />
                )}
              />
            )}

            {abilityContext.can(
              appPermissions.category.permissions.view,
              appPermissions.category.name,
            ) && <Route exact path={editCategoryPath} component={EditCategoryContainer} />}
            {abilityContext.can(
              appPermissions.category.permissions.create,
              appPermissions.category.name,
            ) && <Route exact path={newCategoryPath} component={NewCategoryContainer} />}
          </Fragment>
        ),
      }}
    />
  );
};

Category.propTypes = {};

const actionCreators = {
  fetchListDefaultAttributes,
  fetchListAttributesMatrix,
};

const mapStateToProps = state => ({
  defaultAttribute: state.defaultAttribute,
  attributesMatrix: state.attributesMatrix,
});

export default withRouter(connect(mapStateToProps, actionCreators)(Category));
