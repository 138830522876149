/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useContext, memo, useState, useCallback,
} from 'react';
import { connect } from 'react-redux';
import {
  bool, func, string, shape, arrayOf,
} from 'prop-types';
import { isEmpty } from 'lodash';
import {
  Dialog, DialogActions, DialogContent, TextField, Tooltip, Typography,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { NotificationManager } from 'react-notifications';

import MerchantDetailContainer from '../MerchantDetailContainer';
import DetailContainer from '../ProductDetailContainer';
import { AbilityContext } from '../AbilityContext';
import appPermissions from '../../util/appPermissions';
import Select from '../Select';
import { defaultVisibilityStatusOptions } from './utils/defaultData';
import getPimStatus from './utils/getPimStatus';
import IntlMessages from '../../util/IntlMessages';

import {
  commentFieldValidations,
  productValidations,
  productNameValidations,
  validateVideoUrl,
} from './utils/validations';
import { checkChangedValues } from "./utils/checkChangedValues"
import { generatePayloadByType } from './utils/generatePayloadByType';
import checkValidations from '../../util/validator';
import { mapUserDetailsToComment } from '../../util/activityLog';
import { VariationInfo } from './components/VariationInfo';
import { ProductInfo } from './components/ProductInfo';
import { DialogActionsContent } from './components/DialogActionsContent';
import { ProductPrices } from './components/ProductPrices';
import { History } from './components/History';
import { Review } from './components/Review';

import {
  setErrors as setGlobalErrors,
  finishProductEdit,
  nextProductEdit,
  updateProduct,
  imsUpdateProductQty,
  setFields,
  multipleUpdateUserActivityLogs,
  setIsSaveAndClose,
  bulkDeleteProductLockedAttributes,
  setProductSpecificationErrors,
  fetchPureProduct,
  fetchActivityLogsByProductId,
} from '../../actions/product';

import {
  getProductReviews
} from '../../actions/yotpo';

import { setEditLanguage } from '../../actions/languageManager';
import { setMerchantActivityDialog } from '../../actions/pim';
import { getPriceQuantityHistory } from '../../actions/pim';

import {
  updateProductAttributes,
  clearCategoriesAttributesState,
} from '../../actions/productAttribute';

import UnlockAllAttributesDialog from '../../pages/Pim/components/UnlockAllAttributesDialog';

import {
  productSelector,
} from './selectors/productSelector';
import { productPriceFieldValidations } from '../MerchantDetailContainer/utils/validations';
import OpenProductPriceModal from "components/ProductPrice/OpenProductPriceModal"
import { pimSelector } from "../../pages/Pim/pages/Home/selectors/pim";
import { productAttributeSelector } from "../ProductDetailContainer/selectors/productAttributeSelector";
import Badge from "@material-ui/core/Badge";

const handleNotificationSuccess = (msg) => {
  NotificationManager.success(msg);
};

const ProductDetail = memo((props) => {
  const {
    pimAttributes,
    disabled,
    productProps,
    sessionItem,
    productList,
    containerClassName,
    refreshModal,
    getProductDetail,
    productComments,
    productAttributeProps,
    merchantLogs
  } = props;

  const [pageMode, setPageMode] = useState('information');
  const [leftInfo, setLeftInfo] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [comment, setComment] = useState('');
  const [systemComment, setSystemComment] = useState('Edit Product');
  const [errors, setErrors] = useState({});
  const [errorsMerchant, setErrorsMerchant] = useState({});
  const [productSuccessMessageShowed, psetPductSuccessMessageShowed] = useState(false);
  const [qtyError, setQtyError] = useState(null);
  const [isOpenedUnlockDialog, setIsOpenedUnlockDialog] = useState(false);
  const abilityContext = useContext(AbilityContext);

  useEffect(() => () => {
    props.clearCategoriesAttributesState();
  }, []);

  useEffect(() => () => {
    if (productProps.updated && props.productUpdating) {
      handleNotificationSuccess(<IntlMessages id="pim.product.update.alert.success" />);
      psetPductSuccessMessageShowed(true);
    }

    if (
      (!props.product.updated && props.product.updating && productSuccessMessageShowed)
      && (
        !props.product.fetchMerchantsCommentsCountFinished
        || !props.product.fetchProductCommentsFinished
        || !props.product.fetchVariationsCommentsCountFinished
      )
    ) {
      psetPductSuccessMessageShowed(false);
    }
  }, [
    productProps.updated,
    props.productUpdating,
  ]);

  if (isFirstLoad === true && productProps.info) {
    setLeftInfo(productProps.info);
    setIsFirstLoad(false);
  }

  const productIsParent = productProps.item.type === 'parent';
  const productIsMerchant = productProps.item.type === 'merchant';

  const isAbleUpdate = abilityContext.can(
    appPermissions.pim.permissions.update,
    appPermissions.pim.name,
  );

  const onRemoveLockedAttribute = useCallback((e, field) => {
    const removingLockedAttributes = productProps.fields.removingLockedAttributes ?
        productProps.fields.removingLockedAttributes :
        [];
    removingLockedAttributes.push(field);
    props.setFields({
      ...productProps.fields,
      removingLockedAttributes: removingLockedAttributes,
    });
  }, [props, productProps.fields]);

  const handleNotificationWarning = msg => NotificationManager.warning(msg);

  const validateDialog = () => {
    const res = checkValidations(commentFieldValidations, { systemComment });
    if (!isEmpty(res)) {
      setErrors(res);
      return true;
    }
    setErrors({});
    return false;
  };

  const validateProductMerchantPrice = () => {
    const { finalPrice, ils } = productProps.fields.price || {};
    const res = checkValidations(productPriceFieldValidations, { finalPrice, ils });
    if (!isEmpty(res)) {
      setErrorsMerchant(res);
      return true;
    }
    setErrorsMerchant({});
    return false;
  };

  const handleCommentChange = e => setComment(e.target.value);

  const handleSystemCommentChange = e => setSystemComment(e.target.value);

  const onSaveProductClick = async (closeAfterUpdate, newComment) => {
    props.setIsSaveAndClose(closeAfterUpdate);
    const payload = generatePayloadByType(
      productProps.fields.type,
      productProps.fields,
      productProps.item,
    );

    const productIsParent = productProps.fields.type === 'parent';

    const extraFields = productProps.fields.title ? {
      en: productProps.fields.title.en,
      he: productProps.fields.title.he,
    } : {};

    // Required fields "description" and "title" exists in  parent only
    const validationErrors = productIsParent ? checkValidations(productValidations, productProps.fields) : {};

    const nameErrors = productProps.fields.title
      ? checkValidations(productNameValidations, extraFields)
      : null;

    const videoErrors = validateVideoUrl(productProps.fields.videos);

    if (isEmpty(payload)) {
      return handleNotificationWarning(
        <IntlMessages id="pim.save.alert.warning" />,
      );
    }

    if (productProps.fields.title && isEmpty(productProps.fields.title.he)) delete nameErrors.he;
    if ((!isEmpty(validationErrors) || !isEmpty(nameErrors)) && productIsParent) {
      if (validationErrors.title || !isEmpty(nameErrors)) validationErrors.title = nameErrors;
      return props.setGlobalErrors(validationErrors);
    }
    if (!isEmpty(validationErrors) || !isEmpty(videoErrors)) {
      if (validationErrors.videos || !isEmpty(videoErrors)) validationErrors.videos = videoErrors;
      return props.setGlobalErrors(validationErrors);
    }

    if (!isEmpty(nameErrors)) {
      return false;
    }

    props.setGlobalErrors({});

    const userComment = newComment ? mapUserDetailsToComment(newComment, sessionItem) : null;

    const prevQuantity = productProps.item.stock ? productProps.item.stock.qty : 0;
    const currentQty = productProps.fields.stock ? productProps.fields.stock.qty : 0;
    let blockProdUpdate = false;

    if (productProps.item.type === 'merchant'
      && prevQuantity !== currentQty
      && (payload.stock && payload.stock.qty !== undefined))
    {
      try {
        const { data: { errors: someErrors } } =
          await imsUpdateProductQty(productProps.fields.id, productProps.fields.stock.qty);

        if (someErrors && someErrors.length > 0) {
          setQtyError(someErrors[0].message);
          blockProdUpdate = true;
        } else {
          payload.stock.qty = currentQty;
        }
      } catch (err) {
        const status = err.response && err.response.status;
        if (status === 503) {
          setQtyError('Inventory Service is under Maintenance now, please try again later');
        }
        if (status !== 503 && err.response.data) {
          setQtyError(err.response.data.error.message);
        }
        blockProdUpdate = true;
      }
    }

    if (!blockProdUpdate) {
      return props.updateProduct({
        payload, id: productProps.fields.id, comment: userComment,
      });
    }
  };

  const handleUnlockAllAttributes = () => {
    setIsOpenedUnlockDialog(true);
  }

  const handleCloseUnlockAttributesDialog = () => {
    setIsOpenedUnlockDialog(false);
  }

  const handleUnlockAttributes = () => {
    props.bulkDeleteProductLockedAttributes({
      productIds: [productProps.fields.id],
      fields: [],
      productTypes: [productProps.fields.type],
    }).then(() => {
      props.fetchPureProduct(productProps.fields.id);
    });

    setComment('');
    setSystemComment('');
    setIsOpenedUnlockDialog(false);
  }

  const handleSaveClick = async () => {
    if (productIsMerchant && validateProductMerchantPrice()) return;
    if (validateDialog() || disabled) return;
    await onSaveProductClick(false, `${systemComment}@Updated attributes: ${
      comment ? comment : checkChangedValues(
        productProps.fields, 
        productProps.item, 
        {
          string: "", 
          exceptions: ["updatedAt", "scores", "videosChange","imagesChange"],
          stringExceptions: ["specifications"],
          childrenValue: ["en", "he"]
        }
      )}`);
    setComment('');
    setSystemComment('Edit Product');
    refreshModal();
  };

  const handleSaveAndCloseProductClick = (newComment) => {
    onSaveProductClick(true, newComment);
  };

  const handleSaveAndClose = () => {
    if (productIsMerchant && validateProductMerchantPrice()) return;
    if (validateDialog() || disabled) return;
    handleSaveAndCloseProductClick(`${systemComment}@Updated attributes: ${
      comment ? comment : checkChangedValues(
        productProps.fields, 
        productProps.item, 
        {
          string: "", 
          exceptions: ["updatedAt", "scores", "videosChange","imagesChange"],
          stringExceptions: ["specifications"],
          childrenValue: ["en", "he"]
        }
      )}`);
    setComment('');
    setSystemComment('Edit Product');
  };

  const onTextFieldChange = (e) => {
    const { name, value } = e.target;
    const languageSensitiveFields = ['description', 'title', 'metaTitle', 'metaDescription', 'metaKeywords'];
    const fieldIsLanguageSensitive = languageSensitiveFields.includes(name);
    let withLanguage = '';

    if (fieldIsLanguageSensitive) {
      withLanguage = {
        ...productProps.fields[name],
        en: value,
      };
    }

    if (name === 'pimStatus') {
      const pimStatusValue = {
        status: fieldIsLanguageSensitive ? withLanguage : value,
        reviewedBy: sessionItem.email,
      };
      props.setFields({
        ...productProps.fields,
        [name]: pimStatusValue,
      });
    } else {
      props.setFields({
        ...productProps.fields,
        [name]: fieldIsLanguageSensitive
          ? withLanguage
          : value,
      });
    }
  };

  const onCloseDialog = () => {
    props.finishProductEdit();
    props.setGlobalErrors({});
  };

  const pimStatusField = !isEmpty(pimAttributes)
    && pimAttributes.find(pM => pM.code === 'pim_status.status');

  const isLocked = fieldName => !isEmpty(productProps.item.lockedAttributes)
    && productProps.item.lockedAttributes.some(lA => lA.includes(fieldName));

  const dialogClassName = productIsMerchant ? 'merchant-dialog' : '';

  const getLockIcon = (attrCode, iconClassName) => {
    const productLockedAttributes = productProps.lockedAttributes
        ? productProps.lockedAttributes
        : productProps.fields.lockedAttributes;

    const removingLockedAttributes = productProps.fields.removingLockedAttributes ?
        productProps.fields.removingLockedAttributes : [];

    if (
      productLockedAttributes
      && removingLockedAttributes
      && productLockedAttributes.indexOf(attrCode) !== -1
      && removingLockedAttributes.indexOf(attrCode) === -1)
    {
      return <i className={`zmdi zmdi-lock text-primary ${iconClassName}`} />;
    }

    return null;
  };

  const fetchComments = () => {
    if (productProps.productComments.length === 0) {
      props.fetchActivityLogsByProductId(productProps.fields.id);
    }
    setPageMode('history');
  };

  const fetchReviews = () => {
    props.getProductReviews(productProps.fields.id);
    setPageMode('review');
  };

  const fetchPrices = () => {
    props.getPriceQuantityHistory(productProps.fields.id);
    setPageMode('prices');
  }

  const editProduct = (id) => {
    props.setGlobalErrors({});
    props.nextProductEdit(true);
    setPageMode('information');
    getProductDetail(id, leftInfo);
  };

  const setStyles = () => {
    const windowHeight = parseInt(document.body.getBoundingClientRect().height);

    const leftCol = document.getElementById('left-col');
    const rightCol = document.getElementById('right-col');

    const rightColWidth = rightCol ? parseInt(rightCol.getBoundingClientRect().width) : 0;

    const rightScroll = document.getElementById('right-col-scroll');
    const productEditRight = document.getElementById('product-edit-right');
    const actionsBottom = document.getElementById('actions-bottom');

    const productEditLeft = document.getElementById('product-edit-left');
    const productEditWrapper = document.getElementById('product-edit-wrapper');
    const productEditDialogTopBlank = document.getElementById('product-edit-dialog-top-blank');

    if (leftCol && rightCol && productEditWrapper && productEditDialogTopBlank) {
      const leftColWidth = leftCol.getBoundingClientRect().width;
      const productEditWrapperWidth = parseInt(productEditWrapper.getBoundingClientRect().width);
      rightCol.style.maxWidth = productEditWrapperWidth - leftColWidth + 'px';
      productEditDialogTopBlank.style.width = rightCol.getBoundingClientRect().width - 30 + 'px';
      if (pageMode === 'information' && productIsParent === true) {
        productEditDialogTopBlank.style.height = '80px';
      } else if (pageMode === 'history' || pageMode === 'review') {
        productEditDialogTopBlank.style.height = '95px';
      } else {
        productEditDialogTopBlank.style.height = '0px';
      }
    }

    if (leftCol && rightCol && productEditRight && productEditLeft) {
      leftCol.style.height = windowHeight - 40 + 'px';
      rightCol.style.height = windowHeight - 40 + 'px';
      productEditRight.style.height = windowHeight - 40 + 'px';
      productEditLeft.style.height = windowHeight - 40 + 'px';
    }
    if (actionsBottom) {
      if (pageMode === 'prices' || pageMode === 'history' || pageMode === 'review') {
        actionsBottom.style.top = windowHeight - 110 + 'px';
      } else {
        actionsBottom.style.top = windowHeight - 135 + 'px';
      }
      actionsBottom.style.width = rightColWidth - 12 + 'px';
    }
    if (rightScroll) {
      rightScroll.style.width = rightColWidth - 66 + 'px';
    }
  };

  setTimeout(() => {
    setStyles();
  }, 250);

  window.addEventListener('resize', () => {
    setStyles();
  });

  const productDialogWidth = productIsParent ? 1330 : 500;

  return (
    <Dialog
      className={`product-dialog ${dialogClassName} ${containerClassName}`}
      open={productProps.nextProductLoading || (productProps.editInProgress && productProps.fields.id)}
      onClose={onCloseDialog}
      aria-labelledby="form-dialog-title"
      fullScreen
      style={{overflow: 'hidden'}}
    >
      <DialogContent onClick={() => setStyles()} className="product-edit-dialog">
        <div id="product-edit-wrapper" className="product-edit-dialog-wrapper">
          <div className="row">
            <div id="left-col" className="col-2" style={{minWidth: '300px', padding: '0 0 0 10px', height: '900px'}}>
              <div id="product-edit-left-scroll" className="product-edit-left-scroll">
                {!isEmpty(leftInfo) && (
                  <div id="product-edit-left" className="product-edit-left">
                    <ProductInfo
                      productProps={productProps}
                      editProduct={editProduct}
                      VariationInfo={VariationInfo}
                      productAttributeProps={productAttributeProps}
                      productId={ productProps.item.id }
                      info={leftInfo}
                    />
                  </div>
                )}
              </div>
            </div>
            <div id="right-col" className="col-10" style={{padding: '0', height: '900px'}}>
              <div id="product-edit-right-scroll" className="product-edit-right-scroll">
                <div id="product-edit-right" className="product-edit-right">
                  { (productIsParent || productIsMerchant) && (
                    <div className="product-edit-dialog-top">
                      <div
                        id="right-col-scroll"
                        className="product-edit-dialog-top-scroll"
                        style={{width: productDialogWidth + 'px'}}
                      >
                        <div className="product-edit-dialog-top-wrapper" style={{width: productDialogWidth + 'px'}}>

                          <div onClick={() => setPageMode('information')}
                               className={
                                 'product-edit-dialog-top-buttons button' + (pageMode === 'information' ? ' active' : '')
                               }>
                            <IntlMessages id="button.information" />
                          </div>
                          <div onClick={() => fetchPrices()}
                               className={
                                 'product-edit-dialog-top-buttons button' + (pageMode === 'prices' ? ' active' : '')
                               }>
                            <IntlMessages id="button.prices" />
                          </div>

                          {productIsParent && (
                            <>
                              <div onClick={() => fetchComments()}
                                   className={
                                     'product-edit-dialog-top-buttons button' + (pageMode === 'history' ? ' active' : '')
                                   }>
                                <IntlMessages id="button.history" />
                              </div>
                              {/*TODO
                          <div onClick={() => setPageMode('analytics')}
                               className={
                            'product-edit-dialog-top-buttons button' + (pageMode === 'analytics' ? ' active' : '')
                          }>
                            <IntlMessages id="button.analytics" />
                          </div>
*/}
                              <div onClick={() => fetchReviews()}
                                   className={
                                     'product-edit-dialog-top-buttons button' + (pageMode === 'review' ? ' active' : '')
                                   }>
                                <IntlMessages id="button.review" />
                              </div>
                              {/*TODO
                          <div onClick={() => setPageMode('seo')}
                               className={
                            'product-edit-dialog-top-buttons button' + (pageMode === 'seo' ? ' active' : '')
                          }>
                            <IntlMessages id="button.seo" />
                          </div>
*/}
                            </>
                          )}

                        </div>
                      </div>
                    </div>
                  )}

                  <div id="product-edit-dialog-top-blank" className="product-edit-dialog-top-blank">&nbsp;</div>

                  <div className={(productIsParent || productIsMerchant) ? 'product-edit-dialog-content' : 'product-edit-dialog-content-full'}>
                    {(pageMode === 'information' && !isEmpty(productProps.fields)) && (
                      productIsMerchant
                        ? (
                          <div className="merchant-edit-container-wrapper">
                            <MuiDialogTitle
                              className="dialog-title flex justify-between"
                              style={{border: 'none', padding: '0px'}}>
                              <div className="dialog-subtitle flex direction-column">
                                <Typography variant="h6">
                                  {productProps.fields.title && productProps.fields.title.en
                                    ? (productProps.fields.title.en)
                                    : (<IntlMessages id="preview.viewProductDialogTitle" />)
                                  }
                                </Typography>
                              </div>
                            </MuiDialogTitle>

                            <div className="merchant-top-buttons">
                              <div className="float-left">
                                <div className="float-left">
                                  <TextField
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    className="text-cap mt-0 mb-0"
                                    label={(
                                      <>
                                        {isLocked('content_source') &&
                                          <i className="zmdi icon-abs zmdi-lock left text-primary" />
                                        }
                                        <IntlMessages id="pim.dialog.contentSource.label" />
                                      </>
                                    )}
                                    defaultValue={productProps.fields.contentSource}
                                  />
                                  <span
                                    className="locked-attribute-merchant merchant-left-7"
                                    onClick={e => onRemoveLockedAttribute(e, 'content_source')}
                                  >
                                    { getLockIcon('content_source', 'left') }
                                  </span>
                                </div>
                                <div className="float-left">
                                  <Select
                                    id="visibilityStatus"
                                    name="visibilityStatus"
                                    fullWidth
                                    label={(<IntlMessages id="pim.dialog.visibilityStatus.label" />)}
                                    className="select-wrapper field-element"
                                    value={productProps.fields.visibilityStatus || ''}
                                    onChange={onTextFieldChange}
                                    options={defaultVisibilityStatusOptions}
                                    selectClassName="mb-10"
                                    prefixClassName="prefix-abs"
                                  />
                                  <span
                                    className="locked-attribute-merchant merchant-left-visibility-status"
                                    onClick={e => onRemoveLockedAttribute(e, 'visibility_status')}
                                  >
                                    { getLockIcon('visibility_status', 'left') }
                                  </span>
                                </div>
                                {pimStatusField && (
                                  <div className="float-left">
                                    <Select
                                      id="pimStatus"
                                      name="pimStatus"
                                      fullWidth
                                      label={(<IntlMessages id="pim.dialog.pimStatus.label" />)}
                                      className="select-wrapper field-element ml-20"
                                      value={
                                        getPimStatus(productProps.fields.pimStatus) || pimStatusField.options
                                          .find(pmf => pmf.value === 'not_reviewed').value
                                      }
                                      onChange={onTextFieldChange}
                                      options={pimStatusField.options}
                                      selectClassName="mb-10"
                                      prefixClassName="prefix-abs"
                                    />
                                    <span
                                      className="locked-attribute-merchant merchant-left-7"
                                      onClick={e => onRemoveLockedAttribute(e, 'pim_status')}
                                    >
                                        {productProps.item.pimStatus && productProps.item.pimStatus.reviewedBy && (
                                          <Tooltip
                                            className="pim-status-label"
                                            title={`Reviewed by: ${productProps.item.pimStatus.reviewedBy}`}
                                            placement="top-start"
                                            arrow
                                          >
                                            <Badge className="pim-status-label" color="error" variant="dot">
                                              &nbsp;
                                            </Badge>
                                          </Tooltip>
                                        )}
                                      { getLockIcon('pim_status', 'left') }
                                    </span>
                                  </div>
                                )}
                              </div>

                              <div className="float-right">
                                <OpenProductPriceModal priceFormula={productProps.item.priceFormula} button/>
                              </div>

                              <div className="clear-both" />
                            </div>

                            <MerchantDetailContainer comments={productProps.productComments} errors={errorsMerchant} />
                          </div>
                        ) : (
                          <DetailContainer
                            disabled={disabled}
                            specificationErrors={productProps.specificationErrors}
                            productList={productList}
                            pimAttributes={pimAttributes}
                            onTextFieldChange={onTextFieldChange}
                            nextProductLoading={productProps.nextProductLoading}
                          />
                        )
                    )}
                    { pageMode === 'prices' && (
                      <ProductPrices
                        productIsParent={productIsParent}
                        merchantLogs={merchantLogs}
                        productId={ productProps.item.id }
                        info={leftInfo}
                        editProduct={editProduct}
                        productAttributeProps={productAttributeProps}
                        currencyItem={props.currencyItem}
                      />
                    )}
                    { pageMode === 'history' && (
                      <History
                        productCommentsLoading={props.productCommentsLoading}
                        productComments={productComments}
                      />
                    )}
                    { pageMode === 'analytics' && (
                      <div>TBD</div>
                    )}
                    { pageMode === 'review' && props.yotpo && (
                      <Review
                        response={props.yotpo.response}
                        getProductReviews={props.getProductReviews}
                        productId={productProps.fields.id}
                      />
                    )}
                    { pageMode === 'seo' && (
                      <div>TBD</div>
                    )}
                  </div>

                  <div
                    id="actions-bottom"
                    className={productIsMerchant
                      ? 'product-edit-dialog-bottom merchant'
                      : 'product-edit-dialog-bottom'}
                  >
                    <DialogActions className="dialog-footer" style={
                      {display: 'block', paddingLeft: '0px', paddingRight: '0px'}
                    }>
                      <DialogActionsContent
                        handleCommentChange={handleCommentChange}
                        handleSystemCommentChange={handleSystemCommentChange}
                        handleUnlockAllAttributes={handleUnlockAllAttributes}
                        handleSaveAndCloseProductClick={handleSaveAndCloseProductClick}
                        handleSaveAndClose={handleSaveAndClose}
                        comment={comment}
                        errors={errors}
                        props={props}
                        systemComment={systemComment}
                        productProps={productProps}
                        qtyError={qtyError}
                        disabled={disabled}
                        onSaveProductClick={onSaveProductClick}
                        isAbleUpdate={isAbleUpdate}
                        handleSaveClick={handleSaveClick}
                        onCloseDialog={onCloseDialog}
                        pageMode={pageMode}
                      />
                    </DialogActions>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <UnlockAllAttributesDialog
        key={`unlock-dialog-${productProps.fields.id}`}
        isOpened={isOpenedUnlockDialog}
        onClose={handleCloseUnlockAttributesDialog}
        onUnlockAttributesClick={handleUnlockAttributes}
      />
    </Dialog>
  );
});

ProductDetail.propTypes = {
  clearCategoriesAttributesState: func.isRequired,
  setEditLanguage: func.isRequired,
  fetchPureProduct: func.isRequired,
  productLanguage: shape().isRequired,
  product: shape(),

  pimAttributes: arrayOf(shape({
    code: string,
  })),

  commentMessages: arrayOf(shape()),
  multipleUpdateUserActivityLogs: func.isRequired,
  disabled: bool,
  productUpdating: bool.isRequired,
  containerClassName: string,

  productProps: shape().isRequired,
  setGlobalErrors: func.isRequired,
  finishProductEdit: func.isRequired,
  nextProductEdit: func.isRequired,
  sessionItem: shape().isRequired,
  updateProduct: func.isRequired,
  setFields: func.isRequired,
  updateProductAttributes: func.isRequired,
  bulkDeleteProductLockedAttributes: func.isRequired,
  setIsSaveAndClose: func.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  getProductReviews: func.isRequired,
  setMerchantActivityDialog: func.isRequired,
  getPriceQuantityHistory: func.isRequired,
  productList: arrayOf(shape()).isRequired,
  productComments: arrayOf(shape()).isRequired,
  productCommentsLoading: bool.isRequired,
  pim: shape().isRequired,
  productAttributeProps: shape().isRequired,
  currencyItem: shape().isRequired,
  yotpo: shape().isRequired,
  merchantLogs: shape().isRequired,
};

ProductDetail.defaultProps = {
  product: {},
  pimAttributes: [],

  commentMessages: [],
  disabled: false,
  containerClassName: '',
};

const mapDispatchToProps = {
  setEditLanguage,
  setGlobalErrors,
  finishProductEdit,
  nextProductEdit,
  updateProduct,
  imsUpdateProductQty,
  setFields,
  updateProductAttributes,
  clearCategoriesAttributesState,
  fetchPureProduct,
  multipleUpdateUserActivityLogs,
  setIsSaveAndClose,
  bulkDeleteProductLockedAttributes,
  setProductSpecificationErrors,
  fetchActivityLogsByProductId,
  getProductReviews,
  setMerchantActivityDialog,
  getPriceQuantityHistory,
};

const mapStateToProps = state => ({
  commentMessages: state.commentMessage.list,
  productProps: productSelector(state.product),
  productUpdating: state.product.updating,
  sessionItem: state.session.item,
  productLanguage: state.languageManager.productLanguage,
  productComments: state.product.productComments,
  productCommentsLoading: state.product.fetchProductCommentsStart,
  pim: pimSelector(state.pim),
  productAttributeProps: productAttributeSelector(state.productAttribute),
  currencyItem: state.currency.item,
  yotpo: state.yotpo,
  merchantLogs:  state.pim.merchantLogs,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
