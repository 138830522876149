/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useSwitch = (state, props) => {
  useEffect(() => {
    state.setIsSwitchOn(props.isSwitchOn);
  }, [props.isSwitchOn]);
};

export default useSwitch;
