import firebase from 'firebase/app';
import RestActions from '../util/rest/actions';

import { previewFieldAttributesPath } from '../util/paths';
import { showErrorNotification } from '../util/api';

const restPreviewFieldAttributeActions = new RestActions('preview_field_attribute');

export const fetchPreviewFieldAttributes = () => async (dispatch) => {
  dispatch(restPreviewFieldAttributeActions.fetchAllStart());

  let previewFieldAttributes;
  try {
    previewFieldAttributes = await firebase.database().ref(
      previewFieldAttributesPath,
    ).once('value');
  } catch (error) {
    showErrorNotification(error, 'Firebase');
    dispatch(restPreviewFieldAttributeActions.fetchAllFinished({ hasError: true }));
  }
  const previewFieldAttributesArr = previewFieldAttributes.val();
  dispatch(restPreviewFieldAttributeActions.fetchAllFinished(previewFieldAttributesArr));
};

export default {
  fetchPreviewFieldAttributes,
};
