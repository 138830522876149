import React from 'react';
import { shape, bool, string } from 'prop-types';
import { isEmpty } from 'lodash';

import PagePlaceholder from '../../../../../components/PagePlaceholder';
import IntlMessages from '../../../../../util/IntlMessages';

const PagePlaceholderComponent = ({
  displayMode, productListEmpty, fetchingList, fetchedList, selectedEntity,
}) => (
  <>
    {!displayMode && productListEmpty && isEmpty(selectedEntity) && (
      <PagePlaceholder
        icon={<i className="ti-hand-point-up text-primary text-lg" />}
        title={<IntlMessages id="title.selectADisplayingMode" />}
        subtitle={<IntlMessages id="title.selectADisplayingModeBody" />}
      />
    )}
    {displayMode && productListEmpty && !fetchingList && fetchedList && (
      <PagePlaceholder
        icon={<i className="ti-face-sad text-primary text-lg" />}
        title={<IntlMessages id="title.sorry" />}
        subtitle={<IntlMessages id="title.noResultsFound" />}
      />
    )}
    {displayMode && productListEmpty && isEmpty(selectedEntity) && (
      <PagePlaceholder
        className="white"
        icon={<i className="ti-arrow-circle-left text-primary text-lg" />}
        title={<IntlMessages id="title.selectACategory" />}
        subtitle={<IntlMessages id="title.selectACategoryBody" />}
      />
    )}
  </>
);

PagePlaceholderComponent.propTypes = {
  fetchingList: bool.isRequired,
  fetchedList: bool.isRequired,
  displayMode: string.isRequired,
  productListEmpty: bool.isRequired,
  selectedEntity: shape(),
};

PagePlaceholderComponent.defaultProps = {
  selectedEntity: {},
};

export default PagePlaceholderComponent;
