export default [
  {
    value: 'edit',
    label: 'Edit',
    color: 'primary',
    className: '',
  }, {
    value: 'delete',
    label: 'Delete',
    color: 'secondary',
    className: 'button-danger',
  },
];
