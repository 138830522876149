import { createSelector } from 'reselect';

const promotionRootSelector = state => state;

const stateSelector = createSelector(
  promotionRootSelector,
  promotion => ({
    updating: promotion.updating,
    updated: promotion.updated,
  }),
);

export const promotionSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  promotionSelector,
};
