/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, shape } from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap';
import QueueAnim from 'rc-queue-anim';

import {
  signinUserInFirebase,
} from '../../../actions/auth';
import {
  forgotPasswordAppPath, rootAppPath,
} from '../../../util/paths';

import smallLogo from '../../../assets/img/logo-small.png';

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      sessionSet: false,
    };
  }

  componentDidUpdate() {
    const { success } = this.props.authUser;
    const { fetchedOne } = this.props.session;

    if (success && fetchedOne && !this.state.sessionSet) {
      this.props.history.push(rootAppPath);
      this.setState({ sessionSet: true });
    }
  }

  onUserLogin = () => {
    this.props.signinUserInFirebase(this.state);
  }

  onKeyPress = (e) => {
    if (e.charCode === 13) this.onUserLogin();
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const { email, password } = this.state;

    return (
      <QueueAnim type="bottom" duration={2000}>
        <div className="rct-session-wrapper flex justify-center items-center">
          <div className="session-inner-wrapper justify-center items-center">
            <div className="container">
              <div className="row row-eq-height justify-center">
                <div className="col-sm-7 col-md-7 col-lg-8">
                  <div className="session-body text-center">
                    <div className="session-head mb-30">
                      <div className="site-logo">
                        <Link to={rootAppPath} className="logo-normal header-brand">
                          <img src={smallLogo} alt="site logo" />
                        </Link>
                      </div>
                    </div>
                    <Form>
                      <FormGroup className="has-wrapper">
                        <Input
                          type="mail"
                          value={email}
                          name="email"
                          id="user-mail"
                          className="has-input input-lg"
                          placeholder="Enter Email Address"
                          onChange={this.onChange}
                          onKeyPress={this.onKeyPress}
                        />
                        <span className="has-icon">
                          <i className="ti-email" />
                        </span>
                      </FormGroup>
                      <FormGroup className="has-wrapper">
                        <Input
                          value={password}
                          type="Password"
                          name="password"
                          id="pwd"
                          className="has-input input-lg"
                          placeholder="Password"
                          onChange={this.onChange}
                          onKeyPress={this.onKeyPress}
                        />
                        <span className="has-icon"><i className="ti-lock" /></span>
                      </FormGroup>
                      <FormGroup className="mb-15">
                        <Link to={forgotPasswordAppPath}>
                          <Button>
                            Forgot password
                          </Button>
                        </Link>
                      </FormGroup>
                      <FormGroup className="mb-15">
                        <Button
                          color="primary"
                          className="btn-block text-white w-100"
                          variant="contained"
                          size="large"
                          onClick={this.onUserLogin}
                        >
                          Sign In
                        </Button>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </QueueAnim>
    );
  }
}

Signin.propTypes = {
  signinUserInFirebase: func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  authUser: shape().isRequired,
  session: shape().isRequired,
};

const mapDispatchToProps = {
  signinUserInFirebase,
};

const mapStateToProps = state => ({
  authUser: state.authUser,
  session: state.session,
});

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
