import React, { useState, useEffect, memo } from 'react';
import {
  shape, arrayOf, func, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import arrayMove from 'array-move';
import Fab from '@material-ui/core/Fab';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { Box, Button, Grid } from '@material-ui/core';
import IntlMessages from '../../util/IntlMessages';

const RenderRemoveIcon = ({ updateProductImages, img, disabled }) => (
  <Fab
    style={{ color: '#ffffff', backgroundColor: '#d3233e' }}
    aria-label="Add"
    className="product-edit-image-control"
    onClick={e => updateProductImages(e, img)}
    disabled={disabled}
  >
    <RemoveIcon />
  </Fab>
);

RenderRemoveIcon.propTypes = {
  img: shape(),
  updateProductImages: func,
  disabled: bool,
};

RenderRemoveIcon.defaultProps = {
  img: {},
  updateProductImages: null,
  disabled: false,
};

const RenderEditIcon = ({ img, onEdit, disabled }) => (
  <Fab
    color="primary"
    aria-label="Edit"
    className="product-edit-image-control"
    onClick={() => onEdit(img)}
    disabled={disabled}
  >
    <EditIcon />
  </Fab>
);

RenderEditIcon.propTypes = {
  img: shape().isRequired,
  onEdit: func.isRequired,
  disabled: bool.isRequired,
};

const SortableItem = SortableElement((props) => {
  const {
    img, isFirstImage, setEditFields, updateProductImages, imgParams, setImgParams,
    disabled,
  } = props;

  const onImgLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    return setImgParams({ naturalWidth, naturalHeight });
  };

  if (img.url.indexOf('null') === 0) {
    return '';
  }

  return (
    <div className={`flex dnd-img ${isFirstImage ? 'main' : ''}`}>
      <div className="img-item relative">
        <img onLoad={onImgLoad} src={img.url} alt={img.alt} />
        <div className="img-size">
          {`${imgParams.naturalWidth}x${imgParams.naturalHeight}`}
        </div>
        <Box style={{ position: 'absolute', bottom: 0 }}>
          <RenderRemoveIcon
            updateProductImages={updateProductImages}
            img={img}
            disabled={disabled}
          />
          <RenderEditIcon
            img={img}
            onEdit={setEditFields}
            disabled={disabled}
          />
        </Box>
      </div>
    </div>
  );
});

const SortableList = SortableContainer(props => (
  !isEmpty(props.imagesList)
    ? (
      <div className="img-list">
        <div className="flex flex-wrap img-container">
          {props.imagesList.map((img, index) => (
            <SortableItem
              key={img.url}
              index={index}
              img={img}
              setEditFields={props.setEditFields}
              isFirstImage={props.imagesList.indexOf(img) === 0}
              disabled={props.disabled}
              {...props}
            />
          ))
          }
        </div>
      </div>
    )
    : (
      <h6 className="helper-text h6 mt-10">
        <IntlMessages id="pim.dialog.product.image.noImages.message" />
      </h6>
    )
));

const ProductImagesComponent = memo((props) => {
  const { images, addProductImages, editProductImage } = props;
  const [localItems, setItems] = useState(images);
  const [imgParams, setImgParams] = useState({ naturalWidth: 0, naturalHeight: 0 });

  const [imageEditArea, showImageEditArea] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [imageAltEn, setImageAltEn] = useState('');
  const [imageAltHe, setImageAltHe] = useState('');
  const [imagePublicId, setImagePublicId] = useState('');
  const [imagePublicIdError, setImagePublicIdError] = useState(false);
  const [imageUrlError, setImageUrlError] = useState(false);
  const [imageAltError, setImageAltError] = useState(false);
  // 'add', 'edit', null
  const [editMode, setEditMode] = useState(null);

  const resetEditFields = () => {
    showImageEditArea(false);
    setEditMode('add');
    setImageUrl('');
    setImageAltEn('');
    setImageAltHe('');
    setImagePublicId('');
  };

  const handleCloseEditForm = () => {
    resetEditFields();
    showImageEditArea(false);
  };
  /**
   * @param img {{ url: string, alt: { en: string, he: string}, public_id: string }}
   */
  const setEditFields = (img) => {
    resetEditFields();
    showImageEditArea(true);
    setEditMode('edit');
    setImageUrl(img.url);
    setImageAltEn((img.alt && img.alt.en) ? img.alt.en : '');
    setImageAltHe((img.alt && img.alt.he) ? img.alt.he : '');
    // Need to catch publicId definition
    setImagePublicId(img.public_id ? img.public_id : img.publicId);
  };

  const checkFields = () => {
    if (!imageUrl) {
      setImageUrlError(true);
      return false;
    }
    if (!imagePublicId) {
      setImagePublicIdError(true);
      return false;
    }
    if ((imageAltEn || imageAltHe) && !(imageAltEn && imageAltHe)) {
      setImageAltError(true);
      return false;
    }

    setImageUrlError(false);
    setImagePublicIdError(false);
    setImageAltError(false);
    return true;
  };

  const handleAddImage = (e) => {
    if (!checkFields()) return;
    resetEditFields();
    addProductImages(e, {
      url: imageUrl,
      alt: (!imageAltEn || !imageAltHe) ? null : {
        en: imageAltEn,
        he: imageAltHe,
      },
      public_id: imagePublicId,
    });
  };

  const handleEditImage = (e) => {
    if (!checkFields()) return;
    resetEditFields();
    editProductImage(e, {
      url: imageUrl,
      alt: (!imageAltEn || !imageAltHe) ? null : {
        en: imageAltEn,
        he: imageAltHe,
      },
      public_id: imagePublicId,
    });
  };

  const handleOpenEditArea = () => {
    setEditMode('add');
    showImageEditArea(true);
  };

  useEffect(
    () => setItems(images),
    [images],
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = arrayMove(localItems, oldIndex, newIndex);
    setItems(newItems);
    props.onProductImagesDragEnd(newItems);
  };

  return (
    <div className="attributes-fields">
      <div key="videos" className="attributes-group-1">
        <h4 className="attribute-title"><IntlMessages id="pim.dialog.images.label" /></h4>
        <div className="images-list-dnd min-height-250 product-images-list-wrapper flex direction-column">
          <SortableList
            axis="xy"
            imagesList={!isEmpty(localItems) ? localItems : []}
            onSortEnd={onSortEnd}
            helperClass="sortable-helper"
            setEditFields={setEditFields}
            imgParams={imgParams}
            setImgParams={setImgParams}
            disabled={props.disabled}
            {...props}
          />
        </div>

        <div className="group-sm">
          {!imageEditArea && (
            <Fab
              color="primary"
              aria-label="Add"
              className="product-edit-image-add"
              onClick={handleOpenEditArea}
            >
              <AddIcon />
            </Fab>
          )}
        </div>
        {imageEditArea && (
        <>
          <div className="group-sm">
            <TextField
              error={imageUrlError}
              label="Image url"
              variant="outlined"
              size="small"
              className="block"
              name="url"
              onChange={e => setImageUrl(e.target.value)}
              value={imageUrl}
              helperText={imageUrlError && 'Required'}
            />
          </div>
          <div className="group-sm">
            <TextField
              error={imagePublicIdError}
              label="Image public id"
              variant="outlined"
              size="small"
              className="block"
              name="url"
              onChange={e => setImagePublicId(e.target.value)}
              value={imagePublicId}
              helperText={imagePublicIdError && 'Required field'}
            />
          </div>
          <div className="group-sm">
            <TextField
              error={imageAltError}
              label="Image alt code (en)"
              variant="outlined"
              size="small"
              className="block"
              name="url"
              onChange={e => setImageAltEn(e.target.value)}
              value={imageAltEn}
            />
          </div>
          <div className="group-sm">
            <TextField
              error={imageAltError}
              label="Image alt code (he)"
              variant="outlined"
              size="small"
              className="block"
              name="url"
              onChange={e => setImageAltHe(e.target.value)}
              value={imageAltHe}
              helperText={imageAltError && ''}
            />
          </div>
          <Grid
            container
            className="group-sm"
            spacing={2}
          >
            <Grid item>
              { ['add', null].includes(editMode)
                ? <Button variant="contained" color="primary" className="text-white" onClick={handleAddImage}>
                    <IntlMessages id="buttons.add" />
                  </Button>
                : <Button variant="contained" color="primary" className="text-white" onClick={handleEditImage}>
                    <IntlMessages id="buttons.save" />
                  </Button>
              }
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" className="btn-info text-white" onClick={handleCloseEditForm}>
                <IntlMessages id="buttons.close" />
              </Button>
            </Grid>
          </Grid>
        </>
        )}
      </div>
    </div>
  );
});

ProductImagesComponent.propTypes = {
  images: arrayOf(shape()),
  updateProductImages: func,
  addProductImages: func.isRequired,
  editProductImage: func.isRequired,
  onProductImagesDragEnd: func,
  disabled: bool,
};

ProductImagesComponent.defaultProps = {
  images: [],
  updateProductImages: null,
  onProductImagesDragEnd: null,
  disabled: false,
};

export default ProductImagesComponent;
