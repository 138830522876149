import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { uniq, uniqBy, isEmpty } from 'lodash';
import { showErrorNotification } from '../util/api';

import {
  productAttributesOptionsSearchPath,
} from '../util/paths';

import {
  VIRTUAL_FACET_SEARCH_ATTRIBUTE_OPTIONS_START,
  VIRTUAL_FACET_SEARCH_ATTRIBUTE_OPTIONS_FINISHED,
  VIRTUAL_FACET_CLEAR_FOUND_ATTRIBUTE_OPTIONS,
  VIRTUAL_FACET_CLEAR_INITIAL_ATTRIBUTE_OPTIONS,
  VIRTUAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START,
  VIRTUAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
  VIRTUAL_FACET_SET_SELECTED_ATTRIBUTE_OPTION,
  VIRTUAL_FACET_SET_SELECTED_ATTRIBUTES_OPTIONS,
} from './types';

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';

export const searchAttributeOptions = (attributeCode, query) => (dispatch) => {
  const params = {

    filter: [{
      condition: 'and',
      group: [{
        field: 'label.en',
        operator: 'like',
        value: `*${query}*`,
      }, {
        field: 'attribute_code',
        operator: typeof attributeCode === 'string' ? 'eq' : 'in',
        value: attributeCode,
      }],
    }, {
      condition: 'and',
      group: [{
        field: 'label.en.keyword',
        operator: 'like',
        value: `*${query}*`,
      }, {
        field: 'attribute_code',
        operator: typeof attributeCode === 'string' ? 'eq' : 'in',
        value: attributeCode,
      }],
    }],
    fields: [
      'attribute_code',
      'option_code',
      'label',
    ],
    pagination: { page: 1, limit: 50 },
  };

  dispatch({
    type: VIRTUAL_FACET_SEARCH_ATTRIBUTE_OPTIONS_START,
    payload: {
      searchAttributeOptionsStart: true,
      searchAttributeOptionsFinished: false,
    },
  });

  axios.post(productAttributesOptionsSearchPath + '?ref=' + ref, {
    ...decamelizeKeys(params),
  }).then((res) => {
    const result = [...res.data.data];
    const foundOptions = camelizeKeys(result);

    dispatch({
      type: VIRTUAL_FACET_SEARCH_ATTRIBUTE_OPTIONS_FINISHED,
      payload: {
        searchAttributeOptionsStart: false,
        searchAttributeOptionsFinished: true,
        foundOptions,
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: VIRTUAL_FACET_SEARCH_ATTRIBUTE_OPTIONS_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  });
};

export const clearFoundAttributeOptions = () => (dispatch) => {
  dispatch({
    type: VIRTUAL_FACET_CLEAR_FOUND_ATTRIBUTE_OPTIONS,
    payload: { foundOptions: [] },
  });
};

export const clearInitialAttributeOptions = () => (dispatch) => {
  dispatch({
    type: VIRTUAL_FACET_CLEAR_INITIAL_ATTRIBUTE_OPTIONS,
    payload: {
      initialOptions: [],
      fetchInitialAttributesOptionsStart: false,
      fetchInitialAttributesOptionsFinished: false,
    },
  });
};

export const fetchInitialAttributeOptions = (codes, options) => (dispatch) => {
  const fiteredOptions = options.filter(o => o.startsWith('optn_'));
  if (!isEmpty(fiteredOptions)) {
    const group = [{
      field: 'option_code',
      operator: 'in',
      value: uniq(fiteredOptions.flat()),
    }, {
      field: 'attribute_code',
      operator: 'in',
      value: uniq(codes.flat()),
    }];

    const params = {
      filter: [{
        condition: 'and',
        group,
      }],
      fields: [
        'attribute_code',
        'option_code',
        'label',
      ],
      pagination: { page: 1, limit: fiteredOptions.length },
    };
    dispatch({
      type: VIRTUAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START,
      payload: {
        fetchInitialAttributesOptionsStart: true,
        fetchInitialAttributesOptionsFinished: false,
      },
    });
    axios.post(productAttributesOptionsSearchPath + '?ref=' + ref, {
      ...decamelizeKeys(params),
    }).then((res) => {
      const result = [...res.data.data];
      const initialOptions = camelizeKeys(result);
      dispatch({
        type: VIRTUAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
        payload: {
          fetchInitialAttributesOptionsStart: false,
          fetchInitialAttributesOptionsFinished: true,
          initialOptions,
        },
      });
    }, (error) => {
      showErrorNotification(error, 'CDMS');
      dispatch({
        type: VIRTUAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
        payload: { error: error.response.data, hasError: true },
      });
    });
  }
};

export const setSelectedAttributeOption = (option, rewrite = false) => (dispatch, getState) => {
  const { selectedAttributesOptions } = getState().virtualFacet;
  const payload = {
    selectedAttributesOptions: rewrite
      ? [option]
      : uniqBy([...selectedAttributesOptions, option], 'optionCode'),
  };

  dispatch({
    type: VIRTUAL_FACET_SET_SELECTED_ATTRIBUTE_OPTION,
    payload,
  });
};

export const setSelectedAttributesOptions = (options, rewrite = false) => (dispatch, getState) => {
  const { selectedAttributesOptions } = getState().virtualFacet;
  const payload = {
    selectedAttributesOptions: rewrite
      ? options
      : uniqBy([...selectedAttributesOptions, ...options], 'optionCode'),
  };

  dispatch({
    type: VIRTUAL_FACET_SET_SELECTED_ATTRIBUTES_OPTIONS,
    payload,
  });
};
