import 'date-fns';
import React from 'react';
import {
  string, oneOfType, node, func, shape,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import IntlMessages from '../../util/IntlMessages';

const MaterialUIPickers = (props) => {
  const handleDateChange = (selectedValue) => {
    if (props.onChange) {
      switch (props.callback.type) {
        case 'object':
          props.onChange({
            target: {
              value: moment(selectedValue).format(props.callback.format),
              name: props.name,
            },
          });
          break;

        default:
          props.onChange(selectedValue);
          break;
      }
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          disableToolbar
          className={`date-picker ${props.className}`}
          variant={props.variant}
          format={props.format}
          margin={props.margin}
          id="date-picker-inline"
          label={props.label}
          value={props.value || null}
          onChange={handleDateChange}
          maxDate={props.maxDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

MaterialUIPickers.propTypes = {
  className: string,
  callback: shape({
    type: string,
    format: string,
  }),
  name: string,
  margin: string,
  format: string,
  variant: string,
  label: oneOfType([string, node]),
  value: string,
  maxDate: shape(),
  onChange: func,
};

MaterialUIPickers.defaultProps = {
  callback: shape({
    type: '',
    format: '',
  }),
  className: '',
  name: '',
  margin: 'normal',
  variant: 'inline',
  format: 'MM/dd/yyyy',
  label: <IntlMessages id="datepicker.label.selectDate" />,
  maxDate: () => { },
  value: () => { },
  onChange: null,
};

export default MaterialUIPickers;
