import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const productItemSelector = state => state.item;
const productRootSelector = state => state;

const mapProductsImages = (images) => {
  if (!images) return [];
  return ([
    ...images.map((img) => {
      const params = '?auto=format%2Ccompress&bg=transparent&fill=solid&fit=fillmax&h=220&trim=auto&trimsd=1&w=auto';
      return {
        ...img,
        url: `${img.url}${params}`,
      };
    }),
  ]);
};

const itemSelector = createSelector(
  productItemSelector,
  item => (!isEmpty(item) ? ({
    ...item,
    images: mapProductsImages(item.images),
  }) : {}),
);

export const productSelector = createSelector(
  itemSelector,
  productRootSelector,
  (item, state) => ({
    ...state, item,
  }),
);

export default {
  productSelector,
};
