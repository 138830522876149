import React from 'react';
import { camelize, decamelize } from 'humps';
import {
  array, shape, arrayOf, bool, func, string,
} from 'prop-types';
import {
  Tooltip, Grid, Card, CardMedia, CardContent, CardActions,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines } from '@fortawesome/free-solid-svg-icons';

import { sortableHandle } from 'react-sortable-hoc';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../../../util/IntlMessages';

import Checkbox from '../../../../components/Checkbox';
import ShowMore from '../ShowMore';

const DragHandle = sortableHandle(() => <FontAwesomeIcon size="lg" icon={faGripLines} />);

const VariantLevelItem = (props) => {
  const {
    onProductSelect, variantValue, variantProductItem, isEditMode,
    selectedProducts, disabled, defaultImage,
  } = props;
  const temporaryDisabled = true;

  const handleProductSelect = () => {
    onProductSelect({ ...variantValue, id: variantValue.productId });
  };

  const handleEditClick = (event) => {
    props.onEditClick({ event, productItem: variantProductItem });
  };

  const imgUrl = variantValue.imgData && variantValue.imgData.url
    ? variantValue.imgData.url
    : defaultImage;

  const fetchComments = () => props.fetchActivityLogsByProductId(variantProductItem.id);

  return (
    <Grid item xs={12}>
      <div className="product-item flex items-center pr-20 pl-20">
        <div className="product-item-type variation">
          <span>variation</span>
        </div>
        <Card className="product-item-card-list without-dnd">
          <CardActions className="card-collapse-btn pr-0">
            {isEditMode && (
              <div>
                <Checkbox
                  checked={selectedProducts.some(p => p.id === variantValue.productId)}
                  onChange={handleProductSelect}
                  name={variantValue.title ? camelize(variantValue.title) : ''}
                />
              </div>
            )}
          </CardActions>
          <Tooltip
            className="img-tooltip"
            title={(
              <img
                className="product-item-image tooltip-inner"
                src={imgUrl}
                alt=""
              />
            )}
            placement="right"
          >
            <CardMedia
              className="product-item-image"
              image={imgUrl}
            />
          </Tooltip>
          <CardContent className="flex full-width ml-0 mr-0">
            <div className="flex items-center full-width">
              {
                Object.keys(variantValue).filter(vKey => (typeof variantValue[vKey] === 'string'
                || typeof variantValue[vKey] === 'number') && vKey !== 'id').map((itemKey) => {
                  const val = variantValue[itemKey];
                  const itemValueLength = val ? val.length : 0;
                  const title = decamelize(itemKey).replace('_', ' ');

                  return (
                    <div
                      className={`flex direction-column pr-20 pl-20 product-item-data ${itemValueLength <= 25 || itemKey === 'items' ? 'tiny' : ''}`}
                      key={itemKey}
                    >
                      <div className="flex">
                        <span className="key capitalize">{title}</span>
                        {
                          itemValueLength && itemValueLength > 25 && !isEditMode
                            ? (
                              <ShowMore
                                attr={{ key: title }}
                                itemValue={val}
                              />
                            )
                            : null
                        }
                      </div>
                      <span className={`value value-${itemKey}`}>
                        {
                          val && val.length > 60
                            ? `${val.substring(0, 60)}...`
                            : val
                        }
                      </span>
                    </div>
                  );
                })
              }
            </div>
            {props.onEditClick && (
              <div className="flex edit-icon-wrapper items-center">
                <div className="edit-icon">
                  <Button
                    color="primary"
                    className="btn-xs extra float-right"
                    onClick={handleEditClick}
                  >
                    <IntlMessages id="preview.table.product.button.view.label" />
                  </Button>
                </div>
                {
                  props.hasComments && (
                    <Tooltip
                      onClick={fetchComments}
                      title={(
                        <div className="comments">
                          {
                            props.productCommentsLoading
                              ? <IntlMessages id="preview.list.productComments.loading" />
                              : props.productComments.map((pc, index) => (
                                <div key={pc.id}>
                                  {`${index + 1}. ${pc.comment}`}
                                </div>
                              ))
                          }
                        </div>
                      )}
                      placement="left"
                    >
                      <i className="ti-comment-alt icon-premium icon-xs" />
                    </Tooltip>
                  )
                }
              </div>
            )}
            {!disabled && !temporaryDisabled && (
              <div className="flex ml-auto dnd-icon items-center">
                <DragHandle />
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </Grid>
  );
};

VariantLevelItem.propTypes = {
  selectedProducts: arrayOf(shape()).isRequired,
  variantValue: shape().isRequired,
  variantProductItem: shape({
    variants: array,
    childrenIds: array,
  }).isRequired,
  productComments: arrayOf(shape()).isRequired,
  defaultImage: string.isRequired,
  isEditMode: bool.isRequired,
  hasComments: bool.isRequired,
  disabled: bool,
  productCommentsLoading: bool.isRequired,
  onEditClick: func.isRequired,
  onProductSelect: func.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
};

VariantLevelItem.defaultProps = {
  disabled: false,
};

export default VariantLevelItem;
