import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

const initialState = {};
const rankingCategoryRestTypes = restTypes('ranking_category');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case rankingCategoryRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case rankingCategoryRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    default: return state;
  }
};
