/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  func, shape, arrayOf,
} from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import { camelize } from 'humps';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationManager } from 'react-notifications';

import IntlMessages from '../../../../util/IntlMessages';
import { actionOptions, actions, productStatuses } from '../../utils/actionBarConsts';
import { actionAttributeValidations } from '../../utils/validations';
import validateAll from '../../../../util/validator';
import {
  commentValidations,
  systemCommentValidations,
} from './utils/validations';
import changeProductsStatus from './utils/changeProductsStatus';
import changeProductsPimStatus from './utils/changeProductsPimStatus';
import { mapUserDetailsToComment } from '../../../../util/activityLog';

import {
  searchAttributeOptions,
  clearFoundAttributeOptions,
  fetchAttributeOptions,
} from '../../../../actions/productAttribute';

import {
  setSelectedAction,
  setContainerStyles,
  setSelectedProducts,
  bulkDeleteProductLockedAttributes,
  updateMultipleProducts,
} from '../../../../actions/pim';

import {
  multipleUpdateUserActivityLogs,
} from '../../../../actions/product';

import {
  pimSelector,
} from './selectors/pimSelector';
import {
  productAttributeSelector,
} from './selectors/productAttributeSelector';

import { mergeFields } from '../../pages/Home/utils/mappers';
import entityTypes from '../../../../util/entityTypes';

import ActionBarForm from './components/ActionBarForm';
import ActionBarControls from './components/ActionBarControls';

const parentActions = [actions.edit];

const ListActionBar = (props) => {
  const {
    pimProps, productAttributeProps, pimModeAttributes, activeUser,
    commentMessages,
  } = props;
  const [comment, setComment] = useState('');
  const [systemComment, setSystemComment] = useState('');
  const [attribute, setAttribute] = useState('');
  const [attributeValue, setAttributeValue] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertSettings, setAlertSettings] = useState({
    showSuccess: true,
    content: 'pim.list.actionBar.saveModal.content.onlyParentUpdate',
  });
  const [attributeErrors, setAttributeErrors] = useState({});
  const [availableAttributeList, setAvailabelAttributeList] = useState([]);
  const [errors, setErrors] = useState({});

  const loadFinished = (
    productAttributeProps.productAttributesFetched
    && !productAttributeProps.productAttributesFetching
  );

  const formatAttributeOptions = (options) => {
    if (!isEmpty(options)) {
      return options.map(o => ({
        label: o.label.en,
        value: o.label.en,
      }));
    }
    return [];
  };

  const getAvailableFilterAttributes = () => {
    let availableAttributes = [];
    if (!isEmpty(productAttributeProps.productAttributes)) {
      availableAttributes = productAttributeProps.productAttributes
        .filter(attr => attr.isFilterable)
        .map(attr => ({
          elementType: attr.frontendInputType,
          label: attr.label.en,
          value: attr.code || attr.value,
          disabled: attr.disabled,
          options: formatAttributeOptions(attr.options),
        }));
    }
    const totalList = [
      ...availableAttributes,
      ...pimModeAttributes.filter(attr => attr.isEditable),
    ];

    setAvailabelAttributeList(totalList);
    return totalList;
  };

  useEffect(() => {
    const actionBar = document.querySelector('.actions-bar');
    const actionBarHeight = actionBar ? actionBar.offsetHeight : 0;
    const newHeight = `calc(100% - ${actionBarHeight + 58 + 66}px)`;

    if (actionBar && pimProps.containerStyles.height !== newHeight) {
      props.setContainerStyles({
        height: `calc(100% - ${actionBarHeight + 58 + 66}px)`,
      });
    }
  });

  useEffect(() => {
    if (loadFinished) {
      getAvailableFilterAttributes();
    }
  }, [loadFinished]);

  useEffect(() => {
    props.setSelectedAction('');
  }, [pimProps.selectedProducts]);

  useEffect(() => {
    setComment('');
    setSystemComment('');
    setAttribute('');
    setAttributeValue('');
  }, [
    pimProps.selectedAction,
    pimProps.selectedProducts,
  ]);

  useEffect(() => {
    setAttributeValue('');
  }, [attribute]);

  const handleNotificationWarning = msg => NotificationManager.warning(msg);

  const handleChangeAttribute = async (e) => {
    const availableAttributes = getAvailableFilterAttributes();
    const selectedAttribute = availableAttributes
      .find(a => a.value === e.target.value) || {};
    let currentAttributeWithOptions = productAttributeProps.attributesOptions
      .find(attr => attr.code === e.target.value);

    if (isEmpty(currentAttributeWithOptions)) {
      if (!selectedAttribute.isCustomAttribute) {
        if (isEmpty(currentAttributeWithOptions)) {
          const attributeWithOptions = await props.fetchAttributeOptions(e.target.value);
          currentAttributeWithOptions = attributeWithOptions
            .find(attr => attr.code === e.target.value);
          currentAttributeWithOptions.options = formatAttributeOptions(
            currentAttributeWithOptions.options,
          );
        }
      } else {
        currentAttributeWithOptions = selectedAttribute;
      }
    } else {
      currentAttributeWithOptions = {
        ...currentAttributeWithOptions,
        options: currentAttributeWithOptions.options.map(o => ({
          label: o.label && o.label.en ? o.label.en : o.label,
          value: o.optionCode,
        })),
      };
    }

    setAttributeValue('');
    setAttribute({
      ...selectedAttribute,
      elementType: selectedAttribute.elementType && selectedAttribute.elementType.startsWith('dropdown_')
        ? 'dropdown'
        : selectedAttribute.elementType,
      options: currentAttributeWithOptions
        ? currentAttributeWithOptions.options
        : [],
    });
  };

  const toggleAlert = () => {
    setShowAlert(!showAlert);
  };

  const checkIsParentExist = () => pimProps.selectedProducts.some(p => p.type === 'parent');

  const getActionOptions = () => {
    let updatedActionOptions = [...actionOptions];
    updatedActionOptions = actionOptions.map((a) => {
      const isParentAction = parentActions.find(pa => pa === a.value);

      if (!checkIsParentExist() && isParentAction) {
        return {
          ...a,
          disabled: true,
        };
      }

      return a;
    });
    return updatedActionOptions;
  };

  const checkIsProductLevelAttribute = () => {
    const productAttribute = pimModeAttributes
      .find(pa => pa.value === attribute.value && !pa.isProductAttribute);
    return !!productAttribute;
  };

  const getAttributeData = (name, value) => {
    switch (name) {
      case 'title':
      case 'description':
        return ({ en: value });

      default:
        return value;
    }
  };

  const mapAttribute = (products) => {
    if (checkIsProductLevelAttribute()) {
      return products.map((p) => {
        const attributeName = camelize(attribute.value);
        const product = {
          id: p.id,
        };

        if (p[attributeName] === attributeValue) {
          product.noChanges = true;
        }

        if (attributeName.includes('.')) {
          const splittedName = attributeName.split('.');
          product[splittedName[0]] = {
            ...p[splittedName[0]],
            [splittedName[1]]: attributeValue,
          };
        } else {
          product[attributeName] = getAttributeData(attributeName, attributeValue);
        }
        return product;
      }).filter(p => !p.noChanges);
    }

    const updatedProducts = products.map((p) => {
      const updatedProduct = { id: p.id, specifications: p.specifications };
      const attributeName = camelize(attribute.value);

      if (p.specifications && p.specifications[attributeName] === attributeValue) {
        updatedProduct.noChanges = true;
      }

      if (p.specifications) {
        updatedProduct.specifications[attributeName] = attributeValue;
      }

      return updatedProduct;
    }).filter(p => !p.noChanges);
    return updatedProducts;
  };

  const validateAttributes = () => {
    const attributesErrors = validateAll(actionAttributeValidations, {
      attribute,
      attributeValue,
    });
    setAttributeErrors(attributesErrors);
    return attributesErrors;
  };

  const validateActionBar = () => {
    let hasErrors = false;
    if (pimProps.selectedAction === actions.edit) {
      const attributesErrors = validateAttributes();
      if (!isEmpty(attributesErrors)) {
        hasErrors = true;
      }
    }

    const commentErrors = validateAll({
      ...commentValidations, ...systemCommentValidations,
    }, { comment, systemComment });

    if (!isEmpty(commentErrors)) {
      hasErrors = true;
      setErrors(commentErrors);
    } else {
      setErrors({});
    }

    return !hasErrors;
  };

  const mapActionData = (products) => {
    const productsList = cloneDeep(products);
    let updatedProducts = [];

    switch (pimProps.selectedAction) {
      case actions.restore:
        updatedProducts = changeProductsStatus(
          productsList.filter(p => p.visibilityStatus !== productStatuses.visibleGlobal),
          productStatuses.visibleGlobal,
        );
        return updatedProducts;
      case actions.remove:
        updatedProducts = changeProductsStatus(
          productsList.filter(p => p.visibilityStatus !== productStatuses.notVisible),
          productStatuses.notVisible,
        );
        return updatedProducts;
      case actions.markAsReviewed:
        updatedProducts = changeProductsPimStatus({
          productsList,
          status: productStatuses.reviewed,
          reviewer: activeUser.email,
        });
        return updatedProducts;
      case actions.markAsNotReviewed:
        updatedProducts = changeProductsPimStatus({
          productsList,
          status: productStatuses.notReviewed,
          reviewer: activeUser.email,
        });
        return updatedProducts;
      case actions.unlockAttributes:
        updatedProducts = productsList.filter(p => p.lockedAttributes && p.lockedAttributes.length);
        return updatedProducts;
      case actions.edit: {
        updatedProducts = mapAttribute(productsList);
        return updatedProducts;
      }
      default: return updatedProducts;
    }
  };

  const isParentProducts = () => {
    const onlyParentIsPresent = pimProps.selectedProducts
      .every(p => p.type === 'parent');

    return onlyParentIsPresent;
  };

  const handleUnlockAttributesSubmit = (filteredProducts) => {
    if (pimProps.selectedAction === actions.unlockAttributes) {
      if (isEmpty(filteredProducts)) {
        setAlertSettings({
          showSuccess: false,
          content: 'pim.list.actionBar.saveModal.content.noProductsWithLockedAttrs',
        });
        setShowAlert(true);
        return false;
      }
      return true;
    }
    return false;
  };

  const handleMultipleProductsUpdate = (products, newComment, actionType) => {
    const userComment = newComment
      ? mapUserDetailsToComment(newComment, activeUser)
      : null;
    switch (actionType) {
      case 'bulkDeleteLockedAttributes': {
        const productIds = products.map(p => p.id);
        const productTypes = products.map(p => p.type);
        props.bulkDeleteProductLockedAttributes({
          productIds,
          fields: mergeFields([
            ...pimProps.globalFields[pimProps.filterTarget],
            ...pimProps.savedFilterFields[pimProps.filterTarget],
          ]),
          filterTarget: pimProps.filterTarget,
          productTypes,
        });

        const updatedActivityLogs = [{
          affectedIds: productIds,
          entityType: entityTypes.catalogProductAttribute,
          newComment,
        }];

        props.multipleUpdateUserActivityLogs(updatedActivityLogs);
      }
        break;
      default:
        props.updateMultipleProducts(products, userComment);
        break;
    }
  };

  const handleUnlockProductsAttributesClick = (products) => {
    const fields = (
      !isEmpty(pimProps.globalFields[pimProps.filterTarget])
      || !isEmpty(pimProps.savedFilterFields[pimProps.filterTarget])
    )
      ? mergeFields([
        ...pimProps.globalFields[pimProps.filterTarget],
        ...pimProps.savedFilterFields[pimProps.filterTarget],
      ])
      : [];

    const productTypes = [];
    products.forEach((product) => {
      productTypes[product.id] = product.type;
    });

    props.bulkDeleteProductLockedAttributes({
      productIds: products.map(p => p.id),
      fields,
      productTypes,
    });
  };

  const handleSubmit = (products, newComment, action) => {
    if (action && action === actions.unlockAttributes) {
      handleUnlockProductsAttributesClick(products);
    } else {
      handleMultipleProductsUpdate(products, newComment);
    }
  };

  const handleSubmitAlert = () => {
    const productsToUpdate = pimProps.selectedProducts
      .filter(selected => selected.type === 'parent');

    props.setSelectedProducts(productsToUpdate);
    const updatedProducts = mapActionData(productsToUpdate);

    if (!isEmpty(updatedProducts)) {
      const productsToSave = updatedProducts.map(up => ({
        ...up,
      }));
      handleSubmit(productsToSave, `${systemComment}@${comment}`);
    }
    setShowAlert(false);
  };

  const handleSubmitActionBar = () => {
    const isValid = validateActionBar();
    if (!isValid) return;

    if (!isParentProducts() && parentActions.find(a => a === pimProps.selectedAction)) {
      setAlertSettings({
        showSuccess: true,
        content: 'pim.list.actionBar.saveModal.content.onlyParentUpdate',
      });
      setShowAlert(true);
    } else {
      const updatedProducts = mapActionData(pimProps.selectedProducts);
      if (handleUnlockAttributesSubmit(updatedProducts)) {
        handleSubmit(updatedProducts, `${systemComment}@${comment}`, actions.unlockAttributes);
        return;
      }
      if (!isEmpty(updatedProducts)) {
        const productsToSave = updatedProducts.map(up => ({
          ...up,
        }));
        handleSubmit(productsToSave, `${systemComment}@${comment}`);
      }

      if (isEmpty(updatedProducts)) {
        handleNotificationWarning(
          <IntlMessages id="pim.table.noDataToUpdate.title" />,
        );
      }
    }
  };

  const handleCancelActionBar = () => {
    setComment('');
    setSystemComment('');
    setAttribute('');
    setAttributeValue('');
    props.setSelectedAction('');
  };

  const actionOptionsList = getActionOptions();

  return (
    <div className="actions-bar flex items-center justify-between">
      <ActionBarForm
        formProps={{
          selectedAction: pimProps.selectedAction,
          attributeErrors,
          actionOptionsList,
          attribute,
          availableAttributeList,
          errors,
          attributeValue,
          systemComment,
          comment,
          loading: productAttributeProps.productAttributesFetching,
          pimModeAttributes,
          foundOptions: productAttributeProps.foundOptions,
          searchAttributeOptionsStart: productAttributeProps.searchAttributeOptionsStart,
          fetchingAttributeOptions: productAttributeProps.fetchingAttributeOptions,
          commentMessagesOptions: commentMessages.map(cm => ({
            ...cm, label: cm.title, value: cm.title,
          })),
        }}
        actions={{
          setSelectedAction: props.setSelectedAction,
          clearFoundAttributeOptions: props.clearFoundAttributeOptions,
          searchAttributeOptions: props.searchAttributeOptions,
          handleChangeAttribute,
          setAttributeValue,
          setSystemComment,
          setComment,
        }}
      />

      <ActionBarControls
        controlsProps={{
          selectedAction: pimProps.selectedAction,
          selectedProducts: pimProps.selectedProducts,
        }}
        actions={{
          handleCancelActionBar,
          handleSubmitActionBar,
        }}
      />

      <SweetAlert
        warning
        btnSize="sm"
        showCancel
        show={showAlert}
        confirmBtnText={alertSettings.showSuccess && <IntlMessages id="preview.list.actionBar.saveModal.confirmButton" />}
        confirmBtnBsStyle="success"
        cancelBtnText={<IntlMessages id="preview.list.actionBar.saveModal.cancelButton" />}
        cancelBtnBsStyle="danger"
        title={<IntlMessages id="preview.list.actionBar.saveModal.title" />}
        onConfirm={handleSubmitAlert}
        onCancel={toggleAlert}
      >
        {alertSettings.content && <IntlMessages id={alertSettings.content} />}
      </SweetAlert>
    </div>
  );
};

ListActionBar.propTypes = {

  pimProps: shape().isRequired,
  productAttributeProps: shape().isRequired,
  activeUser: shape().isRequired,
  pimModeAttributes: arrayOf(shape()),
  commentMessages: arrayOf(shape()).isRequired,
  setSelectedAction: func.isRequired,
  searchAttributeOptions: func.isRequired,
  clearFoundAttributeOptions: func.isRequired,
  fetchAttributeOptions: func.isRequired,
  setSelectedProducts: func.isRequired,
  bulkDeleteProductLockedAttributes: func.isRequired,
  multipleUpdateUserActivityLogs: func.isRequired,
  updateMultipleProducts: func.isRequired,
  setContainerStyles: func.isRequired,
};

ListActionBar.defaultProps = {
  pimModeAttributes: [],
};

const mapStateToProps = state => ({
  pimModeAttributes: state.system.modes.pim.parent,
  activeUser: state.session.item,
  commentMessages: state.commentMessage.list,

  pimProps: pimSelector(state.pim),
  productAttributeProps: productAttributeSelector(state.productAttribute),
});

const actionCreators = {
  searchAttributeOptions,
  clearFoundAttributeOptions,
  setSelectedAction,
  setContainerStyles,
  fetchAttributeOptions,
  setSelectedProducts,
  bulkDeleteProductLockedAttributes,
  multipleUpdateUserActivityLogs,
  updateMultipleProducts,
};

export default connect(mapStateToProps, actionCreators)(ListActionBar);
