import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';
import { defaultPagination } from '../util/defaultData';

import {
  PREVIEW_NAVIGATION_SET_CATEGORY_FIELDS,
  PREVIEW_NAVIGATION_SET_PRODUCT_CATEGORIES_ATTRIBUTES,
  PREVIEW_NAVIGATION_SET_ATTRIBUTES_MATRIX_ATTRIBUTES,
  PREVIEW_NAVIGATION_SET_FILTERS,
  PREVIEW_NAVIGATION_SET_FILTERS_ERRORS,
  PREVIEW_NAVIGATION_SET_PRODUCTS_NIN_IDS,
  PREVIEW_NAVIGATION_SET_PRODUCTS_IN_IDS,
  PREVIEW_NAVIGATION_SET_SELECTED_PRODUCT_LIST,
  PREVIEW_NAVIGATION_UPDATE_LOADED_PRODUCTS_IN,
  PREVIEW_NAVIGATION_UPDATE_LOADED_PRODUCTS_NIN,
  PREVIEW_NAVIGATION_COMPLETE_PRODUCTS_LIST_START,
  PREVIEW_NAVIGATION_COMPLETE_PRODUCTS_LIST_FINISHED,
  PREVIEW_NAVIGATION_SET_IS_APPLY_FILTER_ACTIVE,
  PREVIEW_NAVIGATION_SET_IS_SWITCH_ON,
  PREVIEW_NAVIGATION_SET_PRODUCT_FILTER,
  PREVIEW_NAVIGATION_FETCH_PRODUCT_LIST_START,
  PREVIEW_NAVIGATION_FETCH_PRODUCT_LIST_FINISHED,
  PREVIEW_NAVIGATION_SET_PRODUCT_COMPOUND_QUERY,
  PREVIEW_NAVIGATION_SET_LEFT_SIDE_PAGINATION,
  PREVIEW_NAVIGATION_FETCH_PRODUCTS_BY_IDS_START,
  PREVIEW_NAVIGATION_FETCH_PRODUCTS_BY_IDS_FINISHED,
  PREVIEW_NAVIGATION_SET_SHOW_CATEGORIES_DIALOG,
  PREVIEW_NAVIGATION_SET_MAPPED_CATEGORIES,
  PREVIEW_NAVIGATION_SET_SELECTED_CATEGORIES,
  PREVIEW_NAVIGATION_CLEAR_CATEGORY_PRODUCT_STATE,
  PREVIEW_NAVIGATION_SET_RANKING_RULE_CODE,
  PREVIEW_NAVIGATION_SET_SORTING_ERRORS,
  PREVIEW_NAVIGATION_SET_SORT_RULES,
  PREVIEW_NAVIGATION_SET_FACETS,
  PREVIEW_NAVIGATION_SET_IS_DATA_CHANGED,
  PREVIEW_NAVIGATION_SET_IS_CATEGORY_SAVING,
  PREVIEW_NAVIGATION_SET_ERRORS,
  PREVIEW_NAVIGATION_SET_SELECTED_TREE_ITEMS,
  PREVIEW_NAVIGATION_SET_PHYSICAL_CATEGORIES_IDS,
  PREVIEW_NAVIGATION_CLEAR_NAVIGATION_FILTER_STATE,
  PREVIEW_NAVIGATION_SET_EXISTED_SEARCH_PHRASE,
  PREVIEW_NAVIGATION_CLEAR_NAVIGATION_FACET_STATE,
} from '../actions/types';

const initialState = {
  originalItem: {},
  drawerState: {
    isOpen: false,
  },
  filter: {
    filters: [],
    filtersErrors: [],
    productFilter: [],
    isSwitchOn: false,
    productCategoriesAttributes: [{
      code: 'id',
      frontendInputType: 'selectProductsButton',
      id: 'attr_iHeYyv1MRdm1mG',
      isFilterable: true,
      isRequired: false,
      isSearchable: false,
      isSortable: false,
      isVariation: false,
      label: 'Products',
      value: 'id',
    }],
    attributesMatrixAttributes: [],
    product: {
      list: [],
      selectedProductList: { in: [], nin: [] },
      productsInIds: [],
      productsNotInIds: [],
      productsByIdsForIn: [],
      productsByIdsForNin: [],
      compoundSearch: { value: '' },
      leftSidePagination: defaultPagination,
      isProductDialogApplyFilterActive: false,
      categoryFetchProductListStart: false,
      categoryFetchProductListFinished: false,
      fetchProductsByIdsStarted: false,
      fetchProductsByIdsFinished: false,
    },
    category: {
      selectedTreeItems: [],
      showSelectCategoriesDialog: false,
      mappedCategories: [],
      selectedCategories: [],
      selectedDialogCategories: [],
      physicalCategorySearchQuery: '',
      showCategoriesDialog: false,
      physicalCategoriesIds: [],
    },
  },
  sort: {
    rankingRuleCode: null,
    sortingsErrors: [],
    sortRules: [],
  },
  facet: {
    facets: [],
  },
  isDataChanged: false,
  isNavigationSaving: false,
  errors: {},
  existedSearchPhrase: '',
};
const previewNavigationRestTypes = restTypes('preview-navigation');
const {
  fetchOneStart,
  fetchOneFinished,
  updateStart,
  updateFinished,
  clearFromStateFinished,
} = previewNavigationRestTypes;

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };
    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case updateStart:
      return {
        ...state,
        ...restStates.updateStart,
        ...action.payload,
      };
    case updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };
    case clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case PREVIEW_NAVIGATION_SET_CATEGORY_FIELDS:
      return {
        ...state,
        ...action.payload,
      };

    case PREVIEW_NAVIGATION_SET_IS_DATA_CHANGED:
    case PREVIEW_NAVIGATION_SET_IS_CATEGORY_SAVING:
    case PREVIEW_NAVIGATION_SET_ERRORS:
    case PREVIEW_NAVIGATION_SET_EXISTED_SEARCH_PHRASE:
      return {
        ...state,
        ...action.payload,
      };

    case PREVIEW_NAVIGATION_SET_PRODUCT_CATEGORIES_ATTRIBUTES:
    case PREVIEW_NAVIGATION_SET_ATTRIBUTES_MATRIX_ATTRIBUTES:
    case PREVIEW_NAVIGATION_SET_FILTERS:
    case PREVIEW_NAVIGATION_SET_FILTERS_ERRORS:
    case PREVIEW_NAVIGATION_SET_IS_SWITCH_ON:
    case PREVIEW_NAVIGATION_SET_PRODUCT_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };

    case PREVIEW_NAVIGATION_SET_PRODUCTS_NIN_IDS:
    case PREVIEW_NAVIGATION_SET_PRODUCTS_IN_IDS:
    case PREVIEW_NAVIGATION_SET_SELECTED_PRODUCT_LIST:
    case PREVIEW_NAVIGATION_UPDATE_LOADED_PRODUCTS_IN:
    case PREVIEW_NAVIGATION_UPDATE_LOADED_PRODUCTS_NIN:
    case PREVIEW_NAVIGATION_COMPLETE_PRODUCTS_LIST_START:
    case PREVIEW_NAVIGATION_COMPLETE_PRODUCTS_LIST_FINISHED:
    case PREVIEW_NAVIGATION_SET_IS_APPLY_FILTER_ACTIVE:
    case PREVIEW_NAVIGATION_FETCH_PRODUCT_LIST_START:
    case PREVIEW_NAVIGATION_FETCH_PRODUCT_LIST_FINISHED:
    case PREVIEW_NAVIGATION_SET_PRODUCT_COMPOUND_QUERY:
    case PREVIEW_NAVIGATION_SET_LEFT_SIDE_PAGINATION:
    case PREVIEW_NAVIGATION_FETCH_PRODUCTS_BY_IDS_START:
    case PREVIEW_NAVIGATION_FETCH_PRODUCTS_BY_IDS_FINISHED:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            ...action.payload,
          },
        },
      };

    case PREVIEW_NAVIGATION_SET_SHOW_CATEGORIES_DIALOG:
    case PREVIEW_NAVIGATION_SET_MAPPED_CATEGORIES:
    case PREVIEW_NAVIGATION_SET_SELECTED_CATEGORIES:
    case PREVIEW_NAVIGATION_SET_SELECTED_TREE_ITEMS:
    case PREVIEW_NAVIGATION_SET_PHYSICAL_CATEGORIES_IDS:
      return {
        ...state,
        filter: {
          ...state.filter,
          category: {
            ...state.filter.category,
            ...action.payload,
          },
        },
      };

    case PREVIEW_NAVIGATION_SET_RANKING_RULE_CODE:
    case PREVIEW_NAVIGATION_SET_SORTING_ERRORS:
    case PREVIEW_NAVIGATION_SET_SORT_RULES:
      return {
        ...state,
        sort: {
          ...state.sort,
          ...action.payload,
        },
      };

    case PREVIEW_NAVIGATION_CLEAR_CATEGORY_PRODUCT_STATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...initialState.filter.product,
          },
        },
      };

    case PREVIEW_NAVIGATION_CLEAR_NAVIGATION_FILTER_STATE:
      return {
        ...state,
        filter: {
          ...initialState.filter,
          attributesMatrixAttributes: state.filter.attributesMatrixAttributes,
        },
      };

    case PREVIEW_NAVIGATION_CLEAR_NAVIGATION_FACET_STATE:
      return {
        ...state,
        facet: initialState.facet,
      };

    case PREVIEW_NAVIGATION_SET_FACETS:
      return {
        ...state,
        facet: {
          ...state.facet,
          ...action.payload,
        },
      };

    default: return state;
  }
};
