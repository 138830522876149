/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment, useState, useEffect,
} from 'react';
import {
  shape, func, number, string, arrayOf, bool, oneOfType, object, array,
} from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty, uniqBy, cloneDeep } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import {
  setSelectedAttributeOption,
  setSelectedAttributesOptions,
  searchAttributeOptions,
  clearFoundAttributeOptions,
} from '../../../../actions/productAttribute';
import Field from '../../../../components/Field';
import operators from '../../../../util/constants/operators';
import IntlMessages from '../../../../util/IntlMessages';

const GroupItem = (props) => {
  const [attribute, setAttribute] = useState(null);
  const [attributes, setAttributes] = useState(props.attributes);
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);
  const elementSet = new Set(['select', 'dropdown']);
  const multipleOperatoSet = new Set(['in', 'nin']);
  const elementTypeSet = new Set(['text', 'number']);
  const exludedFieldsSet = new Set(['locked_attributes']);
  const enumTextFieldSet = new Set(['product_id', 'pub_aopi']);
  const operatorsWithoutValue = new Set(['exists', 'not_exists']);
  let timeout = null;

  useEffect(() => {
    setAttributes([...props.attributes]);
  }, [props.attributes]);

  const {
    groupItem, onChange, itemIndex, onDeleteItemClick,
    fetchingAttributeOptions,
  } = props;

  let isMultiple = false;
  let isMultipleValue = groupItem.fieldOption;

  if (multipleOperatoSet.has(groupItem.operator)) {
    isMultiple = true;

    if (attribute && elementSet.has(attribute.elementType)) {
      if (!Array.isArray(groupItem.fieldOption) && groupItem.fieldOption) {
        isMultipleValue = groupItem.fieldOption.split(',');
      }

      if (isEmpty(groupItem.fieldOption)) {
        isMultipleValue = [];
      }
    }
  }

  const handleAutocompleteChange = (query) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      const name = attribute.value;
      props.searchAttributeOptions(name, query);
    }, 100);
  };

  const getAttributeOptions = (currentAtribute, globalAttributes) => {
    const globalAttribute = globalAttributes.find(a => a.code === currentAtribute.code);

    if (globalAttribute && !isEmpty(globalAttribute.options) && currentAtribute.elementType === 'select') {
      return globalAttribute.options;
    }

    const autocompleteOptions = uniqBy(
      [...props.initialOptions, ...props.foundOptions, ...props.selectedAttributesOptions],
      'optionCode',
    );
    const filteredOptions = !isEmpty(autocompleteOptions)
      ? autocompleteOptions.filter(ao => ao.attributeCode === currentAtribute.value)
      : [];

    return !isEmpty(filteredOptions)
      ? filteredOptions.map((o) => {
        const optionLabel = o.label && o.label.en ? o.label.en : o.label;
        return ({
          label: optionLabel,
          value: o.optionCode,
        });
      })
      : [];
  };

  const handleDeleteClick = () => onDeleteItemClick(itemIndex);

  if (
    typeof isMultipleValue !== 'string'
    && groupItem.operator !== 'in'
    && groupItem.operator !== 'nin'
  ) {
    groupItem.fieldOption = '';
    isMultipleValue = '';
  }

  const handleGetOptions = async (value) => {
    let data = [];
    const firebaseAttribute = attributes
      ? attributes.find(a => a.value === value) : null;
    let result = firebaseAttribute;

    if (!firebaseAttribute.isCustomAttribute && !isOptionsLoading) {
      setIsOptionsLoading(true);
      data = await props.fetchAttributeOptions(value);
      setIsOptionsLoading(false);
      const currentAttribute = data.find(attr => attr.code === value);
      result = {
        ...firebaseAttribute,
        options: currentAttribute.options,
      };
    }

    if (result) setAttribute(result);
  };

  useEffect(() => {
    if (!attribute && groupItem && groupItem.field) {
      handleGetOptions(groupItem.field);
    }
  }, [groupItem]);

  const handleAttributeChange = (e) => {
    const newFilter = {
      ...cloneDeep(groupItem),
      fieldOption: '',
      operator: 'eq',
      field: e.target.value,
    };

    if ('errors' in newFilter) delete newFilter.errors;
    handleGetOptions(e.target.value);
    onChange(newFilter, itemIndex);
  };

  const handleOperatorChange = (e) => {
    const newFilter = {
      ...cloneDeep(groupItem),
      [e.target.name]: e.target.value,
    };

    if ('errors' in newFilter) delete newFilter.errors;

    if (operatorsWithoutValue.has(e.target.value)) {
      delete newFilter.fieldOption;
    }

    if (multipleOperatoSet.has(newFilter.operator) && !Array.isArray(newFilter.fieldOption)) {
      newFilter.fieldOption = [newFilter.fieldOption];
    }

    if (!multipleOperatoSet.has(newFilter.operator) && Array.isArray(newFilter.fieldOption)) {
      newFilter.fieldOption = '';
    }

    onChange(newFilter, itemIndex);
  };

  const handleAttributeValueChange = (e) => {
    const sameGroups = props.groups.filter(g => g.field === groupItem.field);
    const rewrite = sameGroups.length <= 1;

    const newFilter = {
      ...cloneDeep(groupItem),
      [e.target.name]: e.target.value,
    };

    if ('errors' in newFilter) delete newFilter.errors;

    if (
      multipleOperatoSet.has(newFilter.operator)
      && e.target.element === 'autocompleteMultiple'
    ) {
      const value = !isEmpty(e.target.value) ? e.target.value.map(v => v.value || v) : [];
      newFilter.fieldOption = value;

      if (!isEmpty(value)) {
        const allOptions = [...props.foundOptions, ...props.selectedAttributesOptions];
        const filteredOptions = allOptions.filter(o => e.target.value.includes(o.optionCode));
        props.setSelectedAttributesOptions(filteredOptions, rewrite);
      }
    }

    if (
      e.target.element === 'autocompleteSingle'
      || elementTypeSet.has(e.target.type)
    ) {
      newFilter.fieldOption = e.target.value;

      if (e.target.value) {
        const targetOption = props.foundOptions
          .find(o => o.optionCode === e.target.value);

        if (targetOption) {
          props.setSelectedAttributeOption(targetOption, rewrite);
        }
      }
    }

    onChange(newFilter, itemIndex);
  };

  let availableOperators = [];

  if (attributes && !isEmpty(attributes) && attribute && attribute.elementType) {
    const attributeCopy = {
      ...attribute,
      extraType: enumTextFieldSet.has(attribute.key)
        ? 'enum'
        : attribute.elementType,
    };
    availableOperators = operators.filter(
      o => o.availableFor.includes(attributeCopy.extraType),
    ) || [];

    if (attribute.operators) {
      const extraOperators = attribute.operators.map(a => operators.find(o => o.value === a));
      availableOperators.push(extraOperators);
    }
  }

  const mapOptions = (attr, options) => {
    if (!isEmpty(options)) {
      const payload = options.map(o => ({
        value: attr.isCustomAttribute
          ? (o.code || o.value)
          : (o.label.en || o.title),
        label: (o.label && typeof o.label === 'string'
          ? o.label : o.label.en) || o.title,
      }));
      return payload;
    }
    return [];
  };

  const mappedOptions = attribute ? ([...mapOptions(attribute, attribute.options)] || []) : [];
  const showExtraField = attribute
    || (!isEmpty(groupItem) && attributes.some(attr => attr.value === groupItem.field));

  const currentAttribute = attributes.find(attr => attr.value === groupItem.field);

  const showField = !isEmpty(groupItem) && !operatorsWithoutValue.has(groupItem.operator);

  const getElementType = (elementName) => {
    if (elementSet.has(elementName) && !isMultiple) return 'autocomplete';
    if (elementSet.has(elementName) && isMultiple) return 'autocomplete-multiple';
    return elementName;
  };

  const getIntlErros = name => groupItem.errors && groupItem.errors[name]
    && groupItem.errors[name].map(e => <IntlMessages key={e.message} id={e.message} />);

  const noIntlErros = (props.filterTarget === 'mapper'
    && props.selectedMapperCategories && props.selectedMapperCategories.length > 0)
    ||
    (props.filterTarget === 'repricer' && props.selectedTree);

  const getFieldElement = () => {
    const condition = !attribute
      || (currentAttribute && attribute && attribute.code !== currentAttribute.code);

    const elementName = condition
      ? currentAttribute.elementType
      : attribute.elementType;

    const fieldValue = elementSet.has(elementName)
      ? mappedOptions.find(mo => mo.value === groupItem.fieldOption) || ''
      : groupItem.fieldOption;

    const multipleClass = isMultiple ? 'clear-abs' : '';

    const errorClass = !noIntlErros && getIntlErros('fieldOption') ? 'has-error errors-tooltip' : '';
    const fieldOptions = mappedOptions || currentAttribute.options || [];
    const getMultipleValue = () => (typeof isMultipleValue === 'string'
      ? isMultipleValue
      : isMultipleValue.map(imo => fieldOptions.find(mo => mo.value === imo)));

    const getCurrentAttrValue = () => (currentAttribute.options
      ? currentAttribute.options.find(mo => mo.value === groupItem.fieldOption)
      : mappedOptions.find(mo => mo.value === groupItem.fieldOption));

    const value = isMultiple
      ? getMultipleValue()
      : fieldValue || (
        getCurrentAttrValue()
      );

    const attributeOptions = getAttributeOptions(attribute, props.attributes);
    const autocompleteValue = !isEmpty(attributeOptions)
      ? attributeOptions.find(fo => fo.value === groupItem.fieldOption)
      : '';

    const menuPlacement = (
      document.location.href.indexOf('cams') !== -1 ||
      document.location.href.indexOf('errors') !== -1 ||
      document.location.href.indexOf('merchant-status') !== -1
    )
      ? 'bottom'
      : 'top';

    switch (getElementType(elementName)) {
      case 'autocomplete':
        return (
          <Field
            type={attribute.elementType}
            element={
              attribute.elementType.startsWith('dropdown') || attribute.elementType === 'select'
                ? 'autocomplete'
                : attribute.elementType
            }
            onChange={handleAttributeValueChange}
            options={attributeOptions}
            value={autocompleteValue}
            loadOptions={props.searchAttributeOptionsStart}
            handleSearchOptions={handleAutocompleteChange}
            containerClassName={`autocomplete-xs custom-group ${props.selectClassName} ${errorClass}`}
            wrapperClassName="action-bar-text"
            hideStaticLabel
            searchable
            menuPlacement={menuPlacement}
            emptyPlaceholder="Attribute"
            name="Attribute value"
            key={`fieldOption-${itemIndex}-${JSON.stringify(fieldOptions)}`}
            label="Value"
            inputProps={{
              name: groupItem.field,
            }}
            loading={props.fetchingAttributeOptions}
            disabled={props.fetchingAttributeOptions}
            onBlur={props.clearFoundAttributeOptions}
            loaderClassName="background-gray"
          />
        );
      case 'autocomplete-multiple':
        return (
          <Field
            type={attribute ? attribute.elementType : ''}
            element="autocomplete-multiple"
            onChange={handleAttributeValueChange}
            loadOptions={props.searchAttributeOptionsStart}
            onBlur={props.clearFoundAttributeOptions}
            multiple={isMultiple}
            options={attributeOptions}
            value={groupItem.fieldOption}
            handleSearchOptions={handleAutocompleteChange}
            containerClassName={`autocomplete-xs custom-group ${props.selectClassName} ${errorClass}`}
            name={groupItem.field}
            fieldName="fieldOption"
            key={`fieldOption-${itemIndex}-${JSON.stringify(fieldOptions)}`}
            label="Value"
            disabled={props.fetchingAttributeOptions}
          />
        );
      case 'datePicker':
        return (
          <div className="field-option-wrapper mt-5">
            <Field
              element={getElementType(elementName)}
              callback={{
                type: 'object',
                format: 'YYYY-MM-DD HH:mm:ss',
              }}
              type={attribute ? attribute.elementType : ''}
              onChange={handleAttributeValueChange}
              className={`select-xs select-btn date-picker-xs custom-group ${props.selectClassName} ${errorClass}`}
              value={groupItem.fieldOption}
              options={isEmpty(fieldOptions) ? [...mappedOptions] : fieldOptions}
              maxDate={moment()}
              name="fieldOption"
              key={`fieldOption-${itemIndex}-${JSON.stringify(fieldOptions)}`}
              errors={groupItem.errors}
              helperText={!noIntlErros && getIntlErros('fieldOption')}
              disabled={fetchingAttributeOptions}
            />
          </div>
        );

      default:
        return (
          <div className="field-option-wrapper mt-5">
            <Field
              element={getElementType(elementName)}
              type={attribute ? attribute.elementType : ''}
              onChange={handleAttributeValueChange}
              containerClassName={`autocomplete-xs custom-group ${props.selectClassName} ${errorClass}`}
              value={value}
              multiple={isMultiple}
              options={isEmpty(fieldOptions) ? [...mappedOptions] : fieldOptions}
              name={groupItem.field}
              fieldName="fieldOption"
              key={`fieldOption-${itemIndex}-${JSON.stringify(fieldOptions)}`}
              label="Value"
              className={`select-xs select-btn ${multipleClass} ${errorClass}`}
              limitsToShow={100}
              errors={groupItem.errors}
              helperText={!noIntlErros && getIntlErros('fieldOption')}
              disabled={fetchingAttributeOptions}
              searchable
              valueType="arrayOfObject"
              inputProps={{
                name: groupItem.field,
              }}
            />
          </div>
        );
    }
  };

  if (
    document.location.href.indexOf('cams') !== -1 ||
    document.location.href.indexOf('errors') !== -1 ||
    document.location.href.indexOf('merchant-status') !== -1
  ) {
    availableOperators = [
      {
        label: 'Equal',
        value: 'eq',
        availableFor: [
          'select',
          'text',
        ]
      },
      {
        label: 'Not Equal',
        value: 'neq',
        availableFor: [
          'select',
          'text',
        ]
      },
    ]
  }

  return (
    <Fragment>
      <div className="flex autocomplete-inline flex-grid-50">
        <Field
          element="autocomplete"
          onChange={handleAttributeChange}
          containerClassName={`autocomplete-xs ${props.selectClassName} 
          ${!noIntlErros && getIntlErros('field') ? 'has-error errors-tooltip' : ''}`}
          value={attributes.find(attr => attr.value === groupItem.field)}
          options={attributes.map(attr => ({ value: attr.value, label: attr.label }))}
          key={`field-${itemIndex}-${JSON.stringify(attributes)}`}
          name="field"
          label="Field"
          helperText={!noIntlErros && getIntlErros('field')}
          className="select-xs select-btn"
          limitsToShow={100}
          searchable
          loading={props.categoriesAttributesFetching || fetchingAttributeOptions}
          inputProps={{
            name: 'field',
          }}
        />
        {attribute && (
          <Field
            element="autocomplete"
            onChange={handleOperatorChange}
            containerClassName={`autocomplete-xs rel-right ${props.selectClassName} 
            ${!noIntlErros && getIntlErros('operator') ? 'has-error errors-tooltip tooltip-right' : ''}`}
            value={availableOperators.find(ao => ao.value === groupItem.operator) || ''}
            options={[...availableOperators.flat()]}
            name="operator"
            key={`operator-${itemIndex}-${JSON.stringify(availableOperators)}`}
            label="Operator"
            helperText={!noIntlErros && getIntlErros('operator')}
            className="select-xs select-btn"
            inputProps={{
              name: 'operator',
            }}
          />
        )}
      </div>
      {
        showExtraField && attribute && showField
        && !exludedFieldsSet.has(attribute.code) && getFieldElement()
      }
      <IconButton
        aria-label="Delete"
        className="btn-mui-xs text-danger btn-top-right"
        onClick={handleDeleteClick}
      >
        <i className="ti-close" />
      </IconButton>
    </Fragment>
  );
};

GroupItem.propTypes = {
  groupItem: shape({
    field: string,
    operator: string,
    fieldOption: oneOfType([string, array]),
    errors: shape({
      message: string,
    }),
  }).isRequired,
  itemIndex: number.isRequired,
  onChange: func.isRequired,
  onDeleteItemClick: func.isRequired,
  attributes: arrayOf(shape({
    elementType: string,
    label: string,
    value: oneOfType([string, array]),
    options: arrayOf(shape({
      value: oneOfType([string, object]),
      label: oneOfType([string, object]),
    })),
  })).isRequired,
  categoriesAttributesFetching: bool.isRequired,
  fetchAttributeOptions: func.isRequired,
  fetchingAttributeOptions: bool,
  selectClassName: string.isRequired,
  searchAttributeOptionsStart: bool.isRequired,
  searchAttributeOptions: func.isRequired,
  foundOptions: arrayOf(shape()).isRequired,
  initialOptions: arrayOf(shape()).isRequired,
  selectedAttributesOptions: arrayOf(shape()).isRequired,
  setSelectedAttributeOption: func.isRequired,
  setSelectedAttributesOptions: func.isRequired,
  clearFoundAttributeOptions: func,
  groups: arrayOf(shape()),
};

GroupItem.defaultProps = {
  fetchingAttributeOptions: false,
  clearFoundAttributeOptions: null,
  groups: [],
};

const mapStateToProps = state => ({
  foundOptions: state.productAttribute.foundOptions,
  initialOptions: state.productAttribute.initialOptions,
  selectedAttributesOptions: state.productAttribute.selectedAttributesOptions,
  searchAttributeOptionsStart: state.productAttribute.searchAttributeOptionsStart,
});

const actionCreators = {
  searchAttributeOptions,
  setSelectedAttributeOption,
  setSelectedAttributesOptions,
  clearFoundAttributeOptions,
};

export default connect(mapStateToProps, actionCreators)(GroupItem);
