/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const usePreviewFilterAttributes = ({
  fetchPreviewFilterAttributes,
}) => {
  useEffect(() => {
    fetchPreviewFilterAttributes();
  }, []);
};

export default usePreviewFilterAttributes;
