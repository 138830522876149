import React, { Component } from 'react';

import Home from '../index';

class PreviewPageHOC extends Component {
  constructor(props) {
    super(props); 

    this.state = {
      mounted: false,
    };
  }
  static propTypes = {};
  static defaultProps = {};

  componentDidMount () {
    this.setState({
      mounted: true,
    });
  }

  state = {};

  render() {
    return this.state.mounted ? <Home /> : null;
  }
}

export default PreviewPageHOC;
