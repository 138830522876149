/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, memo } from 'react';
import {
  func, shape, bool,
} from 'prop-types';
import Avatar from '@material-ui/core/Avatar';

const AvatarComponent = memo((props) => {
  const {
    influencer, onChange, getAvatarFromStorage,
    storage, avatarData, disabled,
  } = props;

  useEffect(() => {
    if (influencer.id && influencer.photoUrl) {
      getAvatarFromStorage(influencer.id, influencer.photoUrl);
    }
  }, [influencer.photoUrl]);

  return (
    <div className="relative avatar-container">
      <Avatar
        alt={influencer.name}
        src={avatarData.url || storage.avatarUrl}
        className="avatar"
        disabled={disabled}
      />
      <label className="avatar-upload" htmlFor="upload-avatar">
        <input
          onChange={onChange}
          type="file"
          id="upload-avatar"
          style={{ display: 'none' }}
          accept="image/*"
          disabled={disabled}
        />
      </label>
    </div>
  );
});

AvatarComponent.propTypes = {
  influencer: shape().isRequired,
  storage: shape().isRequired,
  avatarData: shape().isRequired,
  onChange: func.isRequired,
  getAvatarFromStorage: func.isRequired,
  disabled: bool.isRequired,
};

export default AvatarComponent;
