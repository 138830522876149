import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  SET_PREVIEW_NAVIGATION_VIRTUAL_FACET_ID_TO_DELETE,
} from '../actions/types';

const initialState = {};
const previewNavigationVirtualFacetRestTypes = restTypes('preview_navigation_virtual_facet');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case previewNavigationVirtualFacetRestTypes.createStart:
      return {
        ...state,
        ...restStates.createStart,
      };
    case previewNavigationVirtualFacetRestTypes.createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        ...action.payload,
      };
    case previewNavigationVirtualFacetRestTypes.fetchStart:
      return {
        ...state,
        ...restStates.fetchStart,
      };
    case previewNavigationVirtualFacetRestTypes.fetchFinished:
      return {
        ...state,
        ...restStates.fetchFinished,
        ...action.payload,
      };
    case previewNavigationVirtualFacetRestTypes.deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
      };
    case previewNavigationVirtualFacetRestTypes.deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };
    case previewNavigationVirtualFacetRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case previewNavigationVirtualFacetRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case previewNavigationVirtualFacetRestTypes.updateStart:
      return {
        ...state,
        ...restStates.updateStart,
      };
    case previewNavigationVirtualFacetRestTypes.updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };
    case previewNavigationVirtualFacetRestTypes.clearStateFinished:
      return {
        ...initialState,
        ...restInitState,
      };
    case SET_PREVIEW_NAVIGATION_VIRTUAL_FACET_ID_TO_DELETE:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
};
