/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  func, arrayOf, number, string, shape, oneOfType,
} from 'prop-types';
import { isEmpty } from 'lodash';
import MatButton from '@material-ui/core/Button';
import { setShowContextMenu } from 'actions/contextMenu';
import {
  fetchRecursiveParentCategory,
  fetchChildCategories,
  fetchRootCategories,
  searchCategories,
  clearFromVirtualCategoryWidgetState,
} from 'actions/virtualCategoryWidget';

import IntlMessages from 'util/IntlMessages';
import appPermissions from 'util/appPermissions';

import Can from 'components/Can';
import CategoryWidget from 'widgets/Category';
import MainSidebar from 'components/MainSidebar';

import { getEditCategoryPath, newCategoryPath } from '../../utils/paths';
import ContextMenuContent from '../ContextMenuContent';
import { exportCategories } from "../../../../actions/pim";

let timeoutDelay = null;
const isReloadingPage = true;

const Sidebar = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    const { collapse } = props.virtualCategoryWidget;
    const { item } = props.virtualCategoryPage;
    if (!isEmpty(item) && props.virtualCategoryPage.fetchedOne) {
      if (item.parentId && !collapse.includes(item.parentId)) {
        props.fetchRecursiveParentCategory(item.parentId);
      }
    }
  }, [props.virtualCategoryPage.fetchedOne]);

  useEffect(() => {
    const {
      item, deleted, fetchedOne, isCategorySaving,
    } = props.virtualCategoryPage;
    if (!isEmpty(item) && (deleted || fetchedOne || isCategorySaving)) {
      const params = { showDisabled: true };
      if (item.parentId) {
        if (isReloadingPage === false) {
          props.fetchChildCategories(item.parentId, params);
        }
      } else {
        props.history.push(getEditCategoryPath([props.virtualCategoryPage.item.id]));
      }
    }
  }, [
    props.virtualCategoryPage.deleted,
    props.virtualCategoryPage.created,
    props.virtualCategoryPage.fetchedOne,
    props.virtualCategoryPage.updated,
  ]);

  const {
    fetchingOne: fetchingOneCategory,
  } = props.virtualCategoryPage;

  const {
    searchResults,
  } = props.virtualCategoryWidget;

  const {
    categoriesAttributesFetching,
  } = props.productAttribute;

  const handleRedirectToSelectedCategory = (categoryId) => {
    props.history.push(getEditCategoryPath(categoryId));
    if (isReloadingPage === true) {
      window.location.reload();
    }
  };

  const handleRedirectToNewCategory = () => {
    props.history.push(newCategoryPath);
  };

  const handleExportCategories = () => {
    props.exportCategories();
  }

  const getItemCategoryId = targetTreeNode => (
    !isEmpty(targetTreeNode.props)
      ? targetTreeNode.props.eventKey
      : targetTreeNode
  );

  const handleOpenNewCategory = () => {
    const { targetTreeNode } = props.contextMenu;
    const categoryId = getItemCategoryId(targetTreeNode);

    props.setShowContextMenu(false);
    props.history.push(getEditCategoryPath(categoryId));
  };

  const handleCreateSubcategory = () => {
    const { targetTreeNode } = props.contextMenu;
    const categoryId = getItemCategoryId(targetTreeNode);

    props.setShowContextMenu(false);
    props.history.push(`${newCategoryPath}?root_category=${categoryId}`);
  };

  const handleDuplicateCategory = () => {
    const { targetTreeNode } = props.contextMenu;
    const categoryId = getItemCategoryId(targetTreeNode);

    props.setShowContextMenu(false);
    props.history.push(`${newCategoryPath}?duplicate_category_id=${categoryId}`);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;

    setSearchValue(value);
    if (value.length > 170) {
    } else {
      clearTimeout(timeoutDelay);
      timeoutDelay = setTimeout(() => {
        if (value === '' || !value) {
          setSearching(false);
          props.clearFromVirtualCategoryWidgetState({
            searchResults: [],
          });
        } else {
          props.searchCategories({
            full_text: {
              fields: ['name.en'],
              value,
            },
            pagination: {
              page: 1,
              limit: 500,
            },
          });
          setSearching(true);
        }
      }, 300);
    }
  };

  return (
    <MainSidebar
      className="sidebar-inner-wrapper-lg"
      search={{
        onChange: handleSearchChange,
        value: searchValue,
      }}
      actions={{
        position: 'fixed',
        content: (
          (
            <>
              <Can
                do={appPermissions.category.permissions.create}
                on={appPermissions.category.name}
              >
                <MatButton
                  variant="contained"
                  color="primary"
                  className="text-white"
                  size="small"
                  style={{ float: 'left', width: '60%' }}
                  onClick={handleRedirectToNewCategory}
                >
                  <IntlMessages id="button.newCategoryFilter" />
                </MatButton>
                <MatButton
                  variant="contained"
                  color="primary"
                  className="text-white green-color"
                  size="small"
                  style={{ float: 'right', width: '39%' }}
                  onClick={handleExportCategories}
                >
                  <IntlMessages id="button.exportCategories" />
                </MatButton>
              </Can>
            </>
          )
        ),
      }}
    >
      <div className="h5 sidebar-title">
        <IntlMessages id="sidebar.categoriesTitle" />
      </div>
      <Can
        do={appPermissions.category.permissions.view}
        on={appPermissions.category.name}
      >
        <CategoryWidget
          disabled={fetchingOneCategory || categoriesAttributesFetching}
          checkboxList={{
            use: searching,
            list: searchResults,
          }}
          onSelectTreeItem={handleRedirectToSelectedCategory}
          contextMenuSettings={{
            use: true,
            content: (
              <ContextMenuContent
                onCreateSubcategory={handleCreateSubcategory}
                onOpenNewCategory={handleOpenNewCategory}
                onDuplicateCategoryClick={handleDuplicateCategory}
              />
            ),
          }}
        />
      </Can>
    </MainSidebar>
  );
};

Sidebar.propTypes = {
  setShowContextMenu: func.isRequired,
  fetchRecursiveParentCategory: func.isRequired,
  fetchChildCategories: func.isRequired,
  fetchRootCategories: func.isRequired,
  clearFromVirtualCategoryWidgetState: func.isRequired,
  searchCategories: func.isRequired,
  history: shape().isRequired,
  virtualCategoryPage: shape().isRequired,
  virtualCategoryWidget: shape().isRequired,
  productAttribute: shape().isRequired,
  contextMenu: shape(),
  searchResults: arrayOf(shape({
    id: oneOfType([number, string]),
    name: shape(),
  })),
  exportCategories: func.isRequired,
};

Sidebar.defaultProps = {
  searchResults: [],
  contextMenu: {},
};

const actionCreators = {
  setShowContextMenu,
  fetchRecursiveParentCategory,
  fetchChildCategories,
  fetchRootCategories,
  clearFromVirtualCategoryWidgetState,
  searchCategories,
  exportCategories,
};

const mapStateToProps = state => ({
  contextMenu: state.contextMenu,
  virtualCategoryWidget: state.virtualCategoryWidget,
  virtualCategoryPage: state.virtualCategoryPage,
  productAttribute: state.productAttribute,
});

export default withRouter(connect(mapStateToProps, actionCreators)(Sidebar));
