export const mapPrefix = (field) => {
  if (field.startsWith('ao_')) {
    return `specifications.${field}`;
  }
  return field;
};

export const mapPrefixToFilters = filters => (filters.map(f => ({
  ...f,
  group: f.group.map(g => ({
    ...g,
    field: mapPrefix(g.field),
  })),
})));

export const mapFrontEndPrefix = (field) => {
  if (field.startsWith('ao_')) {
    return `variation.facets.${field}`;
  }
  return field;
};

export const mapFrontEndPrefixToFilters = filters => (filters.map(f => ({
  ...f,
  group: f.group.map(g => ({
    ...g,
    field: mapFrontEndPrefix(g.field),
  })),
})));

export const mapCampaignPrefix = (field) => {
  if (field === 'merchant_id') {
    return `variation.${field}`;
  }
  return field;
};

export const mapCampaignPrefixToFilters = filters => (filters.map(f => ({
  ...f,
  group: f.group.map(g => ({
    ...g,
    field: mapCampaignPrefix(g.field),
  })),
})));

export default {
  mapPrefix,
  mapPrefixToFilters,
  mapFrontEndPrefix,
  mapFrontEndPrefixToFilters,
};
