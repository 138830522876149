import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  RESET_USER_PASSWORD_START,
  RESET_USER_PASSWORD_FINISHED,
  VERIFY_USER_RESET_CODE_START,
  VERIFY_USER_RESET_CODE_FINISHED,
  CONFIRM_RESET_USER_PASSWORD_START,
  CONFIRM_RESET_USER_PASSWORD_FINISHED,
  CLEAR_AUTH_STATE,
} from '../actions/types';

const initialState = {
  user: localStorage.getItem('user_id'),
  loading: false,
  hasError: false,
  errorMessage: '',
  resetFinished: false,
  resetStarted: false,
  confirmResetStart: false,
  confirmResetFinished: false,
  codeInvalid: null,
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };

    case LOGIN_USER_SUCCESS:
      return { ...state, ...action.payload };

    case LOGIN_USER_FAILURE:
      return { ...state, ...action.payload };

    case LOGOUT_USER:
      return { ...initialState, ...action.payload };

    case SIGNUP_USER:
      return { ...state, loading: true };

    case SIGNUP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case SIGNUP_USER_FAILURE:
      return { ...state, loading: false };

    case RESET_USER_PASSWORD_START:
      return {
        ...state,
        loading: true,
        resetFinished: false,
        resetStarted: true,
      };

    case RESET_USER_PASSWORD_FINISHED:
      return {
        ...state,
        ...action.payload,
        loading: false,
        resetFinished: true,
        resetStarted: false,
      };

    case VERIFY_USER_RESET_CODE_START:
      return {
        ...state,
        ...action.payload,
        verifyCodeStart: true,
        verifyCodeFinished: false,
      };

    case VERIFY_USER_RESET_CODE_FINISHED:
      return {
        ...state,
        ...action.payload,
        verifyCodeStart: false,
        verifyCodeFinished: true,
      };

    case CONFIRM_RESET_USER_PASSWORD_START:
      return {
        ...state,
        ...action.payload,
        confirmResetStart: true,
        confirmResetFinished: false,
      };

    case CONFIRM_RESET_USER_PASSWORD_FINISHED:
      return {
        ...state,
        ...action.payload,
        confirmResetStart: false,
        confirmResetFinished: true,
      };

    case CLEAR_AUTH_STATE:
      return {
        loading: false,
        resetFinished: false,
        resetStarted: false,
        hasError: false,
        errorMessage: '',
        user: null,
      };
    default: return { ...state };
  }
};
