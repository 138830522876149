/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import {
  bool, shape, arrayOf, func,
} from 'prop-types';
import { camelize, decamelize } from 'humps';
import { isEmpty, uniq, uniqBy } from 'lodash';
import { Divider, FormControlLabel, Checkbox } from '@material-ui/core';

import SectionInfoRow from '../../components/SectionInfoRow';
import AttributesFilter from '../../components/AttributesFilter';
import SelectProducts from '../../components/SelectProducts';
import ProductsPagination from '../../../../components/ProductsPagination';
import DealBannerContainer from '../DealBannerContainer';

import {
  mapProductList,
  getFilterWithoutProductFilter,
  mapFrontEndPrefixToFilters,
  getSplitedFilterByQuery,
  getProductCompoundSearchFilter,
} from '../../utils/mappers';

import { defaultPagination } from '../../utils/defaultData';

import {
  dealFiltersSelector,
  dealMappedFiltersSelector,
  dealSelectedProductsFilterSelector,
} from '../../selectors';

import {
  updateDealFilters,
  fetchProductList,
  setDealSectionProductFilter,
  fetchDealSectionProductsByIds,
  setSelectedDealSectionProductList,
  setDealSectionProductsInIds,
  updateDealSectionLoadedProductsIn,
  setSelectedDealSectionProductsFilters,
  completeDealSectionProductList,
  updateInfluencerFields,
} from '../../../../actions/influencer';

import {
  fetchAttributeOptions,
} from '../../../../actions/productAttribute';

import IntlMessages from '../../../../util/IntlMessages';

const NewDealContainer = memo((props) => {
  const [compoundSearch, setCompoundSearch] = useState(null);
  const [leftSidePagination, setLeftSidePagination] = useState(defaultPagination);

  const {
    influencer, productsFilters, fetchingScopeCategoriesByIds,
    categoriesAttributesFetching, fetchingAttributeOptions, disabled,
    scopeCategoriesByIds, mappedFilters, influencerProduct, productList, fetchedOne,
    selectedProductsFilters, handleBannerChange, textFieldsErrors,
  } = props;

  useEffect(() => {
    const productsInFilter = selectedProductsFilters.find(f => f.field === 'id');
    const productsInIds = productsInFilter ? productsInFilter.value : [];

    if (fetchedOne && !isEmpty(productsInIds)) {
      props.updateDealSectionLoadedProductsIn([...productsInIds.map(p => ({ id: p }))]);
      props.setDealSectionProductsInIds(productsInIds);
    }
  }, [fetchedOne]);

  const handleSelectProductsDialogClose = () => {};
  const handleProductDialogApplyFilterChange = () => {};

  const handleSelectProductsDialogOpen = () => {
    const physicalCategoriesIds = scopeCategoriesByIds
      .map(c => c.virtualParams.mappedCategories).flat();
    const productFilters = mapFrontEndPrefixToFilters(getFilterWithoutProductFilter(mappedFilters));
    let newProductFilter = [];

    if (!isEmpty(physicalCategoriesIds)) {
      newProductFilter = [{
        group: [{
          field: 'categories',
          value: physicalCategoriesIds,
          operator: 'in',
        }],
      }];
      props.setDealSectionProductFilter([...newProductFilter]);
    }

    props.fetchProductList({
      viewMode: 'frontendWithParams',
      compoundSearch,
      pagination: leftSidePagination,
      filter: [...newProductFilter.flat(), ...productFilters].flat(),
    });
  };

  const handleSearchSubmit = (query) => {
    const splitedFilter = getSplitedFilterByQuery(query);
    const newCompoundSearch = getProductCompoundSearchFilter(splitedFilter.queryString.join(', '));
    const physicalCategoriesIds = scopeCategoriesByIds
      .map(c => c.virtualParams.mappedCategories).flat();
    let newProductFilter = [];

    setCompoundSearch(newCompoundSearch);
    setLeftSidePagination(defaultPagination);

    const productsGroupFilter = !isEmpty(splitedFilter.productIds)
      ? [{
        group: [{
          field: 'id',
          value: uniq(splitedFilter.productIds),
          operator: 'in',
        }],
      }]
      : [];

    if (!isEmpty(physicalCategoriesIds)) {
      newProductFilter = [{
        group: [{
          field: 'categories',
          value: physicalCategoriesIds,
          operator: 'in',
        }],
      }];
    }

    const newFilters = getFilterWithoutProductFilter(mappedFilters);
    const filtersWithPrefix = mapFrontEndPrefixToFilters(newFilters);

    const productSearchFilter = [
      ...filtersWithPrefix,
      ...influencerProduct.deal.productFilter,
    ];

    props.fetchProductList({
      viewMode: 'frontendWithParams',
      compoundSearch: newCompoundSearch,
      pagination: defaultPagination,
      searchFilter: {},
      filter: [...productSearchFilter, ...newProductFilter, ...productsGroupFilter].flat(),
    });
  };

  const handleSelectedListChange = (selectedProductList, key) => {
    props.setSelectedDealSectionProductList({
      ...influencerProduct.deal.selectedProductList,
      [key]: selectedProductList,
    });

    props.setDealSectionProductsInIds(selectedProductList.map(p => p.id));
    props.updateDealSectionLoadedProductsIn(selectedProductList);
  };

  const handleDialogSelectClick = () => {
    const { selectedProductList } = influencerProduct.deal;
    if (!isEmpty(selectedProductList.in)) {
      const productsInIds = selectedProductList.in.map(p => p.id);
      const newFilters = [{
        id: new Date().toString(),
        field: 'id',
        operator: 'in',
        value: productsInIds,
      }];
      props.setSelectedDealSectionProductsFilters(newFilters);
    }
  };

  const onLeftSidePaginate = ({ selected }) => {
    const updatedPagination = {
      ...leftSidePagination,
      page: selected + 1,
    };

    const newFilters = getFilterWithoutProductFilter(mappedFilters);
    const filtersWithPrefix = mapFrontEndPrefixToFilters(newFilters);

    const productSearchFilter = [
      ...filtersWithPrefix,
      ...influencerProduct.deal.productFilter,
    ];

    setLeftSidePagination(updatedPagination);
    props.fetchProductList({
      viewMode: 'frontendWithParams',
      compoundSearch,
      filter: productSearchFilter,
      pagination: updatedPagination,
      searchFilter: {},
    });
  };

  const getSearchSuffix = () => (
    <FormControlLabel
      className="label-clear checkbox-sm"
      control={(
        <Checkbox
          checked
          disabled
          onChange={handleProductDialogApplyFilterChange}
          color="primary"
        />
      )}
      label={<IntlMessages id="influencer.selectProductsDialog.search.checkbox.applyFilter" />}
    />
  );

  const handleDealNameChange = (e) => {
    const { value, name } = e.target;
    const payload = {
      ...influencer,
      page: {
        ...influencer.page,
        deal: {
          ...influencer.page.deal,
          [name]: value,
        },
      },
    };

    props.updateInfluencerFields(payload);
  };

  const getParseDealLink = () => {
    const parsedDealName = influencer.page.deal.name
      ? decamelize(camelize(influencer.page.deal.name))
      : '';

    return `americanoutlets.com/influencer/${influencer.page.storeName}/${parsedDealName}`;
  };

  const loading = (
    fetchingScopeCategoriesByIds
    || categoriesAttributesFetching
  );

  const listIn = uniqBy([
    ...influencerProduct.deal.productsByIdsForIn,
  ], 'id');

  const mappedListIn = mapProductList(listIn);

  return (
    <>
      <SectionInfoRow
        label="Deal link"
        url={getParseDealLink()}
        title={<IntlMessages id="influencer.deal.title" />}
        name="name"
        inputLabel="Deal Name"
        value={influencer.page.deal.name}
        onChange={handleDealNameChange}
        textFieldsErrors={textFieldsErrors}
        disabled={disabled}
      />
      <Divider className="influencers-row-divider" />
      <DealBannerContainer
        handleBannerChange={handleBannerChange}
        disabled={disabled}
      />
      <Divider className="influencers-row-divider" />
      <div className="section-subtitle">
        <IntlMessages id="influencer.products.filters.subtitle" />
      </div>
      <AttributesFilter
        updateFilters={props.updateDealFilters}
        filters={productsFilters}
        loading={loading}
        fetchAttributeOptions={props.fetchAttributeOptions}
        fetchingAttributeOptions={fetchingAttributeOptions}
        disabled={disabled}
      />
      <Divider className="influencers-row-divider" />
      <SelectProducts
        onOpen={handleSelectProductsDialogOpen}
        onClose={handleSelectProductsDialogClose}
        sourceList={mapProductList(productList) || []}
        listIn={mappedListIn}
        listNin={[]}
        listInIds={influencerProduct.deal.productsInIds || []}
        listNinIds={[]}
        completingCategoryProductsList={
          influencerProduct.deal.completingCategoryProductsList
        }
        fetchProductsByIdsStarted={influencerProduct.deal.fetchProductsByIdsStarted}
        fetchProductsByIds={props.fetchDealSectionProductsByIds}
        onSearchSubmit={handleSearchSubmit}
        onSelectedListChange={handleSelectedListChange}
        onDialogSelectClick={handleDialogSelectClick}
        completeProductList={props.completeDealSectionProductList}
        operator="in"
        maxSelected={50}
        loading={{
          leftList: influencerProduct.influencerFetchProductListStart,
          rightList: influencerProduct.deal.fetchProductsByIdsStarted,
        }}
        leftPagination={(
          <ProductsPagination
            containerClassName="pagination-sm pagination-abs"
            onPaginate={onLeftSidePaginate}
            product={{
              pages: influencerProduct.pages,
              total: influencerProduct.total,
            }}
            pagination={leftSidePagination}
          />
        )}
        search={{
          suffix: getSearchSuffix(),
        }}
        disabled={disabled}
      />
    </>
  );
});

NewDealContainer.propTypes = {
  influencer: shape().isRequired,
  productsFilters: arrayOf(shape()).isRequired,
  scopeCategoriesByIds: arrayOf(shape()).isRequired,
  mappedFilters: arrayOf(shape()).isRequired,
  influencerProduct: shape().isRequired,
  productList: arrayOf(shape()).isRequired,
  selectedProductsFilters: arrayOf(shape()).isRequired,
  textFieldsErrors: shape().isRequired,

  fetchingScopeCategoriesByIds: bool.isRequired,
  categoriesAttributesFetching: bool.isRequired,
  fetchingAttributeOptions: bool.isRequired,
  fetchedOne: bool.isRequired,
  disabled: bool.isRequired,

  updateDealFilters: func.isRequired,
  fetchAttributeOptions: func.isRequired,
  fetchProductList: func.isRequired,
  setDealSectionProductFilter: func.isRequired,
  fetchDealSectionProductsByIds: func.isRequired,
  setSelectedDealSectionProductList: func.isRequired,
  setDealSectionProductsInIds: func.isRequired,
  updateDealSectionLoadedProductsIn: func.isRequired,
  setSelectedDealSectionProductsFilters: func.isRequired,
  completeDealSectionProductList: func.isRequired,
  updateInfluencerFields: func.isRequired,
  handleBannerChange: func.isRequired,
};

const mapStateToProps = state => ({
  influencer: state.influencer.item,
  productsFilters: dealFiltersSelector(state.influencer.item),
  mappedFilters: dealMappedFiltersSelector(state.influencer.item),
  selectedProductsFilters: dealSelectedProductsFilterSelector(state.influencer.item),
  influencerProduct: state.influencer.product,
  productList: state.influencer.product.list,
  fetchedOne: state.influencer.fetchedOne,
  textFieldsErrors: state.influencer.textFieldsErrors,

  fetchingScopeCategoriesByIds: state.scopeCategory.fetchingScopeCategoriesByIds,
  scopeCategoriesByIds: state.scopeCategory.categoriesByIds,

  categoriesAttributesFetching: state.productAttribute.categoriesAttributesFetching,
  fetchingAttributeOptions: state.productAttribute.fetchingAttributeOptions,
});

const actionCreators = {
  updateDealFilters,
  fetchAttributeOptions,
  fetchProductList,
  setDealSectionProductFilter,
  fetchDealSectionProductsByIds,
  setSelectedDealSectionProductList,
  setDealSectionProductsInIds,
  updateDealSectionLoadedProductsIn,
  setSelectedDealSectionProductsFilters,
  completeDealSectionProductList,
  updateInfluencerFields,
};

export default connect(mapStateToProps, actionCreators)(NewDealContainer);
