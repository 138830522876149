import React from 'react';
import { shape } from 'prop-types';
import { isEmpty } from 'lodash';
import { decamelize } from 'humps';
import CollapsibleGrid from 'components/CollapsibleGrid';

const CombinedFacet = (props) => {
  const {
    facet, facetListParams,
  } = props;

  const key = decamelize(facet.key);
  const currentKeyValue = facetListParams.virtualFacetFilter[key];

  const getFacetOptionValue = optn => ({
    label: optn.label.en,
    value: optn.optionCode,
    key: optn.id,
    checked: !isEmpty(currentKeyValue) && currentKeyValue.includes(optn.optionCode),
  });

  const handleCheckboxChange = (value) => {
    const itemAlreadyExist = currentKeyValue && currentKeyValue.includes(value);

    const sameFilterValue = itemAlreadyExist
      ? [...currentKeyValue].filter(v => v !== value)
      : [value];

    const getFilterValue = () => {
      if (currentKeyValue && itemAlreadyExist) return sameFilterValue;
      if (currentKeyValue && !itemAlreadyExist) return [...currentKeyValue, value];
      return [value];
    };

    const newFilterValue = getFilterValue();

    const filterItem = !isEmpty(newFilterValue)
      ? {
        [key]: newFilterValue,
      } : {};

    const existedValues = { ...facetListParams.virtualFacetFilter };

    if (existedValues[key]) {
      delete existedValues[key];
    }

    facetListParams.handleChangeFacetFilter({
      ...existedValues,
      ...filterItem,
    });
  };

  return (
    <div className="checkbox-sm combined-facet">
      <h4 className="facet-title">{facet.facetData.label.en}</h4>
      {
        facet.groups.map((group) => {
          const optionsCodes = group.values.flat();
          const options = optionsCodes.map((oCode) => {
            const optn = facetListParams.initialCombinedFacetsOptions
              .find(o => o.optionCode === oCode);
            return optn;
          }).filter(optn => optn);

          return (
            <div className="combined-facet-group" key={`combined_facet_${group.id}`}>
              <CollapsibleGrid
                title={group.label.en}
                options={options.map(o => getFacetOptionValue(o))}
                onChange={handleCheckboxChange}
              />
            </div>
          );
        })
      }
    </div>
  );
};

CombinedFacet.propTypes = {
  facet: shape().isRequired,
  facetListParams: shape().isRequired,
};

CombinedFacet.defaultProps = {};

export default CombinedFacet;
