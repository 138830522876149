import React from 'react';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { isEmpty } from 'lodash';
import arrayMove from 'array-move';
import Row from './SortByRow';
import removeUselessErrors from './utils/removeUselessErrors';
import { ascDescOptions, rankingRule } from '../../util/constants/constants';

const SortableContainer = sortableContainer(({ children }) => (
  <ul className="drag-list-container drag-list-dragger block">{children}</ul>
));

const DragHandle = sortableHandle(() => <i className="ti-drag" />);

const SortableItem = sortableElement(props => (
  <li className="drag-list-item drag-list-item-reverse border-no">
    <Row {...props} />
    {props.showDragHandle && <DragHandle disabled={props.disabled} />}
  </li>
));

export const SortByBody = (props) => {
  const {
    rows, attrOptions, rankingOptions, rankingRuleCode, loading, disabled,
  } = props;

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedList = arrayMove(rows, oldIndex, newIndex);
    props.onSortEnd(updatedList);
  };

  const onSortSelectChange = (e, row, key, rowIndex) => {
    const { value, name } = e.target;
    const updatedSortings = rows.map((s, index) => {
      if (s.field === row.field && rowIndex === index && value === rankingRule) {
        return {
          ...row,
          [key]: value,
          defaultValue: s.field === rankingRule || value === rankingRule ? '' : value,
        };
      }
      if (rowIndex === index) {
        return {
          ...row,
          [key]: value,
          defaultValue: s.defaultValue.includes('rank_') || name === 'attributeName'
            ? ascDescOptions.asc.value
            : value,
        };
      }
      return s;
    });

    const payload = removeUselessErrors(updatedSortings);

    props.onSortSelectChange({
      isRankingRule: value === rankingRule,
      productSortingAttributes: value === rankingRule
        ? payload.filter(p => p.field === rankingRule)
        : payload,
    });
  };

  const onRankingChange = (e) => {
    props.onRankingChange(e.target.value);
  };

  const onRowRemove = (row, index) => {
    const sortingsErrors = [...props.errors];
    const filteredAttrs = rows.filter((r, i) => i !== index);
    const payload = removeUselessErrors(filteredAttrs);

    if (sortingsErrors) {
      sortingsErrors.splice(index, 1);
    }

    props.onRowRemove({
      upddatedErrors: sortingsErrors,
      isRankingRule: payload.some(p => p.code === rankingRule),
      productSortingAttributes: row.code === rankingRule
        ? payload.filter(p => p.code === rankingRule)
        : payload,
    });
  };

  return (
    !isEmpty(rows) && (
      <SortableContainer lockAxis="y" onSortEnd={onSortEnd} useDragHandle>
        {rows.map((row, index) => (
          <SortableItem
            key={`item-${row.key || row.field}`}
            index={index}
            sortIndex={index}
            rankingRuleCode={rankingRuleCode}
            row={row}
            onSortSelectChange={onSortSelectChange}
            attrOptions={attrOptions}
            onRowRemove={onRowRemove}
            showDragHandle={rows.length > 1}
            rankingOptions={rankingOptions}
            errors={props.errors[index]}
            onRankingChange={onRankingChange}
            loading={loading}
            disabled={disabled}
          />
        ))}
      </SortableContainer>
    )
  );
};

export default SortByBody;
