import { createSelector } from 'reselect';

const previewRootSelector = state => state;

const stateSelector = createSelector(
  previewRootSelector,
  preview => ({
    productsOverride: preview.productsOverride,
    productsImagesOverride: preview.productsImagesOverride,
    list: preview.list,
    selectedEntityName: preview.selectedEntityName,
    selectedSearchFilter: preview.selectedSearchFilter,
    updating: preview.updating,
    updatingMultiple: preview.updatingMultiple,
    isEditMode: preview.isEditMode,
  }),
);

export const previewSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  previewSelector,
};
