export const getDefaultRootFilter = type => [{
  group: [{
    field: 'type',
    value: type,
    operator: 'eq',
  }],
}];

export const defaultFields = ['id', 'images', 'specifications', 'attribute_set', 'scores', 'children_ids', 'categories', 'type', 'parent_id', 'parent_images', 'locked_attributes', 'pim_status'];

export const paginationLimit = 96;

export const defaultPagination = {
  page: 1,
  limit: paginationLimit,
};

export const defaultVisibilityStatusOptions = [{
  title: 'Not Visible',
  value: 'not_visible',
}, {
  title: 'Visible in Search',
  value: 'visible_in_search',
}, {
  title: 'Visible in Catalog',
  value: 'visible_in_catalog',
}, {
  title: 'Visible Global',
  value: 'visible_global',
}];

export const defaultStatusOptions = [{
  title: 'Imported',
  value: 'imported',
}, {
  title: 'Revised',
  value: 'revised',
}, {
  title: 'Merchandised',
  value: 'merchandised',
}, {
  title: 'Active',
  value: 'active',
}, {
  title: 'Published',
  value: 'published',
}];
