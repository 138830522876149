import React, { useState } from 'react';
import {
  func
} from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import IntlMessages from '../../../../util/IntlMessages';
import {
  getMappingData,
  saveMappingData, 
  getMappingNewRecord, 
  setMappingFilterState, 
  getExportToFMS 
} from '../../../../actions/mapper';
import { Button, IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';

export const Search = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchError, setSearchError] = useState('');

  function explode(delimiter, string)
  {
    const emptyArray = {0: ''};

    if ( arguments.length !== 2
      || typeof arguments[0] === 'undefined'
      || typeof arguments[1] === 'undefined' )
    {
      return null;
    }

    if ( delimiter === ''
      || delimiter === false
      || delimiter === null )
    {
      return false;
    }

    if ( typeof delimiter === 'function'
      || typeof delimiter === 'object'
      || typeof string === 'function'
      || typeof string === 'object' )
    {
      return emptyArray;
    }

    if ( delimiter === true ) {
      delimiter = '1';
    }

    return string.toString().split (delimiter.toString());
  }

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      getMapperData(e.target.value, 0);
    }
  };

  const getSearch = (e) => {
    getMapperData(searchValue, 0);
  };

  const getReset = (e) => {
    setSearchValue('');
    setSearchError('');
    props.getMappingData({
      limit: props.mapperItemsPerPage,
      offset: 0,
      reset: true,
      marketplace: props.mapperMarketplace
    });
    props.setMappingFilterState({disabled: false});
  };

  const getSearchValue = (searchValue, field) => {
    let operator = 'eq';

    if (field === 'title') {
      if (searchValue.substring(0, 1) === '"' && searchValue.slice(-1) === '"') {
        searchValue = searchValue.slice(1,-1);
      } else {
        searchValue = '%' + searchValue + '%';
        operator = 'like';
      }
    }

    return {
      'field' : field,
      'value' : searchValue,
      'operator' : operator
    };
  };

  const simpleSearch = (searchValue, offset, sort) => {
    if (!searchValue) {
      return getReset();
    }

    const modelIdField = 'model_id';
    let formatFilter = [];
    if (searchValue) {
      formatFilter[0] = {
        'condition': 'or',
        'group': [
          getSearchValue(searchValue, 'title'),
          getSearchValue(searchValue, modelIdField)
        ]
      };
    }

    if (formatFilter && formatFilter.length === 0) {
      formatFilter = null;
    }

    return formatFilter;
  };

  const prepareSearchString = (searchString, operator = 'eq') => {
    const res = explode(':', searchString);

    let field = res[0] ? res[0].trim() : '';
    let value = res[1] ? res[1].trim() : '';

    if (field === '' || value === '') {
      let error = 'Search parsing error';
      if (field) {
        error += ': field "' + field + '" has no value';
      } else if (value) {
        error += ': value "' + value + '" has no field';
      }

      return {
        'error': error
      };
    }

    let error = true;
    const mapperFieldsKeys = Object.keys(props.mapperFields);
    for (let i = 0; i < mapperFieldsKeys.length; i++) {
      if (field === 'categories' || mapperFieldsKeys[i] === field) {
        error = false;
      }
    }
    if (error) {
      return {
        'error': 'Search parsing error: field "' + field + '" does not exist'
      };
    }

    if (field === 'title') {
      value = '%' + value + '%';
      operator = 'like';
    } else if (field === 'categories') {
      let values = explode(',', value);
      for (let i = 0; i < values.length; i++) {
        values[i] = parseInt(values[i]);
      }
      value = values;
      operator = 'in';
    } else if (field === 'status') {
      const statusOptions = props.mapperFields.status.options;
      for (let i = 0; i < statusOptions.length; i++) {
        if (value.toString().toLowerCase() === statusOptions[i].toString().toLowerCase()) {
          value = i;
        }
      }
    }

    return {
      'field': field,
      'value': value,
      'operator' : operator
    };
  };

  const smartSearch = (searchValue, offset, sort) => {
    if (props.mapCondition.filter) {
      for (let i = 0; i < props.mapCondition.filter.length; i++) {
        for (let j = 0; j < props.mapCondition.filter[i].group.length; j++) {
          const group = props.mapCondition.filter[i].group;
          if (group[j] && group[j].field === 'categories') {
            searchValue += ' && categories: ' + group[j].value;
          }
        }
      }
    }

    const formatFilter = [];
    const orArray = explode('||', searchValue);
    for (let i = 0; i < orArray.length; i++) {
      if (orArray[i].indexOf('&&') === -1) {
        let preparedSearch = prepareSearchString(orArray[i]);
        if (preparedSearch.error) {
          setSearchError(preparedSearch.error);
        }

        formatFilter[i] = {
          'group': [preparedSearch]
        };

      } else {
        const andArray = explode('&&', orArray[i]);
        let group = [];
        for (let j = 0; j < andArray.length; j++) {
          let preparedSearch = prepareSearchString(andArray[j]);
          if (preparedSearch.error) {
            setSearchError(preparedSearch.error);
          }

          group[j] = preparedSearch;
        }

        formatFilter[i] = {
          'group': group
        };
      }
    }

    return formatFilter;
  };

  const getMapperData = (searchValue, offset) => {
    setSearchError('');
    const sort = props.mapCondition.sort ? props.mapCondition.sort : null;

    const formatFilter = (searchValue.indexOf(':') !== -1 || searchValue.indexOf('&&') !== -1 || searchValue.indexOf('||') !== -1)
      ? smartSearch(searchValue, offset, sort)
      : simpleSearch(searchValue, offset, sort);

    props.getMappingData({
      limit: props.mapperItemsPerPage,
      offset: offset,
      filter: formatFilter,
      sort: sort,
      marketplace: props.mapperMarketplace
    });
  };

  const saveMapperData = (e) => {
    const savingData = [];
    const realSavingData = [];
    const allData = [];
    const errorData = [];
    let isSaveRecord = false;
    let k = 0;
    for (let i = 0; i < props.mapData.length; i++) {
      isSaveRecord = false;
      savingData[i] = {};
      allData[i] = {};
      errorData[i] = {};
      for (let j = 0; j < Object.keys(props.mapperFields).length; j++) {
        let mapFieldValues = Object.values(props.mapperFields)[j];
        let value = (props.mapData[i] && props.mapData[i][j]) ? props.mapData[i][j] : null;
        let unsavedValue = (props.unsavedMapData[i] && props.unsavedMapData[i][j]) ? props.unsavedMapData[i][j] : null;

        if (mapFieldValues.editable === true) {
          if (value !== unsavedValue) {
            isSaveRecord = true;
          }

          if (value !== unsavedValue || mapFieldValues.type === 'invisible' || mapFieldValues.type === 'id') {
            if (mapFieldValues.type === 'select') {
              if (value === null) {
                value = 0;
              }
              value = parseInt(value);
            }
            if (mapFieldValues.type === 'checkbox') {
              value = !!value;
            }
            if (value && mapFieldValues.rule === 'json') {
              if (value.toString().indexOf(',')) {
                value = value.toString().split(',');
              } else {
                value = value.toString().split(' ');
              }

              let clearValue = [];
              let j = 0;
              let clearVal = '';
              for (let i = 0; i < value.length; i++) {
                clearVal = value[i].toString().trim();
                if (clearVal) {
                  clearValue[j] = clearVal;
                  j++;
                }
              }
              value = clearValue;
            }

            savingData[i][Object.keys(props.mapperFields)[j]] = value;
          }
        }
        allData[i][Object.keys(props.mapperFields)[j]] = value;

        errorData[i][Object.keys(props.mapperFields)[j]] = '';
        if (value && mapFieldValues.rule === 'webid') {
          function validateWebId(str)
          {
            var pattern = new RegExp("^[A-Z]{2}[0-9]{8}$", "g");

            return !!pattern.test(str);
          }

          if (!validateWebId(value)) {
            errorData[i][Object.keys(props.mapperFields)[j]] = <IntlMessages id="sidebar.mapper.webid" />
          }
        }
        if (value && mapFieldValues.rule === 'alpanumerical') {
          function validateAlpanumerical(str)
          {
            var pattern = new RegExp("[^a-zA-Z0-9]+", "g");

            return !!pattern.test(str);
          }
          if (validateAlpanumerical(value)) {
            errorData[i][Object.keys(props.mapperFields)[j]] = <IntlMessages id="sidebar.mapper.alpanumerical" />
          }
        }
        if (value && mapFieldValues.rule === 'url') {
          function validateURL(str)
          {
            var pattern = new RegExp('^(https?:\\/\\/)?'+
              '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
              '((\\d{1,3}\\.){3}\\d{1,3}))'+
              '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
              '(\\?[;&a-z\\d%_.~+=-]*)?'+
              '(\\#[-a-z\\d_]*)?$','i');

            return !!pattern.test(str);
          }
          if (!validateURL(value)) {
            errorData[i][Object.keys(props.mapperFields)[j]] = <IntlMessages id="sidebar.mapper.url" />
          }
        }
      }
      if (isSaveRecord === true) {
        realSavingData[k] = savingData[i];
        k++;
      }
    }

    if (realSavingData.length === 0) {
      NotificationManager.warning('No changes to submit to save');
    } else {
      props.saveMappingData({ savingData: realSavingData, allData: allData, errorData: errorData });
    }
  };

  const getNewRecord = (e) => {
    props.getMappingNewRecord({open: true});
  };
  
  const exportToFMS = (e) => {
    const sort = props.mapCondition.sort ? props.mapCondition.sort : null;
    const formatFilter = (searchValue.indexOf(':') !== -1 || searchValue.indexOf('&&') !== -1 || searchValue.indexOf('||') !== -1)
      ? smartSearch(searchValue, 0, sort)
      : simpleSearch(searchValue, 0, sort);

    props.getExportToFMS({
      filter: formatFilter,
      sort: sort,
      marketplace: props.mapperMarketplace
    });
  };

  return (
    <>
    <div className="product-heading product-header flex justify-between items-center">
      <div className="mapper-search-wrapper float-right">

        <div className="search-input-lg block">
          <OutlinedInput
            id="search-input-lg"
            type="search"
            className="search-input-lg float-left mapper-search-form"
            placeholder="Search"
            onChange={handleSearchChange}
            disabled={false}
            value={searchValue}
            onKeyPress={handleSearchKeyPress}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={getSearch}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )}
          />

        </div>

      </div>

      <Button
        variant="contained"
        color="primary"
        size="medium"
        className="text-white float-right"
        onClick={getReset}
      >
        <IntlMessages id="buttons.reset" />
      </Button>

      &nbsp;&nbsp;&nbsp;

      <Button
        variant="contained"
        color="primary"
        size="medium"
        className="text-white logo-color float-right"
        onClick={saveMapperData}
      >
        <IntlMessages id="buttons.save" />
      </Button>

      &nbsp;&nbsp;&nbsp;

      <Button
        variant="contained"
        color="primary"
        size="medium"
        className="text-white mapper-w-150 float-right"
        onClick={getNewRecord}
      >
        <IntlMessages id="button.addNewRecord" />
      </Button>
      
      &nbsp;&nbsp;&nbsp;

      <Button
        variant="contained"
        color="primary"
        size="medium"
        className="text-white green-color mapper-w-200 float-right"
        onClick={exportToFMS}
      >

        <IntlMessages id="button.exportToFMS" />
      </Button>

    </div>

    <div className="mapper-search-error">{ searchError }</div>

    </>
  );
};

Search.propTypes = {
  getMappingData: func.isRequired,
  saveMappingData: func.isRequired,
  getMappingNewRecord: func.isRequired,
  setMappingFilterState: func.isRequired,
  getExportToFMS: func.isRequired
};

Search.defaultProps = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  getMappingData,
  saveMappingData,
  getMappingNewRecord,
  setMappingFilterState,
  getExportToFMS
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
