import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { uniqBy, uniq, isEmpty } from 'lodash';
import RestActions from '../util/rest/actions';
import { attributesPath, productAttributesOptionsSearchPath } from '../util/paths';
import getUniqueAttributes from '../util/getUniqueAttributes';
import { showErrorNotification } from '../util/api';

import {
  FETCH_CATEGORIES_ATTRIBUTES_START,
  FETCH_CATEGORIES_ATTRIBUTES_FINISHED,
  CLEAR_CATEGORIES_ATTRIBUTES_STATE,
  CLEAR_PRODUCT_ATTRIBUTE_STATE,
  FETCH_PRODUCT_ATTRIBUTES_START,
  FETCH_PRODUCT_ATTRIBUTES_FINISHED,
  UPDATE_PRODUCT_ATTRIBUTE_LIST,
  FETCH_CERTAIN_PRODUCT_ATTRIBUTES_START,
  FETCH_CERTAIN_PRODUCT_ATTRIBUTES_FINISHED,
  FETCH_ATTRIBUTE_OPTIONS_START,
  FETCH_ATTRIBUTE_OPTIONS_FINISHED,
  FETCH_ATTRIBUTES_OPTIONS_START,
  FETCH_ATTRIBUTES_OPTIONS_FINISHED,
  FETCH_PRODUCT_ATTRIBUTES_FOR_VARIATIONS_START,
  FETCH_PRODUCT_ATTRIBUTES_FOR_VARIATIONS_FINISHED,
  FETCH_PRODUCT_ATTRIBUTE_SETS_FINISHED,
  SEARCH_ATTRIBUTE_OPTIONS_FINISHED,
  CLEAR_FOUND_ATTRIBUTE_OPTIONS,
  FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
  SET_SELECTED_ATTRIBUTE_OPTION,
  SET_SELECTED_ATTRIBUTES_OPTIONS,
  FETCH_FACETS_ATTRIBUTES_OPTIONS_FINISHED,
  CLEAR_INITIAL_ATTRIBUTE_OPTIONS,
} from './types';

import {
  searchProductAttributeSets,
  searchProductAttributes,
} from '../services/API/productAttribute';

import { CDMSClient } from '../util/api';

const productAttributeActions = new RestActions('product_attribute');

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';

export const fetchCategoriesAttributes = categoryIds => async (dispatch, getState) => {
  const limit = 300;
  const currentAttributes = getState().productAttribute.categoriesAttributes;
  const pages = Array.from(Array(Math.ceil(categoryIds.length / limit)).keys());

  dispatch({
    type: FETCH_CATEGORIES_ATTRIBUTES_START,
  });

  try {
    const promises = await pages.map(async (page) => {
      const categoriesIdsPart = limit * page;
      const payload = [...categoryIds].slice(categoriesIdsPart, categoriesIdsPart + limit);
      const path = `${attributesPath}?category_ids=${payload.join(',')}`;
      const attributesList = await axios.get(path);

      return attributesList;
    });

    const result = await Promise.all(promises);
    const fetchedList = result.map(item => item.data.data).flat();
    const mappedList = fetchedList.map(el => getUniqueAttributes(camelizeKeys(el))).flat();
    const list = uniqBy([...currentAttributes, ...mappedList], 'code');

    dispatch({
      type: FETCH_CATEGORIES_ATTRIBUTES_FINISHED,
      payload: { list },
    });
  } catch (err) {
    dispatch({
      type: FETCH_CATEGORIES_ATTRIBUTES_FINISHED,
      payload: { error: err.response, hasError: true },
    });
  }
};

export const clearCategoriesAttributesState = () => (dispatch) => {
  dispatch({
    type: CLEAR_CATEGORIES_ATTRIBUTES_STATE,
  });
};

export const clearProductAttributeState = () => (dispatch) => {
  dispatch({
    type: CLEAR_PRODUCT_ATTRIBUTE_STATE,
  });
};

export const clearFromProductCategoryState = payload => (dispatch) => {
  dispatch(productAttributeActions.clearFromStateFinished(payload));
};

export const fetchProductAttributes = (filter, fields = [], pagination) => (dispatch) => {
  const path = `${attributesPath}/search?ref=` + ref;

  dispatch({
    type: FETCH_PRODUCT_ATTRIBUTES_START,
  });

  axios.post(path, {
    filter,
    fields,
    pagination,
  }).then((res) => {
    dispatch({
      type: FETCH_PRODUCT_ATTRIBUTES_FINISHED,
      payload: { productAttributes: camelizeKeys(res.data.data) },
    });
  }).catch(error => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_PRODUCT_ATTRIBUTES_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  });
};

export const fetchProductAttributesForVariations = (
  filter, fields = [], pagination,
) => (dispatch, getState) => {
  const path = `${attributesPath}/search?ref=` + ref;
  const productAttributesState = getState().productAttribute.productAttributesForVariations;

  dispatch({
    type: FETCH_PRODUCT_ATTRIBUTES_FOR_VARIATIONS_START,
    payload: {
      fetchingAttributesForVariations: true,
      fetchedAttributesForVariations: false,
    },
  });
  axios.post(path, {
    filter,
    fields,
    pagination,
  }).then((res) => {
    dispatch({
      type: FETCH_PRODUCT_ATTRIBUTES_FOR_VARIATIONS_FINISHED,
      payload: {
        productAttributesForVariations: uniqBy([
          ...productAttributesState,
          ...camelizeKeys(res.data.data),
        ], 'code'),
        fetchingAttributesForVariations: false,
        fetchedAttributesForVariations: true,
      },
    });
  }).catch(error => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_PRODUCT_ATTRIBUTES_FOR_VARIATIONS_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  });
};

export const updateProductAttributes = attr => (dispatch, getState) => {
  const productAttributesState = getState().productAttribute.productAttributes;
  const productAttributes = uniqBy([...productAttributesState, attr], 'code');

  dispatch({ type: UPDATE_PRODUCT_ATTRIBUTE_LIST, payload: { productAttributes } });
};

export const fetchCertainProductAttributes = (
  filter, fields = [], pagination,
) => async (dispatch, getState) => {
  const productAttributesState = getState().productAttribute.certainProductAttributes;

  dispatch({
    type: FETCH_CERTAIN_PRODUCT_ATTRIBUTES_START,
  });

  try {
    const res = await cdmsFetchCertainProductAttributes({filter, fields, pagination});
    const result = uniqBy([...productAttributesState, ...res.data.data], 'code');

    dispatch({
      type: FETCH_CERTAIN_PRODUCT_ATTRIBUTES_FINISHED,
      payload: { certainProductAttributes: camelizeKeys(result) },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_CERTAIN_PRODUCT_ATTRIBUTES_FINISHED,
      payload: {
        error: error.response ? error.response.data : '',
        hasError: true,
      },
    });
  }
};

export const cdmsFetchCertainProductAttributes = (payload) =>
  CDMSClient.post(`${attributesPath}/search?ref=` + ref, payload);

export const fetchAttributeOptions = code => async (dispatch, getState) => {
  const attributesOptionsState = getState().productAttribute.attributesOptions;
  const path = `${attributesPath}/search?ref=` + ref;
  const fields = [
    'code',
    'options',
    'label',
    'frontend_input_type',
  ];

  const filter = [
    {
      group: [{
        field: 'code',
        value: code,
        operator: 'like',
      }],
    },
  ];

  if (attributesOptionsState.some(attr => attr.code === code)) {
    return attributesOptionsState;
  }

  dispatch({
    type: FETCH_ATTRIBUTE_OPTIONS_START,
    payload: {
      optionsLoadingCode: code,
    },
  });

  return axios.post(path, {
    filter,
    fields,
    pagination: { page: 1, limit: 500 },
  }).then((res) => {
    const result = uniqBy([...attributesOptionsState, ...res.data.data], 'code');
    const attributesOptions = camelizeKeys(result);

    dispatch({
      type: FETCH_ATTRIBUTE_OPTIONS_FINISHED,
      payload: {
        attributesOptions,
        optionsLoadingCode: '',
      },
    });
    return attributesOptions;
  }).catch(error => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_ATTRIBUTE_OPTIONS_FINISHED,
      payload: {
        error: error.response ? error.response.data : '',
        hasError: true,
      },
    });
  });
};

export const fetchAttributesOptions = codes => async (dispatch, getState) => {
  const attributesOptionsState = getState().productAttribute.attributesOptions;
  const path = `${attributesPath}/search?ref=` + ref;
  const fields = [
    'code',
    'options',
    'label',
    'frontend_input_type',
  ];

  const filter = [
    {
      group: [{
        field: 'code',
        value: uniqBy([...codes]),
        operator: 'in',
      }],
    },
  ];

  dispatch({
    type: FETCH_ATTRIBUTES_OPTIONS_START,
    payload: {
      optionsLoadingCodes: codes,
    },
  });

  return axios.post(path, {
    filter,
    fields,
    pagination: { page: 1, limit: 500 },
  }).then((res) => {
    const result = uniqBy([...attributesOptionsState, ...res.data.data], 'code');
    const attributesOptions = camelizeKeys(result);

    dispatch({
      type: FETCH_ATTRIBUTES_OPTIONS_FINISHED,
      payload: {
        attributesOptions,
        optionsLoadingCodes: [],
      },
    });
    return attributesOptions;
  }).catch(error => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_ATTRIBUTES_OPTIONS_FINISHED,
      payload: {
        error: error.response ? error.response.data : '',
        hasError: true,
      },
    });
  });
};

export const fetchProductAttributeSets = ({
  ids, fields = [], pagination,
}) => async (dispatch) => {
  const setsQuery = {
    filter: [
      {
        group: [{
          field: 'id',
          value: uniq(ids),
          operator: 'in',
        }],
      },
    ],
    pagination,
  };

  try {
    const sets = await searchProductAttributeSets(setsQuery);
    const codes = sets.map(s => s.groups.map(g => g.attributes).flat()).flat();
    const attributesQuery = {
      filter: [
        {
          group: [{
            field: 'code',
            value: uniq(codes),
            operator: 'in',
          }],
        },
      ],
      fields,
      pagination,
    };
    let attributes = [];

    if (!isEmpty(codes)) {
      attributes = await searchProductAttributes(attributesQuery);
    }

    dispatch({
      type: FETCH_PRODUCT_ATTRIBUTE_SETS_FINISHED,
      payload: {
        attributeSets: camelizeKeys(sets),
        productAttributes: camelizeKeys(attributes),
      },
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_PRODUCT_ATTRIBUTE_SETS_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  }
};

export const searchAttributeOptions = (attributeCode, query) => (dispatch) => {
  const params = {
    filter: [{
      condition: 'and',
      group: [{
        field: 'label.en.keyword',
        operator: 'like',
        value: `*${query}*`,
      }, {
        field: 'attribute_code',
        operator: typeof attributeCode === 'string' ? 'eq' : 'in',
        value: attributeCode,
      }],
    }],
    sort: [{
        field: 'label.en.keyword',
        order: 'asc',
    }],
    fields: [
      'attribute_code',
      'option_code',
      'label',
    ],
    pagination: { page: 1, limit: 100 },
  };

  axios.post(productAttributesOptionsSearchPath + '?ref=' + ref, {
    ...decamelizeKeys(params),
  }).then((res) => {
    const result = [...res.data.data];
    const foundOptions = camelizeKeys(result);

    dispatch({
      type: SEARCH_ATTRIBUTE_OPTIONS_FINISHED,
      payload: {
        foundOptions,
      },
    });
  }).catch(error => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: SEARCH_ATTRIBUTE_OPTIONS_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  });
};

export const clearFoundAttributeOptions = () => (dispatch) => {
  dispatch({
    type: CLEAR_FOUND_ATTRIBUTE_OPTIONS,
    payload: { foundOptions: [] },
  });
};

export const clearInitialAttributeOptions = () => (dispatch) => {
  dispatch({
    type: CLEAR_INITIAL_ATTRIBUTE_OPTIONS,
    payload: {
      initialOptions: [],
      fetchInitialAttributesOptionsStart: false,
      fetchInitialAttributesOptionsFinished: false,
    },
  });
};

export const fetchInitialAttributeOptions = (codes, options) => (dispatch) => {
  options = options.filter(o => o);
  const fiteredOptions = options.filter(o => o.startsWith('optn_'));

  if (!isEmpty(fiteredOptions)) {
    const group = [{
      field: 'option_code',
      operator: 'in',
      value: uniq(options.flat()),
    }, {
      field: 'attribute_code',
      operator: 'in',
      value: uniq(codes.flat()),
    }];

    const params = {
      filter: [{
        condition: 'and',
        group,
      }],
      fields: [
        'attribute_code',
        'option_code',
        'label',
      ],
      pagination: { page: 1, limit: fiteredOptions.length },
    };

    axios.post(productAttributesOptionsSearchPath + '?ref=' + ref, {
      ...decamelizeKeys(params),
    }).then((res) => {
      const result = [...res.data.data];
      const initialOptions = camelizeKeys(result);
      dispatch({
        type: FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
        payload: {
          initialOptions,
        },
      });
    }).catch(error => {
      showErrorNotification(error, 'CDMS');
      dispatch({
        type: FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
        payload: { error: error.response.data, hasError: true },
      });
    });
  }
};

export const fetchFacetsAttributeOptions = (codes, options) => (dispatch) => {
  options = options.filter(o => o);
  const fiteredOptions = options.filter(o => o.startsWith('optn_'));

  if (!isEmpty(fiteredOptions)) {
    const group = [{
      field: 'option_code',
      operator: 'in',
      value: uniq(fiteredOptions),
    }, {
      field: 'attribute_code',
      operator: 'in',
      value: uniq(codes),
    }];

    const params = {
      filter: [{
        condition: 'and',
        group,
      }],
      fields: [
        'attribute_code',
        'option_code',
        'label',
      ],
      pagination: { page: 1, limit: fiteredOptions.length },
    };

    axios.post(productAttributesOptionsSearchPath + '?ref=' + ref, {
      ...decamelizeKeys(params),
    }).then((res) => {
      const result = [...res.data.data];
      const facetsOptions = camelizeKeys(result);
      dispatch({
        type: FETCH_FACETS_ATTRIBUTES_OPTIONS_FINISHED,
        payload: {
          facetsOptions,
        },
      });
    }).catch(error => {
      showErrorNotification(error, 'CDMS');
      dispatch({
        type: FETCH_FACETS_ATTRIBUTES_OPTIONS_FINISHED,
        payload: {
          error: error.response ? error.response.data : '',
          hasError: true,
        },
      });
    });
  }
};

export const setSelectedAttributeOption = (option, rewrite = false) => (dispatch, getState) => {
  const { selectedAttributesOptions } = getState().productAttribute;
  const payload = {
    selectedAttributesOptions: rewrite
      ? [option]
      : uniqBy([...selectedAttributesOptions, option], 'optionCode'),
  };

  dispatch({
    type: SET_SELECTED_ATTRIBUTE_OPTION,
    payload,
  });
};

export const setSelectedAttributesOptions = (options, rewrite = false) => (dispatch, getState) => {
  const { selectedAttributesOptions } = getState().productAttribute;
  const payload = {
    selectedAttributesOptions: rewrite
      ? options
      : uniqBy([...selectedAttributesOptions, ...options], 'optionCode'),
  };

  dispatch({
    type: SET_SELECTED_ATTRIBUTES_OPTIONS,
    payload,
  });
};
