import React, { memo } from 'react';
import {
  arrayOf, shape, func, bool,
} from 'prop-types';
import { uniqBy } from 'lodash';
import { connect } from 'react-redux';

import MatButton from '@material-ui/core/Button';
import swal from '@sweetalert/with-react';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  searchAttributeOptions,
  setSelectedAttributeOption,
} from '../../../../actions/productAttribute';

import IntlMessages from '../../../../util/IntlMessages';
import FiltersList from './FiltersList';

const AttributesFilter = memo((props) => {
  const {
    filters, loading, updateFilters, errors, disabled,
    fetchAttributeOptions, fetchingAttributeOptions,
  } = props;

  const handleAddNewFilter = () => {
    const newFilterItem = {
      id: new Date().getUTCMilliseconds(),
      field: '',
      value: '',
      operator: 'eq',
    };

    updateFilters([
      ...filters,
      newFilterItem,
    ]);
  };

  const handleFilterAttributeChange = ({ updatedFilter, filterId }) => {
    const updatedFilters = filters.map((f) => {
      if (f.id === filterId) {
        return updatedFilter;
      }
      return f;
    });
    updateFilters(updatedFilters);
  };

  const handleAttributeRowDelete = (filterId) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to permanently delete this filter?',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    })
      .then((isAccepted) => {
        if (isAccepted) {
          updateFilters([
            ...filters.filter(f => f.id !== filterId),
          ]);
        }
      });
  };

  const attributesOptions = props.selectedCategoriesAttributes.map(attr => ({
    id: attr.code,
    label: attr.label.en,
    value: attr.code,
    type: attr.frontendInputType,
    options: attr.options
      ? attr.options.map(o => ({
        ...o,
        label: o.label.en,
      }))
      : [],
  }));

  const combinedOptions = uniqBy([
    ...props.initialOptions,
    ...props.foundOptions,
    ...props.selectedAttributesOptions,
  ], 'optionCode');

  const attributeValueOptions = attributesOptions.map(attr => ({
    ...attr,
    options: combinedOptions.filter(o => o.attributeCode === attr.value),
  }));

  return (
    <div className="category-filter-wrapper pt-0 block flex direction-column items-end">
      <div className="flex items-end direction-column relative block">
        <FiltersList
          listProps={{
            errors,
            loading,
            filters,
            disabled,
            attributesOptions,
            fetchAttributeOptions,
            attributeValueOptions,
            fetchingAttributeOptions,
            handleAttributeRowDelete,
            handleFilterChange: handleFilterAttributeChange,
            handleSearchOptions: props.searchAttributeOptions,
            setSelectedAttributeOption: props.setSelectedAttributeOption,
          }}
        />
      </div>
      <div className="category-filter-footer mt-15 relative flex items-center">
        {loading && (
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className="progress-warning influencer-filter-footer loader bottom"
            size={15}
            thickness={4}
          />
        )}
        <MatButton
          variant="contained"
          color="primary"
          className="text-white"
          onClick={handleAddNewFilter}
          disabled={loading || disabled}
        >
          <IntlMessages id="button.addFilterLabel" />
        </MatButton>
      </div>
    </div>
  );
});

AttributesFilter.propTypes = {
  updateFilters: func.isRequired,
  fetchAttributeOptions: func.isRequired,
  searchAttributeOptions: func.isRequired,
  setSelectedAttributeOption: func.isRequired,
  filters: arrayOf(shape()).isRequired,
  errors: shape(),
  loading: bool,
  fetchingAttributeOptions: bool.isRequired,
  disabled: bool.isRequired,

  foundOptions: arrayOf(shape()).isRequired,
  initialOptions: arrayOf(shape()).isRequired,
  selectedAttributesOptions: arrayOf(shape()).isRequired,
  selectedCategoriesAttributes: arrayOf(shape()).isRequired,
};

AttributesFilter.defaultProps = {
  loading: false,
  errors: {},
};

const mapStateToProps = state => ({
  foundOptions: state.productAttribute.foundOptions,
  initialOptions: state.productAttribute.initialOptions,
  selectedAttributesOptions: state.productAttribute.selectedAttributesOptions,
  attributesOptions: state.productAttribute.attributesOptions,
  selectedCategoriesAttributes: state.influencer.selectedCategoriesAttributes,
});

const actionCreators = {
  searchAttributeOptions,
  setSelectedAttributeOption,
};

export default connect(mapStateToProps, actionCreators)(AttributesFilter);
