/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { rankingRule } from '../../../../../util/constants/constants';

const useSortingEffect = ({
  virtualCategoryFetched,
  setSortRules,
  virtualCategoryItem,
  setRankingRuleCode,
}) => {
  const directionOptions = [{
    value: 'ASC',
    label: 'ASC',
  }, {
    value: 'DESC',
    label: 'DESC',
  }];

  const buildSorts = (item) => {
    const { virtualParams } = item;
    const mappedAttributes = virtualParams.sortRules.map(s => ({
      field: s.field,
      options: directionOptions,
      defaultValue: s.direction.toUpperCase(),
    }));
    if (virtualParams.rankingRuleCode) {
      mappedAttributes.push({
        field: rankingRule,
        defaultValue: virtualParams.rankingRuleCode,
        options: directionOptions,
      });
    }
    return mappedAttributes;
  };

  useEffect(() => {
    if (virtualCategoryFetched) {
      setSortRules(buildSorts(virtualCategoryItem));
      setRankingRuleCode(virtualCategoryItem.virtualParams.rankingRuleCode);
    }
  }, [virtualCategoryFetched]);
};

export default useSortingEffect;
