/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useScopeCategory = (state, props) => {
  useEffect(() => {
    props.fetchRootCategories();
  }, []);

  useEffect(() => () => {
    props.clearScopeCategoryTreeState();
  }, []);
};

export default useScopeCategory;
