import React from 'react';
import { isEmpty } from 'lodash';
import { Button, ButtonGroup } from 'reactstrap';
import MatButton from '@material-ui/core/Button';
import {
  string, arrayOf, shape, bool,
} from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import IntlMessages from '../../util/IntlMessages';

const ButtonGroupComponent = ({
  className, list, materialBtn, loading,
}) => (
  <ButtonGroup className={className}>
    {!isEmpty(list) && list.map((item) => {
      const handleClick = () => item.onClick(item);

      return (
        materialBtn
          ? !item.hide && (
            <MatButton
              {...item.materialButtonSettings}
              className={item.key}
              key={item.key || item.id}
              onClick={handleClick}
              disabled={item.disabled}
            >
              <IntlMessages id={`${item.value}`} />
            </MatButton>
          )

          : !item.hide && (
            <Button
              {...item.buttonSettings}
              key={item.key || item.id}
              onClick={handleClick}
              disabled={item.disabled}
            >
              <IntlMessages id={`${item.value}`} />
            </Button>
          )
      );
    })}
    {loading && (
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className="progress-warning btn-group-loader btn-group loader bottom"
        size={20}
        thickness={4}
      />
    )}
  </ButtonGroup>
);

ButtonGroupComponent.propTypes = {
  className: string,
  list: arrayOf(shape({
    id: string,
    key: string,
    value: string,
  })).isRequired,
  materialBtn: bool,
  loading: bool,
};

ButtonGroupComponent.defaultProps = {
  className: '',
  materialBtn: false,
  loading: false,
};

export default ButtonGroupComponent;
