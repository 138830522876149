export default [
  {
    label: 'Less than',
    value: 'lt',
    availableFor: ['number', 'slider', 'datePicker', 'select', 'input'],
  }, {
    label: 'Less than equal',
    value: 'lte',
    availableFor: ['number', 'slider', 'select', 'input'],
  }, {
    label: 'Greater than',
    value: 'gt',
    availableFor: ['number', 'slider', 'datePicker', 'select', 'input'],
  }, {
    label: 'Greater than equal',
    value: 'gte',
    availableFor: ['number', 'slider', 'select', 'input'],
  }, {
    label: 'Equal',
    value: 'eq',
    availableFor: ['dropdown', 'select', 'number', 'text', 'slider', 'dropdown_swatch_color', 'enum', 'datePicker'],
  }, {
    label: 'Not Equal',
    value: 'ne',
    availableFor: ['dropdown', 'select', 'number', 'text', 'slider', 'dropdown_swatch_color', 'enum'],
  }, {
    label: 'Exists',
    value: 'exists',
    availableFor: ['dropdown', 'select', 'number', 'text', 'slider', 'dropdown_swatch_color', 'enum', 'exists'],
  }, {
    label: 'Not exists',
    value: 'not_exists',
    availableFor: ['dropdown', 'select', 'number', 'text', 'slider', 'dropdown_swatch_color', 'enum', 'exists'],
  }, {
    label: 'Like',
    value: 'like',
    availableFor: ['text'],
  }, {
    label: 'Not like',
    value: 'nlike',
    availableFor: ['text'],
  }, {
    label: 'In',
    value: 'in',
    availableFor: ['dropdown', 'select', 'selectProductsButton', 'dropdown_swatch_color', 'enum', 'createFacet'],
  }, {
    label: 'Not in',
    value: 'nin',
    availableFor: ['dropdown', 'select', 'selectProductsButton', 'dropdown_swatch_color', 'enum', 'createFacet'],
  }, {
    label: 'Between',
    value: 'between',
    availableFor: [],
  }, {
    label: 'Regexp',
    value: 'regexp',
    availableFor: [],
  }, {
    label: 'Exists',
    value: 'exists',
    availableFor: [],
  }, {
    label: 'Not regexp',
    value: 'nregexp',
    availableFor: [],
  },
];
