export const actionOptions = [{
  title: 'Hide',
  value: 'hide',
}, {
  title: 'Show',
  value: 'show',
}, {
  title: 'Pin to top',
  value: 'pin_to_top',
}, {
  title: 'Pin to position',
  value: 'pin_to_position',
}, {
  title: 'Pin to bottom',
  value: 'pin_to_bottom',
}, {
  title: 'Mark as reviewed',
  value: 'mark_as_reviewed',
  disabled: true,
}, {
  title: 'Add badge',
  value: 'add_badge',
  disabled: true,
}, {
  title: 'Unpin',
  value: 'unpin',
}];

export const actions = {
  hide: 'hide',
  show: 'show',
  pinToTop: 'pin_to_top',
  pinToPosition: 'pin_to_position',
  pinToBottom: 'pin_to_bottom',
  markAsReviewed: 'mark_as_reviewed',
  addBadge: 'add_badge',
  unpin: 'unpin',
};

export const productStatuses = {
  visibleGlobal: 'visible_global',
  notVisible: 'not_visible',
};

export default {
  actionOptions,
  actions,
  productStatuses,
};
