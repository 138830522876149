import React, { useState, useEffect } from 'react';
import Search from '../../components/Search';
import Pagination from '../../../../components/Pagination';
import Sorting from '../../../../components/Sorting';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import IntlMessages from '../../../../util/IntlMessages';
import { moneyFormat } from '../../../Pim/pages/Home/utils/productDataGenerator';
import { initialTableScroll, moveTableScroll } from '../../../../util/table';
import PagePlaceholder from '../../../../components/PagePlaceholder';

export const RepricingTable = ({
  getRepricingData,
  repricingFields,
  mapperData,
  mapperScroll,
  mapperCondition,
  mapperItemsPerPage,
}) => {

  const [unsavedMapData, setUnsavedMapData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [mapScroll, setMapScroll] = useState([]);
  const [mapCondition, setMapCondition] = useState([]);
  const [mapperAllPages, setMapperAllPages] = useState(0);
  const [mapperCurrentPage, setMapperCurrentPage] = useState(1);
  const [currentRepricingFields, setCurrentRepricingFeelds] = useState([]);

  useEffect(() => {
    const formatMapData = [];
    const repricingFieldsKeys = Object.keys(repricingFields);
    const allRepricingFieldsKeys = Object.keys(repricingFields);

    for (let i = 0; i < repricingFieldsKeys.length; i++) {
      if (repricingFields[repricingFieldsKeys[i]].field) {
        repricingFieldsKeys[i] = repricingFields[repricingFieldsKeys[i]].field;
      }
    }

    for (let i = 0; i < mapperData.length; i++) {
      if (mapperData[i]) {

        const formatDataItem = [];
        for (let j = 0; j < repricingFieldsKeys.length; j++) {
          if (repricingFieldsKeys[j].indexOf('.') !== -1) {
            const splitField = repricingFieldsKeys[j].split('.');
            if (mapperData[i][splitField[0]]) {
              formatDataItem[j] = mapperData[i][splitField[0]][splitField[1]];
            }

          } else {
            formatDataItem[j] = mapperData[i][repricingFieldsKeys[j]];
          }

        }
        formatMapData[i] = formatDataItem;
      }
    }

    let j = 0;
    const currentRepricingFields = [];
    for (let i = 0; i < allRepricingFieldsKeys.length; i++) {
      if (formatMapData && formatMapData[0] && formatMapData[0][i] !== undefined) {
        currentRepricingFields[j] = repricingFields[allRepricingFieldsKeys[i]];
        currentRepricingFields[j].field = allRepricingFieldsKeys[i];
        j++;
      }
    }
    setCurrentRepricingFeelds(currentRepricingFields);

    const currentFormatData = [];
    for (let i = 0; i < formatMapData.length; i++) {
      currentFormatData[i] = formatMapData[i].filter(function (el) {
        return el !== undefined;
      });
    }
    setMapData(currentFormatData);
    setUnsavedMapData(currentFormatData);

    setMapScroll(mapperScroll);
    setMapCondition(mapperCondition);
    setMapperAllPages(Math.ceil(mapperScroll.total / mapperItemsPerPage));
    let currentPage = Math.ceil(mapperScroll.offset / mapperItemsPerPage) + 1;
    if (currentPage <= 0) {
      currentPage = 1;
    }
    setMapperCurrentPage(currentPage);
  }, [
    mapperData, mapperScroll, mapperCondition, repricingFields, mapperItemsPerPage
  ]);

  const MapperTitle = ((props) => {
    const {
      item, type, field, name, sortable, fixedWidth
    } = props;

    if (type === 'invisible') {
      return '';
    }

    let className = "mapper-no-wrap";
    if (fixedWidth === 'long') {
      className += " mapper-w-350";
    }
    if (fixedWidth === 'middle') {
      className += " mapper-w-150";
    }
    if (fixedWidth === 'short') {
      className += " mapper-w-50";
    }

    if (sortable === true) {
      className += " mapper-sortable";

      return (
        <Sorting
          getData={getRepricingData}
          item={field ? field : item}
          condition={mapCondition}
          name={name}
          className={className}
          itemsPerPage={mapperItemsPerPage}
        />
      );
    }

    return (
      <TableCell><div className={className}>{ name }</div></TableCell>
    );
  });

  const MapperItem = ((props) => {
    const {
      type, currency, options, maxLength, value
    } = props;

    let tablevalue = '';

    if (type === 'checkbox') {
      tablevalue = value ? 'Yes' : 'No';
    } else if (type === 'checkbox_revert') {
      tablevalue = value ? 'No' : 'Yes';
    } else if (type === 'price') {
      tablevalue = moneyFormat(parseFloat(value), currency);
    } else if (type === 'url' && options) {
      if (value) {
        return (
          <TableCell className="mapper-td">
            <a href={options.replace('%value%', value)} target="_blank" rel="noopener noreferrer">zap.co.il</a>
          </TableCell>
        );
      } else {
        tablevalue = 'N/A';
      }
    }
    else if (type === 'select' && options) {
      const optionsKeys = Object.keys(options);
      for (let i = 0; i < optionsKeys.length; i++) {
        if (optionsKeys[i] === value) {
          tablevalue = options[optionsKeys[i]];
        }
      }
    } else {
      tablevalue = value || value === 0 ? value : 'N/A';
      if (maxLength && tablevalue.length > maxLength) {
        tablevalue = tablevalue.substring(0, maxLength) + '...';
      }
    }

    if (Array.isArray(tablevalue)) {
      let arrValue = '';
      for (let i = 0; i < tablevalue.length; i++) {
        arrValue += tablevalue[i];
        if (i < tablevalue.length - 1) {
          arrValue += ',';
        }
      }
      tablevalue = arrValue;
    }

    return (
      <TableCell className="mapper-td">
        {tablevalue}
      </TableCell>
    );
  });

  initialTableScroll(
    'mapperTable',
    'mapperTableScroll',
    'mapperTableContainer',
    'mapperTableContainerScroll'
  );

  const moveScroll = () => {
    moveTableScroll('mapperTableContainer', 'mapperTableContainerScroll');
  };

  return (
    <>
      <Search
        repricingFields={repricingFields}
        mapperItemsPerPage={mapperItemsPerPage}
        unsavedMapData={unsavedMapData}
        mapData={mapData}
        mapScroll={mapScroll}
        mapCondition={mapCondition}
      />

      <br/>

      <div id="mapperTableScrollDiv" className="mapper-table-scroll">
        <TableContainer
          id="mapperTableContainerScroll"
          className="product-table-container"
          onScroll={moveScroll}
        >
          <Table id="mapperTableScroll">
            <tr><td>&nbsp;</td></tr>
          </Table>
        </TableContainer>
      </div>

      <TableContainer id="mapperTableContainer" className="product-table-container-no-scroll">
        <Table id="mapperTable" stickyHeader aria-label="sticky table" size="small">
          <TableHead className="products-table-header">
            <TableRow>
              {Object.keys(currentRepricingFields).map((item, itemKey) => (
                <MapperTitle
                  classNaqme="mapper-no-wrap"
                  item={item}
                  field={Object.values(currentRepricingFields)[itemKey].field}
                  type={Object.values(currentRepricingFields)[itemKey].type}
                  name={Object.values(currentRepricingFields)[itemKey].name}
                  sortable={Object.values(currentRepricingFields)[itemKey].sortable}
                  fixedWidth={Object.values(currentRepricingFields)[itemKey].fixedWidth}
                />
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {mapData && mapData.map((item, itemKey) => (
              <TableRow>
                {Object.keys(currentRepricingFields).map((value, valueKey) => (
                  <MapperItem
                    type={Object.values(currentRepricingFields)[valueKey].type}
                    currency={Object.values(currentRepricingFields)[valueKey].currency}
                    options={Object.values(currentRepricingFields)[valueKey].options}
                    maxLength={Object.values(currentRepricingFields)[valueKey].maxLength}
                    value={item[valueKey]}
                  />
                ))}
              </TableRow>
            ))}

            {mapData.length === 0 && (
              <TableRow>
                <TableCell className="rules-no-border">
                  <PagePlaceholder
                    icon={<i className="ti-face-sad text-primary text-lg" />}
                    title={<IntlMessages id="title.sorry" />}
                    subtitle={<IntlMessages id="title.noResultsFound" />}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        getData={getRepricingData}
        dataCount={mapData ? mapData.length : 0}
        itemsPerPage={mapperItemsPerPage}
        allPages={mapperAllPages}
        currentPage={mapperCurrentPage}
        scroll={mapScroll}
        condition={mapCondition}
      />
    </>
  );
};

export default RepricingTable;
