import { useState } from 'react';

export const useStateHook = () => {
  const [selectedList, setSelectedList] = useState([]);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedFacet, setSelectedFacet] = useState({});
  const [facetDialogTitleCode, setFacetDialogTitleCode] = useState('facet.dialog.createNew.title');
  const [facetManagementEventName, setFacetManagementEventName] = useState('');
  const [facetDialogMode, setFacetDialogMode] = useState('create');

  const state = {
    selectedList,
    list,
    selected,
    isDialogOpen,
    selectedFacet,
    facetDialogTitleCode,
    facetManagementEventName,
    facetDialogMode,
  };

  const setState = {
    setSelectedList,
    setList,
    setSelected,
    setIsDialogOpen,
    setSelectedFacet,
    setFacetDialogTitleCode,
    setFacetManagementEventName,
    setFacetDialogMode,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
