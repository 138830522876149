import React from 'react';
import {
  func, arrayOf, shape, bool, oneOfType, node, string,
} from 'prop-types';
import { isEmpty } from 'lodash';
import {
  sortableContainer, sortableElement, sortableHandle,
} from 'react-sortable-hoc';
import { Button, IconButton } from '@material-ui/core';
import AutoComplete from 'components/AutoComplete';
import AutoCompleteMultiple from 'components/AutoComplete/Multiple';
import arrayMove from 'array-move';

import IntlMessages from 'util/IntlMessages';
import TooglePaper from 'components/TooglePaper';
import renderHelper from '../utils/renderHelper';

const DragHandle = sortableHandle(() => <i className="ti-drag" />);

const SortableItem = sortableElement(({
  item, disabledGlobally, onDelete, onChange, groupType, onBlur,
  onSearchOptions, groupValueOptions, errors, index,
}) => {
  const handleChange = event => onChange(event);
  const handleDelete = () => onDelete(item.id);

  const renderAutocomplete = () => {
    const hasError = !isEmpty(errors);
    switch (groupType) {
      case 'hierarchy':
        return (
          <AutoComplete
            useAdvancedOptions
            className="select-autocomplete-wrapper block"
            name="groupedValue"
            inputProps={{
              name: 'operator',
            }}
            placeholder="Enter value"
            value={groupValueOptions.find(g => g.value === item.value)}
            options={groupValueOptions.filter(e => e.label)}
            hideStaticLabel
            onChange={handleChange}
            onDelete={handleDelete}
            error={hasError}
            helperText={hasError ? renderHelper(errors) : ''}
            onBlur={onBlur}
            handleSearchOptions={onSearchOptions}
          />
        );
      case 'combined_values':
        return (
          <AutoCompleteMultiple
            error={hasError}
            name="groupedValue"
            placeholder="Enter value"
            helperText={hasError ? renderHelper(errors) : ''}
            onBlur={onBlur}
            className="autocomplete-select-control-wrapper autocomplete-value-xs select-product-attribute-wrapper block"
            value={item.value}
            inputValue={item.query}
            closeMenuOnSelect={false}
            options={groupValueOptions}
            onChange={handleChange}
            handleSearchOptions={onSearchOptions}
            useAdvancedOptions
          />
        );

      default:
        return null;
    }
  };

  return (
    <li className={`drag-list-item drag-list-item-wrapper facet-grouped-values-type-wrapper ${disabledGlobally ? 'disabled' : ''}`}>
      <DragHandle disabled={disabledGlobally} />
      <span
        key={item.id}
        className={`td td-${item.className} block flex items-center`}
      >
        <div className="flex-1 form-control-clean">
          {renderAutocomplete()}
        </div>
        {index > 0 && (
          <IconButton
            className="btn-mui-sm text-danger-hover"
            onClick={handleDelete}
          >
            <i className="ti-trash" />
          </IconButton>
        )}
      </span>
    </li>
  );
});

const SortableContainer = sortableContainer(({ children }) => (
  <ul className="drag-list-container drag-list-dragger drag-list-card">{children}</ul>
));

const GroupValue = (props) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedList = arrayMove(props.list, oldIndex, newIndex);

    props.onChange(updatedList);
  };

  const handleListItemDelete = (item) => {
    const newList = props.list.filter(l => l.id !== item.id);

    props.onChange(newList);
  };

  const handleListItemValueChange = (event, item) => {
    const { value } = event.target;
    const newList = props.list.map(l => ({
      ...l,
      value: l.id === item.id ? value : l.value,
    }));
    props.onChange(newList);
  };

  const lockDrag = !isEmpty(props.list) ? 'no-drag' : '';

  return (
    <div className="facet-name-wrapper facet-group facet-group-column">
      {props.useToggle && (
        <div className="facet-group-inner">
          <TooglePaper
            label={<IntlMessages id="facet.selectedValue.toogle.title" />}
            value={props.displayLanguage.value}
            onChange={props.displayLanguage.onChange}
            list={props.displayLanguage.list}
          />
        </div>
      )}
      <div className="facet-group-inner">
        <SortableContainer className="relative" lockAxis="y" onSortEnd={onSortEnd} useDragHandle>
          {!isEmpty(props.list) && props.list.map((item) => {
            const error = !isEmpty(props.errors)
              ? props.errors.find(err => err.id === item.id)
              : {};

            return (
              <SortableItem
                className={lockDrag}
                errors={!isEmpty(error) && !isEmpty(error.messages) ? error.messages : []}
                key={`item-${props.list.indexOf(item)}`}
                index={props.list.indexOf(item)}
                disabledGlobally={false}
                onDelete={() => handleListItemDelete(item)}
                onChange={event => handleListItemValueChange(event, item)}
                onBlur={props.onBlur}
                onSearchOptions={props.onSearchOptions}
                item={item}
                groupValueOptions={props.options}
                groupType={props.type}
              />
            );
          })}
        </SortableContainer>
      </div>
      <div className="facet-group-inner">
        <div className="facet-actions flex items-center">
          <Button
            variant={props.button.variant}
            disabled={props.button.disabled}
            color={props.button.color}
            disableElevation
            onClick={props.onAdd}
            startIcon={props.button.startIcon}
          >
            {
              props.button.useIntl
                ? <IntlMessages id={props.button.label} />
                : props.button.label
            }

          </Button>
          {props.button.disabled && (
            <div className="helper errors ml-10">
              <IntlMessages id={props.button.helperId} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

GroupValue.propTypes = {
  type: string,
  list: arrayOf(shape()),
  displayLanguage: shape({
    value: string,
    onChange: func,
  }),
  button: shape({
    useIntl: bool,
    label: oneOfType([node, string]),
    startIcon: node,
  }),
  onAdd: func,
  onChange: func,
  onBlur: func,
  onSearchOptions: func,
  useToggle: bool,
  options: arrayOf(shape()),
  errors: arrayOf(shape({
    messages: arrayOf(shape({
      message: string,
    })),
  })),
};

GroupValue.defaultProps = {
  type: '',
  displayLanguage: null,
  list: [],
  button: {
    color: 'primary',
    variant: 'contained',
    useIntl: true,
    startIcon: null,
    label: 'facet.attribute.addSystemValue.title',
  },
  onAdd: null,
  onChange: null,
  onBlur: null,
  onSearchOptions: null,
  useToggle: true,
  options: [],
  errors: [],
};

export default GroupValue;
