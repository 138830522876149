/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment, memo, useCallback, useEffect, useMemo
} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { camelizeKeys, decamelizeKeys } from 'humps';
import {
  string, shape, func, bool,
} from 'prop-types';
import { isEmpty, uniq } from 'lodash';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import config from '../../util/config';

import OpenProductPriceModal from "components/ProductPrice/OpenProductPriceModal"

import {
  searchAttributeOptions,
  fetchInitialAttributeOptions,
  clearFoundAttributeOptions,
  setSelectedAttributeOption,
  fetchProductAttributesForVariations,
} from '../../actions/productAttribute';

import {
  setFields,
  fetchActivityLogsByProductId,
  sendEnrichmentRetriggerData,
} from '../../actions/product';

//import useEditEffects from './hooks/useEdit';
import useStateStore from './hooks/useState';

import IntlMessages from '../../util/IntlMessages';
import ProductImages from '../ProductImages';
import ProductVideos from '../ProductVideos';
import Field from '../Field';

import {
  productAttributeSelector,
} from './selectors/productAttributeSelector';
import {
  productSelector,
} from './selectors/productSelector';
import { Tooltip } from "@material-ui/core";
import { defaultVisibilityStatusOptions } from "../ProductDetail/utils/defaultData";
import getPimStatus from "../ProductDetail/utils/getPimStatus";
import Badge from "@material-ui/core/Badge";

const getFormattedOption = (initOption, foundOption, labelKey) => {
  if (!isEmpty(initOption)) {
    return ([{
      label: initOption.label[labelKey],
      value: initOption.label[labelKey],
      disabled: foundOption.value === initOption.label[labelKey],
    }]);
  } return [];
};

const DetailContainer = memo((props) => {
  const state = useStateStore();

  let timeout = null;

  const {
    pimAttributes,
    productAttributeProps,
    productProps,
    productLanguage,
    specificationErrors,
    disabled,
    className,
    onTextFieldChange,
  } = props;

  const { productAttributes, attributeSets } = productAttributeProps;
  const currentAttributeSetGroups = attributeSets && attributeSets[0]
    ? attributeSets[0].groups
    : [];
  const productIsParent = productProps.editableProduct.type === 'parent';
  const productIsVariant = productProps.fields.type === 'variation';
  const specs = productIsParent
    ? productProps.fields.specifications
    : productProps.fields.variationAttributes;

  const formAttributeSetGroups = [];
  for (let i = 0; i < currentAttributeSetGroups.length; i++) {
    if (currentAttributeSetGroups[i].title.en !== 'System') {
      formAttributeSetGroups[i] = currentAttributeSetGroups[i];
    }
  }

  const formProductAttributes = [];
  for (let i = 0; i < productAttributes.length; i++) {
    if (!productIsVariant || productAttributes[i].isVariation === true) {
      formProductAttributes.push(productAttributes[i]);
    }
  }

  const isLocked = fieldName => !isEmpty(productProps.item.lockedAttributes)
    && productProps.item.lockedAttributes.some(lA => lA.includes(fieldName));

  const pimStatusField = !isEmpty(pimAttributes) && pimAttributes.find(pM => pM.code === 'pim_status.status');

  useEffect(() => {
    if (
      (
        !productAttributeProps.fetchInitialAttributesOptionsFinished
        && !productAttributeProps.fetchInitialAttributesOptionsStart
      )
      || (
        productAttributeProps.fetchInitialAttributesOptionsFinished
        && !productAttributeProps.fetchInitialAttributesOptionsStart
        && !isEmpty(productProps.fields)
        && (
          !isEmpty(productProps.fields.specifications)
          || !isEmpty(productProps.fields.variationAttributes)
        )
      )
    ) {
      let currentProductFields = productProps.fields.specifications;

      switch (productProps.fields.type) {
        case 'variation':
          currentProductFields = productProps.fields.variationAttributes;
          break;

        default:
          currentProductFields = productProps.fields.specifications;
          break;
      }
      const decamelizedKeys = decamelizeKeys({ ...currentProductFields });
      const codes = uniq(Object.keys(decamelizedKeys));
      const options = currentProductFields
        ? Object.values(currentProductFields)
        : [];

      if (
        productProps.fields.type === 'variation'
        && !isEmpty(codes)
        && !isEmpty(options)
        && !state.initialAttributesFetched
      ) {
        props.fetchInitialAttributeOptions(codes, options);
        state.setInitialAttributesFetched(true);
      }
    }
  }, [productAttributeProps.fetchInitialAttributesOptionsFinished]);

  const getLockIcon = useCallback((attrCode, iconClassName) => {
    const productLockedAttributes = isEmpty(productProps.editableProduct.lockedAttributes)
      ? productProps.item.lockedAttributes
      : productProps.editableProduct.lockedAttributes;

    const removingLockedAttributes = productProps.fields.removingLockedAttributes
      ? productProps.fields.removingLockedAttributes
      : [];

    if (!isEmpty(productLockedAttributes) && productLockedAttributes.indexOf(attrCode) !== -1) {
      if (removingLockedAttributes.indexOf(attrCode) === -1) {
        return <i className={`zmdi zmdi-lock text-primary ${iconClassName}`} />;
      }
    }

    return null;
  }, [productProps.fields, productProps.editableProduct.lockedAttribute, productProps.item.lockedAttributes]);

  const onDeleteSpecClick = useCallback((target) => {
    const currentProductIsParent = productProps.fields.type === 'parent';
    const specField = currentProductIsParent ? 'specifications' : 'variationAttributes';
    const pureObject = Object.assign({}, productProps.fields[specField]);
    const decamelizedPureObject = decamelizeKeys(pureObject);
    delete decamelizedPureObject[target];

    const newFields = {
      ...productProps.fields,
      [specField]: camelizeKeys(decamelizedPureObject),
    };

    props.setFields(newFields);
  }, [productProps.fields, props.setFields]);

  const getField = useCallback((payload) => {
    const key = payload.code;
    const currentSpecificationValue = specs ? (specs[key] ? specs[key] : decamelizeKeys(specs)[key]) : '';
    const productType = productIsParent
      ? 'specifications'
      : 'variationAttributes';
    const productLockedAttributes = isEmpty(productProps.editableProduct.lockedAttributes)
      ? productProps.item.lockedAttributes
      : productProps.editableProduct.lockedAttributes;
    const removingLockedAttributes = productProps.fields.removingLockedAttributes
      ? productProps.fields.removingLockedAttributes
      : [];

    let showIcon = false;
    if (productLockedAttributes) {
      showIcon = !(productLockedAttributes.indexOf(`${productType}.${key}`) !== -1
        && removingLockedAttributes.indexOf(`${productType}.${key}`) === -1);
    }

    const options = payload.options ? payload.options.map((o) => {
      const label = o.label && o.label.en ? o.label.en : o.title.en;
      const value = o.label && o.label.en ? o.label.en : o.title.en;

      return { label, value };
    }) : [];

    const getFieldElementByType = (fieldType) => {
      if (fieldType.startsWith('dropdown_')) return 'dropdown';

      switch (fieldType) {
        case 'text':
          return 'matInput';
        default: return fieldType;
      }
    };

    const field = {
      element: getFieldElementByType(payload.frontendInputType),
      label: <span>{payload.label.en}</span>,
      className: 'select-wrapper field-element group-sm',
      wrapperClassName: 'full-width group-sm',
      name: key,
      size: 'small',
      variant: 'outlined',
      id: key,
      value: currentSpecificationValue,
      options,
      emptyPlaceholder: 'Select from the list',
      hideStaticLabel: true,
      limitsToShow: 100,
      searchable: true,
      prefix: (
        <>
          {
              productAttributeProps.fetchingAttributeOptions
              && productAttributeProps.optionsLoadingCode === key
              && (
              <CircularProgress
                className="progress-warning loader attribute primary-color bottom"
                size={17}
                variant="indeterminate"
                disableShrink
                thickness={4}
              />
              )
            }
          {
              showIcon && currentSpecificationValue && (
              <IconButton
                className="btn-ico-sm ml-10 text-danger"
                aria-label="Delete"
                disabled={!productIsParent}
                onClick={() => onDeleteSpecClick(key)}
              >
                <i className="zmdi zmdi-close" />
              </IconButton>
              )
            }
        </>
      )
    }
    return field;
  }, [
    specs,
    productAttributeProps.fetchingAttributeOptions,
    productAttributeProps.optionsLoadingCode,
    onDeleteSpecClick,
    productIsParent,
    productProps.editableProduct.lockedAttribute, 
    productProps.item.lockedAttributes,
    productProps.fields,
    productIsParent
  ]);

  const onStockFieldChange = useCallback((e) => {
    const { value } = e.target;

    props.setFields({
      ...productProps.fields,
      stock: {
        ...productProps.fields.stock,
        qty: parseFloat(value),
      },
    });
  }, [productProps.fields, props.setFields]);

  const onZmidFieldChange = useCallback((e) => {
    const { value } = e.target;
    const zmid = value ? [value] : null;
    props.setFields({
      ...productProps.fields,
      zmid: zmid,
      dataEnrichment: {},
      priceEnrichment: { competitors: { zap: null }},
    });
  }, [productProps.fields, props.setFields])

  const onForcefullyChange =  useCallback((e) => {
    const checked = productProps.fields.isForcefullyIncludedInGoogleFeed !== true;
    props.setFields({
      ...productProps.fields,
      isForcefullyIncludedInGoogleFeed: checked,
    });
  }, [productProps.fields, props.setFields])

  const onPriceFieldChange = useCallback((e) => {
    const { value } = e.target;

    props.setFields({
      ...productProps.fields,
      price: {
        ...productProps.fields.price,
        totalPrice: parseFloat(value.replace('$', '')),
      },
    });
  }, [productProps.fields, props.setFields]);

  const onProductFieldChange = useCallback((e) => {
    const { name, value } = e.target;
    const data = productIsParent
      ? 'specifications'
      : 'variationAttributes';

    const newValue = {
      ...productProps.fields[data],
      [name]: value,
    };

    props.setFields({
      ...productProps.fields,
      [data]: newValue,
    });
  }, [props.setFields, productProps.fields, productIsParent]);

  const onHebrewTextFieldChange = useCallback((e) => {
    const { name, value } = e.target;
    props.setFields({
      ...productProps.fields,
      [name]: {
        ...productProps.fields[name],
        he: value,
      },
    });
  }, [productProps.fields, props.setFields]);

  const attributesGroups = useMemo(() => formAttributeSetGroups.map((g) => {
    const title = g.title.en;
    const groupFields = formProductAttributes
      .filter(f => g.attributes && g.attributes.includes(f.code))
      .map(p => getField(p));

    return {
      title,
      groupFields,
    };
  }), [formAttributeSetGroups, formProductAttributes, getField]);

  const childPrice = useMemo(() => productProps.childList
    && productProps.childList.length === 1
    && productProps.childList[0].price
    && productProps.childList[0].price.finalPrice, [productProps.childList]);

  const variantPrice = useMemo(() => (productProps.fields.price
    ? productProps.fields.price.finalPrice
    : null), [productProps.fields.price]);

  const price = useMemo(() => (productIsParent ? childPrice : variantPrice), [productIsParent, childPrice, variantPrice]);
  const temporaryDisabled = true;

  const handleAutocompleteChange = useCallback((data) => {
    const currentOption = productAttributeProps.foundOptions.find(
      o => o.attributeCode === data.target.name
        && (
          o.optionCode === data.target.value
        ),
    );
    onProductFieldChange(data);
    props.setSelectedAttributeOption(currentOption);
  }, [props.setSelectedAttributeOption, onProductFieldChange, productAttributeProps.foundOptions]);

  const handleAutocompleteSearchChange = useCallback((field, query) => {
    const { name } = field;

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      props.searchAttributeOptions(name, query);
    }, 100);
  }, [props.searchAttributeOptions]);

  const updateProductImages = useCallback((e, data) => {
    const mainImageList = productProps.fields.images
      .filter(img => img.url !== data.url);

    props.setFields({
      ...productProps.fields,
      images: mainImageList,
      imagesChange: true,
    });
  }, [productProps.fields]);

  const addProductImage = useCallback((e, data) => {
    const mainImageList = [...productProps.fields.images, data];

    props.setFields({
      ...productProps.fields,
      images: mainImageList,
      imagesChange: true,
    });
  }, [productProps.fields]);

  const editProductImage = useCallback((e, data) => {
    const mainImageList = productProps.fields.images.map(img => (img.url === data.url ? data : img));

    props.setFields({
      ...productProps.fields,
      images: mainImageList,
      imagesChange: true,
    });
  }, [productProps.fields]);

  const handleProductImagesDragEnd = useCallback((updatedProductIamges) => {
    props.setFields({
      ...productProps.fields,
      images: updatedProductIamges,
      imagesChange: true,
    });
  }, [props.setFields]);

  const addProductVideos = useCallback((video) => {
    const mainVideoList = productProps.fields.videos ? productProps.fields.videos : [];
    mainVideoList[mainVideoList.length] = video;

    props.setFields({
      ...productProps.fields,
      videos: mainVideoList,
      videosChange: true,
    });
  }, [productProps.fields]);

  const removeProductVideos = useCallback((e, index) => {
    const currentVideoList = productProps.fields.videos;
    const mainVideoList = [];
    let count = 0;
    for (let i = 0; i < currentVideoList.length; i++) {
      if (i !== index) {
        mainVideoList[count] = currentVideoList[i];
        count++;
      }
    }

    props.setFields({
      ...productProps.fields,
      videos: mainVideoList,
      videosChange: true,
    });
  }, [productProps.fields]);

  const updateProductVideos = useCallback((index, video) => {
    const mainVideoList = productProps.fields.videos;
    mainVideoList[index] = video;

    props.setFields({
      ...productProps.fields,
      videos: mainVideoList,
      videosChange: true,
    });
  }, [productProps.fields]);

  const updateProductVideosEnrichment = useCallback((videos) => {
    props.setFields({
      ...productProps.fields,
      videos: videos,
      videosChange: true,
    });
  }, [productProps.fields]);

  const sendEnrichmentRetriggerVideos = useCallback(() => {
    const fields = ['videos'];
    props.sendEnrichmentRetriggerData(productProps.fields.id, fields);
  }, [productProps.fields]);

  const onRemoveLockedAttribute = useCallback((e, field) => {
    const removingLockedAttributes = productProps.fields.removingLockedAttributes ?
        productProps.fields.removingLockedAttributes :
        [];
    removingLockedAttributes.push(field);

    if (field.indexOf('videos.') !== -1) {
      const videoIndex = parseInt(field.replace('videos.', ''));
      const productLockedAttributes = isEmpty(productProps.editableProduct.lockedAttributes)
          ? productProps.item.lockedAttributes
          : productProps.editableProduct.lockedAttributes;
      let lockedCount = 0;
      for (let i = 0 ; i < productLockedAttributes.length; i++) {
        if (productLockedAttributes[i].indexOf('videos.' + videoIndex) !== -1) {
          lockedCount++;
        }
      }
      let removingCount = 0;
      for (let i = 0 ; i < removingLockedAttributes.length; i++) {
        if (removingLockedAttributes[i].indexOf('videos.' + videoIndex) !== -1) {
          removingCount++;
        }
      }
      if (lockedCount - removingCount === 1) {
        removingLockedAttributes.push('videos.' + videoIndex);
      }
    }

    props.setFields({
      ...productProps.fields,
      removingLockedAttributes: removingLockedAttributes,
    });
  }, [props.setFields, productProps.fields, productProps.editableProduct, productProps.item]);

  const enaleEditing = disabled ? 'view-mode' : '';
  const priceReadOnly = !!((productIsParent || temporaryDisabled));

  const zapMinPrice = (
    productProps.item.priceEnrichment &&
    productProps.item.priceEnrichment.competitors &&
    productProps.item.priceEnrichment.competitors.zap &&
    productProps.item.priceEnrichment.competitors.zap.price
  ) ? '₪' + productProps.item.priceEnrichment.competitors.zap.price : 'N/A';
  const zapMaxPrice = (
    productProps.item.priceEnrichment &&
    productProps.item.priceEnrichment.competitors &&
    productProps.item.priceEnrichment.competitors.zap &&
    productProps.item.priceEnrichment.competitors.zap.maxPrice
  ) ? '₪' + productProps.item.priceEnrichment.competitors.zap.maxPrice : 'N/A';

  const ZapUrl = (props) => {
    if (!props.zmid) {
      return (<></>);
    }

    const zapUrl = config.zapWebsitePath.replace('%id%', props.zmid);
    return (
        <div className="group-sm zap-box">
          <div className="zap-statuses">
            <span className="zap-statuses-title"><IntlMessages id="preview.edit.label.zap_url" />: </span>
            <a className="zap-url" href={ zapUrl } target="_blank" rel="noopener noreferrer">
              { zapUrl }
            </a>
          </div>
        </div>
    );
  };

  const ZapStatus = (props) => {
    const mappingData = (props.mappingData && props.mappingData[0]) ? props.mappingData[0] : [];

    if (!mappingData.id) {
      return (
        <div className="group-sm zap-box">
          <div className="zap-statuses">
            <span className="zap-statuses-title"><IntlMessages id="preview.edit.label.zap_data_is_not_exists" /> </span>
          </div>
        </div>
      );
    } else if (!mappingData.last_sync_status) {
      return (
        <div className="group-sm zap-box">

          {mappingData.model_id && (
            <div className="zap-statuses">
              <span className="zap-statuses-title"><IntlMessages id="preview.edit.label.zap_model_id" />: </span>
              { mappingData.model_id }
            </div>
          )}

          <div className="zap-statuses">
            <span className="zap-statuses-title"><IntlMessages id="preview.edit.label.cdms_sync_status" />: </span>
            <span className={ 'zap-statuses-sync_0' }>
                <IntlMessages id={ 'preview.edit.label.zap_parsing_status_0' } />
            </span>
          </div>

        </div>
      );
    }

    return (
        <div className="group-sm zap-box">

          {mappingData.model_id && (
            <div className="zap-statuses">
              <span className="zap-statuses-title"><IntlMessages id="preview.edit.label.zap_model_id" />: </span>
              { mappingData.model_id }
            </div>
          )}

          {mappingData.zap_parsed_at && (
            <div className="zap-statuses">
              <span className="zap-statuses-title"><IntlMessages id="preview.edit.label.zap_parsed_at" />: </span>
              { mappingData.zap_parsed_at }
            </div>
          )}

          {mappingData.last_sync_status && (
            <div className="zap-statuses">
              <span className="zap-statuses-title"><IntlMessages id="preview.edit.label.cdms_sync_status" />: </span>
              <span className={ 'zap-statuses-sync_' + mappingData.last_sync_status }>
                <IntlMessages id={ 'preview.edit.label.zap_parsing_status_' + mappingData.last_sync_status } />
              </span>
            </div>
          )}

          {mappingData.last_sync && (
            <div className="zap-statuses">
              <span className="zap-statuses-title"><IntlMessages id="preview.edit.label.cdms_attempt_date" />: </span>
              { mappingData.last_sync.date ? mappingData.last_sync.date : mappingData.last_sync }
            </div>
          )}

          {mappingData.is_active && (
            <div className="zap-statuses">
              <span className="zap-statuses-title"><IntlMessages id="preview.edit.label.is_archive" />: </span>
              { mappingData.is_active === 1 || mappingData.is_active === true ? 'No' : 'Yes' }
            </div>
          )}

        </div>
    );
  };

  return (
    <>
      <div className={`product-edit-wrapper flex relative ${className} ${enaleEditing}`}>
        <div className="product-edit-left-col relative">
          {productAttributeProps.productAttributesFetching
            ? (
              <CircularProgress
                className="progress-warning loader primary-color bottom"
                size={20}
                variant="indeterminate"
                disableShrink
                thickness={4}
              />
            )
            : (
              <>
                <div className="default-fields">
                  {productIsParent && (
                    <div className="form-group-cell pb-15">
                      {productLanguage.en && (
                        <>
                        <span
                          className="locked-attribute locked-attribute-title"
                          onClick={e => onRemoveLockedAttribute(e, 'title.en')}
                        >
                          { getLockIcon('title.en', 'left') }
                        </span>
                        <span className="clear-both" />
                          <Field
                            element="matInput"
                            variant="outlined"
                            size="small"
                            label={<IntlMessages id="preview.edit.label.title.en" />}
                            className="select-wrapper float-left field-element"
                            wrapperClassName="group-sm"
                            name="title"
                            id="titleEn"
                            value={productProps.fields.title ? productProps.fields.title.en : ''}
                            hideStaticLabel
                            onChange={onTextFieldChange}
                            helperText={(
                              productProps.errors.title && !isEmpty(productProps.errors.title.en)
                            )
                              ? productProps.errors.title.en
                                .map(err => <IntlMessages key={err.type} id={err.message} />)
                              : null}
                          />
                        </>
                      )}
                      {productLanguage.he && (
                        <>
                          <br/>
                          <span
                            className="locked-attribute locked-attribute-title"
                            onClick={e => onRemoveLockedAttribute(e, 'title.he')}
                          >
                            { getLockIcon('title.he', 'left') }
                          </span>
                          <span className="clear-both" />
                          <Field
                            element="matInput"
                            variant="outlined"
                            size="small"
                            label={<IntlMessages id="preview.edit.label.title.he" />}
                            className="select-wrapper field-element"
                            wrapperClassName="group-sm"
                            name="title"
                            id="titleHe"
                            value={productProps.fields.title ? productProps.fields.title.he : ''}
                            hideStaticLabel
                            helperText={(
                              productProps.errors.title && !isEmpty(productProps.errors.title.he)
                            )
                              ? productProps.errors.title.he
                                .map(err => <IntlMessages key={err.type} id={err.message} />)
                              : null}
                            onChange={onHebrewTextFieldChange}
                          />
                        </>
                      )}
                    </div>
                  )}
                  {productProps.editableProduct.type === 'parent' && (
                    <>
                      <span className="locked-attribute" onClick={e =>
                          onRemoveLockedAttribute(e, 'is_forcefully_included_in_google_feed')}>
                        { getLockIcon('is_forcefully_included_in_google_feed', 'left') }
                      </span>
                      <span className="clear-both" />
                      <Field
                        element="checkbox"
                        variant="outlined"
                        size="small"
                        label={<IntlMessages id="preview.edit.label.is_forcefully_included_in_google_feed" />}
                        className="field-element"
                        wrapperClassName="group-sm"
                        name="isForcefullyIncludedInGoogleFeed"
                        id="is_forcefully_included_in_google_feed"
                        value={productProps.fields.isForcefullyIncludedInGoogleFeed}
                        checked={productProps.fields.isForcefullyIncludedInGoogleFeed === true}
                        hideStaticLabel
                        onChange={onForcefullyChange}
                      />
                    </>
                  )}
                  {productProps.editableProduct.type !== 'parent' && (
                      <OpenProductPriceModal priceFormula={productProps.item.priceFormula}>
                        <span className="locked-attribute" onClick={e => onRemoveLockedAttribute(e, 'min_price')}>
                          { getLockIcon('min_price', 'left') }
                        </span>
                        <span className="clear-both" />
                        <Field
                          element="matInput"
                          variant="outlined"
                          size="small"
                          label={<IntlMessages id="preview.edit.label.price" />}
                          className="select-wrapper field-element"
                          wrapperClassName="group-sm"
                          name="price"
                          id="price"
                          value={price ? `$${price}` : 0}
                          hideStaticLabel
                          onChange={onPriceFieldChange}
                          disabled={priceReadOnly}
                          inputProps={{
                            readOnly: priceReadOnly,
                          }}
                          style={{width: "100%"}}
                        />
                      </OpenProductPriceModal>
                  )}
                  {productProps.editableProduct.type !== 'parent' && (
                    <>
                      <br />
                      <span className="locked-attribute" onClick={e => onRemoveLockedAttribute(e, 'total_qty')}>
                        { getLockIcon('total_qty', 'left') }
                      </span>
                      <span className="clear-both" />
                      <Field
                        element="number"
                        variant="outlined"
                        size="small"
                        label={<IntlMessages id="preview.edit.label.quantity" />}
                        className="select-wrapper field-element"
                        wrapperClassName="group-sm"
                        name="qty"
                        id="qty"
                        value={productProps.fields.stock ? productProps.fields.stock.qty : 0}
                        hideStaticLabel
                        disabled={priceReadOnly}
                        inputProps={{
                          readOnly: priceReadOnly,
                        }}
                        onChange={onStockFieldChange}
                      />
                    </>
                  )}
                  {!isEmpty(productProps.fields) && productProps.fields.type === 'variation' && (
                    <div className="group-sm">
                      <>
                        <span className="locked-attribute" onClick={e => onRemoveLockedAttribute(e, 'upc')}>
                          { getLockIcon('upc', 'left') }
                        </span>
                        <span className="clear-both" />
                        <TextField
                          label="UPC"
                          variant="outlined"
                          size="small"
                          disabled={true}
                          inputProps={{
                            readOnly: true,
                          }}
                          className="block"
                          name="upc"
                          value={!isEmpty(productProps.fields.upc) ? productProps.fields.upc.join(', ') : '-'}
                        />
                      </>
                    </div>
                  )}
                </div>
                <div className="attributes-fields">
                  {attributesGroups.map(aG => aG.groupFields.length > 0 && (
                    <div key={aG.title} className="attributes-group">
                      <h4 className="attribute-title">{aG.title}</h4>
                      <div className="attributes-group-items" style={{ paddingTop: '0px' }}>
                        {aG.groupFields.map((f) => {
                          if (f.element === 'dropdown') {
                            const autocompleteOptions = uniq(
                              [...productAttributeProps.foundOptions],
                            );

                            const currentInitialOption = uniq(
                              [
                                ...productAttributeProps.initialOptions,
                                ...autocompleteOptions,
                                ...productAttributeProps.selectedAttributesOptions,
                              ],
                            ).find(io => io.optionCode === f.value) || {};

                            const attributeOption = getFormattedOption(currentInitialOption, f, 'en');
                            const mapSelectedProductOptions = !isEmpty(autocompleteOptions)
                              ? autocompleteOptions.map((o) => {
                                const optionLabel = o.label && o.label.en ? o.label.en : o.label;
                                return ({
                                  label: optionLabel,
                                  value: o.optionCode,
                                  disabled: f.value === optionLabel,
                                });
                              }).filter(ao => ao.value !== attributeOption.value)
                              : [];

                            return (
                              <>
                                <span className="locked-attribute locked-attribute-dropdown"
                                      onClick={e => onRemoveLockedAttribute(e, 'specifications.' + f.id)}
                                >
                                  { getLockIcon('specifications.' + f.id, 'left') }
                                </span>
                                <span className="clear-both" />
                                <Field
                                  {...f}
                                  options={mapSelectedProductOptions}
                                  loadOptions={productAttributeProps.searchAttributeOptionsStart}
                                  element="autocomplete"
                                  containerClassName="autocomplete-sm autocomplete-outlined"
                                  prefixClassName="prefix-abs"
                                  disabled={productIsVariant}
                                  inputProps={{
                                    name: f.id,
                                    id: `product-attr-${f.id}`,
                                    productIsVariant,
                                    readOnly: productIsVariant,
                                  }}
                                  value={attributeOption}
                                  key={f.id}
                                  onChange={handleAutocompleteChange}
                                  handleSearchOptions={
                                    query => handleAutocompleteSearchChange(f, query)
                                  }
                                  onBlur={props.clearFoundAttributeOptions}
                                />
                              </>
                            );
                          } return (
                            <>
                              <span className="locked-attribute locked-attribute-fields"
                                    onClick={e => onRemoveLockedAttribute(e, 'specifications.' + f.id)}
                              >
                                  { getLockIcon('specifications.' + f.id, 'left') }
                              </span>
                              <span className="clear-both" />
                              <Field
                                {...f}
                                key={f.id}
                                onChange={onProductFieldChange}
                                variant="outlined"
                                disabled={productIsVariant}
                                inputProps={{
                                  name: f.id,
                                  id: `product-attr-${f.id}`,
                                  productIsVariant,
                                  readOnly: productIsVariant,
                                }}
                                errors={specificationErrors[f.id]}
                                size="small"
                              />
                            </>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )
          }
        </div>
        <div className="product-edit-right-col">
          <span className="locked-attribute locked-attribute-images"
                onClick={e => onRemoveLockedAttribute(e, 'images')}
          >
            { getLockIcon('images', 'left') }
          </span>
          <span className="clear-both" />
          <ProductImages
            images={productProps.images || {}}
            updateProductImages={updateProductImages}
            addProductImages={addProductImage}
            editProductImage={editProductImage}
            onProductImagesDragEnd={handleProductImagesDragEnd}
            disabled={disabled}
          />
          <span className="locked-attribute locked-attribute-images"
                onClick={e => onRemoveLockedAttribute(e, 'videos')}
          >
            { getLockIcon('videos', 'left') }
          </span>
          <span className="clear-both" />
          <ProductVideos
            productId={productProps.fields.id}
            productType={productProps.editableProduct.type}
            videos={productProps.fields.videos || {}}
            errors={productProps.errors.videos || {}}
            addProductVideos={addProductVideos}
            removeProductVideos={removeProductVideos}
            updateProductVideos={updateProductVideos}
            updateProductVideosEnrichment={updateProductVideosEnrichment}
            sendEnrichmentRetriggerVideos={sendEnrichmentRetriggerVideos}
            onRemoveLockedAttribute={onRemoveLockedAttribute}
            getLockIcon={getLockIcon}
          />
          {productIsVariant && (
            <div className="attributes-fields">
              <div key="description" className="attributes-group-1">
                <h4 className="attribute-title"><IntlMessages id="preview.edit.label.zap" /></h4>
                <div>&nbsp;</div>
                <Fragment>
                  <div className="group-sm">
                    <span className="locked-attribute" onClick={e => onRemoveLockedAttribute(e, 'zmid')}>
                      { getLockIcon('zmid', 'left') }
                    </span>
                    <span className="clear-both" />
                    <TextField
                        label="Zap Model ID"
                        variant="outlined"
                        size="small"
                        className="block"
                        name="zmid"
                        onChange={onZmidFieldChange}
                        value={ productProps.fields.zmid && !isEmpty(productProps.fields.zmid)
                          ? productProps.fields.zmid[0]
                          : '' }
                    />
                  </div>
                  <ZapUrl zmid={ productProps.fields.zmid && !isEmpty(productProps.fields.zmid)
                    ? productProps.fields.zmid[0]
                    : '' } />
                  <div className="group-sm">
                    <span className="locked-attribute" onClick={e => onRemoveLockedAttribute(e, 'zap_min_price')}>
                      { getLockIcon('zap_min_price', 'left') }
                    </span>
                    <span className="clear-both" />
                    <TextField
                        label="Zap Min Price"
                        variant="outlined"
                        size="small"
                        disabled={true}
                        inputProps={{
                          readOnly: true,
                        }}
                        className="block"
                        name="zapMinPrice"
                        value={ zapMinPrice }
                    />
                  </div>
                  <div className="group-sm">
                    <span className="locked-attribute" onClick={e => onRemoveLockedAttribute(e, 'zap_max_price')}>
                      { getLockIcon('zap_max_price', 'left') }
                    </span>
                    <span className="clear-both" />
                    <TextField
                        label="Zap Max Price"
                        variant="outlined"
                        size="small"
                        disabled={true}
                        inputProps={{
                          readOnly: true,
                        }}
                        className="block"
                        name="zapMaxPrice"
                        value={ zapMaxPrice }
                    />
                  </div>
                  <ZapStatus mappingData={
                    productProps && productProps.item && productProps.item.mappingData
                      ? productProps.item.mappingData
                      : []
                  } />
                </Fragment>
              </div>
            </div>
          )}
          {productIsParent && (
            <div className="attributes-fields">
              <div key="description" className="attributes-group-1">
                <h4 className="attribute-title"><IntlMessages id="preview.edit.label.description" /></h4>
                <div>&nbsp;</div>
                <Fragment>
                  {productLanguage.en && (
                    <>
                      <span className="locked-attribute" onClick={e => onRemoveLockedAttribute(e, 'description.en')}>
                        { getLockIcon('description.en', 'left') }
                      </span>
                      <span className="clear-both" />
                      <TextField
                        id="description"
                        name="description"
                        variant="outlined"
                        disabled={disabled}
                        inputProps={{
                          disabled,
                          readOnly: disabled,
                        }}
                        size="small"
                        fullWidth
                        label={<IntlMessages id="preview.edit.label.description.en" />}
                        multiline
                        rowsMax="12"
                        className="group-sm"
                        value={productProps.fields.description ? productProps.fields.description.en : ''}
                        onChange={onTextFieldChange}
                        helperText={(
                          productProps.errors.description && !isEmpty(productProps.errors.description)
                        )
                          ? productProps.errors.description.map(err => (
                            <IntlMessages key={err.type} id={err.message} />
                          ))
                          : null}
                      />
                    </>
                  )}
                  {productLanguage.he && (
                    <>
                      <br/><br/>
                      <span className="locked-attribute" onClick={e => onRemoveLockedAttribute(e, 'description.he')}>
                        { getLockIcon('description.he', 'left') }
                      </span>
                      <span className="clear-both" />
                      <TextField
                        id="description"
                        name="description"
                        variant="outlined"
                        disabled={disabled}
                        inputProps={{
                          disabled,
                          readOnly: disabled,
                        }}
                        size="small"
                        fullWidth
                        label={<IntlMessages id="preview.edit.label.description.he" />}
                        multiline
                        rowsMax="12"
                        className="group-sm"
                        value={productProps.fields.description ? productProps.fields.description.he : ''}
                        onChange={onHebrewTextFieldChange}
                      />
                    </>
                  )}
                </Fragment>
              </div>
              <div key="meta_tag_fields" className="attributes-group-1">
                <h4 className="attribute-title"><IntlMessages id="preview.edit.label.meta_tag_fields" /></h4>
                <div>&nbsp;</div>
                <Fragment>
                  {productLanguage.en && (
                    <>
                      <span className="locked-attribute" onClick={e => onRemoveLockedAttribute(e, 'meta_title.en')}>
                        { getLockIcon('meta_title.en', 'left') }
                      </span>
                      <span className="clear-both" />
                      <TextField
                        id="metaTitle"
                        name="metaTitle"
                        variant="outlined"
                        disabled={disabled}
                        inputProps={{
                          disabled,
                          readOnly: disabled,
                        }}
                        size="small"
                        fullWidth
                        label={<IntlMessages id="preview.edit.label.meta_title.en" />}
                        multiline
                        rowsMax="12"
                        className="group-sm"
                        value={productProps.fields.metaTitle ? productProps.fields.metaTitle.en : ''}
                        onChange={onTextFieldChange}
                      />
                    </>
                  )}
                  {productLanguage.he && (
                    <>
                      <br/><br/>
                      <span className="locked-attribute" onClick={e => onRemoveLockedAttribute(e, 'meta_title.he')}>
                        { getLockIcon('meta_title.he', 'left') }
                      </span>
                      <span className="clear-both" />
                      <TextField
                        id="metaTitle"
                        name="metaTitle"
                        variant="outlined"
                        disabled={disabled}
                        inputProps={{
                          disabled,
                          readOnly: disabled,
                        }}
                        size="small"
                        fullWidth
                        label={<IntlMessages id="preview.edit.label.meta_title.he" />}
                        multiline
                        rowsMax="12"
                        className="group-sm"
                        value={productProps.fields.metaTitle ? productProps.fields.metaTitle.he : ''}
                        onChange={onHebrewTextFieldChange}
                      />
                    </>
                  )}
                </Fragment>
                <Fragment>
                  {productLanguage.en && (
                    <>
                      <br/><br/>
                      <span className="locked-attribute" onClick={e =>
                          onRemoveLockedAttribute(e, 'meta_description.en')}>
                        { getLockIcon('meta_description.en', 'left') }
                      </span>
                      <span className="clear-both" />
                      <TextField
                        id="metaDescription"
                        name="metaDescription"
                        variant="outlined"
                        disabled={disabled}
                        inputProps={{
                          disabled,
                          readOnly: disabled,
                        }}
                        size="small"
                        fullWidth
                        label={<IntlMessages id="preview.edit.label.meta_description.en" />}
                        multiline
                        rowsMax="12"
                        className="group-sm"
                        value={productProps.fields.metaDescription ? productProps.fields.metaDescription.en : ''}
                        onChange={onTextFieldChange}
                      />
                    </>
                  )}
                  {productLanguage.he && (
                    <>
                      <br/><br/>
                      <span className="locked-attribute" onClick={e =>
                          onRemoveLockedAttribute(e, 'meta_description.he')}>
                        { getLockIcon('meta_description.he', 'left') }
                      </span>
                      <span className="clear-both" />
                      <TextField
                        id="metaDescription"
                        name="metaDescription"
                        variant="outlined"
                        disabled={disabled}
                        inputProps={{
                          disabled,
                          readOnly: disabled,
                        }}
                        size="small"
                        fullWidth
                        label={<IntlMessages id="preview.edit.label.meta_description.he" />}
                        multiline
                        rowsMax="12"
                        className="group-sm"
                        value={productProps.fields.metaDescription ? productProps.fields.metaDescription.he : ''}
                        onChange={onHebrewTextFieldChange}
                      />
                    </>
                  )}
                </Fragment>
                <Fragment>
                  {productLanguage.en && (
                    <>
                      <br/><br/>
                      <span className="locked-attribute" onClick={e => onRemoveLockedAttribute(e, 'meta_keywords.en')}>
                        { getLockIcon('meta_keywords.en', 'left') }
                      </span>
                      <span className="clear-both" />
                      <TextField
                        id="metaKeywords"
                        name="metaKeywords"
                        variant="outlined"
                        disabled={disabled}
                        inputProps={{
                          disabled,
                          readOnly: disabled,
                        }}
                        size="small"
                        fullWidth
                        label={<IntlMessages id="preview.edit.label.meta_keywords.en" />}
                        multiline
                        rowsMax="12"
                        className="group-sm"
                        value={productProps.fields.metaKeywords ? productProps.fields.metaKeywords.en : ''}
                        onChange={onTextFieldChange}
                      />
                    </>
                  )}
                  {productLanguage.he && (
                    <>
                      <br/><br/>
                      <span className="locked-attribute" onClick={e => onRemoveLockedAttribute(e, 'meta_keywords.he')}>
                        { getLockIcon('meta_keywords.he', 'left') }
                      </span>
                      <span className="clear-both" />
                      <TextField
                        id="metaKeywords"
                        name="metaKeywords"
                        variant="outlined"
                        disabled={disabled}
                        inputProps={{
                          disabled,
                          readOnly: disabled,
                        }}
                        size="small"
                        fullWidth
                        label={<IntlMessages id="preview.edit.label.meta_keywords.he" />}
                        multiline
                        rowsMax="12"
                        className="group-sm"
                        value={productProps.fields.metaKeywords ? productProps.fields.metaKeywords.he : ''}
                        onChange={onHebrewTextFieldChange}
                      />
                    </>
                  )}
                </Fragment>
              </div>
            </div>
          )}
          <div className="attributes-fields">
            <div key="system_values" className="attributes-group-1">
              <h4 className="attribute-title"><IntlMessages id="preview.edit.label.system_values" /></h4>
              <br/>
              <span className="locked-attribute" onClick={e => onRemoveLockedAttribute(e, 'content_source')}>
                  { getLockIcon('content_source', 'left') }
                </span>
              <span className="clear-both" />
              <Fragment>
                <TextField
                  id="contentSource"
                  name="contentSource"
                  variant="outlined"
                  disabled={ true }
                  inputProps={{
                    disabled: true,
                    readOnly: true,
                  }}
                  size="small"
                  fullWidth
                  label={(
                    <>
                      {
                        isLocked('content_source')
                        &&
                        <i className="zmdi icon-abs zmdi-lock left text-primary" />
                      }
                      <IntlMessages id="pim.dialog.contentSource.label" />
                    </>
                  )}
                  className="group-sm"
                  defaultValue={productProps.fields.contentSource}
                />
                <br/><br/>
                <span className="locked-attribute" onClick={e => onRemoveLockedAttribute(e, 'condition')}>
                    { getLockIcon('condition', 'left') }
                  </span>
                <span className="clear-both" />
                <Tooltip
                  title={productProps.fields.conditionNotes ? productProps.fields.conditionNotes : ''}
                  placement="top">
                  <TextField
                    id="condition"
                    name="condition"
                    variant="outlined"
                    disabled={ true }
                    inputProps={{
                      disabled: true,
                      readOnly: true,
                    }}
                    size="small"
                    fullWidth
                    label={(<IntlMessages id="pim.dialog.condition.label" />)}
                    className="group-sm"
                    value={productProps.fields.condition ? productProps.fields.condition : 'N/A'}
                  />
                </Tooltip>
                <br/><br/>
                <Field
                  hideStaticLabel
                  hideFormErrors
                  element="dropdown"
                  id="visibilityStatus"
                  name="visibilityStatus"
                  fullWidth
                  label={(<IntlMessages id="pim.dialog.visibilityStatus.label" />)}
                  variant="outlined"
                  className={"input-wrapper min-w-sm input-bordered form-group input-select field-white"}
                  prefixClassName="prefix-abs"
                  value={productProps.fields.visibilityStatus || ''}
                  onChange={onTextFieldChange}
                  options={defaultVisibilityStatusOptions}
                  style={getLockIcon('visibility_status', 'left') ? {'margin-top': '20px'} : {}}
                />
                <span className="clear-both" />
                <span
                  className="locked-attribute locked-attribute-status"
                  onClick={e => onRemoveLockedAttribute(e, 'visibility_status')}>
                    { getLockIcon('visibility_status', 'left') }
                  </span>
                <span className="clear-both" />
                {pimStatusField && (
                  <>
                    <Field
                      hideStaticLabel
                      hideFormErrors
                      element="dropdown"
                      id="pimStatus"
                      name="pimStatus"
                      fullWidth
                      label={(<IntlMessages id="pim.dialog.pimStatus.label" />)}
                      variant="outlined"
                      className={"input-wrapper min-w-sm input-bordered form-group input-select field-white"}
                      prefixClassName="prefix-abs"
                      value={getPimStatus(productProps.fields.pimStatus) || pimStatusField.options
                        .find(pmf => pmf.value === 'not_reviewed').value
                      }
                      onChange={onTextFieldChange}
                      options={pimStatusField.options}
                    />
                    <span className="clear-both" />
                    <span
                      className="locked-attribute locked-attribute-status"
                      onClick={e => onRemoveLockedAttribute(e, 'pim_status')}>
                        { getLockIcon('pim_status', 'left') }
                      { productProps.item.pimStatus && productProps.item.pimStatus.reviewedBy && (
                        <Tooltip
                          className="pim-status-label"
                          title={`Reviewed by: ${productProps.item.pimStatus.reviewedBy}`}
                          placement="top-start"
                          arrow
                        >
                          <Badge className="pim-status-label" color="error" variant="dot">
                            &nbsp;
                          </Badge>
                        </Tooltip>
                      )}
                      </span>
                  </>
                )}
              </Fragment>
            </div>
          </div>
        </div>
      </div>

    </>
  );
});

DetailContainer.propTypes = {
  className: string,
  productAttributeProps: shape().isRequired,
  productProps: shape().isRequired,
  productLanguage: shape().isRequired,
  specificationErrors: shape().isRequired,

  disabled: bool,
  searchAttributeOptions: func.isRequired,
  fetchInitialAttributeOptions: func.isRequired,
  clearFoundAttributeOptions: func.isRequired,
  setSelectedAttributeOption: func.isRequired,
  setFields: func.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  fetchProductAttributesForVariations: func.isRequired,
  sendEnrichmentRetriggerData: func.isRequired,
};

DetailContainer.defaultProps = {
  className: '',
  disabled: false,
};

const mapStateToProps = state => ({
  productAttributeProps: productAttributeSelector(state.productAttribute),
  productProps: productSelector(state.product),
  productLanguage: state.languageManager.productLanguage,
});

const actionCreators = {
  searchAttributeOptions,
  fetchInitialAttributeOptions,
  clearFoundAttributeOptions,
  setSelectedAttributeOption,
  setFields,
  fetchActivityLogsByProductId,
  fetchProductAttributesForVariations,
  sendEnrichmentRetriggerData,
}

export default withRouter(connect(mapStateToProps, actionCreators)(DetailContainer));
