import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import SignIn from './SignIn';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';
import RctThemeProvider from '../../container/RctThemeProvider';

import {
  signinAppPath,
  forgotPasswordPath,
  changePasswordPath,
} from './utils/paths';

export default () => (
  <RctThemeProvider>
    <NotificationContainer />
    <Router>
      <Switch>
        <Route exact path={signinAppPath} component={SignIn} />
        <Route exact path={forgotPasswordPath} component={ForgotPassword} />
        <Route exact path={changePasswordPath} component={ChangePassword} />
      </Switch>
    </Router>
  </RctThemeProvider>
);
