import { isEmpty } from 'lodash';
import { setIsLoading } from './global';
import { PMACClient, showErrorNotification } from '../util/api';

import {
  PIM_SET_MAPPER_DATA,
} from './types';

import { downLoadFile, getResultCondition, mappingCategoriesResult } from "./pim";
import { NotificationManager } from "react-notifications";

export const mappingNewResult = (data) => PMACClient.post('/products_mapping',{ data });
export const mappingCopyResult = (data, id) => PMACClient.post(`/products_mapping/${id}/copy`,{ data });
export const mappingSaveResult = (data) => PMACClient.put('/products_mapping/bulk',{ data });
export const mappingResult = (data) => PMACClient.post('/products_mapping/search', data);
export const mappingAssign = (data) => PMACClient.post('/products_mapping/assign', data);
export const mappingExport = (data) => PMACClient.post('/products_mapping/export', data);

export const getMappingData = (
  { limit, offset, filter = null, sort = null, fields, reset = false, marketplace, categories }
) => async (dispatch) => {
  dispatch(setIsLoading(true));
  dispatch({
    type: PIM_SET_MAPPER_DATA,
    payload: {
      mapper: {
        itemsPerPage: limit,
        error: {},
        reset: reset
      }
    }
  });

  try {
    const condition = getResultCondition(filter, sort, limit, offset);
    if (fields) {
      condition['fields'] = fields;
    }
    const mapperCondition  = getMapperCondition(condition, marketplace, categories);

    const res = await mappingResult(mapperCondition);
    const { data } = res.data;

    dispatch({
      type: PIM_SET_MAPPER_DATA,
      payload: {
        mapper: {
          data: data,
          scroll: res.data.scroll,
          condition: condition,
          reset: reset
        }
      }
    });
  } catch (error) {
    showErrorNotification(error, 'PMAC');

    dispatch({
      type: PIM_SET_MAPPER_DATA,
      payload: {
        mapper: {
          data: [], scroll: [], reset: false,
        }
      }
    });
  }
  dispatch(setIsLoading(false));
};

export const saveMappingData = ({ savingData, allData, errorData }) => async (dispatch) => {
  dispatch(setIsLoading(true));
  let error = false;
  for (let i = 0; i < errorData.length; i++) {
    const errorKeys = Object.keys(errorData[i]);
    for (let j = 0; j < errorKeys.length; j++) {
      if (errorData[i][errorKeys[j]]) {
        error = true;

        break;
      }
    }
  }

  if (error === true) {
    dispatch({
      type: PIM_SET_MAPPER_DATA,
      payload: {
        mapper: {
          data: allData,
          error: errorData
        }
      }
    });
  } else {
    dispatch({
      type: PIM_SET_MAPPER_DATA,
      payload: {
        mapper: {
          data: allData,
          error: {}
        }
      }
    });

    try {
      const res = await mappingSaveResult(savingData);
      const { error } = res.data;

      if (!isEmpty(error)) {
        const errorKeys = Object.keys(error);
        for (let i = 0; i < errorKeys.length; i++) {
          NotificationManager.warning(error[errorKeys[i]].message, '', 0);
        }
      } else {
        NotificationManager.success('The data was saved successfully');
      }
    } catch (error) {
      showErrorNotification(error, 'PMAC');
    }
  }
  dispatch(setIsLoading(false));
};

export const getExportToFMS = ({filter, sort, marketplace}) => async () => {
  try {
    const condition = getResultCondition(filter, sort);
    const marketplaceCondition  = getMapperCondition(condition, marketplace);

    const res = await mappingExport(marketplaceCondition);
    downLoadFile(res.data, 'export.csv');
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const addMappingData = ({ savingData, id = null }) => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    if (id === null) {
      await mappingNewResult(savingData);
    } else {
      await mappingCopyResult(savingData, id);
    }
    NotificationManager.success('The data was saved successfully');
  } catch (error) {
    showErrorNotification(error, 'PMAC');
  }
  dispatch(setIsLoading(false));
};

export const getMappingCondition = (
  { limit, offset, filter = null, sort = null, reset = false }
) => async (dispatch) => {
  const condition = getResultCondition(filter, sort, limit, offset);
  dispatch({
    type: PIM_SET_MAPPER_DATA,
    payload: {
      mapper: {
        condition: condition,
        reset: reset
      },
      repricing: {
        condition: condition,
        reset: reset
      }
    }
  });
};

export const getMappingNewRecord = ({ open }) => async (dispatch) => {
  dispatch({
    type: PIM_SET_MAPPER_DATA,
    payload: {
      mapper: {
        newRecordOpen: open
      }
    }
  });
};

export const setMappingFilterState = ({ disabled }) => async (dispatch) => {
  dispatch({
    type: PIM_SET_MAPPER_DATA,
    payload: {
      mapper: {
        filterDisabled: disabled,
      }
    }
  });
};

export const getMappingCategories = (marketplace) => async (dispatch) => {
  try {
    const res = await mappingCategoriesResult(marketplace.replace('.co.il', ''));
    const { data } = res.data;

    dispatch({
      type: PIM_SET_MAPPER_DATA,
      payload: { mapper: { categories: data } }
    });
  } catch (error) {
    showErrorNotification(error, 'PMAC');
  }
};

const getMapperCondition = (condition, marketplace, categories = []) => {
  const marketplaceFilter = {
    group: [ { field: 'marketplace', value: marketplace.replace('.co.il', ''), operator: 'eq' } ]
  }

  if (condition.filter) {
    condition.filter[condition.filter.length] = marketplaceFilter;
  } else {
    condition.filter = [marketplaceFilter];
  }

  if (categories.length > 0) {
    const mapperCategories = Object.keys(categories).map(Number);
    const filteredMapperCategories = mapperCategories.filter(function (el) {
      return el !== null && !isNaN(el);
    });

    condition.filter[condition.filter.length] = {
      group: [{ field: 'categories', value: filteredMapperCategories, operator: 'in' }]
    };
  }

  return condition;
};
