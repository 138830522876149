import React from 'react';
import TextField from '@material-ui/core/TextField';
import {
  func, string, oneOfType, number, node, bool, object, array,
} from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';

import InputErrors from '../InputErrors';

export const InputField = ({
  onChange, className, value, placeholder, name,
  wrapperClassName, errors, error, id, type, helperText,
  label, prefix, suffix, fullWidth, disabled, onFocus, onBlur, variant,
  size,
}) => {
  const checkValue = (e) => {
    const { target } = e;
    if (target.type === 'number') {
      target.value = target.value.replace('-', '');

      if (target.value.startsWith('.') || target.value.startsWith(',')) {
        target.value = `0${target.value}`;
      }
    }
    onChange(e);
    if (onBlur) onBlur();
  };
  const suffixClassName = suffix ? 'select-wrapper-suffix' : '';
  const prefixClassName = prefix ? 'select-wrapper-prefix' : '';
  const fullWidthClassName = fullWidth ? 'block' : '';

  return (
    <div
      className={`search-wrapper flex ${wrapperClassName} ${fullWidthClassName}`}
    >
      {prefix && <div className="select-prefix">{prefix}</div>}
      <TextField
        fullWidth={fullWidth}
        type={type}
        label={label}
        className={`text-input-lg ${suffixClassName} ${prefixClassName} ${className}`}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={checkValue}
        value={value}
        name={name}
        id={id}
        disabled={disabled}
        onFocus={onFocus}
        variant={variant}
        size={size}
      />
      {suffix && <div className="select-suffix">{suffix}</div>}
      {helperText && <FormHelperText><span className="text-danger">{helperText}</span></FormHelperText>}
      {(error[name] || errors[name])
        && !Array.isArray(errors)
        && <InputErrors errors={error[name] || errors[name]} />}
      {Array.isArray(errors) && <InputErrors errors={errors} />}
    </div>
  );
};

InputField.propTypes = {
  fullWidth: bool,
  onChange: func,
  className: string,
  placeholder: string,
  value: oneOfType([string, number]),
  name: string,
  wrapperClassName: string,
  helperText: oneOfType([string, array, object]),
  errors: oneOfType([object, array]),
  error: oneOfType([object, string, bool]),
  id: oneOfType([string, number]),
  label: oneOfType([node, string]),
  prefix: oneOfType([node, string]),
  suffix: oneOfType([node, string]),
  type: string,
  variant: string,
  size: string,
  disabled: bool,
  onFocus: func,
  onBlur: func,
};

InputField.defaultProps = {
  fullWidth: false,
  onChange: null,
  className: '',
  placeholder: '',
  value: '',
  name: '',
  wrapperClassName: '',
  errors: {},
  error: {},
  id: '',
  variant: 'standard',
  size: 'medium',
  prefix: null,
  suffix: null,
  label: null,
  type: 'text',
  disabled: false,
  helperText: '',
  onFocus: null,
  onBlur: null,
};

export default InputField;
