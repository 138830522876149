/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useAttributeMatrix = ({
  attributesMatrixFetched,
  attributesMatrixFetching,
  fetchListAttributesMatrix,
}) => {
  useEffect(() => {
    if (!attributesMatrixFetched && !attributesMatrixFetching) {
      fetchListAttributesMatrix();
    }
  }, []);
};

export default useAttributeMatrix;
