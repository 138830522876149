import React, { Fragment } from 'react';
import {
  func, arrayOf, string, shape, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { Button, Badge } from '@material-ui/core';
import SearchForm from '../../../../components/Header/SearchForm';
import ListVertical from '../../../../components/List/ListVertical';
import IntlMessages from '../../../../util/IntlMessages';
import Can from '../../../../components/Can';
import appPermissions from '../../../../util/appPermissions';
import ExpansionPanel from '../../../../components/ExpansionPanel';

const Sidebar = (props) => {
  const {
    navigationList, onSelectListItem, handleGetPrevPage, handleGetNextPage, navigateToSearchFilter,
    groupList, hideTitle, isNavigationsDisabled, search, errors, pagination,
    handleRightClick,
  } = props;

  const getPanels = () => {
    if (!isEmpty(groupList)) {
      return groupList.map(group => ({
        titleId: group.titleId,
        title: (
          <div className="h5 sidebar-title">
            <IntlMessages id={group.titleId} />
          </div>
        ),
        badge: <Badge color="primary" badgeContent={pagination.navigation.allPages * pagination.paginationLimit} />,
        details: (
          <ListVertical
            titleId={group.titleId}
            disabled={isNavigationsDisabled}
            handleRightClick={handleRightClick}
            gap={8}
            searchValue={search.value}
            pagination={pagination}
            handleGetPrevPage={handleGetPrevPage}
            handleGetNextPage={handleGetNextPage}
            className="sidebar-list-item"
            onClick={onSelectListItem}
            list={group.list && !isEmpty(group.list)
              ? group.list.map(l => ({
                id: l.id,
                label: l.name,
              }))
              : []}
          />
        ),
      }));
    }
    return [];
  };

  return (
    <Fragment>
      {!hideTitle && (
        <div className="h5 sidebar-title">
          <IntlMessages id="sidebar.searchFilterTitle" />
        </div>
      )}
      <div className="sidebar-group mt">
        <div className="list-inline-item search-icon d-inline-block">
          <SearchForm
            onChange={search.onChange}
            className="block"
            error={errors.search}
            value={search.value}
          />
        </div>
      </div>
      <Can
        do={appPermissions.navigation.permissions.create}
        on={appPermissions.navigation.name}
      >
        <div className="sidebar-group sidebar-group-fixed">
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="text-white block"
            onClick={navigateToSearchFilter}
          >
            <IntlMessages id="button.newSearchFilter" />
          </Button>
        </div>
      </Can>
      <div className="rct-scroll rct-scroll-expansion search-expansion">
        <div className="sidebar-group">
          <div className="sidebar-list">
            {navigationList && !isEmpty(navigationList) && (
              <ListVertical
                disabled={isNavigationsDisabled}
                handleRightClick={handleRightClick}
                gap={8}
                searchValue={search.value}
                pagination={pagination}
                handleGetPrevPage={handleGetPrevPage}
                handleGetNextPage={handleGetNextPage}
                className="sidebar-list-item"
                onClick={onSelectListItem}
                list={navigationList && !isEmpty(navigationList)
                  ? navigationList.map(l => ({
                    id: l.id,
                    label: l.name,
                  }))
                  : []}
              />
            )}
            <ExpansionPanel
              className="expansion-secondary"
              panels={getPanels()}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Sidebar.propTypes = {
  navigateToSearchFilter: func.isRequired,
  onSelectListItem: func,
  handleGetPrevPage: func,
  handleGetNextPage: func,
  search: shape({
    value: string,
    onChange: func,
  }),
  groupList: arrayOf(shape({
    label: string,
    list: arrayOf(shape({
      id: string,
      name: string,
    })),
  })),
  navigationList: arrayOf(shape({
    id: string,
    name: string,
  })),
  hideTitle: bool,
  isNavigationsDisabled: bool,
  errors: shape(),
  handleRightClick: func,
};

Sidebar.defaultProps = {
  search: {
    value: '',
    onChange: null,
  },
  onSelectListItem: null,
  handleGetPrevPage: null,
  handleGetNextPage: null,
  navigationList: [],
  groupList: [],
  hideTitle: false,
  isNavigationsDisabled: true,
  errors: {},
  handleRightClick: null,
};

export default Sidebar;
