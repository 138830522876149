/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  memo, useState, Fragment, useEffect,
} from 'react';
import {
  bool, func, shape, string, arrayOf,
} from 'prop-types';
import { isEmpty } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import Field from '../../../../components/Field';
import FormDialog from '../../../../components/FormDialog';
import IntlMessages from '../../../../util/IntlMessages';
import entityTypes from '../../../../util/entityTypes';
import {
  commentValidations,
  systemCommentValidations,
} from './utils/validations';
import validateAll from '../../../../util/validator';

const UnlockAttributesDialog = memo((props) => {
  const {
    onUnlockAttributesClick,
    isOpened,
    onClose,
    commentMessages,
    bulkDeleteProductLockedAttributesFinished,
    onCommentsUpdate,
    product,
  } = props;

  const [comment, setComment] = useState('');
  const [updating, setUpdating] = useState(false);
  const [systemComment, setSystemComment] = useState('');
  const [errors, setErrors] = useState({});

  const updateComments = () => {
    const updatedActivityLogs = [{
      affectedIds: [product.id],
      entityType: entityTypes.catalogProductAttribute,
      comment: `${systemComment}@${comment}`,
    }];
    onCommentsUpdate(updatedActivityLogs);
    setComment('');
    setSystemComment('');
    onClose();
  };

  useEffect(() => {
    if (bulkDeleteProductLockedAttributesFinished && updating) {
      setUpdating(false);
      updateComments();
    }
  }, [bulkDeleteProductLockedAttributesFinished]);

  const handleChangeComment = e => setComment(e.target.value);

  const handleChangeSystemComment = e => setSystemComment(e.target.value);

  const validateComments = () => {
    let hasErrors = false;
    const commentErrors = validateAll({
      ...commentValidations, ...systemCommentValidations,
    }, { comment, systemComment });

    if (!isEmpty(commentErrors)) {
      hasErrors = true;
      setErrors(commentErrors);
    } else {
      setErrors({});
    }
    return hasErrors;
  };

  const unlockAttributes = () => {
    const hasErrors = validateComments();
    if (hasErrors) { return; }
    onUnlockAttributesClick();
    setUpdating(true);
  };

  const closeUnlockAttributesDialog = () => {
    setComment('');
    setSystemComment('');
    onClose();
  };

  return (
    <FormDialog
      title={<IntlMessages id="pim.unlockAttributesDialog.title" />}
      open={isOpened}
      hideCloseButton={false}
      className="dialog-inputs-custom dialog-content-visible"
      closeButtonTitle="Close"
      submitButtonTitle="Submit"
      maxWidth="xs"
      onClose={closeUnlockAttributesDialog}
      onSubmit={unlockAttributes}
    >
      <Fragment>
        <Field
          type="dropdown"
          element="autocomplete"
          onChange={handleChangeSystemComment}
          options={commentMessages.map(cm => ({
            ...cm, label: cm.title, value: cm.title,
          }))}
          value={systemComment
            ? { label: systemComment, value: systemComment }
            : ''}
          className={
            `select-wrapper select-bordered top-oriented min-w-sm form-group block ml-0
          `}
          hideStaticLabel
          searchable
          menuPlacement="top"
          emptyPlaceholder="System comment"
          name="System comment"
          key="system-comment"
          label="System comment"
          suffixClassName="suffix-abs"
          suffix={!isEmpty(errors.systemComment) && (
            <Tooltip
              placement="top-end"
              title={(
                <div className="tooltip-title-content-wrapper flex direction-column">
                  {errors.systemComment.map(e => <IntlMessages id={e.message} key={e.message} />)}
                </div>
              )}
            >
              <i className="ti-info-alt" />
            </Tooltip>
          )}
          inputProps={{
            name: 'System comment',
            id: 'system-comment-select',
          }}
        />
        <Field
          element="text"
          onChange={handleChangeComment}
          value={comment}
          className={`min-w-sm input-bordered form-group input-text
          `}
          emptyPlaceholder="Comment"
          name="comment"
          key="comment"
          label="Comment"
          suffix={!isEmpty(errors.comment) && (
            <Tooltip
              placement="top-end"
              title={(
                <div className="tooltip-title-content-wrapper flex direction-column">
                  {errors.comment.map(e => <IntlMessages id={e.message} key={e.message} />)}
                </div>
              )}
            >
              <i className="ti-info-alt" />
            </Tooltip>
          )}
        />
      </Fragment>
    </FormDialog>
  );
});

UnlockAttributesDialog.propTypes = {
  onUnlockAttributesClick: func.isRequired,
  onClose: func.isRequired,
  isOpened: bool.isRequired,
  commentMessages: arrayOf(shape({
    title: string,
  })).isRequired,
  bulkDeleteProductLockedAttributesFinished: bool,
  onCommentsUpdate: func.isRequired,
  product: shape({
    id: string,
  }).isRequired,
};

UnlockAttributesDialog.defaultProps = {};

export default UnlockAttributesDialog;
