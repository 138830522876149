/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

export const useAttributesMatrix = ({
  attributesOptions,
  attributesMatrixFetched,
  attributesMatrixList,
  setAttributesMatrixAttributes,
  navigationFetched,
  fetchedAttributeOptions,
  fetchedAttributesOptions,
  attributesMatrixAttributes,
}) => {
  const getAttributeOptions = (attr) => {
    const attribute = attributesOptions.find(ao => ao.code === attr);
    return attribute ? attribute.options : [];
  };

  const mapAttributesOptions = attributes => attributes.map((a) => {
    if ((a.frontendInputType === 'select' || a.frontendInputType === 'dropdown') && isEmpty(a.options)) {
      return {
        ...a,
        options: getAttributeOptions(a.field),
      };
    }
    return a;
  });

  useEffect(() => {
    if (attributesMatrixFetched) {
      const updatedAttributes = [
        ...attributesMatrixList,
      ];
      const updatedList = mapAttributesOptions(updatedAttributes);
      setAttributesMatrixAttributes(updatedList);
    }
  }, [attributesMatrixFetched, navigationFetched]);

  useEffect(() => {
    if (fetchedAttributeOptions || fetchedAttributesOptions) {
      const updatedList = mapAttributesOptions(attributesMatrixAttributes);
      setAttributesMatrixAttributes(updatedList);
    }
  }, [
    fetchedAttributeOptions,
    fetchedAttributesOptions,
  ]);
};

export default useAttributesMatrix;
