import React from 'react';
import {
  bool, func,
} from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IntlMessages from '../../../../util/IntlMessages';
import MapperCategories from "../../../Mapper/components/MapperCategories";

const MapperCategoriesDialog = (props) => {
  const {
    open, onClose,
  } = props;

  const handleOk = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"><IntlMessages id="text.mapperCategories" /></DialogTitle>
        <DialogContent>
          <MapperCategories
            mapperCategories={props.mapperCategories}
            mapperMarketplace={props.mapperMarketplace}
            selectedCategories={props.selectedCategories}
            setSelectedCategories={props.setSelectedCategories}
            openedCategories={props.openedCategories}
            setOpenedCategories={props.setOpenedCategories}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOk} color="primary">
            <IntlMessages id="button.save" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

MapperCategoriesDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
};

export default MapperCategoriesDialog;
