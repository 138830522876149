import React from 'react';
import {
  func, shape, bool, node,
} from 'prop-types';

import Form from '../../../../components/Form';

const AttributesRow = ({
  values, errors, disabled, onChange, onSlide,
  dataSource, content,
}) => (
  <div className="attributes-row flex items-center">
    <Form
      className="form-compact flex-1"
      onChange={onChange}
      gap={0}
      fields={[
        [{
          element: 'select',
          label: 'Attributes',
          className: 'select-wrapper inner-label-hide',
          name: 'field',
          inputProps: {
            name: 'field',
          },
          value: values.field || '',
          errors: errors.field || [],
          disabled,
          options: [...dataSource.options],
          displayEmpty: true,
          hideFormErrors: true,
        }, {
          element: 'select',
          label: 'Normalization',
          className: 'select-wrapper inner-label-hide',
          name: 'normalization',
          inputProps: {
            name: 'normalization',
          },
          value: values.normalization || '',
          errors: errors.normalization || [],
          disabled,
          options: dataSource.normalizationList ? dataSource.normalizationList : [],
          displayEmpty: true,
          hideFormErrors: true,
        }, {
          element: 'select',
          label: 'Pushing',
          className: 'select-wrapper inner-label-hide',
          name: 'pushing',
          inputProps: {
            name: 'pushing',
          },
          value: values.pushing || '',
          errors: errors.pushing || [],
          disabled,
          options: dataSource.pushingList ? dataSource.pushingList : [],
          displayEmpty: true,
          hideFormErrors: true,
        }, {
          element: 'slider',
          label: (
            <div className="flex justify-between">
              <span>Weight</span>
              <b>
                Total:
                <span className={`ml-5 ${dataSource.totalWeigt === 100 ? 'text-success' : 'text-danger'}`}>
                  {`${dataSource.totalWeigt}%`}
                </span>
              </b>
            </div>
          ),
          name: 'slider',
          className: 'slider-wrapper slider-progress',
          sliderClassName: 'slider-inner',
          onChange: onSlide,
          value: values.weight,
          errors: errors.weight || [],
          disabled,
        }],
      ]}
    />
    {content}
  </div>
);

AttributesRow.propTypes = {
  disabled: bool,
  onChange: func,
  onSlide: func,
  values: shape(),
  errors: shape(),
  dataSource: shape(),
  content: node,
};

AttributesRow.defaultProps = {
  disabled: false,
  onChange: null,
  onSlide: null,
  values: {},
  errors: {},
  dataSource: {},
  content: null,
};

export default AttributesRow;
