import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const promotionWidgetRootSelector = state => state;
const promotionWidgetSelectedPromotionSelector = state => state.selectedPromotion;


const mapProductsOverride = (virtualParams) => {
  if (!virtualParams) return {};
  return ({
    ...virtualParams,
    productsOverride: virtualParams.positions
      ? Object.keys(virtualParams.positions)
        .map(p => ({ productId: p, position: virtualParams.positions[p] }))
      : [],
  });
};

const selectedPromotionSelector = createSelector(
  promotionWidgetSelectedPromotionSelector,
  selectedPromotion => (!isEmpty(selectedPromotion) ? ({
    ...selectedPromotion,
    virtualParams: mapProductsOverride(selectedPromotion.virtualParams),
  }) : {}),
);

const stateSelector = createSelector(
  promotionWidgetRootSelector,
  state => ({
    selectedPromotion: state.selectedPromotion,
  }),
);

export const promotionWidgetSelector = createSelector(
  stateSelector,
  selectedPromotionSelector,
  (state, selectedPromotion) => ({
    ...state,
    selectedPromotion,
  }),
);

export default {
  promotionWidgetSelector,
};
