/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import config from '../../../../../../util/config';
import {
  func, shape, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { CircularProgress } from '@material-ui/core';

import GridSelect from './components/GridSelect';
import ProductsPagination from '../ProductsPagination';
import Can from '../../../../../../components/Can';
import appPermissions from '../../../../../../util/appPermissions';
import ListActionBar from '../../../../components/ListActionBar';
import ProductContainer from '../../../../containers/Product';
import IntlMessages from '../../../../../../util/IntlMessages';
import ProductDialog from '../../../../../../components/ProductDetail';
import PagePlaceholder from '../../../components/PagePlaceholder';
import ProductManagement from './components/ProductManagement';
import ProductActions from './components/ProductActions';
import BadgeLabel from '../../../../../../components/BadgeLabel';
import PreviewSearch from '../../../../components/PreviewSearch';

import {
  setPreviewListDirection,
  setContainerStyles,
  setPagination,
  fetchProductList,
} from '../../../../../../actions/preview';

import {
  mapPrefixToFilters,
} from '../../../../../../util/filterMapper';

import {
  previewSelector,
} from './selectors/previewSelector';
import {
  productSelector,
} from './selectors/productSelector';
import {
  virtualCategoryWidgetSelector,
} from '../../../../selectors/virtualCategoryWidgetSelector';
import {
  promotionWidgetSelector,
} from '../../../../selectors/promotionWidgetSelector';

const MainContent = memo((props) => {
  const {
    previewProps,
    promotionFetching,
    searchFetching,
    productProps,
    virtualCategoryWidgetProps,
    promotionWidgetProps,
  } = props;

  const getEntityToUse = () => {
    const { selectedSearchFilter } = previewProps;
    let entityToUse = {};

    if (!isEmpty(virtualCategoryWidgetProps.selectedCategory)) {
      entityToUse = virtualCategoryWidgetProps.selectedCategory;
    }
    if (!isEmpty(promotionWidgetProps.selectedPromotion)) {
      entityToUse = promotionWidgetProps.selectedPromotion;
    }
    if (!isEmpty(selectedSearchFilter)) entityToUse = selectedSearchFilter;
    return entityToUse;
  };

  const selectedEntity = getEntityToUse();

  const isActionBarActive = previewProps.displayMode === 'list' && previewProps.isEditMode && !isEmpty(previewProps.selected);
  const temporaryDisabled = true;

  const getVirtualFacets = () => {
    const virtualFacets = {
      filters: previewProps.virtualFacetFilter,
    };
    return virtualFacets;
  };

  useEffect(() => {
    if (previewProps.displayMode.includes('_per_row') && previewProps.dir === 'ltr') {
      props.setPreviewListDirection('rtl');
    }
    if (!previewProps.displayMode.includes('_per_row') && previewProps.dir !== 'ltr') {
      props.setPreviewListDirection('ltr');
    }
  }, [previewProps.dir, props, previewProps.displayMode]);

  useEffect(() => {
    if (!isActionBarActive && previewProps.containerStyles.height) {
      props.setContainerStyles({
        height: 'calc(100vh - 124px)',
      });
    }
  }, [isActionBarActive]);

  const handleGlobalPagination = (updatedPagination) => {
    props.fetchProductList({
      viewMode: 'frontendWithParams',
      filter: mapPrefixToFilters(previewProps.facetsPreFilters),
      compoundSearch: previewProps.compoundSearch,
      pagination: updatedPagination,
      searchFilter: previewProps.searchQuery,
      virtualFacets: getVirtualFacets(),
    });
  };

  const onPaginate = ({ selected }) => {
    const updatedPagination = {
      ...previewProps.pagination,
      page: selected + 1,
    };

    props.setPagination(updatedPagination);
    handleGlobalPagination(updatedPagination);
  };

  const productListEmpty = previewProps.list
    && isEmpty(previewProps.list)
    && !previewProps.fetching
    && !previewProps.fetchingSelectedCategory;

  const showProductManagement = !isEmpty(promotionWidgetProps.selectedPromotion)
    || !isEmpty(previewProps.selectedSearchFilter)
    || !isEmpty(virtualCategoryWidgetProps.selectedCategory);

  const productContainerLocalClassName = isActionBarActive ? 'action-bar-on' : 'action-bar-off';

  const loading = promotionFetching || searchFetching;

  const productContainerClassName = previewProps.isEditMode ? 'editable' : '';

  const selCat = !isEmpty(virtualCategoryWidgetProps.selectedCategory) ?
    virtualCategoryWidgetProps.selectedCategory :
    null;

  const linktoFE = !isEmpty(selCat) ? config.websitePath + '/' + selCat.urlPath + '/' + selCat.id : '';
  const linktoFEName = !isEmpty(selCat) ? selCat.name.en : '';
  const linktoFEShortName = linktoFEName.length > 30 ? linktoFEName.substring(0, 29) + '...' : linktoFEName;

  return (
    <div className="full-height pos-rel">
      {loading && <div className="loader-main"><CircularProgress size={30} /></div>}
      <div className="product-heading product-header flex justify-between items-center">
        <div className="product-header-left justify-start flex">
          {`${!previewProps.total ? 0 : previewProps.total} Items`}
          {showProductManagement && (
            <Can
              do={appPermissions.preview.permissions.update}
              on={appPermissions.preview.name}
            >
              <ProductManagement isHiddenProductsMode={previewProps.isHiddenProductsMode} />
            </Can>
          )}
        </div>

        <a target="_blank"
           title={linktoFEName}
           rel="noopener noreferrer"
           href={linktoFE}
           className="no-wrap">{linktoFEShortName}</a>

        <div className="product-header-middle">
          {!isEmpty(selectedEntity)
            && selectedEntity.virtualParams
            && selectedEntity.virtualParams.rankingRuleCode && (
            <div className="search-prefix">
              <BadgeLabel
                label={<IntlMessages id="preview.productList.header.badge.rankingRuleCode.label" />}
                value={selectedEntity.virtualParams.rankingRuleCode}
                useTooltip
                limit={15}
              />
            </div>
          )}
          <PreviewSearch />
          <GridSelect flexAuto />
        </div>
        {!isEmpty(previewProps) && (
          <div className="product-header-right">
            <ProductsPagination
              flexAuto
              onPaginate={onPaginate}
              total={previewProps.total}
              pages={previewProps.pages}
              pagination={previewProps.pagination}
            />
          </div>
        )}
      </div>

      {isActionBarActive && (
        <Can
          do={appPermissions.preview.permissions.update}
          on={appPermissions.preview.name}
        >
          <ListActionBar />
        </Can>
      )}

      <ProductContainer
        className={productContainerClassName}
        wrapperClassName={`${productContainerLocalClassName} ${previewProps.dir}`}
      />

      {previewProps.isEditMode && (
        <ProductActions />
      )}

      {
        productProps.editInProgress && (
          <ProductDialog
            disabled={temporaryDisabled}
            containerClassName="dialog-view-mode"
            productList={previewProps.list}
          />
        )
      }
      <PagePlaceholder
        displayMode={previewProps.displayMode}
        fetchingList={previewProps.fetching}
        fetchedList={previewProps.fetched}
        productListEmpty={productListEmpty}
        selectedEntity={selectedEntity}
      />
    </div>
  );
});

MainContent.propTypes = {
  promotionFetching: bool.isRequired,
  searchFetching: bool.isRequired,
  setPreviewListDirection: func.isRequired,
  setContainerStyles: func.isRequired,
  fetchProductList: func.isRequired,
  setPagination: func.isRequired,
  previewProps: shape().isRequired,
  productProps: shape().isRequired,

  virtualCategoryWidgetProps: shape().isRequired,
  promotionWidgetProps: shape().isRequired,
};

MainContent.defaultProps = {};

const mapStateToProps = state => ({
  promotionFetching: state.promotion.fetching,
  searchFetching: state.search.fetching,
  virtualCategoryWidgetProps: virtualCategoryWidgetSelector(state.virtualCategoryWidget),
  promotionWidgetProps: promotionWidgetSelector(state.promotionWidget),
  previewProps: previewSelector(state.preview),
  productProps: productSelector(state.product),
});

const mapDispatchToProps = {
  setPreviewListDirection,
  setContainerStyles,
  setPagination,
  fetchProductList,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContent);
