/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  func, node, arrayOf, shape, string, bool, number, object, oneOfType,
} from 'prop-types';
import { isEmpty, uniqBy } from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Badge from '@material-ui/core/Badge';
import { DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { Form } from 'reactstrap';
import TransferListComponent from '../TransferList';
import IntlMessages from '../../util/IntlMessages';
import SearchGroup from '../Search/SearchGroup';
import RightSidePagination from './components/RightSidePagination';

const SelectProductsButton = (props) => {
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
  });

  useEffect(() => {
    if (open) {
      if (
        props.productsFilterKey
        && !isEmpty(props.selectedProductsIds)
      ) {
        props.fetchProductsByIds(
          props.selectedProductsIds.slice(0, pagination.limit),
          props.productsFilterKey,
          pagination,
        );
      }
    }
  }, [open]);

  useEffect(() => {
    if (
      !isEmpty(props.targetList)
      && open
      && !props.completingCategoryProductsList
      && !props.fetchProductsByIdsStarted
    ) {
      const listToCheck = props.targetList.slice(
        (pagination.page - 1) * 100,
        pagination.page * 100,
      );
      const toFetch = listToCheck.filter(
        p => !p.attribute || (!p.attribute.title && !p.attribute.description),
      );
      const ids = toFetch.map(p => p.id);

      if (!isEmpty(toFetch)) {
        props.completeProductList(ids, props.productsFilterKey, { limit: 100 });
      }
    }
  }, [props.targetList.length, open]);

  const handleClickOpen = () => {
    props.onOpen();
    setOpen(true);
  };
  const handleClose = (eventName = 'close') => {
    props.onClose(eventName);
    setOpen(false);
  };
  const onSearchSubmit = query => props.onSearchSubmit(query);

  const onDialogSelectClick = () => {
    props.onDialogSelectClick();
    handleClose('select');
  };


  const getButton = () => (
    <Button
      className="no-wrap block ml-a"
      variant="contained"
      color="primary"
      onClick={handleClickOpen}
      disabled={props.disabled}
    >
      <IntlMessages id="text.selectProducts" />
    </Button>
  );

  const getButtonWrapper = () => {
    const { badge } = props;

    if (badge) {
      return (
        <Badge className="block" color="error" variant="dot">
          {getButton()}
        </Badge>
      );
    }
    return getButton();
  };

  const onRightSidePaginate = ({ selected }) => {
    const updatedPagination = {
      ...pagination,
      page: selected + 1,
    };
    const idsPayload = props.selectedProductsIds.slice(
      (updatedPagination.page - 1) * updatedPagination.limit,
      updatedPagination.page * updatedPagination.limit,
    );
    const needToFetch = !idsPayload.every(id => props.targetList
      .some(l => l.id === id && l.attribute && l.attribute.title && l.attribute.description));

    if (needToFetch) {
      props.fetchProductsByIds(
        idsPayload,
        props.productsFilterKey,
        pagination,
      );
    }
    setPagination(updatedPagination);
  };

  return (
    <Form className="flex justify-end">
      <div className="form-group ml-0 mr-0 self-end min-w-s">
        {getButtonWrapper()}
        {props.helperText && <span className="text-error text-danger">{props.helperText}</span>}
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={props.maxWidth}
        className="select-products"
      >
        <DialogTitle>
          <SearchGroup
            noResult={false}
            onSearchSubmit={onSearchSubmit}
            placeholder="Search products"
            search={{
              ...props.search,
              className: props.search.className,
              queryLengthLimit: props.search.queryLengthLimit,
              errorMessage: props.search.errorMessage,
              useExtraSearchButton: props.search.useExtraSearchButton,
              loading: props.loading,
            }}
          />
        </DialogTitle>
        <DialogContent>
          <TransferListComponent
            disableTransferToRight
            labels={{
              left: <IntlMessages id="transferDialog.listLabel.left" />,
              right: <IntlMessages id="transferDialog.listLabel.right" />,
            }}
            left={props.sourceList}
            leftPagination={props.leftPagination}
            onRightSidePaginate={onRightSidePaginate}
            rightPagination={(
              <RightSidePagination
                total={uniqBy(props.targetList, 'id').length}
                onPaginate={onRightSidePaginate}
                pagination={pagination}
              />
            )}
            right={props.targetList}
            pagination={pagination}
            listItemWrapperClassName="flex-wrap flex-grid-calc-160"
            cardHeaderClassName="actions-center"
            listItemClassName="pl-10 crop-secondary"
            onSelectedListChange={props.onSelectedListChange}
            filterOperatorKey={props.filterOperatorKey}
            defaultImage={props.defaultImage}
            showMoreIcon
            loading={props.loading}
            maxSelected={props.maxSelected}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="default"
          >
            <IntlMessages id="transferDialog.buttonClose" />
          </Button>

          <Button
            onClick={onDialogSelectClick}
            color="primary"
            variant="contained"
            // disabled={isEmpty(props.targetList)} // TODO To figure out how to disable the button https://monosnap.com/file/OCeVV0ErfJHdxyVWDm3ULJOgF372lG
          >
            <IntlMessages id="transferDialog.buttonSlectProducts" />
          </Button>
        </DialogActions>
      </Dialog>

    </Form>
  );
};

SelectProductsButton.propTypes = {
  onSearchSubmit: func.isRequired,
  sourceList: arrayOf(shape()),
  targetList: arrayOf(shape()),
  leftPagination: node,
  onSelectedListChange: func,
  onDialogSelectClick: func,
  onOpen: func,
  onClose: func,
  helperText: oneOfType([string, node, object]),
  badge: bool,
  filterOperatorKey: string,
  search: shape({
    queryLengthLimit: number,
    errorMessage: string,
  }),
  disabled: bool,
  defaultImage: string,
  loading: shape({
    leftList: bool,
  }),
  maxWidth: string,
  productsFilterKey: string,
  fetchProductsByIds: func,
  selectedProductsIds: arrayOf(string),
  completeProductList: func,
  completingCategoryProductsList: bool,
  fetchProductsByIdsStarted: bool,
  maxSelected: number,
};

SelectProductsButton.defaultProps = {
  targetList: [],
  sourceList: [],
  leftPagination: null,
  onSelectedListChange: null,
  onDialogSelectClick: null,
  onOpen: null,
  onClose: null,
  helperText: '',
  badge: false,
  filterOperatorKey: '',
  search: {
    queryLengthLimit: null,
    errorMessage: '',
  },
  disabled: false,
  defaultImage: '',
  loading: {
    leftList: false,
  },
  maxWidth: 'lg',
  productsFilterKey: '',
  fetchProductsByIds: null,
  selectedProductsIds: [],
  completeProductList: null,
  completingCategoryProductsList: false,
  fetchProductsByIdsStarted: false,
  maxSelected: null,
};

export default SelectProductsButton;
