import axios from 'axios';
import {
  productsFrontSearchAppPath,
  productsSearchAppPath,
} from '../../util/paths';
import { showErrorNotification } from '../../util/api';

export const getFrontendProductsByCategory = async (query, urlSearchParams) => {
  try {
    const result = await axios.post(`${productsFrontSearchAppPath}${urlSearchParams}`, query);
    return result.data.data;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const getFrontendProductsByUrlKey = async (query, urlSearchParams) => {
  try {
    const result = await axios.post(`${productsFrontSearchAppPath}${urlSearchParams}`, query);
    return result.data.data;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const getFrontendProductsByPhrase = async (query, urlSearchParams) => {
  try {
    const result = await axios.post(`${productsFrontSearchAppPath}${urlSearchParams}`, query);
    return result.data.data;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const getFrontendProductsByIds = async (query) => {
  try {
    const result = await axios.post(`${productsFrontSearchAppPath}`, query);
    return result.data.data;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export const getBackendProductsByIds = async (query) => {
  try {
    const result = await axios.post(`${productsSearchAppPath}`, query);
    return result.data.data;
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};

export default {
  getFrontendProductsByCategory,
  getFrontendProductsByUrlKey,
  getFrontendProductsByPhrase,
  getFrontendProductsByIds,
};
