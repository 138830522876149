import React, { useContext } from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import RctThemeProvider from '../container/RctThemeProvider';

import { AbilityContext } from './AbilityContext';
import appPermissions from '../util/appPermissions';

import Preview from '../pages/Preview';
import Ranking from '../pages/Ranking';
import Category from '../pages/Category';
import Navigation from '../pages/Navigation';
import SystemPage from '../pages/System';
import Pim from '../pages/Pim';
import Mapper from '../pages/Mapper';
import ActivityLog from '../pages/ActivityLog';
import Repricing from '../pages/Repricing';
import Repricer from '../pages/Repricer';
import RepricerIssues from '../pages/Repricer';
import CamsErrors from '../pages/CamsErrors';
import Cams from '../pages/Cams';
import CamsMerchantStatus from '../pages/CamsMerchantStatus';
import Rules from '../pages/Rules';
import Feeds from '../pages/Feeds';
import Influencer from '../pages/Influencer';

import {
  notFoundAppPath,
  rootAppPath,
  rankingsAppPath,
  categoriesAppPath,
  searchAppPath,
  systemAppPath,
  previewAppPath,
  pimAppPath,
  mapperAppPath,
  repricerAppPath,
  repricingAppPath,
  repricerIssuesAppPath,
  camsAppPath,
  camsErrorsAppPath,
  camsMerchantStatusAppPath,
  rulesAppPath,
  feedsAppPath,
  influencersAppPath,
  activityLogAppPath,
} from '../util/paths';

import {
  AsyncSessionPage404Component,
} from './AsyncComponent/AsyncComponent';

export default () => {
  const abilityContext = useContext(AbilityContext);

  return (
    <RctThemeProvider>
      <NotificationContainer />
      <Router>
        <Switch>
          {abilityContext.can(
            appPermissions.category.permissions.view,
            appPermissions.category.name,
          ) && <Redirect from={rootAppPath} exact to={categoriesAppPath} />}
          {abilityContext.can(
            appPermissions.influencer.permissions.view,
            appPermissions.influencer.name,
          ) && <Redirect from={rootAppPath} exact to={influencersAppPath} />}
          {abilityContext.can(
            appPermissions.ranking.permissions.view,
            appPermissions.ranking.name,
          ) && <Route path={rankingsAppPath} component={Ranking} />}
          {abilityContext.can(
            appPermissions.category.permissions.view,
            appPermissions.category.name,
          ) && <Route path={`${categoriesAppPath}`} component={Category} />}
          {abilityContext.can(
            appPermissions.search.permissions.view,
            appPermissions.search.name,
          ) && <Route path={searchAppPath} component={Navigation} />}
          {abilityContext.can(
            appPermissions.system.permissions.view,
            appPermissions.system.name,
          ) && <Route path={systemAppPath} component={SystemPage} />}
          {abilityContext.can(
            appPermissions.previewPortal.permissions.view,
            appPermissions.previewPortal.name,
          ) && <Route exact path={previewAppPath} component={Preview} />}
          {abilityContext.can(
            appPermissions.pimPortal.permissions.view,
            appPermissions.pimPortal.name,
          ) && <Route exact path={pimAppPath} component={Pim} />}
          {abilityContext.can(
            appPermissions.mapper.permissions.view,
            appPermissions.mapper.name,
          ) && <Route exact path={mapperAppPath} component={Mapper} />}
          {abilityContext.can(
            appPermissions.pimPortal.permissions.view,
            appPermissions.mapper.name,
          ) && <Route exact path={activityLogAppPath} component={ActivityLog} />}
          {abilityContext.can(
            appPermissions.repricing.permissions.view,
            appPermissions.repricing.name,
          ) && <Route exact path={repricingAppPath} component={Repricing} />}
          {abilityContext.can(
            appPermissions.repricer.permissions.view,
            appPermissions.repricer.name,
          ) && <Route exact path={repricerAppPath} component={Repricer} />}
          {abilityContext.can(
            appPermissions.repricer.permissions.view,
            appPermissions.repricer.name,
          ) && <Route exact path={repricerIssuesAppPath} component={RepricerIssues} />}
          {abilityContext.can(
            appPermissions.rules.permissions.view,
            appPermissions.rules.name,
          ) && <Route exact path={rulesAppPath} component={Rules} />}
          {abilityContext.can(
            appPermissions.cams.permissions.view,
            appPermissions.cams.name,
          ) && <Route exact path={camsErrorsAppPath} component={CamsErrors} />}
          {abilityContext.can(
            appPermissions.cams.permissions.view,
            appPermissions.cams.name,
          ) && <Route exact path={camsAppPath} component={Cams} />}
          {abilityContext.can(
            appPermissions.cams.permissions.view,
            appPermissions.cams.name,
          ) && <Route exact path={camsMerchantStatusAppPath} component={CamsMerchantStatus} />}
          {abilityContext.can(
            appPermissions.feeds.permissions.view,
            appPermissions.feeds.name,
          ) && <Route exact path={feedsAppPath} component={Feeds} />}
          {abilityContext.can(
            appPermissions.influencer.permissions.view,
            appPermissions.influencer.name,
          ) && <Route path={influencersAppPath} component={Influencer} />}
          <Route exact path={notFoundAppPath} component={AsyncSessionPage404Component} />
          <Route component={AsyncSessionPage404Component} />
        </Switch>
      </Router>
    </RctThemeProvider>
  );
};
