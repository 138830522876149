export default productList => (productList && productList.length > 0
  ? productList.map(product => ({
    ...product,
    image: product.images && product.images.length > 0 ? product.images[0] : {},
    disabled: false,
    checked: false,
    attribute: {
      title: product.title || '',
      price: `$${product.minPrice || ''}`,
      description: product.description || '',
      productId: product.id,
    },
  }))
  : []);
