import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

const initialState = {
  list: {},
  modes: {
    preview: [],
    pim: {},
  },
};
const systemRestTypes = restTypes('system');
const {
  fetchAllStart,
  fetchAllFinished,
  updateStart,
  updateFinished,
  updateMultipleStart,
  updateMultipleFinished,
} = systemRestTypes;

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        modes: action.payload.modes,
      };

    case updateStart:
      return {
        ...state,
        ...restStates.updateStart,
      };
    case updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        hasError: action.payload ? action.payload.hasError : false,
      };

    case updateMultipleStart:
      return {
        ...state,
        ...restStates.updateMultipleStart,
      };
    case updateMultipleFinished:
      return {
        ...state,
        ...restStates.updateMultipleFinished,
        hasError: action.payload.hasError,
      };

    default: return state;
  }
};
