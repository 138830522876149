import React, { useState } from 'react';
import {
  string, shape, arrayOf, func,
} from 'prop-types';
import {
  Collapse, List, ListItem, ListItemIcon, Checkbox, ListItemText,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const CollapsibleGrid = (props) => {
  const [open, setOpen] = useState(false);
  const {
    options, title, onChange,
  } = props;

  const handleClick = () => setOpen(!open);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItem button onClick={handleClick}>
        <ListItemText className="combined-facet-group-title" primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" className="combined-facet-group-list facet-options-list" disablePadding>
          {options.map((o) => {
            const handleChange = () => onChange(o.value);
            const isChecked = o.checked;
            const checkedClassName = isChecked ? 'checked' : '';

            return (
              <ListItem
                key={o.key}
                onClick={handleChange}
                className={`combined-facet-group-item ${checkedClassName}`}
                button
              >
                <ListItemIcon>
                  <Checkbox
                    edge="end"
                    checked={isChecked}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText className="combined-facet-group-item-label" primary={o.label} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};

CollapsibleGrid.propTypes = {
  options: arrayOf(shape()).isRequired,
  title: string.isRequired,
  onChange: func.isRequired,
};

CollapsibleGrid.defaultProps = {};

export default CollapsibleGrid;
