import React, { useState, useEffect } from 'react';
import Search from '../../components/Search';
import Pagination from '../../../../components/Pagination';
import Sorting from '../../../../components/Sorting';
import {Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Button} from '@material-ui/core';
import IntlMessages from '../../../../util/IntlMessages';
import { initialTableScroll, moveTableScroll } from '../../../../util/table';
import PagePlaceholder from "../../../../components/PagePlaceholder";
import ChangesDialog from "./changesDialog";

export const ActivityLogTable = ({
  getActivityLogData,
  activityLogFields,
  mapperData,
  mapperScroll,
  mapperCondition,
  mapperItemsPerPage,
}) => {

  const [unsavedMapData, setUnsavedMapData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [mapChanges, setMapChanges] = useState([]);
  const [mapScroll, setMapScroll] = useState([]);
  const [mapCondition, setMapCondition] = useState([]);
  const [mapperAllPages, setMapperAllPages] = useState(0);
  const [mapperCurrentPage, setMapperCurrentPage] = useState(1);
  const [currentActivityLogFields, setCurrentActivityLogFeelds] = useState([]);

  const [open, setOpen] = useState(false);
  const [dialogHeader, setDialogHeader] = useState('');
  const [changes, setChanges] = useState('No changes');

  useEffect(() => {
    const formatMapData = [];
    const activityLogFieldsKeys = Object.keys(activityLogFields);
    const allActivityLogFieldsKeys = Object.keys(activityLogFields);

    for (let i = 0; i < activityLogFieldsKeys.length; i++) {
      if (activityLogFields[activityLogFieldsKeys[i]].field) {
        activityLogFieldsKeys[i] = activityLogFields[activityLogFieldsKeys[i]].field;
      }
    }

    const mapChanges = [];
    for (let i = 0; i < mapperData.length; i++) {
      if (mapperData[i]) {
        const formatDataItem = [];
        for (let j = 0; j < activityLogFieldsKeys.length; j++) {
          if (activityLogFieldsKeys[j].indexOf('.') !== -1) {
            const splitField = activityLogFieldsKeys[j].split('.');
            if (mapperData[i][splitField[0]]) {
              formatDataItem[j] = mapperData[i][splitField[0]][splitField[1]];
            }
          } else {
            formatDataItem[j] = mapperData[i][activityLogFieldsKeys[j]];
          }
        }
        formatMapData[i] = formatDataItem;
        if (mapperData[i].changes.length !== 0) {
          mapChanges[i] = mapperData[i].changes;
        }
      }
    }
    setMapChanges(mapChanges);

    let j = 0;
    const currentActivityLogFields = [];
    for (let i = 0; i < allActivityLogFieldsKeys.length; i++) {
      if (formatMapData && formatMapData[0] && formatMapData[0][i] !== undefined) {
        currentActivityLogFields[j] = activityLogFields[allActivityLogFieldsKeys[i]];
        currentActivityLogFields[j].field = allActivityLogFieldsKeys[i];
        j++;
      }
    }
    setCurrentActivityLogFeelds(currentActivityLogFields);

    const currentFormatData = [];
    for (let i = 0; i < formatMapData.length; i++) {
      currentFormatData[i] = formatMapData[i].filter(function (el) {
        return el !== undefined;
      });
    }
    setMapData(currentFormatData);
    setUnsavedMapData(currentFormatData);

    setMapScroll(mapperScroll);
    setMapCondition(mapperCondition);
    setMapperAllPages(Math.ceil(mapperScroll.total / mapperItemsPerPage));
    let currentPage = Math.ceil(mapperScroll.offset / mapperItemsPerPage) + 1;
    if (currentPage <= 0) {
      currentPage = 1;
    }
    setMapperCurrentPage(currentPage);
  }, [
    mapperData, mapperScroll, mapperCondition, activityLogFields, mapperItemsPerPage
  ]);

  const handleShowDialog = (dialogHeaderText, content) => () => {
    setDialogHeader(dialogHeaderText);
    setChanges(content);
    setOpen(true);
  };

  const MapperTitle = ((props) => {
    const {
      item, type, field, name, sortable, fixedWidth
    } = props;

    if (type === 'invisible') {
      return '';
    }

    let className = "mapper-no-wrap";
    if (fixedWidth === 'long') {
      className += " mapper-w-350";
    }
    if (fixedWidth === 'middle') {
      className += " mapper-w-150";
    }
    if (fixedWidth === 'short') {
      className += " mapper-w-50";
    }

    if (sortable === true) {
      className += " mapper-sortable";

      return (
        <Sorting
          getData={getActivityLogData}
          item={field ? field : item}
          condition={mapCondition}
          name={name}
          className={className}
          itemsPerPage={mapperItemsPerPage}
        />
      );
    }

    return (
      <TableCell><div className={className}>{ name }</div></TableCell>
    );
  });

  const MapperItem = ((props) => {
    const {
      type, options, maxLength, value
    } = props;

    let tablevalue = '';

    if (type === 'select' && options) {
      const optionsKeys = Object.keys(options);
      for (let i = 0; i < optionsKeys.length; i++) {
        if (optionsKeys[i] === value) {
          tablevalue = options[optionsKeys[i]];
        }
      }
    } else {
      tablevalue = value || value === 0 ? value : 'N/A';
      if (maxLength && tablevalue.length > maxLength) {
        tablevalue = tablevalue.substring(0, maxLength) + '...';
      }
    }

    if (Array.isArray(tablevalue)) {
      let arrValue = '';
      for (let i = 0; i < tablevalue.length; i++) {
        arrValue += tablevalue[i];
        if (i < tablevalue.length - 1) {
          arrValue += ',';
        }
      }
      tablevalue = arrValue;
    }

    return (
      <TableCell className="mapper-td" style={{whiteSpace: 'nowrap'}}>
        {tablevalue}
      </TableCell>
    );
  });

  initialTableScroll(
    'mapperTable',
    'mapperTableScroll',
    'mapperTableContainer',
    'mapperTableContainerScroll'
  );

  const moveScroll = () => {
    moveTableScroll('mapperTableContainer', 'mapperTableContainerScroll');
  };

  return (
    <>
      <Search
        activityLogFields={activityLogFields}
        mapperItemsPerPage={mapperItemsPerPage}
        unsavedMapData={unsavedMapData}
        mapData={mapData}
        mapScroll={mapScroll}
        mapCondition={mapCondition}
      />

      <br/>

      <div id="mapperTableScrollDiv" className="mapper-table-scroll">
        <TableContainer id="mapperTableContainerScroll"
                        className="product-table-container"
                        onScroll={moveScroll} >
          <Table id="mapperTableScroll">
            <tr><td>&nbsp;</td></tr>
          </Table>
        </TableContainer>
      </div>

      <TableContainer id="mapperTableContainer" className="product-table-container-no-scroll">
        <Table id="mapperTable" stickyHeader aria-label="sticky table" size="small">

          <TableHead className="products-table-header">
            <TableRow>
              {Object.keys(currentActivityLogFields).map((item, itemKey) => (
                <MapperTitle classNaqme="mapper-no-wrap"
                             item={item}
                             field={Object.values(currentActivityLogFields)[itemKey].field}
                             type={Object.values(currentActivityLogFields)[itemKey].type}
                             name={Object.values(currentActivityLogFields)[itemKey].name}
                             sortable={Object.values(currentActivityLogFields)[itemKey].sortable}
                             fixedWidth={Object.values(currentActivityLogFields)[itemKey].fixedWidth}
                />
              ))}
              <TableCell style={{width: '150px'}} align="center">
                <div className="mapper-no-wrap">
                  <IntlMessages id="title.showChanges" />
                </div>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {mapData && mapData.map((item, itemKey) => (
              <TableRow>
                {Object.keys(currentActivityLogFields).map((value, valueKey) => (
                  <MapperItem type={Object.values(currentActivityLogFields)[valueKey].type}
                              options={Object.values(currentActivityLogFields)[valueKey].options}
                              maxLength={Object.values(currentActivityLogFields)[valueKey].maxLength}
                              value={item[valueKey]}
                  />
                ))}
                <TableCell align="center">
                  {mapChanges[itemKey] ? (
                    <Button
                      style={{width: '150px'}}
                      color="primary"
                      variant="contained"
                      onClick={handleShowDialog(
                        <IntlMessages id="title.changes" />,
                        JSON.stringify(mapChanges[itemKey], null, 3)
                      )}
                    >
                      <IntlMessages id="title.showChanges" />
                    </Button>
                  ) : (
                    <IntlMessages id="title.noChanges" />
                  )}
                </TableCell>
              </TableRow>
            ))}

            {mapData.length === 0 && (
              <TableRow>
                <TableCell className="rules-no-border">
                  <PagePlaceholder
                    icon={<i className="ti-face-sad text-primary text-lg" />}
                    title={<IntlMessages id="title.sorry" />}
                    subtitle={<IntlMessages id="title.noResultsFound" />}
                  />
                </TableCell>
              </TableRow>
            )}

          </TableBody>
        </Table>
        <ChangesDialog
          open={open}
          close={() => setOpen(false)}
          changes={changes}
          header={dialogHeader}
        />
      </TableContainer>

      <Pagination
        getData={getActivityLogData}
        dataCount={mapData ? mapData.length : 0}
        itemsPerPage={mapperItemsPerPage}
        allPages={mapperAllPages}
        currentPage={mapperCurrentPage}
        scroll={mapScroll}
        condition={mapCondition}
      />
    </>
  );
};

export default ActivityLogTable;
