import { isEmpty, uniq } from 'lodash';

export const handleUnHideProducts = ({
  products,
  filters,
  setIsHiddenProductsMode,
  setHiddenProductsFilter,
  handleUpdateEntityFilters,
}) => {
  const productsIds = products.map(p => p.id);
  const existingProductFilter = filters
    .find(f => f.group
      .some(fG => fG.field === 'id' && fG.operator === 'nin'));
  const currentGroup = existingProductFilter
    ? existingProductFilter.group.find(g => g.field === 'id' && g.operator === 'nin')
    : {};
  const filteredGroupValue = currentGroup.value.filter(v => productsIds.every(pId => pId !== v));
  const newFilterById = {
    condition: 'and',
    group: [{
      field: 'id',
      value: uniq(filteredGroupValue),
      operator: 'nin',
    }],
  };
  const filterPayload = [
    ...filters.filter(f => f !== existingProductFilter),
  ];
  const hiddenProductsFilter = [];

  if (!isEmpty(filteredGroupValue)) {
    filterPayload.push(newFilterById);
    hiddenProductsFilter.push({
      ...newFilterById,
      group: newFilterById.group.map((g) => {
        if (g.field === 'id') {
          return {
            ...g,
            operator: 'in',
          };
        }
        return g;
      }),
    });
  }

  if (isEmpty(filteredGroupValue)) {
    setIsHiddenProductsMode(false);
  }

  setHiddenProductsFilter(hiddenProductsFilter);
  handleUpdateEntityFilters({ filters: filterPayload });
};

export const handleHideProducts = ({
  products,
  filters,
  setHiddenProductsFilter,
  handleUpdateEntityFilters,
}) => {
  const productsIds = products.map(p => p.id);
  const existingProductFilter = filters
    .find(f => f.group
      .some(fG => fG.field === 'id' && fG.operator === 'nin'));

  const getGroup = (group) => {
    const payload = group.map(g => ({
      ...g,
      value: g.field === 'id' && g.operator === 'nin'
        ? uniq([...g.value, ...productsIds])
        : g.value,
    }));
    return payload;
  };

  const newFilterById = existingProductFilter
    ? {
      ...existingProductFilter,
      group: getGroup(existingProductFilter.group),
    }
    : {
      condition: 'and',
      group: [{
        field: 'id',
        value: uniq(productsIds),
        operator: 'nin',
      }],
    };

  const filterPayload = [
    ...filters.filter(f => f !== existingProductFilter),
    newFilterById,
  ];
  const hiddenProductsFilter = [
    ...filters.filter(f => f !== existingProductFilter),
    {
      ...newFilterById,
      group: newFilterById.group.map((g) => {
        if (g.field === 'id') {
          return {
            ...g,
            operator: 'in',
          };
        }
        return g;
      }),
    },
  ];
  setHiddenProductsFilter(hiddenProductsFilter);
  handleUpdateEntityFilters({ filters: filterPayload });
};

export default {
  handleUnHideProducts,
  handleHideProducts,
};
