import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  SET_CONTEXT_MENU_TARGET_TREE_NODE,
  SET_CONTEXT_MENU_SHOW,
  SET_CONTEXT_MENU_STYLES,
} from '../actions/types';

const initialState = {
  contextMenuStyles: { left: 0, top: 0, position: 'fixed' },
  showContextMenu: false,
  targetTreeNode: null,
};
const contextMenuRestTypes = restTypes('context_menu');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case contextMenuRestTypes.clearAllFinished:
      return {
        ...restInitState,
        ...restStates.clearAllFinished,
        ...initialState,
      };
    case SET_CONTEXT_MENU_TARGET_TREE_NODE:
    case SET_CONTEXT_MENU_SHOW:
    case SET_CONTEXT_MENU_STYLES:
      return {
        ...state,
        ...action.payload,
      };

    default: return state;
  }
};
