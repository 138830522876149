/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useAttributesMatrix = (state, props) => {
  useEffect(() => {
    if (!props.attributesMatrix.fetched && !props.attributesMatrix.fetching) {
      props.fetchAttributesMatrix();
    }
  }, []);
};

export default useAttributesMatrix;
