export const commentValidations = {
  comment: [{
    type: 'isRequired',
    message: 'pim.unlockAttributesDialog.validations.commentIsRequired',
  }, {
    type: 'maxSize',
    message: 'text.errors.lessThan200Characters',
    condition: 50,
  }],
};

export const systemCommentValidations = {
  systemComment: [{
    type: 'isRequired',
    message: 'pim.unlockAttributesDialog.validations.systemCommentIsRequired',
  }],
};

export default {
  commentValidations,
  systemCommentValidations,
};
