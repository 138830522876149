import { createSelector } from 'reselect';

const pimRootSelector = state => state;

const stateSelector = createSelector(
  pimRootSelector,
  state => ({
    filterTarget: state.filterTarget,
    prevFilterTarget: state.prevFilterTarget,
    selectedProducts: state.selected,
    displayMode: state.displayMode,
    globalFilters: state.globalFilters,
    sort: state.sort,
    globalFields: state.globalFields,
    searchQuery: state.searchQuery,
    savedFilterFields: state.savedFilterFields,
    savedFilterFilters: state.savedFilterFilters,
    fullTextListModeSearch: state.fullTextListModeSearch,
  }),
);

export const pimSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  pimSelector,
};
