export const userTableValidations = {
  roles: [
    {
      type: 'isRequired',
      message: 'system.errors.cantBeEmpty',
    },
  ],
  displayName: [
    {
      type: 'isRequired',
      message: 'system.errors.cantBeBlank',
    },
  ],
};

export const newUserValidations = {
  roles: [
    {
      type: 'isRequired',
      message: 'system.errors.cantBeEmpty',
    },
  ],
  displayName: [
    {
      type: 'isRequired',
      message: 'system.errors.cantBeBlank',
    },
  ],
  email: [
    {
      type: 'isRequired',
      message: 'system.errors.cantBeBlank',
    }, {
      type: 'email',
      message: 'system.errors.pleaseProvideValidEmailAddress',
    },
  ],
  password: [
    {
      type: 'isRequired',
      message: 'system.errors.cantBeBlank',
    }, {
      type: 'minSize',
      message: 'system.errors.passwordShouldBeAtLeastSixCharacters',
      condition: 6,
    },
  ],
};

export const companyFieldValidations = {
  companyName: [
    {
      type: 'isRequired',
      message: 'system.errors.cantBeBlank',
    },
  ],
};

export default {
  userTableValidations,
  newUserValidations,
  companyFieldValidations,
};
