import {
  shape, arrayOf, string, func, bool,
} from 'prop-types';

import ReactRouterPropTypes from 'react-router-prop-types';

export const propTypes = {
  rankingAttribute: shape({
    list: arrayOf(shape({
      title: string,
      value: string,
    })),
  }).isRequired,
  pushing: shape({
    list: arrayOf(shape({
      title: string,
      value: string,
    })),
  }).isRequired,
  normalization: shape({
    list: arrayOf(shape({
      title: string,
      value: string,
    })),
  }).isRequired,
  virtualCategory: shape({
    search: arrayOf(shape({
      id: string,
      parentId: string,
      name: string,
    })),
  }).isRequired,
  ranking: shape({
    item: shape({
      id: string,
    }),
    created: bool,
    creating: bool,
  }).isRequired,
  scopeCategory: shape({
    treeItems: arrayOf(shape({
      id: string,
    })),
    searchResults: arrayOf(shape({
      id: string,
    })),
  }).isRequired,
  fetchChildScopeCategories: func.isRequired,
  createRank: func.isRequired,
  clearCreateRankingState: func.isRequired,
  fetchRankingList: func.isRequired,
  fetchRootScopeCategories: func.isRequired,
  clearScopeCategoriesState: func.isRequired,
  clearScopeCategorySearchState: func.isRequired,
  searchScopeCategories: func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match,
  onToggleScopeCategoryTreeCollapse: func.isRequired,
  handleLoadScopeSubcategories: func.isRequired,
  fetchRankingByName: func.isRequired,
};

export const defaultProps = {

};

export default {
  propTypes,
  defaultProps,
};
