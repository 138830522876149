import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const influencerItemSelector = item => item;

const categoriesSelector = createSelector(
  influencerItemSelector,
  item => (!isEmpty(item) && item.page.topTen.virtualCategories
    ? item.page.topTen.virtualCategories
    : []),
);

export const topTenSelectedCategoriesSelector = createSelector(
  categoriesSelector,
  virtualCategories => (virtualCategories),
);

export default {
  topTenSelectedCategoriesSelector,
};
