import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const influencerItemSelector = item => item;

const filtersSelector = createSelector(
  influencerItemSelector,
  item => (!isEmpty(item) && item.page.deal.filters ? item.page.deal.filters : []),
);

export const dealFiltersSelector = createSelector(
  filtersSelector,
  dealFilters => (dealFilters),
);

export default {
  dealFiltersSelector,
};
