/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { func, shape, bool } from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Form, FormGroup, Input } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import QueueAnim from 'rc-queue-anim';
import QueryString from 'query-string';

import IntlMessages from '../../../util/IntlMessages';
import smallLogo from '../../../assets/img/logo-small.png';

import {
  confirmPasswordReset,
  verifyPasswordResetCode,
} from '../../../actions/auth';

import {
  signinAppPath,
} from '../utils/paths';
import { rootAppPath } from '../../../util/paths';

const ChangePassword = (props) => {
  const [newPassword, setNewPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState(null);

  useEffect(() => {
    if (!verificationCode) {
      const parsedQueryString = QueryString.parse(location.search);
      const code = parsedQueryString.oobCode;
      setVerificationCode(code);
      props.verifyPasswordResetCode(code);
    }
  }, [props, verificationCode]);

  useEffect(() => {
    if (props.authUser.confirmResetFinished && !props.authUser.hasError) {
      props.history.push(signinAppPath);
    }
  }, [props, props.authUser.confirmResetFinished]);

  const onChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleSubmit = () => {
    props.confirmPasswordReset(newPassword, verificationCode);
  };

  return (
    <QueueAnim type="bottom" duration={2000}>
      <div className="rct-session-wrapper flex justify-center items-center">
        <div className="session-inner-wrapper justify-center items-center">
          <div className="container">
            <div className="row row-eq-height justify-center">
              <div className="col-sm-7 col-md-7 col-lg-8">
                <div className="session-body text-center">
                  <div className="session-head mb-30">
                    <div className="site-logo">
                      <Link to={rootAppPath} className="logo-normal header-brand">
                        <img src={smallLogo} alt="site logo" />
                      </Link>
                    </div>
                  </div>
                  {
                    !props.authUser.codeInvalid && (
                      <Form>
                        <FormGroup className="has-wrapper">
                          <Input
                            type="password"
                            name="newPassword"
                            id="newPassword"
                            value={newPassword}
                            className="has-input input-lg"
                            placeholder="Enter a new password"
                            onChange={onChangeNewPassword}
                          />
                          <span className="has-icon"><i className="ti-password" /></span>
                        </FormGroup>
                        <FormGroup>
                          <Button
                            variant="contained"
                            className="btn-info text-white btn-block btn-large w-100"
                            onClick={handleSubmit}
                          >
                            <IntlMessages id="auth.forgotPassword.changePasswordButton" />
                          </Button>
                        </FormGroup>
                      </Form>
                    )
                  }
                  {
                    props.authUser.codeInvalid && <IntlMessages id="auth.forgotPassword.codeIsInvalid" />
                  }
                  {
                    props.authUser.hasError && props.authUser.confirmResetFinished
                      && <IntlMessages id="auth.forgotPassword.codeIsInvalid" />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </QueueAnim>
  );
};

ChangePassword.propTypes = {
  confirmPasswordReset: func.isRequired,
  verifyPasswordResetCode: func.isRequired,
  authUser: shape({
    isInvalid: bool,
    confirmResetFinished: bool,
  }).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

const mapDispatchToProps = {
  confirmPasswordReset,
  verifyPasswordResetCode,
};

const mapStateToProps = ({ authUser }) => ({
  authUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
