/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, memo } from 'react';
import {
  shape, func, arrayOf,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { useDropzone } from 'react-dropzone';
import IconButton from '@material-ui/core/IconButton';

import IntlMessages from '../../../../../../../../../util/IntlMessages';

export const ImportContentComponent = memo((props) => {
  const { setFiles, files } = props;

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: '.xls,.xlsx',
  });

  useEffect(() => {
    const payload = [...files, ...acceptedFiles];
    const file = payload[0];

    setFiles(payload);

    if (file) {
      const nameArray = file.name.split('_');
      const fileDate = nameArray
        ? [...nameArray].splice(2).join('_').replace('.xlsx', '')
        : '';

      if (fileDate) {
        props.setExportFileDate(fileDate);
      }
    }
  },
  [acceptedFiles]);

  const onDeleteClick = (file) => {
    const newFiles = [...files];

    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    props.setExportFileDate('');
  };

  const filesNodes = files.map(file => (
    <li className="flex justify-between dropzone-selected-item" key={file.path}>
      <div className="flex direction-column ">
        <span>{`Name: ${file.path}`}</span>
        <span>{`Size: ${file.size}`}</span>
      </div>
      <IconButton
        size="small"
        color="primary"
        aria-label="Delete"
        onClick={() => onDeleteClick(file)}
      >
        <i className="ti-close red" />
      </IconButton>
    </li>
  ));
  const emptyMsg = <IntlMessages id="importPage.emptyZone" />;
  const data = !isEmpty(filesNodes) ? filesNodes : emptyMsg;
  const disabledClass = !isEmpty(files) ? 'disabled' : '';

  return (
    <section>
      <div {...getRootProps({ className: `dropzone ${disabledClass}` })}>
        <input {...getInputProps({ multiple: false, disabled: !isEmpty(files) })} />
        <IntlMessages id="importPage.placeholder" />
      </div>
      <aside className="dropzone-content">
        <h4>Files</h4>
        <ul>{data}</ul>
      </aside>
    </section>
  );
});

ImportContentComponent.propTypes = {
  files: arrayOf(shape()),
  setFiles: func,
  setExportFileDate: func,
};

ImportContentComponent.defaultProps = {
  files: [],
  setFiles: null,
  setExportFileDate: null,
};

export default ImportContentComponent;
