import { systemAppPath } from '../../../util/paths';
import appPermissions from '../../../util/appPermissions';

export const sidebarLinks = abilityContext => [{
  menuTitle: 'sidebar.displayFields',
  typeMulti: null,
  newItem: false,
  key: 'displayFields',
  open: false,
  childRoutes: [
    {
      menuTitle: 'sidebar.preview',
      newItem: false,
      path: `${systemAppPath}/preview`,
      enabled: abilityContext.can(
        appPermissions.displayFields.permissions.view,
        appPermissions.displayFields.name,
      ),
    },
  ],
}, {
  menuTitle: 'sidebar.management',
  typeMulti: null,
  newItem: false,
  key: 'users',
  open: false,
  childRoutes: [
    {
      menuTitle: 'sidebar.users',
      newItem: false,
      path: `${systemAppPath}/users`,
      key: 'users',
      enabled: abilityContext.can(
        appPermissions.users.permissions.view,
        appPermissions.users.name,
      ),
    }, {
      menuTitle: 'sidebar.bulkImport',
      newItem: false,
      path: `${systemAppPath}/bulk-import`,
      key: 'bulkImport',
      enabled: abilityContext.can(
        appPermissions.bulkImport.permissions.view,
        appPermissions.bulkImport.name,
      ),
    }],
}];

export default sidebarLinks;
