import axios from 'axios';
import { camelizeKeys } from 'humps';
import { isEmpty, uniq, uniqBy } from 'lodash';

import RestActions from '../util/rest/actions';
import {
  FETCH_ROOT_SCOPE_CATEGORIES_START,
  FETCH_ROOT_SCOPE_CATEGORIES_FINISHED,
  FETCH_CHILD_SCOPE_CATEGORIES_START,
  FETCH_CHILD_SCOPE_CATEGORIES_FINISHED,
  SEARCH_SCOPE_CATEGORIES_START,
  SEARCH_SCOPE_CATEGORIES_FINISHED,
  CLEAR_SCOPE_CATEGORIES_STATE,
  CLEAR_FETCH_SCOPE_CATEGORY_STATE,
  TOGGLE_SCOPE_CATEGORY_TREE_COLLAPSE,
  HANDLE_LOAD_SCOPE_SUBCATEGORIES,
  CLEAR_SCOPE_CATEGORY_TREE_STATE,
  FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_START,
  FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_FINISHED,
  SEARCH_VIRTUAL_CATEGORIES_FILTER_FINISHED,
  SEARCH_VIRTUAL_CATEGORIES_FILTER_START,
  SEARCH_PHYSICAL_CATEGORIES_FILTER_FINISHED,
  SEARCH_PHYSICAL_CATEGORIES_FILTER_START,
  FETCH_SCOPE_CATEGORIES_BY_IDS_START,
  FETCH_SCOPE_CATEGORIES_BY_IDS_FINISHED,
  FETCH_PARENT_CATEGORIES_BY_IDS_START,
  FETCH_PARENT_CATEGORIES_BY_IDS_FINISHED,
  SET_SELECTED_SCOPE_CATEGORY_ID,
  FETCH_PARENT_SCOPE_CATEGORY_START,
  FETCH_PARENT_SCOPE_CATEGORY_FINISHED,
} from './types';

import {
  categoriesTreePath,
  physicalCategoriesTreePath,
  getCategoryTreePath,
  getPhysicalCategoryTreePath,
  categoriesSearchPath,
  physicalCategoriesSearchPath,
  getCategoryPath,
  getPhysicalCategoryPath,
  getCategoriesExpandTreePath,
  getPhysicalCategoriesExpandTreePath,
} from '../util/paths';
import { mapObjectToArray } from '../util/objectsToArray';
import { showErrorNotification } from '../util/api';

const rootScopeCategoriesActions = new RestActions('scope_category');
const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';

export const fetchRootCategories = cb => (dispatch) => {
  dispatch({ type: FETCH_ROOT_SCOPE_CATEGORIES_START });

  const isPhysical = (document.location.href.indexOf('repricer') !== -1);
  const path = isPhysical ? `${physicalCategoriesTreePath}` : `${categoriesTreePath}?is_virtual=1`;

  return axios.get(path).then((res) => {
    const rootCategories = res ? camelizeKeys(res.data.data) : [];

    dispatch({
      type: FETCH_ROOT_SCOPE_CATEGORIES_FINISHED,
      payload: { list: rootCategories },
    });
    if (cb) cb();
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_ROOT_SCOPE_CATEGORIES_FINISHED,
      payload: {
        error: error.response.data, hasError: true,
      },
    });
  });
};

export const clearFetchScopeCategoryState = () => (dispatch) => {
  dispatch({ type: CLEAR_FETCH_SCOPE_CATEGORY_STATE });
};

export const fetchChildCategories = rootId => (dispatch) => {
  dispatch({ type: FETCH_CHILD_SCOPE_CATEGORIES_START });

  const isPhysical = (document.location.href.indexOf('repricer') !== -1);
  const path = isPhysical ? getPhysicalCategoryTreePath(rootId) : getCategoryTreePath(rootId);

  return axios.get(path).then((res) => {
    dispatch({
      type: FETCH_CHILD_SCOPE_CATEGORIES_FINISHED,
      payload: { list: camelizeKeys(res.data.data) },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_CHILD_SCOPE_CATEGORIES_FINISHED,
      payload: {
        error: error.response.data, hasError: true,
      },
    });
  });
};

export const clearScopeCategoriesState = () => (dispatch) => {
  dispatch({ type: CLEAR_SCOPE_CATEGORIES_STATE });
};

export const clearSearchListState = () => (dispatch) => {
  dispatch(rootScopeCategoriesActions.clearSearchListFinished());
};

export const searchCategories = (query, cb) => async (dispatch) => {
  const idsFilter = query.filter
    ? query.filter.find(f => f.group.some(g => g.field === 'id'))
    : null;
  let pages = [0];
  const pagination = {
    limit: 100,
  };
  if (idsFilter) {
    const iDs = (idsFilter.group.find(g => g.field === 'id') || {}).value;
    pages = Array.from(Array(Math.ceil(iDs.length / pagination.limit)).keys());
  }
  dispatch({ type: SEARCH_SCOPE_CATEGORIES_START });

  try {
    const promises = await pages.map(async (page) => {
      const requestQuery = {
        ...query,
        pagination: {
          page: page + 1,
          limit: pagination.limit,
        },
      };

      const isPhysical = (document.location.href.indexOf('repricer') !== -1);
      const path = isPhysical ? physicalCategoriesSearchPath : categoriesSearchPath;
      const categoriesResult = await axios.post(path + '?ref=' + ref, { ...requestQuery });

      return camelizeKeys(categoriesResult.data.data);
    });

    const categoriesResult = await Promise.all(promises);
    const categories = categoriesResult.flat();

    dispatch({
      type: SEARCH_SCOPE_CATEGORIES_FINISHED,
      payload: {
        list: camelizeKeys(categories),
      },
    });
    if (cb) cb(camelizeKeys(categories));
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: SEARCH_SCOPE_CATEGORIES_FINISHED,
      payload: {
        error: error.response.data, hasError: true,
      },
    });
  }
};

export const searchCategoriesFilter = query => (dispatch) => {
  const isPhysical = (document.location.href.indexOf('repricer') !== -1);
  dispatch({ type: isPhysical ? SEARCH_PHYSICAL_CATEGORIES_FILTER_START : SEARCH_VIRTUAL_CATEGORIES_FILTER_START });
  const path = isPhysical ? physicalCategoriesSearchPath : categoriesSearchPath;

  return axios.post(path + '?ref=' + ref, query).then((res) => {
    const searchResults = mapObjectToArray(res.data.data);
    dispatch({
      type: isPhysical ? SEARCH_PHYSICAL_CATEGORIES_FILTER_FINISHED : SEARCH_VIRTUAL_CATEGORIES_FILTER_FINISHED,
      payload: {
        list: camelizeKeys(searchResults),
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: isPhysical ? SEARCH_PHYSICAL_CATEGORIES_FILTER_FINISHED : SEARCH_VIRTUAL_CATEGORIES_FILTER_FINISHED,
      payload: {
        error: error.response.data, hasError: true,
      },
    });
  });
};

export const fetchOne = id => (dispatch) => {
  dispatch(rootScopeCategoriesActions.fetchStart());

  const isPhysical = (document.location.href.indexOf('repricer') !== -1);
  const path = isPhysical ? getPhysicalCategoryPath(id) : getCategoryPath(id);

  return axios.get(path).then((res) => {
    dispatch(rootScopeCategoriesActions.fetchFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(rootScopeCategoriesActions.fetchFinished({
      error: error.response.data, hasError: true,
    }));
  });
};

export const onToggleScopeCategoryTreeCollapse = collapse => (dispatch) => {
  dispatch({ type: TOGGLE_SCOPE_CATEGORY_TREE_COLLAPSE, payload: collapse });
};

export const handleLoadScopeSubcategories = loadedKeys => (dispatch) => {
  dispatch({ type: HANDLE_LOAD_SCOPE_SUBCATEGORIES, payload: { loadedKeys } });
};

export const clearScopeCategoryTreeState = () => (dispatch) => {
  dispatch({ type: CLEAR_SCOPE_CATEGORY_TREE_STATE });
};

export const clearFromScopeCategoryState = payload => (dispatch) => {
  dispatch(rootScopeCategoriesActions.clearFromStateFinished(payload));
};


export const loadAllChildCategories = (parent, expand, cb) => (dispatch) => {
  dispatch({ type: FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_START });

  const isPhysical = (document.location.href.indexOf('repricer') !== -1);
  const path = isPhysical
    ? `${getPhysicalCategoriesExpandTreePath(parent.id)}?expand=${expand}`
    : `${getCategoriesExpandTreePath(parent.id)}?expand=${expand}`;

  return axios.get(path).then((res) => {
    const result = camelizeKeys(res.data.data);
    const ids = [];
    const categories = [];
    const totalArray = [parent];

    const getChildrenItems = (array) => {
      const resp = array.map((arrayItem) => {
        totalArray.push(arrayItem);

        if (arrayItem.children) {
          getChildrenItems(arrayItem.children);
        }

        return arrayItem;
      });
      return resp;
    };

    getChildrenItems(result);

    totalArray.map((category) => {
      ids.push(category.id);
      categories.push(category);

      return category;
    });

    if (cb) cb(ids);

    dispatch({
      type: FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_FINISHED,
      payload: { parentWidthChildren: camelizeKeys(categories) },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_FINISHED,
      payload: {
        error: error.response.data, hasError: true,
      },
    });
  });
};

export const fetchScopeCategoriesByIds = ids => (dispatch) => {
  dispatch({
    type: FETCH_SCOPE_CATEGORIES_BY_IDS_START,
    payload: {
      fetchingScopeCategoriesByIds: true,
      fetchedScopeCategoriesByIds: false,
    },
  });
  const filter = [{
    group: [{
      field: 'id',
      value: uniq(ids),
      operator: 'in',
    }],
  }];

  const isPhysical = (document.location.href.indexOf('repricer') !== -1);
  const path = isPhysical ? physicalCategoriesSearchPath : categoriesSearchPath;

  return axios.post(path + '?ref=' + ref, { filter }).then((res) => {
    const data = res.data ? res.data.data : [];
    dispatch({
      type: FETCH_SCOPE_CATEGORIES_BY_IDS_FINISHED,
      payload: {
        categoriesByIds: camelizeKeys(data),
        fetchingScopeCategoriesByIds: false,
        fetchedScopeCategoriesByIds: true,
      },
    });
  }, (error) => {
    dispatch({
      type: FETCH_SCOPE_CATEGORIES_BY_IDS_FINISHED,
      payload: {
        error: error.response.data, hasError: true,
      },
    });
  });
};

export const fetchRecursiveParentCategories = cIds => async (dispatch, getState) => {
  const pagination = {
    limit: 100,
  };
  const pages = Array.from(Array(Math.ceil(cIds.length / pagination.limit)).keys());
  const { treeItems } = getState().scopeCategory;

  dispatch({ type: FETCH_PARENT_CATEGORIES_BY_IDS_START });

  const promises = await pages.map(async (page) => {
    const query = {
      filter: [{
        group: [{
          field: 'id',
          value: uniq(cIds),
          operator: 'in',
        }],
      }],
      pagination: {
        page: page + 1,
        limit: pagination.limit,
      },
    };

    const isPhysical = (document.location.href.indexOf('repricer') !== -1);
    const path = isPhysical ? physicalCategoriesSearchPath : categoriesSearchPath;
    const categoriesResult = await axios.post(path + '?ref=' + ref, { ...query });

    return camelizeKeys(categoriesResult.data.data);
  });

  const categoriesResult = await Promise.all(promises);
  const categories = categoriesResult.flat();

  const ids = categories.filter(c => c.pathByCategoryId).map((c) => {
    return c.pathByCategoryId.substr(0, c.pathByCategoryId.indexOf('/'));
  });

  const rootPromises = await uniq(ids).map(async (id) => {
    const isPhysical = (document.location.href.indexOf('repricer') !== -1);
    const path = isPhysical ? getPhysicalCategoriesExpandTreePath(id) : getCategoriesExpandTreePath(id);
    const rootCategoriesResult = await axios.get(`${path}?expand=3`);

    return camelizeKeys(rootCategoriesResult.data.data);
  });

  const rootCategoriesResult = await Promise.all(rootPromises);
  const totalRootResult = rootCategoriesResult.flat();
  const totalArray = [];

  const getChildrenItems = (array) => {
    const resp = array.map((arrayItem) => {
      totalArray.push(arrayItem);

      if (arrayItem.children) {
        getChildrenItems(arrayItem.children);
      }

      return arrayItem;
    });

    return resp;
  };

  getChildrenItems(totalRootResult);

  const treePayload = uniqBy(
    [...totalArray, ...treeItems],
    'id',
  );

  const filtered = treePayload.filter((c) => {
    const childrenCategoriesFirstLevel = treePayload
      .filter(cat => cat.parentId === c.id);
    const childrenCategorySecondLevel = childrenCategoriesFirstLevel
      ? treePayload.filter(cat => childrenCategoriesFirstLevel
        .some(item => cat.parentId === item.id))
      : null;
    const childrenCategoryThirdLevel = childrenCategorySecondLevel
      ? treePayload.filter(cat => childrenCategorySecondLevel
        .some(item => cat.parentId === item.id))
      : null;
    const includedItems = [
      ...childrenCategoriesFirstLevel,
      ...childrenCategorySecondLevel,
      ...childrenCategoryThirdLevel,
    ].filter(cat => cIds.includes(cat.id));

    return !isEmpty(includedItems);
  });
  const collapsePayload = uniq(filtered.map(c => c.id));
  const filteredtree = uniqBy([
    ...treePayload.filter(c => filtered.some(cat => c.parentId === cat.id)),
    ...treeItems,
  ], 'id');

  dispatch({
    type: FETCH_PARENT_CATEGORIES_BY_IDS_FINISHED,
    payload: {
      treeItems: filteredtree,
    },
  });
  await dispatch(handleLoadScopeSubcategories(collapsePayload));
  await dispatch(onToggleScopeCategoryTreeCollapse(collapsePayload));
};

export const setSelectedScopeCategoryId = categoryId => (dispatch) => {
  dispatch({
    type: SET_SELECTED_SCOPE_CATEGORY_ID,
    payload: {
      selectedScopeCategoryId: categoryId,
    }
  });
};

export const fetchParentScopeCategory = parentId => (dispatch) => {
  dispatch({
    type: FETCH_PARENT_SCOPE_CATEGORY_START,
    fetchParentScopeCategoryStarted: true,
    fetchParentScopeCategoryFinished: false,
  });

  const isPhysical = (document.location.href.indexOf('repricer') !== -1);
  const path = isPhysical ? getPhysicalCategoryPath(parentId) : getCategoryPath(parentId);

  return axios.get(path).then((res) => {
    const category = camelizeKeys(res.data.data);

    dispatch({
      type: FETCH_PARENT_SCOPE_CATEGORY_FINISHED,
      payload: {
        parentScopeCategory: category,
        fetchParentScopeCategoryStarted: false,
        fetchParentScopeCategoryFinished: true,
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_PARENT_SCOPE_CATEGORY_FINISHED,
      payload: {
        error: error.response.data, hasError: true,
        fetchParentScopeCategoryStarted: false,
        fetchParentScopeCategoryFinished: true,
      },
    });
  });
};

export default {
  fetchRootCategories,
  fetchChildCategories,
  clearScopeCategoriesState,
  fetchOne,
  onToggleScopeCategoryTreeCollapse,
  handleLoadScopeSubcategories,
  loadAllChildCategories,
  setSelectedScopeCategoryId,
  fetchParentScopeCategory,
};
