import { createSelector } from 'reselect';

const systemRootSelector = state => state;

const stateSelector = createSelector(
  systemRootSelector,
  state => ({
    modes: state.modes,
  }),
);

export const systemSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  systemSelector,
};
