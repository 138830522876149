/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { isEmpty, cloneDeep, uniqBy } from 'lodash';
import { camelize } from 'humps';

export const getCamelizeKey = (label = '') => (!label.toLowerCase().startsWith('display') ? camelize(`display_${label}`) : camelize(label));

export const getMappedVirtualFacetList = facetsList => Object.keys(facetsList).map(key => ({
  ...facetsList[key],
  key,
}));

export const getNewFacet = (prevFacet, nextFacet) => {
  const facet = {
    ...prevFacet,
    facetsList: {
      ...nextFacet.facetsList,
      ...prevFacet.facetsList,
    },
  };

  const groupedValues = {
    ...prevFacet.groupedValues,
    ...nextFacet.groupedValues,
  };

  if (!isEmpty(groupedValues)) {
    facet.groupedValues = groupedValues;
  }

  return facet;
};

const getUpdatedList = list => list.map((item) => {
  const filteredKeys = Object.keys(item).filter(k => k !== 'groupedValues');
  const value = {};

  filteredKeys.forEach((k) => { value[k] = item[k]; });
  return value;
});

export const getFacetPayload = ({ prevFacet, nextFacet, useRev = false }) => {
  const categoryFacet = cloneDeep(nextFacet.item);
  const extraList = !isEmpty(nextFacet.list) ? nextFacet.list : [];
  let currentFacetIndex = -1;
  let revNewList = [];

  if (!isEmpty(extraList)) {
    extraList.forEach((item, index) => {
      if (Object.values(prevFacet.facetsList).find(fi => fi.label.en === item.label.en)) {
        currentFacetIndex = index;
      }
    });
  }

  if (currentFacetIndex >= 0) {
    extraList[currentFacetIndex] = [...Object.values(prevFacet.facetsList)];
    revNewList = [...extraList].flat();
  } else {
    revNewList = [...extraList, ...Object.values(prevFacet.facetsList)].flat();
  }

  const newList = !useRev
    ? [...extraList, ...Object.values(prevFacet.facetsList)].flat()
    : revNewList.flat();
  const updatedFacet = [{
    ...prevFacet,
    ...categoryFacet,
    groupedValues: {
      ...categoryFacet.groupedValues,
      ...prevFacet.groupedValues,
    },
    facetsList: uniqBy(getUpdatedList(newList), 'label.en'),
  }];

  return updatedFacet;
};


export default {
  getMappedVirtualFacetList,
  getCamelizeKey,
  getNewFacet,
  getFacetPayload,
};
