/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { cloneDeep, isEmpty } from 'lodash';

const useRankingEffect = ({
  navigationItem, navigationFetched, setFilters, updateLoadedProductsIn,
  updateLoadedProductsNin, setProductsInIds, setProductsNotInIds, setMappedCategories,
  setSelectedCategories, setSelectedTreeItems, setIsSwitchOn,
}) => {
  useEffect(() => {
    if (navigationFetched) {
      const deepClonedSeacrhVirtualParams = cloneDeep(navigationItem.virtualParams);
      const isSwitchOn = !isEmpty(navigationItem.virtualParams.mappedCategories);

      setSelectedTreeItems([...deepClonedSeacrhVirtualParams.selectedCategories]);
      setSelectedCategories([...deepClonedSeacrhVirtualParams.selectedCategories]);
      setMappedCategories([...deepClonedSeacrhVirtualParams.mappedCategories]);
      setFilters(deepClonedSeacrhVirtualParams.filters);
      setIsSwitchOn(isSwitchOn);
    }

    if (
      !isEmpty(navigationItem.virtualParams)
      && !isEmpty(navigationItem.virtualParams.filters)
    ) {
      let productsInIds = [];
      let productsNotInIds = [];

      navigationItem.virtualParams.filters.forEach((f) => {
        if (!isEmpty(f.group)) {
          f.group.forEach((g) => {
            if (g.field === 'id' && g.operator === 'in') {
              productsInIds = g.value;
            }
            if (g.field === 'id' && g.operator === 'nin') {
              productsNotInIds = g.value;
            }
          });
        }
      });
      updateLoadedProductsIn([...productsInIds.map(p => ({ id: p }))]);
      updateLoadedProductsNin([...productsNotInIds.map(p => ({ id: p }))]);
      setProductsInIds([...productsInIds]);
      setProductsNotInIds([...productsNotInIds]);
    }
  }, [navigationFetched]);
};

export default useRankingEffect;
