/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { NotificationManager } from 'react-notifications';
import IntlMessages from '../../../../../util/IntlMessages';

import {
  mapPrefixToFilters,
} from '../utils/mappers';

import { defaultPagination } from '../../../utils/defaultData';

const usePreview = ({
  clearProductState,
  clearVirtualCategoryTreeState,
  clearVirtualCategoryState,
  setFields,
  setSelectedProducts,
  fetchCountProductsActivitiesByProductIds,
  setPagination,
  fetchProductList,
  finishProductEdit,
  injectProducts,
  setProductForEdit,
  setSelectedEntity,
  setIsHiddenProductsMode,
  setSelectedProduct,
  edit,
  editInProgress,
  isSaveAndClose,
  productUpdated,
  bulkDeleteProductLockedAttributesFinished,
  fetchedOne,
  fetchedPureProduct,
  item,
  list,
  previewFetched,
  pagination,
  compoundSearch,
  isHiddenProductsMode,
  hiddenProductsFilter,
  facetsPreFilters,
  searchQuery,
  pages,
  updatedMultiple,
  selectedCategory,
  selectedPromotion,
  selectedSearchFilter,
}) => {
  useEffect(() => () => {
    clearProductState();
    clearVirtualCategoryTreeState();
    clearVirtualCategoryState();
  }, []);

  useEffect(() => {
    setFields(edit);
  }, [editInProgress]);

  useEffect(() => {
    if (previewFetched) {
      setSelectedProducts([]);
      const productIds = list.map(p => p.id);

      if (!isEmpty(productIds)) {
        fetchCountProductsActivitiesByProductIds(productIds);
      }

      if (pagination.page > 1 && isEmpty(list)) {
        setPagination(defaultPagination);
        fetchProductList({
          viewMode: 'frontendWithParams',
          compoundSearch,
          filter: isHiddenProductsMode
            ? hiddenProductsFilter
            : mapPrefixToFilters(facetsPreFilters),
          pagination: defaultPagination,
          searchFilter: isHiddenProductsMode
            ? {}
            : searchQuery,
        });
      }

      if (pagination.page > 1 && pages <= 1) {
        setPagination(defaultPagination);
      }
    }
  }, [previewFetched]);

  useEffect(() => {
    if (isSaveAndClose) {
      finishProductEdit();
    }
    if (productUpdated) {
      setSelectedProducts([]);
      fetchCountProductsActivitiesByProductIds([edit.id]);

      setFields(edit);
    }
  }, [productUpdated]);

  useEffect(() => {
    if (bulkDeleteProductLockedAttributesFinished) {
      const productIds = list.map(p => p.id);
      if (!isEmpty(productIds)) {
        fetchCountProductsActivitiesByProductIds(productIds);
      }
    }
  }, [bulkDeleteProductLockedAttributesFinished]);

  useEffect(() => {
    if ((productUpdated || updatedMultiple)) {
      if (!isEmpty(edit)) {
        injectProducts([edit]);
      }
      NotificationManager.success(<IntlMessages id="preview.save.alert.productsUpdated" />);
    }
  }, [productUpdated, updatedMultiple]);

  useEffect(() => {
    if (fetchedOne) {
      setProductForEdit({
        edit: item,
        editInProgress: true,
      });
    }
  }, [fetchedOne]);

  useEffect(() => {
    if (fetchedPureProduct) {
      setSelectedProduct(item);
    }
  }, [fetchedPureProduct]);

  useEffect(() => {
    if (!isEmpty(selectedCategory)) setSelectedEntity('category');
    if (!isEmpty(selectedPromotion)) setSelectedEntity('promotion');
    if (!isEmpty(selectedSearchFilter)) setSelectedEntity('searchFilter');

    setIsHiddenProductsMode(false);
  }, [
    selectedCategory.id,
    selectedPromotion.id,
    selectedSearchFilter.id,
  ]);

  useEffect(() => {
    if (bulkDeleteProductLockedAttributesFinished) {
      setSelectedProducts([]);
    }
  }, [bulkDeleteProductLockedAttributesFinished]);

  useEffect(() => {
    if (!isEmpty(selectedCategory) && selectedCategory.id) {
      fetchProductList({
        viewMode: 'frontend',
        filter: [],
        pagination: defaultPagination,
        searchFilter: { categoryId: selectedCategory.id },
        ignoreFacets: false,
      });
    }
  }, [selectedCategory.id]);

  useEffect(() => {
    if (!isEmpty(selectedPromotion) && selectedPromotion.id) {
      fetchProductList({
        viewMode: 'frontend',
        filter: [],
        pagination: defaultPagination,
        searchFilter: { urlKey: selectedPromotion.urlKey },
        ignoreFacets: false,
      });
    }
  }, [selectedPromotion.id]);
};

export default usePreview;
