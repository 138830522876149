/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  shape, func,
} from 'prop-types';

import useCategoryEffects from './hooks/useCategory';
import useAttributeMatrixEffects from './hooks/useAttributeMatrix';
import useCategoryFacetEffects from './hooks/useCategoryFacet';
import useProductAttributesEffects from './hooks/productAttribute';
import usePreviewFilterAttributesEffects from './hooks/usePreviewFilterAttributes';
import useDefaultAttributeEffects from './hooks/useDefaultAttribute';
import usePromotionEffects from './hooks/usePromotion';
import useSearchFilterEffects from './hooks/useSearchFilter';
import usePreview from './hooks/usePreview';

import HeaderContent from './components/HeaderContent';
import SubHeaderContent from './components/SubHeaderContent';
import HeaderControls from './components/HeaderControls';
import PreviewLayout from '../../../../components/Layout/previewLayout';

import QuickEditContainer from '../../containers/QuickEditContainer';

import MainContent from './components/MainContent';
import Sidebar from '../../containers/Sidebar';
import Can from '../../../../components/Can';
import appPermissions from '../../../../util/appPermissions';

import {
  fetchRootCategories as fetchVirtualRootCategories,
  clearState as clearVirtualCategoryState,
  clearVirtualCategoryTreeState,
} from '../../../../actions/virtualCategory';

import {
  fetchPreviewFilterAttributes,
} from '../../../../actions/previewFilterAttribute';

import {
  fetchListDefaultAttributes,
} from '../../../../actions/defaultAttribute';

import {
  fetchCategoryFacetList,
  clearFromCategoryFacetState,
} from '../../../../actions/categoryFacet';

import {
  clearCategoriesAttributesState,
  fetchCertainProductAttributes,
  fetchProductAttributeSets,
} from '../../../../actions/productAttribute';
import {
  fetchList as fetchListAttributesMatrix,
} from '../../../../actions/attributesMatrix';

import {
  fetchPromotionList,
  clearFromPromotionState,
} from '../../../../actions/promotion';

import {
  setProductForEdit,
  setFields,
  finishProductEdit,
} from '../../../../actions/product';

import { virtualCategorySelector } from './selectors/category';
import { navigationSelector } from './selectors/navigation';
import { promotionSelector } from './selectors/promotion';
import { previewSelector } from './selectors/preview';
import { productSelector } from './selectors/product';
import { attributesMatrixSelector } from './selectors/attributesMatrix';
import { previewCategorySelector } from './selectors/previewCategory';
import { systemSelector } from './selectors/system';
import { virtualCategoryWidgetSelector } from './selectors/virtualCategoryWidget';
import { promotionWidgetSelector } from './selectors/promotionWidget';

import {
  fetchProductList,
  clearProductState,
  injectProducts,
  fetchCountProductsActivitiesByProductIds,
  setSelectedPromotion,
  setSelectedSearchFilter,
  setSelectedEntity,
  setPagination,
  setIsHiddenProductsMode,
  setSelectedProducts,
  setHiddenProductsExist,
  setHiddenProductsIds,
  setSelectedProduct,
} from '../../../../actions/preview';

import { setSelectedCategory } from '../../../../actions/virtualCategoryWidget';

const Home = memo((props) => {
  const {
    previewProps,
    virtualCategoryProps,
    attributesMatrixProps,
    previewCategoryProps,
    productProps,
    systemProps,
    promotionProps,
    navigationProps,
    virtualCategoryWidgetProps,
    promotionWidgetProps,
  } = props;

  useCategoryEffects({
    setHiddenProductsExist: props.setHiddenProductsExist,
    setHiddenProductsIds: props.setHiddenProductsIds,
    fetchVirtualRootCategories: props.fetchVirtualRootCategories,
    setSelectedCategory: props.setSelectedCategory,
    fetchProductList: props.fetchProductList,

    selectedCategory: virtualCategoryWidgetProps.selectedCategory,
    compoundSearch: previewProps.compoundSearch,
    isHiddenProductsMode: previewProps.isHiddenProductsMode,
    hiddenProductsFilter: previewProps.hiddenProductsFilter,
    pagination: previewProps.pagination,
    searchQuery: previewProps.searchQuery,
    selectedAction: previewProps.selectedAction,
    previewFetched: previewProps.fetched,
    facetsPreFilters: previewProps.facetsPreFilters,

    virtualCategoryUpdated: virtualCategoryProps.updated,
    virtualCategoryItem: virtualCategoryProps.item,
  });

  useAttributeMatrixEffects({
    fetchListAttributesMatrix: props.fetchListAttributesMatrix,

    attributesMatrixFetched: attributesMatrixProps.fetched,
    attributesMatrixFetching: attributesMatrixProps.fetching,
  });

  useCategoryFacetEffects({
    clearFromCategoryFacetState: props.clearFromCategoryFacetState,
    fetchCategoryFacetList: props.fetchCategoryFacetList,

    previewFetched: previewProps.fetched,
    ignoreFacets: previewProps.ignoreFacets,
    aggregation: previewProps.aggregation,
    aggregationValues: previewProps.aggregationValues,
    virtualFacet: previewProps.virtualFacet,

    previewCategoryUpdated: previewCategoryProps.updated,
  });

  useProductAttributesEffects({
    fetchProductAttributeSets: props.fetchProductAttributeSets,
    fetchCertainProductAttributes: props.fetchCertainProductAttributes,
    clearCategoriesAttributesState: props.clearCategoriesAttributesState,

    selectedAction: previewProps.selectedAction,
    selected: previewProps.selected,
    list: previewProps.list,
    fetchParentLevelChildrenProductsFinished: previewProps.fetchParentLevelChildrenProductsFinished,
    edit: productProps.edit,
    previewAttributes: systemProps.modes.preview,
  });

  usePreviewFilterAttributesEffects({
    fetchPreviewFilterAttributes: props.fetchPreviewFilterAttributes,
  });

  useDefaultAttributeEffects({
    fetchListDefaultAttributes: props.fetchListDefaultAttributes,
  });

  usePromotionEffects({
    setHiddenProductsExist: props.setHiddenProductsExist,
    setHiddenProductsIds: props.setHiddenProductsIds,
    fetchPromotionList: props.fetchPromotionList,
    clearFromPromotionState: props.clearFromPromotionState,
    fetchProductList: props.fetchProductList,
    setSelectedPromotion: props.setSelectedPromotion,

    selectedPromotion: promotionWidgetProps.selectedPromotion,
    compoundSearch: previewProps.compoundSearch,
    isHiddenProductsMode: previewProps.isHiddenProductsMode,
    hiddenProductsFilter: previewProps.hiddenProductsFilter,
    facetsPreFilters: previewProps.facetsPreFilters,
    pagination: previewProps.pagination,
    selectedAction: previewProps.selectedAction,
    previewFetched: previewProps.fetched,
    searchQuery: previewProps.searchQuery,

    promotionUpdated: promotionProps.updated,
    item: promotionProps.item,

    attributesMatrixList: attributesMatrixProps.list,
  });

  useSearchFilterEffects({
    setHiddenProductsExist: props.setHiddenProductsExist,
    setHiddenProductsIds: props.setHiddenProductsIds,
    fetchProductList: props.fetchProductList,
    setSelectedSearchFilter: props.setSelectedSearchFilter,

    selectedSearchFilter: previewProps.selectedSearchFilter,
    compoundSearch: previewProps.compoundSearch,
    isHiddenProductsMode: previewProps.isHiddenProductsMode,
    hiddenProductsFilter: previewProps.hiddenProductsFilter,
    facetsPreFilters: previewProps.facetsPreFilters,
    pagination: previewProps.pagination,
    searchQuery: previewProps.searchQuery,
    selectedAction: previewProps.selectedAction,
    previewFetched: previewProps.fetched,

    navigationUpdated: navigationProps.updated,
    item: navigationProps.item,
    attributesMatrixList: attributesMatrixProps.list,
  });

  usePreview({
    clearProductState: props.clearProductState,
    clearVirtualCategoryTreeState: props.clearVirtualCategoryTreeState,
    clearVirtualCategoryState: props.clearVirtualCategoryState,
    setFields: props.setFields,
    setSelectedProducts: props.setSelectedProducts,
    fetchCountProductsActivitiesByProductIds: props.fetchCountProductsActivitiesByProductIds,
    setPagination: props.setPagination,
    fetchProductList: props.fetchProductList,
    finishProductEdit: props.finishProductEdit,
    injectProducts: props.injectProducts,
    setProductForEdit: props.setProductForEdit,
    setSelectedEntity: props.setSelectedEntity,
    setIsHiddenProductsMode: props.setIsHiddenProductsMode,
    setSelectedProduct: props.setSelectedProduct,

    edit: productProps.edit,
    editInProgress: productProps.editInProgress,
    isSaveAndClose: productProps.isSaveAndClose,
    productUpdated: productProps.updated,
    bulkDeleteProductLockedAttributesFinished:
      productProps.bulkDeleteProductLockedAttributesFinished,
    fetchedOne: productProps.fetchedOne,
    fetchedPureProduct: productProps.fetchedPureProduct,
    item: productProps.item,

    list: previewProps.list,
    previewFetched: previewProps.fetched,
    pagination: previewProps.pagination,
    compoundSearch: previewProps.compoundSearch,
    isHiddenProductsMode: previewProps.isHiddenProductsMode,
    hiddenProductsFilter: previewProps.hiddenProductsFilter,
    facetsPreFilters: previewProps.facetsPreFilters,
    searchQuery: previewProps.searchQuery,
    pages: previewProps.pages,
    updatedMultiple: previewProps.updatedMultiple,
    selectedPromotion: promotionWidgetProps.selectedPromotion,
    selectedSearchFilter: previewProps.selectedSearchFilter,

    selectedCategory: virtualCategoryWidgetProps.selectedCategory,
  });

  const listDisplayModeClassName = `app-container-mode-${previewProps.displayMode} sm`;
  const appContentWrapperClassName = previewProps.displayMode === 'list'
    ? 'app-content-wrapper'
    : 'app-content-wrapper sm';

  return (
    <PreviewLayout
      wrapperClassName={previewProps.fetching ? 'container-loading' : ''}
      containerWrapperClassName={`preview ${appContentWrapperClassName} ${listDisplayModeClassName}`}
      horizontalMenu
      header={{
        className: 'header-mer',
        content: (
          <HeaderContent />
        ),
        subHeaderContent: <SubHeaderContent />,
        controls: (
          <Can
            do={appPermissions.preview.permissions.update}
            on={appPermissions.preview.name}
          >
            <HeaderControls />
          </Can>
        ),
      }}
      sidebar={{
        className: 'sidebar-mer sidebar-home-mer sidebar-scroll-secondary',
        content: <Sidebar />,
      }}
      main={{
        className: 'main-mer grid-wide',
        withoutScroll: true,
        content: (
          <>
            <MainContent />
            {
              (
                !previewProps.isHiddenProductsMode
                && (!isEmpty(virtualCategoryWidgetProps.selectedCategory)
                || !isEmpty(promotionWidgetProps.selectedPromotion)
                || !isEmpty(previewProps.selectedSearchFilter))
              ) && <QuickEditContainer facetFilter={[]} />
            }
          </>
        ),
      }}
    />
  );
});

Home.propTypes = {
  previewProps: shape().isRequired,
  productProps: shape().isRequired,
  systemProps: shape().isRequired,
  virtualCategoryProps: shape().isRequired,
  attributesMatrixProps: shape().isRequired,
  previewCategoryProps: shape().isRequired,
  promotionProps: shape().isRequired,
  navigationProps: shape().isRequired,
  virtualCategoryWidgetProps: shape().isRequired,
  promotionWidgetProps: shape().isRequired,

  setHiddenProductsExist: func.isRequired,
  setHiddenProductsIds: func.isRequired,
  fetchVirtualRootCategories: func.isRequired,
  setSelectedCategory: func.isRequired,
  fetchProductList: func.isRequired,
  fetchListAttributesMatrix: func.isRequired,
  clearFromCategoryFacetState: func.isRequired,
  fetchCategoryFacetList: func.isRequired,
  fetchProductAttributeSets: func.isRequired,
  fetchCertainProductAttributes: func.isRequired,
  clearCategoriesAttributesState: func.isRequired,
  fetchPreviewFilterAttributes: func.isRequired,
  fetchListDefaultAttributes: func.isRequired,
  fetchPromotionList: func.isRequired,
  clearFromPromotionState: func.isRequired,
  setSelectedPromotion: func.isRequired,
  setSelectedSearchFilter: func.isRequired,
  clearProductState: func.isRequired,
  clearVirtualCategoryTreeState: func.isRequired,
  clearVirtualCategoryState: func.isRequired,
  setFields: func.isRequired,
  setSelectedProducts: func.isRequired,
  fetchCountProductsActivitiesByProductIds: func.isRequired,
  setPagination: func.isRequired,
  finishProductEdit: func.isRequired,
  injectProducts: func.isRequired,
  setProductForEdit: func.isRequired,
  setSelectedEntity: func.isRequired,
  setIsHiddenProductsMode: func.isRequired,
  setSelectedProduct: func.isRequired,
};
Home.defaultProps = {};

export const mapStateToProps = state => ({
  previewProps: previewSelector(state.preview),
  virtualCategoryProps: virtualCategorySelector(state.virtualCategory),
  productProps: productSelector(state.product),
  attributesMatrixProps: attributesMatrixSelector(state.attributesMatrix),
  previewCategoryProps: previewCategorySelector(state.previewCategory),
  systemProps: systemSelector(state.system),
  promotionProps: promotionSelector(state.promotion),
  navigationProps: navigationSelector(state.search),
  virtualCategoryWidgetProps: virtualCategoryWidgetSelector(state.virtualCategoryWidget),
  promotionWidgetProps: promotionWidgetSelector(state.promotionWidget),
});

export const mapDispatchToProps = {
  setHiddenProductsExist,
  setHiddenProductsIds,
  fetchVirtualRootCategories,
  setSelectedCategory,
  fetchProductList,
  fetchListAttributesMatrix,
  clearFromCategoryFacetState,
  fetchCategoryFacetList,
  fetchProductAttributeSets,
  fetchCertainProductAttributes,
  clearCategoriesAttributesState,
  fetchPreviewFilterAttributes,
  fetchListDefaultAttributes,
  fetchPromotionList,
  clearFromPromotionState,
  setSelectedPromotion,
  setSelectedSearchFilter,
  clearProductState,
  clearVirtualCategoryTreeState,
  clearVirtualCategoryState,
  setFields,
  setSelectedProducts,
  fetchCountProductsActivitiesByProductIds,
  setPagination,
  finishProductEdit,
  injectProducts,
  setProductForEdit,
  setSelectedEntity,
  setIsHiddenProductsMode,
  setSelectedProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
