
import React, { Fragment } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import {
  string, arrayOf, shape, number, oneOfType, func, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import IntlMessages from '../../util/IntlMessages';

const ListVertical = ({
  className, list, type, gap, size, dir, isActive, onClick, icon, disabled,
  hideDivider, handleRightClick, handleGetPrevPage, handleGetNextPage, titleId, pagination, searchValue
}) => {
  const listType = type ? ` list-${type}` : '';
  const listDir = dir ? ` list-${dir}` : '';
  const listSize = size ? ` list-${size}` : '';
  const listGap = gap ? ` list-${gap}` : '';
  const activeClassName = isActive ? ' list-item-active' : '';
  const noEventClass = isEmpty(list) ? 'no-event' : '';

  const PrevLink = (props) => {
    let isPrevLink = false;
    if (!searchValue && props.titleId === 'text.searchFilters' && pagination.navigation.currentPage > 1) {
      isPrevLink = true;
    }
    if (!searchValue && props.titleId === 'text.promotionFilters' && pagination.promotion.currentPage > 1) {
      isPrevLink = true;
    }

    if (isPrevLink === false) {
      return (<></>);
    }

    return (
      <Fragment key="prev">
        <ListItem
          className="list-navigation-left"
          button
          onClick={(e) => handleGetPrevPage(e, props.titleId)}
          disabled={props.disabled}
        >
          <ListItemText className="pagination-link" primary="<<< Prev" />
        </ListItem>
      </Fragment>
    );
  };

  const NextLink = (props) => {
    let isPrevLink = false;
    if (!searchValue && props.titleId === 'text.searchFilters' && pagination.navigation.currentPage < pagination.navigation.allPages) {
      isPrevLink = true;
    }
    if (!searchValue && props.titleId === 'text.promotionFilters' && pagination.promotion.currentPage < pagination.promotion.allPages) {
      isPrevLink = true;
    }

    if (isPrevLink === false) {
      return (<></>);
    }

    return (
      <Fragment key="next">
        <ListItem
          className="list-navigation-right"
          button
          onClick={(e) => handleGetNextPage(e, props.titleId)}
          disabled={props.disabled}
        >
          <ListItemText className="pagination-link" primary="Next >>>" />
        </ListItem>
      </Fragment>
    );
  };

  return (
    <nav className={`list-wrapper ${noEventClass}`}>
      <List className={`list ${className}${listType}${listGap}${listSize}${listDir} ${activeClassName}`}>
        {list && !isEmpty(list) ? list.map(item => (
          <Fragment key={item.id || item.value || item.title}>
            <ListItem
              onContextMenu={e => handleRightClick && handleRightClick(e, item.id)}
              button
              onClick={() => onClick(item)}
              disabled={disabled}
            >
              {icon && <ListItemIcon><i className={icon} /></ListItemIcon>}
              <ListItemText primary={item.label} />
            </ListItem>
            {!hideDivider && <Divider />}
          </Fragment>
        )) : (
          <>
            <ListItem className={`no-event default-list-item ${noEventClass}`} disabled={disabled}>
              <IntlMessages id="title.noItems" />
            </ListItem>
            <Divider />
          </>
        )}

        <PrevLink titleId={titleId} disabled={disabled} />
        <NextLink titleId={titleId} disabled={disabled} />

      </List>
    </nav>
  );
};

ListVertical.propTypes = {
  className: string,
  isActive: bool,
  size: string,
  dir: string,
  type: string,
  icon: string,
  onClick: func,
  list: oneOfType([
    arrayOf(shape({
      id: oneOfType([number, string]),
      key: string,
    })),
    func,
  ]),
  gap: number,
  disabled: bool,
  hideDivider: bool,
  handleRightClick: func,
  handleGetPrevPage: func,
  handleGetNextPage: func,
};

ListVertical.defaultProps = {
  className: '',
  isActive: false,
  type: '',
  dir: '',
  size: '',
  list: null,
  gap: null,
  onClick: null,
  icon: null,
  disabled: false,
  hideDivider: false,
  handleRightClick: null,
  handleGetPrevPage: null,
  handleGetNextPage: null,
};

export default ListVertical;
