import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import {
  string, node, oneOfType, number, bool,
} from 'prop-types';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import IntlMessages from '../../util/IntlMessages';


export default class InputErrors extends Component {
  static propTypes = {
    content: oneOfType([node, string]),
    buttonText: oneOfType([node, string]),
    positionTop: number,
    positionLeft: number,
    anchorReference: string,
    anchorOriginVertical: string,
    anchorOriginHorizontal: string,
    transformOriginHorizontal: string,
    transformOriginVertical: string,
    buttonClassName: string,
    popoverClassName: string,
    disabled: bool,
  };

  static defaultProps = {
    positionTop: 0,
    positionLeft: 0,
    content: null,
    buttonText: 'popover.buttonDefault',
    anchorReference: 'anchorEl',
    anchorOriginVertical: 'center',
    anchorOriginHorizontal: 'center',
    transformOriginHorizontal: 'left',
    transformOriginVertical: 'center',
    buttonClassName: '',
    popoverClassName: '',
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClickButton = () => {
    this.setState({
      open: true,
      anchorEl: findDOMNode(this.button),
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  button = null;

  render() {
    const { open, anchorEl } = this.state;
    const {
      anchorReference, positionTop, positionLeft,
      anchorOriginVertical, anchorOriginHorizontal,
      transformOriginVertical, transformOriginHorizontal,
      content, buttonText, buttonClassName, popoverClassName,
      disabled,
    } = this.props;
    return (
      <div className="form-errors">
        <Button
          ref={(refNode) => { this.button = refNode; }}
          variant="contained"
          color="primary"
          className={`text-white ${buttonClassName}`}
          onClick={this.handleClickButton}
          disabled={disabled}
        >
          {typeof buttonText === 'string' ? <IntlMessages id={buttonText} /> : buttonText}
        </Button>
        <Popover
          className={popoverClassName}
          open={open}
          anchorEl={anchorEl}
          anchorReference={anchorReference}
          anchorPosition={{ top: positionTop, left: positionLeft }}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: anchorOriginVertical, horizontal: anchorOriginHorizontal }}
          transformOrigin={{
            vertical: transformOriginVertical, horizontal: transformOriginHorizontal,
          }}
        >
          {content}
        </Popover>
      </div>
    );
  }
}
