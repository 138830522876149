/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment, useEffect, useState, useContext,
} from 'react';
import { withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../../components/Layout';
import List from '../../components/List';
import Sidebar from './components/Sidebar';
import New from './containers/New';
import PagePlaceholder from '../../components/PagePlaceholder';
import IntlMessages from '../../util/IntlMessages';
import Edit from './containers/Edit';
import { headerList } from '../utils/headerList';
import { AbilityContext } from '../../components/AbilityContext';
import appPermissions from '../../util/appPermissions';

import {
  rnkingsPath,
  newRankingPath,
  getEditRankingPath,
  editRankingPath,
} from './utils/paths';
import { clearVirtualCategoryTreeState } from '../../actions/virtualCategory';
import {
  fetchList as fetchRankingList,
  fetchItem as fetchRanking,
} from '../../actions/ranking';
import { propTypes, defaultProps } from './propTypes';
import { clearScopeCategoryTreeState } from '../../actions/scopeCategory';
import { fetchList as fetchListAttributesMatrix } from '../../actions/attributesMatrix';

export const RankingPage = (props) => {
  const abilityContext = useContext(AbilityContext);
  const [filteredRankingList, setFilteredRankingList] = useState([]);
  const [searchValue, setSarchValue] = useState('');

  useEffect(() => () => {
    props.clearVirtualCategoryTreeState();
    props.clearScopeCategoryTreeState();
  }, []);

  useEffect(() => {
    props.fetchRankingList();
    props.fetchListAttributesMatrix();
  }, []);

  useEffect(() => {
    if (props.ranking.fetched) {
      setFilteredRankingList(props.ranking.list);
      setSarchValue('');
    }
  }, [props.ranking.fetched]);

  const navigateToEditRanking = (item) => {
    props.fetchRanking(item.id);
    props.history.push(getEditRankingPath(item.id));
  };

  const navigateToNewRanking = () => {
    props.history.push(newRankingPath);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    const { list } = props.ranking;
    const newFilteredRankingList = list.filter(
      r => r.name.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredRankingList(newFilteredRankingList);
    setSarchValue(value);
  };

  const {
    updating, fetchingOne, creating, deleting,
  } = props.ranking;
  const noEventClass = updating || fetchingOne || creating || deleting ? 'disabled no-event' : '';

  return (
    <Layout
      horizontalMenu
      header={{
        className: 'header-mer',
        subHeaderContent: (
          <List
            dir="horizontal"
            className="justify-center"
            dataSource={headerList({ abilityContext })}
          />
        ),
      }}
      sidebar={{
        className: `sidebar-mer sidebar-ranking-mer sidebar-scroll-secondary ${noEventClass}`,
        content: <Sidebar
          search={{
            value: searchValue,
            onChange: handleSearch,
          }}
          rankingList={filteredRankingList}
          onSelectListItem={navigateToEditRanking}
          navigateToNewRanking={navigateToNewRanking}
        />,
      }}
      main={{
        className: 'main-mer main-ranking-mer',
        content: (
          <Fragment>
            <Route
              exact
              path={rnkingsPath}
              render={() => (
                <PagePlaceholder
                  icon={<i className="ti-arrow-circle-left text-primary text-lg" />}
                  title={<IntlMessages id="rankings.placeholder.title" />}
                  subtitle={<IntlMessages id="rankings.placeholder.subTitle" />}
                />
              )}
            />

            {abilityContext.can(
              appPermissions.ranking.permissions.view,
              appPermissions.ranking.name,
            ) && <Route exact path={editRankingPath} component={Edit} />}
            {abilityContext.can(
              appPermissions.ranking.permissions.create,
              appPermissions.ranking.name,
            ) && <Route exact path={newRankingPath} component={New} />}
          </Fragment>
        ),
      }}
    />
  );
};

RankingPage.propTypes = propTypes;

RankingPage.defaultProps = defaultProps;

const mapStateToProps = ({
  ranking,
}) => ({
  ranking,
});

const actionCreators = {
  fetchRankingList,
  fetchRanking,
  fetchListAttributesMatrix,
  clearVirtualCategoryTreeState,
  clearScopeCategoryTreeState,
};

export default withRouter(connect(mapStateToProps, actionCreators)(RankingPage));
