import React from 'react'
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';

import IntlMessages from 'util/IntlMessages';
import {setPriceFormulaModal} from 'actions/pim.js'

import classes from './styles.module.scss';

const OpenProductPriceModal = ({children, button, priceFormula, setPriceFormulaModal}) => {
        
    const handleOpen = () => {
        setPriceFormulaModal({isOpen: true, priceFormula, loading: false})
    }

    return (
        <div className={classes.productPriceContainer} style={button ? {width: "auto", margin: "0 5px"} : {}}>
            {!!children && !button && <div className={classes.children} onClick={() => handleOpen()}>{children}</div>}
            {button && (            
                <Button
                    variant="contained"
                    onClick={() => handleOpen()}
                    color="primary"
                    className="text-white"
                    style={{backgroundColor: '#2b629b'}}
                >
                    <IntlMessages id="button.explainPrice" />
                </Button>
            )}

        </div>
    )
}

const mapStateToProps = state => (state);

const mapDispatchToProps = {
    setPriceFormulaModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenProductPriceModal);
