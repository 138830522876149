/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useCategoryFacet = ({
  clearFromCategoryFacetState,
  fetchCategoryFacetList,
  previewFetched,
  ignoreFacets,
  aggregation,
  aggregationValues,
  virtualFacet,
  previewCategoryUpdated,
}) => {
  useEffect(() => {
    if (
      (previewFetched && !ignoreFacets)
      || previewCategoryUpdated
    ) {
      fetchCategoryFacetList({
        aggregation,
        aggregationValues,
        virtualFacet,
      }, {
        isAttributesMatrix: true,
      });
    }
  }, [
    previewFetched,
    previewCategoryUpdated,
  ]);

  useEffect(() => () => {
    clearFromCategoryFacetState({
      fetching: false,
      fetched: false,
      list: [],
    });
  }, []);
};

export default useCategoryFacet;
