import React from 'react';
import {
  func, shape, bool, node,
} from 'prop-types';

import Form from '../../../../components/Form';
import IntlMessages from '../../../../util/IntlMessages';

const AttributesRow = ({
  values, disabled, onChange, content, errors, permanentlyDisabled, exportable,
}) => {

  const checkboxes = exportable
    ? {
      element: 'checkbox',
      className: 'checkbox-wrapper inner-label-hide',
      name: 'exportable',
      checked: values.exportable || false,
      disabled,
    } : {
      element: 'checkbox',
      className: 'checkbox-wrapper inner-label-hide',
      name: 'exportable',
      checked: values.exportable || false,
      disabled,
    };

  return (
    <div className="attributes-row flex items-center system-attributes">
      <Form
        className="form-compact flex-1 no-margin"
        onChange={onChange}
        gap={0}
        fields={[
          [{
            element: 'matInput',
            className: 'select-wrapper inner-label-hide',
            name: 'label',
            id: values.key,
            value: values.label || '',
            disabled,
            helperText: errors.label && errors.label.some(err => err[values.key])
              ? <IntlMessages id="system.preview.itemIsRequired" />
              : null,
          }, checkboxes],
        ]}
      />
      {content}
    </div>
  );
};

AttributesRow.propTypes = {
  disabled: bool,
  onChange: func,
  values: shape(),
  content: node,
  errors: shape(),
  permanentlyDisabled: bool,
};

AttributesRow.defaultProps = {
  disabled: false,
  onChange: null,
  values: {},
  content: null,
  errors: {},
  permanentlyDisabled: false,
};

export default AttributesRow;
