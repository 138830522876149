import { createSelector } from 'reselect';

const virtualCategoryWidgetRootSelector = state => state;

const stateSelector = createSelector(
  virtualCategoryWidgetRootSelector,
  state => ({
    selectedCategory: state.selectedCategory,
  }),
);

export const virtualCategoryWidgetSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  virtualCategoryWidgetSelector,
};
