import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const previewListSelector = state => state.list;
const previewItemSelector = state => state.item;
const previewSelectedCategorySelector = state => state.selectedCategory;
const previewRootSelector = state => state;

const mapProductsImages = (images) => {
  if (!images) return [];
  return ([
    ...images.map((img) => {
      const params = '?auto=format%2Ccompress&bg=transparent&fill=solid&fit=fillmax&h=220&trim=auto&trimsd=1&w=auto';
      return {
        ...img,
        url: `${img.url}${params}`,
      };
    }),
  ]);
};

const mapProductsOverride = (virtualParams) => {
  if (!virtualParams) return {};
  return ({
    ...virtualParams,
    productsOverride: virtualParams.positions
      ? Object.keys(virtualParams.positions)
        .map(p => ({ productId: p, position: virtualParams.positions[p] }))
      : [],
  });
};

const listSelector = createSelector(
  previewListSelector,
  list => (!isEmpty(list) ? list.reduce((acc, current) => {
    acc.push({
      ...current,
      images: mapProductsImages(current.images),
      variants: !isEmpty(current.variants)
        ? current.variants.map(v => ({
          ...v,
          images: mapProductsImages(v.images),
        }))
        : [],
    });
    return acc;
  }, []) : []),
);

const itemSelector = createSelector(
  previewItemSelector,
  item => (!isEmpty(item) ? ({
    ...item,
    images: mapProductsImages(item.images),
  }) : {}),
);

const selectedCategorySelector = createSelector(
  previewSelectedCategorySelector,
  selectedCategory => (!isEmpty(selectedCategory) ? ({
    ...selectedCategory,
    virtualParams: mapProductsOverride(selectedCategory.virtualParams),
  }) : {}),
);

export const previewSelector = createSelector(
  listSelector,
  itemSelector,
  selectedCategorySelector,
  previewRootSelector,
  (list, item, selectedCategory, state) => ({
    list,
    item,
    selectedCategory,
    compoundSearch: state.compoundSearch,
    isHiddenProductsMode: state.isHiddenProductsMode,
    hiddenProductsFilter: state.hiddenProductsFilter,
    pagination: state.pagination,
    searchQuery: state.searchQuery,
    selectedAction: state.selectedAction,
    fetched: state.fetched,
    facetsPreFilters: state.facetsPreFilters,
    ignoreFacets: state.ignoreFacets,
    aggregation: state.aggregation,
    aggregationValues: state.aggregationValues,
    virtualFacet: state.virtualFacet,
    selected: state.selected,
    fetchParentLevelChildrenProductsFinished: state.fetchParentLevelChildrenProductsFinished,
    selectedPromotion: state.selectedPromotion,
    selectedSearchFilter: state.selectedSearchFilter,
    pages: state.pages,
    updatedMultiple: state.updatedMultiple,
    fetchedSelectedCategory: state.fetchedSelectedCategory,
    displayMode: state.displayMode,
    productsCommentsCount: state.productsCommentsCount,
    productComments: state.productComments,
    defaultImage: state.defaultImage,
    fetchProductCommentsStart: state.fetchProductCommentsStart,
    productsImagesOverride: state.productsImagesOverride,
  }),
);

export default {
  previewSelector,
};
