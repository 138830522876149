/**
 * App Info Theme
 */
import { createTheme } from '@material-ui/core/styles';
import AppConfig from '../../constants/AppConfig';

const theme = createTheme({
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true,
  },
  palette: {
    primary: {
      main: AppConfig.themeColors.info
    },
    secondary: {
      main: AppConfig.themeColors.primary
    }
  }
});

export default theme;