export const getRankingByNameQuery = rankingName => ({
  filter: [{
    condition: 'and',
    group: [{
      field: 'name',
      value: rankingName,
      operator: 'eq',
    }],
  }],
});

export default {
  getRankingByNameQuery,
};
