/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useState, useContext
} from 'react';
import { func, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../../components/Layout';
import List from '../../components/List';
import SidebarContent from '../Pim/pages/Home/components/SidebarContent';
import { setFilterTarget } from '../../actions/pim';
import RepricingTable from './components/RepricingTable';
import { getRepricingData } from '../../actions/repricer';
import { headerList } from '../utils/headerList';
import { AbilityContext } from '../../components/AbilityContext';
import { fetchAttributes } from '../../actions/system';
import { sortFields } from "../../util/fields";

export const Repricing = (props) => {
  const abilityContext = useContext(AbilityContext);
  const [firstLoad, setFirstLoad] = useState(false);
  const [mapperData, setMapperData] = useState([]);
  const [mapperScroll, setMapperScroll] = useState([]);
  const [mapperReset, setMapperReset] = useState(false);
  const [mapperCondition, setMapperCondition] = useState({});
  const [mapperItemsPerPage, setMapperItemsPerPage] = useState(20);

  const repricingFields = props.repricingFields;
  const sortedFields = sortFields(repricingFields, 'order');
  const sortedSearchFields = sortFields(repricingFields, 'searchableOrder');

  useEffect(() => {
    props.fetchAttributes();
    props.setFilterTarget('repricing');
    if (props.repricingPim.pim.repricing.data) {
      setMapperData(props.repricingPim.pim.repricing.data);
    }
    if (props.repricingPim.pim.repricing.scroll) {
      setMapperScroll(props.repricingPim.pim.repricing.scroll);
    }
    setMapperReset(false);
    if (props.repricingPim.pim.repricing.reset === true) {
      setMapperReset(true);
    }
    if (props.repricingPim.pim.repricing.condition) {
      setMapperCondition(props.repricingPim.pim.repricing.condition);
    }
    if (props.repricingPim.pim.repricing.itemsPerPage) {
      setMapperItemsPerPage(props.repricingPim.pim.repricing.itemsPerPage);
    }
  }, [
    props.repricingPim.pim.repricing, props.setFilterTarget
  ]);

  if (firstLoad === false) {
    const storedSearchFilters = sessionStorage.getItem('pim.searchFilters');
    const storedSearchFields = sessionStorage.getItem('pim.searchFields');
    const parsedStoredSearchFilters = JSON.parse(storedSearchFilters) || { repricing: [] };
    const parsedStoredSearchFields = JSON.parse(storedSearchFields) || { repricing: [] };
    if (parsedStoredSearchFilters.repricing.length === 0 && parsedStoredSearchFields.repricing.length === 0) {
      setTimeout(function(){
        props.getRepricingData({limit: mapperItemsPerPage, offset: 0});
      }, 1000);
    }
    setFirstLoad(true);
  }

  return (
    <Layout
      horizontalMenu
      header={{
        className: 'header-mer',
        subHeaderContent: (
          <List
            dir="horizontal"
            className="justify-center"
            dataSource={headerList({ abilityContext })}
          />
        ),
      }}
      sidebar={{
        className: `sidebar-mer sidebar-ranking-mer sidebar-scroll-secondary`,
        content: <SidebarContent
          sortedFields={sortedFields}
          sortedSearchFields={sortedSearchFields}
          condition={mapperCondition}
          scroll={mapperScroll}
          getRepricingData={props.getRepricingData}
          reset={mapperReset}
          itemsPerPage={mapperItemsPerPage}
        />,
      }}
      main={{
        className: 'main-mer main-ranking-mer',
        content: (
          <RepricingTable
            getRepricingData={props.getRepricingData}
            repricingFields={sortedFields}
            mapperData={mapperData}
            mapperScroll={mapperScroll}
            mapperCondition={mapperCondition}
            mapperItemsPerPage={mapperItemsPerPage}
          />
        )
      }}
    />
  );
};

Repricing.propTypes = {
  setFilterTarget: func.isRequired,
  getRepricingData: func.isRequired,
  repricingPim: shape().isRequired,
  fetchAttributes: func.isRequired,
  repricingFields: shape(),
};

Repricing.defaultProps = {
  repricingFields: {},
};

const mapStateToProps = state => ({
  repricingFields: state.system.modes.repricing,
  repricingPim: state,
});

const mapDispatchToProps = {
  setFilterTarget,
  getRepricingData,
  fetchAttributes
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Repricing));
