export default (gridMode) => {
  switch (gridMode) {
    case 'grid_4_per_row':
      return 3;
    case 'grid_5_per_row':
      return 4;
    case 'grid_6_per_row':
      return 2;
    default:
      return 2;
  }
};
