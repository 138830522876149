import React from 'react';

import IntlMessages from '../../../../util/IntlMessages';
import Alert from '../../../../components/Alert';

export const AlertWarning = () => (
  <Alert
    color="warning"
    header={<IntlMessages id="alert.warningTitle" />}
    body={<IntlMessages id="alert.import.warningBody" />}
  />
);


export default {
  AlertWarning,
};
