import firebase from 'firebase/app';
import RestActions from '../util/rest/actions';

import { facetInputTypesPath } from '../util/paths';
import { showErrorNotification } from '../util/api';

const restFacetInputTypeActions = new RestActions('facet_input_types');

export const fetchFacetInputTypeList = () => async (dispatch) => {
  dispatch(restFacetInputTypeActions.fetchAllStart());

  let facetInputTypes;
  try {
    facetInputTypes = await firebase.database().ref(facetInputTypesPath).once('value');
  } catch (error) {
    showErrorNotification(error, 'Firebase');
    dispatch(restFacetInputTypeActions.fetchAllFinished({ hasError: true }));
  }

  const restfacetInputTypes = facetInputTypes.val();

  dispatch(restFacetInputTypeActions.fetchAllFinished({ list: restfacetInputTypes }));
};

export default {
  fetchFacetInputTypeList,
};
