/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { func, shape, string } from 'prop-types';
import Search from '../../../../../components/Search';
import { getMergingProductById } from '../../../../../actions/product';
import { NotificationManager } from "react-notifications";

const TargetProduct = memo((props) => {

  const [targetProductId, setTargetProductId] = useState('');

  const handleSearchTargetProduct = (e) => {
    setTargetProductId(e.target.value);
  };

  const handleGetSearchTargetProduct = () => {
    const re = /^prod_([a-zA-Z0-9])+/;
    if (!targetProductId || !re.test(targetProductId)) {
      NotificationManager.error('Please enter a valid product ID');
    } else {
      props.getMergingProductById({ id: targetProductId });
    }
  };

  const handleSearchTargetProductKeyPress = (e) => {
    const { charCode } = e;

    if (charCode === 13) handleGetSearchTargetProduct();
  };

  return (
    <>
    <Search
      onChange={handleSearchTargetProduct}
      onSearchKeyPress={handleSearchTargetProductKeyPress}
      onSearch={handleGetSearchTargetProduct}
      isShowSearchButton={true}
      value={targetProductId}
      key="targetProductIdSearch"
      className="search-preview"
      wrapperClassName="product-header-search flex"
      errorMessage="text.helper.cantBeBlank"
      queryLengthLimit={120}
      placeholder="Search target product by ID"
    />
    <hr />
    </>
  );
});

TargetProduct.propTypes = {
  getMergingProductById: func.isRequired,
  mergeSearchedProduct: shape(),
  product: shape({
    id: string
  }),
  targetProduct: shape({
    id: string
  })
};

TargetProduct.defaultProps = {};

const mapStateToProps = state => ({
  mergeSearchedProduct: state.product.mergeSearchedProduct,
});

const mapDispatchToProps = {
  getMergingProductById
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TargetProduct));
