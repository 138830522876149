import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { camelize } from 'humps';
import {
  func,
  shape,
} from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';

import {
  IconButton, Tooltip, Button, TableCell,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines } from '@fortawesome/free-solid-svg-icons';
import { sortableHandle } from 'react-sortable-hoc';
import config from '../../../../util/config';
import { setMerchantActivityDialog, setShowZapInfo } from '../../../../actions/pim';

import IntlMessages from '../../../../util/IntlMessages';
import Checkbox from '../../../../components/Checkbox';

import ShowMore from '../ShowMore';
import MergeProductsDialog from '../MergeProductsDialog';
import ProductActionsDialog from '../ProductActionsDialog';
import { getPublishPrice, moneyFormat } from '../../pages/Home/utils/productDataGenerator';
import { getProductIdFromURL } from "../../utils/sidebar";
import FormDialog from '../../../../components/FormDialog';
import { bulkUpdateProductStatus } from "../../../../actions/product";

const renderValue = (code, itemValue, urlKey, handleShowZapInfo) => {
  if (!itemValue || itemValue === '-') {
    return 'N/A';
  }

  if (code === 'id' && urlKey) {
    const linkToProduct = `${config.websitePath}/${urlKey.en}/${itemValue}`;

    return (<a href={linkToProduct} target="_blank" rel="noopener noreferrer">{itemValue}</a>);
  }

  if (code === 'zmid') {
    const linkToProduct = config.zapWebsitePath.replace('%id%', itemValue[0]);

    return (<a href={linkToProduct} target="_blank" rel="noopener noreferrer">zap.co.il</a>);
  }

  if (code === 'price_enrichment.competitors.zap.price' || code === 'price_enrichment.competitors.zap.max_price') {
    return (<span className="zap-link" onClick={handleShowZapInfo}>{itemValue}</span>);
  }

  if (typeof itemValue === 'string') {
    return String(itemValue).slice(0, 90);
  }

  if (Number.isFinite(itemValue) || (typeof itemValue === 'object' && !Array.isArray(itemValue))) {
    return itemValue;
  }

  if (Array.isArray(itemValue)) {
    return itemValue.join(', ');
  }

  return 'N/A';
};

const ListTreetItem = memo((props) => {
  const { itemProps } = props;

  const [isOpenedActionsPicker, setIsOpenedActionsPicker] = useState(false);
  const [isOpenedMergeDialog, setIsOpenMergeDialog] = useState(false);
  const [isOpenedProductActions, setIsOpenedProductActions] = useState(false);
  const [productActionType, setProductActionType] = useState(null);
  const [isOpenedSetInStockDialog, setIsOpenSetInStockDialog] = useState(false);
  const [isOpenedSetOOSDialog, setIsOpenSetOOSDialog] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const { childrenIds, variantsLoaded } = itemProps.productItem;
  const DragHandle = sortableHandle(() => <FontAwesomeIcon size="lg" icon={faGripLines} />);
  const attrLgSet = new Set(['description', 'description.en', 'title', 'locked_attributes']);

  window.addEventListener('click', () => {
    setIsOpenedActionsPicker(false);
  });

  const showActionsPicker = () => {
    if (isOpenedActionsPicker === false) {
      setTimeout(() => {
        setIsOpenedActionsPicker(true);
      }, 100);
    } else {
      setIsOpenedActionsPicker(false);
    }
  }

  const handleExpandProduct = () => {
    if (!itemProps.attributeSets.some(set => set.id === itemProps.productItem.attributeSet)) {
      itemProps.fetchProductAttributeSets({ ids: [itemProps.productItem.attributeSet] });
    }

    if (variantsLoaded) {
      itemProps.setCollapsed(
        itemProps.collapsed.includes(itemProps.productItem.id)
          ? itemProps.collapsed.filter(c => c !== itemProps.productItem.id)
          : [...itemProps.collapsed, itemProps.productItem.id],
      );
    } else {
      itemProps.handleExpandParentLevel(itemProps.productItem);
      itemProps.setCollapsed([...itemProps.collapsed, itemProps.productItem.id]);
    }
  };

  if (isFirstLoad === true && props.pim.origList && props.pim.origList.length === 1) {
    setIsFirstLoad(false);
    const productId = getProductIdFromURL();
    if (productId !== null) {
      handleExpandProduct();
    }
  }

  const handleShowZapInfo = () => {
    props.setShowZapInfo(itemProps.productItem);
  };

  const handleMergeProductsClick = () => {
    setIsOpenMergeDialog(true);
  };

  const handleCloseMergeProductsDialog = () => {
    setIsOpenMergeDialog(false);
  };

  const handleCloseProductActionsDialog = () => {
    setIsOpenedProductActions(false);
  };

  const handleSetInStock = () => {
    setIsOpenSetInStockDialog(true);
  };

  const handleCloseSetInStockDialog = () => {
    setIsOpenSetInStockDialog(false);
  };

  const submitSetInStock = () => {
    props.bulkUpdateProductStatus(itemProps.productItem.id, 'in_stock');
    setIsOpenSetInStockDialog(false);
  };

  const handleSetOOS = () => {
    setIsOpenSetOOSDialog(true);
  };

  const handleCloseSetOOSDialog = () => {
    setIsOpenSetOOSDialog(false);
  };

  const submitOOS = () => {
    props.bulkUpdateProductStatus(itemProps.productItem.id, 'out_of_stock');
    setIsOpenSetOOSDialog(false);
  };

  const handleRemoveProduct = () => {
    setProductActionType('remove');
    setIsOpenedProductActions(true);
  };

  const handleRestoreProduct = () => {
    setProductActionType('restore');
    setIsOpenedProductActions(true);
  };

  const handleMarkAsReviewed = () => {
    setProductActionType('markAsReviewed');
    setIsOpenedProductActions(true);
  };

  const handleMarkAsNotReviewed = () => {
    setProductActionType('markAsNotReviewed');
    setIsOpenedProductActions(true);
  };

  const handleUnlockAttributes = () => {
    setProductActionType('unlockAttributes');
    setIsOpenedProductActions(true);
  };

  const temporaryDisabled = true;

  const isReviewed = itemProps.productItem.pimStatus && itemProps.productItem.pimStatus.status === 'reviewed';

  let imgUrl = itemProps.defaultImage;
  if (itemProps.productItem && itemProps.productItem.images && itemProps.productItem.images[0]) {
    imgUrl = itemProps.productItem.images[0].url;
  }
  if (itemProps.parentValue.imgData && itemProps.parentValue.imgData.url) {
    imgUrl = itemProps.parentValue.imgData.url;
  }

  const fetchComments = () => {
    props.setMerchantActivityDialog(true);
    itemProps.fetchActivityLogsByProductId(itemProps.productItem.id);
  };
  const handleProductSelect = () => itemProps.onProductSelect(cloneDeep(itemProps.productItem));

  const handleItemMouseEnter = (e) => {
    itemProps.setPopupAnchor(e.target);
    itemProps.setProductCategories(itemProps.productItem.categories);
  };

  const handleItemMouseLeave = () => {
    itemProps.setPopupAnchor(null);
    itemProps.setProductCategories([]);
  };

  const ExpandProducts = (props) => {
    const childrenIds = props.childrenIds;
    const itemProps = props.itemProps;

    if (!isEmpty(childrenIds) || (itemProps.productItem.type === 'merchant' && itemProps.productItem.parentId)) {
      return (
        <div className="justify-center direction-column product-arrow">
          <IconButton color="inherit" onClick={handleExpandProduct}>
            <i className={`zmdi ${itemProps.collapsed.includes(itemProps.productItem.id)
            ? 'zmdi-chevron-down' : 'zmdi-chevron-right'}`} />
          </IconButton>
        </div>
      );
    }

    return (<></>);
  };

  const ActionsPicker = ({type, productItem}) => {
    return (
      <div className="product-actions-picker">
        <div
          className="product-actions-picker-option"
          onClick={event => itemProps.onEditClick({ event, productItem: productItem })}
        >
          <IntlMessages id="preview.table.product.button.edit.label" />
        </div>

        {type === 'parent' && (
          <div
            className="product-actions-picker-option"
            onClick={handleSetInStock}
          >
            <IntlMessages id="pim.table.product.button.setInStock" />
          </div>
        )}
        {type === 'parent' && (
          <div
            className="product-actions-picker-option"
            onClick={handleSetOOS}
          >
            <IntlMessages id="pim.table.product.button.setOOS" />
          </div>
        )}
        {type !== 'parent' && (
          <div
            className="product-actions-picker-option"
            onClick={handleMergeProductsClick}
          >
            <IntlMessages id="pim.table.product.button.mergeProducts" />
          </div>
        )}
        <div
          className="product-actions-picker-option"
          onClick={fetchComments}
        >
          <IntlMessages id="pim.table.product.button.history" />
        </div>
        <div
          className="product-actions-picker-option"
          onClick={handleRemoveProduct}
        >
          <IntlMessages id="pim.table.product.button.remove" />
        </div>
        <div
          className="product-actions-picker-option"
          onClick={handleRestoreProduct}
        >
          <IntlMessages id="pim.table.product.button.restore" />
        </div>
        <div
          className="product-actions-picker-option"
          onClick={handleMarkAsReviewed}
        >
          <IntlMessages id="pim.table.product.button.markAsReviewed" />
        </div>
        <div
          className="product-actions-picker-option"
          onClick={handleMarkAsNotReviewed}
        >
          <IntlMessages id="pim.table.product.button.markAsNotReviewed" />
        </div>
        <div
          className="product-actions-picker-option"
          onClick={handleUnlockAttributes}
        >
          <IntlMessages id="pim.table.product.button.unlockAttributes" />
        </div>
      </div>
    );
  };

  return (
    <>
      <TableCell key="cell-contoller" className="type-cell" align="left">
        <div className="flex relative">
          <div className={`product-item-type ${itemProps.productItem.type}`}>
            <span>{itemProps.productItem.type}</span>
          </div>
          <div className="flex edit-icon-wrapper items-center ml-15">
            <div className="edit-icon">
              <Button
                color="primary"
                className="btn-xs extra float-right"
                onClick={showActionsPicker}
              >
                <IntlMessages id="title.actions" />
              </Button>
            </div>

            {isReviewed && (
              <Tooltip
                title={`by ${itemProps.productItem.pimStatus.reviewedBy}`}
                placement="top"
              >
                <span className="badge-success badge-xs badge-rel badge-text secondary">
                  <IntlMessages id="pim.table.product.badge.reviewed.label" />
                </span>
              </Tooltip>
            )}
          </div>

          {isOpenedActionsPicker && (
            <ActionsPicker type={itemProps.productItem.type} productItem={itemProps.productItem} />
          )}

          {!itemProps.fromRules && (
            <ExpandProducts childrenIds={childrenIds} itemProps={itemProps} />
          )}

          {(!itemProps.disabled || itemProps.fromRules) && (
              <Checkbox
                className="product-checkbox"
                checked={
                  itemProps.selectedProducts.some(product => product.id === itemProps.parentValue.id)
                }
                onChange={handleProductSelect}
                name={ itemProps.parentValue.title ? camelize(itemProps.parentValue.title) : '' }
              />
          )}

        </div>
      </TableCell>
      <TableCell key="cell-image" align="left">
        <Tooltip
          className="img-tooltip"
          title={(
            <img
              className="product-item-image tooltip-inner"
              src={imgUrl}
              alt=""
            />
          )}
          placement="right"
        >
          <img
            className="product-item-image parent-level-image"
            src={imgUrl}
            alt=""
          />
        </Tooltip>
      </TableCell>

      {itemProps.sortedAttributes.filter(attr => attr.enabled).map((attr) => {
        let itemValue = itemProps.parentValue[camelize(attr.value)];
        let itemValueLength = itemValue ? itemValue.length : 0;
        if (attr.code === 'locked_attributes' && Array.isArray(itemValue)) {
          const stringValue = itemValue.join(', ');
          itemValueLength = stringValue.length;
          itemValue = stringValue;
        }

        if (
          attr.code === 'price.orig_price'
          || attr.code === 'price.final_price'
          || attr.code === 'price.min_price'
          || attr.code === 'price.profit'
          || attr.code === 'price.msrp'
          || attr.code === 'price.cost'
          || attr.code === 'price.shipping_cost'
          || attr.code === 'max_msrp_price'
          || attr.code === 'min_msrp_price'
          || attr.code === 'max_price'
          || attr.code === 'min_price') {
          itemValue = moneyFormat(itemValue, '$');
        }

        if (
          attr.code === 'price_enrichment.competitors.zap.price'
          || attr.code === 'price_enrichment.competitors.zap.max_price') {
          if (itemProps.productItem.priceEnrichment &&
              itemProps.productItem.priceEnrichment.competitors &&
              itemProps.productItem.priceEnrichment.competitors.zap &&
              itemProps.productItem.priceEnrichment.competitors.zap.currency === 'ILS') {
              itemValue = moneyFormat(itemValue, '₪');
          } else {
            itemValue = moneyFormat(itemValue, '$');
          }
        }

        if (attr.code === 'origin_publish_price' || attr.code === 'frontend_price.publish_price') {
          let currencySymbol = itemProps.currency.symbols;
          if (currencySymbol === 'ILS') {
            currencySymbol = '₪';
          }

          if (itemValue >= 0) {
            itemValue = moneyFormat(Math.round(itemValue), currencySymbol);
          } else if (itemProps.productItem.price && itemProps.productItem.price.ils) {
            itemValue = moneyFormat(Math.round(itemProps.productItem.price.ils), currencySymbol);
          } else if (itemProps.productItem.price && itemProps.productItem.price.finalPrice) {
            const publishPrice = getPublishPrice(
              itemProps.productItem.price.finalPrice,
              itemProps.currency.value,
              itemProps.productItem.isDomestic
            );
            itemValue = moneyFormat(Math.round(publishPrice), currencySymbol);
          }
        }

        if (attr.code === 'is_domestic' || attr.code === 'is_visible_by_category') {
          itemValue = itemValue === true ? 'Yes' : 'No';
        }

        const isShowMore = itemValueLength && itemValueLength > 90;
        const isCategoriesAttribute = attr.code === 'categories';

        const firstLetterClassName = (attr.value !== 'locked_attributes' && attrLgSet.has(attr.code))
          ? attr.value
          : '';
        const moreButtonClassName = attrLgSet.has(attr.code) ? 'three-dots-abs' : '';
        const categoriesClassName = isCategoriesAttribute ? 'categories-list' : '';
        const currentAttribute = itemProps.certainProductAttributes
          .find(el => el.code === attr.value) || {};

        return (
          <TableCell key={`${itemProps.parentValue.id}_${attr.code}`} align="left">
            <div
              className={`flex direction-column product-item-data card-item-inline relative ${categoriesClassName}`}
              key={attr.code}
              onMouseEnter={isCategoriesAttribute ? handleItemMouseEnter : null}
              onMouseLeave={isCategoriesAttribute ? handleItemMouseLeave : null}
            >
              <div className={`flex ${moreButtonClassName}`}>
                <span className="key hidden">{(currentAttribute.label ? currentAttribute.label.en : '') || (attr.label ? attr.label.en : '')}</span>
                {isShowMore
                  ? (
                    <ShowMore
                      attr={attr}
                      itemValue={itemValue || '-'}
                    />
                  )
                  : null}
              </div>
              <span className={`value relative ${firstLetterClassName}`}>
                {renderValue(attr.code, itemValue, itemProps.urlKey || '', handleShowZapInfo)}
              </span>
            </div>
          </TableCell>
        );
      })}
      {!itemProps.disabled && !temporaryDisabled && (
        <TableCell key="cell-drag-handle">
          <div className="flex ml-auto dnd-icon items-center">
            <DragHandle />
          </div>
        </TableCell>
      )}
      <MergeProductsDialog
        key={`merge-dialog-${itemProps.productItem.id}`}
        isOpened={isOpenedMergeDialog}
        onClose={handleCloseMergeProductsDialog}
        onMergeProductsClick={handleMergeProductsClick}
        commentMessages={itemProps.commentMessages}
        onCommentsUpdate={itemProps.multipleUpdateUserActivityLogs}
        product={itemProps.productItem}
      />
      <FormDialog
        title={<IntlMessages id="pim.table.product.badge.setInStock.label" />}
        open={isOpenedSetInStockDialog}
        hideCloseButton={false}
        className="dialog-inputs-custom dialog-content-visible"
        closeButtonTitle="Close"
        submitButtonTitle="Submit"
        maxWidth="xs"
        onClose={handleCloseSetInStockDialog}
        onSubmit={submitSetInStock}
      >
        <IntlMessages id="alert.sureTitle" />
      </FormDialog>
      <FormDialog
        title={<IntlMessages id="pim.table.product.badge.setOOS.label" />}
        open={isOpenedSetOOSDialog}
        hideCloseButton={false}
        className="dialog-inputs-custom dialog-content-visible"
        closeButtonTitle="Close"
        submitButtonTitle="Submit"
        maxWidth="xs"
        onClose={handleCloseSetOOSDialog}
        onSubmit={submitOOS}
      >
        <IntlMessages id="alert.sureTitle" />
      </FormDialog>
      <ProductActionsDialog
        key={`product-actions-dialog-${itemProps.productItem.id}`}
        type={productActionType}
        isOpened={isOpenedProductActions}
        onClose={handleCloseProductActionsDialog}
        commentMessages={itemProps.commentMessages}
        product={itemProps.productItem}
      />
    </>
  );
});

ListTreetItem.propTypes = {
  bulkUpdateProductStatus: func.isRequired,
  itemProps: shape().isRequired,
};

ListTreetItem.defaultProps = {};

const mapStateToProps = state => (state);

const mapDispatchToProps = {
  bulkUpdateProductStatus,
  setMerchantActivityDialog,
  setShowZapInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(ListTreetItem);
