/* eslint-disable no-param-reassign */
import { isEmpty } from 'lodash';
import { checkFilterGroups } from './checkFilterGroups';

const operatorsWithoutValue = new Set(['exists', 'not_exists']);

export const getCheckedSidebarFilter = (filter) => {
  const hasError = !isEmpty(filter) ? checkFilterGroups(filter) : [];
  return hasError && filter.map((f) => {
    if (isEmpty(f.errors)) delete f.errors;
    return ({
      ...f,
      group: f.group.map((g) => {
        if (isEmpty(g.errors)) {
          delete g.errors;
          if (operatorsWithoutValue.has(g.operator)) {
            return ({
              field: g.field,
              operator: g.operator,
            });
          } return g;
        } return checkFilterGroups(g);
      }),
    });
  });
};

export default {
  getCheckedSidebarFilter,
};
