import { createSelector } from 'reselect';

const previewRootSelector = state => state;

const stateSelector = createSelector(
  previewRootSelector,
  physicalCategory => ({
    searched: physicalCategory.searched,
    searchResults: physicalCategory.searchResults,
    fetchedPhysicalCategoriesByIds: physicalCategory.fetchedCategoriesByIds,
    physicalCategoriesByIds: physicalCategory.categoriesByIds,
  }),
);

export const physicalCategorySelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  physicalCategorySelector,
};
