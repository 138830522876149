import React from 'react';
import { func, string } from 'prop-types';
import { Badge } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import IntlMessages from 'util/IntlMessages';

const BadgeButton = props => (
  <div className="facet-wrapper sticky-label">
    <Badge
      className="mb-5 mr-5 badge-outline"
      color="primary"
      pill
    >
      <span className="badge-label">
        {props.messageId && <IntlMessages id={props.messageId} />}
        {`: ${props.label}`}
      </span>
      <IconButton
        aria-label="Delete"
        className="btn-mui-xs btn-mui-badge pos-rel"
        onClick={props.onClick}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Badge>
  </div>
);

BadgeButton.propTypes = {
  label: string,
  messageId: string,
  onClick: func,
};

BadgeButton.defaultProps = {
  onClick: null,
  label: '',
  messageId: '',
};

export default BadgeButton;
