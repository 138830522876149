/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, memo } from 'react';
import {
  shape, arrayOf, string, func, bool,
} from 'prop-types';
import { isEmpty, uniq } from 'lodash';
import { Button, CircularProgress, Badge } from '@material-ui/core';
import swal from '@sweetalert/with-react';

import { Form } from 'reactstrap';
import ScopeDialog from '../../../../components/ScopeDialog';
import IntlMessages from '../../../../util/IntlMessages';

const SelectVirtualCategories = memo((props) => {
  const {
    selectedTreeItems, showCategoriesDialog, setShowCategoriesDialog, fetchingParentCategoriesByIds,
    searched, searchResults, collapse, loadedKeys, treeItems, fetchChildrenStarted,
    scopeCategoriesByIds, fetchedScopeCategoriesByIds, disabled, clearSearchListState,
    fetchChildCategories, searchScopeCategories, onToggleScopeCategoryTreeCollapse,
    handleLoadScopeSubcategories, fetchScopeCategoriesByIds, onSubmit, loading, influencer,
    clearInfluencerProductState,
  } = props;

  useEffect(() => {
    if (fetchedScopeCategoriesByIds) {
      const physicalCategoriesIds = scopeCategoriesByIds.map((c) => {
        const { mappedCategories, selectedCategories } = c.virtualParams || {};
        return [...mappedCategories || [], ...selectedCategories || []];
      });
      onSubmit(selectedTreeItems, physicalCategoriesIds.flat());
    }
  }, [fetchedScopeCategoriesByIds]);

  const getVirtualCategoriesIds = array => array.map((a) => {
    if (typeof a !== 'string') return a.id;
    return a;
  });

  const openSelectCategoriesButton = () => {
    setShowCategoriesDialog(true);
  };

  const closeSelectCategoriesButton = () => {
    setShowCategoriesDialog(false);
    clearSearchListState();
  };

  const handleShowAlert = () => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to change the selected categories? All filters related to selected categories can be deleted',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    }).then((accepted) => {
      if (accepted) {
        props.updateInfluencerFields({
          ...influencer,
          page: {
            ...influencer.page,
            topTen: {
              ...influencer.page.topTen,
              filters: [],
              virtualCategories: [],
              selectedProductsFilters: [],
            },
            deal: {
              ...influencer.page.deal,
              filters: [],
              selectedProductsFilters: [],
            },
            productSection: {
              ...influencer.page.productSection,
              filters: [],
              selectedProductsFilters: [],
            },
          },
        });
        clearInfluencerProductState();
        closeSelectCategoriesButton();
      }
    });
  };

  const submitCheckedCategories = (selected) => {
    const mappedList = getVirtualCategoriesIds(selected);

    if (!isEmpty(mappedList)) {
      const ids = uniq(mappedList);
      fetchScopeCategoriesByIds(ids);
      onSubmit(mappedList);
      closeSelectCategoriesButton();
    } else {
      handleShowAlert();
    }
  };

  const handleSearchScopeCategory = (query) => {
    searchScopeCategories({
      filter: [{
        group: [{
          field: 'name.en',
          value: `*${query}*`,
          operator: 'like',
        }],
      }],
      pagination: {
        page: 1,
        limit: 500,
      },
    });
  };

  const getSelectButton = () => (
    <Button
      className="no-wrap block"
      variant="contained"
      color="primary"
      disabled={disabled || fetchingParentCategoriesByIds}
      onClick={openSelectCategoriesButton}
    >
      <IntlMessages id="label.selectCategoriesLabel" />
    </Button>
  );

  const getButtonWrapper = () => {
    if (!isEmpty(selectedTreeItems)) {
      return (
        <Badge className="block" color="error" variant="dot">
          {getSelectButton()}
        </Badge>
      );
    }
    return getSelectButton();
  };

  return (
    <Form inline className="form-group min-w-s mt-20 mb-20 relative">
      {loading && (
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className="progress-warning influencer-categories loader bottom"
          size={15}
          thickness={4}
        />
      )}
      {getButtonWrapper()}

      <ScopeDialog
        dialog={{
          closeButtonTitle: <IntlMessages id="rankings.cancelScopeDialogButtonLabel" />,
          submitButtonTitle: <IntlMessages id="rankings.selectScopeDialogButtonLabel" />,
        }}
        search={{
          noResult: searched && isEmpty(searchResults),
          placeholder: 'Search virtual categories...',
          list: searchResults,
          errorMessage: 'text.errors.lessThan500Characters',
          queryLengthLimit: 500,
        }}
        showScopeDialog={showCategoriesDialog}
        collapsed={collapse}
        loadedKeys={loadedKeys}
        selectedTreeItems={selectedTreeItems}
        treeItems={treeItems}
        closeScopeDialog={closeSelectCategoriesButton}
        onSubmit={submitCheckedCategories}
        toogleTreeComponents={clearSearchListState}
        fetchChildCategories={fetchChildCategories}
        onSearchSubmit={handleSearchScopeCategory}
        onToggleCategoryTreeCollapse={onToggleScopeCategoryTreeCollapse}
        handleLoadSubcategories={handleLoadScopeSubcategories}
        loading={fetchingParentCategoriesByIds || fetchChildrenStarted}
        multiple
        maxSelected={2}
      />
    </Form>
  );
});

SelectVirtualCategories.propTypes = {
  fetchingParentCategoriesByIds: bool.isRequired,
  searched: bool.isRequired,
  fetchChildrenStarted: bool.isRequired,
  fetchedScopeCategoriesByIds: bool.isRequired,
  searchResults: arrayOf(shape()).isRequired,
  collapse: arrayOf(string).isRequired,
  loadedKeys: arrayOf(string).isRequired,
  treeItems: arrayOf(shape()).isRequired,
  scopeCategoriesByIds: arrayOf(shape()).isRequired,
  selectedTreeItems: arrayOf(string).isRequired,
  influencer: shape().isRequired,
  showCategoriesDialog: bool.isRequired,
  disabled: bool,
  loading: bool,

  setShowCategoriesDialog: func.isRequired,
  clearSearchListState: func.isRequired,
  fetchChildCategories: func.isRequired,
  searchScopeCategories: func.isRequired,
  onToggleScopeCategoryTreeCollapse: func.isRequired,
  handleLoadScopeSubcategories: func.isRequired,
  fetchScopeCategoriesByIds: func.isRequired,
  onSubmit: func.isRequired,
  updateInfluencerFields: func.isRequired,
  clearInfluencerProductState: func.isRequired,
};

SelectVirtualCategories.defaultProps = {
  disabled: false,
  loading: false,
};

export default SelectVirtualCategories;
