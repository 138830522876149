import { camelizeKeys, camelize } from 'humps';
import firebase from 'firebase/app';
import RestActions from '../util/rest/actions';
import { getCurrency } from './currency';

import { CLEAR_SESSION_STATE } from './types';

const restSessionActions = new RestActions('session');
const {
  fetchStart,
  fetchFinished,
} = restSessionActions;

export const fetchSession = (userId, cb) => async (dispatch) => {
  dispatch(fetchStart());
  const roles = await firebase.database().ref('/roles').once('value');
  const user = await firebase.database().ref(`/users/${userId}`).once('value');
  const usersResult = user.val();
  const result = camelizeKeys({
    roles: roles.val(),
    user: {
      ...usersResult,
      roles: usersResult.roles.filter(r => r),
    },
  });

  const userRoles = {};
  result.user.roles.forEach((ur) => {
    const key = camelize(ur);

    userRoles[key] = result.roles[key];
  });

  let roleLevels = [];
  Object.keys(userRoles).forEach((ur) => {
    const levels = userRoles[ur].permissions
      ? Object.keys(userRoles[ur].permissions).map(key => key) : [];
    roleLevels = Array.from(new Set([...roleLevels, ...levels]));
  });

  const levelPermissions = {};
  roleLevels.forEach((rl) => {
    Object.keys(userRoles).forEach((ur) => {
      const userRoleLevelPermissions = userRoles[ur].permissions
        ? userRoles[ur].permissions[rl] : null;
      if (userRoleLevelPermissions) {
        const permissions = Object.keys(userRoleLevelPermissions)
          .filter(k => userRoleLevelPermissions[k]);
        let mappedPermissions = [];
        if (levelPermissions[rl]) {
          mappedPermissions = levelPermissions[rl];
        }
        levelPermissions[rl] = Array.from(new Set([...permissions, ...mappedPermissions]));
      }
    });
  });

  const formatedLevelPermissions = {};
  Object.keys(levelPermissions).forEach((lp) => {
    const objPermissions = {};
    levelPermissions[lp].forEach((p) => {
      objPermissions[p] = p;
    });
    formatedLevelPermissions[lp] = objPermissions;
  });

  const sessionPayload = {
    roles: roles.val(),
    user: {
      ...user.val(),
      permissions: formatedLevelPermissions,
      id: userId,
    },
  };


  dispatch(getCurrency({symbols: 'ILS' }));
  dispatch(fetchFinished(camelizeKeys(sessionPayload)));
  if (cb) cb();
};

export const clearSessionState = () => (dispatch) => {
  dispatch({ type: CLEAR_SESSION_STATE });
};

export default {
  fetchSession,
  clearSessionState,
};
