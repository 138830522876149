import React from 'react';
import { decamelize } from "humps";

export const VariationAttribute = ({item, attributes, productAttributeProps}) => {
  const productAttributes = productAttributeProps.productAttributes;
  const labels = {};
  for (let i= 0; i < productAttributes.length; i++) {
    if (productAttributes[i].code + '_orig' === decamelize(item)) {
      labels[item] = productAttributes[i].label.en;
    }
  }

  if (!labels[item] || attributes[item].indexOf('optn_') !== -1) {
    return (<></>);
  }

  return (
    <div style={{fontWeight: 'normal'}}>
      { labels[item] + ': ' + attributes[item] }
    </div>
  )
};
