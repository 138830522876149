import { isEmpty } from 'lodash';

export const groupConditionValidations = {
  groupCondition: [{
    type: 'isRequired',
    message: 'preview.errors.filterItemIsRequired',
  }],
};

export const fieldsValidations = {
  field: [{
    type: 'isRequired',
    message: 'preview.errors.filterItemIsRequired',
  }],
};

export const filterNameValidations = {
  filter: [{
    type: 'isRequired',
    message: 'preview.errors.filterItemIsRequired',
  }],
};

export const actionAttributeValidations = {
  attribute: [{
    type: 'isRequired',
    message: 'error',
  }],
  attributeValue: [{
    type: 'isRequired',
    message: 'error',
  }],
};

export const productValidations = {
  description: [{
    type: 'textFieldIsRequired',
    message: 'preview.errors.fieldIsRequired',
  }],
  title: [{
    type: 'textFieldIsRequired',
    message: 'preview.errors.fieldIsRequired',
  }],
};


export const categoryNameValidations = {
  en: [
    {
      type: 'isRequired',
      message: 'category.errors.cantBeEmpty',
    }, {
      type: 'minSize',
      message: 'text.errors.moreThanThreeCharacters',
      condition: 3,
    }, {
      type: 'maxSize',
      message: 'text.errors.lessThan200Characters',
      condition: 200,
    },
  ],
  he: [
    {
      type: 'isRequired',
      message: 'category.errors.cantBeEmpty',
    }, {
      type: 'minSize',
      message: 'text.errors.moreThanThreeCharacters',
      condition: 3,
    }, {
      type: 'maxSize',
      message: 'text.errors.lessThan200Characters',
      condition: 200,
    },
  ],
};

export const navigationNameValidations = [
  {
    type: 'isRequired',
    message: 'search.errors.cantBeBlank',
  }, {
    type: 'minSize',
    message: 'text.errors.moreThanThreeCharacters',
    condition: 3,
  }, {
    type: 'maxSize',
    message: 'text.errors.lessThan200Characters',
    condition: 200,
  },
];

export const searchPhraseFilterValidations = {
  name: navigationNameValidations,
  searchPhrase: [
    {
      type: 'isRequired',
      message: 'search.errors.cantBeBlank',
    }, {
      type: 'xss',
      message: 'search.errors.xss',
    },
  ],
};

export const searchPromotionFilterValidations = {
  name: navigationNameValidations,
  urlKey: [{
    type: 'maxSize',
    message: 'text.errors.lessThan500Characters',
    condition: 500,
  }, {
    type: 'minSize',
    message: 'search.errors.cantBeBlank',
    condition: 2,
  }],
};

export const validateFilters = (array) => {
  const operatorsWithoutValue = new Set(['exists', 'not_exists']);
  const errors = array.map(item => ({
    ...item,
    group: item.group.map((g) => {
      const list = Object.keys(g).map((key) => {
        if (isEmpty(g[key]) && !operatorsWithoutValue.has(g.operator)) return ({ [key]: 'helper.fieldCantNotBeBlank' });
        return null;
      });
      return Object.assign({}, ...list);
    }),
  }));
  return errors;
};

export const validateSortings = (array) => {
  const errors = array.map((p) => {
    if (!p.field) return ({ field: 'helper.fieldCantNotBeBlank' });
    return ({ field: '' });
  }).filter(el => el);
  return errors;
};
