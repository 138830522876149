import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';
import {
  CLEAR_CREATE_RANKING_STATE,
  CLEAR_FETCHED_RANKING_STATE,
  CLEAR_UPDATE_RANKING_STATE,
  CLEAR_DELETE_RANKING_STATE,
  CLEAR_RANKING_STATE,
  FETCH_RANKING_BY_NAME_START,
  FETCH_RANKING_BY_NAME_FINISHED,
  RANKING_SEARCH_CATEGORIES_BY_RANKING_RULE_START,
  RANKING_SEARCH_CATEGORIES_BY_RANKING_RULE_FINISHED,
  RANKING_BULK_CATEGORIES_UPDATE_START,
  RANKING_BULK_CATEGORIES_UPDATE_FINISHED,
  RANKING_BULK_NAVIGATIONS_UPDATE_START,
  RANKING_BULK_NAVIGATIONS_UPDATE_FINISHED,
} from '../actions/types';

const initialState = {
  rankingByName: {},
  fetchRankingByNameStarted: false,
  fetchRankingByNameFinished: false,
  bulkUpdateCategoriesStart: false,
  bulkUpdateCategoriesFinished: false,
  bulkUpdateNavigationsStart: false,
  bulkUpdateNavigationsFinished: false,
};
const rankingRestTypes = restTypes('ranking');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case rankingRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case rankingRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case rankingRestTypes.fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };
    case rankingRestTypes.fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case rankingRestTypes.createStart:
      return {
        ...state,
        ...restStates.createStart,
        ...action.payload,
      };
    case rankingRestTypes.createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        ...action.payload,
      };
    case rankingRestTypes.updateStart:
      return {
        ...state,
        ...restStates.updateStart,
        ...action.payload,
      };
    case rankingRestTypes.updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };
    case rankingRestTypes.deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
        ...action.payload,
      };
    case rankingRestTypes.deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };
    case CLEAR_CREATE_RANKING_STATE:
      return {
        ...state,
        creating: false,
        created: false,
      };
    case CLEAR_FETCHED_RANKING_STATE:
      return {
        ...state,
        fetchingOne: false,
        fetchedOne: false,
        item: {},
      };

    case CLEAR_UPDATE_RANKING_STATE:
      return {
        ...state,
        updating: false,
        updated: false,
        item: {},
      };
    case CLEAR_DELETE_RANKING_STATE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        item: {},
      };
    case CLEAR_RANKING_STATE:
      return {
        ...initialState,
      };
    case FETCH_RANKING_BY_NAME_START:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_RANKING_BY_NAME_FINISHED:
      return {
        ...state,
        ...action.payload,
      };

    case RANKING_SEARCH_CATEGORIES_BY_RANKING_RULE_START:
    case RANKING_SEARCH_CATEGORIES_BY_RANKING_RULE_FINISHED:
    case RANKING_BULK_CATEGORIES_UPDATE_START:
    case RANKING_BULK_CATEGORIES_UPDATE_FINISHED:
    case RANKING_BULK_NAVIGATIONS_UPDATE_START:
    case RANKING_BULK_NAVIGATIONS_UPDATE_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
};
