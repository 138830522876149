import {
  fetchItem as fetchRanking,
  clearFetchedRankingState,
  clearUpdatedRankingState,
  clearDeletedRankingState,
  updateRank,
  deleteRank,
  fetchList as fetchRankingList,
  deleteRankFromState,
  fetchRankingByName,
  searchCategoriesByRankingRule,
  searchNavigationsByRankingRule,
  bulkUpdateVirtualCategories,
  bulkUpdateNavigations,
} from '../../../../actions/ranking';
import { fetchRankingCategories } from '../../../../actions/rankingCategory';
import {
  fetchRootCategories as fetchRootScopeCategories,
  fetchChildCategories as fetchChildScopeCategories,
  clearScopeCategoriesState,
  searchCategories as searchScopeCategories,
  clearSearchListState as clearScopeCategorySearchState,
  onToggleScopeCategoryTreeCollapse,
  handleLoadScopeSubcategories,
  loadAllChildCategories,
  clearFromScopeCategoryState,
  fetchScopeCategoriesByIds,
} from '../../../../actions/scopeCategory';
import {
  normalizationSelector,
  pushingSelector,
  rankingAttributeSelector,
} from '../../selectors/appSettings';

export const mapStateToProps = state => ({
  rankingAttribute: rankingAttributeSelector(state.appSettings.settings),
  normalization: normalizationSelector(state.appSettings.settings),
  pushing: pushingSelector(state.appSettings.settings),
  rankingCategory: state.rankingCategory,
  ranking: state.ranking,
  scopeCategory: state.scopeCategory,
  bulkUpdateCategoriesStart: state.ranking.bulkUpdateCategoriesStart,
  bulkUpdateNavigationsStart: state.ranking.bulkUpdateNavigationsStart,
});

export const actionCreators = {
  fetchRanking,
  fetchRootScopeCategories,
  fetchChildScopeCategories,
  clearFetchedRankingState,
  clearUpdatedRankingState,
  clearDeletedRankingState,
  updateRank,
  deleteRank,
  fetchRankingList,
  fetchRankingCategories,
  clearScopeCategoriesState,
  clearScopeCategorySearchState,
  searchScopeCategories,
  deleteRankFromState,
  onToggleScopeCategoryTreeCollapse,
  handleLoadScopeSubcategories,
  loadAllChildCategories,
  bulkUpdateVirtualCategories,
  fetchRankingByName,
  clearFromScopeCategoryState,
  fetchScopeCategoriesByIds,
  searchCategoriesByRankingRule,
  searchNavigationsByRankingRule,
  bulkUpdateNavigations,
};

export default {
  mapStateToProps,
  actionCreators,
};
