/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useFacetInputTypes = ({
  actions,
}) => {
  useEffect(() => {
    actions.fetchFacetInputTypeList();
  }, []);
};

export default useFacetInputTypes;
