/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { defaultMaxPagination, searchPromotionFilter } from '../utils/defaultData';
import { getSearchPromotionsQuery } from '../../utils/queries';

const usePromotion = (state, props) => {
  useEffect(() => {
    props.fetchPromotionList(searchPromotionFilter, defaultMaxPagination);
  }, []);

  useEffect(() => {
    if (props.navigation.deleted && !props.navigation.hasError) {
      state.setValue('');
      props.searchPromotions(getSearchPromotionsQuery(''));
      props.fetchPromotionList(searchPromotionFilter, defaultMaxPagination);
    }
  }, [props.navigation.deleted]);
};

export default usePromotion;
