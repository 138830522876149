import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  FETCH_CHILD_VIRTUAL_CATEGORIES_START,
  FETCH_CHILD_VIRTUAL_CATEGORIES_FINISHED,
  FETCH_ROOT_VIRTUAL_CATEGORIES_START,
  FETCH_ROOT_VIRTUAL_CATEGORIES_FINISHED,
  SET_CATEGORY_TREE_ITEMS,
  SET_SELECTED_CATEGORY,
  VIRTUAL_CATEGORY_WIDGET_SEARCH_VIRTUAL_CATEGORIES_START,
  VIRTUAL_CATEGORY_WIDGET_SEARCH_VIRTUAL_CATEGORIES_FINISHED,
  TOGGLE_CATEGORY_TREE_COLLAPSE,
  TOGGLE_CATEGORY_TREE_COLLAPSE_ITEM,
  FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_START,
  FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_FINISHED,
  HANDLE_LOAD_SUBCATEGORIES,
  CLEAR_VIRTUAL_CATEGORY_TREE_STATE,
  CLEAR_CATEGORY_PRODUCT_STATE,
} from '../actions/types';

const initialState = {
  treeItems: [],
  searchResults: [],
  collapse: [],
  loadedKeys: [],
  multipleChildListFetching: false,
  multipleChildListFetched: false,
  categoryToDuplicate: {},
  selectedCategory: {},
  contextMenuStyles: { left: 0, top: 0, position: 'fixed' },
  showContextMenu: false,
  targetTreeNode: null,
};
const virtualCategoryRestTypes = restTypes('virtual_category_widget');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case virtualCategoryRestTypes.createStart:
      return {
        ...state,
        ...restStates.createStart,
        ...action.payload,
      };
    case virtualCategoryRestTypes.createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        ...action.payload,
      };
    case virtualCategoryRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case virtualCategoryRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case virtualCategoryRestTypes.clearAllFinished:
      return {
        ...restInitState,
        ...initialState,
      };
    case virtualCategoryRestTypes.clearOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        item: {},
        fetchedOne: false,
      };
    case virtualCategoryRestTypes.clearSearchListFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        search: [],
        searched: false,
      };
    case virtualCategoryRestTypes.clearErrorFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case virtualCategoryRestTypes.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        ...action.payload,
      };
    case SET_CATEGORY_TREE_ITEMS:
      return {
        ...state,
        treeItems: [
          ...state.treeItems,
          ...action.payload.items,
        ],
      };
    case VIRTUAL_CATEGORY_WIDGET_SEARCH_VIRTUAL_CATEGORIES_START:
      return {
        ...state,
        searchStarted: true,
        searchFinished: false,
      };
    case VIRTUAL_CATEGORY_WIDGET_SEARCH_VIRTUAL_CATEGORIES_FINISHED:
      return {
        ...state,
        searchStarted: false,
        searchFinished: true,
        searchResults: action.payload.list,
      };
    case FETCH_ROOT_VIRTUAL_CATEGORIES_START:
      return {
        ...state,
        fetchRootStarted: true,
        fetchRootFinished: false,
      };
    case FETCH_ROOT_VIRTUAL_CATEGORIES_FINISHED:
      return {
        ...state,
        treeItems: action.payload.list,
        fetchRootStarted: false,
        fetchRootFinished: true,
      };
    case FETCH_CHILD_VIRTUAL_CATEGORIES_START:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_CHILD_VIRTUAL_CATEGORIES_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case TOGGLE_CATEGORY_TREE_COLLAPSE:
      return {
        ...state,
        collapse: action.payload,
      };
    case TOGGLE_CATEGORY_TREE_COLLAPSE_ITEM:
      return {
        ...state,
        collapse: state.collapse.some(c => c === action.payload)
          ? state.collapse
          : [...state.collapse, action.payload],
      };
    case FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_START:
      return {
        ...state,
        multipleChildListFetching: true,
        multipleChildListFetched: false,
      };
    case FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_FINISHED:
      return {
        ...state,
        multipleChildListFetching: false,
        multipleChildListFetched: true,
      };
    case HANDLE_LOAD_SUBCATEGORIES:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_VIRTUAL_CATEGORY_TREE_STATE:
      return {
        ...state,
        loadedKeys: [],
        collapse: [],
      };
    case CLEAR_CATEGORY_PRODUCT_STATE:
      return {
        ...state,
        product: initialState.product,
      };

    default: return state;
  }
};
