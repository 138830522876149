/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

import {
  mapPrefixToFilters,
} from '../../../../../util/filterMapper';

export const useCategory = ({
  setHiddenProductsExist,
  setHiddenProductsIds,
  fetchVirtualRootCategories,
  setSelectedCategory,
  fetchProductList,
  selectedCategory,
  compoundSearch,
  isHiddenProductsMode,
  hiddenProductsFilter,
  facetsPreFilters,
  pagination,
  searchQuery,
  selectedAction,
  previewFetched,
  virtualCategoryUpdated,
  virtualCategoryItem,
}) => {
  const findAndUpdateFilterById = (category) => {
    const { virtualParams } = category;
    const { filters } = virtualParams || {};
    const hiddenProductsExist = filters
      ? filters.find(f => f.group
        .some(fG => fG.field === 'id' && fG.operator === 'nin'))
      : false;
    const currentGroup = hiddenProductsExist
      ? hiddenProductsExist.group.find(g => g.field === 'id')
      : {};

    setHiddenProductsExist(!!hiddenProductsExist);
    setHiddenProductsIds(currentGroup.value || []);
  };

  useEffect(() => {
    fetchVirtualRootCategories();
  }, []);

  useEffect(() => {
    if (virtualCategoryUpdated && !isEmpty(selectedCategory)) {
      const visibilityActions = new Set(['hide', 'show', 'unpin', 'pin_to_top', 'pin_to_position', 'pin_to_bottom']);

      fetchProductList({
        viewMode: 'frontendWithParams',
        compoundSearch,
        filter: isHiddenProductsMode
          ? hiddenProductsFilter
          : mapPrefixToFilters(facetsPreFilters),
        pagination,
        searchFilter: isHiddenProductsMode
          ? {}
          : searchQuery,
        ignoreFacets: visibilityActions.has(selectedAction)
          || !selectedAction,
      });

      setSelectedCategory({
        ...virtualCategoryItem,
        virtualParams: {
          ...virtualCategoryItem.virtualParams,
        },
      });
    }
  }, [virtualCategoryUpdated]);

  useEffect(() => {
    if (!isEmpty(selectedCategory)) {
      findAndUpdateFilterById(selectedCategory);
    }
  }, [
    selectedCategory,
    virtualCategoryUpdated,
    previewFetched,
  ]);
};

export default useCategory;
