/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  memo, useContext, useState, useEffect,
} from 'react';
import {
  func, shape, arrayOf, bool,
} from 'prop-types';
import { connect } from 'react-redux';
import {
  isEqual, isEmpty, cloneDeep, uniq,
} from 'lodash';
import swal from '@sweetalert/with-react';
import { NotificationManager } from 'react-notifications';
import { CircularProgress } from '@material-ui/core';

import {
  createPreviewCategoryVirtualFacet,
  updatePreviewCategoryVirtualFacet,
  deletePreviewCategoryVirtualFacet,
  setPreviewCategoryVirtualFacetToDeleteId,
} from 'actions/previewCategoryVirtualFacet';

import {
  setSelectedProductList,
  setIsCategorySaving,
  setErrors,
  setSortingsErrors,
  setFiltersErrors,
  fetchVirtualCategoryByPathByName,
  updateCategory,
  clearFromPreviewCategoryState,
} from 'actions/previewCategory';

import { fetchRootCategories as fetchVirtualRootCategories } from 'actions/virtualCategory';
import { fetchProductList } from 'actions/preview';
import { setSelectedCategory } from 'actions/virtualCategoryWidget';

import { getCamelizeKey } from 'util/virtualFacetManagment';

import { previewCategorySelector } from './selectors/previewCategory';
import { previewSelector } from './selectors/preview';
import { categoryFacetSelector } from './selectors/categoryFacet';

import { AbilityContext } from '../../../../../components/AbilityContext';
import ButtonGroup from '../../../../../components/ButtonGroup';

import { rankingRule } from '../../../../../util/constants/constants';
import IntlMessages from '../../../../../util/IntlMessages';
import { mapFiltersByOperator } from '../../../../../util/mapFiltersByOperator';
import checkValidations from '../../../../../util/validator';
import { defaultPagination } from '../../../../../util/defaultData';

import appPermissions from '../../../../../util/appPermissions';
import buttonList from '../../../../../util/buttonList';
import { getCheckCategoryNameFilter } from '../../../../../util/getCheckCategoryNameFilter';

import {
  mapFilters,
} from '../../../../../util/attributesMatrixMapping';


import {
  categoryNameValidations,
  validateSortings,
  validateFilters,
} from '../../../utils/validations';

const Footer = memo((props) => {
  const [saveAndClose, setSaveAndClose] = useState(false);
  const abilityContext = useContext(AbilityContext);

  const {
    onClose,
    virtualCategories,
    attributesMatrixList,
    facetFilter,
  } = props;

  const {
    virtualCategoryItem,
    originalVirtualCategoryItem,
    virtualCategoryUpdating,
    virtualCategoryUpdated,
    previewCategorySort,
    previewCategoryFilter,
    // previewCategoryFacet,
    isCategorySaving,
    virtualCategoryFetching,
  } = props.previewCategoryState;

  const {
    previewCompoundSearch,
    previewSearchQuery,

  } = props.previewState;

  const handleNotificationSuccess = msg => NotificationManager.success(msg);
  const handleNotificationWarning = msg => NotificationManager.warning(msg);
  const handleNotificationError = msg => NotificationManager.error(msg);

  const handleSetSelectedCatagory = () => {
    const body = {
      ...virtualCategoryItem,
      virtualParams: {
        ...virtualCategoryItem.virtualParams,
        filters: mapFilters(
          virtualCategoryItem.virtualParams.filters,
          attributesMatrixList,
        ),
      },
    };

    props.setSelectedCategory(body);
  };

  useEffect(() => {
    if (
      virtualCategoryUpdated
      || (props.categoryFacet.created || props.categoryFacet.updated)
    ) {
      if (props.categoryFacet.updated && !props.categoryFacet.updating && virtualCategoryUpdated) {
        handleNotificationSuccess(<IntlMessages id="previewCategory.categoryUpdated.success" />);
        handleNotificationWarning(
          <IntlMessages id="category.update.alert.warning.notification" />,
        );
      }

      props.setIsCategorySaving(false);
      props.fetchProductList({
        viewMode: 'frontendWithParams',
        filter: facetFilter,
        compoundSearch: previewCompoundSearch,
        pagination: defaultPagination,
        searchFilter: previewSearchQuery,
      });

      props.fetchVirtualRootCategories();
      handleSetSelectedCatagory();

      if (saveAndClose) onClose();
      setSaveAndClose(false);
      props.clearFromPreviewCategoryState({
        updated: false,
      });
    }
  }, [
    virtualCategoryUpdated,
    props.categoryFacet.created,
    props.categoryFacet.updated,
  ]);

  useEffect(() => {
    if (!isEmpty(props.categoryFacet.virtualFacetIdToDelete)) {
      setSaveAndClose({ closeAfterUpdate: true });
    }
  }, [
    props.categoryFacet.virtualFacetIdToDelete,
  ]);

  const checkChanges = () => {
    const sortRules = previewCategorySort.sortRules.map(sr => ({
      field: sr.field,
      direction: sr.defaultValue.toLowerCase(),
    })).filter(el => el.field !== rankingRule);
    const isCategoryChanged = (
      !isEqual(
        virtualCategoryItem,
        originalVirtualCategoryItem,
      )
      || !isEqual(
        previewCategoryFilter.filters,
        originalVirtualCategoryItem.virtualParams.filters,
      )
      || !isEqual(
        sortRules,
        originalVirtualCategoryItem.virtualParams.sortRules,
      )
      || !isEqual(
        previewCategoryFilter.category.mappedCategories.map(mc => mc.id),
        originalVirtualCategoryItem.virtualParams.mappedCategories,
      )
      || !isEqual(
        previewCategorySort.rankingRuleCode,
        originalVirtualCategoryItem.virtualParams.rankingRuleCode,
      )
      || !isEqual(
        previewCategoryFilter.category.selectedCategories,
        originalVirtualCategoryItem.virtualParams.selectedCategories,
      )
    );

    const isFacetsChanged = !isEqual(
      props.categoryFacet.list,
      props.categoryFacet.origList,
    );

    return { isCategoryChanged, isFacetsChanged };
  };

  const handleOnClose = () => {
    const { isCategoryChanged, isFacetsChanged } = checkChanges();

    if (isCategoryChanged || isFacetsChanged) {
      swal({
        title: 'Are you sure?',
        text: 'If you leave this page then any unsaved changes will be lost.',
        icon: 'warning',
        dangerMode: true,
        buttons: true,
      }).then((willDiscard) => {
        if (willDiscard) {
          props.setSelectedProductList({ in: [], nin: [] });
          onClose();
        }
      });
    } else {
      props.setSelectedProductList({ in: [], nin: [] });
      onClose();
    }
  };

  const checkSortings = () => {
    let sortingsErrors = [];
    if (!isEmpty(previewCategorySort.sortRules)) {
      sortingsErrors = validateSortings(previewCategorySort.sortRules);
    }

    if (previewCategorySort.sortRules.some(p => p.field === rankingRule)
      && !previewCategorySort.rankingRuleCode) {
      sortingsErrors = [{ rankingRuleCode: 'helper.fieldCantNotBeBlank' }];
    }

    if (
      (
        !isEmpty(sortingsErrors) && sortingsErrors
          .some(s => s.field === '') && sortingsErrors
          .map(s => s.field === '')
          .filter(el => el).length === sortingsErrors.length
      )
      || (previewCategorySort.sortRules.some(p => p.field === rankingRule)
        && previewCategorySort.rankingRuleCode)
    ) {
      sortingsErrors = [];
    }
    return sortingsErrors;
  };

  const checkCategoryValidations = (categoryData) => {
    const errors = checkValidations(
      categoryNameValidations, virtualCategoryItem.name || categoryData.name,
    );
    const sortingsErrors = checkSortings();
    let filtersErrors = validateFilters(previewCategoryFilter.filters);

    props.setErrors(errors);
    props.setSortingsErrors(sortingsErrors);
    props.setFiltersErrors(filtersErrors);

    const isFiltersErrorsGroupEmpty = filtersErrors.map(f => f.group.map(g => isEmpty(g))).flat();

    if (!isFiltersErrorsGroupEmpty.some(f => !f)) {
      filtersErrors = [];
      props.setFiltersErrors([]);
    }

    if (!isEmpty(errors) || !isEmpty(sortingsErrors) || !isEmpty(filtersErrors)) {
      return false;
    }

    return true;
  };

  const handleFacetDelete = () => {
    if (props.categoryFacet.virtualFacetIdToDelete) {
      props.deletePreviewCategoryVirtualFacet(props.categoryFacet.virtualFacetIdToDelete);
    }
  };

  const virtualFacetManagement = (categoryFacet) => {
    if (
      isEmpty(props.categoryFacet.list)
      || (!categoryFacet.id && !props.categoryFacet.virtualFacetIdToDelete)
    ) {
      if (isEmpty(categoryFacet.groupedValues)) {
        // eslint-disable-next-line no-param-reassign
        delete categoryFacet.groupedValues;
      }
      props.createPreviewCategoryVirtualFacet(categoryFacet);
    } else {
      const payload = {
        ...categoryFacet,
        facetsList: {
          ...categoryFacet.facetsList,
        },
      };

      delete payload.id;
      delete payload.updatedAt;
      delete payload.createdAt;

      if (isEmpty(payload.groupedValues)) {
        delete payload.groupedValues;
      }

      if (!isEmpty(props.categoryFacet.list)) {
        const categoryFacetId = categoryFacet.id || props.categoryFacet.virtualFacetIdToDelete;
        props.updatePreviewCategoryVirtualFacet(categoryFacetId, payload);
      }
    }
  };

  const handleOnSaveClick = ({ closeAfterUpdate }) => {
    if (!isCategorySaving) {
      props.setIsCategorySaving(true);
      setSaveAndClose(closeAfterUpdate);
      const { isCategoryChanged, isFacetsChanged } = checkChanges();
      if (!isCategoryChanged && !isFacetsChanged) {
        props.setIsCategorySaving(false);
        return handleNotificationWarning(
          <IntlMessages id="category.create.alert.warning" />,
        );
      }

      const params = { isSync: true, mapToMatrix: true };
      const { name } = virtualCategoryItem;
      const categoryVirtualParams = {
        ...virtualCategoryItem.virtualParams,
        rankingRuleCode: previewCategorySort.rankingRuleCode || null,
        // facets: previewCategoryFacet.facets,
        filters: mapFiltersByOperator(previewCategoryFilter.filters),
        sortRules: !isEmpty(previewCategorySort.sortRules)
          ? previewCategorySort.sortRules.map(s => ({
            field: s.field,
            direction: s.defaultValue.toLowerCase(),
          })).filter(s => s.field !== rankingRule)
          : [],
        positions: virtualCategoryItem.virtualParams
          ? virtualCategoryItem.virtualParams.positions
          : {},
        mappedCategories: previewCategoryFilter.isSwitchOn
          ? uniq(previewCategoryFilter.category.mappedCategories.map(mc => mc.id))
          : [],
        selectedCategories: !previewCategoryFilter.isSwitchOn
          ? uniq(previewCategoryFilter.category.selectedCategories.map(mc => mc.id))
          : [],
      };

      const categoryData = {
        virtualParams: categoryVirtualParams,
        name: {
          en: (!isEmpty(name) && name.en) || '',
          he: (!isEmpty(name) && name.he) || '',
        },
        parentId: virtualCategoryItem.parentId,
        isEnabled: virtualCategoryItem.isEnabled,
        useInProductSearch: virtualCategoryItem.useInProductSearch,
        useInDepartmentsTree: virtualCategoryItem.useInDepartmentsTree,
        isShowSubcategoriesOnTop: virtualCategoryItem.isShowSubcategoriesOnTop,
        includeInMenu: virtualCategoryItem.includeInMenu,
      };

      const isValid = checkCategoryValidations(categoryData);

      if (
        !isEmpty(props.categoryFacet.list)
        && isFacetsChanged
      ) {
        const categoryFacet = cloneDeep(props.categoryFacet.item);

        const reducedFacet = categoryFacet.facetsList
          .reduce((acc, cur) => ({ ...acc, [getCamelizeKey(cur.label.en)]: cur }), {});

        categoryFacet.facetsList = reducedFacet;
        virtualFacetManagement(categoryFacet);
      }

      if (
        !isEmpty(props.categoryFacet.virtualFacetIdToDelete)
        && isEmpty(props.categoryFacet.list)
      ) {
        handleFacetDelete();
      } else props.setPreviewCategoryVirtualFacetToDeleteId('');

      if (isValid && isCategoryChanged) {
        props.setErrors({});
        const parentCategory = virtualCategories
          .find(c => c.id === virtualCategoryItem.parentId) || {};
        const generatedPathByNameEn = parentCategory.pathByName
          ? `${parentCategory.pathByName.en} > ${virtualCategoryItem.name.en}`
          : virtualCategoryItem.name.en;
        const generatedPathByNameHe = parentCategory.pathByName
          ? `${parentCategory.pathByName.he} > ${virtualCategoryItem.name.he}`
          : virtualCategoryItem.name.he;

        props.fetchVirtualCategoryByPathByName(
          getCheckCategoryNameFilter(generatedPathByNameEn, generatedPathByNameHe),
          defaultPagination,
          (items) => {
            const pathByNameEnWasChanged = (
              generatedPathByNameEn !== originalVirtualCategoryItem.pathByName.en
            );
            const pathByNameHeWasChanged = (
              generatedPathByNameHe !== originalVirtualCategoryItem.pathByName.he
            );

            if ((pathByNameEnWasChanged || pathByNameHeWasChanged)
              && items.some(i => i.id !== virtualCategoryItem.id) > 0) {
              props.setIsCategorySaving(false);
              return handleNotificationError(<IntlMessages id="category.create.alert.error" />);
            }

            return props.updateCategory({ ...categoryData, id: virtualCategoryItem.id }, params);
          },
        );
      } else {
        props.setIsCategorySaving(false);
      }
    }
    return false;
  };

  const loading = isCategorySaving
  || virtualCategoryUpdating
  || props.fetchingCategoriesByIds
  || virtualCategoryFetching;

  const getListParams = () => {
    let listParams = {
      onCloseClick: handleOnClose,
      disabled: loading,
    };

    const checkPermissions = (permission) => {
      if (abilityContext.can(
        permission,
        appPermissions.category.name,
      )) {
        return true;
      }
      return false;
    };

    if (checkPermissions(appPermissions.category.permissions.update)) {
      listParams = {
        ...listParams,
        buttons: {
          success: {
            className: 'button-primary',
          },
        },
        onSaveAndCloseClick: () => handleOnSaveClick({ closeAfterUpdate: true }),
        onSaveClick: handleOnSaveClick,
      };
    }

    return buttonList(listParams);
  };

  const buttonOrder = ['close', 'saveAndClose', 'save'];
  const orderedButtons = getListParams()
    .sort((a, b) => buttonOrder.indexOf(a.key) - buttonOrder.indexOf(b.key));

  const disabledClass = loading ? 'disabled' : '';

  return (
    <div className={`relative preview-drawer-footer ${disabledClass}`}>
      {loading && (
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className="progress-warning custom-loader bottom"
          size={20}
          thickness={4}
        />
      )}
      <ButtonGroup
        materialBtn
        className="btn-group-white flex items-center"
        list={orderedButtons}
      />
    </div>
  );
});

Footer.propTypes = {
  previewCategoryState: shape().isRequired,
  previewState: shape().isRequired,
  virtualCategories: arrayOf(shape()).isRequired,
  attributesMatrixList: arrayOf(shape()).isRequired,
  facetFilter: arrayOf(shape()).isRequired,

  onClose: func.isRequired,
  setSelectedProductList: func.isRequired,
  setIsCategorySaving: func.isRequired,
  setErrors: func.isRequired,
  setSortingsErrors: func.isRequired,
  setFiltersErrors: func.isRequired,
  fetchVirtualCategoryByPathByName: func.isRequired,
  updateCategory: func.isRequired,
  clearFromPreviewCategoryState: func.isRequired,
  fetchProductList: func.isRequired,
  fetchVirtualRootCategories: func.isRequired,
  setSelectedCategory: func.isRequired,

  categoryFacet: shape().isRequired,

  createPreviewCategoryVirtualFacet: func.isRequired,
  updatePreviewCategoryVirtualFacet: func.isRequired,
  deletePreviewCategoryVirtualFacet: func.isRequired,
  setPreviewCategoryVirtualFacetToDeleteId: func.isRequired,

  fetchingCategoriesByIds: bool.isRequired,
};

Footer.defaultProps = {};

const mapStateToProps = state => ({
  previewCategoryState: previewCategorySelector(state.previewCategory),
  previewState: previewSelector(state.preview),
  virtualCategories: state.virtualCategory.treeItems,
  attributesMatrixList: state.attributesMatrix.list,
  fetchingCategoriesByIds: state.physicalCategory.fetchingCategoriesByIds,

  categoryFacet: categoryFacetSelector(state.previewCategoryVirtualFacet),
});

const mapDispatchToProps = {
  setSelectedProductList,
  setIsCategorySaving,
  setErrors,
  setSortingsErrors,
  setFiltersErrors,
  fetchVirtualCategoryByPathByName,
  updateCategory,
  clearFromPreviewCategoryState,
  fetchProductList,
  fetchVirtualRootCategories,
  setSelectedCategory,

  createPreviewCategoryVirtualFacet,
  updatePreviewCategoryVirtualFacet,
  deletePreviewCategoryVirtualFacet,
  setPreviewCategoryVirtualFacetToDeleteId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
