import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const influencerItemSelector = item => item;

const mapProductsFilters = filters => ([{
  condition: 'or',
  group: filters.map(f => ({
    field: f.field,
    value: f.value,
    operator: f.operator,
  })),
}]);

const filtersSelector = createSelector(
  influencerItemSelector,
  item => (!isEmpty(item)
    ? mapProductsFilters([
      ...item.page.deal.filters || [],
      ...item.page.deal.selectedProductsFilters || [],
    ])
    : []),
);

export const dealMappedFiltersSelector = createSelector(
  filtersSelector,
  mappedFilters => (mappedFilters),
);

export default {
  dealMappedFiltersSelector,
};
