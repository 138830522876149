import { combineReducers } from 'redux';
import global from './global';
import settings from './settings';
import ranking from './ranking';
import authUserReducer from './AuthUserReducer';
import feedbacksReducer from './FeedbacksReducer';
import productAttribute from './productAttribute';
import session from './session';
import sidebar from './sidebar';
import user from './user';
import system from './system';
import virtualCategory from './virtualCategory';
import virtualCategoryWidget from './virtualCategoryWidget';
import physicalCategory from './physicalCategory';
import preview from './preview';
import rankingCategory from './rankingCategory';
import scopeCategory from './scopeCategory';
import error from './error';
import attributesMatrix from './attributesMatrix';
import previewFilterAttribute from './previewFilterAttribute';
import previewFieldAttribute from './previewFieldAttribute';
import facetInputTypes from './facetInputTypes';
import categoryFacet from './categoryFacet';
import previewFilter from './previewFilter';
import search from './search';
import promotion from './promotion';
import categoryImport from './categoryImport';
import productManagement from './productManagement';
import defaultAttribute from './defaultAttribute';
import pim from './pim';
import languageManager from './languageManager';
import serviceConfig from './serviceConfig';
import commentMessage from './commentMessage';
import influencer from './influencer';
import tune from './tune';
import previewCategory from './previewCategory';
import previewNavigation from './previewNavigation';
import quickEditDrawer from './quickEditDrawer';
import categoryVirtualFacet from './categoryVirtualFacet';
import navigationVirtualFacet from './navigationVirtualFacet';
import previewCategoryVirtualFacet from './previewCategoryVirtualFacet';
import previewNavigationVirtualFacet from './previewNavigationVirtualFacet';
import currency from './currency';
import virtualFacet from './virtualFacet';
import appSettings from './appSettings';
import product from './product';
import promotionWidget from './promotionWidget';
import contextMenu from './contextMenu';
import virtualCategoryPage from './virtualCategoryPage';
import virtualCategoryVirtualFacet from './virtualCategoryVirtualFacet';
import navigation from './navigation';
import cams from './cams';
import yotpo from './yotpo';
import feeds from './feeds';

const reducers = combineReducers({
  global,
  settings,
  authUser: authUserReducer,
  feedback: feedbacksReducer,
  productAttribute,
  session,
  ranking,
  sidebar,
  user,
  system,
  virtualCategory,
  physicalCategory,
  preview,
  rankingCategory,
  scopeCategory,
  error,
  attributesMatrix,
  previewFilterAttribute,
  previewFieldAttribute,
  facetInputTypes,
  categoryFacet,
  previewFilter,
  search,
  promotion,
  categoryImport,
  productManagement,
  defaultAttribute,
  pim,
  cams,
  yotpo,
  languageManager,
  serviceConfig,
  commentMessage,
  influencer,
  tune,
  previewCategory,
  previewNavigation,
  quickEditDrawer,
  categoryVirtualFacet,
  navigationVirtualFacet,
  previewCategoryVirtualFacet,
  previewNavigationVirtualFacet,
  currency,
  virtualFacet,
  appSettings,
  product,
  virtualCategoryWidget,
  promotionWidget,
  contextMenu,
  virtualCategoryPage,
  virtualCategoryVirtualFacet,
  navigation,
  feeds,
});

export default reducers;
