import { createSelector } from 'reselect';

const virtualCategoryRootSelector = state => state;

const stateSelector = createSelector(
  virtualCategoryRootSelector,
  state => ({
    collapse: state.collapse,
    loadedKeys: state.loadedKeys,
  }),
);

export const virtualCategorySelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  virtualCategorySelector,
};
