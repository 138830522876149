/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useProductSortingAttributes = (state, props) => {
  useEffect(() => {
    state.setProductSortingAttributes(props.attributes.productSorting);
  }, [props.attributes.productSorting]);
};

export default useProductSortingAttributes;
