import {
  FETCH_APP_SETTINGS_START,
  FETCH_APP_SETTINGS_FINISHED,
} from '../actions/types';

const initialState = {
  hasErrors: false,
  settings: {
    normalizations: [],
    pushings: [],
    rankingAttributes: []
  },
  fetchSettingsStarted: false,
  fetchSettingsFinished: false,
};

export default (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_APP_SETTINGS_START:
      return {
        ...state,
        ...action.payload
      };
    case FETCH_APP_SETTINGS_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
};
