/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  string, arrayOf, shape, func, bool,
} from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';

export const SuggestionsComponent = (props) => {
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(
    () => (props.searchValue
      ? setShowSuggestions(props.inputFocused)
      : setShowSuggestions(false)),
    [props.inputFocused, props.searchValue],
  );

  const phrases = Array.from(new Set(props.suggestions
    .map(el => el.searchPhrase)
    .flat()
    .filter(el => el.includes(props.searchValue))));

  let filteredSuggestions = [];
  phrases.forEach((p) => {
    const items = props.suggestions.filter(s => s.searchPhrase.includes(p));
    filteredSuggestions = [
      ...filteredSuggestions,
      ...items.map(i => ({
        name: i.name,
        value: p,
      })),
    ];
  });

  const handleClick = (suggestion) => {
    props.onSearchPhraseClick(suggestion);
    if (showSuggestions) setShowSuggestions(false);
  };

  return (
    <div className={`suggestions suggestions-wrapper ${props.wrapperClassName}`}>
      {showSuggestions && filteredSuggestions.map(suggestion => (
        <MenuItem
          key={`${suggestion.name}_${suggestion.value}`}
          value=""
          className="suggestions-item"
          onClick={() => handleClick(suggestion)}
        >
          <span>{`${suggestion.value} (${suggestion.name})`}</span>
        </MenuItem>
      ))}
    </div>
  );
};

SuggestionsComponent.propTypes = {
  wrapperClassName: string,
  suggestions: arrayOf(shape()),
  searchValue: string,
  onSearchPhraseClick: func,
  inputFocused: bool,
};

SuggestionsComponent.defaultProps = {
  wrapperClassName: '',
  suggestions: [],
  searchValue: '',
  onSearchPhraseClick: null,
  inputFocused: false,
};

export default SuggestionsComponent;
