/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import {
  func, shape, bool,
} from 'prop-types';

import { toBase64 } from '../../utils/base64Parser';
import Banner from '../../components/Banner';
import { getMainBannerFromStorage } from '../../../../actions/influencer';

const MainBannerContainer = memo((props) => {
  const {
    storage,
    influencer,
    handleBannerChange,
    bannerData,
    influencerPage,
    disabled,
  } = props;

  useEffect(() => {
    if (influencer.id && influencerPage.bannerImageUrl) {
      props.getMainBannerFromStorage(influencer.id, influencerPage.bannerImageUrl);
    }
  }, [influencerPage.bannerImageUrl]);

  const onBannerChange = async (e) => {
    const { files } = e.target;
    const file = files[0];
    const base64String = await toBase64(file);

    handleBannerChange({
      url: base64String,
      name: file.name,
    });
  };

  return (
    <Banner
      onBannerChange={onBannerChange}
      bannerUrl={storage.mainBannerUrl}
      alt={influencer.name}
      bannerData={bannerData}
      classNameSuffix="main"
      id="upload-main-banner"
      disabled={disabled}
    />
  );
});

MainBannerContainer.propTypes = {
  influencer: shape().isRequired,
  storage: shape().isRequired,
  bannerData: shape().isRequired,
  influencerPage: shape().isRequired,
  handleBannerChange: func.isRequired,
  getMainBannerFromStorage: func.isRequired,
  disabled: bool.isRequired,
};

const mapStateToProps = state => ({
  influencer: state.influencer.item,
  storage: state.influencer.storage,
  bannerData: state.influencer.mainBannerData,
  influencerPage: state.influencer.item.page,
});

const actionCreators = {
  getMainBannerFromStorage,
};

export default connect(mapStateToProps, actionCreators)(MainBannerContainer);
