import React from 'react';
import IntlMessages from "../../../util/IntlMessages";

export const MerchantInfo = ({item, editProduct, index, productId, variation, onlyName}) => {
  if (item.parentId === variation.id) {
    const productClass = item.id === productId ? ' merchant-active' : '';
    const merchantName = item.vendorName
      ? item.vendorName
      : item.title
        ? item.title.en
        : <IntlMessages id="components.merchant" /> + index + 1;

    const buybox = item.scores ? item.scores.buybox : null;

    if (onlyName === true) {
      return (
        <div>{ merchantName + ' (Buybox: ' + buybox + ')' }</div>
      );
    }

    return (
      <div
        onClick={() => editProduct(item.id)}
        className={'product-edit-dialog-top-buttons merchant' + productClass}
      >
        { merchantName + ' (Buybox: ' + buybox + ')' }
      </div>
    );
  }

  return (<></>);
}
