import React, { Fragment } from 'react';
import { shape, bool, string } from 'prop-types';

import PagePlaceholder from '../../../../../components/PagePlaceholder';
import IntlMessages from '../../../../../util/IntlMessages';

const PagePlaceholderComponent = ({ displayMode, productListEmpty, product }) => (
  <Fragment>
    {!displayMode && productListEmpty && !product.fetched && (
      <PagePlaceholder
        icon={<i className="ti-hand-point-up text-primary text-lg" />}
        title={<IntlMessages id="title.selectADisplayingMode" />}
        subtitle={<IntlMessages id="title.selectADisplayingModeBody" />}
      />
    )}
    {displayMode && productListEmpty && !product.fetching && !product.fetched && (
      <PagePlaceholder
        icon={<i className="ti-arrow-circle-left text-primary text-lg" />}
        title={<IntlMessages id="pim.title.applyFilterOrCategory" />}
        subtitle={<IntlMessages id="pim.subtitle.applyFilterOrCategory" />}
      />
    )}
    {displayMode && productListEmpty && !product.fetching && product.fetched && (
      <PagePlaceholder
        icon={<i className="ti-face-sad text-primary text-lg" />}
        title={<IntlMessages id="title.sorry" />}
        subtitle={<IntlMessages id="title.noResultsFound" />}
      />
    )}
  </Fragment>
);

PagePlaceholderComponent.propTypes = {
  product: shape().isRequired,
  displayMode: string.isRequired,
  productListEmpty: bool.isRequired,
};

PagePlaceholderComponent.defaultProps = {
};

export default PagePlaceholderComponent;
