import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const previewRootSelector = state => state;
const previewSelectedCategorySelector = state => state.selectedCategory;
const previewSelectedPromotionSelector = state => state.selectedPromotion;
const previewSelectedSearchFilterSelector = state => state.selectedSearchFilter;

const mapProductsOverride = (virtualParams) => {
  if (!virtualParams) return {};
  return ({
    ...virtualParams,
    productsOverride: virtualParams.positions
      ? Object.keys(virtualParams.positions)
        .map(p => ({ productId: p, position: virtualParams.positions[p] }))
      : [],
  });
};

const selectedCategorySelector = createSelector(
  previewSelectedCategorySelector,
  selectedCategory => (!isEmpty(selectedCategory) ? ({
    ...selectedCategory,
    virtualParams: mapProductsOverride(selectedCategory.virtualParams),
  }) : {}),
);

const selectedPromotionSelector = createSelector(
  previewSelectedPromotionSelector,
  selectedPromotion => (!isEmpty(selectedPromotion) ? ({
    ...selectedPromotion,
    virtualParams: mapProductsOverride(selectedPromotion.virtualParams),
  }) : {}),
);

const selectedSearchFilterSelector = createSelector(
  previewSelectedSearchFilterSelector,
  selectedSearchFilter => (!isEmpty(selectedSearchFilter) ? ({
    ...selectedSearchFilter,
    virtualParams: mapProductsOverride(selectedSearchFilter.virtualParams),
  }) : {}),
);

const stateSelector = createSelector(
  previewRootSelector,
  selectedCategorySelector,
  selectedPromotionSelector,
  selectedSearchFilterSelector,
  (preview, selectedCategory, selectedPromotion, selectedSearchFilter) => ({
    selectedCategory,
    selectedPromotion,
    selectedSearchFilter,
    showPopover: preview.showPopover,
    hiddenProductsIds: preview.hiddenProductsIds,
    isHiddenProductsMode: preview.isHiddenProductsMode,
    selectedProduct: preview.selectedProduct,
    productPinOrderHeplerTextId: preview.productPinOrderHeplerTextId,
    actionPopoverAnchor: preview.actionPopoverAnchor,
    displayMode: preview.displayMode,
    selectedPinAction: preview.selectedPinAction,
    productPinOrder: preview.productPinOrder,
  }),
);

export const previewSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  previewSelector,
};
