import firebase from 'firebase/app';
import { camelizeKeys } from 'humps';

import RestActions from '../util/rest/actions';
import { commentMessagesPath } from '../util/paths';
import { showErrorNotification } from '../util/api';

const restCommentMessageActions = new RestActions('comment_message');

export const fetchCommentMessages = () => async (dispatch) => {
  dispatch(restCommentMessageActions.fetchAllStart());
  let commentMessagesResponse;
  try {
    commentMessagesResponse = await firebase.database().ref(commentMessagesPath).once('value');
  } catch (error) {
    showErrorNotification(error, 'Firebase');
    dispatch(restCommentMessageActions.fetchAllFinished({ hasError: true }));
  }

  const commentMessages = commentMessagesResponse.val();
  dispatch(restCommentMessageActions.fetchAllFinished(camelizeKeys(commentMessages)));
};

export default {
  fetchCommentMessages,
};
