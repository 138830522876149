/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useState, useEffect,
} from 'react';
import {
  shape, arrayOf, bool, func, string,
} from 'prop-types';
import { isEmpty } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { camelize } from 'humps';

import Item from './Item';

const List = (props) => {
  const {
    productList, defaultImage, attributes, certainProductAttributes, productsImagesOverride,
    disabled, productComments, isEditMode, fetchActivityLogsByProductId, pagination, list,
  } = props;
  const [scrollContainerlassName, setScrollContainerlassName] = useState('width-full');
  const [elementStyle, setElementStyle] = useState({});
  const sortedAttributes = attributes.sort((a, b) => a.order - b.order);

  useEffect(() => {
    if (!isEmpty(productList)) {
      const scrollContainer = document.getElementById('scrollContainer');
      if (
        scrollContainer.scrollWidth <= scrollContainer.clientWidth
        && isEmpty(setScrollContainerlassName)
      ) {
        setScrollContainerlassName('width-full');
      }
      if (
        scrollContainer.scrollWidth > scrollContainer.clientWidth
        && isEmpty(elementStyle)
      ) {
        setScrollContainerlassName('width-auto');
        setElementStyle({ width: scrollContainer.scrollWidth + 80 });
      }
    }
  });

  const getValue = (p, o) => p.reduce((xs, x) => (((xs && xs[x]) || (xs && Number.isInteger(xs[x])))
    ? xs[x]
    : null), o);

  return (
    <div id="scrollContainer" className="product-list product-list-table-image-view">
      <Grid container spacing={0} className={`justify-center ${scrollContainerlassName}`} style={elementStyle}>
        {productList.map((item, index) => {
          const imgData = item.images ? item.images[0] : defaultImage;
          const rootValues = attributes
            .filter(attr => getValue(camelize(attr.code)
              .split('.'), item) !== undefined);
          const value = {
            id: item.id,
            imgData,
            brand: item.specifications.aoBrandNameOrig,
          };

          rootValues.forEach((v) => {
            const itemValue = getValue(camelize(v.code).split('.'), item);

            value[camelize(v.value)] = itemValue;
          });
          return (
            <Item
              key={item.id}
              disabled={disabled}
              index={index}
              parentValue={value}
              productList={productList}
              itemsCount={productList.length}
              productItem={item}
              sortedAttributes={sortedAttributes}
              certainProductAttributes={certainProductAttributes}
              isEditMode={isEditMode}
              fetchActivityLogsByProductId={fetchActivityLogsByProductId}
              pagination={pagination}
              productComments={productComments}
              productsImagesOverride={productsImagesOverride}
              list={list}
            />
          );
        })}
      </Grid>
    </div>
  );
};


List.propTypes = {
  productList: arrayOf(shape()).isRequired,
  defaultImage: string.isRequired,
  attributes: arrayOf(shape()),
  certainProductAttributes: arrayOf(shape()).isRequired,
  productComments: arrayOf(shape()).isRequired,
  isEditMode: bool.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  pagination: shape().isRequired,
  disabled: bool.isRequired,
  productsImagesOverride: arrayOf(shape()).isRequired,
  list: arrayOf(shape()).isRequired,
};

List.defaultProps = {
  attributes: [],
};

export default List;
