import {
  getFeedsActionsPath,
  updateFeedsActionsPath,
} from '../util/paths';

import {
  SET_FEEDS_DATA,
} from './types';

import { CDMSClient, showErrorNotification } from '../util/api';
import { getResultCondition } from "./pim";
import { setIsLoading } from './global';

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';
export const getFeedsDataRes = condition => CDMSClient.post(getFeedsActionsPath + '?ref=' + ref, condition);
export const updateFeedsDataRes = (id, condition) => CDMSClient.put(updateFeedsActionsPath(id), condition);

export const getFeedsData = ({ limit, offset, filter, sort, fields, reset }) => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const condition = getResultCondition(filter, sort, limit, offset / limit, true);
    if (fields) {
      condition['fields'] = fields;
    }

    const res = await getFeedsDataRes(condition);
    const { data } = res;

    dispatch({
      type: SET_FEEDS_DATA,
      payload: {
        data: data.data,
        scroll: { offset: offset, pages: res.data.pages, total: res.data.total },
        condition: condition,
        reset: reset,
      }
    });
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: SET_FEEDS_DATA,
      payload: { reset: false, }
    });
  }
  dispatch(setIsLoading(false));
};

export const updateFeedsData = (id, condition) => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    await updateFeedsDataRes(id, condition);
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
  dispatch(setIsLoading(false));
};

export const getFeedsCondition = (
  { limit, offset, filter = null, sort = null, reset = false }) => async (dispatch) => {
    const condition = getResultCondition(filter, sort, limit, offset);
    dispatch({
      type: SET_FEEDS_DATA,
      payload: {
        condition: condition,
        reset: reset
      }
    });
  };

export const setFeedsFilterState = ({ disabled }) => async (dispatch) => {
  dispatch({
    type: SET_FEEDS_DATA,
    payload: {
      filterDisabled: disabled,
    }
  });
};
