import React from 'react';
import { array, string, oneOfType } from 'prop-types';
import IntlMessages from '../../util/IntlMessages';


const InputErrors = ({ errors, className }) => (
  <div className={`form-errors ${className}`}>
    {errors && errors.length > 0
      && errors.map(err => (
        err.type !== 'maxNumber'
          ? <IntlMessages key={err.message} id={err.message} />
          : (
            <div key={err.message} className="condition-wrapper">
              <IntlMessages key={err.message} id={err.message} />
              <span className="ml-5">{err.condition}</span>
            </div>
          )
      ))}
  </div>
);

InputErrors.propTypes = {
  errors: oneOfType([array, string]),
  className: string,
};

InputErrors.defaultProps = {
  errors: null,
  className: '',
};

export default InputErrors;
