import firebase from 'firebase/app';
import { camelizeKeys } from 'humps';

import RestActions from '../util/rest/actions';
import { attributesMatrixPath } from '../util/paths';
import { showErrorNotification } from '../util/api';

const restAttributesMatrixActions = new RestActions('attributes_matrix');

export const fetchList = () => async (dispatch) => {
  dispatch(restAttributesMatrixActions.fetchAllStart());
  let attributesMatrix;
  try {
    attributesMatrix = await firebase.database().ref(attributesMatrixPath).once('value');
  } catch (error) {
    showErrorNotification(error, 'Firebase');
    dispatch(restAttributesMatrixActions.fetchAllFinished({ hasError: true }));
  }

  const restAttributesMatrixObj = attributesMatrix.val();
  dispatch(restAttributesMatrixActions.fetchAllFinished(camelizeKeys(restAttributesMatrixObj)));
};

export default {
  fetchList,
};
