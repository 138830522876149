import axios from 'axios';
import { decamelizeKeys, camelizeKeys } from 'humps';
import { isEmpty, uniq, cloneDeep } from 'lodash';
import RestActions from '../util/rest/actions';
import { showErrorNotification } from '../util/api';

import {
  FETCH_CHILD_VIRTUAL_CATEGORIES_START,
  FETCH_CHILD_VIRTUAL_CATEGORIES_FINISHED,
  FETCH_ROOT_VIRTUAL_CATEGORIES_START,
  FETCH_ROOT_VIRTUAL_CATEGORIES_FINISHED,
  SET_CATEGORY_TREE_ITEMS,
  SET_SELECTED_CATEGORY,
  SEARCH_VIRTUAL_CATEGORIES_START,
  SEARCH_VIRTUAL_CATEGORIES_FINISHED,
  TOGGLE_CATEGORY_TREE_COLLAPSE,
  TOGGLE_CATEGORY_TREE_COLLAPSE_ITEM,
  COLLAPSE_INITIAL_CATEGORIES_TREE,
  FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_START,
  FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_FINISHED,
  HANDLE_LOAD_SUBCATEGORIES,
  CLEAR_VIRTUAL_CATEGORY_TREE_STATE,
  FETCH_VIRTUAL_CATEGORIES_BY_NAME_START,
  FETCH_VIRTUAL_CATEGORIES_BY_NAME_FINISHED,
  CATEGORY_FETCH_PRODUCT_LIST_START,
  CATEGORY_FETCH_PRODUCT_LIST_FINISHED,
  CLEAR_CATEGORY_PRODUCT_STATE,
  CATEGORY_FETCH_PRODUCTS_BY_IDS_START,
  CATEGORY_FETCH_PRODUCTS_BY_IDS_FINISHED,
  COMPLETE_CATEGORY_PRODUCTS_LIST_FINISHED,
  COMPLETE_CATEGORY_PRODUCTS_LIST_START,
  CATEGORY_SET_SELECTED_PRODUCT_LIST,
  CATEGORY_SET_PRODUCTS_IN_IDS,
  CATEGORY_SET_PRODUCTS_NIN_IDS,
  CATEGORY_UPDATE_LOADED_PRODUCTS_IN,
  CATEGORY_UPDATE_LOADED_PRODUCTS_NIN,
  FETCH_CATEGORY_TO_DUPLICATE_START,
  FETCH_CATEGORY_TO_DUPLICATE_FINISHED,
} from './types';
import {
  virtualCategoriesPath,
  getCategoryPath,
  categoriesTreePath,
  getCategoryTreePath,
  categoriesSearchPath,
  categoriesBulkPath,
  productsFrontAppPath,
} from '../util/paths';

import {
  getValueByOperator,
  getFieldByOperator,
} from '../util/mapFiltersByOperator';

import {
  getFrontendProductsByIds,
} from '../services/API/product';

import { generateRequestBody } from '../util/productPayload';
import { mapObjectToArray } from '../util/objectsToArray';
import {
  mapToAttributesMatrix,
  mapFilters,
  unmapFromAttributesMatrix,
} from '../util/attributesMatrixMapping';
import normalizeFilters from '../util/normalizeFilters';
import { mapPositions } from '../util/mapVirtualParamsPositions';
import { mapExportableProducts } from '../util/mapExportableProducts';

import { CDMSClient } from '../util/api';

const restVirtualCategoryActions = new RestActions('virtual_category');

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';

export const onToggleVirtualCategoryTreeCollapse = collapse => (dispatch) => {
  dispatch({ type: TOGGLE_CATEGORY_TREE_COLLAPSE, payload: collapse });
};

export const onToggleCategoryTreeCollapseItem = catId => (dispatch) => {
  dispatch({ type: TOGGLE_CATEGORY_TREE_COLLAPSE_ITEM, payload: catId });
};

export const handleLoadVirtualSubcategories = loadedKeys => (dispatch) => {
  dispatch({ type: HANDLE_LOAD_SUBCATEGORIES, payload: { loadedKeys } });
};

export const clearVirtualCategoryTreeState = () => (dispatch) => {
  dispatch({ type: CLEAR_VIRTUAL_CATEGORY_TREE_STATE });
};

export const clearState = () => (dispatch) => {
  dispatch(restVirtualCategoryActions.clearAllFinished());
};

export const clearErrorState = () => (dispatch) => {
  dispatch(restVirtualCategoryActions.clearErrorFinished({ error: {}, hasErrors: false }));
};

export const setTreeItems = items => (dispatch) => {
  dispatch({
    type: SET_CATEGORY_TREE_ITEMS,
    payload: {
      items: camelizeKeys(items),
    },
  });
};

export const fetchItem = id => (dispatch, getState) => {
  const { list: attributesMatrix } = getState().attributesMatrix;
  dispatch(restVirtualCategoryActions.fetchStart());
  return axios.get(getCategoryPath(id)).then((res) => {
    const data = normalizeFilters(camelizeKeys(res.data.data));
    const payload = { ...data };
    payload.virtualParams = mapPositions(
      data.virtualParams,
      res.data.data.virtual_params,
    );
    dispatch(restVirtualCategoryActions.fetchFinished({
      item: unmapFromAttributesMatrix(payload, attributesMatrix),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restVirtualCategoryActions.fetchFinished({
      error: error.response.data, hasError: true,
    }));
  });
};

export const clearItemState = () => (dispatch) => {
  dispatch(restVirtualCategoryActions.clearOneFinished());
};

export const clearSearchListState = () => (dispatch) => {
  dispatch(restVirtualCategoryActions.clearSearchListFinished());
};

export const fetchRootCategories = (cb, params = {}) => async (dispatch) => {
  dispatch({ type: FETCH_ROOT_VIRTUAL_CATEGORIES_START });
  const path = `${categoriesTreePath}?is_virtual=1&show_disabled=${params.showDisabled === false ? '0' : '1'}`;
  try {
    const res = await cdmsFetchRootCategories(path);
    const rootCategories = camelizeKeys(res.data.data);

    dispatch({
      type: FETCH_ROOT_VIRTUAL_CATEGORIES_FINISHED,
      payload: { list: rootCategories }
    });
    
    if (cb) cb();

  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_ROOT_VIRTUAL_CATEGORIES_FINISHED,
      payload: {
        error: error.response ? error.response.data : '', hasError: true,
      },
    });
  }
};

export const cdmsFetchRootCategories = (path) => CDMSClient.get(path);

export const fetchChildCategories = (rootId, params = {}) => (dispatch, getState) => {
  const { treeItems } = getState().virtualCategory;
  const path = `${getCategoryTreePath(rootId)}?show_disabled=${params.showDisabled === false ? '0' : '1'}`;

  dispatch({
    type: FETCH_CHILD_VIRTUAL_CATEGORIES_START,
    payload: {
      fetchChildrenStarted: true,
      fetchChildrenFinished: false,
    },
  });
  return axios.get(path).then((res) => {
    const childCategories = camelizeKeys(res.data.data);

    dispatch({
      type: FETCH_CHILD_VIRTUAL_CATEGORIES_FINISHED,
      payload: {
        treeItems: [...treeItems, ...childCategories],
        rootId,
        fetchChildrenStarted: false,
        fetchChildrenFinished: true,
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_CHILD_VIRTUAL_CATEGORIES_FINISHED,
      payload: {
        error: error.response.data, hasError: true,
      },
    });
  });
};

export const fetchMultipleChildScopeCategories = items => (dispatch) => {
  dispatch({ type: FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_START });

  Promise.all(items.map(async (item) => {
    await dispatch(fetchChildCategories(item));
  })).then(() => {
    dispatch({ type: FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_FINISHED });
  }, (error) => {
    dispatch({
      type: FETCH_MULTIPLE_CHILD_VIRTUAL_CATEGORIES_FINISHED,
      error,
      hasError: true,
    });
  });
};


export const fetchRecursiveParentCategory = cId => (dispatch) => {
  dispatch({ type: COLLAPSE_INITIAL_CATEGORIES_TREE });

  return axios.get(getCategoryPath(cId)).then((res) => {
    const cat = camelizeKeys(res.data.data);
    if (cat && cat.hasChildren) {
      dispatch(onToggleCategoryTreeCollapseItem(cat.id));
    }

    if (cat && cat.parentId) dispatch(fetchRecursiveParentCategory(cat.parentId));
  });
};

export const searchCategories = query => (dispatch) => {
  dispatch({ type: SEARCH_VIRTUAL_CATEGORIES_START });
  return axios.post(categoriesSearchPath + '?ref=' + ref, query).then((res) => {
    const searchResults = camelizeKeys(mapObjectToArray(res.data.data));
    const result = searchResults.map((c) => {
      const originalCategory = mapObjectToArray(res.data.data)
        .find(category => category.id === c.id);
      const payload = { ...c };

      if (originalCategory) {
        payload.virtualParams = mapPositions(
          c.virtualParams,
          originalCategory.virtual_params,
        );
      }
      return payload;
    });
    dispatch({
      type: SEARCH_VIRTUAL_CATEGORIES_FINISHED,
      payload: {
        list: result,
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: SEARCH_VIRTUAL_CATEGORIES_FINISHED,
      payload: {
        error: error.response.data, hasError: true,
      },
    });
  });
};

export const create = (category, params) => (dispatch, getState) => {
  const payload = cloneDeep(category);
  const transformedPath = params.isSync
    ? `${virtualCategoriesPath}?is_synchronous=${params.isSync}`
    : virtualCategoriesPath;
  const { list: attributesMatrix } = getState().attributesMatrix;

  if (payload.virtualParams.facets) delete payload.virtualParams.facets;

  const data = {
    ...payload,
    virtual_params: {
      ...payload.virtual_params,
      selected_categories: uniq(payload.virtualParams.selectedCategories),
      mapped_categories: uniq(payload.virtualParams.mappedCategories),
      ranking_rule_code: payload.virtualParams.rankingRuleCode || null,
      sort_rules: payload.virtualParams.sortRules.map(s => ({
        field: mapToAttributesMatrix(s.field, attributesMatrix),
        direction: s.direction.toLowerCase(),
      })),
      filters: mapFilters(payload.virtualParams.filters, attributesMatrix),
    },
  };

  dispatch(restVirtualCategoryActions.createStart());
  return axios.post(transformedPath, { data: decamelizeKeys(data) }).then((res) => {
    dispatch(restVirtualCategoryActions.createFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restVirtualCategoryActions.createFinished({
      error: error.response.data, hasErrors: true,
    }));
  });
};

export const mapVirtualParams = (virtualParams, mapToMatrix, attributesMatrix) => {
  const positions = Array.isArray(virtualParams.positions) ? {} : virtualParams.positions;
  return ({
    ...virtualParams,
    filters: mapToMatrix ? [...mapFilters(virtualParams.filters, attributesMatrix)]
      : virtualParams.filters,
    sortRules: mapToMatrix ? virtualParams.sortRules.map(s => ({
      field: mapToAttributesMatrix(s.field, attributesMatrix),
      direction: s.direction.toLowerCase(),
    })) : virtualParams.sortRules,
    positions: virtualParams.productsOverride
      ? virtualParams.productsOverride.reduce((result, item) => {
        const payload = { ...result };

        payload[item.productId] = item.position;
        return payload;
      }, {})
      : positions,
  });
};

export const update = (category, params) => (dispatch, getState) => {
  const transformedPath = params.isSync
    ? `${getCategoryPath(category.id)}?is_synchronous=${params.isSync}`
    : getCategoryPath(category.id);
  const { list: attributesMatrix } = getState().attributesMatrix;
  const { virtualParams } = category;
  const categoryToUpdate = {
    ...category,
    virtualParams: mapVirtualParams(virtualParams, params.mapToMatrix, attributesMatrix),
  };
  delete categoryToUpdate.virtualParams.facets;
  const data = decamelizeKeys(categoryToUpdate);

  if (category.virtualParams.productsOverride
    && isEmpty(category.virtualParams.productsOverride)) {
    delete data.virtual_params.positions;
  } else {
    data.virtual_params.positions = categoryToUpdate.virtualParams.positions;
  }

  if (data.virtual_params.products_override) {
    delete data.virtual_params.products_override;
  }

  dispatch(clearErrorState());
  dispatch(restVirtualCategoryActions.updateStart());

  delete data.virtual_params.facets; // TODO remove

  return axios.put(transformedPath, {
    data,
  }).then((res) => {
    const updatedCategory = camelizeKeys(res.data.data);
    const payload = { ...updatedCategory };
    payload.virtualParams = mapPositions(
      updatedCategory.virtualParams,
      res.data.data.virtual_params,
    );
    dispatch(restVirtualCategoryActions.updateFinished({
      success: true,
      hasErrors: false,
      item: unmapFromAttributesMatrix(payload, attributesMatrix),
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restVirtualCategoryActions.updateFinished({
      error, hasErrors: true,
    }));
  });
};

export const deleteCategory = (id, params) => (dispatch) => {
  const transformedPath = params.isSync
    ? `${getCategoryPath(id)}?is_synchronous=${params.isSync}`
    : getCategoryPath(id);
  dispatch(restVirtualCategoryActions.deleteStart());
  return axios.delete(transformedPath).then(() => {
    dispatch(restVirtualCategoryActions.deleteFinished({ lastDeletedCategoryId: id }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restVirtualCategoryActions.deleteFinished({
      error: error.response.data,
      hasError: true,
    }));
  });
};

export const clearFromVirtualCategoryState = payload => (dispatch) => {
  dispatch(restVirtualCategoryActions.clearFromStateFinished(payload));
};

export const fetchVirtualCategoryByPathByName = (filter, pagination, cb) => (dispatch) => {
  dispatch({ type: FETCH_VIRTUAL_CATEGORIES_BY_NAME_START });
  axios.post(categoriesSearchPath + '?ref=' + ref, decamelizeKeys({ ...filter, pagination })).then((res) => {
    const itemAlreadyExist = res.data.data && res.data.data.length > 0;
    dispatch({
      type: FETCH_VIRTUAL_CATEGORIES_BY_NAME_FINISHED,
      payload: { itemAlreadyExist },
    });
    if (cb) cb(res.data.data);
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_VIRTUAL_CATEGORIES_BY_NAME_FINISHED,
      payload: { error: error.response.data, hasError: true },
    });
  });
};

export const bulkUpdateVirtualCategories = (categories, params) => (dispatch, getState) => {
  const { list: attributesMatrix } = getState().attributesMatrix;

  dispatch(restVirtualCategoryActions.updateMultipleStart());

  return axios.put(categoriesBulkPath, {
    data: decamelizeKeys(categories.map(c => ({
      ...c,
      virtualParams: mapVirtualParams(c.virtualParams, params.mapToMatrix, attributesMatrix),
    }))),
  }).then(() => {
    dispatch(restVirtualCategoryActions.updateMultipleFinished({
      success: true,
      hasErrors: false,
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(restVirtualCategoryActions.updateMultipleFinished({
      error, hasErrors: true,
    }));
  });
};

export const fetchProductList = ({
  viewMode,
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  fullText = {},
  compoundSearch = null,
}) => (dispatch, getState) => {
  const sort = [{
    field: 'categories.position',
    order: 'asc',
  }];
  const { list } = getState().previewFilterAttribute;
  const mappedFilters = filter.map((f) => {
    const group = f.group.map(g => ({
      ...g,
      value: getValueByOperator({ value: g.value, operator: g.operator }),
      field: getFieldByOperator({
        field: g.field, operator: g.operator, previewFilterAttribute: list,
      }),
    }));

    return {
      ...f,
      group,
    };
  });

  const body = {
    viewMode,
    fields,
    pagination,
    fullText,
    sort,
    searchFilter,
  };

  if (!isEmpty(mappedFilters)) body.filter = mappedFilters;

  const params = {
    ...generateRequestBody(body),
  };

  if (compoundSearch && !isEmpty(compoundSearch) && compoundSearch.value) {
    params.body.compoundSearch = compoundSearch;
  }

  dispatch({ type: CATEGORY_FETCH_PRODUCT_LIST_START });

  return axios.post(
    params.path,
    decamelizeKeys(params.body),
  ).then((res) => {
    const {
      pages, total, aggregation,
    } = res.data;
    const result = res.data.data.map((p) => {
      if (p.variants) {
        return {
          ...p,
          variants: Object.keys(p.variants).map((v) => {
            const item = p.variants[v];
            return ({
              ...item,
              id: v,
            });
          }),
        };
      }
      return p;
    });

    dispatch({
      type: CATEGORY_FETCH_PRODUCT_LIST_FINISHED,
      payload: {
        list: mapExportableProducts(camelizeKeys(result)),
        aggregation,
        pages,
        total,
        updated: false,
        updating: false,
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: CATEGORY_FETCH_PRODUCT_LIST_FINISHED,
      payload: {
        error: error.response.data,
        hasErrors: true,
        list: [],
      },
    });
  });
};

export const clearProductState = () => (dispatch) => {
  dispatch({ type: CLEAR_CATEGORY_PRODUCT_STATE });
};

export const fetchProductsByIdsStart = payload => (dispatch) => {
  dispatch({
    type: CATEGORY_FETCH_PRODUCTS_BY_IDS_START,
    payload,
  });
};

export const fetchProductsByIdsFinished = payload => (dispatch) => {
  dispatch({
    type: CATEGORY_FETCH_PRODUCTS_BY_IDS_FINISHED,
    payload,
  });
  return payload;
};

export const fetchProductsByIds = (ids, key, pagination) => async (dispatch, getState) => {
  const virtualCategoryState = getState().virtualCategory;
  const currentKey = key || 'productsByIds';
  const currentTargetList = virtualCategoryState.product[currentKey];
  dispatch(fetchProductsByIdsStart());

  const condition = ids.length > 1
    ? { condition: 'or' }
    : {};

  return axios.post(
    `${productsFrontAppPath}/search`,
    {
      filter: [
        {
          ...condition,
          group: [{
            field: 'id',
            value: uniq(ids),
            operator: 'in',
          }],
        },
      ],
      pagination,
    },
  ).then((res) => {
    const items = mapExportableProducts(camelizeKeys(res.data.data));

    dispatch(fetchProductsByIdsFinished({
      [currentKey]: [...currentTargetList.map((l) => {
        const itemToSet = items.find(p => p.id === l.id);
        return itemToSet || l;
      })],
    }));
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch(fetchProductsByIdsFinished({
      error: error.response.data, hasErrors: true,
    }));
  });
};

export const completeProductList = (ids, key, pagination) => async (dispatch, getState) => {
  dispatch({
    type: COMPLETE_CATEGORY_PRODUCTS_LIST_START,
    payload: {
      completingCategoryProductsList: true,
      completedCategoryProductsList: false,
    },
  });

  const currentKey = key || 'productsByIds';
  const virtualCategoryState = getState().virtualCategory;
  const currentTargetList = virtualCategoryState.product[currentKey];
  const query = {
    filter: [{
      group: [{
        field: 'id',
        value: uniq(ids),
        operator: 'in',
      }],
    }],
    pagination,
  };

  const res = await getFrontendProductsByIds(query);
  const items = camelizeKeys(res);

  dispatch({
    type: COMPLETE_CATEGORY_PRODUCTS_LIST_FINISHED,
    payload: {
      completingCategoryProductsList: false,
      completedCategoryProductsList: true,
      [currentKey]: [...currentTargetList.map((l) => {
        const itemToSet = items.find(p => p.id === l.id);
        return itemToSet || l;
      })],
    },
  });
};

export const setSelectedProductList = payload => (dispatch) => {
  dispatch({
    type: CATEGORY_SET_SELECTED_PRODUCT_LIST,
    payload: {
      selectedProductList: payload,
    },
  });
};

export const setProductsInIds = payload => (dispatch) => {
  dispatch({
    type: CATEGORY_SET_PRODUCTS_IN_IDS,
    payload: {
      productsInIds: payload,
    },
  });
};

export const setProductsNotInIds = payload => (dispatch) => {
  dispatch({
    type: CATEGORY_SET_PRODUCTS_NIN_IDS,
    payload: {
      productsNotInIds: payload,
    },
  });
};

export const updateLoadedProductsIn = payload => (dispatch) => {
  dispatch({
    type: CATEGORY_UPDATE_LOADED_PRODUCTS_IN,
    payload: {
      productsByIdsForIn: payload,
    },
  });
};

export const updateLoadedProductsNin = payload => (dispatch) => {
  dispatch({
    type: CATEGORY_UPDATE_LOADED_PRODUCTS_NIN,
    payload: {
      productsByIdsForNin: payload,
    },
  });
};

export const setSelectedCategory = id => (dispatch) => {
  dispatch({
    type: SET_SELECTED_CATEGORY,
    payload: {
      selectedCategory: id,
    },
  });
};

export const fetchCategoryToDuplicate = id => (dispatch, getState) => {
  const { list: attributesMatrix } = getState().attributesMatrix;
  dispatch({
    type: FETCH_CATEGORY_TO_DUPLICATE_START,
    fetchCategoryToDuplicateStarted: true,
    fetchCategoryToDuplicateFinished: false,
  });
  return axios.get(getCategoryPath(id)).then((res) => {
    const data = normalizeFilters(camelizeKeys(res.data.data));
    const payload = { ...data };
    payload.virtualParams = mapPositions(
      data.virtualParams,
      res.data.data.virtual_params,
    );

    dispatch({
      type: FETCH_CATEGORY_TO_DUPLICATE_FINISHED,
      payload: {
        fetchCategoryToDuplicateStarted: false,
        fetchCategoryToDuplicateFinished: true,
        categoryToDuplicate: unmapFromAttributesMatrix(payload, attributesMatrix),
      },
    });
  }, (error) => {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: FETCH_CATEGORY_TO_DUPLICATE_FINISHED,
      payload: {
        error: error.response.data,
        hasError: true,
      },
    });
  });
};

export default {
  fetchItem,
  fetchChildCategories,
  fetchRootCategories,
  setTreeItems,
  searchCategories,
  create,
  update,
  clearErrorState,
  deleteCategory,
  onToggleVirtualCategoryTreeCollapse,
  fetchVirtualCategoryByPathByName,
  bulkUpdateVirtualCategories,
};
