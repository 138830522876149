import { isEmpty } from 'lodash';

export default ({ productsList, status, reviewer }) => (!isEmpty(productsList)
  ? productsList.map((p) => {
    if (
      (p.pimStatus && p.pimStatus.status !== status)
      || !p.pimStatus
    ) {
      return ({
        id: p.id,
        pimStatus: {
          status,
          reviewedBy: reviewer,
        },
      });
    }
    return false;
  }).filter(p => p)
  : []);
