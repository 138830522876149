import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  UPDATE_INFLUENCER_FIELDS,
  SET_AVATAR_DATA,
  GET_AVATAR_FROM_STORAGE_START,
  GET_AVATAR_FROM_STORAGE_FINISHED,
  SET_MAIN_BANNER_DATA,
  GET_MAIN_BANNER_FROM_STORAGE_START,
  GET_MAIN_BANNER_FROM_STORAGE_FINISHED,
  SET_SELECTED_VIRTUAL_CATEGORIES,
  SET_SELECTED_CATEGORIES_ATTRIBUTES,
  UPDATE_TOP_TEN_FILTERS,
  INFLUENCER_FETCH_PRODUCT_LIST_START,
  INFLUENCER_FETCH_PRODUCT_LIST_FINISHED,
  INFLUENCER_FETCH_TOP_TEN_PRODUCTS_BY_IDS_START,
  INFLUENCER_FETCH_TOP_TEN_PRODUCTS_BY_IDS_FINISHED,
  INFLUENCER_COMPLETE_TOP_TEN_PRODUCTS_LIST_START,
  INFLUENCER_COMPLETE_TOP_TEN_PRODUCTS_LIST_FINISHED,
  INFLUENCER_COMPLETE_PRODUCT_SECTION_PRODUCTS_LIST_START,
  INFLUENCER_COMPLETE_PRODUCT_SECTION_PRODUCTS_LIST_FINISHED,
  INFLUENCER_SET_TOP_TEN_PRODUCT_FILTER,
  INFLUENCER_SET_SELECTED_TOP_TEN_PRODUCT_LIST,
  INFLUENCER_SET_TOP_TEN_PRODUCTS_IN_IDS,
  INFLUENCER_SET_PRODUCT_SECTION_PRODUCTS_IN_IDS,
  INFLUENCER_UPDATE_PRODUCT_SECTION_LOADED_PRODUCTS_IN,
  INFLUENCER_UPDATE_TOP_TEN_LOADED_PRODUCTS_IN,
  INFLUENCER_FETCH_EXAMPLE_PRODUCTS_START,
  INFLUENCER_FETCH_EXAMPLE_PRODUCTS_FINISHED,
  UPDATE_PRODUCTS_FILTERS,
  INFLUENCER_PRODUCTS_SECTION_PRODUCT_FILTER,
  INFLUENCER_SET_SELECTED_PRODUCTS_SECTION_PRODUCT_LIST,
  INFLUENCER_SET_SELECTED_TOP_TEN_PRODUCTS_FILTER,
  INFLUENCER_SET_SELECTED_PRODUCT_SECTION_PRODUCTS_FILTER,
  INFLUENCER_FETCH_PRODUCT_SECTION_PRODUCTS_BY_IDS_START,
  INFLUENCER_FETCH_PRODUCT_SECTION_PRODUCTS_BY_IDS_FINISHED,
  INFLUENCER_SET_SELECTED_DEAL_SECTION_PRODUCT_LIST,
  INFLUENCER_DEAL_SECTION_PRODUCT_FILTER,
  INFLUENCER_UPDATE_DEAL_SECTION_LOADED_PRODUCTS_IN,
  INFLUENCER_SET_DEAL_SECTION_PRODUCTS_IN_IDS,
  INFLUENCER_COMPLETE_DEAL_SECTION_PRODUCTS_LIST_START,
  INFLUENCER_COMPLETE_DEAL_SECTION_PRODUCTS_LIST_FINISHED,
  INFLUENCER_FETCH_DEAL_SECTION_PRODUCTS_BY_IDS_START,
  INFLUENCER_FETCH_DEAL_SECTION_PRODUCTS_BY_IDS_FINISHED,
  INFLUENCER_SET_SELECTED_DEAL_SECTION_PRODUCTS_FILTER,
  UPDATE_DEAL_FILTERS,
  SET_DEAL_BANNER_DATA,
  GET_DEAL_BANNER_FROM_STORAGE_START,
  GET_DEAL_BANNER_FROM_STORAGE_FINISHED,
  INFLUENCER_SET_TEXT_FIELDS_ERRORS,
  INFLUENCER_CLEAR_PRODUCT_STATE,
} from '../actions/types';

const initialState = {
  item: {},
  original: {},
  noBannerImage: 'https://image.flaticon.com/icons/png/512/149/149092.png',
  avatarData: {},
  mainBannerData: {},
  dealBannerData: {},
  storage: {},
  selectedCategoriesAttributes: [],
  textFieldsErrors: {},
  product: {
    list: [],
    exampleProductsList: [],
    topTen: {
      productFilter: [],
      selectedProductList: [],
      productsInIds: [],
      productsByIdsForIn: [],
      completingCategoryProductsList: false,
      completedCategoryProductsList: false,
      fetchProductsByIdsStarted: false,
      fetchProductsByIdsFinished: false,
    },
    deal: {
      productFilter: [],
      selectedProductList: [],
      productsInIds: [],
      productsByIdsForIn: [],
      completingCategoryProductsList: false,
      completedCategoryProductsList: false,
      fetchProductsByIdsStarted: false,
      fetchProductsByIdsFinished: false,
    },
    productSection: {
      productFilter: [],
      selectedProductList: [],
      productsInIds: [],
      productsByIdsForIn: [],
      completingCategoryProductsList: false,
      completedCategoryProductsList: false,
      fetchProductsByIdsStarted: false,
      fetchProductsByIdsFinished: false,
    },
    influencerFetchProductListStart: false,
    influencerFetchProductListFinished: false,
    fetchingExampleProductsList: false,
    fetchedExampleProductsList: false,
  },
};
const influencerRestTypes = restTypes('influencer');
const {
  fetchOneStart,
  fetchOneFinished,
  createStart,
  createFinished,
  updateStart,
  updateFinished,
} = influencerRestTypes;

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };
    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case createStart:
      return {
        ...state,
        ...restStates.createStart,
      };
    case createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        ...action.payload,
      };
    case updateStart:
      return {
        ...state,
        ...restStates.updateStart,
      };
    case updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
      };
    case UPDATE_INFLUENCER_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_AVATAR_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case GET_AVATAR_FROM_STORAGE_START:
      return {
        ...state,
        ...action.payload,
      };
    case GET_AVATAR_FROM_STORAGE_FINISHED:
      return {
        ...state,
        getAvatarFromStorageFinished: action.payload.getAvatarFromStorageFinished,
        storage: {
          ...state.storage,
          avatarUrl: action.payload.avatarUrl,
        },
      };
    case SET_MAIN_BANNER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SET_DEAL_BANNER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case GET_MAIN_BANNER_FROM_STORAGE_START:
      return {
        ...state,
        ...action.payload,
      };
    case GET_DEAL_BANNER_FROM_STORAGE_START:
      return {
        ...state,
        ...action.payload,
      };
    case GET_MAIN_BANNER_FROM_STORAGE_FINISHED:
      return {
        ...state,
        getMainBannerFromStorageFinished: action.payload.getMainBannerFromStorageFinished,
        storage: {
          ...state.storage,
          mainBannerUrl: action.payload.bannerUrl,
        },
      };
    case GET_DEAL_BANNER_FROM_STORAGE_FINISHED:
      return {
        ...state,
        getMainDealFromStorageFinished: action.payload.getDealBannerFromStorageFinished,
        storage: {
          ...state.storage,
          dealBannerUrl: action.payload.bannerUrl,
        },
      };
    case SET_SELECTED_VIRTUAL_CATEGORIES:
      return {
        ...state,
        item: {
          ...state.item,
          page: {
            ...state.item.page,
            topTen: {
              ...state.item.page.topTen,
              virtualCategories: action.payload.virtualCategories,
            },
          },
        },
      };
    case SET_SELECTED_CATEGORIES_ATTRIBUTES:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_TOP_TEN_FILTERS:
      return {
        ...state,
        item: {
          ...state.item,
          page: {
            ...state.item.page,
            topTen: {
              ...state.item.page.topTen,
              filters: action.payload.topTenFilters,
            },
          },
        },
      };
    case INFLUENCER_SET_SELECTED_TOP_TEN_PRODUCTS_FILTER:
      return {
        ...state,
        item: {
          ...state.item,
          page: {
            ...state.item.page,
            topTen: {
              ...state.item.page.topTen,
              selectedProductsFilters: action.payload.selectedProductsFilters,
            },
          },
        },
      };
    case INFLUENCER_SET_SELECTED_PRODUCT_SECTION_PRODUCTS_FILTER:
      return {
        ...state,
        item: {
          ...state.item,
          page: {
            ...state.item.page,
            productSection: {
              ...state.item.page.productSection,
              selectedProductsFilters: action.payload.selectedProductsFilters,
            },
          },
        },
      };
    case INFLUENCER_SET_SELECTED_DEAL_SECTION_PRODUCTS_FILTER:
      return {
        ...state,
        item: {
          ...state.item,
          page: {
            ...state.item.page,
            deal: {
              ...state.item.page.deal,
              selectedProductsFilters: action.payload.selectedProductsFilters,
            },
          },
        },
      };
    case INFLUENCER_FETCH_PRODUCT_LIST_START:
    case INFLUENCER_FETCH_PRODUCT_LIST_FINISHED:
    case INFLUENCER_FETCH_EXAMPLE_PRODUCTS_START:
    case INFLUENCER_FETCH_EXAMPLE_PRODUCTS_FINISHED:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
        },
      };
    case INFLUENCER_SET_SELECTED_TOP_TEN_PRODUCT_LIST:
    case INFLUENCER_SET_TOP_TEN_PRODUCT_FILTER:
    case INFLUENCER_UPDATE_TOP_TEN_LOADED_PRODUCTS_IN:
    case INFLUENCER_SET_TOP_TEN_PRODUCTS_IN_IDS:
    case INFLUENCER_COMPLETE_TOP_TEN_PRODUCTS_LIST_START:
    case INFLUENCER_COMPLETE_TOP_TEN_PRODUCTS_LIST_FINISHED:
    case INFLUENCER_FETCH_TOP_TEN_PRODUCTS_BY_IDS_START:
    case INFLUENCER_FETCH_TOP_TEN_PRODUCTS_BY_IDS_FINISHED:
      return {
        ...state,
        product: {
          ...state.product,
          topTen: {
            ...state.product.topTen,
            ...action.payload,
          },
        },
      };
    case INFLUENCER_SET_SELECTED_DEAL_SECTION_PRODUCT_LIST:
    case INFLUENCER_DEAL_SECTION_PRODUCT_FILTER:
    case INFLUENCER_UPDATE_DEAL_SECTION_LOADED_PRODUCTS_IN:
    case INFLUENCER_SET_DEAL_SECTION_PRODUCTS_IN_IDS:
    case INFLUENCER_COMPLETE_DEAL_SECTION_PRODUCTS_LIST_START:
    case INFLUENCER_COMPLETE_DEAL_SECTION_PRODUCTS_LIST_FINISHED:
    case INFLUENCER_FETCH_DEAL_SECTION_PRODUCTS_BY_IDS_START:
    case INFLUENCER_FETCH_DEAL_SECTION_PRODUCTS_BY_IDS_FINISHED:
      return {
        ...state,
        product: {
          ...state.product,
          deal: {
            ...state.product.deal,
            ...action.payload,
          },
        },
      };
    case INFLUENCER_SET_SELECTED_PRODUCTS_SECTION_PRODUCT_LIST:
    case INFLUENCER_PRODUCTS_SECTION_PRODUCT_FILTER:
    case INFLUENCER_UPDATE_PRODUCT_SECTION_LOADED_PRODUCTS_IN:
    case INFLUENCER_SET_PRODUCT_SECTION_PRODUCTS_IN_IDS:
    case INFLUENCER_COMPLETE_PRODUCT_SECTION_PRODUCTS_LIST_START:
    case INFLUENCER_COMPLETE_PRODUCT_SECTION_PRODUCTS_LIST_FINISHED:
    case INFLUENCER_FETCH_PRODUCT_SECTION_PRODUCTS_BY_IDS_START:
    case INFLUENCER_FETCH_PRODUCT_SECTION_PRODUCTS_BY_IDS_FINISHED:
      return {
        ...state,
        product: {
          ...state.product,
          productSection: {
            ...state.product.productSection,
            ...action.payload,
          },
        },
      };
    case UPDATE_PRODUCTS_FILTERS:
      return {
        ...state,
        item: {
          ...state.item,
          page: {
            ...state.item.page,
            productSection: {
              ...state.item.page.productSection,
              filters: action.payload.productsFilters,
            },
          },
        },
      };
    case UPDATE_DEAL_FILTERS:
      return {
        ...state,
        item: {
          ...state.item,
          page: {
            ...state.item.page,
            deal: {
              ...state.item.page.deal,
              filters: action.payload.dealFilters,
            },
          },
        },
      };
    case INFLUENCER_SET_TEXT_FIELDS_ERRORS:
      return {
        ...state,
        ...action.payload,
      };
    case INFLUENCER_CLEAR_PRODUCT_STATE:
      return {
        ...state,
        product: {
          ...initialState.product,
        },
      };

    default: return state;
  }
};
