/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  shape, arrayOf, node, oneOfType, string, func, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import { TrendingUp } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';

import swal from '@sweetalert/with-react';
import {
  sortableContainer, sortableElement, sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import FacetManagementDialog from 'components/FacetManagementDialog';
import RctCollapsibleCard from 'components/RctCollapsibleCard/RctCollapsibleCard';
import { mappedTBody } from 'components/Facet/tableData';
import IntlMessages from 'util/IntlMessages';

import useStateStore from 'components/Facet/hooks/useState';
import useList from 'components/Facet/hooks/useList';
import facetOptions from './utils/facetOptions';

const DragHandle = sortableHandle(() => <i className={`ti-drag ${window.location.pathname.includes('new') ? 'disabled' : ''}`} />);

const SortableItem = sortableElement(({
  value, disabledGlobally,
}) => (
  <li className={`drag-list-item ${disabledGlobally || value.disabled ? 'disabled' : ''}`}>
    <DragHandle disabled={disabledGlobally || value.disabled} />
    {value.cells.map(cell => (
      <span
        key={cell.dataField}
        className={`td td-${cell.className}`}
      >
        {cell.content}
      </span>
    ))}
    <div className="drag-list-actions flex items-center">
      {value.isPromoted && (
        <Tooltip title="Facet promoted">
          <TrendingUp style={{ color: green[500] }} fontSize="small" />
        </Tooltip>
      )}
      {!value.disabled && facetOptions.map((option) => {
        const handleClick = () => value.onDropdownChange(option, value);
        return (
          <Button
            key={option.value}
            onClick={handleClick}
            color={option.color}
            className={option.className}
          >
            {option.label}
          </Button>
        );
      })}
    </div>
  </li>
));

const getOriginalFacetList = list => (
  !isEmpty(list)
    ? list.filter(f => !f.default)
    : []
);

const SortableContainer = sortableContainer(({ children }) => (
  <ul className="drag-list-container drag-list-dragger">{children}</ul>
));

const Facet = (props) => {
  const state = useStateStore();
  useList({
    setSelectedList: state.setSelectedList,
    setList: state.setList,
    setSelected: state.setSelected,
    tBody: props.table.tBody,
    list: props.list,
  });

  const handleDeleteFacet = () => props.onDeleteButton.onClick({ delete: true });

  const handleDeleteFacetItem = (item) => {
    state.setSelectedFacet({});
    const newFilteredList = state.selectedList.filter(
      l => !l.default && l.label.en !== item.label.en,
    );
    const defaultList = state.selectedList.filter(l => l.default);
    const newList = [...defaultList, ...newFilteredList];

    if (props.onItemDelete) {
      props.onItemDelete(newList, item);
    }

    state.setSelectedList(newList);
  };

  const preHandleFacetItemDelete = (newList) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to permanently delete current virtual facet?',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    }).then((willDiscard) => {
      if (willDiscard) {
        handleDeleteFacetItem(newList);
      }
    });
  };

  const handleEditFacet = (item) => {
    state.setIsDialogOpen(true);
    state.setSelectedFacet(item);
    state.setFacetDialogTitleCode('facet.dialog.edit.title');
    state.setFacetDialogMode('edit');
  };

  const handleDropdownChange = (option, cell) => {
    state.setFacetManagementEventName(option.value);
    switch (option.value) {
      case 'delete':
        preHandleFacetItemDelete(cell);
        break;
      case 'edit':
        handleEditFacet(cell);
        break;

      default:
        break;
    }
  };

  const handleCreateClick = () => {
    state.setIsDialogOpen(true);
    if (props.onCreate) props.onCreate();
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedList = arrayMove(state.selectedList, oldIndex, newIndex);
    const disabledList = updatedList.filter(uf => uf.disabled);
    const enabledList = updatedList.filter(uf => !uf.disabled);
    const sortedList = [...disabledList, ...enabledList];

    state.setSelectedList(sortedList);

    if (props.onDragEnd) {
      props.onDragEnd(sortedList);
    }
  };

  const handleFacetManagementDialogClose = () => {
    state.setIsDialogOpen(false);
    state.setSelectedFacet({});
    state.setFacetManagementEventName('create');
    state.setFacetDialogMode('create');
  };

  const handleFacetManagementDialogOk = (payload) => {
    props.newFacet.onOk(payload);
    handleFacetManagementDialogClose();
  };

  const mappedFacetList = () => state.list.map(facet => ({
    label: facet.label.en,
    value: facet.value,
    disabled: facet.disabled,
    originalItem: facet,
  }));

  const { footer, loading, disabled } = props;

  const facetList = !isEmpty(props.table.tBody)
    ? props.table.tBody.filter(tr => !tr.default)
    : [];

  const tBodyParams = {
    disabled: loading || disabled,
    onDropdownChange: handleDropdownChange,
    list: state.list,
  };
  const tBody = mappedTBody(state.selectedList, tBodyParams);

  const lockDrag = tBody && tBody.length === 1 ? 'no-drag' : '';

  return (
    <>
      <RctCollapsibleCard
        className="facet-group-wrapper"
        fullBlock
      >
        <div className="form-label">
          <IntlMessages id="form.facetsLabel" />
        </div>
        <SortableContainer className="relative" lockAxis="y" onSortEnd={onSortEnd} useDragHandle>
          {!isEmpty(tBody) && tBody.map(tr => (
            <SortableItem
              className={lockDrag}
              key={`item-${tBody.indexOf(tr)}`}
              index={tBody.indexOf(tr)}
              disabledGlobally={isEmpty(facetList) || loading}
              value={tr}
            />
          ))}
          {loading && (
            <CircularProgress
              variant="indeterminate"
              disableShrink
              className="progress-warning category-facets loader bottom"
              size={15}
              thickness={4}
            />
          )}
        </SortableContainer>
        <div className="facet-actions flex justify-between">
          <Button
            disabled={loading || isEmpty(mappedFacetList())}
            variant="contained"
            color="primary"
            onClick={handleCreateClick}
          >
            <IntlMessages id="facet.create.label" />
          </Button>
          {!window.location.pathname.includes('new') && (
            <Button
              disabled={props.onDeleteButton.disabled || isEmpty(getOriginalFacetList(tBody))}
              color="secondary"
              variant="outlined"
              onClick={handleDeleteFacet}
            >
              <IntlMessages id="facet.deleteAll.label" />
            </Button>
          )}
        </div>
        {footer}
      </RctCollapsibleCard>
      <FacetManagementDialog
        mode={state.facetDialogMode}
        entityId={props.entity.id}
        entityType={props.entity.type}
        eventName={state.facetManagementEventName}
        header={{
          title: state.facetDialogTitleCode,
          useIntl: true,
        }}
        open={state.isDialogOpen}
        selected={state.selectedFacet}
        onClose={handleFacetManagementDialogClose}
        onOk={handleFacetManagementDialogOk}
        facetList={tBody}
        basedOn={{
          attributeOptions: mappedFacetList(),
        }}
      />
    </>
  );
};

Facet.propTypes = {
  list: arrayOf(shape()),
  table: shape({
    tBody: arrayOf(shape()),
  }),
  footer: oneOfType([string, node]),
  onItemDelete: func,
  onDragEnd: func,
  loading: bool,
  disabled: bool,
  onCreate: func,
  entity: shape({
    id: string,
    type: string,
  }),
  onDeleteButton: shape({
    disabled: bool,
    onClick: func,
  }),
  newFacet: shape({
    onOk: func,
  }),
};

Facet.defaultProps = {
  list: [],
  table: {
    tBody: [],
  },
  footer: null,
  onItemDelete: null,
  onDragEnd: null,
  loading: false,
  disabled: false,
  onCreate: null,
  entity: {
    id: '',
    type: '',
  },
  onDeleteButton: {
    disable: false,
    onClick: null,
  },
  newFacet: {
    onOk: null,
  },
};

export default Facet;
