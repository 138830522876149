import { createSelector } from 'reselect';

const productRootSelector = state => state;

const stateSelector = createSelector(
  productRootSelector,
  product => ({
    errors: product.errors,
    fields: product.fields,
    productComments: product.productComments,
    childList: product.childList,
    editableProduct: product.edit,
    item: product.item,
    childProductsFetched: product.childProductsFetched,
    childProductsFetching: product.childProductsFetching,
    images: product.fields.images,
    updated: product.updated,
    defaultImage: product.defaultImage,
  }),
);

export const productSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  productSelector,
};
