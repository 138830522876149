/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useList = ({
  setSelectedList,
  setList,
  setSelected,
  tBody,
  list,
}) => {
  useEffect(() => {
    setSelectedList([
      ...tBody,
    ]);
  }, [tBody]);

  useEffect(() => {
    setList(list);
  }, [list]);

  useEffect(() => {
    setSelected({});
  }, [window.location.pathname]);
};

export default useList;
