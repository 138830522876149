export default [
  {
    label: 'Top',
    value: 'top',
  }, {
    label: 'Bottom',
    value: 'bottom',
  }, {
    label: 'Position',
    value: 'position',
  },
];
