/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, memo, Fragment } from 'react';
import {
  shape, node,
} from 'prop-types';
import Popover from '@material-ui/core/Popover';
import DialogContent from '@material-ui/core/DialogContent';

const FullCardComponent = memo((props) => {
  const { content, product } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [expandKey, setExpandKey] = useState('');

  const handleClose = () => {
    setAnchorEl(null);
    setExpandKey('');
  };

  const ShowMorePopover = () => (
    <Popover
      className="show-more-popover no-event"
      open={!!expandKey}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <DialogContent className="product-popover-content">
        {content}
      </DialogContent>
    </Popover>
  );

  const onMouseLeave = () => {
    setAnchorEl(null);
    setExpandKey('');
  };

  const onMouseEnter = (e, key) => {
    setAnchorEl(e.target);
    setExpandKey(key);
  };

  return (
    <Fragment>
      <i
        onMouseLeave={onMouseLeave}
        onMouseEnter={e => onMouseEnter(e, product.id)}
        className={`icon-more ${expandKey === product.id
          ? 'ti-more-alt'
          : 'ti-more'}`}
      />
      {expandKey === product.id && <ShowMorePopover />}
    </Fragment>
  );
});

FullCardComponent.propTypes = {
  content: node,
  product: shape(),
};

FullCardComponent.defaultProps = {
  content: null,
  product: {},
};

export default FullCardComponent;
