import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const pimRootSelector = state => state;
const pimListSelector = state => state.list;

const mapProductsImages = (images) => {
  if (!images) return [];
  return ([
    ...images.map((img) => {
      const params = '?auto=format%2Ccompress&bg=transparent&fill=solid&fit=fillmax&h=220&trim=auto&trimsd=1&w=auto';
      return {
        ...img,
        url: `${img.url}${params}`,
      };
    }),
  ]);
};

const listSelector = createSelector(
  pimListSelector,
  list => (!isEmpty(list) ? list.reduce((acc, current) => {
    acc.push({
      ...current,
      images: mapProductsImages(current.images),
      variants: !isEmpty(current.variants)
        ? current.variants.map(v => ({
          ...v,
          images: mapProductsImages(v.images),
          merchants: !isEmpty(v.merchants)
            ? v.merchants.map(m => ({
              ...m,
              images: mapProductsImages(m.images),
            }))
            : [],
        }))
        : [],
    });
    return acc;
  }, []) : []),
);

const stateSelector = createSelector(
  pimRootSelector,
  listSelector,
  (state, list) => ({
    pagination: state.pagination,
    savedFilterFilters: state.savedFilterFilters,
    globalFilters: state.globalFilters,
    filterTarget: state.filterTarget,
    savedFilterFields: state.savedFilterFields,
    globalFields: state.globalFields,
    sort: state.sort,
    searchQuery: state.searchQuery,
    fullTextListModeSearch: state.fullTextListModeSearch,
    fetching: state.fetching,
    displayMode: state.displayMode,
    isEditMode: state.isEditMode,
    selected: state.selected,
    total: state.total,
    pages: state.pages,
    paginationHelperTextId: state.paginationHelperTextId,
    containerStyles: state.containerStyles,
    productsImagesOverride: state.productsImagesOverride,
    helperText: state.helperText,
    isCustomFieldsMode: state.isCustomFieldsMode,
    searchValue: state.searchValue,
    list,
  }),
);

export const pimSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  pimSelector,
};
