import {
  rankingsAppPath, categoriesAppPath, searchAppPath, pimAppPath, mapperAppPath, repricingAppPath, rulesAppPath,
  camsAppPath, camsErrorsAppPath, camsMerchantStatusAppPath, repricerAppPath, repricerIssuesAppPath, activityLogAppPath,
  feedsAppPath, systemAppPath, influencersAppPath, previewAppPath, profileAppPath, businessPortalAppPath,
  // publicationsAppPath,
} from '../../util/paths';
import appPermissions from '../../util/appPermissions';

export const headerList = ({ abilityContext }) => {
  const getActiveLinkClassName = link => (document.location.pathname
    .includes(link) ? 'list-item-link-active' : '');

  const pathname = document.location.pathname;

  if (
      pathname === pimAppPath ||
      pathname === mapperAppPath ||
      pathname === activityLogAppPath ||
      pathname === rulesAppPath)
  {
    return ([
      {
        id: 0,
        icon: 'list',
        key: 'catalog',
        value: 'Catalog',
        valueId: 'subheader.pim',
        activeClassName: 'list-item-link-active',
        className: getActiveLinkClassName('pim'),
        route: pimAppPath,
        enabled: abilityContext.can(
          appPermissions.pimPortal.permissions.view,
          appPermissions.pimPortal.name,
        ),
      },
      {
        id: 1,
        icon: 'settings',
        key: 'mapper',
        value: 'Mapper',
        valueId: 'subheader.mapper',
        activeClassName: 'list-item-link-active',
        className: getActiveLinkClassName('mapper'),
        route: mapperAppPath,
        enabled: abilityContext.can(
          appPermissions.mapper.permissions.view,
          appPermissions.mapper.name,
        ),
      },
      {
        id: 2,
        icon: 'settings',
        key: 'activityLog',
        value: 'Activity Log',
        valueId: 'subheader.activityLog',
        activeClassName: 'list-item-link-active',
        className: getActiveLinkClassName('activityLog'),
        route: activityLogAppPath,
        enabled: abilityContext.can(
          appPermissions.mapper.permissions.view,
          appPermissions.mapper.name,
        ),
      },
      {
        id: 3,
        icon: 'settings',
        key: 'rules',
        value: 'Rules',
        valueId: 'subheader.rules',
        activeClassName: 'list-item-link-active',
        className: getActiveLinkClassName('rules'),
        route: rulesAppPath,
        enabled: abilityContext.can(
          appPermissions.rules.permissions.view,
          appPermissions.rules.name,
        ),
      },
    ].filter(item => item.enabled !== false));
  }

  if (
    pathname === repricingAppPath ||
    pathname === repricerAppPath ||
    pathname === repricerIssuesAppPath
  ) {
    return ([
      {
        id: 0,
        icon: 'list',
        key: 'repricer',
        value: 'Repricer',
        valueId: 'subheader.repricer',
        activeClassName: 'list-item-link-active',
        className: getActiveLinkClassName('repricer'),
        route: repricerAppPath,
        enabled: abilityContext.can(
          appPermissions.repricer.permissions.view,
          appPermissions.repricer.name,
        ),
      },
      {
        id: 1,
        icon: 'settings',
        key: 'repricing',
        value: 'Log',
        valueId: 'subheader.repricing',
        activeClassName: 'list-item-link-active',
        className: getActiveLinkClassName('repricing'),
        route: repricingAppPath,
        enabled: abilityContext.can(
          appPermissions.repricing.permissions.view,
          appPermissions.repricing.name,
        ),
      },
      {
        id: 1,
        icon: 'settings',
        key: 'issues',
        value: 'Issues',
        valueId: 'subheader.issues',
        activeClassName: 'list-item-link-active',
        className: getActiveLinkClassName('issues'),
        route: repricerIssuesAppPath,
        enabled: abilityContext.can(
          appPermissions.repricer.permissions.view,
          appPermissions.repricer.name,
        ),
      },
    ].filter(item => item.enabled !== false));
  }

  if (pathname === camsAppPath || pathname === camsErrorsAppPath || pathname === camsMerchantStatusAppPath) {
    return ([
      {
        id: 0,
        icon: 'list',
        key: 'camsErrors',
        value: 'CAMS Errors',
        valueId: 'subheader.camsErrors',
        activeClassName: 'list-item-link-active',
        className: getActiveLinkClassName('errors'),
        route: camsErrorsAppPath,
        enabled: abilityContext.can(
          appPermissions.cams.permissions.view,
          appPermissions.cams.name,
        ),
      },
      {
        id: 1,
        icon: 'list',
        key: 'cams',
        value: 'CAMS',
        valueId: 'subheader.cams',
        activeClassName: 'list-item-link-active',
        className: getActiveLinkClassName('cams'),
        route: camsAppPath,
        enabled: abilityContext.can(
          appPermissions.cams.permissions.view,
          appPermissions.cams.name,
        ),
      },
      {
        id: 2,
        icon: 'list',
        key: 'camsMerchantStatus',
        value: 'CAMS Merchant Status',
        valueId: 'subheader.camsMerchantStatus',
        activeClassName: 'list-item-link-active',
        className: getActiveLinkClassName('merchant-status'),
        route: camsMerchantStatusAppPath,
        enabled: abilityContext.can(
          appPermissions.cams.permissions.view,
          appPermissions.cams.name,
        ),
      },
    ].filter(item => item.enabled !== false));
  }

  return ([
    {
      id: 0,
      icon: 'settings',
      key: 'sliders-h',
      value: 'Rankings',
      valueId: 'subheader.rankings',
      activeClassName: 'list-item-link-active',
      className: getActiveLinkClassName('rankings'),
      route: rankingsAppPath,
      enabled: abilityContext.can(
        appPermissions.ranking.permissions.view,
        appPermissions.ranking.name,
      ),
    }, {
      id: 1,
      key: 'categories',
      icon: 'list',
      value: 'Categories',
      valueId: 'subheader.categories',
      activeClassName: 'list-item-link-active',
      className: getActiveLinkClassName('categories'),
      route: categoriesAppPath,
      enabled: abilityContext.can(
        appPermissions.category.permissions.view,
        appPermissions.category.name,
      ),
    }, {
      id: 2,
      key: 'search',
      icon: 'search',
      value: 'Campaigns',
      valueId: 'subheader.campaigns',
      activeClassName: 'list-item-link-active',
      className: getActiveLinkClassName('search'),
      route: searchAppPath,
      enabled: abilityContext.can(
        appPermissions.search.permissions.view,
        appPermissions.search.name,
      ),
    }, {
      id: 3,
      key: 'feeds',
      icon: 'list',
      value: 'Feeds Manager',
      valueId: 'subheader.feeds',
      activeClassName: 'list-item-link-active',
      className: getActiveLinkClassName('feeds'),
      route: feedsAppPath,
      enabled: abilityContext.can(
        appPermissions.feeds.permissions.view,
        appPermissions.feeds.name,
      ),
    },
    // {
    //   id: 3,
    //   key: 'publications',
    //   icon: 'search',
    //   value: 'Publications',
    //   valueId: 'subheader.publications',
    //   activeClassName: 'list-item-link-active',
    //   notifications: 3,
    //   route: publicationsAppPath,
    // },
    {
      id: 4,
      key: 'system',
      icon: 'seacogrch',
      value: 'System',
      valueId: 'subheader.system',
      activeClassName: 'list-item-link-active',
      className: getActiveLinkClassName('system'),
      route: systemAppPath,
      enabled: abilityContext.can(
        appPermissions.system.permissions.view,
        appPermissions.system.name,
      ),
    }, {
      id: 5,
      key: 'influencer',
      icon: 'seacogrch',
      value: 'Influencer',
      valueId: 'subheader.influencer',
      activeClassName: 'list-item-link-active',
      className: getActiveLinkClassName('influencer'),
      route: influencersAppPath,
      enabled: abilityContext.can(
        appPermissions.influencer.permissions.view,
        appPermissions.influencer.name,
      ),
    },
  ].filter(item => item.enabled !== false));
};

export const headerRightList = ({ userName, onLogoutClick }) => [
  {
    id: 0,
    key: 'pim',
    value: 'PIM',
    valueId: 'headerRightNav.pim',
    activeClassName: 'list-item-link-active',
    linkClassName: 'uppercase',
    route: pimAppPath,
  }, {
    id: 1,
    key: 'cams',
    value: 'CAMS',
    valueId: 'headerRightNav.cams',
    activeClassName: 'list-item-link-active',
    linkClassName: 'uppercase',
    route: camsAppPath,
  }, {
    id: 2,
    key: 'preview',
    value: 'Preview',
    valueId: 'headerRightNav.preview',
    activeClassName: 'list-item-link-active',
    route: previewAppPath,
  }, {
    id: 3,
    key: 'repricer',
    value: 'Repricer',
    valueId: 'headerRightNav.repricer',
    activeClassName: 'list-item-link-active',
    route: repricerAppPath,
  }, {
    id: 4,
    key: 'username',
    value: userName,
    className: 'no-event',
    valueId: '',
    activeClassName: 'list-item-link-active',
    route: profileAppPath,
    ignoreTranslation: true,
  }, {
    id: 5,
    key: 'logout',
    value: 'Log out',
    valueId: 'headerRightNav.logout',
    activeClassName: '',
    route: window.location.pathname,
    onClick: onLogoutClick,
  },
];

export const headerPreviewRightList = ({ userName, onLogoutClick, abilityContext }) => {
  const portalLink = ['/search', '/categories', '/rankings', '/system'];
  const businessPortalClassName = portalLink
    .find(pL => document.location.pathname
      .includes(pL)) ? 'list-item-link-active' : '';

  return ([
    {
      id: 0,
      key: 'pim',
      value: 'PIM',
      valueId: 'headerRightNav.pim',
      activeClassName: 'list-item-link-active',
      linkClassName: 'uppercase',
      route: pimAppPath,
      enabled: abilityContext.can(
        appPermissions.pimPortal.permissions.view,
        appPermissions.pimPortal.name,
      ),
    }, {
      id: 1,
      key: 'cams',
      value: 'CAMS',
      valueId: 'headerRightNav.cams',
      activeClassName: 'list-item-link-active',
      linkClassName: 'uppercase',
      route: camsAppPath,
      enabled: abilityContext.can(
        appPermissions.cams.permissions.view,
        appPermissions.cams.name,
      ),
    }, {
      id: 2,
      key: 'preview',
      value: 'Preview',
      valueId: 'headerRightNav.preview',
      activeClassName: 'list-item-link-active',
      route: previewAppPath,
      enabled: abilityContext.can(
        appPermissions.previewPortal.permissions.view,
        appPermissions.previewPortal.name,
      ),
    }, {
      id: 3,
      key: 'repricer',
      value: 'Repricer',
      valueId: 'headerRightNav.repricer',
      activeClassName: 'list-item-link-active',
      route: repricerAppPath,
      enabled: abilityContext.can(
        appPermissions.repricer.permissions.view,
        appPermissions.repricer.name,
      ),
    }, {
      id: 4,
      key: 'businessPortal',
      value: 'Business Portal',
      valueId: 'headerRightNav.businessPortal',
      className: businessPortalClassName,
      activeClassName: 'list-item-link-active',
      route: businessPortalAppPath,
      enabled: abilityContext.can(
        appPermissions.businessPortal.permissions.view,
        appPermissions.businessPortal.name,
      ),
    }, {
      id: 5,
      key: 'username',
      value: userName,
      className: 'no-event',
      valueId: '',
      activeClassName: 'list-item-link-active',
      route: profileAppPath,
      ignoreTranslation: true,
    }, {
      id: 6,
      key: 'logout',
      value: 'Log out',
      valueId: 'headerRightNav.logout',
      activeClassName: '',
      route: window.location.pathname,
      onClick: onLogoutClick,
    },
  ].filter(item => item.enabled !== false));
};

export const headerPimRightList = ({ userName, onLogoutClick }) => [
  {
    id: 0,
    key: 'preview',
    value: 'Preview',
    valueId: 'headerRightNav.preview',
    activeClassName: 'list-item-link-active',
    route: previewAppPath,
  }, {
    id: 1,
    key: 'repricer',
    value: 'Repricer',
    valueId: 'headerRightNav.repricer',
    activeClassName: 'list-item-link-active',
    route: repricerAppPath,
  }, {
    id: 2,
    key: 'businessPortal',
    value: 'Business Portal',
    valueId: 'headerRightNav.businessPortal',
    activeClassName: 'list-item-link-active',
    route: businessPortalAppPath,
  }, {
    id: 3,
    key: 'username',
    value: userName,
    className: 'no-event',
    valueId: '',
    activeClassName: 'list-item-link-active',
    route: profileAppPath,
    ignoreTranslation: true,
  }, {
    id: 4,
    key: 'logout',
    value: 'Log out',
    valueId: 'headerRightNav.logout',
    activeClassName: '',
    route: window.location.pathname,
    onClick: onLogoutClick,
  },
];

export default {
  headerList,
  headerRightList,
  headerPreviewRightList,
  headerPimRightList,
};
