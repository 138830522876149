import React from 'react';
import {
  string, shape, func, arrayOf, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { TextField } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import renderHelper from '../utils/renderHelper';

const FacetName = (props) => {
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (props.onChange) {
      props.onChange({
        ...props.label,
        [name]: value,
      });
    }
  };

  const hasError = !isEmpty(props.errors);

  return (
    <div className="facet-name-wrapper facet-group facet-group-inline">
      <div className="facet-group-inner">
        <TextField
          error={hasError}
          name="en"
          label={<IntlMessages id="facet.name.en.title" />}
          value={props.label.en}
          helperText={hasError ? renderHelper(props.errors) : ''}
          onChange={handleOnChange}
          disabled={props.primaryFieldSettings.disabled}
        />
      </div>
      <div className="facet-group-inner">
        <TextField
          name="he"
          label={<IntlMessages id="facet.name.he.title" />}
          value={props.label.he || ''}
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
};


FacetName.propTypes = {
  primaryFieldSettings: shape({
    disabled: bool,
  }),
  onChange: func,
  errors: arrayOf(shape()),
  label: shape({
    he: string,
    en: string,
  }),
};

FacetName.defaultProps = {
  primaryFieldSettings: {
    disabled: false,
  },
  errors: [],
  onChange: null,
  label: {
    en: '',
  },
};


export default FacetName;
