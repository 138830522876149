/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo } from 'react';
import { connect } from 'react-redux';
import {
  string, shape, func, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import arrayMove from 'array-move';
import swal from 'sweetalert';

import { previewSelector } from './selectors/previewSelector';
import { productSelector } from './selectors/productSelector';

import {
  setUpdatedProductsOverrides,
  setActionPopoverState,
  setActionPopoverAnchor,
  setSelectedProduct,
} from '../../../../actions/preview';
import {
  setProductForEdit,
  fetchPureProduct,
  fetchProduct,
  fetchActivityLogsByProductId,
} from '../../../../actions/product';
import { fetchAttributes } from '../../../../actions/system';

import {
  virtualCategoryWidgetSelector,
} from '../../selectors/virtualCategoryWidgetSelector';
import {
  promotionWidgetSelector,
} from '../../selectors/promotionWidgetSelector';

import ParentContainerGrid from '../ParentContainerGrid';
import ParentContainerList from '../ParentContainerList';

import ProductImageViewListDnD from '../../components/ProductImageViewListDnD';
import getGridColumns from '../../../../util/getGridColumns';

const ProductContainer = memo((props) => {
  const {
    className, previewProps, searchUpdating, virtualCategoryWidgetProps,
    wrapperClassName, virtualCategoryUpdating,
    promotionUpdating, productProps, promotionWidgetProps,
  } = props;

  const selectedEntityUpdating = virtualCategoryUpdating
    || searchUpdating
    || promotionUpdating
    || previewProps.updating
    || previewProps.updatingMultiple;

  const disabledDnD = !previewProps.isEditMode;
  const temporaryDisabled = true;

  const handleSettingsClick = ({ event, item }) => {
    props.setActionPopoverState(true);
    props.setActionPopoverAnchor(event.currentTarget);
    props.fetchPureProduct(item.id);
  };

  const getEntityToUse = () => {
    const { selectedSearchFilter } = previewProps;
    let entityToUse = {};

    if (!isEmpty(virtualCategoryWidgetProps.selectedCategory)) {
      entityToUse = virtualCategoryWidgetProps.selectedCategory;
    }
    if (!isEmpty(promotionWidgetProps.selectedPromotion)) {
      entityToUse = promotionWidgetProps.selectedPromotion;
    }
    if (!isEmpty(selectedSearchFilter)) entityToUse = selectedSearchFilter;
    return entityToUse;
  };

  const selectedEntity = getEntityToUse();

  const [localItems, setItems] = useState(previewProps.list);

  useEffect(() => {
    props.fetchAttributes();
  }, []);

  useEffect(
    () => setItems(previewProps.list),
    [previewProps.list],
  );

  const handleReplacePositions = (oldIndex, newIndex, productsOverride) => {
    const newPosition = newIndex + 1;
    const newItems = arrayMove(localItems, oldIndex, newIndex);
    const nearbyProducts = [];
    const targetProduct = localItems[oldIndex];
    const increasePosition = oldIndex < newIndex;
    const sortedOverrides = productsOverride
      .sort((a, b) => a.position - b.position);

    setItems(newItems);

    sortedOverrides.filter(o => o.productId !== targetProduct.id)
      .reduce((acc, current) => {
        let item = current;
        const positionShouldBeChanged = (current.position === newPosition)
          || (acc.position === current.position);

        if (positionShouldBeChanged) {
          item = {
            ...current,
            position: !increasePosition
              ? current.position + 1
              : current.position - 1,
          };
        }

        nearbyProducts.push(item);
        return item;
      }, []);

    const newOverrides = [
      ...nearbyProducts,
      {
        position: newIndex + 1,
        productId: targetProduct.id,
      },
    ];

    props.setUpdatedProductsOverrides(newOverrides);
  };

  const handleEditProduct = (forceSelected) => {
    const productToEdit = forceSelected && forceSelected.id
      ? forceSelected
      : previewProps.selectedProduct;

    props.setActionPopoverState(false);
    props.setActionPopoverAnchor(null);

    if (previewProps.displayMode === 'list') {
      props.fetchProduct(productToEdit.id);
    } else {
      props.setProductForEdit({
        edit: productProps.item,
        editInProgress: true,
      });
    }
    props.fetchActivityLogsByProductId(productToEdit.id);
  };

  const onEditClick = ({ productItem }) => {
    props.setSelectedProduct(productItem);
    handleEditProduct(productItem);
  };

  const handleShowAlert = (oldIndex, newIndex, productsOverride) => {
    swal({
      title: 'There is a product with same position',
      text: `Are you sure you want to replace a product with position ${newIndex + 1}?`,
      icon: 'warning',
      dangerMode: true,
      buttons: {
        shift: {
          text: 'Replace',
          closeModal: true,
          value: 'replace',
        },
        cancel: 'Cancel',
      },
    })
      .then((isOk) => {
        if (isOk) handleReplacePositions(oldIndex, newIndex, productsOverride);
      });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newPosition = newIndex + 1;
      const { virtualParams } = selectedEntity;
      const { productsOverride } = virtualParams || {};
      const localOverrides = previewProps.productsOverride;
      const overrides = !isEmpty(localOverrides)
        ? localOverrides
        : productsOverride;

      const productWithSamePosition = overrides
        .find(p => p.position === newPosition);

      if (productWithSamePosition) {
        handleShowAlert(oldIndex, newIndex, overrides);
      } else {
        handleReplacePositions(oldIndex, newIndex, overrides);
      }
    }
  };

  const columns = getGridColumns(previewProps.displayMode);

  const RenderProductDnD = () => {
    switch (previewProps.displayMode) {
      case 'list':
        return (
          <div
            id="scrollContainer"
            className="flex direction-column dnd-wrapper product list product-list"
            style={previewProps.containerStyles}
          >
            <ParentContainerList
              listViewContainerProps={{
                items: localItems,
                disabled: disabledDnD,
                onEditClick,
                selectedEntity,
                onSortEnd,
                pagination: previewProps.pagination,
              }}
            />
          </div>
        );
      case 'image_view':
        return (
          <div
            id="scrollContainer"
            className="flex direction-column dnd-wrapper product list product-list"
            style={previewProps.containerStyles}
          >
            <ProductImageViewListDnD
              grid={columns}
              disabled // disabledDnD TODO: enabled after FE & BE indexes issue fix
              items={localItems}
              onSortEnd={onSortEnd}
              pagination={previewProps.pagination}
            />
          </div>
        );
      default:
        return (
          <ParentContainerGrid
            gridContainerProps={{
              grid: columns,
              items: localItems,
              disabled: temporaryDisabled,
              onSettingsCLick: handleSettingsClick,
              selectedEntity,
              onSortEnd,
              pagination: previewProps.pagination,
              edidActionsAreDisabled: selectedEntityUpdating,
            }}
          />
        );
    }
  };

  return (
    <div className={`product-list-wrapper full-height ${className} ${wrapperClassName}`}>
      {(previewProps.fetching || previewProps.fetchingSelectedCategory) && (
        <div className="loader-cover">
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className="progress-warning mr-30 mb-10 loader bottom cover"
            size={30}
            thickness={4}
          />
        </div>
      )}
      {RenderProductDnD()}
    </div>
  );
});

ProductContainer.propTypes = {
  className: string,
  fetchAttributes: func.isRequired,
  wrapperClassName: string,
  previewProps: shape().isRequired,
  productProps: shape().isRequired,
  setUpdatedProductsOverrides: func.isRequired,
  fetchPureProduct: func.isRequired,
  setActionPopoverState: func.isRequired,
  setActionPopoverAnchor: func.isRequired,
  fetchProduct: func.isRequired,
  setProductForEdit: func.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  setSelectedProduct: func.isRequired,
  virtualCategoryUpdating: bool.isRequired,
  searchUpdating: bool.isRequired,
  promotionUpdating: bool.isRequired,

  virtualCategoryWidgetProps: shape().isRequired,
  promotionWidgetProps: shape().isRequired,
};

ProductContainer.defaultProps = {
  wrapperClassName: '',
  className: '',
};

const mapStateToProps = state => ({
  previewProps: previewSelector(state.preview),
  productProps: productSelector(state.product),
  categoryUpdated: state.virtualCategory.updated,
  virtualCategoryUpdating: state.virtualCategory.updating,
  searchUpdating: state.search.updating,
  promotionUpdating: state.promotion.updating,
  virtualCategoryWidgetProps: virtualCategoryWidgetSelector(state.virtualCategoryWidget),
  promotionWidgetProps: promotionWidgetSelector(state.promotionWidget),
});

const mapDispatchToProps = {
  fetchAttributes,
  setUpdatedProductsOverrides,
  fetchPureProduct,
  setActionPopoverState,
  setActionPopoverAnchor,
  fetchProduct,
  setProductForEdit,
  fetchActivityLogsByProductId,
  setSelectedProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
