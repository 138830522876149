import React from 'react';
import { connect } from 'react-redux';
import {
  string, shape, func, arrayOf, oneOfType, object,
} from 'prop-types';
import { isEmpty, uniq, uniqBy } from 'lodash';
import {
  sortableContainer, sortableElement, sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from "array-move";
import {
  searchAttributeOptions,
  clearFoundAttributeOptions,
  setSelectedAttributesOptions,
} from 'actions/virtualFacet';

import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import IntlMessages from 'util/IntlMessages';

import Name from './Name';
import GroupValue from './GroupValue';
import Controls from './Controls';


const getUpdatedList = (array, item, data, key) => array.map(l => ({
  ...l,
  [key]: l.id === item.id ? data : l[key],
}));

const getBasedOnOptions = (options = []) => {
  const autocompleteOptions = uniq([...options]).filter(e => e);
  return !isEmpty(autocompleteOptions)
    ? autocompleteOptions.map((o) => {
      const optionLabel = o.label && o.label.en ? o.label.en : o.label;
      return ({
        label: optionLabel,
        value: o.optionCode,
        disabled: false,
      });
    })
    : [];
};

const DragHandle = sortableHandle(() => <i className="ti-drag" />);

const SortableItem = sortableElement(({
  disabledGlobally,
  item,
  isFacetPromoted,
  type,
  attributeOptionList,
  error,
  onDeleteGroupClick,
  handleNameChange,
  handleIsPromotedChange,
  onAddValueClick,
  handleChangeValue,
  handleOnBlur,
  handleSearchOptions,
}) => (
  <li className={`drag-list-item drag-list-item-wrapper facet-sortable-item ${disabledGlobally ? 'disabled' : ''}`}>
    <DragHandle disabled={disabledGlobally} />
    <div key={item.id} className="facet-virtual-value-wrapper facet-group facet-group-sm facet-group-secondary facet-group-column">
      <div className="facet-group-actions">
        <Button
          size="small"
          className="btn-xs btn-danger btn-sec btn-uppercase"
          onClick={onDeleteGroupClick}
        >
          <IntlMessages id="facet.selectedValue.button.deleteGroup.title" />
        </Button>
      </div>

      <div className="facet-group facet-group-inner facet-group-sub-head">
        <Name
          label={item.label}
          onChange={data => handleNameChange(data, item)}
          errors={!isEmpty(error) && !isEmpty(error.label) ? error.label.messages : []}
        />
        {
            type === 'combined_values' && (
            <div className="facet-group-controls">
              <Controls
                      // disabled={!props.isPromoted}
                disabled={!isFacetPromoted}
                onIsPromotedChange={isPromoted => handleIsPromotedChange(item, isPromoted)}
                isPromoted={item.isPromoted}
              />
            </div>
            )
          }
      </div>

      <div className="facet-group facet-group-dnd-wrapper">
        <GroupValue
          errors={!isEmpty(error.values) ? error.values : []}
              // type={props.type}
          type={type}
          list={item.values}
          options={getBasedOnOptions(attributeOptionList)}
          onAdd={onAddValueClick}
          onChange={newList => handleChangeValue(newList, item)}
          useToggle={false}
          onBlur={handleOnBlur}
          onSearchOptions={handleSearchOptions}
          button={{
            useIntl: true,
            color: 'primary',
            variant: 'text',
            startIcon: <Add />,
            size: 'small',
            label: 'facet.attribute.addVirtualValue.title',
            helperId: 'facet.attribute.addVirtualValueHelper.title',
            disabled: isEmpty(type),
          }}
        />
      </div>
    </div>
  </li>
));

const SortableContainer = sortableContainer(({ children }) => (
  <ul className="drag-list-container drag-list-dragger drag-list-card">{children}</ul>
));

const GroupedValues = (props) => {
  let timeout = null;

  const handleDeleteGroup = (item) => {
    const newList = props.list.filter(l => l.id !== item.id);
    props.onChange(newList);
  };

  const handleNameChange = (data, item) => {
    const newList = getUpdatedList(props.list, item, data, 'label');
    props.onChange(newList);
  };

  const handleAddValue = (item) => {
    const newItem = {
      id: new Date().getUTCMilliseconds(),
      value: '',
    };

    const newList = props.list.map(l => ({
      ...l,
      values: l.id === item.id ? [...l.values, newItem] : l.values,
    }));
    props.onChange(newList);
  };

  const handleChangeValue = (updatedValueList, item) => {
    const selectedValues = updatedValueList.map(i => i.value).flat();
    const optionList = [
      ...props.foundOptions,
      ...props.initialOptions,
      ...props.selectedAttributesOptions,
    ].filter(e => e);

    const newList = getUpdatedList(props.list, item, updatedValueList, 'values');

    const selectedOptions = selectedValues
      .map(i => optionList.find(j => j.optionCode === i)).filter(e => e);

    props.setSelectedAttributesOptions(selectedOptions);

    props.onChange(newList);
  };

  const handleOnBlur = () => props.clearFoundAttributeOptions();

  const handleSearchOptions = (query) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      props.searchAttributeOptions(props.attributeSettings.codes, query);
    }, 100);
  };

  const handleIsPromotedChange = (item, isPromoted) => {
    const newList = getUpdatedList(props.list, item, isPromoted, 'isPromoted');
    props.onChange(newList);
  };

  const attributeOptionList = uniqBy([
    ...props.selectedAttributesOptions,
    ...props.foundOptions,
    ...props.initialOptions,
  ], 'optionCode');

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedList = arrayMove(props.list, oldIndex, newIndex);

    props.onChange(updatedList);
  };

  return (
    <SortableContainer className="relative" lockAxis="y" onSortEnd={onSortEnd} useDragHandle>
      {
        !isEmpty(props.list) && props.list.map((item, idx) => {
          const error = !isEmpty(props.errors) ? props.errors.find(err => err.id === item.id) : {};
          const onDeleteGroupClick = () => handleDeleteGroup(item);
          const onAddValueClick = () => handleAddValue(item);

          return (
            <SortableItem
              key={`item-${idx}`}
              item={item}
              index={idx}
              disabledGlobally={false}
              isFacetPromoted={props.isPromoted}
              type={props.type}
              attributeOptionList={attributeOptionList}
              error={error}
              onDeleteGroupClick={onDeleteGroupClick}
              handleNameChange={handleNameChange}
              handleIsPromotedChange={handleIsPromotedChange}
              onAddValueClick={onAddValueClick}
              handleChangeValue={handleChangeValue}
              handleOnBlur={handleOnBlur}
              handleSearchOptions={handleSearchOptions}
            />
          );
        })
      }
    </SortableContainer>
  );
};

GroupedValues.propTypes = {
  type: string,
  list: arrayOf(shape({
    label: shape({
      en: string,
      he: string,
    }),
    onChange: func,
  })),
  attributeSettings: shape({
    query: string,
    errors: arrayOf(shape()),
    value: arrayOf(oneOfType([string, object])),
    codes: arrayOf(oneOfType([string, object])),
    options: arrayOf(shape({
      value: string,
      label: oneOfType([string, object]),
    })),
  }),

  initialOptions: arrayOf(shape()).isRequired,
  foundOptions: arrayOf(shape()).isRequired,
  errors: arrayOf(shape()),
  selectedAttributesOptions: arrayOf(shape()).isRequired,

  clearFoundAttributeOptions: func.isRequired,
  searchAttributeOptions: func.isRequired,
  setSelectedAttributesOptions: func.isRequired,
  setIsPromoted: func,
};

GroupedValues.defaultProps = {
  attributeSettings: {},
  errors: [],
  type: '',
  list: [{
    label: {},
  }],
};

const mapStateToProps = state => ({
  initialOptions: state.virtualFacet.initialOptions,
  foundOptions: state.virtualFacet.foundOptions,
  selectedAttributesOptions: state.virtualFacet.selectedAttributesOptions,
});

const actionCreators = {
  searchAttributeOptions,
  clearFoundAttributeOptions,
  setSelectedAttributesOptions,
};

export default connect(mapStateToProps, actionCreators)(GroupedValues);
