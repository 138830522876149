import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import {
  func, shape, string, bool, number,
} from 'prop-types';
import { isEmpty } from 'lodash';

import Transfer from '../../Transfer';
import IntlMessages from '../../../util/IntlMessages';
import { scopeValidations } from '../utils/validations';
import checkValidations from '../../../util/validator';
import InputErrors from '../../InputErrors';

export default class SelectCategories extends Component {
  static propTypes = {
    transferSourceData: shape({}).isRequired,
    onSearchSubmit: func,
    query: string,
    errorMessage: string,
    noResult: bool,
    queryLengthLimit: number,
  };;

  static defaultProps = {
    onSearchSubmit: null,
    query: '',
    errorMessage: '',
    noResult: false,
    queryLengthLimit: null,
  };;

  constructor(props) {
    super(props);
    this.state = {
      query: props.query,
      errors: {},
    };
  }

  handleOnCategorySearch = (e) => {
    const { value } = e.target;

    this.setState({
      query: value,
    });
  }

  handleOnCategoryKeyDown = (e) => {
    const { query } = this.state;
    const { queryLengthLimit } = this.props;
    const { value } = e.target;
    const { charCode } = e;

    this.handleOnCategorySearch(e);

    if (charCode === 13) {
      if (this.props.onSearchSubmit) {
        const errors = checkValidations(scopeValidations, { searchCategories: value });

        this.setState({ errors });
        if (isEmpty(errors) && query.length < queryLengthLimit) this.props.onSearchSubmit(value);
      }
    }
  }

  handleOnSearchIconClick = () => {
    const { query } = this.state;
    const { queryLengthLimit } = this.props;
    const errors = checkValidations(scopeValidations, { searchCategories: query });

    this.setState({ errors });

    if (this.props.onSearchSubmit && isEmpty(errors) && query.length < queryLengthLimit) {
      this.props.onSearchSubmit(query);
    }
  }

  render() {
    const {
      transferSourceData, noResult, queryLengthLimit, errorMessage,
    } = this.props;
    const { query, errors } = this.state;
    return (
      <Fragment>
        <Paper className="flex items-center pos-rel" elevation={1}>
          {noResult && (
            <span className="input-placeholder">
              <IntlMessages id="placeholder.noResult" />
            </span>
          )}
          {errorMessage && (!isEmpty(query) && query.length > queryLengthLimit) && (
            <span className="input-placeholder">
              <IntlMessages id={errorMessage} />
            </span>
          )}
          <input
            className="form-control form-control-clear block pl-15"
            placeholder="Search Categories"
            onChange={this.handleOnCategorySearch}
            onKeyPress={this.handleOnCategoryKeyDown}
            name="searchCategories"
            value={query}
          />
          <IconButton aria-label="Search" onClick={this.handleOnSearchIconClick}>
            <SearchIcon />
          </IconButton>
        </Paper>
        {
          errors.searchCategories && !Array.isArray(errors)
          && (
            <InputErrors errors={errors.searchCategories} />
          )
        }
        <Transfer {...transferSourceData} />
      </Fragment>
    );
  }
}
