import React, { useState } from 'react';
import {
  string, arrayOf, shape, func, bool,
  oneOfType, array, number, node,
} from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Field from '../../../../components/Field';
import groupConditions from '../../../../util/constants/groupConditions';
import FilterGroup from '../FilterGroup';
import SidebarFilterScopeDialog from '../SidebarFilterScopeDialog';
import MapperCategoriesDialog from "../MapperCategoriesDialog";

const FilterItem = (props) => {
  const [showScopeDialog, setShowScopeDialog] = useState(false);
  const [showMapperCategoriesDialog, setShowMapperCategoriesDialog] = useState(false);

  const {
    filter, onChange, onDeleteFilter, savedFiltersLength,
    fetchAttributeOptions, fetchingAttributeOptions, onSubmitScopeDialog,
    fetchVirtualRootCategories, selectClassName, filterGroupIndex, className, filterTarget,
  } = props;

  const handleChange = (e) => {
    onChange({
      ...filter,
      [e.target.name]: e.target.value,
    }, filter.key);
  };

  const handleGroupChange = (group) => {
    const updatedGroup = {
      ...filter,
      group,
    };
    onChange(updatedGroup, filter.key);
  };

  const handleOpenScopeDialog = () => {
    if (filterTarget !== 'repricer') {
      fetchVirtualRootCategories();
    }
    setShowScopeDialog(true);
  };

  const handleOpenMapperCategoriesDialog = () => {
    setShowMapperCategoriesDialog(true);
  };

  const handleCloseMapperCategoriesDialog = () => {
    setShowMapperCategoriesDialog(false);
  };

  const handleCloseScopeDialog = () => {
    setShowScopeDialog(false);
  };

  const isVisible = filter.group.length <= 1;

  if (isVisible) {
    filter.groupCondition = 'and';
  }

  const errors = (filterTarget !== 'mapper' && filterTarget !== 'repricer')
    ? filter.errors && filter.errors.groupCondition
    : !props.selectedCategories && filter.errors && filter.errors.groupCondition;

  return (
    <Card className={`card-xs ${className}`}>
      <CardContent>
        <div className="flex items-center">
          <Field
            disabled={isVisible}
            element="select"
            onChange={handleChange}
            value={filter.groupCondition}
            options={groupConditions}
            name="groupCondition"
            label="Group Condition"
            className={`select-sm select-btn select-btn-default flex-1 ${errors ? 'has-error' : ''}`}
          />
          <IconButton
            aria-label="Delete"
            className="btn-mui-sm text-danger card-close"
            onClick={() => onDeleteFilter(filter)}
          >
            <i className="ti-close" />
          </IconButton>
        </div>
        <FilterGroup
          selectedTree={filter.selectedTreeCategories || []}
          group={filter.group}
          savedFiltersLength={savedFiltersLength}
          filterGroupIndex={filterGroupIndex}
          onChange={handleGroupChange}
          onSelectCategoriesClick={handleOpenScopeDialog}
          onSelectMapperCategoriesClick={handleOpenMapperCategoriesDialog}
          attributes={filter.attributes}
          categoriesAttributesFetching={props.categoriesAttributesFetching}
          fetchAttributeOptions={fetchAttributeOptions}
          fetchingAttributeOptions={fetchingAttributeOptions}
          selectClassName={selectClassName}
          filterTarget={filterTarget}
          selectedMapperCategories={props.selectedCategories}
        />
        <SidebarFilterScopeDialog
          key={`filter-scope-dialog-${filter.key}`}
          onCloseScopeDialog={handleCloseScopeDialog}
          onSubmitScopeDialog={onSubmitScopeDialog}
          showScopeDialog={showScopeDialog}
          filterId={filter.key}
          selectedTreeCategories={filter.selectedTreeCategories}
        />
      </CardContent>
      <MapperCategoriesDialog
        open={showMapperCategoriesDialog}
        onClose={handleCloseMapperCategoriesDialog}
        onOk={handleCloseMapperCategoriesDialog}
        mapperCategories={props.mapperCategories}
        mapperMarketplace={props.mapperMarketplace}
        selectedCategories={props.selectedCategories}
        setSelectedCategories={props.setSelectedCategories}
        openedCategories={props.openedCategories}
        setOpenedCategories={props.setOpenedCategories}
      />
      {props.helperText}
    </Card>
  );
};

FilterItem.propTypes = {
  helperText: oneOfType([node, string]),
  className: string,
  filter: shape({
    group: arrayOf(shape({
      field: string,
      fieldOption: oneOfType([string, array]),
      operator: string,
      errors: shape({
        message: string,
      }),
    })),
    groupCondition: string,
    errors: shape({
      message: string,
    }),
    attributes: arrayOf(shape({
      elementType: string,
      label: string,
      value: string,
      options: arrayOf(shape({
        value: string,
        label: string,
      })),
    })).isRequired,
  }).isRequired,
  onChange: func.isRequired,
  onDeleteFilter: func.isRequired,
  categoriesAttributesFetching: bool.isRequired,
  fetchAttributeOptions: func.isRequired,
  fetchingAttributeOptions: bool,
  onSubmitScopeDialog: func.isRequired,
  fetchVirtualRootCategories: func.isRequired,
  selectClassName: string.isRequired,
  filterGroupIndex: number,
  savedFiltersLength: number.isRequired,
};

FilterItem.defaultProps = {
  helperText: null,
  className: '',
  fetchingAttributeOptions: false,
  filterGroupIndex: null,
};

export default FilterItem;
