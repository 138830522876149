import { isEmpty } from 'lodash';

export const categoryNameValidations = [
  {
    type: 'isRequired',
    message: 'search.errors.cantBeBlank',
  }, {
    type: 'minSize',
    message: 'text.errors.moreThanThreeCharacters',
    condition: 3,
  }, {
    type: 'maxSize',
    message: 'text.errors.lessThan200Characters',
    condition: 200,
  },
];

export const searchPhraseFilterValidations = {
  name: categoryNameValidations,
  searchPhrase: [
    {
      type: 'isRequired',
      message: 'search.errors.cantBeBlank',
    }, {
      type: 'xss',
      message: 'search.errors.xss',
    },
  ],
};

export const searchPromotionFilterValidations = {
  name: categoryNameValidations,
  urlKey: [{
    type: 'maxSize',
    message: 'text.errors.lessThan500Characters',
    condition: 500,
  }, {
    type: 'minSize',
    message: 'search.errors.cantBeBlank',
    condition: 2,
  }],
};


export const validateFilters = (array) => {
  const operatorsWithoutValue = new Set(['exists', 'not_exists']);
  const errors = array.map(item => ({
    ...item,
    group: item.group.map((g) => {
      const list = Object.keys(g).map((key) => {
        if (isEmpty(g[key]) && !operatorsWithoutValue.has(g.operator)) return ({ [key]: 'helper.fieldCantNotBeBlank' });
        return null;
      });
      return Object.assign({}, ...list);
    }),
  }));
  return errors;
};

export const validateSortings = (array) => {
  const errors = array.map((p) => {
    if (!p.field) return ({ field: 'helper.fieldCantNotBeBlank' });
    return ({ field: '' });
  }).filter(el => el);
  return errors;
};


export default {
  searchPhraseFilterValidations,
  searchPromotionFilterValidations,
  validateFilters,
  validateSortings,
};
