/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import {
  bool, func, shape,
} from 'prop-types';
import FormDialog from '../../../../components/FormDialog';
import IntlMessages from '../../../../util/IntlMessages';
import { Button } from "@material-ui/core";

const VideosEnrichmentDialog = memo((props) => {
  const {
    isOpened,
    onClose,
    enrichmentVideos,
    onAddVideosClick,
    onSelectVideoClick,
    onShowPreview,
    selectedVideos,
    sendEnrichmentRetriggerVideos,
  } = props;

  const addVideos = () => {
    onAddVideosClick();
  };

  const closeVideosEnrichmentDialog = () => {
    onClose();
  };

  const handleRequestVideos = () => {
    sendEnrichmentRetriggerVideos();
  };

  const EnrichmentVideoItem = (props) => {
    const checked = (selectedVideos && selectedVideos.indexOf(props.item.url) !== -1);

    return (
      <div className="enrichment-video-preview">
        <div className="enrichment-video-preview-checkbox-over">
          <input
            className="enrichment-video-preview-checkbox"
            type="checkbox"
            onClick={e => onSelectVideoClick(e, props.item.url)}
            defaultChecked={checked}
          />
        </div>

        <img onClick={e => onShowPreview(e, props.item)}
             alt={props.item.title.en}
             className="enrichment-video-preview-image"
             src={props.item.previewUrl}
        />

        <div className="enrichment-video-preview-title">
          <a href={props.item.url} rel="noopener noreferrer" target="_blank">
            {props.item.title.en}
          </a>
        </div>
      </div>
    );
  };

  return (
    <FormDialog
      title={<IntlMessages id="pim.videosEnrichmentDialog.title" />}
      open={isOpened}
      hideCloseButton={false}
      className="dialog-inputs-custom dialog-content-visible"
      closeButtonTitle="Close"
      submitButtonTitle="Submit"
      maxWidth="lg"
      onClose={closeVideosEnrichmentDialog}
      onSubmit={addVideos}
    >
      {enrichmentVideos.length > 0 ? (
        enrichmentVideos.map((item, key) => (
          <EnrichmentVideoItem
            key={`enrichment-video-item-${key}`}
            item={item}
          />
        ))
      ) : (
        <>
          <div><IntlMessages id="pim.videosEnrichmentDialog.notFound" /></div>
          <br />
          <Button
            variant="contained"
            onClick={() => handleRequestVideos()}
            color="primary"
            className="text-white"
          >
            <IntlMessages id="buttons.requestVideos" />
          </Button>
        </>
      )}
    </FormDialog>
  );
});

VideosEnrichmentDialog.propTypes = {
  sendEnrichmentRetriggerVideos: func.isRequired,
  onClose: func.isRequired,
  isOpened: bool.isRequired,
  onAddVideosClick: func.isRequired,
  onSelectVideoClick: func.isRequired,
  onShowPreview: func.isRequired,
  enrichmentVideos: shape(),
  selectedVideos: shape(),
};

VideosEnrichmentDialog.defaultProps = {
  enrichmentVideos: [],
  selectedVideos: [],
};

export default VideosEnrichmentDialog;
