/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDropzone } from 'react-dropzone';
import IconButton from '@material-ui/core/IconButton';
import {
  shape, array, func, bool,
} from 'prop-types';

import IntlMessages from '../../../../util/IntlMessages';

const DropZoneComponent = (props) => {
  const { state, disabled } = props;
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: '.xls,.xlsx',
  });

  useEffect(() => state.setFiles([...state.files, ...acceptedFiles]),
    [acceptedFiles]);

  const onDeleteClick = (file) => {
    const newFiles = [...state.files];

    newFiles.splice(newFiles.indexOf(file), 1);
    state.setFiles(newFiles);
  };

  const filesNodes = state.files.map(file => (
    <li className="flex justify-between dropzone-selected-item" key={file.path}>
      <div className="flex direction-column ">
        <span>{`Name: ${file.path}`}</span>
        <span>{`Size: ${file.size}`}</span>
      </div>
      <IconButton
        size="small"
        color="primary"
        aria-label="Delete"
        onClick={() => onDeleteClick(file)}
      >
        <i className="ti-close red" />
      </IconButton>
    </li>
  ));
  const emptyMsg = <IntlMessages id="importPage.emptyZone" />;
  const data = !isEmpty(filesNodes) ? filesNodes : emptyMsg;
  const disabledClass = disabled || !isEmpty(state.files) ? 'disabled' : '';

  return (
    <section>
      <div {...getRootProps({ className: `dropzone ${disabledClass}` })}>
        <input {...getInputProps({
          multiple: false,
          disabled: disabled || !isEmpty(state.files),
        })}
        />
        <IntlMessages id="importPage.placeholder" />
      </div>
      <aside className="dropzone-content">
        <h4>Files</h4>
        <ul>{data}</ul>
      </aside>
    </section>
  );
};

DropZoneComponent.propTypes = {
  state: shape({
    files: array,
    setFiles: func,
  }).isRequired,
  disabled: bool.isRequired,
};

export default DropZoneComponent;
