/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  shape, func, node, bool, string,
} from 'prop-types';
import { uniq, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Drawer } from '@material-ui/core';

import {
  fetchProductList,
  setIsHiddenProductsMode,
  setPagination,
  setProductsListEmpty,
  setSearchQuery,
  clearProductState,
  setHiddenProductsExist,
  setHiddenProductsIds,
  setHiddenProductsFilter,
  setSearchValue,
  setFacetsPreFilters,
  setSelectedSearchFilter,
} from 'actions/preview';

import { setSelectedPromotion } from 'actions/promotionWidget';
import { setSelectedCategory } from 'actions/virtualCategoryWidget';

import { clearCategoriesAttributesState } from 'actions/productAttribute';
import { clearFromCategoryFacetState } from 'actions/categoryFacet';

import Button from 'components/Button';
import SearchForm from 'components/Header/SearchForm';
import Can from 'components/Can';
import IntlMessages from 'util/IntlMessages';
import appPermissions from 'util/appPermissions';

import BadgeButton from './BadgeButton';

import { defaultPagination, defaultFilter } from '../../utils/defaultData';

const SidebarWrapper = (props) => {
  const resetFilter = ({
    searchBody, selectedSearchFilter,
  }) => {
    props.setSearchQuery(searchBody);
    props.setFacetsPreFilters([]);
    props.setPagination(defaultPagination);
    props.setSelectedSearchFilter(selectedSearchFilter);
  };

  useEffect(() => {
    if (!isEmpty(props.selectedCategory)) {
      const searchBody = {
        ...props.preview.searchQuery,
        categoryId: props.selectedCategory.id,
      };

      if (searchBody.urlKey) delete searchBody.urlKey;

      if (searchBody.searchPhrase) {
        delete searchBody.searchPhrase;
        props.setSearchValue('');
      }

      props.setSelectedPromotion({});
      resetFilter({
        searchBody,
        selectedSearchFilter: {},
      });
    }
  }, [
    props.selectedCategory,
  ]);

  useEffect(() => {
    if (!isEmpty(props.selectedPromotion)) {
      const searchBody = {
        ...props.preview.searchQuery,
        urlKey: props.selectedPromotion.urlKey,
      };

      if (searchBody.categoryId) delete searchBody.categoryId;

      if (searchBody.searchPhrase) {
        delete searchBody.searchPhrase;
        props.setSearchValue('');
      }

      props.setSelectedCategory({});
      resetFilter({
        searchBody,
        selectedPromotion: props.selectedPromotion,
        selectedSearchFilter: {},
      });

      props.fetchProductList({
        viewMode: 'frontend',
        filter: [],
        pagination: defaultPagination,
        searchFilter: { urlKey: props.selectedPromotion.urlKey },
        ignoreFacets: false,
      });
    }
  }, [
    props.selectedPromotion,
  ]);

  const getVirtualFacets = () => {
    const virtualFacets = {
      filters: props.preview.virtualFacetFilter,
    };
    return virtualFacets;
  };

  const handleClearVisibilityState = () => {
    props.setIsHiddenProductsMode(false);
    props.setHiddenProductsFilter([]);
    props.setHiddenProductsIds([]);
    props.setHiddenProductsExist(false);
  };

  const handleClearHiddenProducts = () => {
    const virtualFacets = getVirtualFacets();
    props.setHiddenProductsExist(false);
    props.setHiddenProductsIds([]);
    props.setIsHiddenProductsMode(false);

    props.fetchProductList({
      viewMode: 'frontendWithParams',
      filter: [],
      compoundSearch: props.preview.compoundSearch,
      pagination: props.preview.pagination,
      searchFilter: props.preview.searchQuery,
      virtualFacets,
    });
  };

  const handleShowHiddenProducts = () => {
    props.setPagination(defaultPagination);
    props.setSearchValue('');
    const filter = [{
      condition: 'and',
      group: [{
        field: 'id',
        value: uniq(props.preview.hiddenProductsIds),
        operator: 'in',
      }],
    }];

    props.setHiddenProductsFilter(filter);
    props.setIsHiddenProductsMode(true);
    props.fetchProductList({
      viewMode: 'frontendWithParams',
      filter,
      pagination: defaultPagination,
      searchFilter: {},
    });
  };

  const handleUnselectCategory = () => {
    const searchBody = {
      ...props.preview.searchQuery,
    };

    props.setProductsListEmpty();
    handleClearVisibilityState();

    if (searchBody.categoryId) delete searchBody.categoryId;

    props.setSearchQuery(searchBody);
    props.setPagination(defaultPagination);

    props.setSelectedCategory({});
    props.clearCategoriesAttributesState();
    props.clearProductState();
    props.clearFromCategoryFacetState({
      list: [],
    });

    resetFilter(defaultFilter);
  };

  const handleUnselectPromotion = () => {
    const searchBody = {
      ...props.preview.searchQuery,
    };

    props.setProductsListEmpty();
    handleClearVisibilityState();

    if (searchBody.urlKey) delete searchBody.urlKey;

    props.setSearchQuery(searchBody);
    props.setPagination(defaultPagination);
    props.setSelectedPromotion({});
    props.clearProductState();
    props.clearFromCategoryFacetState({
      list: [],
    });

    resetFilter(defaultFilter);
  };

  const previewItemSelected = !isEmpty(props.selectedCategory) || !isEmpty(props.selectedPromotion);
  const previewHiddenProductsExist = props.preview.hiddenProductsExist;
  let sidebarSecondaryClass = '';

  if (previewItemSelected) sidebarSecondaryClass = 'sidebar-inner-wrapper-sm';
  if (previewHiddenProductsExist) sidebarSecondaryClass = 'sidebar-inner-wrapper-md';

  return (
    <Can
      do={appPermissions.preview.permissions.view}
      on={appPermissions.preview.name}
    >
      <Drawer
        className={`sidebar-wrapper scroll-light ${sidebarSecondaryClass}`}
        variant="permanent"
        anchor="left"
      >
        <div className="sidebar-sticky-block">
          {props.search.onChange && (
            <SearchForm
              onChange={props.search.onChange}
              className="block"
              error={props.search.errors}
            />
          )}
          {previewHiddenProductsExist && (
            <Button
              matSettings={{
                disableElevation: true,
                size: 'small',
              }}
              settings={{
                value: props.preview.isHiddenProductsMode
                  ? <IntlMessages id="preview.button.clearHiddenProducts" />
                  : <IntlMessages id="preview.button.showHiddenProducts" />,
                type: props.preview.isHiddenProductsMode
                  ? 'secondary'
                  : 'primary',
                onClick: props.preview.isHiddenProductsMode
                  ? handleClearHiddenProducts
                  : handleShowHiddenProducts,
                wrapperClassName: 'pb-10 pt-10 full-width',
              }}
            />
          )}
          {!isEmpty(props.selectedCategory) && (
            <BadgeButton
              messageId="preview.sidebar.badge.category"
              label={props.selectedCategory.name.en}
              onClick={handleUnselectCategory}
            />
          )}
          {!isEmpty(props.selectedPromotion) && (
            <BadgeButton
              messageId="preview.sidebar.badge.promotion"
              label={props.selectedPromotion.name}
              onClick={handleUnselectPromotion}
            />
          )}
        </div>
        {props.children}
      </Drawer>
    </Can>
  );
};

SidebarWrapper.propTypes = {
  children: node,
  search: shape({
    onChange: func,
    errors: shape(),
  }),
  preview: shape({
    isHiddenProductsMode: bool,
  }).isRequired,

  selectedPromotion: shape().isRequired,
  selectedCategory: shape({
    id: string,
  }).isRequired,

  fetchProductList: func.isRequired,
  setIsHiddenProductsMode: func.isRequired,
  setPagination: func.isRequired,
  setProductsListEmpty: func.isRequired,
  setSearchQuery: func.isRequired,
  clearProductState: func.isRequired,
  setSelectedCategory: func.isRequired,

  clearCategoriesAttributesState: func.isRequired,
  clearFromCategoryFacetState: func.isRequired,
  setHiddenProductsExist: func.isRequired,
  setHiddenProductsIds: func.isRequired,
  setHiddenProductsFilter: func.isRequired,
  setSearchValue: func.isRequired,
  setSelectedPromotion: func.isRequired,
  setFacetsPreFilters: func.isRequired,
  setSelectedSearchFilter: func.isRequired,
};

SidebarWrapper.defaultProps = {
  children: null,
  search: {},
};

const mapStateToProps = state => ({
  preview: state.preview,

  selectedPromotion: state.promotionWidget.selectedPromotion,
  selectedCategory: state.virtualCategoryWidget.selectedCategory,
});

const mapDispatchToProps = {
  fetchProductList,
  setIsHiddenProductsMode,
  setSearchQuery,
  setPagination,
  clearProductState,
  setProductsListEmpty,
  setSelectedCategory,

  clearCategoriesAttributesState,
  clearFromCategoryFacetState,
  setHiddenProductsExist,
  setHiddenProductsIds,
  setHiddenProductsFilter,
  setSearchValue,
  setSelectedPromotion,

  setFacetsPreFilters,
  setSelectedSearchFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarWrapper);
