import { defaultFields } from '../../../utils/defaultData';

export const mapPrefix = (field) => {
  if (field.startsWith('ao_')) {
    return `specifications.${field}`;
  }
  return field;
};

export const mapPrefixToFilters = filters => (filters.map(f => ({
  ...f,
  group: f.group.map(g => ({
    ...g,
    field: mapPrefix(g.field),
  })),
})));

export const mergeFilters = (filters, stateRootFilter = []) => {
  if (JSON.stringify(filters) === JSON.stringify(stateRootFilter)) return filters;
  return [...filters, ...stateRootFilter];
};

export const mergeFields = (fields, stateRootFields) => ([
  ...new Set([...fields, ...stateRootFields, ...defaultFields]),
]);

export default {
  mapPrefixToFilters,
  mergeFilters,
  mergeFields,
};
