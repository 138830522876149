import React from 'react';
import { isEmpty, uniqBy } from 'lodash';
import { Input } from 'reactstrap';
import { IconButton, Tooltip } from '@material-ui/core';

const renderAttributeLabel = label => (
  label.he
    ? (
      <Tooltip title={`${label.he}`} placement="top">
        <div className="tooltip-subtitle">
          {label.en}
        </div>
      </Tooltip>
    )
    : label.en
);

export const attributesThead = ({ onSearchChange }) => ({
  cells: [
    {
      dataField: 'action-drag',
      className: 'drag w-px-50',
    }, {
      dataField: 'name',
      className: 'name pl-0',
      content: (
        <Input
          className="form-control"
          onChange={onSearchChange}
        />
      ),
    }, {
      dataField: 'action-remove',
      className: 'remove w-px-50',
    },
  ],
});

export const mappedTBody = (array, {
  onDelete, onDropdownChange, disabled, list,
}) => {
  const tabeRow = array.map((item) => {
    const itemLabel = item.label || item.labels;

    const itemAttributeLabel = !isEmpty(list) && !item.default
      ? item.attributes.map(ia => list.filter(lf => lf.code === ia)).flat().map(i => i.label)
      : [];

    return ({
      ...item,
      onDropdownChange,
      cells: !item.cells ? [
        {
          dataField: 'name',
          className: 'name',
          label: itemLabel && itemLabel.en ? itemLabel.en : itemLabel,
          content: (
            <div className="list-item-title-wrapper">
              {
                !isEmpty(itemAttributeLabel) && (
                  <div className="list-item-subtitle-wrapper">
                    <div className="list-item-subtitle-list-label">
                      Based on:
                    </div>
                    <div className="list-item-subtitle-list">
                      {
                        uniqBy(itemAttributeLabel, 'en').map(i => (
                          <div key={`${i.en}_${new Date().getUTCMilliseconds()}`} className="list-item-subtitle">
                            {renderAttributeLabel(i)}
                          </div>
                        ))
                      }
                    </div>
                  </div>
                )
              }
              <span className="list-item-title fw-normal">{itemLabel && itemLabel.en ? itemLabel.en : itemLabel}</span>
            </div>
          ),
        }, {
          dataField: 'action-remove',
          className: 'remove text-center text-danger',
          label: itemLabel,
          content: (
            !item.default && onDelete ? (
              <IconButton
                className="btn-mui-sm text-danger"
                onClick={() => onDelete(item)}
                disabled={disabled}
              >
                <i className="ti-close" />
              </IconButton>
            ) : ''
          ),
        },
      ] : item.cells,
    });
  });

  return tabeRow;
};

export default {
  attributesThead,
  mappedTBody,
};
