/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  memo, useContext, useState, useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  func, bool, shape, arrayOf, string,
} from 'prop-types';
import { connect } from 'react-redux';
import {
  isEqual, isEmpty, uniq, cloneDeep,
} from 'lodash';
import swal from '@sweetalert/with-react';
import { NotificationManager } from 'react-notifications';
import { CircularProgress } from '@material-ui/core';

import {
  createNavigationVirtualFacet,
  updateNavigationVirtualFacet,
  deleteNavigationVirtualFacet,
  setNavigatioVirtualFacetIdToDelete,
} from 'actions/navigationVirtualFacet';

import {
  setSelectedProductList,
  setIsNavigationSaving,
  setErrors,
  setSortingsErrors,
  setFiltersErrors,
  searchNavigationsByPhrases,
  setExistedSearchPhrase,
  fetchNavigationListByName,
  updateSearchFilter,
  fetchNavigationList,
  deleteNavigation,
} from 'actions/navigation';

import { fetchProductList, setSelectedSearchFilter } from 'actions/preview';
import { setSelectedPromotion } from 'actions/promotionWidget';

import { AbilityContext } from 'components/AbilityContext';
import ButtonGroup from 'components/ButtonGroup';

import { getCamelizeKey } from 'util/virtualFacetManagment';
import { rankingRule } from 'util/constants/constants';
import IntlMessages from 'util/IntlMessages';
import { mapFiltersByOperator } from 'util/mapFiltersByOperator';
import checkValidations from 'util/validator';
import { defaultPagination } from 'util/defaultData';
import appPermissions from 'util/appPermissions';
import buttonList from 'util/buttonList';
import { mapFilters } from 'util/attributesMatrixMapping';

import { navigationFacetSelector } from './selectors/navigationFacet';
import { searchFilterPath } from '../../../utils/paths';
import {
  searchPhraseFilterValidations, validateSortings,
  validateFilters, searchPromotionFilterValidations,
} from '../../../utils/validations';

import { promotionFilter, promotionPagination } from './utils/defaultPromotionData';

const Actions = memo((props) => {
  const [saveAndClose, setSaveAndClose] = useState(false);
  const abilityContext = useContext(AbilityContext);

  const {
    navigationItem,
    originalNavigationItem,
    navigationSort,
    navigationUpdating,
    navigationFilter,
    navigationVirtualFacet,
    isNavigationSaving,
    navigationUpdated,
    attributesMatrixList,
  } = props;

  const handleNotificationSuccess = msg => NotificationManager.success(msg);
  const handleNotificationWarning = msg => NotificationManager.warning(msg);
  const handleNotificationError = msg => NotificationManager.error(msg);

  const handleSetSelectedNavigation = (isPromotion) => {
    const body = {
      ...navigationItem,
      virtualParams: {
        ...navigationItem.virtualParams,
        filters: mapFilters(
          navigationItem.virtualParams.filters,
          attributesMatrixList,
        ),
      },
    };

    if (isPromotion) props.setSelectedPromotion(body);
    else props.setSelectedSearchFilter(body);
  };

  useEffect(() => {
    if (
      (
        navigationUpdated
        || (props.navigationFacet.created || props.navigationFacet.updated)
      )
      && (
        !props.navigationFacet.creating
        || !props.navigationFacet.updating
        || navigationUpdating
      )
    ) {
      const isPromotion = navigationItem.type === 'promotion_url';
      if (typeof saveAndClose !== 'undefined') {
        handleNotificationSuccess(<IntlMessages id="navigation.navigationUpdated.success" />);
      }

      props.setIsNavigationSaving(false);

      handleSetSelectedNavigation(isPromotion);

      if (isPromotion) props.fetchNavigationList(promotionFilter, promotionPagination);
      if (saveAndClose) props.history.push(searchFilterPath);
      setSaveAndClose(false);
    }
  }, [
    navigationUpdated,
    props.navigationFacet.created,
    props.navigationFacet.updated,
  ]);

  useEffect(() => {
    if (
      props.navigationDeleted
      && (isEmpty(props.navigationErrors) || isEmpty(props.navigationErrors.facets))
    ) {
      props.history.push(searchFilterPath);
    }
  }, [props.navigationDeleted]);

  const checkChanges = () => {
    const sortRules = navigationSort.sortRules.map(sr => ({
      field: sr.field,
      direction: sr.defaultValue.toLowerCase(),
    })).filter(el => el.field !== rankingRule);
    const isNavigationChanged = (
      !isEqual(
        navigationItem,
        originalNavigationItem,
      )
      || !isEqual(
        navigationFilter.filters,
        originalNavigationItem.virtualParams.filters,
      )
      || !isEqual(
        sortRules,
        originalNavigationItem.virtualParams.sortRules,
      )
      || !isEqual(
        navigationFilter.category.mappedCategories,
        originalNavigationItem.virtualParams.mappedCategories,
      )
      || !isEqual(
        navigationSort.rankingRuleCode,
        originalNavigationItem.virtualParams.rankingRuleCode,
      )
      || !isEqual(
        navigationFilter.category.selectedCategories,
        originalNavigationItem.virtualParams.selectedCategories,
      )
    );
    const isFacetsChanged = !isEqual(
      props.navigationFacet.list,
      props.navigationFacet.origList,
    );

    return { isNavigationChanged, isFacetsChanged };
  };

  const handleOnClose = () => {
    const { isNavigationChanged, isFacetsChanged } = checkChanges();

    if (isNavigationChanged || isFacetsChanged) {
      swal({
        title: 'Are you sure?',
        text: 'If you leave this page then any unsaved changes will be lost.',
        icon: 'warning',
        dangerMode: true,
        buttons: true,
      }).then((willDiscard) => {
        if (willDiscard) {
          props.setSelectedProductList({ in: [], nin: [] });
          props.history.push(searchFilterPath);
        }
      });
    } else {
      props.setSelectedProductList({ in: [], nin: [] });
      props.history.push(searchFilterPath);
    }
  };

  const checkSortings = () => {
    let sortingsErrors = [];
    if (!isEmpty(navigationSort.sortRules)) {
      sortingsErrors = validateSortings(navigationSort.sortRules);
    }

    if (navigationSort.sortRules.some(p => p.field === rankingRule)
      && !navigationSort.rankingRuleCode) {
      sortingsErrors = [{ rankingRuleCode: 'helper.fieldCantNotBeBlank' }];
    }

    if (
      (
        !isEmpty(sortingsErrors) && sortingsErrors
          .some(s => s.field === '') && sortingsErrors
          .map(s => s.field === '')
          .filter(el => el).length === sortingsErrors.length
      )
      || (navigationSort.sortRules.some(p => p.field === rankingRule)
        && navigationSort.rankingRuleCode)
    ) {
      sortingsErrors = [];
    }
    return sortingsErrors;
  };

  const getSearchPhrase = searchPhrase => (searchPhrase && Array.isArray(searchPhrase)
    ? searchPhrase
    : searchPhrase.split(',')
      .filter(el => el)
      .map(el => el.trim()));

  const checkSearchFilterValidations = () => {
    let validationRule = { ...searchPhraseFilterValidations };
    if (navigationItem.type === 'promotion_url') {
      validationRule = { ...searchPromotionFilterValidations };
      if (!navigationItem.urlKey || navigationItem.urlKey.length === 1) {
        validationRule.urlKey = validationRule.urlKey.filter(r => r.type === 'minSize');
      }
    }

    if (!navigationItem.name) {
      validationRule.name = validationRule.name.filter(r => r.type === 'isRequired');
    }

    if (navigationItem.name && navigationItem.name.length < 3) {
      validationRule.name = validationRule.name.filter(r => r.type === 'minSize');
    }

    const errors = checkValidations(validationRule, navigationItem);
    const sortingsErrors = checkSortings();

    let filtersErrors = validateFilters(navigationFilter.filters);

    props.setErrors(errors);
    props.setSortingsErrors(sortingsErrors);
    props.setFiltersErrors(filtersErrors);

    const isFiltersErrorsGroupEmpty = filtersErrors.map(f => f.group.map(g => isEmpty(g))).flat();

    if (!isFiltersErrorsGroupEmpty.some(f => !f)) {
      filtersErrors = [];
      props.setFiltersErrors([]);
    }

    if (!isEmpty(errors) || !isEmpty(sortingsErrors) || !isEmpty(filtersErrors)) {
      return false;
    }

    return true;
  };

  const handleFacetDelete = () => {
    if (props.navigationFacet.virtualFacetIdToDelete) {
      props.deleteNavigationVirtualFacet(props.navigationFacet.virtualFacetIdToDelete);
    }
  };

  const virtualFacetManagement = (navigationFacet) => {
    if (
      isEmpty(props.navigationFacet.list)
      || (!navigationFacet.id && !props.navigationFacet.virtualFacetIdToDelete)
    ) {
      if (isEmpty(navigationFacet.groupedValues)) {
        // eslint-disable-next-line no-param-reassign
        delete navigationFacet.groupedValues;
      }
      props.createNavigationVirtualFacet(navigationFacet);
    } else {
      const payload = {
        ...navigationFacet,
        facetsList: {
          ...navigationFacet.facetsList,
        },
      };

      delete payload.id;
      delete payload.updatedAt;
      delete payload.createdAt;

      if (isEmpty(payload.groupedValues)) {
        delete payload.groupedValues;
      }

      if (!isEmpty(props.navigationFacet.list)) {
        const navigationFacetId = navigationFacet.id
          || props.navigationFacet.virtualFacetIdToDelete;

        props.updateNavigationVirtualFacet(navigationFacetId, payload);
      }
    }
  };

  const handleDelete = () => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to permanently delete this Navigation?',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    })
      .then((willDiscard) => {
        if (willDiscard) {
          props.deleteNavigation(navigationItem.id);
          if (props.navigationFacet.item.id) {
            props.deleteNavigationVirtualFacet(props.navigationFacet.item.id);
          }
        }
      });
  };

  const handleOnSaveClick = async ({ closeAfterUpdate }) => {
    props.setIsNavigationSaving(true);
    const searchPhrases = navigationItem.searchPhrase
      ? getSearchPhrase(navigationItem.searchPhrase) : [];

    let filteredSearchPhrases = [];

    if (!isEmpty(navigationItem.searchPhrase)) {
      filteredSearchPhrases = uniq(searchPhrases.filter(
        vsp => !navigationItem.searchPhrase.find(isp => isp === vsp),
      ));
    } else {
      filteredSearchPhrases = uniq(searchPhrases);
    }

    const res = await props.searchNavigationsByPhrases(filteredSearchPhrases);

    const existSearchPhrases = uniq(
      res.map(r => searchPhrases.find(
        sp => r.searchPhrase.find(rsp => rsp === sp),
      )).filter(e => e),
    );
    if (!isEmpty(existSearchPhrases)) {
      const str = existSearchPhrases.join(', ');
      const message = existSearchPhrases.length > 1
        ? `'${str}' phrases are already exist`
        : `'${str}' phrase is already exist`;
      props.setExistedSearchPhrase(message);
      props.setIsNavigationSaving(false);
    }

    if (!isNavigationSaving && isEmpty(existSearchPhrases)) {
      props.setExistedSearchPhrase('');
      props.setIsNavigationSaving(true);

      const { isNavigationChanged, isFacetsChanged } = checkChanges();

      if (!isNavigationChanged && !isFacetsChanged) {
        props.setIsNavigationSaving(false);
        return handleNotificationWarning(
          <IntlMessages id="navigation.create.alert.warning" />,
        );
      }

      setSaveAndClose(closeAfterUpdate);
      const navigationData = { ...navigationItem };
      const searchDataParams = {};

      if (navigationData.type === 'search_phrase') {
        navigationData.searchPhrase = navigationItem.searchPhrase
          ? getSearchPhrase(navigationItem.searchPhrase) : null;

        delete navigationData.urlKey;
      }

      if (navigationData.type === 'promotion_url') {
        delete navigationData.searchPhrase;
      }

      searchDataParams.mappedCategories = uniq(
        navigationFilter.category.mappedCategories,
      );
      searchDataParams.selectedCategories = uniq(
        navigationFilter.category.selectedCategories,
      );
      searchDataParams.rankingRuleCode = navigationSort.rankingRuleCode || null;
      searchDataParams.facets = navigationVirtualFacet.facets;
      searchDataParams.filters = mapFiltersByOperator(navigationFilter.filters);

      if (navigationData.virtualParams && navigationData.virtualParams.positions) {
        searchDataParams.positions = navigationData.virtualParams.positions;
      }

      searchDataParams.sortRules = !isEmpty(navigationSort.sortRules)
        ? navigationSort.sortRules.map(s => ({
          field: s.field,
          direction: s.defaultValue.toLowerCase(),
        })).filter(s => s.field !== rankingRule) : [];
      navigationData.virtualParams = {
        ...navigationData.virtualParams,
        ...searchDataParams,
      };

      const isValid = checkSearchFilterValidations();

      if (
        !isEmpty(props.navigationFacet.list)
        && isFacetsChanged
        && props.navigationItemFetched
      ) {
        const categoryFacet = cloneDeep(props.navigationFacet.item);

        const reducedFacet = categoryFacet.facetsList
          .reduce((acc, cur) => ({ ...acc, [getCamelizeKey(cur.label.en)]: cur }), {});

        categoryFacet.facetsList = reducedFacet;
        virtualFacetManagement(categoryFacet);
      }

      if (
        !isEmpty(props.navigationFacet.virtualFacetIdToDelete)
        && isEmpty(props.navigationFacet.list)
      ) {
        handleFacetDelete();
      } else props.setNavigatioVirtualFacetIdToDelete('');


      if (isValid && isNavigationChanged) {
        props.setErrors({});
        const getCheckSearchNameFilter = name => ({
          filter: [{
            group: [{
              field: 'name.keyword',
              value: name,
              operator: 'eq',
            }],
          }],
        });

        props.fetchNavigationListByName(getCheckSearchNameFilter(navigationItem.name),
          defaultPagination, (itemAlreadyExist) => {
            const updateParams = { mapToMatrix: true };

            if (navigationData.name !== originalNavigationItem.name && itemAlreadyExist) {
              props.setIsNavigationSaving(false);
              return handleNotificationError(<IntlMessages id="navigation.create.alert.error" />);
            }
            return props.updateSearchFilter(navigationData.id, navigationData, updateParams);
          });
      } else {
        props.setIsNavigationSaving(false);
      }
    }
    return true;
  };

  const loading = isNavigationSaving
    || navigationUpdating
    || props.navigationFacet.fetching;

  const getListParams = () => {
    let listParams = {
      onCloseClick: handleOnClose,
      disabled: loading,
    };

    const checkPermissions = (permission) => {
      if (abilityContext.can(
        permission,
        appPermissions.navigation.name,
      )) {
        return true;
      }
      return false;
    };

    if (checkPermissions(appPermissions.navigation.permissions.update)) {
      listParams = {
        ...listParams,
        buttons: {
          success: {
            className: 'button-primary',
          },
        },
        onSaveAndCloseClick: () => handleOnSaveClick({ closeAfterUpdate: true }),
        onSaveClick: handleOnSaveClick,
        onDeleteClick: handleDelete,
      };
    }

    return buttonList(listParams);
  };

  const buttonOrder = ['close', 'saveAndClose', 'save'];
  const orderedButtons = getListParams()
    .sort((a, b) => buttonOrder.indexOf(a.key) - buttonOrder.indexOf(b.key));

  return (
    <div className="relative">
      {loading && (
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className="progress-warning custom-loader bottom"
          size={20}
          thickness={4}
        />
      )}
      <ButtonGroup
        materialBtn
        className={`btn-group-${props.type} flex items-center mb-${props.gap}`}
        list={orderedButtons}
      />
    </div>
  );
});

Actions.propTypes = {
  type: string,
  gap: string,

  history: shape().isRequired,

  navigationItem: shape().isRequired,
  navigationSort: shape().isRequired,
  originalNavigationItem: shape().isRequired,
  navigationFilter: shape().isRequired,
  navigationVirtualFacet: shape().isRequired,
  attributesMatrixList: arrayOf(shape()).isRequired,

  createNavigationVirtualFacet: func.isRequired,
  updateNavigationVirtualFacet: func.isRequired,
  deleteNavigationVirtualFacet: func.isRequired,

  navigationUpdating: bool.isRequired,
  isNavigationSaving: bool.isRequired,
  navigationUpdated: bool.isRequired,
  navigationDeleted: bool.isRequired,
  navigationItemFetched: bool.isRequired,

  setSelectedProductList: func.isRequired,
  setIsNavigationSaving: func.isRequired,
  setErrors: func.isRequired,
  setSortingsErrors: func.isRequired,
  setFiltersErrors: func.isRequired,
  searchNavigationsByPhrases: func.isRequired,
  setExistedSearchPhrase: func.isRequired,
  fetchNavigationListByName: func.isRequired,
  updateSearchFilter: func.isRequired,
  fetchNavigationList: func.isRequired,
  setSelectedPromotion: func.isRequired,
  setSelectedSearchFilter: func.isRequired,
  deleteNavigation: func.isRequired,

  navigationErrors: shape().isRequired,
  navigationFacet: shape().isRequired,
  setNavigatioVirtualFacetIdToDelete: func.isRequired,
};

Actions.defaultProps = {
  type: 'white',
  gap: '20',
};

const mapStateToProps = state => ({
  navigationItem: state.navigation.item,
  originalNavigationItem: state.navigation.originalItem,
  navigationUpdating: state.navigation.updating,
  navigationUpdated: state.navigation.updated,
  navigationDeleted: state.navigation.deleted,
  navigationItemFetched: state.navigation.fetchedOne,
  navigationSort: state.navigation.sort,
  navigationFilter: state.navigation.filter,
  navigationVirtualFacet: state.navigation.facet,
  navigationErrors: state.navigation.facet,
  isNavigationSaving: state.navigation.isNavigationSaving,

  previewSearchQuery: state.preview.searchQuery,
  previewCompoundSearch: state.preview.compoundSearch,
  previewSelectedEntityName: state.preview.selectedEntityName,

  navigationFacet: navigationFacetSelector(state.navigationVirtualFacet),

  attributesMatrixList: state.attributesMatrix.list,
});

const mapDispatchToProps = {
  setSelectedProductList,
  setIsNavigationSaving,
  setErrors,
  setSortingsErrors,
  setFiltersErrors,
  searchNavigationsByPhrases,
  setExistedSearchPhrase,
  fetchNavigationListByName,
  updateSearchFilter,
  fetchNavigationList,
  fetchProductList,
  setSelectedPromotion,
  setSelectedSearchFilter,
  createNavigationVirtualFacet,
  updateNavigationVirtualFacet,
  deleteNavigationVirtualFacet,
  setNavigatioVirtualFacetIdToDelete,
  deleteNavigation,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Actions));
