/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import IntlMessages from '../../../../util/IntlMessages';
import { systemPath } from '../../utils/paths';
import roles from '../../../../util/roles';

export const useUser = (state, props) => {
  useEffect(() => () => {
    props.clearUserState();
  }, []);

  useEffect(() => {
    props.fetchUsers();
  }, []);

  useEffect(() => {
    if (
      state.newUserForm.roles
      && !state.newUserForm.roles.includes(roles.influencer.name)
      && state.companyName
    ) {
      state.setCompanyName('');
    }
  }, [state.newUserForm.roles]);

  useEffect(() => {
    const { list, fetched } = props.user;
    if (fetched) {
      state.setUsers(list);
    }
  }, [props.user.fetched]);

  useEffect(() => {
    if (props.user.deleted) {
      props.clearFromUserState({
        deleted: false,
        list: props.user.list
          .filter(u => u.id !== state.managedUser.id),
      });
      state.setUsers(
        state.users.filter(u => u.id !== state.managedUser.id),
      );
      state.setManagedUser({});
    }
  }, [props.user.deleted]);

  useEffect(() => {
    const { created, createdUser, hasError } = props.user;
    const shouldInitiateAffiliateFlow = createdUser
      && createdUser.roles.includes(roles.influencer.name);

    if (created) {
      if (!shouldInitiateAffiliateFlow) {
        state.setShowAddUserDialog(false);
        props.fetchUsers();
        state.setShowLoader(false);
        state.setNewUserErrors({});
        state.setNewUserForm({});
      }

      if (hasError) {
        NotificationManager.error(<IntlMessages id="text.somethingWentWrong" />);
      } else {
        NotificationManager.success(<IntlMessages id="text.userCreatedSuccessfully" />);
      }
    }
  }, [props.user.created]);

  useEffect(() => {
    const { hasError } = props.user;

    if (props.user.updatedMultiple) {
      if (hasError) {
        NotificationManager.error(<IntlMessages id="text.somethingWentWrong" />);
      } else {
        const currentUserId = props.session.item.id;

        if (state.usersChanged[currentUserId]) {
          props.fetchSession(currentUserId);
        }

        props.fetchUsers();
        state.setUsers([]);
        state.setUsersChanged({});
        NotificationManager.success(<IntlMessages id="text.userUpdatedSuccessfully" />);
        if (state.closeAfterSave) props.history.push(systemPath);
        state.setCloseAfterSave(false);
      }
    }
  }, [props.user.updatedMultiple]);

  useEffect(() => {
    if (props.user.usersSynced) {
      props.fetchUsers();
    }
  }, [props.user.usersSynced]);
};

export default useUser;
