import React, { useState } from 'react';
import {
  bool, func,
} from 'prop-types';
import { isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Field from '../../../../components/Field';
import validateAll from '../../../../util/validator';
import IntlMessages from '../../../../util/IntlMessages';
import { filterNameValidations } from '../../utils/validations';

const NewFilterDialog = (props) => {
  const [filter, setFilter] = useState({});
  const [errors, setErrors] = useState({});
  const {
    open, onClose, onOk,
  } = props;

  const handleOnchnge = (e) => {
    const { value, name } = e.target;
    setFilter({ [name]: value });
  };

  const handleOk = () => {
    const res = validateAll(filterNameValidations, filter);

    if (isEmpty(res)) {
      onOk(filter);
      setFilter({});
    } else setErrors(res);
  };

  const handleClose = () => {
    onClose();
    setFilter({});
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"><IntlMessages id="text.newFilterDialog" /></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <IntlMessages id="text.newFilterMessage" />
          </DialogContentText>
          <Field
            element="text"
            autoFocus
            value={filter.filter}
            margin="dense"
            name="filter"
            label="Filter name"
            type="text"
            onChange={handleOnchnge}
            fullWidth
            className={`${errors.filter ? 'has-error' : ''}`}
            errors={errors.filter}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <IntlMessages id="button.cancel" />
          </Button>
          <Button onClick={handleOk} color="primary">
            <IntlMessages id="button.save" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

NewFilterDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onOk: func.isRequired,
};

export default NewFilterDialog;
