/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { decamelize } from 'humps';
import { uniq, isEmpty } from 'lodash';

import { actions as actionBarActions } from '../../../utils/actionBarConsts';
import { NotificationManager } from "react-notifications";

const pagination = { limit: 500 };

const useProductAttributes = ({
  pimProps, productProps, actions, pimModeAttributes,
}) => {
  useEffect(() => {
    if (pimProps.selectedAction === actionBarActions.edit) {
      if (!isEmpty(pimProps.selected)) {
        const attributeSets = pimProps.selected.map(p => p.attributeSet);
        const setsList = uniq([...attributeSets]).filter(s => s);
        const fields = [
          'code',
          'frontend_input_type',
          'label',
          'is_filterable',
          'is_editable',
          'is_variation'
        ];

        if (!isEmpty(setsList)) {
          actions.fetchProductAttributeSets({ ids: setsList, pagination, fields });
        }
      }
    }
  }, [pimProps.selectedAction, pimProps.selected]);

  useEffect(() => {
    if (!isEmpty(productProps.edit) && productProps.nextProductLoading === false) {
      const { attributeSet } = productProps.edit;
      const fields = [
        'code',
        'frontend_input_type',
        'label',
        'options',
        'is_filterable',
        'is_editable',
        'is_variation'
      ];

      if (!isEmpty(attributeSet)) {
        actions.fetchProductAttributeSets({ ids: [attributeSet], pagination, fields });
      }
    }
  }, [productProps.edit.id]);

  useEffect(() => {
    if (!isEmpty(pimModeAttributes)) {
      const firebaseAttributes = pimModeAttributes;
      const productAttributesFields = firebaseAttributes.filter(attr => attr.isProductAttribute);
      const fields = [
        'code',
        'frontend_input_type',
        'label',
        'id',
      ];

      const condition = productAttributesFields.length > 1 ? { condition: 'or' } : {};

      const filter = [
        {
          ...condition,
          group: productAttributesFields.map(attr => ({
            field: 'code',
            value: attr.value,
            operator: 'like',
          })),
        },
      ];
      if (!isEmpty(productAttributesFields)) {
        actions.fetchCertainProductAttributes(filter, fields, pagination);
      }
    }
  }, [pimModeAttributes]);

  useEffect(() => {
    if (pimProps.fetchParentLevelChildrenProductsFinished) {
      const { list } = pimProps;

      const selfVariationAttributes = list[0].variationAttributes;

      const parentVariationAttributes = list
        .map(el => (el.variants || [])
          .map(v => v.variationAttributes)).flat();

      const variationAttributes = parentVariationAttributes[0] ? parentVariationAttributes : [selfVariationAttributes];

      for (let i = 0; i < variationAttributes.length; i++) {
        if (variationAttributes[i] === undefined) {
          variationAttributes[i] = selfVariationAttributes;
        }
      }

      if (isEmpty(variationAttributes) || variationAttributes[0] === undefined) {
        NotificationManager.warning('Product has no variations');

        return false;
      }

      const codeNames = [...new Set(variationAttributes
        .map(attr => Object.keys(attr)
          .map(k => k)).flat())];

      const fields = [
        'code',
        'frontend_input_type',
        'label',
        'id',
      ];

      const condition = codeNames.length > 1 ? { condition: 'or' } : {};

      const filter = [
        {
          ...condition,
          group: codeNames.map(name => ({
            field: 'code',
            value: decamelize(name),
            operator: 'like',
          })),
        },
      ];
      if (!isEmpty(codeNames)) {
        actions.fetchCertainProductAttributes(filter, fields, pagination);
      }
    }
  }, [pimProps.fetchParentLevelChildrenProductsFinished]);
};

export default useProductAttributes;
