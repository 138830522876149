import React from 'react';
import {
  string, node, oneOfType, number, bool,
} from 'prop-types';
import { Tooltip } from '@material-ui/core';

const BadgeLabel = (props) => {
  const {
    limit, useTooltip, value, label,
  } = props;

  const getValue = () => (
    limit && limit < value.length
      ? `${value.substr(0, limit)}...`
      : value
  );

  const getContent = () => (
    useTooltip && limit < value.length
      ? (
        <Tooltip title={value}>
          <div className="tooltip-container">
            {getValue()}
          </div>
        </Tooltip>
      )
      : getValue()
  );

  return (
    <div className="badge-label primary">
      <div className="badge-title">
        {label}
      </div>
      <div className="badge-body">
        {getContent()}
      </div>
    </div>
  );
};

BadgeLabel.propTypes = {
  label: oneOfType([node, string]),
  value: string,
  limit: number,
  useTooltip: bool,
};

BadgeLabel.defaultProps = {
  label: null,
  value: null,
  limit: null,
  useTooltip: false,
};


export default BadgeLabel;
