import React, { useState, useEffect, memo } from 'react';
import {
  string, shape, arrayOf, bool, func,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { Grid } from '@material-ui/core';
import arrayMove from 'array-move';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import VariantLevelImageViewItem from '../VariantLevelImageViewItem';
import { generateParentProductData } from '../../pages/Home/utils/productDataGenerator';

const SortableItem = SortableElement(props => <VariantLevelImageViewItem {...props} />);

const SortableList = SortableContainer((props) => {
  const {
    variants, disabled, defaultImage, certainProductAttributes,
    productsCommentsCount, sortedAttributes, productsImagesOverride,
    variantsCollapsed, setVariantsCollapsed,
    searchFields, handleExpandVariantLevel, isEditMode,
    fetchVariantLevelChildrenProductsStarted, lastParentChildrenLoaded,
    disableEdit, setUpdatedProductsImagesOverrides,
    parent, filterTarget, injectProducts, onMouseEnter, onMouseLeave,
  } = props;

  return (
    <div className="product-list">
      <Grid container spacing={0} className="justify-center">
        {variants && variants.map((item, index) => {
          const parentValue = generateParentProductData(parent, defaultImage, sortedAttributes);
          const title = !isEmpty(parentValue.title) ? parentValue.title : parent.title.en;
          const aoBrandName = parentValue.aoBrandName || item.vendorName;

          const value = {
            id: item.id,
            title,
            aoBrandName,
          };

          return (
            <SortableItem
              key={item.id}
              index={index}
              variantValue={value}
              itemsCount={variants.length}
              disabled={disabled}
              variantProductItem={item}
              certainProductAttributes={certainProductAttributes}
              hasComments={productsCommentsCount.some(pcc => pcc.id === item.id)}
              fetchActivityLogsByProductId={props.fetchActivityLogsByProductId}
              productCommentsLoading={props.productCommentsLoading}
              productComments={props.productComments}
              handleExpandVariantLevel={handleExpandVariantLevel}
              variantsCollapsed={variantsCollapsed}
              setVariantsCollapsed={setVariantsCollapsed}
              searchFields={searchFields}
              fetchVariantLevelChildrenProductsStarted={fetchVariantLevelChildrenProductsStarted}
              lastParentChildrenLoaded={lastParentChildrenLoaded}
              isEditMode={isEditMode}
              disableEdit={disableEdit}
              sortedAttributes={sortedAttributes}
              setUpdatedProductsImagesOverrides={setUpdatedProductsImagesOverrides}
              productsImagesOverride={productsImagesOverride}
              filterTarget={filterTarget}
              injectProducts={injectProducts}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          );
        })}
      </Grid>
    </div>
  );
});

const VariantLevelList = memo((props) => {
  const {
    className, variants, certainProductAttributes, productsCommentsCount,
    variantsCollapsed, setVariantsCollapsed, defaultImage, searchFields,
    handleExpandVariantLevel, fetchVariantLevelChildrenProductsStarted,
    lastParentChildrenLoaded, isEditMode, disableEdit, sortedAttributes,
    setUpdatedProductsImagesOverrides, productsImagesOverride, parent, filterTarget,
    injectProducts, disabled, onMouseEnter, onMouseLeave,
  } = props;
  const [localItems, setItems] = useState(variants);

  useEffect(
    () => setItems(variants),
    [variants],
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = arrayMove(localItems, oldIndex, newIndex);
    setItems(newItems);
  };

  return (
    <div className={`dnd-wrapper level-variant product list ignore-parent ${className}`}>
      <SortableList
        axis="y"
        useDragHandle
        productList={localItems}
        onSortEnd={onSortEnd}
        certainProductAttributes={certainProductAttributes}
        productsCommentsCount={productsCommentsCount}
        fetchActivityLogsByProductId={props.fetchActivityLogsByProductId}
        productCommentsLoading={props.productCommentsLoading}
        productComments={props.productComments}
        variantsCollapsed={variantsCollapsed}
        setVariantsCollapsed={setVariantsCollapsed}
        defaultImage={defaultImage}
        searchFields={searchFields}
        variants={variants}
        handleExpandVariantLevel={handleExpandVariantLevel}
        fetchVariantLevelChildrenProductsStarted={fetchVariantLevelChildrenProductsStarted}
        lastParentChildrenLoaded={lastParentChildrenLoaded}
        isEditMode={isEditMode}
        disableEdit={disableEdit}
        sortedAttributes={sortedAttributes}
        setUpdatedProductsImagesOverrides={setUpdatedProductsImagesOverrides}
        productsImagesOverride={productsImagesOverride}
        parent={parent}
        filterTarget={filterTarget}
        injectProducts={injectProducts}
        disabled={disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </div>
  );
});

VariantLevelList.propTypes = {
  className: string,
  lastParentChildrenLoaded: string.isRequired,
  variants: arrayOf(shape()).isRequired,
  searchFields: arrayOf(string).isRequired,
  certainProductAttributes: arrayOf(shape()).isRequired,
  productsCommentsCount: arrayOf(shape()),
  productComments: arrayOf(shape()).isRequired,
  variantsCollapsed: arrayOf(string).isRequired,
  sortedAttributes: arrayOf(shape()).isRequired,
  productsImagesOverride: arrayOf(shape()).isRequired,
  parent: shape().isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  handleExpandVariantLevel: func.isRequired,
  injectProducts: func.isRequired,
  defaultImage: string.isRequired,
  setVariantsCollapsed: func.isRequired,
  setUpdatedProductsImagesOverrides: func.isRequired,
  onMouseEnter: func,
  onMouseLeave: func,
  isEditMode: bool.isRequired,
  productCommentsLoading: bool.isRequired,
  fetchVariantLevelChildrenProductsStarted: bool.isRequired,
  filterTarget: string.isRequired,
  disableEdit: bool,
  disabled: bool.isRequired,
};

VariantLevelList.defaultProps = {
  onMouseEnter: null,
  onMouseLeave: null,
  className: '',
  productsCommentsCount: [],
  disableEdit: false,
};

export default VariantLevelList;
