import { isEmpty } from 'lodash';

const getDiff = (fields, item) => {
  const {
    specifications, title, description, metaTitle, metaDescription, metaKeywords, images, videos,
    variationAttributes, price, stock, visibilityStatus, zmid, dataEnrichment, priceEnrichment,
    isForcefullyIncludedInGoogleFeed, pimStatus,
  } = item;

  const initialFiedls = {
    specifications,
    title,
    description,
    metaTitle,
    metaDescription,
    metaKeywords,
    images,
    videos,
    variationAttributes,
    price,
    stock,
    visibilityStatus,
    zmid,
    dataEnrichment,
    priceEnrichment,
    isForcefullyIncludedInGoogleFeed,
    pimStatus,
  };

  const values = {
    specifications: fields.specifications,
    title: fields.title,
    description: fields.description,
    metaTitle: fields.metaTitle,
    metaDescription: fields.metaDescription,
    metaKeywords: fields.metaKeywords,
    images: fields.images,
    videos: fields.videos,
    variationAttributes: fields.variationAttributes,
    price: fields.price,
    stock: fields.stock,
    visibilityStatus: fields.visibilityStatus,
    zmid: fields.zmid,
    dataEnrichment: fields.dataEnrichment,
    priceEnrichment: fields.priceEnrichment,
    isForcefullyIncludedInGoogleFeed: fields.isForcefullyIncludedInGoogleFeed,
    pimStatus: fields.pimStatus,
  };

  return Object.keys(initialFiedls).filter(k => initialFiedls[k] !== values[k]);
};

export const generatePayloadByType = (type, fields, item) => {
  const imgs = !isEmpty(fields.images)
    ? fields.images
    : [];

  for (let i = 0; i < imgs.length; i++) {
    if (imgs[i]['url'].indexOf('?auto=') !== -1) {
      imgs[i]['url'] = imgs[i]['url'].substring(0, imgs[i]['url'].indexOf('?auto='));
    }
  }

  const vids = !isEmpty(fields.videos)
    ? fields.videos
    : [];

  const finalVids = [];
  for (let i = 0; i < vids.length; i++) {
    if (!vids[0].title.en && !vids[0].title.he) {
      delete(vids[0].title)
    }
    if (!vids[0].previewUrl) {
      delete(vids[0].previewUrl)
    }

    finalVids.push(vids[0]);
  }

  const payload = {};
  const diff = getDiff(fields, item);

  switch (type) {
    case 'parent':
      if (diff.includes('specifications')) payload.specifications = fields.specifications;
      if (diff.includes('title')) {
        payload.title = { en: fields.title.en || '', he: fields.title.he || '' };
      }
      if (diff.includes('description')) {
        payload.description = { en: fields.description.en || '', he: fields.description.he || '' };
      }
      if (diff.includes('metaTitle')) {
        payload.metaTitle = { en: fields.metaTitle.en || '', he: fields.metaTitle.he || '' };
      }
      if (diff.includes('metaDescription')) {
        payload.metaDescription = { en: fields.metaDescription.en || '', he: fields.metaDescription.he || '' };
      }
      if (diff.includes('metaKeywords')) {
        payload.metaKeywords = { en: fields.metaKeywords.en || '', he: fields.metaKeywords.he || '' };
      }
      if (fields.imagesChange && !isEmpty(imgs)) payload.images = imgs;
      if (fields.videosChange) payload.videos = finalVids;

      if (diff.includes('visibilityStatus')) payload.visibilityStatus = fields.visibilityStatus;
      if (diff.includes('pimStatus')) payload.pimStatus = fields.pimStatus;

      if (payload.title) {
        Object.keys(payload.title)
          .forEach(key => !payload.title[key] && delete payload.title[key]);
      }
      if (diff.includes('isForcefullyIncludedInGoogleFeed')) payload.isForcefullyIncludedInGoogleFeed =
        fields.isForcefullyIncludedInGoogleFeed;
      if (payload.description) {
        Object.keys(payload.description)
          .forEach(key => !payload.description[key] && delete payload.description[key]);
      }
      if (payload.metaTitle) {
        Object.keys(payload.metaTitle)
          .forEach(key => !payload.metaTitle[key] && (delete payload.metaTitle[key]));
        if (!payload.metaTitle.en && !payload.metaTitle.he) {
          payload.metaTitle = null;
        }
      }
      if (payload.metaDescription) {
        Object.keys(payload.metaDescription)
          .forEach(key => !payload.metaDescription[key] && delete payload.metaDescription[key]);
        if (!payload.metaDescription.en && !payload.metaDescription.he) {
          payload.metaDescription = null;
        }
      }
      if (payload.metaKeywords) {
        Object.keys(payload.metaKeywords)
          .forEach(key => !payload.metaKeywords[key] && delete payload.metaKeywords[key]);
        if (!payload.metaKeywords.en && !payload.metaKeywords.he) {
          payload.metaKeywords = null;
        }
      }
      break;
    case 'variation':
      if (diff.includes('price')) {
        payload.price = {
          ...fields.price,
          finalPrice: fields.price.finalPrice,
          rmaCost: fields.price.rmaCost || 0,
        };
      }
      if (diff.includes('visibilityStatus')) payload.visibilityStatus = fields.visibilityStatus;
      if (diff.includes('pimStatus')) payload.pimStatus = fields.pimStatus;
      if (diff.includes('stock')) payload.stock = fields.stock;
      if (fields.imagesChange && !isEmpty(imgs)) payload.images = imgs;
      if (fields.videosChange) payload.videos = finalVids;
      if (diff.includes('zmid')) {
        payload.zmid = fields.zmid;
        payload.dataEnrichment = fields.dataEnrichment;
        payload.priceEnrichment = fields.priceEnrichment;
      }
      break;
    case 'merchant':
      if (diff.includes('visibilityStatus')) payload.visibilityStatus = fields.visibilityStatus;
      if (diff.includes('pimStatus')) payload.pimStatus = fields.pimStatus;
      if (diff.includes('stock')) payload.stock = fields.stock;
      if (diff.includes('price')) {
        payload.price = {
          ...fields.price,
          ils: Math.round(fields.price.ils),
          finalPrice: fields.price.finalPrice,
          minPrice: fields.price.minPrice,
          rmaCost: fields.price.rmaCost || 0,
        };
        if (fields.sendIlsPrice !== true) {
          delete(payload.price.ils);
        }
      }
      break;
    default:
      break;
  }

  if (fields.removingLockedAttributes) {
    payload.removingLockedAttributes = fields.removingLockedAttributes;
  }

  return payload;
};

export default generatePayloadByType;
