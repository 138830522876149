import React from 'react';
import {
  func, shape, arrayOf, bool,
} from 'prop-types';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import AttributesRow from './AttributesRow';
import IntlMessages from '../../../../util/IntlMessages';

const DragHandle = sortableHandle(() => <i className="ti-drag" />);

const SortableItem = SortableElement((data) => {
  const {
    row, onChange, errors, disabled, exportable,
  } = data;

  return (
    <AttributesRow
      values={row}
      onChange={event => onChange({
        event, row,
      })}
      errors={errors}
      disabled={disabled}
      exportable={exportable}
      permanentlyDisabled={row.permanentlyDisabled}
      content={(
        <div className="flex ml-auto dnd-icon items-center">
          <DragHandle disabled={disabled} />
        </div>
      )}
    />
  );
});

const SortableList = SortableContainer((data) => {
  const attrs = data.attributes;
  const sortedAttributes = attrs.sort((a, b) => a.order - b.order);

  return (
    <div>
      <div className="flex system-preview-attributes-label">
        <div className="form-group-label">
          <IntlMessages id="title.name" />
        </div>
      </div>
      {sortedAttributes.map((row, index) => (
        <SortableItem
          key={row.key}
          index={index}
          row={row}
          errors={data.errors}
          disabled={data.disabled}
          {...data}
        />
      ))}
    </div>
  );
});

const AttributesTable = props => (
  <div className="content-data table-xs">
    <SortableList
      lockAxis="y"
      attributes={props.attributes}
      onSortEnd={props.onSortEnd}
      onChange={props.onChange}
      errors={props.errors}
      disabled={props.disabled}
      useDragHandle
      {...props}
    />
  </div>
);

AttributesTable.propTypes = {
  onSortEnd: func.isRequired,
  onChange: func.isRequired,
  attributes: arrayOf(shape()).isRequired,
  errors: shape().isRequired,
  disabled: bool.isRequired,
};

export default AttributesTable;
