import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  COMBINED_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START,
  COMBINED_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
  HIERARCHICAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START,
  HIERARCHICAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
} from '../actions/types';

const initialState = {
  facets: [],
  combinedFacets: [],
  hierarchicalFacets: [],
  regularFacets: [],
  initialCombinedFacetsOptions: [],
  initialHierarchicalFacetsOptions: [],
};
const categoryFacetRestTypes = restTypes('category_facet');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case categoryFacetRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case categoryFacetRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case categoryFacetRestTypes.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case COMBINED_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START:
    case COMBINED_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED:
    case HIERARCHICAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START:
    case HIERARCHICAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
};
