import { camelizeKeys } from 'humps';
import firebase from 'firebase/app';
import { showErrorNotification } from '../util/api';

import {
  createAffiliate,
  createAffiliateUser,
  createOffer,
  createGoal,
  generateTrackingUrls,
} from '../services/API/tune';

import {
  TUNE_CREATE_NEW_AFFILIATE_START,
  TUNE_CREATE_NEW_AFFILIATE_FINISHED,
  TUNE_CREATE_NEW_AFFILIATE_USER_START,
  TUNE_CREATE_NEW_AFFILIATE_USER_FINISHED,
  TUNE_FETCH_SETTINGS_START,
  TUNE_FETCH_SETTINGS_FINISHED,
  TUNE_CREATE_OFFER_START,
  TUNE_CREATE_OFFER_FINISHED,
  TUNE_CREATE_GOAL_START,
  TUNE_CREATE_GOAL_FINISHED,
  TUNE_GENERATE_TRACKING_URLS_START,
  TUNE_GENERATE_TRACKING_URLS_FINISHED,
} from './types';

export const createNewAffiliate = data => async (dispatch) => {
  dispatch({
    type: TUNE_CREATE_NEW_AFFILIATE_START,
    payload: {
      affiliateCreating: true,
      affiliateCreated: false,
    },
  });

  try {
    const createdAffiliate = await createAffiliate(data);

    dispatch({
      type: TUNE_CREATE_NEW_AFFILIATE_FINISHED,
      payload: {
        createdAffiliate: camelizeKeys(createdAffiliate),
        affiliateCreating: false,
        affiliateCreated: true,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'Magento');
    throw error;
  }
};

export const createNewAffiliateUser = data => async (dispatch) => {
  dispatch({
    type: TUNE_CREATE_NEW_AFFILIATE_USER_START,
    payload: {
      affiliateUserCreating: true,
      affiliateUserCreated: false,
    },
  });

  try {
    const createdAffiliateUser = await createAffiliateUser(data);

    dispatch({
      type: TUNE_CREATE_NEW_AFFILIATE_USER_FINISHED,
      payload: {
        createdAffiliateUser: camelizeKeys(createdAffiliateUser),
        affiliateUserCreating: false,
        affiliateUserCreated: true,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'Magento');
    throw error;
  }
};

export const fetchTuneSettings = () => async (dispatch) => {
  dispatch({
    type: TUNE_FETCH_SETTINGS_START,
    payload: {
      settingsFetching: true,
      settingsFetched: false,
    },
  });

  try {
    const tuneSettings = await firebase.database().ref('/tune_settings').once('value');
    const tuneSettingsData = camelizeKeys(tuneSettings.val());

    dispatch({
      type: TUNE_FETCH_SETTINGS_FINISHED,
      payload: {
        settings: tuneSettingsData,
        settingsFetching: false,
        settingsFetched: true,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'Magento');
    throw error;
  }
};

export const createTuneOffer = data => async (dispatch) => {
  dispatch({
    type: TUNE_CREATE_OFFER_START,
    payload: {
      offerCreating: true,
      offerCreated: false,
    },
  });

  try {
    const createdOffer = await createOffer(data);

    dispatch({
      type: TUNE_CREATE_OFFER_FINISHED,
      payload: {
        createdOffer: camelizeKeys(createdOffer),
        offerCreating: false,
        offerCreated: true,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'Magento');
    throw error;
  }
};

export const createTuneGoal = data => async (dispatch) => {
  dispatch({
    type: TUNE_CREATE_GOAL_START,
    payload: {
      goalCreating: true,
      goalCreated: false,
    },
  });

  try {
    const createdGoal = await createGoal(data);

    dispatch({
      type: TUNE_CREATE_GOAL_FINISHED,
      payload: {
        createdGoal: camelizeKeys(createdGoal),
        goalCreating: false,
        goalCreated: true,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'Magento');
    throw error;
  }
};

export const generateTuneTrackingUrls = data => async (dispatch) => {
  dispatch({
    type: TUNE_GENERATE_TRACKING_URLS_START,
    payload: {
      trackingGenerating: true,
      trackingGenerated: false,
    },
  });

  try {
    const generatedTrackingUrls = await generateTrackingUrls(data);

    dispatch({
      type: TUNE_GENERATE_TRACKING_URLS_FINISHED,
      payload: {
        generatedTrackingUrls: camelizeKeys(generatedTrackingUrls),
        trackingGenerating: false,
        trackingGenerated: true,
      },
    });
  } catch (error) {
    showErrorNotification(error, 'Magento');
    throw error;
  }
};
