/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useState, useContext
} from 'react';
import { func, shape} from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../../components/Layout';
import List from '../../components/List';
import SidebarContent from '../Pim/pages/Home/components/SidebarContent';
import { setFilterTarget } from '../../actions/pim';
import RulesTable from './components/RulesTable';
import { getRule, deleteRule, getRulesData } from '../../actions/rules';
import { headerList } from '../utils/headerList';
import { AbilityContext } from '../../components/AbilityContext';
import { fetchAttributes } from '../../actions/system';
import { sortFields } from "../../util/fields";

export const Rules = (props) => {
  const abilityContext = useContext(AbilityContext);
  const [firstLoad, setFirstLoad] = useState(false);
  const [rulesData, setRulesData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    props.fetchAttributes();
    props.setFilterTarget('rule');
    if (props.rulesPim.pim.rules.data) {
      setRulesData(props.rulesPim.pim.rules.data);
    }
    if (props.rulesPim.pim.rules.itemsPerPage) {
      setItemsPerPage(props.rulesPim.pim.rules.itemsPerPage);
    }
    if (props.rulesPim.pim.rules.formOpen === false) {
      setIsFormOpen(false);
    }
    if (props.rulesPim.pim.rules.formOpen === true) {
      setIsFormOpen(true);
    }
  }, [
    props.rulesPim.pim.rules, props.setFilterTarget
  ]);

  const rulesFields = props.rulesFields;
  const sortedFields = sortFields(rulesFields, 'order');
  const sortedSearchFields = sortFields(rulesFields, 'searchableOrder');

  const foundOpts = [];
  if (props.rulesPim.productAttribute.foundOptions.length > 0) {
    for (let i = 0; i < props.rulesPim.productAttribute.foundOptions.length; i++) {
      const foundOption = props.rulesPim.productAttribute.foundOptions[i];
      if (foundOption.label && foundOption.optionCode) {
        foundOpts[i] = {
          label: foundOption.label.en,
          value: foundOption.optionCode,
        }
      } else if (foundOption.id && foundOption.pathByName) {
        foundOpts[i] = {
          label: foundOption.pathByName,
          value: foundOption.id,
        }
      } else if (foundOption.name && foundOption.merchantId) {
        foundOpts[i] = {
          label: foundOption.name,
          value: foundOption.merchantId,
        }
      }
    }
  }

  if (firstLoad === false) {
    const storedSearchFilters = sessionStorage.getItem('pim.searchFilters');
    const storedSearchFields = sessionStorage.getItem('pim.searchFields');
    const parsedStoredSearchFilters = JSON.parse(storedSearchFilters) || { rule: [] };
    const parsedStoredSearchFields = JSON.parse(storedSearchFields) || { rule: [] };
    if (parsedStoredSearchFilters.rule.length === 0 && parsedStoredSearchFields.rule.length === 0) {
      setTimeout(function(){
        props.getRulesData({limit: itemsPerPage, offset: 0});
      }, 1000);
    }
    setFirstLoad(true);
  }

  return (
    <Layout
      horizontalMenu
      header={{
        className: 'header-mer',
        subHeaderContent: (
          <List
            dir="horizontal"
            className="justify-center"
            dataSource={headerList({ abilityContext })}
          />
        ),
      }}
      sidebar={{
        className: `sidebar-mer sidebar-ranking-mer sidebar-scroll-secondary`,
        content: <SidebarContent
          sortedFields={sortedFields}
          sortedSearchFields={sortedSearchFields}
          condition={props.rulesData.condition}
          scroll={props.rulesData.scroll}
          getRulesData={props.getRulesData}
          reset={props.rulesData.reset}
          itemsPerPage={itemsPerPage}
        />,
      }}
      main={{
        className: 'main-mer main-ranking-mer',
        content: (
          <RulesTable
            rulesFields={sortedFields}
            rulesConditions={props.rulesConditions}
            rulesConditionsOptions={props.rulesConditionsOptions}
            actionOptions={props.actionOptions}
            discardSubsequentRulesOptions={props.discardSubsequentRulesOptions}
            getRule={props.getRule}
            deleteRule={props.deleteRule}
            getRulesData={props.getRulesData}
            rulesData={rulesData}
            rule={props.rulesPim.pim.rules.rule ? props.rulesPim.pim.rules.rule : null}
            rulesScroll={props.rulesPim.pim.rules.scroll ? props.rulesPim.pim.rules.scroll : []}
            rulesCondition={props.rulesPim.pim.rules.condition ? props.rulesPim.pim.rules.condition : []}
            formOpen={isFormOpen}
            itemsPerPage={itemsPerPage}
            foundOptions={foundOpts}
            selectedProducts={props.rulesPim.pim.selected}
          />
        )
      }}
    />
  );
};

Rules.propTypes = {
  setFilterTarget: func.isRequired,
  getRulesData: func.isRequired,
  getRule: func.isRequired,
  deleteRule: func.isRequired,
  rulesPim: shape().isRequired,
  rulesFields: shape(),
  rulesData: shape().isRequired,
  fetchAttributes: func.isRequired,
};

Rules.defaultProps = {
  rulesFields: {},
  rulesConditions: [],
  rulesConditionsOptions: [],
  actionOptions: [],
  discardSubsequentRulesOptions: [],
};

const stateToProps = state => ({
  rulesFields: state.system.modes.rules ? state.system.modes.rules.fields : {},
  rulesData: state.pim.rules,
  rulesConditions: state.system.modes.rules ? state.system.modes.rules.rulesConditions : [],
  rulesConditionsOptions: state.system.modes.rules ? state.system.modes.rules.rulesConditionsOptions : [],
  actionOptions: state.system.modes.rules ? state.system.modes.rules.actionOptions : [],
  discardSubsequentRulesOptions: state.system.modes.rules ? state.system.modes.rules.discardSubsequentRulesOptions : [],
  rulesPim: state,
});

const dispatchToProps = {
  setFilterTarget,
  getRulesData,
  getRule,
  deleteRule,
  fetchAttributes,
};

export default withRouter(connect(stateToProps, dispatchToProps)(Rules));
