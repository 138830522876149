/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import { connect } from 'react-redux';
import {
  string, shape, arrayOf, bool, func,
} from 'prop-types';

import List from './List';

import {
  fetchActivityLogsByProductId,
} from '../../../../actions/product';

const ProductImageViewListDnD = memo((props) => {
  const {
    items, disabled, list,
    defaultImage, attributes, certainProductAttributes,
    isEditMode, pagination, productComments, productsImagesOverride,
  } = props;

  return (
    <List
      disabled={disabled}
      productList={items}
      defaultImage={defaultImage}
      attributes={attributes}
      certainProductAttributes={certainProductAttributes}
      isEditMode={isEditMode}
      fetchActivityLogsByProductId={props.fetchActivityLogsByProductId}
      pagination={pagination}
      productComments={productComments}
      productsImagesOverride={productsImagesOverride}
      list={list}
    />
  );
});

ProductImageViewListDnD.propTypes = {
  items: arrayOf(shape()).isRequired,
  certainProductAttributes: arrayOf(shape()).isRequired,
  attributes: arrayOf(shape()).isRequired,
  defaultImage: string.isRequired,
  productComments: arrayOf(shape()).isRequired,
  productsImagesOverride: arrayOf(shape()).isRequired,
  list: arrayOf(shape()).isRequired,
  pagination: shape().isRequired,
  isEditMode: bool.isRequired,
  disabled: bool,

  fetchActivityLogsByProductId: func.isRequired,
};

ProductImageViewListDnD.defaultProps = {
  disabled: false,
};

const mapStateToProps = state => ({
  attributes: state.system.modes.preview,
  productsImagesOverride: state.preview.productsImagesOverride,
  list: state.preview.list,
  certainProductAttributes: state.productAttribute.certainProductAttributes,
  productComments: state.product.productComments,
  defaultImage: state.preview.defaultImage,
  isEditMode: state.preview.isEditMode,
  isHiddenProductsMode: state.preview.isHiddenProductsMode,
});

const mapDispatchToProps = {
  fetchActivityLogsByProductId,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductImageViewListDnD);
