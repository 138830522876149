import { createSelector } from 'reselect';

const productAttributeRootSelector = state => state;

const stateSelector = createSelector(
  productAttributeRootSelector,
  state => ({
    productAttributesFetching: state.productAttributesFetching,
    productAttributesFetched: state.productAttributesFetched,
    foundOptions: state.foundOptions,
    searchAttributeOptionsStart: state.searchAttributeOptionsStart,
    fetchingAttributeOptions: state.fetchingAttributeOptions,
    productAttributes: state.productAttributes,
    attributesOptions: state.attributesOptions,
  }),
);

export const productAttributeSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  productAttributeSelector,
};
