/* eslint-disable default-case */
export const getFieldByOperator = ({ field, operator, previewFilterAttribute }) => {
  const filterAttr = previewFilterAttribute
    ? previewFilterAttribute.find(f => f.value === field)
    : null;

  switch (operator) {
    case 'nlike':
    case 'like':
      return filterAttr && filterAttr.filterValue
        ? filterAttr.filterValue
        : field;

    default:
      return field;
  }
};

export const getValueByOperator = ({ value, operator }) => {
  switch (operator) {
    case 'nin':
    case 'in':
      if (Array.isArray(value)) {
        return [...new Set(value)];
      } return [...new Set(value.split(',').filter(f => f))];

    case 'nlike':
    case 'like':
      if (value.includes('*') && value.startsWith('*') && value.endsWith('*')) return value;
      return `*${value}*`;
    case 'between':
      return {
        from: value.from,
        to: value.to,
      };

    default:
      return value;
  }
};

export const mapFacetOperator = (facetInputType) => {
  switch (facetInputType) {
    case 'slider': return 'between';
  }

  return 'eq';
};

const getCondition = filter => (filter.group.length > 1 ? {
  condition: filter.groupCondition || filter.condition,
} : { condition: 'and' });

export const mapFiltersByOperator = (filters, previewFilterAttribute) => {
  const mappedFilters = filters.map(sf => ({
    ...getCondition(sf),
    group: sf.group.map(g => ({
      field: getFieldByOperator({ field: g.field, operator: g.operator, previewFilterAttribute }),
      value: getValueByOperator({ value: g.fieldOption || g.value, operator: g.operator }),
      operator: g.operator,
    })),
  }));

  return mappedFilters;
};

export default {
  mapFiltersByOperator,
  getFieldByOperator,
  getValueByOperator,
};
