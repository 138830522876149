import { createSelector } from 'reselect';

const previewRootSelector = state => state;

const stateSelector = createSelector(
  previewRootSelector,
  previewCategory => ({
    virtualCategoryItem: previewCategory.item,
    originalVirtualCategoryItem: previewCategory.originalItem,
    virtualCategoryUpdating: previewCategory.updating,
    virtualCategoryUpdated: previewCategory.updated,
    previewCategorySort: previewCategory.sort,
    previewCategoryFilter: previewCategory.filter,
    previewCategoryFacet: previewCategory.facet,
    isCategorySaving: previewCategory.isCategorySaving,
  }),
);

export const previewCategorySelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  previewCategorySelector,
};
