import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const influencerItemSelector = item => item;

const selectedProductsFiltersSelector = createSelector(
  influencerItemSelector,
  item => (!isEmpty(item) && item.page.productSection.selectedProductsFilters
    ? item.page.productSection.selectedProductsFilters
    : []),
);

export const productSectionSelectedProductsFilterSelector = createSelector(
  selectedProductsFiltersSelector,
  selectedProductsFilters => (selectedProductsFilters),
);

export default {
  productSectionSelectedProductsFilterSelector,
};
