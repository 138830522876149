export const getUniqueAttributes = (attributes, matrixAttributes) => (
  attributes.filter(f => (
    !matrixAttributes || !matrixAttributes.some(ma => ma.field === f.code)
  ))
);

export const getFilterableAttributes = attributes => (
  attributes.filter(
    ca => ca.isFilterable,
  ).map(i => ({
    ...i,
    options: i.options
      ? i.options.map(o => ({
        label: o.label ? o.label.en || o.label : o.title,
        value: o.code || o.value,
      }))
      : [],
  }))
);

export default {
  getUniqueAttributes,
  getFilterableAttributes,
};
