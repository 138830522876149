export const countProductsActivitiesMapper = (data) => {
  const mappedResults = [];
  Object.keys(data.aggregation.affected_ids).forEach((a) => {
    mappedResults.push({
      id: a,
      countComments: data.aggregation.affected_ids[a],
    });
  });
  return mappedResults;
};

export default {
  countProductsActivitiesMapper,
};
