import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  string, shape, node, bool,
} from 'prop-types';
import Sidebar from 'react-sidebar';
import { Scrollbars } from 'react-custom-scrollbars';
import classnames from 'classnames';

import Header from '../Header';
import SidebarContent from '../Sidebar';
import GlobalLoader from '../GlobalLoader';

const RootPimLayout = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => () => {
    window.removeEventListener('resize', updateDimensions);
  }, []);

  const getScrollBarStyle = () => ({
    height: 'calc(100vh - 50px)',
  });

  const {
    main, horizontalMenu, wrapperClassName, containerWrapperClassName,
  } = props;
  const { navCollapsed, rtlLayout, miniSidebar } = props.settings;
  const withoutScroll = !!main.withoutScroll;

  return (
    <div className={`app ${wrapperClassName}`}>
      <div className={`app-main-container crm-layout sidebar-preview ${windowWidth > 1199 && horizontalMenu ? 'sidebar-fixed' : 'sidebar-abs'}`}>
        <Sidebar
          sidebar={(
            <SidebarContent
              className={props.sidebar.className}
              sidebarContent={props.sidebar.content}
              key={new Date().getMilliseconds}
              windowWidth={windowWidth}
            />
          )}
          open={windowWidth <= 1199 ? navCollapsed : false}
          docked={windowWidth > 1199 && !horizontalMenu ? !navCollapsed : false}
          pullRight={rtlLayout}
          styles={{ content: { overflowY: '' } }}
          className="sidebar-wrapper"
          contentClassName={classnames({ 'app-conrainer-wrapper': miniSidebar })}
        >
          <div className={`app-container ${containerWrapperClassName}`}>
            <div className="rct-app-content">
              <div className="app-header">
                <Header
                  className={props.header.className}
                  content={props.header.content}
                  controls={props.header.controls}
                  subHeaderContent={props.header.subHeaderContent}
                  horizontalMenu={props.horizontalMenu}
                />
              </div>
              <div className="rct-page">
                {
                  !withoutScroll
                    ? (
                      <Scrollbars
                        className="rct-scroll"
                        autoHide
                        autoHideDuration={100}
                        style={getScrollBarStyle()}
                      >
                        <div className={`rct-page-content full-height relative ${main.className || ''}`}>
                          {main.content}
                        </div>
                      </Scrollbars>
                    ) : (
                      <div className={`rct-page-content full-height relative ${main.className || ''}`}>
                        {main.content}
                      </div>
                    )
                }
              </div>
            </div>
          </div>
        </Sidebar>
      </div>
      <GlobalLoader />
    </div>
  );
};

RootPimLayout.propTypes = {
  className: string,
  header: shape({
    content: node,
    className: string,
  }),
  sidebar: shape({
    className: string,
    content: node,
  }),
  main: shape(),
  settings: shape().isRequired,
  horizontalMenu: bool,
  wrapperClassName: string,
  containerWrapperClassName: string,
};

RootPimLayout.defaultProps = {
  className: '',
  header: {},
  sidebar: {},
  main: {},
  horizontalMenu: false,
  wrapperClassName: '',
  containerWrapperClassName: '',
};

const mapStateToProps = state => ({
  settings: state.settings,
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RootPimLayout));
