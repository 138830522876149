import React, { useState } from 'react';
import {
  string, func, bool, arrayOf, shape,
} from 'prop-types';
import TextField from '@material-ui/core/TextField';

import Suggestions from './components/Suggestions';

export const SearchAutocompleteComponent = (props) => {
  const [focused, setFocused] = useState(false);
  let timeoutDelay = null;

  const onFocus = () => setFocused(true);
  const onBlur = () => {
    if (timeoutDelay) clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      setFocused(false);
      clearTimeout(timeoutDelay);
    }, 300);
  };

  return (
    <div className={`search-wrapper ${props.wrapperClassName}`}>
      <TextField
        onChange={props.onChange}
        className="text-input-lg search-preview pl-10"
        disabled={props.disabled}
        name={props.name}
        id={props.id}
        label={props.label}
        value={props.value}
        placeholder={props.placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      <Suggestions
        suggestions={props.suggestions}
        searchValue={props.value}
        onSearchPhraseClick={props.onSearchPhraseClick}
        inputFocused={focused}
      />
    </div>
  );
};

SearchAutocompleteComponent.propTypes = {
  wrapperClassName: string,
  name: string,
  id: string,
  onChange: func.isRequired,
  disabled: bool,
  label: string,
  value: string,
  placeholder: string,

  suggestions: arrayOf(shape()),
  onSearchPhraseClick: func,
};

SearchAutocompleteComponent.defaultProps = {
  wrapperClassName: '',
  name: '',
  id: '',
  disabled: false,
  label: '',
  value: '',
  placeholder: '',

  suggestions: [],
  onSearchPhraseClick: null,
};

export default SearchAutocompleteComponent;
