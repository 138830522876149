import React, {
  useState, useRef, useEffect, memo,
} from 'react';
import {
  arrayOf, func, shape, string, bool, oneOfType, object, number,
} from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import {
  List, ListItem, ListItemAvatar, ListItemText, ListItemIcon, Avatar,
  Checkbox, Button,
} from '@material-ui/core';
import IntlMessages from '../../util/IntlMessages';
import EmptyComponent from '../Empty';
import ProductCard from '../ProductCard';
import ProductCardContent from './ProductCardContent';

export const TransferListComponent = memo((props) => {
  const [scrollTop, setScrollTop] = useState(0);
  const domRef = useRef();
  useEffect(() => {
    if (domRef.current) {
      domRef.current.scrollTop = scrollTop;
    }
  }, [scrollTop]);

  const getAttribute = attribute => ({
    ...attribute,
    title: attribute.title.en,
    description: attribute.description.en,
  });

  const getFavoriteClassName = id => {
    if (props.favorites) {
      for (let i = 0; i < props.favorites.length; i++) {
        if (props.favorites[i].id === id) {
          return 'favorite-avatar';
        }
      }
    }

    return '';
  }

  const handleProductCardClose = (scrollTopValue) => {
    const { appVersion } = window.navigator;
    if (!appVersion.includes('Android') && !appVersion.includes('iPhone')) {
      setScrollTop(scrollTopValue + 1);
    }
  };

  const handleRemoveProductClick = (itemId) => {
    const newList = cloneDeep(props.list).filter(item => item.id !== itemId);
    props.onSelectedListChange(newList, props.filterOperatorKey);
  };

  return (
    !isEmpty(props.list) ? (
      <List
        dense
        id="transferList"
        ref={domRef}
        className={'transfer-list-container overflow-y-auto'}
        component="div"
        role="list"
      >
        {props.list.map((item) => {
          const labelId = `transfer-list-all-item-${item}-label`;
          const isChecked = props.selected && props.selected.length > 0
            && props.selected.some(s => s.id === item.id);
          const totalListLength = [...props.checked, ...props.selected].length;
          const disabledItem = totalListLength === props.maxSelected
            && !props.checked.some(p => p.id === item.id) && props.isLeftList;
          const disabledListItemClass = isChecked || disabledItem || item.disabled ? 'list-item-disabled' : '';

          const onItemRemove = () => handleRemoveProductClick(item.id);

          return (
            <div key={item.id} className="transfer-list-item relative">
              <ListItem
                className={`list-item-secondary ${disabledListItemClass}`}
                key={item.id}
              >
                {item.messageId && (
                  <div className={`list-item-message list-item-message-${item.messageType}`}>
                    <IntlMessages id={item.messageId} />
                  </div>
                )}
                <ListItemIcon className="checkbox-sm">
                  <Checkbox
                    checked={isChecked || item.checked || props.checked.indexOf(item) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    onClick={props.onClick(item)}
                  />
                </ListItemIcon>
                <ListItemAvatar>
                  <Avatar
                    alt={(item.image && item.image.alt ? item.image.alt.en : '') || 'image alt'}
                    src={item.image && item.image.url ? item.image.url : ''}
                    className={getFavoriteClassName(item.id)}
                  />
                </ListItemAvatar>
                <div className={props.listItemWrapperClassName}>
                  {props.showMoreIcon && (
                    <ProductCard
                      product={item}
                      onClose={handleProductCardClose}
                      content={(
                        <ProductCardContent
                          productData={item}
                        />
                      )}
                    />
                  )}
                  {item.attribute && Object.keys(getAttribute(item.attribute)).map(attrKey => (
                    <ListItemText
                      className={`list-item-${attrKey} ${props.listItemClassName} ${Object.keys(item.attribute).length > 2 ? 'pb-10' : ''}`}
                      key={`${item.attribute[attrKey]}_${item.id}_${Object.keys(item.attribute).indexOf(attrKey)}`}
                      id={`${item.attribute[attrKey]}_${item.id}`}
                      primary={<b className="text-capitalize">{attrKey.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}</b>}
                      secondary={getAttribute(item.attribute)[attrKey]}
                    />
                  ))}
                </div>
              </ListItem>
              {item.deleted && (
                <div className="transfer-remove-btn">
                  <Button
                    color="primary"
                    variant="outlined"
                    className="btn-link btn-s text-normal block"
                    onClick={onItemRemove}
                  >
                    <IntlMessages id="transfer.list.product.deleteButtonLabel" />
                  </Button>
                </div>
              )}
            </div>
          );
        })}
        <ListItem />
      </List>
    ) : (
      <EmptyComponent
        icon={<i className="ti-face-sad" />}
        title={<IntlMessages id="placeholder.noData" />}
      />
    )
  );
});

TransferListComponent.propTypes = {
  list: arrayOf(shape()),
  selected: arrayOf(shape()),
  onClick: func.isRequired,
  checked: arrayOf(shape({
    id: string,
    title: oneOfType([string, object]),
    description: oneOfType([string, object]),
  })),
  listItemWrapperClassName: string,
  listItemClassName: string,
  showMoreIcon: bool,
  disabledList: bool,
  maxSelected: number,
  onSelectedListChange: func,
  filterOperatorKey: string,
};

TransferListComponent.defaultProps = {
  list: [],
  selected: [],
  checked: [],
  listItemWrapperClassName: '',
  listItemClassName: '',
  showMoreIcon: false,
  disabledList: false,
  maxSelected: null,
  onSelectedListChange: null,
  filterOperatorKey: '',
};

export default TransferListComponent;
