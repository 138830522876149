import React, { memo } from 'react';
import {
  shape,
} from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';

import SortableList from './SortableList';

const HocSortableList = SortableContainer(props => (
  <SortableList
    disabled={props.disabled}
    hocSortableListProps={props.hocSortableListProps}
  />
));

const ParentLevelGrid = memo((props) => {
  const {
    gridListProps,
  } = props;

  return (
    <HocSortableList
      disabled={gridListProps.disabled}
      hocSortableListProps={{
        axis: 'xy',
        productList: gridListProps.items,
        onSortEnd: gridListProps.onSortEnd,
        grid: gridListProps.grid,
        onSettingsCLick: gridListProps.onSettingsCLick,
        defaultImage: gridListProps.defaultImage,
        attributes: gridListProps.attributes,
        certainProductAttributes: gridListProps.certainProductAttributes,
        selectedEntity: gridListProps.selectedEntity,
        disabled: gridListProps.disabled,
        isEditMode: gridListProps.isEditMode,
        productsCommentsCount: gridListProps.productsCommentsCount,
        fetchActivityLogsByProductId: gridListProps.fetchActivityLogsByProductId,
        productCommentsLoading: gridListProps.productCommentsLoading,
        productComments: gridListProps.productComments,
        pagination: gridListProps.pagination,
        edidActionsAreDisabled: gridListProps.edidActionsAreDisabled,
        initialAttributesOptions: gridListProps.initialAttributesOptions,
      }}
    />
  );
});

ParentLevelGrid.propTypes = {
  gridListProps: shape().isRequired,
};

ParentLevelGrid.defaultProps = {};

export default ParentLevelGrid;
