/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import {
  bool, func,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IntlMessages from '../../../../util/IntlMessages';
import PimPage from "../../../Pim/pages/Home";

const AddProductsDialog = memo((props) => {
  const {
    onAddProductsClick,
    isOpened,
    onClose,
    getRulesForm,
  } = props;

  const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));

  const classes = useStyles();

  const addProducts = () => {
    onAddProductsClick();
    getRulesForm({open: true});
  };

  const closeAddProductsDialog = () => {
    onClose();
    getRulesForm({open: true});
  };

  return (
    <div>
      <Dialog fullScreen open={isOpened} onClose={closeAddProductsDialog}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={closeAddProductsDialog} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <IntlMessages id="rules.addProductsTitle" />
            </Typography>
            <Button autoFocus color="inherit" onClick={addProducts}>
              <IntlMessages id="button.rulesSaveProduct" />
            </Button>
          </Toolbar>
        </AppBar>

        <PimPage fromRules={true} />

      </Dialog>
    </div>

  );
});

AddProductsDialog.propTypes = {
  onAddProductsClick: func.isRequired,
  onClose: func.isRequired,
  getRulesForm: func.isRequired,
  isOpened: bool.isRequired,
};

AddProductsDialog.defaultProps = {};

export default AddProductsDialog;
