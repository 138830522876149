import React from 'react';
import {
  string, oneOfType, node,
} from 'prop-types';

export const SubheaderComponent = ({
  title, wrapperClassName, content,
}) => (
  <div className={`subheader ${wrapperClassName}`}>
    <h4 className="subheader-title">{title}</h4>
    {content}
  </div>
);

SubheaderComponent.propTypes = {
  title: oneOfType([
    string, node,
  ]),
  content: node,
  wrapperClassName: string,
};

SubheaderComponent.defaultProps = {
  title: '',
  content: null,
  wrapperClassName: '',
};

export default SubheaderComponent;
