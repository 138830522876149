import { createSelector } from 'reselect';

const previewRootSelector = state => state;

const stateSelector = createSelector(
  previewRootSelector,
  preview => ({
    selectedProducts: preview.selected,
    productList: preview.list,
    containerStyles: preview.containerStyles,
    isHiddenProductsMode: preview.isHiddenProductsMode,
    selectedAction: preview.selectedAction,
    selectedCategory: preview.selectedCategory,
    selectedSearchFilter: preview.selectedSearchFilter,
    selectedPromotion: preview.selectedPromotion,
    selectedEntityName: preview.selectedEntityName,
  }),
);

export const previewSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  previewSelector,
};
