import {
  SET_YOTPO_DATA,
} from './types';

import { YotpoClient, showErrorNotification } from '../util/api';

import { setIsLoading } from './global';

export const getProductReviewsRes = (id, page, perPage) =>
  YotpoClient.get('/products/' + id + '/reviews?page=' + page + '?per_page=' + perPage);

export const getProductReviews = (id, page = 1, perPage = 5) => async (dispatch) => {
  dispatch(setIsLoading(true));

  try {
    const res = await getProductReviewsRes(id, page, perPage);
    const { data } = res;

    dispatch({
      type: SET_YOTPO_DATA,
      payload: { status: data.status, response: data.response }
    });

  } catch (error) {
    showErrorNotification(error, 'YOTPO');
  }

  dispatch(setIsLoading(false));
};
