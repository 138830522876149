import React from 'react';
import {
  func, shape, number, string,
} from 'prop-types';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { paginationLimit } from '../../util/defaultData';

const ProductsPagination = props => (
  <div className="pagination pagination-xs justify-end">
    {props.product.total > paginationLimit && (
      <ReactPaginate
        pageCount={props.product.pages}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        breakLabel={<span>...</span>}
        nextLabel={<FontAwesomeIcon size="lg" icon={faAngleRight} />}
        previousLabel={<FontAwesomeIcon size="lg" icon={faAngleLeft} />}
        breakClassName="break-me"
        activeClassName="active"
        containerClassName={`pagination flex justify-center ${props.containerClassName}`}
        onPageChange={props.onPaginate}
        pageClassName="page-btn"
        forcePage={props.pagination.page - 1}
      />
    )}
  </div>
);

ProductsPagination.propTypes = {
  onPaginate: func.isRequired,
  product: shape({
    pages: number,
    total: number,
  }).isRequired,
  pagination: shape({
    page: number,
  }),
  containerClassName: string,
};

ProductsPagination.defaultProps = {
  pagination: {},
  containerClassName: '',
};

export default ProductsPagination;
