import React, { useState, useEffect } from 'react';
import 'rc-tree/assets/index.css';
import {
  func, arrayOf, string, shape, oneOfType, object, bool,
} from 'prop-types';
import { withRouter } from 'react-router-dom';
import Tree from 'rc-tree';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { generateTreeList } from './utils/generateTreeList';
import TreeNodePopover from '../ScopePopupPopover';
import roles from '../../util/roles';

export const CategoriesCheckboxTreeComponent = (props) => {
  const [localCategories, setLocalCategories] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(false);
  const [hoveredCategoryId, setHoveredCategoryId] = useState('');
  const [popoverAnchor, setPopoverAnchor] = useState(null);

  let delayHandler = null;

  useEffect(() => {
    setLocalCategories(props.categories);
  }, [props.categories]);

  useEffect(() => {
    setCheckedKeys(props.checkedKeys);
  }, [props.checkedKeys]);

  const handleMultipleCheck = (checked, checkedItem) => {
    setCheckedKeys(checked);
    props.onSelect(checkedItem);
  };

  const handleSingleCheck = (keyIsChecked, checked) => {
    if (keyIsChecked) {
      setCheckedKeys([]);
      props.onSelect([]);
    } else {
      setCheckedKeys(checked);
      props.onSelect(checked[0]);
    }
  };

  const onCheck = (checked, checkedItem) => {
    const { multiple } = props;
    const keyIsChecked = checkedKeys
      .some(k => checked
        .some(s => k === s));

    if (multiple) handleMultipleCheck(checked, checkedItem);
    else handleSingleCheck(keyIsChecked, checked);
  };

  const onBeforeCheck = (checked, item) => {
    const { eventKey } = item.node.props;
    const currentNodeId = item.node.props.eventKey;
    const { checkStrictly, categories } = props;
    const currentCategory = categories.find(c => c.id === currentNodeId);

    if (checkStrictly) onCheck(checked.checked, eventKey);
    else {
      const expand = 100;
      props.loadAllChildCategories(currentCategory, expand, (ids) => {
        if (ids) {
          const idAlreadyExist = checkedKeys.some(k => k === currentNodeId);
          if (!idAlreadyExist) {
            setCheckedKeys([
              ...checkedKeys,
              ...ids,
            ]);
            props.onMultipleSelect([
              ...checkedKeys,
              ...ids,
            ]);
          } else {
            const pureCheckedKeys = checkedKeys;
            const filteredCheckedKeys = pureCheckedKeys.filter(cK => ids.every(id => id !== cK));
            setCheckedKeys(filteredCheckedKeys);
            props.onMultipleSelect(filteredCheckedKeys);
          }
        }
      });
    }
  };

  const onExpand = (expandedKeys) => {
    setAutoExpandParent(false);
    props.onToggleCategoryTreeCollapse(expandedKeys);
  };

  const onDragEnter = info => props.onToggleCategoryTreeCollapse(info.expandedKeys);

  const onDrop = (info) => {
    const { categories } = props;
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const data = [...localCategories];
    const transferData = data.map((cat) => {
      const item = cat;
      if (item.id === dragKey) {
        item.parentId = dropKey;
      }
      return item;
    });

    setLocalCategories(transferData);

    const targetCategory = categories.find(c => c.id === dragKey);
    const payload = {
      ...targetCategory,
      parentId: info.dropToGap ? null : dropKey,
    };

    const categoryData = {
      id: payload.id,
      virtualParams: payload.virtualParams,
      name: payload.name,
      description: payload.description,
      parentId: payload.parentId,
      isEnabled: payload.isEnabled,
      includeInMenu: payload.includeInMenu,
      useInProductSearch: payload.useInProductSearch,
    };

    props.handleDnDChange(categoryData);
  };

  const onLoad = loadedKeys => props.handleLoadSubcategories(loadedKeys);

  const onClick = (e, node) => {
    const { activeCategory, checkable } = props;
    const { eventKey } = node.props;
    const alreadySelected = activeCategory === eventKey;

    if (checkable) return;
    if (!alreadySelected) props.onSelect(eventKey);
  };

  const handleLoadChildren = treeNode => props.loadChildren(treeNode.props.eventKey);

  const onMouseLeave = () => {
    setHoveredCategoryId('');
    setPopoverAnchor(null);
    clearTimeout(delayHandler);
  };

  const onMouseEnter = ({ event, node }) => {
    event.persist();
    const { eventKey } = node.props;

    delayHandler = setTimeout(() => {
      setHoveredCategoryId(eventKey);
      setPopoverAnchor(event.target);
    }, 1000);
  };

  const {
    className, selected, isRadio, checkable, draggable, multiple, handleRightClick,
    activeCategory, collapsed, loadedKeys, session, ignoreScopeValidation,
    isCategoriesTreeDisabled, showSecondaryTitle, loading, loadingId, disableNotSelected,
  } = props;

  const onEnterActive = node => ({ height: node.scrollHeight });

  const motion = {
    motionName: 'node-motion',
    motionAppear: false,
    onEnterActive,
    onLeaveStart: node => ({ height: node.offsetHeight }),
  };

  const wrapperClassName = isRadio && selected && !isEmpty(selected)
    ? 'disable-list'
    : 'enable-list';

  return (
    <div className={`tree-wrapper ${className} ${wrapperClassName}`}>
      <Tree
        motion={motion}
        onExpand={onExpand}
        autoExpandParent={autoExpandParent}
        draggable={draggable}
        onDragEnter={onDragEnter}
        onDrop={onDrop}
        loadData={handleLoadChildren}
        onClick={onClick}
        onCheck={onBeforeCheck}
        checkable={checkable}
        checkedKeys={checkedKeys}
        checkStrictly
        showIcon={false}
        expandedKeys={collapsed}
        onRightClick={handleRightClick}
        loadedKeys={loadedKeys}
        onLoad={onLoad}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {generateTreeList({
          multiple,
          checkedKeys,
          activeCategory,
          showSecondaryTitle,
          session,
          ignoreScopeValidation,
          roles,
          checkable,
          isCategoriesTreeDisabled,
          localCategories,
          selected,
          isRadio,
          collapsed,
          loading,
          loadingId,
          disableNotSelected,
        })}
      </Tree>
      {showSecondaryTitle && (
        <TreeNodePopover
          hoveredCategoryId={hoveredCategoryId}
          popoverAnchor={popoverAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        />
      )}
    </div>
  );
};

CategoriesCheckboxTreeComponent.propTypes = {
  className: string,
  selected: arrayOf(oneOfType([string, object])),
  collapsed: arrayOf(oneOfType([string, object])),
  onSelect: func.isRequired,
  onToggleCategoryTreeCollapse: func.isRequired,
  loadChildren: func.isRequired,
  categories: arrayOf(shape({
    id: string,
    name: shape({
      en: string,
      he: string,
    }),
  })),
  checkable: bool,
  isRadio: bool,
  handleDnDChange: func,
  draggable: bool,
  multiple: bool,
  loadedKeys: arrayOf(string),
  handleRightClick: func,
  handleLoadSubcategories: func,
  activeCategory: string,
  checkStrictly: bool,
  loadAllChildCategories: func,
  onMultipleSelect: func,
  session: shape({
    item: shape(),
  }).isRequired,
  ignoreScopeValidation: bool,
  isCategoriesTreeDisabled: bool,
  showSecondaryTitle: bool,
  checkedKeys: arrayOf(string),
  loading: bool,
  loadingId: string,
  disableNotSelected: bool,
};

CategoriesCheckboxTreeComponent.defaultProps = {
  className: '',
  selected: [],
  collapsed: [],
  categories: [],
  isRadio: false,
  checkable: false,
  draggable: false,
  handleDnDChange: null,
  multiple: false,
  handleRightClick: null,
  handleLoadSubcategories: null,
  activeCategory: '',
  loadedKeys: [],
  checkStrictly: true,
  loadAllChildCategories: null,
  onMultipleSelect: null,
  ignoreScopeValidation: false,
  isCategoriesTreeDisabled: false,
  showSecondaryTitle: true,
  checkedKeys: [],
  loading: false,
  loadingId: '',
  disableNotSelected: false,
};

const mapStateToProps = state => ({
  session: state.session,
});
export default withRouter(connect(mapStateToProps, {})(CategoriesCheckboxTreeComponent));
