import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const productRootSelector = state => state;
const productItemSelector = state => state.item;

const mapProductsImages = (images) => {
  if (!images) return [];
  return ([
    ...images.map((img) => {
      const params = '?auto=format%2Ccompress&bg=transparent&fill=solid&fit=fillmax&h=220&trim=auto&trimsd=1&w=auto';
      return {
        ...img,
        url: `${img.url}${params}`,
      };
    }),
  ]);
};

const itemSelector = createSelector(
  productItemSelector,
  item => (!isEmpty(item) ? ({
    ...item,
    images: mapProductsImages(item.images),
  }) : {}),
);

const stateSelector = createSelector(
  productRootSelector,
  product => ({
    fields: product.fields,
    updating: product.updating,
    updated: product.updated,
    edit: product.edit,
    info: product.info,
    childList: product.childList,
    editInProgress: product.editInProgress,
    nextProductLoading: product.nextProductLoading,
    pimOptions: product.pimOptions,
    lockedAttributes: product.edit.lockedAttributes,
    productComments: product.productComments,
    specificationErrors: product.specificationErrors,
  }),
);

export const productSelector = createSelector(
  stateSelector,
  itemSelector,
  (state, item) => ({
    ...state,
    item,
  }),
);

export default {
  productSelector,
};
