export const initialTableScroll = (tableId, tableScrollId, containerId, containerScrollId) => {
  return setTimeout(() => {
    const scrollTable = document.getElementById(tableId);
    const scrollTableScroll = document.getElementById(tableScrollId);
    if (scrollTable && scrollTableScroll) {
      scrollTableScroll.style.width = scrollTable.getBoundingClientRect().width + 'px';
    }

    const tableContainer = document.getElementById(containerId);
    const tableContainerScroll = document.getElementById(containerScrollId);
    if (tableContainer && tableContainerScroll) {
      tableContainerScroll.style.width = tableContainer.getBoundingClientRect().width + 'px';
    }
  },1000);
}

export const moveTableScroll = (containerId, containerScrollId) => {
  const tableContainer = document.getElementById(containerId);
  const tableContainerScroll = document.getElementById(containerScrollId);
  if (tableContainer && tableContainerScroll) {
    tableContainer.scrollLeft = parseInt(tableContainerScroll.scrollLeft);
  }
};

export const moveMapperTableScroll = (headerID) => {
  const rctPageContent = document.getElementsByClassName('rct-page-content');
  setTimeout(() => {
    const scrollTop = rctPageContent[0].parentNode.scrollTop;
    const header = document.getElementById(headerID);
    if (header) {
      header.style = 'top: ' + scrollTop + 'px';
    }
  }, 250);
};

export const movePricesTableScroll = (headerID) => {
  const productEditRightScroll = document.getElementById('product-edit-right-scroll');
  setTimeout(() => {
    const scrollTop = productEditRightScroll ? productEditRightScroll.scrollTop + 10 : 10;
    const header = document.getElementById(headerID);
    if (header) {
      header.style = 'top: ' + scrollTop + 'px';
    }
  }, 250);
};
