/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { uniq, isEmpty } from 'lodash';
import { camelize } from 'humps';
import {
  string, shape, func, bool,
} from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import { fetchAttributes } from '../../../../actions/system';

import { fetchInitialAttributeOptions } from '../../../../actions/productAttribute';
import ProductImageViewListDnD from '../../components/ProductImageViewListDnD';
import getGridColumns from '../../../../util/getGridColumns';
import ParentContainer from '../ParentContainer';

import {
  pimSelector,
} from './selectors/pimSelector';
import {
  productSelector,
} from './selectors/productSelector';

const ProductContainer = memo((props) => {
  const {
    wrapperClassName, attributes,
    attributesFetched, pimProps, productProps, fromRules, pimOptions,
  } = props;

  const pimAttributes = attributes[pimProps.filterTarget] || [];

  useEffect(() => {
    if (!attributesFetched) props.fetchAttributes();
  }, []);

  useEffect(() => {
    if (pimProps.fetchedList) {
      const codes = pimAttributes
        .filter(attr => attr.isProductAttribute)
        .map(attr => attr.value)
        .filter(attr => attr);
      const options = [];

      pimProps.productsList.forEach((p) => {
        for (let i = 0; i < codes.length; i++) {
          if (p.specifications && p.specifications[camelize(codes[i])] && p.specifications[camelize(codes[i])]) {
            options.push(p.specifications[camelize(codes[i])]);
          }
        }
      });

      const codesPayload = uniq(codes.flat());
      const optionsPayload = uniq(options.flat()).filter(o => o);

      if (!isEmpty(codesPayload) && !isEmpty(optionsPayload)) {
        props.fetchInitialAttributeOptions(codesPayload, optionsPayload);
      }
    }
  }, [pimProps.fetchedList]);

  const columns = getGridColumns(pimProps.displayMode);

  const isLoading = (
    productProps.updating
    || productProps.bulkUpdateActivityLogsStart
    || pimProps.fetching
    || pimProps.updatingMultiple
  );

  if (!pimProps.globalFields[pimProps.filterTarget]) {
    pimProps.globalFields[pimProps.filterTarget] = [];
  }

  if (!pimProps.savedFilterFields[pimProps.filterTarget]) {
    pimProps.savedFilterFields[pimProps.filterTarget] = [];
  }

  const searchFields = [
    ...pimProps.globalFields[pimProps.filterTarget],
    ...pimProps.savedFilterFields[pimProps.filterTarget],
  ];

  const RenderProductDnD = () => {
    switch (pimProps.displayMode) {
      case 'list':
        return (
          <ParentContainer
            fromRules={fromRules}
            pimOptions={pimOptions}
            grid={columns}
          />
        );
      case 'image_view':
        return (
          <ProductImageViewListDnD
            grid={columns}
            disabled={!pimProps.isEditMode}
            items={pimProps.productsList}
            isEditMode={pimProps.isEditMode}
            pagination={pimProps.pagination}
            searchFields={searchFields}
          />
        );

      default:
        return null;
    }
  };

  const className = pimProps.isEditMode ? 'editable' : '';
  const customFieldsClassName = pimProps.isCustomFieldsMode ? 'custom-fields' : '';

  return (
    <div className={`product-list-wrapper full-height table-header-static table-header-static-pim ${className} ${wrapperClassName}`}>
      <div
        id="scrollContainer"
        className={`flex direction-column dnd-wrapper product list product-list ${customFieldsClassName}`}
        style={pimProps.containerStyles}
      >
        {isLoading && (
          <div className="loader-cover">
            <CircularProgress
              variant="indeterminate"
              disableShrink
              className="progress-warning mr-30 mb-10 loader bottom cover"
              size={30}
              thickness={4}
            />
          </div>
        )}
        {RenderProductDnD()}
      </div>
    </div>
  );
});

ProductContainer.propTypes = {
  pimProps: shape().isRequired,
  productProps: shape().isRequired,
  attributes: shape(),
  fetchAttributes: func.isRequired,
  wrapperClassName: string,
  fetchInitialAttributeOptions: func.isRequired,
  attributesFetched: bool.isRequired,
};

ProductContainer.defaultProps = {
  wrapperClassName: '',
  attributes: {},
};

const mapStateToProps = state => ({
  attributes: state.system.modes.pim,
  attributesFetched: state.system.fetched,
  pimProps: pimSelector(state.pim),
  productProps: productSelector(state.product),
});

const mapDispatchToProps = {
  fetchAttributes,
  fetchInitialAttributeOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
