export const ascDescOptions = {
  asc: {
    value: 'ASC',
    label: 'ASC',
  },
  desc: {
    value: 'DESC',
    label: 'DESC',
  },
};

export const rankingRule = 'ranking_rule';

export const stockStatusList = [
  {
    label: 'In stock',
    value: 'in_stock',
  }, {
    label: 'Out of stock',
    value: 'out_of_stock',
  }, {
    label: 'Coming soon',
    value: 'coming_soon',
  }, {
    label: 'Discontinued',
    value: 'discontinued',
  },
];

export default {
  ascDescOptions,
  rankingRule,
  stockStatusList,
};
