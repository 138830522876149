import { isEmpty } from 'lodash';

export const textFieldsValidations = {
  storeName: [{
    type: 'isRequired',
    message: 'influencer.errors.cantBeBlank',
  }, {
    type: 'minSize',
    message: 'text.errors.moreThanThreeCharacters',
    condition: 3,
  }, {
    type: 'maxSize',
    message: 'text.errors.lessThan200Characters',
    condition: 50,
  }],
  name: [{
    type: 'isRequired',
    message: 'influencer.errors.cantBeBlank',
  }, {
    type: 'minSize',
    message: 'text.errors.moreThanThreeCharacters',
    condition: 3,
  }, {
    type: 'maxSize',
    message: 'text.errors.lessThan200Characters',
    condition: 50,
  }],
  dealName: [{
    type: 'isRequired',
    message: 'influencer.errors.cantBeBlank',
  }, {
    type: 'minSize',
    message: 'text.errors.moreThanThreeCharacters',
    condition: 3,
  }, {
    type: 'maxSize',
    message: 'text.errors.lessThan200Characters',
    condition: 50,
  }],
};

export const validateFilters = (filters) => {
  const result = filters.map((item) => {
    const payload = item;

    if (isEmpty(item.field) || isEmpty(item.value)) {
      payload.errors = {
        field: isEmpty(item.field),
        value: isEmpty(item.value),
      };
    } else if (payload.errors) {
      delete payload.errors;
    }

    return payload;
  });
  return result;
};

export default {
  textFieldsValidations,
  validateFilters,
};
