import React, { memo } from 'react';
import {
  shape,
} from 'prop-types';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import IntlMessages from '../../../../util/IntlMessages';

const ProductItem = memo((props) => {
  const { productItem } = props;

  return (
    <div className="products-list-item flex flex-wrap">
      {productItem && (
        <Grid key={productItem.id} item>
          <div className="product-item relative">
            <Card className="product-item-card">
              <CardMedia
                className="product-item-image"
                image={productItem.images && productItem.images[0]
                  ? productItem.images[0].url
                  : productItem.defaultImage
                }
                title=""
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="div">
                  {
                    productItem.fields.map(field => (
                      <div className="flex pr-10 pl-10 product-item-data" key={field.id}>
                        <span className="pr-5 key">{field.label}</span>
                        <span className="pl-5 value">{field.value ? `${String(field.value).slice(0, 50)}...` : '-'}</span>
                      </div>
                    ))
                  }
                  {productItem.status === 'no_sellers' && (
                    <div className="pr-10 pl-10">
                      <IntlMessages id="preview.productStatus.noSellers" />
                    </div>
                  )}
                  {/* {productItem.price && productItem.price.finalPrice && (
                    <div className="flex pr-10 pl-10 product-item-data">
                      <span className="pr-5 key">Price</span>
                      <span className="pl-5 value">{`$${productItem.price.finalPrice}`}</span>
                    </div>
                  )} */}
                </Typography>
              </CardContent>
            </Card>
          </div>
        </Grid>
      )}
    </div>
  );
});

ProductItem.propTypes = {
  productItem: shape().isRequired,
};

ProductItem.defaultProps = {};

export default ProductItem;
