import axios from 'axios';
import RestActions from '../util/rest/actions';

import { currencyExchangeApiPath } from '../util/paths';
import { showErrorNotification } from '../util/api';
import firebase from 'firebase/app';

const restCurrencyActions = new RestActions('currency');

export const getCurrency = ({ symbols = 'ILS' }) => async (dispatch) => {

  const currentLocation = window.location;
  if (currentLocation.pathname.toString() === '/'
      || currentLocation.href.toString().indexOf('categories') !== -1
      || currentLocation.href.toString().indexOf('pim') !== -1
      || currentLocation.href.toString().indexOf('repricing') !== -1
      || currentLocation.href.toString().indexOf('repricer') !== -1
  ) {
    dispatch(restCurrencyActions.fetchStart());

    const msInHour = 3600000;
    const token = localStorage.getItem('fb_token');

    const currency = await firebase.database().ref('/modes/currency').once('value');
    const currencyTimeCache = currency.val().cache;

    const timeInMs = Date.now();
    if (parseInt(timeInMs) - parseInt(currency.val().time) > currencyTimeCache * msInHour) {
      try {
        const resp = await axios.get(currencyExchangeApiPath, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const rates = resp.data && resp.data.data && resp.data.data.exchange_rates;
        const ratesData = rates && rates.find(el => el.currency_to.toLowerCase() === symbols.toLowerCase());

        await firebase.database().ref('/modes/currency').update({
          rate: ratesData.rate,
          time: timeInMs,
        });

        dispatch(restCurrencyActions.fetchFinished({
          item: {
            symbols,
            value: ratesData.rate,
          },
        }));

      } catch (error) {
        showErrorNotification(error, 'Magento');
        dispatch(restCurrencyActions.fetchFinished({
          item: {},
        }));
      }
    }

    dispatch(restCurrencyActions.fetchFinished({
      item: {
        symbols,
        value: currency.val().rate,
      },
    }));
  }

};

export default {
  getCurrency,
};
