import React, { useState, useEffect } from 'react';
import {
  func, arrayOf, shape, string, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines } from '@fortawesome/free-solid-svg-icons';
import { IconButton, Fab } from '@material-ui/core';
import { Remove, BookmarkBorder, Bookmark } from '@material-ui/icons';

const DragHandle = sortableHandle(() => <div className="drag-handler"><FontAwesomeIcon size="lg" icon={faGripLines} /></div>);

const SortableItem = sortableElement(({
  value, onClick, isEditMode, onDelete,
}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!value.selected) onClick(value);
  };

  const handleDelete = () => {
    onDelete(value);
  };

  const temporaryDisabled = true;

  return (
    <li className="sortable-image-list-item">
      {isEditMode && (
        <>
          {!temporaryDisabled && <DragHandle />}
          <div className="sortable-image-list-item-actions">
            <IconButton
              size="small"
              aria-label="set as main"
              onClick={handleClick}
              disabled={temporaryDisabled}
            >
              {
                value.selected
                  ? <Bookmark color="primary" fontSize="small" />
                  : <BookmarkBorder fontSize="small" />
              }
            </IconButton>
          </div>
          <div className="sortable-image-list-item-actions bottom right">
            <Fab
              className="icon-xs"
              size="small"
              color="primary"
              aria-label="delete image"
              onClick={handleDelete}
              disabled={temporaryDisabled}
            >
              <Remove />
            </Fab>
          </div>
        </>
      )}
      <div className="sortable-image-list-item-value">
        <img
          className="image-sm"
          src={value.url}
          alt={!isEmpty(value.alt) ? value.alt.en || value.alt.he : new Date().getUTCMilliseconds()}
        />
      </div>
    </li>
  );
});

const SortableContainer = sortableContainer(({ children }) => <ul className="sortable-image-list list">{children}</ul>);

const mappedList = array => ([...array.map((l, index) => {
  if (index === 0) {
    return ({ ...l, selected: true });
  } return ({ ...l, selected: false });
})]);

const mappedProductImegaes = list => list.map(({ alt, url, publicId }) => ({ alt, url, publicId }));

const SortableImage = (props) => {
  const [list, setList] = useState([]);
  useEffect(() => {
    setList(mappedList(props.list));
  }, [props.list]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedList = arrayMove(list, oldIndex, newIndex);
    setList(mappedList(updatedList));
    props.onSortEnd(mappedProductImegaes(updatedList));
  };

  const handleDeleteClick = (itemToRemove) => {
    const filteredList = list.filter(item => item.publicId !== itemToRemove.publicId);
    setList(mappedList(filteredList));
    props.onSortEnd(mappedProductImegaes(filteredList));
  };

  const handleSortableItemClick = (selectedItem) => {
    const updatedList = [
      ...list
        .filter(ul => ul.publicId !== selectedItem.publicId)
        .map(ul => ({ ...ul, selected: false })),
    ];
    updatedList.unshift({ ...selectedItem, selected: true });
    setList(updatedList);
    props.onSortEnd(mappedProductImegaes(updatedList));
  };

  const temporaryDisabled = true;

  return (
    <SortableContainer
      axis="x"
      lockAxis="x"
      useDragHandle
      onSortEnd={onSortEnd}
      disabled={temporaryDisabled}
    >
      {list.map((item, index) => (
        <SortableItem
          key={`item-${item.publicId}`}
          index={index}
          value={item}
          onClick={handleSortableItemClick}
          onDelete={handleDeleteClick}
          isEditMode={props.isEditMode}
        />
      ))}
    </SortableContainer>
  );
};

SortableImage.propTypes = {
  list: arrayOf(shape({
    alt: shape({
      en: string,
    }),
    url: string,
    publicId: string,
  })),
  onSortEnd: func,
  isEditMode: bool,
};

SortableImage.defaultProps = {
  list: [{
    alt: { en: '' },
    url: '',
    publicId: '',
  }],
  onSortEnd: null,
  isEditMode: false,
};

export default SortableImage;
