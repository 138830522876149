import { setIsLoading } from './global';
import { camelizeKeys } from 'humps';
import { PMACClient, showErrorNotification } from '../util/api';
import { CDMSClient } from '../util/api';
import { cdmsGetProducts } from './product.js';

import {
  SET_REPRICER_DATA,
} from './types';

import { downLoadFile, getResultCondition } from "./pim";

const ref = typeof window !== 'undefined' ? localStorage.getItem('user_id') : '';
export const repricingResult = condition => CDMSClient.post('/repricing_log/search?ref=' + ref, condition);
export const repricingExport = condition => CDMSClient.post('/repricing_log/export', condition);
export const getRepricerDataRes = (data) => CDMSClient.post('/repricing_data/search?ref=' + ref, data);
export const getCompetitorsRes = (data) => PMACClient.post('/competitors/search', data);

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
}

export const getRepricerData = ({ limit, offset, filter, fullText, sort, reset }) => async (dispatch) => {
  dispatch(setIsLoading(true));
  dispatch({
    type: SET_REPRICER_DATA,
    payload: {
      repricer: {
        itemsPerPage: limit,
        error: {},
        reset: reset
      }
    }
  });

  try {
    if (document.location.href.indexOf('issues') !== -1) {
      if (!filter) {
        filter = [];
      }
      filter.push({ condition: 'or', group: [
        { field: 'stock_status', operator: 'eq', value: 'out_of_stock' },
        { field: 'visibility_status', operator: 'eq', value: 'hidden_by_repricing' },
        { field: 'is_on_feeds.zap', operator: 'eq', value: true },
        { field: 'is_on_feeds.ksp', operator: 'eq', value: true }
      ] });
    }

    const condition = getResultCondition(filter, sort, limit, offset / limit, true, true);
    if (fullText) {
      condition.full_text = fullText;
    }
    const res = await getRepricerDataRes(condition);

    const competitorIds = [];
    const variationsIds = [];
    for (let i = 0; i < res.data.data.length; i++) {
      if (res.data.data[i].competitors.zmid && res.data.data[i].competitors.zmid[0]) {
        competitorIds.push(parseInt(res.data.data[i].competitors.zmid[0]));
      }
      if (res.data.data[i].competitors.kmid && res.data.data[i].competitors.kmid[0]) {
        competitorIds.push(parseInt(res.data.data[i].competitors.kmid[0]));
      }
      variationsIds.push(res.data.data[i].id);
    }

    if (competitorIds.length === 0) {
      dispatch({
        type: SET_REPRICER_DATA,
        payload: {
          repricer: {
            data: [], scroll: [], loading: false, reset: false
          }
        }
      });
      dispatch(setIsLoading(false));

      return false;
    }

    if (variationsIds.length !== 0) {
      try {
        const variationsFilter = [
          { group: [ { field: 'id', value: variationsIds.filter(onlyUnique), operator: 'in' } ] }
        ];
        const variationFields = ['id', 'parent_id', 'images', 'stock', 'visibility_status'];
        const variationsRes = await cdmsGetProducts({filter: variationsFilter, fields: variationFields});

        try {
          const competitorFilter = [
            { group: [ { field: 'model_id', value: competitorIds.filter(onlyUnique), operator: 'in' } ] },
            { condition: 'or',
              group: [
                { field: 'competitor_source', value: 'zap', operator: 'eq' },
                { field: 'competitor_source', value: 'ksp', operator: 'eq' }
              ] }
          ];

          const competitorScroll = { limit: competitorIds.length, offset: 0 };
          const competitorRes = await getCompetitorsRes({filter: competitorFilter, scroll: competitorScroll});

          const competitorsData = [];
          if (competitorRes.data && variationsRes.data) {
            const competitorsKeys = Object.keys(competitorRes.data.data);
            for (let i = 0; i < competitorsKeys.length; i++) {
              competitorsData[i] = competitorRes.data.data[competitorsKeys[i]];
            }
          }

          const variationsKeys = Object.keys(variationsRes.data.data);

          const data = [];
          for (let i = 0; i < res.data.data.length; i++) {
            data[i] = res.data.data[i];
            for (let j = 0; j < competitorsData.length; j++) {
              const stores = competitorsData[j].stores;
              if (stores && stores.length > 5) {
                for (let k = 0; k < stores.length; k++) {
                  if (k > 4 && stores[k].store_name === 'American Outlets') {
                    stores[4] = stores[k];
                  }
                }
              }
              competitorsData[j].stores = stores;

              if (res.data.data[i].competitors.zmid && res.data.data[i].competitors.zmid[0] === competitorsData[j].model_id) {
                data[i].zap = competitorsData[j];
              }
              if (res.data.data[i].competitors.kmid && res.data.data[i].competitors.kmid[0] === competitorsData[j].model_id) {
                data[i].ksp = competitorsData[j];
              }
            }

            for (let j = 0; j < variationsKeys.length; j++ ) {
              if (res.data.data[i].id === variationsRes.data.data[j].id && data[i]) {
                data[i].data = variationsRes.data.data[j];
              }
            }
          }

          dispatch({
            type: SET_REPRICER_DATA,
            payload: {
              repricer: {
                data: camelizeKeys(data),
                scroll: { offset: offset, pages: res.data.pages, total: res.data.total },
                condition: condition,
                reset: false
              }
            }
          });
        } catch (error) {
          showErrorNotification(error, 'PMAC');
          dispatch({
            type: SET_REPRICER_DATA,
            payload: {
              repricer: {
                data: [], scroll: [], loading: false, reset: false
              }
            }
          });
        }
      } catch(error) {
        showErrorNotification(error, 'CDMS');
        dispatch({
          type: SET_REPRICER_DATA,
          payload: {
            repricer: {
              data: [], scroll: [], loading: false, reset: false
            }
          }
        });
      }
    }
  } catch (error) {
    showErrorNotification(error, 'CDMS');
    dispatch({
      type: SET_REPRICER_DATA,
      payload: {
        repricer: {
          data: [], scroll: [], loading: false, reset: false
        }
      }
    });
  }
  dispatch(setIsLoading(false));
};

export const getRepricingData = (
  { limit, offset, filter = null, sort = null, fields, reset = false}
) => async (dispatch) => {
  dispatch(setIsLoading(true));
  dispatch({
    type: SET_REPRICER_DATA,
    payload: {
      repricing: {
        itemsPerPage: limit,
        error: {},
        reset: reset
      }
    }
  });

  try {
    const condition = getResultCondition(filter, sort, limit, offset / limit, true);
    if (fields) {
      condition['fields'] = fields;
    }

    const res = await repricingResult(condition);
    const { data } = res.data;

    dispatch({
      type: SET_REPRICER_DATA,
      payload: {
        repricing: {
          data: data,
          scroll: { offset: offset ? offset : 0, pages: res.data.pages, total: res.data.total },
          condition: condition,
          reset: false
        }
      }
    });

  } catch (error) {
    showErrorNotification(error, 'CDMS');

    dispatch({
      type: SET_REPRICER_DATA,
      payload: {
        repricing: {
          data: [], scroll: [], reset: false
        }
      }
    });
  }
  dispatch(setIsLoading(false));
};

export const exportRepricingData = (props) => async () => {
  try {
    const condition = getResultCondition(props.filter, props.sort);

    const res = await repricingExport(condition);
    downLoadFile(res.data, 'export.csv');
  } catch (error) {
    showErrorNotification(error, 'CDMS');
  }
};
