export const commentValidations = {
  comment: [{
    type: 'isRequired',
    message: 'preview.actionContextMenu.commentIsRequired',
  }],
};

export const systemCommentValidations = {
  systemComment: [{
    type: 'isRequired',
    message: 'preview.actionContextMenu.systemCommentIsRequired',
  }],
};

export default {
  commentValidations,
  systemCommentValidations,
};
