import React from 'react';
import { func } from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const ContextMenuContent = (props) => {
  const { onDuplicateNavigationClick } = props;

  return (
    <div className="context-backdrop">
      <ListItem button onClick={onDuplicateNavigationClick}>
        <ListItemIcon>
          <i className="zmdi zmdi-copy zmdi-hc-lg" />
        </ListItemIcon>
        <ListItemText primary="Duplicate navigation" />
      </ListItem>
    </div>
  );
};

ContextMenuContent.propTypes = {
  onDuplicateNavigationClick: func.isRequired,
};

export default ContextMenuContent;
