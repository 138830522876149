import React, { useState, useEffect, memo } from 'react';
import {
  string, shape, arrayOf, bool, func,
} from 'prop-types';
import arrayMove from 'array-move';
import { SortableContainer } from 'react-sortable-hoc';

import SortableList from './SortableList';

const HocSortableList = SortableContainer(props => <SortableList {...props} />);

const VariantLevelList = memo((props) => {
  const {
    variants, certainProductAttributes, productsCommentsCount,
    defaultImage, isEditMode, sortedAttributes,
    setUpdatedProductsImagesOverrides, productsImagesOverride, parent,
    injectProducts, disabled,
  } = props;
  const [localItems, setItems] = useState(variants);

  useEffect(
    () => setItems(variants),
    [variants],
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = arrayMove(localItems, oldIndex, newIndex);
    setItems(newItems);
  };

  return (
    <HocSortableList
      axis="y"
      useDragHandle
      productList={localItems}
      onSortEnd={onSortEnd}
      certainProductAttributes={certainProductAttributes}
      productsCommentsCount={productsCommentsCount}
      fetchActivityLogsByProductId={props.fetchActivityLogsByProductId}
      productCommentsLoading={props.productCommentsLoading}
      productComments={props.productComments}
      defaultImage={defaultImage}
      variants={variants}
      isEditMode={isEditMode}
      sortedAttributes={sortedAttributes}
      setUpdatedProductsImagesOverrides={setUpdatedProductsImagesOverrides}
      productsImagesOverride={productsImagesOverride}
      parent={parent}
      injectProducts={injectProducts}
      disabled={disabled}
    />
  );
});

VariantLevelList.propTypes = {
  variants: arrayOf(shape()).isRequired,
  certainProductAttributes: arrayOf(shape()).isRequired,
  productsCommentsCount: arrayOf(shape()),
  productComments: arrayOf(shape()).isRequired,
  sortedAttributes: arrayOf(shape()).isRequired,
  productsImagesOverride: arrayOf(shape()).isRequired,
  parent: shape().isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  injectProducts: func.isRequired,
  defaultImage: string.isRequired,
  setUpdatedProductsImagesOverrides: func.isRequired,
  isEditMode: bool.isRequired,
  productCommentsLoading: bool.isRequired,
  disabled: bool.isRequired,
};

VariantLevelList.defaultProps = {
  productsCommentsCount: [],
};

export default VariantLevelList;
