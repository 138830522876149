import { camelizeKeys } from 'humps';
import firebase from 'firebase/app';
import RestActions from '../util/rest/actions';
import { previewFiltersAppPath } from '../util/paths';
import { showErrorNotification } from '../util/api';

import {
  SET_SELECTED_SAVED_FILTER,
  SET_SAVED_FILTERS,
} from './types';

const categoryFacetActions = new RestActions('preview_filter');
const {
  fetchAllStart,
  fetchAllFinished,
  updateMultipleStart,
  updateMultipleFinished,
  clearFromStateFinished,
} = categoryFacetActions;


export const fetchPreviewFilters = () => async (dispatch) => {
  dispatch(fetchAllStart());
  let previewFilters;
  try {
    previewFilters = await firebase.database().ref(previewFiltersAppPath).once('value');
  } catch (error) {
    showErrorNotification(error, 'Firebase');
    dispatch(fetchAllFinished({ hasError: true }));
  }
  const restPreviewFilters = previewFilters.val();
  let mappedFilters = [];
  if (restPreviewFilters && restPreviewFilters.length) {
    mappedFilters = restPreviewFilters.map(r => ({
      ...r,
      fields: r.fields || [],
      filter: r.filter || [],
      categories: r.categories || [],
      groupSearchFields: r.groupSearchFields || [],
    }));
  }

  dispatch(fetchAllFinished({ list: camelizeKeys(mappedFilters) }));
};

export const updatePreviewFilters = filters => (dispatch) => {
  dispatch(updateMultipleStart());

  return firebase.database().ref(previewFiltersAppPath).set(filters)
    .then((res) => {
      dispatch(updateMultipleFinished(res));
    })
    .catch((error) => {
      showErrorNotification(error, 'Firebase');
      dispatch(updateMultipleFinished({ error, hasError: true }));
    });
};

export const clearFromPreviewFilterState = payload => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};

export const setSelectedSavedFilter = selectedSavedFilter => (dispatch) => {
  dispatch({
    type: SET_SELECTED_SAVED_FILTER,
    payload: { selectedSavedFilter },
  });
};

export const setSavedFilters = list => (dispatch) => {
  dispatch({
    type: SET_SAVED_FILTERS,
    payload: { list },
  });
};
