import React, { memo } from 'react';
import { camelize } from 'humps';
import {
  array, shape, arrayOf, bool, func,
} from 'prop-types';
import { isEmpty } from 'lodash';
import {
  Grid, Card, CardContent, CardActions,
  Button, Tooltip,
} from '@material-ui/core';

import IntlMessages from '../../../../util/IntlMessages';
import Checkbox from '../../../../components/Checkbox';
import SortableImage from '../SortableImage';

const VariantLevelItem = memo((props) => {
  const {
    variantValue, variantProductItem, isEditMode, productComments,
    disabled,
    productsImagesOverride, hasComments, productCommentsLoading,
    certainProductAttributes, sortedAttributes, setUpdatedProductsImagesOverrides,
    injectProducts,
  } = props;
  const localAttributes = ['id', 'title.en', 'images', 'specifications.ao_brand_name'];

  const isReviewed = variantProductItem.pimStatus && variantProductItem.pimStatus.status === 'reviewed';

  const fetchComments = () => props.fetchActivityLogsByProductId(variantProductItem.id);

  const handleUnlockAttributesClick = () => {};

  const handleProductSelect = () => {
    if (productsImagesOverride.find(pio => pio.id === variantProductItem.id)) {
      const filteredList = productsImagesOverride
        .filter(pio => pio.id !== variantProductItem.id);

      setUpdatedProductsImagesOverrides(filteredList);
      injectProducts([], variantProductItem);
    } else {
      const clonnedList = [...productsImagesOverride];
      const newItem = ({
        id: variantProductItem.id,
        images: variantProductItem.images,
      });

      clonnedList.push(newItem);
      setUpdatedProductsImagesOverrides(clonnedList);
    }
  };

  const getSortableImageList = () => {
    const item = productsImagesOverride.find(pio => pio.id === variantValue.id);
    return item ? item.images : variantProductItem.images;
  };

  const handleSortEnd = (images) => {
    let updatedList = [...productsImagesOverride];

    if (!productsImagesOverride.find(p => p.id === variantProductItem.id)) {
      updatedList.push({ id: variantProductItem.id, images });
    } else {
      updatedList = [...productsImagesOverride].map((ul) => {
        if (ul.id === variantProductItem.id) return ({ id: ul.id, images });
        return ul;
      });
    }
    setUpdatedProductsImagesOverrides(updatedList);
  };

  const availableAttributes = localAttributes
    .map(a => sortedAttributes
      .find(s => s.code === a)).filter(a => a);
  const showUnlockAttributeButton = !isEmpty(variantProductItem.lockedAttributes);

  const temporaryDisabled = true;

  return (
    <Grid item xs={12}>
      <div className="product-item block flex items-center pr-20 pl-20 images-item">
        <div className="product-item-type variation">
          <span>variation</span>
        </div>
        <Card className="product-item-card-list without-dnd variant">
          <CardActions className="card-collapse-btn pr-0">
            {!disabled && (
              <div>
                <Checkbox
                  color="primary"
                  disabled={variantProductItem.images.length < 2 || temporaryDisabled}
                  checked={productsImagesOverride.some(p => p.id === variantValue.id)}
                  onChange={handleProductSelect}
                  name={variantValue.title
                    ? camelize(variantValue.title)
                    : ''
                  }
                />
              </div>
            )}
          </CardActions>
          <CardContent className="product-content 222 flex full-width ml-0 mr-0">
            <div className="flex full-width list-items direction-column justify-center">
              {availableAttributes.map((attr) => {
                const cellXlClassName = attr.code === 'id' ? 'cell-xl' : '';
                const currentAttribute = certainProductAttributes
                  .find(el => el.code === attr.value) || {};

                let itemValue = typeof variantValue[camelize(attr.value)] === 'string'
                  ? variantValue[camelize(attr.value)]
                  : false;

                if (camelize(attr.value) === 'aoBrandName') {
                  itemValue = variantValue.brand;
                }

                return (
                  <div
                    className={`flex direction-column product-item-data relative ${cellXlClassName}`}
                    key={attr.key}
                  >
                    {attr.label !== 'Images' && (
                      <div className="value relative">
                        <div className="product-cell product-cell-inline">
                          <div className="product-cell-label">{(currentAttribute.label ? currentAttribute.label.en : '') || attr.label}</div>
                          {itemValue && <div className="product-cell-value">{itemValue}</div>}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex edit-icon-wrapper items-center mr-3">
              {showUnlockAttributeButton && (
                <div className="lock-icon">
                  <Button
                    color="primary"
                    className="btn-xs extra float-right"
                    onClick={handleUnlockAttributesClick}
                    disabled
                  >
                    <IntlMessages id="pim.table.product.button.unlockAttributes" />
                  </Button>
                </div>
              )}
              {isReviewed && (
                <Tooltip
                  title={`by ${variantProductItem.pimStatus.reviewedBy}`}
                  placement="top"
                >
                  <span className="badge-success badge-xs badge-rel badge-text secondary">
                    <IntlMessages id="pim.table.product.badge.reviewed.label" />
                  </span>
                </Tooltip>
              )}
              {
                hasComments && (
                  <Tooltip
                    onClick={fetchComments}
                    title={(
                      <div className="comments">
                        {
                          productCommentsLoading
                            ? <IntlMessages id="pim.list.productComments.loading" />
                            : productComments.map((pc, index) => (
                              <div key={pc.id}>
                                {`${index + 1}. ${pc.comment}`}
                              </div>
                            ))
                        }
                      </div>
                    )}
                    placement="right"
                  >
                    <i className="ti-comment-alt icon-premium icon-xs" />
                  </Tooltip>
                )}
            </div>
          </CardContent>
          <SortableImage
            list={getSortableImageList()}
            onSortEnd={handleSortEnd}
            isEditMode={isEditMode}
          />
        </Card>
      </div>
    </Grid>
  );
});

VariantLevelItem.propTypes = {
  disabled: bool,
  variantValue: shape().isRequired,
  productComments: arrayOf(shape({})).isRequired,
  productsImagesOverride: arrayOf(shape()).isRequired,
  certainProductAttributes: arrayOf(shape()).isRequired,
  sortedAttributes: arrayOf(shape()).isRequired,
  variantProductItem: shape({
    variants: array,
    childrenIds: array,
  }).isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  setUpdatedProductsImagesOverrides: func.isRequired,
  injectProducts: func.isRequired,
  isEditMode: bool.isRequired,
  hasComments: bool.isRequired,
  productCommentsLoading: bool.isRequired,
};

VariantLevelItem.defaultProps = {
  disabled: false,
};

export default VariantLevelItem;
