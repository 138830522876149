import React, { useState, useEffect, useReducer } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import IntlMessages from '../../../../util/IntlMessages';
import { getRulesData, saveRulesData, getRulesForm, replaceVariationFilters } from '../../../../actions/rules';
import { toggleSelectedProduct } from '../../../../actions/pim';

import {
  getCategoriesAutocomplete,
  getBrandsAutocomplete,
  getMerchantsAutocomplete } from '../../../../actions/autocomplete';
import {
  Button,
  TextareaAutosize,
  Checkbox,
  OutlinedInput,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import Select from 'react-select';
import AddProductsDialog from "../AddProductsDialog";
import moment from "moment";

export const Form = (props) => {
  const [informationShow, setInformationShow] = useState(true);
  const [conditionShow, setConditionShow] = useState(false);
  const [actionShow, setActionShow] = useState(false);
  const [rulesFields, setRulesFields] = useState({});
  const [conditions, setConditions] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [autocompleteSearchWord, setAutocompleteSearchWord] = useState('');
  const [errors, setErrors] = useState({});
  const [foundOptions, setFoundOptions] = useState([]);
  const [isOpenedAddProductsDialog, setIsOpenedAddProductsDialog] = useState(false);

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const rulesConditions = props.rulesConditions;
  const rulesConditionsOptions = props.rulesConditionsOptions;
  const actionOptions = props.actionOptions;

  useEffect(() => {
    if (props.foundOptions) {
      setFoundOptions(props.foundOptions);
    }
    if (props.rule !== null) {
      rulesFields['id'] = props.rule.id ? props.rule.id : '';
      rulesFields['name'] = props.rule.name ? props.rule.name : '';
      rulesFields['description'] = props.rule.description ? props.rule.description : '';
      rulesFields['active'] = props.rule.status && props.rule.status === 'enabled';
      rulesFields['priority'] = props.rule.priority ? props.rule.priority : '';
      rulesFields['from'] = props.rule.active_from
        ? props.rule.active_from.replace(' ', 'T')
        : '';
      rulesFields['to'] = props.rule.active_to
        ? props.rule.active_to.replace(' ', 'T')
        : '';
      rulesFields['action'] = props.rule.action && props.rule.action.action ? props.rule.action.action : '';
      rulesFields['amount'] = props.rule.action && props.rule.action.amount ? props.rule.action.amount : '';
      rulesFields['discardSubsequentRules'] = props.rule.discard_subsequent_rules
        ? props.rule.discard_subsequent_rules
        : 0;
      setRulesFields(rulesFields);

      const conds = [];
      if (props.rule.condition && firstLoad === false) {
        setFirstLoad(true);
        for (let i = 0; i < props.rule.condition.length; i++) {
          for (let j = 0; j < rulesConditions.length; j++) {
            if (rulesConditions[j].value === props.rule.condition[i].name) {
              const operators = rulesConditions[j].operators ? rulesConditions[j].operators : [];
              const operator = rulesConditions[j].operator ? rulesConditions[j].operator : [];
              const options = rulesConditions[j].options ? rulesConditions[j].options : [];

              let propsValue = props.rule.condition[i].value;
              let propsLabel = props.rule.condition[i].label;
              if (rulesConditions[j].type === 'products' || rulesConditions[j].type === 'multiselect') {
                propsValue = !Array.isArray(propsValue) ? propsValue.toString().split(',') : propsValue;
                propsLabel = !Array.isArray(propsValue) ? propsLabel.toString().split(',') : propsLabel;
              }
              if (rulesConditions[j].type === 'products') {
                propsLabel = propsValue;
              }
              if (rulesConditions[j].type === 'range') {
                propsValue = [propsValue.from, propsValue.to];
                propsLabel = ['From', 'To'];
              }
              if (!propsLabel) {
                propsLabel = getOptionLabel(options, propsValue);
              }

              conds[i] = {
                field: {
                  label: rulesConditions[j].label,
                  operators: operators,
                  options: options,
                  operator: operator,
                  type: rulesConditions[j].type,
                  value: rulesConditions[j].value,
                },
                operator: {
                  value: props.rule.condition[i].operator,
                  label: getOperatorLabel(operators, operator, props.rule.condition[i].operator),
                },
                value: propsValue,
                label: propsLabel,
              }
            }
          }
        }
        setConditions(conds);
      }
    }
  }, [
    props.foundOptions, props.rule, rulesFields, rulesConditions, conditions, firstLoad,
  ]);

  const transformDateToMaterialFormat = date => moment(date).format('YYYY-MM-DDTHH:mm');

  const getOperatorLabel = (operators, operator, operatorValue) => {
    if (operator.label) {
      return operator.label;
    }

    for (let i = 0; i < operators.length; i++) {
      if (operators[i].value === operatorValue) {
        return operators[i].label;
      }
    }

    return operatorValue;
  }

  const getOptionLabel = (options, value) => {
    const label = [];
    if (Array.isArray(value) === true) {
      for (let i = 0; i < options.length; i++) {
        for (let j = 0; j < value.length; j++) {
          if (options[i].value && options[i].value === value[j]) {
            label[j] = options[i].label;
          }
        }
      }

      return label;
    }

    for (let i = 0; i < options.length; i++) {
      if (options[i].value && options[i].value === value) {
        return options[i].label;
      }
    }

    return value;
  };

  const saveForm = (e) => {
    const errors = [];
    if (!rulesFields.name) {
      errors['name'] = 'system.errors.cantBeBlank';
    }
    if (!rulesFields.action) {
      errors['action'] = 'system.errors.cantBeBlank';
    }
    if (!rulesFields.amount) {
      errors['amount'] = 'system.errors.cantBeBlank';
    }

    const savingCondition = [];
    for (let i = 0; i < conditions.length; i++) {
      savingCondition[i] = {
        name: conditions[i].field.value,
        value: conditions[i].value.toString(),
      }
      if (conditions[i].field.operators) {
        savingCondition[i].operator = conditions[i].operator.value;
      }
    }
    if (savingCondition.length === 0) {
      errors['condition'] = 'system.errors.conditionCantBeBlank';
    }

    setErrors(errors);
    forceUpdate();

    const formatSavingCondition = [];
    for (let i = 0; i < savingCondition.length; i++) {
      if (savingCondition[i].name === 'price_range' || savingCondition[i].name === 'profit_range') {
        const val = savingCondition[i].value.toString().split(',');
        formatSavingCondition[i] = {
          name: savingCondition[i].name,
          value: { from: parseFloat(val[0]), to: parseFloat(val[1]) },
          operator: savingCondition[i].operator,
        };
      }
      else if (
        savingCondition[i].name === 'mapped_in'
        || savingCondition[i].operator === 'in'
        || savingCondition[i].operator === 'nin'
      ) {
        formatSavingCondition[i] = {
          name: savingCondition[i].name,
          value: savingCondition[i].value.toString().split(','),
          operator: savingCondition[i].operator,
        };
      } else if (savingCondition[i].name === 'created_before') {
        formatSavingCondition[i] = {
          name: savingCondition[i].name,
          value: savingCondition[i].value.toString().toString().substr(0,10),
          operator: savingCondition[i].operator,
        };
      } else if (savingCondition[i].name === 'quantity') {
        formatSavingCondition[i] = {
          name: savingCondition[i].name,
          value: parseInt(savingCondition[i].value),
          operator: savingCondition[i].operator,
        };
      } else {
        formatSavingCondition[i] = savingCondition[i];
      }
    }

    if (Object.keys(errors).length === 0) {
      const savingData = {
        data: {
          name: rulesFields.name,
          status: rulesFields.active ? 'enabled' : 'disabled',
          priority: rulesFields.priority ? parseFloat(rulesFields.priority) : 0,
          action: {
            action: rulesFields.action,
            amount: parseFloat(rulesFields.amount),
          },
          condition: formatSavingCondition,
          discard_subsequent_rules : !!rulesFields.discardSubsequentRules,
        }
      };

      if (!isEmpty(rulesFields.from)) {
        savingData.data['active_from'] = rulesFields.from.toString().substr(0,10);
      }
      if (!isEmpty(rulesFields.to)) {
        savingData.data['active_to'] = rulesFields.to.toString().substr(0,10);
      }
      if (!isEmpty(rulesFields.description)) {
        savingData.data['description'] = rulesFields.description;
      }

      props.saveRulesData({
        savingData,
        cond: props.condition,
        scroll: props.scroll,
        limit: props.itemsPerPage,
        id: rulesFields.id ? rulesFields.id : null
      }).then(() => {
        setRulesFields({});
        setConditions([]);
        setFirstLoad(false);
        props.getRulesData({limit: props.itemsPerPage, offset: 0});
        replaceVariationFilters('pim');
      });
    }
  };

  const cancelForm = (e) => {
    setErrors({});
    setRulesFields({});
    setConditions([]);
    setFirstLoad(false);

    props.getRulesForm({open: false}).then(() => {
      props.getRulesData({limit: props.itemsPerPage, offset: 0});
      replaceVariationFilters('pim');
    });
  };

  const handleRulesTabs = (e, type, state) => {
    switch (type) {
      case 'information':
        setInformationShow(state);
        setConditionShow(false);
        setActionShow(false);
        break;
      case 'condition':
        setConditionShow(state);
        setInformationShow(false);
        setActionShow(false);
        break;
      case 'action':
        setActionShow(state);
        setConditionShow(false);
        setInformationShow(false);
        break;
      default:
        break;
    }
  }

  const handleCloseAddProductsDialog = () => {
    setIsOpenedAddProductsDialog(false);
  };

  const handleOpenAddProductsDialog = () => {
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].field.value === 'product_ids') {
        if (conditions[i].value) {
          for (let j = 0; j < conditions[i].value.length; j++) {
            const productItem = {id: conditions[i].value[j]};
            props.toggleSelectedProduct(productItem);
          }
        }
      }
    }

    setIsOpenedAddProductsDialog(true);
  }

  const handleRulesFieldChange = (e, name) => {
    const { value } = e.target;
    rulesFields[name] = value;
    setRulesFields(rulesFields);
  }

  const handleRulesSelectChange = (e, name, isMulti = false) => {
    if (isMulti === false) {
      rulesFields[name] = e.value ? e.value : null;
    } else {
      const value = [];
      for (let i = 0; i < e.length; i++) {
        value[i] = e[i].value;
      }
      rulesFields[name] = value;
    }
    setRulesFields(rulesFields);
  };

  const handleRulesCheckboxChange = (name) => {
    rulesFields[name] = !rulesFields[name];
    setRulesFields(rulesFields);
  }

  const handleAddNewCondition = (e) => {
    let isConditionAdded = false;
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].field.value === e.value) {
        isConditionAdded = true;
      }
    }

    if (isConditionAdded === false) {
      for (let i = 0; i < rulesConditions.length; i++) {
        if (rulesConditions[i].value === e.value) {
          conditions[conditions.length] = {
            field: rulesConditions[i],
            operator: rulesConditions[i].operator ? rulesConditions[i].operator : null,
          };
          setConditions(conditions);
          setFoundOptions([]);
          setAutocompleteSearchWord('');
        }
      }
    }

    forceUpdate();
  }

  const handleRemoveCondition = (e, fieldValue) => {
    const newConditions = [];
    let j = 0;
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].field.value !== fieldValue) {
        newConditions[j] = conditions[i];
        j++;
      }
    }

    setConditions(newConditions);
    forceUpdate();
  }

  const handleSelectConditionOperator = (e, fieldValue) => {
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].field.value === fieldValue) {
        conditions[i]['operator'] = e;
      }
    }

    setConditions(conditions);
    forceUpdate();
  }

  const handleRemoveConditionOperator = (e, fieldValue) => {
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].field.value === fieldValue) {
        delete conditions[i]['operator'];
      }
    }

    setConditions(conditions);
    forceUpdate();
  }

  const handleEditConditionValue = (e, fieldValue, type) => {
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].field.value === fieldValue
        && type !== 'products'
        && type !== 'multiselect'
        && type !== 'autocomplete'
      ) {
        conditions[i]['mode'] = 'edit';
      }
    }

    setConditions(conditions);
    forceUpdate();
  }

  const handleShowMultiSelect = (e, fieldValue) => {
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].field.value === fieldValue) {
        if (!conditions[i]['mode']) {
          conditions[i]['mode'] = 'edit';
        } else {
          delete conditions[i]['mode'];
        }
      }
    }

    setConditions(conditions);
    forceUpdate();
  }

  const removeEditModes = (e) => {
    const className = e.target.className;
    if (
        className === 'rules-form'
        || className === 'clear-both'
        || className === 'rules-new-record-header'
        || className === 'rules-new-record-header-left'
        || className === 'MuiTableCell-root MuiTableCell-body'
    ) {
      for (let i = 0; i < conditions.length; i++) {
        delete conditions[i]['mode'];
      }

      setConditions(conditions);
      forceUpdate();
    }
  }

  const handleChangeConditionValue = (e, fieldValue, onEnter) => {
    if (e.key === 'Enter' || onEnter === false) {
      const {value} = e.target;
      for (let i = 0; i < conditions.length; i++) {
        if (conditions[i].field.value === fieldValue) {
          conditions[i]['value'] = value;
          delete conditions[i]['mode'];
        }
      }

      setConditions(conditions);
      forceUpdate();
    }
  }

  const handleChangeConditionRangeValue = (e, index, indexValue, fieldValue, onEnter) => {
    if (e.key === 'Enter' || onEnter === false) {
      const {value} = e.target;
      for (let i = 0; i < conditions.length; i++) {
        if (conditions[i].field.value === fieldValue) {
          if (index === 0) {
            conditions[i]['value'] = [value, indexValue];
          } else {
            conditions[i]['value'] = [indexValue, value];
          }
          delete conditions[i]['mode'];
        }
      }

      setConditions(conditions);
      forceUpdate();
    }
  }

  const handleChangeConditionSelectValue = (e, fieldValue) => {
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].field.value === fieldValue) {
        conditions[i]['value'] = e.value;
        conditions[i]['label'] = e.label;
        delete conditions[i]['mode'];
      }
    }

    setConditions(conditions);
    forceUpdate();
  }

  const handleChangeConditionMultiSelectValue = (e, fieldValue) => {
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].field.value === fieldValue) {
        if (!conditions[i]['value'] && !conditions[i]['label']) {
          conditions[i]['value'] = [e.value];
          conditions[i]['label'] = [e.label];
        } else {
          let isInList = false;
          for (let j = 0; j < conditions[i]['value']; j++) {
            if (conditions[i]['value'][j] === e.value) {
              isInList = true;
            }
          }
          if (isInList === false) {
            conditions[i]['value'][conditions[i]['value'].length] = e.value;
            conditions[i]['label'][conditions[i]['label'].length] = e.label;
          }
        }
        delete conditions[i]['mode'];
      }
    }

    setConditions(conditions);
    forceUpdate();
  }

  const handleRemoveMultiSelectValue = (e, fieldValue, valueKey) => {
    const newValues = [];
    const newLabels = [];
    let k = 0;
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].field.value === fieldValue) {
        for (let j = 0; j < conditions[i]['value'].length; j++) {
          if (j !== valueKey) {
            newValues[k] = conditions[i]['value'][j];
            newLabels[k] = conditions[i]['label'][j];
            k++;
          }
        }
        if (newValues.length > 0) {
          conditions[i]['value'] = newValues;
          conditions[i]['label'] = newLabels;
        } else {
          delete(conditions[i]['value']);
          delete(conditions[i]['label']);
        }
      }
    }

    setConditions(conditions);
    forceUpdate();
  }

  const handleAddProducts = () => {
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].field.value === 'product_ids') {
        const products = [];

        for (let j = 0; j < props.selectedProducts.length; j++) {
          products.push(props.selectedProducts[j].id);
        }

        const uniqueSet = new Set(products);

        conditions[i]['label'] = [...uniqueSet];
        conditions[i]['value'] = [...uniqueSet];
        delete conditions[i]['mode'];
      }
    }

    setIsOpenedAddProductsDialog(false);
  }

  const handleChangeProductsValue = (e, fieldValue, valueKey, onEnter) => {
    if (e.key === 'Enter' || onEnter === false) {
      const {value} = e.target;

      for (let i = 0; i < conditions.length; i++) {
        if (conditions[i].field.value === fieldValue) {
          const productsOld = conditions[i]['value'] ? conditions[i]['value'] : [];
          const productsNew = value.split(',');
          const products = productsOld.concat(productsNew);

          const uniqueSet = new Set(products);

          conditions[i]['label'] = [...uniqueSet];
          conditions[i]['value'] = [...uniqueSet];
          delete conditions[i]['mode'];
        }
      }

      setConditions(conditions);
      forceUpdate();
    }
  }

  const handleChangeConditionDateValue = (e, fieldValue) => {
    const {value} = e.target;
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].field.value === fieldValue) {
        conditions[i]['value'] = value;
        delete conditions[i]['mode'];
      }
    }
    setConditions(conditions);
    forceUpdate();
  }

  const handleSearchAutocomplete = (e, fieldValue) => {
    const { value } = e.target;
    if (value.length >= 2) {
      setAutocompleteSearchWord(value);
      if (fieldValue === 'categories') {
        props.getCategoriesAutocomplete(value, 10);
      }
      if (fieldValue === 'brand') {
        props.getBrandsAutocomplete(value, 10);
      }
      if (fieldValue === 'merchant') {
        props.getMerchantsAutocomplete(value, 10);
      }
    }
    setTimeout(() => {
      const field = document.getElementById('rules_conditions_value_' + fieldValue);
      if (field) {
        document.getElementById('rules_conditions_value_' + fieldValue).focus();
      }
    }, 1000);
  }

  const handleAddAutocompleteItem = (e, item, fieldValue) => {
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].field.value === fieldValue) {
        if (!conditions[i]['value'] && !conditions[i]['label']) {
          conditions[i]['value'] = [item.value];
          conditions[i]['label'] = [item.label];
        } else {
          let isInList = false;
          for (let j = 0; j < conditions[i]['value']; j++) {
            if (conditions[i]['value'][j] === item.value) {
              isInList = true;
            }
          }
          if (isInList === false) {
            conditions[i]['value'][conditions[i]['value'].length] = item.value;
            conditions[i]['label'][conditions[i]['label'].length] = item.label;
          }
        }
        delete conditions[i]['mode'];
        setFoundOptions([]);
        setAutocompleteSearchWord('');
      }
    }

    setConditions(conditions);
    forceUpdate();
  }

  const RulesInformation = () => {
    if (informationShow === false) {
      return (
        <div className="rules-new-record-header"
             onClick={e => handleRulesTabs(e, 'information', true)}>
          <div className="rules-new-record-header-left"><IntlMessages id="button.rulesInformation" /></div>
          <div className="rules-new-record-header-right">
            <div className="round">&darr;</div>
          </div>
          <div className="clear-both">&nbsp;</div>

          <div className="rules-right rules-error" style={{paddingLeft: '10px'}}>
            { errors.name ? <IntlMessages id="system.errors.sectionCantBeBlank" /> : '' }
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="rules-new-record-header">
          <div className="rules-new-record-header-left"
               onClick={e => handleRulesTabs(e, 'information', false)}>
            <IntlMessages id="button.rulesInformation" />
          </div>
          <div className="rules-new-record-header-right"
               onClick={e => handleRulesTabs(e, 'information', false)}>
            <div className="round">&uarr;</div>
          </div>
          <div className="clear-both">&nbsp;</div>

          <div className="search-input-lg block">
            <div className="rules-form">
              <div className="row">
                <div className="col-4 rules-right">
                  <IntlMessages id="rules.fields.label.name" /> <span className="zap-red">*</span>
                </div>
                <div className="col-8 rules-left rules-full-width">
                  <OutlinedInput
                    id="name"
                    name="name"
                    type="text"
                    className="select-wrapper field-element"
                    defaultValue={rulesFields.name ? rulesFields.name : ''}
                    onChange={e => handleRulesFieldChange(e, 'name')}
                  />
                  <div className="rules-right rules-error">
                    { errors.name ? <IntlMessages id={errors.name} /> : '' }
                  </div>
                </div>

                <div className="col-4 rules-right">
                  <IntlMessages id="rules.fields.label.description" />
                </div>
                <div className="col-8 rules-left rules-full-width">
                  <TextareaAutosize
                      id="description"
                      name="description"
                      className="select-wrapper rules-textarea field-element"
                      defaultValue={rulesFields.description ? rulesFields.description : ''}
                      onChange={e => handleRulesFieldChange(e, 'description')}
                  />
                  <div className="rules-right rules-error">
                    { errors.description ? <IntlMessages id={errors.description} /> : '' }
                  </div>
                </div>

                <div className="col-4 rules-right">
                  <IntlMessages id="rules.fields.label.active" />
                </div>
                <div className="col-8 rules-left">
                  <Checkbox
                    id="active"
                    name="active"
                    color="primary"
                    value={rulesFields.active ? rulesFields.active : ''}
                    defaultChecked={!!rulesFields.active}
                    onClick={e => handleRulesCheckboxChange('active')}
                  />
                  <div className="rules-right rules-error">
                    { errors.active ? <IntlMessages id={errors.active} /> : '' }
                  </div>
                </div>

                <div className="col-4 rules-right">
                  <IntlMessages id="rules.fields.label.from" />
                </div>
                <div className="col-8 rules-left">
                  <OutlinedInput
                    id="from"
                    name="from"
                    type="datetime-local"
                    className="select-wrapper field-element"
                    defaultValue={rulesFields.from ? transformDateToMaterialFormat(rulesFields.from) : ''}
                    onChange={e => handleRulesFieldChange(e, 'from')}
                  />
                  <div className="rules-right rules-error">
                    { errors.from ? <IntlMessages id={errors.from} /> : '' }
                  </div>
                </div>

                <div className="col-4 rules-right">
                  <IntlMessages id="rules.fields.label.to" />
                </div>
                <div className="col-8 rules-left">
                  <OutlinedInput
                    id="to"
                    name="to"
                    type="datetime-local"
                    className="select-wrapper  field-element"
                    defaultValue={rulesFields.to ? transformDateToMaterialFormat(rulesFields.to) : ''}
                    onChange={e => handleRulesFieldChange(e, 'to')}
                  />
                  <div className="rules-right rules-error">
                    { errors.to ? <IntlMessages id={errors.to} /> : '' }
                  </div>
                </div>

                <div className="col-4 rules-right">
                  <IntlMessages id="rules.fields.label.priority" />
                </div>
                <div className="col-8 rules-left rules-full-width">
                  <OutlinedInput
                    id="priority"
                    name="priority"
                    type="number"
                    className="select-wrapper rules-w-100 field-element"
                    defaultValue={rulesFields.priority ? rulesFields.priority : ''}
                    onChange={e => handleRulesFieldChange(e, 'priority')}
                  />
                  <div className="rules-right rules-error">
                    { errors.priority ? <IntlMessages id={errors.priority} /> : '' }
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </>
    );
  }

  const ConditionItemOperator = (props) => {
    if (props.operator) {
      if (props.operators.length > 0) {
        return (
          <span className="rules-conditions-operator"
                onClick={e => handleRemoveConditionOperator(e, props.fieldValue)}>
            {props.operator.label}
          </span>
        )
      } else {
        return (
          <span className="rules-conditions-operator-no-edit">{props.operator.label}</span>
        )
      }
    } else if (props.operators) {
      return (
        <Select
          id={'rules_conditions_operators_' + props.fieldValue}
          className="select-wrapper rules-w-150 field-element"
          placeholder="Select operator"
          onChange={e => handleSelectConditionOperator(e, props.fieldValue)}
          options={props.operators}
        />
      );
    }

    return (<></>);
  }

  const AddMultiSelect = (props) => {
    if (props.operator) {
      return (
        <span className="rules-multiselect-add"
              onClick={e => handleShowMultiSelect(e, props.fieldValue)}>
        &nbsp;&nbsp;&nbsp;+&nbsp;
      </span>
      )
    }

    return (<></>);
  }

  const RangeValue = (props) => {
    if (props.from && props.to) {
      return (
        <>
          <IntlMessages className="rules-gray" id="rules.fields.label.from" /> {props.from} &nbsp;
          <IntlMessages className="rules-gray" id="rules.fields.label.to" /> {props.to}
        </>
      );
    } else if (props.from) {
      return (
        <>
          <IntlMessages className="rules-gray" id="rules.fields.label.from" /> {props.from}
        </>
      );
    } else if (props.to) {
      return (
        <>
          <IntlMessages className="rules-gray" id="rules.fields.label.to" /> {props.to}
        </>
      );
    } else {
      return (<></>);
    }
  }

  const MultiselectValue = (props) => {
    return (
      <>
        {props.labels[props.valueKey]}
        &nbsp;
        <span className="rules-remove-multiselect-value"
              onClick={e => handleRemoveMultiSelectValue(e, props.fieldValue, props.valueKey)}>
          &#x2717;
        </span>
        &nbsp;&nbsp;&nbsp;
        <span> </span>
      </>
    )
  }

  const ConditionItemValue = (props) => {
    if (props.value && props.mode !== 'edit') {
      if (props.type === 'range') {
        return (
          <span className="rules-conditions-value"
                onClick={e => handleEditConditionValue(e, props.fieldValue, props.type)}
          >
            <RangeValue from={props.value[0]} to={props.value[1]} />
          </span>
        );
      }

      if (props.type === 'products' || props.type === 'multiselect' || props.type === 'autocomplete') {
        const propsValue = Array.isArray(props.value) ? props.value : props.value.toString().split(',');
        const propsLabel = Array.isArray(props.label) ? props.label : props.label.toString().split(',');

        return (
          <>
            {propsValue.map((value, valueKey) => (
              <MultiselectValue
                key={valueKey}
                fieldValue={props.fieldValue}
                labels={propsLabel}
                value={value}
                valueKey={valueKey} />
            ))}
          </>
        );
      }

      let value = props.label ? props.label : props.value;
      if (props.type === 'date') {
        value = Array.isArray(props.value)
          ? props.value[0].toString().substr(0,10)
          : props.value.toString().substr(0,10);
      }

      return (
        <>
          <span className="rules-conditions-value"
                onClick={e => handleEditConditionValue(e, props.fieldValue, props.type)}
          >
            {value}
          </span>
        </>
      )
    }

    if (!props.operator) {
      return (<></>);
    }

    if (!props.type || props.type === 'text' || props.type === 'number') {
      const type = props.type === 'number' ? 'number' : 'text';
      return (
        <OutlinedInput
          id={'rules_conditions_value_' + props.fieldValue}
          type={type}
          className="select-wrapper w-300 field-element"
          defaultValue={props.value}
          onKeyPress={e => handleChangeConditionValue(e, props.fieldValue, true)}
          onBlur={e => handleChangeConditionValue(e, props.fieldValue, false)}
        />
      )
    }

    if (props.type === 'range') {
      return (
        <>
          <IntlMessages id="rules.fields.label.from" />
          &nbsp;
          <OutlinedInput
            id={'rules_conditions_value_' + props.fieldValue + '_from'}
            type="text"
            className="select-wrapper rules-w-100 field-element"
            defaultValue={props.value && props.value[0] ? props.value[0] : ''}
            onKeyPress={e => handleChangeConditionRangeValue(
              e, 0,
              document.getElementById('rules_conditions_value_' + props.fieldValue + '_to').value,
              props.fieldValue,
              true
            )}
          />
          &nbsp;
          <IntlMessages id="rules.fields.label.to" />
          &nbsp;
          <OutlinedInput
            id={'rules_conditions_value_' + props.fieldValue + '_to'}
            type="text"
            className="select-wrapper rules-w-100 field-element"
            defaultValue={props.value && props.value[1] ? props.value[1] : ''}
            onKeyPress={e => handleChangeConditionRangeValue(
              e, 1,
              document.getElementById('rules_conditions_value_' + props.fieldValue + '_from').value,
              props.fieldValue,
              true
            )}
            onBlur={e => handleChangeConditionRangeValue(
              e, 1,
              document.getElementById('rules_conditions_value_' + props.fieldValue + '_from').value,
              props.fieldValue,
              false
            )}
          />
        </>
      )
    }

    if (props.type === 'date') {
      return (
        <OutlinedInput
          id={'rules_conditions_value_' + props.fieldValue}
          type="datetime-local"
          className="select-wrapper w-300 field-element"
          defaultValue={transformDateToMaterialFormat(props.value)}
          onChange={e => handleChangeConditionDateValue(e, props.fieldValue)}
        />
      )
    }

    if (props.type === 'select') {
      return (
        <Select
          id={'rules_conditions_value_' + props.fieldValue}
          className="select-wrapper w-300 field-element"
          placeholder="Select..."
          defaultValue={ props.value ?
            {
              value: props.value,
              label: getOptionLabel(props.options, props.value),
            } : '' }
          onChange={e => handleChangeConditionSelectValue(e, props.fieldValue)}
          options={props.options}
        />
      )
    }

    if (props.type === 'multiselect') {
      return (
        <>
          {props.mode === 'edit' && (
            <Select
              id={'rules_conditions_value_' + props.fieldValue}
              className="select-wrapper w-300 field-element"
              placeholder="Select..."
              onChange={e => handleChangeConditionMultiSelectValue(e, props.fieldValue)}
              options={props.options}
            />
          )}
        </>
      )
    }

    if (props.type === 'autocomplete') {
      return (
        <>
          {props.mode === 'edit' && (
            <>
              <OutlinedInput
                id={'rules_conditions_value_' + props.fieldValue}
                type="text"
                ref={input => input && input.focus()}
                className="select-wrapper w-300 field-element"
                placeholder="Start typing the name you want..."
                defaultValue={autocompleteSearchWord}
                autoComplete="off"
                onChange={e => handleSearchAutocomplete(e, props.fieldValue)}
              />
              {foundOptions.length > 0 && (
                <div className="rules-autocomplete-parent">
                  {foundOptions.map((item, key) => (
                    <AutocompleteOptions
                        key={key}
                        item={item}
                        fieldValue={props.fieldValue}
                        searchWord={autocompleteSearchWord} />
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )
    }

    if (props.type === 'products') {
      return (
        <>
          <OutlinedInput
            id={'rules_conditions_value_' + props.fieldValue}
            type="text"
            className="select-wrapper w-300 field-element"
            placeholder="Product IDs, separated by commas"
            defaultValue=""
            onKeyPress={e => handleChangeProductsValue(e, props.fieldValue, true)}
            onBlur={e => handleChangeProductsValue(e, props.fieldValue, false)}
          />
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            size="medium"
            className="text-white mapper-w-200"
            onClick={handleOpenAddProductsDialog}
          >
            <IntlMessages id="button.openProductsDialog" />
          </Button>
        </>
      )
    }

    return (<></>);
  }

  const AutocompleteOptions = (props) => {
    const reg = new RegExp(props.searchWord, 'gi');
    const label = props.item.label.replace(reg, function(str) {
      return '<span class="rules-autocomplete-search">' + str + '</span>'
    });

    return (
      <div className="rules-autocomplete"
           onClick={e => handleAddAutocompleteItem(e, props.item, props.fieldValue)}
      >
        <span dangerouslySetInnerHTML={{__html: label}} />
      </div>
    )
  }

  const ConditionItem = (props) => {
    return (
      <TableRow>
        <TableCell>{props.number + 1}. </TableCell>
        <TableCell><strong>{props.item.field.label}</strong></TableCell>
        <TableCell>
          <ConditionItemOperator
            type={props.item.field.type ? props.item.field.type : null}
            fieldValue={props.item.field.value}
            operator={props.item.operator ? props.item.operator : null}
            operators={props.item.field.operators ? props.item.field.operators : []}
          />
        </TableCell>
        <TableCell>
        {(props.item.field.type === 'products'
          || props.item.field.type === 'multiselect'
          || props.item.field.type === 'autocomplete'
        ) && (
            <AddMultiSelect
              fieldValue={props.item.field.value}
              operator={props.item.operator ? props.item.operator : null} />
        )}
        </TableCell>
        <TableCell>
          <ConditionItemValue
            fieldValue={props.item.field.value}
            operator={props.item.operator ? props.item.operator : null}
            options={props.item.field.options ? props.item.field.options : null}
            type={props.item.field.type ? props.item.field.type : null}
            label={props.item.label ? props.item.label : null}
            value={props.item.value ? props.item.value : null}
            mode={props.item.mode ? props.item.mode : null}
          />
        </TableCell>
        <TableCell><span onClick={e => handleRemoveCondition(e, props.item.field.value)}
                  className="rules-condition-delete">	&#10006; </span>
        </TableCell>
        <TableCell>&nbsp;</TableCell>
      </TableRow>
    );
  }

  const RulesCondition = () => {
    if (conditionShow === false) {
      return (
        <div className="rules-new-record-header"
             onClick={e => handleRulesTabs(e, 'condition', true)}>
          <div className="rules-new-record-header-left"><IntlMessages id="button.rulesCondition" /></div>
          <div className="rules-new-record-header-right">
            <div className="round">&darr;</div>
          </div>
          <div className="clear-both">&nbsp;</div>

          <div className="rules-right rules-error" style={{paddingLeft: '10px'}}>
            { errors.condition ? <IntlMessages id="system.errors.sectionCantBeBlank" /> : '' }
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="rules-new-record-header">
          <div className="rules-new-record-header-left"
               onClick={e => handleRulesTabs(e, 'condition', false)}>
            <IntlMessages id="button.rulesCondition" />
          </div>
          <div className="rules-new-record-header-right"
               onClick={e => handleRulesTabs(e, 'condition', false)}>
            <div className="round">&uarr;</div>
          </div>
          <div className="clear-both">&nbsp;</div>

          <div className="rules-right rules-error" style={{paddingLeft: '10px'}}>
            { errors.condition ? <IntlMessages id={errors.condition} /> : '' }
          </div>

          <div className="search-input-lg block">
            <div className="rules-form">

              <Select
                id="rules_conditions"
                name="rulesConditions"
                className="select-wrapper w-300 field-element"
                placeholder="Add new condition"
                onChange={e => handleAddNewCondition(e)}
                options={rulesConditionsOptions}
              />

              <hr/>

              {conditions.length > 0 && (
                <>
                <Table className="rules-conditions-table">
                  <TableBody>
                    {conditions.map((item, key) => (
                      <ConditionItem key={key} number={key} item={item} />
                    ))}
                  </TableBody>
                </Table>
                <hr/>
                </>
              )}

            </div>
          </div>
        </div>
      </>
    );
  }

  const RulesAction = () => {
    if (actionShow === false) {
      return (
        <div className="rules-new-record-header-no-border"
             onClick={e => handleRulesTabs(e, 'action', true)}>
          <div className="rules-new-record-header-left"><IntlMessages id="button.rulesAction" /></div>
          <div className="rules-new-record-header-right">
            <div className="round">&darr;</div>
          </div>
          <div className="clear-both">&nbsp;</div>

          <div className="rules-right rules-error" style={{paddingLeft: '10px'}}>
            { (errors.action || errors.amount) ? <IntlMessages id="system.errors.sectionCantBeBlank" /> : '' }
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="rules-new-record-header-no-border">
          <div className="rules-new-record-header-left"
               onClick={e => handleRulesTabs(e, 'action', false)}>
            <IntlMessages id="button.rulesAction" />
          </div>
          <div className="rules-new-record-header-right"
               onClick={e => handleRulesTabs(e, 'action', false)}>
            <div className="round">&uarr;</div>
          </div>
          <div className="clear-both">&nbsp;</div>

          <div className="search-input-lg block">
            <div className="rules-form">
              <div className="row">
                <div className="col-4 rules-right">
                  <IntlMessages id="rules.fields.label.action" /> <span className="zap-red">*</span>
                </div>
                <div className="col-8 rules-left">
                  <Select
                    id="action"
                    name="action"
                    className="select-wrapper w-300 field-element"
                    defaultValue={ rulesFields.action ?
                      {
                        value: rulesFields.action,
                        label: getOptionLabel(actionOptions, rulesFields.action),
                      } : '' }
                    onChange={e => handleRulesSelectChange(e, 'action')}
                    options={actionOptions}
                  />
                  <div className="rules-right rules-error">
                    { errors.action ? <IntlMessages id={errors.action} /> : '' }
                  </div>
                </div>

                <div className="col-4 rules-right">
                  <IntlMessages id="rules.fields.label.amount" /> <span className="zap-red">*</span>
                </div>
                <div className="col-8 rules-left rules-full-width">
                  <OutlinedInput
                    id="amount"
                    name="amount"
                    type="number"
                    className="select-wrapper field-element"
                    defaultValue={rulesFields.amount ? rulesFields.amount : ''}
                    onChange={e => handleRulesFieldChange(e, 'amount')}
                  />
                  <div className="rules-right rules-error">
                    { errors.amount ? <IntlMessages id={errors.amount} /> : '' }
                  </div>
                </div>

                <div className="col-4 rules-right">
                  <IntlMessages id="rules.fields.label.discardSubsequentRules" />
                </div>
                <div className="col-8 rules-left">
                  <Checkbox
                    id="active"
                    name="active"
                    color="primary"
                    value={rulesFields.discardSubsequentRules ? rulesFields.discardSubsequentRules : ''}
                    defaultChecked={!!rulesFields.discardSubsequentRules}
                    onClick={e => handleRulesCheckboxChange('discardSubsequentRules')}
                  />
                  <div className="rules-right rules-error">
                    { errors.discardSubsequentRules ? <IntlMessages id={errors.discardSubsequentRules} /> : '' }
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </>
    );
  }

  if (props.openEditForm === false) {
    return (
      <>
        <div className="mapper-total">
          <IntlMessages id="sidebar.found" />
          &nbsp;
          { props.scroll.total ? props.scroll.total : 0 }
          &nbsp;
          <IntlMessages id="sidebar.records" />
        </div>
      </>
    );
  }

  return (
    <div onClick={e => removeEditModes(e)} className="rules-new-record">
      <h2><IntlMessages id="button.newRulesRecord" /></h2>
      <div className="buttons-area">

        <span className="mapper-sortable" onClick={cancelForm}>
          <IntlMessages id="button.cancel" />
        </span>
        &nbsp;&nbsp;&nbsp;
        <Button
          variant="contained"
          color="primary"
          size="medium"
          ignored={ignored}
          className="text-white mapper-w-75"
          onClick={saveForm}
        >
          <IntlMessages id="button.save" />
        </Button>

      </div>

      <RulesInformation />
      <RulesCondition />
      <RulesAction />

      <AddProductsDialog
        key="add-product"
        isOpened={isOpenedAddProductsDialog}
        onClose={handleCloseAddProductsDialog}
        onAddProductsClick={handleAddProducts}
        getRulesForm={props.getRulesForm}
      />

    </div>
  );
};

Form.propTypes = {
  getRulesData: func.isRequired,
  saveRulesData: func.isRequired,
  getRulesForm: func.isRequired,
  getBrandsAutocomplete: func.isRequired,
  getCategoriesAutocomplete: func.isRequired,
  getMerchantsAutocomplete: func.isRequired,
  toggleSelectedProduct: func.isRequired,
};

Form.defaultProps = {};

const stateToProps = state => ({});

const dispatchToProps = {
  getRulesData,
  saveRulesData,
  getRulesForm,
  getBrandsAutocomplete,
  getCategoriesAutocomplete,
  getMerchantsAutocomplete,
  toggleSelectedProduct,
};

export default withRouter(connect(stateToProps, dispatchToProps)(Form));
