import { createSelector } from 'reselect';

const previewRootSelector = state => state;

const stateSelector = createSelector(
  previewRootSelector,
  state => ({
    productsCommentsCount: state.productsCommentsCount,
    defaultImage: state.defaultImage,
    selectedProducts: state.selected,
    isEditMode: state.isEditMode,
    isHiddenProductsMode: state.isHiddenProductsMode,
    productsFetching: state.fetching,
    facetsPreFilters: state.facetsPreFilters,
    searchFilter: state.searchFilter,
    fields: state.fields,
  }),
);

export const previewSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  previewSelector,
};
