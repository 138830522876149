/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  shape, bool,
} from 'prop-types';

import IntlMessages from '../../../../util/IntlMessages';

const DropZoneComponent = (props) => {
  const { categoryImport } = props;
  const {
    xlsxUploading,
    xlsxUploaded,
    bulkVirtualCategoriesDeleting,
    bulkVirtualCategoriesDeleted,
    bulkVirtualCategoriesCreating,
    bulkVirtualCategoriesCreated,
    bulkVirtualCategoriesUpdating,
    bulkVirtualCategoriesUpdated,
    hasErrors,
    error,
  } = categoryImport;
  const showDeleteLoader = bulkVirtualCategoriesDeleting || bulkVirtualCategoriesDeleted;
  const showBulkCreateLoader = bulkVirtualCategoriesCreating || bulkVirtualCategoriesCreated;
  const showBulkUpdateLoader = bulkVirtualCategoriesUpdating || bulkVirtualCategoriesUpdated;
  const showXLSXLoader = xlsxUploading || xlsxUploaded;

  return (
    <section className="container step-loader">
      {showDeleteLoader && (
        <div className="flex">
          {bulkVirtualCategoriesDeleting && (
            <div className="flex progress-item">
              <IntlMessages id="importPage.status.categoriesBulkRemoving" />
              <CircularProgress
                className="progress-primary mr-30 mb-10 ml-10 bottom"
                size={15}
                variant="indeterminate"
                disableShrink
                thickness={4}
              />
            </div>
          )}
          {bulkVirtualCategoriesDeleted && (
            <div className="flex progress-item">
              <IntlMessages id="importPage.status.categoriesBulkRemoved" />
              <i className="ti-check green ml-10 text-success" />
            </div>
          )}
        </div>
      )}
      {showXLSXLoader && (
        <div className="flex">
          {xlsxUploading && (
            <div className="flex progress-item">
              <IntlMessages id="importPage.status.xlsxParsing" />
              <CircularProgress
                className="progress-primary mr-30 mb-10 ml-10 bottom"
                size={15}
                variant="indeterminate"
                disableShrink
                thickness={4}
              />
            </div>
          )}
          {xlsxUploaded && (
            <div className="flex progress-item">
              <IntlMessages id="importPage.status.xlsxParsed" />
              <i className="ti-check green ml-10 text-success" />
            </div>
          )}
        </div>
      )}
      {showBulkCreateLoader && (
        <div className="flex">
          {bulkVirtualCategoriesCreating && (
            <div className="flex progress-item">
              <IntlMessages id="importPage.status.categoriesBulkCreating" />
              <CircularProgress
                className="progress-primary mr-30 mb-10 ml-10 bottom"
                size={15}
                variant="indeterminate"
                disableShrink
                thickness={4}
              />
            </div>
          )}
          {bulkVirtualCategoriesCreated && !hasErrors && (
            <div className="flex progress-item">
              <IntlMessages id="importPage.status.categoriesBulkCreated" />
              <i className="ti-check green ml-10 text-success" />
            </div>
          )}
          {bulkVirtualCategoriesCreated && hasErrors && (
            <div className="flex progress-item">
              <span className="text-diff">Something went wrong: {error.message}</span>
              <i className="ti-alert ml-10 text-danger flex items-center" />
            </div>
          )}
        </div>
      )}
      {showBulkUpdateLoader && (
        <div className="flex">
          {bulkVirtualCategoriesUpdating && (
            <div className="flex progress-item">
              <IntlMessages id="importPage.status.title.categoriesBulkUpdating" />
              <CircularProgress
                className="progress-primary mr-30 mb-10 ml-10 bottom"
                size={15}
                variant="indeterminate"
                disableShrink
                thickness={4}
              />
            </div>
          )}
          {bulkVirtualCategoriesUpdated && !hasErrors && (
            <div className="flex progress-item">
              <IntlMessages id="importPage.status.title.categoriesBulkUpdated" />
              <i className="ti-check green ml-10 text-success" />
            </div>
          )}
          {bulkVirtualCategoriesUpdated && hasErrors && (
            <div className="flex progress-item">
              <span className="text-diff">Something went wrong: {error.message}</span>
              <i className="ti-alert ml-10 text-danger flex items-center" />
            </div>
          )}
        </div>
      )}
    </section>
  );
};

DropZoneComponent.propTypes = {
  categoryImport: shape({
    xlsxUploading: bool,
    xlsxUploaded: bool,
    bulkVirtualCategoriesDeleting: bool,
    bulkVirtualCategoriesDeleted: bool,
    bulkVirtualCategoriesCreating: bool,
    bulkVirtualCategoriesCreated: bool,
    hasErrors: bool,
    error: shape(),
  }).isRequired,
};

export default DropZoneComponent;
