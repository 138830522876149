import React from 'react';
import IntlMessages from "../../../util/IntlMessages";
import { VariationAttribute } from './VariationAttribute';
import { MerchantInfo } from './MerchantInfo';
import { Table, TableCell, TableContainer, TableBody, TableHead, TableRow } from "@material-ui/core";
import { getPublishPrice, moneyFormat } from "../../../pages/Pim/pages/Home/utils/productDataGenerator";
import { movePricesTableScroll} from "../../../util/table";
import { MerchantLogInfo } from "./MerchantLogInfo";

export const ProductPrices = (
  { productIsParent, merchantLogs, productId, info, editProduct, productAttributeProps, currencyItem}
) => {

  const SalePrice = ({item}) => {
    const originPublishPrice = getPublishPrice(item.price.finalPrice, currencyItem.value, item.isDomestic);

    return (
      item.frontendPrice
        ? moneyFormat(Math.ceil(item.frontendPrice.publishPrice), '₪')
        : moneyFormat(Math.ceil(originPublishPrice), '₪')
    );
  }

  window.addEventListener('wheel', () => {
    movePricesTableScroll('pricesTableHead');
  });

  window.addEventListener('click', () => {
    movePricesTableScroll('pricesTableHead');
  });

  if (productIsParent) {
    return (
      <div className="prices-table-scroll">
        <TableContainer className="product-table-container">
          <Table className="prices-table" id="pricesTable" stickyHeader aria-label="sticky table" size="small">
            <TableHead id="pricesTableHead" className="products-table-header mapper-sticky">
              <TableRow>
                <TableCell><IntlMessages id="product.edit.prices.variation" /></TableCell>
                <TableCell><IntlMessages id="product.edit.prices.merchant" /></TableCell>
                <TableCell style={{textAlign: 'center'}}><IntlMessages id="product.edit.prices.quantity" /></TableCell>
                <TableCell style={{textAlign: 'center'}}><IntlMessages id="product.edit.prices.netPrice" /></TableCell>
                <TableCell style={{textAlign: 'center'}}><IntlMessages id="product.edit.prices.shippingMarkup" /></TableCell>
                <TableCell style={{textAlign: 'center'}}><IntlMessages id="product.edit.prices.salePrice" /></TableCell>
                <TableCell style={{textAlign: 'center'}}><IntlMessages id="product.edit.prices.profit" /></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                info.variations && info.variations.map((item, index) => {
                  const attrKeys = Object.keys(item.variationAttributes);

                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {
                          attrKeys && attrKeys.map((attrKey, attrIndex) => (
                            <VariationAttribute
                              key={attrIndex + '-' + attrKey}
                              item={attrKey}
                              attributes={item.variationAttributes}
                              productAttributeProps={productAttributeProps}
                            />
                          ))
                        }
                        { attrKeys.length === 0 && (
                          <IntlMessages id="product.edit.noAttributes" />
                        )}
                      </TableCell>
                      <TableCell>
                        {info.merchants && info.merchants.map((merchantItem, merchantItemKey) => (
                          <MerchantInfo
                            key={'merchant-' + merchantItemKey}
                            editProduct={editProduct}
                            item={merchantItem}
                            productId={productId}
                            index={merchantItemKey}
                            variation={item}
                            onlyName={true}
                          />
                        ))}
                      </TableCell>
                      <TableCell style={{textAlign: 'center'}}>{ item.stock.qty }</TableCell>
                      <TableCell style={{textAlign: 'center'}}>
                        { moneyFormat(item.price.cost, '$') }
                      </TableCell>
                      <TableCell style={{textAlign: 'center'}}>
                        { moneyFormat(item.price.shippingCost, '$') }
                      </TableCell>
                      <TableCell style={{textAlign: 'center'}}>
                        <SalePrice key={'sale-price-' + index} item={item} />
                      </TableCell>
                      <TableCell style={{textAlign: 'center'}}>{ moneyFormat(item.price.profit, '$') }</TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else if (merchantLogs && merchantLogs.length > 0) {
    return (
      <div className="prices-log-table-scroll" style={{ top: '-20px', position: 'relative' }}>
        <TableContainer className="product-table-container">
          <Table className="prices-table" id="pricesTable" stickyHeader aria-label="sticky table" size="small">
            <TableHead id="pricesTableHead" className="products-table-header mapper-sticky">
              <TableRow>
                <TableCell><IntlMessages id="product.edit.prices.price_change" /></TableCell>
                <TableCell><IntlMessages id="product.edit.prices.quantity_change" /></TableCell>
                <TableCell><IntlMessages id="product.edit.prices.date" /></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {merchantLogs && merchantLogs.map((merchantItem, merchantItemKey) => (
                <TableRow key={merchantItemKey}>
                  <MerchantLogInfo
                    key={'merchant-' + merchantItemKey}
                    item={merchantItem}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else {
    return (
      <div className="prices-table-scroll" style={{ paddingTop: '20px', textAlign: 'center'}}>
        <IntlMessages id="title.notChanged" />
      </div>
    );
  }
};

