import React, { memo, useContext } from 'react';
import {
  func, bool, shape, arrayOf,
} from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import swal from '@sweetalert/with-react';

import {
  fetchPreviewNavigationVirtualFacetList,
  setPreviewNavigationVirtualFacetToDeleteId,
  setPreviewNavigationVirtualFacetItem,
  setPreviewNavigationVirtualFacetList,
} from 'actions/previewNavigationVirtualFacet';


import { getFacetPayload } from 'util/virtualFacetManagment';
import { navigationFacetSelector } from './selectors/navigationFacet';

import useFacetEffects from './hooks/useFacet';
import previewNavigationVirtualFacet from './hooks/previewNavigationVirtualFacet';

import FacetComponent from '../../../../../components/Facet';
import { AbilityContext } from '../../../../../components/AbilityContext';

import appPermissions from '../../../../../util/appPermissions';

import {
  getFilterableAttributes,
} from '../../../../../util/attributesMappers';

import {
  setFacets,
} from '../../../../../actions/previewNavigation';

const Facet = memo((props) => {
  const abilityContext = useContext(AbilityContext);

  useFacetEffects({
    navigationFetched: props.navigationFetched,
    navigationItem: props.navigationItem,
    setFacets: props.setFacets,
  });

  previewNavigationVirtualFacet({
    navigationItemId: props.navigationItem.id,
    navigationFetched: props.navigationFetched,
    navigationUpdated: props.navigationUpdated,

    fetchNavigationVirtualFacetList: props.fetchPreviewNavigationVirtualFacetList,
    navigationVirtualFacetUpdated: props.navigationFacet.updated,
    navigationVirtualFacetDeleted: props.navigationFacet.deleted,
    navigationVirtualFacetCreated: props.navigationFacet.created,
    navigationVirtualFacetFatching: props.navigationFacet.fatching,
  });

  const {
    navigationItem,
    rankingFetching,
    categoriesAttributesFetching,
    categoriesAttributes,
    defaultAttributes,
    filterCategory,
  } = props;

  const noPermissions = !abilityContext.can(
    appPermissions.navigation.permissions.update,
    appPermissions.navigation.name,
  );

  const loading = rankingFetching || categoriesAttributesFetching;

  const getFacetAttributes = () => getFilterableAttributes(categoriesAttributes);

  const getCategoryFacets = () => {
    const facetAttributes = getFacetAttributes();
    let navigationFacets = [];
    if (
      !isEmpty(props.navigationFacet.list)
      && !isEmpty(categoriesAttributes)
      && !isEmpty(facetAttributes)
    ) {
      navigationFacets = props.navigationFacet.list.map((cF) => {
        if (facetAttributes.some(pASL => pASL.code === cF)) {
          return facetAttributes.find(pASL => pASL.code === cF);
        }
        return false;
      }).filter(a => a);
    }

    const defaultAttributesList = defaultAttributes || [];
    const filteredFacets = navigationFacets.filter(
      cf => !defaultAttributesList.some(da => da.field === cf.code),
    );
    return [...defaultAttributesList, ...filteredFacets, ...props.navigationFacet.list];
  };

  const handleFacetChanges = (facets) => {
    const mappedFacets = facets.filter(f => !f.code && !f.default);
    // eslint-disable-next-line no-param-reassign
    mappedFacets.forEach(i => delete i.groupedValues);
    const newLsit = [{
      ...props.navigationFacet.item,
      facetsList: mappedFacets,
    }];
    props.setPreviewNavigationVirtualFacetList(newLsit);
  };

  const preHandleFacetDelete = () => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to permanently delete virtual facets?',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
    }).then((willDiscard) => {
      if (willDiscard) {
        props.setPreviewNavigationVirtualFacetToDeleteId(props.navigationFacet.item.id);
        props.setPreviewNavigationVirtualFacetList([]);
      }
    });
  };


  const handleFacetItemDelete = (newList) => {
    handleFacetChanges(newList);
    if (isEmpty(newList.filter(l => !l.default))) {
      props.setPreviewNavigationVirtualFacetToDeleteId(props.navigationFacet.item.id);
    } else {
      props.setPreviewNavigationVirtualFacetToDeleteId('');
    }
  };

  const handleFacetManagementDialogOk = (facet) => {
    const facetPayload = getFacetPayload({
      prevFacet: facet,
      useRev: true,
      nextFacet: {
        item: props.navigationFacet.item,
        list: props.navigationFacet.list,
      },
    });
    props.setPreviewNavigationVirtualFacetList(facetPayload);
  };

  return (
    <div className="relative preview-drawer-facet">
      <FacetComponent
        key={navigationItem.id}
        entity={{
          type: 'navigation',
          id: navigationItem.id,
        }}
        loading={loading}
        inputLabel="Select"
        list={getFacetAttributes()}
        defaultAttributes={defaultAttributes}
        disabled={isEmpty(filterCategory.mappedCategories) || noPermissions}
        table={{
          tBody: getCategoryFacets(),
        }}
        onAdd={handleFacetChanges}
        onDeleteButton={{
          disabled: isEmpty(props.navigationFacet.list)
            && isEmpty(props.navigationFacet.virtualFacetIdToDelete),
          onClick: preHandleFacetDelete,
        }}
        onItemDelete={handleFacetItemDelete}
        onDragEnd={handleFacetChanges}
        newFacet={{
          onOk: handleFacetManagementDialogOk,
        }}
      />
    </div>
  );
});

Facet.propTypes = {
  navigationItem: shape().isRequired,
  filterCategory: shape().isRequired,
  categoriesAttributes: arrayOf(shape()).isRequired,
  defaultAttributes: arrayOf(shape()).isRequired,

  rankingFetching: bool.isRequired,
  categoriesAttributesFetching: bool.isRequired,
  navigationFetched: bool.isRequired,
  navigationUpdated: bool.isRequired,

  setFacets: func.isRequired,

  fetchPreviewNavigationVirtualFacetList: func.isRequired,
  setPreviewNavigationVirtualFacetToDeleteId: func.isRequired,
  setPreviewNavigationVirtualFacetList: func.isRequired,

  navigationFacet: shape().isRequired,
};

Facet.defaultProps = {};

const mapStateToProps = state => ({
  navigationItem: state.previewNavigation.item,
  filterCategory: state.previewNavigation.filter.category,
  navigationFetched: state.previewNavigation.fetchedOne,
  navigationUpdated: state.previewNavigation.updated,

  rankingFetching: state.ranking.fetching,

  categoriesAttributesFetching: state.productAttribute.categoriesAttributesFetching,
  categoriesAttributes: state.productAttribute.categoriesAttributes,

  defaultAttributes: state.defaultAttribute.attributes.categories,

  navigationFacet: navigationFacetSelector(state.previewNavigationVirtualFacet),
});

const mapDispatchToProps = {
  setFacets,

  fetchPreviewNavigationVirtualFacetList,
  setPreviewNavigationVirtualFacetToDeleteId,
  setPreviewNavigationVirtualFacetItem,
  setPreviewNavigationVirtualFacetList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Facet);
