import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  SEARCH_PROMOTIONS_START,
  SEARCH_PROMOTIONS_FINISHED,
} from '../actions/types';

const initialState = {
  list: [],
  item: {},
  searchStarted: false,
  searchFinished: false,
  searchResults: [],
  allPages: 0,
};
const promotion = restTypes('promotion');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case promotion.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case promotion.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case promotion.fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };
    case promotion.fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case promotion.updateStart:
      return {
        ...state,
        ...restStates.updateStart,
      };
    case promotion.updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };
    case promotion.updateMultipleStart:
      return {
        ...state,
        ...restStates.updateMultipleStart,
      };
    case promotion.updateMultipleFinished:
      return {
        ...state,
        ...restStates.updateMultipleFinished,
        ...action.payload,
      };
    case promotion.createStart:
      return {
        ...state,
        ...restStates.createStart,
      };
    case promotion.createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        ...action.payload,
      };
    case promotion.clearOneFinished:
      return {
        ...state,
        ...action.payload,
      };
    case promotion.deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
        ...action.payload,
      };
    case promotion.deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };
    case promotion.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case SEARCH_PROMOTIONS_START:
      return {
        ...state,
        searchStarted: true,
        searchFinished: false,
      };
    case SEARCH_PROMOTIONS_FINISHED:
      return {
        ...state,
        searchStarted: false,
        searchFinished: true,
        searchResults: action.payload.list,
      };
    default: return state;
  }
};
