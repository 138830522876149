/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  arrayOf, shape, func, node, string, oneOfType,
  number, bool, object,
} from 'prop-types';
import { isEmpty } from 'lodash';

import { FormHelperText } from '@material-ui/core';

import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { getGroupedOptions } from './utils/getSlicedList';
import IntlMessages from '../../util/IntlMessages';

const animatedComponents = makeAnimated();

const AutocompleteMultiple = (props) => {
  const [selectedList, setSelectedList] = useState([]);
  const [value, setValue] = useState([]);
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState([]);

  const handleInputChange = (searchQuery) => {
    setQuery(searchQuery);
    if (!isEmpty(searchQuery)) {
      const filteredOptions = props.options.filter(
        o => o.label.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      if (props.handleSearchOptions && searchQuery !== query) {
        props.handleSearchOptions(searchQuery);
      }
      setOptions(getGroupedOptions(filteredOptions, props.limitsToShow));
    } else {
      setOptions(getGroupedOptions(props.options, props.limitsToShow));
    }
    if (props.onInputChange) {
      props.onInputChange(searchQuery);
    }
  };

  useEffect(() => {
    if (!isEmpty(props.options)) {
      handleInputChange(query);
    }
  }, [props.options]);

  useEffect(() => {
    if (!isEmpty(props.value) && !isEmpty(props.options)) {
      setSelectedList(props.value);
      setValue(
        props.valueType === 'arrayOfObject'
          ? props.value
          : props.options.filter(o => selectedList.some(v => v === o.value)),
      );
    }
  }, [props.value, props.options, selectedList]);

  const handleBlur = () => {
    if (props.onBlur) props.onBlur();
  };

  const handleChange = (list) => {
    if (!props.disabled) {
      setSelectedList(list || []);

      const valueToSend = !isEmpty(list) ? list.map(l => l.value) : [];
      const labels = !isEmpty(list) ? list.map(l => l.label) : [];
      if (isEmpty(list)) {
        setSelectedList([]);
        setValue(null);
      } else {
        setSelectedList(list || []);
      }

      const getValue = () => {
        switch (props.valueType) {
          case 'arrayOfString':
            return valueToSend;
          case 'arrayOfObject':
            return list;
          default:
            return list;
        }
      };

      handleInputChange(query);
      props.onChange({
        target: {
          value: getValue(),
          name: props.fieldName,
          element: 'autocompleteMultiple',
          labels,
        },
      });
    }
  };

  const NoOptionsMessage = (messageProps) => {
    const noOptionsProps = {
      ...messageProps,
      children: <IntlMessages id="autocomplete.single.noOptions.label" />,
    };

    if (isEmpty(query) && isEmpty(options)) {
      const newProps = {
        ...messageProps,
        children: <IntlMessages id="autocomplete.single.typeSomething.label" />,
      };
      return <components.NoOptionsMessage {...newProps} />;
    }

    if (!isEmpty(query) && isEmpty(options) && props.loadOptions) {
      const newProps = {
        ...messageProps,
        children: <IntlMessages id="autocomplete.single.loading.label" />,
      };
      return <components.NoOptionsMessage {...newProps} />;
    }
    return <components.NoOptionsMessage {...noOptionsProps} />;
  };

  const wrapperClass = !isEmpty(selectedList) ? 'has-value' : '';
  const disabledClass = props.disabled ? 'disabled' : '';
  const includesErrorClass = props.containerClassName.includes('has-error');
  const errorClass = props.error || includesErrorClass ? 'autocomplete-error' : '';
  const noValueMessage = isEmpty(value)
    && includesErrorClass && isEmpty(props.helperText)
    ? 'The field(s) is required'
    : '';

  return (
    <div className={`pos-rel ${errorClass}`}>
      <div className={`autocomplete-select-multiple-wrapper ${props.className} ${props.containerClassName} ${wrapperClass} ${disabledClass} ${errorClass}`}>
        <Select
          isDisabled={props.disabled}
          value={value}
          inputValue={query}
          closeMenuOnSelect={false}
          placeholder={props.placeholder}
          isMulti={props.isMulti}
          components={{ animatedComponents, NoOptionsMessage }}
          defaultValue={props.defaultValue}
          options={options}
          onInputChange={handleInputChange}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {props.label && <div className="autocomplete-select-multiple-label">{props.label}</div>}
      </div>
      <FormHelperText className="autocomplete-helper-text">{props.helperText || noValueMessage}</FormHelperText>
    </div>
  );
};

AutocompleteMultiple.propTypes = {
  label: oneOfType([string, node]),
  options: arrayOf(shape({
    label: string,
  })),
  value: arrayOf(oneOfType([string, object])),
  defaultValue: arrayOf(shape({
    label: string,
  })),
  className: string,
  error: bool,
  disabled: bool,
  onChange: func,
  limitsToShow: number,
  placeholder: string,
  containerClassName: string,
  onInputChange: func,
  onBlur: func,
  fieldName: string,
  valueType: string,
  handleSearchOptions: func,
  loadOptions: bool,
  isMulti: bool,
  helperText: node,
};

AutocompleteMultiple.defaultProps = {
  label: '',
  error: false,
  options: [],
  value: [],
  defaultValue: [],
  className: '',
  onChange: false,
  disabled: false,
  limitsToShow: 100,
  placeholder: '',
  containerClassName: '',
  onInputChange: null,
  onBlur: null,
  fieldName: 'value',
  valueType: 'arrayOfString',
  handleSearchOptions: null,
  loadOptions: false,
  isMulti: true,
  helperText: null,
};

export default AutocompleteMultiple;
