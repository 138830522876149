/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

import {
  mapPrefixToFilters,
} from '../utils/mappers';

import {
  mapFilters,
} from '../../../../../util/attributesMatrixMapping';

export const useSearchFilter = ({
  setHiddenProductsExist,
  setHiddenProductsIds,
  fetchProductList,
  setSelectedSearchFilter,
  selectedSearchFilter,
  compoundSearch,
  isHiddenProductsMode,
  hiddenProductsFilter,
  facetsPreFilters,
  pagination,
  searchQuery,
  selectedAction,
  previewFetched,
  navigationUpdated,
  item,
  attributesMatrixList,
}) => {
  const findAndUpdateFilterById = (searchFilter) => {
    const { virtualParams } = searchFilter;
    const { filters } = virtualParams || {};
    const hiddenProductsExist = filters
      ? filters.find(f => f.group
        .some(fG => fG.field === 'id' && fG.operator === 'nin'))
      : false;
    const currentGroup = hiddenProductsExist
      ? hiddenProductsExist.group.find(g => g.field === 'id')
      : {};

    setHiddenProductsExist(!!hiddenProductsExist);
    setHiddenProductsIds(currentGroup.value || []);
  };

  useEffect(() => {
    if (navigationUpdated && !isEmpty(selectedSearchFilter)) {
      const visibilityActions = new Set(['hide', 'show', 'unpin', 'pin_to_top', 'pin_to_position', 'pin_to_bottom']);

      fetchProductList({
        viewMode: 'frontendWithParams',
        compoundSearch,
        filter: isHiddenProductsMode
          ? hiddenProductsFilter
          : mapPrefixToFilters(facetsPreFilters),
        pagination,
        searchFilter: isHiddenProductsMode
          ? {}
          : searchQuery,
        ignoreFacets: visibilityActions.has(selectedAction),
      });

      setSelectedSearchFilter({
        ...item,
        virtualParams: {
          ...item.virtualParams,
          filters: mapFilters(
            item.virtualParams.filters,
            attributesMatrixList,
          ),
        },
      });
    }
  }, [navigationUpdated]);

  useEffect(() => {
    if (!isEmpty(selectedSearchFilter)) {
      findAndUpdateFilterById(selectedSearchFilter);
    }
  }, [
    selectedSearchFilter,
    navigationUpdated,
    previewFetched,
  ]);
};

export default useSearchFilter;
