/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState, useEffect } from 'react';
import {
  func, string, bool,
} from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import MatButton from '@material-ui/core/Button';

import ScopeDialog from 'components/ScopeDialog';
import Can from 'components/Can';
import IntlMessages from 'util/IntlMessages';
import {
  searchParams,
} from 'util/categoriesSearchParams';
import appPermissions from 'util/appPermissions';

import {
  fetchOne,
  fetchChildCategories,
  searchCategories,
  clearSearchListState,
  onToggleScopeCategoryTreeCollapse,
  handleLoadScopeSubcategories,
  loadAllChildCategories,
  fetchRootCategories,
  setSelectedScopeCategoryId,
  fetchParentScopeCategory,
} from 'actions/scopeCategory';

const ParentCategory = memo((props) => {
  const [showScopeDialog, setShowScopeDialog] = useState(false);
  const [selectedScopeCategory, setSelectedScopeCategory] = useState(false);

  useEffect(() => {
    props.fetchRootCategories();
  }, []);

  useEffect(() => {
    if (!isEmpty(props.parentCategoryId)) {
      props.fetchParentScopeCategory(props.parentCategoryId);
    }
  }, [props.parentCategoryId]);

  useEffect(() => {
    if (!isEmpty(props.scopeCategory.selectedScopeCategoryId)) {
      const selectedScopeCategory = props.scopeCategory.treeItems.find(
        c => c.id === props.scopeCategory.selectedScopeCategoryId,
      );
      if (!isEmpty(selectedScopeCategory)) {
        setSelectedScopeCategory(selectedScopeCategory);
      }
    }
  }, [props.scopeCategory.selectedScopeCategoryId]);

  useEffect(() => {
    if (props.scopeCategory.fetchParentScopeCategoryFinished) {
      setSelectedScopeCategory(props.scopeCategory.parentScopeCategory);
      props.setSelectedScopeCategoryId(props.scopeCategory.parentScopeCategory.id);
    }
  }, [
    props.scopeCategory.parentScopeCategory.id,
  ]);

  const closeScopeDialog = () => setShowScopeDialog(false);
  
  const commitCheckedCategories = (selectedCategory) => {
    setShowScopeDialog(false);
    props.setSelectedScopeCategoryId(selectedCategory[0] || {});

    if (selectedCategory[0] && props.scopeCategory.item.id !== selectedCategory[0]) {
      props.fetchOne(selectedCategory[0]);
    }
  };

  const handleScopeDialogChange = (selectedCategory) => {
    props.setSelectedScopeCategoryId(selectedCategory[0] || {});
  };

  const handleFetchChildCategories = id => props.fetchChildCategories(id);

  const handleSearchScopeCategory = (query) => {
    props.searchCategories(searchParams({
      filter: { value: 'true' },
      full_text: { value: query },
    }));
  };

  const openScopeDialog = () => setShowScopeDialog(true);

  return (
    <>
      <Can
        do={appPermissions.category.permissions.update}
        on={appPermissions.category.name}
      >
        <MatButton
          disabled={props.disabled}
          variant="contained"
          color="primary"
          className="text-white text-ellipses btn-w-lg block"
          onClick={openScopeDialog}
        >
          <span className="btn-subtitle mr-5">
            <IntlMessages id="button.parentCategoryLabel" />
          </span>
          {
            selectedScopeCategory && selectedScopeCategory.name
              ? selectedScopeCategory.name.en
              : <IntlMessages id="button.rootLabel" />
          }
        </MatButton>
      </Can>
      <ScopeDialog
        isRadio
        dialog={{
          closeButtonTitle: <IntlMessages id="virtualCategoryPage.cancelScopeDialogButtonLabel" />,
          submitButtonTitle: <IntlMessages id="virtualCategoryPage.selectScopeDialogButtonLabel" />,
        }}
        search={{
          noResult: props.scopeCategory.searched && isEmpty(props.scopeCategory.searchResults),
          errorMessage: 'text.errors.lessThan500Characters',
          queryLengthLimit: 500,
          placeholder: 'Search virtual categories ...',
          list: props.scopeCategory.searchResults,
        }}
        showScopeDialog={showScopeDialog}
        collapsed={props.scopeCategory.collapse}
        loadedKeys={props.scopeCategory.loadedKeys}
        selectedTreeItems={
          !isEmpty(props.scopeCategory.selectedScopeCategoryId) ? [props.scopeCategory.selectedScopeCategoryId] : []
        }
        treeItems={props.scopeCategory.treeItems}
        closeScopeDialog={closeScopeDialog}
        onSubmit={commitCheckedCategories}
        onChange={handleScopeDialogChange}
        fetchChildCategories={handleFetchChildCategories}
        onSearchSubmit={handleSearchScopeCategory}
        toogleTreeComponents={props.clearSearchListState}
        onToggleCategoryTreeCollapse={props.onToggleScopeCategoryTreeCollapse}
        handleLoadSubcategories={props.handleLoadScopeSubcategories}
        loadAllChildCategories={props.loadAllChildCategories}
      />
    </>
  );
});

ParentCategory.propTypes = {
  fetchOne: func.isRequired,
  fetchChildCategories: func.isRequired,
  searchCategories: func.isRequired,
  clearSearchListState: func.isRequired,
  onToggleScopeCategoryTreeCollapse: func.isRequired,
  handleLoadScopeSubcategories: func.isRequired,
  loadAllChildCategories: func.isRequired,
  fetchRootCategories: func.isRequired,
  setSelectedScopeCategoryId: func.isRequired,
  fetchParentScopeCategory: func.isRequired,
  parentCategoryId: string,
  disabled: bool,
};

ParentCategory.defaultProps = {
  parentCategoryId: '',
  disabled: false,
};

const mapStateToProps = state => ({
  scopeCategory: state.scopeCategory,
});

const actionCreators = {
  fetchOne,
  fetchChildCategories,
  searchCategories,
  clearSearchListState,
  onToggleScopeCategoryTreeCollapse,
  handleLoadScopeSubcategories,
  loadAllChildCategories,
  fetchRootCategories,
  setSelectedScopeCategoryId,
  fetchParentScopeCategory,
};

export default connect(mapStateToProps, actionCreators)(ParentCategory);
