import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  SET_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE,
} from '../actions/types';

const initialState = {};
const categoryVirtualFacetRestTypes = restTypes('category_virtual_facet');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case categoryVirtualFacetRestTypes.createStart:
      return {
        ...state,
        ...restStates.createStart,
      };
    case categoryVirtualFacetRestTypes.createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        ...action.payload,
      };
    case categoryVirtualFacetRestTypes.fetchStart:
      return {
        ...state,
        ...restStates.fetchStart,
      };
    case categoryVirtualFacetRestTypes.fetchFinished:
      return {
        ...state,
        ...restStates.fetchFinished,
        ...action.payload,
      };
    case categoryVirtualFacetRestTypes.deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
      };
    case categoryVirtualFacetRestTypes.deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };
    case categoryVirtualFacetRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case categoryVirtualFacetRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case categoryVirtualFacetRestTypes.updateStart:
      return {
        ...state,
        ...restStates.updateStart,
      };
    case categoryVirtualFacetRestTypes.updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };
    case categoryVirtualFacetRestTypes.clearStateFinished:
      return {
        ...initialState,
        ...restInitState,
        ...action.payload,
      };
    case categoryVirtualFacetRestTypes.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case SET_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
};
