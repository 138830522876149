import React, { useState } from 'react';
import {
  func, string, bool, number,
} from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import {
  FormControl, IconButton, InputAdornment, OutlinedInput,
} from '@material-ui/core';


import IntlMessages from '../../util/IntlMessages';

export const SearchField = ({
  onChange, className, wrapperClassName, disabled, value,
  errorMessage, queryLengthLimit, placeholder,
  onSearch, isShowSearchButton, onSearchKeyPress,
  helperText,
}) => {
  const [hasError, setHasError] = useState('');

  const handleSearchChange = (e) => {
    const { target } = e;
    if (target.value && target.value.length > queryLengthLimit) {
      setHasError(true);
      return;
    }
    setHasError(false);
    onChange(e);
  };
  return (
    <div className={`search-wrapper ${wrapperClassName}`}>
      {hasError && (
        <span className="input-placeholder">
          <IntlMessages id={errorMessage} />
        </span>
      )}
      {helperText && !hasError && (
        <span className="input-placeholder warning">
          <IntlMessages id={helperText} />
        </span>
      )}
      <FormControl className={`search-input-lg block ${className}`} variant="outlined">
        <OutlinedInput
          fullWidth
          id="search-input-lg"
          type="search"
          className={`search-input-lg ${className}`}
          placeholder={placeholder}
          onChange={handleSearchChange}
          disabled={disabled}
          value={value}
          onKeyPress={onSearchKeyPress}
          endAdornment={isShowSearchButton && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={onSearch}
                disabled={disabled}
                edge="end"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )}
        />
      </FormControl>
    </div>
  );
};

SearchField.propTypes = {
  onChange: func,
  className: string,
  wrapperClassName: string,
  disabled: bool,
  value: string,
  helperText: string,
  errorMessage: string,
  queryLengthLimit: number,
  placeholder: string,
  isShowSearchButton: bool,
  onSearchKeyPress: func,
  onSearch: func,
};

SearchField.defaultProps = {
  onChange: null,
  className: '',
  wrapperClassName: '',
  disabled: false,
  value: '',
  helperText: '',
  errorMessage: '',
  queryLengthLimit: null,
  placeholder: '',
  isShowSearchButton: false,
  onSearchKeyPress: null,
  onSearch: null,
};

export default SearchField;
