import { createSelector } from 'reselect';

const productRootSelector = state => state;

const stateSelector = createSelector(
  productRootSelector,
  state => ({
    edit: state.edit,
    editInProgress: state.editInProgress,
    isSaveAndClose: state.isSaveAndClose,
    updated: state.updated,
    fetchedOne: state.fetchedOne,
    fetchedPureProduct: state.fetchedPureProduct,
    item: state.item,
    bulkDeleteProductLockedAttributesFinished: state.bulkDeleteProductLockedAttributesFinished,
  }),
);

export const productSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  productSelector,
};
