export default {
  'sidebar.app': 'App',
  'sidebar.news': 'News',
  'sidebar.horizontal': 'Horizontal',
  'sidebar.horizontalMenu': 'Horizontal Menu',
  'sidebar.general': 'General',
  'sidebar.component': 'Component',
  'sidebar.features': 'Features',
  'sidebar.applications': 'Applications',
  'sidebar.dashboard': 'Dashboard',
  'sidebar.dashboard1': 'Dashboard 1',
  'sidebar.dashboard2': 'Dashboard 2',
  'sidebar.dashboard3': 'Dashboard 3',
  'sidebar.management': 'Management',
  'sidebar.displayFields': 'Display Fields',
  'sidebar.preview': 'Preview',
  'sidebar.modules': 'Modules',
  'sidebar.agency': 'Agency',
  'sidebar.pages': 'Pages',
  'sidebar.gallery': 'Gallery',
  'sidebar.pricing': 'Pricing',
  'sidebar.terms&Conditions': 'Terms & Conditions',
  'sidebar.feedback': 'Feedback',
  'sidebar.report': 'Report',
  'sidebar.faq(s)': 'Faq(s)',
  'sidebar.advancedComponent': 'Advance Component',
  'sidebar.blank': 'Blank',
  'sidebar.session': 'Session',
  'sidebar.login': 'Login',
  'sidebar.register': 'Register',
  'sidebar.lockScreen': 'Lock Screen',
  'sidebar.forgotPassword': 'Forgot Password',
  'sidebar.404': '404',
  'sidebar.500': '500',
  'sidebar.uiComponents': 'UI Components',
  'sidebar.alerts': 'Alerts',
  'sidebar.appBar': 'App Bar',
  'sidebar.avatars': 'Avatars',
  'sidebar.buttons': 'Buttons',
  'sidebar.bottomNavigations': 'Bottom Navigations',
  'sidebar.badges': 'Badges',
  'sidebar.cards': 'Cards',
  'sidebar.cardsMasonry': 'Cards Masonry',
  'sidebar.chip': 'Chip',
  'sidebar.dialog': 'Dialog',
  'sidebar.dividers': 'Dividers',
  'sidebar.drawers': 'Drawers',
  'sidebar.popover': 'Popover',
  'sidebar.expansionPanel': 'Expansion Panel',
  'sidebar.gridList': 'Grid List',
  'sidebar.list': 'List',
  'sidebar.menu': 'Menu',
  'sidebar.popoverAndToolTip': 'Pop Over & ToolTip',
  'sidebar.progress': 'Progress',
  'sidebar.snackbar': 'Snackbar',
  'sidebar.selectionControls': 'Selection Controls',
  'sidebar.advanceUiComponents': 'Advance UI Components',
  'sidebar.dateAndTimePicker': 'Date & Time Picker',
  'sidebar.tabs': 'Tabs',
  'sidebar.stepper': 'Stepper',
  'sidebar.notification': 'Notification',
  'sidebar.sweetAlert': 'Sweet Alert',
  'sidebar.autoComplete': 'Auto Complete',
  'sidebar.aboutUs': 'About Us',
  'sidebar.widgets': 'Widgets',
  'sidebar.forms': 'Forms',
  'sidebar.formElements': 'Form Elements',
  'sidebar.textField': 'Text Field',
  'sidebar.selectList': 'Select List',
  'sidebar.charts': 'Charts',
  'sidebar.reCharts': 'Re Charts',
  'sidebar.reactChartjs2': 'React Chartjs 2',
  'sidebar.icons': 'Icons',
  'sidebar.themifyIcons': 'Themify Icons',
  'sidebar.simpleLineIcons': 'Simple Line Icons',
  'sidebar.materialIcons': 'Material Icons',
  'sidebar.fontAwesome': 'Font Awesome',
  'sidebar.tables': 'Tables',
  'sidebar.basic': 'Basic',
  'sidebar.dataTable': 'Data Table',
  'sidebar.responsive': 'Responsive',
  'sidebar.reactTable': 'React Table',
  'sidebar.maps': 'Maps',
  'sidebar.googleMaps': 'Google Maps',
  'sidebar.leafletMaps': 'Leaflet Maps',
  'sidebar.inbox': 'Inbox',
  'sidebar.users': 'Users',
  'sidebar.userProfile1': 'User Profile 1',
  'sidebar.userProfile2': 'User Profile 2',
  'sidebar.userManagement': 'User Management',
  'sidebar.userProfile': 'User Profile',
  'sidebar.userList': 'User List',
  'sidebar.calendar': 'Calendar',
  'sidebar.cultures': 'Cultures',
  'sidebar.dnd': 'Dnd',
  'sidebar.selectable': 'Selectable',
  'sidebar.customRendering': 'Custom Rendering',
  'sidebar.chat': 'Chat',
  'sidebar.toDo': 'ToDo',
  'sidebar.editor': 'Editor',
  'sidebar.wysiwygEditor': 'WYSIWYG Editor',
  'sidebar.quillEditor': 'Quill Editor',
  'sidebar.reactAce': 'React Ace',
  'sidebar.dragAndDrop': 'Drag And Drop',
  'sidebar.reactDragula': 'React Dragula',
  'sidebar.reactDnd': 'React Dnd',
  'sidebar.blogManagement': 'Blog Management',
  'sidebar.ecommerce': 'Ecommerce',
  'sidebar.shopList': 'Shop List',
  'sidebar.shopGrid': 'Shop Grid',
  'sidebar.invoice': 'Invoice',
  'sidebar.multilevel': 'Multilevel',
  'sidebar.sublevel': 'Sublevel',
  'sidebar.crypto': 'Crypto',
  'sidebar.marketCap': 'Market cap',
  'sidebar.wallet': 'wallet',
  'sidebar.trade': 'trade',
  'sidebar.crm': 'CRM',
  'sidebar.rankings': 'Rankings',
  'sidebar.new': 'New Rankings',
  'sidebar.bulkImport': 'Bulk Import Categories',
  'sidebar.saas': 'SAAS',
  'sidebar.multiLevel': 'MultiLevel',
  'sidebar.level1': 'Level 1',
  'sidebar.level2': 'Level 2',
  'sidebar.boxed': 'Boxed',
  'sidebar.extensions': 'Extensions',
  'sidebar.imageCropper': 'Image Cropper',
  'sidebar.videoPlayer': 'Video Player',
  'sidebar.dropzone': 'Dropzone',
  'sidebar.shop': 'Shop',
  'sidebar.cart': 'Cart',
  'sidebar.checkout': 'Checkout',
  'sidebar.user': 'User',
  'sidebar.miscellaneous': 'Miscellaneous',
  'sidebar.promo': 'Promo',
  'sidebar.gettingStarted': 'Getting Started',
  'sidebar.dateTimePicker': 'Date & Time Picker',
  'sidebar.rankingsTitle': 'Rankings',
  'sidebar.mappersTitle': 'Mapping Filters',
  'sidebar.mappersCategories': 'Categories',
  'sidebar.mappersAllCategories': 'All',
  'sidebar.mappersMarkedCategories': 'Interested',
  'sidebar.mappersNoMarkedCategories': 'No categories in the "Interested"',
  'sidebar.mapper.alpanumerical': 'The field must contain only numbers and letters',
  'sidebar.mapper.webid': 'The value should consist of 2 capital letters at the beginning and 8 numbers at the end (ex.: AB12345678)',
  'sidebar.mapper.removingCategories': 'Are you sure you want to permanently delete all the categories you have saved?',
  'sidebar.mapper.removingCategoriesTitle': 'Removing Interested categories',
  'sidebar.mapper.url': 'The field must be an URL address',
  'sidebar.mapperFilterLogic': 'Filter Logic',
  'sidebar.mapperFilterDisabled': 'You can filter data only with a regular search',
  'sidebar.mapperSearchParsingError': 'Search parsing error',
  'sidebar.mapperSearchParsingError1': 'Search parsing error: one of the fields has no value',
  'sidebar.itemsPerPage': 'Items Per Page',
  'sidebar.projects': 'projects',
  'sidebar.projectDetail': 'project Detail',
  'sidebar.clients': 'clients',
  'sidebar.reports': 'reports',
  'sidebar.categoriesTitle': 'Site Categories',
  'sidebar.searchFilterTitle': 'Search Filters',
  'sidebar.searchFilterName': 'Name',
  'sidebar.influencer.title': 'Influencer',
  'sidebar.total': 'Total',
  'sidebar.found': 'Found',
  'sidebar.records': 'Records',
  'sidebar.enableDateFilter': 'Enable Date Filter',
  'sidebar.disableDateFilter': 'Disable Date Filter',
  'sidebar.camsFilter': 'Filter Target',

  'components.buyNow': 'Buy Now',
  'compenets.choose': 'Choose',
  'compenets.username': 'Username',
  'compenets.passwords': 'Passwords',
  'compenets.signIn': 'Sign In',
  'compenets.dontHaveAccountSignUp': 'Dont Have Account SignUp',
  'compenets.enterUserName': 'Enter User Name',
  'compenets.enterEmailAddress': 'Enter Email Address',
  'compenets.confirmPasswords': 'Confirm Passwords',
  'components.alreadyHavingAccountSignIn': 'Already Having Account Sign In',
  'components.enterYourPassword': 'Enter Your Password',
  'components.unlock': 'Unlock',
  'components.enterPasswords': 'Enter Passwords',
  'components.resestPassword': 'Resest Password',
  'components.pageNotfound': 'Page not Found',
  'components.goToHomePage': 'Go To Home Page',
  'components.sorryServerGoesWrong': 'Sorry Server Goes Wrong',
  'components.persistentDrawer': 'Persistent Drawer',
  'components.withHtml': 'With Html',
  'components.prompt': 'Prompt',
  'components.withDescription': 'With Description',
  'components.success': 'Success',
  'components.passwordPrompt': 'Password Prompt',
  'components.warning': 'Warning',
  'components.customIcon': 'Custom Icon',
  'components.customStyle': 'Custom Style',
  'components.basic': 'Basic',
  'components.submit': 'Submit',
  'components.compose': 'Compose',
  'components.sendMessage': 'Send Message',
  'components.addNewTasks': 'Add New Tasks',
  'components.addToCart': 'Add To Cart',
  'components.payNow': 'Pay Now',
  'components.print': 'Print',
  'components.cart': 'Cart',
  'components.viewCart': 'View Cart',
  'components.checkout': 'Checkout',
  'components.summary': 'Summary',
  'components.yesterday': 'Yesterday',
  'components.last7Days': 'Last 7 Days',
  'components.last1Month': 'Last 1 Month',
  'components.last6Month': 'Last 6 Month',
  'components.spaceUsed': 'Space Used',
  'components.followers': 'Follower',
  'components.trending': 'Trending',
  'components.paid': 'Paid',
  'components.refunded': 'Refunded',
  'components.done': 'Done',
  'components.pending': 'Pending',
  'components.cancelled': 'Cancelled',
  'components.approve': 'Approve',
  'components.week': 'Week',
  'components.month': 'Month',
  'components.year': 'Year',
  'components.today': 'Today',
  'components.popularity': 'Popularity',
  'components.email': 'Email',
  'components.drafts': 'Drafts',
  'components.sent': 'Sent',
  'components.trash': 'Trash',
  'components.all': 'All',
  'components.do': 'Do',
  'components.title': 'Title',
  'components.projectName': 'Project Name',
  'components.companyName': 'Company Name',
  'components.openAlert': 'Open Alert',
  'components.slideInAlertDialog': 'Slide In Alert Dialog',
  'components.openFullScreenDialog': 'Open Full Screen Dialogs',
  'components.basicChip': 'Basic Chip',
  'components.clickableChip': 'Clickable Chip',
  'components.left': 'Left',
  'components.right': 'Right',
  'components.expansionPanel1': 'Expansion Panel 1',
  'components.expansionPanel2': 'Expansion Panel 2',
  'components.generalSetting': 'General Setting',
  'components.advancedSettings': 'Advanced Settings',
  'components.firstName': 'First Name',
  'components.lastName': 'Last Name',
  'components.occupation': 'Occupation',
  'components.phoneNo': 'Phone No',
  'components.address': 'Address',
  'components.city': 'City',
  'components.state': 'State',
  'components.zipCode': 'Zip Code',
  'components.social Connection': 'Social Connection',
  'components.product': 'Product',
  'components.variant': 'Variant',
  'components.merchants': 'Merchants',
  'components.noMerchants': 'This product has no merchants',
  'components.quantity': 'Quantity',
  'components.totalPrice': 'Total Price',
  'components.removeProduct': 'Remove Product',
  'components.mobileNumber': 'Mobile Number',
  'components.address2Optional': 'Address 2 (Optional)',
  'components.country': 'Country',
  'components.zip': 'Zip',
  'components.saveContinue': 'Save & Continue',
  'components.placeOrder': 'Place Order',
  'components.payment': 'Payment',
  'components.billingAddress': 'Billing Address',
  'components.ShippingAddressText': 'Shipping address is the same as billing address.',
  'components.CartEmptyText': 'Your Shopping Cart Is Empty!',
  'components.NoItemFound': 'No Item Found',
  'components.goToShop': 'Go To Shop',
  'components.cardNumber': 'Card Number',
  'components.expiryDate': 'Expiry Date',
  'components.cvv': 'CVV',
  'components.nameOnCard': 'Name On Card',
  'components.confirmPayment': 'Confirm Payment',
  'components.emailPrefrences': 'Email Prefrences',
  'components.myProfile': 'My Profile',

  'widgets.totalEarns': 'Total Earns',
  'widgets.emailsStatistics': 'Emails Statistics',
  'widgets.totalRevenue': 'Total Revenue',
  'widgets.onlineVistors': 'Online Vistors',
  'widgets.trafficSources': 'Traffic Sources',
  'widgets.RecentOrders': 'Recent Orders',
  'widgets.topSellings': 'Top Sellings',
  'widgets.productReports': 'Product Reports',
  'widgets.productStats': 'Product Stats',
  'widgets.ComposeEmail': 'Compose Email',
  'widgets.employeePayroll': 'Employee Payroll',
  'widgets.visitors': 'Visitors',
  'widgets.orders': 'Orders',
  'widgets.orderStatus': 'Order Status',
  'widgets.totalSales': 'Total Sales',
  'widgets.netProfit': 'Net Profit',
  'widgets.overallTrafficStatus': 'Overall Traffic Status',
  'widgets.tax': 'Tax',
  'widgets.expenses': 'Expenses',
  'widgets.currentTime': 'Current Time',
  'widgets.currentDate': 'Current Date',
  'widgets.todayOrders': 'Today Orders',
  'widgets.toDoList': 'To Do Lists',
  'widgets.discoverPeople': 'Discover People',
  'widgets.commments': 'Comments',
  'widgets.newCustomers': 'New Customers',
  'widgets.recentNotifications': 'Recent Notifications',
  'widgets.appNotifications': 'App Notifications',
  'widgets.ratings': 'Ratings',
  'widgets.newEmails': 'New Emails',
  'widgets.siteVisitors': 'Site Visitors',
  'widgets.socialCompanines': 'Social Companines',
  'widgets.recentActivities': 'Recent Activities',
  'widgets.recentOrders': 'Recent Orders',
  'widgets.gallery': 'Gallery',
  'widgets.pricing': 'Pricing',
  'widgets.enterpriseEdition': 'Enterprise Edition',
  'widgets.personalEdition': 'Personal Edition',
  'widgets.teamEdition': 'Team Edition',
  'widgets.standard': 'Standard',
  'widgets.advanced': 'Advanced',
  'widgets.master': 'Master',
  'widgets.Mega': 'Mega',
  'widgets.logIn': 'Log In',
  'widgets.signUp': 'Sign Up',
  'widgets.lockScreen': 'Lock Screen',
  'widgets.alertsWithLink': 'Alerts With Link',
  'widgets.additionalContent': 'Additional Content',
  'widgets.alertDismiss': 'Alert Dismiss',
  'widgets.uncontrolledDisableAlerts': 'Uncontrolled Disable Alerts',
  'widgets.contexualAlerts': 'Contexual Alerts',
  'widgets.alertsWithIcons': 'Alerts With Icons',
  'widgets.Simple App Bars': 'Simple App Bars',
  'widgets.appBarsWithButtons': 'App Bars With Buttons',
  'widgets.imageAvatars': 'Image Avatars',
  'widgets.lettersAvatars': 'Letters Avatars',
  'widgets.iconsAvatars': 'Icons Avatars',
  'widgets.flatButtons': 'Flat Buttons',
  'widgets.raisedButton': 'Raised Button',
  'widgets.buttonWithIconAndLabel': 'Button With Icon And Label',
  'widgets.floatingActionButtons': 'Floating Action Buttons',
  'widgets.iconButton': 'Icon Button',
  'widgets.socialMediaButton': 'Social Media Button',
  'widgets.reactButton': 'React Button',
  'widgets.buttonOutline': 'Button Outline',
  'widgets.buttonSize': 'Button Size',
  'widgets.buttonState': 'Button State',
  'widgets.buttonNavigationWithNoLabel': 'button Navigation With No Label',
  'widgets.buttonNavigation': 'Button Navigation',
  'widgets.iconNavigation': 'Icon Navigation',
  'widgets.badgeWithHeadings': 'Badge With Headings',
  'widgets.contexualVariations': 'Contexual Variations',
  'widgets.badgeLinks': 'Badge Links',
  'widgets.materialBadge': 'Material Badge',
  'widgets.simpleCards': 'Simple Cards',
  'widgets.backgroundVarient': 'Background Varient',
  'widgets.cardOutline': 'Card Outline',
  'widgets.overlayCard': 'Overlay Card',
  'widgets.cardGroup': 'Card Group',
  'widgets.cardTitle': 'Card Title',
  'widgets.speacialTitleTreatment': 'Speacial Title Treatment',
  'widgets.chipWithClickEvent': 'Chip With Click Event',
  'widgets.chipArray': 'Chip Array',
  'widgets.dialogs': 'Dialogs',
  'widgets.listDividers': 'List Dividers',
  'widgets.insetDividers': 'Inset Dividers',
  'widgets.temporaryDrawers': 'Temporary Drawers',
  'widgets.permanentDrawers': 'Permanent Drawers',
  'widgets.simpleExpansionPanel': 'Simple Expansion Panel',
  'widgets.controlledAccordion': 'Controlled Accordion',
  'widgets.secondaryHeadingAndColumns': 'Secondary Heading And Columns',
  'widgets.imageOnlyGridLists': 'Image Only Grid Lists',
  'widgets.advancedGridLists': 'Advanced Grid Lists',
  'widgets.singleLineGridLists': 'Single Line Grid Lists',
  'widgets.simpleLists': 'Simple Lists',
  'widgets.folderLists': 'Folder Lists',
  'widgets.listItemWithImage': 'List Item With Image',
  'widgets.switchLists': 'Switch Lists',
  'widgets.insetLists': 'Inset Lists',
  'widgets.nestedLists': 'Nested Lists',
  'widgets.checkboxListControl': 'Checkbox List Control',
  'widgets.pinedSubHeader': 'Pined Sub Header',
  'widgets.InteractiveLists': 'Interactive Lists',
  'widgets.simpleMenus': 'Simple Menus',
  'widgets.selectedMenu': 'Selected Menu',
  'widgets.maxHeightMenu': 'Max Height Menu',
  'widgets.changeTransition': 'Change Transition',
  'widgets.paper': 'Paper',
  'widgets.anchorPlayGround': 'Anchor Play Ground',
  'widgets.tooltip': 'ToolTip',
  'widgets.positionedToolTips': 'Positioned Snackbar',
  'widgets.circularProgressBottomStart': 'Circular Progress Bottom Start',
  'widgets.interactiveIntegration': 'Interactive Integration',
  'widgets.determinate': 'Determinate',
  'widgets.linearProgressLineBar': 'Linear Progress Line Bar',
  'widgets.indeterminate': 'Indeterminate',
  'widgets.buffer': 'Buffer',
  'widgets.query': 'Query',
  'widgets.transitionControlDirection': 'Transition Control Direction',
  'widgets.simpleSnackbar': 'Simple Snackbar',
  'widgets.positionedSnackbar': 'Positioned Snackbar',
  'widgets.contexualColoredSnackbars': 'Contexual Colored Snackbars',
  'widgets.simpleCheckbox': 'Simple Checkbox',
  'widgets.interminateSelection': 'Interminate Selection',
  'widgets.disabledCheckbox': 'Disabled Checkbox',
  'widgets.customColorCheckbox': 'Custom Color Checkbox',
  'widgets.VerticalStyleCheckbox': 'Vertical Style Checkbox',
  'widgets.horizontalStyleCheckbox': 'Horizontal Style Checkbox',
  'widgets.radioButtons': 'Radio Buttons',
  'widgets.disabledRadio': 'Disabled Radio',
  'widgets.withError': 'With Error',
  'widgets.switches': 'Swiches',
  'widgets.dateAndTimePicker': 'Date And Time Picker',
  'widgets.defaultPicker': 'Default Picker',
  'widgets.timePicker': 'Time Picker',
  'widgets.weekPicker': 'Week Picker',
  'widgets.defaultDatePicker': 'Default Date Picker',
  'widgets.customPicker': 'Custom Picker',
  'widgets.tabs': 'Tabs',
  'widgets.fixedTabs': 'Fixed Tabs',
  'widgets.basicTab': 'Basic Tab',
  'widgets.wrappedLabels': 'Wrapped Labels',
  'widgets.centeredLabels': 'Centered Labels',
  'widgets.forcedScrolledButtons': 'Forced Scrolled Buttons',
  'widgets.iconsTabs': 'Icons Tabs',
  'widgets.withDisableTabs': 'With Disable Tabs',
  'widgets.iconWithLabel': 'Icon With Label',
  'widgets.stepper': 'Stepper',
  'widgets.horizontalLinear': 'Horizontal Linear',
  'widgets.horizontalNonLinear': 'Horizontal Non Linear',
  'widgets.horizontalLinerAlternativeLabel': 'Horizontal Liner Alternative Label',
  'widgets.horizontalNonLinerAlternativeLabel': 'Horizontal Non Liner Alternative Label',
  'widgets.verticalStepper': 'Vertical Stepper',
  'widgets.descriptionAlert': 'Description Alert',
  'widgets.customIconAlert': 'Custom Icon Alert',
  'widgets.withHtmlAlert': 'With Html Alert',
  'widgets.promptAlert': 'Prompt Alert',
  'widgets.passwordPromptAlert': 'Password Prompt Alert',
  'widgets.customStyleAlert': 'Custom Style Alert',
  'widgets.autoComplete': 'Auto Complete',
  'widgets.reactSelect': 'React Select',
  'widgets.downshiftAutoComplete': 'Downshift Auto Complete',
  'widgets.reactAutoSuggests': 'React Auto Suggests',
  'widgets.aboutUs': 'About Us',
  'widgets.ourVission': 'Our Vission',
  'widgets.ourMissions': 'Our Missions',
  'widgets.ourMotivation': 'Our Motivation',
  'widgets.defualtReactForm': 'Defualt React Form',
  'widgets.url': 'Url',
  'widgets.textArea': 'Text Area',
  'widgets.file': 'File',
  'widgets.formGrid': 'Form Grid',
  'widgets.inlineForm': 'Inline Form',
  'widgets.inputSizing': 'Input Sizing',
  'widgets.inputGridSizing': 'Input Grid Sizing',
  'widgets.hiddenLabels': 'Hidden Labels',
  'widgets.formValidation': 'Form Validation',
  'widgets.number': 'Number',
  'widgets.date': 'Date',
  'widgets.time': 'Time',
  'widgets.error': 'Error',
  'widgets.trace': 'Trace',
  'widgets.showDetails': 'Show details',
  'widgets.editFilters': 'Edit Filters',
  'widgets.color': 'Color',
  'widgets.search': 'Search',
  'widgets.selectMultiple': 'Select Multiple',
  'widgets.inputWithSuccess': 'Input With Success',
  'widgets.inputWithDanger': 'Input With Danger',
  'widgets.simpleTextField': 'Simple Text Field',
  'widgets.componet': 'Components',
  'widgets.layouts': 'Layouts',
  'widgets.inputAdorements': 'Input Adorements',
  'widgets.formattedInputs': 'Formatted Inputs',
  'widgets.simpleSelect': 'Simple Select',
  'widgets.nativeSelect': 'Native Select',
  'widgets.MutltiSelectList': 'Mutlti Select List',
  'widgets.lineChart': 'Line Chart',
  'widgets.barChart': 'Bar Chart',
  'widgets.stackedBarChart': 'Stacked Bar Chart',
  'widgets.lineBarAreaChart': 'Line Bar Area Chart',
  'widgets.areaChart': 'Area Chart',
  'widgets.stackedAreaChart': 'Stacked Area Chart',
  'widgets.verticalChart': 'Vertical Chart',
  'widgets.radarChart': 'Radar Chart',
  'widgets.doughnut': 'Doughnut',
  'widgets.polarChart': 'Polar Chart',
  'widgets.pieChart': 'Pie Chart',
  'widgets.bubbleChart': 'Bubble Chart',
  'widgets.horizontalBar': 'Horizontal Bar',
  'widgets.basicTable': 'Basic Table',
  'widgets.contexualColoredTable': 'Contexual Colored Table',
  'widgets.dataTable': 'Data Table',
  'widgets.employeeList': 'Employee List',
  'widgets.responsiveTable': 'Responsive Table',
  'widgets.responsiveFlipTable': 'Responsive Flip Table',
  'widgets.reactGridControlledStateMode': 'React Grid Controlled State Mode',
  'widgets.productsReports': 'Products Reports',
  'widgets.taskList': 'Task List',
  'widgets.basicCalender': 'Basic Calender',
  'widgets.culturesCalender': 'Cultures Calender',
  'widgets.dragAndDropCalender': 'Drag And Drop Calender',
  'widgets.selectableCalender': 'Selectable Calender',
  'widgets.customRendering': 'Custom Rendering',
  'widgets.customCalender': 'Custom Calender',
  'widgets.searchMailList': 'Search Mail List',
  'widgets.forgetPassword': 'Forget Password',
  'widgets.QuickLinks': 'Quick Links',
  'widgets.upgrade': 'upgrade',
  'widgets.app': 'App',
  'widgets.addNew': 'Add New',
  'widgets.orderDate': 'Order Date',
  'widgets.status': 'Status',
  'widgets.trackingNumber': 'Tracking Number',
  'widgets.action': 'Action',
  'widgets.designation': 'Designation',
  'widgets.subject': 'subject',
  'widgets.send': 'Send',
  'widgets.saveAsDrafts': 'Save As Drafts',
  'widgets.onlineSources': 'Online Sources',
  'widgets.lastMonth': 'Last Month',
  'widgets.widgets': 'Widgets',
  'widgets.listing': 'Listing',
  'widgets.paid': 'Paid',
  'widgets.refunded': 'Refunded',
  'widgets.done': 'Done',
  'widgets.pending': 'Pending',
  'widgets.cancelled': 'Cancelled',
  'widgets.approve': 'Approve',
  'widgets.following': 'Following',
  'widgets.follow': 'follow',
  'widgets.graphs&Charts': 'Graphs & Charts',
  'widgets.open': 'Open',
  'widgets.bounced': 'Bounced',
  'widgets.spam': 'Spam',
  'widgets.unset': 'Unset',
  'widgets.bandwidthUse': 'Bandwidth Use',
  'widgets.dataUse': 'Data Use',
  'widgets.unsubscribe': 'Unsubscribe',
  'widgets.profile': 'Profile',
  'widgets.messages': 'Messages',
  'widgets.support': 'Support',
  'widgets.faq(s)': 'Faq(s)',
  'widgets.upgradePlains': 'Upgrade Plains',
  'widgets.logOut': 'Log Out',
  'widgets.mail': 'Mail',
  'widgets.adminTheme': 'Admin Theme',
  'widgets.wordpressTheme': 'Wordpress Theme',
  'widgets.addToCart': 'Add To Cart',
  'widgets.plan': 'Plan',
  'widgets.basic': 'Basic',
  'widgets.pro': 'Pro',
  'widgets.startToBasic': 'Start To Basic',
  'widgets.upgradeToPro': 'Upgrade To Pro',
  'widgets.upgradeToAdvance': 'Upgrade To Advance',
  'widgets.comparePlans': 'Compare Plans',
  'widgets.free': 'Free',
  'widgets.frequentlyAskedQuestions': 'Frequently Asked Questions',
  'widgets.searchIdeas': 'Search Ideas',
  'widgets.startDate': 'Start Date',
  'widgets.endDate': 'End Date',
  'widgets.category': 'Category',
  'widgets.promotion': 'Promotion',
  'widgets.apply': 'Apply',
  'widgets.yesterday': 'Yesterday',
  'widgets.totalOrders': 'Total Orders',
  'widgets.totalVisitors': 'Total Visitors',
  'widgets.typeYourQuestions': 'Type Your Questions',
  'widgets.username': 'Username',
  'widgets.password': 'Password',
  'widgets.signIn': 'Sign In',
  'widgets.enterYourPassword': 'Enter Your Password',
  'widgets.alreadyHavingAccountLogin': 'Already Having Account Login',
  'widgets.composeMail': 'Compose Mail',
  'widgets.issue': 'Issue',
  'widgets.recentChat': 'Recent Chat',
  'widgets.previousChat': 'Previous Chat',
  'widgets.all': 'All',
  'widgets.filters': 'Filters',
  'widgets.deleted': 'Deleted',
  'widgets.starred': 'Starred',
  'widgets.frontend': 'Frontend',
  'widgets.backend': 'Backend',
  'widgets.api': 'Api',
  'widgets.simpleAppBar': 'Simple App Bar',
  'widgets.recents': 'Recents',
  'widgets.cardLink': 'Card Link',
  'widgets.anotherLink': 'Another Link',
  'widgets.cardSubtitle': 'card Subtitle',
  'widgets.confirmationDialogs': 'Confirmation Dialogs',
  'widgets.deletableChip': 'Deletable Chip',
  'widgets.customDeleteIconChip': 'Custom Delete Icon Chip',
  'widgets.openAlertDialog': 'Open Alert Dialog',
  'widgets.openResponsiveDialog': 'Open Responsive Dialog',
  'widgets.openSimpleDialog': 'Open Simple Dialog',
  'widgets.openFormDialog': 'Open Form Dialog',
  'widgets.follower': 'Follower',
  'widgets.important': 'Important',
  'widgets.private': 'Private',
  'widgets.openLeft': 'Open Left',
  'widgets.openRight': 'Open Right',
  'widgets.openTop': 'Open Top',
  'widgets.openBottom': 'Open Bottom',
  'widgets.selectTripDestination': 'Select Trip Destination',
  'widgets.pinnedSubheaderList': 'Pinned Subheader List',
  'widgets.singleLineItem': 'Single Line Item',
  'widgets.optionA': 'Option A',
  'widgets.optionB': 'Option B',
  'widgets.optionC': 'Option C',
  'widgets.optionM': 'Option M',
  'widgets.optionN': 'Option N',
  'widgets.optionO': 'Option O',
  'widgets.customColor': 'Custom Color',
  'widgets.centeredTabs': 'Centered Tabs',
  'widgets.multipleTabs': 'Multiple Tabs',
  'widgets.preventScrolledButtons': 'Prevent Scrolled Buttons',
  'widgets.browse': 'Browse',
  'widgets.formValidate': 'Form Validate',
  'widgets.code': 'Code',
  'widgets.company': 'Company',
  'widgets.price': 'Price',
  'widgets.change': 'Change',
  'widgets.high': 'High',
  'widgets.low': 'Low',
  'widgets.volume': 'Volume',
  'widgets.personalDetails': 'Personal Details',
  'widgets.occupation': 'Occupation',
  'widgets.companyName': 'Company Name',
  'widgets.phoneNo': 'Phone No',
  'widgets.updateProfile': 'Update Profile',
  'widgets.reject': 'Reject',
  'widgets.exportToExcel': 'Export To Excel',
  'widgets.workWeek': 'Work Week',
  'widgets.agenda': 'Agenda',
  'widgets.conference': 'Conference',
  'widgets.multilevel': 'Multilevel',
  'widgets.dailySales': 'Daily Sales',
  'widgets.today': 'Today',
  'widgets.campaignPerformance': 'Campaign Performance',
  'widgets.supportRequest': 'Support Request',
  'widgets.usersList': 'Users List ',
  'widgets.lastWeek': 'Last Week',
  'widgets.buyMore': 'Buy More',
  'widgets.trafficChannel': 'Traffic Channel',
  'widgets.stockExchange': 'Stock Exchange',
  'widgets.tweets': 'Tweets',
  'widgets.ourLocations': 'Our Locations',
  'widgets.sales': 'Sales',
  'widgets.to': 'To',
  'widgets.shipTo': 'Ship To',
  'widgets.description': 'Description',
  'widgets.unitPrice': 'Unit Price',
  'widgets.total': 'Total',
  'widgets.note': 'Note',
  'widgets.chipWithAvatar': 'Chip With Avatar',
  'widgets.chipWithTextAvatar': 'Chip With Text Avatar',
  'widgets.chipWithIconAvatar': 'Chip With Icon Avatar',
  'widgets.customClickableChip': 'Custom Clickable Chip',
  'widgets.outlineChip': 'Outline Chip',
  'widgets.disableChip': 'Disable Chip',
  'widgets.alertDialog': 'Alert Dialog',
  'widgets.animatedSlideDialogs': 'Animated Slide Dialogs',
  'widgets.fullScreenDialogs': 'Full Screen Dialogs',
  'widgets.formDialogs': 'Form Dialogs',
  'widgets.simpleDialogs': 'Simple Dialogs',
  'widgets.responsiveFullScreen': 'Responsive Full Screen',
  'widgets.primary': 'Primary',
  'widgets.social': 'Social',
  'widgets.user': 'User',
  'widgets.admin': 'Admin',
  'widgets.permanentdrawer': 'Permanent Drawer',
  'widgets.persistentdrawer': 'Persistent Drawer',
  'widgets.swiches': 'Swiches',
  'widgets.horizontalLinearAlternativeLabel': 'Horizontal Linear Alternative Label',
  'widgets.horizontalNonLinearAlternativeLabel': 'Horizontal Non Linear Alternative Label',
  'widgets.notifications': 'Notifications',
  'widgets.basicAlert': 'Basic Alert',
  'widgets.successAlert': 'Success Alert',
  'widgets.warningAlert': 'Warning Alert',
  'widgets.reactAutoSuggest': 'React Auto Suggest',
  'widgets.components': 'Components',
  'widgets.inputAdornments': 'Input Adorements',
  'widgets.multiSelectList': 'Multi Select List',
  'widgets.contextualColoredTable': 'Contexual Colored Table',
  'widgets.updateYourEmailAddress': 'Update Your Email Address',
  'widgets.selectADefaultAddress': 'Select A Default Address',
  'widgets.activity': 'Activity',
  'widgets.basicCalendar': 'Basic Calendar',
  'widgets.culturesCalendar': 'Cultures Calendar',
  'widgets.dragAndDropCalendar': 'Drag And Drop Calendar',
  'widgets.quillEditor': 'Quill Editor',
  'widgets.reactDND': 'React DND',
  'widgets.dragula': 'Dragula',
  'widgets.baseConfig': 'Base Config',
  'widgets.customControlBar': 'Custom Control Bar',
  'widgets.withDownloadButton': 'With Download Button',
  'widgets.httpLiveStreaming': 'HTTP Live Streaming',
  'widgets.keyboardShortcuts': 'Keyboard Shortcuts',
  'widgets.preview': 'Preview',
  'widgets.croppedImage': 'Cropped Image',
  'widgets.transactionList': 'Transaction List',
  'widgets.transferReport': ' Transfer Report',
  'widgets.expenseCategory': 'Expense Category',
  'widgets.upcomingEvents': 'Upcoming events',
  'widgets.OngoingProjects': 'Ongoing Projects',
  'widgets.ProjectStatus': 'Project Status',
  'widgets.LiveChatSupport': 'Live Chat Support',
  'widgets.AcceptorrRejectWithin': 'Accept or reject within',
  'widgets.quoteOfTheDay': 'Quote Of The Day',
  'widgets.updated10Minago': 'Updated 10 min ago',
  'widgets.personalSchedule': 'Personal Schedule',
  'widgets.activeUsers': 'Active Users',
  'widgets.totalRequest': 'Total Request',
  'widgets.new': 'New',
  'widgets.ShareWithFriends': 'Share with friends!',
  'widgets.helpToShareText': 'Help us spread the world by sharing our website with your friends and followers on social media!',
  'widgets.thisWeek': 'This Week',
  'widgets.howWouldYouRateUs': 'How would you rate us?',
  'widgets.booking': 'Booking',
  'widgets.confirmed': 'Confirmed',
  'widgets.monthly': 'Monthly',
  'widgets.weekly': 'Weekly',
  'widgets.target': 'Target',
  'widgets.totalActiveUsers': 'Total Active Users',
  'widgets.deadline': 'Deadline',
  'widgets.team': 'Team',
  'widgets.projectManagement': 'Project Management',
  'widgets.latestPost': 'Latest Post',
  'widgets.projectTaskManagement': 'Project Task Management',
  'widgets.selectProject': 'Select Project',
  'widgets.activityBoard': 'Activity Board',
  'widgets.checklist': 'Checklist',

  'themeOptions.themeColor': 'Theme Color',
  'themeOptions.sidebarOverlay': 'Sidebar Overlay',
  'themeOptions.sidebarBackgroundImages': 'Sidebar Background Images',
  'themeOptions.appSettings': 'App Settings',
  'themeOptions.sidebarImage': 'Sidebar Image',
  'themeOptions.miniSidebar': 'Mini Sidebar',
  'themeOptions.boxLayout': 'Box Layout',
  'themeOptions.rtlLayout': 'Rtl Layout',
  'themeOptions.darkMode': 'Dark Mode',
  'themeOptions.sidebarLight': 'Light',
  'themeOptions.sidebarDark': 'Dark',

  'module.inbox': 'Inbox',
  'module.drafts': 'Drafts',
  'module.sent': 'Sent',
  'module.trash': 'Trash',
  'module.spam': 'Spam',
  'module.frontend': 'Frontend',
  'module.backend': 'Backend',
  'module.api': 'Api',
  'module.issue': 'Issue',

  'errors.selectSomeCategories': 'Please, select some category(ies)',

  'images.noImages': 'There are no images',

  'selectProductsDialog.search.button.search': 'Search',

  'editProductPopup.commentsLabel': 'Comments',

  'paper.actions.apply.label': 'Apply',

  'categoriesTree.tooltip.noPhysicalCategories': 'There are no physical categories',

  'hint.searchMailList': 'Search Mail List',
  'hint.whatAreYouLookingFor': 'What are You Looking For',

  'subheader.pim': 'Catalog',
  'subheader.mapper': 'Mapper',
  'subheader.repricing': 'Log',
  'subheader.activityLog': 'Activity Log',
  'subheader.rules': 'Rules',
  'subheader.camsErrors': 'Errors',
  'subheader.camsMerchantStatus': 'Merchant Status',
  'subheader.cams': 'Product Status',
  'subheader.repricer': 'Dashboard',
  'subheader.issues': 'Issues',

  'subheader.rankings': 'Rankings',
  'subheader.categories': 'Categories',
  'subheader.promotions': 'Promotions',
  'subheader.facets': 'Facets',
  'subheader.search': 'Search',
  'subheader.campaigns': 'Campaigns',
  'subheader.feeds': 'Feeds Manager',
  'subheader.publications': 'Publications',
  'subheader.system': 'System',
  'subheader.influencer': 'Influencer',
  'pageSubheader.systemPreview': 'Available attributes',

  'headerRightNav.preview': 'Preview',
  'headerRightNav.repricer': 'Repricer',
  'headerRightNav.pim': 'Pim',
  'headerRightNav.cams': 'Cams',
  'headerRightNav.username': 'User Name',
  'headerRightNav.logout': 'Log out',
  'headerRightNav.businessPortal': 'Business Portal',

  'text.useInNinOperatorsFilterByMessage': "To use 'In' or 'Not in' filter operators, you need to leave less than 2 product attributes",
  'text.useOtherOperatorsFilterByMessage': 'To use other filter operators, you need to leave less than 2 product attributes',
  'text.newFilterMessage': 'To create the filter, please enter new filter name here. Then you can see it in the left sidebar and use it like a quick filter.',
  'text.newFilterDialog': 'New Filter Dialog',
  'text.addNewUser': 'Add new User',
  'text.userUpdatedSuccessfully': 'User Updated Successfully!',
  'text.quickFilters': 'Quick filters',
  'text.selectCategories': 'Select Categories',
  'text.savedFilters': 'Saved filters',
  'text.userCreatedSuccessfully': 'User Created Successfully!',
  'text.somethingWentWrong': 'Something went wrong',
  'text.selectScope': 'Select a Scope',
  'text.availabelUsers': 'Available users',
  'text.addAUser': 'Add a user',
  'text.gotIt': 'Yes, got it!',
  'text.select': 'Select',
  'text.selectProducts': 'Select Products',
  'text.userPassword': 'User Password',
  'text.filterExist': 'Sorry, filter with this name already exists!',
  'text.searchType': 'Search type',
  'text.urlKey': 'URL key:',
  'text.searchFilters': 'Search filters',
  'text.promotionFilters': 'Promotion filters',
  'text.parentFiltersAttention': 'ATTENTION! This filter will not be used because for this category, the rules of the Parental filter apply',

  'buttons.add': 'Add',
  'buttons.cancel': 'Cancel',
  'buttons.save': 'Save',
  'buttons.first': 'First',
  'buttons.prev': 'Prevous',
  'buttons.next': 'Next',
  'buttons.last': 'Last',
  'buttons.search': 'Search',
  'buttons.reset': 'Reset',
  'buttons.productChangesHistory': 'Product Changes History',
  'buttons.saveAndClose': 'Save and Close',
  'buttons.delete': 'Delete',
  'buttons.close': 'Close',
  'buttons.export': 'Export',
  'buttons.addNewRule': 'Add New Rule',
  'buttons.unlockAllAttributes': 'Unlock All Attributes',
  'buttons.requestVideos': 'Request Videos',
  'buttons.apply': 'Apply',
  'buttons.clear': 'Clear',
  'button.newRanking': 'New Ranking',
  'button.newMapping': 'New Mapping',
  'button.newCategoryFilter': 'New Category Filter',
  'button.newRulesRecord': 'New Catalog Price Rule',
  'button.rulesInformation' : 'Rule Information',
  'button.rulesCondition' : 'Conditions',
  'button.rulesAction' : 'Rule Action',
  'button.rulesSaveProduct' : 'Save and Close',
  'button.useDefaultImage': 'Use Default Image',
  'button.cropImage': 'Crop Image',
  'button.acceptTerms': 'Accept Terms',
  'button.reject': 'Reject',
  'button.addNew': 'Add New',
  'button.goToCampaign': 'Go To Campaign',
  'button.viewProfile': 'View Profile',
  'button.sendMessage': 'Send Message',
  'button.saveNow': 'Save Now',
  'button.pen': 'Pen',
  'button.search': 'Search',
  'button.downloadPdfReport': 'Download Pdf Report',
  'button.primary': 'Primary',
  'button.secondary': 'Secondary',
  'button.danger': 'Danger',
  'button.info': 'Info',
  'button.success': 'Success',
  'button.warning': 'Warning',
  'button.link': 'Link',
  'button.smallButton': 'Small Button',
  'button.largeButton': 'Large Button',
  'button.blockLevelButton': 'Block Level Button',
  'button.primaryButton': 'Primary Button',
  'button.button': 'Button',
  'button.save': 'Save',
  'button.clear': 'Clear',
  'button.apply': 'Apply',
  'button.openMenu': 'Open Menu',
  'button.openWithFadeTransition': 'Open With Fade Transition',
  'button.openPopover': 'Open Popover',
  'button.accept': 'Accept',
  'button.click': 'Click',
  'button.complete': 'Complete',
  'button.back': 'Back',
  'button.next': 'Next',
  'button.completeStep': 'Complete Step',
  'button.error': 'Error',
  'button.writeNewMessage': 'Write New Message',
  'button.saveChanges': 'Save Changes',
  'header.switch.editMode.off': 'Edit mode: Off',
  'header.switch.editMode.on': 'Edit mode: On',
  'button.addNewUser': 'Add New User',
  'button.more': 'More',
  'button.cancel': 'Cancel',
  'button.add': 'Add',
  'button.update': 'Update',
  'button.reply': 'Reply',
  'button.delete': 'Delete',
  'button.yes': 'Yes',
  'button.viewAll': 'View All',
  'button.like': 'Like',
  'button.assignNow': 'Assign Now',
  'button.seeInsights': 'See Insights',
  'button.submitCategoriesImport': 'Upload Categories',
  'button.closeAfterUpload': 'Close popup',
  'button.rootLabel': 'Root',
  'button.parentCategoryLabel': 'Parent category: ',
  'button.addFilterGroup': 'Add Filter Group',
  'button.addGroup': 'Add a Group',
  'button.addSearchField': 'Add Search Field',
  'button.addSearchAggregation': 'Add Search Aggregation',
  'button.searchAggregation': 'Search Aggregations',
  'button.deleteSearchAggregation': 'Delete Search Aggregation',
  'button.deleteSearchField': 'Delete Search Field',
  'button.deleteFilterGroup': 'Delete Filter Group',
  'button.selectCategoriesValue': 'Select Categories',
  'button.addFilterLabel': 'Add Filter',
  'button.addSortLabel': 'Add Sort',
  'button.showMore': 'Show more',
  'button.left': ' left',
  'button.showLess': 'Show less',
  'button.hideErrorDetails': 'Hide error details',
  'button.showErrorDetails': 'Show error details',
  'button.editAttributes': 'Edit Attributes',
  'button.unpin': 'Unpin',
  'button.hide': 'Hide',
  'button.show': 'Show',
  'button.addFilterValue': 'Add Filter Value',
  'button.newSearchFilter': 'New Campaign',
  'button.addNewRecord': 'Add New',
  'button.export': 'Export',
  'button.ok': 'OK',
  'button.exportToFMS': 'Export to FMS',
  'button.exportCategories': 'Export',
  'button.copyMappingRecord': 'Copy Mapping Record',
  'button.reset': 'Reset',
  'button.openProductsDialog': 'Open Products Dialog',
  'button.markSelected': 'Mark as Interested',
  'button.unmarkSelected': 'Remove from Interested',
  'button.explainPrice': 'Explain Price',
  'button.information': 'Information',
  'button.prices': 'Prices',
  'button.history': 'Activity history',
  'button.analytics': 'Analytics',
  'button.review': 'Review',
  'button.seo': 'SEO',
  'rules.fields.label.name': 'Rules Name',
  'rules.fields.label.description': 'Description',
  'rules.fields.label.active': 'Active',
  'rules.fields.label.from': 'From',
  'rules.fields.label.to': 'To',
  'rules.fields.label.priority': 'Priority',
  'rules.fields.label.action': 'Action',
  'rules.fields.label.amount': 'Amount',
  'rules.fields.label.discardSubsequentRules': 'Discard Subsequent Rules',
  'rules.fields.label.category': 'Category',
  'rules.fields.label.brand': 'Brand',
  'rules.fields.label.merchant': 'Merchant',
  'rules.fields.label.title': 'Title',
  'rules.fields.label.priceRange': 'Price Range',
  'rules.fields.label.createdBefore': 'Created Before',
  'rules.fields.label.quantity': 'Quantity',
  'rules.fields.label.status': 'Status',
  'rules.fields.label.condition': 'Condition',
  'rules.fields.label.profitRange': 'Profit Range',
  'rules.fields.label.mappedIn': 'Mapped In',
  'rules.noResults': 'No results were found for your search',
  'rules.DeleteRuleDialog.title':  'Are you sure you want to permanently delete this rule?',
  'rules.addProductsTitle': 'Variation products',

  'cams.showProductInfo': 'Show Status',
  'cams.hideProductInfo': 'Return',
  'cams.applyFilter': 'Apply a filter to show more information',
  'cams.date': 'Date',
  'cams.status': 'Status',

  'product.edit.noAttributes': 'No Attributes',

  'product.edit.prices.variation': 'Variation',
  'product.edit.prices.merchant': 'Merchant',
  'product.edit.prices.quantity': 'Quantity',
  'product.edit.prices.netPrice': 'Net Price',
  'product.edit.prices.shippingMarkup': 'Shipping Markup',
  'product.edit.prices.salePrice': 'Sale Price',
  'product.edit.prices.profit': 'Profit',
  'product.edit.prices.price_change': 'Price Change',
  'product.edit.prices.quantity_change': 'Quantity Change',
  'product.edit.prices.date': 'Date',

  'product.edit.history.userID': 'User ID',
  'product.edit.history.affectedItems': 'Affected Items',
  'product.edit.history.action': 'Action',
  'product.edit.history.changes': 'Changes',
  'product.edit.history.comment': 'Comment',
  'product.edit.history.createdAt': 'Created at',
  'product.edit.history.updatedAt': 'Updated at',

  'export.description': 'Specify the email to which the file with the archive of products will be sent',
  'export.answer': 'Products Export task has been scheduled',

  'system.errors.cantBeBlank': 'The field can`t be blank',
  'system.errors.sectionCantBeBlank': 'This section can`t be blank',
  'system.errors.conditionCantBeBlank': 'The condition section can`t be blank',
  'system.errors.cantBeEmpty': 'The user must have at least one role',
  'system.errors.pleaseProvideValidEmailAddress': 'Please provide a valid email address',
  'system.errors.passwordShouldBeAtLeastSixCharacters': 'Password should be at least 6 characters. ',
  'system.errors.oneCapital': 'Should include 1 capital',
  'system.errors.passwordAdvice': 'Desirable should include 1 capital, one number, one sign or special char.',
  'system.preview.itemIsRequired': "Field can't be empty",
  'system.users.create.email.used': 'An account with this email already exists',
  'system.users.link.influencer': 'Open influencer page',
  'systemPage.subheader.infoLabel.autoSync': 'Users scopes sync automatically by schedule at 1 AM',
  'pages.system.button.text.syncUsers': 'Sync users',

  'pageTitle.categoryEdit': 'Edit category',
  'pageTitle.categoryNew': 'New category',
  'form.nameEnLabel': 'English Name:',
  'form.nameHeLabel': 'Hebrew Name:',
  'form.filterByLabel': 'Filter by:',
  'form.parentFilterByLabel': 'Parent Filter by:',
  'form.sortByLabel': 'Sort by:',
  'form.facetsLabel': 'Facet',
  'form.previewNavigation.nameLabel': 'Name:',
  'form.previewNavigation.searchPhraseLabel': 'Search phrase:',
  'form.previewNavigation.urlKeyLabel': 'URL key:',
  'form.navigation.nameLabel': 'Name:',
  'form.navigation.searchPhraseLabel': 'Search phrase:',
  'form.navigation.urlKeyLabel': 'URL key:',
  'helper.sorry': 'Sorry, you can not add more filter values than available product attributes',
  'helper.disabledWhile': 'Disabled while products attribute count greater than 1.',
  'helper.fieldCantNotBeBlank': 'The field can not be blank',
  'helper.selectAttributeBefore': 'Please, select an Attribute code before',
  'helper.moreThan': 'Disabled while products attribute count greater than 1',

  'text.errors.moreThanThreeCharacters': 'Use 3 characters or more',
  'text.errors.moreThan3Characters': 'Use 3 characters or more',
  'text.errors.moreThan1Characters': 'Use 1 characters or more',
  'text.errors.numberLengthLimit': 'This field is required. The position number you entered is too long. The number length limit should be less than ',
  'text.errors.lessThanThirthyCharacters': 'Use less then 30 characters',
  'text.errors.lessThan30Characters': 'Use less then 30 characters',
  'text.errors.lessThan100Characters': 'Use less then 100 characters',
  'text.errors.lessThan200Characters': 'Use less then 200 characters',
  'text.errors.lessThan300Characters': 'Use less then 300 characters',
  'text.errors.numberShouldBeLessOrEqual': 'Number should be less than or equal to',
  'text.errors.lessThan170Characters': 'Use less then 170 characters',
  'text.errors.lessThan500Characters': 'Use less then 500 characters',
  'text.helper.cantBeBlank': 'The field can`t be blank',
  'text.helper.notUrl': 'The field must be an URL address',
  'text.mapperCategories': 'Mapper Categories',

  'label.selectedCategoriesLabel': 'Selected categories',
  'label.selectCategoriesLabel': 'Select categories',
  'label.sourceCategoriesLabel': 'Source categories',
  'label.selectAllCategories': 'Select all',
  'label.selectUnselectAllCategories': 'Select / Unselect All',
  'label.removeProductStars': 'Unselect ★',

  'alert.categoryHasChildren': 'Current category has child categories. You need to delete the child categories first',
  'alert.sureTitle': 'Are you sure?',
  'alert.leaveNewRankingPageTitle': 'Yes, leave this page',
  'alert.leaveNewRankingPageBody': 'If you leave this page then any unsaved changes will be lost.',
  'alert.deleteNewRankingAttrTitle': 'Yes, delete it!',
  'alert.deleteRankingBody': 'Are you sure you want to permanently delete this Ranking?',
  'alert.changesWillBeDiscarded': 'Changes will be discarded, do you want to continue?',
  'alert.deleteNewRankingAttrBody': 'Are you sure you want to permanently delete this attribute?',
  'alert.leaveEditRankingPageTitle': 'Yes, leave this page',
  'alert.leaveEditRankingPageBody': 'If you leave this page then any unsaved changes will be lost.',
  'alert.deleteEditRankingAttrTitle': 'Yes, delete it!',
  'alert.deleteEditRankingAttrBody': 'Are you sure you want to permanently delete this attribute?',
  'alert.deleteEditCategoryBody': 'Are you sure you want to permanently delete this category?',
  'alert.deleteEditCategoryFilterBody': 'Are you sure you want to permanently delete this filter?',
  'alert.deleteEditCategoryFilterAndSortBody': 'Are you sure you want to permanently delete this filter. If you remove, all filter and sort settings data will be lost.',
  'alert.deleteEditCategoryFilterProductAttrBody': 'Are you sure you want to permanently delete this product attribute?',
  'alert.leaveEditSystemPageTitle': 'Yes, leave this page',
  'alert.leaveEditSystemPageBody': 'If you leave this page then any unsaved changes will be lost.',
  'alert.warningTitle': 'Do not forget!',
  'alert.warningBody': 'Categories do not affect the search result. They are excluded from the filter.',
  'alert.import.warningBody': 'When importing a category tree - all current categories will be deleted',
  'alert.useFacets': "To use facets, select some categories by clicking the 'Select Categories' button",
  'alert.somethingWentWrong': 'Oops something went wrong, please try again later...',
  'alert.ifChoseRankingRuleOptionBody': "If you select 'Ranking rule' option from the dropdown on the sortBy section, all other sort filters will be permanently deleted.",
  'alert.buttonWillBeHidden': "The 'Add Sort' button will be hidden.",
  'alert.buttonWillBeHided': "The 'Add Sort' button will be hided.",

  'popover.categoryAffect': 'Categories affect the search result',
  'popoverAffect.categoryDoNotAffect': 'Categories do not affect the search result',
  'popover.buttonDefault': 'Open Popover',
  'popover.buttonShowScope': 'Show Scope',

  'placeholder.noResult': 'Sorry, no results found',
  'placeholder.noData': 'Sorry, no data',
  'placeholder.searchCategories': 'Search Categories',

  'title.filtersActions': 'Filters actions',
  'title.actions': 'Actions',
  'title.gettingProductAttributes': 'Getting product attributes.',
  'title.gettingCategoriesAttributes': 'Getting categories attributes.',
  'title.creatingCategory': 'Creating category.',
  'title.loadingData': 'Loading data.',
  'title.canTakeWhile': 'It can take a while.',
  'title.isExportable': 'Exportable',
  'title.displayOnProduct': 'Display on Product',
  'title.name': 'Name',
  'title.selectADisplayingMode': 'Select a displaying mode',
  'title.selectADisplayingModeBody': 'Select one of the mode in the select above.',
  'title.selectACategory': 'Select a category',
  'title.selectACategoryBody': 'Select one of the Category to the left to show more information.',
  'title.sorry': 'Sorry!',
  'title.noResultsFound': 'No results found.',
  'title.groupOptions': 'Group Options',
  'title.sumbit': 'Submit',
  'title.applyFilter': 'Apply filter',
  'title.saveFilter': 'Save filter',
  'title.noItems': 'No items',
  'title.changes': 'Changes',
  'title.showChanges': 'Show changes',
  'title.noChanges': 'No changes',
  'title.notChanged': 'Not changed',
  'spinning.text': 'Please wait while the data is loading...',

  'rankings.scopeBtn': 'Scope',
  'rankings.addSelectedBtn': 'Add selected',
  'rankings.nameLabel': 'Ranking Name:',
  'rankings.addAttribute': 'Add Attribute',
  'rankings.selectScopeDialogTitle': 'Select Scopes to view',
  'rankings.selectScopeDialogButtonLabel': 'Select',
  'rankings.closeScopeDialogButtonLabel': 'Close',
  'rankings.cancelScopeDialogButtonLabel': 'Cancel',
  'rankings.errors.cantBeBlank': 'The field can`t be blank',
  'rankings.errors.moreThanThreeCharacters': 'Use 3 characters or more',
  'rankings.errors.overTotalMessage': 'Total weight is not equal 100%',
  'rankings.errors.hasDuplicates': "Attribute can't be duplicated",
  'rankings.update.alert.success': 'The ranking was successfully updated!',
  'rankings.delete.alert.success': 'The ranking was successfully deleted!',
  'rankings.update.alert.reject': 'The ranking was not successfully updated!',
  'rankings.placeholder.title': 'Select a ranking',
  'rankings.placeholder.subTitle': 'Select one of the Rankings from the left bar to show more information or create a New Ranking.',
  'ranking.create.alert.error': 'Ranking already exists',
  'ranking.update.alert.error': 'Ranking already exists',
  'ranking.create.alert.warning': 'There are no changes',

  'facet.create.label': 'Create facet',
  'facet.deleteAll.label': 'Delete facets',
  'facet.dialog.createNew.title': 'Create facet',
  'facet.dialog.edit.title': 'Edit facet',
  'facet.dialog.createNew.error.message': 'The field can`t be blank',
  'facet.actions.apply.title': 'Apply',
  'facet.name.en.title': 'English name',
  'facet.name.he.title': 'Hebrew name',
  'facet.attribute.basedOn.title': 'Based on',
  'facet.attribute.basedOn.disabled.title': 'Disabled while the facet has grouped values',
  'facet.attribute.operator.title': 'Operator',
  'facet.attribute.serachValue.title': 'Search value',
  'facet.attribute.addAttribute.title': 'Add',
  'facet.attribute.addVirtualValue.title': 'Add Value',
  'facet.facet.groupedFields.disabled.title': 'Please, select "Based on" attributes type to able to add grouped values',
  'facet.attribute.addVirtualValueHelper.title': 'PLease, select a type to able to add values',
  'facet.attribute.addSystemValue.title': 'Add System Value',
  'facet.selectedValue.toogle.title': 'Values display language:',
  'facet.type.toogle.title': 'Type:',
  'facet.selectedValue.button.deleteGroup.title': 'Delete group',
  'facet.selectedValue.helperText.title': 'Delete values to change group type',
  'facet.helper.cantBeBlank': 'The field can`t be blank',
  'facet.helper.alreadyExist': 'Sorry, a facet with this name is already exist',
  'facet.helper.displayIsConstant': 'Sorry, you can not use "Display" at the beginning of the facet name. This is the system variable',
  'facet.helper.emptyFacetOptions': 'There are no options',
  'facet.helper.incorectFacetName': 'A facet name can not contain any of special symbols or can not contain only numbers',
  'facet.helper.incorectFacetNameNotNumbers': 'A facet name can not contain only numbers',
  'facet.helper.showLess': 'Show less',
  'facet.helper.showMore': 'Show more',
  'facet.promoted': 'Is Promoted',

  'preview.button.viewDetails': 'View',
  'preview.button.viewColumns': 'Columns',
  'preview.button.addNewRule': 'Add New Rule',
  'preview.table.product.button.edit.label': 'Edit',
  'preview.table.product.button.view.label': 'View',
  'preview.save.alert.noChanges': 'There is no changed data to update',
  'preview.save.alert.productsUpdated': 'Product(s) has been updated successfully!',
  'preview.button.addFilter': 'Add New Filter',
  'preview.button.showHiddenProducts': 'Show hidden products',
  'preview.button.clearHiddenProducts': 'Close hidden products',
  'preview.filter': 'Filters',
  'preview.predefinedFilter': 'Predefined Filter',
  'preview.searchFields': 'Search fields',
  'preview.errors.filterItemIsRequired': "Field can't be empty",
  'preview.errors.fieldIsRequired': "Field can't be empty",
  'preview.errors.thatNameIsAlreadyTaken': 'That name is already taken',
  'preview.editProductDialogTitle': 'Edit attributes',
  'preview.viewProductDialogTitle': 'View attributes',
  'preview.productHasNoParents': 'This product has no parents',
  'preview.editProductDialogTitle.addAttribute.noResults.label': 'No results found',
  'preview.editProductDialogTitle.addAttribute.helper.label': 'More values are available - use search',
  'preview.dialog.addNewAttribute': '+ Add attributes',
  'preview.edit.footer.heading': 'Other variants',
  'preview.edit.footer.noVariants': 'There are no other variant for the current product',
  'preview.header.controls': 'Edit Mode',
  'preview.list.actionBar.saveModal.title': 'Are you sure?',
  'preview.list.actionBar.saveModal.content': 'Only parent products will be updated! All other products will be unselected.',
  'preview.list.actionBar.saveModal.confirmButton': 'Confirm',
  'preview.list.actionBar.saveModal.cancelButton': 'Cancel',
  'preview.list.actionBar.cancelButton': 'Cancel',
  'preview.list.actionBar.validations.commentIsRequired': 'The comment is required',
  'preview.list.product.setAsMain.label': 'Set as main',
  'preview.list.actionBar.save': 'Save',
  'preview.productList.selectAllLabel': 'Select all',
  'preview.productList.exportProductsLabel': 'Export Products',
  'preview.productList.mapperCategoriesLabel': 'Categories',
  'preview.placeholder.searchByPhrase': 'Search by search phrase',
  'preview.productDialog.systemComment.label': 'System comment',
  'preview.productDialog.comment.label': 'Comment',
  'preview.sidebar.badge.promotion': 'Promotion',
  'preview.sidebar.badge.category': 'Category',
  'preview.edit.label.title.en': 'Title (en)',
  'preview.edit.label.title.he': 'Title (he)',
  'preview.edit.label.is_forcefully_included_in_google_feed': 'Forcefully Include in Google Feed',
  'preview.edit.label.quantity': 'Quantity',
  'preview.edit.label.price': 'Price',
  'preview.edit.label.video_url': 'Video URL',
  'preview.edit.label.video_type': 'Video Type',
  'preview.edit.label.video_preview_url': 'Video Preview URL',
  'preview.edit.label.zap': 'Zap Data',
  'preview.edit.label.zap_model_id': 'Zap Model ID',
  'preview.edit.label.ksp_model_id': 'Ksp Model ID',
  'preview.edit.label.variation_id': 'Variation ID',
  'preview.edit.label.zap_url': 'Zap URL',
  'preview.edit.label.zap_parsing_status': 'Zap Parsing Status',
  'preview.edit.label.zap_parsed_at': 'Zap Parsed At',
  'preview.edit.label.zap_parsing_status_0': 'Not Synced',
  'preview.edit.label.zap_parsing_status_1': 'Pending',
  'preview.edit.label.zap_parsing_status_2': 'Success',
  'preview.edit.label.zap_parsing_status_3': 'Fail',
  'preview.edit.label.cdms_sync_status': 'CDMS Sync Status',
  'preview.edit.label.cdms_attempt_date': 'CDMS Sync Attempt Date',
  'preview.edit.label.is_archive': 'Is Archive?',
  'preview.edit.label.zap_data_is_not_exists': 'Zap Data Is Not Exists',
  'preview.edit.label.zap_data_is_creating': 'The date record is being created. This may take some time',
  'preview.edit.label.description': 'Description',
  'preview.edit.label.meta_tag_fields': 'Meta Tag Fields',
  'preview.edit.label.comments': 'Comments',
  'preview.edit.label.description.he': 'Description (he)',
  'preview.edit.label.description.en': 'Description (en)',
  'preview.edit.label.meta_title.en': 'Meta Title (en)',
  'preview.edit.label.meta_description.en': 'Meta Description (en)',
  'preview.edit.label.meta_keywords.en': 'Meta Keywords (en)',
  'preview.edit.label.meta_title.he': 'Meta Title (he)',
  'preview.edit.label.meta_description.he': 'Meta Description (he)',
  'preview.edit.label.meta_keywords.he': 'Meta Keywords (he)',
  'preview.edit.label.system_values': 'System Values',
  'preview.productStatus.noSellers': 'No seller',
  'preview.list.productComments.loading': 'Loading...',
  'preview.list.productComments.noComment': 'This product has no activity yet.',
  'preview.list.productReviews.noReviews': 'This product has no reviews yet.',
  'preview.actionContextMenu.commentIsRequired': 'Comment is required',
  'preview.actionContextMenu.systemCommentIsRequired': 'System comment is required',
  'preview.list.actionBar.validations.systemCommentIsRequired': 'The system comment is required',
  'preview.productList.header.badge.rankingRuleCode.label': 'Ranking rule:',
  'previewPage.importDialog.header': 'Import XLSX file',
  'previewPage.exportDialog.headerInProgress': 'Export in progress',
  'previewPage.exportDialog.headerFinished': 'Export finished',
  'previewPage.exportDialog.subheaderInProgress': 'Please wait, it will take some time...',
  'previewPage.exportDialog.subheaderFinished': 'Your file is ready',
  'previewPage.exportDialog.downloadButton': 'Download',
  'previewPage.exportDialog.closeButton': 'Close',
  'previewPage.exportDialog.noProductsToExport': "You didn't select a category or selected category doesn't contain enough products to export, please select another category and try again",
  'previewPage.exportDialog.exportError': 'Export error',
  'previewPage.exportToEmailDialog.header': 'Export products to Email',
  'previewPage.importDialog.uploadButton': 'Upload',
  'previewPage.importDialog.closeButton': 'Close',
  'previewPage.importDialog.updatedAttributesLabel': 'Updated attributes',
  'previewPage.importDialog.positionsCountError': 'There are too much products with positions',
  'previewPage.importDialog.positionsCountErrorLimit': 'The limit is 500. Please reduce the count of products with positions and try again',
  'previewPage.importDialog.lastExportDate': 'Export date:',
  'preview.table.product.button.unlockAttributes': 'Unlock attributes',
  'preview.dnd.disabled': 'Drag&Drop is disabled cause there are collapsed products',
  'preview.category.badge.label': 'Edit category',
  'preview.promotion.badge.label': 'Edit promotion',
  'preview.searchFilter.badge.label': 'Edit search filter',

  'pageTitle.navigationEdit': 'Edit filter',
  'pageTitle.searchEdit': 'Edit filter',
  'pageTitle.searchNew': 'New search filter',
  'pageTitle.importPage': 'Bulk Category Import',
  'pageTitle.rankingNew': 'New ranking',
  'pageTitle.rankingEdit': 'Edit ranking',
  'pageTitle.systemUsers': 'Users',
  'pageTitle.systemAvailableUsers': 'Users: Available users',
  'pageTitle.systemPreview': 'Preview',

  'transfer.list.product.deleted': 'Product deleted',
  'transfer.list.product.deleteButtonLabel': 'Remove from list',

  'users.selectScopeDialogButtonLabel': 'Select',
  'users.cancelScopeDialogButtonLabel': 'Cancel',

  'auth.forgotPassword.resetPasswordButton': 'Reset password',
  'auth.forgotPassword.resetPasswordSuccessMessage': 'We sent an email with the recovery link.',
  'auth.forgotPassword.changePasswordButton': 'Change password',
  'auth.forgotPassword.codeIsInvalid': 'Code is invalid',
  'auth.userAuthSuccess': 'User Login Successfully',
  'auth.userLogOutSuccess': 'User Logout Successfully',

  'datepicker.label.selectDate': 'Select date',

  'category.create.alert.error': 'A category with the same path by name already exists',
  'category.create.alert.success': 'The Category was successfully created',
  'category.facet.alert.created': 'The Category Facets was successfully created',
  'category.facet.alert.deleted': 'The Category Facets was successfully deleted',
  'category.facet.alert.updated': 'The Category Facets was successfully updated',
  'category.create.alert.warning': 'There are no changes',
  'category.update.alert.warning.notification': 'Facets will be updated after refreshing the page',
  'category.errors.cantBeEmpty': 'There are errors on this line',
  'categories.delete.alert.success': 'The category was successfully deleted!',
  'categories.update.alert.success': 'The category was successfully updated!',

  'importPage.emptyZone': 'No selected files yet',
  'importPage.placeholder': "Drag 'n' drop some files here, or click to select files",
  'importPage.status.categoriesBulkRemoving': 'Deleting all virtual categories...',
  'importPage.status.categoriesBulkRemoved': 'All virtual categories were successfully deleted',
  'importPage.status.categoriesBulkCreating': 'Creating virtual categories...',
  'importPage.status.categoriesBulkUpdating': 'Updating virtual categories...',
  'importPage.status.categoriesBulkCreated': 'The new virtual categories tree was successfully created',
  'importPage.status.categoriesBulkUpdated': 'The virtual categories tree was successfully updated',
  'importPage.status.xlsxParsing': 'Parsing xlsx document...',
  'importPage.status.xlsxParsed': 'The xlsx document was successfully parsed',
  'importPage.status.categoriesBulkCreatedWithError': 'Error occurred',
  'importPage.status.title.categoriesBulkCreating': 'Importing, wait for all categories to be imported into CDMS',
  'importPage.status.title.categoriesBulkUpdating': 'The hebrew names are updating for existing categories',
  'importPage.status.title.categoriesBulkUpdated': 'The hebrew names were successfully updated for existing categories',

  'search.selectProductsDialog.search.checkbox.applyFilter': 'Apply Filters',
  'search.errors.xss': 'This field must not contain special characters and Cross-Site Scripting injections',
  'searchCategories.errors.alphanumeric': 'This field must not contain special characters',
  'search.create.alert.warning': 'There are no changes',
  'searchPage.searchUrlName': 'Page URL:',
  'search.create.alert.error': 'Search filter already exists',
  'search.errors.cantBeBlank': 'The field can`t be blank',
  'search.update.success': 'The search filter was successfully updated!',

  'navigation.create.alert.warning': 'There are no changes',
  'navigation.create.empty.warning': 'Fill required fields.',
  'navigation.facet.alert.created': 'The Navigation Facets was successfully created',
  'navigation.facet.alert.deleted': 'The Navigation Facets was successfully deleted',
  'navigation.facet.alert.updated': 'The Navigation Facets was successfully updated',

  'pim.editProductDialogTitle': 'Edit attributes',
  'pim.save.alert.warning': 'There are no changes',
  'pim.table.product.badge.reviewed.label': 'Reviewed',
  'pim.table.product.badge.setInStock.label': 'Set all variation products stock status to In Stock',
  'pim.table.product.badge.setOOS.label': 'Set all variation products stock status to Out of Stock',
  'pim.list.actionBar.validations.commentIsRequired': 'The comment is required',
  'pim.list.actionBar.validations.systemCommentIsRequired': 'The system comment is required',
  'pim.list.actionBar.validations.attributeCommentIsRequired': 'The attribute is required',
  'pim.list.actionBar.validations.attributeValueCommentIsRequired': 'The attribute value is required',
  'pim.table.noDataToUpdate.title': 'Sorry, there are no changes in data for update.',
  'pim.list.actionBar.saveModal.content.onlyParentUpdate': 'Only parent products will be updated! All other products will be unselected.',
  'pim.list.actionBar.saveModal.content.noProductsWithLockedAttrs': 'Sorry, You have not selected any products with blocked attributes!',
  'pim.table.product.button.edit.label': 'Edit',
  'pim.table.product.button.unlockAttributes': 'Unlock attributes',
  'pim.table.product.button.mergeProducts': 'Merge products',
  'pim.table.product.button.setInStock': 'Set In Stock',
  'pim.table.product.button.setOOS': 'Set OOS',
  'pim.table.product.button.history': 'History',
  'pim.table.product.button.remove': 'Remove',
  'pim.table.product.button.restore': 'Restore',
  'pim.table.product.button.markAsReviewed': 'Mark as reviewed',
  'pim.table.product.button.markAsNotReviewed': 'Mark as not reviewed',
  'pim.dialog.addNewAttribute': '+ Add attributes',
  'pim.dialog.unlockAttributes': 'Unlock attributes',
  'pim.dialog.images.label': 'Product images',
  'pim.dialog.videos.label': 'Product videos',
  'pim.dialog.videos.enrichment.label': 'Video enrichment',
  'pim.dialog.product.image.noImages.message': 'There are no images for the current product',
  'pim.dialog.visibilityStatus.label': 'Visibility Status',
  'pim.dialog.pimStatus.label': 'Pim Status',
  'pim.dialog.contentSource.label': 'Content Source',
  'pim.dialog.condition.label': 'Condition',
  'pim.dialog.footer.label.comment': 'Comment',
  'pim.list.productComments.loading': 'Loading...',
  'pim.save.alert.noChanges': 'There is no changed data to update',
  'pim.save.alert.productsUpdated': 'Product(s) has been updated successfully!',
  'pim.filter.fields.isRequired.validation': 'The field(s) is required',
  'pim.filter.errors.maxSizeValidation': 'Max size is 100',
  'pim.filter.isEmpty': 'The filter could not be empty. Add a group or select category(ies)',
  'pim.errors.fieldIsRequired': "Field can't be empty",
  'pim.title.applyFilterOrCategory': 'Apply a filter or select a category',
  'pim.subtitle.applyFilterOrCategory': 'Apply a filter or select a category to show more information',
  'pim.unlockAttributesDialog.validations.commentIsRequired': 'The comment is required',
  'pim.unlockAttributesDialog.validations.systemCommentIsRequired': 'The system comment is required',
  'pim.unlockAttributesDialog.title': 'Are you sure you want to permanently unlock this attributes?',
  'pim.unlockAllAttributesDialog.title': 'Are you sure you want to permanently unlock all attributes?',
  'pim.videosEnrichmentDialog.title': 'Select videos',
  'pim.videosEnrichmentDialog.notFound': 'There are no enrichment videos for this product.',
  'pim.mergeProductsDialog.title': 'Merge Products',
  'pim.mergeProductsDialog.bySuggesting': 'By Suggesting',
  'pim.mergeProductsDialog.manually': 'Manually',
  'pim.mergeProductsDialog.newProduct': 'New Product',
  'pim.mergeProductsDialog.wait': 'Please wait while the proposed merge products are found',
  'pim.mergeProductsDialog.sorry': 'Sorry, there are no merge options for this product',
  'pim.mergeProductsDialog.targetProduct': 'Target Product',
  'pim.mergeProductsDialog.productTitle': 'Title',
  'pim.mergeProductsDialog.merchantID': 'Merchant ID',
  'pim.mergeProductsDialog.domestic': 'Domestic',
  'pim.mergeProductsDialog.createdAt': 'Created At',

  'pim.header.search.message.checkSidebar': 'Please, check that sidebar filters are filled up.',
  'pim.header.search.message.isRequired': 'Field can\'t be empty',
  'pim.product.update.alert.success': 'The product was successfully updated!',

  'autocomplete.emptyField.message': 'The field(s) is required',

  'importCategories.label.onlyUpdate': 'Only existed virtual categories will be updated',
  'importCategories.label.fullImport': 'All virtual categories will be removed and new categories will be created from the XLSX document',

  'transferDialog.buttonAdd': 'Add',
  'transferDialog.buttonRemove': 'Remove',
  'transferDialog.buttonAddToFavorites': 'Add to Favorites',
  'transferDialog.buttonRemoveFromFavorites': 'Remove from Favorites',
  'transferDialog.buttonClose': 'Close',
  'transferDialog.buttonSlectProducts': 'Select Products',
  'transferDialog.listLabel.left': 'Products',
  'transferDialog.listLabel.right': 'Chosen Products',
  'transferDialog.addFavoriteProducts': 'Add Favorite Products',
  'transferDialog.showFavoriteProducts': 'Favorite Products',
  'transferDialog.showAllProducts': 'All Products',
  'transferDialog.removeFavoriteProducts': 'Remove Favorite Products',

  'influencer.emptyContainer.title': 'Select an influencer page',
  'influencer.emptyContainer.body': 'Select one of the influencer pages to show more information',
  'influencer.button.save': 'Save',
  'influencer.button.preview': 'Close preview',
  'influencer.button.upload.banner': 'Upload new',
  'influencer.mainBanner.noImage': 'Upload new banner',
  'influencer.trackingInfo.title': 'Tracking',
  'influencer.topTen.filters.title': 'Filters',
  'influencer.topTen.title': 'Top Ten',
  'influencer.products.title': 'Products',
  'influencer.deal.title': 'New Deal',
  'influencer.selectProductsDialog.search.checkbox.applyFilter': 'Apply Filters',
  'influencer.products.filters.subtitle': 'Filter products',
  'influencer.topTen.categories.message': 'All products selected below belong to the selected categories',
  'influencer.errors.cantBeBlank': 'The field can`t be blank',
  'influencer.nocontent.body': 'It looks like this page wasn`t filled up yet.',
  'influencer.nocontent.title': 'Not found',

  'tune.notification.affiliateCreatedSuccessfully': 'Affiliate created successfully',
  'tune.notification.affiliateUserCreatedSuccessfully': 'Affiliate user created successfully',
  'tune.notification.influencerCreatedSuccessfully': 'Influencer created successfully',

  'previewCategory.selectProductsDialog.search.checkbox.applyFilter': 'Apply Filters',
  'previewCategory.categoryUpdated.success': 'The category was successfully updated',
  'previewCategory.text.loading': 'Category loading...',

  'previewNavigation.selectProductsDialog.search.checkbox.applyFilter': 'Apply Filters',
  'previewNavigation.navigationUpdated.success': 'The navigation was successfully updated',
  'previewNavigation.text.loading': 'Navigation loading...',

  'navigation.selectProductsDialog.search.checkbox.applyFilter': 'Apply Filters',
  'navigation.navigationUpdated.success': 'The navigation was successfully updated',
  'navigation.navigationCreated.success': 'The navigation was successfully created',
  'navigation.text.loading': 'Loading...',
  'navigation.create.alert.error': 'Search filter already exists',

  'autocomplete.single.noOptions.label': 'No options',
  'autocomplete.single.typeSomething.label': 'Type something to search',
  'autocomplete.single.loading.label': 'Loading...',

  'virtualCategoryPage.text.loading': 'Category loading...',
  'virtualCategoryPage.header.categoryEdit': 'Edit category',
  'virtualCategoryPage.selectProductsDialog.search.checkbox.applyFilter': 'Apply Filters',
  'virtualCategoryPage.categoryUpdated.success': 'The category was successfully updated',
  'virtualCategoryPage.update.alert.warning.notification': 'Facets will be updated after refreshing the page',
  'virtualCategoryPage.pageTitle.categoryEdit': 'Edit category',
  'virtualCategoryPage.pageTitle.categoryNew': 'New category',
  'virtualCategoryPage.create.alert.error': 'A category with the same path by name already exists',
  'virtualCategoryPage.edit.alert.error': 'A category with the same path by name already exists',
  'virtualCategoryPage.create.alert.success': 'The Category was successfully created',
  'virtualCategoryPage.delete.alert.success': 'The category was successfully deleted!',
  'virtualCategoryPage.create.alert.warning': 'There are no changes',
  'virtualCategoryPage.cancelScopeDialogButtonLabel': 'Cancel',
  'virtualCategoryPage.selectScopeDialogButtonLabel': 'Select',

  'merhcant.container.edit.stock.status.label': 'Stock status',
  'merhcant.container.edit.stock.quantity.label': 'Stock Quantity',
  'merhcant.container.edit.price.finalPrice.label': 'Final Price',
  'merhcant.container.edit.price.minPrice.label': 'Min Price',
  'merhcant.container.edit.price.finalPriceIsValid': 'Invalid value entered',
  'merhcant.container.edit.price.publishPriceIsValid': 'Invalid value entered',
  'merhcant.container.edit.price.publishPrice.label': 'Publish Price',
  'merhcant.container.edit.price.minPublishPrice.label': 'Publish Min Price',
  'merhcant.container.edit.set_ils_price': 'Set ILS Price?',

  'merhcant.container.edit.price.profit.label': 'Profit',
  'merhcant.container.edit.price.lostProfit.label': 'Profit/Lost',
  'merhcant.container.edit.price.msrp.label': 'MSRP',
  'merhcant.container.edit.price.aoMarkUp.label': 'Markup',
  'merhcant.container.edit.price.netCost.label': 'Net Cost',
  'merhcant.container.edit.price.shippingCost.label': 'Shipping cost',

  'zap.info.title': 'Zap - Ranking & Pricing',
  'zap.info.productName': 'Product Name',
  'zap.info.position': 'Position',
  'zap.info.publishPrice': 'Publish price',
  'zap.info.recommendingPricing': 'Recommending pricing',
  'zap.info.strategy': 'The strategy',
  'zap.info.strategyByRobot': 'The strategy set by the robot',
  'zap.info.lastPriceUpdate': 'Last price update',
  'zap.info.goToZapPage': 'Go to Zap Page',
  'zap.info.noData': 'Sorry, there is no data for product',

  'competitors.total': 'Total competitors',
  'competitors.zapPriceDiff': 'Zap Price Difference',
  'competitors.kspPriceDiff': 'Ksp Price Difference',
  'competitors.zapUpdatedAt': 'Zap Updated At',
  'competitors.kspUpdatedAt': 'Ksp Updated At',

  'no_data_about_price': 'Sorry, there is no data about price',

  'repricer.outOfStock': 'We mapped but out of stock',
  'repricer.expensive': 'We mapped but it was hidden by repricer (“Expensive”)',
  'repricer.isOnFeedZap': 'Product is on feed (ZAP)',
  'repricer.isOnFeedKsp': 'Product is on feed (KSP)',
  'repricer.stock.qty': 'Stock quantity',
  'repricer.stock.status': 'Stock status',
  'repricer.visibilityStatus': 'Visibility status',
  'repricer.variationPrice': 'Variation Price',
  'repricer.items': 'Items',
  'repricer.page': 'Page',
  'repricer.pageOf': 'of',
  'repricer.publishedStatus': 'Published status',
  'repricer.position': 'Position',
  'repricer.stockStatus': 'Stock Status',

  'reviews.verifiedBuyer': 'Verified buyer',
  'reviews.outOf': 'Out of',
};
