import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  func, shape, bool, string, oneOfType, array,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { Divider, CircularProgress } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';

import {
  fetchVirtualCategory, setCategoryFields, clearVirtualCategoryState,
} from 'actions/virtualCategoryPage';

import { clearVirtualCategoryVirtualFacet } from 'actions/virtualCategoryVirtualFacet';
import { clearPhysicalCategoryState } from 'actions/physicalCategory';
import { clearScopeCategoriesState } from 'actions/scopeCategory';
import { clearProductAttributeState } from 'actions/productAttribute';
import { setSelectedCategory } from 'actions/virtualCategoryWidget';
import RctCollapsibleCard from 'components/RctCollapsibleCard/RctCollapsibleCard';

import IntlMessages from 'util/IntlMessages';
import { categoriesAppPath } from 'util/paths';

import Header from '../../components/Header';
import Controls from '../../components/Controls';
import Actions from '../../components/Actions';
import Designation from '../../components/Designation';
import Filter from '../../components/Filter';
import Sort from '../../components/Sort';
import Facet from '../../components/Facet';
import ParentCategory from '../../components/ParentCategory';
import actionModes from '../../utils/actionModes';

class EditCategory extends Component {
  componentDidMount() {
    this.props.fetchVirtualCategory(this.props.match.params.categoryId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.categoryId !== prevProps.match.params.categoryId) {
      this.props.clearVirtualCategoryState();
      this.props.clearVirtualCategoryVirtualFacet();
      this.props.clearPhysicalCategoryState();
      this.props.clearScopeCategoriesState();
      this.props.fetchVirtualCategory(this.props.match.params.categoryId);
    }
    if (
      isEmpty(this.props.match.params.categoryId)
      && !isEmpty(prevProps.match.params.categoryId)
      && this.props.virtualCategory.created
    ) {
      NotificationManager.success(<IntlMessages id="virtualCategoryPage.create.alert.success" />);
    }
  }

  componentWillUnmount() {
    this.props.clearVirtualCategoryState();
    this.props.clearVirtualCategoryVirtualFacet();
    this.props.clearPhysicalCategoryState();
    this.props.clearScopeCategoriesState();
    this.props.clearProductAttributeState();
    this.props.setSelectedCategory({});
  }

  handleChangeController = (e) => {
    const { checked, name } = e.target;

    this.props.setCategoryFields({
      ...this.props.virtualCategory,
      [name]: checked,
    });
  };

  handleNameChange = (e, lngKey) => {
    const { value, name } = e.target;

    this.props.setCategoryFields({
      ...this.props.virtualCategory,
      [name]: {
        ...this.props.virtualCategory[name],
        [lngKey]: value,
      },
    });
  };

  handleOnClose = () => {
    this.props.history.push(categoriesAppPath);
  };

  render() {
    const {
      virtualCategory, virtualCategoryFetched,
    } = this.props;


    return (
      virtualCategoryFetched ? (
        <>
          <Header match={this.props.match} pageTitle="virtualCategoryPage.pageTitle.categoryEdit" />
          <RctCollapsibleCard>
            <Actions
              type="gray"
              onClose={this.handleOnClose}
              actionMode={actionModes.edit}
            />
            <Controls
              handleChangeController={this.handleChangeController}
              fields={{
                useInProductSearch: virtualCategory.useInProductSearch,
                useInDepartmentsTree: virtualCategory.useInDepartmentsTree,
                isShowSubcategoriesOnTop: virtualCategory.isShowSubcategoriesOnTop,
                isEnabled: virtualCategory.isEnabled,
                includeInMenu: virtualCategory.includeInMenu,
              }}
            />
            <Divider className="divider-dashed divider-gap-md" />
            <Designation
              name={virtualCategory.name}
              handleNameChange={this.handleNameChange}
              errors={this.props.virtualCategoryErrors}
            >
              <div className="block form-group form-group-last">
                <ParentCategory
                  parentCategoryId={virtualCategory.parentId}
                  disabled
                />
              </div>
            </Designation>
          </RctCollapsibleCard>

          <RctCollapsibleCard>
            <Filter />
          </RctCollapsibleCard>

          <RctCollapsibleCard>
            <Sort />
          </RctCollapsibleCard>

          {this.props.match.params.categoryId && <Facet key={this.props.match.params.categoryId} />}

          <RctCollapsibleCard>
            <Actions
              gap="0"
              onClose={this.handleOnClose}
              actionMode={actionModes.edit}
            />
          </RctCollapsibleCard>
        </>
      ) : (
        <div className="loader-main flex direction-column">
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className="progress-warning bottom mb-10"
            size={25}
            thickness={4}
          />
          <IntlMessages id="virtualCategoryPage.text.loading" />
        </div>
      )
    );
  }
}

EditCategory.propTypes = {
  history: shape().isRequired,
  fetchVirtualCategory: func.isRequired,
  match: shape({
    params: shape({
      categoryId: string,
    }),
  }).isRequired,
  virtualCategoryFetched: bool.isRequired,
  setCategoryFields: func.isRequired,
  clearVirtualCategoryState: func.isRequired,
  clearVirtualCategoryVirtualFacet: func.isRequired,
  virtualCategory: shape().isRequired,
  virtualCategoryErrors: oneOfType([shape(), array]).isRequired,
  clearPhysicalCategoryState: func.isRequired,
  clearScopeCategoriesState: func.isRequired,
  clearProductAttributeState: func.isRequired,
  setSelectedCategory: func.isRequired,
};

const mapStateToProps = state => ({
  virtualCategory: state.virtualCategoryPage.item,
  virtualCategoryErrors: state.virtualCategoryPage.errors,
  virtualCategoryFetched: state.virtualCategoryPage.fetchedOne,
});

const actionCreators = {
  fetchVirtualCategory,
  setCategoryFields,
  clearVirtualCategoryState,
  clearVirtualCategoryVirtualFacet,
  clearPhysicalCategoryState,
  clearScopeCategoriesState,
  clearProductAttributeState,
  setSelectedCategory,
};

export default connect(mapStateToProps, actionCreators)(EditCategory);
