import restTypes from '../util/rest/types';
import restStates from '../util/rest/states';
import restInitState from '../util/rest/initState';

import {
  SET_PREVIEW_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE,
} from '../actions/types';

const initialState = {};
const previewCategoryVirtualFacetRestTypes = restTypes('preview_category_virtual_facet');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case previewCategoryVirtualFacetRestTypes.createStart:
      return {
        ...state,
        ...restStates.createStart,
      };
    case previewCategoryVirtualFacetRestTypes.createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        ...action.payload,
      };
    case previewCategoryVirtualFacetRestTypes.fetchStart:
      return {
        ...state,
        ...restStates.fetchStart,
      };
    case previewCategoryVirtualFacetRestTypes.fetchFinished:
      return {
        ...state,
        ...restStates.fetchFinished,
        ...action.payload,
      };
    case previewCategoryVirtualFacetRestTypes.deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
      };
    case previewCategoryVirtualFacetRestTypes.deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };
    case previewCategoryVirtualFacetRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case previewCategoryVirtualFacetRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case previewCategoryVirtualFacetRestTypes.updateStart:
      return {
        ...state,
        ...restStates.updateStart,
      };
    case previewCategoryVirtualFacetRestTypes.updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };
    case previewCategoryVirtualFacetRestTypes.clearStateFinished:
      return {
        ...initialState,
        ...restInitState,
      };
    case SET_PREVIEW_CATEGORY_VIRTUAL_FACET_ID_TO_DELETE:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
};
