/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { cloneDeep, isEmpty } from 'lodash';

const useFilter = ({
  virtualCategoryItem, virtualCategoryFetched, setFilters, updateLoadedProductsIn,
  updateLoadedProductsNin, setProductsInIds, setProductsNotInIds, setIsSwitchOn,
}) => {
  useEffect(() => {
    if (virtualCategoryFetched) {
      const deepClonedFilters = cloneDeep(virtualCategoryItem.virtualParams.filters);
      const isSwitchOn = !isEmpty(virtualCategoryItem.virtualParams.mappedCategories);

      setFilters(deepClonedFilters);
      setIsSwitchOn(isSwitchOn);

      if (
        !isEmpty(virtualCategoryItem.virtualParams)
        && !isEmpty(virtualCategoryItem.virtualParams.filters)
      ) {
        let productsInIds = [];
        let productsNotInIds = [];

        virtualCategoryItem.virtualParams.filters.forEach((f) => {
          if (!isEmpty(f.group)) {
            f.group.forEach((g) => {
              if (g.field === 'id' && g.operator === 'in') {
                productsInIds = g.value;
              }
              if (g.field === 'id' && g.operator === 'nin') {
                productsNotInIds = g.value;
              }
            });
          }
        });

        updateLoadedProductsIn([...productsInIds.map(p => ({ id: p }))]);
        updateLoadedProductsNin([...productsNotInIds.map(p => ({ id: p }))]);
        setProductsInIds([...productsInIds]);
        setProductsNotInIds([...productsNotInIds]);
      }
    }
  }, [virtualCategoryFetched]);
};

export default useFilter;
