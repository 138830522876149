/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useOptionsEffect = (state, props) => {
  useEffect(() => {
    state.setStateOptions(props.options);
  }, [props.options]);
};

export default useOptionsEffect;
