/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty, uniq } from 'lodash';

export const useScopeCategory = ({
  scopeCategoriesFetched,
  fetchRootScopeCategories,
  scopeSearchFilterResults,
  scopeSearchFilterFinished,
  setPhysicalCategoriesIds,
  fetchCategoriesAttributes,
}) => {
  useEffect(() => {
    if (!scopeCategoriesFetched) {
      fetchRootScopeCategories();
    }
  }, [scopeCategoriesFetched]);

  useEffect(() => {
    if (!isEmpty(scopeSearchFilterResults)) {
      const physicalCategoriesIds = scopeSearchFilterResults.map((sF) => {
        if (sF.virtualParams) {
          return isEmpty(sF.virtualParams.mappedCategories)
            ? sF.virtualParams.selectedCategories
            : sF.virtualParams.mappedCategories;
        }
        return false;
      }).filter(s => s).flat();

      if (!isEmpty(physicalCategoriesIds)) {
        setPhysicalCategoriesIds(physicalCategoriesIds);
        fetchCategoriesAttributes(uniq(physicalCategoriesIds));
      }
    }
  }, [scopeSearchFilterFinished]);
};

export default useScopeCategory;
