import {
  clearNavigationVirtualFacet,
} from 'actions/navigationVirtualFacet';

import {
  fetchNavigationList,
  fetchNavigation,
  searchNavigationFilters,
} from '../../actions/navigation';

import {
  fetchPromotionList,
  searchPromotions,
} from '../../actions/promotion';

import { clearProductState } from '../../actions/preview';
import { clearFromProductCategoryState } from '../../actions/productAttribute';
import { fetchList as fetchAttributesMatrix } from '../../actions/attributesMatrix';
import {
  fetchListDefaultAttributes,
} from '../../actions/defaultAttribute';

export const mapStateToProps = state => ({
  navigation: state.navigation,
  promotion: state.promotion,
  productAttribute: state.productAttribute,
  defaultAttribute: state.defaultAttribute,
  attributesMatrix: state.attributesMatrix,
});
export const actionCreators = {
  fetchNavigationList,
  fetchPromotionList,
  fetchNavigation,
  clearProductState,
  clearFromProductCategoryState,
  fetchAttributesMatrix,
  fetchListDefaultAttributes,
  searchNavigationFilters,
  searchPromotions,
  clearNavigationVirtualFacet,
};

export default {
  mapStateToProps,
  actionCreators,
};
