import restInitState from '../util/rest/initState';

import {
  VIRTUAL_FACET_SEARCH_ATTRIBUTE_OPTIONS_START,
  VIRTUAL_FACET_SEARCH_ATTRIBUTE_OPTIONS_FINISHED,
  VIRTUAL_FACET_CLEAR_FOUND_ATTRIBUTE_OPTIONS,
  VIRTUAL_FACET_CLEAR_INITIAL_ATTRIBUTE_OPTIONS,
  VIRTUAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START,
  VIRTUAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED,
  VIRTUAL_FACET_SET_SELECTED_ATTRIBUTE_OPTION,
  VIRTUAL_FACET_SET_SELECTED_ATTRIBUTES_OPTIONS,
} from '../actions/types';

const initialState = {
  searchAttributeOptionsStart: false,
  searchAttributeOptionsFinished: false,
  fetchInitialAttributesOptionsStart: false,
  fetchInitialAttributesOptionsFinished: false,
  foundOptions: [],
  searchOptions: [],
  initialOptions: [],
  selectedAttributesOptions: [],
};

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case VIRTUAL_FACET_SEARCH_ATTRIBUTE_OPTIONS_START:
    case VIRTUAL_FACET_SEARCH_ATTRIBUTE_OPTIONS_FINISHED:
    case VIRTUAL_FACET_CLEAR_FOUND_ATTRIBUTE_OPTIONS:
    case VIRTUAL_FACET_CLEAR_INITIAL_ATTRIBUTE_OPTIONS:
    case VIRTUAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_START:
    case VIRTUAL_FACET_FETCH_INITIAL_ATTRIBUTES_OPTIONS_FINISHED:
    case VIRTUAL_FACET_SET_SELECTED_ATTRIBUTE_OPTION:
    case VIRTUAL_FACET_SET_SELECTED_ATTRIBUTES_OPTIONS:
      return {
        ...state,
        ...action.payload,
      };

    default: return state;
  }
};
