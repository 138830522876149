import React, { useState } from 'react';
import {
  string, arrayOf, shape, func, bool, oneOfType,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import TreeNodePopover from '../ScopePopupPopover';

import roles from '../../util/roles';

const ListCheckbox = (props) => {
  const [hoveredCategoryId, setHoveredCategoryId] = useState('');
  const [popoverAnchor, setPopoverAnchor] = useState(null);

  let delayHandler = null;

  const handleOnClick = (item) => {
    if (props.onChange) props.onChange(item.id);
    if (props.handleOnClick) props.handleOnClick(item.id);
  };

  const getTitle = (data) => {
    if (data.name && typeof data.name !== 'string') {
      return data.name.en;
    }
    return data.label || data.title || data.name;
  };

  const onMouseLeave = () => {
    setHoveredCategoryId('');
    setPopoverAnchor(null);
    clearTimeout(delayHandler);
  };

  const onMouseEnter = (event, id) => {
    event.persist();

    delayHandler = setTimeout(() => {
      setHoveredCategoryId(id);
      setPopoverAnchor(event.target);
    }, 1000);
  };

  const {
    list, isRadio, className, session, ignoreScopeValidation, enabledHoverEffects,
    disableNotSelected, handleRightClick,
  } = props;

  return (
    <>
      <List className={className}>
        {list && list.length > 0 && list.map((data) => {
          let disabledDueToRole = session.fetchedOne
            ? (!session.item.scope || !session.item.scope.some(c => c.id === data.id))
            && !session.item.roles
              .some(r => r === roles.admin.name || r === roles.influencer.name)
            : true;

          if (ignoreScopeValidation) disabledDueToRole = false;

          const itemIsChecked = props.selected.some(s => (s.id || s) === data.id);
          const disabled = props.disabled
            || disabledDueToRole
            || (disableNotSelected && !itemIsChecked);

          return (
            <ListItem
              disabled={disabled}
              button
              onContextMenu={e => handleRightClick({ event: e, node: data.id, extraSpace: 20 })}
              onClick={() => handleOnClick(data)}
              key={`item_${list.indexOf(data)}_${getTitle(data) || 'd'}`}
              className={
                `list-checkbox-item ${isRadio && !isEmpty(props.selected)
                  && !props.selected.some(sL => (sL.id || sL) === data.id)
                  ? 'list-item-disabled'
                  : ''}`
              }
            >
              <Checkbox
                className="list-checkbox"
                color="primary"
                checked={itemIsChecked}
              />
              <ListItemText
                className="list-checkbox-title"
                primary={getTitle(data)}
                onMouseEnter={e => onMouseEnter(e, data.id)} // TODO: Move it to a separate function
                onMouseLeave={onMouseLeave}
              />
              {data.icon && (
                <ListItemSecondaryAction>
                  <IconButton className="text-indigo"><i className={data.icon} /></IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </List>
      {enabledHoverEffects && (
        <TreeNodePopover
          hoveredCategoryId={hoveredCategoryId}
          popoverAnchor={popoverAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        />
      )}
    </>
  );
};

ListCheckbox.propTypes = {
  onChange: func,
  handleRightClick: func,
  list: arrayOf(shape({
    id: string,
  })).isRequired,
  selected: arrayOf(oneOfType([
    shape(), string,
  ])),
  isRadio: bool,
  className: string,
  handleOnClick: func,
  session: shape(),
  ignoreScopeValidation: bool,
  disabled: bool,
  enabledHoverEffects: bool,
  disableNotSelected: bool,
};

ListCheckbox.defaultProps = {
  onChange: null,
  handleRightClick: null,
  isRadio: false,
  className: '',
  handleOnClick: null,
  session: {},
  ignoreScopeValidation: false,
  disabled: false,
  enabledHoverEffects: false,
  selected: [],
  disableNotSelected: false,
};

export const mapStateToProps = state => ({
  session: state.session,
});

const actionCreators = {};

export default withRouter(connect(mapStateToProps, actionCreators)(ListCheckbox));
