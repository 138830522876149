import { createSelector } from 'reselect';

const productAttributeRootSelector = state => state;

const stateSelector = createSelector(
  productAttributeRootSelector,
  productAttribute => ({
    categoriesAttributesFetching: productAttribute.categoriesAttributesFetching,
    categoriesAttributes: productAttribute.categoriesAttributes,
  }),
);

export const productAttributeSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  productAttributeSelector,
};
