/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState, useEffect, useReducer } from 'react';
import {
  func, shape, arrayOf,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import { pimSelector } from './selectors/pimSelector';
import { productSelector } from './selectors/productSelector';

import ProductsPagination from '../ProductsPagination';
import Can from '../../../../../../components/Can';
import appPermissions from '../../../../../../util/appPermissions';
import ListActionBar from '../../../../components/ListActionBar';
import ProductContainer from '../../../../containers/Product';
import PagePlaceholder from '../../../components/PagePlaceholder';
import PimSearch from '../../../../components/PimSearch';

import ProductPriceModal from 'components/ProductPrice'

import { getDefaultRootFilter } from '../../../../utils/defaultData';
import { checkAndConverteDataByFieldOperator } from '../../../../utils/checkAndConverteDataByFieldOperator';
import { mapFiltersByOperator } from '../../../../../../util/mapFiltersByOperator';
import { mapPrefixToFilters } from '../../../../../../util/filterMapper';
import IntlMessages from '../../../../../../util/IntlMessages';
import { mergeFields } from '../../utils/mappers';
import { gridOptions } from '../../../../../Preview/utils/gridOptions';

import { updateSavedColumns } from '../../../../../../actions/user';

import {
  setSelectedProducts,
  setContainerStyles,
  setSelectedAction,
  setUpdatedProductsImagesOverrides,
  setPagination,
  setPaginationHelperTextId,
  setHelperText,
  fetchProductList,
  setFilterTarget,
  setDisplayMode,
  setSearchValue,
} from '../../../../../../actions/pim';

import {
  multipleUpdateUserActivityLogs,
  fetchActivityLogsByProductId,
  fetchChildProductList,
  setProductForEdit,
  fetchProduct, setProductCustomColumns,
} from '../../../../../../actions/product';

import {
  fetchProductAttributesForVariations,
  fetchAttributeOptions,
} from '../../../../../../actions/productAttribute';

import { setIsEditMode } from "../../../../../../actions/pim";

import { getProductIdFromURL } from "../../../../utils/sidebar";
import Field from "../../../../../../components/Field";
import FormDialog from "../../../../../../components/FormDialog";
import ProductDetail from "../../../../../../components/ProductDetail";
import {Checkbox, FormControlLabel} from "@material-ui/core";

const handleNotificationWarning = msg => NotificationManager.warning(msg);

const MainContent = memo((props) => {
  const {
    pimAttributes, previewFilterAttributeList,
    pimProps, productProps, fromRules,
  } = props;

  const operatorsWithoutValue = new Set(['exists', 'not_exists']);

  const [isPimOptionsOpened, setIsPimOptionsOpened] = useState(false);
  const [pimOptions, setPimOptions] = useState([]);
  const [filterTarget, setFilterTarget] = useState('');

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const isActionBarActive = pimProps.displayMode === 'list' && pimProps.isEditMode && !isEmpty(pimProps.selected);
  const productContainerLocalClassName = isActionBarActive ? 'action-bar-on' : 'action-bar-off';

  const productListEmpty = pimProps.list
    && isEmpty(pimProps.list)
    && !pimProps.fetching;

  useEffect(() => {
    if (!isActionBarActive && pimProps.containerStyles.height) {
      props.setContainerStyles({
        height: 'calc(100% - 124px)',
      });
    }
  }, [isActionBarActive]);

  useEffect(() => {
    const productId = getProductIdFromURL();
    if (productId !== null) {
      const re = /^prod_([a-zA-Z0-9])+/;
      if (!re.test(productId)) {
        NotificationManager.error('The direct URL contains an incorrect Product ID');
      } else {
        props.fetchProduct(productId);
        props.setIsEditMode(true);
      }
    }
  }, [props.fetchProduct]);

  useEffect(() => {
    if (pimAttributes && pimProps && pimProps.filterTarget !== filterTarget && pimAttributes[pimProps.filterTarget]) {
      handleSavePimOptions();
      setFilterTarget(pimProps.filterTarget);
    }
  }, [pimOptions, pimAttributes, pimProps]);

  const getProductDetail = (productId, productInfo) => {
    props.fetchProduct(productId, productInfo);
  };

  const mapListModeSearchFilters = (filters) => {
    const filteredFilters = filters.filter(f => !f.group.some(g => g.value === 'parent'));
    const mappedFilters = mapFiltersByOperator(filteredFilters, previewFilterAttributeList);

    return mapPrefixToFilters([...mappedFilters, ...getDefaultRootFilter(pimProps.filterTarget)]);
  };

  const fetchProducts = (
    { savedFilterFilters, globalFilters, savedFilterFields, globalFields, sort, searchFilter, fullText, pagination }
  ) => {
    const filtersPayload = [
      ...savedFilterFilters,
      ...globalFilters,
    ];

    const fieldsPayload = [
      ...savedFilterFields,
      ...globalFields,
    ];

    let targetSort = {};
    if (!isEmpty(sort)) {
      targetSort = {
        sort: [{
          field: sort.field,
          order: sort.order,
        }],
      };
    }

    if (pimProps.helperText) props.setHelperText('');
    if (pimProps.paginationHelperTextId) props.setPaginationHelperTextId('');

    const convertedFilters = checkAndConverteDataByFieldOperator(
      [...filtersPayload],
    );

    const mappedFilters = mapListModeSearchFilters(convertedFilters);
    const filter = mappedFilters.filter(f => f.group && f.group
      .every(g => g.field && (!isEmpty(g.value) || operatorsWithoutValue.has(g.operator))));

    return props.fetchProductList({
      viewMode: 'backend',
      filter,
      pagination,
      searchFilter,
      fields: pimProps.isCustomFieldsMode
        ? mergeFields(fieldsPayload, [])
        : [],
      fullText,
      ...targetSort,
    });
  };

  const handlePagination = ({ selected }) => {
    if (!isEmpty(pimProps.productsImagesOverride)) {
      props.setUpdatedProductsImagesOverrides([]);
    }

    const updatedPagination = {
      ...pimProps.pagination,
      page: selected + 1,
    };

    const filtersPayload = [
      ...pimProps.savedFilterFilters[pimProps.filterTarget],
      ...pimProps.globalFilters[pimProps.filterTarget],
    ];

    const convertedFilters = checkAndConverteDataByFieldOperator(
      [...filtersPayload],
    );
    const mappedFilters = mapListModeSearchFilters(convertedFilters);
    const hasEmptyFilters = mappedFilters.some(f => f.group && !f.group
      .every(g => g.field && (!isEmpty(g.value) || operatorsWithoutValue.has(g.operator))));

    if (hasEmptyFilters) return handleNotificationWarning(<IntlMessages id="pim.header.search.message.checkSidebar" />);

    props.setPagination(updatedPagination);
    props.setPaginationHelperTextId('');

    return fetchProducts({
      savedFilterFilters: pimProps.savedFilterFilters[pimProps.filterTarget],
      globalFilters: pimProps.globalFilters[pimProps.filterTarget],
      savedFilterFields: pimProps.savedFilterFields[pimProps.filterTarget],
      globalFields: pimProps.globalFields[pimProps.filterTarget],
      sort: pimProps.sort[pimProps.filterTarget],
      searchFilter: props.pimProps.searchQuery,
      fullText: pimProps.fullTextListModeSearch,
      pagination: updatedPagination,
    });
  };

  const handleRefreshModal = () => {
    if(!productProps.editInProgress && productProps.item) {
      props.setProductForEdit({
        edit: productProps.item,
        info: productProps.info,
        editInProgress: true,
        nextProductLoading: false,
      });
    }
  }

  const handleDisplayModeChange = (event) => {
    const selectedDisplayMode = event.target.value;

    props.setDisplayMode(selectedDisplayMode);

    if (pimProps.searchValue) {
      props.setSearchValue('');
    }

    if (!isEmpty(pimProps.productsImagesOverride)) {
      props.setUpdatedProductsImagesOverrides([]);
    }

    if (selectedDisplayMode === 'image_view' && pimProps.filterTarget === 'merchant') {
      props.setFilterTarget('parent');
    }
  };

  const choosePimOptions = () => {
    setIsPimOptionsOpened(true);
  };

  const handldeClosePimOptions = () => {
    setIsPimOptionsOpened(false);
    props.setProductCustomColumns(pimOptions);

    const allPimOptions = getAllPimOptions(pimOptions);
    props.updateSavedColumns(props.sessionItem.id, allPimOptions);
  };

  const getAllPimOptions = (pimOptions) => {
    const parentSavedItems = (props.sessionItem.selectedPimColumns && props.sessionItem.selectedPimColumns.parent)
      ? props.sessionItem.selectedPimColumns.parent : [];
    const variationSavedItems = (props.sessionItem.selectedPimColumns && props.sessionItem.selectedPimColumns.variation)
      ? props.sessionItem.selectedPimColumns.variation : [];
    const merchantSavedItems = (props.sessionItem.selectedPimColumns && props.sessionItem.selectedPimColumns.merchant)
      ? props.sessionItem.selectedPimColumns.merchant : [];

    if (pimProps.filterTarget === 'parent') {
      return {
        parent: pimOptions,
        variation: variationSavedItems,
        merchant: merchantSavedItems,
      };
    }
    if (pimProps.filterTarget === 'variation') {
      return {
        parent: parentSavedItems,
        variation: pimOptions,
        merchant: merchantSavedItems,
      };
    }
    if (pimProps.filterTarget === 'merchant') {
      return {
        parent: parentSavedItems,
        variation: variationSavedItems,
        merchant: pimOptions,
      };
    }
  };

  const handleSavePimOptions = () => {
    setIsPimOptionsOpened(false);

    const pimOptions = [];

    if (pimAttributes[pimProps.filterTarget]
      && props.sessionItem.selectedPimColumns[pimProps.filterTarget]
      && props.sessionItem.selectedPimColumns[pimProps.filterTarget].length > 0)
    {
      for (let i = 0; i < props.sessionItem.selectedPimColumns[pimProps.filterTarget].length; i++) {
        for (let j = 0; j < pimAttributes[pimProps.filterTarget].length; j++) {
          if (props.sessionItem.selectedPimColumns[pimProps.filterTarget][i] === pimAttributes[pimProps.filterTarget][j].code) {
            pimOptions.push(pimAttributes[pimProps.filterTarget][j].code);
          }
        }
      }
    } else if (pimAttributes[pimProps.filterTarget]) {
      for (let i = 0; i < pimAttributes[pimProps.filterTarget].length; i++) {
        if (pimAttributes[pimProps.filterTarget][i].isDefault === true) {
          pimOptions.push(pimAttributes[pimProps.filterTarget][i].code);
        }
      }
    }

    setPimOptions(pimOptions);
    setFilterTarget(pimProps.filterTarget);
    props.setProductCustomColumns(pimOptions);
  };

  const handleChangeFormItems = (e) => {
    const code = e.target.value;
    const codeIndex = pimOptions.indexOf(code);
    if (codeIndex !== -1) {
      pimOptions.splice(codeIndex, 1);
    } else {
      pimOptions.push(code);
    }
    setPimOptions(pimOptions);
    setFilterTarget(pimProps.filterTarget);
    forceUpdate();
  };

  const filtersPayload = [
    ...pimProps.globalFilters[pimProps.filterTarget],
  ];

  const hasEmptyFilters = filtersPayload.some(f => f.group && !f.group
    .every(g => g.field && (!isEmpty(g.fieldOption) || operatorsWithoutValue.has(g.operator))));

  const PimOptions = () => {
    const attributes = props.pimAttributes[props.pimProps.filterTarget];

    const FormAttributeGroup = ({item}) => {
      return (
        <FormControlLabel
          control={(
            <Checkbox
              color="primary"
              ignored={ignored}
              checked={pimOptions.indexOf(item.code) !== -1}
              onChange={handleChangeFormItems}
              value={item.code}
              name={item.label}
            />
          )}
          label={item.label}
          key={item.value}
        />
      );
    }

    const leftItem = [];
    const rightItem = [];
    for (let i = 0; i < attributes.length; i++) {
      if (i < attributes.length / 2) {
        leftItem.push(attributes[i]);
      } else {
        rightItem.push(attributes[i]);
      }
    }

    return (
      <div className="preview-sidebar">
        <div className="row">
          <div className="col-6">
            {leftItem.map((item, key) => (
              <FormAttributeGroup key={key} item={item} />
            ))}
          </div>
          <div className="col-6">
            {rightItem.map((item, key) => (
              <FormAttributeGroup key={key} item={item} />
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="full-height">
      <div className="product-heading product-header">

        <div className="controller">
          <div className="row">
            <div className="col-5">
              <PimSearch />
            </div>
            <div className="col-2">
              <Field
                hideStaticLabel
                hideFormErrors
                element="dropdown"
                label={<IntlMessages id="preview.button.viewDetails" />}
                className="comment-field select-wrapper select-bordered form-group input-select field-white field-secondary"
                wrapperClassName="flex-wrap"
                name="displayMode"
                value={pimProps.displayMode}
                onChange={handleDisplayModeChange}
                options={gridOptions.filter(go => go.availableFor.includes('pim'))}
              />
            </div>
            <div className="col-1">

              <button onClick={choosePimOptions} className="product-options">
                <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"
                     fill="currentColor" className="product-options-svg">
                  <g clip-path="url(#clip0)" fill="currentColor">
                    <path
                      d="M14.667 0H1.333A1.333 1.333 0 000 1.333v13.334A1.333 1.333 0 001.333 16h13.334A1.334 1.334 0 0016 14.667V1.333A1.334 1.334 0 0014.667 0zM10 1.667v12.666a.333.333 0 01-.333.334H6.333A.333.333 0 016 14.333V1.667a.333.333 0 01.333-.334h3.334a.333.333 0 01.333.334zm-8.333-.334h2.666a.333.333 0 01.334.334v12.666a.333.333 0 01-.334.334H1.667a.333.333 0 01-.334-.334V1.667a.333.333 0 01.334-.334zm12.666 13.334h-2.666a.333.333 0 01-.334-.334V1.667a.333.333 0 01.334-.334h2.666a.333.333 0 01.334.334v12.666a.333.333 0 01-.334.334z"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <path d="M0 0h16v16H0V0z" fill="#fff"></path>
                    </clipPath>
                  </defs>
                </svg>
                <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"
                     fill="currentColor" className="product-options-svg">
                  <path
                    d="M11.912 5.347A.683.683 0 0011.318 5H4.682a.682.682 0 00-.584 1.035l3.318 5.482a.681.681 0 001.168 0l3.318-5.482a.682.682 0 00.01-.688z"
                    fill="currentColor"></path>
                </svg>
              </button>

              <FormDialog
                title={''}
                open={isPimOptionsOpened}
                hideCloseButton={false}
                className="dialog-inputs-custom dialog-content-visible"
                closeButtonTitle="Apply"
                submitButtonTitle="Reset"
                maxWidth="xs"
                onClose={handldeClosePimOptions}
                onSubmit={handleSavePimOptions}
                type="pimOptions"
              >
                <PimOptions />
              </FormDialog>
            </div>
            <div className="col-4">

              {!isEmpty(pimProps.list) && (
                <div
                  className="product-header-right-outliner"
                  style={ pimProps.pages === 1 ? { textAlign: 'right', padddingTop: '5px' } : { padddingTop: '5px' }}
                >
                  <div className="product-header-right-page-items">
                    <div className="product-header-left justify-start">
                      {pimProps.total ? (
                        <div style={{ whiteSpace: 'nowrap' }}>
                          { pimProps.total.toLocaleString() } <IntlMessages id="repricer.items" />.&nbsp;
                        </div>
                      ) : (
                        <div style={{ whiteSpace: 'nowrap' }}>
                          0 <IntlMessages id="repricer.items" />.
                        </div>
                      )}
                    </div>
                  </div>
                  {pimProps.pages > 1 && (
                    <div className="product-header-right">
                      <ProductsPagination
                        flexAuto
                        paginationSettings={{
                          onPaginate: handlePagination,
                          pages: pimProps.pages,
                          total: pimProps.total,
                          pagination: pimProps.pagination,
                          helperTextId: pimProps.paginationHelperTextId,
                          disabled: hasEmptyFilters,
                        }}
                      />
                    </div>
                  )}
                </div>
              )}

            </div>
          </div>
        </div>

      </div>
      {isActionBarActive && (
        <Can
          do={appPermissions.pim.permissions.update}
          on={appPermissions.pim.name}
        >
          <ListActionBar />
        </Can>
      )}
      <ProductContainer
        fromRules={fromRules}
        pimOptions={pimOptions}
        wrapperClassName={`${productContainerLocalClassName} ${pimProps.dir}`}
      />
      {
        (productProps.editInProgress || productProps.nextProductLoading) && (
          <ProductDetail
            refreshModal={handleRefreshModal}
            pimAttributes={pimAttributes[pimProps.filterTarget]}
            productList={pimProps.list}
            getProductDetail={getProductDetail}
          />
        )
      }
      <PagePlaceholder
        displayMode={pimProps.displayMode}
        productListEmpty={productListEmpty}
        product={pimProps}
      />
      <ProductPriceModal/>
    </div>
  );
});

MainContent.propTypes = {
  setContainerStyles: func.isRequired,
  setUpdatedProductsImagesOverrides: func.isRequired,
  setPagination: func.isRequired,
  setPaginationHelperTextId: func.isRequired,
  setHelperText: func.isRequired,
  fetchProductList: func.isRequired,
  setFilterTarget: func.isRequired,
  setDisplayMode: func.isRequired,
  setSearchValue: func.isRequired,
  pimProps: shape().isRequired,
  pimAttributes: shape(),
  productProps: shape().isRequired,
  previewFilterAttributeList: arrayOf(shape()).isRequired,
  sessionItem: shape().isRequired,
  updateSavedProducts: func.isRequired,
};

MainContent.defaultProps = {
  pimAttributes: {},
  sessionItem: {},
};

export const mapDispatchToProps = {
  setSelectedProducts,
  setProductForEdit,
  fetchProduct,
  setIsEditMode,
  fetchChildProductList,
  fetchProductAttributesForVariations,
  fetchActivityLogsByProductId,
  multipleUpdateUserActivityLogs,
  fetchAttributeOptions,
  setContainerStyles,
  setSelectedAction,
  setUpdatedProductsImagesOverrides,
  setPagination,
  setPaginationHelperTextId,
  setHelperText,
  fetchProductList,
  setFilterTarget,
  setDisplayMode,
  setSearchValue,
  setProductCustomColumns,
  updateSavedColumns,
};

export const mapStateToProps = state => ({
  commentMessages: state.commentMessage.list,
  pimAttributes: state.system.modes.pim,
  previewFilterAttributeList: state.previewFilterAttribute.list,
  pimProps: pimSelector(state.pim),
  productProps: productSelector(state.product),
  sessionItem: state.session.item,
});

export default connect(mapStateToProps, mapDispatchToProps)(MainContent);
