import { createSelector } from 'reselect';

const contextMenuRootSelector = state => state;

const stateSelector = createSelector(
  contextMenuRootSelector,
  state => ({
    showContextMenu: state.showContextMenu,
    contextMenuStyles: state.contextMenuStyles,
  }),
);

export const contextMenuSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  contextMenuSelector,
};
