import React, { memo } from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import {
  func, arrayOf, shape, number,
} from 'prop-types';

import ParentLevelList from '../../components/ParentLevelList';
import { pimSelector } from './selectors/pimSelector';
import { productAttributeSelector } from './selectors/productAttributeSelector';
import { productSelector } from './selectors/productSelector';
import {
  setMerchantActivityDialog,
  setShowZapInfo,
  fetchParentLevelChildren,
  toggleSelectedProduct,
  setAllProductsAsSelected,
  fetchPimProductPhysicalCategoriesByIds,
  setSortField,
  bulkDeleteProductLockedAttributes,
  fetchProductList,
  setHelperText,
  setPaginationHelperTextId,
  setSelectedProducts,
  setSelectedProduct,
} from '../../../../actions/pim';
import Dialog from '../../../../components/Dialog';
import ZapInfo from '../../components/ZapInfo';

import {
  fetchProductAttributeSets,
} from '../../../../actions/productAttribute';

import {
  fetchActivityLogsByProductId,
  multipleUpdateUserActivityLogs,
  fetchProduct,
  setProductForEdit,
} from '../../../../actions/product';

import { checkAndConverteDataByFieldOperator } from '../../utils/checkAndConverteDataByFieldOperator';
import { getDefaultRootFilter } from '../../utils/defaultData';
import { mapFiltersByOperator } from '../../../../util/mapFiltersByOperator';
import { mapPrefixToFilters } from '../../../../util/filterMapper';

import { mergeFields } from '../../pages/Home/utils/mappers';

const ParentContainer = memo((props) => {
  const operatorsWithoutValue = new Set(['exists', 'not_exists']);

  const {
    grid, pimProps, productAttributeProps, productProps, pimAttributes,
    previewFilterAttributeList, commentMessages, fromRules,
  } = props;

  const searchFields = [
    ...pimProps.globalFields[pimProps.filterTarget],
    ...pimProps.savedFilterFields[pimProps.filterTarget],
  ];

  const pimAttributesList = pimAttributes[pimProps.filterTarget] || [];
  const sortedAttributes = pimAttributesList.sort((a, b) => a.order - b.order);

  const handleEditProduct = (forceSelected) => {
    const productToEdit = forceSelected && forceSelected.id
      ? forceSelected
      : pimProps.selectedProduct;

    if (!isEmpty(pimProps.selectedProducts)) props.setSelectedProducts([]);

    if (pimProps.displayMode === 'list') {
      props.fetchProduct(productToEdit.id);
    } else {
      props.setProductForEdit({
        edit: productProps.item,
        editInProgress: true,
      });
    }
    // props.fetchActivityLogsByProductId(productToEdit.id);
  };

  const handleEditCLick = ({ productItem }) => {
    props.setSelectedProduct(productItem);
    handleEditProduct(productItem);
  };

  const handleExpandParentLevel = (parent) => {
    const { childrenIds, parentId } = parent;
    const idsToFetch = pimProps.filterTarget === 'parent'
      ? childrenIds
      : [parentId];
    props.fetchParentLevelChildren(idsToFetch, parent);
  };

  const handleUnlockAttributesClick = ({ productItem }) => {
    const productTypes = [];
    productTypes[productItem.id] = productItem.type;
    props.bulkDeleteProductLockedAttributes({
      productIds: [productItem.id],
      fields: [...searchFields],
      filterTarget: pimProps.filterTarget,
      productTypes,
    });
  };

  const mapListModeSearchFilters = (filters) => {
    const filteredFilters = filters.filter(f => !f.group.some(g => g.value === 'parent'));
    const mappedFilters = mapFiltersByOperator(filteredFilters, previewFilterAttributeList);

    return mapPrefixToFilters([...mappedFilters, ...getDefaultRootFilter(pimProps.filterTarget)]);
  };

  const fetchProducts = ({
    sort,
  }) => {
    const filtersPayload = [
      ...pimProps.savedFilterFilters[pimProps.filterTarget],
      ...pimProps.globalFilters[pimProps.filterTarget],
    ];

    const fieldsPayload = [
      ...pimProps.savedFilterFields[pimProps.filterTarget],
      ...pimProps.globalFields[pimProps.filterTarget],
    ];

    let targetSort = {};
    if (!isEmpty(sort)) {
      targetSort = {
        sort: [{
          field: sort.field,
          order: sort.order,
        }],
      };
    }

    props.setHelperText('');
    props.setPaginationHelperTextId('');

    const convertedFilters = checkAndConverteDataByFieldOperator(
      [...filtersPayload],
    );

    const mappedFilters = mapListModeSearchFilters(convertedFilters);
    const filter = mappedFilters.filter(f => f.group && f.group
      .every(g => g.field && (!isEmpty(g.value) || operatorsWithoutValue.has(g.operator))));

    return props.fetchProductList({
      viewMode: 'backend',
      filter,
      pagination: pimProps.pagination,
      searchFilter: pimProps.searchQuery,
      fields: pimProps.isCustomFieldsMode
        ? mergeFields(fieldsPayload, [])
        : [],
      fullText: pimProps.fullTextListModeSearch,
      ...targetSort,
    });
  };

  const onHeaderCellClick = (cell, order) => {
    props.setSortField({
      ...pimProps.sort,
      [pimProps.filterTarget]: { field: cell.sortCode, order },
    });
    fetchProducts({
      sort: { field: cell.sortCode, order },
    });
  };

  const onProductSelect = productItem => props.toggleSelectedProduct(productItem);

  const actions = {
    onProductSelect,
    onHeaderCellClick,
    handleExpandParentLevel,
    onEditClick: handleEditCLick,
    onUnlockAttributesClick: handleUnlockAttributesClick,
    currency: props.currencyItem,
    onAllProductsSelect: props.setAllProductsAsSelected,
    fetchProductAttributeSets: props.fetchProductAttributeSets,
    fetchActivityLogsByProductId: props.fetchActivityLogsByProductId,
    multipleUpdateUserActivityLogs: props.multipleUpdateUserActivityLogs,
    fetchPimProductPhysicalCategoriesByIds: props.fetchPimProductPhysicalCategoriesByIds,
  };

  const pimListProps = {
    items: pimProps.list,
    disabled: !pimProps.isEditMode,
    pagination: pimProps.pagination,
    isEditMode: pimProps.isEditMode,
    selectedProducts: pimProps.selected,
    defaultImage: pimProps.defaultImage,
    isCustomFields: pimProps.isCustomFieldsMode,
    productsCommentsCount: pimProps.productsCommentsCount,
    lastParentChildrenLoaded: pimProps.lastParentChildrenLoaded,
    productsPhysicalCategoryList: pimProps.productsPhysicalCategoryList,
    productsPhysicalCategoryListFetching: pimProps.productsPhysicalCategoryListFetching,
    fetchParentLevelChildrenProductsStarted: pimProps.fetchParentLevelChildrenProductsStarted,
    bulkDeleteProductLockedAttributesStarted: pimProps.bulkDeleteProductLockedAttributesStarted,
    bulkDeleteProductLockedAttributesFinished:
      pimProps.bulkDeleteProductLockedAttributesFinished,
  };

  const pimListAttributesProps = {
    attributes: sortedAttributes,
    attributeSets: productAttributeProps.attributeSets,
    initialAttributesOptions: productAttributeProps.initialOptions,
    certainProductAttributes: productAttributeProps.certainProductAttributes,
  };

  const currentAttributes = [];
  if (pimListAttributesProps.attributes.length > 0 && productProps.pimOptions.length > 0) {
    for (let i = 0; i < pimListAttributesProps.attributes.length; i++) {
      if (productProps.pimOptions.indexOf(pimListAttributesProps.attributes[i].code) !== -1) {
        currentAttributes.push(pimListAttributesProps.attributes[i]);
      }
    }
    pimListAttributesProps.attributes = currentAttributes;
  }

  return (
    <>
      <Dialog />
      <ZapInfo />
      <ParentLevelList
        fromRules={fromRules}
        listProps={{
          ...actions,
          ...pimListProps,
          ...pimListAttributesProps,
          grid,
          searchFields,
          commentMessages,
          productComments: productProps.productComments,
          productCommentsLoading: productProps.fetchProductCommentsStart,
        }}
      />
    </>
  );
});

ParentContainer.propTypes = {
  pimAttributes: shape(),
  productAttributeProps: shape().isRequired,
  pimProps: shape().isRequired,
  productProps: shape().isRequired,
  commentMessages: arrayOf(shape()).isRequired,
  previewFilterAttributeList: arrayOf(shape()).isRequired,
  grid: number.isRequired,
  fetchActivityLogsByProductId: func.isRequired,
  fetchParentLevelChildren: func.isRequired,
  bulkDeleteProductLockedAttributes: func.isRequired,
  toggleSelectedProduct: func.isRequired,
  setAllProductsAsSelected: func.isRequired,
  fetchPimProductPhysicalCategoriesByIds: func.isRequired,
  fetchProductAttributeSets: func.isRequired,
  multipleUpdateUserActivityLogs: func.isRequired,
  setSortField: func.isRequired,
  fetchProductList: func.isRequired,
  currencyItem: shape({
    value: number,
  }),
  setHelperText: func.isRequired,
  setPaginationHelperTextId: func.isRequired,
  setSelectedProducts: func.isRequired,
  fetchProduct: func.isRequired,
  setProductForEdit: func.isRequired,
  setSelectedProduct: func.isRequired,
};

ParentContainer.defaultProps = {
  pimAttributes: {},
  currencyItem: {},
};

const mapStateToProps = state => ({
  pimProps: pimSelector(state.pim),
  productProps: productSelector(state.product),
  productAttributeProps: productAttributeSelector(state.productAttribute),
  commentMessages: state.commentMessage.list,
  currencyItem: state.currency.item,
  pimAttributes: state.system.modes.pim,
  previewFilterAttributeList: state.previewFilterAttribute.list,
});

const mapDispatchToProps = {
  fetchActivityLogsByProductId,
  fetchParentLevelChildren,
  bulkDeleteProductLockedAttributes,
  toggleSelectedProduct,
  setAllProductsAsSelected,
  fetchPimProductPhysicalCategoriesByIds,
  fetchProductAttributeSets,
  multipleUpdateUserActivityLogs,
  setSortField,
  fetchProductList,
  setHelperText,
  setPaginationHelperTextId,
  setSelectedProducts,
  fetchProduct,
  setProductForEdit,
  setSelectedProduct,
  setMerchantActivityDialog,
  setShowZapInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentContainer);
