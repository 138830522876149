import React from 'react';
import IntlMessages from 'util/IntlMessages';

export default messages => (
  <span className="helper errors">
    {
      messages.map(m => (
        <span key={m.type}>
          <IntlMessages id={m.message} />
        </span>
      ))
    }
  </span>
);
