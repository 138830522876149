import React from 'react';
import {
  func, shape, number, string,
} from 'prop-types';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const RightSidePagination = (props) => {
  const { pagination, total } = props;
  const pageCount = total / pagination.limit;

  return (
    <div className="pagination pagination-xs justify-end">
      {total > pagination.limit && (
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          breakLabel={<span>...</span>}
          nextLabel={<FontAwesomeIcon size="lg" icon={faAngleRight} />}
          previousLabel={<FontAwesomeIcon size="lg" icon={faAngleLeft} />}
          breakClassName="break-me"
          activeClassName="active"
          containerClassName={`pagination flex justify-center ${props.containerClassName}`}
          onPageChange={props.onPaginate}
          pageClassName="page-btn"
          forcePage={props.pagination.page - 1}
        />
      )}
    </div>
  );
};

RightSidePagination.propTypes = {
  onPaginate: func.isRequired,
  total: number,
  pagination: shape({
    page: number,
  }),
  containerClassName: string,
};

RightSidePagination.defaultProps = {
  pagination: {},
  containerClassName: '',
  total: 0,
};

export default RightSidePagination;
