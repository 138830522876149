import React from 'react';
import {
  func, string, bool, oneOfType, node,
} from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export const CheckboxField = ({
  checked, className, value, onChange, name, label, disabled, size,
}) => (
  <div className={`checkbox-wrapper ${className}`}>
    <FormControlLabel
      control={(
        <Checkbox
          size={size}
          color="primary"
          checked={checked}
          onChange={onChange}
          value={value}
          name={name}
          disabled={disabled}
        />
      )}
      label={label}
    />
  </div>
);

CheckboxField.propTypes = {
  checked: bool,
  className: string,
  name: string,
  value: string,
  onChange: func,
  label: oneOfType([string, node]),
  disabled: bool,
  size: string,
};

CheckboxField.defaultProps = {
  checked: false,
  className: '',
  name: '',
  value: '',
  onChange: null,
  label: '',
  disabled: false,
  size: 'medium',
};

export default CheckboxField;
