/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import {
  func, shape, node, oneOfType, array,
} from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

import IntlMessages from 'util/IntlMessages';
import InputErrors from 'components/InputErrors';

const Designation = memo((props) => {
  const { handleNameChange, errors, name } = props;

  const onEngNameChange = (e) => {
    const lngKey = 'en';

    handleNameChange(e, lngKey);
  };

  const onHebwrewNameChange = (e) => {
    const lngKey = 'he';

    handleNameChange(e, lngKey);
  };

  return (
    <div className="form-group-inline">
      <FormGroup className="block">
        <Label for="name" className="mr-sm-10 form-label">
          <IntlMessages id="form.nameEnLabel" />
        </Label>
        <div className="form-control-wrapper">
          <Input
            className={errors.en ? 'has-error' : ''}
            value={name ? name.en : ''}
            type="text"
            name="name"
            id="nameEn"
            onChange={onEngNameChange}
          />
          {errors.en && <InputErrors className="errors-rel" errors={errors.en} />}
        </div>
      </FormGroup>
      <FormGroup className="block">
        <Label for="name" className="mr-sm-10 form-label">
          <IntlMessages id="form.nameHeLabel" />
        </Label>
        <div className="form-control-wrapper">
          <Input
            className={errors.he ? 'has-error' : ''}
            value={name ? name.he : ''}
            type="text"
            name="name"
            id="nameHe"
            onChange={onHebwrewNameChange}
          />
          {errors.he && <InputErrors className="errors-rel" errors={errors.he} />}
        </div>
      </FormGroup>
      {props.children}
    </div>
  );
});

Designation.propTypes = {
  handleNameChange: func.isRequired,
  errors: oneOfType([shape(), array]).isRequired,
  name: shape().isRequired,
  children: node,
};

Designation.defaultProps = {
  children: null,
};

export default Designation;
