import { camelizeKeys } from 'humps';
import firebase from 'firebase/app';
import RestActions from '../util/rest/actions';

import { CLEAR_SERVICE_CONFIG_STATE } from './types';
import { setIMSAuthInterceptor, setPMACAuthInterceptor, setCAMSAuthInterceptor } from "../util/api";

const restServiceConfigActions = new RestActions('service_config');
const {
  fetchStart,
  fetchFinished,
} = restServiceConfigActions;

export const fetchServiceConfig = cb => async (dispatch) => {
  dispatch(fetchStart());
  const config = await firebase.database().ref('/service_config').once('value');
  const configResult = config.val();

  setIMSAuthInterceptor(configResult.IMS_token);
  setPMACAuthInterceptor(configResult.PMAC_token);
  setCAMSAuthInterceptor(configResult.CAMS_username, configResult.CAMS_password);

  dispatch(fetchFinished({
    item: camelizeKeys(configResult),
  }));
  if (cb) cb();
};

export const clearServiceConfigState = () => (dispatch) => {
  dispatch({ type: CLEAR_SERVICE_CONFIG_STATE });
};

export default {
  fetchServiceConfig,
  clearServiceConfigState,
};
