import { createSelector } from 'reselect';

const virtualCategoryPageRootSelector = state => state;

const stateSelector = createSelector(
  virtualCategoryPageRootSelector,
  virtualCategoryPage => ({
    virtualCategoryItem: virtualCategoryPage.item,
    filterCategory: virtualCategoryPage.filter.category,
    facetCategory: virtualCategoryPage.facet,
    virtualCategoryFetched: virtualCategoryPage.fetchedOne,
    virtualCategoryUpdated: virtualCategoryPage.updated,
  }),
);

export const virtualCategoryPageSelector = createSelector(
  stateSelector,
  state => ({
    ...state,
  }),
);

export default {
  virtualCategoryPageSelector,
};
