/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  func, shape, arrayOf,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

import { Button, Tooltip } from '@material-ui/core';

import Field from '../../../../components/Field';
import IntlMessages from '../../../../util/IntlMessages';
import { actionOptions, actions } from '../../utils/actionBarConsts';
import checkValidations from '../../../../util/validator';
import {
  commentValidations,
  systemCommentValidations,
} from './utils/validations';
import {
  moveProductsToPosition,
  moveProductsToBottom,
} from '../../pages/Home/utils/pinActions';
import {
  handleUnHideProducts,
  handleHideProducts,
} from '../../pages/Home/utils/visibilityActions';
import entityTypes from '../../../../util/entityTypes';

import {
  setSelectedProducts,
  setContainerStyles,
  setIsHiddenProductsMode,
  setSelectedAction,
  setProductOverride,
  setHiddenProductsFilter,
  setActionPopoverState,
  setActionPopoverAnchor,
} from '../../../../actions/preview';

import {
  multipleUpdateUserActivityLogs,
} from '../../../../actions/product';

import {
  update as updateCategory,
} from '../../../../actions/virtualCategory';

import {
  updateSearchFilter,
} from '../../../../actions/search';

import {
  updatePromotionFilter,
} from '../../../../actions/promotion';

import {
  previewSelector,
} from './selectors/previewSelector';
import {
  virtualCategoryWidgetSelector,
} from '../../selectors/virtualCategoryWidgetSelector';
import {
  promotionWidgetSelector,
} from '../../selectors/promotionWidgetSelector';

const parentActions = [
  actions.pinToBottom, actions.pinToTop, actions.pinToPosition, actions.unpin,
  actions.hide, actions.show,
];

const ListActionBar = (props) => {
  const [comment, setComment] = useState('');
  const [systemComment, setSystemComment] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [order, setOrder] = useState('');
  const [productPinOrderHeplerTextId, setProductPinOrderHeplerTextId] = useState('');
  const [errors, setErrors] = useState({});

  const {
    previewProps,
    virtualCategoryWidgetProps,
    promotionWidgetProps,
  } = props;

  const getEntityToUse = () => {
    const { selectedSearchFilter } = previewProps;
    let entityToUse = {};

    if (!isEmpty(virtualCategoryWidgetProps.selectedCategory)) {
      entityToUse = virtualCategoryWidgetProps.selectedCategory;
    }
    if (!isEmpty(promotionWidgetProps.selectedPromotion)) {
      entityToUse = promotionWidgetProps.selectedPromotion;
    }
    if (!isEmpty(selectedSearchFilter)) entityToUse = selectedSearchFilter;
    return entityToUse;
  };

  const selectedEntity = getEntityToUse();

  const { virtualParams } = selectedEntity;
  const { productsOverride, filters } = virtualParams || {};

  useEffect(() => {
    props.setSelectedAction('');
  }, [
    selectedEntity,
    previewProps.selectedProducts,
  ]);

  useEffect(() => {
    setComment('');
    setSystemComment('');
  }, [previewProps.selectedAction, previewProps.selectedProducts]);

  useEffect(() => {
    const actionBar = document.querySelector('.actions-bar');
    const actionBarHeight = actionBar ? actionBar.offsetHeight : 0;
    const newHeight = `calc(100vh - ${actionBarHeight + 58 + 66}px)`;

    if (actionBar && previewProps.containerStyles.height !== newHeight) {
      props.setContainerStyles({
        height: `calc(100vh - ${actionBarHeight + 58 + 66}px)`,
      });
    }
  });

  const handleChangeAction = (e) => {
    props.setSelectedAction(e.target.value);
  };

  const handleChangeComment = e => setComment(e.target.value);
  const handleChangeSystemComment = e => setSystemComment(e.target.value);

  const toggleAlert = () => {
    setShowAlert(!showAlert);
  };

  const handleUpdateCategory = (newProductsOverride) => {
    const payload = {
      id: virtualCategoryWidgetProps.selectedCategory.id,
      isEnabled: virtualCategoryWidgetProps.selectedCategory.isEnabled,
      includeInMenu: virtualCategoryWidgetProps.selectedCategory.includeInMenu,
      useInProductSearch: virtualCategoryWidgetProps.selectedCategory.useInProductSearch,
      virtualParams: {
        ...virtualCategoryWidgetProps.selectedCategory.virtualParams,
        productsOverride: newProductsOverride,
      },
    };
    const params = { isSync: true, mapToMatrix: true };

    props.updateCategory(payload, params);
  };

  const handleUpdateSearchFilter = (newProductsOverride) => {
    const { selectedSearchFilter } = previewProps;
    const payload = {
      id: selectedSearchFilter.id,
      type: selectedSearchFilter.type,
      searchPhrase: selectedSearchFilter.searchPhrase,
      virtualParams: {
        ...selectedSearchFilter.virtualParams,
        productsOverride: newProductsOverride,
      },
    };

    props.updateSearchFilter(selectedSearchFilter.id, payload);
  };

  const handleUpdatePromotion = (newProductsOverride) => {
    const payload = {
      id: promotionWidgetProps.selectedPromotion.id,
      type: promotionWidgetProps.selectedPromotion.type,
      urlKey: promotionWidgetProps.selectedPromotion.urlKey,
      virtualParams: {
        ...promotionWidgetProps.selectedPromotion.virtualParams,
        productsOverride: newProductsOverride,
      },
    };

    props.updatePromotionFilter(promotionWidgetProps.selectedPromotion.id, payload);
  };

  const prepareAndUpdateVirtualParams = (newProductsOverride) => {
    if (!isEmpty(promotionWidgetProps.selectedPromotion)) {
      handleUpdatePromotion(newProductsOverride);
    }
    if (!isEmpty(previewProps.selectedSearchFilter)) handleUpdateSearchFilter(newProductsOverride);
    if (!isEmpty(virtualCategoryWidgetProps.selectedCategory)) {
      handleUpdateCategory(newProductsOverride);
    }
  };

  const checkIsParentExist = () => previewProps.selectedProducts
    .some(el => previewProps.productList.some(item => item.id === el.id));

  const getActionOptions = () => {
    let updatedActionOptions = [...actionOptions];
    const listActionsForSelectedEntity = [
      actions.pinToTop,
      actions.pinToBottom,
      actions.pinToPosition,
      actions.unpin,
    ];
    updatedActionOptions = actionOptions.map((a) => {
      const isPresent = listActionsForSelectedEntity.find(ac => ac === a.value);
      const unpinAction = actions.unpin === a.value;
      const showAction = actions.show === a.value;
      const hideAction = actions.hide === a.value;
      const allProductsArePinned = previewProps.selectedProducts
        .every(p => productsOverride
          && productsOverride.some(pO => p.id === pO.productId));

      if (!showAction && !unpinAction && previewProps.isHiddenProductsMode) {
        return {
          ...a,
          disabled: true,
        };
      }

      if (showAction && !previewProps.isHiddenProductsMode) {
        return {
          ...a,
          disabled: true,
        };
      }

      if (hideAction && previewProps.isHiddenProductsMode) {
        return {
          ...a,
          disabled: true,
        };
      }

      if (unpinAction && productsOverride && !allProductsArePinned) {
        return {
          ...a,
          disabled: true,
        };
      }

      if (isEmpty(selectedEntity) && isPresent) {
        return {
          ...a,
          disabled: true,
        };
      }

      const isParentAction = parentActions.find(pa => pa === a.value);
      if (!checkIsParentExist() && isParentAction) {
        return {
          ...a,
          disabled: true,
        };
      }
      return a;
    });
    return updatedActionOptions;
  };

  const handleUpdateEntityFilters = ({
    filters: newFilters, productsOverride: newProductsOverride,
  }) => {
    const { selectedEntityName } = previewProps;
    const params = { isSync: true, mapToMatrix: true };

    const payload = {
      id: selectedEntity.id,
      virtualParams: {
        ...virtualParams,
        filters: newFilters || virtualParams.filters,
        productsOverride: newProductsOverride || virtualParams.productsOverride,
      },
    };

    props.setActionPopoverState(false);
    props.setActionPopoverAnchor(null);

    if (selectedEntityName === 'category') {
      payload.isEnabled = selectedEntity.isEnabled;
      payload.includeInMenu = selectedEntity.includeInMenu;
      payload.useInProductSearch = selectedEntity.useInProductSearch;

      props.updateCategory(payload, params);
    } else if (selectedEntityName === 'promotion') {
      payload.type = selectedEntity.type;
      payload.urlKey = selectedEntity.urlKey;

      props.updatePromotionFilter(payload.id, payload);
    } else {
      payload.searchPhrase = selectedEntity.searchPhrase;
      payload.type = selectedEntity.type;

      props.updateSearchFilter(payload.id, payload);
    }
  };

  const hideProducts = products => handleHideProducts({
    products,
    filters,
    setHiddenProductsFilter: props.setHiddenProductsFilter,
    handleUpdateEntityFilters,
  });
  const showProducts = products => handleUnHideProducts({
    products,
    filters,
    setIsHiddenProductsMode: props.setIsHiddenProductsMode,
    setHiddenProductsFilter: props.setHiddenProductsFilter,
    handleUpdateEntityFilters,
  });
  const setMinPositions = products => moveProductsToPosition({
    productsToMove: products,
    order: 1,
    productsOverride,
    setProductOverride: props.setProductOverride,
    prepareAndUpdateVirtualParams,
    updateMultipleComments: props.multipleUpdateUserActivityLogs,
    comment: `${systemComment}@${comment}`,
  });
  const setToPosition = products => moveProductsToPosition({
    productsToMove: products,
    order: parseFloat(order),
    productsOverride,
    setProductOverride: props.setProductOverride,
    prepareAndUpdateVirtualParams,
    updateMultipleComments: props.multipleUpdateUserActivityLogs,
    comment: `${systemComment}@${comment}`,
  });
  const setMaxPositions = products => moveProductsToBottom({
    productsToMove: products,
    productsOverride,
    setProductOverride: props.setProductOverride,
    prepareAndUpdateVirtualParams,
    updateMultipleComments: props.multipleUpdateUserActivityLogs,
    comment: `${systemComment}@${comment}`,
  });

  const unpinProducts = (productsToUnpin) => {
    const sortedOverrides = productsOverride.sort((a, b) => a.position - b.position);
    const payload = sortedOverrides
      .filter(pO => productsToUnpin.every(p => pO.productId !== p.id));

    prepareAndUpdateVirtualParams(payload);
  };

  const unpinPosition = products => unpinProducts(products);

  const handleActions = (products) => {
    switch (previewProps.selectedAction) {
      case actions.hide: hideProducts(products);
        break;
      case actions.show: showProducts(products);
        break;
      case actions.pinToTop: setMinPositions(products);
        break;
      case actions.pinToPosition: setToPosition(products);
        break;
      case actions.pinToBottom: setMaxPositions(products);
        break;
      case actions.unpin: unpinPosition(products);
        break;
    }
  };

  const isParentProducts = () => {
    let onlyParentIsPresent = true;
    previewProps.selectedProducts.forEach((s) => {
      const item = previewProps.productList.find(i => i.id === s.id);
      if (!item) {
        onlyParentIsPresent = false;
      }
    });
    return onlyParentIsPresent;
  };

  const handleSubmitAlert = () => {
    const productsToUpdate = previewProps.productList.filter(
      s => !!previewProps.selectedProducts.find(i => i.id === s.id),
    );
    props.setSelectedProducts(productsToUpdate);

    handleActions(productsToUpdate);
    const updatedProducts = productsToUpdate.map(p => ({
      id: p.id,
    }));
    if (!isEmpty(updatedProducts)) {
      const updatedActivityLogs = [{
        affectedIds: updatedProducts.map(p => p.id),
        entityType: entityTypes.catalogProductAttribute,
        comment: `${systemComment}@${comment}`,
      }];
      props.multipleUpdateUserActivityLogs(updatedActivityLogs);
    }
    setShowAlert(false);
  };

  const checkIsOrderValid = () => (order.toString().length <= 10 && order.toString().length > 0);

  const handleSubmitActionBar = () => {
    const actionErrors = checkValidations({
      ...commentValidations, ...systemCommentValidations,
    }, { comment, systemComment });
    if (!isEmpty(actionErrors)) {
      return setErrors(actionErrors);
    }
    setErrors({});

    if (previewProps.selectedAction === actions.pinToPosition) {
      const isOrderValid = checkIsOrderValid();
      if (!isOrderValid) {
        return setProductPinOrderHeplerTextId('text.errors.numberLengthLimit');
      }
    }
    if (!isParentProducts() && parentActions.find(a => a === previewProps.selectedAction)) {
      return setShowAlert(true);
    }

    const excludedActions = [actions.pinToTop, actions.pinToPosition, actions.pinToBottom];
    handleActions(previewProps.selectedProducts);
    if (!excludedActions.some(ea => ea === previewProps.selectedAction)) {
      const updatedProducts = previewProps.selectedProducts.map(p => ({ id: p.id }));
      if (!isEmpty(updatedProducts)) {
        const updatedActivityLogs = [{
          affectedIds: updatedProducts.map(p => p.id),
          entityType: entityTypes.catalogProductAttribute,
          comment: `${systemComment}@${comment}`,
        }];
        props.multipleUpdateUserActivityLogs(updatedActivityLogs);
        return setProductPinOrderHeplerTextId('');
      }
    }
    return false;
  };

  const handleCancelActionBar = () => {
    setComment('');
    props.setSelectedAction('');
  };

  const handleOrderChange = (e) => {
    const { value } = e.target;

    const format = /^[0-9]*$/gm;

    if (!format.test(value)) {
      setOrder(order);
    } else {
      setOrder(value);
    }
  };

  const orderErrorClass = !isEmpty(productPinOrderHeplerTextId) ? 'has-error input-padding-clear' : '';
  const commentErrorClass = !isEmpty(errors.comment) ? 'has-error input-padding-clear' : '';
  const systemCommentErrorClass = !isEmpty(errors.systemComment) ? 'has-error input-padding-clear' : '';

  const commentMessagesOptions = props.commentMessageList.map(cm => ({
    ...cm, value: cm.title,
  }));

  return (
    <div className="actions-bar flex items-center justify-between">
      <div className="form-wrapper form-inline">
        <Field
          element="select"
          onChange={handleChangeAction}
          value={previewProps.selectedAction}
          options={getActionOptions()}
          className="min-w-sm select-bordered form-group"
          emptyPlaceholder="Action"
          name="action"
          key="action"
          label="Action"
        />
        {
          previewProps.selectedAction && previewProps.selectedAction === 'pin_to_position' && (
            <Field
              element="number"
              onChange={handleOrderChange}
              value={order}
              className={`min-w-sm input-bordered form-group input-text ${orderErrorClass}`}
              emptyPlaceholder="Order"
              name="order"
              key="order"
              label="Order"
              suffix={productPinOrderHeplerTextId && (
                <Tooltip
                  placement="top-end"
                  title={(
                    <div className="tooltip-title-content-wrapper">
                      <IntlMessages id={productPinOrderHeplerTextId} />
                      10
                    </div>
                  )}
                >
                  <i className="ti-info-alt" />
                </Tooltip>
              )}
              min="1"
            />
          )
        }
        {
          previewProps.selectedAction && ([
            <Field
              element="select"
              onChange={handleChangeSystemComment}
              options={commentMessagesOptions}
              value={systemComment}
              className={`min-w-sm select-bordered form-group input-select ${systemCommentErrorClass}`}
              emptyPlaceholder="Comment"
              name="systemComment"
              key="systemComment"
              label={<IntlMessages id="preview.productDialog.systemComment.label" />}
              suffix={!isEmpty(errors.systemComment) && (
                <Tooltip
                  placement="top-end"
                  title={(
                    <div className="tooltip-title-content-wrapper">
                      {errors.systemComment.map(
                        e => <IntlMessages id={e.message} key={e.message} />,
                      )}
                    </div>
                  )}
                >
                  <i className="ti-info-alt" />
                </Tooltip>
              )}
            />,
            <Field
              element="text"
              onChange={handleChangeComment}
              value={comment}
              className={`min-w-sm input-bordered form-group input-text ${commentErrorClass}`}
              wrapperClassName="mr-10"
              emptyPlaceholder="Comment"
              name="comment"
              key="comment"
              label={<IntlMessages id="preview.productDialog.comment.label" />}
              suffix={!isEmpty(errors.comment) && (
                <Tooltip
                  placement="top-end"
                  title={(
                    <div className="tooltip-title-content-wrapper">
                      { errors.comment.map(e => <IntlMessages id={e.message} key={e.message} />) }
                    </div>
                  )}
                >
                  <i className="ti-info-alt" />
                </Tooltip>
              )}
            />,
          ])
        }
      </div>
      <div className="pl-10 pr-10 action-buttons">
        <Button
          variant="outlined"
          onClick={handleSubmitActionBar}
          color="primary"
          disabled={!previewProps.selectedAction || isEmpty(previewProps.selectedProducts)}
        >
          <IntlMessages id="preview.list.actionBar.save" />
        </Button>
        <Button
          variant="outlined"
          onClick={handleCancelActionBar}
          color="default"
          disabled={!previewProps.selectedAction || isEmpty(previewProps.selectedProducts)}
        >
          <IntlMessages id="preview.list.actionBar.cancelButton" />
        </Button>
      </div>
      <SweetAlert
        warning
        btnSize="sm"
        showCancel
        show={showAlert}
        confirmBtnText={<IntlMessages id="preview.list.actionBar.saveModal.confirmButton" />}
        confirmBtnBsStyle="success"
        cancelBtnText={<IntlMessages id="preview.list.actionBar.saveModal.cancelButton" />}
        cancelBtnBsStyle="danger"
        title={<IntlMessages id="preview.list.actionBar.saveModal.title" />}
        onConfirm={handleSubmitAlert}
        onCancel={toggleAlert}
      >
        <IntlMessages id="preview.list.actionBar.saveModal.content" />
      </SweetAlert>
    </div>
  );
};

ListActionBar.propTypes = {
  setSelectedAction: func.isRequired,
  multipleUpdateUserActivityLogs: func.isRequired,
  setProductOverride: func.isRequired,

  setIsHiddenProductsMode: func.isRequired,
  setHiddenProductsFilter: func.isRequired,
  setContainerStyles: func.isRequired,
  updateCategory: func.isRequired,
  updateSearchFilter: func.isRequired,
  updatePromotionFilter: func.isRequired,
  setActionPopoverState: func.isRequired,
  setActionPopoverAnchor: func.isRequired,

  previewProps: shape().isRequired,
  virtualCategoryWidgetProps: shape().isRequired,
  promotionWidgetProps: shape().isRequired,
  setSelectedProducts: func.isRequired,
  commentMessageList: arrayOf(shape()).isRequired,
};

ListActionBar.defaultProps = {};

const mapStateToProps = state => ({
  commentMessageList: state.commentMessage.list,
  previewProps: previewSelector(state.preview),
  virtualCategoryWidgetProps: virtualCategoryWidgetSelector(state.virtualCategoryWidget),
  promotionWidgetProps: promotionWidgetSelector(state.promotionWidget),
});

const mapDispatchToProps = {
  multipleUpdateUserActivityLogs,
  setSelectedProducts,
  setContainerStyles,
  setIsHiddenProductsMode,
  setSelectedAction,
  updateCategory,
  updateSearchFilter,
  updatePromotionFilter,
  setProductOverride,
  setHiddenProductsFilter,
  setActionPopoverState,
  setActionPopoverAnchor,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListActionBar);
