import React, { memo } from 'react';
import { connect } from 'react-redux';
import { shape, func } from 'prop-types';
import { isEmpty } from 'lodash';

import InfluencersContainer from '../index';

import {
  updateInfluencer,
  uploadAvatar,
  uploadMainBanner,
  uploadDealBanner,
} from '../../../../actions/influencer';

const InfluencerNew = memo((props) => {
  const {
    influencer, avatarData, mainBannerData, dealBannerData,
  } = props;

  const onCloseClick = () => {};
  const onSaveAndCloseClick = () => {};
  const onSaveClick = () => {};

  const handleSubmitChanges = () => {
    props.updateInfluencer(influencer);

    if (!isEmpty(avatarData)) {
      props.uploadAvatar({
        nameWithPath: avatarData.nameWithPath,
        url: avatarData.url,
        influencerId: influencer.id,
      });
    }

    if (!isEmpty(mainBannerData)) {
      props.uploadMainBanner({
        nameWithPath: mainBannerData.nameWithPath,
        url: mainBannerData.url,
        influencerId: influencer.id,
      });
    }

    if (!isEmpty(dealBannerData)) {
      props.uploadDealBanner({
        nameWithPath: dealBannerData.nameWithPath,
        url: dealBannerData.url,
        influencerId: influencer.id,
      });
    }
  };

  return (
    <InfluencersContainer
      className="container-influencer new"
      onCloseClick={onCloseClick}
      onSaveAndCloseClick={onSaveAndCloseClick}
      onSaveClick={onSaveClick}
      contentContainerClassName="influencers-content"
      handleSubmitChanges={handleSubmitChanges}
    />
  );
});

InfluencerNew.propTypes = {
  influencer: shape().isRequired,
  avatarData: shape().isRequired,
  mainBannerData: shape().isRequired,
  dealBannerData: shape().isRequired,
  uploadMainBanner: func.isRequired,
  uploadDealBanner: func.isRequired,
  updateInfluencer: func.isRequired,
  uploadAvatar: func.isRequired,
};

const mapStateToProps = state => ({
  influencer: state.influencer.item,
  avatarData: state.influencer.avatarData,
  mainBannerData: state.influencer.mainBannerData,
  dealBannerData: state.influencer.dealBannerData,
});

const actionCreators = {
  updateInfluencer,
  uploadAvatar,
  uploadMainBanner,
  uploadDealBanner,
};

export default connect(mapStateToProps, actionCreators)(InfluencerNew);
