/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

const useFiltersEffect = (state, props) => {
  useEffect(() => {
    const { productAttributes, filters } = props;
    if (!isEmpty(productAttributes)) {
      state.setFilters([...filters]);
    }
  }, [props.filters, props.productAttributes]);
};

export default useFiltersEffect;
